import React from 'react';
import { Card } from 'react-bootstrap';
import { ugcType } from '../../Types/ugc.types';
import { UgcCategory } from './UgcCategory';
import { UgcDetection } from './UgcDetection';
import { UgcPlatform } from './UgcPlatform';

import { UgcSearchTerm } from './UgcSearchTerm';

const WidgetRow = (
  row: any,
  index: number,
  startDate: any,
  endDate: any,
  type: string,
  widgetsTitle: any,
  isCustomCalendarRange: boolean,
) => {
  const renderWidgets = (widget: any) => {
    const name = widget?.name?.split('_')[0];
    if (name === 'platform') {
      return (
        <UgcPlatform
          type={type}
          startDate={startDate}
          endDate={endDate}
          widgetsTitle={widgetsTitle?.breakdownByPlatforms}
          isCustomCalendarRange={isCustomCalendarRange}
        />
      );
    } else if (name === 'searchTerm' && type !== ugcType.App_Store) {
      return (
        <UgcSearchTerm
          type={type}
          startDate={startDate}
          endDate={endDate}
          widgetsTitle={widgetsTitle?.breakdownBySearchTerms}
          isCustomCalendarRange={isCustomCalendarRange}
        />
      );
    } else if (name === 'category') {
      return (
        <UgcCategory
          type={type}
          startDate={startDate}
          endDate={endDate}
          widgetsTitle={widgetsTitle?.breakdownByCategory}
          isCustomCalendarRange={isCustomCalendarRange}
        />
      );
    } else if (name === 'detections') {
      return (
        <UgcDetection
          type={type}
          startDate={startDate}
          endDate={endDate}
          widgetsTitle={widgetsTitle?.breakdownByDetections}
          isCustomCalendarRange={isCustomCalendarRange}
        />
      );
    }
    return null;
  };

  return (
    <Card className={'widgets-container'} key={'widget section ' + index}>
      <div key={'widget-left'} className='widget-item-component'>
        <div className='widget-item-inner'>{renderWidgets(row[0])}</div>
      </div>
      <div key={'widget-right'} className='widget-item-component'>
        {renderWidgets(row[1]) !== null && (
          <div className='widget-item-inner'>{renderWidgets(row[1])}</div>
        )}
      </div>
    </Card>
  );
};

const SocialWidgetsSection = ({
  user,
  startDate,
  endDate,
  type,
  widgetsTitle,
  isCustomCalendarRange,
}: any) => {
  const widgets: any[] = [];

  const defaultWidgets = [
    {
      name: 'platform_1243',
      widgetType: 'chart',
      idx: 1243,
    },
    {
      name: 'detections_1234',
      widgetType: 'line',
      idx: 1243,
    },
    {
      name: 'category_1234',
      widgetType: 'chart',
      idx: 1234,
    },
    {
      name: 'searchTerm_1243',
      widgetType: 'chart',
      idx: 1234,
    },
  ];

  switch (type) {
    case ugcType.Social:
      widgets.push(...defaultWidgets);
      break;
    case ugcType.Marketplace:
      widgets.push(...defaultWidgets);
      break;
    case ugcType.App_Store:
      widgets.push(...defaultWidgets.filter(item => item.name !== 'searchTerm_1243'));
      break;
    default:
      break;
  }

  const widgetDoms = [];

  for (let i = 0; i < widgets.length; i = i + 2) {
    const row = [widgets[i], widgets[i + 1]];
    widgetDoms.push(
      WidgetRow(row, i, startDate, endDate, type, widgetsTitle, isCustomCalendarRange),
    );
  }
  return <>{widgetDoms}</>;
};

export { SocialWidgetsSection };

import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { LabelAndValue } from '../Common/LabelAndValue';

import './dashboard.scss';
import { Image, ON_ERROR_IMAGE } from '../Common/Image';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { Checkbox } from '../Common/Checkbox';
import ScanLocationDescription from '../BulkScan/Common/ScanLocationDescription';
import { DispositionStatus } from '../Common/DispositionStatus';
import { getScreenshotUrl } from '../../helpers/screenshotUrl';
import { getIsReadOnlyUser } from '../../basic-hooks/useUserRoles';

export interface IAdditionalProperty {
  id: string;
  label: string;
  value: string;
  renderDom?: any;
  copyButton?: boolean;
  noMarginBottom?: boolean;
}

interface IComponentProps {
  data: any;
  userType?: any;
  properties: IAdditionalProperty[];
  enableCheckbox: boolean;
  selectedItems: any[];
  onItemCheck: (checked: boolean, item: any) => void;
  allUserAgents: any;
}

interface IComponentState {
  isLoading: boolean;
}

class DashboardTile extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    data: {},
    properties: [],
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { data, properties, enableCheckbox, selectedItems, onItemCheck, allUserAgents } =
      this.props;
    const userType = this.props.userType ? this.props.userType : null;

    const {
      url,
      imageUrl,
      ipAddress,
      timestamp,
      urlSha256,
      isNewAdded = false,
      isLoading = false,
      final_category,
      scanLocation,
      wait,
      isLocationBasedEnabled,
      createdTs,
      disposition,
      brandDisplayName,
      networkOwner,
      rescanDomain,
      userAgent,
      scannedBy,
      onSubmitType,
    } = data;

    const userAgentLabel = _.find(allUserAgents, ['userAgent', userAgent])?.label;
    const urls: any[] = [];
    urls.push(url);
    const ipToLink =
      !_.isEmpty(ipAddress) && ipAddress !== '0.0.0.0' ? '/bulk-scan/ip/' + ipAddress : undefined;
    let compClassName = 'dashboard-tile-component table-tile-component';
    if (isNewAdded) {
      compClassName += ' is-new-added';
    }
    if (_.some(selectedItems, ['_index', data._index])) {
      compClassName += ' is-checked';
    }
    const tagProps = _.find(properties, ['id', 'tags']);
    const hasChecked = _.some(selectedItems, ['_index', data._index]);
    const otherProps = _.filter(properties, property => {
      return property?.id !== 'tags';
    });

    const isReadOnlyUser = getIsReadOnlyUser();

    return (
      <div className={compClassName}>
        {isLoading ? (
          <div className={'rescan-started'}>
            {onSubmitType !== 'bulkscan' ? 'Rescan Started' : 'Scan Started'}
          </div>
        ) : (
          !isReadOnlyUser && (
            <div
              className={'rescan-button-wrapper'}
              onClick={() => {
                this.setState({ isLoading: true });
                rescanDomain({
                  urls,
                  scanLocations: [scanLocation],
                  wait: wait === 'Auto' ? undefined : wait,
                  selectedUserAgent: userAgent,
                  onSubmitType: 'rescan',
                });
              }}
            >
              Rescan
            </div>
          )
        )}
        {isLocationBasedEnabled && (
          <Row className={'scan-location-wrapper'}>
            <ScanLocationDescription
              scanDescription={{
                createdTs,
                scanLocation,
                wait,
                userAgent: userAgentLabel,
              }}
            />
          </Row>
        )}
        <Row className='major-content'>
          <Col sm={12} md={12} lg={6}>
            <Image
              className={'screenshot'}
              imagePath={getScreenshotUrl(imageUrl)}
              alt='scan result screenshot'
            />
          </Col>
          <Col sm={12} md={12} lg={6} className='major-info-content'>
            <div className='d-flex'>
              <Col sm={8} className='dashboard-tile-equal-width'>
                <LabelAndValue
                  copyButton
                  label={'Source URL'}
                  value={url}
                  longTextLineNumberLimit={1}
                  toLink={'/bulk-scan/insights/' + timestamp + '/' + urlSha256}
                  direction={'column'}
                />
              </Col>
              <Col sm={4} className='dashboard-tile-equal-width'>
                <LabelAndValue
                  label={'Disposition'}
                  value={disposition}
                  direction={'column'}
                  renderDom={<DispositionStatus status={disposition} displayedStyle='pill' />}
                />
              </Col>
            </div>
            <div className='ip-and-status d-flex'>
              <Col sm={8} className='dashboard-tile-equal-width'>
                <LabelAndValue
                  label={'IP Address'}
                  value={_.isEmpty(ipAddress) || ipAddress === '0.0.0.0' ? '--' : ipAddress}
                  toLink={ipToLink}
                  direction={'column'}
                />
              </Col>
              <Col sm={4} className='dashboard-tile-equal-width'>
                <LabelAndValue label={'Brand'} value={brandDisplayName} direction={'column'} />
              </Col>
            </div>
            <div className='d-flex'>
              <Col sm={8} className='dashboard-tile-equal-width'>
                <LabelAndValue
                  label={'Hosting Provider'}
                  value={networkOwner}
                  direction={'column'}
                  noMarginBottom
                />
              </Col>
              <Col sm={4} className='dashboard-tile-equal-width'>
                <LabelAndValue
                  noMarginBottom
                  label={'Category'}
                  value={final_category}
                  direction={'column'}
                />
              </Col>
            </div>
            {scannedBy && (
              <div className='scanned-by-block d-flex'>
                <Col sm={8}>
                  <LabelAndValue
                    label={'Scan Source'}
                    value={`${scannedBy.firstName} ${scannedBy.lastName}`}
                    direction={'column'}
                  />
                </Col>
              </div>
            )}
          </Col>
        </Row>
        <Row className='secondary-content d-none d-lg-flex'>
          {tagProps && (
            <LabelAndValue
              noColon
              longTextLineNumberLimit={1}
              noMarginBottom={tagProps.noMarginBottom}
              label={''}
              value={tagProps.value || '--'}
              renderDom={tagProps.renderDom}
              direction={'column'}
            />
          )}
        </Row>
        <Row className='secondary-content d-none d-lg-flex'>
          {_.map(otherProps, (property: IAdditionalProperty) =>
            property ? (
              <Col md={3} key={property.label + property.value}>
                <div>
                  <LabelAndValue
                    longTextLineNumberLimit={1}
                    copyButton={property.copyButton}
                    noMarginBottom={property.noMarginBottom}
                    label={property.label}
                    value={property.value || '--'}
                    renderDom={property.renderDom}
                    direction={'column'}
                  />
                </div>
              </Col>
            ) : null,
          )}
        </Row>
        {isLoading && <LoadingWrapper className={'tile-loading-mask'} />}
        {enableCheckbox && !isReadOnlyUser && (
          <Checkbox
            onChange={(checked: boolean) => {
              onItemCheck(checked, data);
            }}
            defaultChecked={hasChecked}
            isStateless
          />
        )}
      </div>
    );
  }
}

export { DashboardTile };

import React from 'react';
import { AlertActionsTypes, DashBoardDto, IAttribute } from '../../../constants';
import { IDropdownOption } from '../../Common/Dropdown';

export enum EPlaybookSteps {
  INTITIATE_PLAYBOOK = 0,
  DEFINE_OUTPUT = 1,
  DEFINE_QUERY = 2,
  DEFINE_CONNECTOR = 3,
  DEFINE_SCHEDULE = 4,
}
export interface IPlaybooksTableProps {
  playbooks: any[];
  setModalShown: (show: boolean) => void;
  setUseTemplate: (show: boolean) => void;
  onRunNowPlaybook: (playbook: any) => void;
  onDeletePlaybook: (playbook: any) => void;
  setNewPlaybook: (show: boolean) => void;
  tableId: string;
}

export interface IPlaybooksProps {
  user: DashBoardDto;
  attributes: IAttribute[];
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  type: string;
  tableId: string;
}

export interface ITemplate {
  name: string;
  description: string;
  steps: any;
  attributes: any;
  outputType: string;
  filters: any;
  connector: any;
  timezone: string;
  scheduleDropdown: any;
}

export interface IPlaybook extends ITemplate {
  id: string;
  author: string;
  createdBy: object;
  createdTs: string;
  updatedTs: string;
  startDate: string;
  outputTo: string;
  schedule: string;
  displaySchedule: string;
  nextRun: string;
  isActive: boolean;
  internalPlaybook: boolean;
  dateRanges: any;
  runCount: number;
  totalResultCount: number;
  lastRunCount: number;
  lastRun: any;
}
export interface IConnectorOption {
  label: string;
  value: string;
  fields?: any[];
}

export interface INewPlaybookModal {
  templates: ITemplate[];
  useTemplate: boolean;
  modalShown: boolean;
  newPlaybook: boolean;
  setModalShown: (show: boolean) => void;
  setNewPlaybook: (show: boolean) => void;
  onSubmit: (values: any, success: any, failure: any) => void;
  dataClass: any[];
  type: string;
  addPlaybookFilterIds: (stringValue: string) => void;
  resetFilterIds: () => void;
  removePlaybookFilterId: (filterValue: string) => void;
}

export const ACTION_OPTIONS: IDropdownOption[] = [
  {
    label: 'Edit',
    value: 'edit',
  },
  {
    label: 'Delete',
    value: 'delete',
  },
];

export const SORT_DIRECTION_OPTION = [
  {
    value: 'asc',
    label: 'Ascending',
  },
  {
    value: 'desc',
    label: 'Descending',
  },
];

export const OUTPUT_TYPE_OPTION = [
  {
    value: 'JSON',
    label: 'JSON',
  },
  {
    value: 'CSV',
    label: 'CSV',
  },
];

export const PLAYBOOK_STEPS = [
  {
    stepId: EPlaybookSteps.DEFINE_OUTPUT,
    label: 'Define output',
  },
  {
    stepId: EPlaybookSteps.DEFINE_QUERY,
    label: 'Define query',
  },
  {
    stepId: EPlaybookSteps.DEFINE_CONNECTOR,
    label: 'Define connector',
  },
  {
    stepId: EPlaybookSteps.DEFINE_SCHEDULE,
    label: 'Schedule',
  },
];

import React, { useState, createContext, useEffect } from 'react';
import _ from 'lodash';
import { getLocalStorageValue, setLocalStorageValue } from '../constants';

const ActiveBrowserTabContext = createContext({
  isTabHidden: false,
  onSameDomain: false,
  handleVisibilityChange: () => {},
});

export default ActiveBrowserTabContext;

interface IActiveBrowserTabProps {
  children: React.ReactElement;
}

export const ActiveBrowserTabContextProvider = (props: IActiveBrowserTabProps): any => {
  const { children } = props;
  const [isTabHidden, setIsTabHidden] = useState<boolean>(false);
  const [onSameDomain, setOnSameDomain] = useState<boolean>(false);

  useEffect(() => {
    setLocalStorageValue('isTabHidden', false);
    setLocalStorageValue('onSameDomain', true);
  }, []);

  useEffect(() => {
    // event listener to detect tab visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isTabHidden, onSameDomain]);

  const handleVisibilityChange = () => {
    const currentOrigin = window.location.origin;
    const otherTabOrigin: any = process.env.REACT_APP_BOLSTER_PLATFORM_URL;
    const storedState = getLocalStorageValue('isTabHidden');

    if (storedState !== null && storedState !== '{}') {
      setIsTabHidden(storedState);
    }

    if (otherTabOrigin) {
      if (document.hidden) {
        // if the tab is hidden, store the state in local storage
        setLocalStorageValue('isTabHidden', true);
        setLocalStorageValue('onSameDomain', currentOrigin.includes(otherTabOrigin));
        setIsTabHidden(true);
        setOnSameDomain(currentOrigin.includes(otherTabOrigin));
      } else {
        // if the tab is visible, check if the state is stored in local storage and update the state
        if (currentOrigin.includes(otherTabOrigin)) {
          setLocalStorageValue('isTabHidden', !currentOrigin.includes(otherTabOrigin));
          setLocalStorageValue('onSameDomain', currentOrigin.includes(otherTabOrigin));
          setIsTabHidden(!currentOrigin.includes(otherTabOrigin));
          setOnSameDomain(currentOrigin.includes(otherTabOrigin));
        }
      }
    }
  };

  return (
    <ActiveBrowserTabContext.Provider
      value={{
        isTabHidden,
        onSameDomain,
        handleVisibilityChange,
      }}
    >
      {children}
    </ActiveBrowserTabContext.Provider>
  );
};

import React from 'react';
import './DispositionStatus.scss';

export interface IDispositionComponentProps {
  status: string;
  className?: string;
  displayedStyle?: 'plain' | 'pill';
  children?: React.ReactNode;
}

class DispositionStatus extends React.Component<IDispositionComponentProps> {
  render() {
    const { status = '', className = '', displayedStyle = 'plain' } = this.props;
    let compClassName = 'disposition-status-component ' + className;
    switch (status?.toLocaleLowerCase()) {
      case 'clean':
        compClassName += ' disposition-clean';
        break;
      case 'scam':
      case 'phish':
      case 'phishing':
        compClassName += ' disposition-phish';
        break;
      case 'likely_phish':
        compClassName += ' disposition-likely-phish';
        break;
      case 'suspicious':
        compClassName += ' disposition-suspicious';
        break;
      default:
        compClassName += ' disposition-others';
        break;
    }

    if (displayedStyle === 'pill') {
      compClassName += ' pill';
    }

    return (
      <div className={compClassName}>
        {status?.replace('_', ' ')}
        {this.props.children}
      </div>
    );
  }
}

export { DispositionStatus };

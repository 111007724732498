import {
  APP_STORE_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
} from '../AppStore/constants';
import {
  MARKET_PLACE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_REVIEW_PATHNAME,
  MARKET_PLACE_PATHNAME,
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
} from '../Marketplace/constants';
import {
  SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
  SOCIAL_MEDIA_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME,
} from '../SocialMedia/constants';
import { ugcType } from './Types/ugc.types';
export const SEARCH_TERM_TABLE_ID = 'searchesTerm';

export const socTakenDownInProgress = '/takedown-in-progress';
export const socReview = '/review';

export const ugcTypeAndLiveRoutes = {
  [ugcType.Social]: SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME,
  [ugcType.Marketplace]: MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  [ugcType.App_Store]: APP_STORE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
};

export const ugcTypeAndtakedownRoutes = {
  [ugcType.Social]: SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  [ugcType.Marketplace]: MARKET_PLACE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  [ugcType.App_Store]: APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
};

export const ugcTypeAndSafeListRoutes = {
  [ugcType.Social]: SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
  [ugcType.Marketplace]: MARKET_PLACE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
  [ugcType.App_Store]: APP_STORE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
};

export const ugcTypeAndReviewRoutes = {
  [ugcType.Social]: SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
  [ugcType.Marketplace]: MARKET_PLACE_PATHNAME_FINDINGS_REVIEW_PATHNAME,
  [ugcType.App_Store]: APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME, // TODO: Added takedown in progress route to redirect on adding new detection
};

export const ugcTypeAndInsightRoutes = {
  [ugcType.Social]: `${SOCIAL_MEDIA_PATHNAME}/insights`,
  [ugcType.Marketplace]: `${MARKET_PLACE_PATHNAME}/insights`,
  [ugcType.App_Store]: `${APP_STORE_PATHNAME}/insights`,
};

export const UGC_DETECTION_PATHNAME = 'detection';
export const UGC_IN_PROGRESS_PATHNAME = 'takedown-in-progress';
export const UGC_IGNORED_PATHNAME = 'ignored';
export const UGC_TAKENDOWN_PATHNAME = 'takendown';
export const UGC_REVIEW_PATHNAME = 'review';

const commons = [
  {
    label: 'Insight Page(s) of External Link',
    propName: 'linksInContent',
    type: 'linksInContent',
    navToInsight: true,
    copyButton: true,
    labelWithoutExternalLink: 'External Link',
  },
];
export const FINDING_METADATA_TEMPLATE = {
  YouTube: {
    Video: [
      { label: 'Title', propName: 'videoTitle' },
      { label: 'Channel Name', propName: 'channelName' },
      { label: 'Channel Subscriber Count', propName: 'channelSubscriberCount' },
      { label: 'Is Verified', propName: 'isVerified' },
      { label: 'Tags', propName: 'tags' },
      { label: 'Likes Count', propName: 'likesCount' },
      { label: 'Views Count', propName: 'viewsCount' },
      { label: 'Dislikes Count', propName: 'dislikesCount' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      ...commons,
    ],
  },
  Facebook: {
    Advertisement: [
      { label: 'ID', propName: 'id' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      { label: 'Ad', propName: 'adPageLink', redirectButton: true },
      { label: 'Page Hosting the Ad', propName: 'fbPageLink', redirectButton: true },
      {
        label: 'Insight Page for Malicious URL',
        propName: 'linksInContent',
        type: 'linksInContent',
        copyButton: true,
        navToInsight: true,
        labelWithoutExternalLink: 'External Link',
      },
    ],
    Page: [
      { label: 'Page Name', propName: 'pageName' },
      { label: 'Is Verified', propName: 'isVerified' },
      { label: 'Likes Count', propName: 'likesCount' },
      { label: 'Followers Count', propName: 'followersCount' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      ...commons,
    ],
    Post: [
      { label: 'Likes Count', propName: 'likesCount' },
      { label: 'Comments Count', propName: 'commentsCount' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      { label: 'Posted By', propName: 'postedBy' },
      { label: 'Is Verified', propName: 'isVerified' },
      ...commons,
    ],
  },
  Instagram: {
    Profile: [
      { label: 'Account Name', propName: 'accountName' },
      { label: 'Is Verified', propName: 'isVerified' },
      { label: 'Posts Count', propName: 'postsCount' },
      { label: 'Followers Count', propName: 'followersCount' },
      ...commons,
    ],
    Post: [
      { label: 'Posted By', propName: 'postedBy' },
      { label: 'Is Verified', propName: 'isVerified' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      ...commons,
    ],
  },
  LinkedIn: {
    Profile: [
      { label: 'Profile Name', propName: 'profileName' },
      { label: 'Headline', propName: 'headline' },
      { label: 'Location', propName: 'location' },
      ...commons,
    ],
  },
  Reddit: {
    Post: [
      { label: 'Title', propName: 'postTitle' },
      { label: 'Posted By', propName: 'postedBy' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      { label: 'Comments Count', propName: 'commentsCount' },
      ...commons,
    ],
  },
  Twitter: {
    Profile: [
      { label: 'Account Name', propName: 'accountName' },
      { label: 'Account ID', propName: 'accountId' },
      { label: 'Is Verified', propName: 'isVerified' },
      { label: 'Number of Tweets', propName: 'tweets', type: 'length' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      { label: 'Followers Count', propName: 'followersCount' },
      ...commons,
    ],
    Tweet: [
      { label: 'Posted By', propName: 'postedByUsername' },
      { label: 'User ID', propName: 'postedByUserid' },
      { label: 'Is Verified', propName: 'isVerified' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
      ...commons,
    ],
  },
  Discord: {
    Server: [
      { label: 'ID', propName: 'id' },
      { label: 'Invite Link', propName: 'inviteLink' },
      { label: 'Title', propName: 'title' },
      ...commons,
    ],
  },
  Amazon: {
    Product: [
      { label: 'Product Name', propName: 'productName' },
      { label: 'Product Price', propName: 'priceInUsd', prefix: '$' },
      { label: 'Posted Owner', propName: 'postedOwner' },
      { label: 'Ratings', propName: 'ratings' },
    ],
  },
  Walmart: {
    Product: [
      { label: 'Product Name', propName: 'productName' },
      { label: 'Product Price', propName: 'priceInUsd', prefix: '$' },
      { label: 'Posted Owner', propName: 'postedOwner' },
      { label: 'Ratings', propName: 'ratings' },
    ],
  },
  eBay: {
    Product: [
      { label: 'Product Name', propName: 'productName' },
      { label: 'Product Price', propName: 'priceInUsd', prefix: '$' },
      { label: 'Posted Owner', propName: 'postedOwner' },
      { label: 'Ratings', propName: 'ratings' },
      { label: 'Condition', propName: 'condition' },
    ],
  },
  TikTok: {
    Video: [
      { label: 'Title', propName: 'videoTitle' },
      { label: 'Channel Name', propName: 'channelName' },
      { label: 'Channel Subscriber Count', propName: 'channelSubscriberCount' },
      { label: 'Is Verified', propName: 'isVerified' },
      { label: 'Tags', propName: 'tags' },
      { label: 'Likes Count', propName: 'likesCount' },
      { label: 'Views Count', propName: 'viewsCount' },
      { label: 'Active Since', propName: 'activeSince', type: 'time' },
    ],
  },
};

export const SOC_TAKEDOWN_OPTIONS = {
  Facebook: [
    { label: 'Trademark', value: 'trademark' },
    { label: 'Copyright', value: 'copyright' },
  ],
  Instagram: [{ label: 'Trademark', value: 'trademark' }],
  Discord: [{ label: 'Any abuse, Spam', value: 'Any abuse, Spam' }],
  Twitter: [
    { label: 'Trademark', value: 'trademark' },
    { label: 'Copyright', value: 'copyright' },
    { label: 'Counterfeit', value: 'counterfeit' },
  ],
  LinkedIn: [
    { label: 'Fake Profile', value: 'fake_profile' },
    { label: 'Trademark', value: 'trademark' },
    { label: 'Unclaimed Page', value: 'unclaimed_page' },
  ],
  Reddit: [
    { label: 'Fraud', value: 'fraud' },
    { label: 'Copyright', value: 'copyright' },
    { label: 'Trademark', value: 'trademark' },
  ],
  Tiktok: [
    { label: 'Flagging', value: 'flagging' },
    { label: 'Copyright', value: 'copyright' },
    { label: 'General Form', value: 'general_form' },
  ],
  YouTube: [
    { label: 'Circumvention', value: 'circumvention' },
    { label: 'Copyright', value: 'copyright' },
    { label: 'Trademark', value: 'trademark' },
    { label: 'Counterfeit', value: 'counterfeit' },
    { label: 'Other', value: 'other' },
  ],
  Walmart: [
    {
      label: 'Trademark, Patent, Copyright, Counterfeit',
      value: 'Trademark, Patent, Copyright, Counterfeit',
    },
  ],
};

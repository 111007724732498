import React, { useState, useContext } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AttachedFileViewer from '../../../../Common/AttachedFileViewer';
import { AttachedFileObject } from '../../../../Common/DisputeStatus';
import { getNameFromUrlPath } from '../../../../../constants';
import ThemeContext from '../../../../../context/ThemeContext';
import { NextIcon } from '../../../../../assets/SVGIcons';

interface ILogoImageColRenderProps {
  data: any;
}

const LogoImageColRender = ({ data }: ILogoImageColRenderProps) => {
  const [shown, toggleShown] = useState<boolean>(false);

  const { selectedTheme } = useContext(ThemeContext);
  return (
    <div className={'logo-image-render-component d-flex align-items-center'}>
      {data?.logos?.length ? (
        <OverlayTrigger
          trigger='click'
          placement='bottom-start'
          onToggle={toggleShown}
          overlay={
            <Tooltip
              id={`tooltip-takedown-${data.srcUrl}`}
              className={`logo-image-col-tooltip table-icon-tooltip ${selectedTheme}`}
            >
              <AttachedFileViewer
                attachedFiles={data.logos?.map((upload: any): AttachedFileObject => {
                  return {
                    name: getNameFromUrlPath(upload.url),
                    path: upload.url,
                    createdTS: '',
                  };
                })}
              />
            </Tooltip>
          }
        >
          <div className={'col-value cursor-pointer ' + (shown ? 'blue-background' : '')}>
            {data.logos.length}
            <div className='icon'>
              <NextIcon color='black' />
            </div>
          </div>
        </OverlayTrigger>
      ) : (
        <div className={'col-value'}>0</div>
      )}
    </div>
  );
};

export default LogoImageColRender;

import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { DateRange } from 'react-date-range';
import enhanceWithClickOutside from 'react-click-outside';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import './DropdownDateRangePicker.scss';
import calendarIcon from '../../assets/icons/Calendar.svg';
import calendarWhiteIcon from '../../assets/icons/CalendarWhite.svg';

import { IDropdownOption } from './Dropdown';
import ThemeContext from '../../context/ThemeContext';
import { appConstants, DashBoardDto, featureIsAvailable, ThemeModes } from '../../constants';
interface IDateRangeSelection {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IRange {
  selection: IDateRangeSelection;
}

interface IDateRangeDropdownOption extends IDropdownOption {
  ranges?: IRange;
}

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  onChange: (
    startDate: moment.Moment,
    endDate: moment.Moment,
    lastXDay: number,
    isCustomCalendarRange: boolean,
  ) => void;
  type?: string;
  user?: DashBoardDto;
}

interface IComponentState {
  expanded: boolean;
  showCalendar: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  key: string;
}

class DropdownDateRangePicker extends React.Component<IComponentProps, IComponentState> {
  private dateRangeSelected = 0;
  private lastXDay = 0;
  private readonly OPTIONS: IDateRangeDropdownOption[];
  static contextType = ThemeContext;

  static defaultProps = {
    startDate: moment().subtract(1, 'week'),
    endDate: moment(),
    onChange: _.noop,
  };

  constructor(props: IComponentProps) {
    super(props);
    const today = moment();
    const weekStart = moment(moment().subtract(7, 'day'));
    const monthStart = moment(moment().subtract(30, 'day'));
    const allStart = moment(moment().subtract(90, 'day'));
    const halfYearStart = moment(moment().subtract(180, 'day'));
    const lastTwelevMonths = moment(moment().subtract(365, 'days'));
    const thisYearStart = moment().startOf('year');
    const lastYearStart = moment().subtract(1, 'years').startOf('year');
    const twoYearStart = moment().subtract(2, 'years');
    const thisYearEnd = today;
    const lastYearEnd = moment().subtract(1, 'years').endOf('year');
    const twoYearEnd = moment().subtract(2, 'years').endOf('year');

    this.OPTIONS = [
      {
        label: 'Last 30 days',
        value: '30',
        ranges: {
          selection: {
            startDate: monthStart,
            endDate: today,
          },
        },
      },
      {
        label: 'Last 90 days',
        value: '90',
        ranges: {
          selection: {
            startDate: allStart,
            endDate: today,
          },
        },
      },
    ];
    if (this.props.type !== appConstants.CONTENT_TYPE.REPORT) {
      this.OPTIONS.unshift({
        label: 'Last 7 days',
        value: '7',
        ranges: {
          selection: {
            startDate: weekStart,
            endDate: today,
          },
        },
      });
    }
    if (this.props.type !== appConstants.CONTENT_TYPE.CHECK_PHISH) {
      this.OPTIONS.push({
        label: 'Last 180 days',
        value: '180',
        ranges: {
          selection: {
            startDate: halfYearStart,
            endDate: today,
          },
        },
      });
    }

    if (this.props.user) {
      //TODO remove this check once the feature testing is done
      const isFeatureEnabled = featureIsAvailable(
        this.props.user,
        appConstants.FEATURE_CODE.ONE_YEAR_FINDINGS,
      );
      if (isFeatureEnabled || this.props.type !== appConstants.CONTENT_TYPE.WEB) {
        this.OPTIONS.push({
          label: 'Last 12 Months',
          value: lastTwelevMonths.format('YYYY').toString(),
          ranges: {
            selection: {
              startDate: lastTwelevMonths,
              endDate: today,
            },
          },
        });
      }
    }

    if (this.props.type === appConstants.CONTENT_TYPE.REPORT) {
      const reportOptions = [
        {
          label: 'Year To Date',
          value: thisYearStart.format('YYYY').toString(),
          ranges: {
            selection: {
              startDate: thisYearStart,
              endDate: thisYearEnd,
            },
          },
        },
        {
          label: moment().subtract(1, 'years').format('YYYY').toString(),
          value: lastYearStart.format('YYYY').toString(),
          ranges: {
            selection: {
              startDate: lastYearStart,
              endDate: lastYearEnd,
            },
          },
        },
        {
          label: moment().subtract(2, 'years').format('YYYY').toString(),
          value: twoYearStart.format('YYYY').toString(),
          ranges: {
            selection: {
              startDate: twoYearStart,
              endDate: twoYearEnd,
            },
          },
        },
      ];
      this.OPTIONS = this.OPTIONS.concat(reportOptions);
    }
    this.OPTIONS.push({
      label: 'Custom',
      value: 'custom',
    });
    this.state = {
      expanded: false,
      showCalendar: false,
      startDate: props.startDate,
      endDate: props.endDate,
      key: 'selection',
    };
  }
  componentDidUpdate(prevProps: IComponentProps) {
    if (prevProps.startDate !== this.props.startDate && this.props.type) {
      this.setState({ startDate: this.props.startDate });
    }
    if (prevProps.endDate !== this.props.endDate && this.props.type) {
      this.setState({ endDate: this.props.endDate });
    }
  }

  handleClickOutside() {
    this.setState({
      expanded: false,
      showCalendar: false,
    });
    this.dateRangeSelected = 0;
  }

  toggleMenu = () => {
    const { expanded, showCalendar } = this.state;
    const toExpanded = !expanded;
    const newState = {
      expanded: toExpanded,
      showCalendar: showCalendar && toExpanded,
    };
    this.setState(newState);
    if (!toExpanded) {
      this.dateRangeSelected = 0;
    }
  };

  toggleCalendar = (newState?: boolean) => {
    const { showCalendar } = this.state;
    this.setState({
      showCalendar: typeof newState === 'boolean' ? newState : !showCalendar,
    });
  };

  onSelectOption = (option: IDateRangeDropdownOption) => {
    switch (option.value) {
      case 'custom':
        this.lastXDay = 0;
        this.toggleCalendar();
        break;
      default:
        this.lastXDay = parseInt(option.value, 10);
        this.setState(
          {
            startDate: moment(option.ranges?.selection.startDate),
            endDate: moment(option.ranges?.selection.endDate),
          },
          () => this.confirmDateRange(false),
        );
        break;
    }
  };

  handleSelect = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    this.setState(
      {
        startDate: moment(startDate),
        endDate: moment(endDate),
      },
      () => {
        this.dateRangeSelected++;
        if (this.dateRangeSelected === 2) {
          // this.confirmDateRange()
        }
      },
    );
  };

  confirmDateRange = (isCustomCalendarRange: boolean) => {
    const { startDate, endDate } = this.state;
    this.toggleMenu();
    this.props.onChange(startDate, endDate, this.lastXDay, isCustomCalendarRange);
  };

  render() {
    const { expanded, showCalendar, startDate, endDate } = this.state;
    const { selectedTheme } = this.context;

    const diffDayNumberMap = {
      [appConstants.CONTENT_TYPE.REPORT]: 2,
      [appConstants.CONTENT_TYPE.SOCIAL]: 12,
      [appConstants.CONTENT_TYPE.APP_STORE]: 12,
      [appConstants.CONTENT_TYPE.WEB]: 12,
      [appConstants.CONTENT_TYPE.CHECK_PHISH]: 180,
    };

    const diffPeriodTypeMap = {
      [appConstants.CONTENT_TYPE.REPORT]: 'year',
      [appConstants.CONTENT_TYPE.SOCIAL]: 'months',
      [appConstants.CONTENT_TYPE.APP_STORE]: 'months',
      [appConstants.CONTENT_TYPE.WEB]: 'months',
      [appConstants.CONTENT_TYPE.CHECK_PHISH]: 'day',
    };

    let toggleButtonClassName = 'date-range-dropdown btn btn-sm dropdown-toggle';
    let menuClassName = 'dropdown-menu drop-down-left';

    if (expanded) {
      toggleButtonClassName += ' blue-box';
      menuClassName += ' dropdown-menu-shown';

      if (showCalendar) {
        menuClassName += ' calendar-shown';
      }
    }
    const diffDayNumber: number = this.props.type ? diffDayNumberMap[this.props.type] : 180;
    const diffType = this.props.type ? diffPeriodTypeMap[this.props.type] : 'day';
    const minDate = new Date(
      moment()
        .subtract(diffDayNumber, diffType as any)
        .valueOf(),
    );
    const maxDate = new Date(moment().valueOf());
    const selectionRange = {
      startDate: new Date(startDate as any),
      endDate: new Date(endDate as any),
      key: 'selection',
    };

    return (
      <div className={'dropdown-date-range-picker-component btn-group'}>
        <button
          className={toggleButtonClassName}
          type='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
          onClick={this.toggleMenu}
          id='dropdown-date-range-picker'
          data-testid='dropdown-date-range-picker'
        >
          <img
            src={selectedTheme === ThemeModes.DARK.toLowerCase() ? calendarWhiteIcon : calendarIcon}
            alt={'calendar'}
          />
          <span>{startDate.format('MMM DD, YYYY') + ' - ' + endDate.format('MMM DD, YYYY')}</span>
        </button>
        <div className={menuClassName} data-testid='dropdown-date-range-picker-menu'>
          {showCalendar ? (
            <DateRange
              ranges={[selectionRange]}
              onChange={this.handleSelect}
              minDate={minDate}
              maxDate={maxDate}
              months={2}
              direction={'horizontal'}
              rangeColors={
                selectedTheme === ThemeModes.DARK.toLowerCase() ? ['#28486D'] : ['#d3e1fa']
              }
            />
          ) : (
            <div className={'option-items-container'}>
              {this.OPTIONS.map(option => {
                return (
                  <div
                    key={option.value}
                    className={'option-item d-flex align-items-center'}
                    onClick={() => this.onSelectOption(option)}
                  >
                    <span className={'option-item-label'}>{option.label}</span>
                    {option.ranges && (
                      <span className={'option-item-value'}>
                        {option.ranges?.selection.startDate.format('MMM DD,YYYY') +
                          ' - ' +
                          option.ranges?.selection.endDate.format('MMM DD,YYYY')}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {showCalendar && (
            <div className={'submit-button-container'}>
              <Button
                className={'submit-button'}
                variant='primary'
                size='sm'
                onClick={() => this.confirmDateRange(true)}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const EnhancedDropdownDateRangePicker = enhanceWithClickOutside(DropdownDateRangePicker);
export { EnhancedDropdownDateRangePicker as DropdownDateRangePicker };

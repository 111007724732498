import React from 'react';
import _ from 'lodash';
import { TMapChartDataProps } from '../../../Common/DashboardWidgets/Types/chart.type';
import MapChart from '../../../Common/DashboardWidgets/MapChart';

const massageData = (rawData: any): TMapChartDataProps[] => {
  const formatedData = Object.keys(rawData)
    .filter(item => item.toLowerCase() !== 'generic')
    .map(item => ({ label: item, count: rawData[item] }));
  return _.map(
    formatedData,
    (i): TMapChartDataProps => ({
      location: i.label,
      count: i.count || 0,
    }),
  );
};

interface IComponentProps {
  isLoading: boolean;
  widgetData: any;
}

const DashboardRegion: React.FC<IComponentProps> = (props: IComponentProps) => {
  const widgetData = props.widgetData;
  const isLoading = props.isLoading;
  const result = massageData(widgetData);

  return (
    <div className='dashboard-region-widget'>
      <MapChart
        title='Top Originating Countries'
        isLoading={isLoading}
        description='New Phish and Scam sites detected in countries'
        data={result}
      />
    </div>
  );
};

export { DashboardRegion };

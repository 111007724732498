import React from 'react';

const TextCenterWithChevronRight = ({ iconSrc, children, hidden }: any) => {
  return (
    <>
      <div className='acq-chevron-right opacity-0'>
        <img src={iconSrc} alt='chevron' />
      </div>
      {children}
      <div className={`${hidden ? 'acq-chevron-right hidden' : 'acq-chevron-right'}`}>
        <img src={iconSrc} alt='chevron' />
      </div>
    </>
  );
};

export default TextCenterWithChevronRight;

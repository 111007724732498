import fetchApi from '../../../../../services/api.service';
import { handleResponse } from '../../../../../services/serviceWorker';

export const getInsightDetails = async (sha256: string, moduleType: string): Promise<any> => {
  const rawResponse = await fetchApi(
    `/platform-api/v1/content/${moduleType}/finding/${sha256}`,
  ).then(handleResponse);
  return rawResponse;
};

export const getSafeListDetails = async (sha256: string, moduleType: string): Promise<any> => {
  const rawResponse = await fetchApi(
    `/platform-api/v1/content/${moduleType}/finding/${sha256}/safelist`,
  ).then(handleResponse);
  return rawResponse;
};

export const getTakedownDetails = async (sha256: string, moduleType: string): Promise<any> => {
  const rawResponse = await fetchApi(
    `/platform-api/v1/content/${moduleType}/finding/${sha256}/takedown`,
  ).then(handleResponse);
  return rawResponse;
};

export const getTagsByUrlSha = async (sha256: string, moduleType: string): Promise<any> => {
  const rawResponse = await fetchApi(
    `/platform-api/v1/content/${moduleType}/finding/${sha256}/tag`,
  ).then(handleResponse);
  return rawResponse;
};

export const getScanSourceInfo = async (sha256: string): Promise<any> => {
  const rawResponse = await fetchApi(
    `/platform-api/v1/platform-administration/get-scan-source-info/${sha256}`,
  ).then(handleResponse);
  return rawResponse;
};

export const getUgcTimeLineDetails = async (sha256: string): Promise<any> => {
  const rawResponse = await fetchApi(`/platform-api/v1/event-history/timeline/${sha256}`).then(
    handleResponse,
  );
  return rawResponse;
};

import React, { useEffect } from 'react';
import './NavItem.scss';
import { Link } from 'react-router-dom';
import { ThemeModes, userDoesNotHasTyposquatData } from '../../../constants';
import { useState } from 'react';
import NavItemV2 from './NavItemV2';
import _ from 'lodash';
import { navItemIsActive } from '../../../helpers/url-helpers';
import LockIcon from '../../../assets/icons/LockGray.svg';
import LockIconDark from '../../../assets/icons/LockGrayDark.svg';
import ExpandCollapseIcon from '../../Common/ExpandCollapseIcon';
import { useContext } from 'react';
import ActiveAppContext from '../../../context/CheckphishActiveAppContext';
import { PRICING_PAGE_PATH } from '../../Pricing/constants';
import { useGetUser } from '../../../hooks/useAvailableModules';

type Props = {
  navItem: any;
  selectedTheme: string;
};

const ExpandableNavItem = ({ navItem, selectedTheme }: Props) => {
  const {
    id,
    label,
    href,
    subItems,
    iconDarkTheme,
    iconLightTheme,
    iconActiveDarkTheme,
    iconActiveLightTheme,
    disabled = false,
    isNew = false,
    hasIcon = false,
    isLocked = false,
    lockExpand = false,
    isSubMenuText = false,
  } = navItem;
  const [expandNavItem, setExpandNavItem] = useState<boolean>(false);
  const [icon, setIcon] = useState<any>(null);
  const { selectedApp } = useContext(ActiveAppContext);
  const user = useGetUser();

  useEffect(() => {
    if (selectedApp.value === 'checkphish' || isSubMenuText) {
      return setExpandNavItem(true);
    }
  }, [selectedApp]);

  const selectNavIcon = () => {
    if (isSubItemActive() || navItemIsActive(href)) {
      return selectedTheme.toLocaleLowerCase() === ThemeModes.LIGHT.toLocaleLowerCase()
        ? iconActiveLightTheme
        : iconActiveDarkTheme;
    }
    return selectedTheme.toLowerCase() === ThemeModes.LIGHT.toLocaleLowerCase()
      ? iconLightTheme
      : iconDarkTheme;
  };

  const expandNav = () => {
    if (window.location.pathname === PRICING_PAGE_PATH && label === 'Domain Monitoring') {
      return setExpandNavItem(true);
    }
    if (userDoesNotHasTyposquatData(user) && label === 'Domain Monitoring') {
      return setExpandNavItem(true);
    }
    if (!hasIcon) return;
    if (lockExpand) return setExpandNavItem(true);
    const subItemIsActive = isSubItemActive();
    const isActive = navItemIsActive(href);
    setExpandNavItem(isActive || subItemIsActive);
  };

  const onLinkClick = (event: any) => {
    if (disabled || isLocked) {
      event?.preventDefault();
    }
  };

  const showExpandedLine = subItems.reduce((accumulativeValue: boolean, currentItem: any) => {
    return accumulativeValue && _.isEmpty(currentItem.subItems);
  }, true);

  const isSubItemActive = () => {
    let isActive = false;
    if (_.isEmpty(subItems)) {
      return false;
    }
    let updatedSubMenu: any[] = [];
    subItems.forEach((subItem: any) => {
      if (subItem.isSubMenuText) {
        updatedSubMenu = [...updatedSubMenu, ...subItem.subItems];
      } else {
        updatedSubMenu = [...updatedSubMenu, subItem];
      }
    });
    updatedSubMenu.forEach((subItem: any) => {
      if (navItemIsActive(subItem.href)) {
        isActive = true;
      }
    });
    return isActive;
  };

  const setLockIcon = () => {
    return selectedTheme === ThemeModes.LIGHT.toLocaleLowerCase() ? LockIcon : LockIconDark;
  };

  useEffect(() => {
    expandNav();
  }, []);

  useEffect(() => {
    expandNav();
    const navIcon = selectNavIcon();
    setIcon(navIcon);
  }, [window.location.pathname, selectedTheme]);

  return (
    <div className={`expandable-item-wrap ${isSubMenuText ? ' no-spacing' : ''}`}>
      <div
        className={`expandable-item nav-item ${navItemIsActive(href) ? 'active' : ''} ${
          disabled ? 'disabled' : ''
        } ${isLocked ? 'locked-item' : ''}`}
      >
        <button className='lable-wrap'>
          {hasIcon ? (
            <img className='item-icon' src={icon} />
          ) : (
            <ExpandCollapseIcon
              isExpanded={expandNavItem}
              onClick={() => expandNav()}
              isDisabled={isSubMenuText}
            />
          )}
          <Link
            to={href}
            className={`item-label nav-link ${disabled ? 'disabled-link' : ''}`}
            onClick={onLinkClick}
          >
            {label}
            {isNew && <span className='is-new-tag'>New!</span>}
          </Link>
          {isLocked && <img src={setLockIcon()} />}
        </button>
        {hasIcon && !lockExpand && (
          <button onClick={() => expandNav()} data-testid='expand-collapse-icon'>
            <ExpandCollapseIcon isExpanded={expandNavItem} isDisabled={isSubMenuText} />
          </button>
        )}
      </div>
      {expandNavItem && (
        <div className={`subitem-wrap ${showExpandedLine ? 'expanded-line' : ''}`}>
          {subItems.map((item: any, index: number) => {
            return <NavItemV2 key={index} navItem={item} selectedTheme={selectedTheme} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ExpandableNavItem;

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';
import { AppState } from '../../../helpers';
import '../../Dashboard_v2/SubComponents/dashboard.scss';
import { alertActions, dashboardActions } from '../../../actions';
import { ILinkStateProp, ILinkDispatchProps } from '../../Dashboard_v2/Types/dashboard.types';
import { WidgetsSection } from '../../Dashboard_v2/SubComponents/Widgets/DashboardWidgetsSection';
import { DEFAULT_DATE_RANGE } from '../../../constants';
import { MonitoredContainerV2 } from '../../Dashboard_v2/SubComponents/MonitoredDomainsV2/MonitoredContainerV2';
import { Table } from '../../Common/Table/table';
import '../../DomainMonitoring/typosquat.scss';
import { TypoDispositionScan } from '../../../types/typosquat-dashboard.interface';
import RightArrowWhite from '../../../assets/icons/RightArrowWhite.svg';
import RightArrowBlack from '../../../assets/icons/RightArrowBlack.svg';
import { history } from '../../../helpers';
import { TYPOSQUATTING_MASTER_PATHNAME } from '../../DomainMonitoring/constant';
import ThemeContext from '../../../context/ThemeContext';
import { useContext } from 'react';
import {
  buildIpAddressColumn,
  LIVE_SCAN_VERDICT_COLUMN,
  MAILSERVERS_COLUMN,
  NAMESERVERS_COLUMN,
  TAKEDOWN_ENQUIRY_COLUMN,
  buildSourceUrlPermutationColumn,
} from '../../DomainMonitoring/columns';

type Props = ILinkStateProp & ILinkDispatchProps;

const TYPOSQUAT_TABLE_COLUMNS = [
  buildSourceUrlPermutationColumn(false),
  buildIpAddressColumn(false),
  NAMESERVERS_COLUMN,
  MAILSERVERS_COLUMN,
  LIVE_SCAN_VERDICT_COLUMN,
  TAKEDOWN_ENQUIRY_COLUMN,
];

export enum DomainCountLabelEnum {
  MONITOR_FOR_ACQUISITIONS = 'Monitor For Acquisitions',
  TAKEDOWN_MALICIOUS = 'Takedown Malicious',
  MONITOR_PRE_MALICIOUS = 'Monitor Pre-Malicious',
}

const DashboardContainer = ({
  user,
  categories,
  selectedDashboard,
  scanSourceCategories,
  typosquatDashboardAnalytics,
  sendFilters,
  loadFunnelData,
}: Props) => {
  window.document.title = 'Dashboard | Checkphish Platform';
  const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
  const monitoredDomainsForAcquisition = typosquatDashboardAnalytics.domainCounts.filter(
    (c: any) => c.type === DomainCountLabelEnum.MONITOR_FOR_ACQUISITIONS,
  )[0];
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(DEFAULT_DATE_RANGE, 'day'),
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const lastXDay = useRef(DEFAULT_DATE_RANGE);
  const theme = useContext(ThemeContext);

  const transformToTableData = () => {
    return typosquatDashboardAnalytics.topDispositionScans.map(
      (item: TypoDispositionScan, index: number) => ({
        ...item,
        _index: item?.id || index,
        srcUrl: item?.src_url,
        ip: item?.ipv4,
        ip_data: item?.ipv4?.join(' ').replaceAll('0.0.0.0', '--') || '',
        nameserver: item?.ns ? item?.ns.filter(n => n !== '0.0.0.0') : [],
        mailserver: item?.mx ? item?.mx.filter(n => n !== '0.0.0.0') : [],
        live_scan_verdict: item?.disposition,
        takedown_enquiry: item?.takedown_enquiry,
      }),
    );
  };

  const redirectToTyposquatting = () => {
    history.push(TYPOSQUATTING_MASTER_PATHNAME);
  };

  useEffect(() => {
    if (isEditingMode) {
      setIsEditingMode(false);
    } else {
      setIsEditingMode(selectedDashboard.isNew || false);
    }
  }, [selectedDashboard]);

  const onDateRangeChange = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    lastXDayInput: number,
  ) => {
    lastXDay.current = lastXDayInput;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <AuthenticationWrapper>
      <MonitoredContainerV2
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={onDateRangeChange}
        domainCategories={categories}
        user={user}
        scanSourceCategories={scanSourceCategories}
        typosquatDashboardAnalytics={typosquatDashboardAnalytics}
        sendFilters={sendFilters}
        loadFunnelData={loadFunnelData}
      />

      <div className={'page-content dashboard-page dashboard-page-v2'}>
        <Card className={'top-disposition'} key={'widget section top-disposition'}>
          <Card.Header className='self-card-header'>
            Typosquat {typosquatDashboardAnalytics.monitoredDomain}
          </Card.Header>
          <Card.Body className='widgets-container widgets-container-card-body'>
            <div key={'widget-left'} className='widget-item-component'>
              <div className='widget-item-inner'>
                <Table
                  disableToolsBar
                  disableFilter
                  disablePagination
                  data={transformToTableData()}
                  columns={TYPOSQUAT_TABLE_COLUMNS}
                />
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <div className='see-details-btn'>
              <div className='see-details-text' onClick={redirectToTyposquatting}>
                See All
              </div>
              <img src={theme.selectedTheme === 'dark' ? RightArrowWhite : RightArrowBlack} />
            </div>
          </Card.Footer>
        </Card>

        <WidgetsSection
          user={user}
          startDate={startDate}
          endDate={endDate}
          isEditingMode={isEditingMode}
          monitoredDomainsForAcquisitions={monitoredDomainsForAcquisition}
          enableCommunity={true}
        />
      </div>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { categories, scanSourceCategories } = state.appReducer;
  const { user, selectedDashboard } = state.dashboardReducer;
  return {
    user,
    categories,
    selectedDashboard,
    scanSourceCategories,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  getDashboardConfig: dashboardActions.getDashboardConfig,
  onDeleteDashboard: dashboardActions.onDeleteDashboard,
  onSetDefaultDashboard: dashboardActions.onSetDefaultDashboard,
  onChangeDashboard: dashboardActions.onChangeDashboard,
  onSaveDashboard: dashboardActions.onSaveDashboard,
  onDeleteWidgets: dashboardActions.onDeleteWidgets,
};

const connectedDashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
export { connectedDashboard as DashboardContainer };

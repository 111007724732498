import React, { useCallback, useEffect } from 'react';
import { useAppDispatch } from '../../../../helpers/hooks';
import {
  setCurrentDataLeakDescription,
  setCurrentDataLeakEntityType,
  setCurrentDataLeakParams,
  setCurrentSeachTermID,
  setShouldShowDataLeakModal,
  setShouldShowDataLeakTablePopup,
} from '../../../../reducers/darkWeb.reducer';
import { setCurrentRowIndex } from '../../../../reducers/table.reducer';

export default function useOnToggleDataLeakDrawer() {
  const dispatch = useAppDispatch();

  const toggleDrawer = useCallback(() => {
    dispatch(setShouldShowDataLeakTablePopup(false));
    dispatch(setCurrentDataLeakParams(''));
    dispatch(setCurrentDataLeakEntityType(''));
    dispatch(setCurrentDataLeakDescription(''));
    dispatch(setShouldShowDataLeakModal(false));
    dispatch(setCurrentSeachTermID(''));
    dispatch(setCurrentRowIndex(-1));
  }, [dispatch]);

  // Reset the drawer state when the component is unmounted
  useEffect(() => {
    return () => {
      toggleDrawer();
    };
  }, [toggleDrawer]);

  return { toggleDrawer };
}

import React, { useState, createContext, useEffect } from 'react';
import { Nullable } from '../types/common';
import _ from 'lodash';

const ActiveOUContext = createContext({
  activeOUId: null as Nullable<number>,
  setActiveOUId: _.noop,
});

export default ActiveOUContext;

interface IActiveOUProps {
  children: React.ReactElement;
}

export const ActiveOUContextProvider = (props: IActiveOUProps): any => {
  const { children } = props;
  const [activeOUId, setActiveOUId] = useState<Nullable<number>>(null);

  return (
    <ActiveOUContext.Provider
      value={{
        activeOUId,
        setActiveOUId,
      }}
    >
      {children}
    </ActiveOUContext.Provider>
  );
};

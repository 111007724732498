import React from 'react';
import Review from '../../../Ugc/Components/Findings/Review';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  MARKET_PLACE_TABLE_NAME,
  MARKET_PLACE_PATHNAME,
  MARKET_PLACE_FINDING_REVIEW_TABLE_ID,
} from '../../constants';

const MarketplaceReview = () => {
  return (
    <Review
      type={ugcType.Marketplace}
      tableId={MARKET_PLACE_FINDING_REVIEW_TABLE_ID}
      urlColPathName={MARKET_PLACE_PATHNAME}
      tableName={MARKET_PLACE_TABLE_NAME}
      enableSocInterface
    />
  );
};

export default MarketplaceReview;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInsightsContainerState {
  currentTableId: string;
}
const initialState: IInsightsContainerState = {
  currentTableId: '',
};

export const insightsContainerSlice = createSlice({
  name: 'insightsContainer',
  initialState,
  reducers: {
    setCurrentTableId: (state, action: PayloadAction<string>) => {
      state.currentTableId = action.payload;
    },
  },
});

export const { setCurrentTableId } = insightsContainerSlice.actions;

export default insightsContainerSlice.reducer;

import React from 'react';
import { appConstants } from '../../../../constants';
import { Dashboard } from '../../../Ugc/Components/DashboardContainer';
import { IMediaIntroProps, ugcType } from '../../../Ugc/Types/ugc.types';
import MarketplaceLight from '../../../../assets/Marketplace_light.png';
import MarketplaceDark from '../../../../assets/Marketplace_dark.png';
import { widgetsTitle } from '../../../SocialMedia/constants';

const mediaTryOutProps: IMediaIntroProps = {
  featureName: 'Marketplace',
  featureDescription:
    'Detect and remove counterfeit goods and fake listings from popular marketplaces.',
  staticLightImage: MarketplaceLight,
  staticDarkImage: MarketplaceDark,
  featureCode: appConstants.FEATURE_CODE.MARKETPLACE,
};

const MarketPlaceDashboard = () => {
  return (
    <Dashboard
      type={ugcType.Marketplace}
      mediaTryOutProps={mediaTryOutProps}
      widgetsTitle={{ ...widgetsTitle }}
    />
  );
};

export default MarketPlaceDashboard;

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import '../../Styles/EntityWidget.scss';
import EntityDropdown from '../Common/EntityDropdown';
import ExpandTileIcon from '../../../../assets/icons/ExpandTileIcon.svg';
import DonutWidget from './DonutWidget';
import MatrixDataSetWidget from './MatrixDataSetWidget';
import WidgetHeader from '../Common/WidgetHeader';
import CollapseTile from '../../../../assets/icons/CollapseTile.svg';
import { ILableValues } from '../../types';
import { ENTITY_TYPE } from '../../DarkWebConstants';
import { IEntityWidgetProps } from '../../types';
import _ from 'lodash';
import ThemeContext from '../../../../context/ThemeContext';
import { useContext } from 'react';
import { IEntityData } from '../../types';
import { initialEntityData } from '../../DarkWebConstants';
import { setCurrentSeachTermID } from '../../../../reducers/darkWeb.reducer';
import { useAppDispatch } from '../../../../helpers/hooks';

const getDataSum = (dataSet: ILableValues[]) =>
  dataSet.reduce((sum, item) => {
    return _.toNumber(sum) + _.toNumber(item.value);
  }, 0);

const EntityWidget = ({ type, widgetData, entityLables, expandByDefault }: IEntityWidgetProps) => {
  const [entityData, setEntityData] = useState<IEntityData>(initialEntityData);
  const [expandedState, setExpandedState] = useState(expandByDefault);
  const [dropdownValues, setDropDownValues] = useState<ILableValues[]>([]);
  const [dataSetValues, setDataSetValues] = useState<{ uniqueCount: number; totalCount: number }>({
    uniqueCount: 0,
    totalCount: 0,
  });

  const [donutDataSet, setDonutDataSet] = useState<ILableValues[]>([]);
  const [RiskDonutDataSet, setRiskDonutDataSet] = useState<ILableValues[]>([]);
  const toggleExpandedState = () => setExpandedState(oldState => !oldState);
  const { selectedTheme } = useContext(ThemeContext);

  useEffect(() => {
    const searchTermOptions = widgetData.entityData.map((item: any) => ({
      label: item.searchTerm,
      value: item.searchTerm,
    }));
    setDropDownValues(searchTermOptions);
  }, [widgetData]);

  const onSearchTermChange = (searchTerm: ILableValues) => {
    const searchTermValue: any = searchTerm.value;
    const entityDataSelected = widgetData.entityData.find(
      (item: any) => item.searchTerm === searchTermValue,
    );
    if (entityDataSelected) {
      setEntityData(entityDataSelected);
    }
  };
  useEffect(() => {
    //setting data set item
    const { unique, total } = entityData.dataSetItem;
    setDataSetValues({ uniqueCount: unique, totalCount: total });
    //set first donut dataset
    const { sources } = entityData;
    setDonutDataSet([...sources]);
    //set secondDonut dataset
    const { cardDataSet, passwordDataSet } = entityData;
    if (type === ENTITY_TYPE.CREDIT_CARD) {
      const dataSet = [
        { label: 'Cards with CVV, Exp date, or Both', value: cardDataSet.high },
        { label: 'Unexpired cards', value: cardDataSet.medium },
        { label: 'Cards without details', value: cardDataSet.low },
      ];
      setRiskDonutDataSet(dataSet);
    } else {
      const dataSet = [
        { label: 'Plain text password', value: passwordDataSet.high },
        { label: 'Hashed password', value: passwordDataSet.medium },
        { label: 'Without password', value: passwordDataSet.low },
      ];
      setRiskDonutDataSet(dataSet);
    }
  }, [entityData]);
  return (
    <Card
      className={
        expandedState
          ? `enitity-widget-wrap widget-expanded`
          : `enitity-widget-wrap widget-collapsed`
      }
      data-testid={'entity-widget'}
    >
      <div className='inner-wrap'>
        <WidgetHeader
          headingText={entityLables.entityHeading}
          icon={entityLables.icon}
          headingSubtext={`${dropdownValues.length} ${entityLables.subHeading}`}
          entityType={type as ENTITY_TYPE}
        />
        <div
          className={`${
            type === ENTITY_TYPE.EXECUTIVE_MONITORING
              ? 'widget-body inner-body-padding'
              : `widget-body ${selectedTheme === 'light' ? 'light-shadow' : 'dark-shadow'}`
          }`}
        >
          {type !== ENTITY_TYPE.EXECUTIVE_MONITORING && (
            <div className={expandedState ? 'expand-tile-wrap' : 'expand-tile-wrap end-box-shadow'}>
              <img
                src={expandedState ? CollapseTile : ExpandTileIcon}
                alt='Expand Tile'
                role='button'
                className='expand-collapse-icon'
                onClick={toggleExpandedState}
              />
            </div>
          )}
          <div className='dropdown-wrap'>
            <EntityDropdown
              dropdownLabel={entityLables.dropdownLable}
              dropdownValues={dropdownValues}
              onValueSelectFn={onSearchTermChange}
            />
          </div>
          <div className='widget-body-data-wrap'>
            {type === ENTITY_TYPE.EXECUTIVE_MONITORING ? (
              <>
                <DonutWidget
                  showGradient={true}
                  dataSet={donutDataSet}
                  donutLabel={entityLables.donut1Lable}
                  innerLabel={donutDataSet.length}
                  type={type}
                  searchTermId={entityData.searchTermId}
                />
                <DonutWidget
                  showGradient={type === ENTITY_TYPE.EXECUTIVE_MONITORING}
                  donutLabel={entityLables.donutLable}
                  dataSetLabel={entityLables.secondDonutDataSetLabel}
                  innerLabel={getDataSum(
                    type === ENTITY_TYPE.EXECUTIVE_MONITORING ? donutDataSet : RiskDonutDataSet,
                  )}
                  type={type}
                  showLegend={type !== ENTITY_TYPE.EXECUTIVE_MONITORING}
                  dataSet={RiskDonutDataSet}
                  searchTermId={entityData.searchTermId}
                />
              </>
            ) : (
              <>
                <MatrixDataSetWidget
                  matrixHeading={entityLables.dataSetLable}
                  matrixDataSet={{
                    unique: {
                      label: entityLables.uniqueDataSetLable,
                      value: dataSetValues.uniqueCount,
                    },
                    total: {
                      label: entityLables.totalDataSetLable,
                      value: dataSetValues.totalCount,
                    },
                  }}
                  tooltip={entityLables.toolTip}
                  hideUnique={type === ENTITY_TYPE.EMAIL_ADDRESS}
                />
                <DonutWidget
                  showGradient={type === ENTITY_TYPE.EXECUTIVE_MONITORING}
                  donutLabel={entityLables.donutLable}
                  dataSetLabel={entityLables.secondDonutDataSetLabel}
                  innerLabel={getDataSum(
                    type === ENTITY_TYPE.EXECUTIVE_MONITORING ? donutDataSet : RiskDonutDataSet,
                  )}
                  type={type}
                  showLegend={type !== ENTITY_TYPE.EXECUTIVE_MONITORING}
                  dataSet={RiskDonutDataSet}
                  searchTermId={entityData.searchTermId}
                />
                {expandedState && (
                  <DonutWidget
                    showGradient={true}
                    dataSet={donutDataSet}
                    donutLabel={entityLables.donut1Lable}
                    innerLabel={donutDataSet.length}
                    type={type}
                    searchTermId={entityData.searchTermId}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EntityWidget;

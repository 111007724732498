import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import './Annotation.scss';
import Card from 'react-bootstrap/Card';
import { Modal } from 'react-bootstrap';
// import EyeGreyIcon from '../../assets/icons/EyeGrey.svg';
// import EyeCrossIcon from '../../assets/icons/EyeCross.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ON_ERROR_IMAGE } from './Image';
import { EyeCrossIcon, EyeGreyIcon } from '../../assets/SVGIcons';

interface IComponentProps {
  imagePath: string;
  title?: string;
  alt?: string;
  className?: string;
  annotations?: any[];
  brandMnemonic?: string;
  threshold?: number;
}

const draw = (
  ctx: any,
  image: any,
  annotations: any[],
  canvasWidth: number,
  canvasHeight: number,
  showAnnotation: boolean,
) => {
  canvasWidth = canvasWidth + 1; // +1, for making sure that the canvas is larger than the image.
  canvasHeight = canvasHeight + 1; // +1, for making sure that the canvas is larger than the image.
  ctx.canvas.width = canvasWidth;
  ctx.canvas.height = canvasHeight;
  annotations.sort((a, b) => {
    if (a.width * a.height > b.width * b.height) {
      return -1;
    } else if (a.width * a.height < b.width * b.height) {
      return 1;
    }
    return 0;
  });

  if (showAnnotation && annotations.length) {
    const annotation = annotations[0];
    const imageWidth = annotation.image_width || annotation.imageWidth;
    const imageHeight = annotation.image_height || annotation.imageHeight;
    ctx.globalAlpha = 0.3;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    ctx.globalAlpha = 1.0;
    annotations.forEach(({ x, y, width, height }) => {
      const xFactor = canvasWidth / imageWidth;
      const yFactor = canvasHeight / imageHeight;
      ctx.drawImage(
        image,
        x,
        y,
        width,
        height,
        x * xFactor,
        y * yFactor,
        width * xFactor,
        height * yFactor,
      );
      ctx.beginPath();
      ctx.lineWidth = '2';
      ctx.strokeStyle = '#FFA25F';
      ctx.rect(x * xFactor, y * yFactor, width * xFactor, height * yFactor);
      ctx.stroke();
    });
  }
};

const Annotation: FunctionComponent<IComponentProps> = ({
  imagePath = ON_ERROR_IMAGE,
  title = 'Screenshot',
  className = '',
  alt = 'image',
  annotations = [],
  brandMnemonic = '',
  threshold = 0.5,
}) => {
  annotations = _.filter(annotations, annotation => {
    if (brandMnemonic) {
      return annotation.brand === brandMnemonic && annotation.prob >= threshold;
    } else {
      return annotation.prob >= threshold;
    }
  });
  const [fullImageShown, setFullImageShown] = useState<boolean>(false);
  const [showLogoDetection, setShowLogoDetection] = useState<boolean>(
    annotations && annotations.length > 0,
  );
  const canvasRef = useRef<any>(null);
  const canvasModalRef = useRef<any>(null);

  const toggleFullImageShown = () => {
    setFullImageShown(!fullImageShown);
  };

  const onDraw = useCallback(() => {
    const canvas = canvasRef.current;
    if (!fullImageShown && canvas) {
      const parentDom = canvas.parentElement;
      draw(
        canvas!.getContext('2d'),
        document.getElementById(imageId),
        annotations,
        parentDom.offsetWidth,
        parentDom.offsetHeight,
        showLogoDetection,
      );
    }

    const canvasModal = canvasModalRef.current;
    if (fullImageShown && canvasModal) {
      const parentDom = canvasModal.parentElement;
      draw(
        canvasModal!.getContext('2d'),
        document.getElementById(imageModalId),
        annotations,
        parentDom.offsetWidth,
        parentDom.offsetHeight,
        showLogoDetection,
      );
    }
  }, [showLogoDetection, fullImageShown, annotations]);

  useEffect(() => {
    onDraw();
  }, [showLogoDetection, fullImageShown, onDraw]);

  useEffect(() => {
    window.addEventListener('resize', onDraw);
    return () => window.removeEventListener('resize', onDraw);
  }, [onDraw]);

  const compClassName = 'annotation-component ' + className;

  const imageId = imagePath + 'img';
  const imageModalId = imagePath + 'modal-img';

  return (
    <Card bg='light' className='card-between'>
      <Card.Header className={'w-100 d-flex justify-content-between align-items-center'}>
        <div>{title}</div>
        <div className={'header-right d-flex align-items-center'}>
          {
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip
                  id={'tooltip-toggle-logo-detection'}
                  className={'table-source-url-tooltip'}
                >
                  Toggle to see detected logos{' '}
                </Tooltip>
              }
            >
              <div
                className={'eye-icon cursor-pointer mr-2'}
                onClick={() => setShowLogoDetection(!showLogoDetection)}
              >
                {showLogoDetection ? <EyeGreyIcon color='grey' /> : <EyeCrossIcon color='grey' />}
              </div>
            </OverlayTrigger>
          }
          Logos Detected: {annotations.length}
        </div>
      </Card.Header>
      <Card.Body>
        <div className={compClassName} onClick={toggleFullImageShown}>
          <Card.Img
            className={'image-place-holder '}
            id={imageId}
            src={imagePath}
            alt={alt}
            onLoad={onDraw}
            onError={(e: any) => {
              e.target.src = ON_ERROR_IMAGE;
            }}
          />
          <div className={'full-absolute cursor-pointer'}>
            <canvas
              ref={canvasRef}
              onClick={() => {
                setFullImageShown(true);
              }}
            />
          </div>
        </div>
      </Card.Body>
      <Modal
        className={'annotation-modal'}
        show={fullImageShown}
        size='xl'
        centered={true}
        onHide={toggleFullImageShown}
      >
        <div className={'annotation-modal-content'}>
          <Card.Img
            className={'image-place-holder '}
            id={imageModalId}
            src={imagePath}
            alt={alt}
            onLoad={onDraw}
            onError={(e: any) => {
              e.target.src = ON_ERROR_IMAGE;
            }}
          />
          <div className={'full-absolute'}>
            <canvas
              ref={canvasModalRef}
              onClick={() => {
                setFullImageShown(false);
              }}
            />
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export { Annotation };

import React from 'react';

export default function CustomCellRenderer({
  value,
  isDisabled,
  icon,
}: {
  value: any;
  isDisabled: boolean;
  icon?: any;
}) {
  return (
    <div style={isDisabled ? { opacity: '0.4' } : { opacity: 'none' }}>
      {' '}
      {icon || ''}
      {value}
    </div>
  );
}

import React, { useMemo } from 'react';
import { PlatformModule } from '../../../constants';
import { IUserListItemDto } from '../TeamMembers';
import { updateTeamMember } from '../team-member-requests';
import { useAppDispatch } from '../../../helpers/hooks';
import { alertActions } from '../../../actions';
import { useBrandModuleAdmin } from '../../../basic-hooks/useUserRoles';

export default function TeamMemberModules({
  modules,
  disabledModules,
  teamMemberModule,
}: {
  modules?: PlatformModule[];
  disabledModules?: string[];
  teamMemberModule?: IUserListItemDto;
}) {
  const [checkedModules, setCheckedModules] = React.useState<Set<string>>(
    new Set(
      modules
        ?.filter(module => !disabledModules?.includes(module.disabledCode))
        .map(module => module.name) || [],
    ),
  );

  const dispatch = useAppDispatch();

  const isBrandModuleAdmin = useBrandModuleAdmin();

  // brand module admin can not modify another brand module admin
  const disableCheckbox = useMemo(() => {
    return (
      isBrandModuleAdmin &&
      teamMemberModule?.user?.userAttribute?.role?.role_name === 'brand_module_admin'
    );
  }, [isBrandModuleAdmin, teamMemberModule]);

  // checkboxes for each module for a team member
  return (
    <div className='d-flex items-center'>
      {modules?.map(module => (
        <div key={module.name} className='d-flex items-center '>
          <input
            data-testid={`module-${module.name}`}
            type='checkbox'
            style={{ marginRight: '4px' }}
            disabled={disableCheckbox}
            checked={checkedModules?.has(module.name)}
            onChange={() => {
              void (async () => {
                if (checkedModules.has(module.name)) {
                  checkedModules.delete(module.name);
                } else {
                  checkedModules.add(module.name);
                }
                const disabledCodes = modules
                  .filter(module => !checkedModules.has(module.name))
                  .map(module => module.disabledCode);
                const allDisabledCodes = modules.map(module => module.disabledCode);
                setCheckedModules(new Set(checkedModules));
                try {
                  await updateTeamMember(
                    {
                      ...teamMemberModule,
                      ...teamMemberModule?.user,
                      disabled: allDisabledCodes.map(code => ({
                        code: code,
                        // true means disabled
                        enabled: disabledCodes.includes(code),
                      })),
                    } || {},
                  );

                  dispatch(alertActions.success('Module access updated successfully'));
                } catch (error) {
                  dispatch(alertActions.error(error as any));
                }
              })();
            }}
          />
          <span style={{ marginRight: '8px' }}>{module.name}</span>
        </div>
      ))}
    </div>
  );
}

import { setApiUrl, handleResponse } from './serviceWorker';
import { ISubscription } from '../components/Pricing/types';
import fetch from './api.service';

export type apiVersion = 'v1' | 'v2';

export default class StripeService {
  private readonly apiUrl: string;
  private readonly stripe: any;

  constructor() {
    this.apiUrl = setApiUrl();
  }

  getPlans(version: apiVersion = 'v1') {
    return fetch(`/platform-api/${version}/payments/plans`, { credentials: 'include' }).then(
      handleResponse,
    );
  }

  getToken() {
    return fetch('/platform-api/v1/payments/config', { credentials: 'include' }).then(
      handleResponse,
    );
  }

  createCustomer(body: any, version: apiVersion = 'v1') {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', credentials: 'include' },
      body: JSON.stringify(body),
    };
    return fetch(`/platform-api/${version}/payments/customer`, requestOptions).then(handleResponse);
  }

  createSubscription(
    info:
      | { priceId: number; vendorPaymentMethodId: string }
      | {
          items: {
            priceId: number;
            quantity: number;
          }[];
          vendorPaymentMethodId: string;
        },
    version: apiVersion = 'v1',
  ) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subscription: info,
      }),
    };
    return fetch(
      this.apiUrl + `/platform-api/${version}/payments/subscription`,
      requestOptions,
    ).then(handleResponse);
  }

  cancelSubscription(version: apiVersion = 'v1') {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: null,
    };
    return fetch(
      this.apiUrl + `/platform-api/${version}/payments/subscription`,
      requestOptions,
    ).then(handleResponse);
  }

  downgradeSubscription(plan: { priceId: number; quantity: number }) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subscription: {
          item: plan,
        },
      }),
    };
    return fetch(
      this.apiUrl + `/platform-api/v2/payments/downgrade/subscription`,
      requestOptions,
    ).then(handleResponse);
  }
}

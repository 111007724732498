import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import './dashboard.scss';
import L from 'leaflet';
import { getName } from 'country-list';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MapMarkerCircleIcon from '../../assets/icons/MapMarkerCircle.svg';
import { renderNoDataPlaceholder } from './dashboard.detection';
import NoCountry from '../../assets/icons/NoCountry.svg';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import DashboardService from '../../services/dashboard.service';
import { BrandType } from '../../constants';

const provider = new OpenStreetMapProvider();

const countryToLocation = {
  undefined: { lat: 0, lng: 0 },
  Unknown: { lat: 0, lng: 0 },
  AR: { lat: -34.9964963, lng: -64.9672817 },
  AT: { lat: 47.2000338, lng: 13.199959 },
  AU: { lat: -24.7761086, lng: 134.755 },
  BE: { lat: 50.6402809, lng: 4.6667145 },
  BG: { lat: 42.6073975, lng: 25.4856617 },
  BR: { lat: -10.3333333, lng: -53.2 },
  CA: { lat: 61.0666922, lng: -107.9917071 },
  CH: { lat: 46.7985624, lng: 8.2319736 },
  CL: { lat: -31.7613365, lng: -71.3187697 },
  CO: { lat: 2.8894434, lng: -73.783892 },
  DE: { lat: 51.0834196, lng: 10.4234469 },
  FR: { lat: 46.603354, lng: 1.8883335 },
  GB: { lat: 54.7023545, lng: -3.2765753 },
  HK: { lat: 22.350627, lng: 114.1849161 },
  HU: { lat: 47.1817585, lng: 19.5060937 },
  IE: { lat: 52.865196, lng: -7.9794599 },
  IN: { lat: 22.3511148, lng: 78.6677428 },
  IT: { lat: 42.6384261, lng: 12.674297 },
  KR: { lat: 36.638392, lng: 127.6961188 },
  NL: { lat: 52.5001698, lng: 5.7480821 },
  PL: { lat: 52.215933, lng: 19.134422 },
  RU: { lat: 64.6863136, lng: 97.7453061 },
  US: { lat: 39.7837304, lng: -100.4458825 },
  VG: { lat: 18.4024395, lng: -64.5661642 },
  VN: { lat: 13.2904027, lng: 108.4265113 },
  ZA: { lat: -28.8166236, lng: 24.991639 },
};

interface IComponentProps {
  brandType: BrandType;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IComponentState {
  data: any;
  isLoading: boolean;
}

class DashboardCountries extends React.Component<IComponentProps, IComponentState> {
  private readonly dashboardService: DashboardService;
  private map: any = undefined;
  private layer: any = undefined;
  private readonly mapId: string = JSON.stringify(Math.random());
  private _isMounted = false;

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      data: {},
      isLoading: false,
    };
    this.dashboardService = new DashboardService();
  }

  componentDidMount() {
    this._isMounted = true;
    this.getCountriesData();
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  componentDidUpdate(prevProps: IComponentProps): void {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.getCountriesData(true);
    } else {
      this.displayMap();
    }
  }

  getCountriesData = (refreshMap?: boolean) => {
    this.setCompState({
      isLoading: true,
    });

    const { brandType, startDate, endDate } = this.props;
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    this.dashboardService.getCountryWidgetData(query, brandType).then((res: any) => {
      _.unset(res.result, 'Unknown');
      _.unset(res.result, 'undefined');
      this.setCompState(
        {
          data: res.result,
          isLoading: false,
        },
        () => {
          this.displayMap(refreshMap);
        },
      );
    });
  };

  displayMap = (refreshMap?: boolean) => {
    const { isLoading } = this.state;
    if (!isLoading) {
      if (_.isEmpty(this.map) || refreshMap) {
        this.map = L.map('dashboard-map' + this.mapId, {
          center: [30, 0],
          zoomControl: false,
          zoom: 1,
          layers: [
            L.tileLayer(
              'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
              {
                minZoom: 1,
                maxZoom: 1,
                id: 'mapbox/light-v9',
                accessToken:
                  'pk.eyJ1Ijoic2hhc2hpLWJvbHN0ZXIiLCJhIjoiY2tybWdubnAzMnZnOTJybTQ4aGQ0bzRhMyJ9.Tg2wDDRBKgQeoBeMceTDag',
              },
            ),
          ],
        });
      }
      this.addMarkers();
    }
  };

  async addMarkers() {
    const { data } = this.state;
    const setMarkerIcon = (count: number) => {
      count = 6 + 2.5 * Math.log(count);
      return L.icon({
        iconUrl: MapMarkerCircleIcon,
        iconSize: [count, count], // size of the icon
        iconAnchor: [count / 2, count / 2], // point of the icon which will correspond to marker's location
        popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
        shadowSize: [0, 0], // size of the shadow
      });
    };

    Promise.all(
      _.map(data, async (value, countryCode) => {
        if (countryToLocation.hasOwnProperty(countryCode)) {
          return { ...countryToLocation[countryCode], value, countryCode };
        } else {
          const location = await provider.search({ query: countryCode }).catch(err => {
            console.error('Fail getting location of ' + countryCode);
            return {};
          });
          if (_.isEmpty(location)) {
            return { countryCode: '' };
          }
          const lat = parseFloat(_.get(location[0], ['y'], '0'));
          const lng = parseFloat(_.get(location[0], ['x'], '0'));
          countryToLocation[countryCode] = { lat, lng };
          return {
            lat,
            lng,
            value,
            countryCode,
          };
        }
      }),
    ).then(results => {
      this.map.setView(new L.LatLng(30, 0), 1);
      this.layer = L.layerGroup().addTo(this.map);
      _.forEach(results, item => {
        if (item.countryCode !== 'undefined' && item.countryCode !== 'Unknown') {
          const countryName = getName(item.countryCode);
          if (_.isEmpty(countryName)) {
            return;
          }
          const marker = L.marker(
            { lat: item.lat, lng: item.lng },
            { icon: setMarkerIcon(item.value) },
          )
            .bindPopup(countryName + ': ' + item.value)
            .addTo(this.layer);
          marker.on('mouseover', () => {
            marker.openPopup();
          });
          marker.on('mouseout', () => {
            marker.closePopup();
          });
        }
      });
    });
  }

  render() {
    const { data, isLoading } = this.state;
    return (
      <div className='dashboard-widget dashboard-countries-widget' key={this.mapId}>
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-hosting'} className={'table-source-url-tooltip'}>
              New counterfeit sites detected in countries
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title'>Top Originating Countries</div>
        </OverlayTrigger>
        <LoadingWrapper isLoading={isLoading}>
          <div className={'dashboard-map-container ' + (_.isEmpty(data) ? 'height-0' : '')}>
            <div id={'dashboard-map' + this.mapId} className={'dashboard-map'}></div>
          </div>
          {_.isEmpty(data) &&
            renderNoDataPlaceholder(
              NoCountry,
              'No detections found',
              'We haven’t detected any counterfeit sites for this period. Please select another time frame.',
            )}
        </LoadingWrapper>
      </div>
    );
  }
}

export { DashboardCountries };

import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import '../MonitorAndTakedown/Style/AcquisitionsComponent.scss';
import DashboardService from '../../services/dashboard.service';
import { TYPO_ACQUISITION_TABLE_ID, DASHBOARD_NAME, TypoJobStatus } from './constant';
import { TExportTable } from '../Common/Table/constant';
import {
  DOMAIN_COLUMN,
  PRIORITY_COLUMN,
  PRICE_COLUMN,
  REGION_COLUMN,
} from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import { FIRST_LOAD_ACTION_VALUES_TYPES, FIRST_LOAD_ACTION_VALUES } from '../Drawer/AppWrapper';
import { URL_CONSTRUCTION_COLUMN, BRAND_COLUMN, TLD_COLUMN } from './columns';
import { PageTitle } from '../Common/PageTitle';
import { connect } from 'react-redux';
import { alertActions } from '../../actions';
import { AlertActionsTypes, IReasonDropdownValues, makeDefaultCol } from '../../constants';
import { ITableDataColumn, TableData } from '../Common/Table/table.data';
import { TableContextProvider } from '../Common/Table/table.context';
import { getCSVFile } from '../Assets_v2/util';
import { AppState } from '../../helpers';
import { Card, Button } from 'react-bootstrap';
import { NoFindings } from './NoFindingsText';
import { setFilterOptionsForColumns } from './constant';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { useHistory } from 'react-router-dom';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { ITableApiColumn } from '../Common/Table/table.api';
import {
  TyposquattingDomain,
  UserTypoSquatFetchResults,
} from '../../types/typosquat-dashboard.interface';

const dashboardService = new DashboardService();

const createColumns = (): ITableApiColumn[] => {
  return [
    { ...DOMAIN_COLUMN, accessor: 'domain', header: 'Domain', isDefaultSort: false },
    { ...PRIORITY_COLUMN, isDefaultSort: true, defaultSortDirection: 'asc', header: 'Priority' },
    { ...URL_CONSTRUCTION_COLUMN, header: 'URL Construction' },
    { ...PRICE_COLUMN, header: 'Price' },
    { ...REGION_COLUMN, header: 'Region' },
    TLD_COLUMN,
    {
      ...BRAND_COLUMN,
      accessor: 'sub_brand_id',
      render: (data: any) => {
        return data.sub_brand_display_name;
      },
    },
  ];
};

interface IDomainAcquisitionProps {
  alertCustom: (domElement: any) => AlertActionsTypes;
  brandInfo: any;
  user?: any;
  firstTimeLoad: boolean;
  changeFirstLoad: (action: FIRST_LOAD_ACTION_VALUES_TYPES, updatedVal: boolean) => any;
}
const DomainAcquisition = ({
  alertCustom,
  brandInfo,
  user,
  firstTimeLoad,
  changeFirstLoad,
}: IDomainAcquisitionProps) => {
  const history = useHistory();
  window.document.title = 'Monitor for Acquisitions | Web';
  const checkphishContext = useContext(CheckPhishContactUsContext);
  const {
    setContactUsMesage,
    setShowContactUsPage,
    selectedDomain,
    setSelectedDomain,
    setDomainList,
  } = checkphishContext;
  const exportCSVTitle = 'Bolster_Domain_Acquisitions_Candidates';
  const DEFAULT_COLUMN_IDS = [
    'primary_domain',
    'acquisition_priority',
    'matrix_algorithm',
    'estimated_price',
    'region',
    'TLD',
  ];
  if (brandInfo?.brand?.brandMapping) {
    makeDefaultCol(DEFAULT_COLUMN_IDS, 'sub_brand_id', brandInfo?.brand?.brandMapping);
  }

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [purchasedDomains, setPurchasedDomains] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showNoFindings, setShowNoFindings] = useState(false);
  const [columns, setColumns] = useState<ITableApiColumn[]>(createColumns());

  const [userTyposquatDataList, setUserTyposquatDataList] = useState<
    Record<string, any> | undefined
  >(undefined);
  const {
    setTriggerContactUsModal,
    setReason,
    setSelectedUrls,
    setSelectedUrlsSha,
    formSubmitted,
    setFormSubmitted,
  } = useContext(CheckPhishContactUsContext);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setContactUsMesage('');
      setShowContactUsPage(false);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    setColumns(createColumns());
    fetchDomainPurchase();
    setShowNoFindings(false);
  }, []);

  useEffect(() => {
    if (userTyposquatDataList && selectedDomain.value) {
      const orderedData = _.chain(userTyposquatDataList[selectedDomain.value]?.domainInfo || [])
        .orderBy(['priority', 'estimated_price', 'domain'], ['asc', 'asc', 'asc'])
        .map((domain: any) => {
          return {
            ...domain,
            disabledCheckbox: domain?.acquisition_enquiry || false,
            _index: domain?.domain_sha256,
            sub_brand_id: domain.sub_brand_display_name?.toLowerCase(),
            matrix_algorithm: domain?.fuzz_algorithm,
            domain_tld: `.${domain?.tld}`,
          };
        })
        .valueOf();
      orderedData.length ? setShowNoFindings(false) : setShowNoFindings(true);
      let newColumns: any = createColumns();
      newColumns = setFilterOptionsForColumns(
        orderedData,
        columns,
        URL_CONSTRUCTION_COLUMN.id,
        URL_CONSTRUCTION_COLUMN.accessor,
      );
      newColumns = setFilterOptionsForColumns(
        orderedData,
        newColumns,
        BRAND_COLUMN.id,
        'sub_brand_id',
      );
      setColumns(newColumns);
      setPurchasedDomains(orderedData);
    }
  }, [selectedDomain, userTyposquatDataList]);

  const fetchDomainPurchase = () => {
    setShowLoader(true);
    dashboardService
      .getDataForDomainPurchasingCheckphish()
      .then((res: Record<string, any>) => {
        const domains: { domain: string; triggerTs: number }[] = [];
        Object.keys(res).forEach(k => {
          domains.push({
            domain: k,
            triggerTs: res[k].triggerTs,
          });
        });
        const availableDomains = domains
          .sort((a, b) => b.triggerTs - a.triggerTs)
          .map(m => m.domain);
        let tempSelectedDomain: TyposquattingDomain = selectedDomain;
        const index = tempSelectedDomain?.value
          ? availableDomains.indexOf(tempSelectedDomain.value)
          : -1;
        if (index === -1) {
          tempSelectedDomain = {
            value: availableDomains[0],
            status: res[availableDomains[0]].status,
          };
        } else {
          if (tempSelectedDomain.status !== res[tempSelectedDomain.value as string].status) {
            tempSelectedDomain = {
              ...tempSelectedDomain,
              status: res[tempSelectedDomain.value as string].status,
            };
          }
        }
        setSelectedDomain(tempSelectedDomain);
        setDomainList(
          availableDomains.map(d => {
            return {
              value: d,
              status: res[d].status,
            };
          }),
        );
        setUserTyposquatDataList(res);
        setContactUsMesage(
          `With the Free and Starter Plans, you can access a list of 500 recommended domains to acquire. Upgrade to unlock 3000+ recommended domains and stay ahead of potential threats.`,
        );
        if (firstTimeLoad) {
          setShowContactUsPage(true);
          changeFirstLoad(FIRST_LOAD_ACTION_VALUES.DOMAIN_ACQUISITION, false);
        }
      })
      .catch(error => {
        console.log(error, 'ERROR');
        setDomainList([]);
        setSelectedDomain({});
      })
      .finally(() => setShowLoader(false));
  };

  const exportApi = (data: any, columns: ITableDataColumn[], type: TExportTable) => {
    getCSVFile(data, columns, exportCSVTitle);
  };

  const onItemCheck = (checked: boolean, item: any, selectedItems: any) => {
    setSelectedItems(selectedItems);
  };

  const setUrlsForContactUs = () => {
    const urls = selectedItems.map((item: any) => item.domain);
    const urlSha = selectedItems.map((item: any) => item.domain_sha256);
    urls.length && setSelectedUrls(urls);
    urlSha && setSelectedUrlsSha(urlSha);
  };

  const onClickPurchase = () => {
    setReason(IReasonDropdownValues.ACQUISITION);
    setTriggerContactUsModal(true);
  };

  useEffect(() => {
    setUrlsForContactUs();
  }, [selectedItems]);

  useEffect(() => {
    if (formSubmitted) {
      fetchDomainPurchase();
      setSelectedItems([]);
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  return (
    <LoadingWrapper isLoading={showLoader}>
      <div className={'acquisitions-page'}>
        <PageTitle
          title={`Domain Acquisition ${checkphishContext.selectedDomain.value}`}
          className='analytics-dashboard-page-title'
        />
        <div className={'table-page'}>
          <Card className='table-container'>
            <TableContextProvider
              columns={columns}
              dashboardName={DASHBOARD_NAME}
              tableId={TYPO_ACQUISITION_TABLE_ID}
              tableIndex={'brand_matrix_domain_pricing'}
            >
              <TableData
                id={TYPO_ACQUISITION_TABLE_ID}
                tableClassName='tool-bar-container-padding'
                title=''
                columns={columns}
                disableDatePicker
                exportFn={exportApi}
                exportOptions={[{ label: 'CSV', value: 'csv' }]}
                tableIndex={'brand_matrix_domain_pricing'}
                tileTopThreeProps={['primary_domain', 'estimated_price', 'acquisition_priority']}
                indexBy={'primary_domain'}
                defaultColumnIds={DEFAULT_COLUMN_IDS}
                enableCheckbox
                data={purchasedDomains}
                selectedItems={selectedItems}
                onItemCheck={onItemCheck}
                showLoader={showLoader}
                customTools={[
                  <Button
                    disabled={selectedItems.length === 0}
                    key='purchase'
                    className='ml-3'
                    onClick={onClickPurchase}
                  >
                    Purchase
                  </Button>,
                ]}
                user={user}
              />
            </TableContextProvider>
          </Card>
        </div>
        {showNoFindings && <NoFindings />}
      </div>
    </LoadingWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};
const mapDispatchToProps = {
  alertCustom: alertActions.custom,
};

const connectedDomainAcquisition = connect(mapStateToProps, mapDispatchToProps)(DomainAcquisition);
export { connectedDomainAcquisition as DomainAcquisition };

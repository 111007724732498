export const MARKET_PLACE_TABLE_NAME = 'marketplace';
export const MARKET_PLACE_FINDING_DETECTION_TABLE_ID = 'marketplaceDetection';
export const MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID = 'marketplaceFindingsTakedownInProgress';
export const MARKET_PLACE_TAKEN_DOWN_TABLE_ID = 'marketplaceFindingsTakenDown';
export const MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID = 'marketplaceFindingSafeList';
export const MARKET_PLACE_FINDING_REVIEW_TABLE_ID = 'marketplaceFindingReview';
export const MARKET_PLACE_TABLE_IDS = [
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
  MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID,
  MARKET_PLACE_FINDING_REVIEW_TABLE_ID,
];

export const MARKET_PLACE_PATHNAME = '/marketplace';
export const MARKET_PLACE_DASHBOARD_PATHNAME = `${MARKET_PLACE_PATHNAME}/dashboard`;
export const MARKET_PLACE_PATHNAME_FINDINGS_DETECTION_PATHNAME = `${MARKET_PLACE_PATHNAME}/detection`;
export const MARKET_PLACE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME = `${MARKET_PLACE_PATHNAME}/takedown-in-progress`;
export const MARKET_PLACE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME = `${MARKET_PLACE_PATHNAME}/takendown`;
export const MARKET_PLACE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME = `${MARKET_PLACE_PATHNAME}/ignored`;
export const MARKET_PLACE_PATHNAME_FINDINGS_REVIEW_PATHNAME = `${MARKET_PLACE_PATHNAME}/review`;
export const MARKET_PLACE_PATHNAME_SEARCH_TERMS = `${MARKET_PLACE_PATHNAME}/search`;

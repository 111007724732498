import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { IFilter } from '../components/Common/Table/constant';
import { VALUE_DELIMITER } from '../components/Common/Table/filter.date';
import { PLAYBOOK_PROPERTY_TYPE } from '../components/Playbook/Common/PlaybookConstants';
import { appConstants, BrandType, UrlAction } from '../constants';
import fetch, { pollRequest } from './api.service';
import {
  downloadApi,
  handleResponse,
  handleResponseAddUser,
  setPostRequestOptions,
} from './serviceWorker';
import {
  EMatFetchTypes,
  IMatFetchOptions,
  IMatSort,
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
} from '../components/MonitorAndTakedown/constants';
import { HistoricalScreenshotDto } from '../components/SocTakedown/types/historical-takedown.interface';
import { BuildTableDataCsvRequest } from '../types/build-table-data-csv-request.interface';
import { WebUrlData } from '../types/web-url-data.interface';
import { IUserListItemDto } from '../components/TeamMembers/TeamMembers';
import { ISourceFeedTypes } from '../components/Dashboard_v2/Types/dashboard.types';
import { EDarkWebFindingStatus } from '../components/DarkWeb/Components/Types/DarkWeb.types';
import { Observable } from 'rxjs';
import { DEFAULT_VALUE_NO_PASSWORD } from '../components/DarkWeb/Components/Common/TableColDefs/DarkWebColDefs';
import { passwordTypeValueCheck } from '../components/Playbook/playbook-util';

export const setScanTableSort = (sort: any) => {
  const sortBy: any = {};
  const map = {
    url: 'srcUrl',
    networkOwner: 'as_description',
    disposition: 'disposition',
    ipAddress: 'ipAddress',
    takedowns: 'takeDownCount',
    status: 'status',
    source: 'source',
    jobId: 'jobId',
    brandId: 'brandId',
    lastScanned: 'created_ts',
    createdTs: 'created_ts',
    firstSeen: 'first_seen_ts',
    takedownTs: 'takedown_ts',
    sub_brand_display_name: 'sub_brand_id',
  };
  if (sort !== undefined) {
    if (map[sort.sortBy]) {
      sortBy[map[sort.sortBy]] = sort.sortDirection;
    } else {
      sortBy[sort.sortBy] = sort.sortDirection;
    }
  }
  return sortBy;
};

export const setDateRangeFilter = (filters: IFilter[], timezone?: string, type?: string) => {
  const dateRange: any = {
    timezone: timezone || moment.tz.guess(),
  };
  _.forEach(filters, (filter: IFilter) => {
    const { filterBy, filterMethod, filterValue } = filter;
    let value: any = '';
    let unit = '';
    if (typeof filterValue !== 'number') {
      [value, unit] = filterValue.split(VALUE_DELIMITER);
    } else {
      value = filterValue;
    }
    let startKey, endKey;
    switch (filterBy.value) {
      case 'platform_published_ts':
        startKey = 'platform_published_ts_start';
        endKey = 'platform_published_ts_end';
        break;
      case 'created_ts': // for dark web playbook
        startKey = 'dw_last_scan_start';
        endKey = 'dw_last_scan_end';
        break;
      case 'firstSeen':
      case 'first_seen_ts':
        startKey = 'firstSeenStartDate';
        endKey = 'firstSeenEndDate';
        break;
      case 'takedownTs':
      case 'takedown_ts':
        startKey = 'takedownStartDate';
        endKey = 'takedownEndDate';
        break;
      case 'userTakedownTs':
        startKey = 'userTakedownStartDate';
        endKey = 'userTakedownEndDate';
        break;
      case 'current_disposition_ts':
        startKey = 'dispositionChangeStartDate';
        endKey = 'dispositionChangeEndDate';
        break;
      case 'domain_registration_date':
        startKey = 'domainRegistrationStartDate';
        endKey = 'domainRegistrationEndDate';
        break;
      case 'last_updated_ts':
        startKey = 'lastUpdatedStartDate';
        endKey = 'lastUpdatedEndDate';
        break;
      case 'lastScanned':
      case 'createdTs':
      default:
        startKey = 'createStartDate';
        endKey = 'createEndDate';
        break;
    }
    switch (filterMethod.value) {
      case 'last':
        dateRange[startKey] = `now-${value}${unit}/${unit}`;
        dateRange[endKey] = `now/${unit}`;
        break;
      case 'older':
        dateRange[startKey] =
          type && type === appConstants.CONTENT_TYPE.PLAYBOOK ? undefined : `now-${90}d/d`;
        dateRange[endKey] = `now-${value}${unit}/${unit}`;
        break;
      default:
        break;
    }
  });
  return dateRange;
};

export const setScanTableFilter = (filters: IFilter[], type = 'web') => {
  const must: any = {};
  const mustNot: any = {};

  _.forEach(filters, (filter: IFilter) => {
    const { filterBy, filterMethod } = filter;
    let { filterValue } = filter;
    let condition;
    let conditionKey;
    if (
      filterMethod &&
      filterMethod.value === 'numberRange' &&
      filterBy.value !== 'search_term' &&
      filterBy.value !== 'platform_id'
    ) {
      filterValue = filterValue.replace(/ /g, '');
    }
    // TODO  need to remove the social media filter logic from here
    if (type === appConstants.CONTENT_TYPE.SOCIAL) {
      if (filterBy.value === 'category_labels') {
        filterBy.value = 'category_id';
      }
      if (filterBy.value === 'search_term_labels') {
        filterBy.value = 'search_term_id';
      }
      if (filterBy.value === 'origin_label') {
        filterBy.value = 'origin_id';
      }
      if (filterBy.value === 'platform_label') {
        filterBy.value = 'platform_id';
      }
      if (filterBy.value === 'tag_labels') {
        filterBy.value = 'tags.id';
      }
      if (filterBy.value === 'title') {
        filterBy.value = 'metadata->>title';
      }
      if (filterBy.value === 'url') {
        filterBy.value = 'srcUrl';
      }
    }
    switch (filterBy.value) {
      case 'url':
      case 'networkOwner':
      case 'disposition':
      case 'dispositions':
      case 'currentDisposition':
      case 'status':
      case 'source':
      case 'scanSource':
      case 'brandId':
      case 'jobId':
      case 'tld':
      case 'countryCode':
      case 'brandLogoDetected':
      case 'sfb_detected':
      case 'has_mx_records':
      case 'sub_brand_display_name':
      case 'sub_brand_id':
      case 'disputeStatus':
      case 'region':
      case 'userRequestedTakedown':
      case 'userTakedownEmail':
      case 'risk_level':
      case 'sourceFeed':
        if (filterMethod.value === 'includes' || filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }

        switch (filterBy.value) {
          case 'url':
            conditionKey = 'srcUrl';
            break;
          case 'networkOwner':
            conditionKey = 'asDescription';
            break;
          case 'sub_brand_display_name':
            conditionKey = 'sub_brand_id';
            break;
          default:
            conditionKey = filterBy.value;
            break;
        }
        if (_.isEmpty(condition[conditionKey])) {
          condition[conditionKey] = '';
        } else {
          condition[conditionKey] += '|';
        }
        if (
          filterBy.value === 'url' ||
          filterBy.value === 'networkOwner' ||
          filterBy.value === 'jobId' ||
          filterBy.value === 'tld' ||
          filterBy.value === 'countryCode' ||
          filterBy.value === 'brandId'
        ) {
          filterValue = '.*' + filterValue.toLowerCase() + '.*';
        }
        condition[conditionKey] += filterValue;
        break;
      case 'ip':
      case 'ipAddress':
        const ipSubsets = filterValue.split('.');
        const ipAddressStart = [];
        const ipAddressEnd = [];
        for (let i = 0; i < 4; i++) {
          ipAddressStart.push(_.isEmpty(ipSubsets[i]) ? '0' : ipSubsets[i]);
          ipAddressEnd.push(_.isEmpty(ipSubsets[i]) ? '255' : ipSubsets[i]);
        }
        if (filterMethod.value === 'beginWith') {
          condition = must;
        } else {
          condition = mustNot;
        }
        condition.ipAddressStart = ipAddressStart.join('.');
        condition.ipAddressEnd = ipAddressEnd.join('.');
        condition.ip_start = ipAddressStart.join('.');
        condition.ip_end = ipAddressEnd.join('.');
        break;
      case 'live':
        if (filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        condition[filterBy.value] = filterValue.toLowerCase() === 'true';
        break;
      case 'host_phish_count':
        filterValue = filterValue.replace(/ /g, '');
        [must.host_phish_count_start, must.host_phish_count_end] = filterValue.split(',');
        break;
      case 'ip_phish_count':
        filterValue = filterValue.replace(/ /g, '');
        [must.ip_phish_count_start, must.ip_phish_count_end] = filterValue.split(',');
        break;
      case 'risk_score':
        filterValue = filterValue.replace(/ /g, '');
        if (type === 'web') {
          [must.risk_score_start, must.risk_score_end] = filterValue.split(',');
        } else {
          [must.risk_level] = filterValue.split(',');
        }
        break;
      case 'mx_records_count':
        filterValue = filterValue.replace(/ /g, '');
        [must.mx_records_count_start, must.mx_records_count_end] = filterValue.split(',');
        break;
      case 'estimated_price':
      case 'acquisition_priority':
        filterValue = filterValue.replace(/ /g, '');
        [must[`${filterBy.value}_start`], must[`${filterBy.value}_end`]] = filterValue.split(',');
        break;
      case 'risk_trending':
        if (filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        condition['risk_trending'] = filterValue;
        break;
      case 'tags.id':
        if (filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        conditionKey = filterBy.value;
        if (_.isEmpty(condition[conditionKey])) {
          condition[conditionKey] = '';
        } else {
          condition[conditionKey] += '|';
        }
        condition[conditionKey] = condition[conditionKey] + '(' + filterValue + ')';
        const chars = { '&': ' AND ', '|': ' OR ' };
        condition[conditionKey] = condition[conditionKey].replace(/[&|]/g, (m: any) => chars[m]);
        break;
      case 'tags.updated_by':
        if (filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        conditionKey = filterBy.value;
        if (_.isEmpty(condition[conditionKey])) {
          condition[conditionKey] = '';
        } else {
          condition[conditionKey] += '|';
        }
        condition[conditionKey] += filterValue;
        condition[conditionKey] = condition[conditionKey].split('|');
        break;
      case 'takedowns':
        filterValue = filterValue.replace(/ /g, '');
        [must.takeDownCountStart, must.takeDownCountEnd] = filterValue.split(',');
        break;
      case 'brandScanCount':
      case 'brand_scan_count':
        filterValue = filterValue.replace(/ /g, '');
        [must.brandScanCountStart, must.brandScanCountEnd] = filterValue.split(',');
        break;
      case 'bolsterScanCount':
      case 'bolster_scan_count':
        filterValue = filterValue.replace(/ /g, '');
        [must.bolsterScanCountStart, must.bolsterScanCountEnd] = filterValue.split(',');
        break;
      case 'logoDetectedCount':
        [must.logoDetectedCountStart, must.logoDetectedCountEnd] = filterValue.split(',');
        break;
      case 'has_sensitive_data':
        [must.has_sensitive_data] = filterValue.split(',');
        break;
      case 'origin_id':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'is') {
            must.origin_id = filterValue;
          } else {
            mustNot.origin_id = filterValue;
          }
        }
        break;
      case 'category_id':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'is') {
            must.category_id = filterValue;
          } else {
            mustNot.category_id = filterValue;
          }
        }

        break;
      case 'category':
        if (type === appConstants.CONTENT_TYPE.DARK_WEB) {
          if (filterMethod.value === 'is') {
            must.category = filterValue;
          } else {
            mustNot.category = filterValue;
          }
        }
        break;
      case 'external_links':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'is') {
            must.external_links = filterValue;
          } else {
            mustNot.external_links = filterValue;
          }
        }

        break;
      case 'metadata->>title':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'includes') {
            must['title'] = filterValue;
          } else {
            mustNot['title'] = filterValue;
          }
        }
        break;
      case 'is_logo_detection':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'is') {
            must.is_logo_detection = filterValue;
          } else {
            mustNot.is_logo_detection = filterValue;
          }
        }

        break;
      case 'search_term_id':
        if (
          type === appConstants.CONTENT_TYPE.SOCIAL ||
          type === appConstants.CONTENT_TYPE.DARK_WEB
        ) {
          if (filterMethod.value === 'is') {
            must.search_term_id = filterValue;
          } else {
            mustNot.search_term_id = filterValue;
          }
        }

        break;
      case 'brand_logo_detected':
        must.brand_logo_detected = filterValue;
        break;
      case 'srcUrl':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'includes') {
            must.srcUrl = filterValue;
          } else {
            mustNot.srcUrl = filterValue;
          }
        } else if (type === appConstants.CONTENT_TYPE.WEB) {
          if (filterMethod.value === 'includes') {
            must.srcUrl = '.*' + filterValue.toLowerCase() + '.*';
          } else {
            mustNot.srcUrl = '.*' + filterValue.toLowerCase() + '.*';
          }
        }

        break;
      case 'platform_id':
        if (
          type === appConstants.CONTENT_TYPE.SOCIAL ||
          type === appConstants.CONTENT_TYPE.APP_STORE
        ) {
          if (filterMethod.value === 'is') {
            must.platform_id = filterValue;
          } else {
            mustNot.platform_id = filterValue;
          }
        }

        break;
      case 'app_store_status':
        must.app_store_status = filterValue;
        break;
      case 'country_code':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'includes') {
            must.country_code = filterValue.toLowerCase();
          } else {
            mustNot.country_code = filterValue.toLowerCase();
          }
        }

        break;
      case 'created_ts':
      case 'takedown_ts':
      case 'active_since':
        if (type === appConstants.CONTENT_TYPE.SOCIAL) {
          if (filterMethod.value === 'last') {
            must[filterBy.value] = `gte.${filterValue.split('-')[0]}`;
          } else if (filterMethod.value === 'older') {
            must[filterBy.value] = `lte.${filterValue.split('-')[0]}`;
          }
        }
        break;
      case 'password_type':
        if (type === appConstants.CONTENT_TYPE.DARK_WEB) {
          if (filterMethod.value === 'is') {
            must.password_type = passwordTypeValueCheck(filterValue);
          } else {
            mustNot.password_type = passwordTypeValueCheck(filterValue);
          }
        }
        break;
      default:
        if (filterMethod.value === 'includes' || filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        conditionKey = filterBy.value;
        if (_.isEmpty(condition[conditionKey])) {
          condition[conditionKey] = '';
        } else {
          condition[conditionKey] += '|';
        }

        if (filterBy.value === 'final_category') {
          condition[conditionKey] += `${filterValue}`;
        } else {
          condition[conditionKey] += `.*${filterValue}.*`;
        }
        break;
    }
  });

  return { must, mustNot };
};

export default class DashboardService {
  getUserInfo() {
    return fetch('/platform-api/v1/platform-administration/get-user-info', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getDashboardConfig() {
    return fetch('/api/v1/dashboard/get-dashboard-status', {
      credentials: 'include',
    }).then(handleResponse);
  }

  requestForUpgrade = () => {
    return fetch('/api/v1/community/request-upgrade').then(handleResponse);
  };

  saveDashboardConfig(data: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    return fetch('/api/v1/dashboard/save-dashboard-status/', requestOptions).then(handleResponse);
  }

  deleteDashboardConfig(name: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ dashboard: { name } }),
    };
    return fetch('/api/v1/dashboard/delete-dashboard-status/', requestOptions).then(handleResponse);
  }

  deleteWidgets(data: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    return fetch('/api/v1/dashboard/delete-dashboard-status/', requestOptions).then(handleResponse);
  }

  updateTableColumns(relatedTableName: string, columns: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        dashboard: {
          relatedTableName,
          columns,
        },
      }),
    };
    return fetch('/api/v1/dashboard/update-table-columns/', requestOptions).then(handleResponse);
  }

  getScanCount() {
    return fetch('/api/v1/dashboard/user/get-scan-count', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getInsights(timeStamp: string, urlSha256: string, isPublic = false) {
    const url = isPublic
      ? '/api/v1/insights-public/url/' + timeStamp + '/' + urlSha256
      : '/api/v2/insights/url/' + timeStamp + '/' + urlSha256;
    return fetch(url, { credentials: 'include' }).then(handleResponse);
  }

  getInsightsBrandAnalytics = (urlSha256: string, type?: string) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type,
          pageNumber: 0,
          pageSize: 1,
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
          timezone: moment.tz.guess(),
        },
        must: { url_sha256: urlSha256 },
        sort: { created_ts: 'desc' },
      }),
    };
    const api = '/api/v1/brand/url/search';

    return fetch(api, requestOptions).then(handleResponse);
  };

  getDisputeVerdict(urlSha256: string) {
    return fetch(`/api/v2/dispute-verdict/${urlSha256}`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getIpInsights(ip: string) {
    return fetch('/api/v2/ip/' + ip, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getDomainIntel(domain: string) {
    return fetch('/platform-api/v1/platform-administration/get-domain-intel/' + domain, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getDomainInsight(domain: string) {
    return fetch('/platform-api/v1/platform-administration/domain-insight/' + domain, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getScanSourceInfo(urlSha256: any) {
    return fetch('/platform-api/v1/platform-administration/get-scan-source-info/' + urlSha256, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getCommunityScanSourceInfo(urlSha256: any, domainMonitoring: boolean) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify({ domainMonitoring }));
    return fetch(
      '/platform-api/v1/platform-administration/get-community-scan-source-info/' + urlSha256,
      requestOptions,
    ).then(handleResponse);
  }

  getGeoLocation(ip: any) {
    return fetch('/api/v1/location/' + ip, {
      credentials: 'include',
    }).then(handleResponse);
  }

  bulkActionDisputeVerdict(data: FormData) {
    data.append('action', UrlAction.Dispute);
    const requestOptions: RequestInit = {
      method: 'PUT',
      credentials: 'include',
      body: data,
    };
    return fetch('/api/v1/urls', requestOptions).then(handleResponse);
  }

  bulkActionTakedownRequest(data: FormData) {
    data.append('action', UrlAction.TakeDown);
    const requestOptions: RequestInit = {
      method: 'PUT',
      credentials: 'include',
      body: data,
    };
    return fetch('/api/v1/urls', requestOptions).then(handleResponse);
  }

  postTakedownRequest(
    url: any,
    timeStamp: any,
    urlSha256: any,
    brand: any,
    contact: string,
    reporterEmail: string,
    primaryDomain: string,
    tld: string,
    comments = '',
    files: File[] = [],
    googleSafeBrowsing?: boolean,
    apwgBlocklist?: boolean,
  ) {
    const takedownData = new FormData();
    takedownData.append('url', url);
    takedownData.append('timestamp', timeStamp);
    takedownData.append('urlSHA256', urlSha256);
    takedownData.append('brand', brand);
    takedownData.append('abuseContactEmailList', contact);
    takedownData.append('ccEmailList', 'support@redmarlinhelp.zendesk.com');
    takedownData.append('reporter', brand);
    takedownData.append('reporterEmail', reporterEmail);
    takedownData.append('primaryDomain', primaryDomain);
    takedownData.append('tld', tld);
    takedownData.append('comments', comments || '--');
    takedownData.append('reportingEntity', 'user');
    takedownData.append('googleSafeBrowsing', String(googleSafeBrowsing));
    takedownData.append('apwgBlocklist', String(apwgBlocklist));
    for (let i = 0; i < files.length; i++) {
      takedownData.append('files', files[i]);
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: takedownData,
    };
    return fetch('/platform-api/v1/takedown', requestOptions).then(handleResponse);
  }

  getAbuseReport(data: any) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch('/platform-api/v1/platform-administration/takedown-details', requestOptions).then(
      handleResponse,
    );
  }

  getWhoisInfo(domain: string) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify({ domain }));
    return fetch('/platform-api/v1/platform-administration/whois-on-domain', requestOptions).then(
      handleResponse,
    );
  }

  getWhoisInfoOnIP(ip: string) {
    return fetch('/platform-api/v1/platform-administration/whois-ip/' + ip, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getDomTree(timestamp: string, urlSha256: string, type: string) {
    return fetch(
      `/platform-api/v1/platform-administration/${type}/dom-source/${timestamp}/${urlSha256}`,
      {
        credentials: 'include',
      },
    ).then(handleResponse);
  }
  getAbuseContacts(searchterm: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        abuseContact: `${searchterm}`,
        from: 0,
        size: 15,
      }),
    };
    return fetch(`platform-api/v1/platform-administration/search-abuse-contacts`, requestOptions);
  }

  getBrandInfo = (brandType: BrandType) => {
    let api = '/api/v1/dashboard/user/get-brand-info/';
    if (brandType === 'non_brand') {
      api = '/api/v1/non-brand/get-customer-info';
    }
    return fetch(api, {
      credentials: 'include',
    }).then(handleResponse);
  };

  sendTakedownAndAcquisitionRequest = (body: any) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
    const api = '/platform-api/v1/typosquatting/user-request';
    return fetch(api, requestOptions).then(handleResponse);
  };

  getDataForMonitoring = (query: any, brandType: BrandType) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    let api = '/api/v1/brand/get-summary-data/';
    if (brandType === 'non_brand') {
      api = '/api/v1/non-brand/get-summary-data/';
    }
    return fetch(api, requestOptions).then(handleResponse);
  };

  getDataForDetectionAndHosting = (query: any) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
      disposition = 'phish',
      timezone = moment.tz.guess(),
    } = query;
    return fetch(
      `/api/v2/brand/url-status?startDate=${startDate}&endDate=${endDate}&timezone=${timezone}&disposition=${disposition}`,
      {
        credentials: 'include',
      },
    ).then(handleResponse);
  };

  getDomainAcquisitionCountByField = (field: string, ouId?: number | null) => {
    let apiUrl = `/platform-api/v1/brand/domain/acquisition/count/field/${field}`;
    if (ouId) {
      apiUrl += `/${ouId}`;
    }

    return fetch(apiUrl, {
      credentials: 'include',
    }).then(handleResponse);
  };

  getTyposquattingDomainAcquisitionCount = () => {
    return fetch('/platform-api/v1/typosquatting/acquisition-priority', {
      credentials: 'include',
    }).then(handleResponse);
  };

  getCountryWidgetData = (query: any, brandType: BrandType) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    let api = '/api/v1/brand/get-widget-data/country_code';
    if (brandType === 'non_brand') {
      api = '/api/v1/non-brand/get-widget-data/country_code';
    }
    return fetch(api, requestOptions).then(handleResponse);
  };

  getBrandsWidgetData = (query: any) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    return fetch('/api/v1/non-brand/get-top-brand-data', requestOptions).then(handleResponse);
  };

  getDomainWidgetData = (query: any, brandType: BrandType) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
      ouId,
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          start_date: startDate,
          end_date: endDate,
          timezone: moment.tz.guess(),
          ouId,
        },
      }),
    };
    const api = `/api/v1/${brandType === 'non_brand' ? 'non-brand' : 'brand'}/get-widget-data/tld`;

    return fetch(api, requestOptions).then(handleResponse);
  };

  getIpDetectedWidgetData = (query: any, brandType?: BrandType) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          start_date: startDate,
          end_date: endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    const api = `/api/v1/${
      brandType === 'non_brand' ? 'non-brand' : 'brand'
    }/get-ip-detected-widget-data/`;

    return fetch(api, requestOptions).then(handleResponse);
  };

  getSourcesWidgetData = (query: any) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    return fetch('/api/v1/non-brand/get-top-brand-data', requestOptions).then(handleResponse);
  };

  getScanHistory = (
    brandType: BrandType,
    query: any,
    filters: IFilter[] = [],
    sort: any = undefined,
  ) => {
    const {
      startDate = moment().subtract(90, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
      pageNumber = 0,
      pageSize = 15,
    } = query;
    const { must, mustNot } = setScanTableFilter(filters);
    const sortBy = setScanTableSort(sort);

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type: 'dashboard',
          startDate,
          endDate,
          timezone: moment.tz.guess(),
          pageNumber,
          pageSize,
        },
        must,
        mustNot,
        sortBy,
      }),
    };
    let api = '/api/v1/brand/get-table-data/';
    if (brandType === 'non_brand') {
      api = '/api/v1/non-brand/get-table-data/';
    }
    return fetch(api, requestOptions).then(handleResponse);
  };

  exportScanHistoryTable = (
    brandType: BrandType,
    query: any,
    fileType: 'pdf' | 'csv',
    filters: IFilter[] = [],
    sort: any = undefined,
    columns: any[],
    fileName = 'Bolster_Detections_',
  ) => {
    const {
      type = 'dashboard',
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const { must, mustNot } = setScanTableFilter(filters);
    const sortBy = setScanTableSort(sort);

    // not sending page number/page size for this, in the export we send all of the values.
    // also we will default to non-group view as the group view export has not been designed.
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type,
          startDate,
          endDate,
          timezone: moment.tz.guess(),
          fileName,
          pageSize: null,
          pageNumber: null,
          isGroupViewEnabled: false,
        },
        must,
        mustNot,
        sortBy,
        exportTable: { columns },
      }),
    };
    let api = '/api/v1/brand/export-table-data/';
    if (brandType === 'non_brand') {
      api = '/api/v1/non-brand/export-table-data/';
    }
    return downloadApi(api + fileType, requestOptions, fileType);
  };

  async buildTableDataCsv(
    columns: { label: string; field: string }[],
    dataRows: Record<string, any>[],
    fileName: string,
  ) {
    const timezone = moment.tz.guess();

    const body: BuildTableDataCsvRequest = {
      timezone,
      columns,
      dataRows,
      fileName,
    };

    // not sending page number/page size for this, in the export we send all of the values.
    // also we will default to non-group view as the group view export has not been designed.
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
    const url = '/api/v1/brand/build-table-data-csv/';

    return downloadApi(url, requestOptions, 'csv');
  }

  submitDisputeVerdict = (body: any) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: body,
    };
    return fetch('/api/v3/dispute-verdict', requestOptions).then(handleResponse);
  };

  getDataForMonitorAndTakedown = (
    query: any,
    filters: IFilter[] = [],
    sort?: IMatSort,
    ouId?: number,
  ) => {
    const {
      type,
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
      pageNumber = 0,
      pageSize = 15,
      isGroupViewEnabled,
    } = query;
    const { must, mustNot } = setScanTableFilter(filters);
    const sortBy = setScanTableSort(sort);

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type,
          startDate: startDate,
          endDate: endDate,
          timezone: moment.tz.guess(),
          pageNumber: pageNumber,
          pageSize: pageSize,
          isGroupViewEnabled,
        },
        must,
        mustNot: mustNot,
        sortBy: sortBy,
      }),
    };
    const api = '/api/v1/brand/url/search';

    return fetch(api, requestOptions).then(handleResponse);
  };

  async getDataForMonitorAndTakedownCount(
    query: any,
    filters: IFilter[] = [],
  ): Promise<{ count: number }> {
    const {
      type,
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const { must, mustNot } = setScanTableFilter(filters);

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type,
          startDate: startDate,
          endDate: endDate,
          timezone: moment.tz.guess(),
        },
        must,
        mustNot: mustNot,
      }),
    };
    const api = '/api/v1/brand/url/search-count';

    return await handleResponse<{ count: number }>(await fetch(api, requestOptions));
  }

  async getDataForMonitorAndTakedownCountViaAgGrid(apiParams: any): Promise<{ count: number }> {
    const { query, must, mustNot } = apiParams;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query,
        must,
        mustNot: mustNot,
      }),
    };
    const api = '/api/v1/brand/url/search-count';

    return await handleResponse<{ count: number }>(await fetch(api, requestOptions));
  }

  getDataForDomainPurchasing(query: any) {
    const {
      type,
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type,
          startDate: startDate,
          endDate: endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    const api = '/api/v1/brand/domain/acquisition/search';

    return fetch(api, requestOptions).then(handleResponse);
  }

  getDataForDomainPurchasingPlatform(query: any) {
    const {
      type,
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type,
          startDate: startDate,
          endDate: endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    const api = '/platform-api/v1/brand/domain/acquisition/search';

    return fetch(api, requestOptions).then(handleResponse);
  }

  getDataForDomainPurchasingCheckphish() {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    const api = '/platform-api/v1/typosquatting/domain-acquisition';

    return fetch(api, requestOptions).then(handleResponse);
  }

  getDomainMonitoringData(options: { searchType: string }) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options),
    };
    const api = '/platform-api/v1/typosquatting/search';

    return fetch(api, requestOptions).then(handleResponse);
  }

  getDomainMonitoringDataPolling(options: { searchType: string }): Observable<Response> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options),
    };
    const api = '/platform-api/v1/typosquatting/search';
    return pollRequest(handleResponse, api, requestOptions);
  }

  postRequestTakedown = () => {
    return new Promise(resolve => {
      resolve({
        status: 'ok',
      });
    });
  };

  addUser = (user: object) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: user }),
    };
    return fetch('/platform-api/v1/brand-admin/add-user', requestOptions).then(
      handleResponseAddUser,
    );
  };

  getUserList = (): Promise<IUserListItemDto[]> => {
    return fetch('/platform-api/v1/platform-administration/get-user-list/', {
      credentials: 'include',
    }).then(handleResponse);
  };

  deleteUser = (user: object) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: user }),
    };
    return fetch('/platform-api/v1/brand-admin/delete-user/', requestOptions).then(handleResponse);
  };

  editUser = (user: object) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: user }),
    };
    return fetch('/platform-api/v1/brand-admin/modify-user/', requestOptions).then(handleResponse);
  };

  getPassiveDNS(ip: string) {
    return fetch('/api/v1/intel/hosts-on-ip/' + ip, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getIpPhishCount(ip: string) {
    const requestOptions: RequestInit = setPostRequestOptions(
      JSON.stringify({
        mustTerms: [{ terms: { disposition: ['phish'] } }, { term: { ip } }],
      }),
    );
    return fetch(
      '/platform-api/v1/platform-administration/distinction-url-count',
      requestOptions,
    ).then(handleResponse);
  }

  getRelatedURLs(ip: string) {
    return fetch('/platform-api/v1/platform-administration/related-urls-on-ip/' + ip, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getRelatedURLsAndIP(host: string) {
    return fetch('/platform-api/v1/platform-administration/related-url-and-ip-on-host/' + host, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getHostPhishCount(host: string) {
    return fetch('/platform-api/v1/platform-administration/phish-count-on-host/' + host, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getPhishingKitsCountOnHost(host: string) {
    return fetch('/api/v1/intel/phishing-kit-count-on-host/' + host, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getAbuseContact(domain: string) {
    const requestOptions: RequestInit = setPostRequestOptions(
      JSON.stringify({
        srcUrl: domain,
      }),
    );
    return fetch('/platform-api/v1/platform-administration/abuse-contact/', requestOptions).then(
      handleResponse,
    );
  }
  searchAbuseContact(searchterm: string): Promise<{ result: string[] }> {
    const requestOptions: RequestInit = setPostRequestOptions(
      JSON.stringify({
        abuseContact: searchterm,
        from: 0,
        size: 5,
      }),
    );
    return fetch(
      '/platform-api/v1/platform-administration/search-abuse-contacts/',
      requestOptions,
    ).then(handleResponse);
  }

  getTimeline = (urlSHA256: string): Promise<{ timeline: HistoricalScreenshotDto[] }> => {
    return fetch('/api/v1/timeline/' + urlSHA256, {
      credentials: 'include',
    }).then(handleResponse);
  };

  getMonitoredDomains(
    startDate: string,
    endDate: string,
    query?: string,
    sourceFeed?: string,
    ouId?: number | null,
  ) {
    const params = new URLSearchParams();
    params.append('startDate', startDate);
    params.append('endDate', endDate);
    params.append('timezone', moment.tz.guess());
    params.append('ouId', String(ouId));
    if (query) params.append('q', query);
    if (sourceFeed) params.append('sourceFeed', sourceFeed);
    return fetch(`/platform-api/v1/brand/domain/count?${params.toString()}`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getDomainCountsByField(field: string, query: string) {
    return fetch(`/platform-api/v1/brand/domain/count/${field}?q=${query}`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getDomainCountsByCategoryAndField(
    field: string,
    startDate: string,
    endDate: string,
    query?: string,
    activeOUId?: number | null,
  ) {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };

    const params = new URLSearchParams();
    params.append('startDate', startDate);
    params.append('endDate', endDate);
    params.append('timezone', moment.tz.guess());
    params.append('limit', '10');
    params.append('ouId', String(activeOUId));
    if (query) params.append('q', query);

    return fetch(
      `/api/v1/brand/domain/count/category/field/${field}?${params.toString()}`,
      requestOptions,
    ).then(handleResponse);
  }

  getSitesByStatusAndHosting = (query: any) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
      timezone = moment.tz.guess(),
      ouId,
    } = query;
    const params = new URLSearchParams();
    params.append('startDate', startDate);
    params.append('endDate', endDate);
    params.append('timezone', timezone);
    params.append('ouId', String(ouId));
    return fetch(`/platform-api/v1/brand/url-status-hosting?${params.toString()}`, {
      credentials: 'include',
    }).then(handleResponse);
  };

  getBrandDomainSummary() {
    const startDate = moment().subtract(90, 'day').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const timezone = moment.tz.guess();
    return fetch(
      `/api/v1/brand/domain/summary?startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`,
      {
        credentials: 'include',
      },
    ).then(handleResponse);
  }

  getFeatureRequested() {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`/platform-api/v1/brand/feature-requested`, requestOptions).then(handleResponse);
  }

  createFeatureRequest(query: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    };

    return fetch(`/platform-api/v1/brand/feature-requested`, requestOptions).then(handleResponse);
  }

  bulkAddTags(query: any, type?: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
    };
    return fetch(`/platform-api/v1/tag/bulk/update`, requestOptions).then(handleResponse);
  }

  addTags(query: any, type?: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
    };
    return fetch(`/platform-api/v1/content/${type}/finding/tag/bulk`, requestOptions).then(
      handleResponse,
    );
  }

  generateReport(domain: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ domain: domain }),
    };

    return fetch(`/platform-api/v1/typosquatting/generate-report`, requestOptions).then(
      handleResponse,
    );
  }

  getTagsByUrlSha(urlSha256: string, type?: string) {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`/platform-api/v1/content/${type}/finding/${urlSha256}/tag`, requestOptions).then(
      handleResponse,
    );
  }

  getMassagedMatData(fetchOptions: IMatFetchOptions) {
    switch (fetchOptions.type) {
      case EMatFetchTypes.PRE_MALICIOUS:
        fetchOptions.query.type = PRE_MALICIOUS_TABLE_ID;
        break;
      case EMatFetchTypes.TAKEDOWN:
        fetchOptions.query.type = TAKEDOWN_MALICIOUS_TABLE_ID;
        break;
      case EMatFetchTypes.POST_MALICIOUS:
        fetchOptions.query.type = POST_MALICIOUS_TABLE_ID;
        break;
    }

    return this.getDataForMonitorAndTakedown(
      fetchOptions.query,
      fetchOptions.filters,
      fetchOptions.sort,
    ).then((res: any) => this.massageUrlInfo(res?.result, fetchOptions.type));
  }

  getRelatedFindings(
    primaryDomain: string,
    queryType: EMatFetchTypes | EDarkWebFindingStatus,
    query: any,
    filters: IFilter[] = [],
    sort: any = undefined,
  ): Promise<
    Record<string, any> & {
      findings: WebUrlData[];
      relatedCounts: {
        type: string;
        count: number;
      }[];
      tagsInfo: {
        partialTags: number[];
        completeTags: number[];
      };
    }
  > {
    const {
      type,
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const { must, mustNot } = setScanTableFilter(filters);
    const sortBy = setScanTableSort(sort);

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        primaryDomain: primaryDomain,
        query: {
          type,
          startDate: startDate,
          endDate: endDate,
          timezone: moment.tz.guess(),
        },
        must,
        mustNot: mustNot,
        sortBy: sortBy,
      }),
    };
    const api = '/api/v1/brand/url/related';

    return fetch(api, requestOptions)
      .then(handleResponse)
      .then(res => {
        return {
          ...res,
          findings: res.findings.map((item: any) => this.massageDataCommon(item, queryType)),
        };
      });
  }
  getAllSourceFeedsForBrand = (query: any): Promise<ISourceFeedTypes> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
    };

    return fetch(`/platform-api/v1/brand/category-widget-brand`, requestOptions).then(
      handleResponse,
    );
  };

  getAllSourceFeedsGlobally = (query: any): Promise<ISourceFeedTypes> => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
    };

    return fetch(`/platform-api/v1/scan-source/category-widget-global`, requestOptions).then(
      handleResponse,
    );
  };

  private massageUrlInfo(
    result: { total: number; urlInfo: any[] },
    queryType: EMatFetchTypes | EDarkWebFindingStatus,
  ) {
    let data = [];
    let total = 0;
    const urlInfo = result?.urlInfo;
    if (urlInfo) {
      total = result?.total;
      data = urlInfo.map((source: any) => {
        if (source?.related_findings_count && source?.related_findings_count >= 1) {
          return {
            ...this.massageDataCommon(source, queryType),
            primary_domain: source.domain,
          };
        } else {
          return this.massageDataCommon(source, queryType);
        }
      });
    }

    return { data, total };
  }

  massageDataCommon(urlInfoItem: any, queryType: EMatFetchTypes | EDarkWebFindingStatus) {
    if (queryType == EMatFetchTypes.PRE_MALICIOUS) {
      return this.massagePreMaliciousData(urlInfoItem);
    } else {
      const brandLogoDetected = _.has(urlInfoItem, 'brand_logo_detected')
        ? urlInfoItem.brand_logo_detected
          ? 'True'
          : 'False'
        : '--';
      return {
        ...urlInfoItem,
        imagePath: urlInfoItem.image_path,
        srcUrl: urlInfoItem.src_url,
        domain_registration_date: urlInfoItem.primary_domain_whois?.domain_registration_date,
        brandLogoDetected,
        networkOwner: urlInfoItem.as_description,
        countryCode: urlInfoItem.country_code,
        host_phish_count: urlInfoItem?.pastPhishCountOnHost?.result || 0,
        ip_phish_count: urlInfoItem?.pastPhishCountOnIP?.result || 0,
        scanSource: urlInfoItem.scan_source,
        takedown_ts: _.isUndefined(urlInfoItem.takedown_ts)
          ? urlInfoItem.takedown_ts
          : moment(urlInfoItem.takedown_ts).utc().format(),
        tags: urlInfoItem?.tags || [],
        registrar: urlInfoItem.primary_domain_whois?.registrar,
        _index: urlInfoItem.url_sha256,
        sourceFeed: urlInfoItem.sourceFeed,
        sub_brand_id: urlInfoItem.sub_brand_display_name,
      };
    }
  }

  private massagePreMaliciousData(urlInfoItem: any) {
    const brandLogoDetected = _.has(urlInfoItem, 'brand_logo_detected')
      ? urlInfoItem.brand_logo_detected
        ? 'True'
        : 'False'
      : '--';
    let riskTrending = undefined;
    if (urlInfoItem.primary_domain_risk_score?.risk_score_trending > 0) {
      riskTrending = 'up';
    } else if (urlInfoItem.primary_domain_risk_score?.risk_score_trending < 0) {
      riskTrending = 'down';
    }
    return {
      ...urlInfoItem,
      imagePath: urlInfoItem.image_path,
      srcUrl: urlInfoItem.src_url,
      risk_score: urlInfoItem.primary_domain_risk_score?.risk_score,
      risk_trending: riskTrending,
      registrar: urlInfoItem.primary_domain_whois?.registrar,
      registrant_organization: urlInfoItem.primary_domain_whois?.registrant_organization,
      domain_registration_date: urlInfoItem.primary_domain_whois?.domain_registration_date,
      nameservers: urlInfoItem.primary_domain_whois?.nameservers,
      ssl_cert_provider: urlInfoItem.cert?.issuer?.O,
      fuzz_algorithm: urlInfoItem.matrix_algorithm,
      networkOwner: urlInfoItem.as_description,
      brandLogoDetected,
      scanSource: urlInfoItem.scan_source,
      countryCode: urlInfoItem.country_code,
      host_phish_count: urlInfoItem?.pastPhishCountOnHost?.result || 0,
      ip_phish_count: urlInfoItem?.pastPhishCountOnIP?.result || 0,
      tags: urlInfoItem?.tags || [],
      _index: urlInfoItem.url_sha256,
      sub_brand_id: urlInfoItem.sub_brand_display_name,
    };
  }

  getTyposquattingDashboardAnalytics(query?: string): Observable<Response> {
    let params;
    if (query) {
      params = new URLSearchParams();
      params.append('q', query);
    }
    return pollRequest(
      handleResponse,
      `/platform-api/v1/typosquatting/dashboard-analytics${params ? `?${params.toString()}` : ''}`,
      {
        credentials: 'include',
      },
    );
  }
  getRelatedUrlsOnDomain(domain: string) {
    return fetch(`/platform-api/v1/platform-administration/related-urls-on-domain/${domain}`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getTyposquattingDashboardAnalyticsV2(): Promise<any> {
    return fetch('/platform-api/v2/typosquatting/dashboard-analytics', {
      credentials: 'include',
    }).then(handleResponse);
  }

  validateTyposquattingRequestForUser(): Promise<any> {
    return fetch('/platform-api/v1/typosquatting/validate-typosquat-request', {
      credentials: 'include',
    }).then(handleResponse);
  }
}

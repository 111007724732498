import React, { useState, useRef } from 'react';
import ModalOverlay from '../../../Common/ModalOverlay';
import { Form } from 'react-bootstrap';
import Label from '../../../Common/Label';
import { TextInput } from '../../../Common/TextInput';
import { Dropdown, IDropdownOption } from '../../../Common/Dropdown';
import { AppState } from '../../../../helpers';
import { alertActions } from '../../../../actions';
import { connect } from 'react-redux';
import { IAlertDispatchProps } from '../../../../constants';

interface ISetUpNotificationModalProps {
  show: boolean;
  category: string;
  onCancel: () => void;
  onSubmit: () => void;
}

function SetUpNotificationModal({
  show,
  category,
  onCancel,
  onSubmit,
  alertSuccess,
}: ISetUpNotificationModalProps & IAlertDispatchProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [method, setMethod] = useState<string>('email');
  const values = useRef<any>({});

  const onModalSubmit = () => {
    // TODO: Hook up with api
    console.log('values.current', values.current);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      onSubmit();
      alertSuccess('Set Up Notification Success!');
    }, 1000);
  };

  const updateValue = (value: any, field: string) => {
    values.current[field] = value;
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateValue(event.target.value, controlId);
  };

  const handleDropdownChange = (option: IDropdownOption, controlId: string) => {
    setMethod(option.value);
  };

  return (
    <ModalOverlay
      show={show}
      title={`Set Up Notification`}
      isLoading={isLoading}
      onCancel={onCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Save'}
    >
      <>
        <div className='theme-text-secondary-color mb-2'>
          Get notified when new findings are found from
        </div>
        <div className='theme-text-color'>{category}</div>
        <div className='my-4 separator' />
        <Form>
          <Form.Group>
            <Label label='Notification Method' isRequired />
            <Dropdown
              boxStyle
              btnClassName='font-weight-normal'
              controlId='platformId'
              options={[
                { label: 'Email', value: 'email' },
                { label: 'Slack', value: 'slack' },
              ]}
              defaultSelection={{ label: 'Email', value: 'email' }}
              onChange={handleDropdownChange}
            />
          </Form.Group>
          {method === 'email' ? (
            <Form.Group>
              <Label label='Email Address' isRequired />
              <TextInput controlId='emailAddress' type='email' onChange={handleTextFieldChange} />
            </Form.Group>
          ) : (
            <>
              <Form.Group>
                <Label label='Slack Channel' isRequired />
                <TextInput controlId='slackChannel' onChange={handleTextFieldChange} />
              </Form.Group>
              <Form.Group>
                <Label label='Slack Token' isRequired />
                <TextInput controlId='slackToken' onChange={handleTextFieldChange} />
              </Form.Group>
            </>
          )}
        </Form>
      </>
    </ModalOverlay>
  );
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetUpNotificationModal);

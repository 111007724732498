import { AccordionCollapse } from 'react-bootstrap';
import { TableRow } from './table.row';
import { LoadingWrapper } from '../LoadingWrapper';
import React, { useEffect, useState } from 'react';
import { IRowProps } from './constant';
import { connect } from 'react-redux';
import DashboardService from '../../../services/dashboard.service';
import GroupedBottomCounts from './GroupedBottomCounts';
import { WebUrlData } from '../../../types/web-url-data.interface';
import { AppState } from '../../../helpers/store';
import { IMatFetchOptions, MALICIOUS_TABLE_IDS } from '../../MonitorAndTakedown/constants';
import { dashboardActions } from '../../../actions/dashboard.actions';
import { ISelectedWebUrls } from '../../../constants/dashboard.constants';
import _ from 'lodash';
import { HeaderCheckEvent } from './table.body';
import { EventHandler } from '../../../helpers/event-handler';

interface IGroupedHeaderRowItemsProps {
  groupIx: number;
  eventKey: string;
  rowExpanded: boolean;
  headerCheckEvents: EventHandler<HeaderCheckEvent>;
  id: MALICIOUS_TABLE_IDS;
  currentFetchOptions: IMatFetchOptions;
  selectedWebUrls: ISelectedWebUrls;
  setSelectedWebUrls: (tableId: MALICIOUS_TABLE_IDS, selected: WebUrlData[]) => void;
  isTagsSelected?: boolean;
  loadingFindings: boolean;
  findings: WebUrlData[];
  relatedCounts: IRelatedCounts[];
  getRelatedFindings: (item: any) => void;
}

export interface IRelatedCounts {
  type: string;
  count: number;
}

function GroupedHeaderRowItems(props: IGroupedHeaderRowItemsProps & IRowProps) {
  const {
    item,
    groupIx,
    eventKey,
    rowExpanded,
    headerCheckEvents,
    currentFetchOptions,
    id,
    selectedWebUrls,
    setSelectedWebUrls,
    isTagsSelected,
    loadingFindings,
    relatedCounts,
    findings,
    getRelatedFindings,
  } = props;

  useEffect(() => {
    if (rowExpanded && !isTagsSelected) {
      getRelatedFindings(item);
    }
  }, [currentFetchOptions, rowExpanded]);

  useEffect(() => {
    const listener = ({ headerDomain, checked }: HeaderCheckEvent) => {
      // only handled in the case this is expanded
      if (headerDomain === item.domain && rowExpanded) {
        // header unchecked, need uncheck children
        if (checked) {
          const updated = _.uniqBy([...selectedWebUrls[id], ...findings], x => x.url_sha256);
          setSelectedWebUrls(id, updated);
        } else {
          const updated = selectedWebUrls[id].filter(
            selected => !findings.find(f => f.url_sha256 === selected.url_sha256),
          );
          setSelectedWebUrls(id, updated);
        }
      }
    };

    headerCheckEvents?.addListener(listener);

    return () => {
      headerCheckEvents?.removeListener(listener);
    };
  }, [headerCheckEvents, findings, item, selectedWebUrls]);

  if (!loadingFindings) {
    const els = findings?.map((itemRow: WebUrlData, index: number) => {
      return (
        <>
          <AccordionCollapse
            style={{ display: 'table-row !important' }}
            as='tr'
            eventKey={props.eventKey}
            className={'expandable show'}
            key={`${groupIx}_${index}`}
          >
            <TableRow idx={`${groupIx}_${index}`} {...props} item={itemRow} />
          </AccordionCollapse>
        </>
      );
    });
    return (
      <React.Fragment>
        {els} <GroupedBottomCounts relatedCounts={relatedCounts} domainName={item.primary_domain} />
      </React.Fragment>
    );
  } else {
    return (
      <AccordionCollapse
        style={{ display: 'table-row !important' }}
        as='tr'
        eventKey={eventKey}
        className={'expandable show'}
        key={`${groupIx}_${0}`}
      >
        <td colSpan={props.columns.length}>
          <LoadingWrapper isLoading={true} />
        </td>
      </AccordionCollapse>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  const { currentFetchOptions, selectedWebUrls } = state.dashboardReducer;
  return {
    selectedWebUrls,
    currentFetchOptions,
  };
};

const mapDispatchToProps = {
  setSelectedWebUrls: dashboardActions.setSelectedWebUrls,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupedHeaderRowItems);

import _ from 'lodash';
import React, { useState, createContext } from 'react';
import { appOptions } from '../components/Drawer/CheckPhishV2/Constants';
import { ILableValue } from '../constants/types';

const ActiveAppContext = createContext({
  selectedApp: appOptions[0],
  setSelectedApp: _.noop,
});

export default ActiveAppContext;

interface IActiveAppContext {
  children: React.ReactElement;
}

export const ActiveAppContextProvider = (props: IActiveAppContext): any => {
  const { children } = props;
  const [selectedApp, setSelectedApp] = useState<ILableValue>(appOptions[0]);

  return (
    <ActiveAppContext.Provider
      value={{
        selectedApp,
        setSelectedApp,
      }}
    >
      {children}
    </ActiveAppContext.Provider>
  );
};

import React from 'react';
import './dashboard.scss';
import EyeWhiteIcon from '../../assets/icons/EyeWhite.svg';
import EyeGreyIcon from '../../assets/icons/EyeGrey.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface IComponentProps {
  shown: boolean;
  onClick: () => void;
  className?: string;
  color?: 'white' | 'grey';
}

const HideButton = (props: IComponentProps) => {
  const { shown, color, className = '', onClick } = props;
  if (shown) {
    return (
      <div className={'hide-button-container ' + className}>
        <OverlayTrigger overlay={<Tooltip id={'hide-button' + Math.random()}>Hide</Tooltip>}>
          <img
            src={color === 'white' ? EyeWhiteIcon : EyeGreyIcon}
            alt={'hide'}
            onClick={onClick}
            className={'hide-widget-button'}
          />
        </OverlayTrigger>
      </div>
    );
  }
  return null;
};

export { HideButton };

import { Drawer, ThemeOptions, ThemeProvider, createTheme } from '@material-ui/core';
import React from 'react';
import { useAppSelector } from '../../../../helpers/hooks';
import DataLeakDetails from './DataLeakDetails';
import useOnThemeChange from './useOnThemeChange';

export default function SingleFindingDataLeakPopup() {
  const { palleteType, backgroundColor } = useOnThemeChange();

  const theme: ThemeOptions = createTheme({
    palette: {
      type: palleteType as any,
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor,
        },
      },
    },
  });

  const shouldShowDataLeakTablePopup = useAppSelector(
    state => state.darkWebReducer.shouldShowDataLeakTablePopup,
  );
  return (
    <ThemeProvider theme={theme}>
      <Drawer
        style={{ position: 'unset', zIndex: 0, inset: '0px' }}
        hideBackdrop
        open={shouldShowDataLeakTablePopup}
        anchor='right'
      >
        <div style={{ marginTop: '40px' }} data-testid='data-leak-details-drawer'>
          <DataLeakDetails />
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { setModifyCustomTag } from '../../../reducers/tags.reducer';

const useOnCustomTagActions = (agRefs: any) => {
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (agRefs.current && agRefs.current.api && modifyCustomTag.triggerFetchCall) {
      agRefs.current.api.refreshServerSide();
      //fix for ag-grid not updating the rows after the custom tags modified and re-fetching the data on delete & edit
      agRefs.current.api.redrawRows();
    }
  }, [agRefs, modifyCustomTag.triggerFetchCall]);

  useEffect(() => {
    return () => {
      dispatch(
        setModifyCustomTag({
          selectedTag: null,
          isDeleteMode: false,
          isEditMode: false,
          triggerFetchCall: false,
        }),
      );
    };
  }, [dispatch]);
};

export default useOnCustomTagActions;

import React, { useState } from 'react';
import './AccountDrawer.scss';
import { NavItems } from './TopNavigationBar';

export default function AccountDrawer({
  user,
  navItems,
  setSelected,
  onSelectedPage,
  showNotificationDot = false,
}: any) {
  return (
    <div className={'account-container'}>
      <div className={'account-drawer'}>
        {navItems.map((navItem: any, index: number) => {
          if (navItem?.subItems.length === 0) return null;
          return (
            <div className={'account-sub-item-wrapper'} key={index}>
              {navItem.label && <div className={'account-header'}>{navItem.label}</div>}
              <>
                {navItem.subItems.map((item: any, index: number) => {
                  return (
                    <NavItems
                      user={user}
                      navItem={item}
                      setSelected={setSelected}
                      onSelectedPage={onSelectedPage}
                      subItemClassName={'account-sub-item'}
                      key={index}
                      showNotificationDot={
                        item.label === 'Profile Information' && showNotificationDot
                      }
                    />
                  );
                })}
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
}

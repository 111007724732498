import { ILableValue } from '../../../constants/types';

export const appOptions: ILableValue[] = [
  { label: 'Free Plan', value: 'checkphish' },
  { label: 'Premium', value: 'platform' },
];

export const titleMapper = {
  web: 'Web',
  social: 'Social Media',
  'app-store': 'App Store',
  marketplace: 'Marketplace',
  darkweb: 'Darkweb',
  'pre-malicious': 'Monitor Pre-Malicious',
  'takedown-malicious': 'Takedown Malicious',
  'post-malicious': 'Monitor Post-Malicious',
  logo: 'Monitor Logo',
  'monitor-and-acquisitions': 'Monitor for Acquisitions',
  'live-detection': 'Live Detections',
  'takedown-and-progress': 'Takedown In Progress',
  takedown: 'Takedown',
  ignored: 'Ignored',
  'search-terms': 'Search Terms',
  'review-detections': 'Review Detections',
  'active-findings': 'Active Findings',
  'mitigated-findings': 'Mitigated Findings',
  'ignored-findings': 'Ignored FIndings',
  'threat-actors': 'Threat Actors',
};

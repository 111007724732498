import React from 'react';
import SafeList from '../../../Ugc/Components/Findings/SafeList';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
  APP_STORE_TABLE_NAME,
  APP_STORE_PATHNAME,
} from '../../constants';
import {
  APP_NAME,
  APP_STORE,
  APP_STORE_IP_COLUMN,
  APP_STORE_SOURCE_COLUMN,
  APP_STORE_IS_LOGO_DETECTION_COL,
  FIRST_SEEN_COLUMN,
  APP_STORE_COUNTRY_COL,
  APP_STORE_LAST_SCANNED,
  APP_STORE_URL_COLUMN,
  APP_STORE_CATEGORY_COL,
  MATCH_SEARCH_TERMS_COLUMN,
} from './TableColDefs/AppStoreColDefs';

const AppStoreSafeList = () => {
  const AppStoreTableCols = [
    APP_NAME,
    APP_STORE,
    APP_STORE_URL_COLUMN(APP_STORE_PATHNAME),
    APP_STORE_IP_COLUMN(APP_STORE_PATHNAME),
    APP_STORE_SOURCE_COLUMN,
    APP_STORE_IS_LOGO_DETECTION_COL,
    FIRST_SEEN_COLUMN,
    APP_STORE_COUNTRY_COL,
    APP_STORE_LAST_SCANNED,
    APP_STORE_CATEGORY_COL,
    MATCH_SEARCH_TERMS_COLUMN,
  ];
  const AppStoreDefaultDetectionIds = [
    'srcUrl',
    'appName',
    'domainLabel',
    'platform',
    'ip',
    'scanSource',
    'appLastUpdated',
    'searchTerm',
    'brandLogoDetected',
    'createdTs',
    'country_code',
    'tags',
  ];
  return (
    <SafeList
      type={ugcType.App_Store}
      tableId={APP_STORE_FINDING_SAFE_LIST_TABLE_ID}
      urlColPathName={APP_STORE_PATHNAME}
      tableName={APP_STORE_TABLE_NAME}
      AppStoreDetectionTableCols={AppStoreTableCols}
      AppStoreDefaultDetectionIds={AppStoreDefaultDetectionIds}
    />
  );
};

export default AppStoreSafeList;

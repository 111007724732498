import React from 'react';
import './RemovableItem.scss';
import { CrossIcon } from '../../assets/SVGIcons';

interface IRemovableItemProps {
  value: string;
  onRemove: any;
  renderDom?: any;
}

export default function RemovableItem({ value, onRemove, renderDom = null }: IRemovableItemProps) {
  const onClickRemove = (e: any): void => {
    e.stopPropagation();
    onRemove();
  };
  return (
    <div className='removable-item d-flex align-items-center'>
      {renderDom ? renderDom : value}
      <div className='svg-icon-wrapper black stroke flex-center pl-1' onClick={onClickRemove}>
        {CrossIcon}
      </div>
    </div>
  );
}

import React, { FunctionComponent } from 'react';
import { SectionContainer } from '../Common/SectionContainer';
import { ProgressBar } from 'react-bootstrap';
import { CarouselComponent } from './CarouselComponent';

interface IScanProgressProps {
  searchText?: string;
  customUserAgent?: string;
}

const ScanProgress: FunctionComponent<IScanProgressProps> = ({ searchText, customUserAgent }) => {
  const privateScan: boolean = false;

  return (
    <div className={'scan-in-progress-page'}>
      <SectionContainer
        maxContentWidth={'800px'}
        childClassName={'flex-center flex-column scan-in-progress-content'}
      >
        <div className={'flex-center scan-url'}>
          <div className={'pr-2'}>{'Scanning:'}</div>
          <div className={'custom-text-ocean'}>{searchText}</div>
        </div>
        <ProgressBar className={'w-100 mt-lg-5'} animated now={100} />
        <CarouselComponent className={'video-game py-3 py-md-5'} />
        <div className='mb-4'>
          <a
            target={'_blank'}
            rel='noopener noreferrer'
            className='custom-text-ocean try-check-phish-scan'
            href='https://appsource.microsoft.com/en-us/product/office/WA200003488'
          >
            Try CheckPhish Scanner for Outlook
          </a>
        </div>
        <div
          className={'custom-text-muted align-self-start pb-4'}
          style={{ wordBreak: 'break-all' }}
        >{`User Agent: ${customUserAgent}`}</div>
        <div className={'custom-text-muted align-self-start'}>{`Private Scan: ${privateScan}`}</div>
      </SectionContainer>
    </div>
  );
};

export { ScanProgress };

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Status.scss';

interface IStatusLabels {
  active?: string;
  down?: string;
  default?: string;
}

interface IComponentProps {
  status: string;
  disposition?: string;
  labels?: IStatusLabels;
  className?: string;
}

const DISPOSITION_STATUS = ['phish', 'scam'];

export const Status = ({ status, disposition, labels, className }: IComponentProps): any => {
  let statusClassName = 'status-dot';
  let content;

  // only visible when status is 'active' or 'down'
  if (status !== 'active' && status !== 'down') {
    return null;
  }

  const showDot =
    (labels && labels[status] !== '--') ||
    (disposition && DISPOSITION_STATUS.indexOf(disposition) >= 0);

  if (showDot) {
    statusClassName += ` ${status}`;
    content = labels && labels[status];
  } else {
    content = (labels && labels['default']) || '--';
  }

  return (
    <div className={'status-component'}>
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + Math.random()} className={'status-tooltip'}>
            {status}
          </Tooltip>
        }
      >
        <div className={className}>
          <div className={'table-source-url-column d-flex align-items-center'}>
            {showDot && <span className={statusClassName} />}
            {content && <span>{content}</span>}
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

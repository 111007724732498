import React from 'react';
import _ from 'lodash';
import { TypeSortDirection, IHeaderProps } from './constant';
import { CHECKBOX_COLUMN_WIDTH } from './table';
import './table.scss';
import SortUp from '../../../assets/icons/SortUp.svg';
import SortDown from '../../../assets/icons/SortDown.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Checkbox } from '../Checkbox';
import { appConstants, featureIsAvailable, isInternalUser } from '../../../constants';

interface IComponentState {}

class TableHeader extends React.Component<IHeaderProps, IComponentState> {
  static defaultProps = {
    enableCheckbox: false,
    onCheckAll: _.noop,
    isAllSelected: false,
    sortBy: '',
    sortDirection: undefined,
  };

  onSortChange = (sortBy: string) => {
    const oldSortDirection = this.props.sortDirection;
    let newSortDirection: TypeSortDirection = undefined;
    if (sortBy === this.props.sortBy) {
      switch (oldSortDirection) {
        case 'asc':
          newSortDirection = 'desc';
          break;
        case 'des':
        case 'desc':
          newSortDirection = 'asc'; // We dont want unsorted order.
          break;
        case undefined:
          newSortDirection = 'asc';
          break;
        default:
          break;
      }
    } else {
      newSortDirection = 'asc';
    }
    this.props.onSortChange(sortBy, newSortDirection);
  };

  renderColumns = () => {
    const { columns, sortBy, sortDirection, editItemFn, deleteItemFn } = this.props;
    const headerColumns = columns.map((column: any) => {
      const id = column.index || column.accessor || column.header;
      const { header, headerTooltip } = column;
      let sortIcon = null;
      if (sortBy === id) {
        if (sortDirection === 'asc') {
          sortIcon = <img src={SortUp} alt={'sortUp'} />;
        } else if (sortDirection === 'des' || sortDirection === 'desc') {
          sortIcon = <img src={SortDown} alt={'sortDown'} />;
        }
      }
      let classNames = column.columnClassName || '';
      if (column.hiddenOnMobile) {
        classNames += ' web-only';
      }

      let content = (
        <div>
          <span className='long-text-ellipsis-2'>{header}</span>
          {sortIcon}
        </div>
      );

      if (!_.isEmpty(headerTooltip)) {
        content = (
          <OverlayTrigger
            placement={'top-start'}
            overlay={<Tooltip id={'tooltip-' + header}>{headerTooltip}</Tooltip>}
          >
            {content}
          </OverlayTrigger>
        );
      }
      if (column.sortDisabled) {
        if (column.sortDisabled) {
          classNames += ' no-pointer';
        }
        return (
          <th className={classNames} key={id}>
            {content}
          </th>
        );
      }
      return (
        <th
          className={classNames}
          key={id}
          onClick={() => {
            if (column.header) {
              this.onSortChange(id);
            }
          }}
        >
          {content}
        </th>
      );
    });

    if (editItemFn || deleteItemFn) {
      headerColumns.push(<th key={'edit-delete-column-header'} />);
    }

    return headerColumns;
  };

  render() {
    const { enableCheckbox, isAllSelected, onAllCheck, type, user } = this.props;
    return (
      <thead>
        <tr>
          {enableCheckbox && (
            <th>
              <Checkbox
                disabled={type === appConstants.CONTENT_TYPE.WEB}
                onChange={(checked: boolean) => {
                  onAllCheck(checked);
                }}
                defaultChecked={isAllSelected}
                isStateless
              />
            </th>
          )}
          {this.renderColumns()}
        </tr>
      </thead>
    );
  }
}

export { TableHeader };

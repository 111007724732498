import ModalOverlay from '../../Common/ModalOverlay';
import React, { useEffect, useState } from 'react';
import { UploadFiles } from '../../Common/UploadFiles';
import { IAlertDispatchProps, IDashboardDispatchProps } from '../../../constants';
import { Form } from 'react-bootstrap';
import { alertActions, dashboardActions } from '../../../actions';
import { connect } from 'react-redux';
import { UserCommentService } from '../../../services/user-comment.service';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import { EventHistoryEntity } from '../../../types/event-history-entity.interface';
import { Nullable } from '../../../types/common';

const customerCommentService = new UserCommentService();

const defaultValidationErrors = () => ({
  message: [] as string[],
});

export interface IUserCommentModalProps {
  show: boolean;
  urlSha256: string;
  requestEventHistoryId?: Nullable<number>;
  onSubmit: (eventHistoryItem: EventHistoryEntity) => void;
  onCancel: () => void;
}

function UserCommentModal({
  show,
  urlSha256,
  requestEventHistoryId,
  alertSuccess,
  alertError,
  timelineUpdated,
  onSubmit,
  onCancel,
}: IUserCommentModalProps &
  Pick<IAlertDispatchProps, 'alertSuccess' | 'alertError'> &
  Pick<IDashboardDispatchProps, 'timelineUpdated'>) {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [message, setMessage] = useState<string>('');
  const [showErrors, setShowErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState(defaultValidationErrors());

  function onUploadError() {
    alertError('Could not upload file.');
  }

  function validate(): boolean {
    const errors = defaultValidationErrors();
    if (!message.trim()) {
      errors.message = ['Comments are required.'];
    }
    setValidationErrors(errors);
    return errors.message.length == 0;
  }

  async function submit() {
    try {
      setIsLoading(true);
      setShowErrors(true);
      if (validate()) {
        const res = await customerCommentService.createUrlComment({
          urlSha256,
          message,
          files,
          requestEventHistoryId,
        });
        alertSuccess('Comment submitted successfully.');
        onSubmit(res);
        timelineUpdated();
      }
    } catch (e) {
      alertError('Error occurred while submitting comment.');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setFiles([]);
    setMessage('');
    setValidationErrors(defaultValidationErrors());
  }, [show]);

  return (
    <ModalOverlay
      show={show}
      title={'Upload File/Add Comment'}
      onCancel={onCancel}
      onSubmit={submit}
      showButtons={true}
      submitButtonLabel={'Confirm'}
      submitButtonDisabled={isLoading}
      size={'lg'}
    >
      <LoadingWrapper isLoading={isLoading}>
        <UploadFiles
          onChangeHandler={setFiles}
          dragAndDrop
          controlId='uploadFiles'
          onError={onUploadError}
        />
        <Form>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as='textarea'
              rows={5}
              value={message}
              onChange={c => setMessage(c.target.value)}
              isInvalid={showErrors && validationErrors.message.length > 0}
            />
            {showErrors &&
              validationErrors.message.map(error => {
                return <div className='ml-1 text-danger'>{error}</div>;
              })}
          </Form.Group>
        </Form>
      </LoadingWrapper>
    </ModalOverlay>
  );
}

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  timelineUpdated: dashboardActions.timelineUpdated,
};

export default connect(null, mapDispatchToProps)(UserCommentModal);

import React from 'react';
import moment from 'moment';
import { IItem } from './Reports.types';

export const REPORT_NAME_COLUMN = {
  header: 'Report Name',
  accessor: 'report_name',
  id: 'reportName',
  type: 'string',
  agFilterType: 'agTextColumnFilter',
};

export const MODULE_COLUMN = {
  header: 'Module',
  accessor: 'module',
  id: 'module',
  filterDisabled: true,
  sortDisabled: true,
  render: (data: IItem) => {
    if (!data || !data.ugcType) {
      return null;
    }
    return <div>{data.ugcType.label}</div>;
  },
};

export const GENERATED_BY_COLUMN = {
  header: 'Generated By',
  accessor: 'createdByCustomer',
  id: 'createdByCustomer',
  filterDisabled: true,
  sortDisabled: true,
  render: (data: IItem) => {
    if (!data || !data.createdByCustomer) {
      return 'Automated';
    }
    return <div>{data.createdByCustomer.firstName + ' ' + data.createdByCustomer.lastName}</div>;
  },
};

export const GENERATED_ON_COLUMN = {
  header: 'Generated On',
  id: 'created_ts',
  accessor: 'created_ts',
  isDefaultSort: true,
  type: 'date',
  filterDisabled: true,
  render: (data: IItem) => {
    if (!data || !data.created_ts) {
      return null;
    }
    return <div> {moment(data.created_ts).format('MMM D, YYYY')}</div>;
  },
};

export const USER_TOOLS_COLUMN = (toggleEmailModal: any, getDownloadedReport: any) => ({
  header: 'Actions',
  id: 'userTools',
  accessor: 'userTools',
  sortDisabled: true,
  render: (data: IItem) => {
    if (data.is_success) {
      return (
        <div className={'email-download-container'}>
          <span className={'email'} onClick={() => toggleEmailModal([data])}>
            Email
          </span>
          <span className='pdf-download' onClick={() => getDownloadedReport([data])}>
            Download PDF
          </span>
        </div>
      );
    } else {
      return (
        <div className={'email-download-container'}>
          <div className='error-link'>Error </div>
        </div>
      );
    }
  },
  filterDisabled: true,
});

import React, { useEffect, useState } from 'react';
import { PageTitle } from '../Common/PageTitle';
import { Accordion, Card, Button } from 'react-bootstrap';
import './soc-takedown.scss';
import { ChevronDownIcon, ChevronRightIcon } from '../../assets/SVGIcons';
import SocTakedownAddressBook from './soc-takedown-address-book.component';
import SocTakedownTemplates from './soc-takedown-templates.component';
import SocTakedownBrandAssets from './soc-takedown-brand-assets.component';
import { TakedownUrl } from './types/takedown-url.interface';
import SocTakedownForm from './soc-takedown-form.component';
import SocTakedownUrls from './soc-takedown-urls.component';
import { TakedownEmailDetails } from './types/takedown-email-details.interface';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { useHistory, useLocation } from 'react-router-dom';
import {
  MANAGE_EMAIL_TEMPLATES_PATH,
  WEB_DASHBOARD_PATHNAME,
} from '../MonitorAndTakedown/constants';
import { SocTakedownReviewModal } from './soc-takedown-review.modal';
import _ from 'lodash';
import { TakedownRequestForm } from './types/takedown-request-form.interface';
import {
  TakedownRequestFormErrors,
  SocTakedownValidationErrors,
} from './types/soc-takedown-errors.interface';
import { AppState } from '../../helpers';
import { connect } from 'react-redux';
import { alertActions } from '../../actions';

import SocTakedownCommentsModal from './soc-takedown-comments.component';
import {
  FILE_ATTACHMENT_MAX,
  getSingleOrPluralForm,
  IAlertDispatchProps,
  IBrandInfo,
} from '../../constants';
import { invoke } from '../../helpers/async';
import { SocEmailTemplate } from './types/soc-email-template.interface';
import { formatError } from '../../helpers/errors';
import ModalOverlay from '../Common/ModalOverlay';
import { Nullable } from '../../types/common';
import SocTakedownTabs from './SocTakedownTabs';
import { UploadFileItem } from '../Common/UploadFilesMulti';
import moment from 'moment';
import { SocTakedownErrorsModal } from './SocTakedownErrorsModal';

const socTakedownService = new SocTakedownService();

type UrlPageTypes = 'pre-malicious' | 'takedown' | 'post-malicious';
interface ISocTakedownRequestProps {
  brandInfo?: IBrandInfo;
}

export interface DomainInfo {
  urls: TakedownUrl[];
  form: TakedownRequestForm;
  details: TakedownEmailDetails;
  files: File[];
  errors: SocTakedownValidationErrors;
  isValid: boolean;
}

export interface DomainMap {
  [key: string]: DomainInfo;
}

export interface DomainUrlMap {
  [key: string]: TakedownUrl[];
}

type AttachmentScreenshotItem = UploadFileItem<{ type: 'screenshot'; url: TakedownUrl }>;

type AttachmentFileItem = UploadFileItem<{ type: 'file'; file: File }>;

type AttachmentItem = AttachmentScreenshotItem | AttachmentFileItem;

function emptyForm(): TakedownRequestForm {
  return {
    fromEmail: '',
    toEmails: '',
    ccEmails: '',
    bccEmails: '',
    subject: '',
    bodyHtml: '',
    submittedViaForm: false,
    googleSafeBrowsing: false,
    apwgBlocklist: false,
    cloudFlare: false,
    reportedToHostingProvider: false,
    reportedToRegistrar: false,
    reportedToRegistrant: false,
    reportedToOther: false,
  };
}

function emptyFormErrors(): SocTakedownValidationErrors {
  return {
    to: [],
    subject: [],
    body: [],
    attachments: [],
    sentTo: [],
  };
}

function SocTakedownRequestPage({
  brandInfo,
  alertSuccess,
  alertError,
}: ISocTakedownRequestProps & Pick<IAlertDispatchProps, 'alertSuccess' | 'alertError'>) {
  // global properties
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [urlSha256s, setUrlSha256s] = useState<string[]>(query.getAll('urlSha256s'));
  const [urlPage, setUrlPage] = useState<UrlPageTypes>(
    (query.get('urlPage') as UrlPageTypes) ?? 'pre-malicious',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [brandOptions, setBrandOptions] = useState<{ brandId: number; displayName: string }[]>([]);
  const [selectedEventKey, setSelectedEventKey] = useState<string | null>('0');
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [insightsPath, setInsightsPath] = useState('');
  const [returnPath, setReturnPath] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [activeDomain, setActiveDomain] = useState<Nullable<string>>(null);
  const [domainUrlMap, setDomainUrlMap] = useState<DomainUrlMap>({});
  const [domainMap, setDomainMap] = useState<Nullable<DomainMap>>(null);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateForApply, setTemplateForApply] = useState<Nullable<SocEmailTemplate>>(null);

  // per domain properties
  const [emailForm, setEmailForm] = useState<TakedownRequestForm>(emptyForm());
  const [activeTakedownUrls, setActiveTakedownUrls] = useState<TakedownUrl[]>([]);
  const [emailDetails, setEmailDetails] = useState<TakedownEmailDetails | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [templateRefresh, setTemplateRefresh] = useState(0);
  const [validationErrors, setValidationErrors] = useState<SocTakedownValidationErrors>(
    emptyFormErrors(),
  );
  const [attachmentItems, setAttachmentItems] = useState<AttachmentItem[]>([]);

  function emailSelected(email: string) {
    let updatedToEmails = emailForm.toEmails.trim();
    if (!updatedToEmails.includes(email)) {
      if (updatedToEmails.length > 0 && !updatedToEmails.endsWith(',')) {
        updatedToEmails += ',';
      }
      updatedToEmails += ` ${email}`;
    }
    formUpdated({ ...emailForm, toEmails: updatedToEmails });
  }

  function mapRequestFormToDetails(form: TakedownRequestForm): TakedownEmailDetails {
    return {
      toEmails: form.toEmails.trim() ? form.toEmails.split(',').map(x => x.trim()) : [],
      ccEmails: form.ccEmails.trim() ? form.ccEmails.split(',').map(x => x.trim()) : [],
      bccEmails: form.bccEmails.trim() ? form.bccEmails.split(',').map(x => x.trim()) : [],
      fromEmail: form.fromEmail ?? '',
      subject: form.subject,
      bodyHtml: form.bodyHtml,
      googleSafeBrowsing: form.googleSafeBrowsing ?? false,
      apwgBlocklist: form.apwgBlocklist ?? false,
      cloudFlare: form.cloudFlare ?? false,
      reportedToHostingProvider: form.reportedToHostingProvider ?? false,
      reportedToRegistrar: form.reportedToRegistrar ?? false,
      reportedToRegistrant: form.reportedToRegistrant ?? false,
      reportedToOther: form.reportedToOther ?? false,
    };
  }

  function mapDetailsToRequestForm(details: TakedownEmailDetails): TakedownRequestForm {
    return {
      toEmails: details.toEmails.join(', '),
      ccEmails: details.ccEmails.join(', '),
      bccEmails: details.bccEmails.join(', '),
      fromEmail: details.fromEmail,
      subject: details.subject,
      bodyHtml: details.bodyHtml,
      submittedViaForm: false,
      googleSafeBrowsing: details.googleSafeBrowsing,
      apwgBlocklist: details.apwgBlocklist,
      cloudFlare: details.cloudFlare,
      reportedToHostingProvider: details.reportedToHostingProvider,
      reportedToRegistrar: details.reportedToRegistrar,
      reportedToRegistrant: details.reportedToRegistrant,
      reportedToOther: details.reportedToOther,
    };
  }

  function applyModal(template: SocEmailTemplate) {
    setTemplateForApply(template);
  }
  useEffect(() => {
    if (templateForApply) {
      applyTemplate();
    }
  }, [templateForApply]);
  async function applyTemplate() {
    try {
      setShowTemplateModal(false);
      if (templateForApply && domainMap && activeDomain) {
        const updated: DomainMap = {};
        setIsLoading(true);
        for (const [domain, domainInfo] of Object.entries(domainMap)) {
          const details: TakedownEmailDetails = {
            ...(emailDetails as TakedownEmailDetails),
            toEmails: _.uniq([...domainInfo.details.toEmails, ...templateForApply.data.toEmails]),
            ccEmails: _.uniq([...domainInfo.details.ccEmails, ...templateForApply.data.ccEmails]),
            bccEmails: _.uniq([
              ...domainInfo.details.bccEmails,
              ...templateForApply.data.bccEmails,
            ]),
            subject: templateForApply.data.subject,
            bodyHtml: templateForApply.data.bodyHtml,
          };

          const autoFilledDetails = await socTakedownService.autoFillTemplate({
            brandName: domainInfo?.urls[0]?.brand_id || '',
            urlSha256s: domainInfo.urls.map(du => du.sha256),
            ...details,
          });

          updated[domain] = {
            ...domainInfo,
            form: mapDetailsToRequestForm(autoFilledDetails),
            details: autoFilledDetails,
          };
        }
        setActiveDomainInfo(updated[activeDomain]);
        setDomainMap(updated);
        alertSuccess('Template applied successfully.');
      } else {
        console.error('templateForApply is undefined');
        throw new Error('Could not apply template.');
      }
      setTemplateForApply(null);
    } catch (e) {
      alertError(formatError(e));
    } finally {
      setIsLoading(false);
    }
  }

  function onEditTemplate(et: SocEmailTemplate) {
    window.open(`${MANAGE_EMAIL_TEMPLATES_PATH}/update/${et.id}`, '_blank');
  }

  async function onDeleteTemplate(et: SocEmailTemplate) {
    try {
      await socTakedownService.deleteEmailTemplate(et.id as number);
      setTemplateRefresh(templateRefresh + 1);
      alertSuccess(`${et.name} Template was deleted.`);
    } catch (e) {
      alertError(formatError(e));
    }
  }

  async function review() {
    if (domainMap) {
      setShowErrors(true);
      const [isValid, mapWithValidations] = validate(domainMap);
      setDomainMap(mapWithValidations);
      if (isValid) {
        setShowReviewModal(true);
      } else {
        setShowErrorModal(true);
      }
    }
  }

  function discardAndCancel() {
    history.push(returnPath);
  }

  function addScreenshot(urlInfo: TakedownUrl) {
    urlInfo.selected = true;
    updateAttachments([...activeTakedownUrls], files);
  }

  function removeScreenshot(urlInfo: TakedownUrl) {
    urlInfo.selected = false;
    updateAttachments([...activeTakedownUrls], files);
  }

  async function retakeScreenshot(urlInfo: TakedownUrl) {
    try {
      urlInfo.retakeLoading = true;
      setActiveTakedownUrls([...activeTakedownUrls]);
      const res = await socTakedownService.getRetakeScreenshot(urlInfo.url);
      urlInfo.retakeScreenshotPath = res.screenshotPath;
      alertSuccess('Screenshot taken successfully.');
    } catch (e) {
      alertError('Error taking screenshot.');
      console.error(e);
    } finally {
      urlInfo.retakeLoading = false;
    }
    updateAttachments([...activeTakedownUrls], files);
  }

  async function switchScreenshot(urlInfo: TakedownUrl, historicalScreenshotPath: string) {
    try {
      urlInfo.selected = true;
      urlInfo.retakeScreenshotPath = historicalScreenshotPath;
      alertSuccess('Screenshot added successfully.');
    } catch (e) {
      alertError('Error on adding the screenshot.');
      console.error(e);
    }
    updateAttachments([...activeTakedownUrls], files);
  }

  function revertScreenshot(urlInfo: TakedownUrl) {
    urlInfo.retakeScreenshotPath = null;
    updateAttachments([...activeTakedownUrls], files);
  }

  function attachmentChange(updatedItems: AttachmentItem[]): void {
    const selectedUrls = updatedItems
      .map(ui => (ui.value?.type === 'screenshot' ? ui.value?.url : null))
      .filter((x): x is TakedownUrl => x != null);
    const updatedUrls = activeTakedownUrls.map(url => {
      const match = selectedUrls.find(x => x.sha256 === url.sha256);
      return {
        ...url,
        selected: match != null ? true : false,
      };
    });

    const updatedFiles = updatedItems
      .map(x => (x.type === 'file' ? x.file : null))
      .filter((x): x is File => x != null);

    updateAttachments(updatedUrls, updatedFiles);
  }

  function updateAttachments(updatedUrls: TakedownUrl[], updatedFiles: File[]): void {
    setActiveTakedownUrls(updatedUrls);
    setFiles(updatedFiles);

    if (domainMap && activeDomain) {
      const activeDomainInfo = domainMap[activeDomain];

      const [, updatedMap] = validate({
        ...domainMap,
        [activeDomain]: {
          ...activeDomainInfo,
          urls: updatedUrls,
          files: updatedFiles,
        },
      });

      setDomainMap(updatedMap);
      setActiveDomainInfo(updatedMap[activeDomain]);
    }
  }

  function submitted() {
    setShowReviewModal(false);
    setShowCommentsModal(true);
    alertSuccess(
      `Successfully submitted ${getSingleOrPluralForm(
        Object.keys(domainMap ?? {}).length,
        'takedown',
      )}.`,
    );
  }

  function setActiveDomainInfo(activeDomainInfo: DomainInfo): void {
    setEmailForm(activeDomainInfo.form);
    setEmailDetails(activeDomainInfo.details);
    setActiveTakedownUrls(activeDomainInfo.urls);
    setFiles(activeDomainInfo.files);
    setValidationErrors(activeDomainInfo.errors);

    const urlItems: AttachmentScreenshotItem[] = activeDomainInfo.urls
      .filter(u => u.selected)
      .map(u => {
        return {
          type: 'path',
          id: u.sha256,
          name: `Screenshot of ${u.url}`,
          path: u.retakeScreenshotPath ?? u.screenshotPath,
          createdTs: moment(u.timestamp).toISOString(),
          value: {
            type: 'screenshot',
            url: u,
          },
        };
      });

    const fileItems: AttachmentFileItem[] = activeDomainInfo.files.map(f => {
      return {
        type: 'file',
        file: f,
        value: {
          type: 'file',
          file: f,
        },
      };
    });

    setAttachmentItems([...urlItems, ...fileItems]);
  }

  function tabChanged(domain: string) {
    if (domainMap) {
      setActiveDomainInfo(domainMap[domain]);
      setActiveDomain(domain);
    }
  }

  function validate(domainMap: DomainMap): [isValid: boolean, updatedMap: DomainMap] {
    let isValid = true;
    const updatedMap: DomainMap = {};
    for (const [domain, domainInfo] of Object.entries(domainMap)) {
      const errors = {
        ...validateForm(domainInfo.form),
        attachments: validateAttachments(domainInfo),
        sentTo: validateSentTo(domainInfo),
      };

      updatedMap[domain] = {
        ...domainInfo,
        errors,
        isValid: isFormValid(errors),
      };

      isValid = isValid && isFormValid(errors);
    }
    return [isValid, updatedMap];
  }

  function validateForm(updatedForm: TakedownRequestForm): TakedownRequestFormErrors {
    return socTakedownService.validateRequestForm(updatedForm);
  }

  function validateAttachments(domainInfo: DomainInfo): string[] {
    const errors: string[] = [];

    if (domainInfo.files.length + domainInfo.urls.filter(u => u.selected).length > 10) {
      errors.push(`Maximum number of attachments is ${FILE_ATTACHMENT_MAX}.`);
    }

    return errors;
  }

  function validateSentTo(domainInfo: DomainInfo): string[] {
    const errors: string[] = [];

    if (
      !(
        domainInfo.form.reportedToHostingProvider ||
        domainInfo.form.reportedToRegistrar ||
        domainInfo.form.reportedToRegistrant ||
        domainInfo.form.reportedToOther
      )
    ) {
      errors.push(`At least one checkbox needs to be checked.`);
    }

    return errors;
  }

  function isFormValid(errors: SocTakedownValidationErrors) {
    return (
      errors.to.length +
        errors.subject.length +
        errors.attachments.length +
        errors.sentTo.length ===
      0
    );
  }

  function formUpdated(updatedForm: TakedownRequestForm) {
    if (domainMap != null && activeDomain != null) {
      setEmailForm(updatedForm);
      const details = mapRequestFormToDetails(updatedForm);
      setEmailDetails(details);
      const updatedDomainInfo = {
        ...domainMap[activeDomain],
        details: details,
        form: updatedForm,
      };

      const [_, updatedMap] = validate({
        ...domainMap,
        [activeDomain]: updatedDomainInfo,
      });

      setValidationErrors(updatedMap[activeDomain].errors);

      setDomainMap(updatedMap);
    }
  }

  async function fetchBrands() {
    const res = await socTakedownService.getEmailTemplateBrands();
    setBrandOptions(res);
  }

  function errorModalClosed(selectedDomain?: string) {
    if (selectedDomain != null && domainMap != null) {
      setActiveDomainInfo(domainMap[selectedDomain]);
      setActiveDomain(selectedDomain);
    }
    setShowErrorModal(false);
  }

  useEffect(() => {
    invoke(async () => {
      try {
        setIsLoading(true);
        await fetchBrands();

        setInsightsPath(`${WEB_DASHBOARD_PATHNAME}/insights`);
        switch (urlPage) {
          case 'takedown':
            setReturnPath(`${WEB_DASHBOARD_PATHNAME}/takedown-malicious`);
            break;
          case 'post-malicious':
            setReturnPath(`${WEB_DASHBOARD_PATHNAME}/post-malicious`);
            break;
          default:
            setReturnPath(`${WEB_DASHBOARD_PATHNAME}/pre-malicious`);
            break;
        }
        const customReturnUrl = query.get('returnUrl');
        if (customReturnUrl) {
          setReturnPath(decodeURIComponent(customReturnUrl));
        }

        const res = await socTakedownService.getUrlTakedownInfo(urlSha256s);

        const groupedDomains = _.groupBy(res.urls, url => {
          return url.primary_domain;
        });

        setDomainUrlMap(groupedDomains);
        const allDomains = Object.keys(groupedDomains);
        setActiveDomain(allDomains[0]);

        const newDomainMap: DomainMap = {};

        for (const domain of allDomains) {
          const updatedForm = {
            ...emptyForm(),
            fromEmail: res.fromEmail,
            initialBodyHtml: 'Select a template or enter email body here.',
            bodyHtml: 'Select a template or enter email body here.',
          };

          newDomainMap[domain] = {
            urls: groupedDomains[domain],
            form: updatedForm,
            details: mapRequestFormToDetails(updatedForm),
            files: [],
            errors: emptyFormErrors(),
            isValid: true,
          };
        }
        setDomainMap(newDomainMap);
        setActiveDomainInfo(newDomainMap[allDomains[0]]);
      } catch (e) {
        alertError(formatError(e));
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <div className={'soc-takedown-container d-flex flex-column h-100'}>
      <div className={'d-flex pb-2 align-items-center'}>
        <div className={'flex-grow-1'}>
          <PageTitle title={'Initiate Takedown'} />
        </div>
        <div>
          <Button
            variant='outline-secondary'
            onClick={discardAndCancel}
            disabled={isLoading}
            className={'mr-2'}
          >
            Discard and Cancel
          </Button>
          <Button variant='primary' onClick={review} disabled={isLoading} className={'mr-2'}>
            Review and Send
          </Button>
        </div>
      </div>

      <LoadingWrapper isLoading={isLoading}>
        {activeDomain != null && domainMap != null && (
          <div className={'flex-grow-1 soc-takedown-row d-flex'}>
            <div className={'soc-takedown-options-column pr-0'}>
              <Accordion onSelect={x => setSelectedEventKey(x)} defaultActiveKey={'0'}>
                <Card className={'border-top-0 border-right-0 mt-0'}>
                  <Card.Header className={'d-flex align-items-baseline pl-3 pr-2'}>
                    <div className={'flex-grow-1'}>Address Book</div>
                    <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                      {selectedEventKey === '0' ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey='0' className={'px-2'}>
                    <SocTakedownAddressBook
                      emailSelected={e => emailSelected(e)}
                      selectionBasedArray={_.uniq(
                        activeTakedownUrls
                          .filter(x => x.abuseContactEmail != null)
                          .map(x => x.abuseContactEmail),
                      )}
                    />
                  </Accordion.Collapse>
                </Card>
                <Card className={'border-top-0 border-right-0 mt-0'}>
                  <Card.Header className={'d-flex align-items-baseline pl-3 pr-2'}>
                    <div className={'flex-grow-1'}>Template</div>
                    <Accordion.Toggle as={Button} variant='link' eventKey='1'>
                      {selectedEventKey === '1' ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey='1'>
                    <SocTakedownTemplates
                      formType={'initiate'}
                      triggerRefresh={templateRefresh}
                      brandOptions={brandOptions}
                      currentBrand={
                        brandInfo != null
                          ? {
                              brandId: brandInfo.brand.brandId,
                              displayName: brandInfo.brand.displayName,
                            }
                          : null
                      }
                      onApply={t => applyModal(t)}
                      onEdit={t => onEditTemplate(t)}
                      onDelete={t => void onDeleteTemplate(t)}
                    />
                  </Accordion.Collapse>
                </Card>
                <Card className={'border-top-0 border-right-0 mt-0'}>
                  <Card.Header className={'d-flex align-items-baseline pl-3 pr-2'}>
                    <div className={'flex-grow-1'}>Brand Assets</div>
                    <Accordion.Toggle as={Button} variant='link' eventKey='2'>
                      {selectedEventKey === '2' ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey='2'>
                    <SocTakedownBrandAssets currentBrand={brandInfo} />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className={'soc-takedown-form-column px-4 pt-3'}>
              <h5>Send Takedown</h5>
              {Object.keys(domainMap).length > 1 && (
                <SocTakedownTabs
                  domainMap={domainMap}
                  activeDomain={activeDomain}
                  showErrors={showErrors}
                  tabClick={selectedDomain => tabChanged(selectedDomain)}
                />
              )}
              {emailForm != null && (
                <SocTakedownForm
                  key={activeDomain}
                  form={emailForm}
                  fileItems={attachmentItems}
                  onAttachmentChange={updated => {
                    attachmentChange(updated as AttachmentItem[]);
                  }}
                  formChanged={form => formUpdated(form)}
                  validationErrors={validationErrors}
                  showErrors={showErrors}
                />
              )}
            </div>
            <div className={'soc-takedown-url-column px-2 pt-2'}>
              <SocTakedownUrls
                selectedTakedownUrls={activeTakedownUrls}
                onAddScreenshot={urlInfo => addScreenshot(urlInfo)}
                onRemoveScreenshot={urlInfo => removeScreenshot(urlInfo)}
                onRetakeScreenshot={urlInfo => void retakeScreenshot(urlInfo)}
                onRevertScreenshot={urlInfo => revertScreenshot(urlInfo)}
                insightsPath={insightsPath}
                switchScreenshot={switchScreenshot}
              />
            </div>
          </div>
        )}
      </LoadingWrapper>
      <SocTakedownErrorsModal
        show={showErrorModal}
        domainMap={domainMap ?? {}}
        onClose={selectedDomain => errorModalClosed(selectedDomain)}
      />
      <SocTakedownReviewModal
        show={showReviewModal}
        domainMap={domainMap}
        onCancel={() => setShowReviewModal(false)}
        onSubmit={submitted}
        allDomains={Object.keys(domainUrlMap)}
      />
      <SocTakedownCommentsModal
        show={showCommentsModal}
        domainMap={domainMap ?? {}}
        urlSha256s={urlSha256s}
        onCancel={() => {
          setShowCommentsModal(false);
          history.push(returnPath);
        }}
        onSubmit={() => {
          setShowCommentsModal(false);
          history.push(returnPath);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    brandInfo: state.dashboardReducer.brandInfo,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocTakedownRequestPage);

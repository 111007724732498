import React from 'react';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { IReasonDropdownValues } from '../../constants';
import DashboardService from '../../services/dashboard.service';
import { connect } from 'react-redux';
import { AppState } from '../../helpers';
import { alertActions } from '../../actions';
import { AlertActionsTypes } from '../../constants';

const dashboardService = new DashboardService();

const getSelectedDropdownValue = (key: string) => {
  switch (key) {
    case IReasonDropdownValues.UPGRADE:
      return 'Bolster plan upgrade';
    case IReasonDropdownValues.TAKEDOWN:
      return 'Domain takedown';
    case IReasonDropdownValues.ACQUISITION:
      return 'Domain acquisition';
    case IReasonDropdownValues.SUPPORT:
      return 'Other support';
  }
};

interface IHubspotContactForm {
  portalId: string;
  formId: string;
  region: string;
  prefillData?: boolean;
  user?: any;
  darkTheme: boolean;
}

interface IAlert {
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

type Props = IHubspotContactForm & IAlert;

class HubspotContactForm extends React.Component<Props> {
  state = { loading: true };
  static contextType = CheckPhishContactUsContext;
  componentDidMount() {
    const {
      reason,
      selectedUrls,
      selectedUrlsSha,
      setFormSubmitted,
      setTriggerContactUsModal,
      setUserRequestSubmitted,
    } = this.context;
    const { prefillData, user, alertSuccess, alertError, darkTheme } = this.props;
    const selectedUrlsList = selectedUrls?.join(', ');
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: '#hubspotForm',
          ...this.props,
          onFormReady: async function (form: any) {
            if (prefillData) {
              const { first_name, last_name, email } = user;
              if (darkTheme) {
                form.querySelectorAll('span').forEach((item: any) => {
                  item.style.color = '#ffffff';
                });

                const para = form.querySelector('.hs-richtext');
                para.style.color = '#ffffff';

                const link = form.querySelector('a');
                link.style.color = '#8c8ced';
              }

              const reasonElement = form.querySelector('select[name="checkphish_contact_reason"]');
              if (reasonElement) {
                reasonElement.querySelector(
                  `option[value='${getSelectedDropdownValue(reason)}']`,
                ).selected = true;
                await reasonElement.dispatchEvent(new Event('input', { bubbles: true }));
              }
              if (
                selectedUrls.length &&
                (reason === IReasonDropdownValues.TAKEDOWN ||
                  reason === IReasonDropdownValues.ACQUISITION)
              ) {
                const urlList = form.querySelector('textarea');
                urlList.innerText = selectedUrlsList;
                await urlList.dispatchEvent(new Event('input', { bubbles: true }));
              }

              const firstNameElement = form.querySelector('input[name="firstname"]');
              firstNameElement.value = first_name;
              await firstNameElement.dispatchEvent(new Event('input', { bubbles: true }));

              const lastNameElement = form.querySelector('input[name="lastname"]');
              lastNameElement.value = last_name;
              await lastNameElement.dispatchEvent(new Event('input', { bubbles: true }));

              const bussinessEmailElement = form.querySelector('input[name="email"]');
              bussinessEmailElement.value = email;
              await bussinessEmailElement.dispatchEvent(new Event('input', { bubbles: true }));
            }
          },
          onFormSubmit: async function (form: any) {
            const reasonElementVal = form.querySelector(
              'select[name="checkphish_contact_reason"]',
            ).value;
            if (
              (reason === IReasonDropdownValues.TAKEDOWN ||
                reason === IReasonDropdownValues.ACQUISITION) &&
              (reasonElementVal === 'Domain takedown' || reasonElementVal === 'Domain acquisition')
            ) {
              if (!selectedUrlsSha.length) {
                return;
              }
              const body = {
                requestSha: [...selectedUrlsSha],
                requestType: reason,
              };
              dashboardService
                .sendTakedownAndAcquisitionRequest(body)
                .then(res => {
                  setUserRequestSubmitted(true);
                  alertSuccess('User Request Raised Successfully !!!');
                })
                .catch(err => alertError('Error In Raising User Request'));
              setTimeout(() => setFormSubmitted(true), 3000);
            }
            setTimeout(() => setTriggerContactUsModal(false), 3000);
          },
        });

        this.setState({
          loading: false,
        });
      }
    });
  }

  componentWillUnmount(): void {
    const { setReason } = this.context;
    setReason(IReasonDropdownValues.UPGRADE);
  }

  render() {
    return (
      <div>
        <h3 className='text-center p-3'>Contact Us</h3>
        <p className='text-center'>{this.state.loading ? 'Loading...' : ''}</p>
        <div id='hubspotForm' className='hubspot-contact-us-form' />
      </div>
    );
  }
}

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const mapStateToProps = (state: AppState) => {
  return {};
};

const connectedHubspot = connect(mapStateToProps, mapDispatchToProps)(HubspotContactForm);
export { connectedHubspot as HubspotContactForm };

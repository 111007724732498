import React from 'react';
import '../pricing.scss';
import { useContext } from 'react';
import {
  ICheckPhishSubscription,
  initialState,
  CheckphishSubscriptionContext,
} from '../../../context/CheckPhishContactUsContext';
import { ToggleSwitch } from '../../Common/ToggleSwitch';
import { ToggleSwitchItems } from '../../Common/ToggleSwitch';

export interface ToggleSwitchProps {
  items: ToggleSwitchItems[];
  customClass?: string;
}

const TermToggleSwitch = ({ items, customClass = '' }: ToggleSwitchProps) => {
  const { selectedTerm, onTermChange } = useContext<ICheckPhishSubscription>(
    CheckphishSubscriptionContext,
  );
  return (
    <ToggleSwitch
      items={items}
      selected={selectedTerm.value}
      onClick={onTermChange}
      customClassName={`pricing-toggle-switch ${customClass}`}
      disabled={selectedTerm.isLocked}
    />
  );
};

export default TermToggleSwitch;

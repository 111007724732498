import _ from 'lodash';
import React, { useState, createContext, useRef, useEffect } from 'react';
import ApiService from '../services/api.service.checkphish';
import { IReasonDropdownValues } from '../constants';
import { TyposquattingDomain } from '../types/typosquat-dashboard.interface';

import { PRICING_PLANS } from '../components/Pricing/types';
import { term } from '../components/Pricing/types';
import { PlanTerm } from '../components/Pricing/types';
import { EPlanProduct } from '../components/Common/Subscription';
import { ISubsciptionPlan } from '../components/Pricing/types';
import { POPUP_TYPE } from '../components/UserFeedback/UserFeedbackPopUp';

export const initialStateContactUs = {
  identifier: '',
  showContactUsPage: false,
  triggerContactUsModal: false,
  triggerMultiTyposquatDomainContactUsModal: false,
  selectedUrls: [''],
  selectedUrlsSha: [''],
  contactUsMessage: '',
  monitoredDomain: '',
  prevMonitoredDomain: '',
  selectedDomain: {} as TyposquattingDomain,
  reason: IReasonDropdownValues.UPGRADE,
  paidUser: false,
  formSubmitted: false,
  showContactUsV2: false,
  showExploreDemoPopup: false,
  showExploreDemoPage: false,
  exploreDemoPersist: false,
  showFeedbackPopup: false,
  afterCancelationFeedback: false,
  setAfterCancelationFeedback: _.noop,
  setExploreDemoPersistance: _.noop,
  setShowExploreDemoPopup: _.noop,
  setShowExploreDemoPage: _.noop,
  setFormSubmitted: _.noop,
  setShowContactUsPage: _.noop,
  setContactUsMesage: _.noop,
  setMonitoredDomain: _.noop,
  setTriggerContactUsModal: _.noop,
  setSelectedUrls: _.noop,
  setReason: _.noop,
  setSelectedUrlsSha: _.noop,
  setShowContactUsV2: _.noop,
  userRequestSubmitted: false,
  setUserRequestSubmitted: _.noop,
  setSelectedDomain: _.noop,
  domainList: [] as TyposquattingDomain[],
  setDomainList: _.noop,
  setPaidUser: _.noop,
  setTriggerMultiTyposquatDomainContactUsModal: _.noop,
  setShowFeedbackPopup: _.noop,
};

const CheckPhishContactUsContext = createContext(initialStateContactUs);

export default CheckPhishContactUsContext;

interface ICheckPhishContactUs {
  children: React.ReactElement;
}

export const CheckPhishContactUsContextProvider = (props: ICheckPhishContactUs): any => {
  const { children } = props;
  const [showContactUsPage, setShowContactUsPage] = useState<boolean>(false);
  const [contactUsMessage, setContactUsMesage] = useState<string>('');
  const [monitoredDomain, setMonitoredDomain] = useState<string>('');
  const [triggerContactUsModal, setTriggerContactUsModal] = useState<boolean>(false);
  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);
  const [reason, setReason] = useState<IReasonDropdownValues>(IReasonDropdownValues.UPGRADE);
  const [identifier, setIdentifier] = useState<string>('');
  const [selectedUrlsSha, setSelectedUrlsSha] = useState<string[]>([]);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [showContactUsV2, setShowContactUsV2] = useState<boolean>(false);
  const [userRequestSubmitted, setUserRequestSubmitted] = useState<boolean>(false);
  const [showExploreDemoPopup, setShowExploreDemoPopup] = useState<boolean>(false);
  const [showExploreDemoPage, setShowExploreDemoPage] = useState<boolean>(false);
  const [exploreDemoPersist, setExploreDemoPersistance] = useState<boolean>(false);

  const [selectedDomain, setSelectedDomain] = useState<TyposquattingDomain>({});
  const [domainList, setDomainList] = useState<TyposquattingDomain[]>([]);
  const [paidUser, setPaidUser] = useState<boolean>(false);
  const [triggerMultiTyposquatDomainContactUsModal, setTriggerMultiTyposquatDomainContactUsModal] =
    useState<boolean>(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [afterCancelationFeedback, setAfterCancelationFeedback] = useState<boolean>(false);

  useEffect(() => {
    !triggerContactUsModal && setShowContactUsV2(false);
  }, [triggerContactUsModal]);

  const getFingerPrint = () => {
    const apiService = new ApiService();
    apiService
      .getVisitorId()
      .then(visitorId => {
        setIdentifier(visitorId as string);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    if (identifier !== '') return;
    getFingerPrint();
  });

  const prevMonitoredDomain = useRef<string>('');

  useEffect(() => {
    prevMonitoredDomain.current = monitoredDomain;
  }, [monitoredDomain]);

  useEffect(() => {
    if (triggerContactUsModal) setUserRequestSubmitted(false);
  }, [triggerContactUsModal]);

  return (
    <CheckPhishContactUsContext.Provider
      value={{
        identifier,
        showContactUsPage,
        contactUsMessage,
        monitoredDomain,
        prevMonitoredDomain: prevMonitoredDomain.current,
        selectedUrls,
        triggerContactUsModal,
        triggerMultiTyposquatDomainContactUsModal,
        reason,
        formSubmitted,
        selectedUrlsSha,
        showContactUsV2,
        userRequestSubmitted,
        showExploreDemoPage,
        showExploreDemoPopup,
        exploreDemoPersist,
        selectedDomain,
        domainList,
        paidUser,
        showFeedbackPopup,
        afterCancelationFeedback,
        setAfterCancelationFeedback,
        setExploreDemoPersistance,
        setShowExploreDemoPopup,
        setShowExploreDemoPage,
        setUserRequestSubmitted,
        setShowContactUsV2,
        setFormSubmitted,
        setSelectedUrlsSha,
        setShowContactUsPage,
        setContactUsMesage,
        setMonitoredDomain,
        setTriggerContactUsModal,
        setSelectedUrls,
        setReason,
        setSelectedDomain,
        setDomainList,
        setPaidUser,
        setTriggerMultiTyposquatDomainContactUsModal,
        setShowFeedbackPopup,
      }}
    >
      {children}
    </CheckPhishContactUsContext.Provider>
  );
};

export interface IUserSubscription {
  id: number;
  product: EPlanProduct;
  term: PlanTerm;
  quantity: number;
  limit: number;
  price: number;
}

export type SelectedTerm = { value: term; isLocked: boolean };

export interface ICheckPhishSubscription {
  currentLiveScanSubscription: IUserSubscription | null;
  currentTyposquatSubscription: IUserSubscription | null;
  selectedTerm: SelectedTerm;
  onTermChange: (value: any) => void;
  getCurrentPlanName: (productType: EPlanProduct) => PRICING_PLANS;
  selectedPlans: ISubsciptionPlan[];
  setSelectedPlans: any;
  getSelectedPlan: (product: EPlanProduct) => ISubsciptionPlan | null;
  numberOfDomains: number;
  setNumberOfDomains: any;
  numberOfScans: number;
  setNumberOfScans: any;
  subscription: any;
}

export const initialState: ICheckPhishSubscription = {
  currentLiveScanSubscription: null,
  currentTyposquatSubscription: null,
  selectedTerm: { value: PlanTerm.MONTHLY, isLocked: false },
  onTermChange: _.noop,
  getCurrentPlanName: (productType: EPlanProduct) => PRICING_PLANS.FREE,
  selectedPlans: [],
  setSelectedPlans: _.noop,
  getSelectedPlan: (product: EPlanProduct) => null,
  numberOfDomains: 1,
  setNumberOfDomains: _.noop,
  numberOfScans: 25, //initial scans we get with free plan
  setNumberOfScans: _.noop,
  subscription: null,
};

const CheckphishSubscriptionContext = createContext<ICheckPhishSubscription>(initialState);

export enum PlanType {
  CURRENTLY_SUBSCRIBED = 'subscribed',
  CURRENLTY_SELECTED = 'selected',
}

//provider
interface ICheckPhishSubscriptionProvider {
  children: React.ReactNode;
  subscription: any;
}

export const CheckphishSubscriptionProvider = ({
  children,
  subscription,
}: ICheckPhishSubscriptionProvider) => {
  const [currentTyposquatSubscription, setCurrentTyposquatSubscription] =
    useState<IUserSubscription | null>(null);
  const [currentLiveScanSubscription, setCurrentLiveScanSubscription] =
    useState<IUserSubscription | null>(null);
  const [selectedTerm, setSelectedTerm] = useState<SelectedTerm>(initialState.selectedTerm); //start with yearly
  const [selectedPlans, setSelectedPlans] = useState<ISubsciptionPlan[]>([]);
  const [numberOfDomains, setNumberOfDomains] = useState<number>(initialState.numberOfDomains);
  const [numberOfScans, setNumberOfScans] = useState<number>(initialState.numberOfScans);

  // change currentTyposquaPlan as we change the number of domains.
  useEffect(() => {
    const currentTypoPlan = getSelectedPlan(EPlanProduct.TYPOSQUATTING);
    if (currentTypoPlan) {
      currentTypoPlan.quantity = numberOfDomains;
    }
    setSelectedPlans((currentPlans: ISubsciptionPlan[]) => {
      return currentPlans.map((plan: ISubsciptionPlan) => {
        if (plan.product === EPlanProduct.TYPOSQUATTING && !_.isEmpty(currentTypoPlan))
          return currentTypoPlan;
        return plan;
      });
    });
  }, [numberOfDomains]);

  useEffect(() => {
    const selectedTypoPlan = getSelectedPlan(EPlanProduct.TYPOSQUATTING);
    const selectedLiveScanPlan = getSelectedPlan(EPlanProduct.LIVE_SCAN);
    if (
      !_.isEmpty(selectedTypoPlan) &&
      !_.isEmpty(currentTyposquatSubscription) &&
      currentTyposquatSubscription?.id === selectedTypoPlan.priceId &&
      currentTyposquatSubscription.quantity === selectedTypoPlan.quantity &&
      selectedTypoPlan.price !== 0
    ) {
      setSelectedPlans((oldPlans: ISubsciptionPlan[]) => {
        return oldPlans.map((plan: ISubsciptionPlan) => {
          if (plan.product === EPlanProduct.TYPOSQUATTING) {
            return { ...plan, price: 0 };
          }
          return plan;
        });
      });
    }
    if (
      !_.isEmpty(selectedLiveScanPlan) &&
      !_.isEmpty(currentLiveScanSubscription) &&
      currentLiveScanSubscription?.id === selectedLiveScanPlan.priceId &&
      currentLiveScanSubscription.quantity === selectedLiveScanPlan.quantity &&
      selectedLiveScanPlan.price !== 0
    ) {
      setSelectedPlans((oldPlans: ISubsciptionPlan[]) => {
        return oldPlans.map((plan: ISubsciptionPlan) => {
          if (plan.product === EPlanProduct.LIVE_SCAN) {
            return { ...plan, price: 0 };
          }
          return plan;
        });
      });
    }
  }, [currentTyposquatSubscription, currentLiveScanSubscription, selectedPlans, numberOfDomains]);

  const onTermChange = (selected: string) =>
    setSelectedTerm((oldState: SelectedTerm) => ({
      ...oldState,
      value: selected === PlanTerm.YEARLY ? PlanTerm.YEARLY : PlanTerm.MONTHLY,
    }));

  useEffect(() => {
    if (_.isEmpty(subscription)) return resetSubscription();
    //setting default value for selected plans;
    const plans = subscription?.plans.map((item: any) => ({
      priceId: item.id,
      name: item.name,
      description: item.description,
      term: item?.term,
      limit: item.limit,
      price: item.price,
      discounts: item.discount || null,
      product: item.product,
      quantity: item.quantity,
    }));
    setSelectedPlans(plans);
    setSelectedTerm({
      value: plans[0]?.term || PlanTerm.YEARLY,
      isLocked: true,
    });
    const liveScanPlan = subscription?.plans.find((item: any) => {
      return item.product === EPlanProduct.LIVE_SCAN;
    });

    const typosquatPlan = subscription?.plans.find((item: any) => {
      return item.product === EPlanProduct.TYPOSQUATTING;
    });
    if (!_.isEmpty(liveScanPlan)) {
      setNumberOfScans(liveScanPlan?.quantity);
      setCurrentLiveScanSubscription(liveScanPlan);
    } else {
      setNumberOfScans(initialState.numberOfScans);
      setCurrentLiveScanSubscription(initialState.currentLiveScanSubscription);
    }
    if (!_.isEmpty(typosquatPlan)) {
      setNumberOfDomains(typosquatPlan?.quantity);
      setCurrentTyposquatSubscription(typosquatPlan);
    } else {
      setNumberOfDomains(initialState.numberOfDomains);
      setCurrentTyposquatSubscription(initialState.currentTyposquatSubscription);
    }
  }, [subscription]);

  const resetSubscription = () => {
    setCurrentLiveScanSubscription(null);
    setCurrentTyposquatSubscription(null);
    setSelectedTerm(initialState.selectedTerm);
    setSelectedPlans(initialState.selectedPlans);
    setNumberOfDomains(initialState.numberOfDomains);
    setNumberOfScans(initialState.numberOfScans);
  };

  const getCurrentPlanName = (productType: EPlanProduct) => {
    if (productType === EPlanProduct.LIVE_SCAN) {
      return _.isEmpty(currentLiveScanSubscription)
        ? PRICING_PLANS.FREE
        : PRICING_PLANS.PROGESSIONAL_API;
    }
    return _.isEmpty(currentTyposquatSubscription) ? PRICING_PLANS.FREE : PRICING_PLANS.STARTER;
  };

  const getSelectedPlan = (product: EPlanProduct): ISubsciptionPlan | null => {
    if (_.isEmpty(selectedPlans)) return null;
    const plan = selectedPlans.filter((item: ISubsciptionPlan) => item.product === product);
    return _.isEmpty(plan) ? null : plan[0];
  };

  return (
    <CheckphishSubscriptionContext.Provider
      value={{
        currentLiveScanSubscription,
        currentTyposquatSubscription,
        getCurrentPlanName,
        selectedTerm,
        onTermChange,
        selectedPlans,
        setSelectedPlans,
        getSelectedPlan,
        numberOfDomains,
        setNumberOfDomains,
        numberOfScans,
        setNumberOfScans,
        subscription,
      }}
    >
      {children}
    </CheckphishSubscriptionContext.Provider>
  );
};

export { CheckphishSubscriptionContext };

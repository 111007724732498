import React, { useCallback, useState } from 'react';
import '../Playbook/Style/playbook.scss';
import Form from 'react-bootstrap/Form';
import BinIcon from '../../assets/icons/Bin.svg';

interface ICompHookProps {
  values: any;
  field: any;
  onUpdate: (key: string, value: any) => void;
}

const ConnectorParametersTextarea = ({ values, field, onUpdate }: ICompHookProps) => {
  const detailKey = field.key.toLowerCase();
  const value = values.details[detailKey];

  const [textareaIsShown, toggleTextarea] = useState(!!value);

  const onChange = useCallback(
    (key: string, value) => {
      onUpdate(detailKey, value);
    },
    [values],
  );

  const showTextarea = () => {
    toggleTextarea(true);
  };

  const hideTextarea = () => {
    onUpdate(detailKey, '');
    toggleTextarea(false);
  };

  return (
    <div className={'parameters-text-area'}>
      <div className={'mt-3 '}>
        <Form.Label className={'mb-0'}>{field.label}</Form.Label>
        {!textareaIsShown && (
          <div
            onClick={showTextarea}
            className='d-flex align-items-center cursor-pointer add-container'
          >
            <img src='/integrations/Add-blue.svg' className='add-icon' alt='add' />
            <span className='add'>ADD</span>
          </div>
        )}
      </div>
      {textareaIsShown && (
        <div className={'mt-3 w-100 d-flex align-items-center'}>
          <Form.Control
            as={'textarea'}
            rows={5}
            className={'mr-2'}
            defaultValue={value || ''}
            onChange={(e: any) => {
              onChange('parameter', e.currentTarget.value);
            }}
          />
          <img
            className={'ml-2 cursor-pointer'}
            src={BinIcon}
            alt={'remove'}
            onClick={hideTextarea}
          />
        </div>
      )}
    </div>
  );
};

export { ConnectorParametersTextarea };

import React from 'react';
import _ from 'lodash';
import ChevronRightNewWhiteIcon from '../../../../assets/icons/ChevronRightWhite.svg';
import { numberWithCommas, convertToThousand } from '../../../../constants';
import TextCenterWithChevronRight from './TextCenterWithChevronRight';

export default function Funnel({
  width,
  height,
  background,
  isTransform,
  label,
  value,
  totalVariants,
  handleRedirect,
  parentLabel,
  className,
}: any): JSX.Element {
  const customFunnelStyle = {
    width,
    height,
    background,
    transform: isTransform
      ? `perspective( calc( ${width} - 13vw ) ) rotateY(1deg) translate(0.325vw)`
      : 'none',
  };

  const handleRouter = () => {
    handleRedirect(parentLabel, label);
  };
  return (
    <div style={customFunnelStyle} className={'hover-wrapper ' + className} id='funnel-container'>
      <div className='funnel-content' onClick={handleRouter}>
        <div className='funnel-label'>{label}</div>
        <div className='funnel-value'>
          <TextCenterWithChevronRight iconSrc={ChevronRightNewWhiteIcon}>
            {numberWithCommas(value || 0)}
          </TextCenterWithChevronRight>
        </div>
        {totalVariants !== undefined && totalVariants > 0 && (
          <div className='funnel-info'>
            {' '}
            {`out of ${convertToThousand(totalVariants)} variants `}{' '}
          </div>
        )}
      </div>
    </div>
  );
}

import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Modal, Button, Form, ButtonGroup, Spinner } from 'react-bootstrap';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import './UserFeedbackPopup.scss';
import UserService from '../../services/user.service';
import AttachFile from '../Common/AttachFile';
import { useAppDispatch } from '../../helpers/hooks';
import { alertActions } from '../../actions';
import { CancellationReasons } from './constants';
import _ from 'lodash';

interface IUserFeedbackPopup {}

const userService = new UserService();

export enum POPUP_TYPE {
  FEEDBACK = 'feedback',
  BUG_REPORT = 'bug-report',
}

export interface userFeedbackBody {
  feedbackType: POPUP_TYPE;
  subject: string;
  message: string;
  screenshots: string[];
}

export interface IError {
  heading: string;
  description: string;
}

const UserFeedbackPopup = ({}: IUserFeedbackPopup) => {
  const {
    showFeedbackPopup,
    setShowFeedbackPopup,
    afterCancelationFeedback,
    setAfterCancelationFeedback,
  } = useContext(CheckPhishContactUsContext);
  const [feedbackType, setFeedbackType] = useState<POPUP_TYPE>(POPUP_TYPE.FEEDBACK);
  const [feedbackHeading, setFeedbackHeading] = useState<string>('');
  const [feedbackDescription, setFeedbackDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const screenshots = useRef<any[]>([]);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<IError>({ heading: '', description: '' });

  useEffect(() => {
    if (afterCancelationFeedback) {
      setFeedbackHeading(CancellationReasons[0]);
    }
  }, [afterCancelationFeedback]);

  const onHeadingChangeHandler = (event: any) => {
    const heading = event.target.value;
    if (!_.isEmpty(heading)) {
      setError((oldState: IError) => ({
        ...oldState,
        heading: '',
      }));
    }
    setFeedbackHeading(heading);
  };

  const onDescriptionChangeHandler = (event: any) => {
    const description = event.target.value;
    if (!_.isEmpty(description)) {
      setError((oldState: IError) => ({
        ...oldState,
        description: '',
      }));
    }
    setFeedbackDescription(description);
  };

  const validateForm = () => {
    let validForm = true;
    if (!afterCancelationFeedback || feedbackHeading === CancellationReasons[3]) {
      if (_.isEmpty(feedbackHeading)) {
        setError((oldState: IError) => ({
          ...oldState,
          heading: 'Please enter a heading for your feedback.',
        }));
        validForm = false;
      } else {
        setError((oldState: IError) => ({
          ...oldState,
          heading: '',
        }));
      }

      if (_.isEmpty(feedbackDescription)) {
        setError((oldState: IError) => ({
          ...oldState,
          description: 'Please enter a description for your feedback.',
        }));
        validForm = false;
      } else {
        setError((oldState: IError) => ({
          ...oldState,
          description: '',
        }));
      }
    } else {
      setError({
        heading: '',
        description: '',
      });
    }
    return validForm;
  };

  const onSubmitHandler = () => {
    const isValid = validateForm();
    if (!isValid) return;
    setIsLoading(true);
    const body: userFeedbackBody = {
      feedbackType,
      subject: afterCancelationFeedback
        ? `Subscrition Cancelled - ${feedbackHeading}`
        : feedbackHeading,
      message: feedbackDescription,
      screenshots: screenshots.current,
    };
    userService
      .sendUserFeedback(body)
      .then(res => {
        dispatch(alertActions.success('Feedback Submitted Successfully!!!'));
      })
      .catch(err => {
        console.error(err);
        dispatch(alertActions.error('Error sending feedback'));
      })
      .finally(() => {
        setIsLoading(false);
        setShowFeedbackPopup(false);
        setAfterCancelationFeedback(false);
      });
  };

  useEffect(() => {
    if (!showFeedbackPopup) {
      setFeedbackHeading('');
      setFeedbackDescription('');
      setFeedbackType(POPUP_TYPE.FEEDBACK);
      screenshots.current = [];
      setError({ heading: '', description: '' });
      setIsLoading(false);
    }
  }, [showFeedbackPopup]);

  return (
    <Modal
      show={showFeedbackPopup}
      onHide={setShowFeedbackPopup.bind(this, false)}
      centered
      className='feedback-popup'
      backdrop={'static'}
      data-testid={'feedback-popup'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {afterCancelationFeedback ? `We're Sorry to See You Go!` : `Help us improve CheckPhish!`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='feedbackForm.ControlInput1'>
            <Form.Label>
              {afterCancelationFeedback
                ? `Your feedback is important to us. Could you let us know why you're canceling?`
                : `Please select your feedback catergory below.`}
            </Form.Label>
            <ButtonGroup className='feedback-btn-wrap'>
              {afterCancelationFeedback ? (
                <>
                  <Form.Group className='mb-3' controlId='feedbackForm.ControlInput1'>
                    {CancellationReasons.map((reason: string, index: number) => (
                      <Form.Check
                        id={`cancellation-reason-${index + 1}`}
                        type='radio'
                        data-testid='reason-radio'
                        name='feedback-reason'
                        label={reason}
                        checked={feedbackHeading === reason}
                        onChange={e => {
                          if (e.target.checked) setFeedbackHeading(reason);
                        }}
                        key={`reason-${index}`}
                      />
                    ))}
                  </Form.Group>
                </>
              ) : (
                <>
                  <Button
                    className={`feedback-toggle-btn ${
                      feedbackType === POPUP_TYPE.FEEDBACK ? 'active-btn' : ''
                    }`}
                    onClick={setFeedbackType.bind(this, POPUP_TYPE.FEEDBACK)}
                    variant={feedbackType === POPUP_TYPE.FEEDBACK ? 'primary' : 'outline-secondary'}
                  >
                    Feedback
                  </Button>
                  <Button
                    className={`feedback-toggle-btn ${
                      feedbackType === POPUP_TYPE.BUG_REPORT ? 'active-btn' : ''
                    }`}
                    onClick={setFeedbackType.bind(this, POPUP_TYPE.BUG_REPORT)}
                    variant={
                      feedbackType === POPUP_TYPE.BUG_REPORT ? 'primary' : 'outline-secondary'
                    }
                  >
                    Report a Bug
                  </Button>
                </>
              )}
            </ButtonGroup>
          </Form.Group>
          {!afterCancelationFeedback && (
            <Form.Group className='mb-3' controlId='feedbackForm.ControlText1'>
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type='text'
                onChange={onHeadingChangeHandler}
                required
                data-testid='user-input'
                className={`${error.heading ? 'error-border' : ''}`}
              />
              {!_.isEmpty(error.heading) && <span className='error'>{error.heading}</span>}
            </Form.Group>
          )}
          {(!afterCancelationFeedback || feedbackHeading === CancellationReasons[3]) && (
            <Form.Group className='mb-3' controlId='feedbackForm.ControlTextarea1'>
              <Form.Label>Please Leave your feedback below.</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                onChange={onDescriptionChangeHandler}
                required
                data-testid='user-input'
                className={`${error.heading ? 'error-border' : ''}`}
              />
              {!_.isEmpty(error.description) && <span className='error'>{error.description}</span>}
            </Form.Group>
          )}
          {!afterCancelationFeedback && (
            <Form.Group
              controlId='feedbackForm.fileAttachment'
              style={{
                margin: '1rem 0',
              }}
            >
              <AttachFile
                label='Attach Screenshots'
                onChangeHandler={files => {
                  files.forEach(img => {
                    screenshots.current = [];
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(img);
                    fileReader.addEventListener('load', function () {
                      const blob = this.result;
                      screenshots.current.push(blob);
                    });
                  });
                }}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={setShowFeedbackPopup.bind(this, false)}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button variant='primary' onClick={onSubmitHandler} disabled={isLoading}>
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserFeedbackPopup;

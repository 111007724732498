import React from 'react';
import '../../Style/FindingStatus.scss';
import { DISPLAY_STATUS_MAP, EFindingStatus } from '../../Types/ugc.types';

interface IFindingStatusProps {
  status: EFindingStatus;
  className?: string;
}

const FindingStatus = ({ status, className = '' }: IFindingStatusProps) => {
  return (
    <div className={'finding-status-component d-flex align-items-center ' + className}>
      <span data-status={status} className='dot' />
      <span>{DISPLAY_STATUS_MAP[status]}</span>
    </div>
  );
};

export default FindingStatus;

import { InputType, PropertyType } from './PropertyTypes';

export const ASSETS_TABLE_NAME = 'Assets';
export const LOGOS_TABLE_NAME = 'Logos';
export const DOCUMNET_TABLE_NAME = 'Documents';
export const TRADEMARK_TABLE_NAME = 'Registered Trademarks';
export const MARKETPLACE_TABLE_NAME = 'Marketplace';
export const SOCIAL_MEDIA_TABLE_NAME = 'Social Media';
export const APP_STORE_TABLE_NAME = 'App Store';

export const MANAGED_BY_BOLSTER = 'managed_by_bolster';
export const MANAGED_BY_ORGANIZATION = 'managed_by_organization';
export const MANAGED_BY_AFFILIATED = 'managed_by_affiliated';
export const LOGOS_TABLE_ID = 'logos_table_id';
export const DOCUMENTS_TABLE_ID = 'documents_table_id';
export const TRADEMARK_TABLE_ID = 'trademarks_table_id';
export const MARKETPLACE_TABLE_ID = 'marketplace_table_id';
export const APP_STORE_TABLE_ID = 'app_store_table_id';
export const SOCIAL_MEDIA_TABLE_ID = 'social_media_table_id';
export const CONNECTOR_TABLE_ID = 'connectors-table';

// agg of all table ids types
export const BRAND_PROPERTY_TABLE_IDS =
  typeof LOGOS_TABLE_ID ||
  typeof DOCUMENTS_TABLE_ID ||
  typeof TRADEMARK_TABLE_ID ||
  typeof MARKETPLACE_TABLE_ID ||
  typeof APP_STORE_TABLE_ID ||
  typeof SOCIAL_MEDIA_TABLE_ID;

export const ASSETS_FORM_FIELDS = {
  [PropertyType.LOGO]: [
    {
      id: 'files',
      label: 'Logo',
      type: InputType.FILES,
      isRequired: true,
      fileType: 'IMAGE',
    },
  ],
  [PropertyType.TRADEMARK]: [
    {
      id: 'label',
      label: 'Trademark Label',
      type: InputType.TEXT,
      isRequired: true,
    },
    {
      id: 'registrationNumber',
      label: 'Registration Number',
      type: InputType.TEXT,
      isRequired: true,
    },
    {
      id: 'jurisdiction',
      label: 'Jurisdiction',
      type: InputType.TEXT,
      isRequired: true,
    },
    {
      id: 'classId',
      label: 'Class',
      type: InputType.TEXT,
      isRequired: true,
    },
  ],
  [PropertyType.SOCIAL]: [
    {
      id: 'platform',
      label: 'Platform',
      type: InputType.DROPDOWN,
      isRequired: true,
      options: [],
    },
    {
      id: 'name',
      label: 'Account/Channel Name',
      type: InputType.TEXT,
      isRequired: true,
    },
    {
      id: 'url',
      label: 'URL',
      type: InputType.TEXT,
      isRequired: true,
    },
  ],
  [PropertyType.DOCUMENT]: [
    {
      id: 'files',
      label: '',
      type: InputType.FILES,
      isRequired: true,
      fileType: 'PDF',
    },
    {
      id: 'notes',
      label: 'Notes',
      type: InputType.TEXT,
      isRequired: true,
    },
  ],
  [PropertyType.MARKETPLACE]: [
    {
      id: 'platform',
      label: 'Platform',
      type: InputType.DROPDOWN,
      isRequired: true,
      options: [],
    },
    {
      id: 'name',
      label: 'Marketplace Name',
      type: InputType.TEXT,
      isRequired: true,
    },
    {
      id: 'sellerName',
      label: 'Seller Name',
      type: InputType.TEXT,
      isRequired: true,
    },
  ],
  [PropertyType.APP_STORE]: [
    {
      id: 'name',
      label: 'App Name',
      type: InputType.TEXT,
      isRequired: true,
    },
    {
      id: 'domainLabel',
      label: 'App Store',
      type: InputType.TEXT,
      isRequired: true,
    },
    {
      id: 'url',
      label: 'URL',
      type: InputType.TEXT,
      isRequired: true,
    },
  ],
};

import React from 'react';
import RightTick from '../../assets/right-tick.svg';

interface IFeatureList {
  featureTitle: string;
  featureList: string[];
}

export default function FeatureList({ featureTitle, featureList }: IFeatureList) {
  return (
    <div className='features'>
      <div className='feature-head'>{featureTitle}</div>
      <div className='features-list'>
        <ul>
          {featureList.map((item: string, index: number) => (
            <li key={index}>
              <img src={RightTick} alt='check-mark' />
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import ToolTip from '../../Common/ToolTip';
import {
  AlertActionsTypes,
  appConstants,
  DashBoardDto,
  featureIsAvailable,
  IBrandInfo,
  IReasonDropdownValues,
  isBrandReadOnlyUser,
  isInternalUser,
} from '../../../constants';
import { ELoggedIn } from '../../../reducers';
import { Button } from 'react-bootstrap';
import SocRequestDocuments from '../Components/SocRequestDocuments';
import './RequestTakedownContainer.scss';
import UserCommentModal from '../Components/UserCommentModal';
import { SocTakedownService } from '../../../services/soc-takedown.service';
import { invoke } from '../../../helpers/async';
import { EventHistoryEntity } from '../../../types/event-history-entity.interface';
import { SocRequestedDocument } from '../../../types/url-event.types';
import TakedownStatus from '../Components/TakedownStatus';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { AppState, history } from '../../../helpers';
import {
  PRE_MALICIOUS_TABLE_ID_DASH,
  TAKEDOWN_MALICIOUS_TABLE_ID_DASH,
  POST_MALICIOUS_TABLE_ID_DASH,
  WEB_INITIATE_TAKEDOWN_PATHNAME,
  POST_MALICIOUS_PATHNAME,
} from '../../MonitorAndTakedown/constants';
import { EFindingStatus, ugcType } from '../../Ugc/Types/ugc.types';
import TakedownRequestModal from '../../Insights/takedown.request.modal.component';
import DashboardService from '../../../services/dashboard.service';
import { SocTakedownStatuses } from '../../../types/soc-takedown-statuses.enum';
import CheckPhishContactUsContext from '../../../context/CheckPhishContactUsContext';
import { TYPOSQUATTING_PATHNAME } from '../../DomainMonitoring/constant';
const socTakedownService = new SocTakedownService();
const dashboardService = new DashboardService();
interface IRequestContainerProps {
  type: string;
  status: string;
  disposition: string;
  user: DashBoardDto;
  urlSha256: string;
  loggedIn: ELoggedIn;
  brandId: string;
  subBrandId: string;
  brandInfo: IBrandInfo;
  providerName?: string;
  abuseContact: string;
  insightsData?: any;
  userEmail: string;
  enableCommunity: boolean;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}
const RequestTakedownContainer = ({
  type,
  status,
  disposition,
  loggedIn,
  urlSha256,
  user,
  alertSuccess,
  alertError,
  brandId,
  subBrandId,
  brandInfo,
  abuseContact,
  insightsData,
  userEmail,
  providerName = 'Hosting Provider',
  enableCommunity,
}: IRequestContainerProps) => {
  const { setTriggerContactUsModal, setReason, setSelectedUrls, setSelectedUrlsSha } = useContext(
    CheckPhishContactUsContext,
  );
  const [showCustomerCommentModal, setShowCustomerCommentModal] = useState<boolean>(false);
  const [showSocRequestDocumentModal, setShowSocRequestDocumentModal] = useState<boolean>(false);

  const [customerActionsNeeded, setCustomerActionsNeeded] = useState<
    EventHistoryEntity<SocRequestedDocument>[]
  >([]);
  const [takedownStatus, setTakedownStatus] = useState<SocTakedownStatuses>(
    SocTakedownStatuses.None,
  );
  const [takedownStatusLoading, setTakedownStatusLoading] = useState(false);
  const [showTakeDownButton, setShowTakeDownButton] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const timelineUpdated = useSelector((state: AppState) => {
    return state.dashboardReducer.timelineUpdated;
  });
  const isSameBrand = () => {
    return (
      brandId === brandInfo?.brand?.brandName ||
      subBrandId === brandInfo?.brand?.brandName ||
      _.some(brandInfo?.brand?.subBrands, ['brand_name', subBrandId])
    );
  };
  const isPostMalicious = window.location.pathname.indexOf(POST_MALICIOUS_PATHNAME) !== -1;
  const isDomainMonitoring = window.location.pathname.indexOf(TYPOSQUATTING_PATHNAME) !== -1;
  const showRequestTakedownButton = () => {
    // It is not depended on the status (from url_crawl_info) any more.
    if (enableCommunity) {
      validateCheckphishUserDomainMonitoring();
    } else {
      if (type === ugcType.App_Store) {
        return status.toUpperCase() === EFindingStatus.LIVE && isSameBrand();
      }

      if (brandInfo?.brand?.brandMapping) {
        validateWebUserTakedownRequest(brandInfo?.brand?.brandMapping);
      } else {
        validateWebUserTakedownRequest(isSameBrand());
      }
    }
  };
  const redirectToCommunity = () => {
    window.open('https://community.bolster.ai/');
  };

  const validateCheckphishUserDomainMonitoring = () => {
    const showButton: boolean = disposition !== 'clean' && isDomainMonitoring;
    setShowTakeDownButton(showButton);
  };
  const validateWebUserTakedownRequest = (isValidBrand: boolean) => {
    const showButton: boolean = disposition !== 'clean' && !isPostMalicious && isValidBrand;
    setShowTakeDownButton(showButton);
  };
  useEffect(() => {
    showRequestTakedownButton();
  }, [disposition]);

  useEffect(() => {
    invoke(async () => {
      await fetchCustomerActionsNeeded();
    });
  }, [urlSha256, timelineUpdated]);

  const fetchCustomerActionsNeeded = async () => {
    setTakedownStatus(SocTakedownStatuses.None);
    try {
      setTakedownStatusLoading(true);
      const res = await socTakedownService.getCurrentTakedownStatus(urlSha256);
      setTakedownStatus(res.status);
      setCustomerActionsNeeded(_.sortBy(res.actionsNeeded, a => a.data.timestamp));
    } catch (e) {
      console.error('Error fetching customer actions.', e);
    } finally {
      setTakedownStatusLoading(false);
    }
  };

  const { srcURL, timestamp, url_sha256: urlSHA256, brand, primaryDomain, tld } = insightsData;
  const handleRequestTakedown = (
    comments?: string,
    files?: File[],
    googleSafeBrowsing?: boolean,
    apwgBlocklist?: boolean,
  ) => {
    setRefreshing(true);
    // Showing loader
    setIsLoading(true);
    // send takedown request for web
    dashboardService
      .postTakedownRequest(
        srcURL,
        timestamp,
        urlSHA256,
        brand,
        abuseContact,
        userEmail,
        primaryDomain,
        tld,
        comments,
        files,
        googleSafeBrowsing,
        apwgBlocklist,
      )
      .then(res => {
        if (res) {
          alertSuccess?.('Takedown requested successfully');
        }
      })
      .catch(err => {
        alertError?.(err?.message ?? 'Takedown request failed. Please contact support@bolster.ai.');
        setRefreshing(false);
      })
      .finally(() => {
        // get updated takedowns
        setIsLoading(false);
        // hide modal
        setShowRequestModal(false);
      });
  };

  // This container will have

  // TODO: ZION-5663
  // If there is no request takedown and its SOC user
  // then request takedown button will open soc takedwon view

  // TODO: ZION-5663
  // If there is no request  setReason('takedown');takedown and its customer user
  // then request takedown button will opwen existing request takedown modal

  // TODO: ZION-5610
  // If request takedown in progress and its SOC user
  // render request documnets/modal

  // TODO: ZION-5606
  // how status of Takedown in progress or Taken down or Action need
  const showRequestModalOnClick = () => {
    setShowRequestModal(true);
  };
  const rerouteClick = () => {
    const currentPathName = window.location.pathname.split('/').slice(1, 3).join('/');
    const currentLocation = currentPathName.split('/')[1];
    const params = new URLSearchParams('');
    params.append('urlSha256s', urlSHA256);
    switch (currentLocation) {
      case PRE_MALICIOUS_TABLE_ID_DASH:
        params.append('urlPage', 'pre-malicious');
        break;
      case TAKEDOWN_MALICIOUS_TABLE_ID_DASH:
        params.append('urlPage', 'takedown');
        break;
      case POST_MALICIOUS_TABLE_ID_DASH:
        params.append('urlPage', 'post-malicious');
        break;
      default:
        params.append('urlPage', 'pre-malicious');
    }
    params.append('returnUrl', window.location.pathname);
    history.push(WEB_INITIATE_TAKEDOWN_PATHNAME + '?' + params.toString());
  };

  const requestTakedownButtonAction = () => {
    if (enableCommunity) {
      setReason(IReasonDropdownValues.TAKEDOWN);
      setSelectedUrls([srcURL]);
      setSelectedUrlsSha([urlSHA256]);
      setTriggerContactUsModal(true);
    } else {
      loggedIn === ELoggedIn.true && !isInternalUser(user)
        ? showRequestModalOnClick()
        : rerouteClick();
    }
  };

  const isReadOnlyUser = isBrandReadOnlyUser(user);

  return (
    <div>
      <div className={'timeline-and-whois d-flex justify-content-between'}>
        {!takedownStatusLoading &&
          takedownStatus &&
          featureIsAvailable(user, [appConstants.FEATURE_CODE.WEB_SOC_TAKEDOWN]) && (
            <div className={'mr-1'}>
              <TakedownStatus
                loggedIn={loggedIn}
                status={takedownStatus}
                actions={customerActionsNeeded}
              />
            </div>
          )}
        {showTakeDownButton && insightsData.takedownCount === 0 && (
          <>
            {enableCommunity && (
              <ToolTip
                id={'diy-takedown'}
                customClassName={'blue-content-tooltip'}
                placement={'bottom'}
                tooltip={
                  'Learn how to take down a site with guides from CheckPhish Community experts.'
                }
              >
                <Button
                  onClick={redirectToCommunity}
                  className='mr-2 btn transparent-btn primary-border web-only text-uppercase'
                  disabled={enableCommunity && insightsData.takedownEnquiry}
                >
                  DIY TAKEDOWN
                </Button>
              </ToolTip>
            )}
            {enableCommunity ? (
              <ToolTip
                id={'diy-takedown'}
                customClassName={'blue-content-tooltip'}
                tooltip={<span>Get a demo to Auto-Takedowns for Phishing and Scams.</span>}
                placement={'bottom'}
              >
                <Button
                  onClick={requestTakedownButtonAction}
                  className='mr-2 btn btn-primary btn-outline-secondary web-only text-uppercase'
                  disabled={enableCommunity && insightsData.takedownEnquiry}
                >
                  Request Demo
                </Button>
              </ToolTip>
            ) : !isBrandReadOnlyUser(user) ? (
              <Button
                onClick={requestTakedownButtonAction}
                className='mr-2 btn btn-primary btn-outline-secondary web-only text-uppercase'
                disabled={enableCommunity && insightsData.takedownEnquiry}
              >
                Request Takedown
              </Button>
            ) : null}
          </>
        )}

        {loggedIn === ELoggedIn.true &&
          !isInternalUser(user) &&
          featureIsAvailable(user, [appConstants.FEATURE_CODE.WEB_SOC_TAKEDOWN]) &&
          !isBrandReadOnlyUser(user) && (
            <Button
              className='btn btn-light text-uppercase mr-2'
              onClick={() => setShowCustomerCommentModal(true)}
            >
              Upload File/Add Comment
            </Button>
          )}

        {loggedIn === ELoggedIn.true &&
          isInternalUser(user) &&
          takedownStatus === SocTakedownStatuses.TakedownInProgress &&
          featureIsAvailable(user, [appConstants.FEATURE_CODE.WEB_SOC_TAKEDOWN]) && (
            <Button
              className='btn btn-primary btn-outline-secondary web-only text-uppercase'
              onClick={() => setShowSocRequestDocumentModal(true)}
            >
              Request Documents
            </Button>
          )}
      </div>

      {loggedIn === ELoggedIn.true && !isInternalUser(user) && (
        <TakedownRequestModal
          show={showRequestModal}
          abuseContact={abuseContact}
          onSubmit={(
            comments?: string,
            files?: File[],
            googleSafeBrowsing?: boolean,
            apwgBlocklist?: boolean,
          ) => handleRequestTakedown(comments, files, googleSafeBrowsing, apwgBlocklist)}
          onCancel={() => setShowRequestModal(false)}
          isLoading={isLoading}
          providerName={providerName}
          type={type}
        />
      )}
      <UserCommentModal
        show={showCustomerCommentModal}
        urlSha256={urlSha256}
        requestEventHistoryId={customerActionsNeeded?.[0]?.id}
        onSubmit={() => {
          setShowCustomerCommentModal(false);
          fetchCustomerActionsNeeded();
        }}
        onCancel={() => setShowCustomerCommentModal(false)}
      />
      <SocRequestDocuments
        show={showSocRequestDocumentModal}
        urlsha256={urlSha256}
        onSubmit={() => setShowSocRequestDocumentModal(false)}
        onCancel={() => setShowSocRequestDocumentModal(false)}
        alertSuccess={alertSuccess}
        alertError={alertError}
        user={user}
      />
    </div>
  );
};

export default RequestTakedownContainer;

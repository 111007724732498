import React from 'react';
import moment from 'moment';
import Country from '../../Common/Country';

interface IScanLocationDescriptionProps {
  scanDescription: IScanDescription;
}

interface IScanDescription {
  createdTs: string;
  scanLocation: string;
  wait: number | string;
  userAgent: string;
}
const ScanLocationDescription = ({ scanDescription }: IScanLocationDescriptionProps) => {
  const { createdTs, scanLocation, wait, userAgent } = scanDescription;

  const renderSpan = (spanClassName: string, scanValue: any) => {
    return <span className={spanClassName}>{scanValue || '--'}</span>;
  };

  return (
    <div className='scan-description-wrapper'>
      {'Scanned on'}{' '}
      {renderSpan('scan-value-wrapper', moment(createdTs).format('MM-DD-YYYY, hh:mm A'))} {'from'}{' '}
      <Country
        countryCode={scanLocation}
        customClassName={'scan-value-wrapper'}
        defaultCountryCode={'US'}
      />{' '}
      using {userAgent ? <b>{userAgent}</b> : '--'} and timeout of{' '}
      <b>{wait === 'Auto' || wait === undefined ? 'Auto' : wait + 'ms'}</b>
    </div>
  );
};

export default ScanLocationDescription;

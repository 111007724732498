import React from 'react';
import '../../Styles/CategoryPill.scss';
import { colors } from '../../../../constants';
import { CATEGORY_TYPE } from '../../DarkWebConstants';

export interface ICategoryPill {
  category: string;
}

const renderBackgroundColor = (category: string) => {
  switch (category) {
    case CATEGORY_TYPE.ANY: {
      return colors.darkWebEntityAny;
    }
    case CATEGORY_TYPE.BREACH_DATA_FOR_SALE: {
      return colors.darkWebEntityBreachDataForSale;
    }
    case CATEGORY_TYPE.EMPLOYEE_MONITORING: {
      return colors.darkWebEntityEmployeeMonitoring;
    }
    case CATEGORY_TYPE.HACKER_CHATTER: {
      return colors.darkWebEntityHackerChatter;
    }
    case CATEGORY_TYPE.IP_LEAK_DATA_FOR_SALE: {
      return colors.darkWebEntityIPLeakDataForSale;
    }
    case CATEGORY_TYPE.MALWARE_EXPLOITS_FOR_SALE: {
      return colors.darkWebEntityMalwareExploitsForSale;
    }
    case CATEGORY_TYPE.PHISHING_KITS_FOR_SALE: {
      return colors.darkWebEntityPhishingKitsForSale;
    }
    case CATEGORY_TYPE.RANDOMWARE_FOR_SALE: {
      return colors.darkWebEntityRandomwareForSale;
    }
    default: {
      return colors.darkWebEntityDefault;
    }
  }
};

const CategoryPill = ({ category }: ICategoryPill) => {
  return (
    <div className='category-wrap' style={{ backgroundColor: renderBackgroundColor(category) }}>
      {category}
    </div>
  );
};

export default CategoryPill;

import fetch from './api.service';
import { downloadApi, handleResponse, setPostRequestOptions } from './serviceWorker';
import { buildFilterQueryString } from './ugc.service';
import { BrandAssetValue, BrandPropertyResponse } from '../types/brand-property-result.interface';

export default class AssetsService {
  getAssets(isAffiliated: boolean) {
    return fetch(`/api/v1/brand-admin/get-asset-list?is_affiliated=${isAffiliated}`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  deleteAssets(assets: string, brandIds?: any[]) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ list: { assets, brandIds } }),
    };
    return fetch('/api/v1/brand-admin/delete-assets-from-list', requestOptions).then(
      handleResponse,
    );
  }

  addAssetsByInput(assets: string, isAffiliated: boolean) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ list: { assets }, is_affiliated: isAffiliated }),
    };
    return fetch(
      '/platform-api/v1/platform-administration/brand-admin/add-assets-from-list',
      requestOptions,
    ).then(handleResponse);
  }

  addAssetsFromFile(file: any, brandId?: number, isAffiliated: boolean = false) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: file,
    };

    return fetch(
      `/platform-api/v1/platform-administration/brand-admin/add-assets-from-file?isAffiliated=${isAffiliated}`,
      requestOptions,
    ).then(handleResponse);
  }

  getAllPurchasedAssetsByBolster() {
    return fetch('/api/v1/domain/purchased', {
      credentials: 'include',
    }).then(handleResponse);
  }

  purchasedDomains(domains: any) {
    const requestOptions: RequestInit = setPostRequestOptions(
      JSON.stringify({ domainSha256s: domains }),
    );
    return fetch('/platform-api/v1/domain/acquisition/purchase', requestOptions).then(
      handleResponse,
    );
  }

  getDomainAcquisitionSummary() {
    return fetch('/platform-api/v1/domain/acquisition/purchase/summary', {
      credentials: 'include',
    }).then(handleResponse);
  }

  addCredit(data: any): Promise<any> {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch('/platform-api/v1/credit/purchase', requestOptions).then(handleResponse);
  }

  //Below methods are moved to the brand-property-request.ts
  getBrandProperties<TValue extends BrandAssetValue = BrandAssetValue>(
    options: any,
  ): Promise<BrandPropertyResponse<TValue>> {
    const { query, filters, sort } = options;
    const data = {
      filter: buildFilterQueryString(filters || []),
      limit: query?.pageSize,
      offset: query?.pageSize * query?.pageNumber,
      order: Object.keys(sort).length > 0 ? `${sort?.sortBy}.${sort?.sortDirection}.nullslast` : '',
      total: true,
    };

    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch('/platform-api/v1/user/property/search', requestOptions).then(handleResponse);
  }

  createNewBrandProperty = (payload: any) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: payload,
    };
    return fetch('/platform-api/v1/user/property', requestOptions).then(handleResponse);
  };

  deleteBrandProperty = (payload: any) => {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload.propertyIds),
    };
    return fetch('/platform-api/v1/user/property', requestOptions).then(handleResponse);
  };

  downloadPdfAssets = (payload: any) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: payload }),
    };
    return downloadApi('/api/v1/user/property/get-pdf-document', requestOptions);
  };
}

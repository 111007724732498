import { dashboardConstants } from '../constants';

const addUserReducer = (state = { result: true }, action: any) => {
  switch (action.type) {
    case dashboardConstants.ADD_USER_SUCCESS:
      return {
        result: true,
      };
    case dashboardConstants.ADD_USER_FAILURE:
      return {
        result: false,
      };
    default:
      return state;
  }
};
export { addUserReducer };

import fetchApi from '../../services/api.service';
import { handleResponse } from '../../services/serviceWorker';
import { massagedConnectors } from './playbook-util';

export const fetchAllConnectors = async (): Promise<any[]> => {
  const rawResponse = await fetchApi('/platform-api/v1/playbook/connector').then(handleResponse);
  return massagedConnectors(rawResponse);
};
export const fetchConnectorDefinition = async (): Promise<any> => {
  const rawResponse = await fetchApi('/platform-api/v1/playbook/connector/definition').then(
    handleResponse,
  );
  return rawResponse;
};

import { AppState } from '../../helpers';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { LabelAndValue } from '../Common/LabelAndValue';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import AssetsService from '../../services/assests.service';
import { getKeyFromUrlPath, getNameFromUrlPath } from '../../constants/util';
import { alertActions } from '../../actions/alert.actions';
import { AlertActionsTypes } from '../../constants/alert.constants';
import { TypedReactEvent } from '../../types/common';
import { SocBrandAssetDetails } from './types/soc-brand-asset-response.interface.ts/soc-brand-asset-details.interface';
import { invoke } from '../../helpers/async';
import { AddressPropertyValue } from '../../types/brand-property-result.interface';
import { IBrandInfo } from '../../constants';
import { IPropertyType } from '../Assets_v2/PropertyTypes';
import { formatError } from '../../helpers/errors';
import { SocTakedownService } from '../../services/soc-takedown.service';

interface IPropsBrandAssets {
  currentBrand?: IBrandInfo;
  brandPropertyTypes: IPropertyType[];
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

const assetsService = new AssetsService();
const socTakedownService = new SocTakedownService();

function SocTakedownBrandAssets({
  currentBrand,
  brandPropertyTypes,
  alertSuccess,
  alertError,
}: IPropsBrandAssets) {
  const [brandAssets, setBrandAssets] = useState<SocBrandAssetDetails | null>(null);
  const constructAddress = (item: AddressPropertyValue) => {
    return (
      item.address +
      ' ' +
      item.aptSuiteEtc +
      ', ' +
      item.city +
      ', ' +
      item.state +
      ' ' +
      item.zipCode
    );
  };

  useEffect(() => {
    invoke(async () => {
      if (brandPropertyTypes?.length > 0 && currentBrand != null) {
        try {
          const brandAssets = await socTakedownService.getBrandAssets(
            currentBrand,
            brandPropertyTypes,
          );
          setBrandAssets(brandAssets);
        } catch (error) {
          console.error(error);
          alertError(formatError(error));
        }
      }
    });
  }, [currentBrand, brandPropertyTypes]);

  const basicInfo = () => {
    return brandAssets ? (
      <div className={'basic-info-container'}>
        <LabelAndValue
          alignItems={'flexStart'}
          label={'Brand Name'}
          value={brandAssets.brand.brandName}
          direction={'column'}
          copyButton={true}
        />

        <LabelAndValue
          alignItems={'flexStart'}
          label={'Organization'}
          value={brandAssets.organization.name}
          direction={'column'}
          copyButton={true}
        />

        {brandAssets.address != null && (
          <LabelAndValue
            alignItems={'flexStart'}
            label={'Address'}
            value={constructAddress(brandAssets.address)}
            direction={'column'}
            copyButton={true}
          />
        )}

        {brandAssets.domain?.length > 0 && (
          <LabelAndValue
            alignItems={'flexStart'}
            label={'Official Domain'}
            value={brandAssets.domain}
            direction={'column'}
            copyButton={true}
          />
        )}
      </div>
    ) : (
      <div className={'basic-info-container'}>
        <Spinner className='spinner' animation='border' variant='info' size='sm' />
      </div>
    );
  };
  const trademarkInfo = () => {
    return (
      <div className={'trademarks-container'}>
        <h1>Brand Trademarks</h1>
        {brandAssets?.trademarks?.length ? (
          brandAssets.trademarks.map((item, key: number) => {
            return (
              <div className='trademark-box' key={key}>
                <div className='divider'>
                  <LabelAndValue
                    alignItems={'flexStart'}
                    label={'Brand'}
                    value={item.brand}
                    direction={'column'}
                  />
                </div>
                <div className='divider'>
                  <LabelAndValue
                    alignItems={'flexStart'}
                    label={'Trademark'}
                    value={item.label}
                    direction={'column'}
                  />
                </div>
                <div className='divider'>
                  <LabelAndValue
                    alignItems={'flexStart'}
                    label={'Jurisdiction'}
                    value={item.jurisdiction.toString()}
                    direction={'column'}
                  />
                </div>
                <div className='divider'>
                  <LabelAndValue
                    alignItems={'flexStart'}
                    label={'Class'}
                    value={item.classId.toString()}
                    direction={'column'}
                  />
                </div>
                <div className='divider'>
                  <LabelAndValue
                    alignItems={'flexStart'}
                    label={'Registration #'}
                    value={item.registrationNumber.toString()}
                    direction={'column'}
                    copyButton={true}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  async function downloadPdf(
    event: TypedReactEvent<HTMLAnchorElement, React.MouseEvent<HTMLAnchorElement>>,
  ) {
    try {
      const res = await assetsService.downloadPdfAssets({ documentName: event.target.id });

      if (res && res.result) {
        alertSuccess(`Your pdf has downloaded successfully.`);
      } else {
        alertError('File download failed. Please contact support@bolster.ai.');
      }
    } catch (error) {
      alertError(formatError(error));
    }
  }

  const documentInfo = () => {
    return (
      <div className={'documents-container'}>
        Brand Documents
        {brandAssets?.documents?.length ? (
          brandAssets.documents.map((item, key: number) => {
            return (
              <div className={'item-container'} key={key}>
                <div>Brand: {item.brand}</div>
                <a
                  key={key}
                  className={'documents-box'}
                  onClick={downloadPdf}
                  id={getKeyFromUrlPath(item.path)}
                >
                  {getNameFromUrlPath(item.path)}
                </a>
                <div className='document-notes'>Notes: {item.notes} </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    );
  };
  return (
    <div className='soc-takedown-brand-asset-component-container p-3'>
      <Tabs defaultActiveKey='basic-info' className='p-1'>
        <Tab eventKey='basic-info' title='Basic Info'>
          {basicInfo()}
        </Tab>
        <Tab eventKey='trademarks' title='Trademarks'>
          {trademarkInfo()}
        </Tab>
        <Tab eventKey='documents' title='Documents'>
          {documentInfo()}
        </Tab>
      </Tabs>
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  const { brandPropertyTypes } = state.appReducer;
  return {
    brandPropertyTypes,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocTakedownBrandAssets);

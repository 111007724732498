import { DashBoardDto } from './types';
import {
  IMatFetchOptions,
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
} from '../components/MonitorAndTakedown/constants';
import { WebUrlData } from '../types/web-url-data.interface';
import { appConstants } from './app.constants';
import {
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  APP_STORE_TAKEN_DOWN_TABLE_ID,
} from '../components/AppStore/constants';
import { IFindingsTableItemProps } from '../components/Ugc/Types/ugc.types';
import {
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
  SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
} from '../components/SocialMedia/constants';
import {
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_FINDING_REVIEW_TABLE_ID,
  MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID,
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
} from '../components/Marketplace/constants';

export const dashboardConstants = {
  GET_USER_INFO_SUCCESS: 'USERS_INFO_SUCCESS',
  GET_USER_INFO_FAILURE: 'USERS_INFO_FAILURE',

  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_BRAND_INFO_SUCCESS: 'GET_BRAND_INFO_SUCCESS',

  GET_DASHBOARD_CONFIG_SUCCESS: 'GET_DASHBOARD_CONFIG_SUCCESS',
  GET_DASHBOARD_CONFIG_FAILURE: 'GET_DASHBOARD_CONFIG_FAILURE',

  ADD_USER_SUCCESS: 'USERS_ADD_SUCCESS',
  ADD_USER_FAILURE: 'USERS_ADD_FAILURE',

  ON_DASHBOARD_SELECTED: 'ON_DASHBOARD_SELECTED',
  ON_NEW_DASHBOARD: 'ON_NEW_DASHBOARD',
  ON_SET_DEFAULT_DASHBOARD: 'ON_SET_DEFAULT_DASHBOARD',
  ON_CHANGE_DASHBOARD: 'ON_CHANGE_DASHBOARD',

  ON_NEW_DASHBOARD_V2: 'ON_NEW_DASHBOARD_V2',

  SET_CURRENT_QUERY: 'SET_CURRENT_QUERY',

  TIMELINE_UPDATED: 'TIMELINE_UPDATED',

  SET_SELECTED_WEB_URLS: 'SET_SELECTED_WEB_URLS',

  SET_FINDINGS_TABLES_URLS: 'SET_FINDINGS_TABLES_URLS',
  SET_TYPOSQUAT_DATA: 'SET_TYPOSQUAT_DATA',
};

export interface IDashboardDispatchProps {
  setCurrentFetchOptions: (options: IMatFetchOptions) => void;
  timelineUpdated: () => void;
}

export interface GetUserInfoSuccess {
  type: typeof dashboardConstants.GET_USER_INFO_SUCCESS;
  user: DashBoardDto;
  error: string;
}

export interface GetUserInfoFailure {
  type: typeof dashboardConstants.GET_USER_INFO_FAILURE;
  user: DashBoardDto;
  error: string;
}

export type DashboardActionsTypes = GetUserInfoSuccess | GetUserInfoFailure;

export enum UrlAction {
  Dispute = 'dispute-verdict',
  TakeDown = 'report-abuse',
}

export const mockPriorityWidgetData = [
  {
    label: 'Priority 1',
    count: 200,
    estimatedPriceSum: 2744,
    color: '#A83C8F',
  },
  {
    label: 'Priority 2',
    count: 200,
    estimatedPriceSum: 2400,
    color: '#C85E92',
  },
  {
    label: 'Priority 3',
    count: 100,
    estimatedPriceSum: 1200,
    color: '#ED8595',
  },
];

export const mockRegionWidgetData = {
  counts: [
    {
      label: 'Generic',
      count: 1599,
      estimatedPriceSum: 78544,
    },
    {
      label: 'America',
      count: 387,
      estimatedPriceSum: 32542,
    },
    {
      label: 'Europe',
      count: 339,
      estimatedPriceSum: 20949,
    },
    {
      label: 'Africa',
      count: 301,
      estimatedPriceSum: 28419,
    },
    {
      label: 'Asia',
      count: 290,
      estimatedPriceSum: 24512,
    },
    {
      label: 'Australia (Oceania)',
      count: 84,
      estimatedPriceSum: 8186,
    },
  ],
};

export const darkwebMocAnalyticskData = {
  executiveMonitoring: [
    {
      searchTerm: '"James Anderson"',
      searchTermId: [4214],
      values: [
        {
          platform: 'paste : pastebin.com',
          doc_count: {
            value: 3,
          },
        },
        {
          platform: 'paste : doxbin.com',
          doc_count: {
            value: 2,
          },
        },
        {
          platform: 'txy7pgu2kdqg3ovmauiyeyrdhqwjdedvmn2rkcmj2vtknvk7zdo4vqqd.onion',
          doc_count: {
            value: 1,
          },
        },
      ],
    },
  ],
  otherSearchTerms: [
    {
      searchTerm: '"Bank of Bolster Phishing Kit"',
      category: 'Phishing Kits for Sale',
      count: 6,
      metadata: {
        created_ts: '2022-10-13T15:40:44.566523',
      },
    },
    {
      searchTerm: '"Bank of Bolster Source Code"',
      category: 'IP Leak Data for Sale',
      count: 6,
      metadata: {
        created_ts: '2022-10-13T14:28:46.905917',
      },
    },
  ],
  email: [],
  emaildomain: [
    {
      searchTerm: 'bolsterbank.com',
      doc_count: 2215,
      searchTermId: [16056],
      values: [
        {
          platform: 'naz.api',
          doc_count: {
            value: 1039,
          },
          unique_count: {
            value: 175,
          },
          risk_count: [
            {
              key: 'HIGH',
              doc_count: 913,
              count_risk_type: {
                value: 152,
              },
            },
            {
              key: 'LOW',
              doc_count: 107,
              count_risk_type: {
                value: 19,
              },
            },
            {
              key: 'MEDIUM',
              doc_count: 19,
              count_risk_type: {
                value: 5,
              },
            },
          ],
          password_counts: [
            {
              key: 'plain',
              doc_count: 913,
            },
            {
              key: '',
              doc_count: 107,
            },
            {
              key: 'hashed',
              doc_count: 19,
            },
          ],
        },
        {
          platform: 'telegram',
          doc_count: {
            value: 669,
          },
          unique_count: {
            value: 489,
          },
          risk_count: [
            {
              key: 'HIGH',
              doc_count: 483,
              count_risk_type: {
                value: 482,
              },
            },
            {
              key: 'LOW',
              doc_count: 186,
              count_risk_type: {
                value: 7,
              },
            },
          ],
          password_counts: [
            {
              key: 'plain',
              doc_count: 483,
            },
            {
              key: '',
              doc_count: 186,
            },
          ],
        },
        {
          platform: 'onion.v3',
          doc_count: {
            value: 196,
          },
          unique_count: {
            value: 88,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 196,
              count_risk_type: {
                value: 88,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 196,
            },
          ],
        },
        {
          platform: 'unclassified',
          doc_count: {
            value: 74,
          },
          unique_count: {
            value: 48,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 72,
              count_risk_type: {
                value: 46,
              },
            },
            {
              key: 'HIGH',
              doc_count: 2,
              count_risk_type: {
                value: 2,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 72,
            },
            {
              key: 'plain',
              doc_count: 2,
            },
          ],
        },
        {
          platform: '229M Deezer Customers',
          doc_count: {
            value: 47,
          },
          unique_count: {
            value: 47,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 47,
              count_risk_type: {
                value: 47,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 47,
            },
          ],
        },
        {
          platform: 'LinkedIn Data 2023 35M',
          doc_count: {
            value: 39,
          },
          unique_count: {
            value: 39,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 39,
              count_risk_type: {
                value: 39,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 39,
            },
          ],
        },
        {
          platform: 'Deezer 229M Customers',
          doc_count: {
            value: 29,
          },
          unique_count: {
            value: 29,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 29,
              count_risk_type: {
                value: 29,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 29,
            },
          ],
        },
        {
          platform: 'AT&T 70M',
          doc_count: {
            value: 21,
          },
          unique_count: {
            value: 18,
          },
          risk_count: [
            {
              key: 'HIGH',
              doc_count: 21,
              count_risk_type: {
                value: 18,
              },
            },
          ],
          password_counts: [
            {
              key: 'plain',
              doc_count: 21,
            },
          ],
        },
        {
          platform: 'Redline Stealer Cloud ux 20AUG23',
          doc_count: {
            value: 15,
          },
          unique_count: {
            value: 15,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 15,
              count_risk_type: {
                value: 15,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 15,
            },
          ],
        },
        {
          platform: '10K Logs January',
          doc_count: {
            value: 11,
          },
          unique_count: {
            value: 5,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 11,
              count_risk_type: {
                value: 5,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 11,
            },
          ],
        },
        {
          platform: 'Johnny Logs 02DEC23',
          doc_count: {
            value: 9,
          },
          unique_count: {
            value: 6,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 9,
              count_risk_type: {
                value: 6,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 9,
            },
          ],
        },
        {
          platform: 'Logs 28',
          doc_count: {
            value: 9,
          },
          unique_count: {
            value: 9,
          },
          risk_count: [
            {
              key: 'HIGH',
              doc_count: 9,
              count_risk_type: {
                value: 9,
              },
            },
          ],
          password_counts: [
            {
              key: 'plain',
              doc_count: 9,
            },
          ],
        },
        {
          platform: 'Redline Premium MAR APR 969 US Logs',
          doc_count: {
            value: 7,
          },
          unique_count: {
            value: 5,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 7,
              count_risk_type: {
                value: 5,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 7,
            },
          ],
        },
        {
          platform: 'Cutout.pro',
          doc_count: {
            value: 6,
          },
          unique_count: {
            value: 4,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 6,
              count_risk_type: {
                value: 4,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 6,
            },
          ],
        },
        {
          platform: 'Johnny Logs 14DEC23',
          doc_count: {
            value: 5,
          },
          unique_count: {
            value: 5,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 5,
              count_risk_type: {
                value: 5,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 5,
            },
          ],
        },
        {
          platform: 'moh.gov.ge',
          doc_count: {
            value: 5,
          },
          unique_count: {
            value: 4,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 5,
              count_risk_type: {
                value: 4,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 5,
            },
          ],
        },
        {
          platform: 'Marvel Cloud Free Logs March 13',
          doc_count: {
            value: 4,
          },
          unique_count: {
            value: 2,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 4,
              count_risk_type: {
                value: 2,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 4,
            },
          ],
        },
        {
          platform: 'SLogs January 8',
          doc_count: {
            value: 4,
          },
          unique_count: {
            value: 2,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 4,
              count_risk_type: {
                value: 2,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 4,
            },
          ],
        },
        {
          platform: 'Johnny Logs 05DEC23',
          doc_count: {
            value: 3,
          },
          unique_count: {
            value: 3,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 3,
              count_risk_type: {
                value: 3,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 3,
            },
          ],
        },
        {
          platform: 'SLogs March 96',
          doc_count: {
            value: 3,
          },
          unique_count: {
            value: 3,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 3,
              count_risk_type: {
                value: 3,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 3,
            },
          ],
        },
        {
          platform: 'StealerLogs forum 177',
          doc_count: {
            value: 3,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 3,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 3,
            },
          ],
        },
        {
          platform: 'PwcCom',
          doc_count: {
            value: 2,
          },
          unique_count: {
            value: 2,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 2,
              count_risk_type: {
                value: 2,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 2,
            },
          ],
        },
        {
          platform: 'SLogs March 104',
          doc_count: {
            value: 2,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 2,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 2,
            },
          ],
        },
        {
          platform: 'SLogs March 97',
          doc_count: {
            value: 2,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 2,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 2,
            },
          ],
        },
        {
          platform: 'StealerLogs forum 82',
          doc_count: {
            value: 2,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 2,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 2,
            },
          ],
        },
        {
          platform: '3.4MB Amazon',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'HIGH',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: 'plain',
              doc_count: 1,
            },
          ],
        },
        {
          platform: '851MB Stealer Logs October',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'Duolingo.Com',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'SLogs January 1',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'SLogs January 4',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'SLogs January 9',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'StealerLogs forum 173',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'digido.ph',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'segacloud march',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
      ],
      unique_doc_count: 1011,
      plain_text_passwords: 1429,
      hashed_passwords: 19,
      without_passwords: 767,
      card_with_details: null,
      card_without_details: null,
      unexpired_cards: null,
    },
    {
      searchTerm: 'bankofbolster.com',
      doc_count: 5,
      searchTermId: [4215],
      values: [
        {
          platform: 'MGM2022',
          doc_count: {
            value: 2,
          },
          unique_count: {
            value: 2,
          },
          risk_count: [
            {
              key: 'HIGH',
              doc_count: 2,
              count_risk_type: {
                value: 2,
              },
            },
          ],
          password_counts: [
            {
              key: 'plain',
              doc_count: 2,
            },
          ],
        },
        {
          platform: '23andMe - Great Britain',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'MEDIUM',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: 'hashed',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'naz.api',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'MEDIUM',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: 'hashed',
              doc_count: 1,
            },
          ],
        },
        {
          platform: 'telegram',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          password_counts: [
            {
              key: '',
              doc_count: 1,
            },
          ],
        },
      ],
      unique_doc_count: 5,
      plain_text_passwords: 2,
      hashed_passwords: 2,
      without_passwords: 1,
      card_with_details: null,
      card_without_details: null,
      unexpired_cards: null,
    },
  ],
  creditcard: [
    {
      searchTerm: '112233',
      doc_count: 5,
      searchTermId: [4216],
      values: [
        {
          platform: 'AshleyMadison.com',
          doc_count: {
            value: 2,
          },
          unique_count: {
            value: 2,
          },
          risk_count: [
            {
              key: 'HIGH',
              doc_count: 2,
              count_risk_type: {
                value: 2,
              },
            },
          ],
          cards_with_details: {
            doc_count: 2,
          },
          cards_without_details: {
            doc_count: 0,
          },
          unexpired_cards: {
            doc_count: 0,
            count_unexpired: {
              value: 0,
            },
          },
        },
        {
          platform: 'KreditPlus',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          cards_with_details: {
            doc_count: 1,
          },
          cards_without_details: {
            doc_count: 0,
          },
          unexpired_cards: {
            doc_count: 1,
            count_unexpired: {
              value: 1,
            },
          },
        },
        {
          platform: 'Swvl',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'LOW',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          cards_with_details: {
            doc_count: 1,
          },
          cards_without_details: {
            doc_count: 0,
          },
          unexpired_cards: {
            doc_count: 1,
            count_unexpired: {
              value: 1,
            },
          },
        },
        {
          platform: 'naz.api',
          doc_count: {
            value: 1,
          },
          unique_count: {
            value: 1,
          },
          risk_count: [
            {
              key: 'MEDIUM',
              doc_count: 1,
              count_risk_type: {
                value: 1,
              },
            },
          ],
          cards_with_details: {
            doc_count: 1,
          },
          cards_without_details: {
            doc_count: 0,
          },
          unexpired_cards: {
            doc_count: 0,
            count_unexpired: {
              value: 0,
            },
          },
        },
      ],
      unique_doc_count: 5,
      plain_text_passwords: null,
      hashed_passwords: null,
      without_passwords: null,
      card_with_details: 5,
      card_without_details: 0,
      unexpired_cards: 2,
    },
  ],
};

export const socialMediaMockSummaryData = {
  summaryDetails: {
    totalThreatsDetected: 14,
    monitoredPlatforms: 10,
    monitoredSearchTerms: 4,
    monitoredImages: 4,
  },
};

export const socialMediaMockCountData = {
  threatDetections: [
    {
      type: 'Live Detection',
      label: 'Executive Impersonations',
      count: 1,
      id: 5,
    },
    {
      type: 'Live Detection',
      label: 'Adult',
      count: 0,
      id: 10,
    },
    {
      type: 'Live Detection',
      label: 'Currency Scams',
      count: 3,
      id: 11,
    },
    {
      type: 'Live Detection',
      label: 'Data Leakage',
      count: 0,
      id: 12,
    },
    {
      type: 'Live Detection',
      label: 'Cheats & Piracy',
      count: 0,
      id: 13,
    },
    {
      type: 'Live Detection',
      label: 'Trademark Infringements',
      count: 0,
      id: 14,
    },
    {
      type: 'Live Detection',
      label: 'Gift Card Scams',
      count: 0,
      id: 2,
    },
    {
      type: 'Live Detection',
      label: 'Fake Advertisements',
      count: 0,
      id: 1,
    },
    {
      type: 'Live Detection',
      label: 'Phishing',
      count: 0,
      id: 6,
    },
    {
      type: 'Live Detection',
      label: 'Hacked Accounts',
      count: 2,
      id: 18,
    },
    {
      type: 'Live Detection',
      label: 'Toxicity',
      count: 0,
      id: 19,
    },
    {
      type: 'Live Detection',
      label: 'Tech Support Scams',
      count: 0,
      id: 20,
    },
    {
      type: 'Live Detection',
      label: 'Crypto Scams',
      count: 0,
      id: 21,
    },
    {
      type: 'Live Detection',
      label: 'Counterfeit Goods',
      count: 0,
      id: 22,
    },
    {
      type: 'Takedown in Progress',
      label: 'PAUSED',
      count: 0,
    },
    {
      type: 'Takedown in Progress',
      label: 'IN_PROGRESS',
      count: 6,
    },
    {
      type: 'Taken Down',
      label: 'DOWN',
      count: 0,
    },
  ],
};

export const socialMediaMockPlatformWidgetData = {
  counts: [
    {
      label: 'Reddit',
      count: 3,
      status: [
        {
          label: 'LIVE',
          count: 3,
        },
      ],
    },
    {
      label: 'Facebook',
      count: 3,
      status: [
        {
          label: 'LIVE',
          count: 3,
        },
      ],
    },
    {
      label: 'YouTube',
      count: 2,
      status: [
        {
          label: 'LIVE',
          count: 2,
        },
      ],
    },
    {
      label: 'Discord',
      count: 2,
      status: [
        {
          label: 'LIVE',
          count: 2,
        },
      ],
    },
    {
      label: 'LinkedIn',
      count: 1,
      status: [
        {
          label: 'LIVE',
          count: 1,
        },
      ],
    },
  ],
};

export const socialMediaFeildCreated_TsCountMockData = {
  counts: [
    {
      label: '2024-04-01',
      count: 12,
      status: [
        {
          label: 'LIVE',
          count: 12,
        },
      ],
    },
  ],
};

export const socailMediaCategoryWidgetMockData = {
  counts: [
    {
      label: 'Currency Scams',
      count: 7,
    },
    {
      label: 'Hacked Accounts',
      count: 4,
    },
    {
      label: 'Executive Impersonations',
      count: 1,
    },
  ],
};

export const socialMediaSearchTermMockData = [
  {
    label: 'bank of bolster giveaway',
    count: 4,
  },
  {
    label: 'bank of bolster hack',
    count: 2,
  },
  {
    label: 'CEO of Bank of Bolster',
    count: 1,
  },
];

export const CSV_EXPORT_MAX = 10000;

export const FILE_ATTACHMENT_MAX = 10;

export interface ISelectedWebUrls {
  [PRE_MALICIOUS_TABLE_ID]: WebUrlData[];
  [TAKEDOWN_MALICIOUS_TABLE_ID]: WebUrlData[];
  [POST_MALICIOUS_TABLE_ID]: WebUrlData[];
}
export interface IFindingsTableUrls {
  [key: string]: IAppStoreTableUrls | IMarketPlaceTableUrls | ISocialMediaTableUrls;
}
export interface IAppStoreTableUrls {
  [APP_STORE_FINDING_DETECTION_TABLE_ID]: IFindingsTableItemProps[];
  [APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID]: IFindingsTableItemProps[];
  [APP_STORE_TAKEN_DOWN_TABLE_ID]: IFindingsTableItemProps[];
  [APP_STORE_FINDING_SAFE_LIST_TABLE_ID]: IFindingsTableItemProps[];
}
export interface IMarketPlaceTableUrls {
  [MARKET_PLACE_FINDING_DETECTION_TABLE_ID]: IFindingsTableItemProps[];
  [MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID]: IFindingsTableItemProps[];
  [MARKET_PLACE_TAKEN_DOWN_TABLE_ID]: IFindingsTableItemProps[];
  [MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID]: IFindingsTableItemProps[];
  [MARKET_PLACE_FINDING_REVIEW_TABLE_ID]: IFindingsTableItemProps[];
}
export interface ISocialMediaTableUrls {
  [SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID]: IFindingsTableItemProps[];
  [SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID]: IFindingsTableItemProps[];
  [SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID]: IFindingsTableItemProps[];
  [SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID]: IFindingsTableItemProps[];
  [SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID]: IFindingsTableItemProps[];
  [SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID]: IFindingsTableItemProps[];
}
export const DEFAULT_DATE_RANGE = 90;
export const DEFAULT_DATE_RANGE_V2 = 365;
export const WIDGET_HEIGHT = 310;
export const SMALLER_WIDGET_HEIGHT = 236;
export const ROW_HEIGHT = 280;
export const GRAPH_HEIGHT_INDEX = 1;

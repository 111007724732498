import React, { useContext, useEffect, useMemo, useState } from 'react';
import './integration.scss';
import { NewConnectorModal } from '../Connectors/newConnectorModal';
import {
  fetchConnectors,
  fetchConnectorTypes,
  IConnectorType,
  updateConnector,
} from '../Connectors/connector-requests';
import { history } from '../../helpers';
import { useAppDispatch } from '../../helpers/hooks';
import { alertActions } from '../../actions';
import ThemeContext from '../../context/ThemeContext';
import { appConstants, featureIsAvailable, ThemeModes } from '../../constants';
import Back from '../../assets/icons/Back.svg';
import BackWhite from '../../assets/icons/BackWhite.svg';
import { Button } from '@material-ui/core';
import arrow from '../../assets/icons/ArrowRight-white.svg';
import { useFeatureIsAvailable } from '../../basic-hooks/useFeatures';

export function Integrations() {
  const [exchangeConnectorTypeId, setExchangeConnectorTypeId] = useState<number | undefined>();
  const [implementedConnectors, setImplementedConnectors] = useState<IConnectorType[]>([]);
  const isExchangeConnectorCreated = useMemo(() => {
    return implementedConnectors.some(
      connector => connector.connectorType?.id === exchangeConnectorTypeId,
    );
  }, [implementedConnectors, exchangeConnectorTypeId]);
  const isExchangeFeatureEnabled = useFeatureIsAvailable(
    appConstants.FEATURE_CODE.M365_INTEGRATION,
  );

  const integrations = useMemo(() => {
    return [
      {
        name: 'Splunk',
        enabled: true,
        knowledgeBaseSurfix: 'splunk-integration',
      },
      {
        name: 'Sumo Logic',
        knowledgeBaseSurfix: 'sumo-logic',
        enabled: true,
      },
      {
        name: 'Tines',
        knowledgeBaseSurfix: 'tines-integration',
        enabled: true,
      },
      {
        name: 'Cortex XSOAR',
        enabled: true,
        knowledgeBaseSurfix: 'xsoar-integration',
      },
      // {
      //   name: 'Anomali',
      //   enabled: true,
      //   knowledgeBaseSurfix: 'anomali-integration',
      // },
      {
        name: 'ThreatConnect',
        enabled: true,
        knowledgeBaseSurfix: 'threatconnect-integration',
      },
      {
        name: 'Microsoft Sentinel',
        enabled: true,
        knowledgeBaseSurfix: 'microsoft-sentinal-integration',
      },
      {
        name: 'Slack',
        enabled: true,
        knowledgeBaseSurfix: 'slack-integration',
      },
      {
        name: 'Microsoft Teams',
        knowledgeBaseSurfix: 'microsoft-teams-integration',
        enabled: true,
      },
      {
        name: 'Microsoft 365 Exchange/Defender',
        knowledgeBaseSurfix: 'microsoft-365-exchange-defender-integration',
        enabled: isExchangeFeatureEnabled,
      },
      {
        name: 'API Integration',
        knowledgeBaseSurfix: 'api-integration',
        enabled: true,
      },
    ];
  }, [isExchangeFeatureEnabled]);

  const endabledIntegrations = useMemo(() => {
    return integrations.filter(integration => integration.enabled);
  }, [integrations]);

  const [selectedIntegration, setSelectedIntegration] = useState('');
  const dispatch = useAppDispatch();

  const { selectedTheme } = useContext(ThemeContext);
  const isDarkMode = selectedTheme === 'dark';

  const [knowledgeBaseHtml, setKnowledgeBaseHtml] = useState('');

  useEffect(() => {
    const knowledgeBaseSurfix = endabledIntegrations.find(
      integration => integration.name === selectedIntegration,
    )?.knowledgeBaseSurfix;
    if (!knowledgeBaseSurfix) {
      return;
    }
    void (async () => {
      try {
        const response = await fetch(
          `https://bolster.ai/wp-json/wp/v2/kbarticles?slug=${knowledgeBaseSurfix}`,
        );
        const html = (await response.json())?.[0]?.content?.rendered;
        setKnowledgeBaseHtml(html);
      } catch (error) {
        console.error('Failed to fetch knowledge base html', error);
      }
    })();
  }, [endabledIntegrations, selectedIntegration]);

  useEffect(() => {
    document.title = 'Integrations';
  }, []);

  useEffect(() => {
    void fetchConnectorTypes().then(response => {
      if (response) {
        const exchangeId = response?.result?.find(
          connectorType => connectorType.label === 'Exchange',
        )?.id;
        setExchangeConnectorTypeId(exchangeId);
      }
    });
    void fetchConnectors().then(response => {
      if (response) {
        setImplementedConnectors(response?.result);
      }
    });
  }, []);

  return (
    <div className='integrations-tab'>
      {!selectedIntegration && (
        <div>
          <div className='integrations-header'>Available Connectors</div>
          <div className='integration-container'>
            {endabledIntegrations.map((integration, index) => {
              const enabled = integration.enabled;

              return (
                <div
                  key={index}
                  className={`integration-item ${
                    enabled ? 'integration-enabled' : 'integration-disabled'
                  }`}
                  onClick={() => {
                    if (enabled) {
                      setSelectedIntegration(integration.name);
                    }
                  }}
                >
                  <div className='integration-image-container'>
                    <img
                      src={`/integrations/${integration.name}${isDarkMode ? '-dark' : ''}.svg`}
                      alt={integration.name}
                    />
                  </div>
                  <div className='integration-name-hidden-on-hover'>{integration.name}</div>
                  <div className='integration-button-visible-on-hover'>
                    <Button
                      size='small'
                      variant='contained'
                      color='primary'
                      style={{ borderRadius: '20px' }}
                      endIcon={<img src={arrow} />}
                    >
                      Enable
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {selectedIntegration && (
        <div>
          <div className='d-flex align-items-center'>
            <img
              src={selectedTheme === ThemeModes.DARK.toLowerCase() ? BackWhite : Back}
              alt='Back'
              className='back-logo'
              onClick={() => {
                setSelectedIntegration('');
              }}
            />
            <div className='selected-integration-title'>{selectedIntegration}</div>
          </div>
          <div className='new-connector-with-knowledge-base'>
            <NewConnectorModal
              show={!!selectedIntegration}
              onSubmit={connectorInfo => {
                void (async () => {
                  try {
                    await updateConnector(connectorInfo);
                    dispatch(
                      alertActions.success(`Successfully added connector '${connectorInfo.name}`),
                    );
                    history.push('/integrations/implemented-connectors');
                  } catch (error) {
                    dispatch(
                      alertActions.error('Updating connector failure, ' + JSON.stringify(error)),
                    );
                  }
                })();
              }}
              toggleModal={() => {
                setSelectedIntegration('');
              }}
              initValues={{
                isNewAdded: true,
                selectedIntegration,
                name: '',
                connectorTypeId: selectedIntegration === 'Slack' ? 3 : 2,
                details: {
                  method: 'POST',
                },
              }}
              fromIntegration
            />

            <div
              id='knowledge-base-content'
              dangerouslySetInnerHTML={{ __html: knowledgeBaseHtml }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

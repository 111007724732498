import { simpliedIntegrationNameMap } from '../../constants';
import fetchApi from '../../services/api.service';
import { handleResponse, setPostRequestOptions } from '../../services/serviceWorker';

export interface IConnectorDefType {
  id: number;
  key: string;
  label: string;
  doc_url: any;
  details: any;
  metadata: IConnectorMetadata[];
}

export interface IConnectorType {
  id: number;
  name: string;
  brandId: number;
  details: any;
  connectorTypeId: number;
  createdBy: string;
  createdAt: string;
  connectorType: IConnectorDefType;
}

export interface IConnectorMetadata {
  id: number;
  key: string;
  label: string;
  type: string;
  description: string;
  isGlobal: boolean;
}
export interface IMassagedConnectorTypes {
  id: number;
  label: string;
  value: string;
  isDefault: boolean;
}

export const fetchConnectors = async (): Promise<{ result: IConnectorType[] }> => {
  const rawResponse = await fetchApi('/platform-api/v1/playbook/connector').then(handleResponse);
  return rawResponse;
};

export const massageConnectors = (connectors: any) => {
  return connectors.map((connector: any) => {
    return {
      ...connector,
      type:
        simpliedIntegrationNameMap[connector.details?.selectedIntegration] ||
        connector.details?.selectedIntegration ||
        connector.connectorType.label,
    };
  });
};

export const fetchConnectorTypes = async (): Promise<{ result: IConnectorDefType[] }> => {
  const rawResponse = await fetchApi('/platform-api/v1/playbook/connector/definition').then(
    handleResponse,
  );
  return rawResponse;
};

export const massagedConnectorTypes = (
  connectorTypes: IConnectorDefType[],
): IMassagedConnectorTypes[] => {
  return connectorTypes.map((type: IConnectorDefType) => {
    return {
      id: type.id,
      label: type.label,
      value: type.label,
      isDefault: !type.metadata.some((field: any) => field.isGlobal),
    };
  });
};

export const deleteConnector = async (id: number) => {
  const requestOptions = {
    method: 'DELETE',
  };

  const response = await fetchApi(`/platform-api/v1/playbook/connector/${id}`, requestOptions).then(
    handleResponse,
  );
  return response;
};

export const updateConnector = async (connector: any) => {
  const requestOptions = setPostRequestOptions(JSON.stringify({ connector }));
  const response = await fetchApi('/platform-api/v1/playbook/connector', requestOptions).then(
    handleResponse,
  );
  return response;
};

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { numberWithCommas } from '../../../../constants';
import DashboardService from '../../../../services/dashboard.service';
import { TMapChartDataProps } from '../../../Common/DashboardWidgets/Types/chart.type';
import MapChart from '../../../Common/DashboardWidgets/MapChart';
import { Nullable } from '../../../../types/common';
import { mockRegionWidgetData } from '../../../../constants';

const dashboardService = new DashboardService();

const massageData = (rawData: any): TMapChartDataProps[] => {
  return _.map(
    rawData,
    (i): TMapChartDataProps => ({
      location: i.label,
      count: i.count || 0,
    }),
  );
};

function DashboardRegion({ activeOUId }: { activeOUId?: Nullable<number> }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<TMapChartDataProps[]>([]);
  const [generalValue, setGeneralValue] = useState<number>(0);
  const isDemo = window.location.pathname.includes('premium');

  const processDashboardRegionData = (data: any) => {
    const counts = data.counts;
    const result = massageData(counts);
    const general = _.find(counts, i => i.label.toLowerCase() === 'generic');
    setData(result);
    setGeneralValue(_.get(general, 'count', 0));
  };

  const getDomainBreakdownByRegionData = () => {
    setIsLoading(true);
    if (isDemo) {
      processDashboardRegionData(mockRegionWidgetData);
      setIsLoading(false);
      return;
    }
    dashboardService.getDomainAcquisitionCountByField('region', activeOUId).then((res: any) => {
      //need refactoring later
      processDashboardRegionData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getDomainBreakdownByRegionData();
  }, [activeOUId]);

  return (
    <div className='dashboard-region-widget'>
      <MapChart
        title='Domain Breakdown by Region (for acquisition)'
        isLoading={isLoading}
        description='Domain Breakdown by Region (for acquisition)'
        data={data}
      />
      <div className='general-count'>
        Generic: <b>{numberWithCommas(generalValue)}</b>
      </div>
    </div>
  );
}

export { DashboardRegion };

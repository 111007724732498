import React, { useEffect } from 'react';
import './NavItem.scss';
import { Link } from 'react-router-dom';
import { navItemIsActive } from '../../../helpers/url-helpers';
import ExpandableNavItem from './ExpandableNavItem';
import _ from 'lodash';
type Props = {
  navItem: any;
  selectedTheme: any;
};

const NavItemV2 = ({ navItem, selectedTheme }: Props) => {
  const { id, label, href, disabled = false, isNew = false, subItems = [] } = navItem;
  if (!_.isEmpty(subItems)) {
    return <ExpandableNavItem navItem={navItem} selectedTheme={selectedTheme} />;
  }

  return (
    <div
      data-testid={'navItem'}
      className={`nav-item-wrap nav-item ${navItemIsActive(href) ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      {disabled ? (
        <span id={id} className='nav-link'>
          {label}
          {isNew && <span className='is-new-tag'>New!</span>}
        </span>
      ) : (
        <Link id={id} to={href} className='nav-link'>
          {label}
          {isNew && <span className='is-new-tag'>New!</span>}
        </Link>
      )}
    </div>
  );
};

export default NavItemV2;

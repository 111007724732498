import React, { useState, useEffect, useContext } from 'react';
import ExpandIconLight from '../../assets/icons/ExpandIconLight.svg';
import ExpandIconDark from '../../assets/icons/ExpandIconDark.svg';
import CollapseIconLight from '../../assets/icons/CollapseIconLight.svg';
import CollapseIconDark from '../../assets/icons/CollapseIconDark.svg';
import ThemeContext from '../../context/ThemeContext';
import { ThemeModes } from '../../constants';
import DisabledCollapseLight from '../../assets/icons/DisabledCollapseIconLight.svg';
import DisabledCollapseDark from '../../assets/icons/DisabledCollapseIconDark.svg';
import './ExpandCollapseIcon.scss';

type Props = {
  isExpanded: boolean;
  onClick?: any;
  isDisabled?: boolean;
};

const ExpandCollapseIcon = ({ isExpanded, onClick = () => {}, isDisabled = false }: Props) => {
  const { selectedTheme } = useContext(ThemeContext);
  const [icon, setIcon] = useState<any>();

  const setExpandCollapsedIcon = () => {
    if (isDisabled) {
      return selectedTheme.toLocaleLowerCase() === ThemeModes.DARK.toLocaleLowerCase()
        ? DisabledCollapseDark
        : DisabledCollapseLight;
    }
    if (isExpanded) {
      return selectedTheme.toLocaleLowerCase() === ThemeModes.DARK.toLocaleLowerCase()
        ? CollapseIconDark
        : CollapseIconLight;
    }
    return selectedTheme.toLocaleLowerCase() === ThemeModes.DARK.toLocaleLowerCase()
      ? ExpandIconDark
      : ExpandIconLight;
  };

  useEffect(() => {
    const expandCollapseIcon = setExpandCollapsedIcon();
    setIcon(expandCollapseIcon);
  }, [selectedTheme, isExpanded]);

  const onIconClick = () => {
    if (isDisabled) {
      return;
    }
    onClick();
  };

  return (
    <>
      <img src={icon} alt='expand-collapse-icon' onClick={onIconClick} />
    </>
  );
};

export default ExpandCollapseIcon;

import React from 'react';
import TakedownInProgress from '../../../Ugc/Components/Findings/TakedownInProgress';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  MARKET_PLACE_TABLE_NAME,
  MARKET_PLACE_PATHNAME,
} from '../../constants';

const MarketplaceTakedownInProgress = () => {
  return (
    <TakedownInProgress
      type={ugcType.Marketplace}
      tableId={MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID}
      urlColPathName={MARKET_PLACE_PATHNAME}
      tableName={MARKET_PLACE_TABLE_NAME}
      enableSocInterface
    />
  );
};

export default MarketplaceTakedownInProgress;

import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  DashBoardWidgetConfigDto,
  IRunByClientAppType,
  appConstants,
  featureIsAvailable,
} from '../../../../constants';
import { IDashboardWidgetsSection } from '../../Types/dashboard.types';
import { ReplaceButton } from '../../../Dashboard/dashboard.replace.button';
import { DashboardCategory } from './DashboardCategory';
import { DashboardDetection } from './DashboardDetection';
import { DashboardHosting } from './DashboardHosting';
import { DashboardTld } from './DashboardTld';
import { DashboardRegion } from './DashboardRegion';
import DashboardPriority from './DashboardPriority';
import DashboardAcquisition from './DashboardAcquisItion';
import { Nullable } from '../../../../types/common';
import { PriorityWidgetDataContextProvider } from '../../../../context/PriorityWidgetDataContext';

const WidgetRow = (
  row: DashBoardWidgetConfigDto[],
  index: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
  isEditingMode: boolean,
  monitoredDomainsForAcquisition: any,
  enableCommunity: boolean,
  activeOUId?: Nullable<number>,
) => {
  const getDom = (widget: DashBoardWidgetConfigDto) => {
    const name = widget !== undefined && widget.name ? widget.name.split('_')[0] : '';
    if (name === 'detection') {
      return <DashboardDetection startDate={startDate} endDate={endDate} activeOUId={activeOUId} />;
    } else if (name === 'hosting') {
      return <DashboardHosting startDate={startDate} endDate={endDate} activeOUId={activeOUId} />;
    } else if (name === 'ip') {
      // return <DashboardIp brandType={brandType} startDate={startDate} endDate={endDate} />;
    } else if (name === 'tld') {
      return <DashboardTld startDate={startDate} endDate={endDate} activeOUId={activeOUId} />;
    } else if (name === 'region') {
      return <DashboardRegion activeOUId={activeOUId} />;
    } else if (name === 'brands') {
      // return <DashboardSources startDate={startDate} endDate={endDate} />;
    } else if (name === 'sources') {
      // return <DashboardSources startDate={startDate} endDate={endDate} />;
    } else if (name === 'priority') {
      return (
        <PriorityWidgetDataContextProvider
          enableCommunity={enableCommunity}
          activeOUId={activeOUId}
        >
          <DashboardPriority enableCommunity={enableCommunity} />
        </PriorityWidgetDataContextProvider>
      );
    } else if (name === 'acq') {
      return (
        <PriorityWidgetDataContextProvider
          enableCommunity={enableCommunity}
          activeOUId={activeOUId}
        >
          <DashboardAcquisition
            enableCommunity={enableCommunity}
            monitoredDomainsForAcquisition={monitoredDomainsForAcquisition}
          />
        </PriorityWidgetDataContextProvider>
      );
    } else if (name === 'category') {
      return (
        <DashboardCategory
          type={widget.name.split('_')[1]}
          startDate={startDate}
          endDate={endDate}
          activeOUId={activeOUId}
        />
      );
    }
  };
  return (
    <>
      {row[0].oneRow ? (
        <Card className={'widgets-container'} key={'widget section ' + row[0].name}>
          <div key={'widget-left'} className='widget-item-component-v2'>
            <div className='widget-item-inner'>
              <ReplaceButton
                shown={isEditingMode}
                onClick={() => {
                  //TODO
                }}
              />
              {getDom(row[0])}
            </div>
          </div>
        </Card>
      ) : (
        <Card className={'widgets-container'} key={'widget section multiple' + index}>
          {row.length === 1 &&
            row.map((widget, index) => {
              return (
                <div key={`widget-${index + 1}`} className='widget-item-component'>
                  <div className='widget-item-inner' style={{ width: '50%' }}>
                    <ReplaceButton
                      shown={isEditingMode}
                      onClick={() => {
                        //TODO
                      }}
                    />
                    {getDom(widget)}
                  </div>
                </div>
              );
            })}
          {row.length > 1 &&
            row.map((widget, index) => {
              return (
                <div key={`widget-${index + 1}`} className='widget-item-component'>
                  <div className='widget-item-inner'>
                    <ReplaceButton
                      shown={isEditingMode}
                      onClick={() => {
                        //TODO
                      }}
                    />
                    {getDom(widget)}
                  </div>
                </div>
              );
            })}
        </Card>
      )}
    </>
  );
};

const WidgetsSection = ({
  user,
  startDate,
  endDate,
  isEditingMode,
  enableCommunity,
  monitoredDomainsForAcquisitions,
  activeOUId,
  isDemo = false,
}: IDashboardWidgetsSection) => {
  //TODO: Bon: widgets will be from selectedDashboard instead of hard coding.
  const widgets: DashBoardWidgetConfigDto[] = [];
  if (enableCommunity) {
    widgets.push({
      name: 'acq_1243', // TODO: what position?
      widgetType: 'monitoring',
      idx: 1234,
      oneRow: true,
    });
  } else {
    if (isDemo || featureIsAvailable(user, appConstants.FEATURE_CODE.WEB)) {
      widgets.push({
        name: 'detection_1243', // TODO: Bon: Upper tier user, and left column of row #3
        widgetType: 'chart',
        idx: 1243,
      });
      widgets.push({
        name: 'hosting_1243', // TODO: Bon: Upper tier user, and right column of row #3
        widgetType: 'chart',
        idx: 1243,
      });
      widgets.push({
        name: 'category_age_1234',
        widgetType: 'chart',
        idx: 1234,
      });
      widgets.push({
        name: 'category_ip_1234',
        widgetType: 'chart',
        idx: 1234,
      });
      widgets.push({
        name: 'category_host_1234',
        widgetType: 'chart',
        idx: 1234,
      });
      widgets.push({
        name: 'category_tld_1234',
        widgetType: 'chart',
        idx: 1234,
      });
      if (!featureIsAvailable(user, appConstants.FEATURE_CODE.NEW_WEB_DASHBOARD)) {
        widgets.push({
          name: 'region_1243', // TODO: Bon: Upper tier user, and right column of row #3
          widgetType: 'chart',
          idx: 1243,
        });

        widgets.push({
          name: 'priority_1243', // TODO: what position?
          widgetType: 'chart',
          idx: 1243,
        });
      }
    }
    if (featureIsAvailable(user, [appConstants.FEATURE_CODE.NEW_WEB_DASHBOARD])) {
      widgets.push({
        name: 'acq_1243', // TODO: what position?
        widgetType: 'monitoring',
        idx: 1234,
        oneRow: true,
      });
      widgets.push({
        name: 'spotfill_1234', // TODO: what position?
        widgetType: 'monitoring',
        idx: 1234,
        oneRow: true,
      });
      widgets.push({
        name: 'region_1243', // TODO: Bon: Upper tier user, and right column of row #3
        widgetType: 'chart',
        idx: 1243,
        oneRow: false,
      });
    }
  }
  const widgetDoms = [];

  for (let i = 0; i < widgets.length; i = i + 2) {
    const row = [];
    if (widgets[i].oneRow) {
      row.push(widgets[i]);
    } else {
      row.push(widgets[i]);
      if (widgets[i + 1] !== undefined) row.push(widgets[i + 1]);
    }
    widgetDoms.push(
      WidgetRow(
        row,
        i,
        startDate,
        endDate,
        isEditingMode,
        monitoredDomainsForAcquisitions,
        enableCommunity,
        activeOUId,
      ),
    );
  }
  return <>{widgetDoms}</>;
};

export { WidgetsSection };

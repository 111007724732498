import {
  Button,
  Menu,
  MenuItem,
  ThemeOptions,
  ThemeProvider,
  createTheme,
} from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import ThemeContext from '../../context/ThemeContext';
import { ThemeModes } from '../../constants';
import DotDotDot from '../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../assets/icons/DotDotDotWhite.svg';
import AddSocDetailModal from './AddSocDetailModal';
import EditSocDetailModal from './EditSocDetailModal';
import DeleteEventModal from './DeleteEventModal';
import { useReadOnlyUser } from '../../basic-hooks/useUserRoles';

interface WebSocActionColRenderProps {
  data?: any;
  eventData?: any;
  fetchData?: () => void;
  actionFrom: ESocActionOrigin;
}

enum ESocActionModalType {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum ESocActionOrigin {
  TABLE = 'table',
  TIMELINE = 'timeline',
}
export default function WebSocActionColRender({
  data,
  actionFrom,
  eventData,
}: WebSocActionColRenderProps) {
  const { selectedTheme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [addDetailModalShown, setAddDetailModalShown] = useState(false);
  const [deleteEventModalShown, setDeleteEventModalShown] = useState(false);
  const [editDetailModalShown, setEditDetailModalShown] = useState(false);

  const open = Boolean(anchorEl);

  const theme: ThemeOptions = createTheme({
    palette: {
      type: selectedTheme === ThemeModes.DARK.toLowerCase() ? 'dark' : 'light',
    },
  });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const actionOptions = [
    {
      label: 'Add Soc Details',
      value: ESocActionModalType.ADD,
    },
  ];

  const updateOptions = [
    {
      label: 'Edit Soc Details',
      value: ESocActionModalType.EDIT,
    },
    {
      label: 'Delete event',
      value: ESocActionModalType.DELETE,
    },
  ];

  const toggleAddDetailModal = useCallback(() => {
    setAddDetailModalShown(!addDetailModalShown);
  }, [addDetailModalShown]);

  const toggleEditDetailModal = useCallback(() => {
    setEditDetailModalShown(!editDetailModalShown);
  }, [editDetailModalShown]);

  const toggleDeleteEventModal = useCallback(() => {
    setDeleteEventModalShown(!deleteEventModalShown);
  }, [deleteEventModalShown]);

  const onAction = useCallback(
    (action: string) => {
      switch (action) {
        case ESocActionModalType.ADD:
          toggleAddDetailModal();
          break;
        case ESocActionModalType.EDIT:
          toggleEditDetailModal();
          break;
        case ESocActionModalType.DELETE:
          toggleDeleteEventModal();
          break;
        default:
          break;
      }
    },
    [toggleAddDetailModal, toggleEditDetailModal, toggleDeleteEventModal],
  );

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) {
    return null;
  }

  return (
    <>
      <Button
        id='basic-button-soc-actions'
        aria-controls={open ? 'basic-menu-soc-actions' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid='basic-button-soc-actions'
      >
        <img
          src={selectedTheme === ThemeModes.DARK.toLowerCase() ? DotDotDotWhiteIcon : DotDotDot}
          alt={'dotdotdot'}
        />{' '}
      </Button>
      <ThemeProvider theme={theme}>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {actionFrom === ESocActionOrigin.TABLE &&
            actionOptions.map((actionOption, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onAction(actionOption.value);
                  }}
                >
                  {actionOption.label}
                </MenuItem>
              );
            })}
          {actionFrom === ESocActionOrigin.TIMELINE &&
            updateOptions.map((updateOption, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onAction(updateOption.value);
                  }}
                >
                  {updateOption.label}
                </MenuItem>
              );
            })}
        </Menu>
      </ThemeProvider>

      {addDetailModalShown && (
        <AddSocDetailModal show={addDetailModalShown} onCancel={toggleAddDetailModal} data={data} />
      )}

      {editDetailModalShown && (
        <EditSocDetailModal
          show={editDetailModalShown}
          onCancel={toggleEditDetailModal}
          eventData={eventData}
        />
      )}

      {deleteEventModalShown && (
        <DeleteEventModal
          show={deleteEventModalShown}
          onCancel={toggleDeleteEventModal}
          eventData={eventData}
        />
      )}
    </>
  );
}

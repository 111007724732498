import { DashBoardConfigDto, dashboardConstants, generateWidgetId, BrandType } from '../constants';
import { DASHBOARD_TYPE_TABLE_CONFIG } from '../components/Common/Table/table.context';
import { alertActions } from '.';
import { Dispatch } from 'redux';
import DashboardService from '../services/dashboard.service';
import _ from 'lodash';
import { INavSubItem } from '../components/Drawer/AppWrapper';
import { initialDashboardConfig } from '../reducers/dashboard.reducer';
import {
  FINDINGS_TABLE_IDS,
  IMatFetchOptions,
  MALICIOUS_TABLE_IDS,
} from '../components/MonitorAndTakedown/constants';
import { EventHistoryEntity } from '../types/event-history-entity.interface';
import { CustomerComment, CustomerSocResponse } from '../types/url-event.types';
import { userActions } from '.';
import moment from 'moment';
import { WebUrlData } from '../types/web-url-data.interface';
import { IFindingsTableItemProps } from '../components/Ugc/Types/ugc.types';

const dashboardService = new DashboardService();

const SET_DEFAULT_DASHBOARD_CONFIG = (type: string) => {
  const momentNow = generateWidgetId();
  let widgets = [
    {
      index: momentNow,
      idx: momentNow,
      name: 'monitoring_' + momentNow,
      widgetType: 'monitoring',
      type: 'monitoring',
    },
    {
      index: momentNow,
      idx: momentNow,
      name: 'table_' + momentNow,
      widgetType: 'table',
      type: 'table',
    },
  ];
  if (type === 'non_brand') {
    widgets = widgets.concat([
      {
        index: momentNow,
        idx: momentNow,
        name: 'countries_' + momentNow,
        widgetType: 'chart',
        type: 'chart',
      },
      {
        index: momentNow + 1,
        idx: momentNow + 1,
        name: 'brands_' + (momentNow + 1),
        widgetType: 'chart',
        type: 'chart',
      },
    ]);
  } else {
    widgets = widgets.concat([
      {
        index: momentNow,
        idx: momentNow,
        name: 'detection_' + momentNow,
        widgetType: 'chart',
        type: 'chart',
      },
      {
        index: momentNow + 1,
        idx: momentNow + 1,
        name: 'hosting_' + (momentNow + 1),
        widgetType: 'chart',
        type: 'chart',
      },
    ]);
  }

  return {
    dashboard: {
      name: initialDashboardConfig[0].dashboardName,
      isDefault: true,
      dashboardType: type,
      widgets,
    },
  };
};

const getUserInfo = () => {
  return (dispatch: Dispatch): any => {
    return dashboardService.getUserInfo().then(
      (data: any) => {
        dispatch(success(data));
      },
      (error: any) => {
        if (error == 'Invalid authorization token') {
          userActions.logout();
        }
        dispatch(failure(error));
      },
    );
  };

  function success(data: any) {
    return { type: dashboardConstants.GET_USER_INFO_SUCCESS, user: data };
  }

  function failure(error: any) {
    return { type: dashboardConstants.GET_USER_INFO_FAILURE, error: error };
  }
};

const getBrandInfo = (brandType: BrandType) => {
  return (dispatch: Dispatch): any => {
    return dashboardService.getBrandInfo(brandType).then((res: any) => {
      dispatch({
        type: dashboardConstants.GET_BRAND_INFO_SUCCESS,
        payload: res,
      });
    });
  };
};

const getDashboardConfig = (name?: string, cb?: () => void) => {
  return (dispatch: Dispatch): any => {
    const dashboardService = new DashboardService();
    Promise.all([dashboardService.getDashboardConfig(), dashboardService.getUserInfo()])
      .then(([data, userInfo]) => {
        // save the default dashboard config at user first time log in.
        const type = userInfo.type_name;
        const createDashboards = [];
        const dashboardConfigs = _.filter(data, dashboardConfig => {
          return dashboardConfig.dashboardType !== DASHBOARD_TYPE_TABLE_CONFIG;
        });
        if (_.isEmpty(dashboardConfigs)) {
          createDashboards.push(
            dashboardService.saveDashboardConfig(SET_DEFAULT_DASHBOARD_CONFIG(type)),
          );
        }

        if (createDashboards.length) {
          Promise.all(createDashboards).then(() => {
            setTimeout(() => {
              dashboardService.getDashboardConfig().then((data: any) => {
                dispatch(success(data, name));
              });
            }, 500);
          });
        } else {
          const nonMatchDashboards: string[] = [];
          _.forEach(data, dashboard => {
            if (
              type !== 'hybrid' &&
              type !== dashboard.dashboardType &&
              dashboard.dashboardType !== DASHBOARD_TYPE_TABLE_CONFIG
            ) {
              nonMatchDashboards.push(dashboard.dashboardName);
            }
          });
          if (nonMatchDashboards.length) {
            Promise.all(
              _.map(nonMatchDashboards, dasshboardName => {
                return dashboardService.deleteDashboardConfig(dasshboardName);
              }),
            ).then(() => {
              dashboardService.getDashboardConfig().then((data: any) => {
                dispatch(success(data, name));
              });
            });
          } else {
            dispatch(success(data, name));
          }
        }
      })
      .catch((error: any) => {
        dispatch(failure(error));
      })
      .finally(() => {
        //callback to make sure api call's successufully completed and Doesnt block the execution
        if (cb) cb();
      });
  };

  function success(data: any, name?: string) {
    return {
      type: dashboardConstants.GET_DASHBOARD_CONFIG_SUCCESS,
      dashboardConfigData: data,
      selectedDashboardName: name,
    };
  }

  function failure(error: any) {
    return { type: dashboardConstants.GET_DASHBOARD_CONFIG_FAILURE, error: error };
  }
};

const createUser = (user: object) => {
  return (dispatch: Dispatch): any => {
    dashboardService.addUser(user).then(
      (data: any) => {
        dispatch(success());
        dispatch(alertActions.success('Add new user successfully!'));
      },
      (error: any) => {
        dispatch(failure(_.get(error, ['User'], 'Input data validation failed')));
        dispatch(alertActions.error(_.get(error, ['User'], 'Input data validation failed')));
      },
    );
  };

  function success() {
    return { type: dashboardConstants.ADD_USER_SUCCESS };
  }

  function failure(error: string) {
    return { type: dashboardConstants.ADD_USER_FAILURE, error };
  }
};

const onDashboardSelected = (selectedDashboard: INavSubItem) => {
  return (dispatch: Dispatch): any => {
    dispatch({ type: dashboardConstants.ON_DASHBOARD_SELECTED, payload: { selectedDashboard } });
  };
};

const onNewDashboard = () => {
  return (dispatch: Dispatch): any => {
    dispatch({ type: dashboardConstants.ON_NEW_DASHBOARD });
  };
};

const onNewDashboardV2 = () => {
  return (dispatch: Dispatch): any => {
    dispatch({ type: dashboardConstants.ON_NEW_DASHBOARD });
  };
};

const onSaveDashboard = (data: any) => {
  return (dispatch: Dispatch): any => {
    return dashboardService.saveDashboardConfig(data).catch((error: any) => {
      dispatch(alertActions.error(error.toString()));
    });
  };
};

const onSetDefaultDashboard = (
  selectedDashboard: DashBoardConfigDto,
  currentDefaultDashboard: DashBoardConfigDto | undefined,
) => {
  return (dispatch: Dispatch): any => {
    return Promise.all([
      dashboardService.saveDashboardConfig({
        dashboard: { ...selectedDashboard, isDefault: true },
      }),
      currentDefaultDashboard &&
        dashboardService.saveDashboardConfig({
          dashboard: { ...currentDefaultDashboard, isDefault: false },
        }),
    ]).then(() => {
      dispatch({
        type: dashboardConstants.ON_SET_DEFAULT_DASHBOARD,
        payload: { selectedDashboard },
      });
    });
  };
};

const onChangeDashboard = (selectedDashboard: DashBoardConfigDto) => {
  return (dispatch: Dispatch): any => {
    dispatch({ type: dashboardConstants.ON_CHANGE_DASHBOARD, payload: { selectedDashboard } });
  };
};

const onDeleteDashboard = (selectedDashboard: DashBoardConfigDto) => {
  return (dispatch: Dispatch): any => {
    return dashboardService.deleteDashboardConfig(selectedDashboard.label);
  };
};

const onDeleteWidgets = (data: any) => {
  return (dispatch: Dispatch): any => {
    return dashboardService.deleteWidgets(data);
  };
};

const getUsersList = () => {
  return (dispatch: Dispatch): any => {
    return dashboardService.getUserList().then((data: any) => {
      dispatch({ type: dashboardConstants.GET_USER_LIST_SUCCESS, payload: data });
    });
  };
};

const setCurrentFetchOptions = (options: IMatFetchOptions) => {
  return (dispatch: Dispatch): void => {
    dispatch({ type: dashboardConstants.SET_CURRENT_QUERY, payload: options });
  };
};

const timelineUpdated = () => {
  return (dispatch: Dispatch): void => {
    dispatch({ type: dashboardConstants.TIMELINE_UPDATED, payload: moment().toISOString() });
  };
};

const setSelectedWebUrls = (tableId: MALICIOUS_TABLE_IDS, selected: WebUrlData[]) => {
  return (dispatch: Dispatch): void => {
    dispatch({ type: dashboardConstants.SET_SELECTED_WEB_URLS, payload: { tableId, selected } });
  };
};
const setFindingsTableUrls = (
  tableId: FINDINGS_TABLE_IDS,
  selected: IFindingsTableItemProps[],
  type: string,
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: dashboardConstants.SET_FINDINGS_TABLES_URLS,
      payload: { tableId, selected, type },
    });
  };
};

const setTyposquatDataPresent = (typosquatJobStatus: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: dashboardConstants.SET_TYPOSQUAT_DATA,
      payload: typosquatJobStatus,
    });
  };
};

export const dashboardActions = {
  getUserInfo,
  getBrandInfo,
  getDashboardConfig,
  createUser,
  onDashboardSelected,
  onNewDashboard,
  onSetDefaultDashboard,
  onDeleteDashboard,
  onChangeDashboard,
  onSaveDashboard,
  onDeleteWidgets,
  onNewDashboardV2,
  getUsersList,
  setCurrentFetchOptions,
  timelineUpdated,
  setSelectedWebUrls,
  setFindingsTableUrls,
  setTyposquatDataPresent,
};

import React from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { massagedMonthlyEvents } from './GlobalTakeDownUtils';
import { setCurrentSelectedMonth, setGlobalTakedownRowData } from '../../reducers/table.reducer';

export default function useOnYearlyLayoutChange(rowId: string) {
  const globalTakedownRowData = useAppSelector(state => state.tableReducer.globalTakedownRowData);

  //TODO conver this into hook
  const dispatch = useAppDispatch();
  const onYearlyLayoutChange = (layout: 'monthly' | 'yearly', currentMonth: string) => {
    const currentMonthEvents = globalTakedownRowData[rowId]?.data?.events?.[currentMonth];
    const monthlyData = massagedMonthlyEvents(currentMonthEvents);
    dispatch(
      setGlobalTakedownRowData({
        ...globalTakedownRowData,
        [rowId]: {
          data: monthlyData,
          viewMode: layout,
          prevMode: 'yearly',
        },
      }),
    );
    dispatch(setCurrentSelectedMonth(currentMonth));
  };
  return { onYearlyLayoutChange };
}

import React, { useEffect, useState } from 'react';
import DashboardService from '../../services/dashboard.service';
import './soc-takedown.scss';
import { Typeahead } from 'react-bootstrap-typeahead';
import { PlusIcon } from '../../assets/SVGIcons';

export interface ISocTakedownAddressBookProps {
  emailSelected: (email: string) => void;
  selectionBasedArray?: string[];
}
const dashboardService = new DashboardService();

function SocTakedownAddressBook({
  selectionBasedArray,
  emailSelected,
}: ISocTakedownAddressBookProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermContacts, setSearchTermContacts] = useState<{ label: string; value: string }[]>(
    [],
  );

  function onSearchChange(updatedSearchTerm: string) {
    setSearchTerm(updatedSearchTerm);
    dashboardService.searchAbuseContact(updatedSearchTerm).then(res => {
      if (res.result) {
        const contactList = res.result.map(item => ({
          label: item,
          value: item,
        }));
        setSearchTermContacts(contactList);
      }
    });
  }

  return (
    <div className={'address-book-container pl-4 pr-2'}>
      <div className={'mb-4'}>
        <div className={'label'}>Based On Selection</div>
        <div className={'selection-based-container'}>
          {selectionBasedArray ? (
            selectionBasedArray.map((item, key: number) => {
              const firstSplit = item.split('@');
              const secondSplit = firstSplit[1].split('.');
              return (
                <div key={key} className={'selection-boxes'}>
                  <div className={'title-email-container'}>
                    <div className={'selection-title text-capitalize'}> {secondSplit[0]}</div>
                    <div className='selection-name'>{item}</div>
                  </div>
                  <div onClick={() => emailSelected(item)}>
                    <PlusIcon />
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className={'form-block'}>
        <Typeahead
          className='address-book-search-container'
          id={'abuse-contact-selector'}
          placeholder={'Search All Contacts'}
          options={searchTermContacts}
          onChange={selected => {
            if (selected[0]) {
              emailSelected(selected[0].value);
            }
          }}
          onInputChange={(text: string) => {
            onSearchChange(text);
          }}
        />
      </div>
    </div>
  );
}

export default SocTakedownAddressBook;

import React from 'react';
import TakenDown from '../../../Ugc/Components/Findings/TakenDown';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME,
  SOCIAL_MEDIA_PATHNAME,
  SOCIAL_MEDIA_TABLE_NAME,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
} from '../../constants';

const SocialMediaTakenDown = () => {
  return (
    <TakenDown
      type={ugcType.Social}
      tableId={SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID}
      urlColPathName={SOCIAL_MEDIA_PATHNAME}
      tableName={SOCIAL_MEDIA_TABLE_NAME}
    />
  );
};

export default SocialMediaTakenDown;

import React from 'react';
import _ from 'lodash';
import ViewPasswordOnIcon from '../../assets/icons/ViewPasswordOn.svg';
import ViewPasswordOffIcon from '../../assets/icons/ViewPasswordOff.svg';
import './PasswordEye.scss';

export const PASSWORD_LENGTH = 8;
export const PASSWORD_LIMIT = 64;

interface IComponentProp {
  label: string;
  name: string;
  isError: boolean;
  onChange: (value: any) => void;
  helpText?: string;
}

interface IComponentState {
  password: string;
  showPassword: boolean;
}

class PasswordEye extends React.Component<IComponentProp, IComponentState> {
  static defaultProps = {
    isError: false,
    onChange: _.noop,
  };

  constructor(props: IComponentProp) {
    super(props);
    this.state = {
      showPassword: false,
      password: '',
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    this.setState({
      password: value,
    });

    this.props.onChange(event);
  };

  handleClickShowPassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword(event: MouseEvent) {
    event.preventDefault();
  }

  render() {
    const {
      label,
      name,
      isError,
      helpText = 'Password has to be ' +
        PASSWORD_LENGTH +
        ' letters or more and less than ' +
        PASSWORD_LIMIT +
        ' letters',
    } = this.props;
    const { showPassword, password } = this.state;
    let inputClassName = 'form-control m-0';
    if (isError) {
      inputClassName += ' error';
    }

    return (
      <>
        <div className={'password-eye-component form-group'}>
          <label className={'password-label'}> {label}</label>
          <input
            type={showPassword ? 'text' : 'password'}
            className={inputClassName}
            placeholder={label}
            name={name}
            value={password}
            onChange={this.handleChange}
            minLength={PASSWORD_LENGTH}
          />
          <span className={'password-trigger'} onClick={this.handleClickShowPassword}>
            {!showPassword ? (
              <img src={ViewPasswordOnIcon} alt={'on'} />
            ) : (
              <img src={ViewPasswordOffIcon} alt={'off'} />
            )}
          </span>
        </div>
        <div className='nav-text'>{helpText}</div>
      </>
    );
  }
}

export { PasswordEye };

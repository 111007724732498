import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import './AttachedFileViewer.scss';
import File from '../../assets/icons/File.svg';
import { Modal, ListGroup, Carousel } from 'react-bootstrap';
import { useState } from 'react';
import { AttachedFileObject } from './DisputeStatus';
import DownloadFileIcon from '../../assets/icons/DownloadFile.svg';
import CloseWhiteLargerIcon from '../../assets/icons/CloseWhiteLarger.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import ChevronLeftIcon from '../../assets/icons/ChevronLeft.svg';
import ChevronRightIcon from '../../assets/icons/ChevronRight.svg';
import { Interweave } from 'interweave';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface Props {
  attachedFiles: AttachedFileObject[];
  horizontal?: boolean;
  handleFileRemove?: (e: any, ix: number, file: AttachedFileObject) => void;
  theme?: 'sm' | undefined;
  onItemClick?: any;
}

const getExtension = (fileName: string) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
};

const isEml = (fileName: string) => {
  return getExtension(fileName).toLocaleLowerCase() === 'eml';
};
const isText = (fileName: string) => {
  return getExtension(fileName).toLocaleLowerCase() === 'txt';
};
const AttachedFileViewer: React.FC<Props> = ({
  attachedFiles,
  horizontal,
  handleFileRemove,
  theme,
  onItemClick,
}) => {
  const [fileViewerShow, setFileViewerShow] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [textContent, setTextContent] = useState({});

  const handleListItemClick = (e: any) => {
    setFileViewerShow(true);
    setCurrentFileIndex(+e.currentTarget.dataset.index);
    if (onItemClick) {
      onItemClick(e);
    }
  };

  const handleCarouselSelect = (index: number) => {
    setCurrentFileIndex(index);
  };

  const handleHideModal = () => {
    setFileViewerShow(false);
  };

  const previousSlide = () => {
    let newFileIndex = currentFileIndex - 1;
    if (newFileIndex < 0) {
      newFileIndex = attachedFiles.length - 1;
    }
    setCurrentFileIndex(newFileIndex);
  };

  const nextSlide = () => {
    let newFileIndex = currentFileIndex + 1;
    if (newFileIndex >= attachedFiles.length) {
      newFileIndex = 0;
    }
    setCurrentFileIndex(newFileIndex);
  };

  const selectSlide = (e: any) => {
    setCurrentFileIndex(parseInt(e.currentTarget.dataset.index, 10));
  };

  useEffect(() => {
    const currentTextResp = {};
    attachedFiles.map((file: any, indx: number) => {
      if (isText(file.path)) {
        getTextFileResponse(file.path, indx, currentTextResp);
      }
    });
    setTextContent(currentTextResp);
  }, [attachedFiles]);

  const getTextFileResponse = async (fileUrl: string, idx: number, textResp: any) => {
    await fetch(fileUrl)
      .then(res => {
        return res.text();
      })
      .then(resp => {
        textResp[idx] = resp;
      })
      .catch(error => {
        console.log(error);
      });
  };

  const renderTextPreview = () => {
    return (
      <div className={'d-flex flex-column w-100 h-100'}>
        <span className='fix-loader'></span>
        <span className='fix-loader'></span>
        <span className='fix-loader-65'></span>
        <span className='fix-loader-65'></span>
        <span className='fix-loader-65'></span>
        <span className='fix-loader-big'></span>
        <span className='fix-loader-half'></span>
        <span className='fix-loader-half'></span>
        <span className='fix-loader'></span>
        <span className='fix-loader'></span>
      </div>
    );
  };
  return (
    <div className={'attached-file-viewer-container ' + (theme ? theme : '')}>
      <ListGroup horizontal={horizontal}>
        {attachedFiles?.map((file: AttachedFileObject, i: number) => (
          <ListGroup.Item key={file.name + i}>
            <div className='d-flex align-items-center cursor-pointer'>
              <img
                src={File}
                alt={'attached file'}
                className={'file-img mr-3'}
                data-index={i}
                onClick={handleListItemClick}
              />
              <OverlayTrigger
                placement={'bottom-start'}
                overlay={<Tooltip id={file.name + i}>{file.name}</Tooltip>}
              >
                <div className='attached-file-name' data-index={i} onClick={handleListItemClick}>
                  {file.name}
                </div>
              </OverlayTrigger>
            </div>
            {handleFileRemove && (
              <img
                src={CloseIcon}
                className={'cursor-pointer mr-0 ' + (theme === 'sm' ? '' : 'ml-2')}
                alt={'remove'}
                data-file={file.name}
                data-file-index={i}
                onClick={e => handleFileRemove(e, i, file)}
              />
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>

      {attachedFiles?.[currentFileIndex] && (
        <Modal
          size='xl'
          show={fileViewerShow}
          animation={false}
          className='attached-file-viewer-modal'
        >
          <div className={'image-slider d-flex flex-column align-items-stretch'}>
            <div className={'image-slider-header d-flex justify-content-between'}>
              <div className={'image-slider-header-left d-flex align-items-center'}>
                <div className={'file-name'}>{attachedFiles[currentFileIndex].name}</div>
                <div className={'created-ts'}>
                  {' '}
                  {!_.isEmpty(attachedFiles[currentFileIndex].createdTS) &&
                    moment(attachedFiles[currentFileIndex].createdTS).format(
                      'DD-MMM-YYYY, hh:mm a',
                    )}
                </div>
              </div>
              <div className={'image-slider-header-right'}>
                <a
                  id='download_dom'
                  href={attachedFiles[currentFileIndex].path}
                  download={attachedFiles[currentFileIndex].name}
                >
                  <img src={DownloadFileIcon} alt={'download file'} />
                </a>
                <img src={CloseWhiteLargerIcon} alt={'close'} onClick={handleHideModal} />
              </div>
            </div>
            <div className={'image-slider-body d-flex align-items-center justify-content-between'}>
              <img
                src={ChevronLeftIcon}
                className={'cursor-pointer'}
                alt={'ChevronLeftIcon'}
                onClick={previousSlide}
              />
              <Carousel
                className='attached-file-viewer-carousel'
                controls={false}
                indicators={false}
                interval={null}
                activeIndex={currentFileIndex}
                onSelect={handleCarouselSelect}
              >
                {attachedFiles.map((file: any, i) => (
                  <Carousel.Item key={file.name + i}>
                    <>
                      {isEml(file.name) ? (
                        <div
                          className={'w-100 h-100 d-flex align-items-center justify-content-center'}
                        >
                          <div>Preview is not available</div>
                        </div>
                      ) : isText(file.name) ? (
                        <div className={'w-100 h-100 d-flex justify-content-center pt-3'}>
                          <Interweave content={textContent[i]} />
                        </div>
                      ) : (
                        <div
                          className={'w-100 h-100 d-flex align-items-center justify-content-center'}
                        >
                          <img className='img-slide h-100' src={file.path} alt={file.name} />
                        </div>
                      )}
                    </>
                  </Carousel.Item>
                ))}
              </Carousel>
              <img
                src={ChevronRightIcon}
                className={'cursor-pointer'}
                alt={'ChevronRightIcon'}
                onClick={nextSlide}
              />
            </div>
            <div className={'image-slider-footer scrollbar-hidden align-self-center'}>
              {[...attachedFiles].map((file, index) => (
                <div
                  key={file.name + index}
                  className={'image-thumbnail ' + (index === currentFileIndex ? 'selected' : '')}
                >
                  {isEml(file.name) ? (
                    <div
                      className={'p-2 flex-center h-100 w-100 text-center'}
                      key={file.name}
                      data-index={index}
                      onClick={selectSlide}
                    >
                      Preview is not available
                    </div>
                  ) : isText(file.name) ? (
                    renderTextPreview()
                  ) : (
                    <img
                      key={file.name}
                      src={file.path}
                      alt={file.name}
                      data-index={index}
                      onClick={selectSlide}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AttachedFileViewer;

import React, { useEffect, useMemo } from 'react';
import { IPricingPlan, ISubsciptionPlan, PlanTerm } from './types';
import { EPlanProduct } from '../Common/Subscription';
import { useContext } from 'react';
import {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import PlanChip from './Common/PlanChip';
import { PRICING_PLANS } from './types';
import { Switch } from '@material-ui/core';
import { useState } from 'react';
import _ from 'lodash';
import LiveScanPlansSelector from './Common/LiveScanPlansSelector';
import './pricing.scss';
import { CheckoutFormV2 } from '../Common/UpgradeStripe';
import { IChosenPlan } from '../Plans/PlanCards';
import { Elements } from '@stripe/react-stripe-js';
import { LockIcon } from '../../assets/SVGIcons';

// import { gray } from 'd3';
import { generatePrice, getDiscountValue } from './helpers';
import ThemeContext from '../../context/ThemeContext';

export interface CheckoutProps {
  liveScanBundles: any[];
  liveScanPlan: IPricingPlan;
  addPlanToCart: any;
  stripePromise: any;
  paymentSuccessCallBack: any;
}

const Checkout = ({
  liveScanBundles,
  liveScanPlan,
  addPlanToCart,
  stripePromise,
  paymentSuccessCallBack,
}: CheckoutProps) => {
  const { selectedTerm, selectedPlans } = useContext<ICheckPhishSubscription>(
    CheckphishSubscriptionContext,
  );
  const [showAddOnWidget, setShowAddOnWidget] = useState<boolean>(true);
  const [selectedPlansForStripe, setSelectedPlansForStripe] = useState<IChosenPlan[]>([]);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    const plans = selectedPlans.filter((item: ISubsciptionPlan) => {
      if (!showAddOnWidget) {
        return item.product !== EPlanProduct.LIVE_SCAN;
      }
      return item;
    });
    const selectedStripePlans: IChosenPlan[] = plans.map((item: ISubsciptionPlan) => ({
      id: item.priceId,
      price: item.price,
      limit: item.limit,
      term: item.term,
      quantity: item.quantity,
    }));

    setSelectedPlansForStripe(selectedStripePlans);
  }, [selectedPlans, showAddOnWidget]);

  const [selectedTyposquatPlan, selectedLiveScanPlan] = useMemo(() => {
    const typosquatPlan = selectedPlans.find(
      (planItem: ISubsciptionPlan) =>
        planItem.product === EPlanProduct.TYPOSQUATTING && planItem.term === selectedTerm.value,
    );
    const liveScanPlan = !showAddOnWidget
      ? undefined
      : selectedPlans.find(
          (planItem: ISubsciptionPlan) =>
            planItem.product === EPlanProduct.LIVE_SCAN && planItem.term === selectedTerm.value,
        );
    return [typosquatPlan, liveScanPlan];
  }, [selectedPlans, selectedTerm, showAddOnWidget]);

  useEffect(() => {
    setShowAddOnWidget(!_.isEmpty(selectedLiveScanPlan));
  }, []);

  const onSliderChange = (sliderIndex: number) => {
    addPlanToCart(liveScanPlan, liveScanBundles[sliderIndex - 1]);
  };

  const [starterPlanTotal, typosquatPlanTotal] = useMemo(() => {
    let starterTotal = 0;
    let typoTotal = 0;
    if (selectedTyposquatPlan) {
      typoTotal = generatePrice(selectedTyposquatPlan, selectedTyposquatPlan.quantity);
    }
    if (selectedLiveScanPlan) {
      starterTotal = generatePrice(selectedLiveScanPlan, selectedLiveScanPlan.quantity);
    }
    return [starterTotal, typoTotal];
  }, [selectedLiveScanPlan, selectedTyposquatPlan]);

  return (
    <div className='checkout-wrap' data-testid='checkout-wrap'>
      <div
        className={`profession-api-widget-wrap ${showAddOnWidget ? 'highlight' : ''}`}
        data-testid='live-scan-selector'
      >
        <div className='widget-header'>
          <PlanChip
            planName={PRICING_PLANS.PROGESSIONAL_API}
            isTransparent={false}
            planChipText={'Add-on'}
          />
          <Switch
            checked={showAddOnWidget}
            onChange={() => {
              setShowAddOnWidget(oldState => !oldState);
            }}
            className='professional-api-switch'
            data-testid='professional-api-toggle'
          />
        </div>
        <h4>Professional API</h4>
        {showAddOnWidget && !_.isEmpty(liveScanBundles) && !_.isEmpty(liveScanPlan) && (
          <div className='pricing-wrap checkout-slider'>
            <LiveScanPlansSelector
              liveScanBundles={liveScanBundles}
              liveScanPlan={liveScanPlan}
              onSliderChange={onSliderChange}
            />
          </div>
        )}
      </div>
      <div className='checkout-summary-wrap'>
        <h4>Summary</h4>
        <div className='summary-item'>
          {selectedTyposquatPlan ? (
            <>
              Starter Plan{' '}
              <span className='pricing-data'>
                {!typosquatPlanTotal ? (
                  <span className='pricing-data'>Current Plan</span>
                ) : (
                  `$ ${typosquatPlanTotal} per ${
                    selectedTerm.value === PlanTerm.YEARLY ? 'Year' : 'Month'
                  }`
                )}
              </span>
            </>
          ) : (
            <>
              Free Plan <span className='pricing-data'>Free</span>
            </>
          )}
        </div>
        <div className='summary-item'>
          Professional API{' '}
          {selectedLiveScanPlan ? (
            <span className='pricing-data'>
              {starterPlanTotal
                ? `$ ${starterPlanTotal} per ${selectedTerm.value}`
                : `Current Plan`}
            </span>
          ) : (
            <span className='pricing-data'>Free</span>
          )}
        </div>
        <div className='summary-item'>
          {_.capitalize(selectedTerm.value)} Plan Saving{' '}
          <span className='pricing-data saving-highlight'>
            ${' '}
            {getDiscountValue(selectedLiveScanPlan, selectedLiveScanPlan?.quantity) +
              getDiscountValue(selectedTyposquatPlan, selectedTyposquatPlan?.quantity)}
          </span>
        </div>
        <div className='horizontal-line'></div>
        <div className='summary-item total'>
          SubTotal{' '}
          <span className='subtotal-wrap'>
            <div className='pricing-data large-size'>$ {typosquatPlanTotal + starterPlanTotal}</div>
            <div className='pricing-data sub-heading'>+ Applicable Tax</div>
          </span>
        </div>
      </div>
      {typosquatPlanTotal + starterPlanTotal !== 0 && (
        <div className='checkout-form'>
          <div className='form-outline-header'>
            <h4>Payment Details</h4>
            <span>
              Secure from <LockIcon />
            </span>
          </div>
          <Elements
            stripe={stripePromise}
            options={{
              mode: 'setup',
              currency: 'usd',
              appearance: {},
            }}
          >
            <CheckoutFormV2
              chosenPlans={selectedPlansForStripe}
              setSuccess={setSuccess}
              successCallback={paymentSuccessCallBack}
              checkoutV2={true}
              subTotal={typosquatPlanTotal + starterPlanTotal}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default Checkout;

import React, { useEffect, useState } from 'react';
import './Style/RedirectToMaliciousLink.scss';
import _ from 'lodash';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { history } from '../../helpers';
import DashboardService from '../../services/dashboard.service';
import { IFilter, TypeSortDirection } from '../Common/Table/constant';
import {
  POST_MALICIOUS_PATHNAME,
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_PATHNAME,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_PATHNAME,
  TAKEDOWN_MALICIOUS_TABLE_ID,
  WEB_DASHBOARD_PATHNAME,
} from './constants';
import { DISPLAYED_NULL, generateId } from '../../constants';
import CopyButton, { ICopyButtonProps } from '../Common/CopyButton';

const dashboardService = new DashboardService();

const getPathName = async (urlSha256: string, defaultPathName: string) => {
  const query = {
    startDate: moment().subtract(90, 'day').format('YYYY-MM-DD'),
  };
  const filters: IFilter[] = [
    {
      id: generateId(10),
      filterBy: { label: 'URL Sha', value: 'url_sha256' },
      filterMethod: { label: 'Includes', value: 'includes' },
      filterType: 'string',
      filterValue: urlSha256,
    },
  ];
  const sort = { sortBy: 'created_ts', sortDirection: 'desc' as TypeSortDirection };

  const getPreMalicious = dashboardService.getDataForMonitorAndTakedown(
    { ...query, type: PRE_MALICIOUS_TABLE_ID },
    filters,
    sort,
  );

  const getTakedownMalicious = dashboardService.getDataForMonitorAndTakedown(
    { ...query, type: TAKEDOWN_MALICIOUS_TABLE_ID },
    filters,
    sort,
  );

  const getPostMalicious = dashboardService.getDataForMonitorAndTakedown(
    { ...query, type: POST_MALICIOUS_TABLE_ID },
    filters,
    sort,
  );

  return Promise.all([getPreMalicious, getTakedownMalicious, getPostMalicious]).then(
    async (res: any) => {
      const [preMaliciousRes, takedownMaliciousRes, postMaliciousRes] = res;
      let latestTs = 0;
      let pathName = '';
      const setPathNameOfLastRecord = (data: any, urlPathName: string) => {
        _.forEach(data, (item: any) => {
          const timestamp = parseInt(item.timestamp || 0, 10);
          if (timestamp > latestTs) {
            latestTs = timestamp;
            pathName = urlPathName;
          }
        });
      };
      setPathNameOfLastRecord(
        _.get(preMaliciousRes, ['result', 'urlInfo'], []),
        `${WEB_DASHBOARD_PATHNAME}/insights/`,
      );
      setPathNameOfLastRecord(
        _.get(takedownMaliciousRes, ['result', 'urlInfo'], []),
        `${WEB_DASHBOARD_PATHNAME}/insights/`,
      );
      setPathNameOfLastRecord(
        _.get(postMaliciousRes, ['result', 'urlInfo'], []),
        `${WEB_DASHBOARD_PATHNAME}/insights/`,
      );

      if (!pathName) {
        const nonMaliciousRes = await dashboardService.getDataForMonitorAndTakedown(
          { ...query, type: 'dashboard' },
          filters,
          sort,
        );
        setPathNameOfLastRecord(_.get(nonMaliciousRes, ['result', 'urlInfo'], []), defaultPathName);
      }

      return pathName ? `${pathName}/${latestTs}/${urlSha256}` : '';
    },
  );
};

export interface IComponentProps {
  defaultPathName: string;
  url: string;
  urlSha256: string;
  displayAsInsightPageUrl?: boolean;
  copyButton?: boolean;
  checkPathNameExist: (value: any) => any;
}

const RedirectToMaliciousLink = ({
  url,
  urlSha256,
  defaultPathName,
  displayAsInsightPageUrl = false,
  copyButton = false,
  checkPathNameExist,
}: IComponentProps & ICopyButtonProps) => {
  const [isLoadingInsightPageUrl, setIsLoadingInsightPageUrl] = useState(displayAsInsightPageUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [insightPagePathName, setInsightPagePathName] = useState('');

  const onClick = async () => {
    let redirectPath = insightPagePathName;
    if (!insightPagePathName) {
      setIsLoading(true);
      const pathName = await getPathName(urlSha256, defaultPathName);
      setInsightPagePathName(pathName);
      setIsLoading(false);
      redirectPath = pathName;
    }
    history.push(redirectPath);
  };

  useEffect(() => {
    if (displayAsInsightPageUrl) {
      getPathName(urlSha256, defaultPathName).then(pathName => {
        setInsightPagePathName(pathName);
        checkPathNameExist(pathName);
        setIsLoadingInsightPageUrl(false);
      });
    }
  }, []);

  const displayedPath = displayAsInsightPageUrl
    ? (insightPagePathName && `${window.location.host}${insightPagePathName}`) || DISPLAYED_NULL
    : url;

  return (
    <>
      {isLoadingInsightPageUrl ? (
        <Spinner className='spinner' animation='border' variant='primary' size='sm' />
      ) : displayedPath === DISPLAYED_NULL ? (
        <div className='d-flex align-items-center'>
          <div className='long-text-ellipsis-1 card-value'>{url}</div>
          <CopyButton value={url} copyButton={copyButton} />
        </div>
      ) : (
        <div className='d-flex align-items-center'>
          <OverlayTrigger
            key={urlSha256}
            placement={'top'}
            overlay={
              <Tooltip id={'tooltip-' + displayedPath} className={'table-source-url-tooltip'}>
                {url}
              </Tooltip>
            }
          >
            <div className={'table-source-url-column d-flex align-items-center'}>
              <div onClick={onClick} className='long-text-ellipsis-1 card-value text-link'>
                {url}
              </div>
            </div>
          </OverlayTrigger>
          <CopyButton value={url} copyButton={copyButton} />
        </div>
      )}
      <Modal
        show={isLoading}
        centered
        onHide={() => {
          setIsLoading(true);
        }}
        backdropClassName='redirect-to-malicious-link-loading-backdrop'
        contentClassName='redirect-to-malicious-link-loading-content'
      >
        <div className={'insight-spinner-container'}>
          <Spinner animation='border' />
        </div>
      </Modal>
    </>
  );
};

export default RedirectToMaliciousLink;

import React, { useState, useEffect } from 'react';
import _, { countBy } from 'lodash';
import moment from 'moment';
import {
  IChartPlaceholderProps,
  TColorMap,
  TDonutChartDataProps,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import { colors } from '../../../../constants';
import NoHosting from '../../../../assets/icons/NoHosting.svg';
import UgcService from '../../../../services/ugc.service';
import DonutChart from '../../../Common/DashboardWidgets/DonutChart';
import { ugcType } from '../../Types/ugc.types';
import { socailMediaCategoryWidgetMockData } from '../../../../constants';

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  type: string;
  widgetsTitle: any;
  isCustomCalendarRange: boolean;
}

const ugcService = new UgcService();

const placeholder: IChartPlaceholderProps = {
  icon: NoHosting,
  title: 'No Detection found',
  description:
    'We haven’t detected any phish and scam site hosting for this period. Please select another time frame.',
};
const colorMap: TColorMap = {
  0: colors.category1,
  1: colors.category2,
  2: colors.category3,
  3: colors.category4,
  4: colors.category5,
};

const massageData = (rawData: any): any[] => {
  const result = _.chain(rawData)
    .sortBy(['label'])
    .map((i, idx) => {
      i.color = colorMap[idx];
      return i;
    })
    .value();
  return result;
};

function UgcCategory({
  startDate,
  endDate,
  type,
  widgetsTitle,
  isCustomCalendarRange,
}: IComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [priorityData, setPriorityData] = useState<any[]>([]);
  const { title, tooltipDescription } = widgetsTitle;
  const isDemo = window.location.pathname.includes('premium') || false;

  const setWidgetData = (data: any) => {
    setPriorityData(massageData(data.counts));
    setIsLoading(false);
  };

  const getDetectionData = () => {
    setIsLoading(true);
    const query = {
      field: type === ugcType.App_Store ? 'platform_label' : 'category_labels',
      startDate: startDate,
      endDate: endDate,
      limit: 5,
      isCustomCalendarRange: isCustomCalendarRange,
    };
    isDemo && type === 'social'
      ? setWidgetData(socailMediaCategoryWidgetMockData)
      : ugcService
          .getDetectionsByField(query, type)
          .then((res: any) => {
            setWidgetData(res);
          })
          .catch((error: any) => {
            console.log(error);
          });
  };

  useEffect(() => {
    getDetectionData();
  }, [startDate, endDate]);

  return (
    <DonutChart
      title={title}
      isLoading={isLoading}
      description={tooltipDescription}
      data={priorityData}
      placeholder={placeholder}
    />
  );
}

export { UgcCategory };

import { HUBSPOT_FORM_TYPE } from './types';
import { ToggleSwitchItems } from '../Common/ToggleSwitch';
import { PlanTerm } from './types';

export const toggleOptions: ToggleSwitchItems[] = [
  {
    label: 'Pay Annually',
    value: PlanTerm.YEARLY,
    isLocked: false,
  },
  {
    label: 'Pay Monthly',
    value: PlanTerm.MONTHLY,
    isLocked: false,
  },
];

export const hubspotFormId = {
  [HUBSPOT_FORM_TYPE.PRO]: '4856acae-a433-46e5-bcb6-2c99796764eb',
  [HUBSPOT_FORM_TYPE.PREMIUM]: '5e20d587-2e7b-4365-9b62-c7e6799c6452',
};

export const NumberOfDomains: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export enum TogglePlanType {
  DOWNGRADE = 'downgrade',
  CANCEL = 'cancel',
  NONE = '',
}

export const PRICING_PAGE_PATH = '/checkphish/pricing/';

export const SCROLL_TO_COMPARISION = 'scroll-to-comparsion';

import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CloseIcon } from '../../../../assets/SVGIcons';
import {
  IFindingsTableUrls,
  ISelectedWebUrls,
  appConstants,
  setLocalStorageValue,
} from '../../../../constants';
import { FINDINGS_TABLE_IDS, MALICIOUS_TABLE_IDS } from '../../../MonitorAndTakedown/constants';
import { IFindingsTableItemProps } from '../../Types/ugc.types';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../helpers/hooks';
import { setTableTotalRows } from '../../../../reducers/table.reducer';
import { dashboardActions } from '../../../../actions';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
interface IBulkActionsOverHead {
  findingsTableUrls: IFindingsTableUrls | ISelectedWebUrls;
  type: string;
  tableId: string;
  setFindingsTableUrls: (
    tableId: string,
    selected: IFindingsTableItemProps[],
    type: string,
  ) => void;
}
const BulkActionsOverhead = ({
  findingsTableUrls,
  type,
  tableId,
  setFindingsTableUrls,
}: IBulkActionsOverHead) => {
  const dispatch = useDispatch();

  const removeSelected = (item: any) => {
    setFindingsTableUrls(
      tableId as FINDINGS_TABLE_IDS,
      findingsTableUrls[type][tableId].filter(
        (url: { url_sha256: string }) => url.url_sha256 !== item.url_sha256,
      ),
      type,
    );
  };

  const clearAllHandler = () => {
    setFindingsTableUrls(tableId as FINDINGS_TABLE_IDS, [], type);
    dispatch(setTableTotalRows(0));
    setLocalStorageValue(['savedSelectionState', tableId], []);
    dispatch(dashboardActions.setSelectedWebUrls(tableId as MALICIOUS_TABLE_IDS, []));
  };
  const urlBubbles = (item: IFindingsTableItemProps, ix: number) => {
    return (
      <div className='url-bubbles' key={`${item.url}-${ix}`}>
        <OverlayTrigger
          placement={'bottom'}
          overlay={
            <Tooltip id={`tooltip-${item.url}-${ix}`} className={'table-source-url-tooltip'}>
              {item.url}
            </Tooltip>
          }
        >
          <div className={'text-container'}>{item.url}</div>
        </OverlayTrigger>
        <div onClick={() => removeSelected(item)}>
          <CloseIcon color='black' />
        </div>
      </div>
    );
  };

  const getSelectedFindingsData = () => {
    if (type === appConstants.CONTENT_TYPE.WEB) {
      return findingsTableUrls;
    } else {
      return findingsTableUrls?.[type]?.[tableId];
    }
  };

  const isReadOnlyUser = useReadOnlyUser();

  return !isReadOnlyUser ? (
    <div className={'single-takedown-form'}>
      <div className={'first-container'}>
        {getSelectedFindingsData().length === 0 ? (
          <div className={'instruction-label'}>Select URL(s) to initiate Bulk Actions</div>
        ) : (
          <div className={'instruction-label'}>
            Findings Selected: {getSelectedFindingsData()?.length}. Bulk Actions, Updating or
            Deleting, will apply to {getSelectedFindingsData()?.length} findings.
          </div>
        )}
      </div>
      {getSelectedFindingsData().length > 1 && (
        <Button className='px-0' variant='link' size='sm' onClick={clearAllHandler}>
          Clear All
        </Button>
      )}
    </div>
  ) : null;
};

export default BulkActionsOverhead;

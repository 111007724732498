import { Editor, IAllProps } from '@tinymce/tinymce-react';
import React, { useEffect, useState, useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';

export type RichEditorProps = Omit<IAllProps, 'tinymceScriptSrc' | 'init'> & {
  height?: number;
};

export default function RichTextEditor({ height, ...tinyProps }: RichEditorProps) {
  const [showEditor, setShowEditor] = useState(true);
  const { selectedTheme } = useContext(ThemeContext);
  const [tinySkin, setTinySkin] = useState(selectedTheme === 'dark' ? 'oxide-dark' : null);
  const [tinyContentCss, setTinyContentCss] = useState(selectedTheme === 'dark' ? 'dark' : null);

  useEffect(() => {
    let subscribed = true; // will be false on unmount
    setShowEditor(false);
    if (selectedTheme === 'dark') {
      setTinySkin('oxide-dark');
      setTinyContentCss('dark');
    } else {
      setTinySkin(null);
      setTinyContentCss(null);
    }

    setTimeout(() => {
      if (subscribed) {
        setShowEditor(true);
      }
    }, 50);

    return () => {
      subscribed = false;
    };
  }, [selectedTheme]);

  return (
    <React.Fragment>
      {showEditor && (
        <Editor
          {...tinyProps}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          init={{
            height: height ?? 750,
            menubar: false,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'preview',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic | bullist numlist outdent indent | ' +
              'removeformat',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            skin: tinySkin as any,
            content_css: tinyContentCss as any,
            branding: false,
          }}
        />
      )}
    </React.Fragment>
  );
}

import React, { ReactNode, useContext } from 'react';
import { Modal, Button, Spinner, Form } from 'react-bootstrap';
import { CloseIcon } from '../../assets/SVGIcons';
import ThemeContext from '../../context/ThemeContext';
import { Checkbox } from './Checkbox';

export interface IModalOverlayProps {
  show: boolean;
  title?: string;
  children?: ReactNode;
  submitButtonDisabled?: boolean;
  showButtons?: boolean;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  hideSubmitBtn?: boolean;
  hideCancelButton?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  customClassName?: string;
  centered?: boolean;
}

const ModalOverlay = ({
  show,
  title,
  children,
  submitButtonDisabled,
  showButtons,
  submitButtonLabel,
  cancelButtonLabel,
  isLoading,
  onSubmit,
  onCancel,
  hideSubmitBtn = false,
  size,
  hideCancelButton = false,
  customClassName,
  centered = false,
}: IModalOverlayProps) => {
  const { selectedTheme } = useContext(ThemeContext);
  return (
    <Modal
      show={show}
      onHide={onCancel}
      className={`${selectedTheme} ${customClassName}`}
      size={size}
      centered={centered}
    >
      <Modal.Header>
        {title && <Modal.Title>{title}</Modal.Title>}
        <div onClick={onCancel}>
          <CloseIcon color='grey' />
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {showButtons && (
        <Modal.Footer>
          <div className='button-container'>
            {!hideCancelButton && (
              <Button variant='outline-secondary' onClick={onCancel}>
                {cancelButtonLabel}
              </Button>
            )}

            {!hideSubmitBtn && (
              <Button
                variant='primary'
                disabled={submitButtonDisabled || isLoading}
                onClick={onSubmit}
              >
                {isLoading ? (
                  <Spinner className='spinner' animation='border' variant='dark' size='sm' />
                ) : (
                  submitButtonLabel
                )}
              </Button>
            )}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

ModalOverlay.defaultProps = {
  showButtons: true,
  submitButtonLabel: 'Submit',
  cancelButtonLabel: 'Cancel',
};

export default ModalOverlay;

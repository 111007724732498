import React, { useEffect, useState } from 'react';
import ModalOverlay from '../Common/ModalOverlay';
import { deleteSocActionDetails } from './web-requests';
import { setShouldRefreshTimeline } from '../../reducers/timeline.reducer';
import { useAppDispatch } from '../../helpers/hooks';
import { alertActions } from '../../actions';

interface IDeleteEventModalProps {
  show: boolean;
  onCancel: () => void;
  eventData: any;
}
export default function DeleteEventModal({ show, onCancel, eventData }: IDeleteEventModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const onModalSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSocActionDetails({ eventId: eventData.id });

      dispatch(alertActions.success('Event deleted successfully'));
      // refresh the timline
      dispatch(setShouldRefreshTimeline(true));
    } catch (error) {
      dispatch(alertActions.error('Error deleting event'));
    } finally {
      setIsLoading(false);
      onCancel();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setShouldRefreshTimeline(false));
    };
  }, [dispatch]);

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={'Delete Event'}
      onCancel={onCancel}
      onSubmit={onModalSubmit}
      cancelButtonLabel='Cancel'
      submitButtonLabel={'Delete'}
    >
      <div className='delete-soc-action-wrapper'>
        <div className='delete-event-wrapper pt-1 pb-2'>{eventData.data.title}</div>
        <div className='delete-event-detail'>This action can’t be undone.</div>
      </div>
    </ModalOverlay>
  );
}

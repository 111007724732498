import React from 'react';
import { Dashboard } from '../../../Ugc/Components/DashboardContainer';
import { IMediaIntroProps, ugcType } from '../../../Ugc/Types/ugc.types';
import SocialMediaStaticImage from '../../../../assets/SocialMedia_static.png';
import SocialMediaStaticDarkImage from '../../../../assets/SocialMedia_static_dark.png';
import { appConstants } from '../../../../constants';
import { widgetsTitle } from '../../constants';

const mediaTryOutProps: IMediaIntroProps = {
  featureName: 'Social Media',
  featureDescription:
    'Detect and remove fake ads, counterfeit products, and executive impersonations.',
  staticLightImage: SocialMediaStaticImage,
  staticDarkImage: SocialMediaStaticDarkImage,
  featureCode: appConstants.FEATURE_CODE.SOCIAL_MEDIA,
};

const SocialMediaDashboard = () => {
  return (
    <Dashboard
      type={ugcType.Social}
      mediaTryOutProps={mediaTryOutProps}
      widgetsTitle={widgetsTitle}
    />
  );
};

export default SocialMediaDashboard;

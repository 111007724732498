import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import './dashboard.scss';
import DashboardService from '../../services/dashboard.service';
import { BrandType, GRAPH_HEIGHT_INDEX, ROW_HEIGHT, THEME_DARK_GRAY_3 } from '../../constants';
import ReactApexChart from 'react-apexcharts';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NoIp from '../../assets/icons/NoIp.svg';
import { renderNoDataPlaceholder } from './dashboard.detection';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { ApexOptions } from 'apexcharts';

interface IComponentProps {
  brandType: BrandType;
  startDate: moment.Moment;
  endDate: moment.Moment;
  limit: number;
}

interface IComponentState {
  data: any;
  isLoading: boolean;
}

class DashboardIp extends React.Component<IComponentProps, IComponentState> {
  private _isMounted = false;
  private readonly dashboardService: DashboardService;

  static defaultProps = {
    limit: 10,
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      data: {},
      isLoading: true,
    };
    this.dashboardService = new DashboardService();
  }

  componentDidMount() {
    this._isMounted = true;
    this.getIpData();
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  componentDidUpdate(prevProps: IComponentProps): void {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.getIpData();
    }
  }

  getIpData = () => {
    this.setCompState({
      isLoading: true,
    });

    const { brandType, startDate, endDate } = this.props;
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    this.dashboardService.getIpDetectedWidgetData(query, brandType).then((res: any) => {
      this.setCompState({
        data: res.result,
        isLoading: false,
      });
    });
  };

  render() {
    const COLOR_PHISH = '#FFA25F';
    const COLOR_LIKELY_PHISH = '#FFD3B2';
    const COLOR_SUSPICIOUS = '#A7DFFF';
    const COLOR_SCAM = '#74C5F4';
    const COLOR_HACKED = '#1BA1CC';
    const COLOR_CRYPTOJACKING = '#007EA7';
    const COLOR_OTHER = '#76EAF5';

    const { limit } = this.props;
    const { data, isLoading } = this.state;
    const shownData: any[] = [];
    const phishData: number[] = [];
    const likelyPhishData: number[] = [];
    const suspiciousData: number[] = [];
    const scamData: number[] = [];
    const hackedData: number[] = [];
    const cryptojackingData: number[] = [];
    const otherData: number[] = [];
    const ip: string[] = [];

    let dataWithoutUndefined: any[] = [];
    _.forEach(data, function (item, key) {
      if (key !== 'undefined') {
        item.ip = key;
        item = {
          phish: 0,
          likely_phish: 0,
          suspicious: 0,
          scam: 0,
          hacked: 0,
          cryptojacking: 0,
          other: 0,
          ...item,
        };
        dataWithoutUndefined.push(item);
      }
    });

    dataWithoutUndefined = _.reverse(
      _.sortBy(dataWithoutUndefined, [
        item => {
          return (
            item.phish +
            item.likely_phish +
            item.suspicious +
            item.scam +
            item.cryptojacking +
            item.hacked +
            item.other
          );
        },
      ]),
    );

    for (let i = 0; i < dataWithoutUndefined.length && i < limit; i++) {
      shownData.push(dataWithoutUndefined[i]);
    }

    _.forEach(shownData, function (value) {
      phishData.push(value.phish);
      likelyPhishData.push(value.likely_phish);
      suspiciousData.push(value.suspicious);
      scamData.push(value.scam);
      hackedData.push(value.hacked);
      cryptojackingData.push(value.cryptojacking);
      otherData.push(value.other);
      ip.push(value.ip);
    });

    const colors: string[] = [];
    const sums: number[] = [];
    const series: any[] = [];

    const pushData = (data: number[], label: string, color: string): void => {
      if (_.sum(data) > 0) {
        series.push({
          name: label + ': ',
          data: data,
          color,
        });
        colors.push(color);
        sums.push(_.sum(data));
      }
    };
    pushData(phishData, 'Phish', COLOR_PHISH);
    pushData(likelyPhishData, 'Likely Phish', COLOR_LIKELY_PHISH);
    pushData(suspiciousData, 'Suspicious', COLOR_SUSPICIOUS);
    pushData(scamData, 'Scam', COLOR_SCAM);
    pushData(hackedData, 'Hacked', COLOR_HACKED);
    pushData(cryptojackingData, 'Cryptojacking', COLOR_CRYPTOJACKING);
    pushData(otherData, 'Other', COLOR_OTHER);

    const tickAmount = 2;
    let xMax = 2;
    if (dataWithoutUndefined[0]) {
      xMax = Math.max(
        dataWithoutUndefined[0].phish +
          dataWithoutUndefined[0].likely_phish +
          dataWithoutUndefined[0].suspicious +
          dataWithoutUndefined[0].scam +
          dataWithoutUndefined[0].hacked +
          dataWithoutUndefined[0].cryptojacking +
          dataWithoutUndefined[0].other,
        4,
      );
      xMax += xMax % tickAmount;
    }

    const options: ApexOptions = {
      chart: {
        type: 'bar',
        fontFamily: 'Fakt',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '67',
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: '#f62c3a',
              },
            ],
          },
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: ip,
        labels: {
          style: {
            colors: THEME_DARK_GRAY_3,
          },
        },
        tickAmount,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        max: xMax,
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          align: 'left',
          style: {
            colors: THEME_DARK_GRAY_3,
          },
        },
        show: dataWithoutUndefined.length > 0,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        custom: (tooltipData: any) => {
          const { dataPointIndex } = tooltipData;
          const currentData = shownData[dataPointIndex];
          const { phish, likely_phish, suspicious, scam, hacked, cryptojacking, other, ip } =
            currentData;
          const renderTooltip = (color: string, label: string, value: number) => {
            if (value > 0) {
              return (
                '<div class="d-flex align-items-center"><div class="dot" style="background-color: ' +
                color +
                '"></div>' +
                label +
                ': <b>' +
                value +
                '</b></div>'
              );
            }
            return '';
          };
          let tooltip =
            '<div class="ip-widget-tooltip-container">' +
            '<div class="apexcharts-tooltip-title">' +
            ip +
            '</div>';
          tooltip += renderTooltip(COLOR_PHISH, 'Phish', phish);
          tooltip += renderTooltip(COLOR_LIKELY_PHISH, 'Likely Phish', likely_phish);
          tooltip += renderTooltip(COLOR_SUSPICIOUS, 'Suspicious', suspicious);
          tooltip += renderTooltip(COLOR_SCAM, 'Scam', scam);
          tooltip += renderTooltip(COLOR_HACKED, 'Hacked', hacked);
          tooltip += renderTooltip(COLOR_CRYPTOJACKING, 'Cryptojacking', cryptojacking);
          tooltip += renderTooltip(COLOR_OTHER, 'Other', other);
          tooltip += '</div>';
          return tooltip;
        },
      },
      fill: {
        colors,
      },
      colors,
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: -50,
        floating: true,
        markers: {
          radius: 6,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 0,
        },
        formatter: (name: string, legendData: any) => {
          const { seriesIndex } = legendData;
          return (
            '<div class="ip-widget-legend-label">' + name + '<b>' + sums[seriesIndex] + '</b></div>'
          );
        },
      },
    };

    return (
      <div className='dashboard-widget dashboard-ip-widget'>
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-ip'} className={'table-source-url-tooltip'}>
              New counterfeit sites detected by IP
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title'>Top 10 IP Addresses with Counterfeit Sites</div>
        </OverlayTrigger>
        <LoadingWrapper isLoading={isLoading}>
          {shownData.length ? (
            <ReactApexChart
              options={options}
              series={series}
              height={ROW_HEIGHT * GRAPH_HEIGHT_INDEX}
              type='bar'
            />
          ) : (
            renderNoDataPlaceholder(
              NoIp,
              'No IP Address detections found',
              'We haven’t detected any counterfeit sites for this period. Please select another time frame.',
            )
          )}
        </LoadingWrapper>
      </div>
    );
  }
}

export { DashboardIp };

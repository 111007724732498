import React, { Fragment } from 'react';
import _ from 'lodash';
import { IBodyProps } from './constant';
import './table.scss';
import { TableRow } from './table.row';
import { Accordion, AccordionToggle } from 'react-bootstrap';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import GroupedHeaderRow from './GroupedHeaderRow';
import { appConstants, UserAppSettings } from '../../../constants';
import GroupedHeaderRowItems, { IRelatedCounts } from './GroupedHeaderRowItems';
import DashboardService from '../../../services/dashboard.service';
import {
  MALICIOUS_TABLE_IDS,
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
} from '../../MonitorAndTakedown/constants';
import { AppState } from '../../../helpers';
import { WebUrlData, WebUrlDataHeader } from '../../../types/web-url-data.interface';
import { invoke } from '../../../helpers/async';
import { dashboardActions } from '../../../actions/dashboard.actions';
import { EventHandler } from '../../../helpers/event-handler';
import { TypedReactEvent } from '../../../types/common';

export interface HeaderCheckEvent {
  headerDomain: string;
  checked: boolean;
}

interface IComponentState {
  rowExpanded: {};
  eventKey: any;
  expandedRows: any;
  headerCheckEvents: EventHandler<HeaderCheckEvent>;
  loadingRelated: { [headerDomain: string]: boolean };
  domainOnHover: string;
  isTagSelected: boolean;
  loadingFindings: boolean;
  findings: WebUrlData[];
  relatedCounts: IRelatedCounts[];
}

class TableBody extends React.Component<IBodyProps, IComponentState> {
  private readonly dashboardService: DashboardService;
  static defaultProps = {
    enableCheckbox: false,
    onCheck: _.noop,
  };

  constructor(props: IBodyProps) {
    super(props);
    this.state = {
      rowExpanded: {},
      eventKey: '',
      expandedRows: [],
      headerCheckEvents: new EventHandler<HeaderCheckEvent>(),
      loadingRelated: {},
      domainOnHover: '',
      isTagSelected: false,
      loadingFindings: false,
      findings: [],
      relatedCounts: [],
    };
    this.dashboardService = new DashboardService();
  }

  toggleExpander = (index: any, clickedDomain: string) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowExpanded = currentExpandedRows.includes(clickedDomain);

    const toggledUrl = {};

    isRowExpanded ? (toggledUrl[clickedDomain] = false) : (toggledUrl[clickedDomain] = true);

    // If the row is expanded, we will collapseit.
    //  Hence remove it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id: any) => id !== clickedDomain)
      : currentExpandedRows.concat(clickedDomain);

    this.setState({
      rowExpanded: toggledUrl,
      expandedRows: newExpandedRows,
      eventKey: index,
    });
  };

  inGroupHeaderSelected = (checked: boolean, item: WebUrlDataHeader, index?: number) => {
    this.state.headerCheckEvents.fireEvent({ headerDomain: item.domain, checked });

    // need to fetch the items
    if (!this.state.rowExpanded[item.domain]) {
      invoke(async () => {
        if (this.props.currentFetchOptions != null) {
          try {
            this.setState({
              ...this.state,
              loadingRelated: {
                ...this.state.loadingRelated,
                [item.domain]: true,
              },
            });

            const res = await this.dashboardService.getRelatedFindings(
              item.domain,
              this.props.currentFetchOptions.type,
              this.props.currentFetchOptions?.query,
              this.props.currentFetchOptions?.filters,
              {
                sortBy: 'first_seen_ts',
                sortDirection: 'desc',
              },
            );
            if (res.findings.length > 0) {
              const tableId = this.props.id as MALICIOUS_TABLE_IDS;
              if (checked) {
                this.props.setSelectedWebUrls(
                  tableId,
                  _.uniqBy(
                    [...(this.props.selectedItems as WebUrlData[]), ...res.findings],
                    x => x.url_sha256,
                  ),
                );
              } else {
                this.props.setSelectedWebUrls(
                  tableId,
                  (this.props.selectedItems as WebUrlData[]).filter(
                    x => res.findings.find(f => f.url_sha256 === x.url_sha256) == null,
                  ),
                );
              }
            }
          } finally {
            this.setState({
              ...this.state,
              loadingRelated: {
                ...this.state.loadingRelated,
                [item.domain]: false,
              },
            });
          }
        }
      });
    }
  };

  getRelatedFindings = async (item: any) => {
    this.setState({
      loadingFindings: true,
    });

    //TODO: Need to combine the api call to related findings and related counts
    invoke(async () => {
      if (this.props.currentFetchOptions != null) {
        try {
          const relatedfindings = await this.dashboardService.getRelatedFindings(
            item.domain,
            this.props.currentFetchOptions.type,
            this.props.currentFetchOptions?.query,
            this.props.currentFetchOptions?.filters,
            {
              sortBy: 'first_seen_ts',
              sortDirection: 'desc',
            },
          );

          item.related_findings = relatedfindings;
          item.related_findings_loaded = true;

          this.setState({
            relatedCounts: relatedfindings.relatedCounts.filter(
              (count: IRelatedCounts) => count.type !== this.props.id,
            ),
            findings: relatedfindings.findings,
            loadingFindings: false,
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.setState({
            loadingFindings: false,
          });
        }
      }
    });
  };

  getSelectedCount = (item: WebUrlDataHeader): number => {
    if (
      [POST_MALICIOUS_TABLE_ID, PRE_MALICIOUS_TABLE_ID, TAKEDOWN_MALICIOUS_TABLE_ID].includes(
        this.props.id ?? '',
      )
    ) {
      return (this.props.selectedItems as WebUrlData[]).filter(
        x => x.primary_domain === (item as WebUrlDataHeader).domain,
      ).length;
    } else {
      return this.props.selectedItems?.find((i: unknown) => i === item) ?? 0;
    }
  };

  getHeaderChecked = (item: WebUrlDataHeader): boolean => {
    if (
      [POST_MALICIOUS_TABLE_ID, PRE_MALICIOUS_TABLE_ID, TAKEDOWN_MALICIOUS_TABLE_ID].includes(
        this.props.id ?? '',
      )
    ) {
      return this.getSelectedCount(item) === item.related_findings_count;
    } else {
      return this.props.selectedItems?.find((i: unknown) => i === item);
    }
  };

  onTagsChanged = (value: boolean) => {
    this.setState({ isTagSelected: value });
  };

  renderRows = () => {
    const { data, rowId, pageSize, disablePagination, selectedItems } = this.props;
    const len = disablePagination ? data.length : Math.min(data.length, pageSize);
    const rows = [];

    for (let i = 0; i < len; i++) {
      const item: any = Object.assign(
        { isNewAdded: false, isDisabled: false, _index: '' },
        data[i],
      );
      const domainName = data[i]['domain'];
      if (!item._index && domainName) {
        item._index = domainName;
      }
      let className = item && item.isNewAdded ? 'row-is-new-added' : '';
      className += item && item.isDisabled ? ' row-is-disabled' : '';
      if (_.some(selectedItems, ['_index', item._index])) {
        className += ' row-is-checked';
      }

      const normalRows = (
        <Fragment key={rowId ? item[rowId] : i}>
          <tbody>
            <tr className={`${className} row-wrapper`}>
              <TableRow {...this.props} item={item} />
            </tr>
          </tbody>
        </Fragment>
      );
      const collapsabelRow = (
        <Fragment key={rowId ? item[rowId] : i}>
          <Accordion
            as={'tbody'}
            onMouseEnter={() => this.setState({ domainOnHover: item.domain })}
            onMouseLeave={() => this.setState({ domainOnHover: '' })}
          >
            <AccordionToggle
              eventKey={`${i}`}
              as={'tr'}
              onClick={(
                e: TypedReactEvent<HTMLAnchorElement, React.MouseEvent<HTMLAnchorElement>>,
              ) => {
                this.toggleExpander(`${i}`, item.domain);
                e.stopPropagation();
              }}
              className={'row-wrapper'}
            >
              <GroupedHeaderRow
                {...this.props}
                onCheck={this.inGroupHeaderSelected}
                item={item}
                rowExpanded={this.state.rowExpanded[item.domain]}
                tableId={this.props.id}
                editing={false}
                isNew={false}
                loadingRelated={this.state.loadingRelated[item.domain]}
                selectedCount={this.getSelectedCount(item)}
                checked={this.getHeaderChecked(item)}
                domainOnHover={this.state.domainOnHover}
                onTagsChanged={this.onTagsChanged}
                getRelatedFindings={this.getRelatedFindings}
              />
            </AccordionToggle>
            {this.state.rowExpanded[item.domain] && (
              <>
                <GroupedHeaderRowItems
                  {...(this.props as IBodyProps & { id: MALICIOUS_TABLE_IDS })}
                  onCheck={this.props.onCheck}
                  editing={false}
                  isNew={false}
                  item={item}
                  eventKey={this.state.eventKey}
                  groupIx={i}
                  rowExpanded={this.state.rowExpanded[item.domain]}
                  headerCheckEvents={this.state.headerCheckEvents}
                  isTagsSelected={this.state.isTagSelected}
                  loadingFindings={this.state.loadingFindings}
                  findings={this.state.findings}
                  relatedCounts={this.state.relatedCounts}
                  getRelatedFindings={this.getRelatedFindings}
                />
              </>
            )}
          </Accordion>
        </Fragment>
      );
      if (
        this.props?.type === appConstants.CONTENT_TYPE.WEB &&
        _.first(
          _.filter(
            this.props.user?.userAppSetting,
            settings => settings?.setting_name === UserAppSettings.GroupFindings,
          ),
        )?.setting_value === 'true'
      ) {
        rows.push(collapsabelRow);
      } else {
        rows.push(normalRows);
      }
    }
    return rows;
  };

  render() {
    const { addingItemRowShown, onCloseAddItemRowShown } = this.props;
    return (
      <>
        {this.renderRows()}
        {addingItemRowShown && (
          <tbody>
            <tr key={'add-item-row'}>
              <TableRow {...this.props} item={{}} editing isNew onClose={onCloseAddItemRowShown} />
            </tr>
          </tbody>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { currentFetchOptions } = state.dashboardReducer;
  return {
    currentFetchOptions,
  };
};

const mapDispatchToProps = {
  setSelectedWebUrls: dashboardActions.setSelectedWebUrls,
};

const connectedTableBody = connect(mapStateToProps, mapDispatchToProps)(TableBody);

export { connectedTableBody as TableBody };

import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './AgGridDateRangeFilter.scss';
import { DateRange } from 'react-date-range';
import { Button } from 'react-bootstrap';
import { AgDateRangeFilterRanges } from './constants';
import { AgGridCustomFilterOptions } from './interfaces';

const TODAY = moment();
const YESTERDAY = moment(moment().subtract(1, 'day'));
const WEEK_START = moment(moment().subtract(7, 'day'));
const MONTH_START = moment(moment().subtract(30, 'day'));
const QUARTER_START = moment(moment().subtract(90, 'day'));
const ONE_EIGHTY_DAYS = moment(moment().subtract(180, 'day'));
const TWELEVE_MONTH = moment(moment().subtract(365, 'day'));

export const getString = ({ model }: any) => {
  if (![null, undefined].includes(model) && Object.keys(model).length >= 1) {
    if (model.defaultSelected) {
      return (
        moment(moment().subtract(parseInt(model.defaultSelected), 'day')).format('YYYY-MM-DD') +
        ' To ' +
        moment().format('YYYY-MM-DD')
      );
    } else {
      return (
        moment(model.dateFrom).format('YYYY-MM-DD') +
        ' To ' +
        moment(model.dateTo).format('YYYY-MM-DD')
      );
    }
  } else {
    return '';
  }
};

const AgGridDateRangeFilter = (props: CustomFilterProps) => {
  const { model, onModelChange } = props;

  const AG_DATERANGE_FILTER_OPTIONS: AgGridCustomFilterOptions[] = [
    {
      label: AgDateRangeFilterRanges.ONE_DAY,
      value: '1',
      ranges: {
        selection: {
          startDate: YESTERDAY,
          endDate: TODAY,
        },
      },
    },
    {
      label: AgDateRangeFilterRanges.SEVEN_DAYS,
      value: '7',
      ranges: {
        selection: {
          startDate: WEEK_START,
          endDate: TODAY,
        },
      },
    },
    {
      label: AgDateRangeFilterRanges.THIRTY_DAYS,
      value: '30',
      ranges: {
        selection: {
          startDate: MONTH_START,
          endDate: TODAY,
        },
      },
    },
    {
      label: AgDateRangeFilterRanges.NINTY_DAYS,
      value: '90',
      ranges: {
        selection: {
          startDate: QUARTER_START,
          endDate: TODAY,
        },
      },
    },
    {
      label: AgDateRangeFilterRanges.ONE_EIGHTY_DAYS,
      value: '180',
      ranges: {
        selection: {
          startDate: ONE_EIGHTY_DAYS,
          endDate: TODAY,
        },
      },
    },
    {
      label: AgDateRangeFilterRanges.TWELEVE_MONTHS,
      value: '365',
      ranges: {
        selection: {
          startDate: TWELEVE_MONTH,
          endDate: TODAY,
        },
      },
    },
    {
      label: AgDateRangeFilterRanges.CUSTOM,
      value: 'custom',
    },
  ];

  const [selectedDates, setSelectedDates] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const doesFilterPass = useCallback(() => {
    return true;
  }, []);

  const selectOptionHandler = async (option: string) => {
    switch (option) {
      case 'custom':
        setIsShowCalendar(!isShowCalendar);
        break;
      default: {
        const values = AG_DATERANGE_FILTER_OPTIONS.find(i => i.value === option);
        onModelChange({
          dateFrom: values?.ranges?.selection.startDate,
          dateTo: values?.ranges?.selection.endDate,
          type: 'inRange',
          filterType: 'date',
          customRange: false,
          defaultSelected: values?.value,
        });
        break;
      }
    }
  };

  const selectionRange = {
    startDate: new Date(selectedDates.startDate as any),
    endDate: new Date(selectedDates.endDate as any),
    key: 'selection',
  };

  const customDateRangeSelector = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedDates({
      startDate: moment(startDate),
      endDate: moment(endDate),
    });
  };

  const getCustomDates = () => {
    onModelChange({
      dateFrom: selectionRange.startDate,
      dateTo: selectionRange.endDate,
      type: 'inRange',
      filterType: 'date',
      customRange: true,
    });
    setIsShowCalendar(false);
  };

  const getModelAsString = useCallback(() => getString({ model }), [model]);

  const resetHandler = () => {
    onModelChange({});
    const dateRangeFilterModel = props.api?.getFilterModel();
    Object.keys(dateRangeFilterModel).map(key => {
      if (Object.keys(dateRangeFilterModel[key]).length === 0) {
        props.api.setColumnFilterModel(key, null);
      }
    });
  };

  useGridFilter({
    doesFilterPass,
    afterGuiDetached: () => {
      setIsShowCalendar(false);
    },
    getModelAsString,
  });

  const minDate = moment().subtract(365, 'day').toDate();
  const maxDate = moment().toDate();

  return (
    <div className='ag-dateRangeFilter-component'>
      <div className='ag-dataRangeFilter-options'>
        {isShowCalendar ? (
          <Fragment>
            <DateRange
              ranges={[selectionRange]}
              onChange={customDateRangeSelector}
              months={1}
              minDate={minDate}
              maxDate={maxDate}
              direction={'horizontal'}
              data-testid='ag-dataRangeFilter-datePicker'
            />
            <div className='ag-dataRangeFilter-apply-button-container'>
              <Button
                className='ag-dataRangeFilter-apply-button'
                variant='primary'
                size='sm'
                onClick={getCustomDates}
                data-testid='ag-dataRangeFilter-applyBtn'
              >
                {' '}
                Apply
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className='ag-dataRangeFilter-option-items-container'>
              {AG_DATERANGE_FILTER_OPTIONS.map(option => {
                return (
                  <div
                    key={option.value}
                    className='ag-dataRangeFilter-option-item d-flex align-items-center'
                    data-testid={`ag-dateRangeFilter-optionItem-${option.value}`}
                    onClick={() => selectOptionHandler(option.value)}
                  >
                    <span className='ag-dataRangeFilter-option-item-label'>{option.label}</span>
                    {option.ranges && (
                      <span className='ag-dataRangeFilter-option-item-value'>
                        {option.ranges?.selection.startDate.format('MMM DD,YYYY') +
                          ' - ' +
                          option.ranges?.selection.endDate.format('MMM DD,YYYY')}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
            <div className='ag-filter-apply-panel'>
              <button
                className='ag-button ag-standard-button ag-filter-apply-panel-button'
                data-testid='ag-dateRangeFilter-reset-button'
                onClick={resetHandler}
              >
                {' '}
                Reset
              </button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AgGridDateRangeFilter;

import Carousel from 'react-bootstrap/Carousel';
import React, { FunctionComponent } from 'react';
import './Scan.scss';
import outlookScanner from '../../assets/scan/carousel/outlook-scanner.jpeg';
import analyzesURL from '../../assets/scan/carousel/analyzes-url.jpeg';
import emailSafety from '../../assets/scan/carousel/email-safety.jpeg';
import emailThreatReport from '../../assets/scan/carousel/email-threat-report.jpeg';

interface ICarouselProps {
  className?: string;
  fade?: boolean;
}

const CarouselComponent: FunctionComponent<ICarouselProps> = (
  props,
): React.ReactElement<HTMLElement> => {
  const { className, fade } = props;
  const imageMapper: string[] = [outlookScanner, analyzesURL, emailSafety, emailThreatReport];

  const itemGenerator = (url: string, index: number) => {
    return (
      <Carousel.Item key={index}>
        <img className='d-block w-100' src={url} alt='Outlook Scanner' />
      </Carousel.Item>
    );
  };

  return (
    <Carousel
      fade={fade}
      interval={2000}
      className={`${className || ''}`}
      prevIcon={<span aria-hidden='true' className='carousel-control-prev-icon invert-color' />}
      nextIcon={<span aria-hidden='true' className='carousel-control-next-icon invert-color' />}
    >
      {imageMapper.map((url: string, index: number) => itemGenerator(url, index))}
    </Carousel>
  );
};

export { CarouselComponent };

import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import PlanChip from './PlanChip';
import PricingInfo from './PricingInfo';
import ActionButton from './ActionButton';
import { PRICING_PLANS } from '../types';
import { IPrice } from '../types';
import _ from 'lodash';
import DomainSelector from './DomainSelector';

export type PlanBlockProps = {
  planName: PRICING_PLANS;
  price: string | IPrice[];
  actionBtnText?: string;
  onActionBtnClick: () => any;
};

const PlanBlock = ({ planName, price, actionBtnText, onActionBtnClick }: PlanBlockProps) => {
  const customClass =
    planName === PRICING_PLANS.PREMIUM ? 'black-and-white bold-text' : 'black-and-white';

  return (
    <div className='plan-block' data-testid='plan-block'>
      <PlanChip planName={planName} isTransparent={true} />
      <div className='plan-price-info-wrap'>
        <PricingInfo price={price} alignContent={true} />
        {planName === PRICING_PLANS.STARTER && <DomainSelector />}
      </div>
      <ActionButton
        planName={planName}
        btnText={actionBtnText}
        onClick={onActionBtnClick}
        customClass={customClass}
        isSmall={true}
      />
    </div>
  );
};

export default PlanBlock;

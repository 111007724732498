import React from 'react';
import Detections from '../../../Ugc/Components/Findings/Detections';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_TABLE_NAME,
  MARKET_PLACE_PATHNAME,
} from '../../constants';

const MarketplaceDetections = () => {
  return (
    <Detections
      type={ugcType.Marketplace}
      tableId={MARKET_PLACE_FINDING_DETECTION_TABLE_ID}
      urlColPathName={MARKET_PLACE_PATHNAME}
      tableName={MARKET_PLACE_TABLE_NAME}
      enableSocInterface
    />
  );
};

export default MarketplaceDetections;

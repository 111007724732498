import React, { Fragment, useEffect } from 'react';
import { VerticalSourceFeedBars } from './VerticalSourceFeedBars';
import './SourceFeedsContainer.scss';
import { ITopFeedsScannedForBrand } from '../../../Types/dashboard.types';
import { ETabTypes, IDefaultSourceFeeds } from '../MonitoredContainerV2';

interface ISourceFeedContainerprops {
  activeSourceFeeds: ITopFeedsScannedForBrand[] | IDefaultSourceFeeds[];
  activeTab: ETabTypes;
  selectedLabel: string;
  onSubTabClick: (selectedTab: ITopFeedsScannedForBrand | IDefaultSourceFeeds) => void;
}
export default function SourceFeedsContainer({
  activeSourceFeeds,
  activeTab,
  selectedLabel,
  onSubTabClick,
}: ISourceFeedContainerprops) {
  const maxValue = Math.max(
    ...activeSourceFeeds.map((feed: ITopFeedsScannedForBrand | IDefaultSourceFeeds) => feed.count),
  );
  return (
    <div className='source-feeds-container'>
      {activeSourceFeeds.map(
        (feed: ITopFeedsScannedForBrand | IDefaultSourceFeeds, index: number) => {
          return (
            <Fragment key={index}>
              <VerticalSourceFeedBars
                feed={feed}
                maxValue={maxValue}
                activeTab={activeTab}
                onSubTabClick={onSubTabClick}
                selectedLabel={selectedLabel}
              />
            </Fragment>
          );
        },
      )}
    </div>
  );
}

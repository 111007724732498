import React, { useState } from 'react';
import _ from 'lodash';
import './Style/RequestPurchaseModal.scss';
import ModalOverlay from '../Common/ModalOverlay';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LabelAndValue } from '../Common/LabelAndValue';
import AssetsService from '../../services/assests.service';
import { alertActions } from '../../actions';
import { numberWithCommas } from '../../constants';
import { Link } from 'react-router-dom';
import { getSingleOrPluralForm } from '../../constants';
import { useAppDispatch } from '../../helpers/hooks';

export interface IRequestPurchaseModalProps {
  data: any;
  show: boolean;
  availableCredit: number;
  onCloseModal: () => void;
  onModalSubmit?: () => void;
}

const RequestPurchaseModal = (props: IRequestPurchaseModalProps) => {
  const assetsService = new AssetsService();

  const { data, show, availableCredit, onCloseModal, onModalSubmit = _.noop } = props;
  const [isLoading, setIsLoading] = useState(false);

  const numberOfDomains = data.length;
  const totalPrice = _.reduce(
    data,
    (sum, i) => {
      return sum + i.estimated_price;
    },
    0,
  );
  const afterPurchaseBalance = availableCredit - totalPrice;

  const dispatch = useAppDispatch();

  const onSubmit = () => {
    setIsLoading(true);
    assetsService
      .purchasedDomains(_.map(data, 'domain_sha256'))
      .then(res => {
        setIsLoading(false);
        dispatch(
          alertActions.success(
            '',
            <div>
              Purchase requested for{' '}
              <u>
                <Link
                  className='text-white'
                  to={{
                    pathname: `/assets/managed-by-bolster`,
                    search: `?targetKey=domain_sha256&targetValues=${_.map(
                      data,
                      'domain_sha256',
                    ).join(',')}`,
                  }}
                >
                  {numberOfDomains} {getSingleOrPluralForm(numberOfDomains, 'domain')}
                </Link>
              </u>{' '}
              for <b>{`$${totalPrice}`}</b>. To modify your request reach out to us within 24 hours.
            </div>,
          ),
        );
        onModalSubmit();
      })
      .catch(err => {
        setIsLoading(false);
        dispatch(alertActions.error(err));
      });
  };

  return (
    <ModalOverlay
      show={show}
      title={'Purchase Confirmation'}
      onCancel={onCloseModal}
      onSubmit={onSubmit}
      submitButtonLabel={'Purchase'}
      submitButtonDisabled={afterPurchaseBalance < 0}
      isLoading={isLoading}
    >
      <div className={'request-purchase-modal'} data-testid='request-purchase-modal'>
        <Form.Group>
          <div>
            You are placing an order for <b>{numberOfDomains}</b> domains for{' '}
            <b>{`$${totalPrice}`}</b>
          </div>
        </Form.Group>
        <Form.Group>
          <Row className='credits-info'>
            <Col sm={6} className='credit'>
              <LabelAndValue
                label={'Available Credit'}
                value={`$${numberWithCommas(availableCredit)}`}
                direction={'column'}
                noMarginBottom
              />
            </Col>
            <Col sm={6} className='credit border-blue'>
              <LabelAndValue
                label={'Credit after placing an order'}
                value={`$${numberWithCommas(afterPurchaseBalance)}`}
                direction={'column'}
                noMarginBottom
              />
            </Col>
          </Row>
        </Form.Group>
      </div>
    </ModalOverlay>
  );
};
export default RequestPurchaseModal;

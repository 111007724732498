import React, { useState, useEffect, useCallback } from 'react';
import { ASSETS_FORM_FIELDS } from '../constants';
import {
  FieldInput,
  IAddPropteryProps,
  PropertyType,
  InputType,
  PropertyValueType,
} from '../PropertyTypes';
import PropertyModal from './PropertyModal';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { alertActions } from '../../../actions';

type Props = IAddPropteryProps;

const AddBrandProperty = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [fields, setFields] = useState<FieldInput[]>([]);
  const { onSubmitModal, type, ugcPlatforms } = props;

  const brandInfo = useAppSelector(state => state.dashboardReducer.brandInfo);
  const dispatch = useAppDispatch();
  const showBrand = (arr: any) => {
    const modifiedFormFields: any = [...arr];
    if (brandInfo?.brand?.brandMapping && brandInfo?.brand?.subBrands.length > 1) {
      modifiedFormFields.unshift({
        id: 'brandId',
        label: 'Brand',
        type: InputType.DROPDOWN,
        isRequired: true,
        options: [],
      });
    }
    modifiedFormFields.map((field: any) => {
      if (field.id === 'platform') {
        field.options = ugcPlatforms;
      }
      if (field.id === 'brandId') {
        field.options = brandInfo?.brand?.subBrands.map((fieldVal: any) => {
          return (fieldVal = {
            ...fieldVal,
            docValue: fieldVal.brand_id,
            label: fieldVal.brand_display_name,
          });
        });
      }
      return field;
    });
    return modifiedFormFields;
  };

  useEffect(() => {
    switch (type.value) {
      case PropertyType.LOGO:
        setFields(showBrand(ASSETS_FORM_FIELDS[PropertyType.LOGO]));
        break;
      case PropertyType.TRADEMARK:
        setFields(showBrand(ASSETS_FORM_FIELDS[PropertyType.TRADEMARK]));
        break;
      case PropertyType.SOCIAL:
        setFields(showBrand(ASSETS_FORM_FIELDS[PropertyType.SOCIAL]));
        break;
      case PropertyType.MARKETPLACE:
        setFields(showBrand(ASSETS_FORM_FIELDS[PropertyType.MARKETPLACE]));
        break;
      case PropertyType.APP_STORE:
        setFields(showBrand(ASSETS_FORM_FIELDS[PropertyType.APP_STORE]));
        break;
      case PropertyType.DOCUMENT:
        setFields(showBrand(ASSETS_FORM_FIELDS[PropertyType.DOCUMENT]));
        break;
      default:
        setFields([]);
    }
  }, [type]);

  const onFormSubmit = () => {
    setIsLoading(true);
    const data = { ...formValues };
    const missingFields = fields.filter(field => field.isRequired && !data[field.id]);
    const formData = new FormData();
    formData.append('brandId', formValues.brandId || brandInfo?.brand.brandId);
    formData.append('brandMnemonic', brandInfo?.brand?.brandName || '');
    formData.append('typeId', type.id.toString());
    switch (type.value) {
      case PropertyType.LOGO:
        if (!data.files?.length) {
          setIsLoading(false);
          return;
        }

        formData.append('valueType', PropertyValueType.FILE);
        data.files.forEach((file: File) => formData.append('uploads', file));
        break;
      case PropertyType.DOCUMENT:
        if (!data.files?.length) {
          setIsLoading(false);
          return;
        }
        formData.append('valueType', PropertyValueType.JSON);
        data.files.forEach((file: File) => formData.append('uploads', file));
        formData.append('valueJson', JSON.stringify(data));
        break;
      default:
        if (missingFields.length) {
          dispatch(
            alertActions.error(
              `Please enter ${missingFields.map(field => field.label).join(', ')}.`,
            ),
          );
          setIsLoading(false);
          return;
        }

        formData.append('valueType', PropertyValueType.JSON);
        formData.append('valueJson', JSON.stringify(data));
    }
    onSubmitModal(formData);
  };

  const updateFormValues = useCallback(
    (key: string, value: any) => {
      setFormValues({
        ...formValues,
        [key]: value,
      });
    },
    [formValues],
  );

  return (
    <PropertyModal
      {...props}
      onFormSubmit={onFormSubmit}
      fields={fields}
      updateFormValues={updateFormValues}
      isLoading={isLoading}
      formValues={formValues}
    />
  );
};
export default AddBrandProperty;

import ModalOverlay from '../Common/ModalOverlay';
import React, { useContext, useEffect, useState } from 'react';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { TakedownEmailDetails } from './types/takedown-email-details.interface';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { ChevronRightIcon, InfoIcon, PaperClip2Icon } from '../../assets/SVGIcons';
import { Button, Card, Form, ProgressBar, Spinner } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';
import { LabelAndValue } from '../Common/LabelAndValue';
import { TakedownReview } from './types/takedown-review.interface';
import ThemeContext from '../../context/ThemeContext';
import { invoke } from '../../helpers/async';
import { formatError } from '../../helpers/errors';
import { DomainMap } from './soc-takedown-request-page.component';
import SocTakedownTabs from './SocTakedownTabs';
import { Nullable } from '../../types/common';
import { Checkbox } from '../Common/Checkbox';
import './soc-takedown.scss';
import _ from 'lodash';

const socTakedownService = new SocTakedownService();

export interface ISocTakedownReviewModalProps {
  show: boolean;
  domainMap: DomainMap | null;
  onSubmit: () => void;
  onCancel: () => void;
  allDomains: string[];
}
export interface ReviewInfoMap {
  [key: string]: TakedownReview;
}

export function SocTakedownReviewModal({
  show,
  domainMap,
  onSubmit,
  onCancel,
  allDomains,
}: ISocTakedownReviewModalProps) {
  const [title, setTitle] = useState('Send Takedown Request');
  const [loading, setLoading] = useState(true);
  const [submitProgress, setSubmitProgress] =
    useState<Nullable<{ submitted: number; total: number }>>(null);
  const [error, setError] = useState<string | null>(null);
  const [reviewInfo, setReviewInfo] = useState<Nullable<ReviewInfoMap>>(null);
  const [attachmentCount, setAttachmentCount] = useState(0);
  const { selectedTheme } = useContext(ThemeContext);
  const [headerBg, setHeaderBg] = useState(selectedTheme === 'dark' ? 'bg-dark' : 'bg-light');
  const [activeDomain, setActiveDomain] = useState('');

  useEffect(() => {
    if (selectedTheme === 'dark') {
      setHeaderBg('bg-dark');
    } else {
      setHeaderBg('bg-light');
    }
  }, [selectedTheme]);

  const onGroupedTabsClick = (domain: string) => {
    if (domainMap) {
      setActiveDomain(domain);
    }
  };

  useEffect(() => {
    if (show && domainMap) {
      setActiveDomain(Object.keys(domainMap)[0]);
      const reviewdInfo: { [domain: string]: TakedownReview } = {};
      invoke(async () => {
        try {
          setLoading(true);
          setError(null);
          for (const [domain, domainInfo] of Object.entries(domainMap)) {
            const res = await socTakedownService.getTakedownReview({
              urlSha256s: domainInfo.urls.map(x => x.sha256),
              ...(domainInfo.details as TakedownEmailDetails),
            });

            reviewdInfo[domain] = res;
          }
          setReviewInfo(reviewdInfo);
        } catch (e) {
          setError(formatError(e));
        } finally {
          setLoading(false);
        }
      });
    }
  }, [show, domainMap]);

  useEffect(() => {
    if (domainMap && activeDomain) {
      setAttachmentCount(
        domainMap[activeDomain].urls
          .filter(x => x.selected)
          .map(x => x.retakeScreenshotPath || x.screenshotPath).length +
          domainMap[activeDomain].files.length,
      );
    }
  }, [domainMap, activeDomain]);

  async function submit() {
    try {
      let progress = { submitted: 0, total: allDomains.length };
      setSubmitProgress(progress);
      setTitle('Submitting Takedown Request');
      setError(null);
      if (domainMap != null) {
        for (const [domain, domainInfo] of Object.entries(domainMap)) {
          await socTakedownService.submitTakedown(
            {
              urlSha256s: domainInfo.urls.map(x => x.sha256),
              ...domainInfo.details,
              bodyHtml: (reviewInfo?.[domain] as TakedownReview).html,
              subject: (reviewInfo?.[domain] as TakedownReview).subject,
              screenshotUrls: domainInfo.urls
                .filter(x => x.selected)
                .map(x => x.retakeScreenshotPath || x.screenshotPath),
              submittedViaForm: domainInfo.form.submittedViaForm,
              imagePaths: domainInfo.urls.map(x => x.retakeScreenshotPath || x.screenshotPath),
            },
            domainInfo.files,
          );

          progress = {
            ...progress,
            submitted: progress.submitted + 1,
          };
          setSubmitProgress(progress);
        }
      }
      onSubmit();
    } catch (e) {
      setSubmitProgress(null);
      setTitle('Send Takedown Request');
      setError(formatError(e));
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalOverlay
      show={show}
      title={title}
      onCancel={onCancel}
      onSubmit={_.noop} // noop
      showButtons={false}
      size={'lg'}
    >
      <LoadingWrapper isLoading={loading}>
        {error && (
          <div className={'alert alert-danger'}>
            <h6 className={'alert-heading'}>{error}</h6>
          </div>
        )}

        {reviewInfo != null && (
          <div>
            <div className={'alert alert-primary d-flex'} role='alert'>
              <div>
                <InfoIcon color='grey' />
              </div>
              <h6 className={'ml-1 alert-heading flex-grow-1'}>
                Please review all the information before sending the takedown request to the hosting
                provider.
              </h6>
            </div>
            {allDomains.length > 1 && domainMap && (
              <SocTakedownTabs
                domainMap={domainMap}
                activeDomain={activeDomain}
                showErrors={false}
                tabClick={selectedDomain => onGroupedTabsClick(selectedDomain)}
              />
            )}
            <Card>
              <Card.Header className={headerBg}>
                {domainMap?.[activeDomain]?.form?.submittedViaForm && (
                  <div className='text-center pb-2'>
                    <h5>Submitted Via Form</h5>
                  </div>
                )}
                {attachmentCount > 0 && (
                  <div className={'d-flex justify-content-end pb-4'}>
                    <div
                      className={
                        'd-flex align-items-center soc-takedown-icon-wrapper icon-disabled'
                      }
                    >
                      <PaperClip2Icon />
                      <span className={'mx-1'}>{attachmentCount}</span>
                      <ChevronRightIcon />
                    </div>
                  </div>
                )}
                <div className={'d-flex justify-content-between soc-takedown-review-to-from'}>
                  <LabelAndValue
                    label={'To'}
                    value={reviewInfo[activeDomain]?.to?.map((x: any) => x.address).join(', ')}
                    direction={'row'}
                  />
                  <LabelAndValue
                    label={'From'}
                    value={reviewInfo[activeDomain].from}
                    direction={'row'}
                    alignItems={'flexEnd'}
                  />
                </div>
                <LabelAndValue
                  label={'Cc'}
                  value={reviewInfo[activeDomain]?.cc?.map((x: any) => x.address)?.join(', ')}
                  direction={'row'}
                />
                <LabelAndValue
                  label={'Bcc'}
                  value={reviewInfo[activeDomain]?.bcc?.map((x: any) => x.address).join(', ')}
                  direction={'row'}
                />

                <LabelAndValue
                  noMarginBottom={true}
                  label={'Subject'}
                  value={reviewInfo[activeDomain].subject}
                  direction={'row'}
                />
              </Card.Header>
              <Card.Body className={'pt-2'}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(reviewInfo[activeDomain].html ?? ''),
                  }}
                ></div>
              </Card.Body>
            </Card>

            <div className='pt-2'>
              <Form.Group className={'d-flex'}>
                <Form.Label className={'mr-3 '}>Submitted via form:</Form.Label>
                <Checkbox
                  disabled={true}
                  isStateless={true}
                  defaultChecked={domainMap?.[activeDomain]?.form?.submittedViaForm}
                />
              </Form.Group>

              <Form.Group className={'d-flex'}>
                <Form.Label className={'mr-3 '}>Report urls to:</Form.Label>
                <Checkbox
                  disabled={true}
                  isStateless={true}
                  label='Google SafeBrowsing'
                  defaultChecked={domainMap?.[activeDomain]?.form?.googleSafeBrowsing}
                />
                <Checkbox
                  disabled={true}
                  isStateless={true}
                  label='APWG Blocklist '
                  defaultChecked={domainMap?.[activeDomain]?.form?.apwgBlocklist}
                />
                <Checkbox
                  disabled={true}
                  isStateless={true}
                  label='Cloudflare '
                  defaultChecked={domainMap?.[activeDomain]?.form?.cloudFlare}
                />
              </Form.Group>
              <Form.Group className={'d-flex'}>
                <Form.Label className={'mr-3 '}>Takedown request is sent to: </Form.Label>
                <Checkbox
                  disabled={true}
                  isStateless={true}
                  label='Hosting Provider '
                  defaultChecked={domainMap?.[activeDomain]?.form?.reportedToHostingProvider}
                />
                <Checkbox
                  disabled={true}
                  isStateless={true}
                  label='Registrar '
                  defaultChecked={domainMap?.[activeDomain]?.form?.reportedToRegistrar}
                />

                <Checkbox
                  disabled={true}
                  isStateless={true}
                  label='Registrant '
                  defaultChecked={domainMap?.[activeDomain]?.form?.reportedToRegistrant}
                />

                <Checkbox
                  disabled={true}
                  isStateless={true}
                  label='Other '
                  defaultChecked={domainMap?.[activeDomain]?.form?.reportedToOther}
                />
              </Form.Group>
            </div>
            <div className='mt-4'>
              {submitProgress != null && (
                <div className='m-2'>
                  <ProgressBar
                    now={(submitProgress.submitted / submitProgress.total) * 100}
                    label={`${submitProgress.submitted}/${submitProgress.total}`}
                  ></ProgressBar>
                </div>
              )}
              <div className='d-flex justify-content-end'>
                <div className='mr-2'>
                  <Button
                    variant='outline-secondary'
                    onClick={onCancel}
                    disabled={loading || submitProgress != null}
                  >
                    Cancel
                  </Button>
                </div>
                <div className='mr-2'>
                  <Button
                    variant='primary'
                    disabled={loading || submitProgress != null}
                    onClick={() => void submit()}
                  >
                    <div className='d-flex align-items-center'>
                      {allDomains.length > 1 && <span>Send Takedown Requests</span>}
                      {allDomains.length <= 1 && <span>Send Takedown Request</span>}
                      {submitProgress != null && (
                        <Spinner
                          className='ml-2 spinner'
                          animation='border'
                          variant='dark'
                          size='sm'
                        />
                      )}
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </LoadingWrapper>
    </ModalOverlay>
  );
}

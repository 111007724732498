import { Drawer, ThemeOptions, ThemeProvider, createTheme } from '@material-ui/core';
import React from 'react';
import { useAppSelector } from '../../../../helpers/hooks';
import DataLeakDetails from './DataLeakDetails';
import '../../Styles/DataLeakDetailsDrawer.scss';
import useOnToggleDataLeakDrawer from './useOnToggleDataLeakDrawer';
import useOnThemeChange from './useOnThemeChange';

export default function DataLeakDetailsDrawer() {
  const { palleteType, backgroundColor } = useOnThemeChange();
  const { toggleDrawer } = useOnToggleDataLeakDrawer();
  const theme: ThemeOptions = createTheme({
    palette: {
      type: palleteType as any,
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor,
        },
      },
    },
  });
  const shouldShowDataLeakModal = useAppSelector(
    state => state.darkWebReducer.shouldShowDataLeakModal,
  );

  return (
    <ThemeProvider theme={theme}>
      <Drawer open={shouldShowDataLeakModal} onClose={toggleDrawer} anchor='right'>
        <div data-testid='data-leak-details-drawer'>
          <DataLeakDetails />
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

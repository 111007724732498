import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { setIsWebTablesGroupView } from '../../../reducers/table.reducer';
import _ from 'lodash';
import { UserAppSettings } from '../../../constants';

export default function useOnGroupedView() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.dashboardReducer.user);

  useEffect(() => {
    const currentSwitchValue = _.find(user.userAppSetting, {
      setting_name: UserAppSettings.GroupFindings,
    });

    dispatch(setIsWebTablesGroupView(currentSwitchValue?.setting_value === 'true'));
  }, [dispatch, user]);
}

import React, { useEffect, useCallback, useState, useContext } from 'react';
import '../../Style/BubbleChart.scss';
import DarkWebService from '../../../../services/darkWeb.service';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import _ from 'lodash';
import * as d3 from 'd3';
import NewTextIcon from '../../../../assets/icons/NewTextIcon.svg';
import {
  generateId,
  replaceSpaceWithDash,
  setLocalStorageValue,
  ThemeModes,
} from '../../../../constants';
import { EDarkWebFindingStatus } from '../Types/DarkWeb.types';
import { history } from '../../../../helpers';
import {
  DARK_WEB_FINDING_DETECTION_TABLE_ID,
  DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME,
} from '../../DarkWebConstants';
import ThemeContext from '../../../../context/ThemeContext';
import { IFilter } from '../../../Common/Table/constant';
import BubbleChartV2 from './BubbleChartV2';
import { renderNoDataPlaceholder } from '../../../Common/DashboardWidgets/LineChart';
import { IChartPlaceholderProps } from '../../../Common/DashboardWidgets/Types/chart.type';
import NoFindingsLight from '../../../../assets/icons/NoFindingsLight.svg';
import NoFindingsDark from '../../../../assets/icons/NoFindingsDark.svg';
import BubbleChart from '@weknow/react-bubble-chart-d3';
const darkWebService = new DarkWebService();
export const initialCategoryFilter = {
  id: generateId(10),
  filterBy: { label: 'Category', value: 'category' },
  filterErr: '',
  filterMethod: { label: 'Includes', value: 'includes' },
  filterType: 'string',
  isCustomFilter: true,
};
const THRESHOLD_BUBBLE_RADIUS = 30;
function BubbleCategoryChart() {
  const [bubbleChartData, setBubbleChartData] = useState<any[]>([]);
  const [mitigatedData, setMitigatedData] = useState<any[]>([]);
  const [ignoredData, setIgnoredData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { selectedTheme } = useContext(ThemeContext);
  const placeholder: IChartPlaceholderProps = {
    icon: selectedTheme === ThemeModes.LIGHT.toLowerCase() ? NoFindingsLight : NoFindingsDark,
    title: 'No Active findings',
    description: 'There is currently no active Dark Web incident discovered by Bolster.',
  };
  useEffect(() => {
    setIsLoading(true);
    darkWebService
      .getDarkWebCategory()
      .then(res => {
        const result: any = [];
        const activeCategories: any = _.first(
          res.filter((item: any) => item.status === EDarkWebFindingStatus.ACTIVE),
        );
        const ignoredCategories: any = _.first(
          res.filter((item: any) => item.status === EDarkWebFindingStatus.IGNORED),
        );
        const mitigatedCategories: any = _.first(
          res.filter((item: any) => item.status === EDarkWebFindingStatus.MITIGATED),
        );
        setMitigatedData(mitigatedCategories?.categoryByRisk || []);
        setIgnoredData(ignoredCategories?.categoryByRisk || []);
        activeCategories.categoryByRisk.forEach((item: any) => {
          item.categories.forEach((c: any) => {
            const massageData: any = {};
            massageData['color'] = 'transparent';
            massageData['label'] = c.category;
            massageData['value'] = c.total;
            massageData['risk'] = item.risk.toLowerCase();
            massageData['backgroundColor'] =
              selectedTheme === ThemeModes.LIGHT.toLowerCase() ? '#F8F8F8' : '#18181a';
            massageData['id'] = replaceSpaceWithDash(c.category);
            massageData['labelColor'] =
              selectedTheme === ThemeModes.LIGHT.toLowerCase() ? '#6f6f6f' : '#a8b3c1';
            massageData['countValueColor'] =
              selectedTheme === ThemeModes.LIGHT.toLowerCase() ? '#333333' : '#ffffff';
            massageData['isNewFinding'] = c.new > 0 ? true : false;
            result.push(massageData);
          });
        });
        setBubbleChartData(result);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    if (bubbleChartData.length > 0 && !isLoading) {
      mapColorWithRisk();
    }
  });

  const mapColorWithRisk = () => {
    const bubbleChart = d3.select('.bubble-chart');
    const newTextWidth = 70;
    bubbleChart
      .selectAll('circle')
      .on('mouseover', null)
      .on('mouseout', null)
      .style('fill', selectedTheme === ThemeModes.LIGHT.toLowerCase() ? '#F8F8F8' : '#18181a');
    bubbleChart
      .selectAll('.node')
      .style('cursor', 'pointer')
      .each(function (d: any) {
        const gNode = d3.select(this);
        gNode.classed(`${d.data.risk}-stroke`, true);
        if (d.data.isNewFinding && d.r > THRESHOLD_BUBBLE_RADIUS) {
          gNode
            .append('svg:image')
            .attr('xlink:href', NewTextIcon)
            .attr('width', newTextWidth)
            .attr('x', -newTextWidth / 2)
            .attr('y', d.r - 32);
        }
        if (d.r <= THRESHOLD_BUBBLE_RADIUS) {
          gNode.select('text').style('font-size', 14).attr('x', -7).attr('y', 5);
          gNode.selectAll('.label-text').style('font-size', 0);
        }
      })
      .on('click', function (d) {
        onCategoryBubbleClick(d?.target?.__data__?.label);
      });
  };
  const renderRiskCounts = () => {
    const riskCount = {
      high: {
        id: 'high',
        label: 'High Risk',
        count: 0,
        color: '#FF4141',
        categories: [],
      },
      medium: {
        id: 'medium',
        label: 'Medium Risk',
        count: 0,
        color: '#FC7B20',
        categories: [],
      },
      low: {
        id: 'low',
        label: 'Low Risk',
        count: 0,
        color: '#FFCE1F',
        categories: [],
      },
    };
    bubbleChartData.map((item: any) => {
      if (riskCount[item.risk]) {
        riskCount[item.risk].count += item.value;
        riskCount[item.risk].categories.push(item.label);
      }
    });

    return Object.values(riskCount).map((item: any, idx: number) => {
      const { label, id, count, color, categories } = item;
      return (
        <div
          className='risk-wrapper'
          key={idx}
          data-categories={categories}
          onClick={() => {
            onRiskLegendClick(id);
          }}
        >
          <div className='badge' style={{ background: color }}></div>
          <div className='risk-label'>{label}:</div>
          <div className='risk-count'>{count}</div>
        </div>
      );
    });
  };

  const renderStatusCounts = () => {
    const countCategories = (categories: any) => {
      let totalCount = 0;
      categories.forEach((item: { categories: any[] }) => {
        item.categories.forEach((itemTotal: { total: number }) => {
          totalCount += itemTotal.total;
        });
      });
      return totalCount;
    };
    const status = [
      {
        label: 'Ignored',
        count: countCategories(ignoredData),
        onClickCategory: () => {
          history.push(DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME);
        },
      },
      {
        label: 'Mitigated',
        count: countCategories(mitigatedData),
        onClickCategory: () => {
          history.push(DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME);
        },
      },
    ];
    return status.map((item: any, idx: number) => {
      return (
        <div className='status-count-wrapper' key={idx}>
          <div className='status-label'>{item.label}</div>
          <div className='status-count' onClick={item.onClickCategory}>
            {item.count || '0'}
          </div>
        </div>
      );
    });
  };

  const onCategoryBubbleClick = (categoryLabel: string) => {
    const filter = {
      ...initialCategoryFilter,
      filterValue: categoryLabel,
    };
    setLocalStorageValue(['tableSetting', DARK_WEB_FINDING_DETECTION_TABLE_ID], {
      filters: [filter],
    });
    history.push(DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME);
  };

  const onRiskLegendClick = (id: string) => {
    const riskLevelfilter = [
      {
        id: generateId(10),
        filterBy: { label: 'Risk Level', value: 'risk_level' },
        filterErr: '',
        filterMethod: { label: 'Includes', value: 'includes' },
        filterType: 'string',
        isCustomFilter: false,
        filterValue: id.toUpperCase(),
        filterLabel: id.toUpperCase(),
      },
    ];
    setLocalStorageValue(['tableSetting', DARK_WEB_FINDING_DETECTION_TABLE_ID], {
      filters: riskLevelfilter,
    });
    history.push(DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME);
  };

  return (
    <div className='bubble-chart-section'>
      <LoadingWrapper isLoading={isLoading}>
        {bubbleChartData.length > 0 ? (
          <>
            <div
              className='bubble-chart-container flex-center'
              id='dark-web-dashboard-bubble-chart-container'
            >
              {/* <BubbleChartV2
                data={bubbleChartData}
                width={850}
                height={650}
                onCategoryBubbleClick={onCategoryBubbleClick}
                offsetX={50}
                offsetY={300}
                isLoading={isLoading}
              /> */}
              <BubbleChart
                graph={{
                  zoom: 0.9,
                  offsetX: 0,
                  offsetY: 0,
                }}
                width={705}
                height={650}
                showLegend={false}
                padding={10}
                valueFont={{
                  family: 'Arial',
                  size: 35,
                  color: selectedTheme === ThemeModes.LIGHT.toLowerCase() ? '#333333' : '#ffffff',
                  weight: 'bold',
                }}
                labelFont={{
                  family: 'Arial',
                  size: 11,
                  color: selectedTheme === ThemeModes.LIGHT.toLowerCase() ? '#6f6f6f' : '#a8b3c1',
                  weight: 'normal',
                }}
                data={bubbleChartData}
              />
            </div>
            <div className='bottom-category-count-wrapper'>
              <div className='categories-by-risk'>{renderRiskCounts()}</div>
              <div className='categories-by-status'>{renderStatusCounts()}</div>
            </div>
          </>
        ) : (
          renderNoDataPlaceholder(placeholder)
        )}
      </LoadingWrapper>
    </div>
  );
}

export default React.memo(BubbleCategoryChart);

import React, { useContext, useEffect } from 'react';
import ThemeContext from '../../../context/ThemeContext';
import { ThemeModes } from '../../../constants';
import { ITagColors, fetchAllTagsColor } from './tag-request';
import './CustomTagColorPalette.scss';

interface ICustomTagColorPaletteProps {
  onColorSelect: (tagId: number) => void;
  oldSelectedColor?: number;
}

export default function CustomTagColorPalette({
  onColorSelect,
  oldSelectedColor,
}: ICustomTagColorPaletteProps): JSX.Element {
  const [tagsColors, setTagsColors] = React.useState<ITagColors[]>([]);
  const { selectedTheme } = useContext(ThemeContext);
  const [selectedColorId, setSelectedColorId] = React.useState<number>(oldSelectedColor || 0);

  useEffect(() => {
    try {
      const fetchTagsColors = async () => {
        const response = await fetchAllTagsColor();
        if (response) {
          setTagsColors(response);
        }
      };
      fetchTagsColors();
    } catch (error) {
      console.error('Error fetching tags colors', error);
    }
  }, []);

  const onColorSelectHandler = (tagId: number) => {
    onColorSelect(tagId);
    setSelectedColorId(tagId);
  };

  return (
    <div className='custom-color-palette-wrapper' data-testid='custom-color-palette-wrapper'>
      {tagsColors.map((tag, index) => (
        <div
          key={index}
          className={`color-palette ${selectedColorId === tag.id ? 'selected' : ''}`}
          style={{
            backgroundColor:
              selectedTheme === ThemeModes.DARK.toLowerCase() ? tag.darkColor : tag.lightColor,
          }}
          onClick={() => onColorSelectHandler(tag.id)}
          data-testid='color-palette'
        ></div>
      ))}
    </div>
  );
}

import React, { useState, useRef } from 'react';
import { Card, Form } from 'react-bootstrap';
import ModalOverlay from '../../../../Common/ModalOverlay';
import { IFindingsTableItemProps } from '../../../Types/ugc.types';
import { TextInput } from '../../../../Common/TextInput';
import { TableApi } from '../../../../Common/Table/table.api';
import { Table } from '../../../../Common/Table/table';
import { getDisplayTimeFromTimeStamp } from '../../../../../constants';

const NOTES_TABLE_COL: any = [
  {
    id: 'note',
    accessor: 'note',
    header: 'Note',
    type: 'text',
  },
  {
    id: 'createdTs',
    accessor: 'created_ts',
    header: 'Created At',
    fieldForExport: false,
    filterDisabled: true,
    isDefaultSort: true,
    type: 'date',
    isLongText: true,
    render: (data: any) => {
      return getDisplayTimeFromTimeStamp(data.createdTs);
    },
  },
  {
    id: 'user',
    accessor: 'user',
    header: 'Added By',
    type: 'text',
    render: ({ user }: any) => {
      return `${user.firstName} ${user.lastName}`;
    },
  },
];

interface ISocActionColRenderProps {
  finding: IFindingsTableItemProps;
  addSocNotes: (notes: any, sha256: string) => void;
}
const SocNotesColRender = ({ finding, addSocNotes }: ISocActionColRenderProps) => {
  const [show, setShow] = useState(false);
  const [newNote, setNewNote] = useState('');
  const { notes, sha256 } = finding;

  const onCancel = () => {
    setShow(false);
  };

  const onModalSubmit = () => {
    addSocNotes({ note: newNote }, sha256);
    setShow(false);
  };

  return (
    <div>
      <div className='note-column' onClick={() => setShow(true)}>
        {notes?.length || '--'}
      </div>
      <ModalOverlay
        show={show}
        showButtons={true}
        title={'SOC Notes'}
        onCancel={onCancel}
        onSubmit={onModalSubmit}
        submitButtonLabel={'Add Notes'}
        submitButtonDisabled={newNote.length === 0}
      >
        <div>
          <Form>
            <Form.Group>
              <Form.Label>Please enter note here</Form.Label>
              <TextInput
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setNewNote(e.currentTarget.value)
                }
              />
            </Form.Group>
          </Form>
          <Card className='table-container'>
            <Table
              data={notes}
              key={'soc-notes-table'}
              columns={NOTES_TABLE_COL}
              customTools={false}
              disableToolsBar={true}
              disableDatePicker={true}
              disableFilter={true}
            />
          </Card>
        </div>
      </ModalOverlay>
    </div>
  );
};

export default SocNotesColRender;

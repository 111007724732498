import React, { useContext, useEffect, useState } from 'react';
import './typosquatv2.scss';
import { TypoSquatPopUp } from './TyposquatPopup';
import { DashboardContainer } from '../Checkphish/TypoSquattingDashboardV2/DashboardContainer';
import DashboardService from '../../services/dashboard.service';
import { TypoSquatDashboardAnalytics } from '../../types/typosquat-dashboard.interface';
import { Button } from 'react-bootstrap';
import * as _ from 'lodash';
import { PageTitle } from '../Common/PageTitle';
import EditIcon from '../../assets/icons/Edit.svg';
import LoadingDashboard from './DashboardLoading';
import { Subscription } from 'rxjs';
import { useHistory } from 'react-router-dom';
import ActiveRoutesContext from '../../context/ActiveRoutesContext';
import { ETyposquattingStatus, TYPOSQUATTING_MASTER_PATHNAME, TypoJobStatus } from './constant';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { unsetLocalStorageValue } from '../../constants';

export type LimitStatusValue = 'inlimit' | 'offlimit';

//offlimit - when the user count is above daily limit.
//inlimit - when the user count is inside daily limit.

const dashboardService = new DashboardService();

export interface ITypoDashboardProps {
  typosquatJobStatus: boolean;
  changeTyposquatJobStatus: (status: boolean) => void;
}

export type stepVal = 'stepUp' | 'stepDown' | 'custom' | 'reset';

//initial state will be -2,
//at step 6 typosquat pooling stops.

const stepStatus = {
  [TypoJobStatus.NO_DOMAIN]: -1, //when no domain is set give render initial page
  [TypoJobStatus.CHANGING_DOMAIN]: 0, //step when domain change call is given
  [TypoJobStatus.JOB_CREATED]: 1,
  [TypoJobStatus.DOMAINS_GENERATED]: 2,
  [TypoJobStatus.DNS_SEARCH]: 3, // pooling data
  [TypoJobStatus.DOMAIN_SCAN]: 4, //pooling data
  [TypoJobStatus.DOMAIN_PURCHASE]: 5, //pooling data
  [TypoJobStatus.DONE]: 6, //pooling data is done
};

export function TypoDashboard({
  typosquatJobStatus,
  changeTyposquatJobStatus,
}: ITypoDashboardProps) {
  const { getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);
  const { setMonitoredDomain, setContactUsMesage, setShowContactUsPage, setShowExploreDemoPopup } =
    useContext(CheckPhishContactUsContext);
  let subscription: Subscription;
  const history = useHistory();
  const [typosquatDashboardAnalytics, setTyposquatDashboardAnalytics] =
    useState<TypoSquatDashboardAnalytics | null>(null);
  const [loadFunnelData, setLoadFunnelData] = useState<boolean>(false);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [funnelReload, setFunnelReload] = useState<boolean>(false);
  const [step, setStep] = useState<number>(-2);
  const [showTyposquatPopup, setShowTyposquatPopup] = useState<boolean>(false);
  const [domainName, setDomainName] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [totalDomains, setTotalDomain] = useState<number>(0);
  const [registerdDomains, setRegisteredDomain] = useState<number>(0);
  const [unregisteredDomains, setUnregisteredDomain] = useState<number>(0);
  const [scanPercentage, changeScanPercentage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(typosquatJobStatus ? true : false);
  const [editDomain, setEditDomain] = useState<boolean>(false);
  const [fetchAnalyticsPolling, setFetchAnalyticsPolling] = useState<boolean>(false);
  const [dailyScans, setDailyScan] = useState<number>(0);
  const [scanLimit, setScanLimit] = useState<number>(1);
  const [limitStatus, setLimitStatus] = useState<LimitStatusValue>('inlimit'); //assuming the the user starts with zero count.

  useEffect(() => {
    unsetLocalStorageValue('loginRedirectUrl');
  }, []);

  useEffect(() => {
    if (scanLimit === -1) {
      setLimitStatus('inlimit');
      return;
    }
    dailyScans >= scanLimit ? setLimitStatus('offlimit') : setLimitStatus('inlimit');
  }, [dailyScans, scanLimit]);

  useEffect(() => {
    if (step === -1 && !typosquatDashboardAnalytics) {
      setLimitStatus('inlimit');
    }
  }, [step, typosquatDashboardAnalytics]);

  const changeTypoPopupState = (newState: boolean | null = null) => {
    newState !== null
      ? setShowTyposquatPopup(newState)
      : setShowTyposquatPopup((oldState: boolean) => !oldState);
  };

  const changeStep = (action: stepVal, value: number = 0) => {
    if (action === 'reset') {
      setStep(-2);
    }
    if (action === 'custom') {
      setStep(value);
    }
    if (action === 'stepUp') {
      setStep(oldState => oldState + 1);
    }
    if (action === 'stepDown') {
      setStep(oldState => oldState - 1);
    }
  };

  const changeDomainName = (value: string) => setDomainName(value);

  const changeSearchText = (value: string) => setSearchText(value);

  const fetchTypoSquattingDashboardAnalytics = (query?: string, goToTyposquatTable = false) => {
    if (funnelReload) {
      setLoadFunnelData(true);
    } else {
      setFetchAnalyticsPolling(true);
    }
    subscription = dashboardService.getTyposquattingDashboardAnalytics(query).subscribe({
      next: (resp: any) => {
        if (funnelReload) {
          changeTyposquatJobStatus(true);
        } else {
          changeTyposquatJobStatus(false);
        }
        const {
          status,
          monitoredDomain,
          metadata,
          totalDomainMonitoringScanLimit,
          usedDomainMonitoringScanLimit,
        } = resp;
        const { topTldVariants, totalResolvedVariants, scanPercentage } = metadata;
        setDailyScan(usedDomainMonitoringScanLimit);
        // setScanLimit(totalDomainMonitoringScanLimit);
        //TODO : limit can be made dynamic based on further requirements.
        scanPercentage && changeScanPercentage(scanPercentage);
        setTotalDomain(topTldVariants);
        setRegisteredDomain(totalResolvedVariants);
        setUnregisteredDomain(topTldVariants - totalResolvedVariants);
        stepStatus[status] && setStep(stepStatus[status]);
        monitoredDomain && changeDomainName(monitoredDomain);
        monitoredDomain && changeSearchText(monitoredDomain);
        if (funnelReload) {
          const updatedData = _.cloneDeep(resp);
          const existingData = _.cloneDeep(typosquatDashboardAnalytics);
          if (existingData) {
            existingData.domainCounts = updatedData.domainCounts;
          }
          setTyposquatDashboardAnalytics(existingData);
          setLoadFunnelData(false);
        } else {
          setTyposquatDashboardAnalytics(resp);
        }
        setShowTyposquatPopup(true);
        setIsLoading(true);
        if (status === 'done') {
          setMonitoredDomain(monitoredDomain);
          setFetchAnalyticsPolling(false);
          setShowTyposquatPopup(false);
          subscription.unsubscribe();
          setIsLoading(false);
          getParentRouteViaFindingStatus(ETyposquattingStatus.TYPOSQUAT_DASHBOARD);
          changeTyposquatJobStatus(true);
          if (goToTyposquatTable) {
            history.push(TYPOSQUATTING_MASTER_PATHNAME);
          }
        }
      },
      error: err => {
        //no typo is set yet
        console.log('error', err);
        if (err === 'No Job for Monitored domain found') {
          setTyposquatDashboardAnalytics(null);
        }
        changeTyposquatJobStatus(true);
        changeTypoPopupState(true);
        setIsLoading(false);
        changeStep('custom', stepStatus['no_domain']);
      },
    });
  };

  useEffect(() => {
    setShowExploreDemoPopup(true);
    return () => setShowExploreDemoPopup(false);
  });

  useEffect(() => {
    if (step === 0) {
      changeScanPercentage(0);
    }
    if (step < 6) {
      changeTyposquatJobStatus(false);
    } else {
      changeTyposquatJobStatus(true);
    }
  }, [step]);

  useEffect(() => {
    fetchTypoSquattingDashboardAnalytics(query);
  }, [query]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
      setContactUsMesage('');
      setShowContactUsPage(false);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    changeSearchText(domainName);
  }, [showTyposquatPopup]);

  const renderInitialPage = () => {
    return (
      <div className='typo-welcome-container'>
        <div className='content-wrap'>
          <div className='heading-wrapper'>
            <Button
              className='get-started-btn gray-btn'
              onClick={() => {
                changeTypoPopupState(true);
              }}
            >
              Get Started
            </Button>
            <h3 className='main-head'>
              Welcome to CheckPhish. Start your set up by entering a domain you like to monitor.
            </h3>
            <h6 className='sub-head'>
              Enter your brand domain to generate up to 300 typosquat variations and we help you
              Scan up to 100 to discover potential scams or phish URLs you may not have considered.
            </h6>
          </div>
          <div className='img-wrapper'></div>
        </div>
      </div>
    );
  };

  const renderProgressPage = () => {
    return (
      <>
        <div className='processing-page'>
          <LoadingDashboard step={step} domainName={domainName} />
        </div>
      </>
    );
  };

  const renderDashboardResultsPage = () => {
    return (
      typosquatDashboardAnalytics && (
        <>
          <DashboardContainer
            loadFunnelData={loadFunnelData}
            sendFilters={e => {
              setFunnelReload(true);
              setQuery(e);
            }}
            typosquatDashboardAnalytics={typosquatDashboardAnalytics}
          />
        </>
      )
    );
  };

  return (
    <div className={`typo-wrapper`}>
      {showTyposquatPopup && (
        <TypoSquatPopUp
          step={step}
          editDomain={editDomain}
          fetchAnalyticsPolling={fetchAnalyticsPolling}
          setFetchAnalyticsPolling={setFetchAnalyticsPolling}
          changeStep={changeStep}
          setPopUpState={newState => {
            setEditDomain(false);
            changeTypoPopupState(newState);
          }}
          searchText={searchText}
          setSearchText={changeSearchText}
          fetchTypoSquattingDashboardAnalytics={fetchTypoSquattingDashboardAnalytics}
          totalDomains={totalDomains}
          registerdDomains={registerdDomains}
          unregisteredDomains={unregisteredDomains}
          progress={scanPercentage}
          setDomainName={(newDomainName: string) => {
            setDomainName(newDomainName);
          }}
          warningTextType={limitStatus}
          scanLimit={scanLimit}
          domainName={domainName}
          isLoading={isLoading}
          setIsLoading={(status: boolean) => {
            setIsLoading(status);
          }}
          limitStatus={limitStatus}
        />
      )}
      {step === -1 && !typosquatDashboardAnalytics ? (
        <>{renderInitialPage()}</>
      ) : (
        <>
          <PageTitle
            title={`Dashboard ${domainName && `${domainName}`}`}
            className='analytics-dashboard-page-title'
            tools={
              domainName && (
                <button
                  className='edit-domain-btn'
                  onClick={() => {
                    setEditDomain(true);
                    changeTypoPopupState(true);
                  }}
                >
                  <img src={EditIcon} alt='edit button' />
                  edit domain
                </button>
              )
            }
          />
          {step == 6 ? renderDashboardResultsPage() : renderProgressPage()}
        </>
      )}
    </div>
  );
}

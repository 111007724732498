import React from 'react';
import './TypoBlurrTable.scss';
import LockBanner from '../Drawer/CheckPhishV2/LockBanner';

type Props = {
  lockIcon: string;
  onUpgrade: () => void;
};

const TypoBlurrTable = ({ lockIcon, onUpgrade }: Props) => {
  return (
    <div className='typosquat-blurr-table-wrap'>
      <LockBanner
        showLockedIcon={true}
        heading={<>You’ve reached 300 limit. Upgrade for continuous monitoring.</>}
        subHeading={
          <>
            Your free plan shows up to <span className='highlight-text'>300</span> typosquat
            results.
            <br />
            Upgrade now for continuous monitoring of unlimited results, with re-scan alerts for any
            changes.
          </>
        }
        upgradeBtnText={'Upgrade Plan'}
      />
    </div>
  );
};

export default TypoBlurrTable;

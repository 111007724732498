import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { Markup } from 'interweave';
import Card from 'react-bootstrap/Card';
import { IDarkWebFindingData } from '../../Types/DarkWeb.types';
import { CantSeeIcon } from '../../../../../assets/SVGIcons';
import { CountDown } from '../../../../Common/CountDown';
import { LoadingWrapper } from '../../../../Common/LoadingWrapper';
import '../../../Style/ContentInfoSection.scss';
import {
  SensitiveDataNumbers,
  SensitiveDataTitles,
  SensitiveDataTypes,
} from '../../../../Assets_v2/PropertyTypes';

const CONTENTS_DISPLAY_DURATION = 30;
interface IContentInfoSectionProps {
  data: IDarkWebFindingData;
  onViewRaw: () => void;
  isLoading: boolean;
}

export const ContentInfoSection = ({ data, onViewRaw, isLoading }: IContentInfoSectionProps) => {
  const [contentShown, setContentShown] = useState<boolean>(false);
  const { content, sensitiveInfo, highlights, sensitiveInfoExists } = data;
  const contentTermHighlight: any = useRef(null);
  const sensitiveTermHighlight: any = useRef([]);
  const sensitiveDataArr = [];
  useEffect(() => {
    scrollContentToBottom(contentTermHighlight);
  }, [contentTermHighlight]);
  const showContent = () => {
    setContentShown(true);
    scrollSensitiveDataToBottom(sensitiveTermHighlight);
  };

  const hideContent = () => {
    setContentShown(false);
  };

  const scrollContentToBottom = (eleRefs: any) => {
    if (eleRefs.current?.children) {
      const childrens = eleRefs.current?.children;
      for (const node of childrens) {
        for (const child of node.children) {
          if (child.localName == 'b') {
            child?.scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'nearest',
            });
            return;
          }
        }
      }
    }
  };
  const scrollSensitiveDataToBottom = (eleRefs: any) => {
    if (eleRefs.current.length) {
      eleRefs.current.forEach((item: any) => {
        for (const child of item.children) {
          for (const childchild of child.children) {
            if (childchild.localName == 'b') {
              childchild?.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'nearest',
              });
              return;
            }
          }
        }
      });
    }
  };
  const renderSensitiveInfo = (key: string) => {
    const highligthArr: string[] = highlights !== '--' ? highlights.split(',') : [];
    const massagedSensitiveData: any[] = [];
    highligthArr.forEach((highlight: any) => {
      sensitiveInfo[key].forEach((term: any) => {
        if (term.includes(highlight)) {
          massagedSensitiveData.push(term.replace(highlight, `<b><em>${highlight}</em></b>`));
        } else {
          massagedSensitiveData.push(term);
        }
      });
    });

    return (
      <div key={key} className={'information-container'}>
        <div className={'title'}> {SensitiveDataTitles[key]} </div>
        <div
          ref={element => {
            if (element) {
              sensitiveTermHighlight.current[SensitiveDataNumbers[key]] = element;
            }
          }}
        >
          {_.map(massagedSensitiveData, (item, index) => {
            return (
              <div key={index}>
                <Markup noWrap content={item} />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  if (sensitiveInfoExists) {
    for (const key in sensitiveInfo) {
      switch (key) {
        case SensitiveDataTypes['EMAIL']:
          sensitiveDataArr.push(renderSensitiveInfo(key));
          break;
        case SensitiveDataTypes['CCN']:
          sensitiveDataArr.push(renderSensitiveInfo(key));
          break;
        case SensitiveDataTypes['SSN']:
          sensitiveDataArr.push(renderSensitiveInfo(key));
          break;
        case SensitiveDataTypes['IP']:
          sensitiveDataArr.push(renderSensitiveInfo(key));
          break;
        case SensitiveDataTypes['CRYPTO']:
          sensitiveDataArr.push(renderSensitiveInfo(key));
          break;
      }
    }
  }
  return (
    <div className='content-info-section d-flex flex-column'>
      <div className='content-info d-flex align-items-stretch'>
        <Card className='content-container'>
          <Card.Header className='self-card-header d-flex justify-content-between'>
            <div>Content</div>
            {/* {isLoading ? null : (
              <div className='text-link' onClick={onViewRaw}>
                View Raw Data
              </div>
            )} */}
          </Card.Header>
          <Card.Body className='content-body'>
            <LoadingWrapper isLoading={isLoading}>
              <div ref={contentTermHighlight}>
                {_.map(content.split('\n'), (line, index) => {
                  return (
                    <div key={index}>
                      <Markup noWrap content={line} />
                    </div>
                  );
                })}
              </div>
            </LoadingWrapper>
          </Card.Body>
        </Card>

        {sensitiveInfoExists && (
          <Card className='info-container mt-0'>
            <Card.Header className='self-card-header'>Sensitive Info</Card.Header>
            <Card.Body className='content-body'>{sensitiveDataArr}</Card.Body>
          </Card>
        )}
        {contentShown || isLoading ? null : (
          <div className='content-blocker flex-center' onClick={showContent}>
            <div
              className='blocker-content d-flex flex-column align-items-center'
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {CantSeeIcon}
              <div className='theme-text-color'>Info Hidden</div>
              <div className='text-center theme-text-secondary-color'>
                Bolster hides this because it may <br></br> contain sensitive information
              </div>
              <div className='text-link' onClick={showContent}>
                Show Anyway
              </div>
            </div>
          </div>
        )}
      </div>
      <Card>
        <Card.Body className='pl-0 pb-0 content-footer d-flex align-items-center flex-row-reverse'>
          {contentShown ? (
            <CountDown
              className='theme-text-secondary-color'
              initialValue={CONTENTS_DISPLAY_DURATION}
              onZero={hideContent}
              prefix={'Hide in '}
              postfix={'s'}
            />
          ) : null}
        </Card.Body>
      </Card>
    </div>
  );
};

import React, { useContext } from 'react';
import moment from 'moment';
import '../Style/playbook.scss';
import TemplateIcon from '../../../assets/icons/template.svg';
import ScratchIcon from '../../../assets/icons/scratch.svg';
import { PlaybookContext } from '../../../context/playbook.context';
import { appConstants } from '../../../constants';

interface IPlaybookGetStartedProps {
  setModalShown: (show: boolean) => void;
  setUseTemplate: (show: boolean) => void;
}

const PlaybookGetStarted = ({ setModalShown, setUseTemplate }: IPlaybookGetStartedProps) => {
  const playbookContext = useContext(PlaybookContext);
  return (
    <div className={'d-flex flex-column align-items-center playbooks-get-started'}>
      <h4 className={'title text-center'}>Get Started With Playbooks</h4>
      <div className={'description text-center'}>
        Playbooks allow you to automate your counterfeit site detection and receive a re-occuring
        report in the format and frequency you choose.
      </div>
      <div
        className={'d-flex flex-column flex-sm-row w-100 align-items-center justify-content-center'}
      >
        {playbookContext.type === appConstants.CONTENT_TYPE['WEB'] ? (
          <GetStartedTile
            icon={TemplateIcon}
            text={'Try an example template'}
            onClick={() => {
              setModalShown(true);
              setUseTemplate(true);
            }}
          />
        ) : (
          <></>
        )}
        <GetStartedTile
          icon={ScratchIcon}
          text={'Create from scratch'}
          onClick={() => {
            playbookContext.updateSchedule('date', moment().valueOf());
            setModalShown(true);
            setUseTemplate(false);
          }}
        />
      </div>
    </div>
  );
};

interface IGetStartedTileProps {
  icon: any;
  text: string;
  onClick: () => void;
}

const GetStartedTile = ({ icon, text, onClick }: IGetStartedTileProps) => {
  return (
    <div
      className={
        'd-flex flex-column align-items-center justify-content-between cursor-pointer get-started-tile'
      }
      onClick={onClick}
    >
      <img className={'cursor-pointer'} src={icon} alt={text} />
      <div className={'text'}>{text}</div>
    </div>
  );
};

export { PlaybookGetStarted };

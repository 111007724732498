import React from 'react';
import Detections from '../../../Ugc/Components/Findings/Detections';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_TABLE_NAME,
  SOCIAL_MEDIA_PATHNAME,
} from '../../constants';

const SocialMediaDetections = () => {
  return (
    <Detections
      type={ugcType.Social}
      tableId={SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID}
      urlColPathName={SOCIAL_MEDIA_PATHNAME}
      tableName={SOCIAL_MEDIA_TABLE_NAME}
      enableSocInterface
    />
  );
};

export default SocialMediaDetections;

import React from 'react';
import _ from 'lodash';
import './Country.scss';

interface ICountryProps {
  countryCode: string;
  countryName?: string;
  defaultCountryCode?: string;
  customClassName?: string;
}
const flagNotAvailable: Record<string, string> = {
  AUR: 'AU',
  FRR: 'FR',
  USP: 'US',
};

const Country = ({
  countryCode,
  countryName,
  defaultCountryCode,
  customClassName = '',
}: ICountryProps) => {
  if (_.isEmpty(countryCode)) {
    if (!defaultCountryCode) {
      return <span>--</span>;
    }
    countryCode = defaultCountryCode;
  }
  const getFlag = () => {
    return flagNotAvailable.hasOwnProperty(countryCode)
      ? flagNotAvailable[countryCode].toLowerCase()
      : countryCode.toLowerCase();
  };

  const countryClassName = 'mr-1 country-icon flag-icon flag-icon-' + getFlag();
  return (
    <>
      <span className={countryClassName} />
      <span className={customClassName}>{countryName || countryCode}</span>
    </>
  );
};

export default Country;

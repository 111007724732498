import React from 'react';
import _ from 'lodash';
import {
  DateSortingFunction,
  customAgGridDateComparator,
  ipv4SortingFunction,
} from '../../../constants';
import { ITableDataColumn } from '../../Common/Table/table.data';
import { customSortingComparator } from '../../Playbook/playbook-util';
import moment from 'moment';

export const DOMAIN_COLUMN: ITableDataColumn = {
  id: 'asset_name',
  accessor: 'asset_name',
  header: 'Domain',
  isLongText: true,
  agFilterType: 'agTextColumnFilter',
  comparator: customSortingComparator,
  disableForSelection: true,
};

export const IP_COLUMN: ITableDataColumn = {
  id: 'ip_address',
  header: 'IP Address',
  accessor: 'ip_address',
  isLongText: true,
  sortingFn: (a: any, b: any) => {
    return ipv4SortingFunction(a, b);
  },
  agFilterType: 'agTextColumnFilter',
  sortDisabled: true,
};

export const DATE_ADDED: ITableDataColumn = {
  id: 'created_ts',
  accessor: 'created_ts',
  header: 'Date Added',
  filterDisabled: true,
  isDefaultSort: true,
  agFilterType: 'agDateColumnFilter',
  render: (data: any) => {
    return <>{data.displayedCreatedTs}</>;
  },
  comparator: (a: any, b: any) => {
    return customAgGridDateComparator(a, b, 'DD-MMM-YYYY');
  },
};

export const BRAND_NAME_COLUMN: ITableDataColumn = {
  id: 'brand',
  accessor: 'brand',
  header: 'Brand',
  type: 'options',
  render: (data: any) => {
    if (_.isEmpty(data.brand)) {
      return <span>--</span>;
    }
    return <>{data.brand}</>;
  },
  filterDisabled: false,
};

export const COUNTRY_COL: ITableDataColumn = {
  id: 'country',
  accessor: 'country',
  header: 'Country',
  headerTooltip: 'Country',
  render: (data: any) => {
    const { country } = data;
    if (_.isEmpty(country)) {
      return <span>--</span>;
    }
    return <>{country}</>;
  },
  agFilterType: 'agTextColumnFilter',
};

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NoHosting from '../../../../assets/icons/NoHosting.svg';
import DashboardService from '../../../../services/dashboard.service';
import { IComponentProps as ILabelAndValueProps } from '../../../Common/LabelAndValue';
import { IBarChartDataProps } from '../../../Common/DashboardWidgets/Types/chart.type';
import BarChart from '../../../Common/DashboardWidgets/BarChart';
import { Nullable } from '../../../../types/common';

const dashboardService = new DashboardService();

const TYPE_MAP = {
  age: {
    field: 'age',
    name: 'Domains by Age and Category',
    orders: ['6 mo+', '1-6 mo', '1 wk-1 mo'],
  },
  host: {
    field: 'as_description',
    name: 'Top Hosting Providers by Category',
  },
  ip: {
    field: 'ip',
    name: 'Top IP Addresses by Category',
    query: '!(ip: 0.0.0.0)',
  },
  tld: {
    field: 'tld',
    name: 'Top TLDs by Category',
  },
};

interface IComponentProps {
  type: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  activeOUId?: Nullable<number>;
}

function DashboardCategory({ type, startDate, endDate, activeOUId }: IComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [highlightValue, setHighlightValue] = useState<ILabelAndValueProps | undefined>(undefined);
  const [data, setData] = useState<IBarChartDataProps[]>([]);

  useEffect(() => {
    setIsLoading(true);
    dashboardService
      .getDomainCountsByCategoryAndField(
        TYPE_MAP[type].field,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        TYPE_MAP[type].query,
        activeOUId,
      )
      .then((res: any) => {
        setHighlightValue(res.metadata[0]);
        setData(res.counts);
        setIsLoading(false);
      });
  }, [type, startDate, endDate, activeOUId]);

  return (
    <BarChart
      title={TYPE_MAP[type].name}
      isLoading={isLoading}
      description=''
      highLightValue={highlightValue}
      data={data}
      orders={TYPE_MAP[type].orders}
      placeholder={{
        icon: NoHosting,
        title: 'No data found',
        description:
          'We haven’t found any data related to this period. Please select another time frame.',
      }}
    />
  );
}

export { DashboardCategory };

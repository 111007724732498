import React, { useState, useRef } from 'react';
import _ from 'lodash';
import '../../Style/SocialMedia.scss';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';
import { Form } from 'react-bootstrap';
import { IDropdownOption, Dropdown } from '../../../Common/Dropdown';
import TextArea from '../../../Common/TextArea';
import ModalOverlay from '../../../Common/ModalOverlay';
import { DashBoardDto, IContentCategory, IContentPlatform } from '../../../../constants/types';
import { UploadFiles } from '../../../Common/UploadFiles';
import { TextInput } from '../../../Common/TextInput';
import { alertActions } from '../../../../actions';
import { IAlertDispatchProps } from '../../../../constants';
import Label from '../../../Common/Label';
import { EFindingStatus, ugcType } from '../../Types/ugc.types';
import { ugcTypeAndInsightRoutes } from '../../constants';
import { useAppSelector } from '../../../../helpers/hooks';

interface IAddFindingForTakedownModalProps {
  show: boolean;
  onCloseModal: () => void;
  onSubmitModal: (data: any) => Promise<any>;
  ugcPlatforms: IContentPlatform[];
  ugcCategories: IContentCategory[];
  user: DashBoardDto;
  type: string;
  status: EFindingStatus;
}

interface INewFinding {
  url: string;
  platformId: string;
  originId: string;
  categoryId: string;
  comments: string;
  screenshot: File[];
  uploads: File[];
}

const AddFindingForTakedownModal = ({
  show,
  onCloseModal,
  onSubmitModal,
  ugcPlatforms,
  ugcCategories,
  type,
  status,
}: IAddFindingForTakedownModalProps & IAlertDispatchProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  const [originOptions, setOriginOptions] = useState<IDropdownOption[]>([]);
  const [searchTermSelected, setSearchTermSelected] = useState('');
  const values = useRef<INewFinding>({
    url: '',
    platformId: '',
    originId: '',
    categoryId: '',
    comments: '',
    screenshot: [],
    uploads: [],
  });
  const { searchTermsOptionsToDisplay } = useAppSelector(state => state.tableReducer);

  const updateValue = (value: any, field: string) => {
    values.current[field] = value;
    const { url, platformId, categoryId, originId } = values.current;
    if (type === ugcType.Social) {
      setIsInvalid(_.isEmpty(url) || !platformId || !categoryId || !originId);
    } else if (type === ugcType.App_Store) {
      setIsInvalid(_.isEmpty(url));
    } else {
      setIsInvalid(_.isEmpty(url) || !platformId || !categoryId);
    }
  };
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateValue(event.target.value, controlId);
  };
  const handleDropdownChange = (option: IDropdownOption, controlId: string) => {
    updateValue(option.value, controlId);
    if (controlId === 'platformId') {
      setOriginOptions(_.find(ugcPlatforms, ['value', option.value])!.origins);
    }
    if (controlId === 'searchTermId') {
      setSearchTermSelected(option.label);
    }
  };
  const handleFilesAdded = (files: File[], controlId: string) => {
    updateValue(files, controlId);
  };

  const onSubmit = () => {
    setIsLoading(true);
    const data = new FormData();
    let sentStatus = status;
    if (status === EFindingStatus.IN_PROGRESS_OR_PAUSED) {
      sentStatus = EFindingStatus.IN_PROGRESS;
    } else if (status === EFindingStatus.PENDING_OR_UNDER_REVIEW) {
      sentStatus = EFindingStatus.PENDING;
    }
    const { url, platformId, originId, categoryId, comments, screenshot, uploads } = values.current;
    data.append('url', url);
    data.append('srcUrl', url);
    data.append('searchTerm', searchTermSelected);
    data.append('platformId', platformId);
    data.append('originId', originId);
    data.append('categoryId', categoryId);
    data.append('comments', comments);
    data.append('screenshot', screenshot[0]);
    data.append('status', sentStatus);
    data.append('insightsLink', `${window.location.host}${ugcTypeAndInsightRoutes[type]}`);
    for (let i = 0; i < uploads.length; i++) {
      data.append('uploads', uploads[i]);
    }

    onSubmitModal(data)
      .then(() => {
        setSearchTermSelected('');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={`Add Detection`}
      onCancel={onCloseModal}
      onSubmit={onSubmit}
      submitButtonLabel={'Submit'}
      submitButtonDisabled={isInvalid}
    >
      <div className={'add-finding-for-takedown-modal'}>
        <Form>
          <Form.Group>
            <Label label='URL' isRequired />
            <TextInput controlId='url' onChange={handleTextFieldChange} />
          </Form.Group>
          {type !== ugcType.App_Store && (
            <>
              <Form.Group>
                <Label label='Platform' isRequired />
                <Dropdown
                  boxStyle
                  btnClassName='font-weight-normal'
                  controlId='platformId'
                  options={ugcPlatforms}
                  onChange={handleDropdownChange}
                />
              </Form.Group>
              {type === ugcType.Social && (
                <Form.Group>
                  <Label label='Origins' isRequired />
                  <Dropdown
                    boxStyle
                    btnClassName='font-weight-normal'
                    controlId='originId'
                    options={originOptions}
                    onChange={handleDropdownChange}
                    disabled={originOptions.length === 0}
                  />
                </Form.Group>
              )}
              <Form.Group>
                <Label label='Category' isRequired />
                <Dropdown
                  boxStyle
                  btnClassName='font-weight-normal'
                  controlId='categoryId'
                  options={ugcCategories}
                  onChange={handleDropdownChange}
                />
              </Form.Group>
              <Form.Group>
                <UploadFiles
                  label='Add Screenshot'
                  onChangeHandler={handleFilesAdded}
                  dragAndDrop
                  filesLimit={1}
                  controlId='screenshot'
                />
              </Form.Group>
            </>
          )}
          {type === ugcType.App_Store && (
            <>
              <Form.Group>
                <Label label='Search Term (optional)' />
                <Dropdown
                  boxStyle
                  btnClassName='font-weight-normal'
                  controlId='searchTermId'
                  options={searchTermsOptionsToDisplay}
                  onChange={handleDropdownChange}
                />
              </Form.Group>
            </>
          )}
          <Form.Group>
            <UploadFiles
              label='Add File'
              onChangeHandler={handleFilesAdded}
              dragAndDrop
              controlId='uploads'
            />
          </Form.Group>
          <TextArea label='Comments' onChange={updateValue} controlId='comments' />
        </Form>
      </div>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { contents } = state.appReducer;
  const { user } = state.dashboardReducer;
  return {
    ugcPlatforms: contents?.[type]?.platforms || [],
    ugcCategories: contents?.[type]?.categories || [],
    user,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFindingForTakedownModal);

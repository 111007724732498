import React, { ChangeEvent, useContext } from 'react';
import './pricing.scss';
import { PRICING_PLANS } from './types';
import PlanChip from './Common/PlanChip';
import PricingInfo from './Common/PricingInfo';
import { IPrice, ISubscriptionItem } from './types';
import CaptionBanner from './Common/CaptionBanner';
import ActionButton from './Common/ActionButton';
import Features from './Features';
import _ from 'lodash';
import {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';

import DomainSelector from './Common/DomainSelector';

export interface IPricingPlanProps {
  planName: PRICING_PLANS;
  price: string | IPrice[];
  captionText: string | { text: string; link: string; isExternalLink: boolean };
  captionIcon?: { img: string; position: 'start' | 'end' };
  description?: string;
  actionBtnText?: string;
  onActionBtnClick: () => any;
  featuresHeader?: string;
  features: string[];
  planChipText?: string;
  actionButtonPosition?: 'top' | 'bottom';
  actionButtonCustomClass?: string;
  hideActionBtn?: boolean;
  disableActionButton?: boolean;
  customClassName?: string;
}

const PricingPlan = ({
  planName,
  price,
  captionText,
  captionIcon,
  description,
  actionBtnText = '',
  features,
  onActionBtnClick,
  featuresHeader = '',
  planChipText,
  actionButtonPosition = 'top',
  actionButtonCustomClass = '',
  hideActionBtn = false,
  disableActionButton = false,
  customClassName,
}: IPricingPlanProps) => {
  const customClass = () => {
    if (!customClassName) return 'pricing-plan';
    return `pricing-plan ${customClassName}`;
  };
  return (
    <div className={customClass()} data-testid='pricing-plan-wrapper'>
      <PlanChip planName={planName} planChipText={planChipText} />
      <div className='plan-price-info-wrap'>
        <PricingInfo price={price} />
        {planName === PRICING_PLANS.STARTER && <DomainSelector />}
      </div>
      <CaptionBanner caption={captionText} captionIcon={captionIcon} planName={planName} />
      {!_.isEmpty(description) && <div className='description'>{description}</div>}
      {!hideActionBtn && actionButtonPosition === 'top' && (
        <ActionButton
          btnText={actionBtnText}
          onClick={onActionBtnClick}
          customClass={`${actionButtonCustomClass}`}
          planName={planName}
          disable={disableActionButton}
        />
      )}
      <Features features={features} planType={planName} featuresHeader={featuresHeader} />
      {!hideActionBtn && actionButtonPosition === 'bottom' && (
        <ActionButton
          btnText={actionBtnText}
          onClick={onActionBtnClick}
          customClass={`${actionButtonCustomClass}`}
          planName={planName}
          disable={disableActionButton}
        />
      )}
    </div>
  );
};

export default PricingPlan;

import * as React from 'react';
import _ from 'lodash';
import { AppState } from '../../helpers';
import { ThunkDispatch } from 'redux-thunk';
import { UserActionsTypes, UserForgotPassDto } from '../../constants';
import { bindActionCreators } from 'redux';
import { userActions } from '../../actions';
import { connect } from 'react-redux';
import Back from '../../assets/icons/Back.svg';
import './auth.scss';
import { Link } from 'react-router-dom';
import { CompanyIcon } from '../Common/CompanyIcon';

interface IUserForgotPassProps {
  forgotpass: (user: UserForgotPassDto) => void;
}

interface IUserForgotPassState {
  user: UserForgotPassDto;
  submitted: boolean;
  apiSubmitted: boolean;
}

type Props = IUserForgotPassProps & ILinkDispatchProps;

class ForgotPass extends React.Component<Props, IUserForgotPassState> {
  // private readonly userService: UserService;
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        email: '',
      },
      submitted: false,
      apiSubmitted: false,
    };
    window.document.title = 'Forget Password | Bolster Platform';
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user } = this.state;
    if (user.email) {
      this.setState({ apiSubmitted: true });
      // Send to the user service to submit to backend
      // this.userService.forgotPassword(user);
      console.log(user);
      this.props.forgotpass(user);
    }
  };

  render() {
    const { user, submitted, apiSubmitted } = this.state;
    return (
      <div>
        <div className={'auth-title '}>
          <CompanyIcon withName />
        </div>
        <div className={'auth-page-wrapper'}>
          <form name='form' onSubmit={this.handleSubmit}>
            <div className={'nav-form'}>
              <Link to={'/sign-in'}>
                <img src={Back} alt={'goback'} />
              </Link>
              <span>Forgot Password</span>
            </div>
            <div className='info-text'>
              Please enter the email associated with your account and we’ll send you a link to reset
              your password.
            </div>

            <div className={'form-group email-component'}>
              <label className={'email-label'}>Email</label>
              <input
                type='email'
                className='form-control'
                name='email'
                value={user.email}
                onChange={this.handleChange}
              />
              <div className='help-block'>
                {submitted && !user.email ? 'email is required' : ''}
              </div>
            </div>
            <div className={'helper-bottom'}>
              <button
                disabled={_.isEmpty(user.email) || apiSubmitted}
                type='submit'
                className='btn btn-primary'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

interface ILinkDispatchProps {
  forgotpass: (user: UserForgotPassDto) => void;
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapPropsToSate = (dispatch: ThunkDispatch<any, any, UserActionsTypes>) => ({
  forgotpass: bindActionCreators(userActions.forgotpass, dispatch),
});

const connectedForgotPass = connect(mapStateToProps, mapPropsToSate)(ForgotPass);
export { connectedForgotPass as ForgotPass };

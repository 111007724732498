import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

export interface IHeaderMetasProps {
  url: string;
  title: string;
  description: string;
  imagePath?: string;
  keywords?: string;
  type?: string;
}

const HeaderMetas: FunctionComponent<IHeaderMetasProps> = ({
  url = '',
  title = '',
  description = '',
  imagePath = 'https://checkphish.ai/static/media/og-image.9d427d73.png',
  keywords = 'url,website,phishing,scanner,analyze,cyber threat intelligence, open source threat intelligence, online fraud',
  type = 'website',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='robots' content='index, follow' />
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />

      <meta property='og:type' content={type} />
      {url !== '' && <meta property='og:url' content={url} />}
      <meta name='title' property='og:title' content={title} />
      <meta name='description' property='og:description' content={description} />
      <meta name='image' property='og:image' content={imagePath} />

      <meta name='twitter:description' content={description} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:image' content={imagePath} />
      <meta name='twitter:site' content='@checkphish' />
      <meta name='twitter:creator' content='@checkphish' />
    </Helmet>
  );
};

export { HeaderMetas };

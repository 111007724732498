import { DashBoardDto, appConstants, featureIsAvailable } from '../constants';

export const getIsWebModuleAvailable = (user: DashBoardDto) => {
  const isWebModuleAvailable =
    featureIsAvailable(user, appConstants.FEATURE_CODE.WEB) &&
    !featureIsAvailable(user, appConstants.FEATURE_CODE.WEB_DISABLED);

  return isWebModuleAvailable;
};

export const getIsSocialMediaModuleAvailable = (user: DashBoardDto) => {
  const isSocialMediaModuleAvailable =
    featureIsAvailable(user, appConstants.FEATURE_CODE.SOCIAL_MEDIA) &&
    !featureIsAvailable(user, appConstants.FEATURE_CODE.SOCIAL_DISABLED);

  return isSocialMediaModuleAvailable;
};

export const getIsAppStoreModuleAvailable = (user: DashBoardDto) => {
  const isAppStoreModuleAvailable =
    featureIsAvailable(user, appConstants.FEATURE_CODE.APP_STORE) &&
    !featureIsAvailable(user, appConstants.FEATURE_CODE.APP_STORE_DISABLED);

  return isAppStoreModuleAvailable;
};

export const getIsDarkWebModuleAvailable = (user: DashBoardDto) => {
  const isDarkWebModuleAvailable =
    featureIsAvailable(user, appConstants.FEATURE_CODE.DARK_WEB_V2) &&
    !featureIsAvailable(user, appConstants.FEATURE_CODE.DARK_WEB_DISABLED);

  return isDarkWebModuleAvailable;
};

import {  IAppDispatchProps, InsightsDto } from "../../constants";
import { ELoggedIn } from "../../reducers";

export const DEFAULT_FILTER = {
    label: 'All',
    value: 'all',
};

export interface IInsightsState {
    socialMedia: {
        url: string;
        link: string;
        isLoading: boolean;
    };
}

export interface IProps {
    loggedIn?: ELoggedIn;
    type?: string;
    insightsData?: {
        scanResults: InsightsDto;
        markerPosition: {
            lat: number;
            lng: number;
        };
        certList: Record<string, string>[];
    };
    isLoading?: boolean;
    isPublic?: boolean;
    webDashboardRoute?: any;
    urlTimeStamp?: string
    urlSha256?: string;
}

export type InsightProps = IProps & IAppDispatchProps;

export enum UgcScanCategoryType {
    'Social Media Scan' = 'social',
    'App Store Scan' = 'app-store',
}
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setGlobalTakedownRowData } from '../../reducers/table.reducer';
import { ETimelineViewModes } from './TakedownTimelineContainer';

export default function useOnTakedownTimelineEvents({ data, rowId }: any) {
  const dispatch = useAppDispatch();
  const globalTakedownRowData = useAppSelector(state => state.tableReducer.globalTakedownRowData);

  //convert into the hook
  useEffect(() => {
    const { monthlyTakedownEvents, yearlyTakedownEvents } = data;

    if (monthlyTakedownEvents && Object.keys(monthlyTakedownEvents).length) {
      dispatch(
        setGlobalTakedownRowData({
          ...globalTakedownRowData,
          [rowId]: {
            data: monthlyTakedownEvents,
            viewMode: ETimelineViewModes.MONTHLY,
          },
        }),
      );
    }
    if (yearlyTakedownEvents && Object.keys(yearlyTakedownEvents).length) {
      dispatch(
        setGlobalTakedownRowData({
          ...globalTakedownRowData,
          [rowId]: {
            data: yearlyTakedownEvents,
            viewMode: ETimelineViewModes.YEARLY,
          },
        }),
      );
    }
  }, [rowId, dispatch]);
}

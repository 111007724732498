import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  FilterChangedEvent,
  SelectionChangedEvent,
  IRowNode,
  FilterModel,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import {
  DarkWebTableApiV2Params,
  convertToFetchDarkWebApiParams,
  getAgGridThemeClassName,
} from './ag-utils';
import { fetchDarkWebData, getSearchTermFindings } from './ag-requests';
import ThemeContext from '../../../../context/ThemeContext';
import { appConstants, getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';
import { generateDarkWebTableColumnDefs } from './ag-col-defs';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import {
  setCurrentRowIndex,
  setIsPersistentFilterSaveButtonVisible,
  setOutGoingPersistentFilterString,
} from '../../../../reducers/table.reducer';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { useSearchTermFilters } from './useSearchTermFilters';
import { ChangeDarkWebFindingStatus } from './ChangeDarkWebFindingStatus';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import useOnCustomTagActions from '../../CustomHooks/useOnCustomTagActions';
import useOnTagFilterOptions from '../../CustomHooks/useOnTagFilterOptions';
import { useAgGridEvents } from './useAgGridEvents';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { TABLE_EMPTY_RESULTS_MESSAGE } from './constants';
import { alertActions } from '../../../../actions';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import useOnAgCellClicked from './useOnAgCellClicked';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);
interface IProps {
  darkwebStatus?: EDarkWebFindingStatus;
  widgetDetails: any;
  extraFilters?: DarkWebTableApiV2Params;
  additionalFields?: string[];
  statusUpdateSuccess: () => void;
  handleGroupFilterChange: (filterModel: FilterModel) => void;
}

const AgGridDarkWebTable = ({
  darkwebStatus,
  widgetDetails,
  extraFilters,
  additionalFields,
  statusUpdateSuccess,
  handleGroupFilterChange,
}: IProps) => {
  const agRef = useRef<AgGridReact>(null);
  const [allRowData, setAllRowData] = useState<object[]>([]);
  const { selectedTheme } = useContext(ThemeContext);
  const { onTableSaveColumns, onItemCheck, tableId, displayedColumnsIDs, columns } =
    useContext(TableContext);
  const dispatch = useAppDispatch();

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  useSearchTermFilters({ agRef, columns, colFieldForSearchTerm: 'search_term' });

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({
      tableId: tableId ?? '',
    });

  const colDefs = useMemo<ColDef[]>(() => {
    return generateDarkWebTableColumnDefs({
      columns,
      firstRenderDisplayColumnIdSet,
      renderGroupActionBtn: (params: any) => {
        return <ChangeDarkWebFindingStatus params={params} onSubmitSuccess={statusUpdateSuccess} />;
      },
      enableCheckbox: true,
    });
  }, [columns, displayedColumnsIDs]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);

  const isReadOnlyUser = useReadOnlyUser();

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      cellRendererParams: {
        checkbox: !isReadOnlyUser,
      },
      width: 300,
      colSpan: (params: any) =>
        params?.node?.id?.includes('.com') ? displayedColumnsIDs.length : 1,
      resizable: true,
      sortable: false,
    };
  }, [isReadOnlyUser]);

  const isFilterModelCacheUsed = useRef(false);

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );

  const getGroupedDataRows = () => {
    if (widgetDetails.searchterms) {
      const allKeys = Object.keys(widgetDetails.searchterms);
      if (allKeys.length > 0) {
        return {
          total: allKeys.length,
          data: allKeys.map(k => {
            return {
              domain: k,
              related_findings_count: widgetDetails.searchterms[k].totalFindings,
              search_term: k,
              extraFilters,
              darkwebStatus,
            };
          }),
        };
      }
    }
    return {
      total: 0,
      data: [],
    };
  };

  const getExpandedDataRows = (agRequest: IServerSideGetRowsRequest) => {
    const searchTerm = agRequest.groupKeys[0];
    const limit = widgetDetails.searchterms[searchTerm].totalFindings;
    const searchTermIds = [widgetDetails.searchterms[searchTerm].searchTermId];
    const apiParams = convertToFetchDarkWebApiParams(
      agRequest,
      limit,
      darkwebStatus,
      {
        ...extraFilters,
        searchTermIds,
      },
      additionalFields,
    );
    apiParams.filter = apiParams.filter.replace('tags', 'tags.id');
    return fetchDarkWebData(apiParams, true);
  };

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[]; total?: number } = { data: [], total: 0 };
        const isGroupDetailsRequest = agRequest.groupKeys.length > 0;
        try {
          if (!isGroupDetailsRequest) {
            const { total, data } = getGroupedDataRows();
            response.data = data;
            response.total = total;
          } else {
            const rawResponse = await getExpandedDataRows(agRequest);
            const mappedData: any = rawResponse.findings.map((item: any) => ({
              ...item,
              entity_type: extraFilters?.entity?.[0],
            }));
            response.data = mappedData || [];
            response.total = rawResponse?.metadata?.total || 0;
          }
          if (response.total === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }
          setAllRowData(response.data);
          setTimeout(() => {
            params.success({
              rowData: response.data,
              rowCount: response.total,
            });
          }, 500);
        } catch (error) {
          console.log('Error:', error);
          params.fail();
        }
      },
    };
  }, []);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      // first render, only initiate filter model from local storage.
      const filterModelCache = tableId ? getLocalStorageValue(['tableFilterModel', tableId]) : {};
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource],
  );

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${
      params.data.content_sha_256 ? params.data.content_sha_256 : params.data.domain
    }`;
  }, []);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId: tableId ?? '',
    allRowData,
  });

  const handleChildNodes = async (
    event: SelectionChangedEvent<AgGridColumnType, any>,
    allFindingsSha: any[],
    toggledNodes: { nodeId: string; selectAllChildren: boolean; toggledNodes: any[] }[],
  ) => {
    const { api } = event;
    for (let i = 0; i < toggledNodes.length; i++) {
      const { nodeId, selectAllChildren, toggledNodes: childNodes } = toggledNodes[i];
      if (selectAllChildren !== undefined) {
        if (selectAllChildren) {
          const selectedRows: any[] = api.getSelectedRows();
          const parentNode = selectedRows.shift();
          const { domain, related_findings_count } = parentNode;
          if (selectedRows.length > 0) {
            selectedRows.map((m: { content_sha_256: string }) =>
              allFindingsSha.push({ content_sha_256: m.content_sha_256 }),
            );
          } else {
            // By default open the row group if not expanded
            const rowNode = api.getRowNode(nodeId) as IRowNode<AgGridColumnType>;
            api.setRowNodeExpanded(rowNode, true);

            const response = await getSearchTermFindings(
              darkwebStatus as EDarkWebFindingStatus,
              extraFilters as DarkWebTableApiV2Params,
              [domain],
              related_findings_count,
            );
            response.findings.forEach((m: { content_sha_256: string }) =>
              allFindingsSha.push({ content_sha_256: m.content_sha_256 }),
            );
          }
        } else {
          await handleChildNodes(event, allFindingsSha, childNodes);
        }
      } else {
        const {
          data: { content_sha_256 },
        } = api.getRowNode(nodeId) as IRowNode<any>;
        allFindingsSha.push({ content_sha_256 });
      }
    }
  };

  const onSelectionChanged = useCallback(
    async (event: SelectionChangedEvent<AgGridColumnType, any>) => {
      const selectedRows: any = event.api.getServerSideSelectionState();
      const { toggledNodes, selectAllChildren } = selectedRows;
      const allFindingsSha: any[] = [];
      if (selectAllChildren) {
        const searchTerms: string[] = [];
        let searchTermsCount = 0;
        Object.keys(widgetDetails.searchterms).map(k => {
          searchTerms.push(k);
          searchTermsCount = searchTermsCount + widgetDetails.searchterms[k].totalFindings;
        });
        const response = await getSearchTermFindings(
          darkwebStatus as EDarkWebFindingStatus,
          extraFilters as DarkWebTableApiV2Params,
          searchTerms,
          searchTermsCount,
        );
        response.findings.forEach((m: { content_sha_256: string }) =>
          allFindingsSha.push({ content_sha_256: m.content_sha_256 }),
        );
      } else {
        if (toggledNodes) {
          await handleChildNodes(event, allFindingsSha, toggledNodes);
        }
      }
      onItemCheck?.(true, '', allFindingsSha);
    },
    [onItemCheck],
  );

  const handleFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      const filterModel = event.api.getFilterModel();
      const filterModelIsNotEmpty = Object.keys(filterModel).length !== 0;
      const isAgGridDateRangeFilter = event?.columns[0]?.getColDef()?.filter?.name ? true : false;
      // from dashboard, or user interacted with filter, show the button
      const shouldShowSaveButton =
        (event.source !== 'api' && filterModelIsNotEmpty) ||
        (isAgGridDateRangeFilter && filterModelIsNotEmpty);
      dispatch(setIsPersistentFilterSaveButtonVisible(shouldShowSaveButton));
      dispatch(setOutGoingPersistentFilterString(JSON.stringify(filterModel)));
      if (tableId) {
        setLocalStorageValue(['tableFilterModel', tableId], filterModel);
      }
      handleGroupFilterChange(filterModel);
    },
    [dispatch, tableId, handleGroupFilterChange],
  );

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  //Custom hooks for triggering fetch call on custom tag action
  useOnCustomTagActions(agRef);

  //custom hook to add new custom tag filter options
  useOnTagFilterOptions(agRef, columns, 'tags.id', appConstants.CONTENT_TYPE.DARK_WEB);

  const currentRowIndex = useAppSelector(state => state.tableReducer.currentRowIndex);
  //Custom hook to highlight the cell
  const { onCellClicked } = useOnAgCellClicked();
  //Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(setIsPersistentFilterSaveButtonVisible(false));
      dispatch(setOutGoingPersistentFilterString('{}'));
      dispatch(setCurrentRowIndex(-1));
    };
  }, [dispatch]);

  const { handleFirstDataRendered, handleOnPaginationChanged } = usePersistentTablePageNumber(
    tableId || '',
  );

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme)}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={false}
        cacheBlockSize={0}
        blockLoadDebounceMillis={1000}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        groupSelectsChildren={true}
        groupSelectsFiltered={true}
        groupDisplayType={'multipleColumns'}
        showOpenedGroup={true}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={handleFilterChanged}
        getRowClass={params => {
          if (params.node.group) {
            return 'darkweb-column-group';
          }
          return '';
        }}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        onCellClicked={onCellClicked}
        context={{
          cellClickedIndex: currentRowIndex,
        }}
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default AgGridDarkWebTable;

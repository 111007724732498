import React from 'react';
import _ from 'lodash';
import './FilterFaq.scss';
import { PageTitle } from '../Common/PageTitle';
import urlFilter from '../../assets/filterFaq/url_filter.png';
import urlResult from '../../assets/filterFaq/url_result.png';
import ipFilter from '../../assets/filterFaq/ip_filter.png';
import ipResult from '../../assets/filterFaq/ip_result.png';
import statusFilter from '../../assets/filterFaq/status_filter.png';
import statusResult from '../../assets/filterFaq/status_result.png';
import hostingProviderFilter from '../../assets/filterFaq/hostingProvider_filter.png';
import hostingProviderResult from '../../assets/filterFaq/hostingProvider_result.png';
import dispositionFilter from '../../assets/filterFaq/disposition_filter.png';
import dispositionResult from '../../assets/filterFaq/disposition_result.png';
import takedownsFilter from '../../assets/filterFaq/takedowns_filter.png';
import takedownsResult from '../../assets/filterFaq/takedowns_result.png';
import sourceFilter from '../../assets/filterFaq/source_filter.png';
import sourceResult from '../../assets/filterFaq/source_result.png';
import urlOrFilter from '../../assets/filterFaq/urlOr_filter.png';
import urlOrResult from '../../assets/filterFaq/urlOr_result.png';
import urlAndDispositionFilter from '../../assets/filterFaq/urlAndDisposition_filter.png';
import urlAndDispositionResult from '../../assets/filterFaq/urlAndDisposition_result.png';
import andOrFilter from '../../assets/filterFaq/andOr_filter.png';
import andOrResult from '../../assets/filterFaq/andOr_result.png';

interface IFilterMethod {
  name: string;
  explanation?: string;
  options?: IFilterMethod[];
  scSetting?: string;
  scResult?: string;
}

interface IFaqConfig {
  filterBy: string;
  filterMethods: IFilterMethod[];
}

const FAQ_CONFIG: IFaqConfig[] = [
  {
    filterBy: 'Source URL',
    filterMethods: [
      {
        name: 'Includes',
        explanation: 'Return the sites whose source URL contains the specified string.',
        scSetting: urlFilter,
        scResult: urlResult,
      },
      {
        name: 'Does Not Include',
        explanation: 'Return the sites whose source URL does NOT contain the specified string.',
      },
    ],
  },
  {
    filterBy: 'IP Address',
    filterMethods: [
      {
        name: 'Begins with',
        explanation: 'Return the sites whose IP address starts with the specified IP Address.',
        scSetting: ipFilter,
        scResult: ipResult,
      },
      {
        name: 'Does not begin with',
        explanation:
          'Return the sites whose IP address does not start with the specified IP Address.',
        scSetting: ipFilter,
        scResult: ipResult,
      },
    ],
  },
  {
    filterBy: 'Status',
    filterMethods: [
      {
        name: 'Is',
        options: [
          {
            name: 'Active',
            explanation: 'Return the sites whose status is "Active".',
            scSetting: statusFilter,
            scResult: statusResult,
          },
          {
            name: 'Down',
            explanation: 'Return the sites whose status is "Down".',
          },
        ],
      },
      {
        name: 'Is not',
        options: [
          {
            name: 'Active',
            explanation: 'Return the sites whose status is Not "Active".',
          },
          {
            name: 'Down',
            explanation: 'Return the sites whose status is Not "Down".',
          },
        ],
      },
    ],
  },
  {
    filterBy: 'Host Provider',
    filterMethods: [
      {
        name: 'Includes',
        explanation: 'Return the sites whose Host Provider contains the specified string.',
        scSetting: hostingProviderFilter,
        scResult: hostingProviderResult,
      },
      {
        name: 'Does Not Include',
        explanation: 'Return the sites whose Host Provider does NOT contain the specified string.',
      },
    ],
  },
  {
    filterBy: 'Disposition',
    filterMethods: [
      {
        name: 'Is',
        options: [
          {
            name: 'Phish',
            explanation: 'Return the sites whose disposition is "phish".',
            scSetting: dispositionFilter,
            scResult: dispositionResult,
          },
          {
            name: 'Clean',
            explanation: 'Return the sites whose status is "Clean".',
          },
          {
            name: 'Suspicious',
            explanation: 'Return the sites whose status is "Suspicious".',
          },
          {
            name: 'Scam',
            explanation: 'Return the sites whose status is "Scam".',
          },
          {
            name: 'Phish',
            explanation: 'Return the sites whose status is "Phish".',
          },
        ],
      },
      {
        name: 'Is Not',
        options: [
          {
            name: 'Clean',
            explanation: 'Return the sites whose status is NOT "Clean".',
          },
          {
            name: 'Phish',
            explanation: 'Return the sites whose disposition is NOT "phish".',
          },
          {
            name: 'Suspicious',
            explanation: 'Return the sites whose status is NOT "Suspicious".',
          },
          {
            name: 'Scam',
            explanation: 'Return the sites whose status is NOT "Scam".',
          },
        ],
      },
    ],
  },
  {
    filterBy: '# Takedowns',
    filterMethods: [
      {
        name: 'In range',
        explanation:
          'Defined the range with two numbers separated by a comma. It will return the sites whose # Takedowns is within the specified range.',
        scSetting: takedownsFilter,
        scResult: takedownsResult,
      },
    ],
  },
  {
    filterBy: 'Source',
    filterMethods: [
      {
        name: 'Includes',
        explanation: 'Return the sites whose Source contains the specified string.',
        scSetting: sourceFilter,
        scResult: sourceResult,
      },
      {
        name: 'Does Not Include',
        explanation: 'Return the sites whose Source does NOT contain the specified string.',
      },
    ],
  },
];

interface IFilterFaqProps {}

interface IFilterFaqState {}

class FilterFaq extends React.Component<IFilterFaqProps, IFilterFaqState> {
  shouldComponentUpdate(): boolean {
    return false;
  }

  renderExample(text: string, filter: any, result: any) {
    return (
      <div className={'example'}>
        <div className={'example-condition'}>{text}</div>
        {this.renderScreenshotExample(filter, result)}
      </div>
    );
  }

  renderScreenshotExample(filter: any, result: any) {
    if (filter && result) {
      return (
        <div className={'example-screenshot'}>
          <div>Filter Setting:</div>
          <img src={filter} alt={'Filter Usage Example'} className={'filter-image'} />
          <div>Result:</div>
          <img src={result} alt={'Filter Result'} className={'result-image'} />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={'filter-faq-page'}>
        <div className={'filter-faq-page-content'}>
          <PageTitle title={'Filter FAQ'} />
          <div className={'page-content filter-faq-content'}>
            {FAQ_CONFIG.map((faq: IFaqConfig) => {
              return (
                <div className={'level'} key={faq.filterBy} id={faq.filterBy}>
                  <div className={'filter-item'}>{faq.filterBy}</div>
                  {faq.filterMethods.map((method: IFilterMethod) => {
                    return (
                      <div className={'level'} key={method.name}>
                        <div className={'filter-item'}>{method.name}</div>
                        {_.isEmpty(method.options) ? (
                          <div className={'level'}>
                            <div className={'filter-explanation'}>{method.explanation}</div>
                            {this.renderScreenshotExample(method.scSetting, method.scResult)}
                          </div>
                        ) : (
                          method.options?.map((option: IFilterMethod) => {
                            return (
                              <div className={'level'} key={option.name}>
                                <div className={'filter-item'}>{option.name}</div>
                                <div className={'level'}>
                                  <div className={'filter-explanation'}>
                                    {option.explanation}
                                    {this.renderScreenshotExample(
                                      option.scSetting,
                                      option.scResult,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={'more-examples'} id={'examples'}>
            <div className={'more-examples-title'}>More Examples</div>
            {this.renderExample(
              'Combine filters with the same category will result in a "or" logic. For example:',
              urlOrFilter,
              urlOrResult,
            )}
            {this.renderExample(
              'Combine filters with the different categories will result in a "and" logic. For example:',
              urlAndDispositionFilter,
              urlAndDispositionResult,
            )}
            {this.renderExample(
              'Combine filters with same category and different categories:',
              andOrFilter,
              andOrResult,
            )}
          </div>
        </div>
        <div className={'page-content filter-faq-page-menu'}>
          {FAQ_CONFIG.map((faq: IFaqConfig) => {
            return (
              <a key={faq.filterBy} href={'#' + faq.filterBy}>
                {faq.filterBy}
              </a>
            );
          })}
          <a key={'examples'} href={'#examples'} id={'more-examples-link'}>
            More Examples
          </a>
        </div>
      </div>
    );
  }
}

export { FilterFaq };

import fetch from './api.service';
import { handleResponse } from './serviceWorker';
import { cleanUpParameters } from '../constants';

export default class SuperAdminService {
  switchBrand(brandName: string) {
    return fetch('/platform-api/v1/platform-administration/switch-brand', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand_name: brandName,
      }),
    })
      .then(handleResponse)
      .then(user => {
        try {
          cleanUpParameters(false);
        } catch (e) {
          // ignore
        }

        try {
          user?.result === true && window.location.reload();
        } catch (e) {
          // ignore
        }

        return user;
      });
  }

  loadAllBrands() {
    return fetch('/platform-api/v1/platform-administration/get-all-brands', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(handleResponse);
  }
}

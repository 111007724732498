import React from 'react';
import './PillLabel.scss';
interface IPillLablelProps {
  label: string;
  backgroundColor?: string;
  customStyle: React.CSSProperties;
}

export default function PillLabel({ label, backgroundColor, customStyle }: IPillLablelProps) {
  return (
    <div
      className='pills-label-wrapper d-flex align-items-center justify-content-center'
      style={customStyle || { backgroundColor }}
    >
      {label}
    </div>
  );
}

import React from 'react';
import './dashboard.scss';
import Replace from '../../assets/icons/Replace.svg';

interface IComponentProps {
  shown: boolean;
  onClick: () => void;
  className?: string;
}

const ReplaceButton = (props: IComponentProps) => {
  const { shown, className = '', onClick } = props;
  if (shown) {
    return (
      <div className={'hide-button-container replace-button-container ' + className}>
        <img src={Replace} alt={'hide'} onClick={onClick} className={'hide-widget-button'} />
      </div>
    );
  }
  return null;
};

export { ReplaceButton };

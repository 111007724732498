import React from 'react';
import {
  BRAND_COLUMN,
  BRAND_PROPERTY_ACTION_COL,
  DOCUMENT_PROPERTY_COL,
  NOTES_COL,
  PROPERTY_CREATED_BY_COL,
  PROPERTY_CREATED_ON_COL,
} from './AssetsColDefination/BrandPropertyCols';
import BrandPropertyComponent from './BrandPropertyComponent';
import { DOCUMNET_TABLE_NAME, DOCUMENTS_TABLE_ID } from './constants';
import { PropertyType } from './PropertyTypes';

const DocumentAssets = () => {
  const PAGE_TITLE = 'Documents';
  window.document.title = `${PAGE_TITLE} | Assets`;

  const TABLE_COLUMNS = [
    BRAND_COLUMN,
    DOCUMENT_PROPERTY_COL,
    PROPERTY_CREATED_ON_COL,
    PROPERTY_CREATED_BY_COL,
    NOTES_COL,
    BRAND_PROPERTY_ACTION_COL,
  ];
  const defaultColumnIds = [
    'brand',
    'documents',
    'created_ts',
    'created_by',
    'notes',
    'assets-action-col',
  ];

  return (
    <BrandPropertyComponent
      tableName={DOCUMNET_TABLE_NAME}
      id={DOCUMENTS_TABLE_ID}
      columns={TABLE_COLUMNS}
      title={PAGE_TITLE}
      disableDatePicker
      type={PropertyType.DOCUMENT}
      defaultColumnIds={defaultColumnIds}
    />
  );
};

export default DocumentAssets;

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { setShouldTriggerAgGrigRefresh } from '../../../reducers/table.reducer';

export default function useOnAgGridRefresh(agRefs: React.MutableRefObject<any>) {
  const dispatch = useAppDispatch();

  const triggerAgGridRefresh = useAppSelector(
    state => state.tableReducer.shouldTriggerAgGrigRefresh,
  );

  useEffect(() => {
    if (agRefs.current && agRefs.current.api && triggerAgGridRefresh) {
      agRefs.current.api.refreshServerSide();
    }
  }, [agRefs, triggerAgGridRefresh]);

  useEffect(() => {
    return () => {
      dispatch(setShouldTriggerAgGrigRefresh(false));
    };
  }, [dispatch]);
}

import React, { useContext, useState } from 'react';
import _, { groupBy } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IFilter } from '../../../../Common/Table/constant';
import { convertToThousand, generateId } from '../../../../../constants';
import {
  LOGO_DETECTION_COLUMN,
  SEARCH_ENGINE_COLUMN,
} from '../../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import { UNREGISTERED_DOMAINS } from '../../MonitoredDomains/MonitoredDomainFunnel';
import { InfoIcon } from '../../../../../assets/SVGIcons';
import { DropdownMultiSelect, IOption } from '../../../../Common/DropdownMultiSelect';
import CheckPhishContactUsContext from '../../../../../context/CheckPhishContactUsContext';

interface IProps {
  title: any;
  updateFilter: any;
  deleteFilter: any;
  monitoredDomains: any;
  enableCommunity: boolean;
}

const MonitoredDomainsHeaderV2 = ({
  title,
  updateFilter,
  deleteFilter,
  monitoredDomains,
  enableCommunity,
}: IProps): JSX.Element => {
  const domainCountsByGroup = groupBy(monitoredDomains, 'label');
  const totalVariants = _.first(domainCountsByGroup[UNREGISTERED_DOMAINS])?.subCount?.count;
  const [selectedFields, setSelectedFields] = useState<IOption[]>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const checkphishContactUsContext = useContext(CheckPhishContactUsContext);
  const addButtonClickToSelectedFields = (filter: IFilter) => {
    const field = {
      label: filter.filterBy.label,
      value: filter.filterBy.value,
    };
    if (selectedFields.some(item => item.value === field.value)) {
      const index = selectedFields.indexOf(field);
      const currentActiveFields = selectedFields;
      currentActiveFields.splice(index, 1);
      setSelectedFields(currentActiveFields);
    } else {
      setSelectedFields([...selectedFields, field]);
    }
  };
  const toggleFilter = (filter: IFilter) => {
    const filterByValue = filter.filterBy.value;
    const index = activeFilters.indexOf(filterByValue);

    if (index < 0) {
      activeFilters.push(filterByValue);
      updateFilter(filter, true);
    } else {
      activeFilters.splice(index, 1);
      deleteFilter(filterByValue);
    }

    setActiveFilters(activeFilters);
  };
  function getSelectionClick(a: any[], b: any[]) {
    return a.filter(element => {
      return !b.includes(element);
    });
  }
  const onDropDownSelections = (selection: IOption[]) => {
    const difference =
      selectedFields.length > selection.length
        ? getSelectionClick(selectedFields, selection)
        : getSelectionClick(selection, selectedFields);
    const field = getFilterFields().find(item => item.filterBy.value === difference[0]?.value);

    if (field) {
      toggleFilter(field);
    }
    setSelectedFields(selection);
  };
  const getFilterFields = (): IFilter[] => {
    return [
      {
        id: generateId(10),
        filterType: 'options',
        filterBy: { label: 'MX Records', value: 'has_mx_records' },
        filterMethod: { label: 'Is', value: 'is' },
        filterValue: 'true',
        filterLabel: 'True',
        width: 120,
      },
      {
        id: generateId(10),
        filterType: 'options',
        filterBy: { label: 'Logo Detected', value: LOGO_DETECTION_COLUMN.accessor },
        filterMethod: { label: 'Is', value: 'is' },
        filterValue: 'true',
        filterLabel: 'True',
        width: 180,
        hideFilter: enableCommunity,
      },
      {
        id: generateId(10),
        filterType: 'options',
        filterBy: { label: 'Google/Bing Searches', value: SEARCH_ENGINE_COLUMN.accessor },
        filterMethod: { label: 'Is', value: 'is' },
        filterValue: '*_searchengine',
        filterLabel: 'True',
        width: 220,
        hideFilter: enableCommunity,
      },
    ];
  };

  const triggerCheckphishContactUs = () => {
    const { monitoredDomain, setContactUsMesage, setShowContactUsPage } =
      checkphishContactUsContext;
    setContactUsMesage(
      `For access to additional features, and to further monitor ${monitoredDomain}, contact us.`,
    );
    setShowContactUsPage(true);
  };

  const mobileOptions = getFilterFields().map(field => {
    return {
      label: field.filterBy.label,
      value: field.filterBy.value,
      disableSelect: field.hideFilter,
      disableSelectAction: triggerCheckphishContactUs,
    };
  });

  const filterClick = (field: IFilter) => {
    if (field.hideFilter) {
      triggerCheckphishContactUs();
    } else {
      toggleFilter(field);
      addButtonClickToSelectedFields(field);
    }
  };

  return (
    <div className='monitored-domains-header-v2'>
      <div className='monitored-domains-title-v2'>
        <div className='title-container-v2'>
          <div className='title-wrapper-v2'>{title}</div>
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-unregister`} className='monitor-domains-icon-tooltip'>
                {`We monitor registered domains daily to detect changes that may move domains to the right. We also monitor all ${convertToThousand(
                  totalVariants,
                )} domain variations weekly to refresh the set of sites
                    recommended for purchase.`}
              </Tooltip>
            }
          >
            <div className='icon'>
              <InfoIcon color='grey' />
            </div>
          </OverlayTrigger>
        </div>
        <div className='filter-container'>
          <span className='button-small'>Filter domains by:</span>
          {getFilterFields().map(field => {
            return (
              <button
                key={field.filterBy.value}
                className={`btn btn-default round-filter ${
                  activeFilters.includes(field.filterBy.value) ? 'active' : ''
                }`}
                onClick={() => filterClick(field)}
                style={{ width: field?.width }}
              >
                {field.filterBy.label}
              </button>
            );
          })}
        </div>
        <div className='filter-container-mobile'>
          <DropdownMultiSelect
            options={mobileOptions}
            hideFooter
            placeholder='Filter domains by:'
            onSubmit={selections => onDropDownSelections(selections)}
            initialSelections={selectedFields}
            updateOnChange
            removable
          />
        </div>
      </div>
      <div className='description'>
        Bolster scans, takes down, and continues scanning suspicious/malicious domains.
      </div>
    </div>
  );
};

export default MonitoredDomainsHeaderV2;

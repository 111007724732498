import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Switch, Tooltip as MUToolTip } from '@material-ui/core';
import './typosquat.scss';
import '../Dashboard_v2/SubComponents/MonitoredDomainsV2/MonitoredContainerV2.scss';
import DashboardService from '../../services/dashboard.service';
import { PageTitle } from '../Common/PageTitle';
import { IBarChartDataProps, TColorMap } from '../Common/DashboardWidgets/Types/chart.type';
import BarChart from '../Common/DashboardWidgets/BarChart';
import { connect } from 'react-redux';
import { alertActions } from '../../actions';
import {
  AlertActionsTypes,
  convertToThousand,
  generateId,
  getColors,
  getLocalStorageValue,
  setLocalStorageValue,
  unsetLocalStorageValue,
} from '../../constants';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import ThemeContext from '../../context/ThemeContext';
import { Alert, Badge, Button, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import CheckPhishContactUsContext, {
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import FilledRightArrow from '../../assets/icons/RightArrowFilled.svg';
import ContiniuosMonitoring from '../../assets/Continiuos_monitoring.svg';
import WarningRedRoundIcon from '../../assets/icons/Warning-round-red-border.svg';
import _ from 'lodash';
import { IFilter } from '../Common/Table/constant';
import { TYPOSQUATTING_MASTER_PATHNAME, TYPOSQUAT_TABLE_ID_DASH, TypoJobStatus } from './constant';
import { history } from '../../helpers';
import TyposquatBlurredModal from './TyposquatBlurredModal';
import TyposquatReminderModal from './TyposquatReminderModal';
import {
  AggregatedTyposquatFetchResultsDashboard,
  DeltaChangeFromAPI,
  DeltaChangeFromItem,
} from '../../types/typosquat-dashboard.interface';
import {
  DELTA_DOMAIN,
  DELTA_CHANGE_TYPE,
  DELTA_CHANGE_PREVIOUS,
  DELTA_CHANGE_CURRENT,
  DELTA_LAST_SCAN,
  DELTA_CHANGE_AT,
} from './columns';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../Common/Table/ag-table/custom.ag-grid.scss';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { getAgGridThemeClassName } from '../Common/Table/ag-table/ag-utils';
import {
  generateTyposquattingDeltaColumnDefs,
  generateTyposquattingDeltaFlatColumnDefs,
} from '../Common/Table/ag-table/ag-col-defs';
import { PRICING_PAGE_PATH } from '../Pricing/constants';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const dashboardService = new DashboardService();
const deltaColumns = [
  DELTA_DOMAIN,
  DELTA_CHANGE_TYPE,
  DELTA_CHANGE_PREVIOUS,
  DELTA_CHANGE_CURRENT,
  DELTA_LAST_SCAN,
  DELTA_CHANGE_AT,
];
const barChartMapper = {
  hostMatrix: 'Top Hosting Provider by Risk',
  tldMatrix: 'Top TLDs by Risk',
};

interface ITypoDashboardV2Props {
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  typosquatJobStatus: boolean;
  changeTyposquatJobStatus: (status: boolean) => void;
}

const TypoDashboardV2 = ({
  alertSuccess,
  alertError,
  changeTyposquatJobStatus,
}: ITypoDashboardV2Props) => {
  const { selectedTheme } = useContext(ThemeContext);
  const checkphishContactUsContext = useContext(CheckPhishContactUsContext);
  const [data, setData] = useState<any>({});
  const [showAfterTrialModal, setShowAfterTrialModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [scanLimit, setScanLimit] = useState<number>(1);
  const [usedScans, setUsedScans] = useState<number>(0);
  const { currentTyposquatSubscription } = useContext(CheckphishSubscriptionContext);
  const agRef = useRef<AgGridReact>(null);
  const [userTyposquatDataList, setUserTyposquatDataList] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [deltaData, setDeltaData] = useState<Record<string, DeltaChangeFromAPI>>({});
  const [deltaFlatData, setDeltaFlatData] = useState<object[]>([]);
  const [deltaTableGroupedView, setDeltaTableGroupedView] = useState<boolean>(false);
  const colorMap: TColorMap = {
    5: getColors(selectedTheme).typosquattingRiskScore5,
    4: getColors(selectedTheme).typosquattingRiskScore4,
    3: getColors(selectedTheme).typosquattingRiskScore3,
    2: getColors(selectedTheme).typosquattingRiskScore2,
    1: getColors(selectedTheme).typosquattingRiskScore1,
  };

  const {
    setShowExploreDemoPopup,
    selectedDomain,
    setSelectedDomain,
    setDomainList,
    setPaidUser,
    paidUser,
  } = checkphishContactUsContext;

  useEffect(() => {
    const loginRedirectUrl = getLocalStorageValue('loginRedirectUrl');
    if (!_.isEmpty(loginRedirectUrl)) {
      unsetLocalStorageValue('loginRedirectUrl');
      history.push(loginRedirectUrl);
    }
  });

  useEffect(() => {
    if (userTyposquatDataList && selectedDomain.value) {
      setData(userTyposquatDataList[selectedDomain.value]);
      handleDeltaData(userTyposquatDataList[selectedDomain.value].deltaChanges);
    }
  }, [selectedDomain, userTyposquatDataList]);

  useEffect(() => {
    setShowExploreDemoPopup(true);
    return () => setShowExploreDemoPopup(false);
  });

  const handleUserData = (resp: AggregatedTyposquatFetchResultsDashboard) => {
    const {
      metadata: { paidUser, totalDomainMonitoringScanLimit, usedDomainMonitoringScanLimit },
      scanResults,
    } = resp;
    setPaidUser(paidUser);
    setScanLimit(totalDomainMonitoringScanLimit); //total scan limit
    setUsedScans(usedDomainMonitoringScanLimit); //number of scan used
    const availableDomains = Object.values(scanResults)
      .sort((a, b) => b.triggerTs - a.triggerTs)
      .map(m => m.monitoredDomain);
    let tempSelectedDomain = selectedDomain;
    const index =
      tempSelectedDomain?.value && tempSelectedDomain.status === TypoJobStatus.DONE
        ? availableDomains.indexOf(tempSelectedDomain.value)
        : -1;
    const isDoneDomain = availableDomains.filter(
      availableDomain => scanResults[availableDomain].status === TypoJobStatus.DONE,
    )[0];
    if (index === -1) {
      if (isDoneDomain) {
        tempSelectedDomain = {
          value: isDoneDomain,
          status: scanResults[isDoneDomain].status,
        };
      } else {
        tempSelectedDomain = {
          value: availableDomains[0],
          status: scanResults[availableDomains[0]].status,
        };
      }
    } else {
      if (tempSelectedDomain.status !== scanResults[tempSelectedDomain.value as string].status) {
        tempSelectedDomain = {
          ...tempSelectedDomain,
          status: scanResults[tempSelectedDomain.value as string].status,
        };
      }
    }
    setSelectedDomain(tempSelectedDomain);
    setUserTyposquatDataList(scanResults);
    setDomainList(
      availableDomains.map(d => {
        return {
          value: d,
          status: scanResults[d].status,
        };
      }),
    );
    changeTyposquatJobStatus(true);
  };

  const fetchDashboardStats = () => {
    setLoading(true);
    dashboardService
      .getTyposquattingDashboardAnalyticsV2()
      .then((resp: AggregatedTyposquatFetchResultsDashboard) => handleUserData(resp))
      .catch(err => {
        console.log(err);
        setDomainList([]);
        setSelectedDomain({});
        const [errPrefix, _errMessage] = err?.split('.');
        if (errPrefix === 'Your free trial is over') {
          setShowAfterTrialModal(true);
        } else {
          const onDashboardPage = window.location.pathname === '/domain-monitoring/dashboard';
          alertError(
            onDashboardPage
              ? 'Error in fetching dashboard stats. Redirecting to typosquat page'
              : 'Error in fetching dashboard stats.',
          );
          changeTyposquatJobStatus(false);
          window.location.pathname === '/domain-monitoring/dashboard' &&
            history.push('/domain-monitoring/typosquat');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  const riskLevelLegend = (): JSX.Element => {
    return (
      <Row className='risk-score-legend'>
        <Col sm={10} className='no-padding'>
          {Object.keys(colorMap)
            .sort((a: string, b: string) => parseInt(b) - parseInt(a))
            .map((k, i) => (
              <div key={i} style={{ display: 'inline-flex', marginRight: '4px' }}>
                <Badge
                  pill
                  variant='primary'
                  style={{
                    background: colorMap[k],
                  }}
                >
                  {k}
                </Badge>
              </div>
            ))}
          <div className='no-padding sub-text'>Risk Level</div>
        </Col>
      </Row>
    );
  };

  const generateScanPercentage = (): number => {
    const { domainGenerationLimit, metadata } = data;
    if (domainGenerationLimit && metadata && metadata.totalResolvedVariants) {
      const scanPercentage =
        (domainGenerationLimit / Math.max(domainGenerationLimit, metadata.totalResolvedVariants)) *
        100;
      return scanPercentage;
    }
    return 0;
  };

  const generateGradientPercentage = (): string => {
    const scanPercentage = generateScanPercentage();
    if (scanPercentage === 0) {
      return '#808080';
    }
    return `linear-gradient(to right, #3bcb8a 0% ${scanPercentage}%, #808080 ${scanPercentage}% 100%)`;
  };

  const routeToTyposquat = (riskScore: string) => {
    const filters: IFilter[] = [
      {
        id: generateId(10),
        filterBy: { label: 'Risk', value: 'risk_score' },
        filterErr: '',
        filterMethod: { label: 'In range', value: 'numberRange' },
        filterType: 'number',
        filterValue: `${riskScore},${riskScore}`,
      },
    ];

    setLocalStorageValue(['tableSetting', TYPOSQUAT_TABLE_ID_DASH], {
      filters,
    });
    history.push(TYPOSQUATTING_MASTER_PATHNAME);
  };

  const renderRiskLevelBreakdown = (): JSX.Element => {
    const { riskScoreMatrix } = data;
    if (!riskScoreMatrix) {
      return <></>;
    }
    const totalRiskScoreMatrix: any = Object.values(riskScoreMatrix).reduce(
      (sum: any, value: any) => sum + value,
    );
    const sortedKeys = Object.keys(riskScoreMatrix).sort((a, b) => parseInt(b) - parseInt(a));
    return (
      <>
        <Col sm={12}>
          <div className='risk-level-bar'>
            {sortedKeys.map((k, i) => (
              <OverlayTrigger
                placement={'bottom'}
                key={i}
                overlay={
                  <Tooltip id={'tooltip-risk-score-' + k}>
                    Priority Count for Risk Score-{k}: {riskScoreMatrix[k]}
                  </Tooltip>
                }
              >
                <div
                  className='risk-level-bar-length'
                  style={{
                    width: `${(riskScoreMatrix[k] / totalRiskScoreMatrix) * 100}%`,
                    background: `${colorMap[k]}`,
                  }}
                  onClick={() => routeToTyposquat(k)}
                >
                  <div className='legend-bubble'>
                    <Badge
                      pill
                      variant='primary'
                      style={{
                        backgroundColor: colorMap[1],
                      }}
                    >
                      {k}
                    </Badge>
                  </div>
                </div>
              </OverlayTrigger>
            ))}
          </div>
        </Col>
        <Col sm={12} style={{ paddingTop: '10px' }}>
          <span>High</span>
          <span style={{ float: 'right' }}>Low</span>
        </Col>
      </>
    );
  };

  const sendForTakedown = () => {
    const filters: IFilter[] = [
      {
        id: generateId(10),
        filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
        filterErr: '',
        filterLabel: 'Clean',
        filterMethod: { label: 'Is Not', value: 'isNot' },
        filterType: 'options',
        filterValue: 'clean',
      },
      {
        id: generateId(10),
        filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
        filterErr: '',
        filterLabel: 'Suspicious',
        filterMethod: { label: 'Is Not', value: 'isNot' },
        filterType: 'options',
        filterValue: 'suspicious',
      },
    ];

    setLocalStorageValue(['tableSetting', TYPOSQUAT_TABLE_ID_DASH], {
      filters,
    });
    history.push({
      pathname: TYPOSQUATTING_MASTER_PATHNAME,
      state: {
        defaultLayout: 'tile',
      },
    });
  };

  // Ag Grid Implementation

  const colDefs = useMemo<ColDef[]>(() => {
    return generateTyposquattingDeltaColumnDefs({
      columns: deltaColumns,
    });
  }, [deltaData]);

  const flatColDefs = useMemo<ColDef[]>(() => {
    return generateTyposquattingDeltaFlatColumnDefs({
      columns: deltaColumns,
    });
  }, [deltaData]);

  const handleDeltaData = (deltaChanges: DeltaChangeFromAPI[]) => {
    if (deltaChanges && deltaChanges.length > 0) {
      const tempDeltaData: Record<string, DeltaChangeFromAPI> = {};
      const tempDeltaChanges: DeltaChangeFromItem[] = [];
      deltaChanges.forEach((deltaChange: DeltaChangeFromAPI) => {
        tempDeltaData[deltaChange.domain] = deltaChange;
        deltaChange.type.forEach(type => {
          tempDeltaChanges.push({
            ...deltaChange,
            type,
            primary_domain: deltaChange.domain,
          });
        });
      });
      setDeltaData(tempDeltaData);
      setDeltaFlatData(tempDeltaChanges);
    }
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: [],
      resizable: true,
      flex: 1,
    };
  }, []);

  const renderDeltaTable = () => {
    return (
      <div
        id='ag-table-container'
        data-testid='ag-table-container'
        className={`${getAgGridThemeClassName(selectedTheme)} table-container`}
      >
        {deltaTableGroupedView ? (
          <AgGridReact
            ref={agRef}
            columnDefs={colDefs}
            rowData={deltaFlatData}
            groupDisplayType={'groupRows'}
            autoGroupColumnDef={{ headerName: DELTA_DOMAIN.header, field: DELTA_DOMAIN.accessor }}
          />
        ) : (
          <AgGridReact
            columnDefs={flatColDefs}
            rowData={deltaFlatData}
            defaultColDef={defaultColDef}
          />
        )}
      </div>
    );
  };

  // End Ag Grid Implementation

  return (
    <AuthenticationWrapper>
      {showAfterTrialModal ? (
        <TyposquatBlurredModal />
      ) : (
        <LoadingWrapper isLoading={loading}>
          <Row>
            <Col sm={12}>
              <PageTitle
                title={`Dashboard ${data.monitoredDomain && `${data.monitoredDomain}`}`}
                className='analytics-dashboard-page-title'
                tools={
                  paidUser && (
                    <button
                      className={`edit-domain-btn`}
                      onClick={() => {
                        history.push({
                          pathname: TYPOSQUATTING_MASTER_PATHNAME,
                          state: {
                            showTyposquatBar: true,
                            error: { status: false, message: '', isSuccessMsg: false },
                            showLinkToLiveScan: {
                              status: false,
                              message: '',
                              includeDomain: false,
                            },
                            searchText: '',
                            firstLoad: false,
                            scanLimit,
                            usedScans,
                          },
                        });
                      }}
                    >
                      <span className='mr-3' style={{ padding: '0 5px' }}>
                        Add Domain
                      </span>
                    </button>
                  )
                }
              />
            </Col>
            {!currentTyposquatSubscription && (
              <Col sm={12} className='upgrade-strip'>
                <p>
                  This dashboard displays the results of {data.domainGenerationLimit ?? 0}/
                  {convertToThousand(
                    Math.max(data.metadata?.totalResolvedVariants, data.domainGenerationLimit) ?? 0,
                    true,
                  )}{' '}
                  typosquatting domain summary. Need more insights?
                  <Button variant='link' onClick={() => history.push(PRICING_PAGE_PATH)}>
                    Upgrade now.
                  </Button>
                </p>
              </Col>
            )}
          </Row>
          {data.daysToExpire !== -1 && (
            <Row className='alert-container'>
              <Col sm={12}>
                <Alert className='expiry-alert d-flex' key={'expiry-alert'} variant='danger'>
                  <img className='work-email-err-msg-logo' src={WarningRedRoundIcon} alt={'logo'} />
                  <p className='mb-0 mt-2'>
                    The domain monitoring feature access will{' '}
                    <span className='darker'>expire in {data.daysToExpire} days </span>. Please{' '}
                    <span
                      className='darker capitalise'
                      onClick={() => history.push('/account/profile')}
                    >
                      add your work email
                    </span>{' '}
                    to continue access.
                  </p>
                </Alert>
              </Col>
            </Row>
          )}
          <div className='monitiored-container-v2'>
            <Row className='top-riskiest'>
              {!_.isEmpty(data.criticalAlerts) && (
                <Col sm={3} className='critical-alerts-container'>
                  <Col className='critical-alerts'>
                    <Col as={'span'} className='critical-header' sm={12}>
                      Critical
                    </Col>
                    <Col sm={12} as={'p'} className='critical-count'>
                      {Object.keys(data.criticalAlerts).length}
                    </Col>
                    <Col sm={12} as={'p'} className='critical-content-header'>
                      New Phish and Scam
                    </Col>
                    <Col sm={12} className='d-flex no-padding'>
                      <Col sm={10} as={'p'} className='critical-content'>
                        Your takedown request is required
                      </Col>
                      <Col sm={2} as={'p'}>
                        <img src={FilledRightArrow} alt='chevron' onClick={sendForTakedown} />
                      </Col>
                    </Col>
                  </Col>
                </Col>
              )}
              <Col
                className='look-alike-domains'
                sm={3}
                style={{
                  ...(!_.isEmpty(data.criticalAlerts) && { padding: '0 40px' }),
                }}
              >
                <Row>
                  <Col sm={12} className='header'>
                    Typosquat Domains
                  </Col>
                  <Col sm={12} className='d-flex domain-generation-wrap'>
                    <Col className='domain-generation-limit' sm={4}>
                      <span>{data.domainGenerationLimit ?? 0}</span>
                    </Col>
                    {generateScanPercentage() < 100 && (
                      <Col sm={8} className='d-flex upgrade-container domain-generation-stats'>
                        {!currentTyposquatSubscription || (
                          <>
                            <Col className='stats'>
                              <Col sm={12} className='no-padding'>
                                <span>
                                  {data.domainGenerationLimit ?? 0} Free/
                                  {convertToThousand(
                                    data.metadata?.totalResolvedVariants ?? 0,
                                    true,
                                  )}
                                </span>
                              </Col>
                              <Col sm={12} className='no-padding'>
                                <div
                                  className='scan-percentage'
                                  style={{
                                    background: generateGradientPercentage(),
                                  }}
                                ></div>
                              </Col>
                            </Col>
                            <Col className='upgrade-button-container'>
                              <Button
                                className='upgrade-button'
                                variant='primary'
                                onClick={() => history.push(PRICING_PAGE_PATH)}
                              >
                                Upgrade
                              </Button>
                            </Col>
                          </>
                        )}
                      </Col>
                    )}
                  </Col>
                  <Col sm={12} className='no-padding d-flex tld-variant-wrap'>
                    <Col className='d-flex tld-stats stats-wrap'>
                      <Col as={'span'} className='title'>
                        TLDs:
                      </Col>
                      <Col as={'span'} sm={12} className='content'>
                        127
                      </Col>
                    </Col>
                    <Col className='d-flex variants-container stats-wrap'>
                      <Col as={'span'} className='title'>
                        Variants:
                      </Col>
                      <Col as={'span'} sm={12} className='content'>
                        {convertToThousand(data.metadata?.totalVariants ?? 0, true)}
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Col>
              <Col
                className='domain-risk-insight stats-wrap'
                sm={!_.isEmpty(data.criticalAlerts) ? 6 : 9}
              >
                <Col sm={12} className='header'>
                  Domain Risk Insight{' '}
                </Col>
                <Row>
                  <Col sm={4}>
                    <Col sm={12} className='insight-header no-padding'>
                      <Col as={'span'} sm={3} className='title mail-server-header header-text'>
                        Mail Servers
                      </Col>
                      <Col as={'span'} sm={9} className='header-content mail-server-content'>
                        {data.scanInsights?.totalMxCount ?? 0}
                      </Col>
                    </Col>
                    <Col sm={12} className='no-padding'>
                      <Col sm={12} as={'p'} className='no-padding insight-title'>
                        Top 5 riskiest domains with MX records:
                      </Col>
                      {data.domainWithMailServerTopRiskiest &&
                        Object.keys(data.domainWithMailServerTopRiskiest).map((k, i) => (
                          <Col key={i} sm={12} as={'p'} className='no-padding insight-content'>
                            {k}
                          </Col>
                        ))}
                    </Col>
                  </Col>
                  <Col sm={4}>
                    <Col sm={12} className='insight-header no-padding'>
                      <Col as={'span'} sm={3} className='title no-padding header-text'>
                        Hosting IPs
                      </Col>
                      <Col as={'span'} sm={9} className='header-content'>
                        {data.scanInsights?.totalIpCount ?? 0}
                      </Col>
                    </Col>
                    <Col sm={12} className='no-padding'>
                      <Col sm={12} as={'p'} className='no-padding insight-title'>
                        Top 5 high risk IP addresses:
                      </Col>
                      {data.ipTopRiskiestMatrix &&
                        Object.keys(data.ipTopRiskiestMatrix).map((k, i) => (
                          <Col key={i} sm={12} as={'p'} className='no-padding insight-content'>
                            {k}
                          </Col>
                        ))}
                    </Col>
                  </Col>
                  <Col sm={4}>
                    <Col sm={12} className='insight-header no-padding'>
                      <Col as={'span'} sm={3} className='title no-padding header-text'>
                        Live Domains
                      </Col>
                      <Col as={'span'} sm={9} className='header-content'>
                        {data.scanInsights?.totalDomainCount ?? 0}
                      </Col>
                    </Col>
                    <Col sm={12} className='no-padding'>
                      <Col sm={12} as={'p'} className='no-padding insight-title'>
                        Top 5 high risk live domains:
                      </Col>
                      {data.domainTopRiskiest &&
                        Object.keys(data.domainTopRiskiest).map((k, i) => (
                          <Col key={i} sm={12} as={'p'} className='no-padding insight-content'>
                            {k}
                          </Col>
                        ))}
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='risk-level-breakdown'>
              <Col sm={5} className='header'>
                <h5>Risk Level Breakdown</h5>
                <span className='content'>
                  This score quantifies a typosquat domain's predictive potential risk to a brand.
                  Risk scores for a domain are weighted based on several factors, including the
                  Category, popularity of the domain, age of the domain, and other factors.
                </span>
              </Col>
              <Col sm={7}>{renderRiskLevelBreakdown()}</Col>
              {riskLevelLegend()}
            </Row>
            <Row className='top-breakdown-chart'>
              {Object.keys(barChartMapper).map((k, i) => (
                <Col sm={6} key={i}>
                  <BarChart
                    title={barChartMapper[k]}
                    isLoading={loading}
                    data={data[k] as IBarChartDataProps[]}
                    colorMap={colorMap}
                    hideLegend={true}
                    plotOptionsHeight={'100%'}
                    xaxisShow={false}
                    showTotalData={true}
                    alignLabel={'right'}
                  />
                </Col>
              ))}
              {riskLevelLegend()}
            </Row>
          </div>
          {!_.isEmpty(deltaData) ? (
            <>
              <PageTitle title={`What's New`} />
              <div className='whats-new-wrapper'>
                <Col sm={12} className='upgrade-strip next-scan-strip d-flex'>
                  <Col sm={11} className='px-md-3'>
                    <p>
                      Next scan on [{data.nextScanTs}]. Check back here for updates or watch for
                      email notifications.
                    </p>
                  </Col>
                  <Col sm={1} className='delta-switch-container no-padding'>
                    <MUToolTip title='Switch to group view of results'>
                      <Switch
                        checked={deltaTableGroupedView}
                        onChange={() => setDeltaTableGroupedView(!deltaTableGroupedView)}
                        color='primary'
                      />
                    </MUToolTip>
                  </Col>
                </Col>
              </div>
              <Row className='delta-table'>{renderDeltaTable()}</Row>
            </>
          ) : (
            <>
              <PageTitle title={`What's New`} />
              <div className='whats-new-wrapper-empty'>
                <img src={ContiniuosMonitoring} />
                <h4>We are continuous monitoring</h4>
                <p>
                  {paidUser
                    ? `No new malicious activity found since the last digest. Next scan on [${data.nextScanTs}]. Check back for updates or watch for an email notification.`
                    : `Monitoring up to 100 typosquats continuously .Next scan on [${data.nextScanTs}]. Need full results?`}
                </p>
                {!paidUser && (
                  <Button onClick={() => history.push(PRICING_PAGE_PATH)}>Upgrade</Button>
                )}
              </div>
            </>
          )}
        </LoadingWrapper>
      )}
      {data?.daysToExpire && data.daysToExpire <= 5 && data.daysToExpire > 0 && (
        <TyposquatReminderModal domain={data.monitoredDomain} daysToExpire={data.daysToExpire} />
      )}
    </AuthenticationWrapper>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};
export default connect(undefined, mapDispatchToProps)(TypoDashboardV2);

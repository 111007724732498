import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { alertActions } from '../../actions';
import { IAlertDispatchProps } from '../../constants';
import { getScreenshotUrl } from '../../helpers/screenshotUrl';
import DashboardService from '../../services/dashboard.service';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { Image } from '../Common/Image';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import ModalOverlay from '../Common/ModalOverlay';
import './soc-takedown-historical-screenshot.modal.scss';
import { AdhocScreenshotDto, HistoricalScreenshotDto } from './types/historical-takedown.interface';
import { TakedownUrl } from './types/takedown-url.interface';
const dashboardService = new DashboardService();
const socTakedownService = new SocTakedownService();

interface IHistoricalScreenshotsProps {
  showHistoricalModal: boolean;
  onCloseHistoricalModal: () => void;
  switchScreenshot: (urlInfo: TakedownUrl, historicalScreenshotPath: string) => void;
  selectedUrlInfo: TakedownUrl;
}

const SocTakedownHistoricalScreenshotModal = ({
  showHistoricalModal,
  onCloseHistoricalModal,
  switchScreenshot,
  selectedUrlInfo,
  alertSuccess,
  alertError,
}: IHistoricalScreenshotsProps & Pick<IAlertDispatchProps, 'alertSuccess' | 'alertError'>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [historicalScreenshots, setHistoricalScreenshots] = useState<HistoricalScreenshotDto[]>([]);
  const [adHocTimelineScreenshots, setAdHocTimelineScreenshots] = useState<AdhocScreenshotDto[]>(
    [],
  );
  const [urlLoadMap, setUrlLoadMap] = useState<{ [screenshotUrl: string]: boolean }>({});

  useEffect(() => {
    const { sha256 } = selectedUrlInfo;
    setIsLoading(true);

    Promise.all([
      dashboardService.getTimeline(sha256),
      socTakedownService.getADHocScreenshots(sha256),
    ])
      .then(allResponses => {
        const [historicalTimelineInfo, adHocTimlineInfo] = allResponses;
        setHistoricalScreenshots(historicalTimelineInfo.timeline);
        setAdHocTimelineScreenshots(adHocTimlineInfo.screenshots);
        setIsLoading(false);
      })
      .catch(err => {
        alertError(err);
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  const renderHistoricalScreenshots = () => {
    return historicalScreenshots?.map((screenshot: HistoricalScreenshotDto, index: number) => {
      return (
        <div className='image-container' key={index}>
          <Image
            onLoad={success => setUrlLoadMap({ ...urlLoadMap, [screenshot.imagePath]: success })}
            imagePath={getScreenshotUrl(screenshot.imagePath)}
            alt='scan result screenshot'
            className={'timeline-image'}
          />
          <div className={'d-flex flex-row justify-content-between mt-1'}>
            <div>{moment(screenshot.createdTS).format('MMM DD,YYYY - hh:mm:ss a')}</div>
            <Button
              disabled={!urlLoadMap[screenshot.imagePath]}
              variant={'link'}
              size={'sm'}
              onClick={() => onAddScreenshotHandler(screenshot.imagePath)}
            >
              Add To Email
            </Button>
          </div>
        </div>
      );
    });
  };

  const renderAdhocScreenshots = () => {
    return adHocTimelineScreenshots.map((adhocScreenshot: AdhocScreenshotDto, index: number) => {
      return (
        <div className='image-container' key={index}>
          <Image
            onLoad={success => setUrlLoadMap({ ...urlLoadMap, [adhocScreenshot.s3url]: success })}
            imagePath={getScreenshotUrl(adhocScreenshot.s3url)}
            alt='scan result screenshot'
            className={'timeline-image'}
          />
          <div className={'d-flex flex-row justify-content-between mt-1'}>
            <div>{moment(adhocScreenshot.timestamp).format('MMM DD,YYYY - hh:mm:ss a')}</div>
            <Button
              disabled={!urlLoadMap[adhocScreenshot.s3url]}
              variant={'link'}
              size={'sm'}
              onClick={() => onAddScreenshotHandler(adhocScreenshot.s3url)}
            >
              Add To Email
            </Button>{' '}
          </div>
        </div>
      );
    });
  };

  const onAddScreenshotHandler = (newUrlpath: string) => {
    switchScreenshot(selectedUrlInfo, newUrlpath);
    onCloseHistoricalModal();
  };

  return (
    <ModalOverlay
      show={showHistoricalModal}
      title={'Add Historical/Adhoc Screenshots'}
      onCancel={onCloseHistoricalModal}
      onSubmit={_.noop}
      hideSubmitBtn={true}
      size={'lg'}
    >
      <LoadingWrapper isLoading={isLoading}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className='mr-2'>
            <div>Historical Screenshots</div>
            <div>{renderHistoricalScreenshots()}</div>
          </div>
          <div>
            <div>Adhoc Screenshots</div>
            <div>{renderAdhocScreenshots()}</div>
          </div>
        </div>
      </LoadingWrapper>
    </ModalOverlay>
  );
};
const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};
export default connect(null, mapDispatchToProps)(SocTakedownHistoricalScreenshotModal);

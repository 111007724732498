import React, { useEffect, useState } from 'react';
import './CheckPhishSideBar.scss';
import ExpandableNavItem from './ExpandableNavItem';
import { CHECKPHISH_DRAWER_SUBITEM } from '../RouteOptions';
import NavItemV2 from './NavItemV2';
import _ from 'lodash';
import ActiveAppContext from '../../../context/CheckphishActiveAppContext';
import { useContext } from 'react';
import { appOptions } from './Constants';
import { history } from '../../../helpers';
import { setLocalStorageValue } from '../../../constants';
import { getCurrentActivePage } from '../../../helpers/url-helpers';
import {
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_MASTER_PATHNAME,
} from '../../DomainMonitoring/constant';
import { Link } from 'react-router-dom';

type Props = { hasTyposquatData: boolean; selectedTheme: string };

const CheckPhishSideBar = ({ hasTyposquatData = true, selectedTheme = 'light' }: Props) => {
  const { selectedApp, setSelectedApp } = useContext(ActiveAppContext);
  const [navItems, setNavItems] = useState<any[]>([]);
  const [selectedNavItems, setSelectedNavItems] = useState<any>([]);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  useEffect(() => {
    return setSelectedApp(appOptions[0]);
  }, []);

  useEffect(() => {
    const lastVisitedPage = getCurrentActivePage();
    if (!_.isEmpty(lastVisitedPage)) {
      return lastVisitedPage.includes('premium')
        ? history.push('/domain-monitoring/dashboard')
        : history.push(lastVisitedPage);
    }
    return hasTyposquatData
      ? history.push(TYPOSQUATTING_DASHBOARD_PATHNAME)
      : history.push(TYPOSQUATTING_MASTER_PATHNAME);
  }, []);

  useEffect(() => {
    !firstRender && setLocalStorageValue('currentActiveUrl', window.location.pathname);
    if (window.location.pathname.includes('premium') && selectedApp === appOptions[0]) {
      setSelectedApp(appOptions[1]);
    }
    if (!window.location.pathname.includes('premium') && selectedApp === appOptions[1]) {
      setSelectedApp(appOptions[0]);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (firstRender) return setFirstRender(false);
    const { pathname } = window.location;
    if (selectedApp === appOptions[0] && pathname.includes('premium')) {
      history.push('/domain-monitoring/dashboard');
    }
    if (!pathname.includes('premium') && selectedApp === appOptions[1]) {
      history.push('/premium/dashboard/web');
    }
  }, [selectedApp, firstRender]);

  useEffect(() => {
    const selectedNavItems = navItems.filter((item: any) => item.parentLabel == selectedApp.label);
    selectedNavItems.length && setSelectedNavItems(selectedNavItems);
  }, [selectedApp, navItems]);

  useEffect(() => {
    const updatedNavItems = CHECKPHISH_DRAWER_SUBITEM(hasTyposquatData);
    updatedNavItems.length && setNavItems(updatedNavItems);
  }, [CHECKPHISH_DRAWER_SUBITEM, hasTyposquatData]);

  return (
    <div className='side-bar-wrapper' data-testid='side-bar-wrapper'>
      {selectedApp.value === appOptions[1].value && (
        <div className='navigation-wrap'>
          <Link
            to={hasTyposquatData ? TYPOSQUATTING_DASHBOARD_PATHNAME : TYPOSQUATTING_MASTER_PATHNAME}
          >
            Go back to my plan
          </Link>
        </div>
      )}
      <div className='side-bar-items-wrap'>
        {selectedNavItems.length &&
          selectedNavItems.map((item: any, index: number) => {
            return item.subItems.length ? (
              <ExpandableNavItem navItem={item} key={index} selectedTheme={selectedTheme} />
            ) : (
              <NavItemV2 navItem={item} key={index} selectedTheme={selectedTheme} />
            );
          })}
      </div>
    </div>
  );
};

export default CheckPhishSideBar;

import React, { useEffect, useState, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import _, { update } from 'lodash';
import { Card, Modal } from 'react-bootstrap';

import StripeService from '../../services/stripe.service';
import { loadStripe } from '@stripe/stripe-js';
import { IPricingPlan, PlanTerm, PRICING_PLANS } from './types';
import { EPlanProduct } from '../Common/Subscription';
import './pricing.scss';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import TermToggleSwitch from './Common/TermToggleSwitch';
import Header from './Header';
import { SCROLL_TO_COMPARISION, toggleOptions } from './constants';
import { getAvailablePlans } from './helpers';
import PricingPlan from './PricingPlan';
import { HubspotFormDetials } from './types';
import { HUBSPOT_FORM_TYPE } from './types';
import { hubspotFormId } from './constants';
import { HubspotContactForm } from '../ContactUs/HubspotContactForm';
import { AppState } from '../../helpers';
import ThemeContext from '../../context/ThemeContext';
import { ISubsciptionPlan } from './types';
import ComparisionTable from './ComparisionTable';
import AddOnWidget from './AddOnWidget';
import Checkout from './Checkout';
import { history } from '../../helpers';

import {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
//assets
import FreeCaptionIcon from '../../assets/icons/FreePlanGoToArrow.svg';
import PremiumCaptionIcon from '../../assets/icons/PremiumPlanGoToArrow.svg';
import PremiumCaptionIconDark from '../../assets/icons/PremiumPlanGoToArrowDark.svg';
import FreeCaptionIconDark from '../../assets/icons/FreePlanGoToArrowDark.svg';
import TogglePlan, { DowngradType } from './TogglePlan';
import AppSettingContext from '../../context/AppSettingContext';
import { TogglePlanType } from './constants';
import { useMemo } from 'react';
import { ThemeModes } from '../../constants';
import { getLocalStorageValue, setLocalStorageValue } from '../../constants';

const stripeService = new StripeService();

export interface IPricingProps {
  getUserInfo: () => any;
  subscription?: any;
}

const PricingPage = ({ getUserInfo }: IPricingProps) => {
  const [stripePromise, setStripePromise] = useState<any>(undefined);
  const [liveScanPlans, setLiveScanPlans] = useState<IPricingPlan[]>([]);
  const [typosquatPlans, setTyposquatPlans] = useState<IPricingPlan[]>([]);
  const [liveScanBundles, setLiveScanBundles] = useState<number[]>([]);
  const {
    selectedTerm,
    selectedPlans,
    setSelectedPlans,
    currentTyposquatSubscription,
    currentLiveScanSubscription,
    numberOfDomains,
    numberOfScans,
  } = useContext<ICheckPhishSubscription>(CheckphishSubscriptionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const [TogglePopupType, setTogglePopupType] = useState<TogglePlanType>(TogglePlanType.NONE);
  const { landingPage } = useContext(AppSettingContext);
  const [downgradeType, setDowngradeType] = useState<DowngradType>(DowngradType.None);
  //hubspot forms
  const [hubsportFormDetails, setHubspotFormDetails] = useState<HubspotFormDetials>({
    showForm: false,
    formId: hubspotFormId[HUBSPOT_FORM_TYPE.PREMIUM],
  });

  const comparisonSectionRef = useRef<HTMLHeadingElement>(null);

  const { themeName } = useContext(ThemeContext);

  const [freeCaptionIcon, premiumCaptionIcon] = useMemo(() => {
    if (themeName === ThemeModes.DARK) {
      return [FreeCaptionIconDark, PremiumCaptionIconDark];
    }
    return [FreeCaptionIcon, PremiumCaptionIcon];
  }, [themeName]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([stripeService.getToken(), stripeService.getPlans('v2')])
      .then(result => {
        const [{ config }, { plans, productBundles }] = result;
        setStripePromise(loadStripe(config.pubKey, { apiVersion: '2022-11-15' })); //setup config key for stripe
        setLiveScanBundles(productBundles['Live Scan']); //adding 25 for free plan as default
        const liveScanPlans: IPricingPlan[] = plans
          .filter((planItem: any) => {
            return planItem.product === EPlanProduct.LIVE_SCAN;
          })
          .map((item: any) => {
            return {
              priceId: item.id,
              name: item.name,
              description: item.description,
              term: item.term,
              limit: item.limit,
              price: item.price,
              discounts: item.discounts || null,
              product: item.product,
            };
          });
        const typosquatPlans: IPricingPlan[] = plans
          .filter((planItem: any) => {
            return planItem.product === EPlanProduct.TYPOSQUATTING;
          })
          .map((item: any) => {
            return {
              priceId: item.id,
              name: item.name,
              description: item.description,
              term: item.term,
              limit: item.limit,
              price: currentLiveScanSubscription?.id === item.id ? 0 : item.price,
              discounts: item.discount || null,
              product: item.product,
            };
          });
        //setting typo and livescan plans
        setLiveScanPlans(liveScanPlans);
        setTyposquatPlans(typosquatPlans);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsLoading(false);
        const appContent = document.querySelector<HTMLDivElement>('.app-content');
        const scrollToComaparison = getLocalStorageValue(SCROLL_TO_COMPARISION);
        if (appContent && scrollToComaparison) {
          appContent.scroll({
            top: 1250,
            left: 0,
            behavior: 'smooth',
          });
          setLocalStorageValue(SCROLL_TO_COMPARISION, false);
        }
      });
  }, []);

  const renderTogglePopup = () => {
    return TogglePopupType === TogglePlanType.CANCEL ? (
      <TogglePlan
        popUpType={TogglePlanType.CANCEL}
        cardTitle='Cancel your plan'
        actionButtonText={'Cancel plan'}
        afterSuccessFn={getUserInfo}
        onGoBackActionHandler={() => {
          setTogglePopupType(TogglePlanType.NONE);
          setDowngradeType(DowngradType.None);
        }}
      />
    ) : (
      <TogglePlan
        popUpType={TogglePlanType.DOWNGRADE}
        cardTitle='Downgrade your plan'
        actionButtonText={
          downgradeType === DowngradType.TYPOSQUAT_FREE ? 'Downgrade to free plan' : 'Downgrade'
        }
        onGoBackActionHandler={() => {
          setTogglePopupType(TogglePlanType.NONE);
          setDowngradeType(DowngradType.None);
        }}
        DowngradeType={downgradeType}
        afterSuccessFn={getUserInfo}
      />
    );
  };
  //selected plans when user checks out

  const showHubspotForm = (type: HUBSPOT_FORM_TYPE.PREMIUM | HUBSPOT_FORM_TYPE.PRO) => {
    setHubspotFormDetails({
      showForm: true,
      formId: hubspotFormId[type],
    });
  };

  const addPlanToCart = (
    planItem: IPricingPlan,
    quantity: number = 1,
    checkoutToCart: boolean = false,
  ) => {
    const subscriptionPlan = { ...planItem, quantity };
    if (checkoutToCart) setShowCheckout(true);
    const planExist = selectedPlans.find(
      (item: ISubsciptionPlan) => subscriptionPlan.priceId === item.priceId,
    );
    //if planDoesnt exist simply add it
    if (_.isEmpty(planExist)) {
      return setSelectedPlans((existingPlans: ISubsciptionPlan[]) => {
        const selectedPlans = [...existingPlans, subscriptionPlan];
        return selectedPlans;
      });
    }
    if (!_.isEqual(subscriptionPlan, planExist)) {
      //if not equale replace by updated plan.
      setSelectedPlans((existingPlans: ISubsciptionPlan[]) =>
        existingPlans.map((planItem: ISubsciptionPlan) =>
          planItem.priceId === subscriptionPlan.priceId ? subscriptionPlan : planItem,
        ),
      );
    }
  };

  useEffect(() => {
    if (_.isEmpty(typosquatPlans) || _.isEmpty(liveScanPlans)) return;
    const updatedCartItems = selectedPlans.map((planItems: ISubsciptionPlan) => {
      if (planItems.product === EPlanProduct.TYPOSQUATTING) {
        return {
          ...getAvailablePlans(typosquatPlans, selectedTerm.value)[0],
          quantity: numberOfDomains,
        };
      }
      return {
        ...getAvailablePlans(liveScanPlans, selectedTerm.value)[0],
        quantity: planItems.quantity,
      };
    });
    setSelectedPlans(updatedCartItems);
  }, [selectedTerm]);

  //context and global states
  const { user } = useSelector((state: AppState) => state.dashboardReducer);
  const theme = useContext(ThemeContext);

  const triggerDownGrade = (DowngradeType: DowngradType) => {
    switch (DowngradeType) {
      case DowngradType.TYPOSQUAT_FREE: {
        setTogglePopupType(TogglePlanType.DOWNGRADE);
        setDowngradeType(DowngradType.TYPOSQUAT_FREE);
        break;
      }
      case DowngradType.TYPOSQUAT_DOWNGRADE: {
        setTogglePopupType(TogglePlanType.DOWNGRADE);
        setDowngradeType(DowngradType.TYPOSQUAT_DOWNGRADE);
        break;
      }
      case DowngradType.LIVESCAN_DOWNGRADE: {
        setTogglePopupType(TogglePlanType.DOWNGRADE);
        setDowngradeType(DowngradType.LIVESCAN_DOWNGRADE);
        break;
      }
      default: {
        setTogglePopupType(TogglePlanType.NONE);
        setDowngradeType(DowngradType.None);
      }
    }
  };

  const paymentSuccessCallBack = () => {
    history.push(landingPage);
    getUserInfo();
  };

  return (
    <div className='pricing-page-wrap' data-testid='pricing-wrapper'>
      <LoadingWrapper isLoading={isLoading}>
        <Header showCheckoutForm={showCheckout} />
        <div className='toggle-switch-wrap' style={{ width: '100%' }} data-testid='toggle-wrap'>
          <TermToggleSwitch items={toggleOptions} customClass='toggle-switch-full-width' />
        </div>
        {TogglePopupType !== TogglePlanType.NONE && renderTogglePopup()}
        {showCheckout ? (
          <Checkout
            liveScanBundles={liveScanBundles}
            liveScanPlan={getAvailablePlans(liveScanPlans, selectedTerm.value)[0]}
            addPlanToCart={addPlanToCart}
            stripePromise={stripePromise}
            paymentSuccessCallBack={paymentSuccessCallBack}
          />
        ) : (
          <>
            <Card
              style={{
                marginTop: '2rem',
              }}
            >
              <div className='pricing-plans-wrap' data-testid='pricing-plan-wrap'>
                <PricingPlan
                  planName={PRICING_PLANS.FREE}
                  price={[{ price: 0, duration: 'lifetime', type: 'lifetime' }]}
                  captionText={{
                    text: 'Get developer-friendly CheckPhish API',
                    link: 'https://checkphish.bolster.ai/checkphish-api/',
                    isExternalLink: true,
                  }}
                  description={
                    'For community users, always free real-time URL scanner and limited domain monitoring results '
                  }
                  captionIcon={{ img: freeCaptionIcon, position: 'end' }}
                  onActionBtnClick={triggerDownGrade.bind(this, DowngradType.TYPOSQUAT_FREE)}
                  features={[
                    '1 domain daily limit to monitor',
                    '300 typosquat results',
                    'Continuous monitor 100 results',
                    'Weekly rescan updates in Scan Disposition and MX changes for up to 100 results',
                    'Up to 500 priority recommended domains to acquire',
                    '25 live scan/day',
                    'Bulk scan functionality',
                    'Advanced API dashboard',
                    'Community access and support',
                  ]}
                  actionButtonCustomClass='black-and-white'
                />
                {!_.isEmpty(typosquatPlans) && (
                  <PricingPlan
                    planName={PRICING_PLANS.STARTER}
                    price={getAvailablePlans(typosquatPlans, selectedTerm.value).map(planItem => ({
                      priceId: planItem.priceId,
                      price: planItem.price * numberOfDomains,
                      duration: `Billed ${_.capitalize(planItem.term)}`,
                      type: planItem.term === 'monthly' ? 'monthly' : 'yearly',
                    }))}
                    captionText={'Comprehensive typosquat monitoring'}
                    description={
                      'Monitor all typosquats and record changes for your primary domain (company.com) with per-domain plan.'
                    }
                    onActionBtnClick={
                      numberOfDomains < (currentTyposquatSubscription?.quantity || 0)
                        ? triggerDownGrade.bind(this, DowngradType.TYPOSQUAT_DOWNGRADE)
                        : addPlanToCart.bind(
                            this,
                            getAvailablePlans(typosquatPlans, selectedTerm.value)[0],
                            numberOfDomains,
                            true,
                          )
                    }
                    features={[
                      'Monitor domain for full typosquat results',
                      'Full access to typosquat scan verdicts',
                      'Full access to typosquat risk scores',
                      'Daily monitoring for changes in new typosquat, MX record, Disposition,  A record and IP ',
                    ]}
                  />
                )}
                <PricingPlan
                  planName={PRICING_PLANS.PREMIUM}
                  price={'Request Demo'}
                  captionText={{
                    text: 'Explore demo workspace',
                    link: '/premium/dashboard/web',
                    isExternalLink: false,
                  }}
                  description={
                    'For organizations needing advanced monitoring and remediation across web, social media, app stores, and the dark web.'
                  }
                  actionBtnText={'Get in Touch'}
                  captionIcon={{ img: premiumCaptionIcon, position: 'end' }}
                  onActionBtnClick={showHubspotForm.bind(this, HUBSPOT_FORM_TYPE.PREMIUM)}
                  featuresHeader='Everything in PRO, plus'
                  features={[
                    '1300 URL scan/day',
                    '1300 TLDs monitored',
                    'Threat Feed (brand logos, copy right, trademark)',
                    'Daily rescan frequency',
                    'Auto-takedown phishing sites',
                    'Admin & SSO',
                    'Notification integrations',
                    'Social Media, Dark Web, and App Store detection and remediation',
                    'Dedicated SOC analyst and customer success support',
                  ]}
                  actionButtonCustomClass='black-and-white'
                />
              </div>
              <div className='add-on-card'>
                {liveScanPlans && (
                  <AddOnWidget
                    liveScanPlan={getAvailablePlans(liveScanPlans, selectedTerm.value)[0]}
                    liveScanBundles={liveScanBundles}
                    onActionBtnClick={
                      numberOfScans < (currentLiveScanSubscription?.quantity || 0)
                        ? triggerDownGrade.bind(this, DowngradType.LIVESCAN_DOWNGRADE)
                        : addPlanToCart
                    }
                  />
                )}
              </div>
            </Card>
            {!_.isEmpty(typosquatPlans) && (
              <div>
                <h4 ref={comparisonSectionRef} style={{ margin: '24px' }}>
                  Compare Different Plans and Features
                </h4>
                <Card className={'comparision-table-wrap'}>
                  <ComparisionTable
                    planData={getAvailablePlans(typosquatPlans, selectedTerm.value)}
                    showHubspotForm={showHubspotForm}
                    addPlanToCart={addPlanToCart}
                    typosquatPlans={typosquatPlans}
                    selectedTerm={selectedTerm}
                    numberOfDomains={numberOfDomains}
                    triggerDownGrade={triggerDownGrade}
                  />
                </Card>
              </div>
            )}

            {/* hubspot modal */}
            <Modal
              className={'max-width-content modal-wrap z-index-1'}
              size='lg'
              show={hubsportFormDetails.showForm}
              data-testid={`hubspot-form-${hubsportFormDetails.formId}`}
              onHide={() => setHubspotFormDetails(oldState => ({ ...oldState, showForm: false }))}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div
                  className='hb-contact-form'
                  style={{
                    width: '600px',
                    padding: '1rem',
                    zIndex: '50000',
                  }}
                >
                  <HubspotContactForm
                    region='na1'
                    portalId='24174425'
                    formId={hubsportFormDetails.formId}
                    prefillData={true}
                    user={user}
                    darkTheme={theme.selectedTheme === 'dark'}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default PricingPage;

import React, { useState } from 'react';
import _ from 'lodash';
import Card from 'react-bootstrap/Card';
import { ImagesSlide } from '../../../../Common/ImagesSlide';
import { IFindingInsightSectionProps } from '../../../Types/ugc.types';
import { Image } from '../../../../Common/Image';
import { Annotation } from '../../../../Common/Annotation';
import { getNameFromUrlPath } from '../../../../../constants';
import { getScreenshotUrl } from '../../../../../helpers/screenshotUrl';

type TTab = 'screenshot' | 'logoImages';

const ImagesSection = ({ data, brandMnemonic }: IFindingInsightSectionProps) => {
  const { screenshot, isLogoDetection, uploads } = data;
  const [currentTab, setCurrentTab] = useState<TTab>('screenshot');

  return (
    <Card bg='light' className='card-between finding-insight-images-section'>
      <Card.Body>
        <div className='tabs d-flex align-items-center'>
          <div
            className={'tab ' + (currentTab === 'screenshot' ? 'selected' : '')}
            onClick={() => {
              setCurrentTab('screenshot');
            }}
          >
            Screenshot
          </div>
          <div
            className={
              'tab ' +
              (currentTab === 'logoImages' ? 'tab-selected' : '') +
              (isLogoDetection ? '' : 'tab-disabled')
            }
            onClick={() => {
              isLogoDetection && setCurrentTab('logoImages');
            }}
          >
            Matched Logo & Images
          </div>
        </div>
        {currentTab === 'screenshot' ? (
          <Annotation
            className={'img-fluid '}
            title=''
            imagePath={getScreenshotUrl(screenshot)}
            brandMnemonic={brandMnemonic}
            annotations={data?.logoDetection?.annotations || []}
            alt='scan result screenshot'
          />
        ) : isLogoDetection ? (
          <ImagesSlide
            images={_.map(uploads, img => ({ name: getNameFromUrlPath(img), path: img }))}
            hideImagesController
          />
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default ImagesSection;

import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../../helpers';
import DashboardHeader from './DashboardHeader';
import { UgcDashboardSummary } from './Widgets/UgcDashboardSummary';
import UgcThreatContainer from './ThreatSection/UgcThreatContainer';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';
import { SocialWidgetsSection } from './Widgets/UgcWidgetSection';
import '../../Dashboard_v2/SubComponents/dashboard.scss';
import UgclMediaIntro from './Common/UgcMediaIntro';
import { appConstants, DEFAULT_DATE_RANGE_V2, featureIsAvailable } from '../../../constants';
import DashboardService from '../../../services/dashboard.service';
import { IFeatureRequested, ugcTypeLabel } from '../Types/ugc.types';
import LockBanner from '../../Drawer/CheckPhishV2/LockBanner';
import { titleMapper } from '../../Drawer/CheckPhishV2/Constants';

const dashboardService = new DashboardService();

const Dashboard = ({
  user,
  ugcPlatforms,
  ugcCategories,
  type,
  mediaTryOutProps,
  widgetsTitle,
}: any) => {
  window.document.title = `Dashboard | ${ugcTypeLabel[type]}`;
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(DEFAULT_DATE_RANGE_V2, 'day'),
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const lastXDay = useRef(DEFAULT_DATE_RANGE_V2);
  const [isLoading, setIsLoading] = useState(false);
  const [featureRequested, setFeatureRequested] = useState<IFeatureRequested>();
  const { featureCode } = mediaTryOutProps;
  const [isCustomCalendarRange, setIsCustomCalendarRange] = useState<boolean>(false);

  const isDemo = window.location.href.includes('premium') || false;

  const onDateRangeChange = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    lastXDayInput: number,
    isCustomCalendarRange: boolean,
  ) => {
    lastXDay.current = lastXDayInput;
    setStartDate(startDate);
    setEndDate(endDate);
    setIsCustomCalendarRange(isCustomCalendarRange);
  };

  useEffect(() => {
    if (featureIsAvailable(user, [featureCode]) === false) {
      getFeatureRequested();
    }
  }, []);

  const onHandleTrialRequested = () => {
    setIsLoading(true);
    const query = {
      productFeatureId: featureCode,
      featureRequested: true,
    };

    dashboardService
      .createFeatureRequest(query)
      .then(res => {
        setFeatureRequested(res);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getFeatureRequested = () => {
    setIsLoading(true);
    dashboardService
      .getFeatureRequested()
      .then(res => {
        const {
          productFeature: { code },
        } = res;
        if (code === featureCode) {
          setFeatureRequested(res);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err, 'err');
        setIsLoading(false);
      });
  };
  return (
    <AuthenticationWrapper>
      {featureIsAvailable(user, [featureCode]) ? (
        <>
          <DashboardHeader
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={onDateRangeChange}
            user={user}
            type={type}
          />
          {isDemo && (
            <LockBanner
              showLockedIcon={false}
              heading={<>Don’t worry! This isn’t your data.</>}
              subHeading={
                <>
                  Use Bolster's sample data to explore the functionality of{' '}
                  <span className='highlight-text normal-text'>{titleMapper[type]}</span>
                  dashboard
                </>
              }
              upgradeBtnText='Request Demo'
            />
          )}
          <UgcDashboardSummary type={type} />
          <UgcThreatContainer
            startDate={startDate}
            endDate={endDate}
            ugcPlatforms={ugcPlatforms}
            ugcCategories={ugcCategories}
            type={type}
            isCustomCalendarRange={isCustomCalendarRange}
          />
          <div className='page-content dashboard-page dashboard-page-v2'>
            <SocialWidgetsSection
              type={type}
              user={user}
              startDate={startDate}
              endDate={endDate}
              widgetsTitle={widgetsTitle}
              isCustomCalendarRange={isCustomCalendarRange}
            />
          </div>
        </>
      ) : (
        <UgclMediaIntro
          featureRequestedData={featureRequested}
          isLoading={isLoading}
          setLoading={setIsLoading}
          setFeatureRequest={setFeatureRequested}
          mediaTryOutProps={mediaTryOutProps}
        />
      )}
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { user } = state?.dashboardReducer;
  const { contents } = state?.appReducer;
  return {
    user,
    ugcPlatforms: contents?.[type]?.platforms || [],
    ugcCategories: contents?.[type]?.categories || [],
  };
};

const mapDispatchToProps = {};

const connectedDashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export { connectedDashboard as Dashboard };

import React, { useCallback } from 'react';
import { useAppDispatch } from '../../../../helpers/hooks';
import {
  setCurrentDataLeakEntityType,
  setCurrentDataLeakParams,
  setCurrentSeachTermID,
  setShouldShowDataLeakModal,
} from '../../../../reducers/darkWeb.reducer';

export default function useOnOpenDataLeakDrawer(
  label: any,
  entityType: string,
  searchTermId: string | number,
) {
  const dispatch = useAppDispatch();
  const openDataLeakDrawer = useCallback(() => {
    dispatch(setShouldShowDataLeakModal(true));
    dispatch(setCurrentDataLeakParams(label));
    dispatch(setCurrentDataLeakEntityType(entityType));
    dispatch(setCurrentSeachTermID(searchTermId));
  }, [dispatch, label, entityType, searchTermId]);
  return { openDataLeakDrawer };
}

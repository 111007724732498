import React, { useState, createContext, useEffect, useContext } from 'react';
import { Nullable } from '../types/common';
import _ from 'lodash';
import {
  TColorMap,
  TDonutChartDataProps,
} from '../components/Common/DashboardWidgets/Types/chart.type';
import { getColors } from '../constants';
import ThemeContext from './ThemeContext';
import DashboardService from '../services/dashboard.service';
import { mockPriorityWidgetData } from '../constants';

const PriorityWidgetDataContext = createContext({
  isLoading: false as boolean,
  priorityData: [] as TDonutChartDataProps[],
});

export default PriorityWidgetDataContext;

interface IPriorityWidgetDataContext {
  children: React.ReactElement;
  activeOUId?: Nullable<number>;
  enableCommunity: boolean;
}

export const PriorityWidgetDataContextProvider = (props: IPriorityWidgetDataContext): any => {
  const { children, activeOUId, enableCommunity } = props;
  const { selectedTheme } = useContext(ThemeContext);

  const colorMap: TColorMap = {
    ['1']: getColors(selectedTheme).priority1,
    ['2']: getColors(selectedTheme).priority2,
    ['3']: getColors(selectedTheme).priority3,
    ['4']: getColors(selectedTheme).priority4,
    ['5']: getColors(selectedTheme).priority5,
  };
  const dashboardService = new DashboardService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [priorityData, setPriorityData] = useState<TDonutChartDataProps[]>([]);

  const massageData = (rawData: any): TDonutChartDataProps[] => {
    const result = _.chain(rawData)
      .sortBy(['label'])
      .map(i => {
        i.color = colorMap[JSON.stringify(i.label)];
        i.label = `Priority ${i.label}`;
        return i;
      })
      .value();
    return result;
  };

  const isDemo = window.location.pathname.includes('premium');

  const getPriorityData = () => {
    setIsLoading(true);
    if (isDemo) {
      setPriorityData(mockPriorityWidgetData);
      setIsLoading(false);
      return;
    }
    if (enableCommunity) {
      dashboardService.getTyposquattingDomainAcquisitionCount().then((res: any) => {
        setPriorityData(massageData(res.counts) || []);
        setIsLoading(false);
      });
    } else {
      dashboardService
        .getDomainAcquisitionCountByField('acquisition_priority', activeOUId)
        .then((res: any) => {
          setPriorityData(massageData(res.counts) || []);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getPriorityData();
  }, [activeOUId]);

  return (
    <PriorityWidgetDataContext.Provider
      value={{
        isLoading,
        priorityData,
      }}
    >
      {children}
    </PriorityWidgetDataContext.Provider>
  );
};

import _ from 'lodash';
import moment from 'moment';
import '../../../Style/TakedownHistorySection.scss';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getDateTimeFormat } from '../../../../../constants';
import AttachedFileViewer from '../../../../Common/AttachedFileViewer';
import { LabelAndValue } from '../../../../Common/LabelAndValue';
import { DISPLAY_STATUS_MAP, EFindingStatus } from '../../../Types/ugc.types';

const DATE_TIME_FORMAT = 'DD-MMM-YYYY, h:mm a';
const TakedownHistorySection = ({ takedown }: any) => {
  const [values, setValues] = useState({
    takedownBy: '',
    takedownComment: '',
    requestTakedownTs: '',
    takedownUploads: [''],
    takedownTs: '',
    takedownStatus: '',
  });

  const initData = () => {
    if (!takedown) return;

    const { takedownStatus, takedownTs, history, createdTs } = takedown;
    const sortedHistory = _.orderBy(history, ['createdTs'], ['desc']);
    const latestTakedown = sortedHistory[0];
    const { user, comments, uploads } = latestTakedown;
    setValues({
      takedownBy: `${user.firstName} ${user.lastName}`,
      takedownComment: comments,
      requestTakedownTs: createdTs,
      takedownUploads: _.map(uploads, upload => upload.url),
      takedownTs,
      takedownStatus,
    });
  };

  useEffect(() => {
    initData();
  }, [takedown]);

  if (!values.requestTakedownTs) return null;

  return (
    <Card bg='light' className='card-between takedown-history-section'>
      <Card.Header className='self-card-header d-flex justify-content-between'>
        <div>Action Taken for Takedown</div>
        {values.takedownStatus === EFindingStatus.DOWN && (
          <div className='takedown-wrapper'>
            <div className='takedown-label'>{DISPLAY_STATUS_MAP[values.takedownStatus]}: </div>
            <div className='takedown-status'>
              {getDateTimeFormat(values.takedownTs, DATE_TIME_FORMAT)}
            </div>
          </div>
        )}
      </Card.Header>
      <Card.Body className='self-card-body'>
        <Row>
          <Col sm={6}>
            <LabelAndValue label={'Requested By'} value={values.takedownBy} direction={'column'} />
          </Col>
          <Col sm={6}>
            <LabelAndValue
              label='Most Recent Requested Date'
              value={getDateTimeFormat(values.requestTakedownTs, DATE_TIME_FORMAT)}
              direction={'column'}
              longTextLineNumberLimit={1}
            />
          </Col>
        </Row>
        {values.takedownComment && (
          <Row>
            <Col sm={12}>
              <LabelAndValue
                label={'Comment'}
                value={values.takedownComment}
                direction={'column'}
              />
            </Col>
          </Row>
        )}
        {values.takedownUploads?.length > 0 && (
          <Row>
            <Col sm={12}>
              <LabelAndValue
                label={'File Uploaded'}
                renderDom={
                  <AttachedFileViewer
                    attachedFiles={_.map(values.takedownUploads, img => ({
                      path: img,
                      name: img.split('/').pop() || img,
                      createdTS: '',
                    }))}
                  />
                }
                direction={'column'}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default TakedownHistorySection;

import React from 'react';
import { DISPLAYED_NULL } from '../../../../constants';
import { EDarkWebRiskLevel } from '../Types/DarkWeb.types';

interface IRiskLevelProps {
  risk: EDarkWebRiskLevel | number;
}

export const RISK_VALUE_TO_LABEL = {
  1: 'LOW',
  2: 'MEDIUM',
  3: 'HIGH',
};

export const RISK_LABEL_TO_VALUE = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export default function RiskLevel({ risk }: IRiskLevelProps) {
  let riskValue: any = DISPLAYED_NULL;
  if (typeof risk !== 'string') {
    riskValue = RISK_VALUE_TO_LABEL[risk]?.toLowerCase();
  } else {
    riskValue = risk?.toLowerCase();
  }
  return (
    <div className={'risk-level'} data-risk-level-value={riskValue}>
      <b>{riskValue}</b>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import NotificationServices from '../../services/notifications.service';
import { LoadingWrapper } from './LoadingWrapper';
import { history } from '../../helpers';

const notificationServics = new NotificationServices();
const DigestInsights = () => {
  const [isLoading, setIsLoading] = useState(true);

  const urlSha256: string = window.location.pathname.split('/').pop() || '';
  const insightLink = window.location.pathname
    .replace('/digest', '/insights')
    .split('/')
    .slice(0, 3)
    .join('/');

  useEffect(() => {
    setIsLoading(true);
    notificationServics
      .getLatestUrlInfo(urlSha256)
      .then((result: { latestTimestamp: number }) => {
        setIsLoading(false);
        history.push(`${insightLink}/${result.latestTimestamp}/${urlSha256}`);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        history.push('/web/dashboard');
      });
  }, []);

  return (
    <AuthenticationWrapper>
      <LoadingWrapper isLoading={isLoading}>Redirecting ...</LoadingWrapper>
    </AuthenticationWrapper>
  );
};

export default DigestInsights;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITags } from '../components/Common/Tags/Tags';

export interface TagsState {
  allPlatformTags: ITags[];
  modifyCustomTag: {
    selectedTag: null | ITags;
    isEditMode: boolean;
    isDeleteMode: boolean;
    triggerFetchCall: boolean;
  };
}
const initialState: TagsState = {
  allPlatformTags: [],
  modifyCustomTag: {
    selectedTag: null,
    isEditMode: false,
    isDeleteMode: false,
    triggerFetchCall: false,
  },
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setAllPlatformTags: (state, action: PayloadAction<ITags[]>) => {
      state.allPlatformTags = action.payload;
    },
    setModifyCustomTag: (
      state,
      action: {
        payload: {
          selectedTag: null | ITags;
          isEditMode: boolean;
          isDeleteMode: boolean;
          triggerFetchCall: boolean;
        };
        type: string;
      },
    ) => {
      state.modifyCustomTag = action.payload;
    },
  },
});

export const { setAllPlatformTags, setModifyCustomTag } = tagsSlice.actions;

export default tagsSlice.reducer;

import { userConstants, UserSignupDto, UserForgotPassDto, cleanUpParameters } from '../constants';
import UserService from '../services/user.service';
import { alertActions } from '.';
import { history } from '../helpers';
import { Dispatch } from 'redux';
import _ from 'lodash';
import packageJson from '../../package.json';

const login = (user: any) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    dispatch(request(user));
    return userService.login(user).then(
      (data: any) => {
        if (data.redirect) {
          window.location.href = window.location.origin.replace('platform', data.redirect);
          throw new Error(); // break the flow
        }

        dispatch(success());
        return { result: true };
      },
      (error: any) => {
        dispatch(failure(error));
        return { result: false };
      },
    );
  };

  function request(user: any) {
    return { type: userConstants.LOGIN_REQUEST, payload: user };
  }

  function success() {
    return { type: userConstants.LOGIN_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.LOGIN_FAILURE, payload: { error } };
  }
};

const acceptTermsAndCondition = (user: any) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    return userService.acceptTermsAndCondition(user).then(
      data => {
        if (data.redirect) {
          window.location.href = window.location.origin.replace('platform', data.redirect);
          throw new Error(); // break the flow
        }

        dispatch(success());
        return { result: true };
      },
      (error: any) => {
        dispatch(failure(error));
        return { result: false };
      },
    );
  };

  function success() {
    return { type: userConstants.ACCEPT_TERMS_AND_CONDITION_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.ACCEPT_TERMS_AND_CONDITION_FAILURE, payload: { error } };
  }
};

const acceptTermsAndConditionForSSO = (user: any) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    return userService.acceptTermsAndConditionForSso().then(
      data => {
        if (data.redirect) {
          window.location.href = window.location.origin.replace('platform', data.redirect);
          throw new Error(); // break the flow
        }

        dispatch(success());
        return { result: true };
      },
      (error: any) => {
        dispatch(failure(error));
        return { result: false };
      },
    );
  };

  function success() {
    return { type: userConstants.ACCEPT_TERMS_AND_CONDITION_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.ACCEPT_TERMS_AND_CONDITION_FAILURE, payload: { error } };
  }
};

const signOut = (redirectUri: string) => {
  // cleanup data before logout
  cleanUpParameters();

  const userService = new UserService();
  userService.logout().finally(() => {
    history.push(redirectUri);
  });
  return { type: userConstants.LOGOUT };
};

const logout = () => {
  return signOut('/sign-in');
};

const checkPhishUserLogout = () => {
  return signOut('/');
};

const register = (user: UserSignupDto) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    dispatch(request());

    userService.register(user).then(
      (user: any) => {
        dispatch(success());
        history.push('/sign-in');
        dispatch(alertActions.success('Please confirm your email!'));
      },
      (error: { toString: () => string }) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }

  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
};

const verify = (token: string, workEmailVerification = false) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    const functionMapper = {
      true: 'verifyWorkEmail',
      false: 'verify',
    };
    userService[functionMapper[workEmailVerification.toString()]](token).then(
      (data: any) => {
        dispatch(success(data));
        history.push('/sign-in');
        dispatch(
          alertActions.success(
            `You have successfully verified your ${workEmailVerification && 'work'} email`,
          ),
        );
      },
      (error: { toString: () => string }) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function success(result: boolean) {
    return { type: userConstants.VERIFY_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.VERIFY_FAILURE };
  }
};

const forgotpass = (user: UserForgotPassDto) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    userService.forgotPassword(user).then(
      (data: any) => {
        console.log(data);
        history.push('/forgot-password-helper');
        dispatch(success(data));
      },
      (error: { toString: () => string }) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function success(result: boolean) {
    return { type: userConstants.FORGOTPASS_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.FORGOTPASS_FAILURE };
  }
};

const resetpass = (password: string, token: string) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    return userService.resetPassword(password, token).then(
      (data: any) => {
        console.log(data);
        history.push('/sign-in');
        dispatch(success(data));
        return { result: true };
      },
      (error: { toString: () => string }) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        return { result: false };
      },
    );
  };

  function success(result: boolean) {
    return { type: userConstants.RESETPASS_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.RESETPASS_FAILURE };
  }
};

const checkAuthentication = () => {
  return (dispatch: Dispatch): any => {
    const userService = new UserService();
    return userService.checkAuthentication().then(
      (data: any) => {
        const currentUiVersion = packageJson.version;
        const requiredUiVersion = _.get(data, ['requiredUiVersion']);
        if (requiredUiVersion && requiredUiVersion !== currentUiVersion) {
          const msg = 'An updated version of Bolster is available. Please refresh to access it.';
          dispatch(alertActions.requiredAction(msg));
        }
        userService.saveFrontendVersion();
        dispatch(isSignedIn());
      },
      error => {
        dispatch(notAuthorized(error));
      },
    );
  };

  function isSignedIn() {
    return { type: userConstants.LOGIN_SUCCESS };
  }

  function notAuthorized(error: string) {
    return { type: userConstants.LOGIN_FAILURE, payload: { error } };
  }
};

const confirmRegistration = (user: object) => {
  return (dispatch: Dispatch) => {
    const userService = new UserService();
    return userService.confirmRegistration(user).then(
      (data: any) => {
        dispatch(success());
        logout();
        return { result: true, type: userConstants.LOGOUT };
      },
      (error: { toString: () => string }) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        return { result: false };
      },
    );
  };

  function success() {
    return { type: userConstants.CONFIRM_REGISTRATION_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.CONFIRM_REGISTRATION_FAILURE };
  }
};

export const userActions = {
  login,
  logout,
  checkPhishUserLogout,
  register,
  verify,
  forgotpass,
  resetpass,
  checkAuthentication,
  confirmRegistration,
  acceptTermsAndCondition,
  acceptTermsAndConditionForSSO,
  signOut,
};

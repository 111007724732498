import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthenticationWrapper } from '..';
import SlackAddApp from '../../assets/slack-add-app.png';
import SlackBolsterPlaybooks from '../../assets/slack-bolster-playbooks.png';
import PlaybookService from '../../services/playbook.service';

const playbookService = new PlaybookService();

const getRedirectUri = () => {
  return window.location.href.split('?')[0];
};

const getSlackOAuth = (code: string, callback: any) => {
  playbookService
    .getSlackOAuth(code, getRedirectUri())
    .then(res => callback && res.access_token && callback(res.access_token));
};

export const Slack = () => {
  const [token, setToken] = useState<string>('');

  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  if (code) getSlackOAuth(code, setToken);

  return (
    <AuthenticationWrapper>
      <div className='card'>
        <div className='container-fluid mt-3'>
          <h4>Slack Installation</h4>
          <ol>
            <li>
              Click "Add to Slack" button below, and select "Allow".
              <div className='mt-3 mb-3'>
                <a
                  // TODO: move slack client details to environment variables
                  href={`https://slack.com/oauth/v2/authorize?client_id=141448714886.1389142880725&scope=files:write&redirect_uri=${getRedirectUri()}`}
                >
                  <img
                    alt='Add to Slack'
                    height='40'
                    width='139'
                    src='https://platform.slack-edge.com/img/add_to_slack.png'
                    srcSet='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x'
                  />
                </a>
              </div>
            </li>
            <li>
              Copy Slack token to connector.
              <div className='row'>
                <div className='mt-3 col-xl-6'>
                  <input
                    className='form-control'
                    readOnly={true}
                    disabled={!token}
                    value={token}
                    placeholder='Token will be generated after completing step 1'
                  />
                </div>
              </div>
              <div className='mt-1 mb-3'>
                Note: If you lose this token, you can regenerate it by clicking "Add to Slack"
                again.
              </div>
            </li>
            <li>
              Add Slack to channel(s) that will be used by Playbook.
              <ul className='mt-3'>
                <li>
                  Click "Add apps" from "More" section of channel details.
                  <div className='mt-1 mb-3'>
                    <img
                      src={SlackAddApp}
                      alt={'Add app to Slack channel'}
                      style={{ border: '1px solid black' }}
                    />
                  </div>
                </li>
                <li>
                  Search for "Bolster Playbooks" and click "Add".
                  <div className='mt-1 mb-3'>
                    <img
                      src={SlackBolsterPlaybooks}
                      alt={'Select Bolster Playbooks app'}
                      style={{ border: '1px solid black' }}
                    />
                  </div>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </AuthenticationWrapper>
  );
};

import React, { Component } from 'react';
import './auth.scss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { alertActions, appActions } from '../../actions';
import {
  AlertActionsTypes,
  IAppDispatchProps,
  redirectToCheckphish,
  unsetLocalStorageValue,
} from '../../constants';
import { CompanyIcon } from '../Common/CompanyIcon';
import UserService from '../../services/user.service';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Spinner } from 'react-bootstrap';
import { SsoSignInButton } from '../Common/SsoSignInButton';
import GoogleLogo from '../../assets/logo/google_logo.svg';
import MicrosoftLogo from '../../assets/logo/office-logo.svg';
import SlackLogo from '../../assets/logo/slack.png';
import { PasswordEye, PASSWORD_LENGTH, PASSWORD_LIMIT } from '../Common/PasswordEye';
import { Checkbox } from '../Common/Checkbox';
import ThemeContext from '../../context/ThemeContext';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import TyposquatContext from '../../context/TyposquatContext';
import { history } from '../../helpers';
import { addHubspotScript } from '../../helpers/hubspot';
import { SignupPageV2 } from './signupV2.component';

interface IUserSignupProps {
  history: any;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

export interface UserSignupDto {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface IUserSignupState {
  createSucceed: boolean;
  isLoading: boolean;
  errMsg: string;
  formShown: boolean;
  user: UserSignupDto;
}

type Props = RouteComponentProps & IUserSignupProps & IAppDispatchProps;

class SignupPage extends Component<Props, IUserSignupState> {
  static contextType = ThemeContext;
  optIn: boolean;
  constructor(props: Props) {
    super(props);
    this.state = {
      createSucceed: false,
      isLoading: false,
      errMsg: '',
      user: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
      formShown: false,
    };
    this.optIn = false;
  }

  componentDidMount(): void {
    this.props.runByClientApp({
      onBolster: () => {
        window.document.title = 'Signup | Bolster Platform';
      },
      onCheckPhish: () => {
        window.document.title = 'Sign up | CheckPhish Platform';
        addHubspotScript();
      },
    });
  }

  onSubscribeChange = (value: any) => {
    this.optIn = value;
  };

  handleSubmit = (
    event: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    identifier: string,
  ) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const userService = new UserService();

    this.props.runByClientApp({
      onBolster: () => {
        const form = event.currentTarget;
        const elements = form.elements;
        const urlObj = new URL(window.location.href);
        const brand = urlObj.searchParams.get('brand');
        userService
          .signUp({
            firstName: elements.firstName.value,
            lastName: elements.lastName.value,
            email: elements.email.value,
            brand,
            number: elements.number.value,
          })
          .then(res => {
            this.setState({ isLoading: false, createSucceed: true });
          })
          .catch(err => {
            this.setState({ isLoading: false, errMsg: err });
          });
      },
      onCheckPhish: () => {
        const { firstName, lastName, email, password } = this.state.user;
        userService.signUp({ firstName, lastName, email, password, fingerprint: identifier }).then(
          () => {
            this.setState({
              isLoading: false,
              createSucceed: true,
            });
          },
          error => {
            this.setState({
              errMsg: error?.User ? error.User.toString() : error.toString(),
              isLoading: false,
            });
          },
        );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'signUpSubmitClicked',
          category: 'Page',
          action: 'clicked',
          label: 'signUpSubmitClicked',
        });
      },
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({
      user: { ...this.state.user, [name]: value },
    });
  };

  goBack = (domain: any) => {
    if (domain.length) {
      return history.push(`domain-monitor?domain=${domain}`);
    }
    redirectToCheckphish();
  };

  renderCheckphish(identifier: string) {
    const { user, errMsg, isLoading, formShown, createSucceed } = this.state;
    const { href } = window.location;
    const isTypoSquatError = href.includes('typosquat');
    const typosquatError =
      'You have reached your daily limit. Sign-up for free to obtain your history and keep monitoring your domain against threats.';
    const theme = this.context.selectedTheme;
    const { firstName, lastName, email, password } = user;
    const summitButtonDisabled =
      !firstName || !lastName || !email || !password || password.length > PASSWORD_LIMIT;
    let displayedErrMsg;
    if (errMsg === 'Please enter a validated work email') {
      displayedErrMsg = (
        <div>
          Please provide work email address. Free email providers not supported at this time for new
          accounts. If there is an error please
          <a href={'/contact-us'}> contact us</a>
        </div>
      );
    } else {
      displayedErrMsg = errMsg;
    }
    const title = 'Sign Up | CheckPhish';
    const url = 'https://checkphish.ai/sign-up';
    const description =
      'CheckPhish is an Artificial Intelligence powered zero-day phishing detection.';
    const type = 'website';
    const imagePath = 'https://checkphish.ai/static/media/og-image.9d427d73.png';
    const keywords =
      'url,website,phishing,scanner,analyze,cyber threat intelligence, open source threat intelligence, online fraud';
    return (
      <AuthenticationWrapper>
        <div className={'sign-up-via-brand-page'}>
          <Helmet>
            <title>{title}</title>
            <meta name='robots' content='index, follow' />
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />

            <meta property='og:type' content={type} />
            <meta property='og:url' content={url} />
            <meta name='title' property='og:title' content={title} />
            <meta name='description' property='og:description' content={description} />
            <meta name='image' property='og:image' content={imagePath} />

            <meta name='twitter:description' content={description} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:image' content={imagePath} />
            <meta name='twitter:site' content='@checkphish' />
            <meta name='twitter:creator' content='@checkphish' />
          </Helmet>
          <div className={'sign-up-brands-container'} id={'sign-up-brands-container'}>
            <div className={'form-container sign-up-container'}>
              <div className={'back-btn'}>
                <TyposquatContext.Consumer>
                  {(state: any) => {
                    const { domain } = state;
                    return (
                      <button className='btn transparent-btn' onClick={() => this.goBack(domain)}>
                        <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
                        Back
                      </button>
                    );
                  }}
                </TyposquatContext.Consumer>
              </div>
              <CompanyIcon className='checkphish-logo-login' />
              {isTypoSquatError && (
                <div className={'typo-error'}>
                  <div className={'err'}>{typosquatError}</div>
                </div>
              )}
              {errMsg ? (
                <h2 className={'title-text pb-5 m-auto text-center custom-text-coral'}>
                  <text>
                    {errMsg} <br /> Please register below or{' '}
                    <Link to={'/sign-in'} className={'pl-2 custom-text-coral'}>
                      <u>log in</u>
                    </Link>
                  </text>
                </h2>
              ) : (
                <>
                  <h2 className={'title-text pb-3 flex-center m-auto text-center'}>
                    Sign up for free continuous monitoring
                  </h2>
                  <h6 className={'title-text pb-3 flex-center m-auto text-center'}>
                    Find and take down typosquat domains with automated scans
                  </h6>
                </>
              )}
              <div
                className={'feature-list-and-sign-up-form flex-center w-100 align-items-stretch'}
              >
                <div className={'auth-page-wrapper sign-up-component-custom-css'}>
                  {!formShown ? (
                    <>
                      <div className={'flex-column form-group email-component bottom-component'}>
                        <input
                          type='email'
                          className={'form-control email-inp' + (errMsg ? ' error' : '')}
                          placeholder='name@work-email.com'
                          name='email'
                          value={email}
                          onChange={this.handleChange}
                        />
                        <button
                          type='submit'
                          className={'btn mt-3 submit-button w-100 text-white'}
                          onClick={() => this.setState({ formShown: true })}
                          disabled={!email.length}
                        >
                          Continue
                        </button>
                      </div>
                    </>
                  ) : (
                    <form name='form' onSubmit={e => this.handleSubmit(e, identifier)}>
                      <h3>Sign up:</h3>
                      {!createSucceed && errMsg && (
                        <div className={'login-error'}>
                          <div className={'help-block'}>{displayedErrMsg}</div>
                        </div>
                      )}
                      {!createSucceed && (
                        <div>
                          <div className={'form-group email-component'}>
                            <label className={'email-label'}>First Name</label>
                            <input
                              className={'form-control'}
                              placeholder='First Name'
                              name='firstName'
                              value={firstName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className={'form-group email-component'}>
                            <label className={'email-label'}>Last Name</label>
                            <input
                              className={'form-control'}
                              placeholder='Last Name'
                              name='lastName'
                              value={lastName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className={'form-group email-component'}>
                            <label className={'email-label'}>Work Email</label>
                            <input
                              type='email'
                              className={'form-control' + (errMsg ? ' error' : '')}
                              placeholder='Enter email'
                              name='email'
                              value={email}
                              onChange={this.handleChange}
                            />
                          </div>
                          <PasswordEye
                            label={'Password'}
                            name={'password'}
                            onChange={this.handleChange}
                          />
                          {password && password.length > PASSWORD_LIMIT && (
                            <div className='help-block'>
                              Password has to be {PASSWORD_LENGTH} letters or more and less than{' '}
                              {PASSWORD_LIMIT} letters
                            </div>
                          )}
                          <div className={'form-group email-component'}>
                            <Checkbox
                              label={'Subscribe to CheckPhish newsletter'}
                              onChange={this.onSubscribeChange}
                            />
                          </div>
                        </div>
                      )}
                      {createSucceed ? (
                        <div className={'login-success'}>
                          <div className={'pt-3'}>
                            {' '}
                            We have sent an email with a confirmation link to your email address. In
                            order to complete the sign-up process, please click the confirmation
                            link.
                          </div>
                          <div className={'pt-3'}>
                            If you do not receive a confirmation email, please check your spam
                            folder. Also, please verify that you entered a valid email address in
                            our sign-up form.
                          </div>
                          <div className={'pt-3'}>
                            If you need assistance, please{' '}
                            <a href='mailto:sales@bolster.ai'>contact us.</a>
                          </div>
                        </div>
                      ) : (
                        <div className={'bottom-component'}>
                          <div className='form-group row m-0 align-items-center'>
                            <button
                              disabled={summitButtonDisabled}
                              type='submit'
                              className={'btn submit-button text-white'}
                            >
                              {isLoading ? (
                                <Spinner
                                  className='spinner'
                                  animation='border'
                                  variant='light'
                                  size='sm'
                                />
                              ) : (
                                'Submit'
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  )}

                  {!formShown && (
                    <>
                      <div className={'small font-weight-bold text-secondary text-center my-3'}>
                        OR
                      </div>
                      <SsoSignInButton
                        icon={MicrosoftLogo}
                        href={`/platform-api/v1/sso/oauth/login/MICROSOFT${
                          identifier ? `?fingerprint=${identifier}` : ''
                        }`}
                        label={'Sign Up with Office 365'}
                        color={theme === 'dark' ? '#ffffff' : '#64717E'}
                        eventName='SSsoSignUpOffice365Clicked'
                      />
                      <SsoSignInButton
                        icon={GoogleLogo}
                        href={`/platform-api/v1/sso/oauth/login/GOOGLE${
                          identifier ? `?fingerprint=${identifier}` : ''
                        }`}
                        label={'Sign Up with Google'}
                        color={theme === 'dark' ? '#ffffff' : '#64717E'}
                        eventName='SSsoSignUpGoogleClicked'
                      />
                      <SsoSignInButton
                        icon={SlackLogo}
                        href={`/platform-api/v1/sso/oauth/login/SLACK${
                          identifier ? `?fingerprint=${identifier}` : ''
                        }`}
                        label={'Sign Up with Slack'}
                        color={theme === 'dark' ? '#ffffff' : '#64717E'}
                        eventName='SSsoSignUpSlackClicked'
                      />
                    </>
                  )}

                  <div className={'pt-3 font-size-normal'}>
                    Already have an account?{' '}
                    <Link className='link-ocean-theme' to={'/sign-in'}>
                      Log in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthenticationWrapper>
    );
  }

  renderPlatform(identifier: string) {
    const { errMsg, isLoading, createSucceed } = this.state;
    let displayedErrMsg;
    if (errMsg === 'Please enter a validated work email') {
      displayedErrMsg = (
        <div>
          Please provide work email address. Free email providers not supported at this time for new
          accounts. If there is an error please
          <a href={'/contact-us'}> contact us</a>
        </div>
      );
    } else {
      displayedErrMsg = errMsg;
    }
    return (
      <div>
        <div className={'auth-title '}>
          <CompanyIcon withName />
        </div>
        <h2 className={'auth-description flex-center text-center m-auto'}>
          Create your account on the Bolster platform to explore its full functionality
        </h2>
        <div className={'auth-page-wrapper'}>
          <form name='form' onSubmit={e => this.handleSubmit(e, identifier)}>
            <h3>Sign up for access:</h3>
            {!createSucceed && errMsg && (
              <div className={'login-error'}>
                <div className={'help-block'}>{displayedErrMsg}</div>
              </div>
            )}
            {!createSucceed && (
              <div>
                <div className={'form-group sign-up-form-component'}>
                  <label className={'label'}>First Name</label>
                  <input
                    required
                    className={'form-control'}
                    placeholder='First Name'
                    name='firstName'
                  />
                </div>
                <div className={'form-group sign-up-form-component'}>
                  <label className={'label'}>Last Name</label>
                  <input
                    required
                    className={'form-control'}
                    placeholder='Last Name'
                    name='lastName'
                  />
                </div>
                <div className={'form-group sign-up-form-component'}>
                  <label className={'label'}>Work Email</label>
                  <input
                    required
                    type='email'
                    className={'form-control'}
                    placeholder='Enter email'
                    name='email'
                  />
                </div>
                <div className={'form-group sign-up-form-component'}>
                  <label className={'label'}>Phone Number (optional) </label>
                  <input className={'form-control'} placeholder='(xxx)xxx-xxxx' name='number' />
                </div>
              </div>
            )}
            {createSucceed ? (
              <div className={'login-success'}>
                <div className={'pt-3'}>
                  Thanks for sending a request to sign up a Bolster user. We will reach you shortly.
                </div>
              </div>
            ) : (
              <div className={'bottom-component'}>
                <div className='form-group'>
                  <button
                    type='submit'
                    className={'btn btn-primary' + (isLoading ? 'disabled' : '')}
                  >
                    {isLoading ? (
                      <img
                        alt='waiting icon'
                        src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                      />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
            )}
            <div className={'pt-3 font-size-normal'}>
              Already have an account? <Link to={'/sign-in'}>Log in</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    return (
      <CheckPhishContactUsContext.Consumer>
        {({ identifier }) =>
          this.props.runByClientApp({
            onCheckPhish: () => {
              return (
                <SignupPageV2
                  identifier={identifier}
                  alertSuccess={this.props.alertSuccess}
                  alertError={this.props.alertError}
                />
              );
            },
            onBolster: () => {
              return this.renderPlatform(identifier);
            },
          })
        }
      </CheckPhishContactUsContext.Consumer>
    );
  }
}

const mapDispatchToProps = {
  runByClientApp: appActions.runByClientApp,
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const connectedSignupPage = connect(null, mapDispatchToProps)(SignupPage);
export { connectedSignupPage as SignupPage };

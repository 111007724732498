//helper methods for pricing mvp
import { EPlanProduct } from '../Common/Subscription';
import { IPricingPlan, ISubsciptionPlan, PlanTerm, term } from './types';
import _ from 'lodash';

export const getAvailablePlans = (allPlans: IPricingPlan[], selectedTerm: term): IPricingPlan[] => {
  if (_.isEmpty(allPlans) || _.isEmpty(selectedTerm)) return [];
  return allPlans.filter((plan: IPricingPlan) => plan.term === selectedTerm);
};

export const generatePrice = (
  plan: IPricingPlan | ISubsciptionPlan | undefined,
  quantity: number,
): number => {
  if (!plan) return 0;
  let finalPrice = plan.price * plan.limit * quantity;
  if (plan.discounts) {
    const { minQuantity, price } = plan.discounts[0];
    if (quantity > minQuantity) {
      finalPrice = price * plan.limit * quantity;
    }
  }
  return finalPrice;
};

export const getDiscountValue = (
  plan: IPricingPlan | ISubsciptionPlan | undefined,
  quantity: number = 1,
) => {
  if (plan?.product === EPlanProduct.TYPOSQUATTING && plan.term === PlanTerm.YEARLY) {
    return 100 * quantity;
  }
  if (!plan || !plan.discounts) return 0;
  const { minQuantity, price } = plan.discounts[0];
  if (quantity <= minQuantity) return 0;
  return plan.price * plan.limit * quantity - price * plan.limit * quantity;
};

export const convertToInternationalFormat = (count: number, noOfDays: number): string => {
  const formatter = new Intl.NumberFormat();
  return formatter.format(count * noOfDays);
};

import React, { MouseEvent } from 'react';
import _ from 'lodash';
import './profile.scss';
import { PageTitle } from '../Common/PageTitle';
import UserService from '../../services/user.service';
import { PasswordEye, PASSWORD_LENGTH, PASSWORD_LIMIT } from '../Common/PasswordEye';
import Button from 'react-bootstrap/Button';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { connect } from 'react-redux';
import { alertActions } from '../../actions';
import { AppState } from '../../helpers';
import { AlertActionsTypes } from '../../constants';

interface IUserChangePassState {
  user: {
    currPassword: string;
    newPassword: string;
  };
  submitted: boolean;
  idxCurr: number;
  idxNew: number;
}

interface ILinkDispatchProps {
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

type Props = ILinkDispatchProps;

class ChangePassword extends React.Component<Props, IUserChangePassState> {
  private readonly userService: UserService;
  private currentPasswordDom: any = '';
  private newPasswordDom: any = '';
  private errMsg: any = [
    '',
    'Current password should not be empty.',
    'New password should not be empty.',
    'Please enter a password with ' +
      PASSWORD_LENGTH +
      ' or more characters and less than ' +
      PASSWORD_LIMIT +
      ' letters',
    'Invalid current password. Please contact support@bolster.ai.',
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      user: {
        currPassword: '',
        newPassword: '',
      },
      submitted: false,
      idxCurr: 0,
      idxNew: 0,
    };
    this.userService = new UserService();
    window.document.title = 'Security | Bolster Platform';
  }

  onChangeCurrentPassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const password = event.currentTarget.value;
    let tempIdx = 0;
    if (!_.isEmpty(password) && password.length > PASSWORD_LIMIT) {
      tempIdx = 3;
    }
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        currPassword: password,
      },
      idxCurr: tempIdx,
    });
  };

  onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const password = event.currentTarget.value;
    const { user } = this.state;
    let tempIdx = 0;
    if (!_.isEmpty(password) && password.length > PASSWORD_LIMIT) {
      tempIdx = 3;
    }
    this.setState({
      user: {
        ...user,
        newPassword: password,
      },
      idxNew: tempIdx,
    });
  };

  handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const { alertSuccess, alertError } = this.props;
    this.setState({ submitted: true });
    const currPassword = this.currentPasswordDom.state.password;
    const newPassword = this.newPasswordDom.state.password;
    const user = {
      currPassword,
      newPassword,
    };
    if (currPassword === '' && newPassword === '') {
      this.setState({ submitted: true, idxCurr: 1, idxNew: 2 });
    } else if (currPassword === '' && newPassword !== '') {
      this.setState({ submitted: true, idxCurr: 1, idxNew: 0 });
    } else if (currPassword !== '' && newPassword === '') {
      this.setState({ submitted: true, idxCurr: 0, idxNew: 2 });
    } else if (newPassword.length < PASSWORD_LENGTH) {
      this.setState({ submitted: true, idxNew: 3 });
    } else if (currPassword && newPassword) {
      // Send to the user service to submit to backend
      this.userService
        .changePassword(user)
        .then((data: any) => {
          console.log(data);
          this.setState({ submitted: true, idxCurr: 0, idxNew: 0 });
          alertSuccess('Change password successfully.');
        })
        .catch(err => {
          console.log(err);
          this.setState({ submitted: true, idxCurr: 4, idxNew: 0 });
          alertError(err);
        });
    }
  };

  handleMouseDownPassword(event: MouseEvent) {
    event.preventDefault();
  }

  render() {
    const { user, submitted, idxCurr, idxNew } = this.state;
    const summitButtonDisabled =
      _.isEmpty(user.currPassword) ||
      user.currPassword.length > PASSWORD_LIMIT ||
      _.isEmpty(user.newPassword) ||
      user.currPassword.length > PASSWORD_LIMIT;
    return (
      <AuthenticationWrapper>
        <PageTitle title={'Security & Privacy'} titleAlwaysShown className={'background-white'} />
        <div className={'change-password-page'}>
          <h6 className={'section-title'}>Change password</h6>
          <form name='form' className={'change-password-wrapper'}>
            <PasswordEye
              isError={idxCurr > 0}
              label={'Current Password'}
              name={'currPassword'}
              onChange={this.onChangeCurrentPassword}
              ref={(r: any) => {
                this.currentPasswordDom = r;
              }}
            />
            <div
              className={'help-block' + (submitted || idxCurr !== 0 ? ' error-text' : ' hidden')}
            >
              {this.errMsg[idxCurr]}
            </div>
            <PasswordEye
              isError={idxNew > 0}
              label={'New Password'}
              name={'newPassword'}
              onChange={this.onChangeNewPassword}
              ref={(r: any) => {
                this.newPasswordDom = r;
              }}
            />
            <div className={'help-block' + (idxNew !== 0 ? ' error-text' : ' hidden')}>
              {this.errMsg[idxNew]}
            </div>
          </form>
          <Button
            disabled={summitButtonDisabled}
            className='submit-button'
            variant='primary'
            onClick={this.handleSubmit}
          >
            {' '}
            Save Changes{' '}
          </Button>
        </div>
      </AuthenticationWrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const connectedChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
export { connectedChangePassword as ChangePassword };

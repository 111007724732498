import React from 'react';
import SafeList from '../../../Ugc/Components/Findings/SafeList';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  MARKET_PLACE_TABLE_NAME,
  MARKET_PLACE_PATHNAME,
  MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID,
} from '../../constants';

const MarketplaceSafelist = () => {
  return (
    <SafeList
      type={ugcType.Marketplace}
      tableId={MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID}
      urlColPathName={MARKET_PLACE_PATHNAME}
      tableName={MARKET_PLACE_TABLE_NAME}
    />
  );
};

export default MarketplaceSafelist;

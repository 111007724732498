import React from 'react';
import DashboardService from '../../services/dashboard.service';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import '../Insights/insights.scss';
import { PageTitleInsights } from '../Common/PageTitleInsights';
import { history } from '../../helpers';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { LabelAndValue } from '../Common/LabelAndValue';
import { ugcType } from '../Ugc/Types/ugc.types';
import { appConstants } from '../../constants';

interface IDomTreeState {
  domTreeText: any;
}

interface IDomTreeProps extends Partial<RouteComponentProps<any>> {
  timestamp: string;
  urlSHA256: string;
  type: string;
  domSourcePath?: string;
}
type Props = IDomTreeProps;

class DomTree extends React.Component<Props, IDomTreeState> {
  private readonly dashboardService: DashboardService;
  private _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      domTreeText: undefined,
    };
    this.dashboardService = new DashboardService();

    window.document.title = 'Dom Tree | Dashboard ';
  }

  componentDidMount() {
    this._isMounted = true;

    const { timestamp, urlSHA256, type } = this.props as IDomTreeProps;
    this.getDomTreeDetails(
      timestamp,
      urlSHA256,
      type === appConstants.CONTENT_TYPE.BULK_SCAN ? appConstants.CONTENT_TYPE.WEB : type,
    );
  }

  getDomTreeDetails(timestamp: string, token: string, type: string): void {
    this.dashboardService
      .getDomTree(timestamp, token, type)
      .then((resp: any) => {
        this.setCompState({
          domTreeText: resp.data,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  renderDownloadButton = () => {
    const { domTreeText } = this.state;
    const localState: any = localStorage.getItem('linkState');
    const linkState = JSON.parse(localState);
    if (_.isEmpty(domTreeText)) {
      return null;
    }

    const data = new Blob([domTreeText], { type: 'text/plain' });
    const url = window.URL.createObjectURL(data);

    return (
      <a
        id='download_dom'
        href={linkState?.type === ugcType.App_Store ? linkState?.domSourcePath : url}
        download='dom_tree_file.txt'
        className={'download-button px-2'}
      >
        download
      </a>
    );
  };

  render() {
    const { domTreeText } = this.state;
    const { type } = this.props;

    return (
      <div data-testid='dom-tree-wrapper'>
        <Row className='page-content dom-tree-page-content'>
          <Card bg='light'>
            <Card.Body>
              <LabelAndValue
                label={'DOM Tree (Document Object Model)'}
                noMarginBottom
                value={domTreeText}
                renderDom={
                  <pre className={'dom-tree'}>
                    <code>{domTreeText}</code>
                  </pre>
                }
                customButton={this.renderDownloadButton()}
                direction={'column'}
              />
            </Card.Body>
          </Card>
        </Row>
      </div>
    );
  }
}

export { DomTree };

import _, { isNull, max } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { numberWithCommas, ThemeModes, IRunByClientAppType } from '../../../../../constants';
import ThemeContext from '../../../../../context/ThemeContext';
import { ITopFeedsScannedForBrand } from '../../../Types/dashboard.types';
import { ETabTypes, IDefaultSourceFeeds } from '../MonitoredContainerV2';
import './VerticalSourceFeedBars.scss';
import { connect } from 'react-redux';
import { appActions } from '../../../../../actions';

interface IProps {
  feed: ITopFeedsScannedForBrand | IDefaultSourceFeeds;
  maxValue: number;
  activeTab: ETabTypes;
  onSubTabClick: (selectedTab: ITopFeedsScannedForBrand | IDefaultSourceFeeds) => void;
  selectedLabel: string;
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
}
function VerticalSourceFeedBars(props: IProps) {
  const progressRef = useRef<any>(null);
  const { selectedTheme } = useContext(ThemeContext);
  const {
    feed: {
      label,
      count,
      colorCodingDark,
      colorCodingLight,
      displayOrder,
      iconDarkUrl,
      iconLightUrl,
    },
    maxValue,
    activeTab,
    onSubTabClick,
    selectedLabel,
  } = props;

  useEffect(() => {
    const progressElement = progressRef.current;
    if (progressElement && progressElement.style.animation) {
      progressElement.style.animation = 'fillProgress 1s linear';
    }
  }, []);

  const enableCommunity = props.runByClientApp({
    onCheckPhish: () => true,
    onBolster: () => false,
  });

  const computeHeight = (count: number, maxValue: number) => {
    if (count === maxValue && maxValue !== 0) {
      return 100;
    }
    if (count === 0) {
      return 0;
    }
    const computedHeight = (count / maxValue) * 100 + 20;
    return computedHeight;
  };

  const getColorForLabel = (count: number) => {
    if (count === 0 && selectedTheme === ThemeModes.LIGHT.toLowerCase()) {
      return '-dark-font-color';
    } else if (displayOrder !== undefined && displayOrder <= 1 && count !== 0) {
      return '-dark-font-color';
    } else {
      return '';
    }
  };
  return (
    <div
      className={`progress-container ${
        enableCommunity && label !== 'Typosquat Generation' ? 'progress-container-disabled' : ''
      } ${selectedLabel.length > 0 && label !== selectedLabel ? 'darken' : ''}`}
      onClick={() =>
        activeTab === ETabTypes.MONITORED_GLOBALLY ? _.noop() : onSubTabClick(props.feed)
      }
    >
      <div className='progress-icon-container'>
        <img src={selectedTheme === ThemeModes.DARK.toLowerCase() ? iconDarkUrl : iconLightUrl} />
      </div>
      <div
        ref={progressRef}
        className='source-progress-bar'
        style={{
          backgroundColor: `${
            selectedTheme === ThemeModes.DARK.toLowerCase() ? colorCodingDark : colorCodingLight
          }`,
          height: `${computeHeight(count, maxValue)}%`,
        }}
      >
        {count >= 0 && (
          <div className={`source-description${getColorForLabel(count)}`}>
            {enableCommunity && label === 'Typosquat Generation' && (
              <div className='source-count'>{numberWithCommas(count)}</div>
            )}
            {!enableCommunity && <div className='source-count'>{numberWithCommas(count)}</div>}
            <div className='source-label'>{label || ''}</div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  runByClientApp: appActions.runByClientApp,
};

const connectedVerticalSourceFeedBars = connect(
  undefined,
  mapDispatchToProps,
)(VerticalSourceFeedBars);
export { connectedVerticalSourceFeedBars as VerticalSourceFeedBars };

const blue = '#72E3FE';
const grey = '#B8B8B8';
const purpleLight1 = '#F8C7AA';
const purpleLight2 = '#F2A49F';
const purpleLight3 = '#EC7C92';
const purpleLight4 = '#E65586';
const purpleLight5 = '#BC438B';
const purpleDark1 = '#933291';
const purpleDark2 = '#692398';
const purpleDark3 = '#551C7B';
const purpleDark4 = '#41155E';
const purpleDark5 = '#3D006C';

const typosquattingRiskScore5 = '#FF1F1F';
const typosquattingRiskScore4 = '#FF4F4E';
const typosquattingRiskScore3 = '#FF7629';
const typosquattingRiskScore2 = '#FFA001';
const typosquattingRiskScore1 = '#64717E';

export const colors = {
  blue,
  grey,
  purpleLight1,
  purpleLight2,
  purpleLight3,
  purpleLight4,
  purpleLight5,
  purpleDark1,
  purpleDark2,
  purpleDark3,
  purpleDark4,
  purpleDark5,
  // color named by functionality:
  background: '#ffffff',
  allScans: grey,
  takedown: '#71C669',
  socialTakenDown: '#76E6FF',
  live: '#EE4526',
  socialLive: '#FF1F1F',
  category1: '#FF504E',
  category2: '#FF6B3A',
  category3: '#FF8C21',
  category4: '#FFA112',
  category5: purpleLight4,
  barChart1: '#FFA001',
  barChart2: '#FF7629',
  barChart3: '#FF4F4E',
  barChart4: '#FF1F1F',
  barChart5: blue,
  highRisk: '#E9668C',
  priority1: '#A83C8F',
  priority2: '#C85E92',
  priority3: '#ED8595',
  priority4: '#EF96A4',
  priority5: '#F2A7B3',
  donutChart1: purpleLight1,
  donutChart2: purpleLight2,
  donutChart3: purpleLight3,
  donutChart4: purpleLight4,
  donutChart5: purpleLight5,
  donutChart6: purpleDark1,
  donutChart7: purpleDark2,
  donutChart8: purpleDark3,
  donutChart9: purpleDark4,
  donutChart10: purpleDark5,
  legendText: '#333333',
  axisLabel: '#64717E',
  chartBorderColor: '#E9ECF0',
  typosquattingRiskScore5,
  typosquattingRiskScore4,
  typosquattingRiskScore3,
  typosquattingRiskScore2,
  typosquattingRiskScore1,
  darkWebEntityAny: '#FF9680',
  darkWebEntityBreachDataForSale: '#F37D3A',
  darkWebEntityEmployeeMonitoring: '#DB5002',
  darkWebEntityHackerChatter: '#D59029',
  darkWebEntityIPLeakDataForSale: '#A15146',
  darkWebEntityMalwareExploitsForSale: '#AE8161',
  darkWebEntityPhishingKitsForSale: '#C85D5D',
  darkWebEntityRandomwareForSale: '#FFAA2A',
  darkWebEntityDefault: '#4D4D4D',
  darkWebPriority1: '#F37D3A',
  darkWebPriority2: '#842915',
  darkWebPriority3: '#FFC600',
  darkWebPriority4: '#E05417',
  darkWebPriority5: '#FFAA2A',
  darkWebHighRisk: '#801E1E',
  darkWebMediumRisk: '#FF8D23',
  darkWebLowRisk: '#FFC600',
};

export type IColors = typeof colors;

export const colorsLightTheme = {
  ...colors,
  darkWebHighRisk: '#801E1E',
  darkWebMediumRisk: '#FF8D23',
  darkWebLowRisk: '#FFC600',
};

export const colorsDarkTheme = {
  ...colors,
  background: '#1C1F22',
  legendText: '#BCBCBC',
  axisLabel: '#FFFFFF',
  chartBorderColor: '#4D5563',
  priority1: '#AD43EA',
  priority2: '#D043EA',
  priority3: '#EA43E2',
  priority4: '#EA43AA',
  priority5: '#EA4388',
  purpleDark2: '#95357F',
  takedown: '#71C669',
  live: '#EE4526',
  socialTakenDown: '#72E3FE',
  socialLive: '#CA5540',
  darkWebHighRisk: '#C80E01',
  darkWebMediumRisk: '#FF8D23',
  darkWebLowRisk: '#FFC600',
};

export const getColors = (theme: string) => {
  switch (theme) {
    case 'light':
      return colorsLightTheme;
    case 'dark':
      return colorsDarkTheme;
    default:
      return colors;
  }
};

// TODO: remove: below are the colors for old dashboard widgets. Remove them after dashboard v2 is released.
export const THEME_COLOR = 'black';
export const THEME_COLOR_ORANGE = '#FFA25F';
export const THEME_COLOR_BLUE_GREEN = '#3CB2BC';
export const THEME_COLOR_BLUE_GRAY = '#899AAD';
export const THEME_COLOR_GREEN = '#008000';
export const THEME_COLOR_BLUE_5 = '#007EA7';
export const THEME_COLOR_BLUE_4 = '#0091E6';
export const THEME_COLOR_BLUE_3 = '#74C5F4';
export const THEME_COLOR_BLUE_2 = '#A7DFFF';
export const THEME_COLOR_BLUE_1 = '#dfeaf3';
export const THEME_DARK_GRAY_3 = '#64717E';
export const THEME_LIGHT_GRAY_2 = '#e1e1e1';
export const THEME_LIGHT_GRAY_1 = '#f4f4f5';
export const THEME_COLOR_RED = '#CF1D05';

import _ from 'lodash';
import React from 'react';
import { generateId, getLocalStorageValue, setLocalStorageValue } from '../../../constants';
import { history } from '../../../helpers';
import {
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
  WEB_DASHBOARD_PATHNAME,
} from '../../MonitorAndTakedown/constants';
import { IRelatedCounts } from './GroupedHeaderRowItems';

interface IGroupedBottomProps {
  domainName: string;
  relatedCounts: any;
}

const routeToMaliciousTable: object = {
  ['Pre-Malicious']: {
    pathName: `${WEB_DASHBOARD_PATHNAME}/pre-malicious`,
    tableId: PRE_MALICIOUS_TABLE_ID,
  },
  ['Takedown']: {
    pathName: `${WEB_DASHBOARD_PATHNAME}/takedown-malicious`,
    tableId: TAKEDOWN_MALICIOUS_TABLE_ID,
  },
  ['Post-Malicious']: {
    pathName: `${WEB_DASHBOARD_PATHNAME}/post-malicious`,
    tableId: POST_MALICIOUS_TABLE_ID,
  },
};

const idToLabels = {
  [POST_MALICIOUS_TABLE_ID]: 'Post-Malicious',
  [PRE_MALICIOUS_TABLE_ID]: 'Pre-Malicious',
  [TAKEDOWN_MALICIOUS_TABLE_ID]: 'Takedown',
};

export default function GroupedBottomCounts({ domainName, relatedCounts }: IGroupedBottomProps) {
  const initialFilters = {
    id: generateId(10),
    filterBy: { label: 'Domain Name', value: 'primary_domain' },
    filterErr: '',
    filterLabel: domainName,
    filterMethod: { label: 'Includes', value: 'includes' },
    filterType: 'string',
    filterValue: domainName,
  };

  const redirectToMalicousTable = (label: any) => {
    const savedSettings = getLocalStorageValue([
      'tableSetting',
      routeToMaliciousTable[label]?.tableId,
    ]);

    const oldFilters = savedSettings?.filters?.length > 0 ? [...savedSettings?.filters] : [];
    const existedDomainFilter = _.filter(
      savedSettings?.filters,
      filter => filter.filterLabel === domainName,
    );
    let combinedFilters: any = [];

    if (existedDomainFilter?.length > 0) {
      combinedFilters = [...oldFilters];
    } else {
      combinedFilters = [...oldFilters, initialFilters];
    }

    setLocalStorageValue(['tableSetting', routeToMaliciousTable[label]?.tableId], {
      filters: combinedFilters,
    });
    history.push(routeToMaliciousTable[label]?.pathName);
  };

  return (
    <tr>
      <div className='grouped-bottom-wrapper'>
        {relatedCounts.map((item: IRelatedCounts, idx: number) => {
          return (
            <div className='count-wrapper' key={idx}>
              <div>{idToLabels[item.type]}</div>
              <span onClick={() => redirectToMalicousTable(idToLabels[item.type])}>
                {item.count}
              </span>
            </div>
          );
        })}
      </div>
    </tr>
  );
}

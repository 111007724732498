import _ from 'lodash';
import React, { useState, createContext } from 'react';
import { EDarkWebFindingStatus } from '../components/DarkWeb/Components/Types/DarkWeb.types';
import { EFindingStatus } from '../components/Ugc/Types/ugc.types';
import {
  ETyposquattingStatus,
  TYPOSQUAT_DASHBOARD_TABLE_ID_DASH,
  TYPOSQUAT_TABLE_ID_DASH,
  TYPO_MALICIOUS_TABLE_ID_DASH,
  TYPO_PRE_MALICIOUS_TABLE_ID_DASH,
} from '../components/DomainMonitoring/constant';

const ActiveRoutesContext = createContext({
  activeRoute: '',
  getParentRouteViaFindingStatus: _.noop,
});

export default ActiveRoutesContext;

enum EWebMaliciousStatus {
  PRE_MALICIOUS = 'pre-malicious',
  POST_MALICIOUS = 'post-malicious',
  TAKEDOWN_MALICIOUS = 'takedown-malicious',
}
export const ugcRoutesAsPerStatus = {
  [EFindingStatus.LIVE]: 'detection',
  [EFindingStatus.IN_PROGRESS]: 'takedown-in-progress',
  [EFindingStatus.SAFELIST]: 'ignored',
  [EFindingStatus.DOWN]: 'takendown',
  [EFindingStatus.UNDER_REVIEW]: 'review',
  [EFindingStatus.PENDING]: 'review',
  [EFindingStatus.PAUSED]: 'takedown-in-progress',
  [EDarkWebFindingStatus.ACTIVE]: 'active',
  [EDarkWebFindingStatus.IGNORED]: 'ignored',
  [EDarkWebFindingStatus.MITIGATED]: 'mitigated',
  [EWebMaliciousStatus.PRE_MALICIOUS]: 'pre-malicious',
  [EWebMaliciousStatus.POST_MALICIOUS]: 'post-malicious',
  [EWebMaliciousStatus.TAKEDOWN_MALICIOUS]: 'takedown-malicious',
  [ETyposquattingStatus.TYPO_PRE_MALICIOUS_TABLE]: TYPO_PRE_MALICIOUS_TABLE_ID_DASH,
  [ETyposquattingStatus.TYPO_MALICIOUS_TABLE]: TYPO_MALICIOUS_TABLE_ID_DASH,
  [ETyposquattingStatus.TYPOSQUAT_DASHBOARD]: TYPOSQUAT_DASHBOARD_TABLE_ID_DASH,
  [ETyposquattingStatus.TYPOSQUAT_TABLE]: TYPOSQUAT_TABLE_ID_DASH,
};

interface IActiveRoutesProps {
  children: React.ReactElement;
}

export const ActiveRoutesContextProvider = (props: IActiveRoutesProps): any => {
  const { children } = props;
  const [activeRoute, setActiveRoute] = useState('');

  const getParentRouteViaFindingStatus = (status: string) => {
    if (!status) {
      setActiveRoute('');
      return;
    } else {
      setActiveRoute(ugcRoutesAsPerStatus[status]);
    }
  };

  return (
    <ActiveRoutesContext.Provider
      value={{
        activeRoute,
        getParentRouteViaFindingStatus,
      }}
    >
      {children}
    </ActiveRoutesContext.Provider>
  );
};

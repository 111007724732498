import React from 'react';
import { IItem } from './Reports.types';
import { useAppSelector } from '../../helpers/hooks';
import _ from 'lodash';

export default function useOnReportsModifyCols(reportColums: any[]) {
  const user = useAppSelector(state => state.dashboardReducer.user);

  if (!reportColums.find((columns: { id: string }) => columns.id === 'brandGroup')) {
    if (user?.orgInfo?.orgUnits && user?.orgInfo?.orgUnits.length > 1) {
      const allBrands = user?.brand_info?.brand?.subBrands?.map(
        (item: any) => item.brandDisplayName,
      );
      reportColums.push({
        header: 'Brands',
        accessor: 'organization_unit_id',
        id: 'organization_unit_id',
        filterDisabled: true,
        sortDisabled: true,
        render: (data: IItem) => {
          const brandsForOu = user?.orgInfo?.brands.filter(
            (item: { ouId: number }) => item.ouId === data.organization_unit_id,
          );
          const brandNames: any = brandsForOu?.map((brand: { name: string }) => brand.name);
          if (_.isEmpty(data) || (!data.organization_unit_id && !data.brand)) {
            return allBrands.join(', ');
          } else if (data?.brand) {
            return data.brand.brandDisplayName;
          }
          return <div>{brandNames?.join(', ')}</div>;
        },
      });
    }
  }

  return { modifiedColumns: reportColums };
}

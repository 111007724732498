import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import '../../Style/DetectionBanner.scss';
import { DashBoardDto, isAdminUser } from '../../../../constants';
import { AppState } from '../../../../helpers';
import SocApproveDetectionModal from './SocApproveDetectionModal';
import SocEditDetectionModal from './SocEditDetectionModal';

interface IFindingInsightBannerProps {
  selectedFinding: any;
  type: string;
  onEditModalSubmit: Function;
}
interface IPropsFromGlobalState {
  user: DashBoardDto;
}

const FindingInsightBanner = ({
  user,
  selectedFinding,
  type,
  onEditModalSubmit,
}: IFindingInsightBannerProps & IPropsFromGlobalState) => {
  const [editDetectionModalShown, setEditDetectionModalShown] = useState(false);
  const [approveDetectionModalShown, setApproveDetectionModalShown] = useState(false);

  if (!isAdminUser(user)) {
    return null;
  }

  const toggleEditDetectionModal = () => {
    setEditDetectionModalShown(!editDetectionModalShown);
  };
  const toggleApproveDetectionModal = () => {
    setApproveDetectionModalShown(!approveDetectionModalShown);
  };

  const onEditFindings = (data: any) => {
    toggleEditDetectionModal();
    onEditModalSubmit();
  };

  const onApproveScanFinding = (data: any) => {
    toggleApproveDetectionModal();
  };

  return (
    <div className='detection-action-banner'>
      <div className='detection-action-banner-inner'>
        <div className='name'>{user?.organization}</div>
        <div className='buttons'>
          <div className='edit-button-container'>
            <Button
              variant='outline-secondary'
              onClick={() => {
                setEditDetectionModalShown(true);
              }}
            >
              Edit Detection
            </Button>
          </div>
          <Button variant='primary' onClick={toggleApproveDetectionModal}>
            Approve Bolster Detection
          </Button>
        </div>
      </div>
      {editDetectionModalShown && (
        <SocEditDetectionModal
          data={selectedFinding}
          show={editDetectionModalShown}
          onCancel={toggleEditDetectionModal}
          onSubmit={onEditFindings}
          type={type}
        />
      )}
      {approveDetectionModalShown && (
        <SocApproveDetectionModal
          data={[selectedFinding]}
          show={approveDetectionModalShown}
          onCancel={toggleApproveDetectionModal}
          onSubmit={onApproveScanFinding}
          type={type}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return { user };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FindingInsightBanner);

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { setShouldRefreshClientSideTable } from '../../../../reducers/table.reducer';

const useOnAgGridClientModeTableRefresh = (agRef: any) => {
  const dispatch = useAppDispatch();
  const shouldRefreshClientSideTable = useAppSelector(
    state => state.tableReducer.shouldRefreshClientSideTable,
  );

  useEffect(() => {
    if (agRef.current && agRef.current.api && shouldRefreshClientSideTable) {
      agRef.current.api.redrawRows();
    }
  }, [agRef, shouldRefreshClientSideTable]);

  useEffect(() => {
    return () => {
      dispatch(setShouldRefreshClientSideTable(false));
    };
  }, [dispatch]);
};

export default useOnAgGridClientModeTableRefresh;

import { alertConstants, AlertActionsTypes } from '../constants';
import moment from 'moment';

const success = (message: string, domElement?: any): AlertActionsTypes => ({
  type: alertConstants.SUCCESS,
  id: moment().valueOf(),
  message,
  domElement,
});

const error = (message: string): AlertActionsTypes => ({
  type: alertConstants.ERROR,
  id: moment().valueOf(),
  message,
});

const clear = (alertId: number) => ({
  type: alertConstants.CLEAR,
  alertId,
});

const custom = (domElement: any): AlertActionsTypes => ({
  type: alertConstants.CUSTOM,
  message: '',
  domElement,
});

const requiredAction = (message: string): AlertActionsTypes => ({
  type: alertConstants.REQUIRED_ACTION,
  message,
});

const releaseNoteAction = (message: string): AlertActionsTypes => ({
  type: alertConstants.RELEASE_NOTE_ACTION,
  message,
});

const information = (message: string): AlertActionsTypes => ({
  type: alertConstants.INFORMATION,
  id: moment().valueOf(),
  message,
});

export const alertActions = {
  requiredAction,
  releaseNoteAction,
  custom,
  success,
  error,
  clear,
  information,
};

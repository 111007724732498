import * as React from 'react';
import { Card } from 'react-bootstrap';
import { ScanComponent } from '../Scan/ScanComponent';
import './typosquatv2.scss';
import { StepProgressBar } from '../Common/StepProgressv2';
import { useState, useEffect } from 'react';
import { stepVal } from './TyposquatDashboard';
import DashboardService from '../../services/dashboard.service';
import ProgressBar from '../Common/LinearProgressBarv2';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import * as _ from 'lodash';
import { parseDomain, ParseResultType } from 'parse-domain';
import { INLIMIT_WARNING_TEXT, OFFLIMIT_WARNING_TEXT } from './constant';
import { LimitStatusValue } from './TyposquatDashboard';

const dashboardService = new DashboardService();

interface IErrorMsg {
  status: boolean;
  message: string;
}

export interface ITypoSquatPopUpProps {
  step: number;
  changeStep: (action: stepVal, value?: number) => void;
  setPopUpState: (newState: boolean | null) => any;
  searchText: string;
  setSearchText: (value: string) => any;
  fetchTypoSquattingDashboardAnalytics: (query?: string, goToTyposquatTable?: boolean) => any;
  totalDomains: number;
  registerdDomains: number;
  unregisteredDomains: number;
  progress: number;
  setDomainName: (newDomainName: string) => any;
  domainName: string;
  isLoading: boolean;
  editDomain: boolean;
  fetchAnalyticsPolling: boolean;
  setFetchAnalyticsPolling: any;
  setIsLoading: (status: boolean) => any;
  warningTextType: LimitStatusValue;
  scanLimit: number;
  limitStatus: string;
}

const generateCurrentProcessingStepAndMessage = (step: number) => {
  switch (step) {
    case 0:
    case 1:
    case 2:
      return { stp: 0, msg: `Generating domain variants, expect 2-3 mins...` };
    case 3:
      return {
        stp: 1,
        msg: `Identifying registered domains variants, expect 2-3 mins...`,
      };
    case 4:
      return {
        stp: 2,
        msg: `Scanning unregistered and registered URLs in progress, expect 2-3 mins...`,
      };
    case 5:
      return {
        stp: 2,
        msg: `Scanning unregistered and registered URLs in progress, expect 2-3 mins...`,
      };
    default:
      return { stp: 3, msg: `` };
  }
};

export function TypoSquatPopUp({
  step,
  changeStep,
  setPopUpState,
  searchText,
  setSearchText,
  fetchTypoSquattingDashboardAnalytics,
  progress,
  setDomainName,
  domainName,
  isLoading,
  setIsLoading,
  editDomain,
  fetchAnalyticsPolling,
  setFetchAnalyticsPolling,
  warningTextType,
  scanLimit,
  limitStatus,
}: ITypoSquatPopUpProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [textMessage, setTextMessage] = useState<string>('');
  const [errMsg, setErrMsg] = useState<IErrorMsg>({ status: false, message: '' });

  const setError = (newState: IErrorMsg) => {
    setErrMsg(newState);
  };

  const renderWarningText = () => {
    return warningTextType === 'offlimit'
      ? OFFLIMIT_WARNING_TEXT(scanLimit)
      : INLIMIT_WARNING_TEXT(scanLimit);
  };

  const disableGenerateButton = () => warningTextType === 'offlimit';

  const { setTriggerContactUsModal } = React.useContext(CheckPhishContactUsContext);

  const onDomainChange = (originalDomain: string) => {
    let originalDomainName = originalDomain;
    if (_.isEmpty(originalDomainName)) {
      setError({
        status: true,
        message: 'Please enter a valid domain',
      });
    } else {
      try {
        const url = new URL(originalDomain);
        originalDomainName = url.hostname;
      } catch (error) {
        /* empty */
      }

      try {
        const parseResult = parseDomain(originalDomainName);
        if (parseResult.type === ParseResultType.Listed) {
          const { domain } = parseResult;
          // check if domain part is too long
          if (domain && domain.length > 15) {
            setError({
              status: true,
              message: 'The max length of domain we can help monitor is 15 characters',
            });
          } else {
            setError({
              status: false,
              message: '',
            });
          }
        }
      } catch (error) {
        // invalid domain name
        setError({
          status: true,
          message: 'Please enter a valid domain',
        });
      }
    }
  };

  useEffect(() => {
    const { stp, msg } = generateCurrentProcessingStepAndMessage(step);
    setCurrentStep(stp);
    setTextMessage(msg);
  }, [step]);

  const onTyposquatSubmit = () => {
    if (!errMsg.status) {
      if (!searchText || searchText === '') {
        setError({
          status: true,
          message: 'Domain cannot be empty',
        });
        return;
      }
      setIsLoading(true);
      setDomainName(searchText);
      setFetchAnalyticsPolling(true);
      changeStep('custom', 0); //set status to changing domian.
      dashboardService
        .generateReport(searchText)
        .then(() => fetchTypoSquattingDashboardAnalytics(undefined, true))
        .catch((err: any) => {
          console.error(err);
          changeStep('custom', 0);
          setError({
            status: true,
            message: _.isArray(err) ? err[0] : err,
          });
          setIsLoading(false);
          fetchTypoSquattingDashboardAnalytics();
        });
    }
  };

  return (
    <Card className={'typo-popup'}>
      {editDomain && !fetchAnalyticsPolling && (
        <button
          className='close-btn'
          onClick={() => {
            setPopUpState(false);
          }}
        >
          X
        </button>
      )}
      {scanLimit !== -1 && (
        <div className='warning-wrap'>
          <p>{renderWarningText()}</p>
          <button className='contact-us' onClick={() => setTriggerContactUsModal(true)}>
            Contact Us
          </button>
        </div>
      )}
      <h5 className='header'>Add a domain to monitor</h5>
      <div className='search-bar-wrap'>
        <ScanComponent
          className={'scan-widget'}
          isLoading={isLoading}
          onlyScanBar={true}
          onChangeSearchText={setSearchText}
          isTypoSquat={true}
          size={'small'}
          value={searchText}
          onTyposquatSubmit={onTyposquatSubmit}
          onDomainChange={onDomainChange}
          disableGenerate={disableGenerateButton()}
          showPretext={false}
          preText=''
          displayTooltip={false}
          toggleScanBarBanner={() => {}}
          limitStatus={limitStatus}
        />
        {errMsg.status ? <span className='err-text'>{errMsg.message}</span> : <></>}
      </div>
      {isLoading && (
        <div className='progress-wrap'>
          <StepProgressBar activeStep={currentStep} />
          <ProgressBar
            progress={`${progress}`}
            width={80}
            height={5}
            displayText={textMessage}
            textPosition={'bottom'}
          />
        </div>
      )}
    </Card>
  );
}

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Risk.scss';
import TrendingUpIcon from '../../assets/icons/TrendingUp.svg';
import TrendingDownIcon from '../../assets/icons/TrendingDown.svg';
import { Placement } from 'react-bootstrap/Overlay';

interface IComponentProps {
  risk: number;
  trending: 'up' | 'down' | undefined;
  tooltipPlacement?: Placement;
}

export const Risk = ({ risk, trending, tooltipPlacement = 'top' }: IComponentProps): any => {
  let riskClassName = 'risk-value ';
  let tooltipRisk = 'Very high';
  switch (risk) {
    case 1:
      riskClassName += 'severity-ver-low';
      tooltipRisk = 'Very Low';
      break;
    case 2:
      riskClassName += 'severity-low';
      tooltipRisk = 'Low';
      break;
    case 3:
      riskClassName += 'severity-moderate';
      tooltipRisk = 'Moderate';
      break;
    case 4:
      riskClassName += 'severity-high';
      tooltipRisk = 'High';
      break;
    default:
      riskClassName += 'severity-critical';
      break;
  }

  let trendingIcon = undefined;
  let tooltipTrending = undefined;
  if (trending === 'up') {
    trendingIcon = TrendingUpIcon;
    tooltipTrending = 'More risky';
  } else if (trending === 'down') {
    trendingIcon = TrendingDownIcon;
    tooltipTrending = 'Less risky';
  }

  return (
    <div className={'risk-component'}>
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={
          <Tooltip id={'tooltip-' + Math.random()} className={'risk-tooltip'}>
            <div>
              Risk: <span className={'high-light'}>{tooltipRisk}</span>
            </div>
            {tooltipTrending && (
              <div>
                Trending: <span className={'high-light'}>{tooltipTrending}</span>
              </div>
            )}
          </Tooltip>
        }
      >
        <div className={'table-source-url-column d-flex align-items-center'}>
          <div className={riskClassName}>{risk}</div>
          {trendingIcon && <img src={trendingIcon} alt={'trending ' + trending} />}
        </div>
      </OverlayTrigger>
    </div>
  );
};

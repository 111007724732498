import _ from 'lodash';
import {
  DashBoardDto,
  IContentPlatform,
  IContentOrigin,
  IContentCategory,
  AlertActionsTypes,
  IFindingsTableUrls,
} from '../../../constants';
import { ITableApiColumn } from '../../Common/Table/table.api';
import { IFilter } from '../../Common/Table/constant';
import { IDropdownOption } from '../../Common/Dropdown';
import { BooleanLiteral } from 'typescript';
import { FINDINGS_TABLE_IDS } from '../../MonitorAndTakedown/constants';
interface ILabelValue {
  value: string;
  label: string;
}
export interface ISearchTermsProp {
  user: DashBoardDto;
  ugcPlatforms: IContentPlatform[];
  ugcCategories: IContentCategory[];
  type: string;
  tableName: string;
  darkWebEntityOptions: IDropdownOption[];
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

export interface IAddSearchTermProps {
  ugcPlatforms: IContentPlatform[];
  ugcCategories: IContentCategory[];
  onSubmit: (data: FormData, searchTerm: string) => Promise<any>;
  type: string;
  darkWebEntityOptions: IDropdownOption[];
}
export interface ISearchesTableProps {
  ugcPlatforms: IContentPlatform[];
  ugcCategories: IContentCategory[];
  newSearchTerm: string;
  tableName: string;
  type: string;
  darkWebEntityOptions: IDropdownOption[];
  user?: any;
}
export interface ISearchesTableItemProps {
  id: number;
  searchTerm: string;
  platforms: string[];
  platformNames: string;
  category: string;
  author: string;
  createdTs: string;
  times: number;
  historyCreatedTs: string;
  lastSearchText: string;
  isNewAdded: boolean;
  isDisabled: boolean;
  isDisabledTextValue: string;
  isLogoDetection: string;
  logos: any[];
  uploadCount: number;
  brandLogoDetected?: string;
  entity?: string;
}

export interface ISearchTermOptions extends IDropdownOption {
  id: number;
  platforms: any;
}

export enum EFindingStatus {
  PENDING = 'PENDING',
  UNDER_REVIEW = 'UNDER_REVIEW',
  PENDING_OR_UNDER_REVIEW = 'PENDING|UNDER_REVIEW',
  LIVE = 'LIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  IN_PROGRESS_OR_PAUSED = 'IN_PROGRESS|PAUSED',
  DOWN = 'DOWN',
  SAFELIST = 'SAFELIST',
  MARKED_AS_SCAM = 'MARKED_AS_SCAM',
}

export enum EAppStoreFindingStatus {
  LIVE = 'LIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  DOWN = 'DOWN',
  SAFELIST = 'SAFELIST',
}

export enum EFindingExternalLinks {
  NONE = 'None',
  CLEAN = 'Clean',
  MALICIOUS = 'Malicious',
}

const DEFAULT_MAP_STATUS = {
  [EFindingStatus.LIVE]: 'Live',
  [EFindingStatus.IN_PROGRESS]: 'Takedown in Progress',
  [EFindingStatus.PAUSED]: 'Takedown Paused',
  [EFindingStatus.DOWN]: 'Taken Down',
  [EFindingStatus.SAFELIST]: 'Ignored',
  [EFindingStatus.MARKED_AS_SCAM]: 'Marked As Scam',
};

export const DISPLAY_STATUS_MAP = {
  [EFindingStatus.PENDING]: 'Pending Scan',
  [EFindingStatus.UNDER_REVIEW]: 'Ready to Review',
  ...DEFAULT_MAP_STATUS,
};

export const DISPLAY_STATUS_APP_STORE_MAP = {
  ...DEFAULT_MAP_STATUS,
};

export const FindingStatusDropdownOptions = (type: string) => {
  return _.chain(type !== ugcType.App_Store ? EFindingStatus : EAppStoreFindingStatus)
    .map((value, key): IDropdownOption => {
      return {
        label:
          type !== ugcType.App_Store ? DISPLAY_STATUS_MAP[key] : DISPLAY_STATUS_APP_STORE_MAP[key],
        value,
      };
    })
    .filter(status => {
      return status.value.indexOf('|') === -1;
    })
    .value();
};

export interface IFindingsTableProps {
  id: FINDINGS_TABLE_IDS;
  title: string;
  status: EFindingStatus;
  columns: ITableApiColumn[];
  enableCheckbox?: boolean;
  user: DashBoardDto;
  ugcPlatforms: IContentPlatform[];
  ugcOrigins: IContentOrigin[];
  ugcCategories: IContentCategory[];
  defaultColumnIds?: any;
  type: string;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  tableName: string;
  enableSocInterface?: BooleanLiteral;
  usersList?: any;
  findingsTableUrls: IFindingsTableUrls;
  setFindingsTableUrls: (
    tableId: FINDINGS_TABLE_IDS,
    selected: IFindingsTableItemProps[],
    type: string,
  ) => void;
}
export interface IFindingsTableItemProps {
  scanSource: string;
  sha256: string;
  url: string;
  brandId: string;
  platformId: number;
  platformLabel: string;
  platform: ILabelValue & {
    logo_url: string;
  };
  origin: ILabelValue;
  originId: number;
  category: ILabelValue;
  searchTermLabels: string;
  uploads: string[];
  metadata: ILabelValue[];
  source: string;
  createdTs: number;
  status: string;
  takedownTs: number;
  notes: [];
  search_term_labels: string;
}
export interface IFindingInsightDataProps {
  url: string;
  sha256: string;
  status: string;
  source: string;
  searchTermLabels: string;
  categoryLabels: string;
  createdTs: string;
  screenshot: string;
  isLogoDetection: boolean;
  uploads: string[];
  platformLabel: string;
  originLabel: string;
  accountName: string;
  accountFollowers: string;
  accountVerified: string;
  postedTs: string;
  takedownBy: string;
  takedownTs: string;
  takedownComment: string;
  takedownUploads: string[];
  logoDetection: any;
  metadata: any;
  tags?: any;
  url_sha256?: string;
  ip: string;
  host: string;
  primaryDomain: string;
}
export interface IFindingInsightSectionProps {
  data: IFindingInsightDataProps;
  brandMnemonic?: string;
  type?: string;
  user?: any;
}
export interface IScanCountInfo {
  brandScanCount: string;
  bolsterScanCount: string;
  scanSource: string;
}

export interface ISocialDashboardHeader {
  startDate: moment.Moment;
  endDate: moment.Moment;
  onDateRangeChange: (
    startDate: moment.Moment,
    endDate: moment.Moment,
    lastXDay: number,
    isCustomCalendarRange: boolean,
  ) => void;
  user: DashBoardDto;
  type: string;
}

export interface IThreatDetections {
  type: string;
  label: string;
  id: number;
  count: number;
}

export interface IUgcThreatHeaders {
  title: string;
  ugcPlatforms: IContentPlatform[];
  updateFilters: (filters: IFilter) => any;
  type: string;
}
export interface IUgcThreatContent {
  filters: IFilter[];
  ugcCategories: IContentCategory[];
  threatDetections: IThreatDetections[];
  type: string;
  query: any;
}

export interface IFeatureRequested {
  id: number;
  userId: number;
  brandId: number;
  productFeatureId: string;
  createdTs: string;
  updatedTs: string;
  featureRequested: boolean;
  productFeature: IProductFeature;
}

export interface IProductFeature {
  id: number;
  code: string;
  productId: number;
  featureId: number;
  label: string;
}

export enum ugcType {
  Social = 'social',
  Marketplace = 'marketplace',
  App_Store = 'app-store',
  Dark_Web = 'dark-web',
}

export const ugcTypeLabel = {
  [ugcType.Social]: 'Social',
  [ugcType.Marketplace]: 'Marketplace',
  [ugcType.App_Store]: 'App Store',
  [ugcType.Dark_Web]: 'Dark Web',
};

export interface IMediaIntroProps {
  featureName: string;
  featureDescription: string;
  staticLightImage: any;
  staticDarkImage: any;
  featureCode: string;
  teaserVideo?: React.ReactNode;
}

export enum ERequestTakedown {
  Bulk = 'bulk',
  Single = 'single',
}

import React, { useCallback } from 'react';
import { DropdownMultiSelect } from '../Common/DropdownMultiSelect';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { appConstants, isBrandReadOnlyUser } from '../../constants';
import { Dropdown } from '../Common/Dropdown';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { DropdownDateRangePicker } from '../Common/DropdownDateRangePicker';
import useOnReportsModuleOptions from './useOnReportsModuleOptions';
import { useOnReportsGeneration } from './useOnReportsGeneration';
import { alertActions } from '../../actions';
import { generateBrandReport } from './report-requests';
import { shouldSetNewlyGeneratedReport, toggleIsGenerating } from '../../reducers/reports.reducer';
import { BrandReportRange } from '../../types/brand-reports-types';
import { setShouldTriggerAgGrigRefresh } from '../../reducers/table.reducer';

export default function ReportsGenerationControl() {
  const isGenerating = useAppSelector(state => state.reportsReducer.isGenerating);
  const user = useAppSelector(state => state.dashboardReducer.user);
  const dispatch = useAppDispatch();

  const {
    setSelectedOuId,
    startDate,
    endDate,
    onDateRangeChange,
    resetGeneration,
    module,
    setModule,
    selectedOuId,
    prevStartDate,
    prevEndDate,
  } = useOnReportsGeneration();

  const { moduleTypesOptions, selectedBrandGroupOptions } = useOnReportsModuleOptions();

  const generateReport = useCallback(() => {
    const ouId = user?.ouId ?? selectedOuId?.value === '' ? undefined : Number(selectedOuId.value);
    const promises: any = [];
    if (module.length > 0) {
      dispatch(toggleIsGenerating(true));
      module.forEach(item => {
        promises.push(
          generateBrandReport(
            item.value,
            BrandReportRange.MONTHLY,
            true,
            startDate?.format('YYYY-MM-DD'),
            endDate?.format('YYYY-MM-DD'),
            prevStartDate?.format('YYYY-MM-DD'),
            prevEndDate?.format('YYYY-MM-DD'),
            ouId,
          ),
        );
      });

      Promise.all(promises)
        .then(res => {
          const newAddedIds = res.map(item => item.id);
          dispatch(shouldSetNewlyGeneratedReport(newAddedIds));
          dispatch(setShouldTriggerAgGrigRefresh(true));
          resetGeneration();
          return res;
        })
        .catch(() => {
          dispatch(
            alertActions.error('Report failed to generate. Please contact support@bolster.ai.'),
          );
        })
        .finally(() => {
          dispatch(toggleIsGenerating(false));
          dispatch(setShouldTriggerAgGrigRefresh(false));
        });
    } else {
      dispatch(alertActions.error('Please Input A Module'));
    }
  }, [
    module,
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
    selectedOuId,
    user,
    dispatch,
    resetGeneration,
  ]);

  const isReadOnlyUser = isBrandReadOnlyUser(user);
  if (isReadOnlyUser) return null;

  return (
    <div className='drop-down-container'>
      <div className='drop-down-fields-wrapper'>
        <div className='form-label'>Select Report Module</div>
        <div className={'form-block'}>
          <DropdownMultiSelect
            options={moduleTypesOptions}
            onSubmit={selections => setModule(selections)}
            hideFooter
            placeholder='Module'
            initialSelections={module}
            updateOnChange
            removable
          />
        </div>
      </div>
      {user && user.orgInfo && user.orgInfo.orgUnits && user.orgInfo.orgUnits.length > 1 && (
        <div className='drop-down-fields-wrapper drop-down-padding'>
          <div className='form-label form-label-padding-bottom' style={{ paddingBottom: '10px' }}>
            Select Brand Group
          </div>
          <Dropdown
            boxStyle={true}
            variant='primary'
            key={'brandGroup'}
            options={selectedBrandGroupOptions}
            defaultSelection={selectedBrandGroupOptions[0]}
            onChange={selection => {
              setSelectedOuId(selection);
            }}
          />
        </div>
      )}
      <div className='drop-down-fields-wrapper'>
        <div className='form-label'> Date Range </div>
        <div className={'form-block'}>
          <DropdownDateRangePicker
            key={'DropdownDateRangePicker'}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateRangeChange}
            type={appConstants.CONTENT_TYPE.REPORT}
          />
        </div>
      </div>
      <div className='reset-button' onClick={resetGeneration}>
        {' '}
        Reset{' '}
      </div>
      <div className='generate-button'>
        {!isGenerating ? (
          <Button variant='primary' onClick={generateReport}>
            {' '}
            Generate Report{' '}
          </Button>
        ) : (
          <Button variant='primary'>
            <LoadingWrapper isLoading />
          </Button>
        )}
      </div>
    </div>
  );
}

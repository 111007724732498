import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { MANAGED_BY_ORGANIZATION } from './constants';
import {
  DATE_ADDED,
  DOMAIN_COLUMN,
  IP_COLUMN,
  BRAND_NAME_COLUMN,
  COUNTRY_COL,
} from './AssetsColDefination/ManagedByOrgCols';
import { REGISTRAR_COLUMN } from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import ManagedByCustomer from './ManagedByCustomer';
import { useAppSelector } from '../../helpers/hooks';
import { AppState } from '../../helpers';

const TABLE_ID = MANAGED_BY_ORGANIZATION;
const TABLE_COLUMNS = [
  DOMAIN_COLUMN,
  IP_COLUMN,
  DATE_ADDED,
  BRAND_NAME_COLUMN,
  REGISTRAR_COLUMN,
  COUNTRY_COL,
];
const ManagedByOrganization = (): JSX.Element => {
  window.document.title = 'Managed By Organization | Assets';

  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);
  const exportCSVTitle = `Bolster Assets Managed By ${user?.organization} ${moment().format(
    'YYYYMMDDhhmmss',
  )}`;
  return (
    <ManagedByCustomer
      tableTitle={`Managed By ${user?.organization}`}
      tableId={TABLE_ID}
      tableColumns={TABLE_COLUMNS}
      exportCSVTitle={exportCSVTitle}
    />
  );
};

export default ManagedByOrganization;

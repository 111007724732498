import { isMobile } from '../constants';
import { history } from '../helpers';
import fetch from './api.service';

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const backendHost = isLocalhost
  ? `http://localhost:${process.env.REACT_APP_ZION_BACK_PORT || 3100}`
  : '';

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA',
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

export function setApiUrl() {
  let apiUrl;
  if (process.env.NODE_ENV === 'production' && navigator.userAgent === 'ReactSnap') {
    apiUrl = '/';
  } else {
    apiUrl = window.location.origin;
  }
  return apiUrl;
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.',
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

export function setPostRequestOptions(body: string): RequestInit {
  return {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body,
  };
}

export function handleResponse<TData = any>(response: Response): Promise<TData> {
  const { REACT_APP_BOLSTER_APP_TYPE } = process.env;
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      // Handle error response here
      switch (response.status) {
        case 440:
          history.push('/sign-in?error=Session timed out.');
          break;

        case 403:
          history.push('/terms-and-conditions');
          break;

        default:
          break;
      }
      const error = (data && data.message) || (data && data.error) || response.statusText;
      // throw Error(response.statusText);
      return Promise.reject(error);
    }
    // Handle successful case here
    return data;
  });
}

export function handleResponseAddUser(response: any) {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    console.log(data);
    if (!response.ok) {
      // Handle error response here
      // {"message": "xxxxx", "error": "{"User" : "xxxxxxxxx"}"}
      let error = data && data.error;
      if (typeof error === 'object') {
        error = { ...error, ...data };
      }
      // throw Error(response.statusText);
      return Promise.reject(error);
    }
    // Handle successful case here
    return data;
  });
}

export function downloadApi(url: string, options: {}, fileType = 'pdf', savedFileName = '') {
  return fetch(url, options)
    .then(response => {
      if (response.status >= 400) {
        throw new Error(response.statusText);
      }
      if (savedFileName === '') {
        const value = response.headers.get('content-disposition');
        if (value) {
          savedFileName = value.split('filename=')[1];
        } else {
          savedFileName = 'download';
        }
      }
      return response.blob();
    })
    .then(blob => {
      if (fileType === 'csv') {
        fileType = 'text/csv;charset=utf-8;';
      } else {
        fileType = 'application/' + fileType;
      }
      const newBlob = new Blob([blob], { type: fileType });
      const url = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = url;
      if (!isMobile()) {
        link.setAttribute('download', savedFileName);
      }
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
      return { result: true };
    })
    .catch(err => ({ result: false, err }));
}

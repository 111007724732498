import React from 'react';
import { Button } from 'react-bootstrap';
import { AlertIcon } from '../../assets/SVGIcons';
import ModalOverlay from '../Common/ModalOverlay';
import { DomainMap } from './soc-takedown-request-page.component';

export interface SocTakedownErrorsModalProps {
  show: boolean;
  domainMap: DomainMap;
  onClose: (selectedDomain?: string) => void;
}

export function SocTakedownErrorsModal({ show, domainMap, onClose }: SocTakedownErrorsModalProps) {
  function errorSection() {
    const els: JSX.Element[] = [];

    for (const [domain, domainInfo] of Object.entries(domainMap)) {
      if (!domainInfo.isValid) {
        els.push(
          <div className='mb-4' key={`${domain}_domain_errors`} onClick={() => onClose(domain)}>
            <Button variant='link'>
              <span className='h6'>{domain}</span>
            </Button>
            <div className='ml-4'>
              {domainInfo.errors.to.length > 0 && (
                <>
                  <div>To:</div>
                  <ul>
                    {domainInfo.errors.to.map(e => (
                      <li key={e} className='text-danger'>
                        {e}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {domainInfo.errors.subject.length > 0 && (
                <>
                  <div>Subject:</div>
                  <ul>
                    {domainInfo.errors.subject.map(e => (
                      <li key={e} className='text-danger'>
                        {e}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {domainInfo.errors.body.length > 0 && (
                <>
                  <div>Message body:</div>
                  <ul>
                    {domainInfo.errors.body.map(e => (
                      <li key={e} className='text-danger'>
                        {e}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {domainInfo.errors.sentTo.length > 0 && (
                <>
                  <div>Takedown request is sent to:</div>
                  <ul>
                    {domainInfo.errors.sentTo.map(e => (
                      <li key={e} className='text-danger'>
                        {e}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {domainInfo.errors.attachments.length > 0 && (
                <>
                  <div>Attachments:</div>
                  <ul>
                    {domainInfo.errors.attachments.map(e => (
                      <li key={e} className='text-danger'>
                        {e}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>,
        );
      }
    }

    return els;
  }

  return (
    <ModalOverlay
      show={show}
      title={'Please fix the following errors'}
      onCancel={() => onClose()}
      onSubmit={() => onClose()}
      showButtons={false}
      size={'lg'}
    >
      {errorSection()}
    </ModalOverlay>
  );
}

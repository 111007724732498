import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import RiskLevel, { RISK_VALUE_TO_LABEL } from './RiskLevel';
import { DARK_WEB_PATHNAME, DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME } from '../../DarkWebConstants';
import '../../Style/RowCard.scss';
import { LabelAndValue } from '../../../Common/LabelAndValue';
import { history } from '../../../../helpers';

export const LAST_10_DAYS = 10;
export default function RowCard({ data }: any) {
  const { category, risk_level, title, threat_actor, platform_published_ts, content_sha_256 } =
    data;

  const onHandleCategory = () => {
    history.push(`${DARK_WEB_PATHNAME}/insights/${content_sha_256}#dark-web-insights-title`);
  };
  return (
    <div className={`row-card-wrapper ${risk_level.toLowerCase()}-risk`}>
      <div className={'label-value-wrapper-title'}>
        <div className={'card-category-name'} onClick={onHandleCategory}>
          {title}
        </div>
        {moment().subtract(LAST_10_DAYS, 'days').isBefore(moment(platform_published_ts)) ? (
          <div className={'new-findings-text'}>New</div>
        ) : null}
      </div>
      <div className='label-value-wrapper-risk'>
        <LabelAndValue
          label={'Risk'}
          direction={'column'}
          longTextLineNumberLimit={1}
          noMarginBottom
          noColon
          renderDom={<RiskLevel risk={risk_level} />}
        />
      </div>
      <div className='label-value-wrapper-risk'>
        <LabelAndValue
          label={'Category'}
          value={category}
          direction={'column'}
          longTextLineNumberLimit={1}
          noMarginBottom
          noColon
        />
      </div>

      <div className='label-value-wrapper-threat'>
        <LabelAndValue
          label={'Threat Actor'}
          direction={'column'}
          longTextLineNumberLimit={1}
          noMarginBottom
          noColon
          renderDom={
            <div className={'table-source-url-column d-flex align-items-center'}>
              <Link to={`${DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME}/insights/${threat_actor}`}>
                {threat_actor}
              </Link>
            </div>
          }
        />
      </div>
      <div className='label-value-wrapper-risk'>
        <LabelAndValue
          label={'Discovered'}
          value={moment(platform_published_ts).format('MMM-DD-YYYY')}
          direction={'column'}
          longTextLineNumberLimit={1}
          noMarginBottom
          noColon
        />
      </div>
    </div>
  );
}

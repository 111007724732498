import React, { useState } from 'react';

export function useOnUgcModalAction() {
  const [requestTakedownModalShown, setRequestTakedownModalShown] = useState(false);
  const [resumeTakedownModalShown, setResumeTakedownModalShown] = useState(false);
  const [markSafelistModalShown, setMarkSafelistModalShown] = useState(false);

  const toggleRequestTakedownModal = () => {
    setRequestTakedownModalShown(!requestTakedownModalShown);
  };
  const toggleResumeTakedownModal = () => {
    setResumeTakedownModalShown(!resumeTakedownModalShown);
  };
  const toggleMarkSafelistModal = () => {
    setMarkSafelistModalShown(!markSafelistModalShown);
  };
  return {
    toggleMarkSafelistModal,
    toggleRequestTakedownModal,
    toggleResumeTakedownModal,
    requestTakedownModalShown,
    resumeTakedownModalShown,
    markSafelistModalShown,
  };
}

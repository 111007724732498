import React, { useCallback, useRef, useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import moment, { isMoment } from 'moment';
import { IFilter, TExportTable } from './constant';
import { IDropdownOption } from '../../Common/Dropdown';
import { Table } from './table';
import { TableContext } from './table.context';
import { getLocalStorageValue, setLocalStorageValue } from '../../../constants';
import { ITags } from '../Tags/Tags';
import { EFindingStatus } from '../../Ugc/Types/ugc.types';
import { EDarkWebFindingStatus } from '../../DarkWeb/Components/Types/DarkWeb.types';
import { DarkWebTableApiV2Params } from './ag-table/ag-utils';
import { useAppSelector } from '../../../helpers/hooks';

export interface ITableApiColumn {
  id: string;
  accessor: string;
  header?: string;
  fieldForExport?: string;
  hiddenInExport?: boolean;
  headerTooltip?: string;
  columnClassName?: string;
  isLongText?: boolean;
  type?: 'options' | 'optionsString' | 'date' | 'ipv4' | 'number' | 'optionsMultiSelect';
  isDefaultSort?: boolean;
  defaultSortDirection?: string;
  sortingFn?: any;
  sortDisabled?: boolean;
  filterDisabled?: boolean;
  filterOptions?: IDropdownOption[];
  optionsOrdersPersist?: boolean;
  hidden?: boolean;
  validator?: (value: any) => any;
  render?: (data: any) => any;
  hiddenInDragDrop?: boolean;
  hasCheckBoxOption?: boolean;
  dateRangeFilterForPlaybook?: string;
  fieldForPlaybook?: string;
  value?: string;
  label?: string;
  editable?: boolean;
  notUpdatable?: boolean;
  agFilterType?:
    | 'agTextColumnFilter'
    | 'agNumberColumnFilter'
    | 'agDateColumnFilter'
    | 'agSetColumnFilter'
    | React.ReactNode
    | boolean;
  comparator?: (valueA: any, valueB: any) => number;
  disableForSelection?: boolean;
  valueGetter?: (data: any) => any;
}

export interface IComponentProps<TData = any> {
  id: string;
  title: string;
  titleComp?: any;
  columns: ITableApiColumn[];
  defaultColumnIds?: string[];
  tileComp?: any;
  tableIndex: string;
  disableDatePicker?: boolean;
  fetchApi: any;
  apiTs?: number;
  exportOptions?: IDropdownOption[];
  exportApi?: any;
  tileTopThreeProps?: string[];
  enableCheckbox?: boolean;
  indexBy?: string;
  onItemCheck?: (checkbox: boolean, data: TData, allSelectedItems: TData[]) => void;
  customTools?: any;
  disablePagination?: boolean;
  customFilterSystem?: any;
  customFilterSystemProps?: any;
  refreshOnColChange?: boolean;
  refreshOnTag?: boolean;
  type?: string;
  user?: any;
  tagsAddedPerSha?: any;
  titleTooltip?: string;
  location?: any;
  selectedItems?: TData[];
  providedTags?: ITags[];
  handleDisplayLayout?: (layout: string) => void;
  isOldTable?: boolean;
  ugcStatus?: EFindingStatus;
  darkwebStatus?: EDarkWebFindingStatus;
  brandPropertyId?: number;
}

const defaultDayRange = 90;

const TableApi = (props: IComponentProps) => {
  const { id, fetchApi, exportApi } = props;
  const [apiTs, setApiTs] = useState<any>(0);
  const tableContext = useContext(TableContext);
  useEffect(() => {
    setApiTs(props.apiTs);
  }, [props.apiTs]);

  const columns = tableContext.columns || props.columns;
  const { displayedColumnsIDs, onTableSaveColumns } = tableContext;
  const isWebTablesGroupView = useAppSelector(state => state.tableReducer.isWebTablesGroupView);

  const savedSetting = getLocalStorageValue(['tableSetting', id]);
  const filters = _.get(savedSetting, ['filters']);
  const startDateStr = _.get(savedSetting, ['query', 'startDate']);
  let startDate = startDateStr ? moment(startDateStr) : moment().subtract(defaultDayRange, 'day');
  let endDate = moment(_.get(savedSetting, ['query', 'endDate']));
  const pageNumber = _.get(savedSetting, ['query', 'pageNumber'], 1);
  const lastXDay = useRef(_.get(savedSetting, 'lastXDay', 0));
  let sortBy = _.get(savedSetting, ['sortBy', 'sortBy']);
  let sortDirection = _.get(savedSetting, ['sortBy', 'sortDirection'], 'desc');
  if (_.isEmpty(sortBy)) {
    const sortByColumn = _.find(columns, column => column.isDefaultSort);
    sortBy = _.get(sortByColumn, 'accessor', 'update_ts');
    sortDirection = _.get(sortByColumn, 'defaultSortDirection', 'desc');
  }
  if (lastXDay.current > 0) {
    endDate = moment();
    startDate = moment().subtract(lastXDay.current, 'day');
  } else {
    lastXDay.current = defaultDayRange;
  }

  const fetchApiFn = useCallback(async (query: any, filters: IFilter[], sort: any) => {
    if (isMoment(query.startDate) && isMoment(query.endDate)) {
      query.startDate = query.startDate.format('YYYY-MM-DD');
      query.endDate = query.endDate.format('YYYY-MM-DD');
    }
    query.pageNumber > 0 && query.pageNumber--;
    return await fetchApi(query, filters, sort)
      .then((res: any) => {
        // Save the query setting in local storage
        query.pageNumber++;
        setLocalStorageValue(['tableSetting', id], {
          query,
          filters,
          sortBy: sort,
          lastXDay: lastXDay.current,
        });
        return res;
      })
      .catch((error: any) => {
        console.log(error, 'error');
        return { data: [], total: 0 };
      });
  }, []);

  const exportApiFn = useCallback(
    exportApi
      ? async (query: any, type: TExportTable, filters: IFilter[], sort: any) => {
          query.startDate = query.startDate.format('YYYY-MM-DD');
          query.endDate = query.endDate.format('YYYY-MM-DD');
          if (query.pageNumber > 0) query.pageNumber--;
          const exportColumns = _.chain(columns)
            .filter(column =>
              isWebTablesGroupView
                ? column.id === 'primary_domain'
                : !column.hidden &&
                  (displayedColumnsIDs.indexOf(column.id) !== -1 ||
                    displayedColumnsIDs.indexOf(column.accessor) !== -1) &&
                  !column.hiddenInExport,
            )
            .map(column => ({
              label: column.header,
              field: column.fieldForExport || column.accessor,
            }))
            .value();
          return await exportApi(query, filters, sort, type, exportColumns);
        }
      : _.noop,
    [displayedColumnsIDs, isWebTablesGroupView],
  );

  const onDateChange = useCallback(
    (startDate: moment.Moment, endDate: moment.Moment, lastXDays: number) => {
      lastXDay.current = lastXDays;
    },
    [],
  );

  const tileCompProperties = _.map(columns, column => ({ id: column.id }));

  const onSaveColumns = async (displayedColumnsIDs: any[]) => {
    if (onTableSaveColumns) await onTableSaveColumns(displayedColumnsIDs, () => {});
    if (props.refreshOnColChange) setApiTs(moment().valueOf());
  };

  return (
    <Table
      {...props}
      columns={columns}
      displayedColumnsIDs={displayedColumnsIDs}
      fetchApi={fetchApiFn}
      apiTs={apiTs}
      filterAppliedOnFetchApi
      exportTableApi={exportApiFn}
      initialFilters={filters}
      startDate={startDate}
      endDate={endDate}
      onDateChange={onDateChange}
      sortBy={sortBy}
      sortDirection={sortDirection}
      initialPageNumber={pageNumber}
      enableEditColumns={onTableSaveColumns !== _.noop}
      onSaveColumns={onSaveColumns}
      tileCompProperties={tileCompProperties}
      type={props.type}
      handleDisplayLayout={(layout: string) =>
        props.handleDisplayLayout && props.handleDisplayLayout(layout)
      }
      darkwebStatus={props.darkwebStatus}
    />
  );
};

export { TableApi };

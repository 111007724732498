import React, { useState } from 'react';
import PauseGreenIcon from '../../../../../assets/icons/PauseGreen.svg';
import { IFindingsTableItemProps } from '../../../Types/ugc.types';
import RequestTakedownModal from '../../Common/RequestTakedownModal';

interface IActionTakedownInProgressColRenderProps {
  data: IFindingsTableItemProps;
  onSuccess?: (message?: string, data?: any) => void;
  type: string;
}

const TakedownInProgressActionColRender = ({
  data,
  onSuccess,
  type,
}: IActionTakedownInProgressColRenderProps) => {
  const [resumeTakedownModalShown, setResumeTakedownModalShown] = useState<boolean>(false);
  const toggleResumeTakedownModal = () => {
    setResumeTakedownModalShown(!resumeTakedownModalShown);
  };
  const { status } = data;

  const onTakedownSuccess = () => {
    toggleResumeTakedownModal();
    onSuccess && onSuccess();
  };

  return (
    <div className={'action-col-render-component d-flex align-items-center'}>
      {status?.toLowerCase() === 'paused' ? (
        <>
          <img src={PauseGreenIcon} alt={'Pause'} />
          <div className='action-text-container'>
            <div className='action-text' onClick={toggleResumeTakedownModal}>
              Resume Takedown
            </div>
          </div>
        </>
      ) : (
        '--'
      )}
      {resumeTakedownModalShown && (
        <RequestTakedownModal
          data={[data]}
          show={resumeTakedownModalShown}
          isResumeTakedown
          onCloseModal={toggleResumeTakedownModal}
          onModalSubmit={onTakedownSuccess}
          title='Provide Additional Info To Resume Takedown'
          description='Please provide more information regarding '
          submitButtonLabel='Submit'
          type={type}
        />
      )}
    </div>
  );
};

export default TakedownInProgressActionColRender;

import React, { useState, useEffect } from 'react';
import { LoadingWrapper } from '../Common/LoadingWrapper';

export default function Logout({ logoutUser }: any) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    logoutUser();
  }, []);

  return <LoadingWrapper isLoading={isLoading}></LoadingWrapper>;
}

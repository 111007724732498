import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import ModalOverlay from '../Common/ModalOverlay';
import { Form } from 'react-bootstrap';
import { TextInput } from '../Common/TextInput';
import TextArea from '../Common/TextArea';
import UploadFiles from '../Common/UploadFiles';
import { alertActions } from '../../actions';
import { useAppDispatch } from '../../helpers/hooks';
import { updateSocActionDetails } from './web-requests';
import moment from 'moment';
import { getSha256 } from '../../constants';
import { setShouldRefreshTimeline } from '../../reducers/timeline.reducer';
import useWebSocDetailFormValues from '../MonitorAndTakedown/useWebSocDetailFormValues';

interface IEditSocModalProps {
  show: boolean;
  eventData: any;
  onCancel: () => void;
}
const DATE_FORMAT = 'MM-DD-YYYY h:mm a';

const EditSocDetailModal = ({ show, onCancel, eventData }: IEditSocModalProps) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const initialValues = {
    title: eventData.data.title,
    comments: eventData.data.comments,
    timeStamp: moment(eventData.data.timeStamp).format(DATE_FORMAT),
    screenShot: '',
  };

  const { onModalCancel, values, handleTextFieldChange, onHandleFilesSelect, isInvalid } =
    useWebSocDetailFormValues({ initialValues, onCancel });

  const urlSha256 = getSha256();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onModalSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const { title, comments, timeStamp, screenShot } = values.current;

      const localtime = moment.tz(timeStamp, DATE_FORMAT, userTimeZone);
      const utcTimestamp = localtime.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      const payload = new FormData();
      payload.append('eventId', eventData.id);
      payload.append('urlSha256', urlSha256);
      payload.append('eventType', 'soc_action');
      payload.append('title', title);
      payload.append('comments', comments);
      payload.append('timeStamp', utcTimestamp);
      payload.append('url', eventData.data.url || '');
      if (screenShot.length > 0) {
        payload.append('file', screenShot[0]);
      }

      await updateSocActionDetails(payload);

      // refresh the timline
      dispatch(setShouldRefreshTimeline(true));
      dispatch(alertActions.success('SOC details updated successfully'));
    } catch (error) {
      dispatch(alertActions.error('Error updating SOC details'));
    } finally {
      setIsLoading(false);
      onCancel();
    }
  }, [dispatch, onCancel, eventData.id, urlSha256, userTimeZone, values, eventData.data.url]);

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={'Edit SOC Details'}
      onCancel={onModalCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Edit'}
      submitButtonDisabled={isInvalid}
    >
      <div className={'soc-add-finding-modal'}>
        <Form>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <TextInput
              controlId='title'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleTextFieldChange(event.target.value, 'title')
              }
              defaultValue={initialValues.title}
            />
          </Form.Group>
          <Form.Group>
            <TextArea
              label='Comments'
              controlId='comments'
              onChange={(value: string) => handleTextFieldChange(value, 'comments')}
              defaultValue={initialValues.comments}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Timestamp</Form.Label>
            <TextInput
              controlId='timeStamp'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleTextFieldChange(event.target.value, 'timeStamp')
              }
              defaultValue={initialValues.timeStamp}
            />
          </Form.Group>

          <Form.Group>
            <UploadFiles
              label='Add Screenshot'
              controlId='screenShot'
              onChangeHandler={onHandleFilesSelect}
              dragAndDrop
              filesLimit={1}
            />
          </Form.Group>
        </Form>
      </div>
    </ModalOverlay>
  );
};
export default EditSocDetailModal;

import React from 'react';
import { getDisplayTimeFromTimeStamp } from '../../../constants';
import { LastRunResult } from './PlaybookTile';
import { ITableApiColumn } from '../../Common/Table/table.api';
import { customSortingComparator } from '../playbook-util';

export const PLAYBOOK_NAME: ITableApiColumn = {
  id: 'name',
  header: 'Name',
  accessor: 'name',
  render: (data: any) => {
    return data.name;
  },
  agFilterType: 'agTextColumnFilter',
  comparator: customSortingComparator,
};

export const PLAYBOOK_SCHEDULE: ITableApiColumn = {
  id: 'schedule',
  header: 'Schedule',
  accessor: 'schedule',
  render: (data: any) => {
    return data.displaySchedule;
  },
  agFilterType: 'agSetColumnFilter',
  sortDisabled: true,
  filterDisabled: true,
  type: 'options',
};

export const PLAYBOOK_NEXT_RUN: ITableApiColumn = {
  id: 'nextRun',
  header: 'Next Run (local time)',
  accessor: 'nextRun',
  filterDisabled: true,
  agFilterType: 'agTextColumnFilter',
  sortDisabled: true,
};

export const PLAYBOOK_OUTPUT: ITableApiColumn = {
  id: 'outputTo',
  header: 'Output To',
  accessor: 'outputTo',
  agFilterType: 'agTextColumnFilter',
};
export const PLAYBOOK_AUTHOR: ITableApiColumn = {
  id: 'author',
  header: 'Author',
  accessor: 'author',
  agFilterType: 'agTextColumnFilter',
};
export const PLAYBOOK_CREATED_TS: ITableApiColumn = {
  id: 'createdTs',
  header: 'Date Created',
  accessor: 'createdTs',
  filterDisabled: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.createdTs);
  },
};
export const PLAYBOOK_UPDATED_TS: ITableApiColumn = {
  id: 'updatedTs',
  header: 'Last Changed',
  accessor: 'updatedTs',
  filterDisabled: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.updatedTs);
  },
};
export const PLAYBOOK_PAST_RUN: ITableApiColumn = {
  id: 'runCount',
  header: 'Past Runs',
  accessor: 'runCount',
  type: 'number',
  agFilterType: 'agNumberColumnFilter',
};
export const PLAYBOOK_TOTAL_RESULT: ITableApiColumn = {
  id: 'totalResultCount',
  header: 'Total Results Output',
  accessor: 'totalResultCount',
  type: 'number',
  agFilterType: 'agNumberColumnFilter',
};
export const PLAYBOOK_LAST_COUNT: ITableApiColumn = {
  id: 'lastRunCount',
  header: '# from the last Run',
  accessor: 'lastRunCount',
  type: 'number',
  render: (data: any) => {
    return <LastRunResult data={data} />;
  },
  agFilterType: 'agNumberColumnFilter',
};
export const PLAYBOOK_ACTION_COLUMN: ITableApiColumn = {
  id: 'actionColumn',
  header: 'Actions',
  accessor: 'actionColumn',
  columnClassName: 'action-column',
  filterDisabled: true,
  sortDisabled: true,
};

export const PLAYBOOK_ID_COLUMN: ITableApiColumn = {
  id: 'id',
  header: 'Id',
  accessor: 'id',
  filterDisabled: true,
  render: (data: any) => {
    return data.id;
  },
};

import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

interface ICountdownProps {
  initialValue: number;
  onZero: () => void;
  interval?: number;
  waitTime?: number;
  prefix?: string;
  postfix?: string;
  className?: string;
}

export const CountDown = ({
  initialValue,
  interval = 1,
  waitTime = 1000,
  prefix = '',
  postfix = '',
  className = '',
  onZero = _.noop,
}: ICountdownProps) => {
  const [currentValue, setCurrentValue] = useState<number>(initialValue);

  useEffect(() => {
    updateValue();
  }, []);

  useEffect(() => {
    if (currentValue >= 0) {
      const timeout = setTimeout(updateValue, waitTime);
      return () => clearTimeout(timeout);
    }
  }, [currentValue]);

  const updateValue = useCallback(() => {
    const updatedValue = currentValue - interval;
    setCurrentValue(updatedValue);
    updatedValue < 0 && onZero && onZero();
  }, [currentValue]);

  return <div className={className}>{`${prefix}${currentValue}${postfix}`}</div>;
};

import React from 'react';
import {
  URL_COLUMN,
  PLATFORM_COLUMN,
  ORIGIN_COLUMN,
  CATEGORY_COLUMN,
  FIRST_FOUND_COLUMN,
  MATCH_LOGO_IMAGE_COLUMN,
  MATCH_SEARCH_TERMS_COLUMN,
  SCAN_STATUS_COLUMN,
  ACTIVE_SINCE,
  EXTERNAL_LINKS_COL,
  IS_LOGO_DETECTION_COL,
  TITLE_COLUMN,
  FINDING_SOURCE,
} from './TableColDef/FindingsTableCols';
import FindingsTableComponent from './FindingsTableComponent';
import { EFindingStatus, ugcType, ugcTypeLabel } from '../../Types/ugc.types';
import { isInternalUser } from '../../../../constants';
import { useAppSelector } from '../../../../helpers/hooks';

const PAGE_TITLE = 'Review Detections';

const Review = ({ type, tableId, urlColPathName, tableName, enableSocInterface }: any) => {
  const user = useAppSelector(state => state.dashboardReducer.user);
  const TABLE_COLUMNS = [
    URL_COLUMN(urlColPathName),
    PLATFORM_COLUMN,
    CATEGORY_COLUMN,
    FIRST_FOUND_COLUMN,
    MATCH_LOGO_IMAGE_COLUMN,
    MATCH_SEARCH_TERMS_COLUMN,
    SCAN_STATUS_COLUMN,
    ACTIVE_SINCE,
    EXTERNAL_LINKS_COL,
    IS_LOGO_DETECTION_COL,
    TITLE_COLUMN,
  ];

  window.document.title = `${PAGE_TITLE} | ${ugcTypeLabel[type]}`;
  const DEFAULT_COLUMN_IDS = [
    'url',
    'createdTs',
    'activeSince',
    'searchTerm',
    'platform',
    'category',
    'title',
    'logos',
    'actions',
    'externalLinks',
    'tags',
  ];

  if (type === ugcType.Social && isInternalUser(user)) {
    TABLE_COLUMNS.push(FINDING_SOURCE);
    DEFAULT_COLUMN_IDS.push('findingSource');
  }

  if (type === ugcType.Social) {
    TABLE_COLUMNS.push(ORIGIN_COLUMN);
    DEFAULT_COLUMN_IDS.push('origin');
  }

  return (
    <FindingsTableComponent
      id={tableId}
      tableName={tableName}
      title={PAGE_TITLE}
      columns={TABLE_COLUMNS}
      status={EFindingStatus.PENDING_OR_UNDER_REVIEW}
      defaultColumnIds={DEFAULT_COLUMN_IDS}
      type={type}
      enableSocInterface={enableSocInterface}
    />
  );
};

export default Review;

import _ from 'lodash';
import React from 'react';
import { capitalize, getDisplayTimeFromTimeStamp, isBrandReadOnlyUser } from '../../../constants';
import { Image } from '../../Common/Image';
import { ITableDataColumn } from '../../Common/Table/table.data';
import InProgressIcon from '../../../assets/icons/InProgress.svg';
import { EPropertyStatus, EPropertyStatusLabel } from '../PropertyTypes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ITableApiColumn } from '../../Common/Table/table.api';
import ModifyAssetsColRender from '../Common/ModifyAssetsColRender';
import AgGridDateRangeFilter from '../../Common/Table/ag-table/AgGridDateRangeFilter';
import { store } from '../../../helpers';

export const LOGO_PROPERTY_COL: ITableDataColumn = {
  id: 'logos',
  header: 'Managed Logos',
  accessor: 'value',
  filterDisabled: true,
  sortDisabled: true,
  render: ({ value }) => {
    if (!value) return '--';
    return (
      <>
        {_.map(value.split(','), path => (
          <Image className={'column-image'} key={path} imagePath={path} alt={path}></Image>
        ))}
      </>
    );
  },
  disableForSelection: true,
};
export const PROPERTY_CREATED_ON_COL: ITableDataColumn = {
  id: 'created_ts',
  header: 'Updated On',
  accessor: 'created_ts',
  isDefaultSort: true,
  type: 'date',
  filterDisabled: true,
  render: ({ createdTs }: any) => {
    if (!createdTs) {
      return '--';
    }
    return getDisplayTimeFromTimeStamp(`${createdTs}`, 'DD-MMM-YYYY');
  },
  agFilterType: AgGridDateRangeFilter,
};

export const UGC_DATE_ADDED: ITableDataColumn = {
  ...PROPERTY_CREATED_ON_COL,
  header: 'Date Added',
};

export const PROPERTY_CREATED_BY_COL: ITableDataColumn = {
  id: 'created_by',
  header: 'Uploaded By',
  accessor: 'created_by',
  render: ({ createdBy }) => {
    if (!createdBy) return '--';
    return createdBy;
  },
  agFilterType: 'agTextColumnFilter',
  sortDisabled: true,
  filterDisabled: true,
};

export const LOGO_STATUS_COL: ITableDataColumn = {
  id: 'status',
  header: 'Status',
  accessor: 'status',
  type: 'options',
  filterOptions: [
    { label: EPropertyStatusLabel.ACTIVE, value: EPropertyStatus.ACTIVE },
    { label: EPropertyStatusLabel.PENDING, value: EPropertyStatus.PENDING },
  ],
  render: (data: any) => {
    let icon;
    const { status } = data;
    if (status === EPropertyStatus.PENDING) {
      icon = <img src={InProgressIcon} alt={'spinning'} className='pr-1' />;
    }
    return (
      <div className='d-flex align-items-center'>
        {icon}
        {EPropertyStatusLabel[status]}
      </div>
    );
  },
  agFilterType: 'agTextColumnFilter',
};

export const TRADEMARK_PROPERTY_NAME: ITableDataColumn = {
  id: 'trademark',
  header: 'Trademark',
  accessor: 'value_json->>label',
  render: ({ valueJson }) => {
    if (!valueJson.label) return '--';
    return valueJson.label;
  },
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
};
export const TRADEMARK_REGISTRATION: ITableDataColumn = {
  id: 'registration',
  header: 'Registration No.',
  accessor: 'value_json->>registrationNumber',
  render: ({ valueJson }) => {
    if (!valueJson.registrationNumber) return '--';
    return valueJson.registrationNumber;
  },
  agFilterType: 'agTextColumnFilter',
};
export const TRADEMARK_JURISDICTION: ITableDataColumn = {
  id: 'jurisdiction',
  header: 'Jurisdiction',
  accessor: 'value_json->>jurisdiction',
  render: ({ valueJson }) => {
    if (!valueJson.jurisdiction) return '--';
    return valueJson.jurisdiction;
  },
  agFilterType: 'agTextColumnFilter',
};

export const TRADEMARK_CLASS: ITableDataColumn = {
  id: 'classId',
  header: 'Class',
  accessor: 'value_json->>classId',
  render: ({ valueJson }) => {
    if (!valueJson.classId) return '--';
    return valueJson.classId;
  },
  agFilterType: 'agTextColumnFilter',
};

export const UGC_NAME: ITableDataColumn = {
  id: 'name',
  header: 'Account/Channel Name',
  accessor: 'value_json->>name',
  render: ({ valueJson }) => {
    if (!valueJson.name) return '--';
    return valueJson.name;
  },
  agFilterType: 'agTextColumnFilter',
};

export const UGC_PLATFORM: ITableDataColumn = {
  id: 'platform',
  header: 'Platform',
  accessor: 'value_json->>platform',
  type: 'options',
  render: ({ valueJson }) => {
    if (!valueJson.platform) return '--';
    return capitalize(valueJson.platform);
  },
  agFilterType: 'agTextColumnFilter',
};

export const UGC_URL = (): ITableDataColumn => ({
  id: 'url',
  header: 'URL',
  accessor: 'value_json->>url',
  render: ({ valueJson }) => {
    if (valueJson.url) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + valueJson.url} className={'table-source-url-tooltip'}>
              {valueJson.url}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column table-long-text long-text-ellipsis-1'}>
            {valueJson.url}
          </div>
        </OverlayTrigger>
      );
    }
    return <div>{'--'}</div>;
  },
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
});

export const NOTES_COL: ITableDataColumn = {
  id: 'notes',
  header: 'Notes',
  accessor: 'value_json->>notes',
  render: ({ valueJson }) => {
    if (!valueJson.notes) return '--';
    return valueJson.notes;
  },
  agFilterType: 'agTextColumnFilter',
};

export const DOCUMENT_PROPERTY_COL: ITableDataColumn = {
  id: 'documents',
  header: 'Documents',
  accessor: 'value',
  filterDisabled: true,
  sortDisabled: true,
  disableForSelection: true,
};

export const MARKETPLACE_NAME: ITableDataColumn = {
  ...UGC_NAME,
  header: 'Marketplace Name',
};

export const MARKETPLACE_SELLER_NAME = (): ITableDataColumn => ({
  id: 'sellerName',
  header: 'Seller Name',
  accessor: 'value_json->>sellerName',
  render: ({ valueJson }) => {
    if (valueJson.sellerName) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + valueJson.sellerName} className={'table-source-url-tooltip'}>
              {valueJson.sellerName}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column table-long-text long-text-ellipsis-1'}>
            {valueJson.sellerName}
          </div>
        </OverlayTrigger>
      );
    }
    return <div>{'--'}</div>;
  },
  agFilterType: 'agTextColumnFilter',
});

export const APP_STORE_NAME: ITableDataColumn = {
  ...UGC_NAME,
  header: 'App Name',
};

export const APP_STORE_STORE_NAME: ITableDataColumn = {
  id: 'domainLabel',
  header: 'App Store',
  accessor: 'value_json->>domainLabel',
  render: ({ valueJson }) => {
    if (!valueJson.domainLabel) return '--';
    return valueJson.domainLabel;
  },
  agFilterType: 'agTextColumnFilter',
};

export const BRAND_COLUMN: ITableDataColumn = {
  id: 'brand',
  header: 'Brand',
  accessor: 'brand_id',
  type: 'options',
  filterOptions: [],
  render: ({ brand }) => {
    return brand?.brandDisplayName || '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const BRAND_PROPERTY_ACTION_COL: ITableApiColumn = {
  id: 'assets-action-col',
  header: 'Actions',
  accessor: 'assets-action-col',
  hiddenInExport: true,
  filterDisabled: true,
  sortDisabled: true,
  render: (data: any) => {
    if (isBrandReadOnlyUser(store.getState()?.dashboardReducer?.user)) return null;
    return <ModifyAssetsColRender data={data} />;
  },
};

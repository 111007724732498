import React, { useEffect, useState } from 'react';
import UgcService from '../../../../services/ugc.service';
import { SummaryWidget } from '../../../Common/SummaryWidget';
import { ugcType } from '../../Types/ugc.types';
import { socialMediaMockSummaryData } from '../../../../constants';

const SUMMARY_HEADERS = {
  totalThreatsDetected: 'Total Detections',
  monitoredPlatforms: 'Monitored Platforms',
  monitoredAppStores: 'Monitored App Stores',
  monitoredSearchTerms: 'Monitored Search Terms',
  monitoredImages: 'Monitored Images',
};

const ugcService = new UgcService();

function UgcDashboardSummary({ type }: any) {
  const [socialSummaryData, setSocialSummaryData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isDemo = window.location.pathname.includes('premium') || false;

  useEffect(() => {
    getSummaryData();
  }, []);

  const setSummaryWidgetData = (res: any) => {
    const data: any = [];
    const monitoredKeys = Object.keys(res?.summaryDetails || {});
    const monitoredValues = Object.values(res?.summaryDetails || {});

    monitoredKeys.map((item, index) => {
      const obj = {};
      obj['id'] = item;
      obj['value'] = monitoredValues[index];
      obj['label'] = SUMMARY_HEADERS[item];
      obj['tooltip'] = SUMMARY_HEADERS[item];
      data.push(obj);
    });
    setIsLoading(false);
    setSocialSummaryData(
      type === ugcType.App_Store
        ? data.filter((item: any) => item.id !== 'monitoredAppStores')
        : data,
    );
  };

  const getSummaryData = () => {
    setIsLoading(true);
    isDemo && type === 'social'
      ? setSummaryWidgetData(socialMediaMockSummaryData)
      : ugcService
          .getSummaryDetails(type)
          .then(res => {
            setSummaryWidgetData(res);
            // as per ZION-4429, we are filtering monitoredAppStores for now
          })
          .catch(error => {
            setIsLoading(false);
            console.log(error, 'error');
          });
  };
  return <SummaryWidget isLoading={isLoading} data={socialSummaryData} />;
}

export { UgcDashboardSummary };

import React from 'react';
import { Card } from 'react-bootstrap';
import { PageTitleInsights } from '../../../../Common/PageTitleInsights';
import { IDarkWebFindingData } from '../../Types/DarkWeb.types';

interface IViewRawDataProps {
  data: IDarkWebFindingData;
  backFn: () => void;
}
export default function ViewRawData({ data, backFn }: IViewRawDataProps) {
  const { content } = data;
  return (
    <div className='raw-data-page'>
      <PageTitleInsights title={'View Raw Data'} backFn={backFn} />
      <Card className='content-container'>
        <Card.Body className='content-body'>{content}</Card.Body>
      </Card>
    </div>
  );
}

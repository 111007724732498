import fetchApi from '../../services/api.service';
import { handleResponse } from '../../services/serviceWorker';
import { IUserListItemDto } from './TeamMembers';
import { INewMemberPayload } from './common/AddTeamMemberModal';

export const fetchAllTeamMembers = async (): Promise<IUserListItemDto[]> => {
  const rawResponse = await fetchApi('/platform-api/v1/platform-administration/get-user-list').then(
    handleResponse,
  );
  return rawResponse;
};

export const addNewTeamMember = async (newUser: INewMemberPayload): Promise<any> => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: newUser }),
  };
  const rawResponse = await fetchApi('/platform-api/v1/brand-admin/add-user', requestOptions).then(
    handleResponse,
  );
  return rawResponse;
};

export const updateTeamMember = async (updatedUser: object): Promise<any> => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: updatedUser }),
  };
  const rawResponse = await fetchApi(
    '/platform-api/v1/brand-admin/modify-user',
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const deleteTeamMember = async (user: object): Promise<any> => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user }),
  };
  const rawResponse = await fetchApi(
    '/platform-api/v1/brand-admin/delete-user',
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

import { CustomCellRendererProps } from 'ag-grid-react';
import React, { useEffect } from 'react';
import './TakedownTimelineContainer.scss';
import MonthlyTakedownTimelineView from './MonthlyTakedownTimelineView';
import YearlyTakedownTimelineView from './YearlyTakedownTimelineView';
import { useAppSelector } from '../../helpers/hooks';
import useOnTakedownTimelineEvents from './useOnTakedownTimelineEvents';
export interface ITakedownEvents {
  action: string;
  createdts: string;
  label: string;
}

export interface ITakedownActions {
  currentMonth: string;
  events: {
    [key: string]: ITakedownEvents[];
  };
}

export enum ETimelineViewModes {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export default function TakedownTimelineContainer(props: CustomCellRendererProps) {
  const globalTakedownRowData = useAppSelector(state => state.tableReducer.globalTakedownRowData);
  const { data, node } = props;
  const rowId = node.id as string;

  useOnTakedownTimelineEvents({ data, rowId });

  return (
    <div className='takedown-timeline-container'>
      {globalTakedownRowData?.[rowId]?.viewMode === ETimelineViewModes.MONTHLY && (
        <MonthlyTakedownTimelineView
          takeDownEvents={globalTakedownRowData[rowId].data}
          rowId={rowId}
          yearlyTakedownEvents={data.yearlyTakedownEvents}
        />
      )}
      {globalTakedownRowData?.[rowId]?.viewMode === ETimelineViewModes.YEARLY && (
        <YearlyTakedownTimelineView
          takeDownEvents={globalTakedownRowData[rowId].data}
          rowId={rowId}
        />
      )}
    </div>
  );
}

import { featureIsAvailable } from '../constants';
import { store } from '../helpers';
import { useMemo } from 'react';

export const useFeatureIsAvailable = (featureCode: string) => {
  const user = useMemo(
    () => store.getState().dashboardReducer?.user || {},
    [store.getState().dashboardReducer?.user],
  );
  return useMemo(() => featureIsAvailable(user, featureCode), [user, featureCode]);
};

// for other usage outside function component
export const getIsFeatureAvailable = (featureCode: string) => {
  const user = store.getState().dashboardReducer?.user || {};
  return featureIsAvailable(user, featureCode);
};

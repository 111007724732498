import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import '../../../Style/DarkWeb.scss';
import { AppState, history } from '../../../../../helpers';
import { AuthenticationWrapper } from '../../../../AuthenticationWrapper';
import { PageTitleInsights } from '../../../../Common/PageTitleInsights';
import {
  EDarkWebFindingStatus,
  EDarkWebRiskLevel,
  IDarkWebFindingData,
} from '../../Types/DarkWeb.types';
import FindingStatusAndRisk from './FindingStatusAndRisk';
import ScanResultSection from './ScanResultSection';
import { ContentInfoSection } from './ContentInfoSection';
import ViewRawData from './ViewRawData';
import DarkWebService from '../../../../../services/darkWeb.service';
import { getSha256, getInsightPagePathname, appConstants } from '../../../../../constants';
import SimilarFindingsSection from './SimilarFindingsSection';
import ActiveRoutesContext from '../../../../../context/ActiveRoutesContext';
import DashboardService from '../../../../../services/dashboard.service';

const darkWebService = new DarkWebService();
const dashboardService = new DashboardService();

interface IDarkWebInsightsProp {}

function DarkWebInsights(props: IDarkWebInsightsProp) {
  const [contentInfoSectionTs, setContentInfoSectionTs] = useState<number>(moment().valueOf());
  const [similarFindings, setSetSimilarFindings] = useState<IDarkWebFindingData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSimilarFindingsLoading, setIsSimilarFindingsLoading] = useState<boolean>(false);
  const { getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);
  const [insightData, setInsightData] = useState<IDarkWebFindingData>({
    name: 'Data Leak',
    status: EDarkWebFindingStatus.IGNORED,
    risk_level: EDarkWebRiskLevel.LOW,
    title: '',
    threat_actor: '',
    url: '',
    network: '',
    platform: '',
    searchTerm: '',
    highlights: '',
    content: ``,
    content_sha_256: ``,
    sensitiveInfo: '',
    platform_published_ts: '',
    category: '',
    sensitiveInfoExists: false,
    tags: [],
    url_sha256: '',
    created_ts: '',
  });
  const [rawDataShown, setRawDataShown] = useState<boolean>(false);
  const toggleRawDataShown = useCallback(() => {
    setRawDataShown(!rawDataShown);
  }, [rawDataShown]);

  useEffect(() => {
    const originalPathname = getInsightPagePathname();
    const unListen = history.listen((location: any, action: any) => {
      if (location.pathname.indexOf(originalPathname) > -1) {
        fetchData();
      }
    });

    fetchData();

    return () => {
      unListen();
    };
  }, []);

  const fetchSimilarFindings = (finding: { category: string; content_sha_256: string }) => {
    setIsSimilarFindingsLoading(true);
    darkWebService
      .getSimilarFinding(
        {
          category: finding.category,
          excludeSha256s: [finding.content_sha_256],
        },
        {
          includeFields: [
            'category',
            'risk_level',
            'title',
            'threat_actor',
            'platform_published_ts',
            'content_sha_256',
          ],
        },
      )
      .then(res => {
        setSetSimilarFindings(res);
        setIsSimilarFindingsLoading(false);
      });
  };

  const fetchData = () => {
    setIsLoading(true);
    const content_sha_256 = getSha256().split('#')[0];

    const includeFields = [
      'title',
      'status',
      'risk_level',
      'threat_actor',
      'platform',
      'search_term',
      'highlights',
      'content',
      'content_sha_256',
      'platform_published_ts',
      'category',
      'source_specific_info',
    ];

    Promise.allSettled([
      darkWebService.getFinding(content_sha_256, { includeFields }),
      dashboardService.getTagsByUrlSha(content_sha_256, appConstants.CONTENT_TYPE.DARK_WEB),
    ])
      .then((allResponses: any) => {
        const [insightData, tagsData] = allResponses.map((result: any) => result.value);
        fetchSimilarFindings(insightData);
        const {
          source_specific_info = {},
          title,
          status,
          risk_level,
          threat_actor,
          platform,
          search_term,
          highlights,
          content,
          content_sha_256,
          platform_published_ts,
          category,
          created_ts,
        } = insightData;
        const { network, url } = source_specific_info;
        setInsightData({
          name: title,
          title,
          status,
          risk_level,
          threat_actor,
          url,
          network: capitalize(network) || '--',
          platform: platform || '--',
          searchTerm: search_term,
          highlights: highlights.length
            ? _.map(highlights, (term: any) => term.highlight).join(', ')
            : '--',
          content,
          content_sha_256,
          sensitiveInfo: source_specific_info,
          platform_published_ts,
          category,
          sensitiveInfoExists: source_specific_info.has_sensitive_data || false,
          tags: tagsData.tags || [],
          url_sha256: content_sha_256,
          created_ts,
        });
        setIsLoading(false);
        setContentInfoSectionTs(moment().valueOf());
        getParentRouteViaFindingStatus(status);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <AuthenticationWrapper>
      <div className='dark-web-insights-page' id={'dark-web-insights-title'}>
        {rawDataShown ? (
          <ViewRawData data={insightData} backFn={toggleRawDataShown} />
        ) : (
          <>
            <PageTitleInsights
              title={insightData.title}
              backFn={() => {
                history.go(-2);
              }}
              tools={<FindingStatusAndRisk data={insightData} />}
              isLoading={isLoading}
            />
            <div className='page-content insights-container'>
              <ScanResultSection
                data={insightData}
                isLoading={isLoading}
                type={appConstants.CONTENT_TYPE.DARK_WEB}
              />
              <ContentInfoSection
                key={contentInfoSectionTs}
                data={insightData}
                onViewRaw={toggleRawDataShown}
                isLoading={isLoading}
              />
            </div>
            <SimilarFindingsSection
              findings={similarFindings}
              isLoading={isSimilarFindingsLoading}
            />
          </>
        )}
      </div>
    </AuthenticationWrapper>
  );
}

export default DarkWebInsights;

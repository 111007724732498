import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import L from 'leaflet';
import { getName } from 'country-list';
import { isInternalUser, numberWithCommas, ThemeModes } from '../../../constants';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LoadingWrapper } from '../LoadingWrapper';
import { IChartProps, IMapChartProps } from './Types/chart.type';
import ThemeContext from '../../../context/ThemeContext';

const MAP_ID = 'domains_count_by_region';
const mapCenterX = 10;
const mapCenterY = 0;
const provider = new OpenStreetMapProvider();
const invalidNames = ['generic', 'Generic', 'unknown', 'undefined'];
const countryToLocation = {
  undefined: { lat: 0, lng: 0 },
  Unknown: { lat: 0, lng: 0 },
  AR: { lat: -34.9964963, lng: -64.9672817 },
  AT: { lat: 47.2000338, lng: 13.199959 },
  AU: { lat: -24.7761086, lng: 134.755 },
  BE: { lat: 50.6402809, lng: 4.6667145 },
  BG: { lat: 42.6073975, lng: 25.4856617 },
  BR: { lat: -10.3333333, lng: -53.2 },
  CA: { lat: 61.0666922, lng: -107.9917071 },
  CH: { lat: 46.7985624, lng: 8.2319736 },
  CL: { lat: -31.7613365, lng: -71.3187697 },
  CO: { lat: 2.8894434, lng: -73.783892 },
  DE: { lat: 51.0834196, lng: 10.4234469 },
  FR: { lat: 46.603354, lng: 1.8883335 },
  GB: { lat: 54.7023545, lng: -3.2765753 },
  HK: { lat: 22.350627, lng: 114.1849161 },
  HU: { lat: 47.1817585, lng: 19.5060937 },
  IE: { lat: 52.865196, lng: -7.9794599 },
  IN: { lat: 22.3511148, lng: 78.6677428 },
  IT: { lat: 42.6384261, lng: 12.674297 },
  KR: { lat: 36.638392, lng: 127.6961188 },
  NL: { lat: 52.5001698, lng: 5.7480821 },
  PL: { lat: 52.215933, lng: 19.134422 },
  RU: { lat: 64.6863136, lng: 97.7453061 },
  US: { lat: 39.7837304, lng: -100.4458825 },
  VG: { lat: 18.4024395, lng: -64.5661642 },
  VN: { lat: 13.2904027, lng: 108.4265113 },
  ZA: { lat: -28.8166236, lng: 24.991639 },
  Asia: { lat: 51.2086975, lng: 89.2343748 },
  Africa: { lat: 11.5024338, lng: 17.7578122 },
  Europe: { lat: 51, lng: 10 },
  'Australia (Oceania)': { lat: -24.7761086, lng: 134.755 },
  America: { lat: 13, lng: -85 }, // Combine with North and South America
};

const MapChart = ({
  title,
  isLoading,
  data,
  className,
  description,
}: IChartProps & IMapChartProps) => {
  const mapRef = React.useRef<any>(null);
  const { selectedTheme } = useContext(ThemeContext);

  const creatMap = () => {
    mapRef.current = L.map(MAP_ID, {
      attributionControl: false,
      center: [mapCenterX, mapCenterY],
      zoomControl: false,
      zoom: 1,
      layers: [
        L.tileLayer(
          'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
          {
            minZoom: 1,
            maxZoom: 1,
            id:
              selectedTheme === ThemeModes.DARK.toLowerCase()
                ? 'shashi-bolster/ckx5v8plz2end14obplifich8'
                : 'shashi-bolster/ckrmikoplbabc17p3eyjve6rq',
            accessToken:
              'pk.eyJ1Ijoic2hhc2hpLWJvbHN0ZXIiLCJhIjoiY2tybWdubnAzMnZnOTJybTQ4aGQ0bzRhMyJ9.Tg2wDDRBKgQeoBeMceTDag',
          },
        ),
      ],
    });
  };
  const addMarkers = async () => {
    const setMarkerIcon = (count: number) => {
      return L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='marker-pin'>${numberWithCommas(count)}</div>`,
        iconSize: [46, 24], // size of the icon
        iconAnchor: [23, 12], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -5], // point from which the popup should open relative to the iconAnchor
        shadowSize: [0, 0], // size of the shadow
      });
    };

    Promise.all(
      _.map(data, async item => {
        const countryCode = item.location;
        const value = item.count;
        if (countryToLocation.hasOwnProperty(countryCode)) {
          return { ...countryToLocation[countryCode], value, countryCode };
        } else if (invalidNames.indexOf(countryCode) === -1) {
          const location = await provider.search({ query: countryCode }).catch(err => {
            console.error('Fail getting location of ' + countryCode);
            return {};
          });
          if (_.isEmpty(location)) {
            return { countryCode: '' };
          }
          const lat = parseFloat(_.get(location[0], ['y'], '0'));
          const lng = parseFloat(_.get(location[0], ['x'], '0'));
          countryToLocation[countryCode] = { lat, lng };
          return {
            lat,
            lng,
            value,
            countryCode,
          };
        } else {
          return {
            lat: 0,
            lng: 0,
            value,
            countryCode,
          };
        }
      }),
    ).then(results => {
      mapRef.current.setView(new L.LatLng(mapCenterX, mapCenterY), 1);
      const layer = L.layerGroup().addTo(mapRef.current);
      _.forEach(results, item => {
        const { lat, lng, value, countryCode } = item;
        if (invalidNames.indexOf(countryCode) === -1) {
          const countryName = getName(countryCode) || countryCode;
          const marker = L.marker({ lat: lat, lng: lng }, { icon: setMarkerIcon(value) })
            .bindPopup(countryName.toUpperCase() + ': ' + value)
            .addTo(layer);
          marker.on('mouseover', () => {
            marker.openPopup();
          });
          marker.on('mouseout', () => {
            marker.closePopup();
          });
        }
      });
    });
  };

  useEffect(() => {
    if (!mapRef.current) {
      creatMap();
      mapRef.current.invalidateSize();
    }
    addMarkers();
  }, [data]);

  return (
    <div className={'dashboard-widget dashboard-map-chart ' + className}>
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-hosting'} className={'table-source-url-tooltip'}>
            {description}
          </Tooltip>
        }
      >
        <div className='dashboard-chart-title'>{title}</div>
      </OverlayTrigger>
      <LoadingWrapper isLoading={isLoading} className='loading-wrapper'>
        {''}
      </LoadingWrapper>
      <div className={'dashboard-map-container ' + (isLoading ? 'opacity-0' : '')}>
        <div id={MAP_ID} className={'dashboard-map'}></div>
      </div>
    </div>
  );
};

export default MapChart;

import React from 'react';
import { ITableDataColumn } from '../../Common/Table/table.data';
import { ERoleName, customAgGridDateComparator, isAdminUser } from '../../../constants';
import { customSortingComparator } from '../../Playbook/playbook-util';
import { getAvailableModules } from '../../../helpers/get-available-modules';
import { IUserListItemDto } from '../TeamMembers';
import TeamMemberModules from './TeamMemberModules';
import { store } from '../../../helpers';

export const TEAM_MEMBER_FIRST_NAME: ITableDataColumn = {
  id: 'firstName',
  header: 'First Name',
  accessor: 'firstName',
  isLongText: true,
  editable: false,
  agFilterType: 'agTextColumnFilter',
  comparator: customSortingComparator,
  disableForSelection: true,
};

export const TEAM_MEMBER_LAST_NAME: ITableDataColumn = {
  id: 'lastName',
  header: 'Last Name',
  accessor: 'lastName',
  isLongText: true,
  editable: false,
  agFilterType: 'agTextColumnFilter',
  comparator: customSortingComparator,
  disableForSelection: true,
};
export const TEAM_MEMBER_EMAIL: ITableDataColumn = {
  id: 'email',
  header: 'Email',
  accessor: 'email',
  isLongText: true,
  editable: false,
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
};
export const TEAM_MEMBER_BRAND_NAME: ITableDataColumn = {
  id: 'brand',
  header: 'Brand Name',
  accessor: 'brandId',
  isLongText: true,
  editable: false,
  type: 'options',
  agFilterType: 'agTextColumnFilter',
};
export const TEAM_MEMBER_IS_VERIFIED: ITableDataColumn = {
  id: 'verified',
  header: 'Verified',
  accessor: 'verified',
  columnClassName: 'hide-below-md',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'True' },
    { label: 'False', value: 'False' },
  ],
  agFilterType: 'agTextColumnFilter',
  editable: false,
};
export const TEAM_MEMBER_ROLE: ITableDataColumn = {
  id: 'roleId',
  header: 'Role',
  accessor: 'roleId',
  editable: false,
  columnClassName: 'hide-below-md',
  type: 'options',
  filterOptions: [
    { label: 'Admin', value: 'Admin' },
    { label: 'User', value: 'User' },
    { label: 'Read-Only', value: 'Read-Only' },
  ],
  agFilterType: 'agTextColumnFilter',
};

export const TEAM_MEMBER_MODULE: ITableDataColumn = {
  id: 'teamMemberModule',
  header: 'Module',
  accessor: 'teamMemberModule',
  editable: false,
  columnClassName: 'hide-below-md',
  filterDisabled: true,
  sortDisabled: true,
  render: (data: { teamMemberModule: IUserListItemDto }) => {
    const user = store.getState()?.dashboardReducer?.user;
    if (user && !isAdminUser(user, false, false)) return null;
    const isTeamMemberAdmin =
      data?.teamMemberModule?.user?.userAttribute?.role?.role_name === ERoleName.brandAdmin;
    if (isTeamMemberAdmin) return 'All Modules';

    const disabledModuleCodes = data?.teamMemberModule?.user?.userFeatureAccess?.map(
      module => module?.productFeatureAccess?.pf?.code,
    );

    const availableModules = getAvailableModules();

    return (
      <TeamMemberModules
        modules={availableModules}
        disabledModules={disabledModuleCodes}
        teamMemberModule={data?.teamMemberModule}
      />
    );
  },
};

export const TEAM_MEMBER_LAST_ACTIVITY: ITableDataColumn = {
  id: 'lastActivity',
  header: 'Last Activity',
  accessor: 'lastActivity',
  columnClassName: 'hide-below-md',
  filterDisabled: true,
  editable: false,
  comparator: (a: any, b: any) => {
    return customAgGridDateComparator(a, b, 'DD-MMM-YYYY');
  },
  type: 'date',
  agFilterType: 'agDateColumnFilter',
};

export const TEAM_MEMBER_ACTION_COL: ITableDataColumn = {
  id: 'actions-col',
  header: 'Actions',
  accessor: 'actionColumn',
  editable: false,
  columnClassName: 'hide-below-md',
  sortDisabled: true,
  filterDisabled: true,
  hiddenInExport: true,
};

import React, { useEffect } from 'react';
import { ScanComponent } from '../Scan/ScanComponent';
import ProgressBar from '../Common/LinearProgressBar';
import { StepProgressBar } from '../Common/StepProgress';
import { useContext } from 'react';
import CheckPhishContactUsContext, {
  CheckPhishContactUsContextProvider,
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import { IErrorMsg } from './TyposquatPage';
import './typosquat.scss';
import { Button } from 'react-bootstrap';
import BlueTooltipIcon from '../../assets/icons/BlueTooltip.svg';
import { Link } from 'react-router-dom';
import ScanBarBanner from './ScanBarBanner';
import WarningSign from '../../assets/icons/BannerWarning.svg';
import SuccessSign from '../../assets/icons/Success.svg';
import ExportDark from '../../assets/icons/ExportDark.svg';
import ExportDarkActive from '../../assets/icons/ExportDarkActive.svg';
import ExportActive from '../../assets/icons/ExportActive.svg';
import ExportIcon from '../../assets/icons/ExportIcon.svg';
import ThemeContext from '../../context/ThemeContext';
import { useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { PRICING_PAGE_PATH } from '../Pricing/constants';
import { history } from '../../helpers';

interface ITyposquatSearchBarProps {
  isLoading: boolean;
  changeSearchText: (searchText: string) => void;
  searchText: string;
  onTyposquatSubmit: () => void;
  progress: number;
  activeStep: number;
  totalVarients: number;
  totalResolvedVariants: number;
  showCancelBtn: boolean;
  onDomainChange: (originalDomain: string) => void;
  onCancel: () => void;
  errMsg: IErrorMsg;
  typosquatData: any[];
  disabledGenerateButton: boolean;
  limitStatus: 'offlimit' | 'inlimit';
  showLiveScanLink: { status: boolean; message: string; includeDomain: boolean };
  showExportPopup: boolean;
  domainName: string;
  triggerCSVExport: (newState: boolean) => void;
  setExportModalDisplay: (newState: boolean) => void;
  scanLimit: number;
  extractedDomain: string;
  showScanBarBanner: boolean;
  toggleScanBarBanner: () => void;
}

const TyposquatSearchBar: React.FC<ITyposquatSearchBarProps> = ({
  isLoading,
  changeSearchText,
  searchText,
  onTyposquatSubmit,
  progress,
  activeStep,
  totalVarients,
  totalResolvedVariants,
  showCancelBtn,
  onCancel,
  onDomainChange,
  errMsg,
  typosquatData,
  disabledGenerateButton,
  limitStatus,
  showLiveScanLink,
  showExportPopup,
  domainName,
  scanLimit,
  triggerCSVExport,
  setExportModalDisplay,
  extractedDomain,
  showScanBarBanner,
  toggleScanBarBanner,
}: ITyposquatSearchBarProps) => {
  const { setTriggerContactUsModal, paidUser } = useContext(CheckPhishContactUsContext);
  const { selectedTheme } = useContext(ThemeContext);
  const exportIcon = useRef<any>(null);
  const { currentTyposquatSubscription } = useContext(CheckphishSubscriptionContext);

  const setExportIcon = (onButton = true, returnImg = false) => {
    let img = ExportIcon;
    if (selectedTheme === 'dark') {
      img = onButton ? ExportDarkActive : ExportDark;
    } else {
      img = onButton ? ExportActive : ExportIcon;
    }
    if (returnImg) return img;
    exportIcon.current.setAttribute('src', img);
  };

  return isLoading ? (
    <>
      <div className='progress-wrap'>
        {searchText.length && <h5>Generating Typosquat for {searchText}</h5>}
        <StepProgressBar
          activeStep={activeStep - 1}
          totalVarients={totalVarients}
          totalResolvedVariants={totalResolvedVariants}
        />
        <ProgressBar progress={`${progress}`} width={80} height={5} textPosition={'bottom'} />
        {!currentTyposquatSubscription && activeStep === 3 && (
          <div className='status'>Scan in progress. Expect about 2-5 mins...</div>
        )}
        {currentTyposquatSubscription && activeStep >= 2 && (
          <div className='progress-text'>
            <h4>Typosquat generation and scanning in progress</h4>
            <span className='status'>
              We’re on it! Typosquat generation is in progress and currently at{' '}
              {Math.floor(progress) + ' '}%{' '}
              <span className='domain-count'>
                ({typosquatData.length && ` ${typosquatData.length} registered domains found `}) .
              </span>
              Please check back soon for the full results.
            </span>
          </div>
        )}
      </div>
    </>
  ) : (
    <>
      {showCancelBtn && (
        <button className='cancel-edit-typosquat edit-domain-btn' onClick={onCancel}>
          cancel
        </button>
      )}
      {showExportPopup ? (
        <>
          <h5>Export Scan Results for "{domainName}"</h5>
          <p className='typosquat-scan-sub-text'>
            You have {scanLimit} daily limit {paidUser ? 'on your plan' : 'on the free plan'}.
            Export before monitoring a new domain?
          </p>
          <div className='export-wrapper'>
            <Button
              onClick={() => triggerCSVExport(true)}
              className='transparent-btn primary-border export-btn'
              onMouseOver={() => setExportIcon(true, false)}
              onMouseOut={() => setExportIcon(false, false)}
            >
              Export First <img ref={exportIcon} src={setExportIcon(false, true)} />
            </Button>
            <Button
              onClick={() => setExportModalDisplay(false)}
              className='primary-btn btn continue-btn'
            >
              Continue
            </Button>
          </div>
        </>
      ) : (
        <>
          <h5>Add a domain to monitor</h5>
          <p className='typosquat-scan-sub-text'>
            {limitStatus === 'inlimit' ? (
              <>
                You have {scanLimit} daily limit{' '}
                {paidUser
                  ? '.'
                  : `on the free plan. Start a trial to monitor more
                domains.`}
                <button className='contact-us-link' onClick={() => history.push(PRICING_PAGE_PATH)}>
                  Contact Us
                </button>
              </>
            ) : (
              <>
                You have hit your daily limit{' '}
                {paidUser
                  ? `on your plan`
                  : `on the free plan Return at ${moment()
                      .add(1, 'day')
                      .utc()
                      .format('MMM Do')}, 12 AM UTC to monitor new
                domain or `}
                <button className='contact-us-link' onClick={() => history.push(PRICING_PAGE_PATH)}>
                  Upgrade
                </button>{' '}
                to monitor more domains.
              </>
            )}
          </p>
          <div className='search-bar-wrap'>
            <ScanComponent
              className={`scan-widget`}
              displayError={showLiveScanLink.status}
              isLoading={isLoading}
              onlyScanBar={true}
              onChangeSearchText={changeSearchText}
              isTypoSquat={true}
              size={'small'}
              value={searchText}
              onTyposquatSubmit={onTyposquatSubmit}
              onDomainChange={onDomainChange}
              disableGenerate={disabledGenerateButton}
              placeholderText={'domain.com'}
              displayTooltip={true}
              submitBtnText={'Generate Typosquat'}
              submitBtnCustomClass={'typosquat-search-btn-custom'}
              showPretext={true}
              preText={'https://www.'}
              extractedDomain={extractedDomain}
              toggleScanBarBanner={toggleScanBarBanner}
              limitStatus={limitStatus}
            />
            {errMsg.status ? (
              errMsg.isSuccessMsg ? (
                <span className='success-text'>
                  <img src={SuccessSign} alt='success sign' />
                  The domain is validated, you can generate typosquat now.
                </span>
              ) : (
                <span className='err-text'>{errMsg.message}</span>
              )
            ) : (
              showLiveScanLink.status && (
                <div className='err-text-modal live-scan-suggestion'>
                  <img src={WarningSign} alt='warning-sign' className='warning-sign' />
                  {!_.isEmpty(showLiveScanLink.message) && (
                    <div className='message'>
                      {showLiveScanLink.includeDomain ? (
                        <>
                          {showLiveScanLink.message.split(extractedDomain)[0]}
                          <span className='extracted-domain'>{extractedDomain}.</span>
                          <br />
                          {showLiveScanLink.message.split(extractedDomain)[1]}
                        </>
                      ) : (
                        <>{showLiveScanLink.message}</>
                      )}
                      <span className='link-wrapper'>
                        {' '}
                        Please use
                        <Link to={'/bulk-scan'}> URL Scanner</Link> instead.
                      </span>
                    </div>
                  )}
                </div>
              )
            )}
            {showScanBarBanner && (
              <ScanBarBanner isError={false} customClassName='tooltip-wrapper'>
                <img src={BlueTooltipIcon} alt='tooltip' className='tooltip-icon-typosquat' />
                Enter your company domain you want to monitor, e.g., amazon.com.
              </ScanBarBanner>
            )}
          </div>
        </>
      )}
    </>
  );
};

export { TyposquatSearchBar };

import * as React from 'react';
import { alertConstants, setLocalStorageValue } from '../../constants';
import './GlobalAlert.scss';
import Button from 'react-bootstrap/Button';
import CheckCircleIcon from '../../assets/icons/CheckCircle.svg';
import DangerCircleIcon from '../../assets/icons/DangerCircle.svg';
import RefreshIcon from '../../assets/icons/Refresh.svg';
import CloseWhiteIcon from '../../assets/icons/CloseWhite.svg';
import InfoWhiteIcon from '../../assets/icons/InfoWhite.svg';
import packageJson from '../../../package.json';

const TIMEOUT = 10000;

interface ILinkStateProp {
  alert: any;
  index: number;
}

interface ILinkDispatchProps {
  clearAlert: (id: number) => void;
}

type Props = ILinkStateProp & ILinkDispatchProps;

interface IGlobalAlertState {}

class GlobalAlertItem extends React.Component<Props, IGlobalAlertState> {
  private autoCloseTimeout: any = undefined;

  componentDidMount(): void {
    this.createAutoClose();
  }

  createAutoClose = () => {
    const { alert } = this.props;
    if (
      alert.type === alertConstants.REQUIRED_ACTION ||
      alert.type === alertConstants.RELEASE_NOTE_ACTION
    ) {
      return;
    }
    this.autoCloseTimeout = setTimeout(() => {
      this.props.clearAlert(this.props.alert.id);
    }, TIMEOUT);
  };

  onMouseEnter = () => {
    clearTimeout(this.autoCloseTimeout);
  };

  onMouseLeave = () => {
    this.createAutoClose();
  };

  onRemoveReleaseNoteAlert = () => {
    const currentUiVersion = packageJson.version;
    const { alert, clearAlert } = this.props;
    clearAlert(alert.id);
    setLocalStorageValue('showReleaseNoteCC' + currentUiVersion, 'true');
  };

  render() {
    const { alert, clearAlert } = this.props;
    let icon = null;
    let alertClassName = 'global-alert-item d-flex align-items-center';
    let closable = true;
    let button = null;
    let messageBody = <span className={'global-alert-message'}>{alert.message}</span>;

    switch (alert.type) {
      case alertConstants.SUCCESS:
        icon = (
          <img src={CheckCircleIcon} alt={'Check Circle Icon'} className={'alert-status-icon'} />
        );
        if (alert.domElement) {
          messageBody = alert.domElement;
        }
        break;
      case alertConstants.ERROR:
        icon = (
          <img src={DangerCircleIcon} alt={'Danger Circle Icon'} className={'alert-status-icon'} />
        ); //TODO: bon, change icon
        alertClassName += ' alert-danger';
        break;
      case alertConstants.INFORMATION:
        icon = <img src={InfoWhiteIcon} alt={'Information Icon'} className={'alert-status-icon'} />;
        alertClassName += ' alert-information';
        break;
      case alertConstants.CUSTOM:
        alertClassName += ' alert-custom-action';
        messageBody = alert.domElement;
        break;
      case alertConstants.REQUIRED_ACTION:
        icon = <img src={RefreshIcon} alt={'Refresh Icon'} className={'alert-status-icon'} />;
        alertClassName += ' alert-required-action';
        closable = false;
        button = (
          <Button
            variant='primary'
            onClick={() => {
              setLocalStorageValue('showReleaseAlert', 'true');
              window.location.reload();
            }}
          >
            Refresh
          </Button>
        );
        break;
      case alertConstants.RELEASE_NOTE_ACTION:
        alertClassName += ' alert-required-action';
        closable = false;
        button = (
          <>
            <a
              href={'/release'}
              rel='noopener noreferrer'
              target={'_blank'}
              onClick={this.onRemoveReleaseNoteAlert}
            >
              Read now
            </a>
            <span className={'buttons-separator'}> | </span>
            <span className={'close-text'} onClick={this.onRemoveReleaseNoteAlert}>
              Read Later
            </span>
          </>
        );
        break;
      default:
        break;
    }

    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={alertClassName}
        key={alert.id}
      >
        {icon}
        {messageBody}
        {closable && (
          <img
            src={CloseWhiteIcon}
            alt={'Close'}
            onClick={() => {
              clearAlert(alert.id);
            }}
          />
        )}
        {button}
      </div>
    );
  }
}

export { GlobalAlertItem };

import React, { useState, useEffect } from 'react';
import BubbleCategoryChart from './BubbleCategoryChart';
import '../../Style/DarkWebDashboard.scss';
import { PageTitle } from '../../../Common/PageTitle';
import FindingsWidgetSection from './FindingsWidgetSection';
import RecentFindings from './RecentFindings';
import DarkWebService from '../../../../services/darkWeb.service';
import { EDarkWebFindingStatus, IDarkWebFindingData } from '../Types/DarkWeb.types';
import _ from 'lodash';
import { appConstants, featureIsAvailable } from '../../../../constants';
import DarkWebStaticDarkImage from '../../../../assets/DarkWeb_static_dark.svg';
import DarkWebStaticImage from '../../../../assets/DarkWebStaticImage.svg';
import { IFeatureRequested, IMediaIntroProps, ugcType } from '../../../Ugc/Types/ugc.types';
import { widgetsTitle } from '../../../SocialMedia/constants';
import { AppState } from '../../../../helpers';
import { alertActions, dashboardActions } from '../../../../actions';
import { connect } from 'react-redux';
import UgclMediaIntro from '../../../Ugc/Components/Common/UgcMediaIntro';
import DashboardService from '../../../../services/dashboard.service';
import { useAppDispatch } from '../../../../helpers/hooks';

const darkWebService = new DarkWebService();
const dashboardService = new DashboardService();

const mediaTryOutProps: IMediaIntroProps = {
  featureName: 'Dark Web',
  featureDescription:
    'Monitor underground chat forums and marketplaces for credential breaches and Pll data, CVV & cardshop forums for stolen credit card records, hacker groups for breaches and ransomeware.',
  // teaser video for dark web
  teaserVideo: (
    <div>
      <iframe
        src='https://demo.arcade.software/KUvaqbGnlZiEnDTT4uR4?embed&show_copy_link=true '
        title='Teaser | Dark Web'
        loading='lazy'
        allowFullScreen
        allow='clipboard-write'
        style={{ width: 846, height: 545, border: 'none' }}
      ></iframe>
    </div>
  ),
  staticLightImage: DarkWebStaticImage,
  staticDarkImage: DarkWebStaticDarkImage,
  featureCode: appConstants.FEATURE_CODE.DARK_WEB_V2,
};
const DarkWebDashboard = ({ user }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [featureRequested, setFeatureRequested] = useState<IFeatureRequested>();
  const { featureCode } = mediaTryOutProps;
  window.document.title = 'Dashboard | Dark Web';
  const dispatch = useAppDispatch();
  const getFeatureRequested = () => {
    setIsLoading(true);
    dashboardService
      .getFeatureRequested()
      .then(res => {
        const { productFeature } = res;
        if (productFeature?.code === featureCode) {
          setFeatureRequested(res);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err, 'err');
        setIsLoading(false);
        dispatch(alertActions.error(err || 'Error while fetching feature request'));
      });
  };

  useEffect(() => {
    if (featureIsAvailable(user, [featureCode]) === false) {
      getFeatureRequested();
    }
  }, []);
  return (
    <div className='dark-web-dashboard-container'>
      <UgclMediaIntro
        featureRequestedData={featureRequested}
        setLoading={setIsLoading}
        isLoading={isLoading}
        setFeatureRequest={setFeatureRequested}
        mediaTryOutProps={mediaTryOutProps}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};

const mapDispatchToProps = {
  getUserInfo: dashboardActions.getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DarkWebDashboard);

import React from 'react';
import TakenDown from '../../../Ugc/Components/Findings/TakenDown';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  MARKET_PLACE_TABLE_NAME,
  MARKET_PLACE_PATHNAME,
  MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
} from '../../constants';

const MarketplaceTakenDown = () => {
  return (
    <TakenDown
      type={ugcType.Marketplace}
      tableId={MARKET_PLACE_TAKEN_DOWN_TABLE_ID}
      urlColPathName={MARKET_PLACE_PATHNAME}
      tableName={MARKET_PLACE_TABLE_NAME}
    />
  );
};

export default MarketplaceTakenDown;

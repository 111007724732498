import React from 'react';
import _ from 'lodash';
import { PRICING_PLANS } from '../types';
import '../pricing.scss';
import './CaptionBanner.scss';
import { Link } from 'react-router-dom';

export type BannerProps = {
  caption: string | { text: string; link: string; isExternalLink: boolean };
  captionIcon?: { img: string; position: 'start' | 'end' };
  planName: PRICING_PLANS;
};

const renderLink = (href: string, isExternalLink: boolean, text: string) =>
  isExternalLink ? (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      {text}
    </a>
  ) : (
    <Link to={href}>{text}</Link>
  );

const CaptionBanner = ({ caption, captionIcon, planName }: BannerProps) => {
  return (
    <div className={`caption-banner-wrap ${planName}`} data-testid='caption-banner'>
      {!_.isEmpty(captionIcon) && captionIcon.position === 'start' && (
        <img src={captionIcon.img} alt={`caption-img ${captionIcon.position}`} />
      )}
      {_.isString(caption) ? (
        <span>{caption}</span>
      ) : (
        renderLink(caption.link, caption.isExternalLink, caption.text)
      )}
      {!_.isEmpty(captionIcon) && captionIcon.position === 'end' && (
        <img src={captionIcon.img} alt={`caption-img ${captionIcon.position}`} />
      )}
    </div>
  );
};
export default CaptionBanner;

import React from 'react';
import _ from 'lodash';
import './LabelAndValue.scss';
import RedirectButtonIcon from '../../assets/icons/RedirectNewTab.svg';

export interface IRedirectButtonProps {
  value: string;
  onRedirect?: () => void;
}

const RedirectButton = (props: IRedirectButtonProps) => {
  const { value, onRedirect = _.noop } = props;
  return (
    <a href={value} rel='noopener noreferrer' target='_blank'>
      <img
        src={RedirectButtonIcon}
        alt={'redirect'}
        className={'redirect-button'}
        onClick={onRedirect}
      />
    </a>
  );
};

export default RedirectButton;

import React from 'react';
import moment from 'moment';

import ModalOverlay from '../Common/ModalOverlay';
import './takedown.list.modal.scss';

export interface ITakedownListModalProps {
  show: boolean;
  url: string;
  takedowns?: any[];
  onClose: () => void;
}

const TakedownListModal = ({ show, url, takedowns, onClose }: ITakedownListModalProps) => {
  return (
    <ModalOverlay
      show={show}
      title={'Takedown Requests by Bolster'}
      onCancel={onClose}
      onSubmit={onClose}
      showButtons={false}
    >
      <div className={'takedown-list-modal'}>
        <div className={'takedown-url'}>{url}</div>
        <div className={'takedown-list'}>
          {takedowns &&
            takedowns.map(td => (
              <div className={'takedown-list-item d-flex'} key={`boster_takedown_${td.created_ts}`}>
                <span>{moment(td.updated_ts).format('DD-MMM-YYYY, h:mm a')}</span>
              </div>
            ))}
        </div>
      </div>
    </ModalOverlay>
  );
};

export default TakedownListModal;

import React from 'react';
import _ from 'lodash';
import { LabelAndValue } from '../Common/LabelAndValue';
import {
  appConstants,
  getDisplayTimeFromTimeStamp,
  getDomain,
  getNavPathnameForInsightPage,
  getSingleOrPluralForm,
} from '../../constants';
import DashboardService from '../../services/dashboard.service';
import { history } from '../../helpers';
import './insights.scss';
import { Table } from '../Common/Table/table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { PageTitleInsights } from '../Common/PageTitleInsights';
import Card from 'react-bootstrap/Card';
import { IImageProp, ImagesSlide } from '../Common/ImagesSlide';
import { WEB_DASHBOARD_PATHNAME } from '../MonitorAndTakedown/constants';

const RELATED_ASNS_COLUMNS = [
  {
    header: 'ASN',
    headerTooltip: 'Link to ASN Details View',
    accessor: 'asn',
  },
];

export interface IIP {
  ip: string;
}

interface IAsn {
  asn: string;
}

interface IInsightsState {
  isLoading: boolean;
  seen: number;
  host: string;
  lastScanned: string;
  ip: string;
  countryCode: string;
  country: string;
  networkOwner: string;
  relatedIps: IIP[];
  relatedAsns: IAsn[];
  imagePaths: IImageProp[];
  abuseContact: string;
  hostPhishCount: string;
  ipPhishCount: string;
  relatedUrls: { url: string; urlSHA256: string; timestamp: string }[];
}

interface Props {
  type: string;
  isPublic?: boolean;
  webDashboardRoute?: any;
}

class DomainInsights extends React.Component<Props, IInsightsState> {
  private readonly dashboardService: DashboardService = new DashboardService();
  private readonly domain: string = getDomain();

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      seen: 0,
      host: '',
      lastScanned: '--',
      ip: '--',
      countryCode: '',
      country: '--',
      networkOwner: '',
      relatedIps: [],
      relatedAsns: [],
      imagePaths: [],
      abuseContact: '--',
      hostPhishCount: '0',
      ipPhishCount: '0',
      relatedUrls: [],
    };
    window.document.title = 'Domain Insight | Bolster Platform';
  }

  componentDidMount() {
    const { isPublic } = this.props;
    if (isPublic) {
      this.setState({ isLoading: false });
      return;
    }
    this.dashboardService
      .getDomainIntel(this.domain)
      .then((res: any) => {
        this.setState({
          isLoading: false,
          relatedIps: _.map(res.results.relatedIPs, (value, ip) => ({ ip })),
          relatedAsns: _.map(res.results.relatedASNs, (value, asn) => ({ asn })),
          imagePaths: _.map(res.results.recentHitList, hit => ({
            name: hit.domain,
            path: hit.imageKey,
          })),
        });
      })
      .catch(err => {
        console.log(err);
      });

    this.dashboardService
      .getDomainInsight(this.domain)
      .then((res: any) => {
        const ip = _.get(res, ['result', 'lastScan', 'ip'], '--');
        const host = _.get(res, ['result', 'host'], '--');
        const urlSHA256 = _.get(res, ['result', 'lastScan', 'urlSHA256'], '--');

        this.setState({
          isLoading: false,
          seen: _.get(res, ['result', 'hits'], 0),
          host,
          lastScanned: _.get(res, ['result', 'lastScanTS'], '--'),
          ip,
          countryCode: _.get(res, ['result', 'countryCode'], ''),
          country: _.get(res, ['result', 'lastScanCountry'], '--'),
          networkOwner: _.get(res, ['result', 'networkOwner'], '--'),
        });

        this.dashboardService
          .getRelatedUrlsOnDomain(urlSHA256)
          .then((res: any) => {
            this.setState({
              relatedUrls: res?.result || [],
            });
          })
          .catch(err => {
            console.log(err);
          });

        this.dashboardService
          .getIpPhishCount(ip)
          .then((data: any) => {
            this.setState({
              ipPhishCount: data['result'].toString() || '0',
            });
          })
          .catch((err: any) => {
            console.log(JSON.stringify(err));
          });

        this.dashboardService
          .getHostPhishCount(host)
          .then((data: any) => {
            this.setState({
              hostPhishCount: _.get(data, ['result']).toString() || '0',
            });
          })
          .catch((err: any) => {
            console.log(JSON.stringify(err));
          });
      })
      .catch(err => {
        console.log(err);
      });

    this.dashboardService
      .getAbuseContact('http://' + this.domain)
      .then((res: any) => {
        this.setState({
          abuseContact: _.get(res, ['abuse_email'], '--'),
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const {
      isLoading,
      seen,
      host,
      lastScanned,
      ip,
      countryCode,
      country,
      networkOwner,
      abuseContact,
      hostPhishCount,
      ipPhishCount,
      relatedIps,
      relatedAsns,
      imagePaths,
      relatedUrls,
    } = this.state;
    const countryClassName = 'country-icon flag-icon flag-icon-' + countryCode.toLowerCase();
    const localState: any = localStorage.getItem('whoisState');
    const linkState = localState && JSON.parse(localState);

    const RELATED_IPS_COLUMNS = [
      {
        header: 'IP Address',
        headerTooltip: 'Link to IP Details View',
        accessor: 'ip',
        type: 'ipv4',
        render: (data: any) => {
          const { ip } = data;
          if (_.isEmpty(ip) || ip === '0.0.0.0') {
            return '--';
          }
          return (
            <Link
              to={`${
                this.props.webDashboardRoute ? this.props.webDashboardRoute : `/${this.props.type}`
              }/ip/${ip}`}
            >
              {ip}
            </Link>
          );
        },
      },
    ];
    const SIMILAR_URLS_COLUMNS = [
      {
        header: 'URLs hosted on the same domain',
        accessor: 'url',
        isLongText: true,
        render: (data: any) => {
          const { url, timestamp, urlSHA256 } = data;
          if (_.isEmpty(url)) {
            return '--';
          }
          return (
            <Link
              to={`${
                this.props.webDashboardRoute ? this.props.webDashboardRoute : `/${this.props.type}`
              }/insights/${timestamp}/${urlSHA256}`}
            >
              {url}
            </Link>
          );
        },
      },
    ];

    return (
      <div>
        <PageTitleInsights
          title={'Domain threat intel - ' + this.domain}
          backFn={() => {
            history.go(-2);
          }}
          tools={
            linkState &&
            linkState?.type &&
            linkState?.type === appConstants.CONTENT_TYPE.APP_STORE && (
              <Link
                to={{
                  pathname: `${linkState?.type}/insights/whois`,
                  state: { domain: this.domain },
                }}
                className='btn btn-light whois-info-button'
              >
                VIEW WHOIS INFORMATION
              </Link>
            )
          }
        />
        {isLoading ? (
          <div className={'insight-spinner-container'}>
            <Spinner animation='border' />
          </div>
        ) : (
          <div className={'page-content insights-container'}>
            <Row>
              <Col lg={6}>
                <div className='card-container'>
                  <Card className='card-between'>
                    <Card.Header>Domain Details</Card.Header>
                    <Card.Body>
                      <div className={'row'}>
                        <div className={'col-12 col-sm-6'}>
                          <LabelAndValue
                            label={'Seen'}
                            value={`${seen} ${getSingleOrPluralForm(seen, 'time')} in last 90 days`}
                            direction={'column'}
                          />
                          <LabelAndValue label={'Host'} value={host} direction={'column'} />
                          <LabelAndValue
                            label={'Last Scanned'}
                            value={
                              lastScanned === '--'
                                ? lastScanned
                                : getDisplayTimeFromTimeStamp(lastScanned)
                            }
                            direction={'column'}
                          />
                          <LabelAndValue label={'IP'} value={ip} direction={'column'} />
                          <LabelAndValue
                            label={'Country'}
                            renderDom={
                              <div className='dom-value'>
                                {countryCode && <span className={countryClassName} />}
                                <span className='card-value card-text long-text-ellipsis-1'>
                                  {country}
                                </span>
                              </div>
                            }
                            direction={'column'}
                          />
                        </div>
                        <div className={'col-12 col-sm-6'}>
                          <LabelAndValue
                            label={'Network Owner'}
                            value={networkOwner}
                            direction={'column'}
                          />
                          <LabelAndValue
                            label={'Abuse Contact'}
                            tooltip={
                              'contact of the hosting provider for reporting brand infringement and requesting takedowns'
                            }
                            value={abuseContact}
                            direction={'column'}
                          />
                          <LabelAndValue
                            label={'Host Phish Count'}
                            tooltip={
                              'Number of phishing and other scams hosted on this host in last 30 days'
                            }
                            value={hostPhishCount}
                            direction={'column'}
                          />
                          <LabelAndValue
                            label={'IP Phish Count'}
                            value={ipPhishCount}
                            tooltip={
                              'Number of phishing and other scams hosted on this IP address in last 30 days'
                            }
                            direction={'column'}
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <Card bg='light' className='card-between'>
                  <Table
                    tableClassName='tool-bar-container-padding'
                    disableDatePicker={true}
                    disableFilter={true}
                    data={relatedUrls}
                    title={'Similar URLs'}
                    columns={SIMILAR_URLS_COLUMNS}
                  />
                </Card>
                <Card bg='light' className='card-between'>
                  <Table
                    tableClassName='tool-bar-container-padding'
                    disableDatePicker={true}
                    disableFilter={true}
                    data={relatedIps}
                    title={'Related IPs'}
                    columns={RELATED_IPS_COLUMNS}
                  />
                </Card>
              </Col>
              <Col lg={6}>
                {imagePaths.length > 0 && (
                  <Card bg='light' className='card-between images-slide-card'>
                    <Card.Body>
                      <ImagesSlide images={imagePaths} />
                    </Card.Body>
                  </Card>
                )}
                <Card bg='light' className='card-between'>
                  <Table
                    tableClassName='tool-bar-container-padding'
                    disableDatePicker={true}
                    disableFilter={true}
                    data={relatedAsns}
                    title={'Related ASNs'}
                    columns={RELATED_ASNS_COLUMNS}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export { DomainInsights };

import React from 'react';
import { ISubBarItem } from '../../../../InsightsContainer/Common/SubNavigationBar';
import {
  appConstants,
  featureIsAvailable,
  getNavPathnameForInsightPage,
} from '../../../../../constants';
import { EMenuType } from '../../../../Drawer/RouteOptions';
import { useAppSelector } from '../../../../../helpers/hooks';

export function useGetSubNavigationTab(sha256: string, moduleType: string) {
  const user = useAppSelector(state => state.dashboardReducer.user);

  const subBarItems: ISubBarItem[] = [
    {
      href: `${getNavPathnameForInsightPage()}/insights/${sha256}`,
      label: 'Insights',
      type: EMenuType.MENU_HEADER,
      id: '/insights',
      disabled: false,
    },
    ...(featureIsAvailable(user, [appConstants.FEATURE_CODE.USER_GLOBAL_TAKEDOWN]) ||
    moduleType === appConstants.CONTENT_TYPE.APP_STORE
      ? [
          {
            href: `${getNavPathnameForInsightPage()}/insights/timeline/${sha256}`,
            label: 'TimeLine',
            type: EMenuType.MENU_HEADER,
            id: '/timeline',
            disabled: false,
          },
        ]
      : []),

    {
      href: `${getNavPathnameForInsightPage()}/insights/dom/${sha256}`,
      label: 'Dom Tree',
      type: EMenuType.MENU_HEADER,
      id: '/dom',
      disabled: false,
    },
    ...(moduleType === appConstants.CONTENT_TYPE.APP_STORE
      ? [
          {
            href: `${getNavPathnameForInsightPage()}/insights/whois/${sha256}`,
            label: 'Whois Info',
            type: EMenuType.MENU_HEADER,
            id: '/whois',
            disabled: false,
          },
        ]
      : []),
  ];
  return { subBarItems };
}

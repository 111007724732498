import React from 'react';
import { SearchTerms } from '../../../Ugc/Components/SearchTerms/SearchTerms';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import { SOCIAL_MEDIA_TABLE_NAME } from '../../constants';

const SocialMediaSearchTerm = () => {
  return <SearchTerms type={ugcType.Social} tableName={SOCIAL_MEDIA_TABLE_NAME} />;
};

export default SocialMediaSearchTerm;

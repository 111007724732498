import React, { useContext } from 'react';
import { Image, Button } from 'react-bootstrap';
import LockIconLight from '../../../assets/icons/TypoLockBlack.svg';
import LockIconDark from '../../../assets/icons/TypoLockLight.svg';
import ThemeContext from '../../../context/ThemeContext';
import { ThemeModes } from '../../../constants';
import _ from 'lodash';
import CheckPhishContactUsContext from '../../../context/CheckPhishContactUsContext';
import './LockBanner.scss';
import { PRICING_PAGE_PATH } from '../../Pricing/constants';
import { history } from '../../../helpers';

type Props = {
  showLockedIcon: boolean;
  heading: React.ReactNode;
  subHeading: React.ReactNode;
  upgradeBtnText: string;
};

const LockBanner = ({ showLockedIcon, heading, subHeading, upgradeBtnText }: Props) => {
  const { selectedTheme } = useContext(ThemeContext);
  const { setShowContactUsV2, setTriggerContactUsModal } = useContext(CheckPhishContactUsContext);
  const lockIcon = _.isEqual(selectedTheme, ThemeModes.DARK.toLocaleLowerCase())
    ? LockIconDark
    : LockIconLight;

  const onUpgrade = () => {
    history.push(PRICING_PAGE_PATH);
  };

  return (
    <div className='lock-banner-wrap' data-testid='lock-banner-wrap'>
      <div className='banner'>
        {showLockedIcon && (
          <div className='lock-icon'>
            <Image src={lockIcon} alt='locked' />
          </div>
        )}
        <div className='content-wrap'>
          <div className='heading'>{heading}</div>
          <div data-testid='sub-heading' className='sub-heading'>
            {subHeading}
          </div>
        </div>
        <div className='upgrade-wrap'>
          <Button className={'upgrade-btn'} onClick={onUpgrade}>
            {upgradeBtnText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LockBanner;

import _ from 'lodash';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { alertActions } from '../../actions';
import { getNameFromUrlPath, getKeyFromUrlPath, isBrandReadOnlyUser } from '../../constants';
import { AppState } from '../../helpers';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { ITableApiColumn, TableApi } from '../Common/Table/table.api';
import { TableContextProvider } from '../Common/Table/table.context';
import AddBrandProperty from './Common/AddBrandProperty';
import { IPropteryTableProps, EPropertyTypeLabel } from './PropertyTypes';
import './Style/BrandPropertyStyle.scss';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { addNewBrandProperty, downloadPdfAssets } from './brand-property-requests';
import { setShouldTriggerAgGrigRefresh } from '../../reducers/table.reducer';
import { useReadOnlyUser } from '../../basic-hooks/useUserRoles';

type Props = IPropteryTableProps;

const BrandPropertyComponent = (props: Props) => {
  const [selectProptertyType, setSelectProptertyType] = useState<any>({});
  const [showAddPropertyModal, setShowAddPropertyModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { id, columns, tableName, disableDatePicker, ugcType, defaultColumnIds } = props;
  const brandPropertyTypes = useAppSelector(state => state.appReducer.brandPropertyTypes);

  const propertyId = _.find(brandPropertyTypes, ['value', props.type])?.id;

  const ugcPlatforms = useAppSelector((state: AppState) => {
    if (ugcType) {
      return state.appReducer.contents?.[ugcType]?.platforms;
    }
    return [];
  });

  useEffect(() => {
    if (brandPropertyTypes.length) {
      const property = _.find(brandPropertyTypes, ['value', props.type]);
      setSelectProptertyType(property);
    }
  }, [brandPropertyTypes, props.type]);

  const onCancel = () => {
    setShowAddPropertyModal(false);
  };

  const onSubmit = useCallback(
    async (newPayload: any) => {
      try {
        const response = await addNewBrandProperty(newPayload);
        console.log(response);
        dispatch(
          alertActions.success(
            `Congratulations! Your ${selectProptertyType.label} property has successfully added.`,
          ),
        );
      } catch (error) {
        dispatch(alertActions.error(error as string));
      } finally {
        setShowAddPropertyModal(false);
        dispatch(setShouldTriggerAgGrigRefresh(true));
      }
    },
    [dispatch, selectProptertyType.label],
  );

  const customTools = [];
  const isReadOnlyUser = useReadOnlyUser();
  if (!isReadOnlyUser)
    customTools.push(
      <Button
        key='add-logo'
        className='ml-3'
        variant='primary'
        onClick={() => setShowAddPropertyModal(true)}
      >
        Add {EPropertyTypeLabel[selectProptertyType.value]}
      </Button>,
    );

  const modifyCols = (columns: ITableApiColumn[]) => {
    const downloadPdf = async (event: any) => {
      try {
        const response = await downloadPdfAssets({ documentName: event.target.id });
        if (response && response.result) {
          dispatch(
            alertActions.success(
              `Your ${selectProptertyType.label} property has downloaded successfully.`,
            ),
          );
        } else {
          dispatch(alertActions.success('File download failed. Please contact'));
        }
      } catch (error) {
        dispatch(alertActions.error(error as string));
      }
    };

    const documentCol = _.find(columns, ['id', 'documents']);
    if (documentCol) {
      documentCol.render = ({ valueJson }) => {
        if (!valueJson.path) return '--';
        return (
          <a
            className={'document-link'}
            onClick={downloadPdf}
            id={getKeyFromUrlPath(valueJson.path)}
          >
            {getNameFromUrlPath(valueJson.path)}
          </a>
        );
      };
    }

    const platformColumn = _.find(columns, ['id', 'platform']);
    if (platformColumn) {
      platformColumn.filterOptions = _.map(ugcPlatforms, p => ({
        label: p.label,
        value: p.docValue,
      }));
    }
  };

  return (
    <AuthenticationWrapper>
      <div className={'table-page dashboard-page page-content brand-property-container'}>
        <Card className='table-container'>
          <TableContextProvider
            columns={columns}
            dashboardName={tableName}
            tableId={id}
            modifyColumns={modifyCols}
          >
            <TableApi
              {...props}
              columns={columns}
              indexBy={'url'}
              tableIndex={'brand-assets'}
              fetchApi={_.noop}
              disableDatePicker={disableDatePicker}
              customTools={customTools}
              defaultColumnIds={defaultColumnIds}
              brandPropertyId={propertyId}
            />
          </TableContextProvider>
        </Card>
      </div>
      {showAddPropertyModal && (
        <AddBrandProperty
          show={showAddPropertyModal}
          onCloseModal={onCancel}
          onSubmitModal={onSubmit}
          brandPropertyTypes={brandPropertyTypes}
          type={selectProptertyType}
          ugcPlatforms={ugcPlatforms}
        />
      )}
    </AuthenticationWrapper>
  );
};

export default BrandPropertyComponent;

import React, { Component } from 'react';
import './auth.scss';
import { Link } from 'react-router-dom';
import { CompanyIcon } from '../Common/CompanyIcon';
import UserService from '../../services/user.service';
import BackIcon from '../../assets/icons/Back.svg';
import { backendHost } from '../../services/serviceWorker';
import moment from 'moment';
import { setLocalStorageValue } from '../../constants';

interface IUserLoginSsoProps {}

interface IUserLoginSsoState {
  email: string;
  errMsg: string;
  submitted: boolean;
  isLoading: boolean;
}

class LoginSsoPage extends Component<IUserLoginSsoProps, IUserLoginSsoState> {
  constructor(props: IUserLoginSsoProps) {
    super(props);
    this.state = {
      email: '',
      errMsg: '',
      submitted: false,
      isLoading: false,
    };
    window.document.title = 'Login with SSO | Bolster Platform';
  }

  handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const userService = new UserService();
    const form = event.currentTarget;
    const elements = form.elements;
    const email = elements.email.value;
    userService
      .validateUser({
        email,
      })
      .then(res => {
        if (res && res.isValid) {
          setLocalStorageValue('sessionExpiration', res.expired);
          setLocalStorageValue('sessionInActiveLimit', res.inActiveLimit);
          setLocalStorageValue('userLatestActivity', moment().valueOf());
          const redirectATag: any = document.getElementById('redirectATag');
          redirectATag.href = backendHost + res.loginURI;
          redirectATag.click();
        } else {
          this.setState({ isLoading: false, errMsg: `User ${email} not found.` });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false, errMsg: JSON.stringify(err) });
      });
  };

  render() {
    const { errMsg, isLoading } = this.state;

    return (
      <div>
        <div className={'auth-title '}>
          <CompanyIcon withName />
        </div>
        <div className={'auth-page-wrapper'}>
          <form name='form' onSubmit={this.handleSubmit}>
            <div className={'d-flex'}>
              <Link to={'/sign-in'}>
                <img className={'cursor-pointer pr-3'} src={BackIcon} alt={'companyIcon'} />
              </Link>
              <h3>Login with SSO</h3>
            </div>
            {errMsg && (
              <div className={'login-error'}>
                <div className={'help-block'}>{errMsg}</div>
              </div>
            )}
            <div>
              <div className={'form-group sign-up-form-component'}>
                <label className={'label'}>Email</label>
                <input
                  required
                  type='email'
                  className={'form-control'}
                  placeholder='Enter email'
                  name='email'
                />
              </div>
            </div>

            <div className={'bottom-component'}>
              <div />
              <div className='form-group'>
                <button type='submit' className={'btn btn-primary' + (isLoading ? 'disabled' : '')}>
                  {isLoading ? (
                    <img
                      alt='waiting icon'
                      src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                    />
                  ) : (
                    'Submit'
                  )}
                </button>
                <a
                  id='redirectATag'
                  href={backendHost + '/platform-api/v1/sso/validate'}
                  className='d-none'
                >
                  redirect
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export { LoginSsoPage };

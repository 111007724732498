import { UserResetPassDto } from './types';

export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  ACCEPT_TERMS_AND_CONDITION_SUCCESS: 'USERS_ACCEPT_TERMS_AND_CONDITION_SUCCESS',
  ACCEPT_TERMS_AND_CONDITION_FAILURE: 'USERS_ACCEPT_TERMS_AND_CONDITION_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  VERIFY_SUCCESS: 'USERS_VERIFY_SUCCESS',
  VERIFY_FAILURE: 'USERS_VERIFY_FAILURE',

  FORGOTPASS_SUCCESS: 'USERS_FORGOTPASS_SUCCESS',
  FORGOTPASS_FAILURE: 'USERS_FORGOTPASS_FAILURE',

  RESETPASS_SUCCESS: 'USERS_RESETPASS_SUCCESS',
  RESETPASS_FAILURE: 'USERS_RESETPASS_FAILURE',

  CONFIRM_REGISTRATION_SUCCESS: 'USERS_CONFIRM_REGISTRATION_SUCCESS',
  CONFIRM_REGISTRATION_FAILURE: 'USERS_CONFIRM_REGISTRATION_FAILURE',
};

export interface RegisterRequest {
  type: typeof userConstants.REGISTER_REQUEST;
}

export interface RegisterSuccess {
  type: typeof userConstants.REGISTER_SUCCESS;
}

export interface RegisterFailure {
  type: typeof userConstants.REGISTER_FAILURE;
  error: string;
}

export interface LoginRequest {
  type: typeof userConstants.LOGIN_REQUEST;
}

export interface LoginSuccess {
  type: typeof userConstants.LOGIN_SUCCESS;
}

export interface LoginFailure {
  type: typeof userConstants.LOGIN_FAILURE;
  error: string;
}

export interface Logout {
  type: typeof userConstants.LOGOUT;
}

export interface VerifySuccess {
  type: typeof userConstants.VERIFY_SUCCESS;
  token: string;
}

export interface VerifyFailure {
  type: typeof userConstants.VERIFY_FAILURE;
  token: string;
}

export interface ForgotPassSuccess {
  type: typeof userConstants.FORGOTPASS_SUCCESS;
  email: string;
}

export interface ForgotPassFailure {
  type: typeof userConstants.FORGOTPASS_FAILURE;
  email: string;
}

export interface ResetPassSuccess {
  type: typeof userConstants.RESETPASS_SUCCESS;
  user: UserResetPassDto;
  token: string;
}

export interface ResetPassFailure {
  type: typeof userConstants.RESETPASS_FAILURE;
  user: UserResetPassDto;
  token: string;
}

export enum UserAppSettings {
  Theme = 'theme',
  LadingPage = 'landing_page',
  GroupFindings = 'group_findings',
  TeamView = 'team_view',
}
export enum ThemeModes {
  DARK = 'Dark',
  LIGHT = 'Light',
  AUTO = 'Auto',
}

export type UserActionsTypes =
  | RegisterRequest
  | RegisterSuccess
  | RegisterFailure
  | LoginRequest
  | LoginSuccess
  | LoginFailure
  | Logout
  | VerifySuccess
  | VerifyFailure
  | ForgotPassSuccess
  | ForgotPassFailure
  | ResetPassSuccess
  | ResetPassFailure;

export enum IReasonDropdownValues {
  UPGRADE = 'upgrade',
  TAKEDOWN = 'domain_takedown',
  ACQUISITION = 'domain_acquisition',
  SUPPORT = 'support',
}

import { PlatformModule } from './types';
import { IRunByClientAppType } from './util';
export enum ClientAppType {
  Bolster = 'PLATFORM',
  CheckPhish = 'CHECKPHISH',
}

// determine Bolster App Type
const getClientAppType = (): ClientAppType => {
  let clientAppType;
  const { REACT_APP_BOLSTER_APP_TYPE: envAppType } = process.env;

  // return if valid
  if (Object.values(ClientAppType).includes(envAppType as ClientAppType)) {
    clientAppType = envAppType as ClientAppType;
  }

  if (!clientAppType) {
    // check if current domain is ended with bolster.ai
    const { hostname } = window.location;
    if (hostname.match(/bolster.ai$/gi) != null) {
      clientAppType = ClientAppType.Bolster;
    }
  }

  // default to CHECKPHISH
  return clientAppType || ClientAppType.CheckPhish;
};

export const appConstants = {
  PRODUCT_CODE: '4b69317c',
  FEATURE_CODE: {
    WEB: '92bd400d',
    SOCIAL_MEDIA: '6f9182ba',
    MARKETPLACE: 'fece96a3',
    TYPOSQUATTING_SCORE: 'hfgmfz5n',
    APP_STORE: 'ccf8ff81',
    LOCATION_BASED: 'd5abcf2b',
    DARK_WEB: 'f4c33e93',
    DARK_WEB_V2: '9b36cf1b',
    GROUP_FINDINGS: 'eccbb50c', //Todo: Can be used for ZION-4929
    APP_STORE_PLAYBOOK: 'bb632d97',
    SOCIAL_PLAYBOOK: 'f1281425',
    WEB_SOC_TAKEDOWN: '6e073383',
    SOLO_TEAM: 'f25835af',
    TEAM_SCAN: '80dc5cd1',
    WEB_NEW_TIMELINE: '1e97c343',
    CROSS_PAGE: 'd1d3cd1f',
    REPORTING: 'b2d85fc2',
    NEW_WEB_DASHBOARD: 'a68c7055',
    ENABLE_TYPOSQUATTING_CHECKPHISH: '5ad6a27',
    INTERNAL_PLAYBOOK_ACCESS: 'fac65202',
    NEW_USER_SESSION: 'ef2d2b0d',
    TYPOSQUATTING_CHECKPHISH_DASHBOARD_V2: '5bd0a85',
    NOTIFICATION_SETTINGS: 'dfa835ab',
    AG_GRID_TABLE: '9af41a78',
    PERSISTENT_FILTERS: 'cdff4058',
    CUSTOMER_GENERATED_TAGS: 'f1357c56',
    USER_GLOBAL_TAKEDOWN: 'e1ff5bc9',
    ONE_YEAR_FINDINGS: 'dc1dc183',
    SEARCH_TERM_RE_SCAN: '05f999cf',
    // disabled code for the main modules, will override the enabled code if both are true
    WEB_DISABLED: '56783eec',
    SOCIAL_DISABLED: 'd773a6b1',
    APP_STORE_DISABLED: '0ddd787b',
    DARK_WEB_DISABLED: '7badd21e',
    // read only user per module feature code
    READ_ONLY_USER_PER_MODULE: 'c77eddd6',
    // M365 integration feature code
    M365_INTEGRATION: 'a2e9f2e5',
  },
  GET_ENTERPRISE_APP_CONFIG: 'GET_ENTERPRISE_APP_CONFIG',
  GET_CHECKPHISH_APP_CONFIG: 'GET_CHECKPHISH_APP_CONFIG',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_ATTRIBUTES: 'GET_ATTRIBUTES',
  SET_PRICING_POPUP: 'SET_PRICING_POPUP',
  UGC_CONTENT_TYPE: {
    SOCIAL: 'social',
    MARKETPLACE: 'marketplace',
    APP_STORE: 'app-store',
    DARK_WEB: 'dark-web',
  },
  CONTENT_TYPE: {
    SOCIAL: 'social',
    MARKETPLACE: 'marketplace',
    APP_STORE: 'app-store',
    DARK_WEB: 'dark-web',
    WEB: 'web',
    BULK_SCAN: 'bulk_scan',
    PLAYBOOK: 'playbook',
    SOCIAL_PLAYBOOK: 'social_playbook',
    CHECK_PHISH: 'checkphish',
    REPORT: 'report',
    TEAM_MEMBERS: 'team_members',
  },
  CLIENT_APP_TYPE: getClientAppType(),
};

export interface IAppDispatchProps {
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
}

export const PlatformMainModules: PlatformModule[] = [
  {
    name: 'Web',
    enabledCode: appConstants.FEATURE_CODE.WEB,
    disabledCode: appConstants.FEATURE_CODE.WEB_DISABLED,
  },
  {
    name: 'Social Media',
    enabledCode: appConstants.FEATURE_CODE.SOCIAL_MEDIA,
    disabledCode: appConstants.FEATURE_CODE.SOCIAL_DISABLED,
  },
  {
    name: 'App Store',
    enabledCode: appConstants.FEATURE_CODE.APP_STORE,
    disabledCode: appConstants.FEATURE_CODE.APP_STORE_DISABLED,
  },
  {
    name: 'Dark Web',
    enabledCode: appConstants.FEATURE_CODE.DARK_WEB_V2,
    disabledCode: appConstants.FEATURE_CODE.DARK_WEB_DISABLED,
  },
];

// map the feature code to the disabled code
export const MainModuleDisabledCodeMap = {
  [appConstants.FEATURE_CODE.WEB]: appConstants.FEATURE_CODE.WEB_DISABLED,
  [appConstants.FEATURE_CODE.SOCIAL_MEDIA]: appConstants.FEATURE_CODE.SOCIAL_DISABLED,
  [appConstants.FEATURE_CODE.APP_STORE]: appConstants.FEATURE_CODE.APP_STORE_DISABLED,
  [appConstants.FEATURE_CODE.DARK_WEB_V2]: appConstants.FEATURE_CODE.DARK_WEB_DISABLED,
};

export const platformUserRolesMap: {
  [key: string]: string;
} = {
  super_admin: 'Admin',
  brand_admin: 'Admin',
  brand_user: 'User',
  brand_read_only_user: 'Read-Only',
  brand_module_admin: 'Module Admin',
};

export const integrationDocsSufixMap: {
  [key: string]: string;
} = {
  Splunk: 'splunk-integration',
  'Sumo Logic': 'sumo-logic',
  Tines: 'tines-integration',
  'Cortex XSOAR': 'xsoar-integration',
  ThreatConnect: 'threatconnect-integration',
  'Microsoft Sentinel': 'microsoft-sentinal-integration',
  Slack: 'slack-integration',
  'Microsoft Teams': 'microsoft-teams-integration',
  'Microsoft 365 Exchange/Defender': 'microsoft-365-exchange-defender-integration',
  'API Integration': 'api-integration',
};

export const simpliedIntegrationNameMap: {
  [key: string]: string;
} = {
  'API Integration': 'API',
  'Microsoft 365 Exchange/Defender': 'Exchange',
};

import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import './Upgrade.scss';
import { dashboardActions } from '../../actions';
import { AppState } from '../../helpers';
import { UpgradeStripeV2 } from './UpgradeStripe';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';

interface IUpgradeV1Props {
  className: string;
}
interface IUpgradeV2Props extends IUpgradeV1Props {
  showPricingPopup: boolean;
  getUserInfo: () => any;
  liveScanSubscriptionPlan: any;
}

const UpgradeV1: FunctionComponent<IUpgradeV1Props> = ({
  className = '',
}) => {
  const { setShowContactUsV2, setTriggerContactUsModal } = useContext(CheckPhishContactUsContext);

  const showContactUsV2 = () => {
    setShowContactUsV2(true);
    setTriggerContactUsModal(true);
  };


  return (
    <div className={'upgrade-button-component ' + className}>
      <Button variant='primary' className='btn' onClick={showContactUsV2}>
        Upgrade
      </Button>
    </div>
  );
};

const UpgradeV2: FunctionComponent<IUpgradeV2Props> = ({
  className = '',
  getUserInfo,
  showPricingPopup,
  liveScanSubscriptionPlan,
}) => {
  const [info, setInfo] = useState(() => {});

  const showForm = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'upgradeClicked',
      category: 'Page',
      action: 'clicked',
      label: 'upgradeClicked',
    });

    setInfo(() => (
      <UpgradeStripeV2
        liveScanSubscriptionPlan={liveScanSubscriptionPlan}
        successCallback={onSuccess}
        onHide={hideForm}
        className={'dashboard-upgrade-button'}
      />
    ));
  };

  useEffect(() => {
    // Show Upgrade Modal in dashboard if selections were made previously on plans page
    // And, clear the flag
    if (liveScanSubscriptionPlan === undefined && showPricingPopup === true) {
      setInfo(() => (
        <UpgradeStripeV2
          liveScanSubscriptionPlan={liveScanSubscriptionPlan}
          successCallback={onSuccess}
          onHide={hideForm}
          className={'dashboard-upgrade-button'}
        />
      ));
    }
  }, [showPricingPopup]);

  const hideForm = () => {
    setInfo(() => {});
  };

  const onSuccess = () => {
    getUserInfo();
  };

  return (
    <div className={'upgrade-button-component ' + className}>
      <Button variant='primary' className='btn' data-testid='upgrade-v2-btn' onClick={showForm}>
          Upgrade
        </Button>
      {info}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { showPricingPopup } = state.appReducer;
  return {
    showPricingPopup,
  };
};

const mapDispatchToProps = { getUserInfo: dashboardActions.getUserInfo };

const connectedUpgrade = connect(mapStateToProps, mapDispatchToProps)(UpgradeV2);

export { UpgradeV1, connectedUpgrade as UpgradeV2 };

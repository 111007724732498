import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Priority.scss';
import TrendingUpIcon from '../../assets/icons/TrendingUp.svg';
import TrendingDownIcon from '../../assets/icons/TrendingDown.svg';
import { Placement } from 'react-bootstrap/Overlay';

interface IComponentProps {
  priority: number;
  tooltipPlacement?: Placement;
}

export const Priority = ({ priority, tooltipPlacement = 'top' }: IComponentProps): any => {
  let priorityClassName = 'priority-value ';
  let tooltipPriority = 'Very high';
  switch (priority) {
    case 1:
      priorityClassName += 'severity-critical';
      tooltipPriority = 'Very high';
      break;
    case 2:
      priorityClassName += 'severity-high';
      tooltipPriority = 'High';
      break;
    case 3:
      priorityClassName += 'severity-moderate';
      tooltipPriority = 'Moderate';
      break;
    case 4:
      priorityClassName += 'severity-low';
      tooltipPriority = 'Low';
      break;
    case 5:
      priorityClassName += 'severity-ver-low';
      tooltipPriority = 'Very Low';
      break;
    default:
      priorityClassName += 'severity-critical';
      break;
  }

  return (
    <div className={'priority-component'}>
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={
          <Tooltip id={'tooltip-' + Math.random()} className={'priority-tooltip'}>
            <div>
              Priority: <span className={'high-light'}>{tooltipPriority}</span>
            </div>
          </Tooltip>
        }
      >
        <div className={'table-source-url-column d-flex align-items-center'}>
          <div className={priorityClassName}>{`P${priority}`}</div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

import React from 'react';
import './ToggleSwitch.scss';
import _ from 'lodash';
import LockImage from './LockImage';

export interface ToggleSwitchItems {
  label: string;
  value: any;
  isLocked?: boolean;
  additionalText?: string;
}

export interface ToggleSwitchProps {
  items: ToggleSwitchItems[];
  selected: any;
  onClick?: (value: any) => void;
  customClassName?: string;
  disabled?: boolean;
}

const ToggleSwitch = ({
  items,
  selected,
  onClick,
  customClassName = '',
  disabled,
}: ToggleSwitchProps) => {
  const toggleClass = _.isEmpty(customClassName)
    ? `toggle-switch`
    : `${customClassName} toggle-switch`;

  return (
    <div className={toggleClass} data-testid='toggle-switch'>
      {items.map(item => (
        <div
          className={`toggle-switch-item ${selected === item.value && ' selected'} ${
            item.isLocked ? 'lock-disabled disabled' : ''
          }`}
          onClick={() => !disabled && onClick && onClick(item.value)}
          key={`toggle_switch_${item.value}`}
        >
          {item.label}
          {!_.isEmpty(item.additionalText) && (
            <span className='additional-text'>{item.additionalText || ''}</span>
          )}
          {(item.isLocked || (disabled && selected !== item.value)) && <LockImage />}
        </div>
      ))}
    </div>
  );
};

export { ToggleSwitch };

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  IChartPlaceholderProps,
  ILineChartDataProps,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import LineChart from '../../../Common/DashboardWidgets/LineChart';
import NoDetections from '../../../../assets/icons/NoDetections.svg';
import UgcService from '../../../../services/ugc.service';
import { convertLineChartData } from '../../../Common/DashboardWidgets/utils';
import { socialMediaFeildCreated_TsCountMockData } from '../../../../constants';

const ugcService = new UgcService();

const placeholder: IChartPlaceholderProps = {
  icon: NoDetections,
  title: 'No Detection found',
  description:
    'We haven’t detected any phish and scam sites for this period. Please select another time frame.',
};

const tooltipFormatter = (name: string): string => {
  if (name.indexOf('Total') !== -1) {
    return 'Total';
  } else if (name.indexOf('Live') !== -1) {
    return 'Live';
  } else if (name.indexOf('Down') !== -1) {
    return 'Taken Down';
  }
  return '';
};

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  type: string;
  widgetsTitle: any;
  isCustomCalendarRange: boolean;
}

enum FindingStatusLabel {
  LIVE = 'live',
  DOWN = 'down',
}

function UgcDetection({
  startDate,
  endDate,
  type,
  widgetsTitle,
  isCustomCalendarRange,
}: IComponentProps) {
  const dateFormat = 'YYYY-MM-DD';
  const { title, tooltipDescription } = widgetsTitle;
  const isDemo = window.location.pathname.includes('premium') || false;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ILineChartDataProps[]>([]);

  const setWidgetData = (res: any) => {
    const formatResponse = {};
    res.counts.forEach((count: any) => {
      formatResponse[count.label] = { live: 0, down: 0 };
      count.status.forEach((status: any) => {
        formatResponse[count.label][FindingStatusLabel[status.label]] += status.count;
      });
    });
    if (_.isEmpty(formatResponse)) {
      setData([]);
      setIsLoading(false);
      return;
    }
    setData(convertLineChartData(formatResponse, lineChartProperties));
    setIsLoading(false);
  };

  const lineChartProperties = {
    startDate,
    endDate,
    totalLabel: 'Total',
    liveLabel: 'Live',
    takedownLabel: 'Taken Down',
  };

  const getDetectionData = () => {
    setIsLoading(true);
    const query = {
      field: 'created_ts',
      subfield: 'status',
      startDate: startDate,
      endDate: endDate,
      isCustomCalendarRange: isCustomCalendarRange,
    };
    isDemo && type === 'social'
      ? setWidgetData(socialMediaFeildCreated_TsCountMockData)
      : ugcService
          .getDetectionsByFieldAndSubField(query, type)
          .then((res: any) => {
            setWidgetData(res);
          })
          .catch(error => {
            console.log(error);
          });
  };

  useEffect(() => {
    getDetectionData();
  }, [startDate, endDate]);

  return (
    <LineChart
      title={title}
      isLoading={isLoading}
      description={tooltipDescription}
      data={data}
      placeholder={placeholder}
      yAxisTitle='Detections'
      tooltipFormatter={tooltipFormatter}
    />
  );
}

export { UgcDetection };

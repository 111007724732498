import * as React from 'react';
import './typosquat.scss';

export interface INoFindingsProps {}

export function NoFindings(props: INoFindingsProps) {
  return (
    <div className='no-finding-wrap'>
      <h3 className='main-head'>No Findings Yet</h3>
      <p className='sub-text'>Make sure you have Notifications settings selected</p>
      <p className='sub-text'>For when new findings detected.</p>
    </div>
  );
}

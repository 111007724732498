import { ITableApiColumn } from '../Common/Table/table.api';

export const CONNECTOR_COL_NAME: ITableApiColumn = {
  header: 'Name',
  accessor: 'name',
  id: 'name',
  agFilterType: 'agTextColumnFilter',
};

export const CONNECTOR_COL_TYPE: ITableApiColumn = {
  header: 'Type',
  accessor: 'type',
  type: 'options',
  filterOptions: [
    {
      label: 'API',
      value: 'API',
    },
    {
      label: 'Email',
      value: 'Email',
    },
  ],
  id: 'type',
  agFilterType: 'agTextColumnFilter',
};

export const CONNECTOR_COL_CREATED_AT: ITableApiColumn = {
  header: 'Created Date',
  accessor: 'createdAt',
  id: 'createdAt',
};

export const CONNECTOR_COL_CREATED_BY: ITableApiColumn = {
  header: 'Created By',
  accessor: 'createdBy',
  id: 'createdBy',
  agFilterType: 'agTextColumnFilter',
};

export const CONNECTOR_COL_DETAILS: ITableApiColumn = {
  header: 'Actions',
  accessor: 'actionColumn',
  columnClassName: 'action-column',
  id: 'actionColumn',
  filterDisabled: true,
  sortDisabled: true,
};

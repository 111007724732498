import {
  INotificationConfigs,
  IUpdateNotificationPayload,
  IUserNotificationPreferences,
} from '../components/Profile/CommunicationSettings/CommunicationSettings.types';
import { handleResponse, setApiUrl } from './serviceWorker';
export interface IUpdateNotificationPreference {
  module_name: string;
  actions: any;
}

export interface NotificationServicesInterface {
  setNotificationTyposquatNotificationPreferences(
    updatedSettings: IUpdateNotificationPreference,
  ): Promise<Response>;
  getTyposquatNotificationLabels(): Promise<Response>;
  getLatestUrlInfo(urlSha256: string): Promise<Response>;
  getAllNotificationConfig(applicationType?: string): Promise<INotificationConfigs>;
  getUserNotificationPrefrences(): Promise<IUserNotificationPreferences>;
  updateUserNotificationSettings(updatedSettings: IUpdateNotificationPayload): Promise<Response>;
}

export default class NotificationServices implements NotificationServicesInterface {
  private apiUrl: string;

  constructor() {
    this.apiUrl = setApiUrl();
  }

  setNotificationTyposquatNotificationPreferences(updatedSettings: IUpdateNotificationPreference) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedSettings),
    };
    return fetch('/platform-api/v1/notifications/notification-preference', requestOptions).then(
      handleResponse,
    );
  }

  getTyposquatNotificationLabels() {
    return fetch(this.apiUrl + '/platform-api/v1/notifications/notification-settings-label').then(
      handleResponse,
    );
  }

  getLatestUrlInfo(urlSha256: string) {
    return fetch(`/platform-api/v1/notifications/url-info/${urlSha256}`).then(handleResponse);
  }

  getAllNotificationConfig(applicationType: string = 'PLATFORM'): Promise<INotificationConfigs> {
    return fetch(
      `/platform-api/v1/notifications/all-notification-settings/${applicationType}`,
    ).then(handleResponse);
  }

  //get user notification settings
  getUserNotificationPrefrences(): Promise<IUserNotificationPreferences> {
    return fetch('/platform-api/v1/notifications/user-notification-settings').then(handleResponse);
  }

  updateUserNotificationSettings(updatedSettings: IUpdateNotificationPayload) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedSettings),
    };
    return fetch('/platform-api/v1/notifications/user-notification-settings', requestOptions).then(
      handleResponse,
    );
  }
}

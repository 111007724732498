import React, { useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Arc from '../../../../assets/monitor-acquisitions-arc.png';
import ArcDark from '../../../../assets/monitor-acquisitions-arc-dark.svg';

import Slash from '../../../../assets/icons/slash.svg';
import RightArrowWhite from '../../../../assets/icons/RightArrowWhite.svg';
import RightArrowBlack from '../../../../assets/icons/RightArrow.svg';

import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes, convertToThousand, numberWithCommas } from '../../../../constants';
import { history } from '../../../../helpers';
import { WEB_DASHBOARD_PATHNAME } from '../../../MonitorAndTakedown/constants';
import { IPriority } from './DashboardPriority';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME } from '../../../DomainMonitoring/constant';
import PriorityWidgetDataContext from '../../../../context/PriorityWidgetDataContext';
import CheckPhishContactUsContext from '../../../../context/CheckPhishContactUsContext';

export default function DashboardAcquisition(props: IPriority) {
  const { monitoredDomainsForAcquisition, enableCommunity } = props;
  const { selectedTheme } = useContext(ThemeContext);
  const { isLoading, priorityData } = useContext(PriorityWidgetDataContext);
  const isDemo = window.location.pathname.includes('premium');
  const { monitoredDomain, setContactUsMesage, setShowContactUsPage } = useContext(
    CheckPhishContactUsContext,
  );

  const moneyValues = [
    {
      amount:
        priorityData && priorityData.length > 0 && priorityData[0]?.estimatedPriceSum
          ? priorityData[0]?.estimatedPriceSum
          : 0,
      count:
        priorityData && priorityData.length > 0 && priorityData[0]?.count
          ? priorityData[0]?.count
          : 0,
    },
    {
      amount:
        priorityData && priorityData.length > 0 && priorityData[1]?.estimatedPriceSum
          ? priorityData[1]?.estimatedPriceSum
          : 0,
      count:
        priorityData && priorityData.length > 0 && priorityData[1]?.count
          ? priorityData[1]?.count
          : 0,
    },
    {
      amount:
        priorityData && priorityData.length > 0 && priorityData[2]?.estimatedPriceSum
          ? priorityData[2]?.estimatedPriceSum
          : 0,
      count:
        priorityData && priorityData.length > 0 && priorityData[2]?.count
          ? priorityData[2]?.count
          : 0,
    },
  ];

  const progressBarRender = (color: string, P: number, money: number, count: number) => {
    const partialBarWidth = (num: number) => {
      const maxValue = Math.max(...moneyValues.map((item: { amount: number }) => item.amount));
      return `${(num / maxValue) * 100}`;
    };
    const length = partialBarWidth(money);
    return (
      <div className='bar-container'>
        <div
          className='p-font'
          style={{
            color: selectedTheme === ThemeModes.DARK.toLowerCase() ? '#ADABAB' : '#333',
          }}
        >
          {' '}
          P{P}{' '}
        </div>
        {count > 0 ? (
          <OverlayTrigger
            placement={'auto'}
            overlay={<Tooltip id={`acquisition-priority-${P}-${count}`}>{count}</Tooltip>}
          >
            <div
              className='money-bars'
              style={{
                backgroundColor: color,
                width: `${length}%`,
              }}
            ></div>
          </OverlayTrigger>
        ) : (
          <div
            className='money-bars'
            style={{
              backgroundColor: color,
              width: `${length}%`,
            }}
          ></div>
        )}
        <div className='money'> ${numberWithCommas(money)}</div>
      </div>
    );
  };

  const triggerCheckphishContactUs = () => {
    setContactUsMesage(`For access to additional features, contact us.`);
    setShowContactUsPage(true);
  };

  const redirect = () => {
    let monitorAcquistionPathname;
    if (isDemo) return triggerCheckphishContactUs();
    if (enableCommunity) {
      monitorAcquistionPathname = TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME;
    } else {
      monitorAcquistionPathname = `${WEB_DASHBOARD_PATHNAME}/acquisitions`;
    }
    history.push(monitorAcquistionPathname);
  };
  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className='dashboard-monitored-acquisitions'>
        <div className='arc-container'>
          <div className='dashboard-chart-title'>Monitored for Acquisitions</div>
          <div className='small-text'> Recommended Domains to Acquire </div>
          <div className='arc-image-container'>
            <div className='arc-text-container'>
              <div className='arc-number-text-big'>
                {' '}
                {monitoredDomainsForAcquisition?.count || 3000}{' '}
              </div>
              <img src={Slash} />
              <div className='arc-number-text-small'>
                {convertToThousand(monitoredDomainsForAcquisition?.subCount?.count) || '90k'}{' '}
                Variants
              </div>
            </div>
            <div className='unregistered-text'> Unregistered Domains</div>
            <img
              width='273.24px'
              height='150px'
              src={selectedTheme === ThemeModes.DARK.toLowerCase() ? ArcDark : Arc}
            />
          </div>
        </div>
        <div className='progress-bar-container'>
          <div className='dashboard-chart-title'>Recommended Domains to Acquire</div>
          {progressBarRender('#FF6967', 1, moneyValues[0].amount, moneyValues[0].count)}
          {progressBarRender('#D589BF', 2, moneyValues[1].amount, moneyValues[1].count)}
          {progressBarRender('#B398FF', 3, moneyValues[2].amount, moneyValues[2].count)}
        </div>
        <div className='see-details-btn'>
          <div
            className='see-details-text'
            onClick={redirect}
            style={{
              color: selectedTheme === ThemeModes.DARK.toLowerCase() ? '#fff' : '#000',
            }}
          >
            See Details
          </div>
          <img
            src={
              selectedTheme === ThemeModes.DARK.toLowerCase() ? RightArrowWhite : RightArrowBlack
            }
          />
        </div>
      </div>
    </LoadingWrapper>
  );
}

import React, { useEffect } from 'react';
import { ACQUISITION_TABLE_ID } from '../../MonitorAndTakedown/constants';
import moment from 'moment';
import { setIsExportCSVCalled } from '../../../reducers/table.reducer';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  MANAGED_BY_AFFILIATED,
  MANAGED_BY_BOLSTER,
  MANAGED_BY_ORGANIZATION,
} from '../../Assets_v2/constants';
import { TEAM_MEMBER_TABLE_ID } from '../../TeamMembers/TeamMemberConstant';
import { GLOBAL_TAKEDOWN_TABLE_ID } from '../../GlobalTakedown/GlobalTakedown';

const useOnClientSideTableExport = (
  isExportCSVCalled: boolean,
  agRef: any,
  tableId: string,
  columns: any,
) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.dashboardReducer.user);
  const exportTitle = {
    [ACQUISITION_TABLE_ID]: 'Bolster_Domain_Acquisitions_Candidates',
    searchesTerm: `Bolster Search Terms ${moment().format('YYYYMMDDhhmmss')}`,
    [MANAGED_BY_BOLSTER]: `Managed By Bolster  ${moment().format('YYYYMMDDhhmmss')}`,
    [MANAGED_BY_AFFILIATED]: `Managed By Affiliated  ${moment().format('YYYYMMDDhhmmss')}`,
    [MANAGED_BY_ORGANIZATION]: `Managed By ${user?.organization}  ${moment().format(
      'YYYYMMDDhhmmss',
    )}`,
    [TEAM_MEMBER_TABLE_ID]: 'Bolster_Platform_Team_Members',
    [GLOBAL_TAKEDOWN_TABLE_ID]: 'Bolster_Global_Takedown',
  };
  useEffect(() => {
    if (agRef.current && agRef.current.api && isExportCSVCalled) {
      const exportFileTitle = exportTitle[tableId];

      const gridCols = agRef.current.api.getAllGridColumns();

      const fitlerActiveColumns = gridCols.filter((column: any) => {
        if (column.colId !== 'actionColumn' && column.colId !== 'teamMemberModule') {
          return column.visible;
        }
      });

      agRef.current.api.exportDataAsCsv({
        fileName: exportFileTitle,
        columnKeys: fitlerActiveColumns.map((column: any) => column.colId),
      });
    }
    return () => {
      dispatch(setIsExportCSVCalled(false));
    };
  }, [isExportCSVCalled, tableId, agRef]);

  const onExportCSV = () => {
    dispatch(setIsExportCSVCalled(true));
  };

  return { onExportCSV };
};

export default useOnClientSideTableExport;

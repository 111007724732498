export const SOCIAL_MEDIA_TABLE_NAME = 'socialMedia';
export const SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID = 'findingsDetection';
export const SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID = 'findingsTakedownInProgress';
export const SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID = 'findingsTakenDown';
export const SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID = 'findingsMarkedScam';
export const SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID = 'findingSafeList';
export const SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID = 'findingReview';

export const SOCIAL_MEDIA_PATHNAME = '/social-media';
export const SOCIAL_MEDIA_DASHBOARD_PATHNAME = `${SOCIAL_MEDIA_PATHNAME}/dashboard`;
export const SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME = `${SOCIAL_MEDIA_PATHNAME}/detection`;
export const SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME = `${SOCIAL_MEDIA_PATHNAME}/takedown-in-progress`;
export const SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME = `${SOCIAL_MEDIA_PATHNAME}/takendown`;
export const SOCIAL_MEDIA_FINDINGS_MARKED_SCAM_PATHNAME = `${SOCIAL_MEDIA_PATHNAME}/markedscam`;
export const SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME = `${SOCIAL_MEDIA_PATHNAME}/ignored`;
export const SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME = `${SOCIAL_MEDIA_PATHNAME}/review`;
export const SOCIAL_MEDIA_SEARCH_TERMS = `${SOCIAL_MEDIA_PATHNAME}/search`;

export const widgetsTitle = {
  breakdownByPlatforms: {
    title: 'Top Platforms with Detections',
    tooltipDescription: 'New Detection breakdown by Platforms',
  },
  breakdownByCategory: {
    title: 'Detections By Category',
    tooltipDescription: 'New detections by risk Category',
  },
  breakdownByDetections: {
    title: 'Overall Detections',
    tooltipDescription: '',
  },
  breakdownBySearchTerms: {
    title: 'Top Search Terms Detected',
    tooltipDescription: 'New Detection breakdown by Search Terms',
  },
};

export const SOCIAL_MEDIA_TABLE_IDS = [
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
  SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID,
  SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
];

import React from 'react';
import { SearchTerms } from '../../../Ugc/Components/SearchTerms/SearchTerms';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import { MARKET_PLACE_TABLE_NAME } from '../../constants';

const MarketplaceSearchTerms = () => {
  return <SearchTerms type={ugcType.Marketplace} tableName={MARKET_PLACE_TABLE_NAME} />;
};

export default MarketplaceSearchTerms;

import React from 'react';
import ExpandRightIcon from '../../../../assets/icons/ExpandRightBlue.svg';
import { ENTITY_TYPE } from '../../DarkWebConstants';
import { useHistory } from 'react-router-dom';
import { DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME } from '../../../DarkWeb/DarkWebConstants';
import { useContext } from 'react';
import CheckPhishContactUsContext from '../../../../context/CheckPhishContactUsContext';
export interface IWidgetHeaderProps {
  headingText: string;
  headingSubtext: string;
  icon: any;
  entityType: ENTITY_TYPE;
}

const WidgetHeader = ({ headingText, headingSubtext, icon, entityType }: IWidgetHeaderProps) => {
  const history = useHistory();
  const isDemo = window.location.pathname.includes('premium');
  const { monitoredDomain, setContactUsMesage, setShowContactUsPage } = useContext(
    CheckPhishContactUsContext,
  );

  const triggerCheckphishContactUs = () => {
    setContactUsMesage(`For access to additional features, contact us.`);
    setShowContactUsPage(true);
  };

  return (
    <div className='widget-header'>
      <div className='header-info'>
        <img className='icon-wrap' src={icon} alt='entity-icon' />
        <div className='entity-title'>{headingText}</div>
        <div className='found-summary-subtext subtext'>{headingSubtext}</div>
      </div>
      <button
        className='see-findings-wrap'
        onClick={() =>
          isDemo
            ? triggerCheckphishContactUs()
            : history.push({
                pathname: DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME,
                search: `entity=${entityType}`,
              })
        }
      >
        See Active Findings <img src={ExpandRightIcon} className='expand' alt='view-details' />
      </button>
    </div>
  );
};

export default WidgetHeader;

import React from 'react';
import StatusDotOrangeIcon from '../../../../../assets/icons/StatusDotOrange.svg';
import StatusDotBlueIcon from '../../../../../assets/icons/StatusDotBlue.svg';
import { EFindingStatus, IFindingsTableItemProps } from '../../../Types/ugc.types';

interface ITakedownStatusColRenderProps {
  data: IFindingsTableItemProps;
}

const TakedownStatusColRender = ({ data }: ITakedownStatusColRenderProps) => {
  const { status } = data;
  const takedownIsPaused = status === EFindingStatus.PAUSED;
  return (
    <div className={'action-col-render-component d-flex align-items-center'}>
      <img src={takedownIsPaused ? StatusDotOrangeIcon : StatusDotBlueIcon} alt={'paused'} />
      <div className='pl-2'>{takedownIsPaused ? 'Takedown Paused' : 'Takedown in Progress'}</div>
    </div>
  );
};

export default TakedownStatusColRender;

import React, { useState } from 'react';
import _ from 'lodash';
import Card from 'react-bootstrap/Card';
import './LabelAndValue.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { copyToClipboard } from '../../constants';
import { CopyIcon } from '../../assets/SVGIcons';
import './CopyButton.scss';

export interface ICopyButtonProps {
  value?: string;
  copyButton?: boolean;
  onCopy?: () => void;
  showCopyText?: boolean;
}

const CopyButton = (props: ICopyButtonProps) => {
  const { value = '', copyButton = false, onCopy = _.noop, showCopyText = false } = props;
  const [copied, setCopied] = useState(false);
  return copyButton ? (
    <OverlayTrigger
      overlay={
        <Tooltip id='tooltip-bulk-scan'>
          <Card.Text> {copied ? 'Copied to clipboard' : 'click to copy'} </Card.Text>
        </Tooltip>
      }
    >
      <div
        className='copy-button-wrapper'
        onClick={() => {
          copyToClipboard(value);
          setCopied(true);
          onCopy();
          setTimeout(() => {
            setCopied(false);
          }, 10000);
        }}
        data-testid='copy-button'
      >
        <CopyIcon /> {showCopyText && <div className='copy-text'>Copy Link</div>}
      </div>
    </OverlayTrigger>
  ) : null;
};

export default CopyButton;

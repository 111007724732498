import React, { useState, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';
import { alertActions } from '../../../../actions';
import { IAlertDispatchProps } from '../../../../constants';
import UgcService from '../../../../services/ugc.service';
import { EFindingStatus, IFindingsTableItemProps } from '../../Types/ugc.types';
import ModalOverlay from '../../../Common/ModalOverlay';
import { SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME } from '../../../SocialMedia/constants';
import { redirectToTable } from './Util';

const ugcService = new UgcService();

interface IModalProps {
  data: IFindingsTableItemProps[];
  show: boolean;
  onCancel: () => void;
  onSubmit?: (data: any) => void;
  type: string;
}

const SocApproveDetectionModal = ({
  data,
  show,
  onCancel,
  onSubmit,
  alertSuccess,
  alertError,
  type,
}: IModalProps & IAlertDispatchProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onModalSubmit = () => {
    if (data[0].status !== EFindingStatus.UNDER_REVIEW) {
      alertError(`Not ready to approve. Please wait until scan is finished.`);
      onCancel();
      return;
    }
    setIsLoading(true);
    ugcService
      .approveFindings(data, type)
      .then(() => {
        setIsLoading(false);
        onSubmit && onSubmit(data);
        alertSuccess(`Congratulations! URL has been added to Live Detection.`);
        // redirectToTable(SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME, 'url', data.url);
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
        alertError(err);
      });
  };
  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={'Approve Detection'}
      onCancel={onCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Approve Detection'}
    >
      <div className='approve-finding-modal'>
        <div>
          Approving <br />
          <b className='high-lighted-text'>
            {_.map(data, i => (
              <Fragment key={i.url}>
                {i.url} <br />
              </Fragment>
            ))}
          </b>
        </div>
      </div>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocApproveDetectionModal);

import React, { useContext, useState, useMemo, useEffect } from 'react';
import { IPricingPlan, PRICING_PLANS } from './types';
import PricingPlan from './PricingPlan';
import { ISubscriptionItem } from './types';
import './pricing.scss';
import { PlanCardsV2 } from '../Plans/PlanCards';
import { IChosenPlan } from '../Plans/PlanCards';
import { IPlanV2 } from '../Plans/PlanCards';
import _ from 'lodash';
import CheckphishScreenShotLight from '../../assets/checkphish-bb-light.png';
import CheckphishScreenShotDark from '../../assets/checkphish-bb-dark.png';
import ThemeContext from '../../context/ThemeContext';
import { ThemeModes } from '../../constants';
import LiveScanPlansSelector from './Common/LiveScanPlansSelector';
import { ISubsciptionPlan } from './types';
import {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';

export interface IAddOnWidgetProps {
  liveScanPlan: IPricingPlan;
  liveScanBundles: number[];
  onActionBtnClick: any;
}

const AddOnWidget = ({ liveScanPlan, liveScanBundles, onActionBtnClick }: IAddOnWidgetProps) => {
  const { themeName } = useContext(ThemeContext);
  const { currentLiveScanSubscription, numberOfScans, setNumberOfScans } =
    useContext<ICheckPhishSubscription>(CheckphishSubscriptionContext);

  const checkphishSrc = useMemo(() => {
    return themeName === ThemeModes.LIGHT ? CheckphishScreenShotLight : CheckphishScreenShotDark;
  }, [themeName]);

  const onSliderChange = (sliderIndex: number) =>
    setNumberOfScans(liveScanBundles[sliderIndex - 1]);

  return (
    <div className='add-on-widget-wrap' data-testid='addon-wrap'>
      <PricingPlan
        planName={PRICING_PLANS.PROGESSIONAL_API}
        price={'Professional API'}
        captionText={'Live Scan URL ONLY'}
        onActionBtnClick={onActionBtnClick.bind(this, liveScanPlan, numberOfScans, true)}
        features={[
          'Custom pricing for API Scans ',
          'Bulk scan functionality',
          'Advanced API dashboard ',
          'Outlook Email Plugin ',
        ]}
        planChipText={'Add-on'}
        hideActionBtn={false}
        actionButtonCustomClass={`highlight-${PRICING_PLANS.PROGESSIONAL_API}`}
        actionButtonPosition={'bottom'}
        disableActionButton={currentLiveScanSubscription?.quantity === numberOfScans}
        customClassName='professional-api--min-width'
      />
      <div className='pricing-wrap' data-testid='pricing-wrap'>
        {!_.isEmpty(liveScanPlan) && !_.isEmpty(liveScanBundles) && (
          <LiveScanPlansSelector
            liveScanPlan={liveScanPlan}
            liveScanBundles={liveScanBundles}
            onSliderChange={onSliderChange}
          />
        )}
      </div>
      <div className='img-wrap'>
        <img src={checkphishSrc} alt={`checkphish-screenshot-${themeName}`} />
      </div>
    </div>
  );
};

export default AddOnWidget;

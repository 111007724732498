import React, { useCallback, useState, useRef, useContext } from 'react';
import _ from 'lodash';
import AttachFile from '../../Common/AttachFile';
import { RadioButtons } from '../../Common/RadioButtons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import DashboardService from '../../../services/dashboard.service';
import { AlertActionsTypes, appConstants, featureIsAvailable } from '../../../constants';
import { DISPUTE_DISPOSITION_OPTIONS } from '../../Common/DisputeStatus';
import TextArea from '../../Common/TextArea';
import ThemeContext from '../../../context/ThemeContext';
import { CloseIcon } from '../../../assets/SVGIcons';
import { BulkScanSelectedItem } from '../BulkScanConstants';
import { WebUrlData } from '../../../types/web-url-data.interface';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import { useAppSelector } from '../../../helpers/hooks';

const dashboardService = new DashboardService();

interface IDisputeDispositionModalProps {
  data: BulkScanSelectedItem[] | WebUrlData[];
  show: boolean;
  submitButtonLabel?: string;
  onCloseModal: () => void;
  onSuccess: (res: any, msg: string) => void;
  onFailure: (message: string) => AlertActionsTypes;
  bulkScanPage: boolean;
}

interface IDispute {
  disposition?: string;
  comments?: string;
}

const DisputeDispositionModal = ({
  data,
  show,
  onCloseModal,
  onSuccess,
  onFailure,
  bulkScanPage,
}: IDisputeDispositionModalProps) => {
  const [dispute, setDispute] = useState<IDispute>({});
  const [sendingDisputeRequest, setSendingDisputeRequest] = useState(false);
  const [disputeError, setDisputeError] = useState<string>('');
  const uploadedAttachedFilesDispute = useRef<File[]>([]);
  const { selectedTheme } = useContext(ThemeContext);
  const { user } = useAppSelector(state => state.dashboardReducer);

  const setDisputeState = useCallback(
    (key: string, value: string) => {
      setDispute({ ...dispute, [key]: value });
      setDisputeError('');
    },
    [dispute],
  );

  const submitDisputeVerdict = () => {
    const { disposition, comments } = dispute;

    if (!disposition) {
      setDisputeError('Please select a disposition.');
      return;
    }

    const disputeData = new FormData();
    const urlScans = data.map(item => {
      return {
        urlSha256: item.url_sha256,
        timestamp: item.timestamp,
        currentDisposition: item.disposition,
      };
    });
    disputeData.append('urlScansRaw', JSON.stringify(urlScans));
    disputeData.append('comments', comments || '');
    disputeData.append('customerDisposition', disposition);
    for (let i = 0; i < uploadedAttachedFilesDispute.current.length; i++) {
      disputeData.append('files', uploadedAttachedFilesDispute.current[i]);
    }
    setSendingDisputeRequest(true);
    dashboardService
      .bulkActionDisputeVerdict(disputeData)
      .then(res => {
        setSendingDisputeRequest(false);
        setDispute({});
        onSuccess(res, 'Dispute Request Sent Successfully!');
        onCloseModal();
      })
      .catch(err => {
        setSendingDisputeRequest(false);
        onFailure(err);
      });
  };

  const DISPUTE_OPTIONS = [];
  for (const value in DISPUTE_DISPOSITION_OPTIONS) {
    DISPUTE_OPTIONS.push({ label: DISPUTE_DISPOSITION_OPTIONS[value], value });
  }
  const isOldTable = !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);

  return (
    <Modal show={isOldTable ? show : true} className={`${selectedTheme}`}>
      <Modal.Header closeButton={false}>
        <Modal.Title>Dispute Disposition</Modal.Title>
        <div className='close' onClick={onCloseModal}>
          <CloseIcon color='grey' />
        </div>
      </Modal.Header>
      <LoadingWrapper isLoading={isOldTable ? false : !show}>
        <Modal.Body>
          {disputeError && <div className={'mb-2 text-danger'}>{disputeError}</div>}
          <div className='pb-3'>
            Please be sure you only selected ULRs that impact your own brand
          </div>
          <RadioButtons
            label='Please select what you believe to be the correct disposition.'
            options={DISPUTE_OPTIONS}
            onChange={disposition => setDisputeState('disposition', disposition)}
            direction={'column'}
          />
          <AttachFile
            label='Attach File'
            onChangeHandler={files => (uploadedAttachedFilesDispute.current = files)}
          />
          <TextArea
            label='Comments'
            onChange={value => {
              setDisputeState('comments', value);
            }}
          />
        </Modal.Body>
      </LoadingWrapper>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant='primary'
          onClick={submitDisputeVerdict}
          disabled={isOldTable ? sendingDisputeRequest : sendingDisputeRequest || !show}
        >
          {sendingDisputeRequest ? (
            <Spinner className='spinner' animation='border' variant='light' size='sm' />
          ) : (
            'Submit'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisputeDispositionModal;

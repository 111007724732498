import React, { useState } from 'react';
import { Form, Modal, Spinner, Button } from 'react-bootstrap';
import { CloseIcon } from '../../assets/SVGIcons';
import { capitalize, ThemeModes } from '../../constants';
import { IDropdownOption, Dropdown } from '../Common/Dropdown';
import './Style/PropertyModalStyle.scss';
interface IUploadAssetModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (data: any, brandId?: number, onSuccess?: any, onFailure?: any) => void;
  brandInfo: any;
  isAddingNewAssets: boolean;
  selectedTheme: string;
}

export default function UploadAssetModal({
  show,
  onHide,
  onSubmit,
  brandInfo,
  isAddingNewAssets,
  selectedTheme,
}: IUploadAssetModalProps) {
  const [brandId, setBrandId] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  let fileSelector: any = {};

  const submitUpload = (event: any) => {
    if (event.target.files[0].type !== 'text/csv') {
      setErrorMessage('Please select a .csv file.');
      return;
    }
    setErrorMessage('');
    onSubmit(event, brandId, () => {
      onHide();
    });
  };
  const brandOptions = (): any[] => {
    const brands: any = [];
    brandInfo?.subBrands.map((brand: any) => {
      const result = {};
      result['label'] = brand.brand_display_name;
      result['value'] = brand.brand_id;
      brands.push(result);
    });
    return brands;
  };
  const handleBrandInputChange = (selection: IDropdownOption) => {
    setBrandId(Number(selection.value));
  };

  return (
    <div>
      <input
        type='file'
        name='file'
        id='file'
        accept={'.csv'}
        key={Math.random()}
        ref={ref => (fileSelector = ref)}
        onChange={submitUpload}
        style={{ display: 'none' }}
      />
      <Modal
        show={show}
        size='sm'
        onHide={onHide}
        className={`${selectedTheme} upload-asset-modal`}
      >
        <Modal.Header>
          <Modal.Title id='upload-file-assets'>Upload file</Modal.Title>
          <div onClick={onHide}>
            <CloseIcon color='grey' />
          </div>
        </Modal.Header>
        <Modal.Body className={'modal-body'}>
          <Form>
            {brandInfo?.brandMapping === true && brandInfo?.subBrands?.length > 1 ? (
              <Form.Group>
                <Form.Label>Select Brand:</Form.Label>
                <Dropdown boxStyle options={brandOptions()} onChange={handleBrandInputChange} />
              </Form.Group>
            ) : null}
          </Form>
          <p>Please select a .csv file with domains listed. </p>
          <p>Examples:</p>
          <p className='code-block large-height'>
            <div>example1.com</div>
            <div>example2.com</div>
            <div>…</div>
          </p>
          <p>Or</p>
          <div className='code-block'>example1.com, example2.com, …</div>
          <p>
            <b className='text-danger'>{errorMessage}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant='primary'
            disabled={
              brandInfo?.brandMapping === true && brandId === 0 && brandInfo?.subBrands?.length > 1
            }
            onClick={(e: any) => {
              e.preventDefault();
              fileSelector.click();
            }}
          >
            {isAddingNewAssets ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : (
              'Select File'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IMassagedConnectorTypes } from '../components/Connectors/connector-requests';

export interface PlaybookState {
  recentChangesMultiOptions: { fields: string[] };
  recentChangesDateRange: { dateRange: string };
  connectorTypes: IMassagedConnectorTypes[];
}
const initialState: PlaybookState = {
  recentChangesMultiOptions: { fields: [] },
  recentChangesDateRange: { dateRange: '' },
  connectorTypes: [],
};

export const playbookSlice = createSlice({
  name: 'playbook',
  initialState,
  reducers: {
    setRecentChangesMultiOptions: (state, action: PayloadAction<any>) => {
      state.recentChangesMultiOptions = action.payload;
    },
    setRecentChangesDateRange: (state, action: PayloadAction<any>) => {
      state.recentChangesDateRange = action.payload;
    },
    setConnectorTypes: (state, action: PayloadAction<IMassagedConnectorTypes[]>) => {
      state.connectorTypes = action.payload;
    },
  },
});

export const { setRecentChangesMultiOptions, setRecentChangesDateRange, setConnectorTypes } =
  playbookSlice.actions;

export default playbookSlice.reducer;

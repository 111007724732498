import React from 'react';
import './SideBar.scss';
import { NavLink, Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { ITabItem, ITabSubItem } from './TabItems';
import BackIcon from '../../assets/icons/Back.svg';
import ExpandRightIcon from '../../assets/icons/ExpandRightBlack.svg';
import CloseDarkIcon from '../../assets/icons/CloseDark.svg';
import Button from 'react-bootstrap/Button';
import { redirectToCheckphish } from '../../constants';

interface IWebSideMenuProps {
  items: ITabItem[];
  isOpen: boolean;
  close: () => void;
}

interface IWebSideMenuState {
  activeNavItem: ITabItem;
  level2IsOpen: boolean;
  isActive: boolean;
}

export default class WebSideMenu extends React.Component<IWebSideMenuProps, IWebSideMenuState> {
  constructor(props: IWebSideMenuProps) {
    super(props);

    this.state = {
      activeNavItem: props.items[0],
      level2IsOpen: false,
      isActive: false,
    };
  }

  componentDidMount(): void {
    setTimeout(this.toggleActive, 1);
  }

  toggleActive = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  close = () => {
    const delay = 300; // this number has to match the value animation-duration in SideBar.scss.
    this.setState(
      {
        isActive: !this.state.isActive,
      },
      () => {
        setTimeout(this.props.close, delay);
      },
    );
  };

  hide2ndLevel = () => {
    this.setState({
      level2IsOpen: false,
    });
  };

  onNavItemChange = (navItem: ITabItem) => {
    let level2IsOpen = false;
    if (navItem.subItems && navItem.subItems.length) {
      level2IsOpen = true;
    } else {
      this.props.close();
    }
    this.setState({
      activeNavItem: navItem,
      level2IsOpen,
    });
  };

  onNavSubItemChange = (navSubItem: ITabSubItem) => {
    this.setState(
      {
        level2IsOpen: false,
      },
      this.props.close,
    );
  };

  renderNavItem = (navItem: ITabItem) => {
    const { id, href, label, subItems, redirect = false } = navItem;
    const link = redirect ? redirectToCheckphish(href, true) || href : href;
    const itemIsActive = window.location.pathname.indexOf(navItem.id) === 0;
    const navItemClassName = 'side-bar-item-container' + (itemIsActive ? ' selected' : '');

    return (
      <Nav.Item>
        {id === 'checkphishDomainMonitoring' || id === 'checkphishLiveScan' ? (
          <Link
            to={link}
            key={id}
            className={navItemClassName}
            onClick={this.onNavItemChange.bind(this, navItem)}
          >
            <span>{label}</span>
            {subItems && subItems.length > 0 && <img src={ExpandRightIcon} alt={'expand'} />}
          </Link>
        ) : (
          <Nav.Link
            href={link}
            key={id}
            className={navItemClassName}
            onClick={this.onNavItemChange.bind(this, navItem)}
          >
            <span>{label}</span>
            {subItems && subItems.length > 0 && <img src={ExpandRightIcon} alt={'expand'} />}
          </Nav.Link>
        )}
        {navItem.isNewLabel && <div className='new-label-text-mobile'>New</div>}
      </Nav.Item>
    );
  };

  renderNavSubItem = (navSubItem: ITabSubItem) => {
    const { id, href, label, webOnly } = navSubItem;
    if (webOnly) {
      return null;
    }
    return (
      <Nav.Item>
        <Nav.Link
          key={id}
          className={'side-bar-item-container'}
          href={href}
          onClick={this.onNavSubItemChange.bind(this, navSubItem)}
        >
          <span>{label}</span>
        </Nav.Link>
      </Nav.Item>
    );
  };

  render() {
    const { items } = this.props;
    const { activeNavItem, level2IsOpen, isActive } = this.state;
    const { subItems = [] } = activeNavItem;

    return (
      <div className={'side-bar-mobile-component d-xl-none'}>
        <div
          className={'nav-bar-background' + (isActive ? ' is-active' : '')}
          onClick={this.close}
        />
        <div
          className={
            'first-level side-bar-level d-flex flex-column web-theme' +
            (isActive ? ' is-active' : '')
          }
        >
          <div className={'first-level-header side-bar-header'}>
            <div className={'company'}></div>
            <img src={CloseDarkIcon} alt={'close nav icon'} onClick={this.close} />
          </div>
          <div className={'first-level-body side-bar-body'}>
            {items.map((item: ITabItem) => {
              return this.renderNavItem(item);
            })}
          </div>
          <div className={'p-4 w-100'}>
            <div className={''}>
              <Link to={'/sign-in'} className={'flex-center my-4 custom-text-dark'}>
                LOG IN
              </Link>
            </div>
            <Button variant={'outline-light'} className={'w-100 p-2'}>
              <Link to={'/sign-up'} className={'text-white'}>
                SIGNUP
              </Link>
            </Button>
          </div>
        </div>
        <div
          className={
            'second-level side-bar-level web-theme' +
            (level2IsOpen ? '' : ' width-0') +
            (isActive ? ' is-active' : '')
          }
        >
          <div className={'second-level-header side-bar-header'}>
            <img src={BackIcon} alt={'companyIcon'} onClick={this.hide2ndLevel} />
            <span>{activeNavItem.label}</span>
          </div>
          <div className={'second-level-body side-bar-body'}>
            {subItems.map((subItem: ITabSubItem) => {
              return this.renderNavSubItem(subItem);
            })}
          </div>
        </div>
      </div>
    );
  }
}

import { IEntityData, IEntityLableDataSet } from './types';
import { colors } from '../../constants';
import {
  TITLE_COLUMN,
  PLATFORM_COLUMN,
  RISK_COLUMN,
  CATEGORY_COLUMN,
  SEARCH_TERM_COLUMN,
  THREAT_ACTOR_COLUMN,
  SENSITIVE_DATA_EXISTS_COLUMN,
  TITLE_COLUMN_DYNAMIC_HEADER,
  PASSWORD_COLUMN,
  PASSWORD_TYPE_COLUMN,
  DATALEAK_SOURCE_COLUMN,
  SEARCH_TERM_COLUMN_DYNAMIC,
  CCN_COLUMN,
  CVV_COLUMN,
  EXP_DATE_COLUMN,
  DISCOVERY_DATE_COLUMN,
  DARK_WEB_LAST_SCANNED_COLUMN,
} from '../DarkWeb/Components/Common/TableColDefs/DarkWebColDefs';

export const colorList = [
  colors.darkWebPriority1,
  colors.darkWebPriority2,
  colors.darkWebPriority3,
  colors.darkWebPriority4,
  colors.darkWebPriority5,
];

export enum ENTITY_TYPE {
  EMAIL_DOMAIN = 'emaildomain',
  CREDIT_CARD = 'creditcard',
  EXECUTIVE_MONITORING = 'executiveMonitoring',
  EMAIL_ADDRESS = 'email',
  OTHERS = 'otherSearchTerms',
}

export const generateEnitityLables = (
  EmailDomainEntityIcon: string,
  EmailAddressEntityIcon: string,
  CreditCardEntityIcon: string,
  ExecutiveEntityIcon: string,
  OtherEntityIcon: string,
): IEntityLableDataSet => ({
  emaildomain: {
    entityHeading: 'Email Domain',
    subHeading: 'domains',
    icon: EmailDomainEntityIcon,
    dropdownLable: 'Domain',
    donut1Lable: 'Data Leak Source',
    dataSetLable: 'Email Address In Breach',
    uniqueDataSetLable: 'Unique Emails',
    totalDataSetLable: 'Total Email Addresses',
    secondDonutDataSetLabel:
      'Breakdown of emails with plaintext passwords, hashed passwords or no passwords at all.',
    donutLable: 'Password Details',
    toolTip:
      'Total refers to the overall count of findings, while Unique represents the number of distinct email domains identified. For example, if "bolster.ai" is found in both Data Leak 1 and Data Leak 2, Total would be 2, and Unique would be 1, signifying the singular occurrence of that email domain.',
  },
  email: {
    entityHeading: 'Email Address',
    subHeading: 'address',
    icon: EmailAddressEntityIcon,
    dropdownLable: 'Address',
    donut1Lable: 'Data Leak Source',
    dataSetLable: 'Email Address In Breach',
    uniqueDataSetLable: 'Unique Emails',
    totalDataSetLable: 'Total Email Addresses',
    donutLable: 'Password Details',
    secondDonutDataSetLabel:
      'Breakdown of emails with plaintext passwords, hashed passwords or no passwords at all.',
    toolTip:
      'Total represents the overall count of findings, while Unique signifies the number of distinct email addresses found. For example, if "andy@bolster.ai" is found in both Data Leak 1 and Data Leak 2, Total would be 2, and Unique would be 1, representing the singular presence of that email address.',
  },
  creditcard: {
    entityHeading: 'Credit Card',
    subHeading: 'BIN numbers',
    icon: CreditCardEntityIcon,
    dropdownLable: 'BIN',
    donut1Lable: 'Data Leak Source',
    dataSetLable: 'Credit Cards In Breach',
    uniqueDataSetLable: 'Unique Credit Cards',
    totalDataSetLable: 'Total Credit Cards',
    secondDonutDataSetLabel:
      'Breakdown of credit cards that are unexpired, have additional details or no details at all.',
    donutLable: 'Credit Card Details',
    toolTip:
      'Total indicates the total count of findings, while Unique represents the number of distinct credit card details discovered. For instance, if a specific credit card is identified in both Data Leak 1 and Data Leak 2, Total would be 2, and Unique would be 1, denoting the singular occurrence of that credit card information.',
  },
  executiveMonitoring: {
    entityHeading: 'Employee',
    subHeading: 'employees',
    icon: ExecutiveEntityIcon,
    dropdownLable: 'Employee',
    donut1Lable: 'Platform',
    dataSetLable: '',
    uniqueDataSetLable: '',
    totalDataSetLable: '',
    secondDonutDataSetLabel: '',
    donutLable: 'Times Detected',
    toolTip: '',
  },
  otherSearchTerms: {
    entityHeading: 'Other Terms',
    subHeading: 'Top 5 Search Terms',
    icon: OtherEntityIcon,
    dropdownLable: '',
    donut1Lable: 'Data Leak Source',
    dataSetLable: '',
    secondDonutDataSetLabel: '',
    uniqueDataSetLable: '',
    totalDataSetLable: '',
    donutLable: '',
    toolTip: '',
  },
});

export enum CATEGORY_TYPE {
  ANY = 'Any',
  BREACH_DATA_FOR_SALE = 'Breach Data for Sale',
  EMPLOYEE_MONITORING = 'Employee Monitoring',
  HACKER_CHATTER = 'Hacker Chatter',
  IP_LEAK_DATA_FOR_SALE = 'IP Leak Data for Sale',
  MALWARE_EXPLOITS_FOR_SALE = 'Malware Exploits for Sale',
  PHISHING_KITS_FOR_SALE = 'Phishing Kits for Sale',
  RANDOMWARE_FOR_SALE = 'Randomware for Sale',
}

export const ENTITY_MAPPER = {
  [ENTITY_TYPE.EMAIL_DOMAIN]: {
    label: 'Email Domain',
    totalFindings: 'Total in breach',
    searchTermCount: 'Domain',
    searchTermCountPlural: 'Domains',
    extraFilters: {
      isEntity: true,
      entity: ['emaildomain'],
    },
    additionalFields: ['entity', 'email', 'password', 'password_type'],
    columns: [
      SEARCH_TERM_COLUMN_DYNAMIC('Domain'),
      TITLE_COLUMN_DYNAMIC_HEADER('Email', false),
      PASSWORD_COLUMN,
      PASSWORD_TYPE_COLUMN,
      DISCOVERY_DATE_COLUMN,
      DATALEAK_SOURCE_COLUMN,
      DARK_WEB_LAST_SCANNED_COLUMN,
    ],
  },
  [ENTITY_TYPE.EMAIL_ADDRESS]: {
    label: 'Email Address',
    totalFindings: 'Total findings',
    searchTermCount: 'Address',
    searchTermCountPlural: 'Addresses',
    extraFilters: {
      isEntity: true,
      entity: ['email'],
    },
    additionalFields: ['entity', 'password', 'password_type'],
    columns: [
      SEARCH_TERM_COLUMN_DYNAMIC('Account'),
      PASSWORD_COLUMN,
      PASSWORD_TYPE_COLUMN,
      DISCOVERY_DATE_COLUMN,
      DATALEAK_SOURCE_COLUMN,
      DARK_WEB_LAST_SCANNED_COLUMN,
    ],
  },
  [ENTITY_TYPE.CREDIT_CARD]: {
    label: 'Credit Card',
    totalFindings: 'Total in breach',
    searchTermCount: 'BIN Number',
    searchTermCountPlural: 'BIN Numbers',
    extraFilters: {
      isEntity: true,
      entity: ['creditcard'],
    },
    additionalFields: ['entity', 'password_type', 'ccn', 'cvv', 'exp_date'],
    columns: [
      SEARCH_TERM_COLUMN_DYNAMIC('Account'),
      CCN_COLUMN,
      CVV_COLUMN,
      EXP_DATE_COLUMN,
      DISCOVERY_DATE_COLUMN,
      DATALEAK_SOURCE_COLUMN,
      DARK_WEB_LAST_SCANNED_COLUMN,
    ],
  },
  [ENTITY_TYPE.EXECUTIVE_MONITORING]: {
    label: 'Employee',
    totalFindings: 'Total detections',
    searchTermCount: 'Employee',
    searchTermCountPlural: 'Employees',
    extraFilters: {
      isEmployee: true,
    },
    additionalFields: ['entity', 'content'],
    columns: [
      SEARCH_TERM_COLUMN_DYNAMIC('Matched Search Term'),
      TITLE_COLUMN_DYNAMIC_HEADER('Title', false),
      DISCOVERY_DATE_COLUMN,
      DATALEAK_SOURCE_COLUMN,
      DARK_WEB_LAST_SCANNED_COLUMN,
    ],
  },
  [ENTITY_TYPE.OTHERS]: {
    label: 'Other Terms',
    totalFindings: 'Total findings',
    searchTermCount: 'Search terms',
    searchTermCountPlural: 'Search term',
    extraFilters: {
      isOthers: true,
    },
    additionalFields: [],
    columns: [
      SEARCH_TERM_COLUMN,
      TITLE_COLUMN,
      DATALEAK_SOURCE_COLUMN,
      RISK_COLUMN,
      CATEGORY_COLUMN,
      THREAT_ACTOR_COLUMN,
      DISCOVERY_DATE_COLUMN,
      SENSITIVE_DATA_EXISTS_COLUMN,
      DARK_WEB_LAST_SCANNED_COLUMN,
    ],
  },
};

export const initialAnalyticsData = {
  executiveMonitoring: [],
  email: [],
  emaildomain: [],
  creditcard: [],
  otherSearchTerms: [],
};

export const initialEntityData: IEntityData = {
  searchTerm: '',
  searchTermId: '',
  sources: [{ label: '', value: 0 }],
  dataSetItem: {
    unique: 0,
    total: 0,
  },
  passwordDataSet: {
    high: 0,
    medium: 0,
    low: 0,
  },
  cardDataSet: {
    high: 0,
    medium: 0,
    low: 0,
  },
};

export const DARK_MODE_DATA_LEAK_BG_COLOR = '#26292d';
export const LIGHT_MODE_DATA_LEAK_BG_COLOR = '#ffffff';

import * as React from 'react';
import { AppState } from '../../helpers';
import { ThunkDispatch } from 'redux-thunk';
import { UserActionsTypes, IAppDispatchProps } from '../../constants';
import { bindActionCreators } from 'redux';
import { userActions, appActions } from '../../actions';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { history } from '../../helpers';

interface RouteParams {
  token: string;
}
interface IUserVerifyProps {
  verify: (token: string, workEmail: boolean) => void;
}

type Props = IUserVerifyProps &
  ILinkDispatchProps &
  RouteComponentProps<RouteParams> &
  IAppDispatchProps;

class VerifyWorkEmail extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.verifyEmail();

    this.props.runByClientApp({
      onBolster: () => {
        history.push('/sign-in');
      },
      onCheckPhish: () => {
        window.document.title = `Verify Work Email | CheckPhish Platform`;
      },
    });
  }

  verifyEmail = () => {
    const token: string = this.props.match.params.token;
    this.props.verify(token, true);
  };

  render() {
    return null;
  }
}

interface ILinkStateProp {}

interface ILinkDispatchProps {
  verify: (token: string) => void;
}

const mapStateToProps = (state: AppState) => {
  return state.verifyReducer;
};

const mapPropsToSate = (dispatch: ThunkDispatch<any, any, UserActionsTypes>) => ({
  verify: bindActionCreators(userActions.verify, dispatch),
  runByClientApp: appActions.runByClientApp,
});

const connectedForgotPass = connect(mapStateToProps, mapPropsToSate)(VerifyWorkEmail);
export { connectedForgotPass as VerifyWorkEmail };

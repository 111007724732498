import _ from 'lodash';
import React, { useState, createContext, useRef, useEffect } from 'react';

const TyposquatContext = createContext({
  domain: '',
  typosquatResult: [],
  typosquatMetadata: {
    totalVariants: 0,
    topTldVariants: 0,
    totalResolvedVariants: 0,
  },
  typosquatReportGenerated: false,
  setDomain: _.noop,
  setTyposquatResult: _.noop,
  setTyposquatMetadata: _.noop,
  setTyposquatReportGenerated: _.noop,
});

export default TyposquatContext;

interface ITyposquatContext {
  children: React.ReactElement;
}

export const TyposquatContextProvider = (props: ITyposquatContext): any => {
  const { children } = props;
  const [domain, setDomain] = useState<string>('');
  const [typosquatResult, setTyposquatResult] = useState<any>([]);
  const [typosquatMetadata, setTyposquatMetadata] = useState<any>({
    totalVariants: 0,
    topTldVariants: 0,
    totalResolvedVariants: 0,
  });
  const [typosquatReportGenerated, setTyposquatReportGenerated] = useState<boolean>(false);

  return (
    <TyposquatContext.Provider
      value={{
        domain,
        typosquatResult,
        typosquatMetadata,
        typosquatReportGenerated,
        setDomain,
        setTyposquatResult,
        setTyposquatMetadata,
        setTyposquatReportGenerated,
      }}
    >
      {children}
    </TyposquatContext.Provider>
  );
};

import React from 'react';
import { Card } from 'react-bootstrap';
import { DashBoardWidgetConfigDto } from '../../../../constants';
import { IDashboardWidgetsSection } from '../Types/dashboard.types';
import { CHECKPHISH_WIDGETS } from '../Constants/dashboardCP';
import { DashboardDetection } from './DashboardDetection';
import { DashboardHosting } from './DashboardHosting';
import DashboardBrands from './DashboardBrands';
import DashboardUrlBySource from './DashboardUrlBySource';
import DashboardUrlByCategories from './DashboardUrlByCategory';
import { DashboardRegion } from './DashboardRegion';

const WidgetRow = (
  row: DashBoardWidgetConfigDto[],
  index: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
  isLoading: boolean,
  widgetsData: {},
) => {
  const getDom = (widget: DashBoardWidgetConfigDto) => {
    const name = widget.name.split('_')[0];
    if (name === 'detection') {
      return (
        <DashboardDetection
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoading}
          widgetData={widgetsData['phishAndScamSiteDetection']}
        />
      );
    } else if (name === 'hosting') {
      return (
        <DashboardHosting
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoading}
          widgetData={widgetsData['top10PhishScamSiteHosting']}
        />
      );
    } else if (name === 'brands') {
      return (
        <DashboardBrands isLoading={isLoading} widgetData={widgetsData['topGenerationBrands']} />
      );
    } else if (name === 'country') {
      return (
        <DashboardRegion isLoading={isLoading} widgetData={widgetsData['topGenerationCountries']} />
      );
    } else if (name === 'source') {
      return <DashboardUrlBySource isLoading={isLoading} widgetData={widgetsData['urlBySource']} />;
    } else if (name === 'category') {
      return (
        <DashboardUrlByCategories
          isLoading={isLoading}
          widgetData={widgetsData['scansByCategories']}
        />
      );
    }
  };
  return (
    <Card className={'widgets-container'} key={'widget section ' + index}>
      <div key={'widget-left'} className='widget-item-component'>
        <div className='widget-item-inner'>{getDom(row[0])}</div>
      </div>
      <div key={'widget-right'} className='widget-item-component'>
        <div className='widget-item-inner'>{getDom(row[1])}</div>
      </div>
    </Card>
  );
};

const WidgetsSection = ({
  user,
  startDate,
  endDate,
  isLoading,
  widgetsData,
}: IDashboardWidgetsSection) => {
  const widgetDoms = [];

  for (let i = 0; i < CHECKPHISH_WIDGETS.length; i = i + 2) {
    const row = [{ ...CHECKPHISH_WIDGETS[i] }, { ...CHECKPHISH_WIDGETS[i + 1] }];
    widgetDoms.push(WidgetRow(row, i, startDate, endDate, isLoading, widgetsData));
  }
  return <>{widgetDoms}</>;
};

export { WidgetsSection };

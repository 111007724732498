import * as _ from 'lodash';
import moment from 'moment';
import { SCANTYPE } from '../components/Checkphish/AllScans/scanType.enum';
import { IFilter } from '../components/Common/Table/constant';
import fetch from './api.service';
import { setScanTableSort } from './dashboard.service';
import { handleResponse } from './serviceWorker';

const setUrlScanTableFilter = (filters: IFilter[], excludeClean: boolean = false) => {
  const must: any = {};
  const mustNot: any = {};
  if (excludeClean) {
    mustNot.disposition = 'clean';
  }

  _.forEach(filters, (filter: IFilter) => {
    const { filterBy, filterMethod } = filter;
    let { filterValue } = filter;
    let condition;
    let conditionKey;

    switch (filterBy.value) {
      case 'url':
      case 'networkOwner':
      case 'disposition':
      case 'status':
      case 'source':
      case 'scanSource':
      case 'scanCategory':
      case 'brandId':
      case 'final_category':
      case 'brandLogoDetected':
      case 'scanLocation':
        if (filterMethod.value === 'includes' || filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        switch (filterBy.value) {
          case 'url':
            conditionKey = 'srcUrl';
            break;
          case 'networkOwner':
            conditionKey = 'asDescription';
            break;
          default:
            conditionKey = filterBy.value;
            break;
        }
        if (_.isEmpty(condition[conditionKey])) {
          condition[conditionKey] = '';
        } else {
          condition[conditionKey] += '|';
        }
        if (
          filterBy.value === 'url' ||
          filterBy.value === 'networkOwner' ||
          filterBy.value === 'source' ||
          filterBy.value === 'brandId'
        ) {
          if (typeof filterValue === 'string') {
            filterValue = '.*' + filterValue.toLowerCase() + '.*';
          } else {
            condition['scanCategory'] = filterValue['scanCategory'];
            filterValue = '.*' + (filterValue['source'] as string).toLowerCase() + '.*';
          }
        }
        condition[conditionKey] += filterValue;
        break;
      case 'ipAddress':
        const ipSubsets = filterValue.split('.');
        const ipAddressStart: string[] = [];
        const ipAddressEnd: string[] = [];
        for (let i = 0; i < 4; i++) {
          ipAddressStart.push(_.isEmpty(ipSubsets[i]) ? '0' : ipSubsets[i]);
          ipAddressEnd.push(_.isEmpty(ipSubsets[i]) ? '255' : ipSubsets[i]);
        }
        if (filterMethod.value === 'beginWith') {
          condition = must;
        } else {
          condition = mustNot;
        }
        condition.ipAddressStart = ipAddressStart.join('.');
        condition.ipAddressEnd = ipAddressEnd.join('.');
        break;
      case 'tagIds':
        if (filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        conditionKey = filterBy.value;
        if (_.isEmpty(condition[conditionKey])) {
          condition[conditionKey] = '';
        } else {
          condition[conditionKey] += '|';
        }
        condition[conditionKey] = condition[conditionKey] + '(' + filterValue + ')';
        const chars = { '&': ' AND ', '|': ' OR ' };
        condition[conditionKey] = condition[conditionKey].replace(/[&|]/g, (m: any) => chars[m]);
        break;
      case 'tagUpdatedBy':
        if (filterMethod.value === 'is') {
          condition = must;
        } else {
          condition = mustNot;
        }
        conditionKey = filterBy.value;
        if (_.isEmpty(condition[conditionKey])) {
          condition[conditionKey] = '';
        } else {
          condition[conditionKey] += '|';
        }
        condition[conditionKey] += filterValue;
        condition[conditionKey] = condition[conditionKey].split('|');
        break;
      case 'takedowns':
        filterValue = filterValue.replace(/ /g, '');
        [must.takeDownCountStart, must.takeDownCountEnd] = filterValue.split(',');
        break;
      default:
        break;
    }
  });
  return { must, mustNot };
};

export default class CheckPhishService {
  getUrlScanData = (type: SCANTYPE, query: any, filters: IFilter[] = [], sort: any = undefined) => {
    const { startDate, endDate, pageNumber = 0, pageSize = 15 } = query;
    const { must, mustNot } = setUrlScanTableFilter(filters);
    const sortBy = setScanTableSort(sort);
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          type,
          startDate,
          endDate,
          timezone: moment.tz.guess(),
          pageNumber,
          pageSize,
        },
        must,
        mustNot,
        sortBy,
      }),
    };
    return fetch('/platform-api/v1/live-scan/get-scan-data', requestOptions).then(handleResponse);
  };

  getSummaryData = (query: any) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    const api = '/platform-api/v1/live-scan/get-summary-data';
    return fetch(api, requestOptions).then(handleResponse);
  };

  getWidgetsData = (query: any) => {
    const {
      startDate = moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate = moment().format('YYYY-MM-DD'),
    } = query;
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: {
          startDate,
          endDate,
          timezone: moment.tz.guess(),
        },
      }),
    };
    const api = '/platform-api/v1/live-scan/get-widgets-data';
    return fetch(api, requestOptions).then(handleResponse);
  };
}

import React, { useState, useRef, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { TableApi, ITableApiColumn } from '../../../Common/Table/table.api';
import { TableContextProvider } from '../../../Common/Table/table.context';
import { AuthenticationWrapper } from '../../../AuthenticationWrapper';
import { Card } from 'react-bootstrap';
import {
  DARK_WEB_TABLE_NAME,
  DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  DEFAULT_SEARCH_FIELDS,
} from '../../DarkWebConstants';
import { IFilter, TExportTable } from '../../../Common/Table/constant';
import DarkWebService from '../../../../services/darkWeb.service';
import { EDarkWebFindingStatus, IDarkWebFindingData } from '../Types/DarkWeb.types';
import {
  TITLE_COLUMN,
  RISK_COLUMN,
  CATEGORY_COLUMN,
  STATUS_COLUMN,
  THREAT_ACTOR_COLUMN,
  DISCOVERY_DATE_COLUMN,
  PLATFORM_COLUMN,
  SEARCH_TERM_COLUMN,
  SENSITIVE_DATA_EXISTS_COLUMN,
} from '../Common/TableColDefs/DarkWebColDefs';
import { AppState } from '../../../../helpers';
import { alertActions, dashboardActions } from '../../../../actions';
import { ThemeModes, appConstants } from '../../../../constants';
import ChangeStatusModal from '../Common/ChangeStatusModal';
import { Dropdown, IDropdownOption } from '../../../Common/Dropdown';
import {
  capitalize,
  featureIsAvailable,
  getProvidedTagLabelValue,
  getUsersListLabelValue,
} from '../../../../constants/util';
import FindingTablesCategoryFilter from '../Common/FindingTablesCategoryFilter';
import DarkWebFindingStatus from '../Common/DarkWebFindingStatus';
import UgcService from '../../../../services/ugc.service';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import { ExportScanHistoryModal } from '../../../MonitorAndTakedown/ExportScanHistoryModal';
import { Nullable } from '../../../../types/common';
import { ExportScanDetails } from '../../../MonitorAndTakedown/types/export-scan-details.interface';
import Tags, { ETagsTypes, ITags } from '../../../Common/Tags/Tags';
import ThemeContext from '../../../../context/ThemeContext';
import {
  TAGS_AUTHOR_COLUMN,
  TAGS_COLUMN,
} from '../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import DeleteCustomTagsModal from '../../../Common/Tags/DeleteCustomTagsModal';
import EditCustomTagsModal from '../../../Common/Tags/EditCustomTagsModal';
import { getBreachDescriptions } from '../../../DarkWeb_v2/Components/Common/darkweb-requests';
import { setBreachDescriptions } from '../../../../reducers/darkWeb.reducer';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';

const darkWebService = new DarkWebService();
const ugcService = new UgcService();

const columns: ITableApiColumn[] = [
  TITLE_COLUMN,
  PLATFORM_COLUMN,
  RISK_COLUMN,
  CATEGORY_COLUMN,
  STATUS_COLUMN,
  SEARCH_TERM_COLUMN,
  THREAT_ACTOR_COLUMN,
  DISCOVERY_DATE_COLUMN,
  SENSITIVE_DATA_EXISTS_COLUMN,
];
interface IDarkWebFindingsTableProps {
  id: string;
  status: EDarkWebFindingStatus;
}

function DarkWebFindingsTable(props: IDarkWebFindingsTableProps) {
  const { id, status } = props;
  const title = `${capitalize(status.toLowerCase())} Findings`;
  window.document.title = `${title} Findings | Dark Web`;

  const { selectedTheme } = useContext(ThemeContext);
  const [totalCounts, setTotalCounts] = useState<number>(0);
  const [apiTs, setApiTs] = useState<number>(moment().valueOf());
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [newStatus, setNewStatus] = useState<EDarkWebFindingStatus>(status);
  const [changeStatusModalShown, setChangeStatusModalShown] = useState<boolean>(false);
  const [exportQuery, setExportQuery] = useState<Nullable<ExportScanDetails>>(null);
  const displayLayoutRef = useRef(true);

  const dispatch = useAppDispatch();

  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);
  const providedTags = useAppSelector((state: AppState) => state.tagsReducer.allPlatformTags);
  const usersList = useAppSelector((state: AppState) => state.dashboardReducer.usersList);
  const currentFetchOptions = useAppSelector(
    (state: AppState) => state.dashboardReducer.currentFetchOptions,
  );

  const toggleChangeStatusModal = () => {
    setChangeStatusModalShown(!changeStatusModalShown);
  };
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);

  const fetchApi = (query: any, filters: IFilter[], sort: any) => {
    const options = {
      type: EDarkWebFindingStatus[status],
      filters,
      sort,
      query,
    };
    dispatch(dashboardActions.setCurrentFetchOptions(options));
    return darkWebService
      .getFindings(status, query, filters, sort, {
        includeFields: DEFAULT_SEARCH_FIELDS,
      })
      .then((res: any) => {
        let data: any[] = [];
        let total = 0;
        if (res) {
          data = res?.findings.map((finding: any) => {
            return {
              ...finding,
              url_sha256: finding.content_sha_256,
            };
          });
          total = _.get(res, ['metadata', 'total'], res?.findings?.length);
        }
        setTotalCounts(total);
        return { data, total };
      });
  };

  const exportApi = (
    query: any,
    filters: IFilter[],
    sort = undefined,
    type: TExportTable,
    columns: any[],
  ) => {
    const exportCSVTitle = `Bolster Darkweb ${title} ${moment().format('YYYYMMDDhhmmss')}`;
    setExportQuery({
      fetchType: EDarkWebFindingStatus[status],
      query,
      filters,
      sort,
      columns,
      fileName: exportCSVTitle,
    });
  };
  const fetchForExport = async (pageNumber: number, pageSize: number) => {
    const { query, filters, sort } = currentFetchOptions;
    const data = await darkWebService
      .exportFindings(status, { ...query, pageSize, pageNumber }, filters, sort, [
        'brand_id',
        'category_id',
        'category',
        'content_sha_256',
        'created_ts',
        'highlights',
        'keywords',
        'network',
        'platform_published_ts',
        'platform',
        'risk_level',
        'risk_score',
        'search_term_id',
        'search_term',
        'sha256',
        'source_specific_info.has_sensitive_data',
        'source',
        'status',
        'sub_category_id',
        'sub_category',
        'tags',
        'threat_actor',
        'title',
        'updated_ts',
      ])
      .then((res: any) => {
        return res?.findings.map(
          (item: {
            source_specific_info: { has_sensitive_data: string };
            title: string;
            threat_actor: string;
          }) => {
            return {
              ...item,
              title: item?.title?.replace('\n', ' ') || '--',
              has_sensitive_data: item?.source_specific_info?.has_sensitive_data || '--',
              threat_actor: item?.threat_actor || '--',
            };
          },
        );
      });
    return { data };
  };

  const onItemCheck = (checked: boolean, item: any, selectedItems: any) => {
    setSelectedItems(selectedItems);
  };

  const onChangeStatusSuccess = () => {
    setSelectedItems([]);
    setChangeStatusModalShown(false);
    setTimeout(() => {
      setApiTs(moment().valueOf());
    }, 1000);
  };

  const isReadOnlyUser = useReadOnlyUser();

  const customTools = [
    <Dropdown
      boxStyle
      variant='primary'
      fixedPlaceholder={'BULK ACTION'}
      key={'change dark web status'}
      options={_.map(EDarkWebFindingStatus, value => ({
        label: `Mark as ${capitalize(value.toLowerCase())}`,
        value,
        disabled: value === status,
      }))}
      disabled={selectedItems.length === 0 || isReadOnlyUser}
      onChange={selection => {
        toggleChangeStatusModal();
        setNewStatus(selection.value);
      }}
    />,
  ];

  const modifyCols = async (columns: any) => {
    const statusCol = _.find(columns, ['id', 'status']);
    const tagsColumn = _.find(columns, ['id', 'tags.id']);
    const tagsAuthorColumn = _.find(columns, ['id', 'Tags Author']);

    const isCustomTagFeatureAvailable = featureIsAvailable(
      user,
      appConstants.FEATURE_CODE.CUSTOMER_GENERATED_TAGS,
    );
    const filteredTags = providedTags.filter((tag: ITags) => {
      if (isCustomTagFeatureAvailable) {
        //TODO : Remove this logic when custom tag feature is available for all users
        if (tag.type !== ETagsTypes.BOLSTER_RESTRICTED) {
          return true;
        }
      } else if (tag.type === ETagsTypes.BOLSTER_PROVIDED) {
        return true;
      }
    });
    const tagsDropdown = getProvidedTagLabelValue(
      filteredTags,
      selectedTheme === ThemeModes.DARK.toLowerCase(),
    );
    if (!(tagsColumn && tagsDropdown.length && tagsAuthorColumn)) {
      columns.push(
        {
          ...TAGS_COLUMN,
          filterOptions: tagsDropdown,
          render: (rowData: any) => {
            return (
              <Tags
                leftAligned={displayLayoutRef.current}
                rowData={rowData}
                type={appConstants.CONTENT_TYPE.DARK_WEB}
              />
            );
          },
        },
        {
          ...TAGS_AUTHOR_COLUMN,
          filterOptions: getUsersListLabelValue(usersList),
        },
      );
    }
    if (statusCol) {
      statusCol.render = (data: IDarkWebFindingData) => {
        return (
          <DarkWebFindingStatus
            data={data}
            status={data.status}
            placement='right-start'
            showChevron
            onSubmit={onChangeStatusSuccess}
          />
        );
      };
    }
    const searchTermsColumn = _.find(columns, ['id', 'search_term']);
    if (searchTermsColumn) {
      searchTermsColumn.filterOptions = await ugcService
        .getSearches(ugcType.Dark_Web)
        .then((res: any) => {
          return _.chain(res)
            .map((item): IDropdownOption => {
              const { searchTerm, id, category, option } = item;
              return {
                label: `${searchTerm} - ${category?.label} ${
                  option?.options ? '- ' + option?.options?.label : ''
                } `,
                value: id,
              };
            })
            .uniqBy('label')
            .value();
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    return columns;
  };
  return (
    <AuthenticationWrapper>
      <div className={'page-content dashboard-page'}>
        <Card className={'dashboard-table-container'}>
          <TableContextProvider
            columns={columns}
            dashboardName={DARK_WEB_TABLE_NAME}
            tableId={id}
            modifyColumns={modifyCols}
            onItemCheck={onItemCheck}
          >
            <TableApi
              {...props}
              title={title}
              enableCheckbox
              columns={columns}
              indexBy={'content_sha_256'}
              onItemCheck={onItemCheck}
              apiTs={apiTs}
              tableIndex={'darkweb_brand_info'}
              fetchApi={fetchApi}
              disableDatePicker
              customTools={customTools}
              customFilterSystem={
                window.location.pathname.includes(DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME)
                  ? FindingTablesCategoryFilter
                  : null
              }
              customFilterSystemProps={{ status }}
              exportApi={exportApi}
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              user={user}
              type={appConstants.CONTENT_TYPE.DARK_WEB}
              darkwebStatus={status}
            />
          </TableContextProvider>
        </Card>
      </div>
      <ChangeStatusModal
        data={selectedItems}
        show={changeStatusModalShown}
        status={newStatus}
        onCancel={toggleChangeStatusModal}
        onSubmit={onChangeStatusSuccess}
      />
      <ExportScanHistoryModal
        show={exportQuery != null}
        details={exportQuery}
        onComplete={() => setExportQuery(null)}
        onCancel={() => setExportQuery(null)}
        maxCounts={totalCounts}
        fetchForExport={fetchForExport}
        type={appConstants.CONTENT_TYPE.DARK_WEB}
      />
      {modifyCustomTag.isDeleteMode && <DeleteCustomTagsModal />}
      {modifyCustomTag.isEditMode && <EditCustomTagsModal />}
    </AuthenticationWrapper>
  );
}

export default DarkWebFindingsTable;

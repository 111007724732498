import { CellClickedEvent } from 'ag-grid-community';
import React, { useCallback } from 'react';
import { useAppDispatch } from '../../../../helpers/hooks';
import { setCurrentRowIndex } from '../../../../reducers/table.reducer';

export default function useOnAgCellClicked() {
  const dispatch = useAppDispatch();

  const onCellClicked = useCallback(
    (params: CellClickedEvent) => {
      const colID = params.column.getColId();
      if (colID === 'platform') {
        dispatch(setCurrentRowIndex(params.rowIndex as number));
      }
    },
    [dispatch],
  );
  return { onCellClicked };
}

export enum NETWORK_PROVIDERS {
  FASTLY = 'fastly',
  ALIBABA = 'alibaba',
  OVH_HOSTING = 'ovh',
  NAMECHEAP = 'namecheap',
  MULTACOM_CORPORATION = 'multacom',
  AWS_HOSTING = 'aws',
  WEEBLY = 'weebly',
  CLOUDFLARE = 'cloudflare',
  GOOGLE = 'google',
  AMAZON = 'amazon',
  INTERSERVER = 'interserver',
}

export const networkToLinkMapper = {
  [NETWORK_PROVIDERS.FASTLY]:
    'https://community.bolster.ai/t/how-to-article-takedown-phishing-urls-hosted-on-fastly/40',
  [NETWORK_PROVIDERS.ALIBABA]:
    'https://community.bolster.ai/t/how-to-article-takedown-phishing-urls-hosted-on-alibaba/39',
  [NETWORK_PROVIDERS.OVH_HOSTING]:
    'https://community.bolster.ai/t/how-to-article-takedown-phishing-urls-hosted-on-ovh-hosting/34',
  [NETWORK_PROVIDERS.NAMECHEAP]:
    'https://community.bolster.ai/t/how-to-article-takedown-phishing-urls-domains-registered-on-namecheap/38',
  [NETWORK_PROVIDERS.MULTACOM_CORPORATION]:
    'https://community.bolster.ai/t/how-to-article-takedown-phishing-urls-hosted-on-multacom-corporation/33',
  [NETWORK_PROVIDERS.AWS_HOSTING]:
    'https://community.bolster.ai/t/how-to-do-a-phishing-takedown-on-amazon-aws-hosting/31/2',
  [NETWORK_PROVIDERS.AMAZON]:
    'https://community.bolster.ai/t/how-to-do-a-phishing-takedown-on-amazon-aws-hosting/31/2',
  [NETWORK_PROVIDERS.WEEBLY]:
    'https://community.bolster.ai/t/how-to-article-takedown-phishing-urls-hosted-on-weebly/32',
  [NETWORK_PROVIDERS.CLOUDFLARE]:
    'https://community.bolster.ai/t/help-with-removing-a-phishing-page-on-cloudflare/42',
  [NETWORK_PROVIDERS.GOOGLE]: 'https://community.bolster.ai/t/google-hosting-services/49',
  [NETWORK_PROVIDERS.INTERSERVER]:
    'https://community.bolster.ai/t/how-to-article-takedown-phishing-urls-hosted-on-interserver/50',
};

export const mapNetworkProviderToLink = (networkProvider: string) => {
  const searchTerms = networkProvider
    .toLocaleLowerCase()
    .replace(',', '')
    .replace('.', '')
    .split(' ');
  let itemFound = null;
  searchTerms.forEach((item: any) => {
    Object.entries(NETWORK_PROVIDERS).forEach(([key, value]) => {
      if (value === 'aws') {
        if (item.startsWith(value)) {
          itemFound = networkToLinkMapper[value];
        }
      } else {
        if (item.includes(value)) {
          itemFound = networkToLinkMapper[value];
        }
      }
    });
  });
  return { link: itemFound };
};

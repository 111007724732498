import * as React from 'react';
import { Alert } from '../../constants';

export const AlertComponent: React.FC<Alert> = (props: Alert) => {
  return (
    <div className='alert-message'>
      {props.message && <div className={`alert ${props.type}`}>{props.message}</div>}
    </div>
  );
};

import React, { useContext } from 'react';
import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes } from '../../../../constants';

export function ReadOnlyTeaserPage({ featureName }: { featureName: string }) {
  const selectedTheme = useContext(ThemeContext).selectedTheme;
  const isDarkMode = selectedTheme === ThemeModes.DARK.toLowerCase();

  return (
    <div className={'social-media-intro-container'}>
      <div className={'intro-text-wrapper'}>
        <div className={'social-media-protection-header'}>{`${featureName} Dashboard`}</div>
        <div
          className={'social-media-protection-description'}
        >{`You do not have permission to access ${featureName} module. Please contact your administrator to request access.`}</div>
      </div>

      <div className={'social-media-protection-image-container'}>
        {
          <img
            alt='media-tryout-image'
            src={`/teaserImg/${featureName}${isDarkMode ? '-dark' : ''}.png`}
            className={'social-media-protection-image'}
          />
        }
      </div>
    </div>
  );
}

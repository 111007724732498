import { alertConstants, Alert } from '../constants';
import { Record } from 'immutable';
import moment from 'moment';
import _ from 'lodash';

export interface IAlertState {
  alerts: Alert[];
}

const initialStateValue: IAlertState = {
  alerts: [],
};

class InitialState extends Record(initialStateValue) implements IAlertState {}

const initialState = new InitialState();

const alert = (state = initialState, action: any) => {
  const alerts = state.alerts.concat(); //duplicate the alerts array.
  const { type, message, domElement = null } = action;
  const id = moment().valueOf();
  switch (type) {
    case alertConstants.SUCCESS:
      alerts.push({
        type,
        message,
        domElement,
        id,
      });
      return state.set('alerts', alerts);
    case alertConstants.ERROR:
      alerts.push({
        type,
        message,
        id,
      });
      return state.set('alerts', alerts);
    case alertConstants.INFORMATION:
      alerts.push({
        type,
        message,
        id,
      });
      return state.set('alerts', alerts);
    case alertConstants.CLEAR:
      _.remove(alerts, function (alert) {
        return alert.id === action.alertId;
      });
      return state.set('alerts', alerts);
    case alertConstants.CUSTOM:
    case alertConstants.REQUIRED_ACTION:
    case alertConstants.RELEASE_NOTE_ACTION:
      if (_.findIndex(alerts, ['type', type]) === -1) {
        alerts.push({
          type,
          message,
          domElement,
          id,
        });
      }
      return state.set('alerts', alerts);
    default:
      return state;
  }
};

export { alert };

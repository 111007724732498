import React from 'react';
import Review from '../../../Ugc/Components/Findings/Review';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
  SOCIAL_MEDIA_PATHNAME,
  SOCIAL_MEDIA_TABLE_NAME,
} from '../../constants';

const SocialMediaReview = () => {
  return (
    <Review
      type={ugcType.Social}
      tableId={SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID}
      urlColPathName={SOCIAL_MEDIA_PATHNAME}
      tableName={SOCIAL_MEDIA_TABLE_NAME}
      enableSocInterface
    />
  );
};

export default SocialMediaReview;

import React, { useState, useContext, useRef, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import './LandingPage.scss';
import { AppState } from '../../helpers';
import { Button } from 'react-bootstrap';
import { AuthenticationWrapper } from '..';
import AppSettingContext from '../../context/AppSettingContext';
import { Dropdown, IDropdownOption } from '../Common/Dropdown';
import { PageTitle } from '../Common/PageTitle';
import { DRAWER_SUB_ITEMS } from '../Drawer/RouteOptions';
import { connect } from 'react-redux';
import { DashBoardDto, featureIsAvailable, userDoesNotHasTyposquatData } from '../../constants';

interface ILandingPageProps {
  user: DashBoardDto;
}

const LandingPage = ({ user }: ILandingPageProps) => {
  const [timestamp, setTimestamp] = useState<number>(moment().valueOf());
  const [landingPageOptions, setLandingPageOptions] = useState<IDropdownOption[]>([]);
  const [currentLandingPage, setCurrentLandingPage] = useState<IDropdownOption | undefined>();

  const appSetting = useContext(AppSettingContext);
  const { landingPage, saveLandingPage } = appSetting;
  const selectedLandingPage = useRef(currentLandingPage);

  useEffect(() => {
    const options: IDropdownOption[] = [];
    _.forEach(DRAWER_SUB_ITEMS(user, userDoesNotHasTyposquatData(user)), (drawItem, key) => {
      const idx = _.findIndex(drawItem, (item: any) => {
        return item.label === 'Dashboard' && featureIsAvailable(user, item.featureCode);
      });
      if (idx !== -1) {
        options.push({
          label: `${key} ${drawItem[idx].label}`,
          value: drawItem[idx].href,
        });
      }
    });
    setLandingPageOptions(options);
    setCurrentLandingPage(_.find(options, { value: landingPage }));
    selectedLandingPage.current = _.find(options, { value: landingPage });
  }, [user]);

  useEffect(() => {
    onReset();
  }, [landingPage]);

  const onSelect = (selection: IDropdownOption) => {
    selectedLandingPage.current = selection;
  };

  const onReset = () => {
    selectedLandingPage.current = currentLandingPage;
    setTimestamp(moment().valueOf());
  };

  const onSave = () => {
    if (selectedLandingPage.current?.value !== currentLandingPage?.value) {
      saveLandingPage(selectedLandingPage.current?.value);
      setCurrentLandingPage(
        _.find(landingPageOptions, { value: selectedLandingPage.current?.value }),
      );
    }
  };

  return (
    <AuthenticationWrapper>
      <PageTitle title={'Landing Page'} titleAlwaysShown className={'background-white'} />
      <div className='landing-page'>
        <div className='page-content' key={timestamp}>
          <div>Modify settings to determine the landing page for logged in users</div>
          <div className='pt-1 d-flex align-items-center'>
            <div className={'form-block'}>Landing Page</div>
            <div className={'form-block'}>
              <Dropdown
                options={landingPageOptions}
                ordersPersist
                onChange={onSelect}
                btnClassName='bg-transparent'
                defaultSelection={currentLandingPage}
              />
            </div>
            <div className={'form-block reset'} onClick={onReset}>
              reset
            </div>
            <div className={'form-block'}>
              <Button variant='primary' onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return { user };
};

const connectedLandingPage = connect(mapStateToProps, {})(LandingPage);
export { connectedLandingPage as LandingPage };

import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import DonutChart from '../../../Common/DashboardWidgets/DonutChart';
import { IRunByClientAppType, colors, getColors } from '../../../../constants';
import {
  TColorMap,
  IChartPlaceholderProps,
  TDonutChartDataProps,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import NoDomain from '../../../../assets/icons/NoDomain.svg';
import DashboardService from '../../../../services/dashboard.service';
import ThemeContext from '../../../../context/ThemeContext';
import { Nullable } from '../../../../types/common';
import PriorityWidgetDataContext from '../../../../context/PriorityWidgetDataContext';

const placeholder: IChartPlaceholderProps = {
  icon: NoDomain,
  title: 'No domain data found',
  description:
    'We haven’t detected any counterfeit site domains for this period. Please select another time frame.',
};
export interface IPriority {
  monitoredDomainsForAcquisition?: any;
  enableCommunity: boolean;
}

const DashboardPriority = (props: IPriority) => {
  const { isLoading, priorityData } = useContext(PriorityWidgetDataContext);

  return (
    <DonutChart
      title='Domains Breakdown by Priority (for acquisition)'
      isLoading={isLoading}
      description='New unregistered sites detected by risk priority'
      data={priorityData}
      placeholder={placeholder}
    />
  );
};

export default DashboardPriority;

import React from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setGlobalTakedownRowData } from '../../reducers/table.reducer';
import { ITakedownActions } from './TakedownTimelineContainer';

export default function useOnMonthlyLayoutChange({
  yearlyTakedownEvents,
  rowId,
}: {
  rowId: string;
  yearlyTakedownEvents: ITakedownActions;
}) {
  const globalTakedownRowData = useAppSelector(state => state.tableReducer.globalTakedownRowData);

  const dispatch = useAppDispatch();
  const onMonthlyLayoutChange = (layout: 'monthly' | 'yearly') => {
    dispatch(
      setGlobalTakedownRowData({
        ...globalTakedownRowData,
        [rowId]: {
          data: yearlyTakedownEvents,
          viewMode: layout,
        },
      }),
    );
  };
  return { onMonthlyLayoutChange };
}

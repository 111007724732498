import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Card from 'react-bootstrap/Card';
import DashboardService from '../../services/dashboard.service';
import moment from 'moment';

import './insights.scss';
import './takedown.scss';
import { InsightsDto, capitalize } from '../../constants';
import { Status } from '../Common/Status';
import { LabelAndValue } from '../Common/LabelAndValue';
import TakedownRequestModal from './takedown.request.modal.component';
import TakedownListModal from './takedown.list.modal.component';
import AttachedFileViewer from '../Common/AttachedFileViewer';
import { AttachedFileObject } from '../Common/DisputeStatus';
import { POST_MALICIOUS_PATHNAME } from '../MonitorAndTakedown/constants';
import { EFindingStatus, IFindingInsightDataProps, ugcType } from '../Ugc/Types/ugc.types';
import UgcService from '../../services/ugc.service';
import RequestTakedownModal from '../Ugc/Components/Common/RequestTakedownModal';
import { useReadOnlyUser } from '../../basic-hooks/useUserRoles';

interface ITakedownProps {
  userEmail: string;
  urlSHA256: string;
  scanResults: any;
  allowTakedown?: boolean;
  onSuccess?: (message: string) => void;
  onError?: (error: string) => void;
  providerName?: string;
  type?: string;
}

const Takedown = ({
  userEmail,
  urlSHA256,
  scanResults,
  allowTakedown,
  onSuccess,
  onError,
  providerName = 'Hosting Provider',
  type,
}: ITakedownProps) => {
  const {
    srcURL,
    timestamp,
    brand,
    abuseContact,
    status,
    disposition,
    takedownTS,
    brandId,
    subBrandId,
    brandInfo,
    primaryDomain,
    tld,
    scanEngineDetails,
  } = scanResults;

  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [showAppStoreRequestModal, setShowAppStoreRequestModal] = useState<boolean>(false);

  const [showListModal, setShowListModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [numOfTakedowns, setNumOfTakedowns] = useState<number>();
  const [bolsterTakedowns, setBolsterTakedowns] = useState<any[]>();
  const [userTakedowns, setUserTakedowns] = useState<any[]>();
  const [userTakedownPending, setUserTakedownPending] = useState<boolean>();
  const [allTakedownsComplete, setAllTakedownsComplete] = useState<boolean>();

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [showTakeDownButton, setShowTakeDownButton] = useState<boolean>(false);
  const dashboardService = useRef<DashboardService>();
  const ugcService = new UgcService();
  const isPostMalicious = window.location.pathname.indexOf(POST_MALICIOUS_PATHNAME) !== -1;
  let brandName = '';

  useEffect(() => {
    // initialized DashboardService
    dashboardService.current = new DashboardService();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fetch takedowns
    brandName =
      brandInfo && (brandInfo.brand?.displayName || capitalize(brandInfo.brand?.brandName));

    getTakedownRequests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSHA256, brandInfo]);

  const getTakedownRequests = () => {
    dashboardService.current
      ?.getAbuseReport({
        urlSha256: [urlSHA256],
      })
      .then((abuseData: any) => {
        setNumOfTakedowns(abuseData?.result?.length);
        setBolsterTakedowns(
          abuseData?.result
            ?.filter((report: any) => report.reporting_entity === 'bolster')
            .sort((a: any, b: any) => b.updated_ts - a.updated_ts),
        );

        const userRequested = abuseData?.result
          ?.filter((report: any) => report.reporting_entity === 'user')
          .sort((a: any, b: any) => b.created_ts - a.created_ts);

        setUserTakedowns(userRequested);

        setUserTakedownPending(
          userRequested?.filter((tdr: any) => tdr.status === 'pending')?.length > 0,
        );
        setAllTakedownsComplete(
          abuseData?.result?.filter((tdr: any) => tdr.status === 'pending')?.length === 0,
        );
        showRequestTakedownButton();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setRefreshing(false));
  };

  const handleRequestTakedown = (
    comments?: string,
    files?: File[],
    googleSafeBrowsing?: boolean,
    apwgBlocklist?: boolean,
  ) => {
    if (allowTakedown) {
      // prevent the button being triggered again until update finished
      setRefreshing(true);
      // Showing loader
      setIsLoading(true);
      // send takedown request for web
      dashboardService.current
        ?.postTakedownRequest(
          srcURL,
          timestamp,
          urlSHA256,
          brand,
          abuseContact,
          userEmail,
          primaryDomain,
          tld,
          comments,
          files,
          googleSafeBrowsing,
          apwgBlocklist,
        )
        .then((data: any) => {
          if (data && data.status === 'ok') {
            onSuccess?.('Takedown requested successfully');
          } else {
            onError?.('Takedown request failed. Please contact support@bolster.ai.');
          }
        })
        .catch(err => {
          onError?.('Takedown request failed. Please contact support@bolster.ai.');
          console.log(err);
          setRefreshing(false);
        })
        .finally(() => {
          // get updated takedowns
          setTimeout(() => getTakedownRequests(), 1000);
          setIsLoading(false);
          // hide modal
          setShowRequestModal(false);
        });
    }
  };

  const isSameBrand = () => {
    return (
      brandId === brandInfo?.brand?.brandName ||
      subBrandId === brandInfo?.brand?.brandName ||
      _.some(brandInfo?.brand?.subBrands, ['brand_name', subBrandId])
    );
  };

  const showRequestTakedownButton = () => {
    // It is not depended on the status (from url_crawl_info) any more.

    if (type === ugcType.App_Store) {
      setShowTakeDownButton(
        scanEngineDetails?.status === EFindingStatus.LIVE && !userTakedownPending && isSameBrand(),
      );
      return;
    }

    if (brandInfo?.brand?.brandMapping) {
      validateWebUserTakedownRequest(brandInfo?.brand?.brandMapping);
    } else {
      validateWebUserTakedownRequest(isSameBrand());
    }
  };
  const validateWebUserTakedownRequest = (isValidBrand: boolean) => {
    const showButton: any =
      disposition !== 'clean' &&
      allowTakedown &&
      !userTakedownPending &&
      !isPostMalicious &&
      isValidBrand;

    setShowTakeDownButton(showButton);
  };
  const onTakedownClick = () => {
    if (type === ugcType.App_Store) {
      setShowAppStoreRequestModal(true);
    } else {
      setShowRequestModal(true);
    }
  };
  let statusLabels;
  if (numOfTakedowns) {
    statusLabels = { active: 'Still Live', down: 'Taken Down' };
  }

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <div className={'insights-takedown'}>
      <Card className={'card-between'}>
        <Card.Header className='self-card-header'>
          <div className={'d-flex w-100 justify-content-between align-items-center'}>
            <div className={'d-flex align-items-center with-divider'}>
              <div className={'header'}>Takedown Requests</div>
              <span>{numOfTakedowns}</span>
            </div>
            <div className={'takedown-info d-flex align-items-center with-divider'}>
              <div className='d-flex align-items-center'>
                {statusLabels && statusLabels[status]}
                {allowTakedown && userTakedownPending === true && (
                  <div className={'insights-takedown-status'}>Takedown Requested</div>
                )}
                {allowTakedown &&
                  userTakedownPending === false &&
                  allTakedownsComplete &&
                  takedownTS &&
                  typeof takedownTS === 'number' &&
                  status !== 'active' && (
                    <div className='insights-takedown-timestamp'>
                      {moment(takedownTS).format('DD-MMM-YYYY, h:mm a')}
                    </div>
                  )}
              </div>
              {showTakeDownButton && !isReadOnlyUser && (
                <span
                  className='report-link'
                  onClick={() => refreshing === false && onTakedownClick()}
                >
                  Request Takedown
                </span>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body className='self-card-body'>
          {allowTakedown && brandInfo && userTakedowns && userTakedowns.length > 0 && (
            <div className={'section-row'}>
              <LabelAndValue
                label={`Takedown Request${userTakedowns.length > 1 ? 's' : ''}${
                  brandName ? ` by ${brandName}` : ''
                }`}
                renderDom={
                  <div className='takedown-user-requests'>
                    {userTakedowns.map(utd => (
                      <div
                        className='takedown-user-requests-item'
                        key={`user_takdown_${utd.created_ts}`}
                      >
                        <div>
                          <span className='takedown-user-requests-item-timestamp'>
                            {moment(utd.created_ts).format('DD-MMM-YYYY, h:mm a')}
                          </span>
                          {utd.full_name && (
                            <span className='takedown-user-requests-item-fullname'>
                              {utd.full_name}
                            </span>
                          )}
                        </div>
                        {utd.comments && (
                          <div className='takedown-user-requests-item-comments'>{utd.comments}</div>
                        )}
                        <AttachedFileViewer
                          attachedFiles={utd.attachments?.map(
                            (fileName: string): AttachedFileObject => {
                              return {
                                name: fileName,
                                path: `/platform-api/v1/takedown/${urlSHA256}/attachment/${fileName}`,
                                createdTS: moment(utd.created_ts).format('YYYY-MM-DD HH:mm:ss'),
                              };
                            },
                          )}
                          horizontal
                        />
                      </div>
                    ))}
                  </div>
                }
                direction={'column'}
                noMarginBottom
              />
            </div>
          )}
          <div className={'section-row d-flex with-divider'}>
            <LabelAndValue
              label={'Bolster Takedown Requests'}
              renderDom={
                <div className={'d-flex'}>
                  <span className={'pr-4'}>{bolsterTakedowns?.length || 0}</span>
                  {bolsterTakedowns && bolsterTakedowns.length > 0 && (
                    <span className='d-flex report-link' onClick={() => setShowListModal(true)}>
                      <span>View</span>
                      <span className='arrow' />
                    </span>
                  )}
                </div>
              }
              direction={'column'}
              noMarginBottom
            />
            <LabelAndValue
              label={'Last Bolster Takedown Request'}
              value={
                bolsterTakedowns && bolsterTakedowns.length > 0
                  ? moment(bolsterTakedowns[0].updated_ts).format('DD-MMM-YYYY, h:mm a')
                  : '--'
              }
              direction={'column'}
              noMarginBottom
            />
          </div>
          <div className={'section-row'}>
            <LabelAndValue
              label={`${providerName} Takedown Contact`}
              value={type === ugcType.App_Store ? scanEngineDetails?.takedownContact : abuseContact}
              direction={'column'}
              noMarginBottom
            />
          </div>
        </Card.Body>
      </Card>
      {showRequestModal && (
        <TakedownRequestModal
          show={showRequestModal}
          abuseContact={abuseContact}
          onSubmit={(
            comments?: string,
            files?: File[],
            googleSafeBrowsing?: boolean,
            apwgBlocklist?: boolean,
          ) => handleRequestTakedown(comments, files, googleSafeBrowsing, apwgBlocklist)}
          onCancel={() => setShowRequestModal(false)}
          isLoading={isLoading}
          providerName={providerName}
          type={type}
        />
      )}

      {type === ugcType.App_Store && showAppStoreRequestModal && (
        <RequestTakedownModal
          show={showAppStoreRequestModal}
          data={[scanResults]}
          onCloseModal={() => setShowAppStoreRequestModal(false)}
          type={type}
        />
      )}
      {showListModal && (
        <TakedownListModal
          show={showListModal}
          url={srcURL}
          takedowns={bolsterTakedowns}
          onClose={() => setShowListModal(false)}
        />
      )}
    </div>
  );
};

Takedown.defaultProps = {
  isNonBrandCustomer: true,
  allowTakedown: false,
};

export default Takedown;

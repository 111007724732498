import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  AlertActionsTypes,
  DashBoardDto,
  getNavPathnameForInsightPage,
  getTimestampAndUrlSHA256,
  InsightsDto,
} from '../../constants';
import { ELoggedIn } from '../../reducers';
import DashboardService from '../../services/dashboard.service';
import { IDropdownOption } from '../Common/Dropdown';
import { Table } from '../Common/Table/table';
import { renderDispositionFilter } from '../Insights/insights.component';
import { ThreatIntelligence } from './threat.component';

export const DEFAULT_FILTER = {
  label: 'All',
  value: 'all',
};

export const DISPOSITION_FILTERS = [
  DEFAULT_FILTER,
  {
    label: 'Phish',
    value: 'phish',
  },
  {
    label: 'Clean',
    value: 'clean',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

interface IProps {
  ip: string;
  host: string;
  scanResults: any;
  urlSHA256: string;
  isPublic?: boolean;
}
interface IInsightsState {
  passiveDNS: object[];
  passiveDNSFilter: IDropdownOption;
  similarURL: object[];
  similarURLFilter: IDropdownOption;
}
interface IPassiveDNS {
  domains: string;
  dispostion: any;
}
interface ISimilarURL {
  urls: any;
  urlSHA256: string;
  disposition: any;
  timestamp: string;
}

const ThreatIntelligenceComponent = (props: IProps) => {
  const dashboardService = new DashboardService();
  const [passiveDNS, setPassiveDNS] = useState<IPassiveDNS[]>();
  const [passiveDNSFilter, setPassiveDNSFilter] = useState(DEFAULT_FILTER);
  const [similarURL, setSimilarURL] = useState<ISimilarURL[]>();
  const [similarURLFilter, setSimilarURLFilter] = useState(DEFAULT_FILTER);
  const [originPassiveDNS, setOriginPassiveDNS] = useState<any[]>([]);
  const [originSimilarURL, setOriginSimilarURL] = useState<any[]>([]);
  const { scanResults, urlSHA256, ip, host, isPublic } = props;
  const PASSIVE_DNS_COLUMNS = [
    {
      header: 'Domains hosted on the same IP address',
      accessor: 'domains',
      isLongText: true,
      render: (data: any) => {
        const { domains } = data;
        if (_.isEmpty(domains)) {
          return '--';
        }
        if (isPublic) {
          return <div>{domains}</div>;
        }
        return (
          <div className={'ip-url-value-wrapper'}>
            <Link to={`${getNavPathnameForInsightPage()}/domain/` + domains}>{domains}</Link>
          </div>
        );
      },
    },
  ];

  const SIMILAR_URLS_COLUMNS = [
    {
      header: 'URLs hosted on the same domain',
      accessor: 'urls',
      isLongText: true,
      render: (data: any) => {
        const { urls } = data;
        if (_.isEmpty(urls)) {
          return '--';
        }
        if (isPublic) {
          return <div>{urls}</div>;
        }
        return (
          <a
            href={
              `${getNavPathnameForInsightPage()}/insights/` + data.timestamp + '/' + data.urlSHA256
            }
          >
            {urls}
          </a>
        );
      },
    },
  ];

  const getPassiveDNS = (data: any) => {
    return Object.keys(data).map(k => ({ domains: k, dispostion: data[k]['disposition'] }));
  };
  const getSimilarURL = (data: any) => {
    if (_.isEmpty(data)) {
      return [];
    }
    return Object.keys(data).map(k => ({
      urls: data[k]['srcUrl'],
      urlSHA256: k,
      disposition: data[k]['disposition'],
      timestamp: data[k]['scanTS'],
    }));
  };
  const onChangeDnsFilter = (filter: IDropdownOption) => {
    let newPassiveDNS = originPassiveDNS.concat();
    if (filter.value === 'other') {
      const blacklist = ['clean', 'phish'];
      newPassiveDNS = _.filter(newPassiveDNS, (item: any) => {
        return blacklist.indexOf(item['dispostion']) === -1;
      });
    } else if (filter.value === 'phish' || filter.value === 'clean') {
      newPassiveDNS = _.filter(newPassiveDNS, (item: any) => {
        return filter.value === item['dispostion'];
      });
    }
    setPassiveDNSFilter(filter);
    setPassiveDNS(newPassiveDNS);
  };
  const onChangeUrlFilter = (filter: IDropdownOption) => {
    let newSimilarURL = originSimilarURL.concat();
    if (filter.value === 'other') {
      const blacklist = ['clean', 'phish'];
      newSimilarURL = _.filter(newSimilarURL, (item: any) => {
        return blacklist.indexOf(item['dispostion']) === -1;
      });
    } else if (filter.value === 'phish' || filter.value === 'clean') {
      newSimilarURL = _.filter(newSimilarURL, (item: any) => {
        return filter.value === item['dispostion'];
      });
    }
    setSimilarURLFilter(filter);
    setSimilarURL(newSimilarURL);
  };
  useEffect(() => {
    if (ip && ip !== '0.0.0.0' && ip !== '--') {
      Promise.all([dashboardService.getPassiveDNS(ip)])
        .then(resp => {
          const [passiveDNS] = resp;
          setOriginPassiveDNS(getPassiveDNS(passiveDNS['result']));
          setPassiveDNS(getPassiveDNS(passiveDNS['result']));
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    }
    if (host && host !== '--' && !isPublic) {
      Promise.all([dashboardService.getRelatedURLsAndIP(host)])
        .then(res => {
          const [relatedUrlsAndIp] = res;
          setOriginSimilarURL(getSimilarURL(_.get(relatedUrlsAndIp, ['result', 'uniqURLMap'])));
          setSimilarURL(getSimilarURL(_.get(relatedUrlsAndIp, ['result', 'uniqURLMap'])));
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    }
  }, [ip, host]);

  return (
    <div className={'my-4 mx-3'}>
      <div>
        <Card className='card-between'>
          <Card.Header>Threat Intelligence</Card.Header>
          <div className='mb-4'>
            <ThreatIntelligence
              hostPhishCount={scanResults.hostPhishCount}
              ipPhishCount={scanResults.ipPhishCount}
              phishingKitsCountOnHost={scanResults.phishingKitsCountOnHost}
              urlSHA256={urlSHA256}
              ip={ip}
            />
          </div>
        </Card>
      </div>
      <Card>
        <Table
          tableClassName='tool-bar-container-padding'
          disableDatePicker={true}
          disableFilter={true}
          data={ip === '0.0.0.0' || ip === '--' || ip === '' ? undefined : passiveDNS}
          title={'Passive DNS'}
          columns={PASSIVE_DNS_COLUMNS}
          customTools={renderDispositionFilter(passiveDNSFilter, onChangeDnsFilter)}
        />
      </Card>
      {!isPublic && (
        <Card>
          <Table
            tableClassName='tool-bar-container-padding'
            disableDatePicker={true}
            disableFilter={true}
            data={ip === '0.0.0.0' || ip === '--' || ip === '' ? undefined : similarURL}
            title={'Similar URLs'}
            columns={SIMILAR_URLS_COLUMNS}
            customTools={renderDispositionFilter(similarURLFilter, onChangeUrlFilter)}
          />
        </Card>
      )}
    </div>
  );
};
export default ThreatIntelligenceComponent;

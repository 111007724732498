import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../../../../../actions';
import { IAlertDispatchProps } from '../../../../../constants';
import RequestTakedownModal from '../../../../Ugc/Components/Common/RequestTakedownModal';

interface IAppStoreTakedownModalProps {
  data: any[];
  show: boolean;
  onCloseModal: () => void;
  type: string;
  onModalSubmit?: (data: any) => void;
}
const AppStoreTakedownModal = ({
  data,
  show,
  onCloseModal,
  type,
  onModalSubmit,
}: IAppStoreTakedownModalProps & IAlertDispatchProps) => {
  return (
    <RequestTakedownModal
      show={show}
      data={data}
      onModalSubmit={onModalSubmit}
      onCloseModal={onCloseModal}
      type={type}
    />
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(undefined, mapDispatchToProps)(AppStoreTakedownModal);

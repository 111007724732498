import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import AttachFile from '../Common/AttachFile';
import { Checkbox } from '../Common/Checkbox';
import ModalOverlay from '../Common/ModalOverlay';
import { ugcType } from '../Ugc/Types/ugc.types';

import './takedown.request.modal.scss';
import { useReadOnlyUser } from '../../basic-hooks/useUserRoles';

export interface ITakedownRequestModalProps {
  show: boolean;
  abuseContact: string[] | string;
  onSubmit: (
    comments?: string,
    files?: File[],
    googleSafeBrowsing?: boolean,
    apwgBlocklist?: boolean,
  ) => void;
  onCancel: () => void;
  isLoading: boolean;
  providerName: string;
  type?: string;
}

const TakedownRequestModal = ({
  show,
  abuseContact,
  onSubmit,
  onCancel,
  isLoading,
  providerName,
  type,
}: ITakedownRequestModalProps) => {
  const [comments, setComments] = useState<string>();
  const [googleSafeBrowsing, setGoogleSafeBrowsing] = useState<boolean>(false);
  const [apwgBlocklist, setApwgBlocklist] = useState<boolean>(false);
  const attachedFiles = useRef<File[]>([]);

  useEffect(() => {
    // clear comments when hide/show
    setComments('');
  }, [show]);

  const handleSubmit = () => {
    onSubmit(comments, attachedFiles.current, googleSafeBrowsing, apwgBlocklist);
  };

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) return null;

  return (
    <ModalOverlay
      show={show}
      title={'Request Takedown'}
      submitButtonLabel={'Confirm'}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    >
      <div className={'takedown-modal'}>
        <div className={'takedown-modal-desc'}>
          Are you sure you want to request a takedown? We’ll send an email to the following address:
        </div>
        <div className={'takedown-modal-contact-label'}>{`${providerName} Takedown Contact:`}</div>
        <div className={'takedown-modal-contact-email mb-3'}>{abuseContact}</div>
        <AttachFile
          label='Attach File'
          onChangeHandler={files => (attachedFiles.current = files)}
        />
        <Form className={'takedown-modal-form'}>
          <Form.Group>
            <Form.Label className={'takedown-modal-form-label'}>Comments</Form.Label>
            <Form.Control
              className={'takedown-modal-form-input'}
              as='textarea'
              rows={3}
              maxLength={1000}
              value={comments}
              onChange={e => setComments(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group className={'align-items-center d-flex flex-row '}>
            <Form.Label className={'takedown-modal-form-label'}>Report URLs to:</Form.Label>
            <Checkbox
              label='Google SafeBrowsing'
              isStateless={false}
              onChange={checked => {
                setGoogleSafeBrowsing(checked);
              }}
              defaultChecked={googleSafeBrowsing}
            />
            <Checkbox
              label='APWG Blocklist '
              isStateless={false}
              onChange={checked => {
                setApwgBlocklist(checked);
              }}
              defaultChecked={apwgBlocklist}
            />
          </Form.Group>
        </Form>
      </div>
    </ModalOverlay>
  );
};

export default TakedownRequestModal;

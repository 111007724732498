import React from 'react';
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { numberWithCommas } from '../../constants';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import './SummaryWidget.scss';

interface ISummaryWidget {
  isLoading: boolean;
  data: ISummaryDTO[];
  customClassName?: string;
}

export interface ISummaryDTO {
  id: string;
  label: string;
  value: string;
  valueDom?: Element;
  thirdRowDom?: Element;
  tooltip: string;
  addButtonDom?: string;
  dateDescription?: string;
  dateId?: string;
}

export const SummaryWidget = ({ isLoading, data, customClassName }: ISummaryWidget) => {
  return (
    <div className={`dashboard-summary-widget-container ${customClassName}`}>
      <Row className='dashboard-summary-widget'>
        <LoadingWrapper isLoading={isLoading}>
          {data.map((item: ISummaryDTO, index) => {
            return (
              <ColumnWithData
                id={item.id}
                label={item.label}
                tooltip={item.tooltip}
                value={item.value}
                valueDom={item.valueDom}
                thirdRowDom={item.thirdRowDom}
                key={index}
                addButtonDom={item.addButtonDom}
                dateDescription={item.dateDescription}
              />
            );
          })}
        </LoadingWrapper>
      </Row>
    </div>
  );
};

export const ColumnWithData = ({
  id,
  label,
  tooltip,
  value,
  valueDom,
  thirdRowDom,
  addButtonDom,
  dateDescription,
}: ISummaryDTO) => {
  return (
    <>
      <div className='summary-widget-item flex-wrap'>
        <div className='summary-widget-item-inner'>
          <div className='summary-widget-upper-wrapper'>
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={'tooltip-' + label} className={'table-source-url-tooltip'}>
                  {tooltip}
                </Tooltip>
              }
            >
              <div className='summary-widget-item-label long-text-ellipsis-1'>{label}</div>
            </OverlayTrigger>
            {addButtonDom && addButtonDom}
          </div>
          <div className='summary-widget-lower-wrapper'>
            {valueDom ? (
              valueDom
            ) : (
              <div className='summary-widget-item-value'>{numberWithCommas(value)}</div>
            )}
            {dateDescription ? (
              <div className='summary-widget-bottom-text'>{dateDescription}</div>
            ) : null}
          </div>
          {thirdRowDom && thirdRowDom}
        </div>
      </div>
    </>
  );
};

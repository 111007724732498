import React, { useState } from 'react';
import _, { groupBy } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IFilter } from '../../../Common/Table/constant';
import {
  LOGO_DETECTION_COLUMN,
  SEARCH_ENGINE_COLUMN,
} from '../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import { UNREGISTERED_DOMAINS } from './MonitoredDomainFunnel';
import { convertToThousand, generateId } from '../../../../constants';
import { InfoIcon } from '../../../../assets/SVGIcons';

const FILTER_FIELDS: IFilter[] = [
  {
    id: generateId(10),
    filterType: 'string',
    filterBy: { label: 'MX Records', value: 'has_mx_records' },
    filterMethod: { label: 'Is', value: 'is' },
    filterValue: 'true',
    filterLabel: 'True',
  },
  {
    id: generateId(10),
    filterType: 'options',
    filterBy: { label: 'Logo Detected', value: LOGO_DETECTION_COLUMN.accessor },
    filterMethod: { label: 'Is', value: 'is' },
    filterValue: 'true',
    filterLabel: 'True',
  },
  {
    id: generateId(10),
    filterType: 'options',
    filterBy: { label: 'Google/Bing Searches', value: SEARCH_ENGINE_COLUMN.accessor },
    filterMethod: { label: 'Is', value: 'is' },
    filterValue: '*_searchengine',
    filterLabel: 'True',
  },
];

const MonitoredDomainsHeader = ({
  title,
  updateFilter,
  deleteFilter,
  monitoredDomains,
}: any): JSX.Element => {
  const domainCountsByGroup = groupBy(monitoredDomains, 'label');
  const totalVariants = _.first(domainCountsByGroup[UNREGISTERED_DOMAINS])?.subCount?.count;

  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const toggleFilter = (filter: IFilter) => {
    const filterByValue = filter.filterBy.value;
    const index = activeFilters.indexOf(filterByValue);

    if (index < 0) {
      activeFilters.push(filterByValue);
      updateFilter(filter, true);
    } else {
      activeFilters.splice(index, 1);
      deleteFilter(filterByValue);
    }

    setActiveFilters(activeFilters);
  };

  return (
    <div className='monitored-domains-header'>
      <div className='monitored-domains-title'>
        <div className='title-container'>
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-unregister`} className='monitor-domains-icon-tooltip'>
                {`We monitor registered domains daily to detect changes that may move domains to the right. We also monitor all ${convertToThousand(
                  totalVariants,
                )} domain variations weekly to refresh the set of sites
                  recommended for purchase.`}
              </Tooltip>
            }
          >
            <div className='icon'>
              <InfoIcon color='grey' />
            </div>
          </OverlayTrigger>
          <div className='title-wrapper'>{title}</div>
        </div>
        <div className='filter-container'>
          <span>Filter domains by:</span>
          {FILTER_FIELDS.map(field => {
            return (
              <button
                key={field.filterBy.value}
                className={`btn btn-default ${
                  activeFilters.includes(field.filterBy.value) ? 'active' : ''
                }`}
                onClick={() => toggleFilter(field)}
              >
                {field.filterBy.label}
              </button>
            );
          })}
        </div>
      </div>
      <div className='monitored-domains-actions'>
        <div className='action-circle' />
        <div className='action-label'>Bolster actions</div>
      </div>
    </div>
  );
};

export default MonitoredDomainsHeader;

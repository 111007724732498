import React, { useState, useEffect, useContext, useCallback } from 'react';
import _ from 'lodash';
import CardContent from './CardContent';
import { history } from '../../../../helpers';

import { replaceDashWithSpace, setLocalStorageValue, ThemeModes } from '../../../../constants';
import {
  EFindingStatus,
  IUgcThreatContent,
  IThreatDetections,
  ugcType,
} from '../../Types/ugc.types';
import { MUST_NOT_FILTER_METHOD_OPTIONS } from '../../../Common/Table/filter.controller.edit.item';
import ThemeContext from '../../../../context/ThemeContext';
import {
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
} from '../../../SocialMedia/constants';
import CheckPhishContactUsContext from '../../../../context/CheckPhishContactUsContext';
import {
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
} from '../../../Marketplace/constants';
import {
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  APP_STORE_TAKEN_DOWN_TABLE_ID,
} from '../../../AppStore/constants';
import { IFilter } from '../../../Common/Table/constant';
import { transformOldIFiltersToUgcAgFilterModel } from '../../../Common/Table/ag-table/ag-utils';
import {
  setIsFilterFromDashboard,
  setNumOfOtherCategories,
} from '../../../../reducers/table.reducer';
import { useAppDispatch } from '../../../../helpers/hooks';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
import { othersCategoryLabel } from '../../../Common/Table/ag-table/constants';

const LIVE_DETECTIONS = 'Live Detection';
const TAKEDOWN_PROGRESS = 'Takedown in Progress';
const SUCCESS_TAKEDOWN = 'Taken Down';

const APP_STORE_ORDER = ['BRAND', 'KNOWN', 'ROGUE'];

const cardTopBarLightModeColors = {
  0: 'linear-gradient(135deg, #EE4526 -1.09%, #ff4617 100%)',
  1: 'linear-gradient(135deg, #ff4617 -1.09%, #ff6a0e 100%)',
  2: 'linear-gradient(135deg, #ff6a0e -1.09%, #ff9007 100%)',
  3: 'linear-gradient(135deg, #ff9007 -1.09%, #fcc64d 100%)',
  4: 'linear-gradient(89.92deg, #FFB801 0.05%, #22C197 97.44%)',
  5: 'linear-gradient(90deg, #22C188 0%, #00DFFF 97.51%)',
  6: 'linear-gradient(90deg, #76E6FF 61.5%, #31A1F1 107%)',
};
const cardTopBarDarkModeColors = {
  0: 'linear-gradient(90deg, #FF271E 3.44%, #FF4418 97.15%)',
  1: 'linear-gradient(90deg, #FF4518 3.44%, #FF6312 97.15%)',
  2: 'linear-gradient(90deg, #FF6D0F 3.44%, #FF8E09 97.15%)',
  3: 'linear-gradient(90deg, #FF9408 3.44%, #FFB401 97.15%)',
  4: 'linear-gradient(89.92deg, #FFB801 0.05%, #22C197 97.44%)',
  5: 'linear-gradient(90deg, #22C188 0%, #00DFFF 97.51%)',
  6: 'linear-gradient(90deg, #76E6FF 61.5%, #31A1F1 107%)',
};

const UgcThreatContent = ({
  filters,
  ugcCategories,
  threatDetections,
  type,
  query,
}: IUgcThreatContent): JSX.Element => {
  const [combinedFilters, setCombinedFilters] = useState<any>({});
  const [topThreeCategories, setTopThreeCategories] = useState<IThreatDetections[]>([]);
  const [otherCategories, setOtherCategories] = useState<IThreatDetections[]>([]);
  const [otherCategoryCount, setOtherCategoryCount] = useState<number>(0);
  const [takendownInProgress, setTakendownInProgress] = useState<IThreatDetections[]>([]);
  const [takeDownCounts, setTakeDownCounts] = useState<IThreatDetections[]>([]);
  const dispatch = useAppDispatch();
  const isDemo = window.location.pathname.includes('premium');
  const { monitoredDomain, setContactUsMesage, setShowContactUsPage } = useContext(
    CheckPhishContactUsContext,
  );

  const triggerCheckphishContactUs = () => {
    setContactUsMesage(`For access to additional features, contact us.`);
    setShowContactUsPage(true);
  };
  let cardTopBarColors = {};
  const { selectedTheme } = useContext(ThemeContext);

  if (selectedTheme === ThemeModes.DARK.toLowerCase()) {
    cardTopBarColors = { ...cardTopBarDarkModeColors };
  } else {
    cardTopBarColors = { ...cardTopBarLightModeColors };
  }

  const initialFilters = {
    filterBy: {
      label: 'Category',
      value: type === ugcType.App_Store ? 'platform_label' : 'category_labels',
    },
    filterErr: '',
    filterMethod: { label: 'Is', value: 'is' },
    filterType: 'string',
  };

  const routes: object = {
    [ugcType.Social]: {
      [LIVE_DETECTIONS]: {
        pathName: '/social-media/detection',
        tableId: SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
      },
      [TAKEDOWN_PROGRESS]: {
        pathName: '/social-media/takedown-in-progress',
        tableId: SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
      },
      [SUCCESS_TAKEDOWN]: {
        pathName: '/social-media/takendown',
        tableId: SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
      },
    },
    [ugcType.Marketplace]: {
      [LIVE_DETECTIONS]: {
        pathName: '/marketplace/detection',
        tableId: MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
      },
      [TAKEDOWN_PROGRESS]: {
        pathName: '/marketplace/takedown-in-progress',
        tableId: MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
      },
      [SUCCESS_TAKEDOWN]: {
        pathName: '/marketplace/takendown',
        tableId: MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
      },
    },
    [ugcType.App_Store]: {
      [LIVE_DETECTIONS]: {
        pathName: '/app-store/detection',
        tableId: APP_STORE_FINDING_DETECTION_TABLE_ID,
      },
      [TAKEDOWN_PROGRESS]: {
        pathName: '/app-store/takedown-in-progress',
        tableId: APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
      },
      [SUCCESS_TAKEDOWN]: {
        pathName: '/app-store/takendown',
        tableId: APP_STORE_TAKEN_DOWN_TABLE_ID,
      },
    },
  };
  const bringFilterToTable = useCallback(
    (allFilters: IFilter[], routeObject: any, label: string) => {
      const agFilterModel = transformOldIFiltersToUgcAgFilterModel(allFilters, query, type, label);
      if (label === othersCategoryLabel && type === ugcType.Social)
        dispatch(setNumOfOtherCategories(agFilterModel['category_labels']?.conditions?.length));
      setLocalStorageValue(['tableFilterModel', routeObject?.tableId], agFilterModel);
      dispatch(setIsFilterFromDashboard(true));
    },

    [dispatch, query],
  );
  useEffect(() => {
    let sortedLiveThreatDetections: IThreatDetections[] = [];
    if (type === ugcType.App_Store) {
      sortedLiveThreatDetections = threatDetections
        ?.filter((item: IThreatDetections) => item.type === LIVE_DETECTIONS)
        .sort((itemA: IThreatDetections, itemB: IThreatDetections) => {
          let indexA = APP_STORE_ORDER.findIndex((key: string) =>
            itemA.label.toUpperCase().includes(key),
          );
          let indexB = APP_STORE_ORDER.findIndex((key: string) =>
            itemB.label.toUpperCase().includes(key),
          );
          if (indexA < 0) indexA = APP_STORE_ORDER.length;
          if (indexB < 0) indexB = APP_STORE_ORDER.length;

          return indexA - indexB;
        });
    } else {
      sortedLiveThreatDetections = threatDetections
        ?.filter((item: IThreatDetections) => item.type === LIVE_DETECTIONS)
        .sort((itemA: IThreatDetections, itemB: IThreatDetections) => itemB.count - itemA.count);
    }
    setOtherCategories(sortedLiveThreatDetections?.slice(3, sortedLiveThreatDetections.length));
    setTopThreeCategories(sortedLiveThreatDetections?.slice(0, 3));
    setOtherCategoryCount(
      sortedLiveThreatDetections?.slice(3).reduce((prev, acc) => prev + acc.count, 0),
    );
    setTakendownInProgress(
      threatDetections?.filter((item: IThreatDetections) => item.type === TAKEDOWN_PROGRESS),
    );
    setTakeDownCounts(
      threatDetections?.filter((item: IThreatDetections) => item.type === SUCCESS_TAKEDOWN),
    );
  }, [threatDetections]);

  useEffect(() => {
    const categoryFilters: any = {};
    ugcCategories.map((category: any) => {
      const newFilters = {
        ...initialFilters,
        filterLabel: category.label,
        filterValue: type === ugcType.App_Store ? category.label : category.id,
        filterType: 'options',
      };
      const key = category.label.toUpperCase();
      categoryFilters[key] = [newFilters];
    });
    if (otherCategories?.length) {
      categoryFilters['OTHERS'] = otherCategories.map(c => ({
        filterBy: {
          label: 'Category',
          value: type === ugcType.App_Store ? 'platform_label' : 'category_labels',
        },
        filterLabel: c.label,
        filterMethod: MUST_NOT_FILTER_METHOD_OPTIONS,
        filterValue: type === ugcType.App_Store ? c.label : c.id,
        filterType: 'options',
      }));
    }

    const takendownFilters = {
      [EFindingStatus.PAUSED]: [
        {
          ...initialFilters,
          filterBy: { label: 'Status', value: 'status' },
          filterLabel: 'Takedown Paused',
          filterType: 'options',
          filterValue: 'PAUSED',
        },
      ],
      [replaceDashWithSpace(EFindingStatus.IN_PROGRESS)]: [
        {
          ...initialFilters,
          filterBy: { label: 'Status', value: 'status' },
          filterLabel: 'Takedown in Progress',
          filterType: 'options',
          filterValue: 'IN_PROGRESS',
        },
      ],
    };

    setCombinedFilters({ ...categoryFilters, ...takendownFilters });
  }, [topThreeCategories, otherCategories]);

  const handleRedirect = (parentLabel: string, label: string) => {
    if (isDemo) return triggerCheckphishContactUs();
    if (type === ugcType.App_Store && parentLabel === TAKEDOWN_PROGRESS) {
      history.push(routes[type][parentLabel]?.pathName);
    } else {
      const allFilters = combinedFilters[label] ? [...combinedFilters[label]] : [];
      if (filters.length >= 1) allFilters.push(...filters);
      setLocalStorageValue(['tableSetting', routes[type][parentLabel]?.tableId], {
        ...{ query },
        filters: [...allFilters],
      });
      bringFilterToTable(allFilters, routes[type][parentLabel], label);
      history.push(routes[type][parentLabel]?.pathName);
    }
  };

  const handleHeaderRedirect = (parentLabel: string) => {
    if (isDemo) return triggerCheckphishContactUs();
    setLocalStorageValue(['tableSetting', routes[type][parentLabel]?.tableId], {
      filters: filters,
    });
    bringFilterToTable(filters, routes[type][parentLabel], '');
    history.push(routes[type][parentLabel]?.pathName);
  };

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <div className={'social-threat-content-wrapper'} data-testid='ugc-threat-content'>
      <div className={'social-detect-wrapper'}>
        <div
          className={'social-detect-label'}
          onClick={() => handleHeaderRedirect(LIVE_DETECTIONS)}
        >
          {LIVE_DETECTIONS}
        </div>
        <div className={'social-content-wrapper'}>
          {topThreeCategories?.map((category, index) => {
            return (
              <CardContent
                label={replaceDashWithSpace(category.label)}
                value={category.count}
                bottomText={!isReadOnlyUser ? 'Initiate Takedown' : ''}
                background={cardTopBarColors[index]}
                parentLabel={LIVE_DETECTIONS}
                handleRedirect={handleRedirect}
                key={index}
              />
            );
          })}

          <CardContent
            label={'Others'}
            value={otherCategoryCount}
            bottomText={!isReadOnlyUser ? 'Initiate Takedown' : ''}
            background={`linear-gradient(135deg, #ff9007 -1.09%, #fcc64d 100%)`}
            parentLabel={LIVE_DETECTIONS}
            handleRedirect={handleRedirect}
            key={4}
          />
        </div>
      </div>
      <div className={'vertical-dotted-wrapper'}>
        <div className={'vertical-dotted-line'}></div>
      </div>
      <div className={'social-detect-wrapper'}>
        <div
          className={'social-detect-label'}
          onClick={() => handleHeaderRedirect(TAKEDOWN_PROGRESS)}
        >
          {TAKEDOWN_PROGRESS}
        </div>
        <div className={'social-content-wrapper'}>
          {takendownInProgress?.map((status, index) => {
            if (type === ugcType.App_Store && status.label === 'PAUSED') return null;
            return (
              <CardContent
                label={replaceDashWithSpace(status.label)}
                value={status.count}
                bottomText={status.label === 'PAUSED' ? 'Provide Info to Resume' : null}
                background={cardTopBarColors[index + 3]}
                parentLabel={TAKEDOWN_PROGRESS}
                handleRedirect={handleRedirect}
                key={index + 4}
              />
            );
          })}
        </div>
      </div>
      <div className={'vertical-dotted-wrapper'}>
        <div className={'vertical-dotted-line'}></div>
      </div>
      <div className={'social-detect-wrapper'}>
        <div
          className={'social-detect-label'}
          onClick={() => handleHeaderRedirect(SUCCESS_TAKEDOWN)}
        >
          {SUCCESS_TAKEDOWN}
        </div>
        <div className={'social-content-wrapper'}>
          {takeDownCounts?.map((status, index) => {
            return (
              <CardContent
                label={replaceDashWithSpace(status?.label)}
                value={status?.count}
                background={cardTopBarColors[6]}
                parentLabel={SUCCESS_TAKEDOWN}
                handleRedirect={handleRedirect}
                key={10}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UgcThreatContent;

import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { capitalize } from '../../../../constants';
import DashboardService from '../../../../services/dashboard.service';
import { Nullable } from '../../../../types/common';
import ModalOverlay from '../../../Common/ModalOverlay';
import { invoke } from '../../../../helpers/async';
import { DarkWebTableApiParams } from '../../../Common/Table/ag-table/ag-utils';
import { fetchDarkWebData, massagedDarkWebData } from '../../../Common/Table/ag-table/ag-requests';
import moment from 'moment';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';

const dashboardService = new DashboardService();
export interface IExportFindingsDarkWebV2HistoryModalDependencies {
  apiParams: DarkWebTableApiParams;
  columns: { label: string; field: string }[];
  status: EDarkWebFindingStatus;
  label?: string;
}

interface IExportFindingsDarkWebV2HistoryModalProps {
  show: boolean;
  dependencies?: IExportFindingsDarkWebV2HistoryModalDependencies;
  onCancel: () => void;
  onComplete: () => void;
}

export function ExportFindingsDarkWebV2HistoryModal({
  show,
  dependencies,
  onComplete,
  onCancel,
}: IExportFindingsDarkWebV2HistoryModalProps) {
  const [error, setError] = useState<Nullable<string>>(null);
  const [response, setResponse] = useState<any>(undefined);

  useEffect(() => {
    invoke(async () => {
      setError(null);

      if (dependencies) {
        try {
          const { apiParams, columns, status, label } = dependencies;
          const data = await fetchDarkWebData(apiParams, true);
          setResponse(data);
          const fileName = `Bolster Darkweb ${capitalize(status.toLowerCase())} Findings${
            label ? `(${label})` : ''
          } ${moment().format('YYYYMMDDhhmmss')}`;
          const massagedData = massagedDarkWebData(data.findings);
          await dashboardService.buildTableDataCsv(columns, massagedData, fileName);
          // notify report is built
          setResponse(undefined);
          onComplete();
        } catch (e) {
          console.error(e);
          setError(`Error occurred processing the csv file.`);
        }
      }
    });
  }, [dependencies, onComplete]);

  const body = () => {
    if (error) {
      return <div className={'text-danger'}>{error}</div>;
    } else {
      return (
        <React.Fragment>
          <span className={'mr-1'}>Building the csv export</span>
          {response && <span className={'mr-1'}> ({response.metadata.total})</span>}
          <div data-testid='exporting-spinner'>
            <Spinner variant={'primary'} size={'sm'} animation='border' />
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <ModalOverlay
      show={show}
      title={'Exporting to CSV'}
      onCancel={() => onCancel()}
      onSubmit={() => onComplete()}
      showButtons={false}
      size={'sm'}
    >
      <div>{body()}</div>
    </ModalOverlay>
  );
}

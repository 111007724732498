import React from 'react';
import ModalOverlay from '../../Common/ModalOverlay';

const DeleteBrandPropertyModal = ({
  show,
  selectProptertyType,
  isLoading,
  onDeleteProperty,
  onCloseModal,
  deleteProperty,
}: any) => {
  return (
    <ModalOverlay
      show={show}
      title={'Delete ' + selectProptertyType.label}
      isLoading={isLoading}
      onSubmit={onDeleteProperty}
      onCancel={onCloseModal}
      submitButtonLabel={'Delete'}
      size={'lg'}
    >
      <div className='delete-property-container'>
        <div className='deleting-header-text'>
          {`You have selected`}{' '}
          <span>{`${deleteProperty.length} ${selectProptertyType.label}`}</span> {`to delete.`}
        </div>
        <div className='delete-value-wrapper'>
          {deleteProperty.map((item: any, index: number) => {
            return (
              <div className='deleting-item' key={index}>
                {item.label}
              </div>
            );
          })}
        </div>
        <div className='confirmation-text'>This action can't be undone.</div>
      </div>
    </ModalOverlay>
  );
};

export default DeleteBrandPropertyModal;

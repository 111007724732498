import * as React from 'react';
import './disabled.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoIcon } from '../../assets/SVGIcons';
import { convertToThousand } from '../../constants';
import ThemeContext from '../../context/ThemeContext';
import { useContext } from 'react';

export interface ILoadingDashboardProps {
  step: number;
  domainName: string;
}

const bars = [1, 2, 3, 4, 5, 6, 7, 8];
const filters = ['MX RECORDS', 'LOGO DETECTED', 'GOOGLE/BING SEARCHES'];

const RenderEmptyVerticalBars = (props: {
  disabledBarClass: string;
  disabledClassName: string;
}) => {
  const { disabledClassName, disabledBarClass } = props;
  return (
    <div className={`progress-container ${disabledClassName}`}>
      <div className={disabledBarClass.length ? disabledBarClass : `source-progress-bar`}>
        <div className={`source-description`}></div>
      </div>
    </div>
  );
};

const RenderEmptyFunnel = (props: {
  height: string;
  width: string;
  customClassName: string;
  label: string;
  disabledBarClass: string;
}) => {
  const { customClassName, label, height, width, disabledBarClass } = props;
  const customFunnelStyle = {
    width,
    height,
  };
  return (
    <div style={customFunnelStyle} className={customClassName}>
      <div className={disabledBarClass}>
        <div className={`funnel-content`} onClick={() => {}}>
          <div className='funnel-label-v2'>
            <div
              className='label-text'
              style={{
                paddingLeft: '24px',
              }}
            >
              {label}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingDashboard = ({ step, domainName }: ILoadingDashboardProps) => {
  const selectedTheme = useContext(ThemeContext);
  return (
    <div className='monitiored-container-v2'>
      <div className='processing-layover'>
        <div className='tabs-wrapper'>
          <div className={`tabs disabled-tabs`}>{`Monitored for`} </div>
          <div className='tab-line'></div>
          <div className={`tabs disabled-tabs `}> Monitored Globally </div>
        </div>
        <div className='source-feeds-container'>
          {bars.map((item: any, index: number) => (
            <RenderEmptyVerticalBars
              key={index}
              disabledBarClass={step === 5 && item === 8 ? 'disabled-bar-item' : ''}
              disabledClassName={`disabled-funnel${item} ${
                selectedTheme.themeName === 'Dark' ? 'disabled-dark-theme' : ''
              }`}
            />
          ))}
        </div>
        <div className='monitored-domains-container-v2 '>
          <div className='monitored-domains-header-v2'>
            <div className='monitored-domains-title-v2'>
              <div className='title-container-v2'>
                <div className='title-wrapper-v2 disabled'>Live Scanned Domain {domainName}</div>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-unregister`} className='monitor-domains-icon-tooltip'>
                      {`We monitor registered domains daily to detect changes that may move domains to the right. We also monitor all ${convertToThousand(
                        100000000,
                      )} domain variations weekly to refresh the set of sites
                    recommended for purchase.`}
                    </Tooltip>
                  }
                >
                  <div className='icon'>
                    <InfoIcon color='grey' />
                  </div>
                </OverlayTrigger>
              </div>
              <div className='filter-container disabled'>
                <span>Filter domains by:</span>
                {filters.map(field => {
                  return (
                    <button
                      key={field}
                      className={`btn btn-default round-filter`}
                      onClick={() => {}}
                      disabled={true}
                    >
                      {field}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className='description'>
              Bolster scans, takes down, and continues scanning suspicious/malicious domains.
            </div>
          </div>

          <div className='monitored-domains-funnel-container empty-funnel-wrap'>
            <div className='monitored-domains-funnel-wrapper-v2'>
              <div className='acquisition-wrapper-v2 disabled-wrapper'>
                <RenderEmptyFunnel
                  customClassName={`disabled-box1 empty-funnel disabled-fill ${
                    selectedTheme.themeName === 'Dark' ? 'disabled-dark-theme-box' : ''
                  }`}
                  width='50%'
                  height='100%'
                  label='Scan Pre-Malicious'
                  disabledBarClass={step === 5 ? 'disabled-box1-item' : ''}
                />
                <RenderEmptyFunnel
                  customClassName={`disabled-box2 empty-funnel disabled-fill ${
                    selectedTheme.themeName === 'Dark' ? 'disabled-dark-theme-box' : ''
                  }`}
                  width='25%'
                  height='100%'
                  label='Takedown Malicious'
                  disabledBarClass={step === 5 ? 'disabled-box2-item' : ''}
                />
                <RenderEmptyFunnel
                  customClassName={`disabled-box3 empty-funnel disabled-fill ${
                    selectedTheme.themeName === 'Dark' ? 'disabled-dark-theme-box' : ''
                  }`}
                  width='25%'
                  height='100%'
                  label='Monitor Post-Malicious'
                  disabledBarClass={''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingDashboard;

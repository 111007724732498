import './soc-takedown.scss';
import { AppState } from '../../helpers';
import { alertActions } from '../../actions';
import { connect } from 'react-redux';
import { IAlertDispatchProps } from '../../constants';
import { PageTitle } from '../Common/PageTitle';
import { Accordion, Button, Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { useParams } from 'react-router-dom';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { invoke } from '../../helpers/async';
import { SocEmailTemplate } from './types/soc-email-template.interface';
import { IBrandInfo } from '../../constants/types';
import _ from 'lodash';
import { ChevronDownIcon, ChevronRightIcon } from '../../assets/SVGIcons';
import SocTakedownTemplates from './soc-takedown-templates.component';
import { EmailTemplateForm } from './types/email-template-form.interface';
import { formatError } from '../../helpers/errors';
import SocEmailTemplateForm from './soc-email-template-form.component';
import { EmailTemplateFormErrors } from './types/email-template-form-errors.interface';
import { SocEmailTemplateSaveDto } from './types/soc-email-template-save-dto';
import { history } from '../../helpers';
import { MANAGE_EMAIL_TEMPLATES_PATH } from '../MonitorAndTakedown/constants';
import { SocAutofillOptions } from './SocAutofillOptions';

const socTakedownService = new SocTakedownService();

interface ISocTakedownManageTemplatesComponentProps {
  page: 'landing' | 'create' | 'update';
}

function blankForm(): EmailTemplateForm {
  return {
    name: '',
    description: '',
    brandId: null,
    toEmails: '',
    ccEmails: '',
    bccEmails: '',
    subject: '',
    initialBodyHtml: '',
    bodyHtml: '',
  };
}

function SocTakedownManageTemplates({
  page,
  alertSuccess,
  alertError,
  brandInfo,
}: ISocTakedownManageTemplatesComponentProps &
  Pick<IAlertDispatchProps, 'alertSuccess' | 'alertError'> & { brandInfo?: IBrandInfo }) {
  const { id, returnUrl } = useParams<{ id?: string; returnUrl?: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState<SocEmailTemplate | null>(null);
  const [selectedEventKey, setSelectedEventKey] = useState<string | null>('0');
  const [emailTemplateForm, setEmailTemplateForm] = useState<EmailTemplateForm>(blankForm());
  const [validationErrors, setValidationErrors] = useState<EmailTemplateFormErrors>({
    name: [],
    description: [],
    subject: [],
    bodyHtml: [],
  });
  const [showErrors, setShowErrors] = useState(false);
  const [brandOptions, setBrandOptions] = useState<{ brandId: number; displayName: string }[]>([]);
  const [templateRefresh, setTemplateRefresh] = useState(0);

  function validateForm(updatedForm: EmailTemplateForm) {
    const errors = socTakedownService.validateEmailTemplate(updatedForm);
    setValidationErrors(errors);
    return errors;
  }

  function formUpdated(updatedForm: EmailTemplateForm) {
    setEmailTemplateForm(updatedForm);
    validateForm(updatedForm);
    setEmailTemplate({
      id: id != null ? +id : null,
      name: updatedForm.name,
      description: updatedForm.description,
      data: {
        toEmails: updatedForm.toEmails.trim()
          ? updatedForm.toEmails.split(',').map(x => x.trim())
          : [],
        ccEmails: updatedForm.ccEmails.trim()
          ? updatedForm.ccEmails.split(',').map(x => x.trim())
          : [],
        bccEmails: updatedForm.bccEmails.trim()
          ? updatedForm.bccEmails.split(',').map(x => x.trim())
          : [],
        subject: updatedForm.subject,
        bodyHtml: updatedForm.bodyHtml,
      },
      brandId: updatedForm.brandId,
      created_ts: null,
      updated_ts: null,
    });
  }

  function isValid(errors: EmailTemplateFormErrors) {
    return (
      errors.name.length +
        errors.description.length +
        errors.subject.length +
        errors.bodyHtml.length ===
      0
    );
  }

  async function save() {
    setShowErrors(true);
    const errors = validateForm(emailTemplateForm);
    if (emailTemplate != null && isValid(errors)) {
      try {
        setIsLoading(true);
        const saveDto: SocEmailTemplateSaveDto = {
          name: emailTemplate.name,
          description: emailTemplate.description,
          data: emailTemplate.data,
          brandId: emailTemplate.brandId,
        };
        if (page === 'create') {
          const res = await socTakedownService.createEmailTemplate(saveDto);
          history.push(`${MANAGE_EMAIL_TEMPLATES_PATH}/update/${res.id}`);
        } else {
          const res = await socTakedownService.updateEmailTemplate(+(id as string), saveDto);
          initFromTemplate(res);
        }
        alertSuccess('Successfully saved template!');
      } catch (e) {
        alertError(formatError(e));
      } finally {
        setIsLoading(false);
      }
    }
  }

  function discardAndCancel() {
    if (returnUrl) {
      history.push(decodeURIComponent(returnUrl));
    } else {
      history.push('/');
    }
  }

  function initFromTemplate(et: SocEmailTemplate) {
    setEmailTemplate(et);
    setEmailTemplateForm({
      name: et.name,
      description: et.description,
      toEmails: (et.data.toEmails ?? []).join(', '),
      ccEmails: (et.data.ccEmails ?? []).join(', '),
      bccEmails: (et.data.bccEmails ?? []).join(', '),
      brandId: et.brandId,
      subject: et.data.subject,
      initialBodyHtml: et.data.bodyHtml,
      bodyHtml: et.data.bodyHtml,
    });
  }

  function onEditTemplate(et: SocEmailTemplate) {
    history.push(`${MANAGE_EMAIL_TEMPLATES_PATH}/update/${et.id}`);
  }

  async function onDeleteTemplate(et: SocEmailTemplate) {
    try {
      await socTakedownService.deleteEmailTemplate(et.id as number);
      // if these match the current template was deleted, reroute to landing page
      if (id != null && et.id === +id) {
        history.push(`${MANAGE_EMAIL_TEMPLATES_PATH}/`);
      }
      setTemplateRefresh(templateRefresh + 1);
      alertSuccess(`${et.name} Template was deleted.`);
    } catch (e) {
      alertError(formatError(e));
    }
  }

  async function fetchBrands() {
    const res = await socTakedownService.getEmailTemplateBrands();
    setBrandOptions(res);
  }

  useEffect(() => {
    invoke(async () => {
      setIsLoading(true);
      try {
        await fetchBrands();
        if (page === 'landing') {
          setShowSave(false);
        } else {
          setShowSave(true);
          if (page === 'update') {
            const et = await socTakedownService.getEmailTemplate(+(id as string));
            initFromTemplate(et);
          } else {
            setEmailTemplate(null);
            setEmailTemplateForm(blankForm());
          }
        }
      } catch (e) {
        alertError(formatError(e));
      } finally {
        setIsLoading(false);
      }
    });
  }, [page, id]);

  function formSection() {
    if (page === 'landing') {
      return <div>Please select a form on the left side or click Add Template.</div>;
    } else {
      return (
        <div>
          <h5 className='mb-3'>{page === 'create' ? 'Add Template' : 'Update Template'}</h5>
          <SocEmailTemplateForm
            form={emailTemplateForm}
            formChanged={form => formUpdated(form)}
            brandOptions={brandOptions}
            validationErrors={validationErrors}
            showErrors={showErrors}
          />
        </div>
      );
    }
  }

  return (
    <div className={'soc-takedown-container d-flex flex-column h-100'}>
      <div className={'d-flex pb-2 align-items-center'}>
        <div className={'flex-grow-1'}>
          <PageTitle title={'Manage Templates'} />
        </div>
        {showSave && (
          <div>
            <Button
              variant='outline-secondary'
              onClick={discardAndCancel}
              disabled={isLoading}
              className={'mr-2'}
            >
              Discard and Cancel
            </Button>
            <Button variant='primary' onClick={save} disabled={isLoading} className={'mr-2'}>
              Save Template
            </Button>
          </div>
        )}
      </div>

      <LoadingWrapper isLoading={isLoading}>
        <div className={'flex-grow-1 soc-takedown-row d-flex'}>
          <div className={'soc-takedown-options-column pr-0'}>
            <Accordion onSelect={x => setSelectedEventKey(x)} defaultActiveKey={'0'}>
              <Card className={'border-top-0 border-right-0 mt-0'}>
                <Card.Header className={'d-flex align-items-baseline pl-3 pr-2'}>
                  <div className={'flex-grow-1'}>Templates</div>
                  <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                    {selectedEventKey === '0' ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='0'>
                  <SocTakedownTemplates
                    formType={'template'}
                    triggerRefresh={templateRefresh}
                    onEdit={onEditTemplate}
                    onDelete={onDeleteTemplate}
                    brandOptions={brandOptions}
                    currentBrand={
                      brandInfo != null
                        ? {
                            brandId: brandInfo.brand.brandId,
                            displayName: brandInfo.brand.displayName,
                          }
                        : null
                    }
                  />
                </Accordion.Collapse>
              </Card>

              <Card className={'border-top-0 border-right-0 mt-0'}>
                <Card.Header className={'d-flex align-items-baseline pl-3 pr-2'}>
                  <div className={'flex-grow-1'}>Auto-fill</div>
                  <Accordion.Toggle as={Button} variant='link' eventKey='1'>
                    {selectedEventKey === '1' ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='1' className={'px-2'}>
                  <SocAutofillOptions />
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className={'soc-takedown-form-column px-4 py-3'}>{formSection()}</div>
        </div>
      </LoadingWrapper>
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    brandInfo: state.dashboardReducer.brandInfo,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocTakedownManageTemplates);

import React, { MouseEvent, useState } from 'react';
import ModalOverlay from '../../Common/ModalOverlay';
import { Button } from '@material-ui/core';
import { ITeamMemberData } from '../TeamMembers';

export interface IDeleteTeamMemberModalProps {
  data: ITeamMemberData;
  onDeleteTeamMember: (data: ITeamMemberData) => Promise<void>;
  isLoading: boolean;
}
export default function DeleteTeamMemberModal({
  data,
  onDeleteTeamMember,
  isLoading,
}: IDeleteTeamMemberModalProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setShowModal(true);
  };

  const onSubmit = () => {
    void onDeleteTeamMember(data);
    setShowModal(false);
  };

  return (
    <>
      <Button
        id='basic-button-search-actions'
        aria-haspopup='true'
        onClick={handleClick}
        variant='outlined'
        color='secondary'
        size='small'
      >
        Delete
      </Button>

      <ModalOverlay
        show={showModal}
        title={'Delete Team Member'}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onCancel={() => setShowModal(false)}
        submitButtonLabel={'Submit'}
      >
        <div className='delete-property-container'>
          <div className='deleting-header-text'>
            {`Are you sure you want to delete user`}{' '}
            <span>{`${data.firstName} ${data.lastName}`}</span> {`?`}
          </div>
          <div className='confirmation-text'>This action can't be undone.</div>
        </div>
      </ModalOverlay>
    </>
  );
}

import React from 'react';
import { Form } from 'react-bootstrap';
import { Dropdown } from '../../Common/Dropdown';
import ModalOverlay from '../../Common/ModalOverlay';
import { TextInput } from '../../Common/TextInput';
import { UploadFiles } from '../../Common/UploadFiles';
import { EPropertyTypeLabel, InputType } from '../PropertyTypes';
import '../Style/PropertyModalStyle.scss';

const PropertyModal = ({
  fields,
  show,
  onFormSubmit,
  onCloseModal,
  isLoading,
  type,
  updateFormValues,
  formValues,
}: any) => {
  return (
    <ModalOverlay
      show={show}
      title={'Add ' + EPropertyTypeLabel[type.value]}
      isLoading={isLoading}
      onSubmit={onFormSubmit}
      onCancel={onCloseModal}
      submitButtonDisabled={
        fields.filter((field: any) => field.isRequired && !formValues[field.id]).length
      }
      size={'lg'}
    >
      <Form className='add-brand-property-logo-form row'>
        {fields?.map((field: any) => {
          switch (field.type) {
            case InputType.FILES:
              return (
                <Form.Group className='col-12' key='files'>
                  <UploadFiles
                    onChangeHandler={(files, controlId) => updateFormValues(controlId, files)}
                    dragAndDrop
                    controlId='files'
                    filesLimit={10}
                    fileType={field.fileType}
                  />
                </Form.Group>
              );
            case InputType.TEXT:
              return (
                <Form.Group className='col-12' key={field.id}>
                  <Form.Label>
                    <div className='field-label-wrapper'>
                      {field.isRequired && <div className='asterisk'>* </div>}
                      <div className='field-value'>{field.label}</div>
                    </div>
                  </Form.Label>
                  <TextInput
                    controlId={field.id}
                    onChange={(event, controlId) => updateFormValues(controlId, event.target.value)}
                  />
                </Form.Group>
              );
            case InputType.DROPDOWN:
              return (
                <Form.Group className='col-12' key={field.id}>
                  <Form.Label>
                    <div className='field-label-wrapper'>
                      {field.isRequired && <div className='asterisk'>* </div>}
                      <div className='field-value'>{field.label}</div>
                    </div>
                  </Form.Label>
                  <Dropdown
                    options={field.options}
                    onChange={(selected: any) => {
                      updateFormValues(field.id, selected?.docValue);
                    }}
                  />
                </Form.Group>
              );
          }
          return null;
        })}
      </Form>
    </ModalOverlay>
  );
};

export default PropertyModal;

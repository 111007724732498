import * as React from 'react';
import './PicFiles.scss';
import emailHeader from '../../assets/bolster-email-template-header.png';
import bolsterLogoWhite from '../../assets/bolster-logo-white.png';
import bolsterLogoBlack from '../../assets/bolster-logo-black.png';
import logoBlack from '../../assets/logo-black.png';
import logoWhite from '../../assets/logo-white.png';
import logoGreen from '../../assets/logo-green.png';
import { Image } from '../Common/Image';

interface IPicFilesProps {}

interface IPicFilesState {}

class PicFiles extends React.Component<IPicFilesProps, IPicFilesState> {
  shouldComponentUpdate(
    nextProps: Readonly<IPicFilesProps>,
    nextState: Readonly<IPicFilesState>,
    nextContext: any,
  ): boolean {
    return false;
  }

  render() {
    return (
      <div className={'pic-files-container'}>
        <div className={'logo-with-background'}>
          <img src={bolsterLogoWhite} alt={'bolsterLogoWhite'} />
        </div>
        <Image imagePath={emailHeader} alt={'emailHeader'} />
        <div className={'pic-item'}>
          <Image imagePath={bolsterLogoWhite} alt={'bolsterLogoWhite'} />
        </div>
        <div className={'pic-item'}>
          <Image imagePath={bolsterLogoBlack} alt={'bolsterLogoBlack'} />
        </div>
        <div className={'pic-item'}>
          <Image imagePath={logoBlack} alt={'logoBlack'} />
        </div>
        <div className={'pic-item'}>
          <Image imagePath={logoWhite} alt={'logoWhite'} />
        </div>
        <div className={'pic-item'}>
          <Image imagePath={logoGreen} alt={'logoGreen'} />
        </div>
      </div>
    );
  }
}

export { PicFiles };

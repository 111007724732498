import React from 'react';
import { ILableValues } from '../../types';
import '../../Styles/DataSetItem.scss';
import _ from 'lodash';
import { InfoIcon } from '../../../../assets/SVGIcons';
import useOnOpenDataLeakDrawer from './useOnOpenDataLeakDrawer';
export interface IDataSetItem {
  dataItem: ILableValues;
  showTooltip: boolean;
  entityType: string;
  searchTermId: string | number;
}

const DataSetItem = ({ dataItem, showTooltip, entityType, searchTermId }: IDataSetItem) => {
  const { openDataLeakDrawer } = useOnOpenDataLeakDrawer(dataItem.label, entityType, searchTermId);

  return (
    <div className='data-set' data-testid={'data-set'}>
      <div className='value' data-testid={'item-value'}>
        {dataItem.value}
      </div>
      <div className='label' data-testid={'item-label'}>
        {dataItem.label}
      </div>
      {showTooltip && (
        <div
          data-testid='info-data-leak-icon'
          style={{ marginLeft: '6px' }}
          onClick={openDataLeakDrawer}
        >
          <InfoIcon color='grey' />
        </div>
      )}
    </div>
  );
};

export default DataSetItem;

import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import './AddCreditModal.scss';
import ModalOverlay from '../Common/ModalOverlay';
import { alertActions } from '../../actions';
import { connect } from 'react-redux';
import { AlertActionsTypes } from '../../constants';
import { Form, InputGroup } from 'react-bootstrap';

interface IAddCredit {
  creditAmount: string;
}

interface IAddCreditModalProps {
  show: boolean;
  onCloseModal: () => void;
  onModalSubmit: (data: any) => Promise<any>;
}

interface ILinkDispatchProps {
  alertSuccess: (message: string, dom?: any) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

const AddCreditModal = (props: IAddCreditModalProps & ILinkDispatchProps) => {
  const { show, onCloseModal, onModalSubmit, alertSuccess, alertError } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  const values = useRef<IAddCredit>({
    creditAmount: '',
  });

  const updateValue = (value: any, field: string) => {
    values.current[field] = value;
    const { creditAmount } = values.current;
    setIsInvalid(creditAmount.length === 0 || Number(creditAmount) < 0);
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateValue(event.target.value, controlId);
  };

  const onSubmit = () => {
    setIsLoading(true);
    onModalSubmit(values.current)
      .then(res => {
        alertSuccess(`Credit request of $${res?.amount} sent successfully`); // TODO Waiting from UX for the message
      })
      .catch((error: any) => {
        alertError('Something went wrong on request for adding the credit'); // TODO Waiting from UX for the message
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ModalOverlay
      show={show}
      title={'Submit Add Credit Request'}
      onCancel={onCloseModal}
      onSubmit={onSubmit}
      submitButtonLabel={'Submit'}
      isLoading={isLoading}
      submitButtonDisabled={isInvalid}
    >
      <div className={'add-credit-modal'}>
        <Form>
          <Form.Group>
            <Form.Label className='add-credit-label'>Amount (in USD)</Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='dollar-sign-wrapper'>$</InputGroup.Text>
              <Form.Control
                id='creditAmount'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleTextFieldChange(e, e.target.id)
                }
                type='number'
                aria-label='Amount (in US dollar)'
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <div className={'add-credit-description'}>
              The Bolster billing team will contact you within 24 hours. Nothing will be charged on
              your account without permission.
            </div>
          </Form.Group>
        </Form>
      </div>
    </ModalOverlay>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(undefined, mapDispatchToProps)(AddCreditModal);

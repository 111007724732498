export interface ITabSubItem {
  label: string;
  id: string;
  href: string;
  className?: string;
  disabled?: boolean;
  webOnly?: boolean;
  isNewLabel?: boolean;
}

export interface ITabItem {
  label: string;
  id: string;
  href: string;
  subItems?: ITabSubItem[];
  isNewLabel?: boolean;
  redirect?: boolean;
}

const subNavItemClassName = 'custom-text-dark font-size-large';

export const TAB_ITEMS: ITabItem[] = [
  {
    label: 'Products',
    id: '/products',
    href: '',
    isNewLabel: false,
    subItems: [
      {
        label: 'Microsoft Email Plugin',
        id: '/checkphish-scanner',
        href: '/checkphish-scanner',
        className: subNavItemClassName,
      },
      {
        label: 'APIs',
        id: '/checkphish-api',
        href: '/checkphish-api',
        className: subNavItemClassName,
      },
    ],
  },
  {
    label: 'Pricing',
    id: 'pricingCheckPhishAPI',
    href: '/pricing',
    isNewLabel: false,
  },
  {
    label: 'Resources',
    id: '/resources',
    href: '',
    isNewLabel: false,
    subItems: [
      {
        label: 'Blog',
        id: '/blog',
        href: '/blog',
        className: subNavItemClassName,
      },
      {
        label: 'FAQ',
        id: '/faq',
        href: '/faq',
        className: subNavItemClassName,
      },
    ],
  },
  {
    label: 'Contact Us',
    id: '/contact-us',
    href: '/contact-us',
    isNewLabel: false,
  },
];

export const TAB_ITEMS_HEADER: ITabItem[] = [
  {
    label: 'Domain Monitoring',
    id: 'checkphishDomainMonitoring',
    href: '/domain-monitoring',
    isNewLabel: true,
    redirect: true,
  },
  {
    label: 'Live URL Scan',
    id: 'checkphishLiveScan',
    href: '/live-scan',
    isNewLabel: false,
    redirect: true,
  },
  {
    label: 'Email Plugin',
    id: '/checkphish-scanner',
    href: '/checkphish-scanner',
    isNewLabel: false,
    redirect: true,
  },
  {
    label: 'APIs',
    id: '/checkphish-api',
    href: '/checkphish-api',
    isNewLabel: false,
    redirect: true,
  },
  {
    label: 'Pricing',
    id: 'pricingCheckPhishAPI',
    href: '/pricing',
    isNewLabel: false,
    redirect: true,
  },
];

export const TAB_ITEMS_FOOTER: ITabItem[] = [
  {
    label: 'FAQ',
    id: '/faq',
    href: '/faq',
    isNewLabel: false,
    redirect: true,
  },
  {
    label: 'Contact Us',
    id: '/contact-us',
    href: '/contact-us',
    isNewLabel: false,
    redirect: true,
  },
];

export const TAB_ITEMS_FOOTER_L2: ITabItem[] = [
  {
    label: 'Privacy Policy',
    id: '/privacy-policy',
    href: '/privacy-policy',
    isNewLabel: false,
    redirect: true,
  },
  {
    label: 'Terms of Service',
    id: '/terms-of-service',
    href: '/terms-of-service',
    isNewLabel: false,
    redirect: true,
  },
];

import * as React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ThemeProvider, createTheme, ThemeOptions } from '@material-ui/core/styles';
import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes, capitalize, getSingleOrPluralForm } from '../../../../constants';
import DotDotDotWhiteIcon from '../../../../assets/icons/DotDotDotWhite.svg';
import DotDotDot from '../../../../assets/icons/DotDotDot.svg';
import { getSearchTermFindings } from './ag-requests';
import { map } from 'lodash';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import _ from 'lodash';
import ModalOverlay from '../../ModalOverlay';
import { LoadingWrapper } from '../../LoadingWrapper';
import DarkWebService from '../../../../services/darkWeb.service';
import { useAppDispatch } from '../../../../helpers/hooks';
import { alertActions } from '../../../../actions';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';

const darkWebService = new DarkWebService();
interface IProps {
  params: any;
  onSubmitSuccess: () => void;
}
const ChangeDarkWebFindingStatus = ({ params, onSubmitSuccess }: IProps) => {
  const { selectedTheme } = React.useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [modalShow, setModalShow] = React.useState<boolean>(false);
  const [allFindings, setAllFindings] = React.useState<string[]>([]);
  const [selectedNewStatus, setSelectedNewStatus] = React.useState<string | undefined>(undefined);

  const dispatch = useAppDispatch();

  const open = Boolean(anchorEl);

  const theme: ThemeOptions = createTheme({
    palette: {
      type: selectedTheme === 'dark' ? 'dark' : 'light',
    },
  });

  const fetchRelatedFindings = () => {
    setAnchorEl(null);
    setModalShow(true);
    setIsLoading(true);
    const {
      data: { darkwebStatus, search_term, related_findings_count, extraFilters },
    } = params;
    getSearchTermFindings(darkwebStatus, extraFilters, [search_term], related_findings_count)
      .then(resp => {
        const allFindings = resp.findings.map(
          (m: { content_sha_256: string }) => m.content_sha_256,
        );
        setAllFindings(allFindings);
      })
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    if (!selectedNewStatus) return;
    fetchRelatedFindings();
  }, [selectedNewStatus]);

  const onModalSubmit = () => {
    const formData = new FormData();
    if (selectedNewStatus === undefined || allFindings.length === 0) {
      return;
    }

    formData.append('sha256', allFindings.join(','));
    formData.append('status', selectedNewStatus);
    setIsLoading(true);
    darkWebService
      .updateFindingStatus(formData)
      .then(() => {
        setIsLoading(false);
        const successMessage = `You have changed the status to ${capitalize(
          selectedNewStatus.toLowerCase(),
        )} successfully`;

        dispatch(alertActions.success(successMessage));
        onSubmitSuccess();
      })
      .catch(error => {
        dispatch(alertActions.error(error.message));
      })
      .finally(() => {
        setIsLoading(false);
        handleClose();
      });
  };

  const handleDotClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setModalShow(false);
  };

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) {
    return null;
  }

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleDotClick}
      >
        <img
          src={selectedTheme === ThemeModes.DARK.toLowerCase() ? DotDotDotWhiteIcon : DotDotDot}
          alt={'dotdotdot'}
          data-testid='dotdotdot'
        />{' '}
      </Button>
      <ThemeProvider theme={theme}>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {map(EDarkWebFindingStatus, value => {
            if (value !== params.data.darkwebStatus) {
              return (
                <MenuItem
                  key={`${params.value}-${value}`}
                  onClick={() => setSelectedNewStatus(value)}
                >
                  Mark as {capitalize(value.toLowerCase())}
                </MenuItem>
              );
            }
          })}
        </Menu>
      </ThemeProvider>
      <ModalOverlay
        show={modalShow}
        isLoading={isLoading}
        title={`Confirm to mark as ${selectedNewStatus}`}
        onCancel={handleClose}
        onSubmit={onModalSubmit}
        submitButtonLabel={'Confirm'}
      >
        <LoadingWrapper isLoading={isLoading}>
          <div className='change-status-modal-container'>
            <div className='theme-text-secondary-color mb-2'>
              {`Are you sure you want to mark ${allFindings.length} ${getSingleOrPluralForm(
                allFindings.length,
                'finding',
              )} as ${selectedNewStatus} ?`}
            </div>
          </div>
        </LoadingWrapper>
      </ModalOverlay>
    </div>
  );
};

export { ChangeDarkWebFindingStatus };

import React, { useCallback, useEffect } from 'react';

export const useAgOnFilterClick = ({ agRef }: { agRef: React.MutableRefObject<any> }) => {
  const handleFilterClick = () => {
    const wrapperContainer = document.querySelector('.ag-header-row-column-filter');
    wrapperContainer?.removeEventListener('click', addClickListener);
    wrapperContainer?.addEventListener('click', addClickListener);
  };

  const addClickListener = (event: any) => {
    const target = event.target as HTMLElement;
    const inputWrapper = target.closest('.ag-text-field-input-wrapper');
    const inputElement = inputWrapper?.querySelector('input');
    if (inputElement && inputElement.disabled) {
      const columnName = inputElement?.getAttribute('aria-label')?.split(' Filter Input');
      const getFilterClickedEle = agRef.current?.api
        .getColumnDefs()
        ?.find((ele: any) => columnName?.[0] === ele.headerName);
      if (columnName?.[0] === getFilterClickedEle?.headerName) {
        inputElement?.click();
        agRef.current?.api?.showColumnFilter(getFilterClickedEle.colId);
      }
    }
  };

  return { handleFilterClick };
};

// optimizing code by using event delegation instead of attaching individual click event listeners to each input wrapper element.
// Event delegation involves attaching a single event listener to a common ancestor element and then using event bubbling to handle events as they propagate up the DOM tree.

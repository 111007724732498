import React, { useCallback, useContext, useMemo, useRef } from 'react';
import useOnAgGridClientModeTableRefresh from './useOnAgGridClientModeTableRefresh';
import { useAgGridEvents } from './useAgGridEvents';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import { AgGridReact } from 'ag-grid-react';
import ThemeContext from '../../../../context/ThemeContext';
import { TableContext } from '../table.context';
import { useAppSelector } from '../../../../helpers/hooks';
import { ColDef, GetRowIdParams } from 'ag-grid-community';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { getLocalStorageValue } from '../../../../constants';
import useOnClientSideTableExport from '../../CustomHooks/useOnClientSideExport';
import { getAgGridThemeClassName } from './ag-utils';
import { AgGridColumnType } from './interfaces';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { generateAgGridTeamMemberColDefs } from './ag-col-defs';
import _ from 'lodash';
import useOnAgGridEditCell from './useOnAgGridEditCell';

const AgGridTeamMemberTable = ({
  columns,
  tableId,
  rowData,
}: {
  columns: any;
  tableId: string;
  rowData: any;
}) => {
  const agRef = useRef<AgGridReact>(null);
  const { selectedTheme } = useContext(ThemeContext);
  const { displayedColumnsIDs } = useContext(TableContext);

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({ tableId });

  const colDefs = useMemo<ColDef[]>(() => {
    return generateAgGridTeamMemberColDefs({
      columns,
      firstRenderDisplayColumnIdSet,
      tableId,
    });
  }, [columns, firstRenderDisplayColumnIdSet, tableId]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, [columnHeaderMenuItems]);

  const isFilterModelCacheUsed = useRef(false);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [handleFilterClick, tableId],
  );

  const isExportCSVCalled = useAppSelector(state => state.tableReducer.isExportCSVCalled);

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data.email}-${params.data.firstName}-${params.data.lastName}`;
  }, []);

  useOnClientSideTableExport(isExportCSVCalled, agRef, tableId, columns);

  const { handleFirstDataRendered, handleOnPaginationChanged } =
    usePersistentTablePageNumber(tableId);

  const { onHandleCellEdit } = useOnAgGridEditCell();
  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    saveFilterChangeToLocalStorage,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData: rowData,
  });

  useOnAgGridClientModeTableRefresh(agRef);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme)}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        rowModelType='clientSide'
        rowData={rowData}
        rowBuffer={10}
        animateRows={false}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowSelection={'multiple'}
        onFilterChanged={saveFilterChangeToLocalStorage}
        suppressRowClickSelection={true}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onCellEditRequest={onHandleCellEdit}
        stopEditingWhenCellsLoseFocus
        readOnlyEdit={true}
        singleClickEdit={true}
        onGridSizeChanged={onHandleGridSizeChanged}
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default AgGridTeamMemberTable;

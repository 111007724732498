import * as React from 'react';
import { PageTitleInsights } from '../Common/PageTitleInsights';
import { useHistory } from 'react-router';
import { Card, Row, Col } from 'react-bootstrap';
import { LabelAndValue } from '../Common/LabelAndValue';
import { Table } from '../Common/Table/table';
import { Link } from 'react-router-dom';
import { renderDispositionFilter } from './PublicInsights';
import { IDropdownOption } from '../Common/Dropdown';
import { IpInsights } from './ip.insights.component';
import _ from 'lodash';

export interface IPublicIpInsightsProps {}

const renderColumn = () => {
  const COLUMNS = [
    {
      header: 'URLs related to this domain',
      accessor: 'srcUrl',
      isLongText: true,
      render: (data: any) => {
        const { domain, srcUrl } = data;
        if (_.isEmpty(domain)) {
          return '--';
        }
        return (
          <Link to={`/domain/${domain}`} onClick={() => {}}>
            {srcUrl}
          </Link>
        );
      },
    },
  ];
  return COLUMNS;
};

export function PublicIpInsights(props: IPublicIpInsightsProps) {
  const history = useHistory();
  //   return (
  //     <div>
  //         <PageTitleInsights
  //           title={'--'}
  //           backFn={() => {
  //             history.go(-1);
  //           }}
  //         />
  //         <div className={'page-content ip-insights-content'}>
  //         <Row>
  //               <Col lg={6}>
  //                 <Card className='card-between'>
  //                   <Card.Header>IP Address Details</Card.Header>
  //                   <Card.Body>
  //                     <Row>
  //                       <Col md={6}>
  //                         <LabelAndValue
  //                           label={'Past Phish on IP'}
  //                           value={'--'}
  //                           direction={'column'}
  //                         />
  //                       </Col>
  //                       <Col md={6}>
  //                         <LabelAndValue label={'ASN'} value={'--'} direction={'column'} />
  //                       </Col>
  //                     </Row>
  //                     <Row>
  //                       <Col md={6}>
  //                         <LabelAndValue label={'ISP'} value={'--'} direction={'column'} />
  //                       </Col>
  //                       <Col md={6}>
  //                         <LabelAndValue
  //                           label={'location'}
  //                           renderDom={
  //                             <div className={'country-name'}>
  //                               <span className={'--'} />
  //                               {'--'}
  //                             </div>
  //                           }
  //                           direction={'column'}
  //                         />
  //                       </Col>
  //                     </Row>
  //                   </Card.Body>
  //                 </Card>
  //                 <Card bg='light' className='card-between'>
  //                   <Table
  //                     tableClassName='tool-bar-container-padding'
  //                     disableDatePicker={true}
  //                     disableFilter={true}
  //                     data={[]}
  //                     title={'Related Domains'}
  //                     columns={renderColumn()}
  //                     customTools={renderDispositionFilter(
  //                       "All",
  //                       ()=>{},
  //                     )}
  //                   />
  //                 </Card>
  //               </Col>
  //         </Row>

  //         </div>
  //     </div>
  //   );
  return <IpInsights type={'bulk-scan'} isPublic={true} />;
}

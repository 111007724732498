import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import './TextInput.scss';

interface ITextInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>, controlId: string) => void;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  controlId?: string;
  type?: string;
  placeholder?: string;
}

const TextInput = ({
  onChange,
  className,
  defaultValue,
  disabled,
  controlId = 'textInput',
  type = 'string',
  placeholder = '',
}: ITextInputProps) => {
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e, controlId);
  };
  return (
    <Form.Control
      className={'text-input-component ' + className}
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={onTextChange}
      type={type}
      id={controlId}
      placeholder={placeholder}
      data-testid={controlId}
    />
  );
};

export { TextInput };

import { Observable } from 'rxjs';
import { pollRequest } from './api.service';
import { handleResponse, setApiUrl } from './serviceWorker';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default class BulksSanService {
  private apiUrl: string;

  constructor() {
    this.apiUrl = setApiUrl();
  }

  getPlans() {
    return fetch(this.apiUrl + '/api/v1/payments/plans').then(handleResponse);
  }

  bulkScan(
    scanUrl: string,
    scanApiKey = '',
    scanType: string,
    privateScan = false,
    customUserAgent = '',
  ) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        scan: {
          scanUrl,
          scanApiKey,
          scanType,
          rescan: false,
          privateScan,
          customUserAgent,
        },
      }),
    };
    return fetch(this.apiUrl + '/platform-api/v1/bulkscan/anonymous-scan', requestOptions).then(
      handleResponse,
    );
  }

  getTopBrands = (category: string) => {
    return fetch(this.apiUrl + '/api/v1/brand/top-trends/' + category).then(handleResponse);
  };

  getVisitorId = (): Promise<string | void> => {
    return FingerprintJS.load()
      .then(fp => fp.get())
      .then(res => res.visitorId)
      .catch(err => console.error(`Error getting visitor id: ${err.message}`));
  };

  generateAnonymousReport(domain: string, identifier: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ domain, identifier }),
    };

    return fetch(`/platform-api/v1/typosquatting/anonymous/generate-report`, requestOptions).then(
      handleResponse,
    );
  }

  fetchAnonymousTyposquatResult(identifier: string): Observable<Response> {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ identifier }),
    };
    return pollRequest(
      handleResponse,
      `/platform-api/v1/typosquatting/anonymous/search`,
      requestOptions,
    );
  }
}

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ETimelineViewModes, ITakedownActions, ITakedownEvents } from './TakedownTimelineContainer';
import EventsLabel from './EventsLabel';
import moment from 'moment';
import './DetailTakedownTimelineView.scss';
import { ExpandedDarkIcon, ExpandedLightIcon } from '../../assets/SVGIcons';
import ThemeContext from '../../context/ThemeContext';
import useOnYearlyLayoutChange from './useOnYearlyLayoutChange';
import { ThemeModes } from '../../constants';

export interface YearlyTakedownTimelineViewProps {
  takeDownEvents: ITakedownActions;
  rowId: string;
}
export default function YearlyTakedownTimelineView(props: YearlyTakedownTimelineViewProps) {
  const { selectedTheme } = useContext(ThemeContext);
  const { takeDownEvents, rowId } = props;

  const { onYearlyLayoutChange } = useOnYearlyLayoutChange(rowId);
  return (
    <div className='detail-takedown-timeline-container' data-testid='yearly-takedown-view'>
      <div className='detail-takedown-timeline-container-title'>{takeDownEvents.currentMonth}</div>
      <div className='detail-takedown-timeline-wrapper'>
        {Object.keys(takeDownEvents.events).map((event, index) => {
          return (
            <div key={index} className='detail-takedown-timeline-item'>
              <div className='detail-takedown-timeline-item-date'>
                <div className='mr-2'>{event}</div>
                <div
                  data-testid='on-monthly-layout-change'
                  onClick={() => onYearlyLayoutChange(ETimelineViewModes.MONTHLY, event)}
                >
                  {selectedTheme === ThemeModes.DARK.toLowerCase() ? (
                    <ExpandedDarkIcon />
                  ) : (
                    <ExpandedLightIcon />
                  )}
                </div>
              </div>
              <div className='detail-takedown-timeline-item-events'>
                {takeDownEvents.events[event].map((a: ITakedownEvents) => {
                  return (
                    <Fragment key={moment(a.createdts).valueOf()}>
                      <EventsLabel
                        label={a.label}
                        createdts={moment(a.createdts).format('hh:mm A')}
                        event={a.action}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

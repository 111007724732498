import React from 'react';
import './PlanChip.scss';
import { PRICING_PLANS } from '../types';
import _ from 'lodash';

export type PlanChipProps = {
  planName: PRICING_PLANS;
  isTransparent?: boolean;
  planChipText?: string;
};

const PlanChip = ({ planName, isTransparent = false, planChipText }: PlanChipProps) => {
  return (
    <div
      className={`plan-chip ${isTransparent ? 'transparent' : planName}`}
      data-testid='plan-chip'
    >
      {_.isEmpty(planChipText) ? planName : planChipText}
    </div>
  );
};

export default PlanChip;

import React from 'react';
import {
  MARKETPLACE_NAME,
  UGC_PLATFORM,
  MARKETPLACE_SELLER_NAME,
  PROPERTY_CREATED_BY_COL,
  UGC_DATE_ADDED,
  BRAND_COLUMN,
  BRAND_PROPERTY_ACTION_COL,
} from './AssetsColDefination/BrandPropertyCols';
import BrandPropertyComponent from './BrandPropertyComponent';
import { MARKETPLACE_TABLE_ID, MARKETPLACE_TABLE_NAME } from './constants';
import { PropertyType } from './PropertyTypes';
import { appConstants } from '../../constants';

const MarketplaceAssets = () => {
  const PAGE_TITLE = 'Marketplace';
  window.document.title = `${PAGE_TITLE} | Assets`;

  const TABLE_COLUMNS = [
    BRAND_COLUMN,
    MARKETPLACE_NAME,
    UGC_PLATFORM,
    MARKETPLACE_SELLER_NAME(),
    PROPERTY_CREATED_BY_COL,
    UGC_DATE_ADDED,
    BRAND_PROPERTY_ACTION_COL,
  ];

  const defaultColumnIds = [
    'brand',
    'name',
    'platform',
    'sellerName',
    'created_by',
    'created_ts',
    'assets-action-col',
  ];
  return (
    <BrandPropertyComponent
      tableName={MARKETPLACE_TABLE_NAME}
      id={MARKETPLACE_TABLE_ID}
      columns={TABLE_COLUMNS}
      title={PAGE_TITLE}
      disableDatePicker
      type={PropertyType.MARKETPLACE}
      defaultColumnIds={defaultColumnIds}
      ugcType={appConstants.CONTENT_TYPE.MARKETPLACE}
    />
  );
};

export default MarketplaceAssets;

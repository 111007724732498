import React from 'react';
import './typosquatbanner.scss';

interface IBannerProps {
  isError: boolean;
  customClassName?: string;
  children: React.ReactNode;
}

const ScanBarBanner: React.FC<IBannerProps> = ({
  isError,
  customClassName,
  children,
}: IBannerProps) => {
  return (
    <div className='banner-wrap'>
      <div className={`banner ${customClassName}`}>{children}</div>
    </div>
  );
};
export default ScanBarBanner;

import React, { useCallback, useState } from 'react';
import CustomTagColorPalette from './CustomTagColorPalette';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import ModalOverlay from '../ModalOverlay';
import { setAllPlatformTags, setModifyCustomTag } from '../../../reducers/tags.reducer';
import { editCustomTag, fetchAllPlatformTags } from './tag-request';
import { alertActions } from '../../../actions';
import './EditCustomTagsModal.scss';

export default function EditCustomTagsModal() {
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);

  const [newTags, setNewTags] = useState<string>(modifyCustomTag.selectedTag?.label || '');
  const [selectedColorId, setSelectedColorId] = useState<number>(
    modifyCustomTag.selectedTag?.color?.id || 0,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const onEditTagHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTags(e.target.value);
    },
    [newTags],
  );

  const onCancel = useCallback(() => {
    dispatch(
      setModifyCustomTag({
        isEditMode: false,
        selectedTag: null,
        isDeleteMode: false,
        triggerFetchCall: false,
      }),
    );
  }, [dispatch]);

  const onModalSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!modifyCustomTag.selectedTag) return;
      const respone = await editCustomTag({
        tagId: modifyCustomTag.selectedTag?.id,
        label: newTags,
        colorId: selectedColorId,
      });
      if (respone) {
        dispatch(alertActions.success('Custom tag edited successfully'));
        const tags = await fetchAllPlatformTags();
        dispatch(setAllPlatformTags(tags));
      }
    } catch (error: any) {
      if (error) {
        dispatch(alertActions.error(error?.message || 'Error editing custom tag'));
      }
    } finally {
      setIsLoading(false);
      dispatch(
        setModifyCustomTag({
          isEditMode: false,
          selectedTag: null,
          isDeleteMode: false,
          triggerFetchCall: true,
        }),
      );
    }
  }, [dispatch, newTags, selectedColorId, modifyCustomTag.selectedTag]);

  return (
    <ModalOverlay
      show={modifyCustomTag.isEditMode}
      isLoading={isLoading}
      title={'Edit Tag'}
      onCancel={onCancel}
      onSubmit={onModalSubmit}
      cancelButtonLabel='Cancel'
      submitButtonLabel={'Save'}
    >
      <div className='edit-custom-tag-wrapper'>
        <div className='edit-title'>Tag Name</div>
        <input
          type='text'
          placeholder='Edit tag name'
          value={newTags}
          onChange={onEditTagHandler}
          className='form-control custom-tag-input'
        />
        <div className='edit-title'>Tag Color</div>
        <CustomTagColorPalette
          onColorSelect={setSelectedColorId}
          oldSelectedColor={modifyCustomTag.selectedTag?.color?.id}
        />
      </div>
    </ModalOverlay>
  );
}

import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { setShouldRestorePageNumber } from '../../../../reducers/table.reducer';
import { AgGridEvent } from 'ag-grid-community';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';

// store and restore the page number when necessary
export const usePersistentTablePageNumber = (tableId: string) => {
  const pageNumberRef = useRef<number | null>(null);
  const dispatch = useAppDispatch();
  const { shouldRestorePageNumber } = useAppSelector(state => state.tableReducer);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPaginationSize, setCurrenPaginationsize] = useState(15);
  const { sizeColumnsToFitGrid } = useAgGridFeaturesConfigs({ tableId });

  // store the page number when leaving the page
  useEffect(() => {
    return () => {
      setLocalStorageValue(['tablePageNumber', tableId], pageNumberRef.current);
      dispatch(setShouldRestorePageNumber(false));
    };
  }, []);

  const handleFirstDataRendered = useCallback(
    (params: AgGridEvent) => {
      sizeColumnsToFitGrid(params.api);
      if (shouldRestorePageNumber) {
        const pageNumber = getLocalStorageValue(['tablePageNumber', tableId], 0);
        params.api.paginationGoToPage(pageNumber);
      }
    },
    [shouldRestorePageNumber, tableId],
  );

  const handleOnPaginationChanged = useCallback((event: AgGridEvent) => {
    pageNumberRef.current = event.api?.paginationGetCurrentPage();
    setCurrentPageNumber(pageNumberRef.current);
    setCurrenPaginationsize(event.api?.paginationGetPageSize());
  }, []);

  return {
    handleFirstDataRendered,
    handleOnPaginationChanged,
    currentPageNumber,
    currentPaginationSize,
  };
};

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { appConstants, featureIsAvailable, getDateTimeFormat } from '../../constants';
import Country from '../Common/Country';
import { LabelAndValue } from '../Common/LabelAndValue';

export default function ScanSettings({ scanInfo, scanSettings, user }: any) {
  const isScanSettingsPresent = () => {
    return (
      featureIsAvailable(user, [appConstants.FEATURE_CODE.LOCATION_BASED]) &&
      Object.keys(scanSettings).length > 0
    );
  };
  return (
    <div>
      <Row>
        <Col sm={6}>
          <LabelAndValue
            label='First Scan Time'
            value={getDateTimeFormat(scanInfo.firstSeenTS)}
            direction={'column'}
            longTextLineNumberLimit={1}
          />
          {isScanSettingsPresent() && (
            <>
              {scanSettings.scanLocation === '--' ? (
                <LabelAndValue
                  label={'Scan Location'}
                  value={scanSettings.scanLocation}
                  direction={'column'}
                />
              ) : (
                <LabelAndValue
                  label={'Scan Location'}
                  direction={'column'}
                  longTextLineNumberLimit={1}
                  renderDom={<Country countryCode={scanSettings.scanLocation} />}
                />
              )}
              <LabelAndValue
                label={'User Agent'}
                value={scanSettings.userAgent}
                direction={'column'}
                longTextLineNumberLimit={2}
              />
            </>
          )}
        </Col>
        <Col sm={6}>
          <LabelAndValue
            label={'Last Scan Time'}
            value={getDateTimeFormat(scanInfo.timestamp)}
            direction={'column'}
            longTextLineNumberLimit={1}
          />
          {isScanSettingsPresent() && (
            <>
              <LabelAndValue
                label={'Timeout'}
                value={scanSettings.timeout === '--' ? 'Auto' : `${scanSettings.timeout} ms`}
                direction={'column'}
                longTextLineNumberLimit={2}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

import React from 'react';
import './EventsLabel.scss';
export default function EventsLabel({
  label,
  createdts,
  event,
}: {
  label: string;
  createdts: string;
  event: string;
}) {
  const mapColorStatus = (event: string) => {
    switch (event) {
      case 'hosting_provider':
        return 'hosting-provider-event';
      case 'registrar':
        return 'registrar-event';
      case 'registrant':
        return 'registrant-event';
      case 'registry':
      case 'tld':
        return 'tld-event';
      default:
        return 'takedown-event';
    }
  };
  return (
    <div className={`timeline-event-container ${mapColorStatus(event)}`}>
      <div className='timeline-event-label'>{label}</div>
      <div className='timeline-event-time'>{createdts}</div>
    </div>
  );
}

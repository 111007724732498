import React, { ChangeEvent, useRef } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { CloseIcon } from '../../../assets/SVGIcons';
import AttachFile from '../../Common/AttachFile';
import { DISPUTE_DISPOSITION_OPTIONS } from '../../Common/DisputeStatus';
import { RadioButtons } from '../../Common/RadioButtons';
import { IDisputeData } from '../InsightsContainer';

interface IInsightsDisputeModalProps {
  isDisputeModalShown: boolean;
  toggleDisputeModal: () => void;
  submitDisputeVerdict: (formValues: any) => void;
  sendingDisputeRequest: boolean;
  disputeData: IDisputeData;
  selectedTheme: string;
  disputeError: string;
}

const COMMENT_MAX_LENGTH = 1000;

export default function InsightsDisputeModal(props: IInsightsDisputeModalProps) {
  const {
    isDisputeModalShown,
    toggleDisputeModal,
    submitDisputeVerdict,
    sendingDisputeRequest,
    disputeData,
    selectedTheme,
    disputeError,
  } = props;

  const { disposition, comments } = disputeData;

  const formValues = useRef<{
    disposition: string;
    comments: string;
    attachments: File[];
  }>({
    disposition: '',
    comments: '',
    attachments: [],
  });

  const updateValue = (value: string | File[], field: string) => {
    formValues.current[field] = value;
  };
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateValue(event.target.value, controlId);
  };

  const handleRadioButtonChange = (value: string, controlId: string) => {
    formValues.current[controlId] = value;
  };
  const handleFilesAdded = (files: File[], controlId: string) => {
    updateValue(files, controlId);
  };

  const renderDisputeOftions = () => {
    const DISPUTE_OPTIONS = [];
    for (const value in DISPUTE_DISPOSITION_OPTIONS) {
      DISPUTE_OPTIONS.push({ label: DISPUTE_DISPOSITION_OPTIONS[value], value });
    }

    return DISPUTE_OPTIONS;
  };

  return (
    <div>
      {' '}
      <Modal show={isDisputeModalShown} onHide={toggleDisputeModal} className={`${selectedTheme}`}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Dispute Disposition</Modal.Title>
          <div className='close' onClick={toggleDisputeModal}>
            <CloseIcon color='grey' />
          </div>
        </Modal.Header>
        <Modal.Body>
          {disputeError && <div className={'mb-2 text-danger'}>{disputeError}</div>}
          <RadioButtons
            label='Please select what you believe to be the correct disposition.'
            defaultValue={disposition}
            options={renderDisputeOftions()}
            onChange={(disposition: string) => handleRadioButtonChange(disposition, 'disposition')}
            direction={'column'}
          />
          <AttachFile
            label='Attach File'
            onChangeHandler={files => handleFilesAdded(files, 'attachments')}
          />
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as='textarea'
            defaultValue={comments}
            maxLength={COMMENT_MAX_LENGTH}
            className={'playbook-description-input'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleTextFieldChange(e, 'comments');
            }}
          />
          <Form.Text>
            {COMMENT_MAX_LENGTH - (comments?.length || 0)} characters remaining.
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={toggleDisputeModal}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => submitDisputeVerdict(formValues.current)}
            disabled={sendingDisputeRequest}
          >
            {sendingDisputeRequest ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : (
              'Submit'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import '../Playbook/Style/playbook.scss';
import Form from 'react-bootstrap/Form';
import { duplicateObject } from '../../constants';
import BinIcon from '../../assets/icons/Bin.svg';

interface ICompHookProps {
  values: any;
  field: any;
  onUpdate: (key: string, value: any) => void;
}

const ConnectorParametersInput = ({ values, field, onUpdate }: ICompHookProps) => {
  const detailKey = field.key.toLowerCase();
  const [detailItems, setDetailItems] = useState(values.details[detailKey] || []);

  const onAddItem = useCallback(() => {
    const newDetailItems = [...detailItems, { parameter: '', value: '' }];
    setDetailItems(newDetailItems);
    onUpdate(detailKey, newDetailItems);
  }, [detailItems, onUpdate, detailKey]);

  const onRemoveItem = useCallback(
    (idx: number) => {
      const newDetailItems = duplicateObject(detailItems);
      newDetailItems.splice(idx, 1);
      setDetailItems(newDetailItems);
      onUpdate(detailKey, newDetailItems);
    },
    [detailItems, onUpdate, detailKey],
  );

  const onEditItem = useCallback(
    (idx: number, key: string, value) => {
      const newDetailItems = duplicateObject(detailItems);
      newDetailItems[idx][key] = value;
      setDetailItems(newDetailItems);
      onUpdate(detailKey, newDetailItems);
    },
    [detailItems, onUpdate, detailKey],
  );

  const fieldLabel = useMemo(() => {
    return `${field.label} ${['Headers', 'Form Data'].includes(field.label) ? '(Optional)' : ''}`;
  }, [field.label]);

  return (
    <div className={'parameters-input'}>
      <div className={'mt-3'}>
        <Form.Label className={'mb-0'}>{fieldLabel}</Form.Label>
        <div onClick={onAddItem} className='d-flex align-items-center cursor-pointer add-container'>
          <img src='/integrations/Add-blue.svg' className='add-icon' />
          <span className='add'>ADD</span>
        </div>
      </div>
      {_.map(detailItems, (item, idx: number) => (
        <div key={idx} className={'mt-3 w-100 d-flex align-items-center'}>
          <Form.Control
            className={'mr-2'}
            defaultValue={item.parameter || ''}
            onChange={(e: any) => {
              onEditItem(idx, 'parameter', e.currentTarget.value);
            }}
          />
          <Form.Control
            className={'mx-2'}
            defaultValue={item.value || ''}
            onChange={(e: any) => {
              onEditItem(idx, 'value', e.currentTarget.value);
            }}
          />
          <img
            className={'ml-2 cursor-pointer'}
            src={BinIcon}
            alt={'remove'}
            onClick={() => {
              onRemoveItem(idx);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export { ConnectorParametersInput };

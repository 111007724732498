import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import Card from 'react-bootstrap/Card';
import AssetsService from '../../services/assests.service';
import { TableContextProvider } from '../Common/Table/table.context';
import { ITableDataColumn, TableData } from '../Common/Table/table.data';
import { TExportTable } from '../Common/Table/constant';
import { Dropdown } from '../Common/Dropdown';
import { getSingleOrPluralForm, isBrandReadOnlyUser } from '../../constants';
import { AppState } from '../../helpers';
import { alertActions } from '../../actions';
import { ASSETS_TABLE_NAME, MANAGED_BY_AFFILIATED } from './constants';
import AddNewAssetModal from './AddNewAssetModal';
import UploadAssetModal from './UploadAssetModal';
import { getCSVFile, massagedAssetsManagedByCustomer } from './util';
import ThemeContext from '../../context/ThemeContext';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setIsAgGridClientMode } from '../../reducers/table.reducer';
import { LoadingWrapper } from '../Common/LoadingWrapper';

interface IItem {
  asset_name: string;
  ipAddress: string;
  createdTs: string;
  displayedCreatedTs: string;
  isNewAdded?: boolean;
  children: any;
  brand_id: number;
}

interface IAssetsProps {
  tableId: string;
  exportCSVTitle: string;
  tableColumns: any[];
  tableTitle: string;
}

enum EAddAssetsType {
  List = 'list',
  File = 'file',
}

type IProps = IAssetsProps;

interface IAssetsState {
  data: IItem[];
  isAddingNewAssets: boolean;
  addAssetsModalShown: boolean;
  uploadFileModalShown: boolean;
}

export enum EModalType {
  None = 'none',
  AddForm = 'Add New Assets',
  Upload = 'Upload Multiple Assets',
}
const assetsService = new AssetsService();

const ManagedByCustomer = ({
  tableId,
  tableColumns,
  exportCSVTitle,
  tableTitle,
}: IProps): JSX.Element => {
  const [isAddingNewAssets, setIsAddingNewAssets] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const dispatch = useAppDispatch();

  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);
  const brandInfo = useAppSelector((state: AppState) => state.dashboardReducer.brandInfo);

  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, [dispatch]);

  const [displayedModalType, setDisplayedModalType] = useState<EModalType>(EModalType.None);
  const [allAssets, setAllAssets] = useState([]);
  const { selectedTheme } = useContext(ThemeContext);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  //TODO: move this code to a seaprate custom hook
  useEffect(() => {
    getAllAssets();
  }, []);

  const getAllAssets = () => {
    setShowLoader(true);
    assetsService
      .getAssets(tableId === MANAGED_BY_AFFILIATED)
      .then((assets: any) => {
        const data = massagedAssetsManagedByCustomer(assets);
        setShowLoader(false);
        setAllAssets(data);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const headerButtons = !isBrandReadOnlyUser(user) ? (
    <Dropdown
      boxStyle
      variant='primary'
      fixedPlaceholder={'Add Assets'}
      key={'Add Asset Dropdown'}
      options={[
        {
          label: EModalType.AddForm,
          value: EModalType.AddForm,
        },
        {
          label: EModalType.Upload,
          value: EModalType.Upload,
        },
      ]}
      onChange={selection => {
        setDisplayedModalType(selection.value);
      }}
    />
  ) : null;

  const addAssetsCallback = (
    res: any,
    successCb: any = _.noop,
    failureCb: any = _.noop,
    addAssetsType: EAddAssetsType = EAddAssetsType.List,
  ) => {
    const { result } = res;

    const successCount = result?.success || 0;
    const existedCount = result?.existing || 0;
    const failureCount = result?.failure || 0;

    if (successCount) {
      dispatch(
        alertActions.success(
          `${successCount} new ${getSingleOrPluralForm(successCount, 'asset')} added`,
        ),
      );
      getAllAssets();
      successCb();
    }

    if (existedCount) {
      dispatch(
        alertActions.success(`
        ${existedCount} ${getSingleOrPluralForm(
          existedCount,
          'asset',
        )} already exists in Managed By Affiliates or Managed By ${brandInfo?.brand?.orgName}`),
      );
      successCb();
    }

    if (failureCount) {
      dispatch(
        alertActions.error(
          `Input error, ${failureCount} ${getSingleOrPluralForm(successCount, 'asset')} not added`,
        ),
      );
      failureCb();
    }
    setIsAddingNewAssets(false);
    setDisplayedModalType(EModalType.None);
  };

  const addNewAssets = (data: any, successCb?: any, failureCb?: any) => {
    setIsAddingNewAssets(true);
    assetsService
      .addAssetsByInput(data, tableId === MANAGED_BY_AFFILIATED)
      .then(resp => addAssetsCallback(resp, successCb, failureCb))
      .catch(err => {
        dispatch(alertActions.error(err.message));
        setShowLoader(false);
        setIsAddingNewAssets(false);
      });
  };

  const uploadNewAssetFile = (event: any, brandId?: number) => {
    const data = new FormData();

    data.append('file', event.target.files[0]);
    const reader = new FileReader();

    let text: any;

    reader.onload = async event => {
      text = event && event.target && event.target.result;
      if (typeof text === 'string') {
        setIsAddingNewAssets(true);
      }
    };

    reader.readAsText(event.target.files[0]);
    assetsService
      .addAssetsFromFile(data, brandId, tableId === MANAGED_BY_AFFILIATED)
      .then(res => addAssetsCallback(res, _.noop, _.noop, EAddAssetsType.File))
      .catch(err => {
        dispatch(alertActions.error(err.message));
        setIsAddingNewAssets(false);
      });
  };

  const deleteAssets = (deletedItems: IItem[]) => {
    assetsService
      .deleteAssets(
        deletedItems.map((item: IItem) => item.asset_name).join(','),
        deletedItems.map((item: IItem) => item?.brand_id),
      )
      .then(() => getAllAssets())
      .catch((err: any) => dispatch(alertActions.error(err.message)))
      .finally(() => {
        setSelectedItems([]);
      });
  };

  const closeAssetsModal = () => {
    setDisplayedModalType(EModalType.None);
  };

  const exportAssets = (data: any, columns: ITableDataColumn[], type: TExportTable) => {
    getCSVFile(data, columns, exportCSVTitle);
  };

  const onItemCheck = (checked: boolean, item: any, selectedItems: any) => {
    setSelectedItems(selectedItems);
  };

  return (
    <LoadingWrapper isLoading={showLoader}>
      <div className={'table-page page-content'}>
        <Card className='table-container'>
          <TableContextProvider
            columns={tableColumns}
            dashboardName={ASSETS_TABLE_NAME}
            tableId={tableId}
            tableIndex={`brand_assets_${tableId}`}
          >
            <TableData
              id={tableId}
              tableIndex={`brand_assets_${tableId}`}
              title={tableTitle}
              deleteModalConfig={{
                title: 'Delete Assets',
                renderBody: (selectedItems: any) => {
                  return (
                    <p>
                      {'Are you sure you want to delete ' +
                        selectedItems.length +
                        getSingleOrPluralForm(selectedItems.length, ' asset') +
                        '?'}
                    </p>
                  );
                },
              }}
              data={allAssets}
              columns={tableColumns}
              enableCheckbox
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              exportFn={exportAssets}
              indexBy={'asset_name'}
              disableDatePicker
              deleteFn={deleteAssets}
              customTools={headerButtons}
              showLoader={showLoader}
              user={user}
              onItemCheck={onItemCheck}
              selectedItems={selectedItems}
            />
          </TableContextProvider>
        </Card>
      </div>
      <AddNewAssetModal
        show={displayedModalType === EModalType.AddForm}
        onHide={closeAssetsModal}
        onSubmit={addNewAssets}
        brandInfo={brandInfo?.brand}
        isAddingNewAssets={isAddingNewAssets}
        selectedTheme={selectedTheme}
      />

      <UploadAssetModal
        show={displayedModalType === EModalType.Upload}
        onHide={closeAssetsModal}
        onSubmit={uploadNewAssetFile}
        brandInfo={brandInfo?.brand}
        isAddingNewAssets={isAddingNewAssets}
        selectedTheme={selectedTheme}
      />
    </LoadingWrapper>
  );
};

export default ManagedByCustomer;

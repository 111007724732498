import React, { useState } from 'react';
import _ from 'lodash';
import { alertActions } from '../../../../actions';
import { AlertActionsTypes } from '../../../../constants';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import ModalOverlay from '../../../Common/ModalOverlay';
import { ISearchesTableItemProps, ugcType } from '../../Types/ugc.types';
import { LabelAndValue } from '../../../Common/LabelAndValue';
import UgcService from '../../../../services/ugc.service';
import moment from 'moment';
import {
  getDefaultEndDate,
  getDefaultScanStartDate,
} from '../../../Common/Table/ag-table/constants';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { AppState } from '../../../../helpers';

const ugcService = new UgcService();

interface IChangeSearchTermStatusModalProps {
  data: ISearchesTableItemProps | undefined;
  statusAction: string;
  description: string;
  onCloseModal: () => void;
  onModalSubmit?: (data: any) => void;
  type: string;
}

enum ESearchTermActions {
  DELETE = 'delete',
  DEACTIVATE = 'deactivate',
  ACTIVATE = 'activate',
  RESCAN = 'rescan',
}

const ChangeSearchTermStatusModal = ({
  data,
  statusAction,
  description,
  onCloseModal,
  onModalSubmit = _.noop,
  type,
}: IChangeSearchTermStatusModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rescanStartDate, setRescanStartDate] = useState<string>(getDefaultScanStartDate());
  const [rescanEndDate, setRescanEndDate] = useState<string>(getDefaultEndDate());

  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);

  const dispatch = useAppDispatch();

  const onSubmit = () => {
    setIsLoading(true);
    ugcService
      .updateSearchTerm(
        data?.id,
        { isDisabled: statusAction === ESearchTermActions.DEACTIVATE },
        type,
      )
      .then(() => {
        dispatch(
          alertActions.success(`Search term "${data!.searchTerm}" successfully ${statusAction}d.`),
        );
      })
      .catch(() => {
        dispatch(
          alertActions.error(
            `${_.capitalize(statusAction)} Search term "${data!.searchTerm}" failure.`,
          ),
        );
      })
      .finally(() => {
        setIsLoading(false);
        onModalSubmit(data);
      });
  };

  const onDeleteTerms = () => {
    setIsLoading(true);
    ugcService
      .deleteSearchTerm(data?.id, type)
      .then(() => {
        dispatch(alertActions.success(`Search term "${data!.searchTerm}" successfully deleted`));
      })
      .catch(() => {
        dispatch(alertActions.error(`Deleting of Search term "${data!.searchTerm}" got failed`));
      })
      .finally(() => {
        setIsLoading(false);
        onModalSubmit(data);
      });
  };

  const getRecanData = () => {
    let reScanData = {};
    if (new Date(rescanEndDate).getTime() >= new Date(rescanStartDate).getTime()) {
      reScanData = {
        brand: user.brand,
        startDate: rescanStartDate,
        endDate: rescanEndDate,
        searchTermId: data?.id,
      };
    } else {
      dispatch(alertActions.error(`Correct start and end dates.`));
    }
    return reScanData;
  };

  const onReScanSearchTerm = () => {
    setIsLoading(true);
    const rescanData = getRecanData();
    ugcService
      .reScanSearchTerm(rescanData, type)
      .then(response => {
        if (response.ok) {
          dispatch(
            alertActions.success(`Search term "${data!.searchTerm}" successfully rescanned`),
          );
        } else {
          dispatch(alertActions.error(`Rescan of Search term "${data!.searchTerm}" failed`));
        }
      })
      .catch(error => {
        dispatch(alertActions.error(`Error rescannig Search term "${data!.searchTerm}"`));
      })
      .finally(() => {
        setIsLoading(false);
        onModalSubmit(data);
      });
  };

  return (
    <ModalOverlay
      show={!_.isEmpty(data)}
      title={`${_.capitalize(statusAction)} Search Term`}
      onCancel={onCloseModal}
      onSubmit={
        statusAction === ESearchTermActions.DELETE
          ? onDeleteTerms
          : statusAction === ESearchTermActions.RESCAN
          ? onReScanSearchTerm
          : onSubmit
      }
      submitButtonLabel={_.capitalize(statusAction)}
      isLoading={isLoading}
    >
      <div className={'detection-action-modal'}>
        <Form.Group>
          <div className={'description'}>
            {description} <b>"{data?.searchTerm}"</b> ?
          </div>
          {statusAction === ESearchTermActions.RESCAN && (
            <div className={'d-flex flex-column'}>
              <label>
                Start Date:
                <input
                  type='date'
                  onChange={event => setRescanStartDate(event.target.value)}
                  value={rescanStartDate}
                />
              </label>
              <label>
                End Date:
                <input
                  type='date'
                  onChange={event => setRescanEndDate(event.target.value)}
                  value={rescanEndDate}
                />
              </label>
            </div>
          )}
        </Form.Group>
        {data && data?.times > 0 && (
          <>
            <Form.Group>
              <div className={'d-flex'}>
                <div className={'w-50'}>
                  <LabelAndValue
                    label={'Times Detected'}
                    value={data?.times + ''}
                    direction={'column'}
                    longTextLineNumberLimit={1}
                  />
                </div>
                <div className={'w-50'}>
                  <LabelAndValue
                    label={'Last Detected'}
                    value={moment(data?.historyCreatedTs).format('DD-MMM-YYYY')}
                    direction={'column'}
                    longTextLineNumberLimit={1}
                  />
                </div>
              </div>
            </Form.Group>
            {statusAction === ESearchTermActions.DELETE && (
              <Form.Group>
                <div className={'description-danger'}>
                  {`Deleting search term will remove ${data.times} associated findings`}
                </div>
              </Form.Group>
            )}
          </>
        )}
      </div>
    </ModalOverlay>
  );
};

export default ChangeSearchTermStatusModal;

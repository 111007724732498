import sha256 from 'sha256';

export default class PendoService {
  constructor() {}

  initialize(args: any) {
    const email_sha256 = sha256(args.email);
    const id = email_sha256.slice(0, 3) + '-' + email_sha256.slice(-3);
    const pendoParmas = {
      visitor: {
        id: id, // Required if user is logged in, default creates anonymous ID
        email: args.email.split('@')[1],
        domain: window.location.hostname,
        account_creation_date: args.created_ts,
        first_login_date: args.accepted_ts,
        latest_login_date: args.last_active_ts,
        // email: args.email,        // Recommended if using Pendo Feedback, or NPS Email
        // full_name: args.first_name+args.last_name,  // Recommended if using Pendo Feedback
        // role: args.role_name,         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: id,
      },
    };
    window?.pendo.initialize(pendoParmas);
  }
}

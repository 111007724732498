import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import rootReducer from '../reducers';
import { AlertActionsTypes, UserActionsTypes } from '../constants';
import { configureStore } from '@reduxjs/toolkit';

// currtly for unit test only
export function setupStore(preloadedState?: any) {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    preloadedState,
  });
}

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export type AppState = ReturnType<typeof rootReducer>;
export type AppAction = AlertActionsTypes | UserActionsTypes;

//Added Redux devtool extension as per https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(
//   rootReducer,
//   composeEnhancer(applyMiddleware(thunk as ThunkMiddleware<AppState, AppAction>, loggerMiddleware)),
// );

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

import fetch from './api.service';
import { handleResponse } from './serviceWorker';

export default class AppConfigService {
  getAllCategories() {
    return fetch('/api/v1/category', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getAllUrlConstructions() {
    return fetch('/api/v1/url-construction', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getContentPlatforms(type: string) {
    return fetch(`/platform-api/v1/content/${type}/platform`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getContentOrigins(type: string) {
    return fetch(`/platform-api/v1/content/${type}/origin`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getContentCategories(type: string) {
    return fetch(`/platform-api/v1/content/${type}/category`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getAllTags() {
    return fetch('/platform-api/v1/tag', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getBrandPropertyTypes = () => {
    return fetch('/platform-api/v1/user/property-type', {
      credentials: 'include',
    }).then(handleResponse);
  };

  getSourceFeedCategories = () => {
    return fetch('/platform-api/v1/scan-source', {
      credentials: 'include',
    }).then(handleResponse);
  };
}

import { WebRiskLookupResponse } from './web-risk-lookup-response';
import { ReportingEntity } from './reporting-entity';
import { ContentType } from './content-type';

// could not find where this event occurs.
export interface UrlDetectedEventData {
  timestamp: string;
}

export interface SOCActionsEventData {
  url: string;
  title: string;
  userId: number;
  comments: string;
  imagePath: string;
  timestamp: string;
  userEmail: string;
  screenshotUrl: string;
  reportingEntityUserName: string;
}

export type GoogleSafeBrowsingData =
  | {
      operationId: string;
    }
  | {
      report: WebRiskLookupResponse;
    };

type TakedownImportSourceDetails =
  | { type: 'elastic_brand_abuse_reporting'; id: string }
  | { type: 'soc_gmail_inbox'; id: string };

export interface TakedownEventData {
  userId: number;
  comments: string;
  reportingEntity: ReportingEntity;
  dstUrl: string;
  primaryDomain: string;
  type: ContentType;
  tld: string;
  timestamp: string;
  reportedToCloudflare: boolean;
  reportedToFreenom: boolean;
  reportedToGoDaddy: boolean;
  reportedToApwg: boolean;
  reportedToGoogleSafeBrowsing: GoogleSafeBrowsingData;
  sentAbuseEmail: boolean;
  attachments: string[];
  toEmails?: string[];
  ccEmails?: string[];
  bccEmails?: string[];
  sourceDetails?: TakedownImportSourceDetails;
  reportingEntityUserName?: string;
  groupedTimestamp?: string[];
  skipAutoTakedown?: boolean;
  latestAction?: string;
  isReply?: boolean;
  eventLabel?: string;
}

export interface DisputeEventData {
  url: string;
  userId: number;
  userEmail: string;
  currentDisposition: string;
  customerDisposition: string;
  comments: string;
  timestamp: string;
  skipAutoTakedown?: boolean;
}

export interface HuntingUpdateEventData {
  // important to note this is checkPhishUserId, so they won't accidentally get mistaken for a zion user id
  checkPhishUserId: number;
  disposition?: string;
  descriptorAction?: string;
  computerVisionHashAction?: string;
  srcUrl: string;
  dstUrl: string;
  source: string;
  nlpAction?: string;
  disputeStatus?: string;
  logoDetectionAction?: string;
  brandScanTsAction?: string;
  timestamp: string;
  skipAutoTakedown?: boolean;
}

export interface SocTakedownEventData {
  urlSha256: string;
  userId: number;
  comments: string;
  reportingEntity: ReportingEntity;
  type: ContentType;
  timestamp: string;
  reportedToApwg: boolean;
  reportedToGoogleSafeBrowsing: GoogleSafeBrowsingData;
  reportedToHostingProvider: boolean;
  reportedToRegistrar: boolean;
  reportedToRegistrant: boolean;
  reportedToOther: boolean;
  reportedToTld: boolean;
  reportedToRegistry: boolean;
  attachments: string[];
  skipAutoTakedown?: boolean;
  latestAction: string;
  isReply?: boolean;
  eventLabel?: string;
}

export interface AdHocScreenshotData {
  s3url: string[];
  timestamp: string;
  skipAutoTakedown?: boolean;
}

export interface SocCustomerMessageEventData {
  message: string;
  timestamp: string;
  attachments: string[];
  submittedByUserId: number;
}

export interface CustomerComment {
  message: string;
  timestamp: string;
  attachments: string[];
  submittedByUserId: number;
  reportingEntityUserName?: string;
  skipAutoTakedown?: boolean;
  latestAction?: string;
  isReply?: boolean;
  eventLabel?: string;
}

export interface CustomerSocResponse {
  message: string;
  timestamp: string;
  attachments: string[];
  requestEventHistoryId: number;
  submittedByUserId: number;
  reportingEntityUserName?: string;
  skipAutoTakedown?: boolean;
}

export interface CustomerDispute {
  comments: string;
  attachmentPaths: string[];
  reportingEntity: string;
  timestamp: string;
  url: string;
  userEmail: string;
  userId: number;
}
export interface SocRequestedDocument {
  message: string;
  timestamp: string;
  submittedByUserId: number;
  groupedTimestamp?: string[];
  skipAutoTakedown?: boolean;
  latestAction?: string;
  isReply?: boolean;
  eventLabel?: string;
}

export enum UrlEventTypes {
  URL_DETECTED = 'url_detected',
  MANUAL_TAKEDOWN = 'manual_takedown',
  AUTO_TAKEDOWN = 'auto_takedown',
  DISPUTE = 'dispute',
  HUNTING_UPDATE = 'hunting_update',
  SOC_TAKEDOWN = 'soc_takedown',
  ADHOC_SCREENSHOT = 'adhoc_screenshot',
  SOC_CUSTOMER_MESSAGE = 'soc_customer_message',
  CUSTOMER_COMMENT = 'customer_comment',
  CUSTOMER_SOC_RESPONSE = 'customer_soc_response',
  SOC_REQUESTED_DOCUMENT = 'soc_request_document',
  URL_PHISH_DETECTED = 'url_detected|phish_detected',
  URL_SCAM_DETECTED = 'url_detected|scam_detected',
  SOC_ACTION = 'soc_action',
}

export type UrlEventDetails =
  | {
      eventType: UrlEventTypes.URL_DETECTED;
      data: UrlDetectedEventData;
    }
  | {
      eventType: UrlEventTypes.MANUAL_TAKEDOWN;
      data: TakedownEventData;
    }
  | {
      eventType: UrlEventTypes.AUTO_TAKEDOWN;
      data: TakedownEventData;
    }
  | {
      eventType: UrlEventTypes.DISPUTE;
      data: DisputeEventData;
    }
  | {
      eventType: UrlEventTypes.HUNTING_UPDATE;
      data: HuntingUpdateEventData;
    }
  | {
      eventType: UrlEventTypes.SOC_TAKEDOWN;
      data: SocTakedownEventData;
    }
  | {
      eventType: UrlEventTypes.ADHOC_SCREENSHOT;
      data: AdHocScreenshotData;
    }
  | {
      eventType: UrlEventTypes.SOC_CUSTOMER_MESSAGE;
      data: SocCustomerMessageEventData;
    }
  | {
      eventType: UrlEventTypes.CUSTOMER_COMMENT;
      data: CustomerComment;
    }
  | {
      eventType: UrlEventTypes.CUSTOMER_SOC_RESPONSE;
      data: CustomerSocResponse;
    }
  | {
      eventType: UrlEventTypes.SOC_REQUESTED_DOCUMENT;
      data: SocRequestedDocument;
    }
  | {
      eventType: UrlEventTypes.SOC_ACTION;
      data: SOCActionsEventData;
    };

export type UrlEventDataTypes =
  | UrlDetectedEventData
  | TakedownEventData
  | DisputeEventData
  | HuntingUpdateEventData
  | SocTakedownEventData
  | AdHocScreenshotData
  | SocCustomerMessageEventData
  | CustomerComment
  | CustomerSocResponse
  | SocRequestedDocument
  | SOCActionsEventData;

export enum EEnitiyEvent {
  HOSTING_PROVIDER = 'hosting_provider',
  REGISTRAR = 'registrar',
  REGISTRANT = 'registrant',
  REGISTRY = 'registry',
  TLD = 'tld',
  OTHER = 'other',
}

export const reportedEntites = [
  { key: 'reportedToHostingProvider', value: EEnitiyEvent.HOSTING_PROVIDER },
  { key: 'reportedToRegistrar', value: EEnitiyEvent.REGISTRAR },
  { key: 'reportedToRegistrant', value: EEnitiyEvent.REGISTRANT },
  { key: 'reportedToRegistry', value: EEnitiyEvent.REGISTRY },
  { key: 'reportedToTld', value: EEnitiyEvent.TLD },
  { key: 'reportedToOther', value: EEnitiyEvent.OTHER },
];

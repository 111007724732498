export class EventHandler<TEvent> {
  listeners = new Set<(event: TEvent) => void | Promise<void>>();
  addListener(listener: (event: TEvent) => void | Promise<void>): void {
    this.listeners.add(listener);
  }

  removeListener(listener: (event: TEvent) => void | Promise<void>): void {
    this.listeners.delete(listener);
  }

  fireEvent(event: TEvent): void {
    this.listeners.forEach(l => {
      void l(event);
    });
  }
}

import { PlatformMainModules, featureIsAvailable } from '../constants';
import { useAppSelector } from '../helpers/hooks';
import { useMemo } from 'react';

export const useAvailableModules = () => {
  const user = useAppSelector(state => state.dashboardReducer?.user);
  // check module's enabledCode and disabledCode to determine if it's available for this user
  const allAvailableModules = useMemo(
    () =>
      PlatformMainModules.filter(
        module =>
          featureIsAvailable(user, module.enabledCode) &&
          !featureIsAvailable(user, module.disabledCode),
      ),
    [user],
  );

  return allAvailableModules;
};

export const useGetUser = () => {
  const user = useAppSelector(state => state.dashboardReducer?.user);
  return user;
};

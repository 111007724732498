import React from 'react';
import { LabelAndValue } from '../Common/LabelAndValue';

export function SocAutofillOptions() {
  return (
    <div>
      <LabelAndValue
        alignItems={'flexStart'}
        label={'Takedown Urls'}
        value={'[TAKEDOWN_URLS]'}
        direction={'column'}
        copyButton={true}
      />
      <LabelAndValue
        alignItems={'flexStart'}
        label={'Takedown Domain'}
        value={'[TAKEDOWN_DOMAIN]'}
        direction={'column'}
        copyButton={true}
      />
      <LabelAndValue
        alignItems={'flexStart'}
        label={'Brand Name'}
        value={'[BRAND_NAME]'}
        direction={'column'}
        copyButton={true}
      />

      <LabelAndValue
        alignItems={'flexStart'}
        label={'Organization'}
        value={'[ORG_NAME]'}
        direction={'column'}
        copyButton={true}
      />

      <LabelAndValue
        alignItems={'flexStart'}
        label={'Address'}
        value={'[BRAND_ADDRESS]'}
        direction={'column'}
        copyButton={true}
      />

      <LabelAndValue
        alignItems={'flexStart'}
        label={'Official Domain'}
        value={'[BRAND_DOMAIN]'}
        direction={'column'}
        copyButton={true}
      />

      <LabelAndValue
        alignItems={'flexStart'}
        label={'Insight URLs'}
        value={'[INSIGHT_URLS]'}
        direction={'column'}
        copyButton={true}
      />
    </div>
  );
}

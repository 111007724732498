import { userConstants } from '../constants';

type registerType = {
  registering: boolean;
};

const registration = (state = { registering: false }, action: any): registerType => {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return { registering: false };
    case userConstants.REGISTER_FAILURE:
      return { registering: false };
    default:
      return state;
  }
};
export { registration };

import React, { useState, useEffect } from 'react';
import { getLocalStorageValue } from '../../../../constants';
import UgcService from '../../../../services/ugc.service';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { IFilter } from '../../../Common/Table/constant';
import '../../Style/ThreatDetection.scss';
import { ugcType } from '../../Types/ugc.types';
import UgcThreatContent from './UgcThreatContent';
import UgcThreatHeader from './UgcThreatHeader';
import { socialMediaMockCountData } from '../../../../constants';

const UgcThreatContainer = ({
  startDate,
  endDate,
  ugcPlatforms,
  ugcCategories,
  type,
  isCustomCalendarRange,
}: any): JSX.Element => {
  const ugcService = new UgcService();
  const isDemo = window.location.pathname.includes('premium') || false;
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [threatDetections, setThreatDetections] = useState<any>([]);

  const setDetectionsData = (res: any) => {
    setThreatDetections(res.threatDetections);
    setIsLoading(false);
  };
  useEffect(() => {
    const oldFilters = getLocalStorageValue([`${type}DashBoard`, 'summary_widget']);
    const allFilters = [...filters];
    if (!filters.length && Object.keys(oldFilters).length > 0 && oldFilters.filters.length > 0) {
      allFilters.push(...oldFilters?.filters);
      setFilters(allFilters);
    }
    const getDetections = () => {
      setIsLoading(true);
      isDemo && type === 'social'
        ? setDetectionsData(socialMediaMockCountData)
        : ugcService
            .getDetectionCounts(startDate, endDate, allFilters, type, isCustomCalendarRange)
            .then(res => {
              setDetectionsData(res);
            })
            .catch(error => {
              setIsLoading(false);
              console.log(error);
            });
    };
    getDetections();
  }, [startDate, endDate, filters]);

  const updateFilters = (filter: any) => {
    setFilters(filter);
  };
  const query = { startDate, endDate };
  return (
    <div className='social-threat-detection-container'>
      <UgcThreatHeader
        title='Detection Overview'
        ugcPlatforms={ugcPlatforms}
        updateFilters={updateFilters}
        type={type}
      />
      <div className='social-threat-content-container'>
        <LoadingWrapper isLoading={isLoading}>
          <UgcThreatContent
            filters={filters}
            ugcCategories={type === ugcType.App_Store ? ugcPlatforms : ugcCategories}
            threatDetections={threatDetections}
            type={type}
            query={query}
          />
        </LoadingWrapper>
      </div>
    </div>
  );
};
export default UgcThreatContainer;

import * as React from 'react';
import './Release.scss';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { Image } from '../Common/Image';
import CustomizeColumn from '../../assets/releasePage/customize-column.png';
import NewDashboard from '../../assets/releasePage/new-dashboard.png';
import DashboardGlance from '../../assets/releasePage/dashboard-glance.png';

interface IReleaseNoteVersionItemProps {
  version: string;
  date: string;
  features: [
    {
      name: string;
      description: string;
    },
  ];
}

interface IReleaseNoteVersionItemState {}

class ReleaseNoteVersionItem extends React.Component<
  IReleaseNoteVersionItemProps,
  IReleaseNoteVersionItemState
> {
  shouldComponentUpdate(): boolean {
    return false;
  }

  render() {
    const { version, date, features } = this.props;
    return (
      <div className={'release-page d-flex flex-column align-items-start p-5 m-auto'}>
        <h2 className={'align-self-center mb-3'}>Bolster Platform Release, v{version}</h2>
        <div className={'align-self-center mb-5'}>Platform &bull; {date}</div>
        <h3 className={'mb-4'}>What’s New:</h3>
        {features.map(({ name, description }) => (
          <div className='pl-4'>
            <h4>{name}</h4>
            <div>{description}</div>
          </div>
        ))}
      </div>
    );
  }
}

interface IReleaseProps {}

interface IReleaseState {}

class Release extends React.Component<IReleaseProps, IReleaseState> {
  shouldComponentUpdate(
    nextProps: Readonly<IReleaseProps>,
    nextState: Readonly<IReleaseState>,
    nextContext: any,
  ): boolean {
    return false;
  }

  renderImage(image: string, footNote: string) {
    return (
      <>
        <Image imagePath={image} />
        <p className={'pt-2 mb-5 align-self-center image-note'}>{footNote}</p>
      </>
    );
  }

  render() {
    return (
      <AuthenticationWrapper>
        <div>
          <ReleaseNoteVersionItem
            version={'4.0'}
            date={'Mar 4th 2022'}
            features={[
              {
                name: 'Marketplace',
                description:
                  'Extending visibility and takedowns of counterfeit goods and fake listings on marketplaces like Amazon, Walmart, and Ebay.',
              },
            ]}
          />
          <ReleaseNoteVersionItem
            version={'3.1'}
            date={'Dec 23th, 2021'}
            features={[
              {
                name: 'Dark Theme',
                description: '',
              },
            ]}
          />
          <ReleaseNoteVersionItem
            version={'3.0'}
            date={'Nov 30th, 2021'}
            features={[
              {
                name: 'Social Media',
                description: `Extending visibility and takedowns of malicious activities (fake ads, scams, executive impersonations) on social media platforms like YouTube, Twitter, and Reddit.`,
              },
            ]}
          />
          <ReleaseNoteVersionItem
            version={'2.3.0'}
            date={'Nov 19th, 2021'}
            features={[
              {
                name: 'Domain Purchasing',
                description: 'Ability to purchase high risk unregistered domains within the app.',
              },
            ]}
          />
          <ReleaseNoteVersionItem
            version={'2.2.0'}
            date={'Aug 1st, 2021'}
            features={[
              {
                name: 'New UI',
                description:
                  'Visibility into complete domain lifecycle and categorization of domains.',
              },
            ]}
          />
          <ReleaseNoteVersionItem
            version={'2.1.0'}
            date={'Jun 25th, 2021'}
            features={[
              {
                name: 'Playbook',
                description: `Enable to schedule and automate Bolster data queries for integration with any API enabled system.`,
              },
            ]}
          />

          <div className={'release-page d-flex flex-column align-items-start p-5 m-auto'}>
            <h2 className={'align-self-center mb-3'}>Bolster Platform Release, v2.0.1</h2>
            <div className={'align-self-center mb-5'}>Platform &bull; Apr 19th, 2021</div>

            <h3 className={'mb-4'}>What’s New:</h3>
            <ul>
              <li>
                Now the dispute verdict workflow enables you to submit comments to the Bolster
                Research team and captures these comments on Insight pages along with additional
                information
              </li>
              <li>Added dashboard table filter for dispute status</li>
              <li>Added dashboard column for “Current Disposition”</li>
            </ul>
            <h3 className={'my-4'}>Bug fixes:</h3>
            <ul>
              <li>
                Fixed: In some cases, the takedown section on the Insight page was missing the site
                status and takedown time
              </li>
            </ul>
          </div>

          <div className={'release-page d-flex flex-column align-items-start p-5 m-auto'}>
            <h2 className={'align-self-center mb-3'}>Bolster Platform Release, v2.0.0</h2>
            <div className={'align-self-center mb-5'}>Platform &bull; Sep 30th, 2020</div>

            <h5 className={'mb-4'}>
              In this release, we introduce the following new features
              <ol>
                <li>Logo Detection</li>
                <li>Single Sign On</li>
                <li>New Detection Metrics Regarding Submitter</li>
                <li>Enhanced URL Insight Page</li>
              </ol>
            </h5>

            <h3 className={'my-4'}>Logo Detection</h3>
            <p className={'mb-4'}>
              You will notice a new column in the dashboard table entitled "Logo Detected”. For each
              site listed in the table, this column will indicate “true” if one or more of your
              brand logos was detected on the site and “false” otherwise. The table filter has been
              extended so that you can filter sites based on whether they contain a logo or not.
            </p>
            <p className={'mb-4'}>
              When you click into the URL Insights page for a site with Logo Detected = true, you
              will see that the screenshot highlights your brand logo(s) that we found being
              displayed on that site.
            </p>
            <p className={'mb-4'}>
              Currently the set of logos monitored is fixed and determined by Bolster. We may extend
              this feature in the future to enable you to upload an image of a logo that Bolster
              should be monitoring.
            </p>

            <h3 className={'my-4'}>SSO</h3>
            <p className={'mb-4'}>
              We have a new option to enable your team to use Bolster via Single Sign-on. We
              currently support Okta via OIDC. Please contact Bolster support if you would like to
              set up SSO for use with Bolster.{' '}
            </p>

            <h3 className={'my-4'}>New Detection Metrics Regarding Submitter</h3>
            <p className={'mb-4'}>
              You will notice two new columns named “# Customer Scans” and “# Bolster Scans”. If you
              are submitting your own URLs either via Bulk Scan or API calls, these new metrics will
              be very helpful as follows:
            </p>
            <ul>
              <li>
                If you filter the table by “# Customer Scans” in [1,N] where N is a suitable upper
                bound on multiple submissions of the same site, you can see all your submissions
                that Bolster assessed to be relevant to your brand and with a non-clean disposition
              </li>
              <ul>
                <li>
                  If you use a secondary filter with Source = Bolster, you will see the subset of
                  the above submissions, where Bolster already monitored the site at the time of
                  your submission
                </li>
                <li>
                  If you use a secondary filter with Source = {`<Your_Brand>`}, then you will see
                  the subset of the above submissions, where at the time of your submission Bolster
                  didnot monitor the submitted site yet
                </li>
              </ul>
              <li>
                The “# Bolster Scan” metric shows how many times Bolster has been scanning a site.
                You will notice that Bolster will repeatedly scan a site to ascertain its most
                recent status regardless of the initial submitter
              </li>
            </ul>

            <h3 className={'my-4'}>Enhanced URL Insight Page</h3>
            <ul>
              <li>
                We added a new option to view the DOM (Document Object Model) Tree of the site, the
                corresponding button is located on the top right alongside the Time-Line and WhoIs
                buttons
              </li>
              <li>We added the following new fields in the Scan Results section:</li>
              <ul>
                <li>TLD: Top-Level Domain</li>
                <li>
                  # Customer Scans: The number of times you submitted this site via Bulk Scan or API
                  call
                </li>
                <li># Bolster Scans: The number of times Bolster scanned this site</li>
                <li>Takedown Time: Timestamp when site was taken down</li>
              </ul>
            </ul>
          </div>

          <div className={'release-page d-flex flex-column align-items-start p-5 m-auto'}>
            <h2 className={'align-self-center mb-3'}>Bolster Platform Release, v1.3.0</h2>
            <div className={'align-self-center mb-5'}>Platform &bull; Aug 25th, 2020</div>
            <h3 className={'mb-4'}>Counterfeit Sites Table is now Configurable</h3>
            <p className={'mb-4'}>
              You can now decide exactly what columns you want to see in the “Counterfeit Sites”
              table. By clicking the gear symbol (see red circle in the screenshot below), you can
              select which columns will be visible in the table. Once you configured the table, the
              export options (PDF, CSV) will exactly reflect the set and order of the columns
              currently in the table. You can also use any column when you create a table filter.
            </p>
            <h3 className={'mb-4'}>New Table Columns</h3>
            <p className={'mb-4'}>
              We have added the following new columns (and we are planning to add more in future
              releases):
            </p>
            <h5 className={'mb-4'}>TLD</h5>
            <p className={'mb-4'}>
              The Top-Level domain of the site, which reflects the domain at the highest level in
              the hierarchical Domain Name System of the Internet.
            </p>
            <h5 className={'mb-4'}>Country</h5>
            <p className={'mb-4'}>
              The flag and the code of the country where this site is hosted.
            </p>
            <h5 className={'mb-4'}>Takedown Time</h5>
            <p className={'mb-4'}>
              The time when this site was taken down by Bolster. This only applies to Phish and Scam
              sites.
            </p>
            <h5 className={'mb-4'}>Past Phish on Host</h5>
            <p className={'mb-4'}>
              The number of times Bolster has detected a Phishing site on this host in past.
            </p>
            <h5 className={'mb-4'}>Past Phish on IP</h5>
            <p className={'mb-4'}>
              The number of times Bolster has detected a Phishing site on this IP address in the
              past.
            </p>
            <h5 className={'mb-4'}>
              How do I get the new update?
              <ol>
                <li>
                  Visit{' '}
                  <a href={'https://platform.bolster.ai/sign-in'}>
                    https://platform.bolster.ai/sign-in
                  </a>
                </li>
                <li>
                  <span className={'bg-yellow'}>Refresh</span> the page to make sure you don't get a
                  cached copy
                </li>
              </ol>
            </h5>
            {this.renderImage(
              CustomizeColumn,
              'Click on "Gear Icon" to customize the table columns.',
            )}
          </div>

          <div className={'release-page d-flex flex-column align-items-start p-5 m-auto'}>
            <h2 className={'align-self-center mb-3'}>Bolster Platform Release, v1.2</h2>
            <div className={'align-self-center mb-5'}>Platform &bull; Jun 09, 2020</div>
            <p className={'mb-4'}>
              Now on the home page, you can now decide exactly what apps you want to see first, in
              whatever configuration you would prefer just by clicking “new dashboard” in the top
              left corner. This change will not impact any service performance, it is strictly to
              enhance the visualization of your brand’s data.
            </p>
            <h3 className={'mb-4'}>New Apps</h3>
            <p className={'mb-4'}>
              In addition to what you’ve had up until now – the graph of Counterfeit Site Detection
              over time, and Sites by Hosting Provider – we now have the following new Apps as well
              as the infrastructure to add even more Apps on an ongoing basis:
            </p>
            <h5 className={'mb-4'}>Top 10 IP Address</h5>
            <p className={'mb-4'}>
              The “Top 10 IP Addresses” App enables you to immediately see which IP addresses are
              repeatedly and most often involved in any phishing or other type of suspicious
              behavior targeting your brand
            </p>
            <h5 className={'mb-4'}>Top Originating Countries</h5>
            <p className={'mb-4'}>
              The “Top Originating Countries” App provides a map with the ten countries from which
              most phishing and other type of suspicious activities are being launched against your
              brand
            </p>
            <h5 className={'mb-4'}>Top-Level Domains</h5>
            <p className={'mb-4'}>
              The “Top-Level Domains” App quickly shows the rate at which phishing, fraudulent and
              suspicious URLs utilize various top-level domains (.com, .tk, .ml, .net, .ru, etc.).
            </p>
            <h5 className={'mb-4'}>
              How do I get the new update?
              <ol>
                <li>
                  Visit{' '}
                  <a href={'https://platform.bolster.ai/sign-in'}>
                    https://platform.bolster.ai/sign-in
                  </a>
                </li>
                <li>
                  <span className={'bg-yellow'}>Refresh</span> the page to make sure you don't get a
                  cached copy
                </li>
                <li>You should see + New Dashboard</li>
              </ol>
            </h5>
            {this.renderImage(
              NewDashboard,
              'Click on "new dashboard" to take you into edit mode and follow the prompts from there.',
            )}
            {this.renderImage(
              DashboardGlance,
              'Include new graphs in your home page for at-a-glance information.',
            )}
          </div>
        </div>
      </AuthenticationWrapper>
    );
  }
}

export { Release };

import moment from 'moment';
import { ITableApiColumn } from '../Common/Table/table.api';

export const MONITORING_TYPE = {
  premalicious: 'premalicious',
  malicious: 'malicious',
  typosquat: 'all',
};

export const DASHBOARD_NAME = 'Domain Monitoring';

export const TYPOSQUATTING_DOMAIN_MONITORING = 'domain-monitoring';
export const TYPOSQUATTING_DOMAIN_MONITORING_PREMALICIOUS = `${TYPOSQUATTING_DOMAIN_MONITORING}/pre-malicious`;
export const TYPOSQUATTING_DOMAIN_MONITORING_MALICIOUS = `${TYPOSQUATTING_DOMAIN_MONITORING}/malicious`;
export const TYPOSQUATTING_DOMAIN_MONITORING_TYPOSQUAT = `${TYPOSQUATTING_DOMAIN_MONITORING}/typosquat`;
export const TYPOSQUAT_DASHBOARD = `${TYPOSQUATTING_DOMAIN_MONITORING}/dashboard`;

export const TYPOSQUATTING_PATHNAME = `/${TYPOSQUATTING_DOMAIN_MONITORING}`;

export const TYPOSQUATTING_DASHBOARD_PATHNAME = `${TYPOSQUATTING_PATHNAME}/dashboard`;
export const TYPOSQUATTING_PREMALICIOUS_PATHNAME = `/${TYPOSQUATTING_DOMAIN_MONITORING_PREMALICIOUS}`;
export const TYPOSQUATTING_MALICIOUS_PATHNAME = `/${TYPOSQUATTING_DOMAIN_MONITORING_MALICIOUS}`;
export const TYPOSQUATTING_MASTER_PATHNAME = `/${TYPOSQUATTING_DOMAIN_MONITORING_TYPOSQUAT}`;
export const TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME = `${TYPOSQUATTING_PATHNAME}/domain-acquisition`;

export enum ETyposquattingStatus {
  TYPO_PRE_MALICIOUS_TABLE = 'typo_pre_malicious',
  TYPO_MALICIOUS_TABLE = 'typo_malicious',
  TYPOSQUAT_TABLE = 'typosquat',
  TYPOSQUAT_DASHBOARD = 'typo_dashboard',
}

export const enum StepStatus {
  NO_DOMAIN = -1, //when no domain is set give render initial page
  CHANGING_DOMAIN = 0, // sent for report generation or just sent for search
  START_SEARCH = 1, // generate report was success
  DOMAINS_GENERATED = 2, // genrating domain //1st
  DNS_SEARCH = 3, // pooling data //2nd
  PUBLISHED = 4, //pooling data//2nd //premium customer
  DOMAIN_SCAN = 4, //pooling data // 3rd
  DOMAIN_PURCHASE = 5, //pooling data 3rd
  MONITORED_DONE = 6, //polling data is done
  DONE = 6, //pooling data is done
}

export const TYPO_PRE_MALICIOUS_TABLE_ID = ETyposquattingStatus.TYPO_PRE_MALICIOUS_TABLE;
export const TYPO_MALICIOUS_TABLE_ID = ETyposquattingStatus.TYPO_MALICIOUS_TABLE;
export const TYPOSQUAT_TABLE_ID = ETyposquattingStatus.TYPOSQUAT_TABLE;
export const TYPO_ACQUISITION_TABLE_ID = 'typo_domain_acquisition';

export const TYPO_PRE_MALICIOUS_TABLE_ID_DASH = 'typo-pre-malicious';
export const TYPO_MALICIOUS_TABLE_ID_DASH = 'typo-malicious';
export const TYPOSQUAT_DASHBOARD_TABLE_ID_DASH = 'typo-dashboard';
export const TYPOSQUAT_TABLE_ID_DASH = ETyposquattingStatus.TYPOSQUAT_TABLE;

export const setFilterOptionsForColumns = (
  data: any[],
  columns: ITableApiColumn[],
  id: string,
  accessor: string,
) => {
  const options = Array.from(new Set(data.map((item: any) => item[accessor])))
    .filter((item: any) => item)
    .map((item: any) => ({
      label: item,
      value: item,
    }));
  let newColumns;
  if (options.length > 0) {
    newColumns = columns.map((item: any) => {
      if (item.id == id) {
        item.filterOptions = options;
      }
      return item;
    });
  } else {
    const index = columns.findIndex(item => item.id === id);
    columns.splice(index, 1);
    newColumns = columns;
  }
  return newColumns;
};

export const INLIMIT_WARNING_TEXT = (
  limit: number,
) => `You have ${limit} daily limit on the free plan. Start a trial to monitor more
            domains.`;

export const OFFLIMIT_WARNING_TEXT = (
  limit: number,
) => `You have used your daily scan limit of ${limit} scan per day, come back tomorrow or start a trial to monitor more
            domains. `;

export enum TypoJobStatus {
  NO_DOMAIN = 'no_domain',
  CHANGING_DOMAIN = 'changing_domain',
  START_SEARCH = 'start_search',
  JOB_CREATED = 'job_created',
  DOMAINS_GENERATED = 'domains_generated',
  DNS_SEARCH = 'dns_search',
  PUBLISHED = 'published',
  DOMAIN_SCAN = 'domain_scan',
  DOMAIN_PURCHASE = 'domain_purchase',
  MONITORED_DONE = 'monitored_done',
  DONE = 'done',
}

import _, { groupBy } from 'lodash';
import React, { useCallback, useContext } from 'react';
import FunnelTextBubbleSuspicious from '../../../../../assets/icons/FunnelTextBubblesSuspicious.svg';
import FunnelTextBubblePhish from '../../../../../assets/icons/FunnelTextBubblesPhish.svg';
import FunnelTextBubbleScam from '../../../../../assets/icons/FunnelTextBubblesScam.svg';
import FunnelTextBubbleClean from '../../../../../assets/icons/FunnelTextBubblesClean.svg';

import EmptyRightArrow from '../../../../../assets/icons/empty-inside-arrow.svg';

import {
  generateId,
  ICategory,
  setLocalStorageValue,
  ThemeModes,
  IBrand,
} from '../../../../../constants';
import { history } from '../../../../../helpers';
import { IFilter } from '../../../../Common/Table/constant';
import {
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
  ACQUISITION_TABLE_ID,
  WEB_DASHBOARD_PATHNAME,
} from '../../../../MonitorAndTakedown/constants';

import Funnel from './FunnelV2';
import ThemeContext from '../../../../../context/ThemeContext';
import TextCenterWithChevronRight from '../../MonitoredDomains/TextCenterWithChevronRight';
import {
  TYPOSQUAT_TABLE_ID,
  TYPOSQUATTING_MASTER_PATHNAME,
} from '../../../../DomainMonitoring/constant';
import CheckPhishContactUsContext from '../../../../../context/CheckPhishContactUsContext';
import { CATEGORY_FILTER_OPTIONS } from '../../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import { useAppDispatch } from '../../../../../helpers/hooks';
import { transformOldIFiltersToAgFilterModel } from '../../../../Common/Table/ag-table/ag-utils';
import {
  setIsFilterFromDashboard,
  setNumOfOtherCategories,
} from '../../../../../reducers/table.reducer';

export const MONITOR_FOR_ACQUISITIONS = 'Monitor for Acquisitions';
export const MONITOR_PRE_MALICIOUS = 'Scan Pre-Malicious';
export const TAKEDOWN_MALICIOUS = 'Takedown Malicious';
export const MONITOR_POST_MALICIOUS = 'Monitor Post-Malicious';

export const UNREGISTERED_DOMAINS = 'Unregistered Domains';
export const ERROR_PAGE = 'Error Pages';
export const PARKED_DOMAINS = 'Parked domains';
export const DIRECTORY_E_COMM_OTHER = 'Directory, e-comm, other';
export const OTHER_CATEGORIES = 'OTHER CATEGORIES';
export const BEC_SENSITIVE = 'BEC, sensitive';
export const TAKEN_DOWN = 'Taken Down';

interface IProps {
  monitoredDomains: any;
  domainCategories: any;
  query: any;
  filters: any;
  activeFilters: any;
  enableCommunity: boolean;
}

const getCategoryFilterLabel = (
  categoriesByGroup: Record<string, ICategory[]>,
  categoryGroup: string,
) => {
  if (categoriesByGroup && categoriesByGroup[categoryGroup]) {
    return categoriesByGroup?.[categoryGroup]?.map(category => category?.label).join(', ');
  }
  return '';
};

const getCategoryFilterValue = (
  domainCountsByGroup: Record<string, any[]>,
  categoryGroup: string,
) => {
  return _.first(domainCountsByGroup?.[categoryGroup])?.categories?.join('|');
};

const MonitoredDomainFunnelV2 = ({
  monitoredDomains,
  domainCategories,
  query,
  filters,
  activeFilters,
  enableCommunity,
}: IProps): JSX.Element => {
  const categoriesByGroup = groupBy(domainCategories, 'group');
  const domainCountsByGroup = groupBy(monitoredDomains, 'label');
  const { selectedTheme } = useContext(ThemeContext);
  const checkphishContactUsContext = useContext(CheckPhishContactUsContext);
  const dispatch = useAppDispatch();
  const isDemo = window.location.pathname.includes('premium');
  const routes: object = {
    [MONITOR_FOR_ACQUISITIONS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/acquisitions`,
      tableId: ACQUISITION_TABLE_ID,
    },
    [MONITOR_PRE_MALICIOUS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/pre-malicious`,
      tableId: PRE_MALICIOUS_TABLE_ID,
    },
    [TAKEDOWN_MALICIOUS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/takedown-malicious`,
      tableId: TAKEDOWN_MALICIOUS_TABLE_ID,
    },
    [MONITOR_POST_MALICIOUS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/post-malicious`,
      tableId: POST_MALICIOUS_TABLE_ID,
    },
  };

  const communityRoutes: object = {
    [MONITOR_PRE_MALICIOUS]: {
      pathName: TYPOSQUATTING_MASTER_PATHNAME,
      tableId: TYPOSQUAT_TABLE_ID,
    },
    [TAKEDOWN_MALICIOUS]: {
      pathName: TYPOSQUATTING_MASTER_PATHNAME,
      tableId: TYPOSQUAT_TABLE_ID,
    },
  };

  const initialFilters: Record<string, IFilter> = {
    [ERROR_PAGE]: {
      id: generateId(10),
      filterBy: { label: 'Category', value: 'final_category' },
      filterErr: '',
      filterLabel: getCategoryFilterLabel(categoriesByGroup, ERROR_PAGE),
      filterMethod: { label: 'Is', value: 'is' },
      filterType: 'options',
      filterValue: getCategoryFilterValue(domainCountsByGroup, ERROR_PAGE),
    },
    [PARKED_DOMAINS]: {
      id: generateId(10),
      filterBy: { label: 'Category', value: 'final_category' },
      filterErr: '',
      filterLabel: getCategoryFilterLabel(categoriesByGroup, PARKED_DOMAINS),
      filterMethod: { label: 'Is', value: 'is' },
      filterType: 'options',
      filterValue: getCategoryFilterValue(domainCountsByGroup, PARKED_DOMAINS),
    },
    [OTHER_CATEGORIES]: {
      id: generateId(10),
      filterBy: { label: 'Category', value: 'final_category' },
      filterErr: '',
      filterLabel: getCategoryFilterLabel(categoriesByGroup, DIRECTORY_E_COMM_OTHER),
      filterMethod: { label: 'Is', value: 'is' },
      filterType: 'string',
      filterValue: getCategoryFilterValue(domainCountsByGroup, DIRECTORY_E_COMM_OTHER),
      otherFilterCategories: categoriesByGroup[DIRECTORY_E_COMM_OTHER],
    },
  };
  const defaultBrand: IFilter = {
    id: generateId(10),
    filterBy: { label: 'Brand', value: 'sub_brand_id' },
    filterErr: '',
    filterMethod: { label: 'Is', value: 'is' },
    filterType: 'options',
    filterValue: '',
  };

  const bringFilterToTable = useCallback(
    (allFilters: IFilter[], routeObject: any, label: string) => {
      const agFilterModel = transformOldIFiltersToAgFilterModel(allFilters, query);
      if (label === OTHER_CATEGORIES)
        dispatch(setNumOfOtherCategories(agFilterModel['final_category']?.conditions?.length));
      setLocalStorageValue(['tableFilterModel', routeObject?.tableId], agFilterModel);
      dispatch(setIsFilterFromDashboard(true));
    },
    [dispatch, query],
  );

  const handleRedirect = (parentLabel: string, label: string) => {
    if (isDemo) {
      return triggerCheckphishContactUs();
    }
    const allFilters: IFilter[] = [];
    if (initialFilters[label]) {
      if (label === OTHER_CATEGORIES && enableCommunity) {
        const values = initialFilters[label].filterValue.split('|');
        const notIncludeValues = CATEGORY_FILTER_OPTIONS.filter(v => !values.includes(v.value));
        notIncludeValues.forEach(({ label, value }) => {
          allFilters.push({
            id: generateId(10),
            filterBy: { label: 'Category', value: 'final_category' },
            filterErr: '',
            filterLabel: label,
            filterMethod: { label: 'Is not', value: 'isNot' },
            filterType: 'options',
            filterValue: value,
          });
        });
      } else {
        allFilters.push(initialFilters[label]);
      }
    }
    if (filters) allFilters.push(...filters);

    if (activeFilters && activeFilters.length > 0) {
      allFilters.push(activeFilters[0]);
    }
    if (enableCommunity && !isDemo) {
      if (parentLabel === MONITOR_PRE_MALICIOUS) {
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Phish',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'phish',
        });
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Scam',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'scam',
        });
      }
      if (parentLabel === TAKEDOWN_MALICIOUS) {
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Clean',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'clean',
        });
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Suspicious',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'suspicious',
        });
      }
    }
    const routeObject = enableCommunity ? communityRoutes[parentLabel] : routes[parentLabel];
    bringFilterToTable(allFilters, routeObject, label);

    setLocalStorageValue(['tableSetting', routeObject?.tableId], {
      ...(!enableCommunity && { query }),
      filters: allFilters,
    });
    history.push(routeObject?.pathName);
  };

  const triggerCheckphishContactUs = () => {
    const { monitoredDomain, setContactUsMesage, setShowContactUsPage } =
      checkphishContactUsContext;
    setContactUsMesage(
      isDemo
        ? 'For access to additional features, contact us'
        : `For access to additional features, and to further monitor ${monitoredDomain}, contact us.`,
    );
    setShowContactUsPage(true);
  };

  const handleHeaderRedirect = (parentLabel: string) => {
    if (isDemo) {
      return triggerCheckphishContactUs();
    }
    const allFilters = filters;
    if (activeFilters && activeFilters.length > 0) {
      allFilters.push(activeFilters[0]);
    }

    if (enableCommunity) {
      if (parentLabel === MONITOR_PRE_MALICIOUS) {
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Phish',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'phish',
        });
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Scam',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'scam',
        });
      }
      if (parentLabel === TAKEDOWN_MALICIOUS) {
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Clean',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'clean',
        });
        allFilters.push({
          id: generateId(10),
          filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
          filterErr: '',
          filterLabel: 'Suspicious',
          filterMethod: { label: 'Is Not', value: 'isNot' },
          filterType: 'options',
          filterValue: 'suspicious',
        });
      }
    }

    const routeObject = enableCommunity ? communityRoutes[parentLabel] : routes[parentLabel];
    bringFilterToTable(allFilters, routeObject, '');
    setLocalStorageValue(['tableSetting', routeObject?.tableId], {
      ...(!enableCommunity && { query }),
      filters: allFilters,
    });
    history.push(routeObject?.pathName);
  };

  return (
    <div className='monitored-domains-funnel-wrapper-v2'>
      <div
        className='acquisition-wrapper-v2'
        style={{
          background: 'linear-gradient(358deg, rgb(255, 206, 79) 1.26%, rgb(255, 159, 50) 96.97%)',
          width: '546.895px',
          minWidth: '523.336px',
        }}
      >
        <div className='monitored-pre-label-wrapper hover-wrapper'>
          <div
            className='monitored-domains-label-v2'
            onClick={() => handleHeaderRedirect(MONITOR_PRE_MALICIOUS)}
          >
            <TextCenterWithChevronRight iconSrc={EmptyRightArrow}>
              {MONITOR_PRE_MALICIOUS}
            </TextCenterWithChevronRight>
          </div>
        </div>
        <div className='text-bubble-container'>
          <img src={FunnelTextBubbleSuspicious} />
        </div>
        <img />
        <div className='funnel-wrapper'>
          <Funnel
            width={'16vw'}
            height={'156px'}
            background={
              selectedTheme === ThemeModes.DARK.toLowerCase()
                ? 'linear-gradient(117deg, #FFB03D 0%, #FF7E32 100%)'
                : 'linear-gradient(90deg, #FFA031 0%, #FF7046 100%)'
            }
            className='parked-domains-funnel'
            isTransform={true}
            parentLabel={MONITOR_PRE_MALICIOUS}
            label={PARKED_DOMAINS}
            value={_.first(domainCountsByGroup[PARKED_DOMAINS])?.count}
            handleRedirect={handleRedirect}
          />
          <Funnel
            width={'16vw'}
            height={'170px'}
            background={
              selectedTheme === ThemeModes.DARK.toLowerCase()
                ? 'linear-gradient(151deg, #FFCE4F 0%, #FF9F32 100%)'
                : 'linear-gradient(151deg, #FFCE4F 0%, #FF9F32 100%)'
            }
            isTransform={true}
            parentLabel={MONITOR_PRE_MALICIOUS}
            label={ERROR_PAGE}
            value={_.first(domainCountsByGroup[ERROR_PAGE])?.count}
            handleRedirect={handleRedirect}
          />

          <Funnel
            width={'16vw'}
            height={'142px'}
            background={
              selectedTheme === ThemeModes.DARK.toLowerCase()
                ? 'linear-gradient(98deg, #FF8432 0%, #FF8432 100%)'
                : 'linear-gradient(92deg, #FF845E 0%, #FC3E37 100%)'
            }
            isTransform={true}
            parentLabel={MONITOR_PRE_MALICIOUS}
            label={OTHER_CATEGORIES}
            value={_.first(domainCountsByGroup[DIRECTORY_E_COMM_OTHER])?.count}
            handleRedirect={handleRedirect}
          />
        </div>
      </div>
      <div
        className='acquisition-wrapper-v2'
        style={{
          background: 'linear-gradient(121deg, #FF9533 0%, #F53D31 100%)',
          width: '325.1px',
          minWidth: '325.1px',
        }}
      >
        <div
          className='monitored-pre-label-wrapper hover-wrapper'
          style={{
            zIndex: '2',
            height: '200px',
          }}
          onClick={() => handleHeaderRedirect(TAKEDOWN_MALICIOUS)}
        >
          <div className='monitored-domains-label-v2'>
            <TextCenterWithChevronRight iconSrc={EmptyRightArrow}>
              {TAKEDOWN_MALICIOUS}
            </TextCenterWithChevronRight>
          </div>
          <div className='text-bubble-container'>
            <img src={FunnelTextBubblePhish} style={{ marginRight: '5px' }} />
            <img src={FunnelTextBubbleScam} />
          </div>
        </div>
        <div className='funnel-wrapper non-clickable'>
          <Funnel
            width={'16vw'}
            height={'129px'}
            background={
              selectedTheme === ThemeModes.DARK.toLowerCase()
                ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(91deg, #FF5D54 0%, #F53D31 100%)'
                : 'linear-gradient(91deg, #FF5D54 0%, #F53D31 100%)'
            }
            parentLabel={TAKEDOWN_MALICIOUS}
            isTransform={true}
            label={'All Categories'}
            value={_.first(domainCountsByGroup[BEC_SENSITIVE])?.count}
            handleRedirect={handleRedirect}
          />
        </div>
      </div>
      {enableCommunity ? (
        <div
          className='acquisition-wrapper-v2'
          style={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #D5D9DD',
            minWidth: '325.1px',
          }}
        >
          <div
            className='monitored-pre-label-wrapper hover-wrapper'
            style={{
              zIndex: '2',
              height: '200px',
            }}
            onClick={triggerCheckphishContactUs}
          >
            <div className='monitored-domains-label-v2'>
              <TextCenterWithChevronRight iconSrc={EmptyRightArrow}>
                {MONITOR_POST_MALICIOUS}
              </TextCenterWithChevronRight>
            </div>
          </div>
          <div className='funnel-wrapper non-clickable'></div>
        </div>
      ) : (
        <div
          className='acquisition-wrapper-v2'
          style={{
            background:
              'linear-gradient(to right bottom, #0cc78f, #68cd75, #9ed15e, #cfd14f, #ffce4f)',
            width: '325.1px',
            minWidth: '325.1px',
          }}
        >
          <div
            className='monitored-pre-label-wrapper hover-wrapper'
            style={{
              zIndex: '2',
              height: '200px',
            }}
            onClick={() => handleHeaderRedirect(MONITOR_POST_MALICIOUS)}
          >
            <div className='monitored-domains-label-v2'>
              <TextCenterWithChevronRight iconSrc={EmptyRightArrow}>
                {MONITOR_POST_MALICIOUS}
              </TextCenterWithChevronRight>
            </div>
            <div className='text-bubble-container'>
              <img src={FunnelTextBubbleClean} style={{ marginRight: '5px' }} />
              <img src={FunnelTextBubbleSuspicious} />
            </div>
          </div>
          <div className='funnel-wrapper non-clickable'>
            <Funnel
              width={'16vw'}
              height={'123px'}
              background={'linear-gradient(121deg, #FF9533 0%, #F53D31 100%)'}
              isTransform={false}
              parentLabel={MONITOR_POST_MALICIOUS}
              label={'All Categories'}
              value={_.first(domainCountsByGroup[TAKEN_DOWN])?.count}
              handleRedirect={handleRedirect}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MonitoredDomainFunnelV2;

import fetchApi from '../../../../services/api.service';
import { handleResponse } from '../../../../services/serviceWorker';

export interface IBreachDataLeakDetails {
  externalLinks: string[];
  details: string[];
}
export const getBreachDescriptions = async (): Promise<Record<string, string>> => {
  const rawGroupResponse = await fetchApi(
    '/platform-api/v1/content/dark-web/breach-descriptions/map',
  ).then(handleResponse);
  return rawGroupResponse;
};

export const getBreachDataLeakDetails = async (params: {
  entity: string;
  breach: string;
  searchTermId: string | number;
}): Promise<IBreachDataLeakDetails> => {
  const { entity, breach, searchTermId } = params;
  const rawGroupResponse = await fetchApi(
    `/platform-api/v1/content/dark-web/breach-description?entity=${entity}&breach=${breach}&searchTermId=${searchTermId}`,
  ).then(handleResponse);
  return rawGroupResponse;
};

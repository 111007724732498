import React, { FunctionComponent } from 'react';
import './ContactUs.scss';
import { HeaderMetas } from '../Common/HeaderMetas';
import ThemeContext from '../../context/ThemeContext';
import { useContext } from 'react';

import { HubspotContactForm } from './HubspotContactForm';

interface IBrandsPageProps {
  loggedIn?: boolean;
  user?: any;
}

const ContactUsPage: FunctionComponent<IBrandsPageProps> = (props: IBrandsPageProps) => {
  const topRef = React.useRef<HTMLInputElement>(null);
  const theme = useContext(ThemeContext);
  return (
    <div ref={topRef}>
      <HeaderMetas
        url={'https://checkphish.ai/contact-us'}
        title={'Contact Us | CheckPhish'}
        description={
          'You can reach out to CheckPhish by sending a message or chat with us by clicking the pop up window in the bottom right corner of our web pages.'
        }
        keywords={'real-time anti-phishing services antiphishing real-time services'}
      />
      <div className='hubspot-contact-form-wrapper'>
        <div className='hubspot-contact-form'>
          <HubspotContactForm
            region='na1'
            portalId='24174425'
            formId='77090355-2b41-4c06-b974-588e5ff3001e'
            darkTheme={theme.selectedTheme === 'dark'}
          />
        </div>
      </div>
    </div>
  );
};

export { ContactUsPage };

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { PaperClip2Icon } from '../../assets/SVGIcons';
import { b64DataUrl } from '../../constants';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { EventHistoryEntity } from '../../types/event-history-entity.interface';
import { CustomerComment, CustomerDispute, CustomerSocResponse } from '../../types/url-event.types';
import AttachedFileViewer from '../Common/AttachedFileViewer';
import { AttachedFileObject } from '../Common/DisputeStatus';
import { LoadingWrapper } from '../Common/LoadingWrapper';

const socTakedownService = new SocTakedownService();

export interface ITimelineCommentProps {
  urlSha256: string;
  item: EventHistoryEntity<(CustomerComment | CustomerSocResponse) & CustomerDispute>;
  isDispute?: boolean;
}

function TimelineCommentAttachments({ urlSha256, item, isDispute = false }: ITimelineCommentProps) {
  const [fileAttachments, setFileAttachments] = useState<AttachedFileObject[]>([]);
  const [attachmentCount, setAttachmentCount] = useState(0);
  const [imagesFetched, setImagesFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  async function fetchImages() {
    setLoading(true);
    try {
      const newAttachments: AttachedFileObject[] = [];
      const promises = item.data.attachments.map(async attachmentUrl => {
        const b64Img = await socTakedownService.getAttachment(urlSha256, attachmentUrl);
        newAttachments.push({
          path: b64DataUrl(b64Img),
          name: b64Img.fileName,
          createdTS: moment(item.createdTs).format('DD-MMM-YYYY h:mm a'),
        });
      });
      await Promise.all(promises);
      setFileAttachments(newAttachments);
    } finally {
      setLoading(false);
    }
  }
  const fetchDisputes = () => {
    setLoading(false);
    const fileAttachments = item?.data.attachmentPaths.map(attachmentItem => {
      return {
        path: attachmentItem,
        name: attachmentItem.slice(attachmentItem.lastIndexOf('/') + 1),
        createdTS: moment(item.createdTs).format('DD-MMM-YYYY h:mm a'),
      };
    });
    setFileAttachments(fileAttachments);
  };

  function popoverToggled(show: boolean) {
    if (show && !imagesFetched) {
      setImagesFetched(true);
      isDispute ? fetchDisputes() : fetchImages();
    }
  }

  useEffect(() => {
    setImagesFetched(false);
    setAttachmentCount(
      isDispute ? item?.data.attachmentPaths.length : item?.data?.attachments?.length ?? 0,
    );
  }, [item]);

  const popover = (
    <Popover id='popover-basic' className={'timeline-popover'}>
      <Popover.Content className={'timeline-popover-content'}>
        <LoadingWrapper isLoading={loading}>
          <AttachedFileViewer attachedFiles={fileAttachments} />
        </LoadingWrapper>
      </Popover.Content>
    </Popover>
  );
  return (
    <div>
      {item.data.message && <div className='comments'>{item.data.message}</div>}
      {attachmentCount > 0 ? (
        <div className={'paper-clip-container'}>
          <OverlayTrigger
            trigger='click'
            placement='right'
            overlay={popover}
            onToggle={show => popoverToggled(show)}
          >
            <span>
              <PaperClip2Icon />
              {attachmentCount}
            </span>
          </OverlayTrigger>
        </div>
      ) : (
        <div className={'paper-clip-container'}>
          <PaperClip2Icon />
          {attachmentCount}
        </div>
      )}
    </div>
  );
}

export default TimelineCommentAttachments;

import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getDateTimeFormat } from '../../../../../constants';
import { LabelAndValue } from '../../../../Common/LabelAndValue';

const DATE_TIME_FORMAT = 'DD-MMM-YYYY, h:mm a';

const SafelistSection = ({ safelist }: any) => {
  const [values, setValues] = useState({
    safelistBy: '',
    safelistComment: '',
    safelistTs: '',
  });

  const initData = () => {
    if (!safelist) return;

    const { user, comments, createdTs } = safelist;
    setValues({
      safelistBy: `${user.firstName} ${user.lastName}`,
      safelistComment: comments,
      safelistTs: createdTs,
    });
  };

  useEffect(() => {
    initData();
  }, [safelist]);

  if (!values.safelistTs) return null;

  return (
    <Card bg='light' className='card-between'>
      <Card.Header className='self-card-header'>Action Taken for Ignored</Card.Header>
      <Card.Body className='self-card-body'>
        <Row>
          <Col sm={6}>
            <LabelAndValue label={'Requested By'} value={values.safelistBy} direction={'column'} />
          </Col>
          <Col sm={6}>
            <LabelAndValue
              label='Requested Date'
              value={getDateTimeFormat(values.safelistTs, DATE_TIME_FORMAT)}
              direction={'column'}
              longTextLineNumberLimit={1}
            />
          </Col>
        </Row>
        {values.safelistComment && (
          <Row>
            <Col sm={12}>
              <LabelAndValue
                label={'Comment'}
                value={values.safelistComment}
                direction={'column'}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default SafelistSection;

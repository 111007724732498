import fetch from './api.service';
import { handleResponse } from './serviceWorker';

interface IBulkScanOptions {
  scanLocation: string;
  wait: number;
  userAgent: string;
}
interface IAddAppStoreDetectionOptions {
  scanCategory: string;
  scanEngineDetails: any;
}
type IBulkOptionsProps = IBulkScanOptions | IAddAppStoreDetectionOptions;
export default class BulkScanService {
  setPublishScanHistoryAnalytics = () => {
    return true;
  };

  bulkScan(scanUrl: string[], scanApiKey: string, scanType: string, options?: IBulkOptionsProps) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        scan: {
          scanUrl,
          scanApiKey,
          scanType,
          rescan: false,
          privateScan: false,
          customUserAgent: '',
          ...options,
        },
      }),
    };
    return fetch('/api/v1/bulk-scan', requestOptions).then(handleResponse);
  }

  getBrandScanPreference() {
    return fetch('/platform-api/v1/brand/scan-preference', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getScanLocations() {
    return fetch('/platform-api/v1/bulkscan/scan-location', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getUserAgents() {
    return fetch('/platform-api/v1/bulkscan/user-agents', {
      credentials: 'include',
    }).then(handleResponse);
  }
}

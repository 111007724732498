import { AlertActionsTypes, DashBoardDto, IBrandInfo } from '../../constants';
import { ITableApiColumn } from '../Common/Table/table.api';
import { UrlEventTypes } from '../../types/url-event.types';

export interface IPropteryTableProps {
  id: string;
  tableName: string;
  title: string;
  type?: string;
  columns: ITableApiColumn[];
  disableDatePicker: boolean;
  defaultColumnIds: string[];
  ugcType?: string;
}

export interface IAddPropteryProps {
  show: boolean;
  onCloseModal: () => void;
  onSubmitModal: (formData: any) => any;
  brandPropertyTypes: any[];
  type: IPropertyType;
  ugcPlatforms: any;
}

export interface IPropertyType {
  id: number;
  isPublic: boolean;
  label: string;
  value: string;
}

export enum EPropertyStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export enum EPropertyStatusLabel {
  ACTIVE = 'Active',
  PENDING = 'Pending',
}

export enum PropertyValueType {
  FILE = 'FILE',
  STRING = 'STRING',
  ARRAY = 'ARRAY',
  JSON = 'JSON',
}

export enum PropertyType {
  TRADEMARK = 'trademark',
  DOCUMENT = 'document',
  LOGO = 'logo',
  SOCIAL = 'social',
  MARKETPLACE = 'marketplace',
  APP_STORE = 'app_store',
  COPYRIGHT = 'copyright',
  HQ_ADDRESS = 'hq_address',
  OFFICIAL_WEBSITE = 'official_website',
  CONTACT = 'contact',
}

export enum EPropertyTypeLabel {
  trademark = 'Trademark',
  document = 'Document',
  logo = 'Logo',
  social = 'Social Media',
  marketplace = 'Marketplace',
  app_store = 'App ',
}

export interface FieldInput {
  id: string;
  label: string;
  type: InputType;
  isRequired?: boolean;
  options?: { value: string; label: string }[];
}
export enum InputType {
  FILES = 'FILES',
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
}

export enum SensitiveDataTypes {
  EMAIL = 'emails',
  CCN = 'ccns',
  SSN = 'ssns',
  IP = 'ips',
  CRYPTO = 'cryptos',
}

export enum SensitiveDataTitles {
  emails = 'Emails',
  ccns = 'Credit Card Numbers',
  ssns = 'Social Security Numbers',
  ips = 'Ip Addresses',
  cryptos = 'Cryptocurrency Addresses',
}

export enum SensitiveDataNumbers {
  emails = 0,
  ccns = 1,
  ssns = 2,
  ips = 3,
  cryptos = 4,
}

enum OtherEventTypes {
  CONTENT_CHANGE = 'content_change',
  RECENT_SCAN = 'recent_scan',
  TAKEN_DOWN = 'taken_down',
  PHISH_DETECTED = 'phish_detected',
  SCAM_DETECTED = 'scam_detected',
  DISPOSITION_CHANGE = 'disposition_change',
}

export const TimeLineEventType = {
  ...UrlEventTypes,
  ...OtherEventTypes,
};

export enum TimelineTitles {
  URL_DETECTED = 'Url Detected',
  MANUAL_TAKEDOWN = 'Manual Takedown Requested',
  AUTO_TAKEDOWN = 'Auto Takedown Requested',
  DISPUTE = 'Dispute Requested',
  HUNTING_UPDATE = 'Hunting Update',
  CUSTOMER_COMMENT = 'Message',
  CUSTOMER_COMMENT_WITH_DOCUMENTS = 'Documents Uploaded',
  CUSTOMER_SOC_RESPONSE = 'Message',
  CUSTOMER_SOC_RESPONSE_WITH_DOCUMENTS = 'Documents Uploaded',
  CONTENT_CHANGE = 'Content Change',
  RECENT_SCAN = 'Most Recent Scan',
  TAKEN_DOWN = 'Site Taken Down',
  PHISH_DETECTED = 'Phish Detected',
  SCAM_DETECTED = 'Scam Detected',
  ACTION_NEEDED = 'Action Needed',
  SOC_CUSTOMER_MESSAGE = 'Message From Bolster Support',
  DISPOSITION_CHANGE = 'Disposition Change',
}

import React from 'react';
import './ImagesSlide.scss';
import Card from 'react-bootstrap/Card';
import { Image, ON_ERROR_IMAGE } from './Image';
import PreviousPageIcon from '../../assets/icons/PreviousPage.svg';
import PreviousPageDisabledIcon from '../../assets/icons/PreviousPageDisabled.svg';
import NextPageIcon from '../../assets/icons/NextPage.svg';
import NextPageDisabledIcon from '../../assets/icons/NextPageDisabled.svg';

export interface IImageProp {
  name: string;
  path: string;
}

interface IComponentProps {
  className: string;
  images: IImageProp[];
  alt: string;
  hideImagesController?: boolean;
}

interface IComponentState {
  currentImageIndex: number;
}

class ImagesSlide extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    className: '',
    alt: 'Image',
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      currentImageIndex: 0,
    };
  }

  onChangeImage = (index: number) => {
    const { images } = this.props;
    this.setState({
      currentImageIndex: Math.min(images.length - 1, Math.max(index, 0)),
    });
  };

  render() {
    const { className, images, alt, hideImagesController } = this.props;
    const { currentImageIndex } = this.state;
    const currentImage = images[currentImageIndex];

    if (images.length === 0) {
      return null;
    }

    return (
      <div className={'images-slide-component ' + className}>
        <Image imagePath={currentImage.path} alt={alt} />
        {!hideImagesController && (
          <div className={'images-controller'}>
            <img
              className={currentImageIndex === 0 ? 'disabled' : ''}
              src={currentImageIndex === 0 ? PreviousPageDisabledIcon : PreviousPageIcon}
              alt={'Previous Page'}
              onClick={() => {
                this.onChangeImage(currentImageIndex - 1);
              }}
            />
            <div className={'long-text-ellipsis-1'}>{currentImage.name}</div>
            <img
              className={currentImageIndex === images.length - 1 ? 'disabled' : ''}
              src={currentImageIndex === images.length - 1 ? NextPageDisabledIcon : NextPageIcon}
              alt={'Next Page'}
              onClick={() => {
                this.onChangeImage(currentImageIndex + 1);
              }}
            />
          </div>
        )}
        <div className={'all-images'}>
          {images.map((image: IImageProp, i: number) => {
            return (
              <Card.Img
                className={i === currentImageIndex ? 'image-selected' : ''}
                key={i}
                alt={alt}
                src={image.path}
                onClick={() => {
                  this.onChangeImage(i);
                }}
                onError={(e: any) => {
                  e.target.src = ON_ERROR_IMAGE;
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export { ImagesSlide };

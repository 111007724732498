import React, { useState } from 'react';
import './TextArea.scss';
import Form from 'react-bootstrap/Form';
import { getSingleOrPluralForm } from '../../constants';

const DEFAULT_LIMIT = 1000;
interface ITextAreaProps {
  label: string;
  onChange: (value: string, controlId: string) => void;
  limit?: number;
  className?: string;
  controlId?: string;
  required?: boolean;
  defaultValue?: string;
  placeholder?: string;
}

const TextArea = ({
  label,
  onChange,
  className = '',
  limit = DEFAULT_LIMIT,
  controlId = 'textArea',
  required,
  defaultValue,
  placeholder = '',
}: ITextAreaProps) => {
  const [remaining, setRemaining] = useState<number>(limit);
  const onTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const text = event.currentTarget.value || '';
    onChange(text, controlId);
    setRemaining(limit - text.length);
  };

  return (
    <Form.Group controlId={controlId} className='text-area-component'>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as='textarea'
        maxLength={limit}
        required={required}
        className={className}
        defaultValue={defaultValue}
        onChange={onTextChange}
        placeholder={placeholder}
      />
      <Form.Text className='text-dark-grey-2 help-text' data-testid='char-remaining'>
        {remaining} {getSingleOrPluralForm(remaining, 'character')} remaining.
      </Form.Text>
    </Form.Group>
  );
};

export default TextArea;

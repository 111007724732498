import React from 'react';
import {
  appConstants,
  DashBoardDto,
  featureIsAvailable,
  getLocalStorageValue,
  isAdminUser,
  isBrandReadOnlyUser,
  isInternalUser,
} from '../../constants';
import DomainMonitoringLight from '../../assets/icons/DomainMonitoringLight.svg';
import DomainMonitoringDark from '../../assets/icons/DomainMonitoringDark.svg';
import UrlScannerLight from '../../assets/icons/UrlScannerLight.svg';
import UrlScannerDark from '../../assets/icons/UrlScannerDark.svg';
import DomainMonitoringActiveLight from '../../assets/icons/DomainMonitoringActiveLight.svg';
import DomainMonitoringActiveDark from '../../assets/icons/DomainMonitoringActiveDark.svg';
import UrlScannerActiveDark from '../../assets/icons/UrlScannerActiveDark.svg';
import UrlScannerActiveLight from '../../assets/icons/UrlScannerActiveLight.svg';
//web
import WebLight from '../../assets/icons/WebModuleLightIcon.svg';
import WebLightActive from '../../assets/icons/WebModuleActiveLightIcon.svg';
import WebDark from '../../assets/icons/WebModuleDarkIcon.svg';
import WebDarkActive from '../../assets/icons/WebModuleActiveDarkIcon.svg';
//social
import SocialLight from '../../assets/icons/SocialMediaModuleLight.svg';
import SocialLightActive from '../../assets/icons/SocialMediaModuleActiveLight.svg';
import SocialDark from '../../assets/icons/SocialMediaModuleDark.svg';
import SocialDarkActive from '../../assets/icons/SocialMediaModuleActiveDark.svg';
//app
import AppStoreLight from '../../assets/icons/AppStoreLightIcon.svg';
import AppStoreLightActive from '../../assets/icons/AppStoreActiveLightIcon.svg';
import AppStoreDark from '../../assets/icons/AppStoreDarkIcon.svg';
import AppStoreDarkActive from '../../assets/icons/AppStoreActiveDarkIcon.svg';
//market
import MarketPlaceLight from '../../assets/icons/MarketplaceLightIcon.svg';
import MarketPlaceActiveLight from '../../assets/icons/MarketplaceActiveLightIcon.svg';
import MarketPlaceDark from '../../assets/icons/MarketplaceDarkIcon.svg';
import MarketPlaceActiveDark from '../../assets/icons/MarketplaceActiveDarkIcon.svg';
//darkweb
import DarkWebLight from '../../assets/icons/DarkwebLightIcon.svg';
import DarkWebActiveLight from '../../assets/icons/DarkwebActiveLightIcon.svg';
import DarkWebDark from '../../assets/icons/DarkwebDarkIcon.svg';
import DarkWebActiveDark from '../../assets/icons/DarkWebActiveDarkIcon.svg';

import {
  USER_GLOBAL_TAKEDOWN_PATHNAME,
  WEB_INITIATE_TAKEDOWN_PATHNAME,
} from '../MonitorAndTakedown/constants';
import {
  MARKET_PLACE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_REVIEW_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME,
  MARKET_PLACE_PATHNAME_SEARCH_TERMS,
} from '../Marketplace/constants';
import {
  POST_MALICIOUS_PATHNAME,
  PRE_MALICIOUS_PATHNAME,
  TAKEDOWN_MALICIOUS_PATHNAME,
  ACQUISITIONS_PATHNAME,
  WEB_DASHBOARD_PATHNAME,
} from '../MonitorAndTakedown/constants';
import {
  SOCIAL_MEDIA_DASHBOARD_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_MARKED_SCAM_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME,
  SOCIAL_MEDIA_SEARCH_TERMS,
} from '../SocialMedia/constants';
import {
  APP_STORE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME,
  APP_STORE_PATHNAME_SEARCH_TERMS,
} from '../AppStore/constants';
import {
  DARK_WEB_DASHBOARD_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME,
  DARK_WEB_PATHNAME_SEARCH_TERMS,
  DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME,
} from '../DarkWeb/DarkWebConstants';
import {
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME,
  TYPOSQUATTING_MALICIOUS_PATHNAME,
  TYPOSQUATTING_MASTER_PATHNAME,
  TYPOSQUATTING_PATHNAME,
  TYPOSQUATTING_PREMALICIOUS_PATHNAME,
} from '../DomainMonitoring/constant';
import { CHECKPHISH_APP_TYPE, ILableValue } from '../../constants/types';
import {
  getIsAppStoreModuleAvailable,
  getIsDarkWebModuleAvailable,
  getIsSocialMediaModuleAvailable,
  getIsWebModuleAvailable,
} from '../../helpers/permissions';
export enum EMenuType {
  MENU_HEADER = 'menu-header',
  SUB_MENU_HEADER = 'sub-menu-header',
}
const domainFeatureCode = [appConstants.FEATURE_CODE.WEB];
const socTakedownFeatureCode = [appConstants.FEATURE_CODE.WEB_SOC_TAKEDOWN];
const socialFeatureCode = [appConstants.FEATURE_CODE.SOCIAL_MEDIA];
const marketplaceFeatureCode = [appConstants.FEATURE_CODE.MARKETPLACE];
const appStoreFeatureCode = [appConstants.FEATURE_CODE.APP_STORE];
const darkWebFeatureCode = [appConstants.FEATURE_CODE.DARK_WEB_V2];
const notificationSettingsFeatureCode = [appConstants.FEATURE_CODE.NOTIFICATION_SETTINGS];
const userGlobalTakedown = [appConstants.FEATURE_CODE.USER_GLOBAL_TAKEDOWN];
export const NAV_ITEMS_V2 = (
  user: DashBoardDto,
  isCommunity: boolean,
  typosquatV2Enabled: boolean,
): any[] => {
  if (isCommunity) {
    return [
      {
        label: 'Dashboard',
        parentLabel: 'CheckPhish',
        id: typosquatV2Enabled ? TYPOSQUATTING_MASTER_PATHNAME : TYPOSQUATTING_DASHBOARD_PATHNAME,
        href: typosquatV2Enabled ? TYPOSQUATTING_MASTER_PATHNAME : TYPOSQUATTING_DASHBOARD_PATHNAME,
        subItems: [],
        type: EMenuType.MENU_HEADER,
        featureCode: [],
        hideDrawer: true,
        render: () => {},
      },
      {
        label: 'Community',
        parentLabel: 'Community',
        id: '/checkphish/community',
        href: 'https://community.bolster.ai',
        subItems: [],
        type: EMenuType.MENU_HEADER,
        featureCode: [],
        hideDrawer: true,
        isNew: true,
        isExternalLink: true,
        render: () => {},
      },
    ];
  }
  const { type_name } = user;
  return [
    ...(type_name === 'brand'
      ? [
          {
            label: 'Attack Surface',
            id: 'attackSurface',
            subItems: [
              {
                label: 'Web',
                id: '/web/dashboard',
                href: '/web/dashboard',
                subItems: [],
                parentLabel: 'Web',
                type: EMenuType.MENU_HEADER,
                featureCode: domainFeatureCode,
              },
              {
                label: 'Social Media',
                id: '/social-media/dashboard',
                href: '/social-media/dashboard',
                subItems: [],
                parentLabel: 'Social Media',
                featureCode: socialFeatureCode,
                type: EMenuType.MENU_HEADER,
              },
              {
                label: 'Marketplace',
                id: '/marketplace/dashboard',
                href: '/marketplace/dashboard',
                subItems: [],
                parentLabel: 'Marketplace',
                featureCode: marketplaceFeatureCode,
                type: EMenuType.MENU_HEADER,
              },
              {
                label: 'App Store',
                id: '/app-store/dashboard',
                href: '/app-store/dashboard',
                subItems: [],
                parentLabel: 'App Store',
                featureCode: appStoreFeatureCode,
                type: EMenuType.MENU_HEADER,
              },
              {
                label: 'Dark Web',
                id: '/dark-web/dashboard',
                href: '/dark-web/dashboard',
                subItems: [],
                parentLabel: 'Dark Web',
                featureCode: darkWebFeatureCode,
                type: EMenuType.MENU_HEADER,
              },
            ],
            type: EMenuType.MENU_HEADER,
            featureCode: domainFeatureCode,
          },
          {
            label: 'Global Takedown',
            id: USER_GLOBAL_TAKEDOWN_PATHNAME,
            href: USER_GLOBAL_TAKEDOWN_PATHNAME,
            parentLabel: 'Global Takedown',
            hidden:
              !featureIsAvailable(user, userGlobalTakedown) ||
              !featureIsAvailable(user, domainFeatureCode),
            type: EMenuType.MENU_HEADER,
            featureCode: userGlobalTakedown,
            hideDrawer: false,
            subItems: [],
          },
          {
            label: 'Scan',
            id: '/bulk-scan',
            href: '/bulk-scan',
            subItems: [],
            parentLabel: 'Scan',
            type: EMenuType.MENU_HEADER,
            featureCode: [],
            hideDrawer: true,
          },
          {
            label: 'Assets',
            id: '/assets/logos',
            href: '/assets/logos',
            subItems: [],
            parentLabel: 'Assets',
            type: EMenuType.MENU_HEADER,
            featureCode: [],
          },
          {
            ...(!isBrandReadOnlyUser(user) && {
              label: 'Automation',
              id: '/automation/playbooks',
              href: '/automation/playbooks/web-playbook',
              subItems: [],
              parentLabel: 'Automation',
              type: EMenuType.MENU_HEADER,
              featureCode: domainFeatureCode,
            }),
          },
          {
            ...(!isBrandReadOnlyUser(user)
              ? {
                  label: 'Integrations',
                  id: '/integrations',
                  href: '/integrations/available-connectors',
                  subItems: [],
                  parentLabel: 'Integrations',
                  type: EMenuType.MENU_HEADER,
                  featureCode: [],
                }
              : {}),
          },
          {
            label: 'Reports',
            id: '/reports',
            href: '/reports',
            subItems: [],
            parentLabel: 'Reports',
            type: EMenuType.MENU_HEADER,
            featureCode: domainFeatureCode,
            hideDrawer: true,
          },
        ]
      : []),
    ...(type_name === 'non_brand'
      ? [
          {
            label: 'Domain Monitoring',
            parentLabel: 'CheckPhish',
            id: typosquatV2Enabled
              ? TYPOSQUATTING_MASTER_PATHNAME
              : TYPOSQUATTING_DASHBOARD_PATHNAME,
            href: typosquatV2Enabled
              ? TYPOSQUATTING_MASTER_PATHNAME
              : TYPOSQUATTING_DASHBOARD_PATHNAME,
            subItems: [],
            type: EMenuType.MENU_HEADER,
            featureCode: [],
            hideDrawer: true,
            render: () => {},
            isNew: true,
          },
          {
            label: 'URL Scanner',
            parentLabel: 'Scan',
            id: '/checkphish',
            href: '/checkphish/dashboard',
            subItems: [],
            type: EMenuType.MENU_HEADER,
            featureCode: [],
            hideDrawer: true,
            render: () => {},
          },
        ]
      : []),
  ];
};

export const MOBILE_L1_DRAWER = (
  user: DashBoardDto,
  isCommunity: boolean,
  typosquatV2Enabled: boolean,
): any => {
  return {
    Main: [...NAV_ITEMS_V2(user, isCommunity, typosquatV2Enabled)],
  };
};

export const ACCOUNT_SUB_ITEMS = (user: DashBoardDto): any => {
  const { type_name } = user;
  return [
    {
      label: 'Account',
      id: '/account/profile',
      href: '/account/profile',
      parentLabel: 'Account',
      subItems: [
        {
          id: '/account/profile',
          label: 'Profile Information',
          href: '/account/profile',
          parentLabel: 'Account',
          type: EMenuType.SUB_MENU_HEADER,
        },
        ...(type_name === 'brand'
          ? [
              {
                id: '/account/team-members',
                label: 'Team Members',
                href: '/account/team-members',
                parentLabel: 'Account',
                adminOnly: true,
                type: EMenuType.SUB_MENU_HEADER,
              },
              {
                id: '/account/communication-settings',
                label: 'Communication Settings',
                href: '/account/communication-settings',
                parentLabel: 'Account',
                type: EMenuType.SUB_MENU_HEADER,
                featureCode: notificationSettingsFeatureCode,
                hidden: !featureIsAvailable(user, notificationSettingsFeatureCode),
              },
            ]
          : []),
        ...(type_name === 'non_brand'
          ? [
              {
                id: 'Notification Settings',
                label: 'Notification Settings',
                href: '/account/notifications',
                parent: 'Settings',
                adminOnly: true,
                parentLabel: 'Account',
                type: EMenuType.SUB_MENU_HEADER,
              },
            ]
          : []),
        {
          id: '/account/change-password',
          label: 'Security & Privacy',
          href: '/account/change-password',
          parentLabel: 'Account',
          adminOnly: true,
          type: EMenuType.SUB_MENU_HEADER,
        },
      ],
    },
    ...(type_name === 'brand'
      ? [
          {
            label: 'Manage',
            id: '/manage/landing-page',
            href: '/manage/landing-page',
            parentLabel: 'Account',
            subItems: [
              {
                id: '/manage/landing-page',
                label: 'Landing Page',
                href: '/manage/landing-page',
                parentLabel: 'Account',
                type: EMenuType.SUB_MENU_HEADER,
              },
            ],
          },
        ]
      : []),
    {
      label: '',
      id: '/logout',
      href: '/logout',
      parentLabel: 'Account',
      subItems: [
        {
          id: '/logout',
          label: 'Logout',
          href: '/logout',
          parentLabel: 'Account',
          adminOnly: true,
          type: EMenuType.SUB_MENU_HEADER,
        },
      ],
    },
  ];
};

export const CHECKPHISH_TOP_NAV_ITEMS: any = (
  typosquatV2Enabled: boolean,
  selectedApp: ILableValue,
) => {
  return selectedApp.value === CHECKPHISH_APP_TYPE.CHECKPHISH
    ? [
        {
          label: 'Dashboard',
          parentLabel: 'CheckPhish',
          id: typosquatV2Enabled ? TYPOSQUATTING_MASTER_PATHNAME : TYPOSQUATTING_DASHBOARD_PATHNAME,
          href: typosquatV2Enabled
            ? TYPOSQUATTING_MASTER_PATHNAME
            : TYPOSQUATTING_DASHBOARD_PATHNAME,
          subItems: [],
          type: EMenuType.MENU_HEADER,
          featureCode: [],
          hideDrawer: true,
          render: () => {},
        },
        {
          label: 'Community',
          parentLabel: 'Community',
          id: '/checkphish/community',
          href: 'https://community.bolster.ai/',
          subItems: [],
          type: EMenuType.MENU_HEADER,
          featureCode: [],
          hideDrawer: true,
          isNew: true,
          isExternalLink: true,
          render: () => {},
        },
      ]
    : [
        {
          label: 'Dashboard',
          parentLabel: 'Platform',
          id: '/premium/dashboard/web',
          href: '/premium/dashboard/web',
          subItems: [],
          type: EMenuType.MENU_HEADER,
          featureCode: [],
          hideDrawer: true,
          render: () => {},
        },
        {
          label: 'Scan',
          parentLabel: 'Platform',
          id: 'platform/scam',
          href: '#',
          subItems: [],
          type: EMenuType.MENU_HEADER,
          featureCode: [],
          hideDrawer: true,
          isLocked: true,
          render: () => {},
        },
        {
          label: 'Assets',
          parentLabel: 'Platform',
          id: 'platform/assets',
          href: '#',
          subItems: [],
          type: EMenuType.MENU_HEADER,
          featureCode: [],
          hideDrawer: true,
          isLocked: true,
          render: () => {},
        },
        {
          label: 'Automation',
          parentLabel: 'Platform',
          id: 'platform/automation',
          href: '#',
          subItems: [],
          type: EMenuType.MENU_HEADER,
          featureCode: [],
          hideDrawer: true,
          isLocked: true,
          render: () => {},
        },
        {
          label: 'Reports',
          parentLabel: 'Platform',
          id: 'platform/automation',
          href: '#',
          subItems: [],
          type: EMenuType.MENU_HEADER,
          featureCode: [],
          hideDrawer: true,
          isLocked: true,
          render: () => {},
        },
      ];
};

export const CHECKPHISH_DRAWER_SUBITEM: any = (hasTypoData: boolean): any => {
  return [
    {
      label: 'Domain Monitoring',
      parentLabel: 'Free Plan',
      id: TYPOSQUATTING_DASHBOARD_PATHNAME,
      href: TYPOSQUATTING_DASHBOARD_PATHNAME,
      iconDarkTheme: DomainMonitoringDark,
      iconLightTheme: DomainMonitoringLight,
      iconActiveDarkTheme: DomainMonitoringActiveDark,
      iconActiveLightTheme: DomainMonitoringActiveLight,
      disabled: !hasTypoData,
      hasIcon: true,
      subItems: [
        {
          label: 'Typosquat',
          isNew: true,
          parentLable: 'Domain Monitoring',
          id: TYPOSQUATTING_MASTER_PATHNAME,
          href: TYPOSQUATTING_MASTER_PATHNAME,
          disabled: false,
        },
        {
          label: 'Domain Acquisition',
          parentLabel: 'Domain Monitoring',
          id: TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME,
          href: TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME,
          disabled: !hasTypoData,
        },
      ],
    },
    {
      label: 'URL Scanner',
      parentLabel: 'Free Plan',
      id: '/checkphish',
      href: '/checkphish/dashboard',
      iconDarkTheme: UrlScannerDark,
      iconLightTheme: UrlScannerLight,
      iconActiveDarkTheme: UrlScannerActiveDark,
      iconActiveLightTheme: UrlScannerActiveLight,
      hasIcon: true,
      subItems: [
        {
          label: 'Live Scan',
          id: '/bulk-scan',
          href: '/bulk-scan',
          subItems: [],
          disabled: false,
          parentLabel: 'Scan',
          featureCode: [],
        },
        {
          label: 'All Scans',
          id: '/checkphish/allscans',
          href: '/checkphish/allscans',
          subItems: [],
          disabled: false,
          parentLabel: 'Scan',
        },
      ],
    },
    {
      label: 'Web',
      parentLabel: 'Premium',
      id: '/premium/dashboard/web',
      href: '/premium/dashboard/web',
      iconLightTheme: WebLight,
      iconDarkTheme: WebDark,
      iconActiveLightTheme: WebLightActive,
      iconActiveDarkTheme: WebDarkActive,
      hasIcon: true,
      subItems: [
        {
          label: 'Monitor & Takedown',
          parentLabel: 'Web',
          id: '/premium/web/monitor-and-takedown/',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Monitor Pre-Malicious',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/web/pre-malicious',
              href: '/premium/web/pre-malicious',
              subItems: [],
            },
            {
              label: 'Takedown Malicious',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/web/takedown-malicious',
              href: '/premium/web/takedown-malicious',
              subItems: [],
            },
            {
              label: 'Monitor Post-Malicious',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/web/post-malicious',
              href: '/premium/web/post-malicious',
              subItems: [],
            },
            {
              label: 'Monitor Logo',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/web/logo',
              href: '/premium/web/logo',
              subItems: [],
            },
          ],
        },
        {
          label: 'Monitor & Acquire',
          parentLabel: 'Web',
          id: '/premium/web/monitor-and-acquire',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Monitor for Acquisitions',
              parentLabel: 'Monitor & Acquire',
              id: '/premium/web/monitor-and-acquisitions',
              href: '/premium/web/monitor-and-acquisitions',
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      label: 'Social Media',
      parentLabel: 'Premium',
      id: '/premium/dashboard/social',
      href: '/premium/dashboard/social',
      iconLightTheme: SocialLight,
      iconDarkTheme: SocialDark,
      iconActiveLightTheme: SocialLightActive,
      iconActiveDarkTheme: SocialDarkActive,
      hasIcon: true,
      subItems: [
        {
          label: 'Monitor & Takedown',
          parentLabel: 'Social Media',
          id: '/premium/social/monitor-and-takedown',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Live Detections',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/social/live-detection',
              href: '/premium/social/live-detection',
              subItems: [],
            },
            {
              label: 'Takedown in Progress',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/social/takedown-and-progress',
              href: '/premium/social/takedown-and-progress',
              subItems: [],
            },
            {
              label: 'Taken Down',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/social/takedown',
              href: '/premium/social/takedown',
              subItems: [],
            },
            {
              label: 'Ignored',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/social/ignored',
              href: '/premium/social/ignored',
              subItems: [],
            },
          ],
        },
        {
          label: 'Setup',
          parentLabel: 'Social Media',
          id: '/premium/social/setup',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Add Search Terms',
              parentLabel: 'Setup',
              id: '/premium/social/search-terms',
              href: '/premium/social/search-terms',
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      label: 'App Store',
      parentLabel: 'Premium',
      id: '/premium/dashboard/app-store',
      href: '/premium/dashboard/app-store',
      iconLightTheme: AppStoreLight,
      iconDarkTheme: AppStoreDark,
      iconActiveLightTheme: AppStoreLightActive,
      iconActiveDarkTheme: AppStoreDarkActive,
      hasIcon: true,
      subItems: [
        {
          label: 'Monitor & Takedown',
          parentLabel: 'App Store',
          id: '/premium/app-store/monitor-and-takedown',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Live Detections',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/app-store/live-detection',
              href: '/premium/app-store/live-detection',
              subItems: [],
            },
            {
              label: 'Takedown in Progress',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/app-store/takedown-and-progress',
              href: '/premium/app-store/takedown-and-progress',
              subItems: [],
            },
            {
              label: 'Taken Down',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/app-store/takedown',
              href: '/premium/app-store/takedown',
              subItems: [],
            },
            {
              label: 'Ignored',
              parentLabel: 'Monitor & Takedown',
              id: '/premium/app-store/ignored',
              href: '/premium/app-store/ignored',
              subItems: [],
            },
          ],
        },
        {
          label: 'Setup',
          parentLabel: 'App Store',
          id: '/premium/app-store/setup',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Add Search Terms',
              parentLabel: 'Setup',
              id: '/premium/app-store/search-terms',
              href: '/premium/app-store/search-terms',
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      label: 'Dark Web',
      parentLabel: 'Premium',
      id: '/premium/dashboard/darkweb',
      href: '/premium/dashboard/darkweb',
      iconLightTheme: DarkWebLight,
      iconDarkTheme: DarkWebDark,
      iconActiveLightTheme: DarkWebActiveLight,
      iconActiveDarkTheme: DarkWebActiveDark,
      hasIcon: true,
      subItems: [
        {
          label: 'Dark Web Findings',
          parentLabel: 'Dark Web',
          id: '/premium/darkweb/findings',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Active Findings',
              parentLabel: 'Dark Web Findings',
              id: '/premium/darkweb/active-findings',
              href: '/premium/darkweb/active-findings',
              subItems: [],
            },
            {
              label: 'Mitigated  Findings',
              parentLabel: 'Dark Web Findings',
              id: '/premium/darkweb/mitigated-findings',
              href: '/premium/darkweb/mitigated-findings',
              subItems: [],
            },
            {
              label: 'Ignored Findings',
              parentLabel: 'Dark Web Findings',
              id: '/premium/darkweb/ignored-findings',
              href: '/premium/darkweb/ignored-findings',
              subItems: [],
            },
          ],
        },
        {
          label: 'Dark Web Actions',
          parentLabel: 'Dark Web',
          id: '/premium/darkweb/dark-web-actions',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Threat Actors',
              parentLabel: 'Dark Web Actions',
              id: '/premium/darkweb/threat-actors',
              href: '/premium/darkweb/threat-actors',
              subItems: [],
            },
          ],
        },
        {
          label: 'Setup',
          parentLabel: 'Dark Web',
          id: '/premium/darkweb/setup',
          href: '#',
          isLocked: true,
          isSubMenuText: true,
          subItems: [
            {
              label: 'Add Search Terms',
              parentLabel: 'Setup',
              id: '/premium/darkweb/search-terms',
              href: '/premium/darkweb/search-terms',
              subItems: [],
            },
            {
              label: 'Review Detection',
              parentLabel: 'Setup',
              id: '/premium/darkweb/review-detections',
              href: '/premium/darkweb/review-detections',
              subItems: [],
            },
          ],
        },
      ],
    },
  ];
};

export const DRAWER_SUB_ITEMS = (
  user: DashBoardDto,
  typosquatV2Enabled: boolean,
  isCommunity: boolean = false,
): any => {
  const { type_name, organization, first_name } = user;
  // disabled feature code will override the enabled feature code
  const isWebModuleAvailable = getIsWebModuleAvailable(user);
  const isSocialModuleAvailable = getIsSocialMediaModuleAvailable(user);
  const isAppStoreModuleAvailable = getIsAppStoreModuleAvailable(user);
  const isDarkWebModuleAvailable = getIsDarkWebModuleAvailable(user);

  return {
    ...(type_name === 'brand' && {
      Web: [
        {
          label: 'Dashboard',
          id: '/web/dashboard',
          href: '/web/dashboard',
          parentLabel: 'Web',
          subItems: [],
          disabled: false,
          featureCode: domainFeatureCode,
        },
        isWebModuleAvailable && {
          label: 'MONITOR & TAKEDOWN',
          id: '/web',
          href: '/web',
          parentLabel: 'Web',
          hidden: false,
          featureCode: domainFeatureCode,
          subItems: [
            {
              id: 'pre-malicious',
              label: 'Monitor Pre-Malicious',
              href: PRE_MALICIOUS_PATHNAME,
              parent: `${WEB_DASHBOARD_PATHNAME}`,
              parentLabel: 'Web',
              disabled: false,
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: domainFeatureCode,
            },
            {
              id: 'takedown-malicious',
              label: 'Takedown Malicious',
              href: TAKEDOWN_MALICIOUS_PATHNAME,
              parent: `${WEB_DASHBOARD_PATHNAME}`,
              featureCode: domainFeatureCode,
              parentLabel: 'Web',
              disabled: false,
              type: EMenuType.SUB_MENU_HEADER,
            },
            {
              id: 'post-malicious',
              label: 'Monitor Post-Malicious',
              href: POST_MALICIOUS_PATHNAME,
              parent: `${WEB_DASHBOARD_PATHNAME}`,
              parentLabel: 'Web',
              disabled: false,
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: domainFeatureCode,
            },
            {
              id: 'initiate-takedown',
              label: 'Initiate Takedown',
              href: WEB_INITIATE_TAKEDOWN_PATHNAME,
              parent: `${WEB_DASHBOARD_PATHNAME}`,
              parentLabel: 'Web',
              hidden: true,
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: socTakedownFeatureCode,
            },
          ],
        },
        isWebModuleAvailable && {
          label: 'MONITOR & ACQUIRE',
          id: '/web',
          href: '/web',
          hidden: true,
          parentLabel: 'Web',
          featureCode: domainFeatureCode,
          subItems: [
            {
              id: 'acquisitions',
              label: 'Monitor for Acquisitions',
              href: ACQUISITIONS_PATHNAME,
              parent: `${WEB_DASHBOARD_PATHNAME}`,
              parentLabel: 'Web',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: domainFeatureCode,
            },
          ],
        },
      ].filter(Boolean),
      'Social Media': [
        {
          label: 'Dashboard',
          id: SOCIAL_MEDIA_DASHBOARD_PATHNAME,
          href: SOCIAL_MEDIA_DASHBOARD_PATHNAME,
          parent: 'social-media',
          featureCode: socialFeatureCode,
          subItems: [],
          parentLabel: 'Social Media',
          type: EMenuType.SUB_MENU_HEADER,
        },
        isSocialModuleAvailable && {
          label: 'MONITOR & TAKEDOWN',
          hidden: true,
          featureCode: socialFeatureCode,
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [
            {
              id: '/detection',
              label: 'Live Detections',
              href: SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME,
              parent: 'social-media',
              parentLabel: 'Social Media',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: socialFeatureCode,
            },
            {
              id: '/takedown-in-progress',
              label: 'Takedown in Progress',
              href: SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
              parent: 'social-media',
              parentLabel: 'Social Media',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: socialFeatureCode,
            },
            {
              id: '/takendown',
              label: 'Taken Down',
              href: SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME,
              parent: 'social-media',
              parentLabel: 'Social Media',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: socialFeatureCode,
            },
            {
              id: '/ignored',
              label: 'Ignored',
              href: SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
              parent: 'social-media',
              parentLabel: 'Social Media',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: socialFeatureCode,
            },
          ],
        },
        isSocialModuleAvailable &&
          !isBrandReadOnlyUser(user) && {
            id: 'social-media-search-terms',
            label: 'SETUP',
            href: SOCIAL_MEDIA_SEARCH_TERMS,
            parent: 'social-media',
            parentLabel: 'Social Media',
            type: EMenuType.SUB_MENU_HEADER,
            featureCode: socialFeatureCode,
            subItems: [
              {
                id: '/search',
                label: 'Add Search Terms',
                href: SOCIAL_MEDIA_SEARCH_TERMS,
                parent: 'social-media',
                parentLabel: 'Social Media',
                type: EMenuType.SUB_MENU_HEADER,
                featureCode: socialFeatureCode,
              },
              ...(first_name && isAdminUser(user)
                ? [
                    {
                      id: '/review',
                      label: 'Review Detection',
                      href: SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
                      parent: 'social-media',
                    },
                  ]
                : []),
            ],
          },
      ].filter(Boolean),
      Marketplace: [
        {
          label: 'Dashboard',
          id: '/marketplace/dashboard',
          href: '/marketplace/dashboard',
          parent: 'Marketplace',
          featureCode: marketplaceFeatureCode,
          parentLabel: 'Marketplace',
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [],
        },
        {
          label: 'MONITOR & TAKEDOWN',
          hidden: true,
          featureCode: marketplaceFeatureCode,
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [
            {
              id: '/marketplace/detection',
              label: 'Live Detections',
              href: MARKET_PLACE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
              parent: 'Marketplace',
              parentLabel: 'Marketplace',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: marketplaceFeatureCode,
            },
            {
              id: '/marketplace/takedown-in-progress',
              label: 'Takedown in Progress',
              href: MARKET_PLACE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
              parent: 'Marketplace',
              parentLabel: 'Marketplace',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: marketplaceFeatureCode,
            },
            {
              id: '/marketplace/takendown',
              label: 'Taken Down',
              href: MARKET_PLACE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME,
              parent: 'Marketplace',
              parentLabel: 'Marketplace',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: marketplaceFeatureCode,
            },
            {
              id: '/marketplace/ignored',
              label: 'Ignored',
              href: MARKET_PLACE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
              parent: 'Marketplace',
              parentLabel: 'Marketplace',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: marketplaceFeatureCode,
            },
          ],
        },
        !isBrandReadOnlyUser(user)
          ? {
              id: '/marketplace/search',
              href: MARKET_PLACE_PATHNAME_SEARCH_TERMS,
              label: 'SETUP',
              hidden: true,
              featureCode: marketplaceFeatureCode,
              type: EMenuType.SUB_MENU_HEADER,
              subItems: [
                {
                  id: '/search',
                  label: 'Add Search Terms',
                  href: MARKET_PLACE_PATHNAME_SEARCH_TERMS,
                  parent: 'Marketplace',
                  parentLabel: 'Marketplace',
                  type: EMenuType.SUB_MENU_HEADER,
                  featureCode: marketplaceFeatureCode,
                },
                ...(first_name && isAdminUser(user)
                  ? [
                      {
                        id: '/marketplace/review',
                        label: 'Review Detection',
                        href: MARKET_PLACE_PATHNAME_FINDINGS_REVIEW_PATHNAME,
                        parent: 'Marketplace',
                      },
                    ]
                  : []),
              ],
            }
          : null,
      ].filter(Boolean),
      'App Store': [
        {
          label: 'Dashboard',
          id: '/app-store/dashboard',
          href: '/app-store/dashboard',
          parent: 'App Store',
          featureCode: appStoreFeatureCode,
          parentLabel: 'App Store',
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [],
        },
        isAppStoreModuleAvailable && {
          label: 'MONITOR & TAKEDOWN',
          hidden: true,
          featureCode: appStoreFeatureCode,
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [
            {
              id: '/app-store/detection',
              label: 'Live Detections',
              href: APP_STORE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
              parent: 'App Store',
              parentLabel: 'App Store',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: appStoreFeatureCode,
            },
            {
              id: '/app-store/takedown-in-progress',
              label: 'Takedown in Progress',
              href: APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
              parent: 'App Store',
              parentLabel: 'App Store',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: appStoreFeatureCode,
            },
            {
              id: '/app-store/takendown',
              label: 'Taken Down',
              href: APP_STORE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME,
              parent: 'App Store',
              parentLabel: 'App Store',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: appStoreFeatureCode,
            },
            {
              id: '/app-store/ignored',
              label: 'Ignored',
              href: APP_STORE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
              parent: 'App Store',
              parentLabel: 'App Store',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: appStoreFeatureCode,
            },
          ],
        },
        isAppStoreModuleAvailable &&
          !isBrandReadOnlyUser(user) && {
            id: '/app-store/search',
            href: APP_STORE_PATHNAME_SEARCH_TERMS,
            label: 'SETUP',
            hidden: true,
            featureCode: appStoreFeatureCode,
            type: EMenuType.SUB_MENU_HEADER,
            subItems: [
              {
                id: '/search',
                label: 'Add Search Terms',
                href: APP_STORE_PATHNAME_SEARCH_TERMS,
                parent: 'App Store',
                parentLabel: 'App Store',
                type: EMenuType.SUB_MENU_HEADER,
                featureCode: appStoreFeatureCode,
              },
            ],
          },
      ].filter(Boolean),
      'Dark Web': [
        {
          label: 'Dashboard',
          parent: 'Dark Web',
          id: '/dark-web/dashboard',
          href: DARK_WEB_DASHBOARD_PATHNAME,
          parentLabel: 'Dark Web',
          featureCode: darkWebFeatureCode,
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [],
        },
        isDarkWebModuleAvailable && {
          label: 'DARK WEB FINDINGS',
          hidden: true,
          featureCode: darkWebFeatureCode,
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [
            {
              id: '/dark-web/active',
              label: 'Active Findings',
              href: DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME,
              parent: 'Dark Web',
              parentLabel: 'Dark Web',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: darkWebFeatureCode,
            },
            {
              id: '/dark-web/mitigated',
              label: 'Mitigated Findings',
              href: DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME,
              parent: 'Dark Web',
              parentLabel: 'Dark Web',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: darkWebFeatureCode,
            },
            {
              id: '/dark-web/ignored',
              label: 'Ignored Findings',
              href: DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME,
              parent: 'Dark Web',
              parentLabel: 'Dark Web',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: darkWebFeatureCode,
            },
          ],
        },
        isDarkWebModuleAvailable && {
          id: '/dark-web/threat-actors',
          href: DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME,
          label: 'DARK WEB ACTORS',
          hidden: true,
          featureCode: darkWebFeatureCode,
          type: EMenuType.SUB_MENU_HEADER,
          subItems: [
            {
              id: '/threat-actors',
              label: 'Threat Actors',
              href: DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME,
              parent: 'Dark Web',
              parentLabel: 'Dark Web',
              type: EMenuType.SUB_MENU_HEADER,
              featureCode: darkWebFeatureCode,
            },
          ],
        },
        isDarkWebModuleAvailable && !isBrandReadOnlyUser(user)
          ? {
              id: '/dark-web/search',
              href: DARK_WEB_PATHNAME_SEARCH_TERMS,
              label: 'SETUP',
              hidden: true,
              featureCode: darkWebFeatureCode,
              type: EMenuType.SUB_MENU_HEADER,
              subItems: [
                {
                  id: '/search',
                  label: 'Add Search Terms',
                  href: DARK_WEB_PATHNAME_SEARCH_TERMS,
                  parent: 'Dark Web',
                  parentLabel: 'Dark Web',
                  type: EMenuType.SUB_MENU_HEADER,
                  featureCode: darkWebFeatureCode,
                },
              ],
            }
          : null,
      ].filter(Boolean),
      Scan: [
        {
          label: 'Scan',
          id: '/bulk-scan',
          href: '/bulk-scan',
          subItems: [],
          parentLabel: 'Scan',
          type: EMenuType.SUB_MENU_HEADER,
          featureCode: domainFeatureCode,
          hideDrawer: true,
        },
      ],
      ...(!isBrandReadOnlyUser(user) && {
        Automation: [
          {
            label: 'PLAYBOOKS',
            id: '/automation/playbooks',
            href: '/automation/playbooks/web-playbook',
            parentLabel: 'Automation',
            type: EMenuType.SUB_MENU_HEADER,
            featureCode: domainFeatureCode,
            subItems: [
              {
                id: '/automation/web-playbooks',
                label: 'Web Playbook',
                href: '/automation/playbooks/web-playbook',
                parent: 'playbook',
                parentLabel: 'Automation',
                type: EMenuType.SUB_MENU_HEADER,
                featureCode: domainFeatureCode,
              },
              {
                id: '/automation/socialmedia_playbooks',
                label: 'Social Media Playbook',
                href: '/automation/playbooks/social',
                parent: 'playbook',
                parentLabel: 'Automation',
                type: EMenuType.SUB_MENU_HEADER,
                featureCode: socialFeatureCode,
              },
              // {
              //   id: '/automation/marketplace_playbooks',
              //   label: 'Marketplace Playbook',
              //   href: '/automation/playbooks/marketplace',
              //   parent: 'playbook',
              //   parentLabel: 'Automation',
              //   type: EMenuType.SUB_MENU_HEADER,
              //   featureCode: marketplaceFeatureCode,
              // },
              {
                id: '/automation/appstore_playbooks',
                label: 'App Store Playbook',
                href: '/automation/playbooks/app-store',
                parent: 'playbook',
                parentLabel: 'Automation',
                type: EMenuType.SUB_MENU_HEADER,
                featureCode: appStoreFeatureCode,
              },
              {
                id: '/automation/darkweb_playbooks',
                label: 'Dark Web Playbook',
                href: '/automation/playbooks/dark-web',
                parent: 'playbook',
                parentLabel: 'Automation',
                type: EMenuType.SUB_MENU_HEADER,
                featureCode: darkWebFeatureCode,
              },
            ],
          },
        ],
      }),
      Reports: [
        {
          label: 'REPORTS',
          id: '/reports',
          href: '/reports',
          subItems: [],
          type: EMenuType.SUB_MENU_HEADER,
          featureCode: domainFeatureCode,
          hideDrawer: true,
          parentLabel: 'Reports',
        },
      ],
      Integrations: [
        {
          label: 'INTEGRATIONS',
          id: '/integrations',
          href: '/integrations/available-connectors',
          subItems: [
            {
              id: '/integrations/available-connectors',
              label: 'Available Connectors',
              href: '/integrations/available-connectors',
              parentLabel: 'INTEGRATIONS',
              type: EMenuType.SUB_MENU_HEADER,
            },
            {
              id: '/integrations/implemented-connectors',
              label: 'Implemented Connectors',
              href: '/integrations/implemented-connectors',
              adminOnly: true,
              parentLabel: 'INTEGRATIONS',
              type: EMenuType.SUB_MENU_HEADER,
            },
          ],
          type: EMenuType.SUB_MENU_HEADER,
          parentLabel: 'Integrations',
        },
      ],
    }),
    ...(type_name === 'non_brand' && {
      Scan: [
        {
          subItems: [
            {
              label: 'Dashboard',
              id: '/checkphish/dashboard',
              href: '/checkphish/dashboard',
              subItems: [],
              parentLabel: isCommunity ? 'CheckPhish-Scan' : 'Scan',
              disabled: false,
              featureCode: [],
              render: () => {
                return null;
              },
            },
            {
              label: 'Live Scan',
              id: '/bulk-scan',
              href: '/bulk-scan',
              subItems: [],
              disabled: false,
              parentLabel: isCommunity ? 'CheckPhish-Scan' : 'Scan',
              featureCode: [],
            },
            {
              label: 'All Scans',
              id: '/checkphish/allscans',
              href: '/checkphish/allscans',
              subItems: [],
              disabled: false,
              parentLabel: isCommunity ? 'CheckPhish-Scan' : 'Scan',
            },
          ],
          disabled: false,
          featureCode: [],
          type: EMenuType.SUB_MENU_HEADER,
        },
      ],
      CheckPhish: [
        {
          subItems: [
            {
              label: 'Dashboard',
              id: TYPOSQUATTING_DASHBOARD_PATHNAME,
              href: TYPOSQUATTING_DASHBOARD_PATHNAME,
              subItems: [],
              parentLabel: 'CheckPhish',
              disabled: typosquatV2Enabled,
              featureCode: [],
            },
            {
              label: 'Typosquat',
              id: TYPOSQUATTING_MASTER_PATHNAME,
              href: TYPOSQUATTING_MASTER_PATHNAME,
              parentLabel: 'CheckPhish',
              subItems: [],
              disabled: !typosquatV2Enabled,
            },
            {
              label: 'Domain Acquisition',
              id: TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME,
              href: TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME,
              subItems: [],
              disabled: true,
              parentLabel: 'CheckPhish',
            },
          ],
          disabled: false,
          featureCode: [],
          type: EMenuType.SUB_MENU_HEADER,
        },
      ],
    }),
    Assets: [
      {
        label: 'GENERAL',
        id: '/assets/logos',
        href: '/assets/logos',
        webOnly: true,
        parentLabel: 'Assets',
        subItems: [
          {
            id: '/logos',
            label: `Logos`,
            href: '/assets/logos',
            parent: '/assets',
          },
          {
            id: '/documents',
            label: 'Documents',
            href: '/assets/documents',
            parent: '/assets',
          },
          {
            id: '/trademarks',
            label: 'Registered Trademarks',
            href: '/assets/trademarks',
            parent: '/assets',
          },
        ],
        type: EMenuType.SUB_MENU_HEADER,
      },
      {
        label: 'DOMAINS',
        id: '/assets/managed-by-organization',
        href: '/assets/managed-by-organization',
        webOnly: true,
        parentLabel: 'Assets',
        subItems: [
          {
            id: '/managed-by-organization',
            label: `Managed By ${organization}`,
            href: '/assets/managed-by-organization',
            parent: '/assets',
          },
          {
            id: '/managed-by-affiliates',
            label: 'Managed By Affiliates',
            href: '/assets/managed-by-affiliates',
            parent: '/assets',
          },
          {
            id: '/managed-by-bolster',
            label: 'Managed By Bolster',
            href: '/assets/managed-by-bolster',
            parent: '/assets',
          },
        ],
        type: EMenuType.SUB_MENU_HEADER,
        featureCode: domainFeatureCode,
      },
      {
        label: 'OFFICIAL ACCOUNT',
        id: '/assets/official-account',
        href: '/assets/official-account',
        webOnly: true,
        parentLabel: 'Assets',
        subItems: [
          {
            id: '/social',
            label: 'Social Media',
            href: '/assets/official-account/social',
            parent: '/assets',
            disabled: !featureIsAvailable(user, socialFeatureCode),
          },
          {
            id: '/marketplace',
            label: 'Marketplace',
            href: '/assets/official-account/marketplace',
            parent: '/assets',
            disabled: !featureIsAvailable(user, marketplaceFeatureCode),
            hidden: !featureIsAvailable(user, marketplaceFeatureCode),
          },
          {
            id: '/app-store',
            label: 'App Store',
            href: '/assets/official-account/app-store',
            parent: '/assets',
            disabled: !featureIsAvailable(user, appStoreFeatureCode),
          },
        ],
        type: EMenuType.SUB_MENU_HEADER,
      },
    ],
    Account: [
      {
        label: '',
        id: '/account/profile',
        href: '/account/profile',
        parentLabel: 'Account',
        type: EMenuType.SUB_MENU_HEADER,
        subItems: [
          {
            id: 'Profile',
            label: 'Profile Information',
            href: '/account/profile',
            parent: 'Settings',
            parentLabel: 'Account',
            type: EMenuType.SUB_MENU_HEADER,
          },
          ...(type_name === 'brand'
            ? [
                {
                  id: 'Team Members',
                  label: 'Team Members',
                  href: '/account/team-members',
                  parent: 'Settings',
                  adminOnly: true,
                  parentLabel: 'Account',
                  type: EMenuType.SUB_MENU_HEADER,
                },
                {
                  id: '/account/communication-settings',
                  label: 'Communication Settings',
                  href: '/account/communication-settings',
                  parentLabel: 'Account',
                  type: EMenuType.SUB_MENU_HEADER,
                  featureCode: notificationSettingsFeatureCode,
                  hidden: !featureIsAvailable(user, notificationSettingsFeatureCode),
                },
              ]
            : []),
          ...(type_name === 'non_brand'
            ? [
                {
                  id: 'Notification Settings',
                  label: 'Notification Settings',
                  href: '/account/notifications',
                  parent: 'Settings',
                  adminOnly: true,
                  parentLabel: 'Account',
                  type: EMenuType.SUB_MENU_HEADER,
                },
              ]
            : []),
          {
            id: 'Security & Privacy',
            label: 'Security & Privacy',
            href: '/account/change-password',
            parent: 'Settings',
            adminOnly: true,
            parentLabel: 'Account',
            type: EMenuType.SUB_MENU_HEADER,
          },
        ],
      },
      ...(type_name === 'brand'
        ? [
            {
              label: '',
              id: '/manage/landing-page',
              href: '/manage/landing-page',
              parentLabel: 'Manage',
              type: EMenuType.SUB_MENU_HEADER,
              subItems: [
                {
                  id: 'Landing Page',
                  label: 'Landing Page',
                  href: '/manage/landing-page',
                  parent: 'Settings',
                  parentLabel: 'Account',
                  type: EMenuType.SUB_MENU_HEADER,
                },
              ],
            },
          ]
        : []),
    ],
  };
};

export const typosquattingDomainFeatureIsAvailable = (user: DashBoardDto) => {
  return featureIsAvailable(user, [appConstants.FEATURE_CODE.WEB]);
};

export const HideSideBarPaths: RegExp[] = [new RegExp(`${WEB_INITIATE_TAKEDOWN_PATHNAME}`)];

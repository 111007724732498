import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Checkbox } from '../Common/Checkbox';
import { Editor as TinyMCEEditor } from 'tinymce';
import { LabelAndValue } from '../Common/LabelAndValue';
import { TakedownRequestForm } from './types/takedown-request-form.interface';
import { SocTakedownValidationErrors } from './types/soc-takedown-errors.interface';
import RichTextEditor from '../Common/RichTextEditor';
import './soc-takedown.scss';
import { IAlertDispatchProps } from '../../constants';
import { alertActions } from '../../actions';
import { connect } from 'react-redux';
import UploadFilesMulti from '../Common/UploadFilesMulti';
import { UploadFileItem } from '../Common/UploadFilesMulti';

export interface ISocTakedownFormProps {
  form: TakedownRequestForm;
  onAttachmentChange: (updatedItems: UploadFileItem[]) => void;
  formChanged: (updated: TakedownRequestForm) => void;
  validationErrors: SocTakedownValidationErrors;
  showErrors: boolean;
  fileItems: UploadFileItem[];
}

function SocTakedownForm({
  form,
  onAttachmentChange,
  validationErrors,
  showErrors,
  formChanged,
  fileItems,
}: ISocTakedownFormProps & Pick<IAlertDispatchProps, 'alertError'>) {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [initialBodyHtml, setInitialBodyHtml] = useState('');

  useEffect(() => {
    if ((form?.ccEmails ?? '').length > 0) {
      setShowCc(true);
    }
  }, [form?.ccEmails]);

  useEffect(() => {
    if ((form?.bccEmails ?? '').length > 0) {
      setShowBcc(true);
    }
  }, [form?.bccEmails]);

  useEffect(() => {
    setInitialBodyHtml(form.bodyHtml);
  }, []);

  function attachmentChanged(updatedItems: UploadFileItem<string>[]): void {
    onAttachmentChange(updatedItems);
  }

  return (
    <div>
      <div className={'d-flex justify-content-end soc-takedown-review-to-from'}>
        <LabelAndValue label={'From'} value={form.fromEmail} direction={'row'} />
      </div>
      <Form>
        <Form.Group controlId='toEmails'>
          <div className={'d-flex align-items-center'}>
            <Form.Label className={'mr-3'}>To: </Form.Label>
            <Form.Control
              className={'flex-grow-1'}
              type='text'
              value={form.toEmails}
              onChange={v => {
                formChanged({ ...form, toEmails: v.target.value });
              }}
              isInvalid={showErrors && validationErrors.to.length > 0}
              name={'toEmails'}
              autoComplete='email'
            />
            <Button variant='link' className={'link-light'} onClick={() => setShowCc(true)}>
              Cc
            </Button>
            <Button variant='link' onClick={() => setShowBcc(true)}>
              Bcc
            </Button>
          </div>
          {showErrors &&
            validationErrors.to.map((error, ix) => {
              return (
                <div className='text-danger soc-validation-error' key={ix}>
                  {error}
                </div>
              );
            })}
        </Form.Group>

        {showCc && (
          <Form.Group className={'d-flex mt-4 align-items-center'} controlId='ccEmails'>
            <Form.Label className={'mr-3'}>Cc: </Form.Label>
            <Form.Control
              className={'flex-grow-1'}
              type='text'
              value={form.ccEmails}
              onChange={v => {
                formChanged({ ...form, ccEmails: v.target.value });
              }}
              name={'ccEmails'}
              autoComplete={'cc-email'}
            />
          </Form.Group>
        )}

        {showBcc && (
          <Form.Group className={'d-flex  mt-4 align-items-center'} controlId='bccEmails'>
            <Form.Label className={'mr-3'}>Bcc: </Form.Label>
            <Form.Control
              className={'flex-grow-1'}
              type='text'
              value={form.bccEmails}
              onChange={v => {
                formChanged({ ...form, bccEmails: v.target.value });
              }}
              name={'bccEmails'}
              autoComplete='bcc-email'
            />
          </Form.Group>
        )}

        <Form.Group className={'mt-4'} controlId='subject'>
          <Form.Control
            type='text'
            placeholder={'Subject'}
            value={form.subject}
            onChange={v => {
              formChanged({ ...form, subject: v.target.value });
            }}
            isInvalid={showErrors && validationErrors.subject.length > 0}
          />
          {showErrors &&
            validationErrors.subject.map(error => {
              return <div className='text-danger soc-validation-error'>{error}</div>;
            })}
        </Form.Group>

        <Form.Group className={'d-flex req-url'}>
          <Form.Label className={'mr-3 '}>Submitted via form:</Form.Label>
          <Checkbox
            isStateless={true}
            onChange={checked => {
              formChanged({ ...form, submittedViaForm: checked });
            }}
            defaultChecked={form.submittedViaForm}
          />
        </Form.Group>

        <Form.Group className={'d-flex req-url'}>
          <Form.Label className={'mr-3 '}>Report urls to:</Form.Label>
          <Checkbox
            label='Google SafeBrowsing'
            isStateless={true}
            onChange={checked => {
              formChanged({ ...form, googleSafeBrowsing: checked });
            }}
            defaultChecked={form.googleSafeBrowsing}
          />
          <Checkbox
            label='APWG Blocklist '
            isStateless={true}
            onChange={checked => {
              formChanged({ ...form, apwgBlocklist: checked });
            }}
            defaultChecked={form.apwgBlocklist}
          />
          <Checkbox
            label='Cloudflare '
            isStateless={true}
            onChange={checked => {
              formChanged({ ...form, cloudFlare: checked });
            }}
            defaultChecked={form.cloudFlare}
          />
        </Form.Group>

        <div
          className={
            showErrors && validationErrors.sentTo.length ? 'border border-danger p-1 mb-2' : ''
          }
        >
          <Form.Group className={'d-flex req-url'}>
            <Form.Label className={'mr-3 '}>Takedown request is sent to: </Form.Label>
            <Checkbox
              label='Hosting Provider '
              isStateless={true}
              onChange={checked => {
                formChanged({ ...form, reportedToHostingProvider: checked });
              }}
              defaultChecked={form.reportedToHostingProvider}
            />
            <Checkbox
              label='Registrar '
              isStateless={true}
              onChange={checked => {
                formChanged({ ...form, reportedToRegistrar: checked });
              }}
              defaultChecked={form.reportedToRegistrar}
            />
            <Checkbox
              label='Registrant '
              isStateless={true}
              onChange={checked => {
                formChanged({ ...form, reportedToRegistrant: checked });
              }}
              defaultChecked={form.reportedToRegistrant}
            />
            <Checkbox
              label='Other '
              isStateless={true}
              onChange={checked => {
                formChanged({ ...form, reportedToOther: checked });
              }}
              defaultChecked={form.reportedToOther}
            />
          </Form.Group>

          {showErrors && validationErrors.sentTo.length > 0 && (
            <div>
              {showErrors &&
                validationErrors.sentTo.map((error, ix) => {
                  return (
                    <div className='text-danger soc-validation-error mb-1' key={ix}>
                      {error}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div
          className={showErrors && validationErrors.body.length ? 'border border-danger p-1' : ''}
        >
          <RichTextEditor
            onInit={(_, editor) => (editorRef.current = editor)}
            onEditorChange={value => {
              formChanged({
                ...form,
                bodyHtml: value,
              });
            }}
            initialValue={initialBodyHtml}
          />
          {showErrors &&
            validationErrors.body.map((error, ix) => {
              return (
                <div className='text-danger soc-validation-error mb-1' key={ix}>
                  {error}
                </div>
              );
            })}
        </div>

        <Form.Group className={'mt-4'}>
          <div className={'mb-2'}>Attachments</div>

          {showErrors &&
            validationErrors.attachments.map((error, ix) => {
              return (
                <div className='text-danger soc-validation-error mb-1' key={ix}>
                  {error}
                </div>
              );
            })}
          <div
            className={
              showErrors && validationErrors.attachments.length ? 'border border-danger p-1' : ''
            }
          >
            <UploadFilesMulti
              items={fileItems}
              onChange={updated => attachmentChanged(updated as UploadFileItem<string>[])}
              options={{
                dragAndDrop: true,
                hideError: true,
              }}
            />
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

const mapDispatchToProps = {
  alertError: alertActions.error,
};

export default connect(null, mapDispatchToProps)(SocTakedownForm);

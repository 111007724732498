import React, { useState } from 'react';
import { ChevronDownIcon } from '../../../../../assets/SVGIcons';

interface IActorChattersPostColRenderProps {
  title: string;
  content: string;
}

export default function ActorChattersPostColRender({
  title,
  content,
}: IActorChattersPostColRenderProps) {
  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShownMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className='actor-chatters-post-col'>
      <div className='title long-text-ellipsis-1'>
        <b>{title}</b>
      </div>
      <div className={`content ${showMore ? 'show-all-content' : 'long-text-ellipsis-2'}`}>
        {content}
      </div>
      <div className='text-link' onClick={toggleShownMore}>
        <b>Read {showMore ? 'Less' : 'More'}</b>
        <ChevronDownIcon className={`chevron-icon blue ml-1 ${showMore ? 'chevron-up' : ''}`} />
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { getColors } from '../../../../constants';
import {
  IChartPlaceholderProps,
  TColorMap,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import BarChart from '../../../Common/DashboardWidgets/BarChart';
import NoHosting from '../../../../assets/icons/NoHosting.svg';
import ThemeContext from '../../../../context/ThemeContext';

const TOTAL = 'Sites detected';

const convert2BarChartDataFormat = (rawData: any) => {
  const results: any = [];
  Object.keys(rawData).forEach(item => {
    results.push({
      label: item,
      total: rawData[item],
      categories: [
        {
          label: 'Sites detected',
          count: rawData[item],
        },
      ],
    });
  });
  return results;
};

const placeholder: IChartPlaceholderProps = {
  icon: NoHosting,
  title: `No Site Hosting Data Found`,
  description: `We haven't detected any Phish and Scam Site Hosting for this period. Please select another time frame, or scan more.`,
};

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  isLoading: boolean;
  widgetData: any;
}

function DashboardHosting({ startDate, endDate, isLoading, widgetData }: IComponentProps) {
  const { selectedTheme } = useContext(ThemeContext);

  const colorMap: TColorMap = {
    [TOTAL]: getColors(selectedTheme).barChart1,
  };

  return (
    <BarChart
      title='Top 10 Phish and Scam Site Hosting'
      isLoading={isLoading}
      description='New Phish and Scam sites detected by hosting provider'
      data={convert2BarChartDataFormat(widgetData)}
      colorMap={colorMap}
      placeholder={placeholder}
      yAxisTitle='Pages'
      hideLegend={true}
    />
  );
}

export { DashboardHosting };

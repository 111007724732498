import _ from 'lodash';
import { DEFAULT_VALUE_NO_PASSWORD } from '../DarkWeb/Components/Common/TableColDefs/DarkWebColDefs';
import { VALUE_DELIMITER } from '../Common/Table/filter.date';

export interface IAllPlaybookConnectors {
  result: IPlaybookConnector[];
}

export interface IPlaybookConnector {
  id: number;
  name: string;
  brandId: number;
  details: Details;
  connectorTypeId: number;
  connectorType: ConnectorType;
}

export interface Details {}

export interface ConnectorType {
  id: number;
  key: string;
  label: string;
  doc_url: any;
  details: any;
}
export const massagedConnectors = (res: IAllPlaybookConnectors) => {
  if (res.result && res.result.length) {
    return _.map(res.result, (connector: IPlaybookConnector) => {
      return {
        ...connector,
        type: connector.connectorType.label,
      };
    });
  }
  return [];
};

//convert the password value to empty string
//if it is 'No Password' for Ag grid and on adding playbook filter
export const passwordTypeValueCheck = (value: string) => {
  if (value === DEFAULT_VALUE_NO_PASSWORD) {
    return '';
  }
  return value;
};

//convert back the empty string to 'No Password' for UI filters
export const passwordTypeValueCheckForDWPlaybook = (filter: any) => {
  if (filter.value === '') {
    return DEFAULT_VALUE_NO_PASSWORD;
  }
  return filter;
};

export const convertDateRange = (dateRange: string) => {
  if (!dateRange) {
    return '';
  }
  // days or hours
  const [range, period] = dateRange.split('-');
  return `now${VALUE_DELIMITER}${range}${period}/${period}`;
};

export const convertDateRangeForPlaybookFilter = (dateRange?: string) => {
  if (!dateRange) {
    return '';
  }
  const period = dateRange[dateRange.length - 1]; // days (d) or hours (h)

  // days or hours
  const result =
    dateRange.replace('now-', '').replace(`${period}/${period}`, '') +
    `${VALUE_DELIMITER}${period}`;
  return result;
};

// Custom sorting comparator for ag-grid to sort combination of string and numeric values
export const customSortingComparator = (valueA: any, valueB: any) => {
  // Remove leading and trailing whitespace and convert to lowercase for comparison
  const valueAFormatted = valueA.toString().trim().toLowerCase();
  const valueBFormatted = valueB.toString().trim().toLowerCase();

  // Check if both values are numeric
  const isValueANumeric = !isNaN(valueAFormatted);
  const isValueBNumeric = !isNaN(valueBFormatted);

  // If both values are numeric, compare them numerically
  if (isValueANumeric && isValueBNumeric) {
    return valueAFormatted - valueBFormatted;
  }

  // If one value is numeric and the other is not, treat the numeric one as smaller
  if (isValueANumeric) {
    return -1;
  }
  if (isValueBNumeric) {
    return 1;
  }
  // If both values are strings, use localeCompare for alphabetical sorting
  return valueAFormatted.localeCompare(valueBFormatted);
};

export interface IDarkWebDispatchProps {
  setBreachDescriptions: () => Promise<void>;
}

export const darkWebConstants = {
  SET_BREACH_DESCRIPTIONS: 'SET_BREACH_DESCRIPTIONS',
  SET_SELECTED_ENTITY_ACTIVE: 'SET_SELECTED_ENTITY',
  SET_SELECTED_ENTITY_MITIGATED: 'SET_SELECTED_ENTITY_MITIGATED',
  SET_SELECTED_ENTITY_IGNORED: 'SET_SELECTED_ENTITY_IGNORED',
  SET_SELECTED_ENTITY_DEFAULT: 'SET_SELECTED_ENTITY_DEFAULT',
};

export const defaultDarkWebEntityWidgetDetails = { searchterms: {}, totalFindings: 0 };

import React from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import BulkScanService from '../../services/bulkscan.service';
import DashboardService from '../../services/dashboard.service';
import './bulkscan.scss';
import { appConstants, DashBoardDto, featureIsAvailable } from '../../constants';
import { IScanLocations } from './BulkScanConstants';
import {
  DropdownMultiSelect,
  DropdownTypeValues,
  IOption,
  IOptionGroup,
} from '../Common/DropdownMultiSelect';
import Country from '../Common/Country';
import { Dropdown } from '../Common/Dropdown';
import moment from 'moment';

const SCAN_LIMIT = 10;
const MIN_WAIT = 5000;
const MAX_WAIT = 60000;
const STEP_SIZE = 5000;
const DEFAULT_WAIT = MIN_WAIT - STEP_SIZE;

interface IScanLocation extends IOption {
  continentCode: string;
  continentName: string;
}

interface IUserAgent {
  id: number;
  userAgent: string;
  description: string;
  label: string;
}

interface IBulkScanFormProps {
  isLoading: boolean;
  submitScans: (scanInfo: any) => void;
  user: DashBoardDto;
  allUserAgents: IUserAgent[];
  isTorChecked: boolean;
}

interface IBulkScanFormState {
  urls: string[];
  selectedScanLocations: IOption[];
  locationOptions: IOptionGroup[];
  wait: number;
  selectedUserAgent: string;
  renderTimestamp: number;
}

interface IBrandScanPreference {
  scanLocationCode?: string;
  scanUserAgent: string;
  scanUserAgentId: number | null;
  scanTimeout: number;
}

class BulkScanForm extends React.Component<IBulkScanFormProps, IBulkScanFormState> {
  private readonly bulkScanService: BulkScanService;
  private readonly dashboardService: DashboardService;
  private defaultScanLocations: IOption[];
  private brandScanPreference: IBrandScanPreference;

  constructor(props: IBulkScanFormProps) {
    super(props);
    this.bulkScanService = new BulkScanService();
    this.dashboardService = new DashboardService();
    this.defaultScanLocations = [];
    this.brandScanPreference = {} as IBrandScanPreference;
    this.state = {
      urls: [],
      selectedScanLocations: [],
      locationOptions: [],
      wait: DEFAULT_WAIT,
      selectedUserAgent: '',
      renderTimestamp: moment().valueOf(),
    };
    this.loadAllScanLocations();
  }

  loadAllScanLocations = async () => {
    await Promise.all([
      this.bulkScanService.getBrandScanPreference(),
      this.bulkScanService.getScanLocations(),
    ]).then(res => {
      this.brandScanPreference = res[0];
      const scanLocations = res[1];

      // by default US is selected if not specified otherwise
      this.defaultScanLocations = [IScanLocations.US];

      if (this.brandScanPreference && this.brandScanPreference?.scanLocationCode) {
        this.defaultScanLocations = [];
        scanLocations.forEach((sl: any) => {
          if (sl.locationCode === this.brandScanPreference.scanLocationCode) {
            this.defaultScanLocations.push({
              label: sl.locationName,
              value: sl.locationCode,
            });
          }
        });
      }

      // all scan locations
      let scanLocationsOptions: IScanLocation[] = [];
      if (featureIsAvailable(this.props.user, [appConstants.FEATURE_CODE.LOCATION_BASED])) {
        scanLocationsOptions = _.chain(scanLocations)
          .filter(
            location =>
              location.locationType === 'PROXY_ISP' ||
              location.locationCode === this.defaultScanLocations[0].value,
          )
          .map(location => {
            const {
              locationCode,
              locationName,
              continent: { continentCode, continentName },
            } = location;
            return {
              label: locationName,
              value: locationCode,
              continentCode,
              continentName,
            };
          })
          .valueOf();
      }

      const groupByContinent = _.groupBy(scanLocationsOptions, 'continentName');
      const locationOptions: IOptionGroup[] = [];
      for (const continentName in groupByContinent) {
        locationOptions.push({
          label: continentName,
          value: continentName,
          options: groupByContinent[continentName],
        });
      }
      const sortedOptions = locationOptions.map(item => {
        if (item.options && item?.options?.length > 0) {
          item.options = item.options.sort((a, b) => {
            if (a?.value < b?.value) {
              return -1;
            } else if (a?.value > b?.value) {
              return 1;
            }
            return 0;
          });
        }
        return item;
      });

      this.setState({
        locationOptions: sortedOptions,
        selectedScanLocations: this.defaultScanLocations,
        selectedUserAgent: this.brandScanPreference?.scanUserAgent || '',
        wait: this.brandScanPreference?.scanTimeout || DEFAULT_WAIT,
      });
    });
  };

  resetScanSettings = () => {
    this.setState({
      selectedScanLocations: this.defaultScanLocations,
      wait: this.brandScanPreference?.scanTimeout || DEFAULT_WAIT,
      selectedUserAgent: this.brandScanPreference?.scanUserAgent,
    });
  };

  onTextAreaChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let urls = event.currentTarget.value.split('\n');
    urls = _.filter(urls, url => !_.isEmpty(url));
    this.setState({
      urls,
    });
  };

  submitScans = () => {
    const { selectedScanLocations, wait, selectedUserAgent } = this.state;
    this.props.submitScans({
      urls: this.state.urls,
      scanLocations: !this.props.isTorChecked
        ? _.map(
            selectedScanLocations.length > 0 ? selectedScanLocations : this.defaultScanLocations,
            l => l.value,
          )
        : ['TOR'],
      wait: wait >= MIN_WAIT ? wait : undefined,
      selectedUserAgent: selectedUserAgent,
      onSubmitType: 'bulkscan',
    });
  };

  onLocationSelect = (option: any) => {
    this.setState({
      selectedScanLocations: option,
    });
  };

  onWaitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const wait = parseInt(e.currentTarget.value, 10);
    this.setState({
      wait,
    });
  };

  onUserAgentSelect = (selection: IUserAgent) => {
    this.setState({
      selectedUserAgent: selection.userAgent,
    });
  };
  onClearUrls = () => {
    this.setState({
      renderTimestamp: moment().valueOf(),
      urls: [],
    });
  };

  shouldComponentUpdate(
    nextProps: Readonly<IBulkScanFormProps>,
    nextState: Readonly<IBulkScanFormState>,
  ): boolean {
    return (
      this.props.user.email !== nextProps.user.email ||
      this.props.isLoading !== nextProps.isLoading ||
      this.state.selectedScanLocations !== nextState.selectedScanLocations ||
      this.state.locationOptions?.length !== nextState.locationOptions?.length ||
      this.state.wait !== nextState.wait ||
      !_.isEqual(this.state.urls, nextState.urls) ||
      this.props.allUserAgents !== nextProps.allUserAgents ||
      this.state.selectedUserAgent !== nextState.selectedUserAgent ||
      this.state.renderTimestamp !== nextState.renderTimestamp ||
      this.props.isTorChecked !== nextProps.isTorChecked
    );
  }

  render() {
    const { isLoading, allUserAgents } = this.props;
    const groupedUserAgents: any = {};

    if (
      !this.brandScanPreference?.scanUserAgentId &&
      this.brandScanPreference?.scanUserAgent &&
      this.state.selectedUserAgent &&
      allUserAgents.length > 0
    ) {
      groupedUserAgents['Custom'] = [
        {
          id: 0,
          userAgent: this.brandScanPreference?.scanUserAgent,
          description: 'Custom User Agent',
          label: this.brandScanPreference?.scanUserAgent,
        },
      ];
    }
    if (allUserAgents?.length > 0) {
      allUserAgents.forEach((element: IUserAgent) => {
        if (groupedUserAgents[element.label]) {
          groupedUserAgents[element.label].push({
            ...element,
            label: element.userAgent,
          });
        } else {
          groupedUserAgents[element.label] = [
            {
              ...element,
              label: element.userAgent,
            },
          ];
        }
      });
    }

    const { urls, selectedScanLocations, locationOptions, wait, renderTimestamp } = this.state;
    const limit = SCAN_LIMIT;
    const counts = urls.length;

    return (
      <Form className='bulk-scan-form' key={renderTimestamp}>
        {locationOptions.length > 0 ? (
          // TODO: add setting components
          <>
            <div>Filter </div>
            <div className='bulk-scan-settings d-flex align-items-center'>
              <div className='drop-down-fields-wrapper'>
                <div className='form-label'>Scanner IP Location</div>
                <div className={'form-block'}>
                  <DropdownMultiSelect
                    options={locationOptions}
                    onSubmit={this.onLocationSelect}
                    initialSelections={selectedScanLocations}
                    renderDom={Country}
                    renderDomKeys={[{ countryCode: 'value' }, { countryName: 'label' }]}
                    updateOnChange
                    hideFooter
                    removable
                    limit={2}
                    defaultText='Default'
                    type={DropdownTypeValues.location}
                    disabled={this.props.isTorChecked}
                  />
                </div>
              </div>
              <div className='drop-down-fields-wrapper'>
                <div className='form-label'>User Agent</div>
                <div className={'form-block'}>
                  <Dropdown
                    defaultSelection={{
                      label: this.state.selectedUserAgent,
                      value: this.state.selectedUserAgent,
                    }}
                    options={groupedUserAgents}
                    onChange={this.onUserAgentSelect}
                    boxStyle
                  />
                </div>
              </div>
              <div className='drop-down-fields-wrapper'>
                <div className='form-label'>Timeout for Page Scan</div>
                <div className={'form-block wait-range-container d-flex align-items-center'}>
                  <input
                    type='range'
                    min={DEFAULT_WAIT}
                    max={MAX_WAIT}
                    className={'wait-range'}
                    step={STEP_SIZE}
                    value={wait}
                    onChange={this.onWaitChange}
                  />
                  <span className={'pl-2'}>{wait === DEFAULT_WAIT ? 'Auto' : wait + 'ms'}</span>
                </div>
              </div>
              <div className='drop-down-fields-wrapper'>
                <div
                  id='bulk-scan-reset-scan-setting'
                  className='reset-button'
                  onClick={this.resetScanSettings}
                >
                  Reset All
                </div>
              </div>
            </div>
          </>
        ) : null}
        <Form.Group controlId='bulkScan.scanUrls'>
          <Form.Label>Please enter a new url on each line</Form.Label>
          <Form.Label className={'limit'}>
            (Limit {counts}/{limit})
          </Form.Label>
          <Form.Control
            as='textarea'
            rows={limit}
            className='bulk-scan-text-area'
            onChange={this.onTextAreaChange}
            placeholder='Enter website URLs to scan for phishing checks'
          />
        </Form.Group>
        <div className='button-containers'>
          <Button
            id='bulk-scan-submit-button'
            variant='primary'
            onClick={this.submitScans}
            disabled={counts < 1 || counts > 10 || isLoading || selectedScanLocations.length === 0}
          >
            {isLoading ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : (
              'Scan'
            )}
          </Button>
          <div id='bulk-scan-clear-all-button' className='clear-button' onClick={this.onClearUrls}>
            Clear All
          </div>
        </div>
      </Form>
    );
  }
}

export { BulkScanForm };

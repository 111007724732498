import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import { TableContextProvider } from '../Common/Table/table.context';
import { ITableDataColumn, TableData } from '../Common/Table/table.data';
import { TExportTable } from '../Common/Table/constant';
import { ASSETS_TABLE_NAME, MANAGED_BY_BOLSTER } from './constants';
import AssetsService from '../../services/assests.service';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { BRAND_NAME_COLUMN, COUNTRY_COL } from './AssetsColDefination/ManagedByOrgCols';
import {
  ACQUISITION_STATUS_COL,
  DOMAIN_COL,
  IP_ADDRESS_COL,
  NOTES_COL,
  PRICE_COL,
  REGISTRATION_DATE_COL,
  RENEWAL_DATE_COL,
} from './AssetsColDefination/ManagedByBolsterCol';
import { REGISTRAR_COLUMN } from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import { getCSVFile } from './util';
import { SummaryWidget } from '../Common/SummaryWidget';
import AddCreditModal from '../Common/AddCreditModal';
import { AppState } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setIsAgGridClientMode } from '../../reducers/table.reducer';
import { getDisplayTimeFromTimeStamp, isBrandReadOnlyUser } from '../../constants';

const assetsService = new AssetsService();

const TABLE_ID = MANAGED_BY_BOLSTER;

interface IManagedByBolster {}
const ManagedByBolster = (props: IManagedByBolster): JSX.Element => {
  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);

  const TABLE_COLUMNS: any[] = [
    DOMAIN_COL,
    IP_ADDRESS_COL,
    BRAND_NAME_COLUMN,
    REGISTRAR_COLUMN,
    COUNTRY_COL,
    REGISTRATION_DATE_COL,
    ACQUISITION_STATUS_COL,
    PRICE_COL,
    NOTES_COL,
    RENEWAL_DATE_COL,
  ];
  window.document.title = 'Managed By Bolster | Assets';
  const [allPurchasedAssets, setAllPurchasedAssets] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showAddCredit, setShowAddCredit] = useState(false);
  const summaryData: any = [
    {
      id: 'creditBalance',
      label: 'Available Credit',
      tooltip: 'Available Credit',
      value: '',
      dateDescription: 'Last Bundle Purchased: ',
      dateId: 'lastCreditPurchaseActivity',
      addButtonDom: !isBrandReadOnlyUser(user) ? (
        <div
          className='summary-detail-top-button'
          onClick={() => {
            setShowAddCredit(true);
          }}
        >
          Add Credit
        </div>
      ) : null,
      type: 'amount',
    },
    {
      id: 'totalDomainPurchaseInvestment',
      label: 'Total Amount Invested',
      tooltip: 'Total Amount Invested',
      value: '',
      dateDescription: 'Last Updated:',
      dateId: 'lastDomainPurchaseActivity',
      type: 'amount',
    },
    {
      id: 'totalDomainsAcquired',
      label: 'Total Domains Acquired',
      tooltip: 'Total Domains Acquired',
      value: '',
      dateId: null,
    },
    {
      id: 'expiringDomains_30',
      label: 'Total Domains up for Renewal',
      tooltip: 'Total Domains up for Renewal',
      value: '',
      dateDescription: 'Expiration Date: 30 days or less',
      dateId: null,
    },
  ];
  const [summaryDetails, setSummaryDetails] = useState<any[]>([]);

  const mountedRef = useRef(false);
  const exportCSVTitle = `Bolster Assets Managed By Bolster ${moment().format('YYYYMMDDhhmmss')}`;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, [dispatch]);

  useEffect(() => {
    mountedRef.current = true;
    getAllPurchasedAssets();
    getDomainAcquisitionSummary();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getAllPurchasedAssets = () => {
    setShowLoader(true);
    assetsService
      .getAllPurchasedAssetsByBolster()
      .then(res => {
        const urlParams = new URLSearchParams(window.location.search);
        const targetKey = urlParams.get('targetKey') || 'url';
        const targetValues = urlParams.get('targetValues');
        const targetValuesArr = targetValues?.split(',') || [];
        if (targetKey && targetValuesArr) {
          res = _.orderBy(res, ['updated_ts'], ['desc']);
        }
        const data = res.map((asset: any) => {
          const { brand } = asset;
          let isNewAdded = false;
          if (targetKey && targetValuesArr) {
            isNewAdded = targetValuesArr.indexOf(asset[targetKey]) !== -1;
          }
          return {
            brand: brand?.brand_display_name || '--',
            brand_id: brand?.brand_id || '--',
            acquisition_status: asset.acquisition_status || '--',
            created_ts: getDisplayTimeFromTimeStamp(asset.created_ts) || '--',
            country: asset.country || '--',
            domain_name: asset.domain_name || '--',
            ip_address: asset.ip_address || '--',
            is_available: asset.is_available || '--',
            notes: asset.notes || '--',
            price: asset.price || '--',
            registrar: asset.registrar || '--',
            registration_date: asset.registration_date || '--',
            related_brand_id: asset.related_brand_id || '--',
            renewal_date: getDisplayTimeFromTimeStamp(asset.renewal_date) || '--',
            updated_ts: asset.updated_ts || '--',
            isNewAdded,
            domain_sha256: asset.domain_sha256,
          };
        });
        if (mountedRef.current) {
          setShowLoader(false);
          setAllPurchasedAssets(data);
        }
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getDomainAcquisitionSummary = () => {
    setShowLoader(true);
    assetsService
      .getDomainAcquisitionSummary()
      .then(res => {
        const newSummary: any[] = [];
        summaryData.forEach((summary: any) => {
          const obj = {
            ...summary,
            value: summary.type === 'amount' ? `$${res[summary.id]}` : res[summary.id],
            dateDescription: res.hasOwnProperty(summary.dateId)
              ? `${summary.dateDescription} ${
                  res[summary.dateId] ? moment(res[summary.dateId]).format('MM/DD/YYYY') : '--'
                }`
              : summary.dateDescription,
          };
          newSummary.push(obj);
        });
        setSummaryDetails(newSummary);
      })
      .catch(error => {
        console.log(error, 'error');
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const exportAssets = (data: any, columns: ITableDataColumn[], type: TExportTable) => {
    getCSVFile(data, columns, exportCSVTitle);
  };

  const onSubmitAddCredit = (data: any): Promise<void> => {
    return assetsService.addCredit(data).finally(() => {
      setShowAddCredit(false);
    });
  };

  return (
    <AuthenticationWrapper>
      <div className={'table-page page-content'}>
        <Card className='table-container'>
          <TableContextProvider
            columns={TABLE_COLUMNS}
            dashboardName={ASSETS_TABLE_NAME}
            tableId={TABLE_ID}
            tableIndex={'brand_asset'}
          >
            <TableData
              id={TABLE_ID}
              tableIndex={'brand_asset'}
              title={`Managed By Bolster`}
              data={allPurchasedAssets}
              columns={TABLE_COLUMNS}
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              exportFn={exportAssets}
              indexBy={'domain_name'}
              disableDatePicker
              showLoader={showLoader}
              summaryWidget={
                <SummaryWidget
                  isLoading={showLoader}
                  data={summaryDetails}
                  customClassName={'mx-0'}
                />
              }
              user={user}
            />
          </TableContextProvider>
        </Card>
      </div>
      <AddCreditModal
        show={showAddCredit}
        onModalSubmit={onSubmitAddCredit}
        onCloseModal={() => setShowAddCredit(false)}
      />
    </AuthenticationWrapper>
  );
};

export default ManagedByBolster;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import '../Playbook/Style/playbook.scss';
import Card from 'react-bootstrap/Card';
import { NewConnectorModal } from './newConnectorModal';
import { alertActions } from '../../actions';

import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setIsAgGridClientMode } from '../../reducers/table.reducer';
import { TableContextProvider } from '../Common/Table/table.context';
import { TableData } from '../Common/Table/table.data';
import {
  deleteConnector,
  fetchConnectorTypes,
  fetchConnectors,
  massageConnectors,
  massagedConnectorTypes,
  updateConnector,
} from './connector-requests';
import {
  CONNECTOR_COL_CREATED_AT,
  CONNECTOR_COL_CREATED_BY,
  CONNECTOR_COL_DETAILS,
  CONNECTOR_COL_NAME,
  CONNECTOR_COL_TYPE,
} from './ConnectorColDefs';
import ConnectorActionColRenderer from './ConnectorActionColRenderer';
import { ITableApiColumn } from '../Common/Table/table.api';
import { setConnectorTypes } from '../../reducers/playbook.reducer';
import { CONNECTOR_TABLE_ID } from '../Assets_v2/constants';
import { integrationDocsSufixMap, simpliedIntegrationNameMap } from '../../constants';

export interface IConnector {
  id: string;
  name: string;
  type: string;
  connectorTypeId: number;
  details: any;
  selectedIntegration?: string;
}

export enum EConnectorUpdateType {
  UPDATED = 'updated',
  ADDED = 'added',
}

interface IConnectorsProps {}

const Connectors = (props: IConnectorsProps) => {
  window.document.title = 'Connectors | Automation';
  const [editingConnector, setEditingConnector] = useState<any>();
  const [connectorsData, setConnectorData] = useState<IConnector[]>([]);
  const [modalShown, setModalShown] = useState<boolean>(false);

  const connectorTypes = useAppSelector(state => state.playbookReducer.connectorTypes);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, [dispatch]);

  useEffect(() => {
    try {
      Promise.allSettled([fetchConnectors(), fetchConnectorTypes()])
        .then(allResponses => {
          const [connectors, connectorTypes] = allResponses.map((result: any) => result.value);
          setConnectorData(massageConnectors(connectors?.result || []));
          dispatch(setConnectorTypes(massagedConnectorTypes(connectorTypes?.result || [])));
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      dispatch(alertActions.error('Failed to fetch connectors data'));
    }
  }, [dispatch, setConnectorData, setConnectorTypes]);

  const toggleModal = useCallback(() => {
    setModalShown(!modalShown);
  }, [modalShown]);

  const onModalSummit = useCallback(async values => {
    const action = values.id ? EConnectorUpdateType.UPDATED : EConnectorUpdateType.ADDED;
    try {
      await updateConnector(values);
      dispatch(alertActions.success(`Successfully ${action} connector '${values.name}'`));
      void fetchMassagedConnectors();
    } catch (error) {
      dispatch(alertActions.error('Updating connector failure, ' + JSON.stringify(error)));
    }
  }, []);

  const onDeleteConnector = useCallback(
    async connector => {
      try {
        const response = await deleteConnector(connector.id);
        console.log(response);
        void fetchMassagedConnectors();
        dispatch(alertActions.success(`Successfully deleted connector '${connector.name}'`));
      } catch (error) {
        dispatch(alertActions.error('Deleting connector failure, ' + JSON.stringify(error)));
      }
    },
    [dispatch],
  );

  const fetchMassagedConnectors = async () => {
    const connectors = await fetchConnectors();
    setConnectorData(massageConnectors(connectors?.result || []));
  };

  const modifyColumns = async (columns: ITableApiColumn[]) => {
    const typeColumn = _.find(columns, { id: 'type' });
    if (typeColumn) {
      typeColumn.filterOptions = connectorTypes
        .map((connectorType: any) => {
          return {
            label: connectorType.label,
            value: connectorType.value,
          };
        })
        .concat(
          Object.keys(integrationDocsSufixMap)
            .filter(k => !simpliedIntegrationNameMap[k])
            .map(key => ({ label: key, value: key })),
        );
    }
    return columns;
  };

  const columns = useMemo(() => {
    return [
      CONNECTOR_COL_NAME,
      CONNECTOR_COL_TYPE,
      CONNECTOR_COL_CREATED_BY,
      CONNECTOR_COL_CREATED_AT,
      {
        ...CONNECTOR_COL_DETAILS,
        render: (data: any) => {
          return (
            <ConnectorActionColRenderer
              data={data}
              setEditingConnector={setEditingConnector}
              onDeleteConnector={onDeleteConnector}
              toggleModal={toggleModal}
            />
          );
        },
      },
    ];
  }, [connectorTypes, onDeleteConnector, toggleModal]);

  const editingConnectorById = useMemo(() => {
    return connectorsData.find(connector => connector.id === editingConnector?.id);
  }, [connectorsData, editingConnector]);

  if (editingConnectorById?.type === 'Exchange') {
    editingConnectorById.selectedIntegration = 'Microsoft 365 Exchange/Defender';
  }
  const initialToken =
    editingConnectorById?.details?.header?.find(
      (header: any) => header.parameter === 'Authorization',
    )?.value || '';

  if (editingConnectorById && initialToken) {
    // senitel for those who has extra token
    editingConnectorById.selectedIntegration = 'Cortex XSOAR';
    editingConnectorById.details.extraToken = initialToken;
  }

  return (
    <>
      <div className={'connectors-page page-content'}>
        <Card className='connectors-table-container'>
          <TableContextProvider
            columns={columns as any}
            dashboardName={'Connectors'}
            tableId={CONNECTOR_TABLE_ID}
            modifyColumns={modifyColumns}
            tableIndex={'type'}
          >
            <TableData
              id={CONNECTOR_TABLE_ID}
              tableIndex='type'
              title={'Implemented Connectors'}
              disableDatePicker
              data={connectorsData}
              columns={columns as any}
            />
          </TableContextProvider>
        </Card>
      </div>
      {modalShown && (
        <NewConnectorModal
          show={modalShown}
          toggleModal={toggleModal}
          onSubmit={onModalSummit}
          initValues={editingConnectorById}
        />
      )}
    </>
  );
};

export { Connectors };

import React from 'react';
import _ from 'lodash';
import 'bootstrap/dist/css/bootstrap.min.css';
import './drawer.scss';

import { Slack } from '../Playbook/slack.component';
import { Switch, Route, Redirect } from 'react-router-dom';
import { BulkScan } from '../BulkScan/bulkscan.component';
import { Dashboard } from '../Dashboard/dashboard.component';
import { Feedback } from '../Feedback/feedback';
import { Profile } from '../Profile/profile.component';
import { LandingPage } from '../Profile/LandingPage';
import { Insights } from '../Insights/insights.component';
import { IpInsights } from '../Insights/ip.insights.component';
import { DomainInsights } from '../Insights/domain.insights.component';
import { Whois } from '../WhoIsInfo/whois.component';
import { DomTree } from '../DomTree/domTree.component';
import { Timeline } from '../Timeline/timeline.component';
import { Acquisitions } from '../MonitorAndTakedown/acquisitions.component';
import PreMalicious from '../MonitorAndTakedown/pre.malicious.component';
import TakedownMalicious from '../MonitorAndTakedown/takedown.malicious.component';
import PostMalicious from '../MonitorAndTakedown/post.malicious.component';
import { Reports } from '../Reports/Reports';
import { WebPlaybook } from '../Playbook/Components/WebPlaybook';
import { PlaybookInsight } from '../Playbook/Common/PlaybookInsights';
import { Connectors } from '../Connectors/connectors.component';
import { SuperAdmin } from '../SuperAdmin/SuperAdmin';
import { ChangePassword } from '../Profile/changepass.component';
import { PRICING_PAGE_PATH } from '../Pricing/constants';
import { connect } from 'react-redux';
import { appActions, dashboardActions, userActions } from '../../actions';
import { AppState, history } from '../../helpers';
import { AllScans } from '../Checkphish/AllScans/AllScans';
import { DashboardContainer as DashboardContainerCP } from '../Checkphish/DashboardCP/DashboardContainer';
import { DomainAcquisition } from '../DomainMonitoring/DomainAcquisition';
import { NotificationsSetting } from '../Profile/NotificationsSetting';
import PricingPage from '../Pricing/PricingPage';

import {
  DashBoardConfigDto,
  DashBoardDto,
  isAdminUser,
  IBrandInfo,
  BrandType,
  appConstants,
  IAppDispatchProps,
  featureIsAvailable,
  userDoesNotHasTyposquatData,
  isBrandReadOnlyUser,
} from '../../constants';
import { GlobalAlert } from '../GlobalAlert/GlobalAlert';
import { Release } from '../Release/Release';
import { DashboardContainer } from '../Dashboard_v2/SubComponents/DashboardContainer';
import ManagedByBolster from '../Assets_v2/ManagedByBolster';
import ManagedByOrganization from '../Assets_v2/ManagedByOrganization';
import SocialMediaDashboard from '../SocialMedia/Components/Dashboard/SocialMediaDashboard';

import {
  SOCIAL_MEDIA_PATHNAME,
  SOCIAL_MEDIA_DASHBOARD_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
  SOCIAL_MEDIA_SEARCH_TERMS,
} from '../SocialMedia/constants';
import {
  POST_MALICIOUS_PATHNAME,
  PRE_MALICIOUS_PATHNAME,
  TAKEDOWN_MALICIOUS_PATHNAME,
  ACQUISITIONS_PATHNAME,
  MONITOR_AND_TAKEDOWN_PATHNAME,
  WEB_DASHBOARD_PATHNAME,
  MANAGE_EMAIL_TEMPLATES_PATH,
  MANAGE_EMAIL_TEMPLATES_CREATE_PATH,
  MANAGE_EMAIL_TEMPLATES_UPDATE_PATH,
  WEB_INITIATE_TAKEDOWN_PATHNAME,
  PRE_MALICIOUS_TABLE_ID,
  USER_GLOBAL_TAKEDOWN_PATHNAME,
  // POST_MALICIOUS_TABLE_ID,
  // TAKEDOWN_MALICIOUS_TABLE_ID,
} from '../MonitorAndTakedown/constants';
import { CHECKPHISH_DRAWER_SUBITEM } from './RouteOptions';
import TopNavigationBar from './TopNavigationBar';
import {
  ACCOUNT_SUB_ITEMS,
  DRAWER_SUB_ITEMS,
  EMenuType,
  NAV_ITEMS_V2,
  typosquattingDomainFeatureIsAvailable,
} from './RouteOptions';
import SideBarV2 from './SideBar_v2';
import Logout from '../Auth/Logout';
import ThemeContext from '../../context/ThemeContext';
import {
  MARKET_PLACE_DASHBOARD_PATHNAME,
  MARKET_PLACE_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_REVIEW_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  MARKET_PLACE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME,
  MARKET_PLACE_PATHNAME_SEARCH_TERMS,
} from '../Marketplace/constants';
import MarketPlaceDashboard from '../Marketplace/Components/Dashboard/MarketPlaceDashboard';
import MarketplaceDetections from '../Marketplace/Components/Findings/MarketplaceDetections';
import MarketplaceTakedownInProgress from '../Marketplace/Components/Findings/MarketplaceTakedownInProgress';
import MarketplaceTakenDown from '../Marketplace/Components/Findings/MarketplaceTakenDown';
import MarketplaceSafelist from '../Marketplace/Components/Findings/MarketplaceSafelist';
import MarketplaceSearchTerms from '../Marketplace/Components/SearchTerms/MarketplaceSearchTerms';
import MarketplaceReview from '../Marketplace/Components/Findings/MarketplaceReview';
import SocialMediaDetections from '../SocialMedia/Components/Findings/SocialMediaDetections';
import SocialMediaTakenDown from '../SocialMedia/Components/Findings/SocialMediaTakenDown';
import SocialMediaTakendownInProgress from '../SocialMedia/Components/Findings/SocialMediaTakendownInProgress';
import SocialMediaSafeList from '../SocialMedia/Components/Findings/SocialMediaSafeList';
import SocialMediaReview from '../SocialMedia/Components/Findings/SocialMediaReview';
import SocialMediaSearchTerm from '../SocialMedia/Components/Searchterm/SocialMediaSearchTerm';
import SocialFindingInsights from '../SocialMedia/Components/Findings/SocialFindingInsights';
import MarketplaceInsights from '../Marketplace/Components/Findings/MarketplaceInsights';
import {
  APP_STORE_DASHBOARD_PATHNAME,
  APP_STORE_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  APP_STORE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME,
  APP_STORE_PATHNAME_SEARCH_TERMS,
} from '../AppStore/constants';
import AppStoreDashboard from '../AppStore/Components/Dashboard/AppStoreDashboard';
import AppStoreDetections from '../AppStore/Components/Findings/AppStoreDetections';
import AppStoreTakendownInProgress from '../AppStore/Components/Findings/AppStoreTakendownInProgress';
import AppStoreTakenDown from '../AppStore/Components/Findings/AppStoreTakendown';
import AppStoreSafeList from '../AppStore/Components/Findings/AppStoreSafeList';
import AppStoreInsights from '../AppStore/Components/Findings/AppStoreInsights';
import AppStoreIPInsights from '../AppStore/Components/Findings/AppStoreIPInsights';
import AppStoreSearchTerms from '../AppStore/Components/SearchTerms/AppStoreSearchTerms';
import {
  DARK_WEB_DASHBOARD_PATHNAME,
  DARK_WEB_FINDING_DETECTION_TABLE_ID,
  DARK_WEB_IGNORED_DETECTION_TABLE_ID,
  DARK_WEB_MITIGATED_DETECTION_TABLE_ID,
  DARK_WEB_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME,
  DARK_WEB_PATHNAME_SEARCH_TERMS,
  DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME,
} from '../DarkWeb/DarkWebConstants';
import DarkWebDashboard from '../DarkWeb/Components/Dashboard/DarkWebDashboard';
import { DarkWebDashboardV2 } from '../DarkWeb_v2/Components/Dashboard/Dashboard';
import DarkWebThreatActors from '../DarkWeb/Components/ThreatActors/DarkWebThreatActors';
import DarkWebSearchTerms from '../DarkWeb/Components/SearchTerms/DarkWebSearchTerms';
import DarkWebInsights from '../DarkWeb/Components/Findings/Insights/DarkWebInsights';
import ThreatActorInsights from '../DarkWeb/Components/ThreatActors/Insights/ThreatActorInsights';
import LogosAssets from '../Assets_v2/LogosAssets';
import DocumentAssets from '../Assets_v2/DocumentAssets';
import TrademarkAssets from '../Assets_v2/TrademarkAssets';
import SocialMediaAssets from '../Assets_v2/SocialMediaAssets';
import MarketplaceAssets from '../Assets_v2/MarketplaceAssets';
import AppStoreAssets from '../Assets_v2/AppStoreAssets';
import { DarkWebPlaybook } from '../Playbook/Components/DarkWebPlaybook';
import { AppStorePlaybook } from '../Playbook/Components/AppStorePlaybook';
import SocialMediaPlaybook from '../Playbook/Components/SocialMediaPlaybook';
import SocTakedownRequestPage from '../SocTakedown/soc-takedown-request-page.component';
import SocTakedownManageTemplates from '../SocTakedown/soc-takedown-manage-templates.component';
import { HideSideBarPaths } from './RouteOptions';
import InsightsContainer from '../InsightsContainer/InsightsContainer';
import { ThreatIntelligence } from '../ThreatIntelligence/threat.component';
import TeamMembers from '../TeamMembers/TeamMembers';
import { TypoDashboard } from '../DomainMonitoring/TyposquatDashboard';
import TypoDashboardV2 from '../DomainMonitoring/TyposquatDashboardV2';
import { TyposquatPage } from '../DomainMonitoring/TyposquatPage';
import { setLastVisitedPage } from '../../helpers/url-helpers';
import {
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME,
  TYPOSQUATTING_DOMAIN_MONITORING,
  TYPOSQUATTING_MASTER_PATHNAME,
  TYPOSQUATTING_PATHNAME,
} from '../DomainMonitoring/constant';
import ManagedByAffiliated from '../Assets_v2/ManagedByAffiliated';
import { ActiveOUContextProvider } from '../../context/ActiveOUContext';
import UserTimeoutOverlay from '../Common/UserTimeoutOverlay';
import AppSettingContext from '../../context/AppSettingContext';
import CommunicationSettings from '../Profile/CommunicationSettings/CommunicationSettings';
import DarkWebFindingsTable from '../DarkWeb/Components/Findings/DarkWebFindingsTable';
import DarkWebFindingsV2 from '../DarkWeb_v2/Components/Findings';
import { EDarkWebFindingStatus } from '../DarkWeb/Components/Types/DarkWeb.types';
import { fetchAllPlatformTags } from '../Common/Tags/tag-request';
import { setAllPlatformTags } from '../../reducers/tags.reducer';
import { ITags } from '../Common/Tags/Tags';
import GlobalTakedown from '../GlobalTakedown/GlobalTakedown';
import UgcInsightsContainer from '../Ugc/Components/Findings/Insight/UgcInsightsContainer';
import LockedPage from './CheckPhishV2/LockedPage';
import LogoPage from './CheckPhishV2/LogoPage';
import { Integrations } from '../Integrations/Integrations';

export enum FIRST_LOAD_ACTION_VALUES {
  TYPOSQUAT = 'typosquat',
  DOMAIN_ACQUISITION = 'domain_acquisition',
  RESET = 'reset',
}

export type FIRST_LOAD_ACTION_VALUES_TYPES =
  | FIRST_LOAD_ACTION_VALUES.TYPOSQUAT
  | FIRST_LOAD_ACTION_VALUES.DOMAIN_ACQUISITION
  | FIRST_LOAD_ACTION_VALUES.RESET;

interface IFirstLoad {
  typosquat: boolean;
  domain_acquisition: boolean;
}

export interface INavSubItem {
  id: string;
  label: string;
  href: string;
  parent: string;
  subItems?: INavSubItem[];
  renderDom?: any;
  isDefault?: boolean;
  hidden?: boolean;
  featureCode?: string | string[];
  disabled?: boolean;
  adminOnly?: boolean;
  internalOnly?: boolean;
  webOnly?: boolean;
  featureReleased?: boolean;
}

export interface INavItem {
  icon: any;
  iconActive: any;
  label: string;
  parentLabel: string;
  type: EMenuType;
  id: string;
  href?: string;
  subItems?: INavSubItem[];
  webOnly?: boolean;
  hidden?: boolean;
  featureCode?: string | string[];
  disabled?: boolean;
  isNew?: boolean;
}

interface IAppWrapperState {
  isSideBarMobileOpen: boolean;
  resetModalShown: boolean;
  navHeader: string;
  selectedPageNavItems: any[];
  prevPath: string;
  activeTypoJob: boolean;
  typosquatFirstLoad: IFirstLoad;
}

interface IRouteGuardProps {
  component?: any;
  render?: any;
  exact?: boolean;
  path: string;
  type: BrandType;
  breakRouteCondition?: boolean;
  typosquatV2Enabled?: boolean;
  user?: any;
}
interface IContextProps {
  getParentRouteViaFindingStatus?: (status: string) => void;
}

type Props = ILinkDispatchProps & ILinkStateProp & IContextProps & IAppDispatchProps;

// Route guard for guarding brand and non_brand users
const GuardRouteByType = ({
  component,
  path,
  type,
  render,
  exact,
  breakRouteCondition,
  user,
  ...props
}: IRouteGuardProps): JSX.Element => {
  const { type_name: userBrandType } = user;
  breakRouteCondition =
    breakRouteCondition === undefined || breakRouteCondition === null ? true : breakRouteCondition;
  const typosquatDataNotPresent = userDoesNotHasTyposquatData(user);
  if (userBrandType !== type || !breakRouteCondition) {
    return (
      //if typosquat is enabled but not set is set for the user then send it to typosquat table directly
      <Redirect
        to={
          userBrandType === 'brand'
            ? '/web/dashboard'
            : typosquatDataNotPresent
            ? TYPOSQUATTING_MASTER_PATHNAME
            : TYPOSQUATTING_DASHBOARD_PATHNAME
        }
      />
    );
  }
  if (path === TYPOSQUATTING_DASHBOARD_PATHNAME && typosquatDataNotPresent) {
    return <Redirect to={TYPOSQUATTING_MASTER_PATHNAME} />;
  }
  return <Route {...props} path={path} component={component} render={render} exact={exact} />;
};

class AppWrapper extends React.Component<Props, IAppWrapperState> {
  static contextType = ThemeContext;
  private initialFirstLoadValue: IFirstLoad = {
    typosquat: true,
    domain_acquisition: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isSideBarMobileOpen: false,
      resetModalShown: false,
      navHeader: 'Web',
      selectedPageNavItems: [],
      prevPath: window.location.pathname,
      activeTypoJob: false, //is typosquat job active.
      typosquatFirstLoad: this.initialFirstLoadValue,
    };
    this.initCalls();
  }

  initCalls() {
    this.props.getUserInfo();
    this.props.getDashboardConfig();
    this.props.runByClientApp({
      onBolster: async () => {
        this.props.getEnterpriseAppConfig();
        this.props.getBrandInfo('brand');
        this.props.getUsersList();
        const tags = await fetchAllPlatformTags();
        this.props.setAllPlatformTags(tags);
      },
      onCheckPhish: () => {
        this.props.getCheckphishAppConfig();
      },
    });
  }

  componentDidMount() {
    this.onHandleNavigationState();
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.location?.pathname !== window.location.pathname) {
      setLastVisitedPage(prevProps.location?.pathname);
      this.setState({ prevPath: prevProps.location?.pathname });
    }
    if (prevState.prevPath !== this.state.prevPath) {
      this.onHandleNavigationState();
    }
    if (prevState.activeTypoJob !== this.state.activeTypoJob) {
      const { setTyposquatDataPresent } = this.props;
      setTyposquatDataPresent(this.state.activeTypoJob);
      this.setState((oldState: any) => {
        const updatedNavItems = this.updateNavOnTyposquat(
          oldState.selectedPageNavItems,
          'checkphish',
          userDoesNotHasTyposquatData(this.props.user) ? 'typosquat' : 'dashboard',
        );
        return {
          ...oldState,
          selectedPageNavItems: updatedNavItems,
        };
      });
    }

    if (window.location.pathname.includes(MONITOR_AND_TAKEDOWN_PATHNAME)) {
      const newPath = window.location.pathname.replace(
        MONITOR_AND_TAKEDOWN_PATHNAME,
        WEB_DASHBOARD_PATHNAME,
      );
      history.push(newPath);
    }
    // redirecting old public insights page to new insights page
    // if (window.location.pathname.includes('/public/insights/')) {
    //   const newPath = window.location.pathname.replace(
    //     '/public/insights',
    //     `/bulk-scan/insights`,
    //   );
    //   history.push(newPath);
    // }
    if (prevProps.location?.pathname && prevProps.location?.pathname !== window.location.pathname) {
      this.onHandleNavigationState();
      window.history.pushState({ prevUrl: prevProps.location?.pathname }, '', '');
    }
    if (prevProps.user.type_name !== this.props.user.type_name) {
      this.onHandleNavigationState();
    }
    if (prevProps.user?.features !== this.props.user?.features) this.onHandleNavigationState();
  }

  changeActiveTypoJobStatus = (status: boolean) => {
    this.setState({
      activeTypoJob: status,
    });
  };

  changeTyposquatFirstLoad = (action: FIRST_LOAD_ACTION_VALUES_TYPES, updatedVal: boolean): any => {
    if (action == FIRST_LOAD_ACTION_VALUES.RESET) {
      return this.setState({ typosquatFirstLoad: { typosquat: true, domain_acquisition: true } });
    }
    const stateValue = this.state.typosquatFirstLoad;
    stateValue[action] = updatedVal;
    this.setState({ typosquatFirstLoad: stateValue });
  };

  onHandleNavigationState = () => {
    const { runByClientApp } = this.props;
    const activeUrl = window.location.pathname.split('/')[1];
    const isCommunity = runByClientApp({
      onBolster: () => false,
      onCheckPhish: () => true,
    });
    let parentLabel = '';
    const { user } = this.props;
    const userHasTyposquatEnabled: boolean = userDoesNotHasTyposquatData(user);
    if (isCommunity) {
      parentLabel =
        activeUrl === 'checkphish' || activeUrl === 'bulk-scan' ? 'CheckPhish-Scan' : 'CheckPhish';
    } else {
      [
        ...NAV_ITEMS_V2(user, false, userDoesNotHasTyposquatData(user)),
        ...ACCOUNT_SUB_ITEMS(user),
      ].forEach((item: any) => {
        const { href, subItems } = item;
        if (href?.split('/').includes(activeUrl)) {
          parentLabel = item.parentLabel;
        } else if (subItems?.length) {
          for (let i = 0; i < subItems?.length; i++) {
            if (subItems[i].href?.split('/').includes(activeUrl)) {
              parentLabel = subItems[i].parentLabel;
              break;
            }
          }
        }
      });
    }
    this.setState({
      navHeader: parentLabel,
      selectedPageNavItems: isCommunity
        ? CHECKPHISH_DRAWER_SUBITEM(userHasTyposquatEnabled)
        : this.updateNavOnTyposquat(
            DRAWER_SUB_ITEMS(user, userHasTyposquatEnabled, isCommunity as boolean)[parentLabel],
            'checkphish',
            userHasTyposquatEnabled
              ? 'typosquat'
              : userHasTyposquatEnabled
              ? 'typosquat'
              : 'dashboard',
          ),
    });
  };

  toggle = () => {
    this.setState({ isSideBarMobileOpen: !this.state.isSideBarMobileOpen });
  };

  onSelectedPage = (navItems: any[], header: string) => {
    this.setState({
      selectedPageNavItems: this.updateNavOnTyposquat(
        navItems,
        'checkphish',
        userDoesNotHasTyposquatData(this.props.user) ? 'typosquat' : 'dashboard',
      ),
      navHeader: header,
    });
  };

  updateNavOnTyposquat = (navItems: any, parentLabel: string, subItemLabel: string) => {
    const { runByClientApp } = this.props;
    const isCommunity = runByClientApp({
      onBolster: () => false,
      onCheckPhish: () => true,
    });
    if (isCommunity) return navItems;
    const { activeTypoJob } = this.state;
    let updatedNavItems = navItems;
    if (navItems && navItems.length > 0) {
      updatedNavItems = navItems?.map((navitem: any) => ({
        ...navitem,
        subItems: navitem.subItems.map((subitem: any) => {
          if (subitem?.parentLabel?.toLowerCase() === parentLabel.toLowerCase()) {
            if (activeTypoJob) {
              return {
                ...subitem,
                disabled: false,
              };
            }
            return {
              ...subitem,
              disabled: subitem?.label?.toLowerCase() !== subItemLabel.toLowerCase(),
            };
          } else {
            return { ...subitem };
          }
        }),
      }));
    }
    return updatedNavItems;
  };

  isSideBarHidden = () => {
    const {
      user: { type_name },
    } = this.props;
    if (type_name === 'non_brand') {
      return false;
    }
    if (HideSideBarPaths.find(x => window.location.pathname.match(x))) {
      return true;
    }
    return false;
  };

  signOut = () => {
    const { runByClientApp, logout, checkphishLogout } = this.props;
    const logoutFunc = runByClientApp({
      onBolster: () => logout,
      onCheckPhish: () => checkphishLogout,
    });
    return logoutFunc;
  };

  render() {
    const { selectedPageNavItems, navHeader } = this.state;
    const { selectedTheme } = this.context;
    const { selectedDashboard, user, runByClientApp, getUserInfo } = this.props;
    const webDashboardRoute = runByClientApp({
      onBolster: () => WEB_DASHBOARD_PATHNAME,
      onCheckPhish: () => TYPOSQUATTING_PATHNAME,
    });
    const webDashboardTableId = runByClientApp({
      onBolster: () => PRE_MALICIOUS_TABLE_ID,
      onCheckPhish: () => 'scanHistory',
    });
    const isCommunity = runByClientApp({
      onBolster: () => false,
      onCheckPhish: () => true,
    });
    const { type_name: userBrandType, first_name } = user;
    return (
      <div className={`app-wrapper ${selectedTheme}`}>
        {' '}
        <TopNavigationBar
          items={NAV_ITEMS_V2(
            user,
            isCommunity as boolean,
            userDoesNotHasTyposquatData(user),
          )?.filter((nav: any) => nav.label)}
          user={user}
          onDashboardSelected={this.props.onDashboardSelected}
          selectedDashboard={selectedDashboard}
          onSelectedPage={this.onSelectedPage}
          navHeader={navHeader}
          isCommunity={isCommunity as boolean}
          typosquatV2Enabled={userDoesNotHasTyposquatData(user)}
        />
        <div className='content-wrapper'>
          <SideBarV2
            hideSideBar={this.isSideBarHidden()}
            user={user}
            brandInfo={this.props.brandInfo}
            navItems={this.updateNavOnTyposquat(
              selectedPageNavItems,
              'checkphish',
              userDoesNotHasTyposquatData(user) ? 'typosquat' : 'dashboard',
            )}
            navHeader={navHeader}
            onSelectedPage={this.onSelectedPage}
            prevPage={this.state.prevPath}
            currentPage={window.location.pathname}
            isCommunity={isCommunity as boolean}
            typosquatV2Enabled={userDoesNotHasTyposquatData(user)}
            onChange={getUserInfo}
          />
          <div className='app-content'>
            <ActiveOUContextProvider>
              <Switch>
                {/* Routes available only to branded user */}
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/web/dashboard'
                  exact
                  component={
                    typosquattingDomainFeatureIsAvailable(user) ? DashboardContainer : Dashboard
                  }
                />
                {/*re-routing the old web/dashboard/insights page to bulkscan page*/}
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/web/dashboard/insights/url'
                  render={() => <Redirect to={'/bulk-scan'} />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={WEB_INITIATE_TAKEDOWN_PATHNAME}
                  component={SocTakedownRequestPage}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={MANAGE_EMAIL_TEMPLATES_CREATE_PATH}
                  render={() => <SocTakedownManageTemplates page={'create'} />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={MANAGE_EMAIL_TEMPLATES_UPDATE_PATH}
                  render={() => <SocTakedownManageTemplates page={'update'} />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={MANAGE_EMAIL_TEMPLATES_PATH}
                  render={() => <SocTakedownManageTemplates page={'landing'} />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/feedback'
                  component={Feedback}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/logos'
                  component={LogosAssets}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/documents'
                  component={DocumentAssets}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/trademarks'
                  component={TrademarkAssets}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/managed-by-bolster'
                  component={ManagedByBolster}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/managed-by-affiliates'
                  component={ManagedByAffiliated}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/official-account/social'
                  component={SocialMediaAssets}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/official-account/marketplace'
                  component={MarketplaceAssets}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/official-account/app-store'
                  component={AppStoreAssets}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/assets/managed-by-organization'
                  component={ManagedByOrganization}
                />
                <GuardRouteByType user={user} type={'brand'} path='/reports' component={Reports} />
                {!isBrandReadOnlyUser(user) && (
                  <GuardRouteByType
                    user={user}
                    type={'brand'}
                    path='/automation/playbooks/web-playbook'
                    component={WebPlaybook}
                  />
                )}
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/automation/playbooks/social'
                  component={SocialMediaPlaybook}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/automation/playbooks/app-store'
                  component={AppStorePlaybook}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/automation/playbooks/dark-web'
                  component={DarkWebPlaybook}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/automation/insights/id/'
                  component={PlaybookInsight}
                />

                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/playbook/slack'
                  render={() => <Redirect to='/integrations/slack' />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/integrations/slack'
                  component={Slack}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${ACQUISITIONS_PATHNAME}`}
                  exact
                  component={Acquisitions}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${PRE_MALICIOUS_PATHNAME}`}
                  component={PreMalicious}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MONITOR_AND_TAKEDOWN_PATHNAME}`}
                  render={() => <div></div>}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${TAKEDOWN_MALICIOUS_PATHNAME}`}
                  exact
                  component={TakedownMalicious}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${POST_MALICIOUS_PATHNAME}`}
                  exact
                  component={PostMalicious}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${USER_GLOBAL_TAKEDOWN_PATHNAME}`}
                  exact
                  component={GlobalTakedown}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_PATHNAME}`}
                  exact
                  component={SocialMediaDashboard}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_DASHBOARD_PATHNAME}`}
                  exact
                  component={SocialMediaDashboard}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME}`}
                  exact
                  component={SocialMediaDetections}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_PATHNAME}/insights/`}
                  render={() => <SocialFindingInsights />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME}`}
                  exact
                  component={SocialMediaTakendownInProgress}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME}`}
                  exact
                  component={SocialMediaTakenDown}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME}`}
                  exact
                  component={SocialMediaSafeList}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  breakRouteCondition={first_name !== null && isAdminUser(user)}
                  path={`${SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME}`}
                  exact
                  component={SocialMediaReview}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  breakRouteCondition={first_name !== null && isAdminUser(user)}
                  path={`${SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME}/insights/`}
                  render={() => <SocialFindingInsights />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_SEARCH_TERMS}`}
                  exact
                  component={SocialMediaSearchTerm}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${SOCIAL_MEDIA_PATHNAME}/dom`}
                  component={DomTree}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_PATHNAME}`}
                  exact
                  component={MarketPlaceDashboard}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_DASHBOARD_PATHNAME}`}
                  exact
                  component={MarketPlaceDashboard}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_PATHNAME_FINDINGS_DETECTION_PATHNAME}`}
                  exact
                  component={MarketplaceDetections}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME}`}
                  exact
                  component={MarketplaceTakedownInProgress}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME}`}
                  exact
                  component={MarketplaceTakenDown}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME}`}
                  exact
                  component={MarketplaceSafelist}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_PATHNAME_SEARCH_TERMS}`}
                  exact
                  component={MarketplaceSearchTerms}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  breakRouteCondition={first_name !== null && isAdminUser(user)}
                  path={`${MARKET_PLACE_PATHNAME_FINDINGS_REVIEW_PATHNAME}`}
                  exact
                  component={MarketplaceReview}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${MARKET_PLACE_PATHNAME}/insights/`}
                  render={() => <MarketplaceInsights />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME}`}
                  exact
                  component={AppStoreDashboard}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_DASHBOARD_PATHNAME}`}
                  exact
                  component={AppStoreDashboard}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME_FINDINGS_DETECTION_PATHNAME}`}
                  exact
                  component={AppStoreDetections}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME}`}
                  exact
                  component={AppStoreTakendownInProgress}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME}`}
                  exact
                  component={AppStoreTakenDown}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME}`}
                  exact
                  component={AppStoreSafeList}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME_SEARCH_TERMS}`}
                  exact
                  component={AppStoreSearchTerms}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME}/dom`}
                  component={DomTree}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME}/insights/domain`}
                  component={DomainInsights}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME}/timeline`}
                  component={Timeline}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME}/insights/`}
                  render={() => <AppStoreInsights />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${APP_STORE_PATHNAME}/ip/`}
                  render={() => <AppStoreIPInsights />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME}`}
                  exact
                  component={
                    featureIsAvailable(user, [appConstants.FEATURE_CODE.DARK_WEB_V2])
                      ? DarkWebDashboardV2
                      : DarkWebDashboard
                  }
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_DASHBOARD_PATHNAME}`}
                  exact
                  component={
                    featureIsAvailable(user, [appConstants.FEATURE_CODE.DARK_WEB_V2])
                      ? DarkWebDashboardV2
                      : DarkWebDashboard
                  }
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME}`}
                  exact
                  render={() =>
                    featureIsAvailable(user, [appConstants.FEATURE_CODE.DARK_WEB_V2]) ? (
                      <DarkWebFindingsV2
                        key={DARK_WEB_FINDING_DETECTION_TABLE_ID}
                        id={DARK_WEB_FINDING_DETECTION_TABLE_ID}
                        status={EDarkWebFindingStatus.ACTIVE}
                      ></DarkWebFindingsV2>
                    ) : (
                      <DarkWebFindingsTable
                        key={DARK_WEB_FINDING_DETECTION_TABLE_ID}
                        id={DARK_WEB_FINDING_DETECTION_TABLE_ID}
                        status={EDarkWebFindingStatus.ACTIVE}
                      ></DarkWebFindingsTable>
                    )
                  }
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME}`}
                  exact
                  render={() =>
                    featureIsAvailable(user, [appConstants.FEATURE_CODE.DARK_WEB_V2]) ? (
                      <DarkWebFindingsV2
                        key={DARK_WEB_MITIGATED_DETECTION_TABLE_ID}
                        id={DARK_WEB_MITIGATED_DETECTION_TABLE_ID}
                        status={EDarkWebFindingStatus.MITIGATED}
                      ></DarkWebFindingsV2>
                    ) : (
                      <DarkWebFindingsTable
                        key={DARK_WEB_MITIGATED_DETECTION_TABLE_ID}
                        id={DARK_WEB_MITIGATED_DETECTION_TABLE_ID}
                        status={EDarkWebFindingStatus.MITIGATED}
                      ></DarkWebFindingsTable>
                    )
                  }
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME}`}
                  exact
                  render={() =>
                    featureIsAvailable(user, [appConstants.FEATURE_CODE.DARK_WEB_V2]) ? (
                      <DarkWebFindingsV2
                        key={DARK_WEB_IGNORED_DETECTION_TABLE_ID}
                        id={DARK_WEB_IGNORED_DETECTION_TABLE_ID}
                        status={EDarkWebFindingStatus.IGNORED}
                      ></DarkWebFindingsV2>
                    ) : (
                      <DarkWebFindingsTable
                        key={DARK_WEB_IGNORED_DETECTION_TABLE_ID}
                        id={DARK_WEB_IGNORED_DETECTION_TABLE_ID}
                        status={EDarkWebFindingStatus.IGNORED}
                      ></DarkWebFindingsTable>
                    )
                  }
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME}/insights`}
                  render={() => <DarkWebInsights />}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME}`}
                  exact
                  component={DarkWebThreatActors}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME}/insights`}
                  component={ThreatActorInsights}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path={`${DARK_WEB_PATHNAME_SEARCH_TERMS}`}
                  exact
                  component={DarkWebSearchTerms}
                />
                <GuardRouteByType user={user} type={'brand'} path='/release' component={Release} />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/public/insights'
                  render={() => <div></div>}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/manage/landing-page'
                  component={LandingPage}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/account/team-members'
                  component={TeamMembers}
                  exact
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/integrations/available-connectors'
                  component={Integrations}
                />
                <GuardRouteByType
                  user={user}
                  type={'brand'}
                  path='/integrations/implemented-connectors'
                  component={Connectors}
                />
                {/* Routes available only to non_brand user(including checkphish) */}
                <GuardRouteByType
                  user={user}
                  type={'non_brand'}
                  path='/checkphish/dashboard'
                  component={DashboardContainerCP}
                />
                <GuardRouteByType
                  user={user}
                  type={'non_brand'}
                  path={TYPOSQUATTING_DOMAIN_ACQUISITION_PATHNAME}
                  render={() => (
                    <DomainAcquisition
                      brandInfo={user.brand_info}
                      firstTimeLoad={this.state.typosquatFirstLoad.domain_acquisition}
                      changeFirstLoad={this.changeTyposquatFirstLoad}
                    />
                  )}
                />
                <GuardRouteByType
                  user={user}
                  type={'non_brand'}
                  path={TYPOSQUATTING_DASHBOARD_PATHNAME}
                  render={() =>
                    featureIsAvailable(user, [
                      appConstants.FEATURE_CODE.TYPOSQUATTING_CHECKPHISH_DASHBOARD_V2,
                    ]) ? (
                      <TypoDashboardV2
                        typosquatJobStatus={this.state.activeTypoJob}
                        changeTyposquatJobStatus={this.changeActiveTypoJobStatus}
                      />
                    ) : (
                      <TypoDashboard
                        typosquatJobStatus={this.state.activeTypoJob}
                        changeTyposquatJobStatus={this.changeActiveTypoJobStatus}
                      />
                    )
                  }
                />
                <GuardRouteByType
                  user={user}
                  type={'non_brand'}
                  path={TYPOSQUATTING_MASTER_PATHNAME}
                  render={() => (
                    <TyposquatPage
                      typosquatJobStatus={this.state.activeTypoJob}
                      changeTyposquatJobStatus={this.changeActiveTypoJobStatus}
                      firstTimeLoad={this.state.typosquatFirstLoad.typosquat}
                      changeFirstLoad={this.changeTyposquatFirstLoad}
                    />
                  )}
                />

                <GuardRouteByType
                  user={user}
                  type={'non_brand'}
                  path={PRICING_PAGE_PATH}
                  render={() => <PricingPage getUserInfo={this.props.getUserInfo} />}
                />
                {/* ungaurded route */}
                <Route path='/checkphish/allscans' component={AllScans} />
                {/* ROutes which are available to both type of users */}
                <Route
                  path={`${webDashboardRoute}/insights`}
                  render={() => (
                    <InsightsContainer
                      type={appConstants.CONTENT_TYPE.WEB}
                      tableId={webDashboardTableId}
                      changeTyposquatJobStatus={this.changeActiveTypoJobStatus}
                    />
                  )}
                />
                <Route
                  path={`${webDashboardRoute}/insights`}
                  render={() => <Insights type={appConstants.CONTENT_TYPE.WEB} />}
                />
                <Route
                  path={`${webDashboardRoute}/ip`}
                  render={() => (
                    <IpInsights
                      type={appConstants.CONTENT_TYPE.WEB}
                      webDashboardRoute={webDashboardRoute}
                      isCommunity={isCommunity as boolean}
                    />
                  )}
                />
                <Route
                  path={`${webDashboardRoute}/domain`}
                  render={() => (
                    <DomainInsights
                      type={appConstants.CONTENT_TYPE.WEB}
                      webDashboardRoute={webDashboardRoute}
                    />
                  )}
                />
                <Route path={`${webDashboardRoute}/insights/whois`} component={Whois} />
                <Route path={`${webDashboardRoute}/insights/dom`} component={DomTree} />
                <Route path={`${webDashboardRoute}/insights/timeline`} component={Timeline} />
                <Route
                  path={`${webDashboardRoute}/insights/threat-intelligence`}
                  component={ThreatIntelligence}
                />
                <Route
                  path={`${SOCIAL_MEDIA_PATHNAME}/insights`}
                  render={() => (
                    <UgcInsightsContainer moduleType={appConstants.CONTENT_TYPE.SOCIAL} />
                  )}
                />
                <Route
                  path={`${MARKET_PLACE_PATHNAME}/insights`}
                  render={() => (
                    <UgcInsightsContainer moduleType={appConstants.CONTENT_TYPE.MARKETPLACE} />
                  )}
                />
                <Route
                  path={`${APP_STORE_PATHNAME}/insights`}
                  render={() => (
                    <UgcInsightsContainer moduleType={appConstants.CONTENT_TYPE.APP_STORE} />
                  )}
                />
                <Route path='/account/profile' component={Profile} />
                <Route path='/account/change-password' component={ChangePassword} />
                <Route
                  path='/account/communication-settings'
                  render={() => <CommunicationSettings user={user} />}
                />
                <Route path='/account/notifications' component={NotificationsSetting} />
                <Route path='/account/internal' component={SuperAdmin} exact />
                <Route path='/logout' render={() => <Logout logoutUser={this.signOut()} />} />
                <Route
                  path='/bulk-scan/insights'
                  render={() => (
                    <InsightsContainer
                      type={appConstants.CONTENT_TYPE.BULK_SCAN}
                      tableId={'scanHistory'}
                    />
                  )}
                />
                <Route
                  path='/bulk-scan/insights'
                  render={() => <Insights type={appConstants.CONTENT_TYPE.BULK_SCAN} />}
                />
                <Route
                  path='/bulk-scan/ip'
                  render={() => (
                    <IpInsights type={'bulk-scan'} isCommunity={isCommunity as boolean} />
                  )}
                />
                <Route
                  path='/bulk-scan/domain'
                  render={() => <DomainInsights type={'bulk-scan'} />}
                />
                <Route path='/bulk-scan/insights/whois' component={Whois} />
                <Route path='/bulk-scan/insights/dom' component={DomTree} />
                <Route path='/bulk-scan/insights/timeline' component={Timeline} />
                <Route
                  path={`/bulk-scan/insights/threat-intelligence`}
                  component={ThreatIntelligence}
                />
                <Route
                  path='/bulk-scan'
                  render={props => <BulkScan {...props} selectedTheme={selectedTheme} />}
                />

                <Route path='/assets' exact component={ManagedByOrganization} />
                {/* painted door routes */}
                <Route path='/premium/dashboard/web' exact component={DashboardContainer} />
                <Route path='/premium/dashboard/social' exact component={SocialMediaDashboard} />
                <Route path='/premium/dashboard/app-store' exact component={AppStoreDashboard} />
                <Route
                  path='/premium/dashboard/marketplace'
                  exact
                  component={MarketPlaceDashboard}
                />
                <Route path='/premium/dashboard/darkweb' exact component={DarkWebDashboardV2} />
                <Route path='/premium/web/logo' exact component={LogoPage} />
                <Route path='/premium/:type/:page' component={LockedPage} />
                <AppSettingContext.Consumer>
                  {({ landingPage }) => (
                    <Redirect to={userBrandType === 'brand' ? '/web/dashboard' : landingPage} />
                  )}
                </AppSettingContext.Consumer>
              </Switch>
            </ActiveOUContextProvider>
            {featureIsAvailable(user, appConstants.FEATURE_CODE.NEW_USER_SESSION) && (
              <UserTimeoutOverlay />
            )}
          </div>
          <GlobalAlert />
        </div>
      </div>
    );
  }
}

interface ILinkStateProp {
  user: DashBoardDto;
  dashboardConfig: DashBoardConfigDto[];
  selectedDashboard: DashBoardConfigDto;
  brandInfo: IBrandInfo | undefined;
}

interface ILinkDispatchProps {
  logout: () => void;
  checkphishLogout: () => void;
  getUserInfo: () => void;
  getEnterpriseAppConfig: () => void;
  getCheckphishAppConfig: () => void;
  getBrandInfo: (brandType: BrandType) => any;
  setTyposquatDataPresent: (typosquatJobStatus: boolean) => void;
  getDashboardConfig: () => any;
  onDashboardSelected: (item: INavSubItem) => void;
  onNewDashboard: () => void;
  onNewDashboardV2: () => void;
  getUsersList: () => void;
  setAllPlatformTags: (tags: ITags[]) => void;
}

const mapStateToProps = (state: AppState) => {
  const { user, dashboardConfig, selectedDashboard, brandInfo } = state.dashboardReducer;
  return {
    user,
    dashboardConfig,
    selectedDashboard,
    brandInfo,
  };
};

const mapDispatchToProps = {
  logout: userActions.logout,
  checkphishLogout: userActions.checkPhishUserLogout,
  getEnterpriseAppConfig: appActions.getEnterpriseAppConfig,
  getCheckphishAppConfig: appActions.getCheckphishAppConfig,
  getUserInfo: dashboardActions.getUserInfo,
  getBrandInfo: dashboardActions.getBrandInfo,
  getDashboardConfig: dashboardActions.getDashboardConfig,
  setTyposquatDataPresent: dashboardActions.setTyposquatDataPresent,
  onDashboardSelected: dashboardActions.onDashboardSelected,
  onNewDashboard: dashboardActions.onNewDashboard,
  onNewDashboardV2: dashboardActions.onNewDashboardV2,
  getUsersList: dashboardActions.getUsersList,
  runByClientApp: appActions.runByClientApp,
  setAllPlatformTags: setAllPlatformTags,
};

const connectedAppWrapper = connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
export { connectedAppWrapper as AppWrapper };

import React from 'react';
import L from 'leaflet';

interface Props {
  className?: string;
  markerPosition: any;
  location: any;
}

class Map extends React.Component<Props, {}> {
  map: any;
  marker: any;
  layer: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      map: null,
      marker: null,
      layer: null,
    };
  }

  componentDidMount() {
    // create map
    this.map = L.map('map', {
      center: [37.3967, -122.10674],
      zoom: 5,
      layers: [
        L.tileLayer(
          'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
          {
            maxZoom: 15,
            id: 'mapbox/streets-v11',
            accessToken:
              'pk.eyJ1Ijoic2hhc2hpLWJvbHN0ZXIiLCJhIjoiY2tybWdubnAzMnZnOTJybTQ4aGQ0bzRhMyJ9.Tg2wDDRBKgQeoBeMceTDag',
          },
        ),
      ],
    });

    // if (this.props.markerPosition.lat !== 0 && this.props.markerPosition.lng !== 0) {
    //   this.map.setView(new L.LatLng(this.props.markerPosition.lat, this.props.markerPosition.lng), 8);
    //   this.layer = L.layerGroup().addTo(this.map);
    //   this.marker = L.marker(this.props.markerPosition).addTo(this.layer);
    // }
  }

  componentDidUpdate() {
    // add marker
    if (this.props.markerPosition.lat !== 0 && this.props.markerPosition.lng !== 0) {
      this.map.setView(
        new L.LatLng(this.props.markerPosition.lat, this.props.markerPosition.lng),
        5,
      );
      this.layer = L.layerGroup().addTo(this.map);
      this.marker = L.marker(this.props.markerPosition).addTo(this.layer);
    } else {
      this.map.setView(
        new L.LatLng(this.props.markerPosition.lat, this.props.markerPosition.lng),
        5,
      );
    }
  }

  render() {
    return (
      <div id='map' className={'map-container ' + this.props.className}>
        <div className='geo-footer-hover'>
          <p>{this.props.location}</p>
          <p>
            {this.props.markerPosition.lat}
            {', '}
            {this.props.markerPosition.lng}
          </p>
        </div>
        {this.props.markerPosition.lat === 0 && this.props.markerPosition.lng === 0 && (
          <div className={'location-not-found'}>
            <div>Location Not Found for this IP Address</div>
          </div>
        )}
      </div>
    );
  }
}

export default Map;

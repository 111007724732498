import React, { useEffect, useState } from 'react';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { PageTitle } from '../Common/PageTitle';
import { Form, Button } from 'react-bootstrap';
import './NotificationsSetting.scss';
import { AppState } from '../../helpers';
import { dashboardActions } from '../../actions';
import { connect } from 'react-redux';
import { DashBoardDto } from '../../constants';
import NotificationServices from '../../services/notifications.service';
import { Loader } from 'react-bootstrap-typeahead';

interface ILinkDispatchProps {
  getUserInfo: () => void;
}

interface IProfileProps {
  user: DashBoardDto;
}

const notificationServics = new NotificationServices();

interface INotificationsSetting {}

type Props = ILinkDispatchProps & IProfileProps & INotificationsSetting;

const NotificationsSetting = ({ getUserInfo, user }: Props) => {
  const [typosquatSettings, setTyposquatSettings] = useState<any>(null);
  const [notificationLabels, setNotificationLabels] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updatingPreferenceState, setUpdatingPreferenceState] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!user.notificationSettings.typosquatting) {
      getUserInfo();
    }
    const { typosquatting } = user.notificationSettings;
    setTyposquatSettings(typosquatting);
    if (notificationLabels) return;
    notificationServics.getTyposquatNotificationLabels().then((labelsData: any) => {
      const { subModules } = labelsData;
      setNotificationLabels(subModules);
    });
  }, [user]);

  useEffect(() => {
    if (notificationLabels && typosquatSettings) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [notificationLabels, typosquatSettings]);

  const isSettingActive = (key: string) => {
    return typosquatSettings[key]?.includes('email');
  };

  const onSettingsChange = (event: any) => {
    const { id, checked } = event.target;
    const newSettings = { ...typosquatSettings };
    newSettings[id] = checked ? ['email', 'in_app'] : ['in_app'];
    setTyposquatSettings(newSettings);
  };

  const updateNotificationSettings = () => {
    setUpdatingPreferenceState(true);
    const body = {
      module_name: 'typosquatting',
      actions: {
        ...typosquatSettings,
      },
    };
    notificationServics.setNotificationTyposquatNotificationPreferences(body).finally(() => {
      setUpdatingPreferenceState(false);
      setIsLoading(true);
      getUserInfo();
    });
  };

  const renderLoader = () => {
    return (
      <div className='loader-wrap'>
        <Loader />
      </div>
    );
  };

  return (
    <AuthenticationWrapper>
      <PageTitle title={'Notification Settings'} titleAlwaysShown className={'background-white'} />
      {isLoading ? (
        renderLoader()
      ) : (
        <div className='notification-wrap'>
          <div className='sub-header'>
            Would you like to receive email notifications when changes to a domain you are
            monitoring happens:
          </div>
          <div className='notification-checkbox-wrap'>
            <Form className='notification-settings-form'>
              {Object.keys(notificationLabels).map((item: any) => (
                <Form.Check
                  type='checkbox'
                  id={item}
                  label={notificationLabels[item]}
                  className='notification-check'
                  checked={isSettingActive(item)}
                  onChange={onSettingsChange}
                />
              ))}
            </Form>
            <Button
              onClick={updateNotificationSettings}
              className='save-btn'
              disabled={updatingPreferenceState}
            >
              {updatingPreferenceState ? <Loader /> : 'Save'}
            </Button>
          </div>
        </div>
      )}
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return { user };
};

const mapDispatchToProps = {
  getUserInfo: dashboardActions.getUserInfo,
};

const connectedNotificationsSetting = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsSetting);

export { connectedNotificationsSetting as NotificationsSetting };

import React from 'react';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import { AuthenticationWrapper } from '../../../AuthenticationWrapper';
import { TableContextProvider } from '../../../Common/Table/table.context';
import { DARK_WEB_TABLE_NAME, DARK_WEB_THREAT_ACTOR_TABLE_ID } from '../../DarkWebConstants';
import DarkWebService from '../../../../services/darkWeb.service';
import {
  THREAT_ACTOR_COLUMN,
  CATEGORIES_COLUMN,
  RELATED_INCIDENTS_COLUMN,
  LAST_CHATTERS_COLUMN,
} from '../Common/TableColDefs/DarkWebColDefs';
import { getCSVFile } from '../../../Assets_v2/util';
import { TableApi } from '../../../Common/Table/table.api';
import { useAppSelector } from '../../../../helpers/hooks';

const darkWebService = new DarkWebService();

const COLUMNS = [
  THREAT_ACTOR_COLUMN,
  CATEGORIES_COLUMN,
  RELATED_INCIDENTS_COLUMN,
  LAST_CHATTERS_COLUMN,
];

const exportCSVTitle = `Bolster Threat Actors ${moment().format('YYYYMMDDhhmmss')}`;

interface IDarkWebThreatActorsProps {}
export default function DarkWebThreatActors(props: IDarkWebThreatActorsProps) {
  window.document.title = 'Threat Actors | Dark Web';
  const { user } = useAppSelector(state => state.dashboardReducer);

  const fetchApi = () => {
    return darkWebService.getThreatActors().then(res => {
      let data: any[] = [];
      let total = 0;
      if (res) {
        total = res?.length;
        data = res;
      }
      return { data, total };
    });
  };

  const exportThreatActors = (query: any, filters: any, sort: any, type: any, columns: any[]) => {
    void fetchApi().then(res => {
      getCSVFile(res.data, columns, exportCSVTitle);
    });
  };

  return (
    <AuthenticationWrapper>
      <div className={'page-content dashboard-page'}>
        <Card className={'dashboard-table-container'}>
          <TableContextProvider
            columns={COLUMNS}
            dashboardName={DARK_WEB_TABLE_NAME}
            tableId={DARK_WEB_THREAT_ACTOR_TABLE_ID}
          >
            <TableApi
              {...props}
              id={DARK_WEB_THREAT_ACTOR_TABLE_ID}
              title={'Threat Actors'}
              columns={COLUMNS}
              indexBy={'threat_actor'}
              tableIndex={'darkweb_brand_info'}
              disableDatePicker
              fetchApi={fetchApi}
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              exportApi={exportThreatActors}
              user={user}
            />
          </TableContextProvider>
        </Card>
      </div>
    </AuthenticationWrapper>
  );
}

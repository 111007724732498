import { ITableDataColumn } from '../Common/Table/table.data';
import { getDisplayTimeFromTimeStamp } from '../../constants';
import CategoryPill from './Components/Common/CategoryPill';
import { ITableApiColumn } from '../Common/Table/table.api';
import React from 'react';

export const SEARCH_TERM_COLUMN: ITableDataColumn = {
  id: 'search_term',
  accessor: 'search_term',
  header: 'Search Terms',
  filterDisabled: true,
};

export const CATEGORY_COLUMN: ITableDataColumn = {
  id: 'category',
  accessor: 'category',
  header: 'Category',
  filterDisabled: true,
  render: (data: any) => {
    return <CategoryPill category={data.category} />;
  },
};

export const TIMES_DETECTED_COLUMN: ITableDataColumn = {
  id: 'times',
  accessor: 'times',
  header: 'Times Detected',
  type: 'number',
};

export const ENTITY_COLUMN: ITableDataColumn = {
  id: 'entity',
  accessor: 'entity',
  header: 'Entity',
  render: (data: any) => {
    return data.entity || '--';
  },
};

export const AUTHOR_COLUMN: ITableDataColumn = {
  id: 'author',
  accessor: 'author',
  header: 'Author',
};

export const ADDED_COLUMN: ITableDataColumn = {
  id: 'added',
  accessor: 'added',
  header: 'Added',
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.added);
  },
};

export const LAST_SEARCH_COLUMN: ITableDataColumn = {
  id: 'lastSearch',
  accessor: 'last_search',
  header: 'Last Search',
};

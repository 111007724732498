import React, { useEffect, useState } from 'react';
import DashboardService from '../../../../../services/dashboard.service';
import NoDetections from '../../../../../assets/icons/NoDetections.svg';
import { LoadingWrapper } from '../../../../Common/LoadingWrapper';
import { IFilter } from '../../../../Common/Table/constant';
import { renderNoDataPlaceholder } from '../../../../Dashboard/dashboard.detection';
import { LOGO_DETECTION_COLUMN } from '../../../../MonitorAndTakedown/ColDefinition/malicious.columns';

import '../../../Style/monitoredDomains.scss';
import MonitoredDomainFunnelV2 from './MonitoredDomainFunnelV2';
import MonitoredDomainsHeaderV2 from './MonitoredDomainsHeaderV2';
import { TypoSquatDashboardAnalytics } from '../../../../../types/typosquat-dashboard.interface';
import { IBrand, IOrgUnits } from '../../../../../constants';

const FILTER_MAP = {
  [LOGO_DETECTION_COLUMN.accessor]: LOGO_DETECTION_COLUMN.id,
};

interface IProps {
  startDate: any;
  endDate: any;
  domainCategories: any;
  user: any;
  activeFilters: any;
  typosquatDashboardAnalytics?: TypoSquatDashboardAnalytics;
  sendFilters?: (query: any) => any;
  loadFunnelData?: boolean;
  isFunnelLoading: boolean;
  setFunnelQueryParams?: (query: any) => any;
  isError: boolean;
  monitoredDomains: any[];
  currentOrgUnit?: IOrgUnits;
  selectedBrandForFilters?: IBrand[];
}
const MonitoredFunnelContainer = ({
  startDate,
  endDate,
  domainCategories,
  user,
  activeFilters,
  typosquatDashboardAnalytics,
  sendFilters,
  loadFunnelData,
  monitoredDomains,
  isFunnelLoading,
  setFunnelQueryParams,
  isError,
  currentOrgUnit,
}: IProps) => {
  const [filters, setFilters] = useState<Record<string, IFilter>>({});
  const filterToqueryString = (filters: Record<string, IFilter>): string | undefined => {
    const newQueries: string[] = [];
    Object.keys(filters).forEach(filter => {
      const {
        filterBy: { value: field },
        filterValue: value,
      } = filters[filter];
      newQueries.push(`${FILTER_MAP[field] || field}: ${value}`);
    });
    return newQueries.length ? newQueries.join(' AND ') : undefined;
  };

  useEffect(() => {
    if (setFunnelQueryParams) {
      setFunnelQueryParams(filterToqueryString(filters));
    }
  }, [filters]);

  const updateFilter = (filter: IFilter) => {
    const newFilters = {
      ...filters,
      [filter.filterBy.value]: filter,
    };
    setFilters(newFilters);
    if (sendFilters) sendFilters(filterToqueryString(newFilters));
  };

  const deleteFilter = (filterByValue: string) => {
    const newFilters = { ...filters };
    delete newFilters[filterByValue];
    setFilters(newFilters);
    if (sendFilters) sendFilters(filterToqueryString(newFilters));
  };
  return (
    <div className='monitored-domains-container-v2 '>
      <MonitoredDomainsHeaderV2
        title={
          typosquatDashboardAnalytics
            ? `Live Scanned Domain ${typosquatDashboardAnalytics.monitoredDomain}`
            : `Live Scanned for ${currentOrgUnit?.name || '--'} `
        }
        updateFilter={updateFilter}
        deleteFilter={deleteFilter}
        monitoredDomains={monitoredDomains}
        enableCommunity={typosquatDashboardAnalytics ? true : false}
      />
      <LoadingWrapper isLoading={typosquatDashboardAnalytics ? loadFunnelData : isFunnelLoading}>
        {monitoredDomains.length > 0 && !isError ? (
          <div className='monitored-domains-funnel-container'>
            <MonitoredDomainFunnelV2
              monitoredDomains={monitoredDomains}
              domainCategories={domainCategories}
              query={{ startDate, endDate }}
              filters={Object.values(filters)}
              activeFilters={activeFilters}
              enableCommunity={typosquatDashboardAnalytics ? true : false}
            />
          </div>
        ) : (
          renderNoDataPlaceholder(
            NoDetections,
            'No data found',
            'We haven’t found any data related to this period. Please select another time frame.',
          )
        )}
      </LoadingWrapper>
    </div>
  );
};

export { MonitoredFunnelContainer };

import React, { useEffect, useState } from 'react';
import { IConnectorOption } from '../Common/PlaybookTypes';
import { fetchAllConnectors, fetchConnectorDefinition } from '../playbook-requests';
import { IConnector } from '../../Connectors/connectors.component';
import _ from 'lodash';

export default function useFetchConnectorDefinition() {
  const [connectorOptions, setConnectorOptions] = useState<IConnectorOption[]>([]);

  useEffect(() => {
    try {
      const fetchConnectors = async () => {
        const [allConnectors, connectorDefinition] = await Promise.all([
          fetchAllConnectors(),
          fetchConnectorDefinition(),
        ]);

        const massagedData: IConnectorOption[] = allConnectors.map((connector: IConnector) => {
          const connectorDef = _.find(connectorDefinition.result, {
            id: connector.connectorTypeId,
          });
          return {
            label: connector.name,
            value: connector.id,
            fields: _.chain(connectorDef?.metadata)
              .filter(i => !i.isGlobal)
              .map(field => {
                return {
                  key: field.key,
                  label: field.label,
                  value: '',
                };
              })
              .value(),
          };
        });

        setConnectorOptions(massagedData);
      };

      void fetchConnectors();
    } catch (error) {
      console.error('Error fetching connector definition', error);
    }
  }, []);

  return { connectorOptions };
}

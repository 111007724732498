import React, { useContext } from 'react';
import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes } from '../../../../constants';
import {
  DARK_MODE_DATA_LEAK_BG_COLOR,
  LIGHT_MODE_DATA_LEAK_BG_COLOR,
} from '../../DarkWebConstants';

export default function useOnThemeChange() {
  const { selectedTheme } = useContext(ThemeContext);
  const palleteType: string = selectedTheme === ThemeModes.DARK.toLowerCase() ? 'dark' : 'light';
  const backgroundColor: string =
    selectedTheme === ThemeModes.DARK.toLowerCase()
      ? DARK_MODE_DATA_LEAK_BG_COLOR
      : LIGHT_MODE_DATA_LEAK_BG_COLOR;

  return { palleteType, backgroundColor };
}

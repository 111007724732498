import React from 'react';
import { Card } from 'react-bootstrap';
import { LoadingWrapper } from '../../../../Common/LoadingWrapper';
import { Table } from '../../../../Common/Table/table';
import {
  TITLE_COLUMN,
  RISK_COLUMN,
  CATEGORY_COLUMN,
  DISCOVERY_DATE_COLUMN,
} from '../../Common/TableColDefs/DarkWebColDefs';
import { IDarkWebFindingData } from '../../Types/DarkWeb.types';

const COLUMNS: any[] = [TITLE_COLUMN, RISK_COLUMN, CATEGORY_COLUMN, DISCOVERY_DATE_COLUMN];
interface IRelatedFindingsProps {
  data: IDarkWebFindingData[];
  isLoading: boolean;
  className?: string;
}
export default function RelatedFindings({
  data,
  isLoading,
  className = '',
}: IRelatedFindingsProps) {
  return (
    <Card className={className}>
      <Card.Header className='self-card-header'>Findings Related to Threat Actor</Card.Header>
      <Card.Body className='self-card-body'>
        <LoadingWrapper isLoading={isLoading}>
          <Table
            initialPageSize={5}
            data={data}
            columns={COLUMNS}
            disableToolsBar
            disableDatePicker
            disableFilter
          />
        </LoadingWrapper>
      </Card.Body>
    </Card>
  );
}

import React, { useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import ThemeContext from '../../context/ThemeContext';
import PlanBlock from './Common/PlanBlock';
import { IPricingPlan, PRICING_PLANS } from './types';
import { toggleOptions } from './constants';
import './pricing.scss';
import TickLight from '../../assets/icons/TickLight.svg';
import TickDark from '../../assets/icons/TickDark.svg';
import CrossLight from '../../assets/icons/CrossLight.svg';
import CrossDark from '../../assets/icons/CrossDark.svg';
import { useContext } from 'react';
import { ThemeModes } from '../../constants';
import { IPlan } from './types';
import _ from 'lodash';
import { HUBSPOT_FORM_TYPE } from './types';
import TermToggleSwitch from './Common/TermToggleSwitch';
import { getAvailablePlans } from './helpers';
import {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
  SelectedTerm,
} from '../../context/CheckPhishContactUsContext';
import { DowngradType } from './TogglePlan';

export interface IComparisionTableProps {
  planData: IPlan[];
  showHubspotForm: (type: HUBSPOT_FORM_TYPE.PREMIUM | HUBSPOT_FORM_TYPE.PRO) => any;
  addPlanToCart: any;
  typosquatPlans: IPricingPlan[];
  selectedTerm: SelectedTerm;
  numberOfDomains: number;
  triggerDownGrade: (DowngradeType: DowngradType) => void;
}

const ComparisionTable = ({
  planData,
  showHubspotForm,
  addPlanToCart,
  typosquatPlans,
  selectedTerm,
  numberOfDomains,
  triggerDownGrade,
}: IComparisionTableProps) => {
  const { themeName } = useContext(ThemeContext);
  const { currentTyposquatSubscription } = useContext<ICheckPhishSubscription>(
    CheckphishSubscriptionContext,
  );

  const CrossIcon = themeName === ThemeModes.LIGHT ? CrossLight : CrossDark;
  const TickIcon = themeName === ThemeModes.LIGHT ? TickLight : TickDark;

  const renderCrossIcon = () => <img src={CrossIcon} alt={`not-avaialble-${themeName}`} />;
  const renderTickIcon = () => <img src={TickIcon} alt={`avaialble-${themeName}`} />;

  return (
    <>
      <TableContainer className='table-container' data-testid='comparision-table-wrap'>
        <Table className='table'>
          <TableHead className='table-head'>
            <TableRow className={`table-row`}>
              <TableCell className={`table-cell`}>
                <div className='compare-plan-block plan-block'>
                  <TermToggleSwitch
                    items={toggleOptions.map(item => ({ label: item.label, value: item.value }))}
                  />
                  <h5 style={{ margin: '10px auto' }}>Compare Plan</h5>
                </div>
              </TableCell>
              <TableCell className={'table-cell'}>
                <PlanBlock
                  planName={PRICING_PLANS.FREE}
                  price={[{ price: 0, duration: 'lifetime', type: 'lifetime' }]}
                  onActionBtnClick={triggerDownGrade.bind(this, DowngradType.TYPOSQUAT_FREE)}
                />
              </TableCell>
              <TableCell className={'table-cell'}>
                <PlanBlock
                  planName={PRICING_PLANS.STARTER}
                  price={planData.map(planItem => ({
                    price: planItem.price * numberOfDomains,
                    duration: `Billed ${_.capitalize(planItem.term)}`,
                    type: planItem.term,
                  }))}
                  onActionBtnClick={
                    numberOfDomains < (currentTyposquatSubscription?.quantity || 0)
                      ? triggerDownGrade.bind(this, DowngradType.TYPOSQUAT_DOWNGRADE)
                      : addPlanToCart.bind(
                          this,
                          getAvailablePlans(typosquatPlans, selectedTerm.value)[0],
                          numberOfDomains,
                          true,
                        )
                  }
                />
              </TableCell>
              <TableCell className='table-cell'>
                <PlanBlock
                  planName={PRICING_PLANS.PREMIUM}
                  price={'Request Demo'}
                  actionBtnText={'Get in Touch'}
                  onActionBtnClick={showHubspotForm.bind(this, HUBSPOT_FORM_TYPE.PREMIUM)}
                />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer className='table-container body-table'>
        <Table className='table' stickyHeader>
          <TableHead className='table-header'>
            <TableRow className={`table-row highlight-blue`}>
              <TableCell className={'table-cell'}>FEATURE</TableCell>
              <TableCell className={'table-cell'} align='center' colSpan={4}>
                DOMIAIN MONITORING
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Typosquats Generation & Monitoring</TableCell>
              <TableCell className={'table-cell'}>Limited</TableCell>
              <TableCell className={'table-cell'}>Full Results</TableCell>
              <TableCell className={'table-cell'}>Full Results</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>DOM similarity matching</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Favicon Identifications</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Ads Monitoring</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Search Engine Results</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>
                <div
                  className='multiple-wrap'
                  style={{ display: 'flex', flexFlow: 'column nowrap' }}
                >
                  <span>ML image augmentation</span>
                  <span> Threat Feed - Typosquat variations</span>
                  <span>Threat Feed - Bolster Proprietary</span>
                  <span>Threat Feed - Newly Registered</span>
                  <span>Threat Feed - Certificate Monitoring</span>
                  <span>Threat Feed - Anti-Virus</span>
                  <span>Threat Feed - Passive DNS</span>
                  <span>Threat Feed - SPAM</span>
                </div>
              </TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Auto Takedown</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
          </TableBody>
          <TableHead className='table-header-light'>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell header-row'}>FEATURE</TableCell>
              <TableCell className={'table-cell header-row'} align='center' colSpan={4}>
                NOTIFICATION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>
                <div>
                  <span className='head'>
                    Rescan Alerts <br />
                  </span>
                  <span>New Detections and Changes In Typosquats Only</span>
                </div>
              </TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>Daily</TableCell>
              <TableCell className={'table-cell'}>Daily</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>
                <div>
                  <span className='head'>
                    Rescan Alerts <br />
                  </span>
                  <span>
                    New Detection And Takedown Completed In Web, Social Media, Dark Web, And App
                    Store
                  </span>
                </div>
              </TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>Daily</TableCell>
            </TableRow>
          </TableBody>
          <TableHead className='table-header-light'>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell header-row'}>FEATURE</TableCell>
              <TableCell className={'table-cell header-row'} align='center' colSpan={4}>
                SUPPORT
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Community Access and Support</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Dedicated Analyst</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Dedicated CSM</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>24/7 Support</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
          </TableBody>
          <TableHead className='table-header-light'>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell header-row'}>FEATURE</TableCell>
              <TableCell className={'table-cell header-row'} align='center' colSpan={4}>
                ADDITIONAL MODULE MONITORING
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Social Media Detection and Remediation</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>App store Detection and Remediation</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Dark Web Detection and Remediation</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
          </TableBody>
          <TableHead className='table-header-light'>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell header-row'}>FEATURE</TableCell>
              <TableCell className={'table-cell header-row'} align='center' colSpan={4}>
                TEAM & COLLABORATION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Teams - Add Users</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Teams - Admin</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
            <TableRow className={`table-row`}>
              <TableCell className={'table-cell'}>Teams - SSO</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderCrossIcon()}</TableCell>
              <TableCell className={'table-cell'}>{renderTickIcon()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ComparisionTable;

import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';
import { alertActions } from '../../../../actions';
import UgcService from '../../../../services/ugc.service';
import ModalOverlay from '../../../Common/ModalOverlay';
import { IFindingsTableItemProps, ISearchTermOptions, ugcType } from '../../Types/ugc.types';
import { IAlertDispatchProps, IContentPlatform } from '../../../../constants';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Dropdown, IDropdownOption } from '../../../Common/Dropdown';
import { UploadFiles } from '../../../Common/UploadFiles';
import { LabelAndValue } from '../../../Common/LabelAndValue';
import { DropdownMultiSelect, IOption } from '../../../Common/DropdownMultiSelect';

const ugcService = new UgcService();
interface IModalProps {
  data: IFindingsTableItemProps;
  show: boolean;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  type: string;
}
interface IPropsFromGlobalState {
  ugcPlatforms: IContentPlatform[];
}

interface IScanFindingValue {
  url: string;
  searchTerms: any;
  platform: any;
  origin: any;
  screenshot?: File;
}

const SocEditDetectionModal = ({
  data,
  show,
  onCancel,
  onSubmit,
  ugcPlatforms,
  alertSuccess,
  alertError,
  type,
}: IModalProps & IPropsFromGlobalState & IAlertDispatchProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTermOptions, setSearchTermOptions] = useState<ISearchTermOptions[]>([]);
  const [platformOptions, setPlatformOptions] = useState<IContentPlatform[]>([]);
  const [originOptions, setOriginOptions] = useState<IDropdownOption[]>([]);
  const [values, setValues] = useState<IScanFindingValue>({
    url: '',
    searchTerms: undefined,
    platform: undefined,
    origin: undefined,
    screenshot: undefined,
  });

  useEffect(() => {
    if (!show) {
      return;
    }
    setIsLoading(true);
    Promise.all([ugcService.getSearches(type), ugcService.getFinding(data.sha256, type)]).then(
      ([searchTerms, findingData]: any) => {
        setIsLoading(false);
        const stOptions = _.chain(searchTerms)
          .map((item): ISearchTermOptions => {
            const { id, searchTerm, platforms } = item;
            return {
              id,
              label: searchTerm,
              value: searchTerm,
              platforms,
            };
          })
          .uniqBy('label')
          .sortBy('label')
          .value();
        setSearchTermOptions(stOptions);
        setInitialValues(stOptions, findingData);
      },
    );
  }, [show]);

  const setInitialValues = (stOptions: ISearchTermOptions[], findingData: any) => {
    const searchTerms: ISearchTermOptions[] = [];
    let platformsUnderSearchTerms: IContentPlatform[] = [];
    const searchTermLabels = data.searchTermLabels || [];
    _.forEach(searchTermLabels, label => {
      const matchedSearchTerm = _.find(stOptions, ['label', label.trim()]);
      if (matchedSearchTerm) {
        const { id, label, value, platforms } = matchedSearchTerm;
        searchTerms.push({
          id,
          label,
          value,
          platforms,
        });
        platformsUnderSearchTerms = platformsUnderSearchTerms.concat(
          _.map(matchedSearchTerm.platforms, p => ({
            ...p,
            value: p.id,
          })),
        );
      }
    });

    const availablePlatforms = platformsUnderSearchTerms?.length
      ? platformsUnderSearchTerms
      : ugcPlatforms;
    setPlatformOptions(_.uniqBy(availablePlatforms, 'label'));

    const platform = _.find(ugcPlatforms, ['id', data.platformId]);
    setOriginOptions(platform?.origins || []);
    setValues({
      ...values,
      url: data.url,
      searchTerms,
      platform,
      origin: _.find(platform?.origins, ['id', data.originId]),
    });
  };

  const onModalCancel = () => {
    onCancel();
  };

  const onModalSubmit = () => {
    setIsLoading(true);
    const { searchTerms, platform, origin, screenshot } = values;
    const formData = new FormData();
    const searchTermIds = _.map(searchTerms, st => st.id);

    if (searchTermIds.length) {
      formData.append('searchTermIds', searchTermIds.join(','));
    }
    if (platform?.value) {
      formData.append('platformId', platform.value);
    }
    if (origin?.value) {
      formData.append('originId', origin.value);
    }
    screenshot && formData.append('screenshot', screenshot);

    ugcService
      .editFinding(data.sha256, formData, type)
      .then(() => {
        alertSuccess(`Congratulations! URL has been updated.`);
        onSubmit(data);
      })
      .catch(err => {
        console.error(err);
        alertError(`Error on updating ${data.url}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateValue = useCallback(
    (value: any, field: string) => {
      if (field === 'platform') {
        setOriginOptions(value?.origins);
      }
      setValues({
        ...values,
        ...(field === 'platform' && { origin: '' }),
        [field]: value,
      });
    },
    [values],
  );

  const handleDropdownChange = (option: IDropdownOption, controlId: string) => {
    updateValue(option, controlId);
    if (controlId === 'platform') {
      const platform = _.find(ugcPlatforms, ['value', option.value]);
      if (platform) {
        updateValue(platform, 'platform');
      }
    }
  };

  const handleDropdownMultiSelectChange = (selections: IOption[], controlId: string) => {
    updateValue(selections, controlId);
    if (controlId === 'searchTerms') {
      let platformsUnderSearchTerms: IContentPlatform[] = [];
      _.forEach(selections, selection => {
        const matchedSearchTerm = _.find(searchTermOptions, ['label', selection.label]);
        if (matchedSearchTerm) {
          platformsUnderSearchTerms = platformsUnderSearchTerms.concat(
            _.map(matchedSearchTerm.platforms, p => ({
              ...p,
              value: p.id,
            })),
          );
        }
      });

      const availablePlatforms = platformsUnderSearchTerms?.length
        ? platformsUnderSearchTerms
        : ugcPlatforms;
      setPlatformOptions(_.uniqBy(availablePlatforms, 'label'));
    }
  };

  const handleFilesAdded = (files: File[], controlId: string) => {
    updateValue(files[0], controlId);
  };

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={'Edit Detection'}
      onCancel={onModalCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Save Detection'}
    >
      <div className='soc-edit-finding-modal'>
        {isLoading ? (
          <div className='flex-center w-100'>
            <Spinner className='spinner' animation='border' />
          </div>
        ) : (
          <Form className='edit-finding-form'>
            <Form.Group>
              <LabelAndValue
                label={'URL'}
                value={values.url}
                direction={'column'}
                longTextLineNumberLimit={1}
                copyButton
              />
            </Form.Group>
            <Form.Group>
              <DropdownMultiSelect
                label={'Matched Search Term'}
                options={searchTermOptions}
                initialSelections={values?.searchTerms}
                controlId='searchTerms'
                boxStyle
                updateOnChange
                onSubmit={handleDropdownMultiSelectChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Platform</Form.Label>
              <Dropdown
                boxStyle
                btnClassName='font-weight-normal'
                controlId='platform'
                options={platformOptions}
                onChange={handleDropdownChange}
                defaultSelection={values?.platform}
              />
            </Form.Group>
            {type === ugcType.Social && (
              <Form.Group>
                <Form.Label>Origin</Form.Label>
                <Dropdown
                  boxStyle
                  btnClassName='font-weight-normal'
                  controlId='origin'
                  options={originOptions}
                  onChange={handleDropdownChange}
                  defaultSelection={values?.origin}
                />
              </Form.Group>
            )}
            <Form.Group>
              <UploadFiles
                label='Screenshot'
                onChangeHandler={handleFilesAdded}
                dragAndDrop
                controlId='screenshot'
                filesLimit={1}
              />
            </Form.Group>
          </Form>
        )}
      </div>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { contents } = state.appReducer;
  return {
    ugcPlatforms: contents[type].platforms,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocEditDetectionModal);

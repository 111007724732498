import React from 'react';
import './Label.scss';
import { Form } from 'react-bootstrap';
import RedStarIcon from '../../assets/icons/RedStar.svg';

interface ILabel {
  label: string;
  isRequired?: boolean;
}

function Label({ label, isRequired = false }: ILabel) {
  return (
    <div className='label-component'>
      {isRequired && <img data-testid='is-required-img' src={RedStarIcon} alt={'mandatory'} />}
      <Form.Label data-is-required={true} data-testid='label'>
        {label}
      </Form.Label>
    </div>
  );
}

export default Label;

import React from 'react';
import './pricing.scss';
import CheckIcon from './Common/CheckIcon';
import { PRICING_PLANS } from './types';
import _ from 'lodash';

export interface FeatureProps {
  features: string[];
  planType: PRICING_PLANS;
  featuresHeader: string;
}

const Features = ({ features, planType, featuresHeader }: FeatureProps) => {
  return (
    <div className='feature-wrap' data-testid='feature-wrap'>
      {!_.isEmpty(featuresHeader) && <div className='feature-header'>{featuresHeader}</div>}
      {features.map((item: any, index: number) => (
        <div className='feature' key={index} data-testid='feature'>
          <CheckIcon planType={planType} />
          {item}
        </div>
      ))}
    </div>
  );
};

export default Features;

import React, { useState, useEffect, useContext, useCallback } from 'react';
import './darkwebfindings.scss';
import DarkWebService from '../../../../services/darkWeb.service';
import { capitalize } from 'lodash';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { connect } from 'react-redux';
import { alertActions } from '../../../../actions';
import { AlertActionsTypes } from '../../../../constants';
import { AuthenticationWrapper } from '../../../AuthenticationWrapper';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../../Common/PageTitle';
import { useAppDispatch } from '../../../../helpers/hooks';
import { setIsDarkWebTablesGroupView } from '../../../../reducers/table.reducer';
import { ENTITY_TYPE } from '../../DarkWebConstants';
import { DarkWebFindingsWidget, ENTITY_IMAGE_MAPPER } from './DarkWebFindingsWidget';
import DarkWebFindingsTable from './DarkWebFindingsTable';
import ThemeContext from '../../../../context/ThemeContext';
import NoDetections from '../../../../assets/icons/NoDetections.svg';
import { renderNoDataPlaceholder } from '../../../Dashboard/dashboard.detection';
import moment from 'moment';
import { transformFilterToDarkWebApiFilterString } from '../../../Common/Table/ag-table/ag-filter-to-api-params';
import { useAppSelector } from '../../../../helpers/hooks';
import _ from 'lodash';
import { defaultDarkWebEntityWidgetDetails } from '../../../../constants/darkWeb.constants';
import { getBreachDescriptions } from '../Common/darkweb-requests';
import {
  setBreachDescriptions,
  setSelectedEntityActive,
  setSelectedEntityIgnored,
  setSelectedEntityMitigated,
} from '../../../../reducers/darkWeb.reducer';
import SingleFindingDataLeakPopup from '../Common/SingleFindingDataLeakPopup';
import { Nullable } from '../../../../types/common';

const darkWebService = new DarkWebService();

interface IProps {
  id: string;
  status: EDarkWebFindingStatus;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  setIsDarkWebTablesGroupView: (isDarkWebTablesGroupView: boolean) => void;
}
const DarkWebFindingsV2 = (props: IProps) => {
  const { id, status, alertError, setIsDarkWebTablesGroupView } = props;
  const [widgetSummaryDetails, setWidgetSummaryDetails] = useState<any>(undefined);
  const [entityWidgetDetails, setEntityWidgetDetails] = useState<any>(undefined);
  const [widgetFilterString, setWidgetFilterString] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const history = useHistory();
  const { selectedTheme } = useContext(ThemeContext);
  const [apiTs, setApiTs] = useState<number>(moment().valueOf());
  const selectedEntity = useAppSelector(
    (state: any) => state.darkWebReducer.selectedEntityCard[status],
  );

  const title = `${capitalize(status.toLowerCase())} Findings`;
  window.document.title = `${title} Findings | Dark Web`;
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  const setSelectedEntity = (selectedValue: Nullable<string>) => {
    switch (status) {
      case EDarkWebFindingStatus.ACTIVE:
        dispatch(setSelectedEntityActive(selectedValue));
        break;
      case EDarkWebFindingStatus.MITIGATED:
        dispatch(setSelectedEntityMitigated(selectedValue));
        break;
      case EDarkWebFindingStatus.IGNORED:
        dispatch(setSelectedEntityIgnored(selectedValue));
        break;
    }
  };

  useEffect(() => {
    try {
      const fetchbBreachDescriptions = async () => {
        const response = await getBreachDescriptions();
        if (response) {
          dispatch(setBreachDescriptions(response));
        }
      };
      void fetchbBreachDescriptions();
    } catch (error) {
      console.log('Error in fetching breach descriptions', error);
    }
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    darkWebService
      .getFindingsWidget(status)
      .then(resp => {
        const keys = Object.keys(resp);
        const sequenceKeys = Object.keys(ENTITY_IMAGE_MAPPER).filter(e => keys.includes(e));
        if (sequenceKeys.length > 0) {
          setWidgetSummaryDetails(resp);
          const urlParams = new URLSearchParams(history.location.search);
          history.replace({
            search: '',
          });
          let entity = urlParams.get('entity');
          if (!entity || (entity && !sequenceKeys.includes(entity))) {
            entity = _.isEmpty(selectedEntity) ? sequenceKeys[0] : selectedEntity;
          }
          setSelectedEntity(entity as ENTITY_TYPE);
        }
      })
      .catch(err => alertError(`Error in fetching dark web findings: ${err.message}`))
      .finally(() => setIsLoading(false));
  }, [status, apiTs]);

  useEffect(() => {
    if (!selectedEntity || !widgetSummaryDetails) return;
    setTableLoading(true);
    if (selectedEntity === ENTITY_TYPE.OTHERS) {
      setEntityWidgetDetails(widgetSummaryDetails[selectedEntity]);
      setTimeout(() => setTableLoading(false), 200);
      return;
    } else {
      if (_.isEmpty(widgetFilterString)) {
        setEntityWidgetDetails(widgetSummaryDetails[selectedEntity]);
        setTimeout(() => setTableLoading(false), 200);
        return;
      }
      darkWebService
        .getFindingsWidget(status, widgetFilterString?.replace('tags', 'tags.id'))
        .then(resp => {
          if (resp[selectedEntity]) {
            setEntityWidgetDetails(resp[selectedEntity]);
          } else {
            setEntityWidgetDetails(defaultDarkWebEntityWidgetDetails);
          }
        })
        .finally(() => setTimeout(() => setTableLoading(false), 200));
    }
  }, [status, selectedEntity, widgetFilterString, widgetSummaryDetails]);

  useEffect(() => {
    if (!selectedEntity) return;
    widgetFilterString && setWidgetFilterString(undefined);
    if (selectedEntity === ENTITY_TYPE.OTHERS) {
      setIsDarkWebTablesGroupView(false);
    } else {
      setIsDarkWebTablesGroupView(true);
    }
  }, [selectedEntity]);

  const onChangeStatusSuccess = () => {
    setTimeout(() => {
      setApiTs(moment().valueOf());
    }, 1000);
  };

  const shouldShowDataLeakTablePopup = useAppSelector(
    state => state.darkWebReducer.shouldShowDataLeakTablePopup,
  );

  return (
    <AuthenticationWrapper>
      <LoadingWrapper isLoading={isLoading}>
        <div
          key={`findings-container-${status}`}
          className={`${selectedTheme} page-content darkweb-v2-dashboard-page ${
            shouldShowDataLeakTablePopup ? 'darkweb-v2-dashboard-resize' : ''
          }`}
        >
          <PageTitle
            title={`${status.toLowerCase()} Findings`}
            className='mb-3 pb-3 findings-title'
          />
          {selectedEntity && entityWidgetDetails ? (
            <React.Fragment>
              <DarkWebFindingsWidget
                widgetDetails={widgetSummaryDetails}
                selectedEntity={selectedEntity}
                setSelectedEntity={setSelectedEntity}
                setSelectedItems={setSelectedItems}
              />
              <LoadingWrapper isLoading={tableLoading}>
                <DarkWebFindingsTable
                  id={id}
                  key={selectedEntity}
                  setIsDarkWebTablesGroupView={setIsDarkWebTablesGroupView}
                  status={status}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  selectedEntity={selectedEntity}
                  widgetDetails={entityWidgetDetails}
                  onChangeStatusSuccess={onChangeStatusSuccess}
                  handleGroupFilterChange={filterModel =>
                    setWidgetFilterString(transformFilterToDarkWebApiFilterString(filterModel))
                  }
                />
              </LoadingWrapper>
            </React.Fragment>
          ) : (
            <>
              {renderNoDataPlaceholder(
                NoDetections,
                'No detections found',
                'We haven’t detected any for this period.',
              )}
            </>
          )}
          <SingleFindingDataLeakPopup />
        </div>
      </LoadingWrapper>
    </AuthenticationWrapper>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  setIsDarkWebTablesGroupView,
};
export default connect(undefined, mapDispatchToProps)(DarkWebFindingsV2);

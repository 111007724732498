import React, { Component } from 'react';
import _ from 'lodash';
import { IDropdownOption, Dropdown } from '../Dropdown';
import { appConstants } from '../../../constants';

export const VALUE_DELIMITER = '-';

interface IComponentProps {
  originFilterValue: string;
  onChange: (value: string) => void;
  type?: string;
}

interface IComponentState {
  value: string;
  unit: string;
}

class FilterDate extends Component<IComponentProps, IComponentState> {
  constructor(props: IComponentProps) {
    super(props);
    const [value = '', unit = 'd'] = props.originFilterValue.split(VALUE_DELIMITER);
    this.state = { value, unit };
  }

  onValueChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.props.onChange('');
      return;
    }
    const value = e.currentTarget.value;
    this.setState({ value });
    this.props.onChange(value + VALUE_DELIMITER + this.state.unit);
  };

  onUnitChangeChange = (selection: IDropdownOption) => {
    const unit = selection.value;
    this.setState({ unit });
    this.props.onChange(this.state.value + VALUE_DELIMITER + unit);
  };

  render() {
    const UNIT_OPTIONS = [
      {
        value: 'd',
        label: 'days',
      },
    ];

    if (
      this.props.type !== appConstants.CONTENT_TYPE.SOCIAL &&
      this.props.type !== appConstants.CONTENT_TYPE.MARKETPLACE
    ) {
      UNIT_OPTIONS.push({
        value: 'h',
        label: 'hours',
      });
    }
    const { value, unit } = this.state;
    const defaultSelection = _.find(UNIT_OPTIONS, _.matchesProperty('value', unit));
    return (
      <>
        <input
          placeholder={'1'}
          type={'number'}
          defaultValue={value}
          onChange={this.onValueChange}
          min='1'
          style={{ width: '84px', flex: 'none' }}
        />
        <Dropdown
          options={UNIT_OPTIONS}
          defaultSelection={defaultSelection}
          onChange={this.onUnitChangeChange}
        />
      </>
    );
  }
}

export { FilterDate };

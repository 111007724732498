import React from 'react';
import _ from 'lodash';
import Nav from 'react-bootstrap/Nav';
import { Table } from '../../Common/Table/table';
import { IFilter } from '../../Common/Table/constant';
import moment from 'moment';
import { alertActions, appActions } from '../../../actions';
import { connect } from 'react-redux';
import {
  AlertActionsTypes,
  DashBoardDto as UserDto,
  BrandType,
  appConstants,
  generateId,
  IAppDispatchProps,
} from '../../../constants';
import { AppState } from '../../../helpers';
import { ToggleSwitch } from '../../Common/ToggleSwitch';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';
import './AllScans.scss';
import { SCANTYPE } from './scanType.enum';
import CheckPhishService from '../../../services/checkphish.service';
import {
  SCAN_HISTORY_COLUMNS_NON_BRAND_CP,
  SCAN_HISTORY_COLUMNS_NON_BRAND_EMAIL_SCANS,
} from './columns.constants';
import { Card } from 'react-bootstrap';

interface ILinkStateProp {
  user: UserDto;
}

interface ILinkDispatchProps {
  getBrandInfo: (brandType: BrandType) => any;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

type IAllScanProps = ILinkStateProp & ILinkDispatchProps & IAppDispatchProps;

interface IAllScanState {
  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedTab: string;
  scanType: SCANTYPE;
}

const tableTypes = [
  {
    label: 'All Scans',
    value: 'allScans',
  },
  {
    label: 'Bulk Scans',
    value: 'bulkScans',
  },
  {
    label: 'API Scans',
    value: 'apiScans',
  },
  {
    label: 'Email Scans',
    value: 'emailScans',
  },
];
export const DEFAULT_FILTERS = {
  allScans: [],
  bulkScans: [
    {
      id: generateId(10),
      filterMethod: {
        label: '',
        value: 'is',
      },
      filterBy: {
        label: '',
        value: 'scanCategory',
      },
      filterValue: 'Bulk Scan',
      filterType: '',
      hideFilter: true,
    },
    {
      id: generateId(10),
      filterMethod: {
        label: '',
        value: 'is',
      },
      filterBy: {
        label: '',
        value: 'source',
      },
      filterValue: 'bulk_',
      filterType: '',
      hideFilter: true,
    },
  ],
  apiScans: [
    {
      id: generateId(10),
      filterMethod: {
        label: '',
        value: 'is',
      },
      filterBy: {
        label: '',
        value: 'source',
      },
      filterValue: 'api_',
      filterType: '',
      hideFilter: true,
    },
  ],
  bulkAndApiScans: [
    {
      id: generateId(10),
      filterMethod: {
        label: '',
        value: 'isNot',
      },
      filterBy: {
        label: '',
        value: 'scanCategory',
      },
      filterValue: 'Email Scan',
      filterType: '',
      hideFilter: true,
    },
  ],
  emailScans: [
    {
      id: generateId(10),
      filterMethod: {
        label: '',
        value: 'is',
      },
      filterBy: {
        label: '',
        value: 'scanCategory',
      },
      filterValue: 'Email Scan',
      filterType: '',
      hideFilter: true,
    },
  ],
};

class Dashboard extends React.Component<IAllScanProps, IAllScanState> {
  private readonly checkPhishService: CheckPhishService;
  private tablePageNumber: number = 1;
  private readonly tableSort: any = {
    sortBy: 'createdTs',
    sortDirection: 'desc',
  };
  private _isMounted: boolean = false;

  constructor(props: IAllScanProps) {
    super(props);
    const startDate = moment().subtract(90, 'day');
    const endDate = moment();
    this.state = {
      startDate,
      endDate,
      selectedTab: tableTypes[0].value,
      scanType: SCANTYPE.SOLO,
    };
    this.checkPhishService = new CheckPhishService();
    window.document.title = 'All Scans | CheckPhish Platform';
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  onDateChange = (startDate: moment.Moment, endDate: moment.Moment, lastXDay: number) => {
    this.setCompState({
      startDate,
      endDate,
    });
    this.tablePageNumber = 1;
  };

  transformSource = (source: string, scanCategory: string): string => {
    if (scanCategory === 'Email Scan' && source === 'Bulk_Scan') {
      return scanCategory;
    }
    source = source.split('_').join(' ');
    return source;
  };

  getScanHistory = (query: any, filters: IFilter[], sort = undefined) => {
    query.pageNumber = query.pageNumber ? query.pageNumber - 1 : 0;
    query.startDate = query.startDate.format('YYYY-MM-DD');
    query.endDate = query.endDate.format('YYYY-MM-DD');
    const { scanType, selectedTab } = this.state;
    const finalFilters: IFilter[] = [...filters, ...DEFAULT_FILTERS[selectedTab]];
    return this.checkPhishService
      .getUrlScanData(scanType, query, finalFilters, sort)
      .then((res: any) => {
        const { total = 0, urlInfo } = res;
        let data = [];
        if (urlInfo) {
          data = urlInfo.map((source: any) => {
            return {
              url: source.srcUrl,
              imageUrl: source.imagePath,
              status: source.status,
              takedowns: source.takedowns || 0,
              source: this.transformSource(source.source, source.scanCategory),
              ipAddress: source.ip,
              networkOwner: source.as_description,
              disposition: source.disposition,
              firstSeen: source.firstSeenTs,
              createdTs: source.createdTs,
              urlSha256: source.urlSha256,
              timestamp: source.timestamp,
              brandId: source.brandId,
              brandDisplayName: source.brandDisplayName,
              subject: source.subject,
              sender: source.sender,
              ...(source.scannedBy && { scannedBy: source.scannedBy }),
            };
          });
        }

        // Save the query setting in local storage
        query.pageNumber++;

        return { data, total };
      });
  };

  onTablePageChange = (num: number) => {
    this.tablePageNumber = num;
  };

  getTabMapper = () => {
    const { scanType } = this.state;
    return {
      allScans: {
        columns: SCAN_HISTORY_COLUMNS_NON_BRAND_CP(scanType, false, true),
        api: this.getScanHistory,
      },
      bulkScans: {
        columns: SCAN_HISTORY_COLUMNS_NON_BRAND_CP(scanType, false, true),
        api: this.getScanHistory,
      },
      apiScans: {
        columns: SCAN_HISTORY_COLUMNS_NON_BRAND_CP(scanType, false, true),
        api: this.getScanHistory,
      },
      emailScans: {
        columns: SCAN_HISTORY_COLUMNS_NON_BRAND_EMAIL_SCANS(scanType, false, true),
        api: this.getScanHistory,
      },
    };
  };

  getScanDetails = () => {
    const { selectedTab } = this.state;
    const selectedTabData = tableTypes.filter(t => t.value === selectedTab)[0];
    const selectedTabMapper = this.getTabMapper();
    return {
      ...selectedTabMapper[selectedTab],
      label: selectedTabData.label,
    };
  };

  renderTable = () => {
    const { startDate, endDate, selectedTab, scanType } = this.state;
    const scanDetails = this.getScanDetails();
    return (
      <Card className={'allscans-table-container'}>
        <ToggleSwitch
          items={tableTypes}
          selected={selectedTab}
          onClick={e => this.setState({ selectedTab: e })}
        />
        <Table
          tableClassName={'allscans-table-component'}
          key={`${scanType}-${scanDetails.label}`}
          loadingWrapperClassName={'allscans-table-loading-wrapper'}
          titleTooltip={'List of all you scans in the selected time-frame and filters'}
          fetchApi={scanDetails.api}
          filterAppliedOnFetchApi
          columns={scanDetails.columns}
          sortBy={_.get(this, ['tableSort', 'sortBy'], 'createdTs')}
          sortDirection={_.get(this, ['tableSort', 'sortDirection'], 'desc')}
          startDate={startDate}
          endDate={endDate}
          initialPageNumber={this.tablePageNumber}
          onPageChange={this.onTablePageChange}
          type={appConstants.CONTENT_TYPE.CHECK_PHISH}
        />
      </Card>
    );
  };

  renderTabSection() {
    const { scanType } = this.state;
    const {
      user: { features },
    } = this.props;
    const soloFeature = features.filter(f => f.code === appConstants.FEATURE_CODE.SOLO_TEAM);
    return (
      <Nav activeKey={scanType} onSelect={key => this.setState({ scanType: key as SCANTYPE })}>
        <Nav.Item>
          <Nav.Link eventKey={SCANTYPE.SOLO}>My Scans</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={SCANTYPE.TEAM} disabled={soloFeature[0]?.enabled}>
            {this.props.runByClientApp({
              onBolster: () => 'Team Scans',
              onCheckPhish: () => 'Team Scans (coming soon)',
            })}
          </Nav.Link>
        </Nav.Item>
      </Nav>
    );
  }

  render() {
    const { user } = this.props;
    if (_.isEmpty(user.email)) {
      return <AuthenticationWrapper />;
    }

    return (
      <AuthenticationWrapper>
        <div className={'page-content allscans-page'}>
          {this.renderTabSection()}
          {this.renderTable()}
        </div>
      </AuthenticationWrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  runByClientApp: appActions.runByClientApp,
};

const connectedDashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export { connectedDashboard as AllScans };

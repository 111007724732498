import { Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Optional } from '../../types/common';

export interface IBrandSelectorProps {
  value: Optional<number>;
  options: { brandId: number; displayName: string }[];
  onChange: (value: Optional<number>) => void;
}

export function BrandSelector({ value, options, onChange }: IBrandSelectorProps) {
  const [selected, setSelected] = useState<number | 'none'>('none');

  useEffect(() => {
    if (value != null) {
      setSelected(value);
    } else {
      setSelected('none');
    }
  }, [value]);

  function valueSelected(selectedValue: string) {
    if (selectedValue === 'none') {
      onChange(null);
    } else {
      onChange(options.find(x => x.brandId === +selectedValue)?.brandId ?? null);
    }
  }

  return (
    <Form.Group controlId='brandSelector'>
      <Form.Control as='select' value={selected} onChange={v => valueSelected(v.target.value)}>
        <option value={'none'} disabled={true}>
          Select a Brand
        </option>
        {options.map(o => {
          return (
            <option key={o.brandId} value={o.brandId}>
              {o.displayName}
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
}

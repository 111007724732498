import React from 'react';
import { SearchTerms } from '../../../Ugc/Components/SearchTerms/SearchTerms';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import { DARK_WEB_TABLE_NAME } from '../../DarkWebConstants';

const DarkWebSearchTerms = () => {
  return <SearchTerms type={ugcType.Dark_Web} tableName={DARK_WEB_TABLE_NAME} />;
};

export default DarkWebSearchTerms;

import React from 'react';
import { IFilter } from './constant';
import './table.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ThemeContext from '../../../context/ThemeContext';
import { CloseIcon } from '../../../assets/SVGIcons';
interface IComponentProps {
  id: string;
  filter: IFilter;
  onRemove: (id: string) => void;
}

interface IComponentState {}

class TableFilterControllerAddedItem extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    columns: [],
  };
  static contextType = ThemeContext;

  onFilterRemove = () => {
    const { id } = this.props;
    this.props.onRemove(id);
  };

  render() {
    const { filterBy, filterMethod, filterValue, filterLabel, filterType } = this.props.filter;
    const display = `${filterBy.label} ${filterMethod.label} "${
      filterType === 'date' ? filterValue?.replace('-d', ' days') : filterLabel || filterValue
    }" `;

    return (
      <div className='added-filter-item-component'>
        <OverlayTrigger
          placement={'bottom'}
          overlay={
            <Tooltip id={'tooltip-toggle-logo-detection'} className={'table-source-url-tooltip'}>
              {display}
            </Tooltip>
          }
        >
          <div className='added-filter-item-detail long-text-ellipsis-1'>{display}</div>
        </OverlayTrigger>
        <div onClick={this.onFilterRemove}>
          <CloseIcon color='grey' />
        </div>
      </div>
    );
  }
}

export { TableFilterControllerAddedItem };

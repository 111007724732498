import React, { useContext } from 'react';
import moment from 'moment';

import { Button } from 'react-bootstrap';
import '../../Style/SocialMediaIntro.scss';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { IFeatureRequested, IMediaIntroProps } from '../../Types/ugc.types';
import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes } from '../../../../constants';
import DashboardService from '../../../../services/dashboard.service';
import { useAppDispatch } from '../../../../helpers/hooks';
import { alertActions } from '../../../../actions';
import CheckPhishContactUsContext from '../../../../context/CheckPhishContactUsContext';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
import { ReadOnlyTeaserPage } from './ReadOnlyTeaserPage';

const dashboardService = new DashboardService();

export default function UgclMediaIntro({
  featureRequestedData,
  setLoading,
  isLoading,
  setFeatureRequest,
  mediaTryOutProps,
}: {
  featureRequestedData: IFeatureRequested | undefined;
  setLoading: any;
  isLoading: boolean;
  setFeatureRequest: any;
  mediaTryOutProps: IMediaIntroProps;
}) {
  const { featureCode } = mediaTryOutProps;
  const isDemo = window.location.pathname.includes('premium');
  const { setShowContactUsV2, setTriggerContactUsModal } = useContext(CheckPhishContactUsContext);
  const dispatch = useAppDispatch();

  const triggerCheckphishContactUs = () => {
    setShowContactUsV2(true);
    setTriggerContactUsModal(true);
  };

  const onHandleTrialRequested = () => {
    if (isDemo) {
      return triggerCheckphishContactUs();
    }
    setLoading(true);
    const query = {
      productFeatureId: featureCode,
      featureRequested: true,
    };

    dashboardService
      .createFeatureRequest(query)
      .then((res: any) => {
        setFeatureRequest(res);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        dispatch(alertActions.error(err || 'Error while requesting feature trial '));
      });
  };

  const { featureName, featureDescription, staticDarkImage, staticLightImage, teaserVideo } =
    mediaTryOutProps;
  const { selectedTheme } = useContext(ThemeContext);
  // read-only user has different teaser page
  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) return <ReadOnlyTeaserPage featureName={featureName} />;

  return (
    <div className={'social-media-intro-container'}>
      <div className={'intro-text-wrapper'}>
        <div className={'social-media-protection-header'}>
          Add {featureName} Protection to Your Plan
        </div>
        <div className={'social-media-protection-description'}>{featureDescription}</div>
      </div>
      <div className={'try-out-button-wrapper'}>
        <LoadingWrapper isLoading={isLoading}>
          {featureRequestedData?.featureRequested === true ? (
            <div className={'trial-confirmation-text'}>
              Thank you for requesting a trial on
              {moment(featureRequestedData.createdTs).format(' MM-DD-YYYY')}. The Bolster team will
              contact you within 24 hours of receiving a request.
            </div>
          ) : (
            <Button variant='primary' onClick={onHandleTrialRequested}>
              Contact Us for A Free Trial
            </Button>
          )}
        </LoadingWrapper>
      </div>
      <div className={'social-media-protection-image-container'}>
        {teaserVideo || (
          <img
            alt='media-tryout-image'
            src={
              selectedTheme === ThemeModes.DARK.toLowerCase() ? staticDarkImage : staticLightImage
            }
            className={'social-media-protection-image'}
          />
        )}
      </div>
    </div>
  );
}

import { IFilter } from '../components/Common/Table/constant';

export type FilterTypes =
  | 'string'
  | 'ipv4'
  | 'number'
  | 'date'
  | 'brand'
  | 'options'
  | 'optionsString'
  | 'optionsMultiSelect'
  | '';

export type FilterMethods =
  | 'includes'
  | 'beginWith'
  | 'numberRange'
  | 'dateRange'
  | 'is'
  | 'isNot'
  | 'notIncludes'
  | 'isNull'
  | 'notBeginWith';

export enum SortDirectionTypes {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortingDto {
  sortBy: string;
  sortDirection: SortDirectionTypes;
}

export interface PagingDto {
  pageNumber: number;
  pageSize: number;
}

export interface SearchFilterDto {
  filterType: FilterTypes;
  filterBy: string;
  filterMethod: FilterMethods;
  filterValue: string;
}

export interface SearchBrandReportDto {
  sorting?: SortingDto;
  paging?: PagingDto;
  filters: IFilter[];
}

export enum BrandReportRange {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  CUSTOM = 'custom',
}

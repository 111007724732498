import React, { Fragment, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import './SideBar_v2.scss';
import { TyposquatSideBanner, PricingSideBanner } from '../DomainMonitoring/TyposquatSideBanner';
import CheckPhishSideBar from './CheckPhishV2/CheckPhishSideBar';
import { navItemIsActive, NavItems } from './TopNavigationBar';
import { DRAWER_SUB_ITEMS, EMenuType, MOBILE_L1_DRAWER } from './RouteOptions';
import { history } from '../../helpers';
import {
  BigArrowLeftIcon,
  BigArrowRightIcon,
  CloseNavIcon,
  OpenNavIcon,
  ChevronDownIcon,
} from '../../assets/SVGIcons';
import ActiveRoutesContext from '../../context/ActiveRoutesContext';
import useWindowSize from '../Common/WindowSizeCustomHook';
import { appConstants, featureIsAvailable } from '../../constants';
import ThemeContext from '../../context/ThemeContext';
import ActiveAppContext from '../../context/CheckphishActiveAppContext';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';

export default function SideBarV2({
  user,
  brandInfo,
  navItems,
  navHeader,
  onSelectedPage,
  hideSideBar,
  prevPage = null,
  currentPage = null,
  isCommunity,
  typosquatV2Enabled,
  onChange,
}: any) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [prevUrl, updatePrevUrl] = useState('/bulk-scan');
  const { activeRoute, getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);
  const windowWidth = useWindowSize().width;
  const { selectedTheme } = useContext(ThemeContext);
  const { selectedApp } = useContext(ActiveAppContext);
  const { showFeedbackPopup, setShowFeedbackPopup, setAfterCancelationFeedback } = useContext(
    CheckPhishContactUsContext,
  );

  useEffect(() => {
    setIsDrawerOpen(true);
  }, []);

  useEffect(() => {
    if (currentPage === '/web/dashboard') {
      if (windowWidth > 1200) {
        setIsDrawerOpen(true);
      } else {
        setIsDrawerOpen(false);
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    if (currentPage?.length && prevPage?.length) {
      const currentUrl = currentPage.split('/').slice(1, 4).join('/');
      const prevUrl = prevPage.split('/').slice(1, 4).join('/');
      //do not update the prev url if we are moving between tabs on insight page.
      if (currentUrl !== prevUrl) {
        updatePrevUrl(prevUrl);
      }
    }
  }, [currentPage, prevPage]);

  const onHideL2Drawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  if (
    hideSideBar ||
    !navItems ||
    (navItems[0]?.hideDrawer !== undefined && navItems[0]?.hideDrawer === true)
  ) {
    return null;
  }

  return (
    <div className={'side-bar-container' + (!isDrawerOpen ? ' border-right-only' : '')}>
      <div className={`${!isDrawerOpen ? 'open-drawer-button-wrapper' : ''}`}>
        {!isDrawerOpen && (
          <div onClick={onHideL2Drawer}>
            <OpenNavIcon color='grey' />
          </div>
        )}
      </div>
      <div
        className={
          'side-bar-web-2nd side-bar-web-level' +
          (isDrawerOpen ? '' : ' width-0') +
          (isCommunity ? ' auto-height-overflow' : '')
        }
      >
        {isCommunity ? (
          <AuthenticationWrapper>
            <CheckPhishSideBar
              hasTyposquatData={user.monitoredDomainPresent}
              selectedTheme={selectedTheme}
            />
          </AuthenticationWrapper>
        ) : (
          <>
            <div className={'second-level-header'}>
              {navHeader !== 'Main' && (
                <div
                  className={'right-arrow-icon'}
                  onClick={() =>
                    onSelectedPage &&
                    onSelectedPage(
                      MOBILE_L1_DRAWER(user, isCommunity, typosquatV2Enabled)['Main'],
                      'Main',
                    )
                  }
                >
                  <BigArrowLeftIcon color='black' />
                </div>
              )}
              <div className={'directory-container'}>
                <div className='brand-name'>{_.get(brandInfo, ['brand', 'displayName'], '')}</div>
                <div className='navHeader'> {navHeader} </div>
              </div>
              {isDrawerOpen && (
                <div onClick={onHideL2Drawer}>
                  <CloseNavIcon color='grey' />
                </div>
              )}
            </div>
            <div className={'second-level-body'}>
              {navItems.map((navItem: any = {}, index: number) => {
                const { subItems, type } = navItem;
                if (type === EMenuType.MENU_HEADER) {
                  if (subItems?.length > 0) {
                    return (
                      <ExpandableItem
                        key={navItem.id + index}
                        isCommunity={isCommunity}
                        navItem={navItem}
                        user={user}
                        onSelectedPage={onSelectedPage}
                        typosquatV2Enabled={typosquatV2Enabled}
                      />
                    );
                  } else {
                    const l1SubItems = DRAWER_SUB_ITEMS(user, typosquatV2Enabled, isCommunity)[
                      navItem.label
                    ];
                    const l2SubItems = l1SubItems[0].subItems;
                    if (l1SubItems.length === 1 && l2SubItems.length) {
                      return (
                        <ExpandableItem
                          key={navItem.id + index}
                          navItem={navItem}
                          user={user}
                          onSelectedPage={onSelectedPage}
                          subItems={l2SubItems}
                          typosquatV2Enabled={typosquatV2Enabled}
                        />
                      );
                    }
                  }
                }

                if (
                  navItem?.subItems?.length === 0 &&
                  (navItem.label === 'Dashboard' ||
                    navItem.label === 'SETUP' ||
                    navItem.label === 'Live Scan' ||
                    navItem.label === 'All Scans')
                ) {
                  return (
                    <Fragment key={index}>
                      <NavItems
                        user={user}
                        navItem={navItem}
                        subItemClassName={'dashboard-label-wrapper'}
                      />
                    </Fragment>
                  );
                }
                return (
                  <div className={'nav-sub-item-container'} key={index}>
                    {navItem.label &&
                      navItem.subItems.length > 0 &&
                      (navHeader === 'Main' ? (
                        <div
                          className={
                            'nav-sub-item-mobile' + (navItemIsActive(navItem) ? ' selected' : '')
                          }
                          onClick={event => {
                            onSelectedPage &&
                              onSelectedPage(
                                DRAWER_SUB_ITEMS(user, typosquatV2Enabled, isCommunity)[
                                  navItem.label
                                ],
                                navItem.label,
                              );
                            history.push(
                              navItem.disabled ? '' : navItem.href || window.location.pathname,
                            );
                          }}
                        >
                          {navItem.label}
                        </div>
                      ) : (
                        <div className={'nav-sub-item-label'}>{navItem.label}</div>
                      ))}
                    <div>
                      {navItem.subItems?.map((item: any, index: number) => {
                        return (
                          <Fragment key={index}>
                            <NavItems
                              user={user}
                              navItem={{ ...item, href: item.disabled ? null : item.href }}
                              prevUrl={prevUrl}
                              subItemClassName={`drawer-sub-item ${
                                item.disabled ? 'disabled-sub-item' : ''
                              }`}
                              activeRoute={activeRoute}
                              getParentRouteViaFindingStatus={getParentRouteViaFindingStatus}
                            />{' '}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {isDrawerOpen &&
        selectedApp.value === 'checkphish' &&
        isCommunity &&
        featureIsAvailable(
          user,
          appConstants.FEATURE_CODE.TYPOSQUATTING_CHECKPHISH_DASHBOARD_V2,
        ) && (
          <>
            <TyposquatSideBanner isDrawerOpen={isDrawerOpen} />
            <span
              className='feedback-text'
              onClick={() => {
                setShowFeedbackPopup(true);
                setAfterCancelationFeedback(false);
              }}
            >
              Submit Feedback
            </span>
          </>
        )}
    </div>
  );
}

export const ExpandableItem = ({
  navItem,
  user,
  onSelectedPage,
  subItems,
  typosquatV2Enabled,
  isCommunity,
}: any) => {
  const [expanded, setExpended] = useState(true);
  const { label } = navItem;
  subItems = subItems || navItem.subItems;

  const toggleExpended = () => {
    setExpended(!expanded);
  };

  return (
    <div
      className={'expandable-item-component nav-sub-item-container ' + (expanded ? 'expanded' : '')}
    >
      <div
        className={'nav-sub-item-mobile' + (navItemIsActive(navItem) ? ' selected' : '')}
        onClick={toggleExpended}
      >
        <div className={'expandable-item d-flex align-items-center justify-content-between w-100'}>
          <div>{label}</div>
          <ChevronDownIcon className='chevron-icon black' />
        </div>
      </div>
      <div className='nav-sub-items-container'>
        {_.map(subItems, subItem =>
          DRAWER_SUB_ITEMS(user, typosquatV2Enabled, isCommunity)[subItem.label] ? (
            subItem.id === '/marketplace/dashboard' && user?.brand !== 'bankofbolster' ? null : (
              <div
                key={subItem.id}
                className={'drawer-sub-item w-100' + (navItemIsActive(subItem) ? ' selected' : '')}
                onClick={event => {
                  onSelectedPage &&
                    onSelectedPage(
                      DRAWER_SUB_ITEMS(user, typosquatV2Enabled, isCommunity)[subItem.label],
                      subItem.label,
                    );
                }}
              >
                <div className='w-100'>{subItem.label}</div>
                <div>
                  <BigArrowRightIcon color='black' />
                </div>
              </div>
            )
          ) : (
            <NavItems
              key={subItem.id}
              user={user}
              navItem={subItem}
              subItemClassName={'drawer-sub-item'}
            />
          ),
        )}
      </div>
    </div>
  );
};

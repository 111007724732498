import React from 'react';
import SafeList from '../../../Ugc/Components/Findings/SafeList';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
  SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  SOCIAL_MEDIA_PATHNAME,
  SOCIAL_MEDIA_TABLE_NAME,
} from '../../constants';

const SocialMediaSafeList = () => {
  return (
    <SafeList
      type={ugcType.Social}
      tableId={SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID}
      urlColPathName={SOCIAL_MEDIA_PATHNAME}
      tableName={SOCIAL_MEDIA_TABLE_NAME}
    />
  );
};

export default SocialMediaSafeList;

import React, { useState } from 'react';
import { SocRequestedDocument } from '../../../types/url-event.types';
import { EventHistoryEntity } from '../../../types/event-history-entity.interface';
import { Nullable } from '../../../types/common';
import { ELoggedIn } from '../../../reducers';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { AlertIcon, ChevronDownIcon } from '../../../assets/SVGIcons';
import moment from 'moment';
import PillLabel from '../../Common/PillLabel';
import { UtilitySpinner } from '../../../assets/SVGIcons';
import './TakedownStatus.scss';
import { SocTakedownStatuses } from '../../../types/soc-takedown-statuses.enum';

interface ITakedownStatusProps {
  loggedIn: ELoggedIn;
  status: SocTakedownStatuses;
  actions?: Nullable<EventHistoryEntity<SocRequestedDocument>[]>;
}

function TakedownStatus({ loggedIn, status, actions }: ITakedownStatusProps) {
  const [iconColor, setIconColor] = useState('red');

  function actionNeededPopover(action: EventHistoryEntity<SocRequestedDocument>) {
    return (
      <Popover id={`action-needed-popover`}>
        <Popover.Content>
          <div className={'text-muted'}>
            {moment(action.data.timestamp).format('DD-MMM-YYYY, hh:mm A')}
          </div>
          <div className={'mt-3 mb-2'} style={{ maxWidth: '120px' }}>
            <PillLabel
              label={'Action Needed'}
              customStyle={{
                backgroundColor: 'red',
              }}
            />
          </div>
          <p>{action.data.message}</p>
          <p>
            To continue the takedown process, please use "Upload File/Add Comment" to submit
            required document.
          </p>
        </Popover.Content>
      </Popover>
    );
  }

  return (
    <div>
      {status === SocTakedownStatuses.ActionNeeded &&
        loggedIn === ELoggedIn.true &&
        actions?.length && (
          <div>
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={actionNeededPopover(actions[0])}
            >
              <Button
                variant={'outline-danger'}
                className='d-flex align-items-center border-0'
                onMouseOver={() => setIconColor('white')}
                onMouseLeave={() => setIconColor('red')}
              >
                <AlertIcon color={iconColor} />
                <span className={'mx-1'}>Action Needed</span>
                <ChevronDownIcon color={iconColor} />
              </Button>
            </OverlayTrigger>
          </div>
        )}
      {status === SocTakedownStatuses.TakedownInProgress && loggedIn === ELoggedIn.true && (
        <div className='d-flex align-items-center p-1'>
          {' '}
          <UtilitySpinner color='blue' />
          <div className='p-1 takedown-status-label'>Takedown In Progress</div>
        </div>
      )}
      {status === SocTakedownStatuses.TakenDown && loggedIn === ELoggedIn.true && (
        <div className='d-flex align-items-center'>
          <div className='takedown-dot'></div>
          <div className='p-1 takedown-status-label'>Takendown</div>
        </div>
      )}
    </div>
  );
}

export default TakedownStatus;

import React, { Fragment } from 'react';
import { LoadingWrapper } from '../../../../Common/LoadingWrapper';
import RowCard from '../../Common/RowCard';
import WidgetHeader from '../../Dashboard/common/WidgetHeader';
import { IDarkWebFindingData } from '../../Types/DarkWeb.types';

interface ISimilarFindingsSectionProps {
  findings: IDarkWebFindingData[];
  isLoading: boolean;
}

export default function SimilarFindingsSection({
  findings,
  isLoading,
}: ISimilarFindingsSectionProps) {
  return (
    <div className='recent-findings-container'>
      <div className='recent-finding-header'>
        <WidgetHeader headerName={'Similar Findings'} />
      </div>
      <LoadingWrapper isLoading={isLoading}>
        <div className='findings-wrapper'>
          {findings.map((data: IDarkWebFindingData, idx: number) => {
            return (
              <Fragment key={idx}>
                <RowCard data={data} />
              </Fragment>
            );
          })}
        </div>
      </LoadingWrapper>
    </div>
  );
}

import { FilterModel } from 'ag-grid-community';
import _ from 'lodash';
import moment from 'moment';
import { store } from '../../../../helpers';
import {
  AgFilterDisplayOptions,
  UTCTimeFormat,
  getDefaultEndDate,
  getDefaultStartDate,
  getDefaultUTCEndDate,
  getDefaultUTCStartDate,
} from './constants';
import { passwordTypeValueCheck } from '../../../Playbook/playbook-util';
import {
  convertCustomCalendarRangeToUtc,
  getCorrectUtcDateAndTime,
} from '../../../../services/ugc.service';

interface OriginalRangeStructure {
  [key: string]: {
    filterType: string;
    type: string;
    filter: number;
    filterTo: number;
  };
}

interface OriginalDateRangeStructure {
  [key: string]: {
    filterType: string;
    type: string;
    dateTo: number;
    dateFrom: number;
    customRange: boolean;
    defaultSelected: string;
  };
}

interface OriginalOptionStructure {
  [key: string]: {
    conditions: any[];
    operator: string;
    filterType: string;
    type: string;
    values?: any[];
  };
}

interface OriginalContainsStructure {
  [key: string]: {
    operator: string;
    conditions: any[];
    filterType: string;
    type: string;
    filter: string;
  };
}

interface TransformedStructure {
  [key: string]: string;
}

interface QueryStructure {
  startDate: string;
  endDate: string;
  timezone: string;
}

interface TransformedFilterStructure {
  must: TransformedStructure;
  mustNot: TransformedStructure;
  query: QueryStructure;
}

// api params are quite different from the filter params in the table
export function transformWebTableRangeParams(
  original: OriginalRangeStructure & OriginalDateRangeStructure,
): TransformedStructure {
  const transformed: TransformedStructure = {};
  for (const key in original) {
    if (key.includes('scan')) {
      const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
      transformed[camelCaseKey + 'Start'] = original[key].filter.toString();
      transformed[camelCaseKey + 'End'] = original[key].filterTo.toString();
    } else if (key === 'risk_score') {
      transformed['risk_score_start'] = original[key].filter.toString();
      transformed['risk_score_end'] = original[key].filterTo.toString();
    } else if (key === 'takedowns') {
      transformed['takeDownCountStart'] = original[key].filter.toString();
      transformed['takeDownCountEnd'] = original[key].filterTo.toString();
    } else if (key === 'first_seen_ts') {
      const firstSeenDateRange = getStartAndEndDate(original[key]);
      transformed['firstSeenStartDate'] = firstSeenDateRange.keyStartDate;
      transformed['firstSeenEndDate'] = firstSeenDateRange.keyEndDate;
    } else if (key === 'domain_registration_date') {
      const registrationDateRange = getStartAndEndDate(original[key]);
      transformed['domainRegistrationStartDate'] = registrationDateRange.keyStartDate;
      transformed['domainRegistrationEndDate'] = registrationDateRange.keyEndDate;
    } else if (key === 'current_disposition_ts') {
      const dispositionDateRange = getStartAndEndDate(original[key]);
      transformed['dispositionChangeStartDate'] = dispositionDateRange.keyStartDate;
      transformed['dispositionChangeEndDate'] = dispositionDateRange.keyEndDate;
    } else if (key === 'takedown_ts') {
      const takedownDateRange = getStartAndEndDate(original[key]);
      transformed['takedownStartDate'] = takedownDateRange.keyStartDate;
      transformed['takedownEndDate'] = takedownDateRange.keyEndDate;
    } else if (key === 'last_updated_ts') {
      const lastUpdatedRange = getStartAndEndDate(original[key]);
      transformed['lastUpdatedStartDate'] = lastUpdatedRange.keyStartDate;
      transformed['lastUpdatedEndDate'] = lastUpdatedRange.keyEndDate;
    } else if (key === 'created_ts' || key === 'lastScanned') {
      const lastFieldDateRange = getStartAndEndDate(original[key]);
      transformed['createStartDate'] = lastFieldDateRange.keyStartDate;
      transformed['createEndDate'] = lastFieldDateRange.keyEndDate;
    }
  }
  return transformed;
}

const getStartAndEndDate = (keyValues: any) => {
  let keyStartDate;
  let keyEndDate;
  if (keyValues.defaultSelected) {
    keyStartDate = moment(moment().subtract(parseInt(keyValues.defaultSelected), 'day')).format(
      'YYYY-MM-DD',
    );
    keyEndDate = moment().format('YYYY-MM-DD');
  } else {
    keyStartDate = moment(keyValues.dateFrom).format('YYYY-MM-DD');
    keyEndDate = moment(keyValues.dateTo).format('YYYY-MM-DD');
  }
  return { keyStartDate, keyEndDate };
};

export function transformWebOptionsParams(original: OriginalOptionStructure): TransformedStructure {
  const transformed: TransformedStructure = {};
  const optionKeyMap: { [key: string]: string } = {
    sub_brand_display_name: 'sub_brand_id',
    primary_domain: 'domain_name',
    ipAddress: 'ip_address',
    current_disposition: 'currentDisposition',
    scan_source: 'scanSource',
  };
  for (const key in original) {
    const targetKey = optionKeyMap[key] || key;
    transformed[targetKey] =
      original[key]?.type || original[key]?.conditions?.map((ele: any) => ele.type).join('|');
  }

  return transformed;
}

// handle tags filter only, original is like {tags: {filterType: "set", type: "in", values: ["tag1", "tag2"]}}
export function transformWebTagsParams(original: OriginalOptionStructure) {
  const transformed: TransformedStructure = {};
  const onlyNoTagsSelected = isOnlyNoTagsSelected(original['tags']?.values);
  const mapTagLabelToId = getMapTagLabelToId();
  // Any tags and No tags as special cases
  const joinTagsWithOR = `(${Object.values(mapTagLabelToId).join(' OR ')})`;
  if (original['tags']?.values?.includes('Any tags')) {
    transformed['tags.id'] = joinTagsWithOR;
  } else if (original['tags']?.values?.includes('No tags')) {
    transformed['tags.id'] = onlyNoTagsSelected
      ? joinTagsWithOR
      : `(${mapTagLabelToId['No tags']})`;
  } else {
    transformed['tags.id'] = `(${original['tags']?.values
      ?.map((label: string) => mapTagLabelToId[label])
      .join(' AND ')})`;
  }

  return { transformedTags: transformed, onlyNoTagsSelected };
}

// TODO need to create a new filter type for ip address, use startsWith instead of contains
export function transformWebTableContainsParams(
  original: OriginalContainsStructure,
): TransformedStructure {
  const transformed: TransformedStructure = {};
  const containsKeyMap: { [key: string]: string } = {
    ipAddress: 'ip',
    TLD: 'tld',
    as_description: 'asDescription',
  };
  for (const key in original) {
    const targetKey = containsKeyMap[key] || key;
    if (targetKey === 'ip') {
      const { ipAddressStart, ipAddressEnd } = transformIPfilter(original, key);
      transformed.ipAddressStart = ipAddressStart;
      transformed.ipAddressEnd = ipAddressEnd;
    } else {
      // country_code value should be converted to lower case because of the backend api
      if (targetKey === 'country_code' || targetKey === 'asDescription') {
        original[key].filter = original[key].filter?.toLowerCase();
      }
      const valueToGet =
        original[key]?.filter || original[key]?.conditions.map((ele: any) => ele.filter).join(',');
      transformed[targetKey] = '.*' + valueToGet + '.*';
    }
  }
  return transformed;
}

export function transformWebTableFilterToApiParams(original: any): TransformedFilterStructure {
  let transformed: TransformedStructure = {};
  let transformedNot: TransformedStructure = {};
  const query: QueryStructure = {
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(),
    timezone: moment.tz?.guess() || 'America/Los_Angeles',
  };
  for (const key in original) {
    if (original[key].type === 'inRange') {
      // date range filter, or other range filters
      if (original[key].filterType === 'date') {
        if (original[key].defaultSelected) {
          query.startDate = getDefaultStartDate();
          query.endDate = getDefaultEndDate();
        }
      }
      transformed = { ...transformed, ...transformWebTableRangeParams({ [key]: original[key] }) };
    } else if (
      original[key]?.type === AgFilterDisplayOptions.CONTAINS ||
      original[key]?.conditions?.every(
        (ele: any) => ele.type === AgFilterDisplayOptions.CONTAINS,
      ) ||
      original[key].type === AgFilterDisplayOptions.BEGINS_WITH
    ) {
      transformed = {
        ...transformed,
        ...transformWebTableContainsParams({ [key]: original[key] }),
      };
    } else if (
      original[key]?.type === AgFilterDisplayOptions.NOT_CONTAINS ||
      original[key]?.conditions?.every(
        (ele: any) => ele.type === AgFilterDisplayOptions.NOT_CONTAINS,
      ) ||
      original[key].type === AgFilterDisplayOptions.NOT_BEGIN_WITH
    ) {
      transformedNot = {
        ...transformedNot,
        ...transformWebTableContainsParams({ [key]: original[key] }),
      };
    } else if (original[key].filterType === 'set') {
      // tags filter
      const { onlyNoTagsSelected, transformedTags } = transformWebTagsParams({
        [key]: original[key],
      });
      if (onlyNoTagsSelected) {
        transformedNot = { ...transformedNot, ...transformedTags };
      } else {
        transformed = { ...transformed, ...transformedTags };
      }
    } else {
      // options filters
      transformed = { ...transformed, ...transformWebOptionsParams({ [key]: original[key] }) };
    }
  }

  return { must: transformed, mustNot: transformedNot, query };
}

function transformIPfilter(
  original: any,
  key: string,
): { ipAddressStart: string; ipAddressEnd: string } {
  const ipSubsets = original[key]?.filter?.split('.');
  const ipStart = [];
  const ipEnd = [];
  for (let i = 0; i < 4; i++) {
    ipStart.push(_.isEmpty(ipSubsets[i]) ? '0' : ipSubsets[i]);
    ipEnd.push(_.isEmpty(ipSubsets[i]) ? '255' : ipSubsets[i]);
  }
  const ipAddressStart = ipStart.join('.');
  const ipAddressEnd = ipEnd.join('.');

  return { ipAddressStart, ipAddressEnd };
}

export const transformUgcFilterToApiParams = (
  source: FilterModel,
  isAppStore: boolean,
): {
  filter: string;
  startDate: string;
  endDate: string;
} => {
  const parts: string[] = [];
  const dateRange = {
    startDate: getDefaultUTCStartDate(),
    endDate: getDefaultUTCEndDate(),
  };

  Object.keys(source).forEach(key => {
    const item = source[key];
    // special massage to be compatible with the backend api
    if (key === 'category_labels') {
      if (item.operator === 'OR') {
        const filteredCategories = item.conditions.map((condition: any) => condition.type);
        parts.push(`category_ids=ov.{${filteredCategories}}`);
        return;
      } else {
        parts.push(`category_ids=ov.{${item.type}}`);
        return;
      }
    }
    // search_term on app store, while search_term_labels on social media
    if (key.includes('search_term')) {
      parts.push(
        isAppStore ? `search_term_id=eq.${item.type}` : `search_term_ids=ov.{${item.type}}`,
      );
      return;
    }

    switch (item.filterType) {
      case 'date':
        if (item.type === 'inRange') {
          transformUgcDateFilterToApiParams(item, dateRange, key, parts, isAppStore);
        }
        break;
      case 'text':
        if (item.type === 'false' || item.type === 'true') {
          parts.push(`${key}=eq.${item.type}`);
        } else if (
          item.type === AgFilterDisplayOptions.CONTAINS ||
          item.type === AgFilterDisplayOptions.BEGINS_WITH
        ) {
          // support multiple url filter seprated by commas
          if (item.filter.includes(',') && ['url', 'src_url'].includes(key)) {
            const massagedStr: string = transformUgcMultiUrlFilter(item.filter, key);
            parts.push(massagedStr);
          } else {
            parts.push(`${key}=ilike.*${item.filter}*`);
          }
        } else if (
          item.type === AgFilterDisplayOptions.NOT_CONTAINS ||
          item.type === AgFilterDisplayOptions.NOT_BEGIN_WITH
        ) {
          if (item.filter.includes(',') && ['url', 'src_url'].includes(key)) {
            const massagedStr: string = transformUgcMultiUrlFilter(item.filter, key, true);
            parts.push(massagedStr);
          } else {
            parts.push(`${key}=not.ilike.*${item.filter}*`);
          }
        } else {
          parts.push(`${key}=eq.${item.type}`);
        }
        break;
      case 'set':
        // now only for tags, for priorities: 1. Any tags 2. No tags 3. other tags
        if (item.values) {
          const mapTagLabelToId = getMapTagLabelToId();
          const allTagIdsWithComma = Object.values(mapTagLabelToId).join(',');
          if (item.values.includes('Any tags')) {
            parts.push(
              !isAppStore ? `not.or=(tag_ids.is.null)` : `tags.id=in.(${allTagIdsWithComma})`,
            );
          } else if (item.values.includes('No tags')) {
            if (!isOnlyNoTagsSelected(item.values)) {
              parts.push(
                !isAppStore
                  ? `or=(tag_ids.cs.{${mapTagLabelToId['No tags']}})`
                  : `tags.id=in.(${mapTagLabelToId['No tags']})`,
              );
              break;
            }
            parts.push(
              !isAppStore ? `or=(tag_ids.is.null)` : `tags.id=not.in.(${allTagIdsWithComma})`,
            );
          } else {
            const tagIds = item.values.map((label: string) => mapTagLabelToId[label]);
            parts.push(
              !isAppStore
                ? `or=(tag_ids.cs.{${tagIds.join(',')}})`
                : `tags.id=in.(${tagIds.join(',')})`,
            );
          }
        }

        break;
    }
  });

  return {
    filter: parts.join('&'),
    ...dateRange,
  };
};

export const transformUgcDateFilterToApiParams = (
  item: any,
  dateRange: { startDate: string; endDate: string },
  key: string,
  filterParts: string[],
  isAppStore: boolean,
) => {
  if (item.defaultSelected) {
    if ((!isAppStore && key === 'created_ts') || key === 'first_seen_ts') {
      dateRange.startDate = moment(moment().subtract(parseInt(item.defaultSelected), 'day'))
        .utc()
        .format();
      dateRange.endDate = moment().utc().format();
    } else {
      filterParts.push(
        `${key}=gte.${moment(moment().subtract(parseInt(item.defaultSelected), 'day')).format(
          'YYYY-MM-DD',
        )}&${key}=lte.${moment().format('YYYY-MM-DD')}`,
      );
    }
  } else if ((!isAppStore && key === 'created_ts') || key === 'first_seen_ts') {
    dateRange.startDate = convertCustomCalendarRangeToUtc(item.dateFrom);
    dateRange.endDate = convertCustomCalendarRangeToUtc(item.dateTo);
  } else {
    filterParts.push(
      `${key}=gte.${moment(item.dateFrom).format('YYYY-MM-DD')}&${key}=lte.${moment(
        item.dateTo,
      ).format('YYYY-MM-DD')}`,
    );
  }
};

// dark web table
export const transformFilterToDarkWebApiFilterString = (source: FilterModel): string => {
  const parts: string[] = [];
  const filterTypeMap: { [key: string]: string } = {
    search_term: 'search_term_id',
  };
  const typesForEqDot = new Set(['true', 'false']);

  Object.keys(source).forEach(key => {
    const item = source[key];
    if (filterTypeMap[key]) {
      key = filterTypeMap[key];
    }
    switch (item.filterType) {
      case 'text':
        if (typeof item.type === 'number' || typesForEqDot.has(item.type)) {
          parts.push(`${key}=eq.${item.type}`);
        } else if (key === 'password_type') {
          const massagedStr = `${key}=in.(${passwordTypeValueCheck(item.type)})`;
          parts.push(massagedStr);
        } else {
          const prefix = item.type === 'contains' ? 'ilike' : 'not.ilike';
          parts.push(`${key}=${prefix}.*${item.filter}*`);
        }
        break;
      case 'set':
        // now only for tags
        if (item.values) {
          const mapTagLabelToId = getMapTagLabelToId();
          const allTagIdsWithComma = Object.values(mapTagLabelToId).join(',');
          if (item.values.includes('Any tags')) {
            parts.push(`${key}=in.(${allTagIdsWithComma})`);
          } else if (item.values.includes('No tags')) {
            parts.push(
              isOnlyNoTagsSelected(item.values)
                ? `${key}=not.in.(${allTagIdsWithComma})`
                : `${key}=in.(${mapTagLabelToId['No tags']})`,
            );
          } else {
            parts.push(
              `${key}=in.(${item.values.map((label: string) => mapTagLabelToId[label]).join(',')})`,
            );
          }
        }
        break;
      case 'date':
        {
          if (item.customRange) {
            parts.push(
              `${key}=gte.${moment(item.dateFrom).format('YYYY-MM-DD')}&${key}=lte.${moment(
                item.dateTo,
              ).format('YYYY-MM-DD')}`,
            );
          } else {
            parts.push(
              `${key}=gte.${moment(moment().subtract(parseInt(item.defaultSelected), 'day')).format(
                'YYYY-MM-DD',
              )}&${key}=lte.${moment().format('YYYY-MM-DD')}`,
            );
          }
        }
        break;
    }
  });
  return parts.join('&');
};

// get mapTagLabelToId from redux store
function getMapTagLabelToId() {
  const tags = store.getState().tagsReducer.allPlatformTags;
  const mapTagLabelToId: { [key: string]: number } = {};
  // sentinel values for Any tags and No tags, with no meaning in the backend, which will return empty result if used with other tags
  mapTagLabelToId['Any tags'] = -1;
  mapTagLabelToId['No tags'] = 0;
  tags.forEach((tag: { label: string | number; id: number }) => {
    mapTagLabelToId[tag.label] = tag.id;
  });
  return mapTagLabelToId;
}

// check if the array has only one element and it is 'No tags'
export function isOnlyNoTagsSelected(tagLabels: string[] = []): boolean {
  return tagLabels.length === 1 && tagLabels[0] === 'No tags';
}

function transformUgcMultiUrlFilter(filter: any, key: string, isNot: boolean = false): string {
  //regExp used to add the escape char on the mutliple url filters
  const parsedStr: string = filter
    .replace(/[.*+?&#^${}()/:|[\]\\]/g, '\\$&')
    .split(',')
    .map((value: string) => `${key}${isNot ? '=not.' : '.'}ilike.*${value.trim()}*`)
    .join(isNot ? '&' : ',');

  return isNot ? `&${parsedStr}` : `or=(${parsedStr})`;
}

export const transformBrandPropertyFilterToApiParams = (
  source: FilterModel,
): {
  filter: string;
} => {
  const parts: string[] = [];
  const filterTypeMap: { [key: string]: string } = {
    type: 'type_id',
  };
  const typesForEqDot = new Set(['true', 'false']);

  Object.keys(source).forEach(key => {
    const item = source[key];
    if (filterTypeMap[key]) {
      key = filterTypeMap[key];
    }
    switch (item.filterType) {
      case 'text':
        if (typeof item.type === 'number' || typesForEqDot.has(item.type)) {
          parts.push(`${key}=eq.${item.type}`);
        } else if (
          item.type === AgFilterDisplayOptions.CONTAINS ||
          item.type === AgFilterDisplayOptions.BEGINS_WITH
        ) {
          parts.push(`${key}=ilike.*${item.filter}*`);
        } else if (
          item.type === AgFilterDisplayOptions.NOT_CONTAINS ||
          item.type === AgFilterDisplayOptions.NOT_BEGIN_WITH
        ) {
          parts.push(`${key}=not.ilike.*${item.filter}*`);
        } else {
          parts.push(`${key}=eq.${item.type}`);
        }
        break;
    }
  });
  return {
    filter: parts.join('&'),
  };
};

export interface GlobalWebTakeDownFilter {
  field: string;
  operator: string;
  value: string;
  isNot: boolean;
}

export function transformToGlobalWebTakeDownFilterToApiParams(
  original: FilterModel,
): GlobalWebTakeDownFilter[] {
  const globalTakeDownStatusMap: { [key: string]: string } = {
    taken_down: 'complete',
    in_progress: 'pending',
  };
  const filterParams: GlobalWebTakeDownFilter[] = [];
  Object.keys(original).forEach(key => {
    const item = original[key];
    switch (item.filterType) {
      case 'text':
        if (item.type === 'contains' || item.type === 'notContains') {
          filterParams.push({
            field: key,
            operator: 'like',
            value: item.filter,
            isNot: item.type === 'contains' ? false : true,
          });
        } else {
          filterParams.push({
            field: key,
            operator: 'eq',
            value: globalTakeDownStatusMap[item.type] || item.type,
            isNot: false,
          });
        }
        break;
      case 'date':
        if (item.type === 'inRange') {
          const dateFilters = [
            { operator: 'gte', datePeriod: 'dateFrom' },
            { operator: 'lte', datePeriod: 'dateTo' },
          ];

          dateFilters.forEach(({ operator, datePeriod }) => {
            if (item[datePeriod]) {
              filterParams.push({
                field: key,
                operator: operator,
                value: item.customRange
                  ? getCorrectUtcDateAndTime(item[datePeriod], true)
                  : getCorrectUtcDateAndTime(item[datePeriod], false),
                isNot: false,
              });
            }
          });
        }
        break;
    }
  });
  return filterParams;
}

import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';
import { alertActions } from '../../../../actions';
import UgcService from '../../../../services/ugc.service';
import ModalOverlay from '../../../Common/ModalOverlay';
import {
  IFindingsTableItemProps,
  ISearchTermOptions,
  FindingStatusDropdownOptions,
} from '../../Types/ugc.types';
import { IAlertDispatchProps, IContentPlatform } from '../../../../constants';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Dropdown, IDropdownOption } from '../../../Common/Dropdown';
import { LabelAndValue } from '../../../Common/LabelAndValue';
import { IOption } from '../../../Common/DropdownMultiSelect';
import { UploadFiles } from '../../../Common/UploadFiles';
import TextArea from '../../../Common/TextArea';
import { ugcTypeAndInsightRoutes } from '../../constants';

const ugcService = new UgcService();
interface IModalProps {
  data: IFindingsTableItemProps[];
  show: boolean;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  type: string;
}
interface IPropsFromGlobalState {
  ugcPlatforms: IContentPlatform[];
}

interface IScanFindingValue {
  urls: string[];
  status: any;
  comments: string;
  initialFilePaths: string[];
  uploads: File[];
}

const SocUpdateDetectionStatusModal = ({
  data,
  show,
  onCancel,
  onSubmit,
  alertSuccess,
  alertError,
  type,
}: IModalProps & IPropsFromGlobalState & IAlertDispatchProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<IScanFindingValue>({
    urls: [],
    status: undefined,
    comments: '',
    initialFilePaths: [],
    uploads: [],
  });
  useEffect(() => {
    if (!show) {
      return;
    }
    setInitialValues();
  }, [show]);

  const setInitialValues = () => {
    const status = _.find(FindingStatusDropdownOptions(type), ['value', data[0].status]);
    setValues({
      urls: _.map(data, d => d.url),
      status,
      comments: '',
      initialFilePaths: [],
      uploads: [],
    });
  };

  const onModalCancel = () => {
    onCancel();
  };

  const onModalSubmit = () => {
    setIsLoading(true);
    const { status, comments, uploads } = values;
    const formData = new FormData();

    formData.append('sha256', _.map(data, d => d.sha256).join(','));
    formData.append('status', status.value);
    formData.append('comments', comments);
    formData.append('insightsLink', `${window.location.host}${ugcTypeAndInsightRoutes[type]}`);

    for (const i in uploads) {
      formData.append('uploads', uploads[i]);
    }

    ugcService
      .updateFindingStatus(formData, type)
      .then(() => {
        alertSuccess(`Congratulations! URL has been updated.`);
        onSubmit(data);
      })
      .catch(err => {
        console.error(err);
        alertError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateValue = useCallback(
    (value: any, field: string) => {
      setValues({
        ...values,
        [field]: value,
      });
    },
    [values],
  );

  const handleDropdownChange = (option: IDropdownOption, controlId: string) => {
    updateValue(option, controlId);
  };
  const handleFilesAdded = (files: File[], controlId: string) => {
    updateValue(files, controlId);
  };

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={'Update Detection Status'}
      onCancel={onModalCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Update Status'}
    >
      <div className='soc-edit-finding-modal'>
        {isLoading ? (
          <div className='flex-center w-100'>
            <Spinner className='spinner' animation='border' />
          </div>
        ) : (
          <Form className='edit-finding-form'>
            <Form.Group>
              <LabelAndValue
                label={'URL'}
                value={_.map(data, d => d.url)}
                direction={'column'}
                longTextLineNumberLimit={1}
                copyButton
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Dropdown
                boxStyle
                btnClassName='font-weight-normal'
                controlId='status'
                options={FindingStatusDropdownOptions(type)}
                ordersPersist
                onChange={handleDropdownChange}
                defaultSelection={values?.status}
              />
            </Form.Group>
            <Form.Group>
              <UploadFiles
                label='Uploads'
                onChangeHandler={handleFilesAdded}
                dragAndDrop
                controlId='uploads'
                initialFilePaths={values?.initialFilePaths}
                filesLimit={1}
              />
            </Form.Group>
            <TextArea label='Comments' onChange={updateValue} controlId='comments' />
          </Form>
        )}
      </div>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { contents } = state.appReducer;
  return {
    ugcPlatforms: contents?.[type]?.platforms || [],
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocUpdateDetectionStatusModal);

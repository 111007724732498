import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';
import { alertActions } from '../../../../actions';
import { IAlertDispatchProps, getSingleOrPluralForm } from '../../../../constants';
import UgcService from '../../../../services/ugc.service';
import ModalOverlay from '../../../Common/ModalOverlay';
import { IFindingsTableItemProps } from '../../Types/ugc.types';

const ugcService = new UgcService();
interface IModalProps {
  data: IFindingsTableItemProps[];
  show: boolean;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  type: string;
}

const SocDeleteDetectionModal = ({
  data = [],
  show,
  onCancel,
  onSubmit,
  alertSuccess,
  alertError,
  type,
}: IModalProps & IAlertDispatchProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onModalSubmit = () => {
    setIsLoading(true);
    ugcService
      .deleteFindings(data, type)
      .then(() => {
        onSubmit(data);
        const length = data.length;
        alertSuccess(`Successfully deleted ${length} ${getSingleOrPluralForm(length, 'URL')}`);
      })
      .catch(err => {
        console.error(err);
        const length = data.length;
        alertError(`Error on deleting ${length} ${getSingleOrPluralForm(length, 'URL')}.`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={'Delete URLs'}
      onCancel={onCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Delete Detections'}
    >
      <div className='delete-finding-modal'>
        <div>
          You have selected{' '}
          <b>
            {data.length} {getSingleOrPluralForm(data.length || 0, 'URL')}
          </b>{' '}
          to delete.
        </div>
        {_.map(data, (item, index) => (
          <div key={index} className='high-lighted-text'>
            {item?.url}
          </div>
        ))}
        <div>This action can't be undone.</div>
      </div>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocDeleteDetectionModal);

import React from 'react';

interface ITagWithIconLabel {
  children: any;
  label?: string;
  customClassName?: string;
  customStyle?: any;
}
const TagWithIconLabel = ({ children, label, customClassName, customStyle }: ITagWithIconLabel) => {
  return (
    <div className={customClassName ?? ''} style={customStyle ?? {}}>
      {children}
      {label && <div>{label}</div>}
    </div>
  );
};

export default TagWithIconLabel;

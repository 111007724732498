import { useEffect, useState } from 'react';

export const useAgGridCurrentPageRows = ({
  agRef,
  allRowData,
  currentPageNumber,
  currentPaginationSize
}: {
  agRef: React.MutableRefObject<any>;
  allRowData: any;
  currentPageNumber: number;
  currentPaginationSize: number
}) => {
  const [currentRowsOfPage, setCurrentRowsOfPage] = useState<object[]>([]);

  useEffect(() => {
    if (agRef.current?.api) {
      const getRenderedNodesPerPage = agRef?.current?.api?.getRenderedNodes();
      const nodesPresentOnRender = getRenderedNodesPerPage?.map((ele: any) => ele.data);
      const currentPageNodes =
        nodesPresentOnRender[0] !== undefined ? nodesPresentOnRender : allRowData;
      setCurrentRowsOfPage(currentPageNodes);
    }
  }, [allRowData, currentPageNumber, currentPaginationSize]);

  return { currentRowsOfPage };
};

import { ITags } from '../../../Common/Tags/Tags';

export enum EDarkWebFindingStatus {
  ACTIVE = 'ACTIVE',
  MITIGATED = 'MITIGATED',
  IGNORED = 'IGNORED',
}
export const DarkWebFindingStatusAction = {
  [EDarkWebFindingStatus.ACTIVE]: 'active',
  [EDarkWebFindingStatus.MITIGATED]: 'mitigated',
  [EDarkWebFindingStatus.IGNORED]: 'ignored',
};

export enum EDarkWebRiskLevel {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export interface IDarkWebFindingData {
  name: string;
  status: EDarkWebFindingStatus;
  risk_level: EDarkWebRiskLevel;
  title: string;
  threat_actor: string;
  url: string;
  network: string;
  platform: string;
  searchTerm: string;
  highlights: string;
  content: string;
  content_sha_256: string;
  sensitiveInfo: {};
  platform_published_ts: string;
  category: string;
  sensitiveInfoExists: boolean;
  tags: ITags[];
  url_sha256: string;
  created_ts: string;
}

export interface IDarkWebThreatActorData {
  threat_actor: string;
  languages: string[];
  last_chatters: string;
  lastScanned: string;
  firstSeen: string;
  categories: string[];
  telegramHandle: string;
  risk: Record<EDarkWebRiskLevel, number>;
}

import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { getSingleOrPluralForm } from '../../../../constants';
import ModalOverlay from '../../../Common/ModalOverlay';
import { IFindingsTableItemProps } from '../../Types/ugc.types';
import { Form } from 'react-bootstrap';
import { Dropdown, IDropdownOption } from '../../../Common/Dropdown';
import { SOC_TAKEDOWN_OPTIONS } from '../../constants';
import AssetsService from '../../../../services/assests.service';
import { DropdownMultiSelect, IOption } from '../../../Common/DropdownMultiSelect';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';

const assetsService = new AssetsService();

interface IModalProps {
  data: IFindingsTableItemProps[];
  platform: string;
  show: boolean;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  orgName?: string;
}

const SocTakedownDetectionModal = ({
  data = [],
  platform,
  show,
  onCancel,
  onSubmit,
  orgName,
}: IModalProps) => {
  const [type, setType] = useState('');
  const hqAddress = useRef('');
  const contact = useRef('');
  const officialWebsite = useRef('');
  const trademarks = useRef<IOption[]>([]);
  const selectedTrademarks = useRef<IOption[]>([]);

  useEffect(() => {
    assetsService.getBrandProperties<any>({ query: {}, filters: [], sort: {} }).then(res => {
      hqAddress.current = _.get(
        _.filter(res.properties, item => item.propertyType.value === 'hq_address'),
        [0, 'valueJson'],
      );
      contact.current = _.get(
        _.filter(res.properties, item => item.propertyType.value === 'contact'),
        [0, 'valueJson'],
      );
      officialWebsite.current = _.get(
        _.filter(res.properties, item => item.propertyType.value === 'official_website'),
        [0, 'valueString'],
      );
      trademarks.current = _.chain(res.properties)
        .filter(item => item.propertyType.value === 'trademark')
        .map(trademark => {
          return {
            value: trademark.valueJson,
            label: trademark.valueJson.label,
          };
        })
        .value();
      selectedTrademarks.current = trademarks.current;
    });
  }, []);

  const onPlatformSelected = (selections: IOption[]) => {
    selectedTrademarks.current = selections;
  };

  const onModalSubmit = () => {
    const takeEvent = new CustomEvent('findingTakedown', {
      detail: {
        data,
        platform,
        type,
        hqAddress: hqAddress.current,
        contact: contact.current,
        officialWebsite: officialWebsite.current,
        trademarks: selectedTrademarks.current,
        brandOwner: orgName,
      },
    });
    document.dispatchEvent(takeEvent);
    onSubmit(data);
  };

  const onSelectType = (selection: IDropdownOption) => {
    setType(selection.value);
  };
  const typeOptions = SOC_TAKEDOWN_OPTIONS[platform];

  return (
    <ModalOverlay
      show={show}
      isLoading={false}
      title={'Takedown URLs'}
      onCancel={onCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Takedown Detections'}
    >
      <div className='takedown-finding-modal'>
        <div>
          You have selected{' '}
          <b>
            {data.length} {getSingleOrPluralForm(data.length || 0, 'URL')}
          </b>{' '}
          for takedown.
        </div>
        {_.map(data, (item, index) => (
          <div key={index} className='high-lighted-text'>
            {item?.url}
          </div>
        ))}
        {platform && (
          <div>
            <Form.Group>
              <Form.Label>Report Type:</Form.Label>
              <Dropdown boxStyle options={typeOptions} onChange={onSelectType} />
              {type === 'trademark' && (
                <DropdownMultiSelect
                  label={'Trademarks:'}
                  placeholder='select trademark(s)'
                  initialSelections={selectedTrademarks.current}
                  options={trademarks.current}
                  boxStyle
                  onSubmit={onPlatformSelected}
                  updateOnChange
                  hideFooter
                />
              )}
            </Form.Group>
          </div>
        )}
      </div>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState) => {
  const { brandInfo } = state.dashboardReducer;
  return {
    orgName: brandInfo?.brand?.orgName,
  };
};

export default connect(mapStateToProps, undefined)(SocTakedownDetectionModal);

import './soc-takedown.scss';
import { AppState } from '../../helpers';
import { connect } from 'react-redux';
import React, { useContext, useState, useCallback } from 'react';
import { SocEmailTemplate } from './types/soc-email-template.interface';
import { useEffect, useRef } from 'react';
import { invoke } from '../../helpers/async';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { formatError } from '../../helpers/errors';
import { Link } from 'react-router-dom';
import { MANAGE_EMAIL_TEMPLATES_CREATE_PATH } from '../MonitorAndTakedown/constants';
import { Card, Dropdown, Nav, Form, Button } from 'react-bootstrap';
import { EyeGreyIcon } from '../../assets/SVGIcons';
import { SocConfirmDeleteModal } from './SocConfirmDeleteModal';
import { ThemeModes } from '../../constants';
import DotDotDot from '../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../assets/icons/DotDotDotWhite.svg';
import ThemeContext from '../../context/ThemeContext';
import { BrandSelector } from '../Common/BrandSelector';
import { Optional, Nullable } from '../../types/common';
import * as _ from 'lodash';
import { SocEmailTemplateSearchDto } from './types/soc-email-template-search-dto';
import { Pager } from '../Common/Pager';
import { SocEmailTemplatePreviewModal } from './SocEmailTemplatePreviewModal';
import { useReadOnlyUser } from '../../basic-hooks/useUserRoles';

const socTakedownService = new SocTakedownService();

const OptionsToggle = React.forwardRef<
  HTMLDivElement,
  { selectedTheme: string; onClick: (e: React.MouseEvent) => void }
>(({ selectedTheme, onClick }, ref) => (
  <div
    role='button'
    className='h6'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <img
      src={selectedTheme === ThemeModes.DARK.toLowerCase() ? DotDotDotWhiteIcon : DotDotDot}
      alt={'dotdotdot'}
    />
  </div>
));

export interface ISocTakedownTemplatesProps {
  formType: 'initiate' | 'template';
  triggerRefresh?: Nullable<number>;
  currentBrand: Optional<{ brandId: number; displayName: string }>;
  brandOptions: { brandId: number; displayName: string }[];
  onApply?: (template: SocEmailTemplate) => void;
  onEdit: (template: SocEmailTemplate) => void;
  onDelete: (template: SocEmailTemplate) => void;
}

function SocTakedownTemplates({
  formType,
  triggerRefresh,
  onApply,
  onEdit,
  onDelete,
  brandOptions,
  currentBrand,
}: ISocTakedownTemplatesProps) {
  const { selectedTheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState<SocEmailTemplate[]>([]);
  const [error, setError] = useState<string | null>();
  const [templateForDelete, setTemplateForDelete] = useState<SocEmailTemplate | null>(null);
  const [selectedTab, setSelectedTab] = useState<'generic' | 'brand'>('generic');
  const [selectedBrandId, setSelectedBrandId] = useState<Optional<number>>(currentBrand?.brandId);
  const [selectedPage, setSelectedPage] = useState(1);
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [previewTemplate, setPreviewTemplate] = useState<SocEmailTemplate | null>(null);
  const isMounted = useRef(false);

  const searchUpdate = useCallback(
    _.debounce(async (value: string) => {
      setError(null);
      setIsLoading(true);
      try {
        setSelectedPage(1);
        await fetchTemplates(value, selectedBrandId, 1);
      } catch (e) {
        setError(formatError(e));
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [selectedTab, selectedBrandId],
  );

  function confirmDelete() {
    const forDelete = templateForDelete;
    setTemplateForDelete(null);
    onDelete(forDelete as SocEmailTemplate);
  }

  async function fetchTemplates(searchText: string, brandId: Optional<number>, page: number) {
    const payload: SocEmailTemplateSearchDto = {
      limit: 5,
      offset: (page - 1) * 5,
    };

    if (searchText.trim().length > 0) {
      payload.name = searchText.trim();
      payload.description = searchText.trim();
    }

    if (selectedTab !== 'generic' && brandId != null) {
      payload.brandId = brandId;
    }

    const { results: templates, totalCount: total } = await socTakedownService.getEmailTemplates(
      payload,
    );
    if (isMounted.current) {
      setEmailTemplates(templates);
      setTotalCount(total);
    }
  }

  async function brandSelected(brandId: Optional<number>) {
    if (brandId !== selectedBrandId) {
      setSelectedPage(1);
      setSelectedBrandId(brandId);
      setError(null);
      setIsLoading(true);
      try {
        await fetchTemplates(currentSearchText, brandId, 1);
      } catch (e) {
        setError(formatError(e));
      } finally {
        setIsLoading(false);
      }
    }
    // if same brand no-op
  }

  async function pageChange(newPage: number) {
    setSelectedPage(newPage);
    setError(null);
    setIsLoading(true);
    try {
      await fetchTemplates(currentSearchText, selectedBrandId, newPage);
    } catch (e) {
      setError(formatError(e));
    } finally {
      setIsLoading(false);
    }
  }

  async function refresh(): Promise<void> {
    setError(null);
    setIsLoading(true);
    try {
      await fetchTemplates(currentSearchText, selectedBrandId, selectedPage);
    } catch (e) {
      setError(formatError(e));
    } finally {
      setIsLoading(false);
    }
  }

  function openPreviewModal(et: SocEmailTemplate) {
    setPreviewTemplate(et);
  }

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if ((triggerRefresh ?? 0) > 0) {
      refresh();
    }
  }, [triggerRefresh]);

  useEffect(() => {
    invoke(async () => {
      setError(null);
      setIsLoading(true);
      try {
        await fetchTemplates(currentSearchText, selectedBrandId, selectedPage);
      } catch (e) {
        if (isMounted.current) {
          setError(formatError(e));
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    });
  }, [currentBrand?.brandId, selectedTab]);

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) {
    return null;
  }

  return (
    <div className='pb-2'>
      <div className={'mb-5'}>
        <Nav
          fill
          variant='tabs'
          activeKey={selectedTab}
          onSelect={t => setSelectedTab(t as 'generic' | 'brand')}
        >
          <Nav.Item>
            <Nav.Link eventKey='generic'>Generic</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='brand'>Brand</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div>
        <div className={'px-2'}>
          <div className={'d-flex flex-column'}>
            <Link
              className={'btn btn-primary btn-lg'}
              to={MANAGE_EMAIL_TEMPLATES_CREATE_PATH}
              target={formType === 'initiate' ? '_blank' : undefined}
            >
              Add Template
            </Link>
          </div>
          <hr className={'soc-hr'} />
          {error && <div className={'text-danger'}>{error}</div>}

          {selectedTab === 'brand' && (
            <div>
              <BrandSelector
                value={selectedBrandId}
                options={brandOptions}
                onChange={value => brandSelected(value)}
              />
            </div>
          )}
          <div>
            <Form.Group controlId='searchTemplates'>
              <Form.Control
                name='searchTemplates'
                type='text'
                placeholder='Search Templates'
                value={currentSearchText}
                onChange={e => {
                  setCurrentSearchText(e.target.value);
                  searchUpdate(e.target.value);
                }}
              />
            </Form.Group>
          </div>
        </div>
      </div>
      <LoadingWrapper isLoading={isLoading}>
        <div className={'px-2'}>
          {emailTemplates.map(et => {
            return (
              <Card className={'mt-2'} key={et.id}>
                <Card.Header className={'pb-2 d-flex'}>
                  <div className={'h6 flex-grow-1'}>{et.name}</div>
                </Card.Header>
                <Card.Body>
                  <Card.Text className='text-secondary'>{et.description}</Card.Text>
                </Card.Body>
                <div className={'d-flex m-3 justify-content-end'}>
                  <Button
                    className='mr-2'
                    onClick={e => {
                      e.stopPropagation();
                      openPreviewModal(et);
                    }}
                  >
                    Preview
                  </Button>
                  {onApply && (
                    <Button className='mr-2' onClick={() => onApply(et)}>
                      {' '}
                      Apply{' '}
                    </Button>
                  )}
                  <Dropdown drop={'down'}>
                    <Dropdown.Toggle
                      selectedTheme={selectedTheme}
                      as={OptionsToggle}
                      id='dropdown-custom-components'
                    ></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey='1' onClick={() => onEdit(et)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey='2'
                        className='text-danger'
                        onClick={() => onDelete(et)}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card>
            );
          })}
        </div>
        <div className='d-flex justify-content-center mt-2'>
          <Pager
            page={selectedPage}
            pageSize={5}
            totalCount={totalCount}
            onPageChange={p => pageChange(p)}
          />
        </div>
        <SocConfirmDeleteModal
          show={templateForDelete != null}
          title={'Confirm Email Template Deletion'}
          onCancel={() => setTemplateForDelete(null)}
          onConfirm={confirmDelete}
        >
          Are you sure you would like to delete the email template: {templateForDelete?.name}?
        </SocConfirmDeleteModal>
        <SocEmailTemplatePreviewModal
          show={previewTemplate != null}
          template={previewTemplate as SocEmailTemplate}
          showApply={formType === 'initiate'}
          onApply={et => {
            setPreviewTemplate(null);
            onApply?.(et as SocEmailTemplate);
          }}
          onCancel={() => setPreviewTemplate(null)}
        />
      </LoadingWrapper>
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    brandInfo: state.dashboardReducer.brandInfo,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SocTakedownTemplates);

import React, { useCallback, useEffect, useMemo } from 'react';
import { TableContextProvider } from '../Common/Table/table.context';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { USER_GLOBAL_TAKEDOWN_TABLE_ID } from '../MonitorAndTakedown/constants';
import { setIsAgGridClientMode } from '../../reducers/table.reducer';
import { appConstants, isAdminUser, isInternalUser } from '../../constants';

import { AuthenticationWrapper } from '../AuthenticationWrapper';
import WebSocActionColRender, { ESocActionOrigin } from './WebSocActionColRender';
import { fetchGlobalTakedownWebData } from './web-requests';
import { SUBMITTED_COLUMNS } from './GlobalTakedownColDefs';
import _ from 'lodash';
import { setCurrentTableId } from '../../reducers/insightsContainer.reducer';
import { PageTitle } from '../Common/PageTitle';
import TakedownFilterWidget from './TakedownFilterWidget';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import './GlobalTakedown.scss';
import { massageGlobalTakeDownWebData } from './GlobalTakeDownUtils';
import AgGridGlobalTakedownTable from '../Common/Table/ag-table/AgGridGlobalTakedownTable';
export interface ICustomeFilterData {
  label: string;
  value: string;
  submittedByUser: number;
  submittedByBolster: number;
  commingSoon?: boolean;
}
export const GLOBAL_TAKEDOWN_TABLE_ID = 'global_takedown_table_web';
export default function GlobalTakedown() {
  window.document.title = 'Global Takedown | Bolster Platform';
  const [selectedModule, setSelectedModule] = React.useState<string>(appConstants.CONTENT_TYPE.WEB);

  const user = useAppSelector(state => state.dashboardReducer.user);
  const dispatch = useAppDispatch();
  const { gtdWebBolsterTakeDownCount, gtdWebCustomerTakeDownCount } = useAppSelector(
    state => state.tableReducer,
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [customFilterData, setCustomFilterData] = React.useState<ICustomeFilterData[]>([]);

  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    dispatch(setCurrentTableId(USER_GLOBAL_TAKEDOWN_TABLE_ID));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, [dispatch]);

  const getTotalCounts = () => {
    const submittedByBolster = gtdWebBolsterTakeDownCount;
    const submittedByUser = gtdWebCustomerTakeDownCount;
    const totalCount: ICustomeFilterData[] = [
      {
        label: 'All',
        value: 'all',
        submittedByUser,
        submittedByBolster,
        commingSoon: true, // TODO remove this once all the modules are ready
      },
      {
        label: 'Web',
        value: appConstants.CONTENT_TYPE.WEB,
        submittedByUser,
        submittedByBolster,
      },
      {
        label: 'Social Media',
        value: appConstants.CONTENT_TYPE.SOCIAL,
        submittedByUser,
        submittedByBolster,
        commingSoon: true, // TODO remove this once all the modules are ready
      },
      {
        label: 'App Store',
        value: appConstants.CONTENT_TYPE.APP_STORE,
        submittedByUser,
        submittedByBolster,
        commingSoon: true,
      },
    ];
    setCustomFilterData(totalCount);
  };

  useEffect(() => {
    getTotalCounts();
  }, [gtdWebBolsterTakeDownCount, gtdWebCustomerTakeDownCount]);

  const modifyColumns = useCallback(
    (columns: any[]) => {
      if (isInternalUser(user) && columns.every(column => column.id !== 'latest_gtd_comment')) {
        columns.push({
          id: 'latest_gtd_comment',
          accessor: 'latest_gtd_comment',
          header: 'Latest Comment',
          render: (data: any) => {
            if (_.isEmpty(data.latest_gtd_comment)) {
              return '--';
            }
            return data.latest_gtd_comment;
          },
          agFilterType: 'agTextColumnFilter',
        });
      }

      return columns;
    },
    [user],
  );

  const handleFilterChange = (value: string) => {
    setSelectedModule(value);
  };

  const columns = useMemo(() => {
    return modifyColumns(SUBMITTED_COLUMNS);
  }, [modifyColumns]);

  return (
    <AuthenticationWrapper>
      <div className='submitted-malicious-container page-content dashboard-page'>
        <PageTitle title='Global Takedown' />
        <div className='pt-3' data-testid={USER_GLOBAL_TAKEDOWN_TABLE_ID}>
          {customFilterData.length > 0 && (
            <TakedownFilterWidget
              filterData={customFilterData}
              selectedModule={selectedModule}
              handleFilterChange={handleFilterChange}
            />
          )}
          <LoadingWrapper isLoading={isLoading}>
            <TableContextProvider
              dashboardName={'Global Takedown'}
              tableId={GLOBAL_TAKEDOWN_TABLE_ID}
            >
              <AgGridGlobalTakedownTable
                tableId={GLOBAL_TAKEDOWN_TABLE_ID}
                columns={columns}
                style={{
                  height: 'calc(100vh - 204px)',
                  marginTop: '48px',
                  position: 'relative',
                }}
              />
            </TableContextProvider>
          </LoadingWrapper>
        </div>
      </div>
    </AuthenticationWrapper>
  );
}

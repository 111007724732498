import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { LabelAndValue } from '../../../../Common/LabelAndValue';
import {
  AlertActionsTypes,
  DashBoardDto,
  getLocalTimeFromUtcTime,
  getNavPathnameForInsightPage,
} from '../../../../../constants';
import { Link } from 'react-router-dom';
import Country from '../../../../Common/Country';
import Takedown from '../../../../Insights/takedown.component';
import { AppState } from '../../../../../helpers';
import { connect } from 'react-redux';
import { alertActions } from '../../../../../actions';
import Tags from '../../../../Common/Tags/Tags';

interface IAppStoreInsightSectionProps {
  data: any;
  type: string;
  scanCountInfo: any;
}
interface IAppStoreDispatchProps {
  user?: DashBoardDto;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

type Props = IAppStoreInsightSectionProps & IAppStoreDispatchProps;

const AppStoreScanResults = (props: Props) => {
  const { alertSuccess, alertError } = props;
  const {
    srcUrl,
    brandId,
    countryCode,
    ip,
    domainLabel,
    cert,
    firstSeenTs,
    createdTs,
    siteTitle,
    urlSha256,
  } = props.data;
  const { brandScanCount, bolsterScanCount, scanSource } = props.scanCountInfo;
  return (
    <>
      <Card bg='light' className='card-between'>
        <Tags rowData={props.data} type={props.type} />
        <Card.Header className='self-card-header'>Scan Results</Card.Header>
        <Card.Body className='selfcard-body'>
          <Row>
            <Col sm={6}>
              <LabelAndValue
                label={'Scan URL'}
                value={srcUrl}
                direction={'column'}
                longTextLineNumberLimit={1}
                copyButton
              />
              <LabelAndValue label={'Brand'} value={brandId} direction={'column'} />
            </Col>
            <Col sm={6}>
              <LabelAndValue
                label={'IP Address'}
                renderDom={
                  <Link
                    to={getNavPathnameForInsightPage() + '/ip/' + ip}
                    className={'height-20 ip-url-value-wrapper'}
                  >
                    {ip}
                  </Link>
                }
                direction={'column'}
              />
              <LabelAndValue label={'App Store'} value={domainLabel} direction={'column'} />
            </Col>
            <Col sm={6}>
              <LabelAndValue label={'App Name'} value={siteTitle} direction={'column'} />
              <LabelAndValue label={'Source'} value={scanSource || '--'} direction={'column'} />
            </Col>
            <Col sm={6}>
              <LabelAndValue
                label={'Location'}
                direction={'column'}
                renderDom={<Country countryCode={countryCode} />}
              />
              <LabelAndValue
                label={'First Seen'}
                value={getLocalTimeFromUtcTime(firstSeenTs, 'MMMM Do YYYY, h:mm:ss a')}
                direction={'column'}
              />
            </Col>
            <Col sm={6}>
              <LabelAndValue
                label={'# Customer Scans'}
                direction={'column'}
                value={brandScanCount}
              />
              <LabelAndValue
                label={'# Bolster Scans'}
                value={bolsterScanCount}
                direction={'column'}
              />
            </Col>
            <Col sm={6}>
              <LabelAndValue
                label={'Last Scanned'}
                value={getLocalTimeFromUtcTime(createdTs, 'MMMM Do YYYY, h:mm:ss a')}
                direction={'column'}
              />
            </Col>
            <Col sm={6}>
              <LabelAndValue
                label={'Certificate Details'}
                value={cert?.domains}
                direction={'column'}
                longTextLineNumberLimit={2}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};
const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppStoreScanResults);

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { EmailTemplateForm } from './types/email-template-form.interface';
import RichTextEditor from '../Common/RichTextEditor';
import { Editor as TinyMCEEditor } from 'tinymce';
import { EmailTemplateFormErrors } from './types/email-template-form-errors.interface';
import { Checkbox } from '../Common/Checkbox';
import { BrandSelector } from '../Common/BrandSelector';

export interface ISocEmailTemplateFormProps {
  form: EmailTemplateForm;
  brandOptions: { brandId: number; displayName: string }[];
  formChanged: (updated: EmailTemplateForm) => void;
  validationErrors: EmailTemplateFormErrors;
  showErrors: boolean;
}

function SocEmailTemplateForm({
  form,
  validationErrors,
  showErrors,
  brandOptions,
  formChanged,
}: ISocEmailTemplateFormProps) {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [isBranded, setIsBranded] = useState(form?.brandId == null ? false : true);

  useEffect(() => {
    if ((form?.ccEmails ?? '').length > 0) {
      setShowCc(true);
    }
  }, [form?.ccEmails]);

  useEffect(() => {
    if ((form?.bccEmails ?? '').length > 0) {
      setShowBcc(true);
    }
  }, [form?.bccEmails]);

  return (
    <div>
      <Form>
        <Form.Group className={'mt-4'} controlId='template_name'>
          <Form.Label className={'mr-3'}>Name:</Form.Label>
          <Form.Control
            name='template_name'
            autoComplete='template_name'
            type='text'
            value={form.name}
            onChange={v => {
              formChanged({ ...form, name: v.target.value });
            }}
            isInvalid={showErrors && validationErrors.name.length > 0}
          />
          {showErrors &&
            validationErrors.name.map(error => {
              return <div className='text-danger soc-validation-error'>{error}</div>;
            })}
        </Form.Group>

        <Form.Group className={'mt-4'} controlId='description'>
          <Form.Label className={'mr-3'}>Description: </Form.Label>
          <Form.Control
            type='text'
            value={form.description}
            onChange={v => {
              formChanged({ ...form, description: v.target.value });
            }}
            isInvalid={showErrors && validationErrors.description.length > 0}
          />
          {showErrors &&
            validationErrors.description.map(error => {
              return <div className='text-danger soc-validation-error'>{error}</div>;
            })}
        </Form.Group>

        <Form.Group controlId='toEmails'>
          <div className={'d-flex align-items-center'}>
            <div className={'flex-grow-1'}>
              <Form.Label className={'mr-3'}>To: </Form.Label>
              <Form.Control
                className={'flex-grow-1'}
                type='text'
                value={form.toEmails}
                onChange={v => {
                  formChanged({ ...form, toEmails: v.target.value });
                }}
                name={'toEmails'}
                autoComplete='email'
              />
            </div>
            <Button variant='link' className={'link-light'} onClick={() => setShowCc(true)}>
              Cc
            </Button>
            <Button variant='link' onClick={() => setShowBcc(true)}>
              Bcc
            </Button>
          </div>
        </Form.Group>

        {showCc && (
          <Form.Group className={'mt-4'} controlId='ccEmails'>
            <Form.Label className={'mr-3'}>Cc: </Form.Label>
            <Form.Control
              className={'flex-grow-1'}
              type='text'
              value={form.ccEmails}
              onChange={v => {
                formChanged({ ...form, ccEmails: v.target.value });
              }}
              name={'ccEmails'}
              autoComplete={'cc-email'}
            />
          </Form.Group>
        )}

        {showBcc && (
          <Form.Group className={'mt-4'} controlId='bccEmails'>
            <Form.Label className={'mr-3'}>Bcc: </Form.Label>
            <Form.Control
              className={'flex-grow-1'}
              type='text'
              value={form.bccEmails}
              onChange={v => {
                formChanged({ ...form, bccEmails: v.target.value });
              }}
              name={'bccEmails'}
              autoComplete='bcc-email'
            />
          </Form.Group>
        )}

        <Form.Group className={'mt-4'} controlId='subject'>
          <Form.Label className={'mr-3'}>Subject:</Form.Label>
          <Form.Control
            type='text'
            value={form.subject}
            onChange={v => {
              formChanged({ ...form, subject: v.target.value });
            }}
            isInvalid={showErrors && validationErrors.subject.length > 0}
          />
          {showErrors &&
            validationErrors.subject.map(error => {
              return <div className='text-danger soc-validation-error'>{error}</div>;
            })}
        </Form.Group>

        <div className={'mt-4'}>
          <Checkbox
            label='Is Branded'
            isStateless={false}
            onChange={checked => {
              setIsBranded(checked);
              if (!checked) {
                formChanged({ ...form, brandId: null });
              }
            }}
            defaultChecked={isBranded}
          />
        </div>

        {isBranded && (
          <Form.Group className={'mt-4'} controlId='brand'>
            <Form.Label className={'mr-3'}>Brand:</Form.Label>
            <BrandSelector
              value={form.brandId}
              options={brandOptions}
              onChange={v => formChanged({ ...form, brandId: v })}
            />
          </Form.Group>
        )}

        <Form.Label className={'mt-4'}>Body:</Form.Label>
        {showErrors &&
          validationErrors.bodyHtml.map(error => {
            return <div className='text-danger soc-validation-error'>{error}</div>;
          })}
        <div
          className={
            showErrors && validationErrors.bodyHtml.length > 0 ? 'border border-danger' : ''
          }
        >
          <RichTextEditor
            onInit={(_, editor) => (editorRef.current = editor)}
            onEditorChange={() => {
              formChanged({
                ...form,
                bodyHtml: editorRef.current?.getContent({ format: 'html' }) ?? '',
              });
            }}
            initialValue={form.initialBodyHtml}
          />
        </div>
      </Form>
    </div>
  );
}

export default SocEmailTemplateForm;

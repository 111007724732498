import React, { MouseEventHandler } from 'react';
import _ from 'lodash';
import './UploadFiles.scss';
import { UploadFilesIcon } from '../../assets/SVGIcons';

export interface UploadFilesButtonProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  label?: string;
}

export default function UploadFilesButton({
  onClick,
  label = 'Upload Files',
}: UploadFilesButtonProps) {
  return (
    <div className='upload-files-button' onClick={onClick}>
      <div>
        <UploadFilesIcon color='blue' />
      </div>
      <span>{label}</span>
    </div>
  );
}

import React from 'react';
import { Button } from 'react-bootstrap';
import { IDashboardHeader } from '../Types/dashboard.types';
import { DropdownDateRangePicker } from '../../Common/DropdownDateRangePicker';
import { Dropdown, IDropdownOption } from '../../Common/Dropdown';
import { PageTitle } from '../../Common/PageTitle';
import DotDotDot from '../../../assets/icons/DotDotDot.svg';
import AddWhite from '../../../assets/icons/AddWhite.svg';
import { appConstants } from '../../../constants';
import { titleMapper } from '../../Drawer/CheckPhishV2/Constants';
import { useParams } from 'react-router';

type ParamsType = {
  type: string;
};

const DashboardHeader = ({
  user,
  selectedDashboard,
  isEditingMode,
  onDashboardActionChange,
  onCancel,
  startDate,
  endDate,
  onDateRangeChange,
  isDemo = false,
}: IDashboardHeader): JSX.Element => {
  const { organization = '' } = user;
  const dashboardType = window.location.href.split('/').pop() || '';
  const DASHBOARD_ACTION_OPTIONS: IDropdownOption[] = [
    {
      label: 'Edit dashboard',
      value: 'edit',
    },
    {
      label: 'Set as Home dashboard',
      value: 'setDefault',
    },
    {
      label: 'Delete dashboard',
      value: 'delete',
    },
  ];

  if (selectedDashboard.isDefault) {
    DASHBOARD_ACTION_OPTIONS.splice(1, 1);
  }
  if (isEditingMode) {
    return (
      <div
        className={
          'dashboard-page-editing-header d-flex align-items-center justify-content-space-between'
        }
      >
        <input
          key={selectedDashboard.id}
          // ref={r => (this.dashboardNameInput = r)}
          className={'dashboard-name-input'}
          placeholder={'Dashboard Name'}
          defaultValue={selectedDashboard.label}
        />
        <div className={'dashboard-page-editing-header-buttons d-flex align-items-center'}>
          <div
            className={'add-section-button d-flex align-items-center'}
            // onClick={this.toggleSectionPicker}
          >
            <img src={AddWhite} alt={'Add'} />
            Add Section
          </div>
          <Button variant='outline-light' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant='light'
            // onClick={this.saveEditMode}
            disabled={
              selectedDashboard.widgets.length === 0 &&
              !selectedDashboard.table &&
              !selectedDashboard.monitoring
            }
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
  return (
    <PageTitle
      title={isDemo ? `${titleMapper[dashboardType] || ''} Demo Dashboard` : 'Dashboard'}
      className={'dashboard-page-title'}
      tools={
        <div className={'d-flex align-items-center'}>
          <DropdownDateRangePicker
            key={'DropdownDateRangePicker'}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateRangeChange}
            type={appConstants.CONTENT_TYPE.WEB}
            user={user}
          />
          {/* TODO: will put this back after POC
          <Dropdown
            btnClassName={'dashboard-action-dropdown web-only'}
            options={DASHBOARD_ACTION_OPTIONS}
            onChange={onDashboardActionChange}
            hideArrow
            fixedPlaceholder={<img src={DotDotDot} alt={'Customise dashboard'} />}
          /> */}
        </div>
      }
    />
  );
};

export default DashboardHeader;

import React from 'react';
import { appConstants } from '../../../../constants';
import { widgetsTitle } from '../../../SocialMedia/constants';
import { Dashboard } from '../../../Ugc/Components/DashboardContainer';
import { IMediaIntroProps, ugcType } from '../../../Ugc/Types/ugc.types';
import AppStoreLight from '../../../../assets/App_Store_light.png';
import AppStoreDark from '../../../../assets/App_Store_dark.png';

const mediaTryOutProps: IMediaIntroProps = {
  featureName: 'App Store',
  featureDescription:
    'Detect and remove fake ads, counterfeit goods and accessories, and executive impersonations.',
  staticLightImage: AppStoreLight,
  staticDarkImage: AppStoreDark,
  featureCode: appConstants.FEATURE_CODE.APP_STORE,
};

const widgetsTitleContent = {
  breakdownByDetections: { ...widgetsTitle.breakdownByDetections },
  breakdownByCategory: { ...widgetsTitle.breakdownByCategory },
  breakdownByPlatforms: {
    ...widgetsTitle.breakdownByPlatforms,
    title: 'Top App Stores with Detections',
  },
};

const AppStoreDashboard = () => {
  return (
    <Dashboard
      type={ugcType.App_Store}
      mediaTryOutProps={mediaTryOutProps}
      widgetsTitle={widgetsTitleContent}
    />
  );
};

export default AppStoreDashboard;

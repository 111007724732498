import React from 'react';
import { InfoIcon } from '../../../../../assets/SVGIcons';
import '../../../Style/WidgetsHeader.scss';

interface IWidgetHeaderProps {
  headerName: string;
  headerDescription?: string;
  className?: string;
}

export default function WidgetHeader({
  headerName,
  headerDescription,
  className = '',
}: IWidgetHeaderProps) {
  return (
    <div className={`widget-header-wrapper ${className}`}>
      <div className='header-name d-flex align-items-center'>
        {/*
         JIRA: ZION-4917
         <InfoIcon color='grey' /> 
        */}
        <div>{headerName}</div>
      </div>
      {headerDescription && <div className='header-description'>{headerDescription}</div>}
    </div>
  );
}

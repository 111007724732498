import React, { useState, useEffect } from 'react';
import FindingActionModal from './FindingActionModal';
import UgcService from '../../../../services/ugc.service';
import {
  EFindingStatus,
  IFindingsTableItemProps,
  IFindingInsightDataProps,
  ERequestTakedown,
  ugcType,
} from '../../Types/ugc.types';
import _ from 'lodash';
import { Image } from '../../../Common/Image';
import Spinner from 'react-bootstrap/Spinner';
import { ugcTypeAndInsightRoutes, ugcTypeAndLiveRoutes } from '../../constants';
import { useAppDispatch } from '../../../../helpers/hooks';
import { setShouldTriggerAgGrigRefresh } from '../../../../reducers/table.reducer';
import { history } from '../../../../helpers';

const ugcService = new UgcService();

interface IInitiateTakedownModalProps {
  data: IFindingsTableItemProps[] | IFindingInsightDataProps[];
  show: boolean;
  onCloseModal: () => void;
  onModalSubmit?: (data: any) => void;
  title?: string;
  description?: string;
  submitButtonLabel?: string;
  isResumeTakedown?: boolean;
  type: string;
}

const RequestTakedownModal = (props: IInitiateTakedownModalProps) => {
  const {
    data,
    show,
    title = 'Confirm to Initiate Takedown',
    description = 'Are you sure you want to initiate takedown of ',
    submitButtonLabel = 'Initiate Takedown',
    isResumeTakedown = false,
    type,
  } = props;
  const dispatch = useAppDispatch();

  const [details, setDetails] = useState(
    isResumeTakedown ? <Spinner className='spinner' animation='border' size='sm' /> : null,
  );

  useEffect(() => {
    if (show && isResumeTakedown && data?.length === 1) {
      ugcService.getFindingTakedown(data[0].sha256, type).then((takedown: any) => {
        const { history = [] } = takedown;
        if (history.length > 0) {
          const lastHistory = _.chain(history).orderBy(['updatedTs'], ['desc']).first().valueOf();
          setDetails(
            <div>
              {lastHistory?.comments}
              {lastHistory?.uploads?.[0]?.url && (
                <Image imagePath={lastHistory.uploads[0].url} alt={'pause-screenshot'} />
              )}
            </div>,
          );
        }
      });
    }
  }, [data, show]);

  const handleRedirect = () => {
    const isFromInsights = window.location.pathname.includes('insights');
    if (isFromInsights) {
      history.push(ugcTypeAndLiveRoutes[type]);
    } else {
      dispatch(setShouldTriggerAgGrigRefresh(true));
    }
  };

  const onModalSubmit = ({ data, files, comment }: any) => {
    const formData = new FormData();
    formData.append('comments', comment);
    formData.append('takedownStatus', EFindingStatus.IN_PROGRESS);
    formData.append('insightsLink', `${window.location.host}${ugcTypeAndInsightRoutes[type]}`);

    files.forEach((file: File) => formData.append('uploads', file));

    if (type === ugcType.App_Store) {
      return ugcService
        .initiateTakedownAppStoreFinding(
          { urlSha256: data.map((d: any) => d.urlSha256), comments: comment, files },
          ugcType.App_Store,
        )
        .then(() => {
          props.onModalSubmit && props.onModalSubmit(data);
          handleRedirect();
        });
    } else {
      if (data.length > 1) {
        formData.append(
          'sha256s',
          data.map((d: any) => d.sha256),
        );
        return ugcService
          .initiateTakedownFinding(formData, type, ERequestTakedown.Bulk)
          .then(() => {
            props.onModalSubmit && props.onModalSubmit(data);
            handleRedirect();
          });
      } else {
        return ugcService
          .initiateTakedownFinding(formData, type, undefined, data?.[0].sha256)
          .then(() => {
            props.onModalSubmit && props.onModalSubmit(data);
            handleRedirect();
          });
      }
    }
  };

  return (
    <FindingActionModal
      {...props}
      title={title}
      data={data}
      description={description}
      details={details}
      displayUrls={!isResumeTakedown}
      submitButtonLabel={submitButtonLabel}
      onModalSubmit={onModalSubmit}
      successMessage={`Congratulations! Your takedown request has been received.`}
      failureMessage={`Failure in takedown request for ${data.map((d: any) => d.url).join(', ')}`}
      type={type}
    />
  );
};

export default RequestTakedownModal;

import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './typosquatbanner.scss';
import CheckPhishContactUsContext, {
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import { useContext } from 'react';
import { UpgradeStripeV2 } from '../Common/UpgradeStripe';
import { EPlanProduct } from '../Common/Subscription';
import { history } from '../../helpers';
import { PRICING_PAGE_PATH, SCROLL_TO_COMPARISION } from '../Pricing/constants';
import { setLocalStorageValue } from '../../constants';

interface ITyposquatSideBanner {
  isDrawerOpen: boolean;
}

interface IPricingSideBanner extends ITyposquatSideBanner {
  onChange: () => any;
  user: any;
}

const TyposquatSideBanner = ({ isDrawerOpen }: ITyposquatSideBanner) => {
  const { currentTyposquatSubscription } = useContext(CheckphishSubscriptionContext);
  return (
    <div className={'bottom-items' + (isDrawerOpen ? '' : ' width-0')}>
      <div className={'upgrade-wrap' + (isDrawerOpen ? '' : ' width-0')}>
        <div className='main-head'>
          {currentTyposquatSubscription ? 'STARTER Plan' : 'FREE'}
          <div className='sub-head'>
            {currentTyposquatSubscription
              ? 'Typosquat Monitoring'
              : 'Up to 300 Typosquats and Live Scan results'}
          </div>
        </div>
        <div className='content'>
          {currentTyposquatSubscription
            ? `Need to detect and identity phishing URLs that mimic your brand's visual identity beyond typos only ?`
            : 'Need to unlock all results to monitor and receive alerts on those changes ?'}
        </div>
        <Button className={'upgrade-btn'} onClick={() => history.push(PRICING_PAGE_PATH)}>
          Upgrade
        </Button>
      </div>
    </div>
  );
};

const PricingSideBanner = ({ isDrawerOpen, onChange, user }: IPricingSideBanner) => {
  const [info, setInfo] = useState(() => {});

  const [liveScanSubscriptionPlan, setLiveScanSubscriptionPlan] = useState<any>(undefined);

  const upgrade = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'upgradeClicked',
      category: 'Page',
      action: 'clicked',
      label: 'upgradeClicked',
    });

    setInfo(() => (
      <UpgradeStripeV2
        liveScanSubscriptionPlan={liveScanSubscriptionPlan}
        successCallback={onSuccess}
        onHide={hideForm}
        className={'dashboard-upgrade-button'}
      />
    ));
  };

  const hideForm = () => {
    setInfo(() => {});
  };

  const onSuccess = () => {
    onChange();
  };

  useEffect(() => {
    // Show Upgrade Modal in dashboard if selections were made previously on plans page
    // And, clear the flag
    if (user && user.subscription) {
      const liveScanSubscriptionPlan = user.subscription.plans.filter(
        (plan: any) => plan.product === EPlanProduct.LIVE_SCAN,
      )[0];
      setLiveScanSubscriptionPlan(liveScanSubscriptionPlan);
    }
  }, [user]);

  return (
    <div className={'bottom-items' + (isDrawerOpen ? '' : ' width-0')}>
      <div className={'upgrade-wrap' + (isDrawerOpen ? '' : ' width-0')}>
        <div className='main-head'>
          FREE
          <div className='sub-head'>25 Live Scan Daily</div>
        </div>
        <div className='content'>Need more API scans and an advanced API dashboard?</div>
        <Button className={'upgrade-btn'} onClick={() => history.push(PRICING_PAGE_PATH)}>
          Upgrade
        </Button>
        {info}
      </div>
    </div>
  );
};

export { PricingSideBanner, TyposquatSideBanner };

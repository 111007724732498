import fetchApi from '../../services/api.service';
import { downloadApi, handleResponse, setPostRequestOptions } from '../../services/serviceWorker';
import { BrandReportRange } from '../../types/brand-reports-types';

export const getReports = async (apiParams: any): Promise<any> => {
  const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(apiParams));
  const rawResponse = await fetchApi('/platform-api/v1/brand-report/search', requestOptions).then(
    handleResponse,
  );
  return rawResponse;
};

export const getReportViaEmail = async (
  reportId: number,
  fileName: string,
  type: string,
  email: string,
) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      reportId,
      fileName,
      type,
      email,
    }),
  };
  const rawResponse = await fetchApi(
    '/platform-api/v1/brand-report/get-brand-report',
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const downloadReportPdf = async (reportId: number, fileName: string, modeType: string) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      reportId,
      fileName,
      type: modeType,
    }),
  };
  return downloadApi('/platform-api/v1/brand-report/get-brand-report', requestOptions);
};

export const generateBrandReport = async (
  module: string,
  dateRange: BrandReportRange,
  customerGenerated: boolean,
  startDate?: string,
  endDate?: string,
  prevStartDate?: string,
  prevEndDate?: string,
  ouId?: number,
) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      module,
      dateRange,
      customerGenerated,
      startDate,
      endDate,
      prevStartDate,
      prevEndDate,
      organizationUnitId: ouId,
    }),
  };

  const rawResponse = await fetchApi(
    '/platform-api/v1/brand-report/generate-report',
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

import moment from 'moment';
import fetchApi from '../../../services/api.service';
import { handleResponse, setPostRequestOptions } from '../../../services/serviceWorker';

interface IFetchApiParams {
  tableId: string;
  moduleType: string;
}

export interface FetchPersistentFiltersResponse {
  id: number;
  isUsers: boolean;
  platformTableId: number;
  defaultFilter: boolean;
  visibility: boolean;
  filter: {
    [key: string]: string;
  };
}
export interface ISavedFilters {
  moduleType: string;
  tableName: string;
  visibility: boolean;
  filter: {
    [key: string]: string;
  };
}
export const fetchPersistentFiltersData = async (
  apiParams: IFetchApiParams,
): Promise<FetchPersistentFiltersResponse[]> => {
  const { tableId, moduleType } = apiParams;

  const params = new URLSearchParams();
  params.append('moduleType', moduleType);
  params.append('tableName', tableId);
  const rawResponse = await fetchApi(
    `/platform-api/v1/persistent-filters?${params.toString()}`,
  ).then(handleResponse);
  return rawResponse;
};

export const saveNewPersistentFilter = async (apiParams: ISavedFilters) => {
  const requestOptions = setPostRequestOptions(JSON.stringify(apiParams));
  const rawResponse = await fetchApi(
    '/platform-api/v1/persistent-filters/filter',
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const deletePersistentFilter = async (apiParams: any) => {
  const requestOptions = setPostRequestOptions(JSON.stringify(apiParams));
  const rawResponse = await fetchApi(
    '/platform-api/v1/persistent-filters/delete/filters',
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const updatePersistentFilter = async (apiParams: any) => {
  const requestOptions = setPostRequestOptions(JSON.stringify(apiParams));
  const rawResponse = await fetchApi(
    '/platform-api/v1/persistent-filters/edit-filter',
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

import React, { Component } from 'react';
import './auth.scss';
import { CompanyIcon } from '../Common/CompanyIcon';
import { AppState, history } from '../../helpers';
import { userActions } from '../../actions';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import _ from 'lodash';

interface ITermsAndConditionsProps {
  acceptTermsAndCondition: any;
  acceptTermsAndConditionForSSO: any;
}

interface IPropsFromState {
  email: string;
  password: string;
}

type Props = ITermsAndConditionsProps & IPropsFromState;

interface ITermsAndConditionsState {
  errMsg: string;
  isLoading: boolean;
}

class TermsAndConditionsPage extends Component<Props, ITermsAndConditionsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errMsg: '',
      isLoading: false,
    };
    window.document.title = 'Terms and Conditions | Bolster Platform';
  }

  handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const { email, password, acceptTermsAndCondition, acceptTermsAndConditionForSSO } = this.props;
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    if (!_.isEmpty(email) && !_.isEmpty(password)) {
      acceptTermsAndCondition({ email, password })
        .then(() => {
          history.push('/dashboard');
        })
        .catch((err: any) => {
          this.setState({
            isLoading: false,
            errMsg: err,
          });
        });
    } else {
      acceptTermsAndConditionForSSO()
        .then(() => {
          history.push('/dashboard');
        })
        .catch((err: any) => {
          this.setState({
            isLoading: false,
            errMsg: err,
          });
        });
    }
  };

  render() {
    const { errMsg, isLoading } = this.state;

    return (
      <div>
        <div className={'auth-title '}>
          <CompanyIcon withName />
        </div>
        <div className={'auth-page-wrapper'}>
          <form name='form' onSubmit={this.handleSubmit}>
            <h3>Terms and conditions</h3>
            {errMsg && (
              <div className={'login-error'}>
                <div className={'help-block'}>{errMsg}</div>
              </div>
            )}
            <div className={'pt-4 info-text'}>
              By continuing, you certify that you have read and agree to our terms and conditions.
            </div>
            <div className={'bottom-component'}>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={'https://bolster.ai/terms-of-service'}
              >
                View terms and conditions
              </a>
              <div>
                <button type='submit' className={'btn btn-primary'} disabled={isLoading}>
                  {isLoading ? (
                    <Spinner className='spinner' animation='border' variant='light' size='sm' />
                  ) : (
                    'Agree and Continue'
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    email: state.login.email,
    password: state.login.password,
  };
};

const mapDispatchToProps = {
  acceptTermsAndCondition: userActions.acceptTermsAndCondition,
  acceptTermsAndConditionForSSO: userActions.acceptTermsAndConditionForSSO,
};

const connectedTermsAndConditionsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditionsPage);
export { connectedTermsAndConditionsPage as TermsAndConditionsPage };

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, FilterChangedEvent, GetRowIdParams } from 'ag-grid-community';
import { useAgGridEvents } from './useAgGridEvents';
import { LicenseManager } from 'ag-grid-enterprise';
import { generateAgGridClientModeColumnDefs } from './ag-col-defs';
import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes, getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';

import { ITableApiColumn } from '../table.api';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import useOnClientSideTableExport from '../../CustomHooks/useOnClientSideExport';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { alertActions } from '../../../../actions';
import { TABLE_EMPTY_RESULTS_MESSAGE, paginationPageSizeSelector } from './constants';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { getAgGridThemeClassName } from './ag-utils';
import useOnAgGridClientModeTableRefresh from './useOnAgGridClientModeTableRefresh';
import {
  MANAGED_BY_AFFILIATED,
  MANAGED_BY_ORGANIZATION,
  MANAGED_BY_BOLSTER,
} from '../../../Assets_v2/constants';
import { ExportCSV } from '../../Export/ExportCSV';
import { CONNECTOR_TABLE_ID } from '../../../Assets_v2/constants';
import useAgGridFilterChange from './useAgGridFilterChange';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const AgGridClientModeTable = ({
  columns,
  tableId,
  rowData,
  showExportCSV,
  className,
  style,
  onAcquisitionItemCheck,
  selectedAcquisitionItems,
}: {
  columns: ITableApiColumn[];
  tableId:
    | 'domain_acquisition'
    | 'searchesTerm'
    | 'user_submitted_malicious'
    | 'global_takedown_table_web'
    | typeof CONNECTOR_TABLE_ID
    | typeof MANAGED_BY_AFFILIATED
    | typeof MANAGED_BY_ORGANIZATION
    | typeof MANAGED_BY_BOLSTER;
  rowData?: any[];
  showExportCSV?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onAcquisitionItemCheck?: (checked: boolean, item: any, selectedAcquisitionItems: any) => void;
  selectedAcquisitionItems?: any[];
}) => {
  const agRef = useRef<AgGridReact>(null);
  const { selectedTheme } = useContext(ThemeContext);
  const { displayedColumnsIDs, onTableSaveColumns } = useContext(TableContext);
  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);
  const dispatch = useAppDispatch();

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({ tableId });

  const colDefs = useMemo<ColDef[]>(() => {
    return generateAgGridClientModeColumnDefs({
      columns,
      firstRenderDisplayColumnIdSet,
      tableId,
      isAcquisitionTable:
        tableId === 'domain_acquisition' ||
        tableId === MANAGED_BY_AFFILIATED ||
        tableId === MANAGED_BY_ORGANIZATION ||
        tableId === MANAGED_BY_BOLSTER,
    });
  }, [columns, displayedColumnsIDs]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);

  const isFilterModelCacheUsed = useRef(false);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(params => {
    handleFilterClick();
    const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
    params.api.setFilterModel(filterModelCache);
    isFilterModelCacheUsed.current = true;
    const savedColumnState = getLocalStorageValue(['savedColumnState', tableId]);
    agRef.current?.api.applyColumnState({
      state: savedColumnState,
      applyOrder: true,
    });
  }, []);

  const isExportCSVCalled = useAppSelector(state => state.tableReducer.isExportCSVCalled);

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data._id}-${params.data.primary_domain}-${params.data.domain_sha256}-${params.data.id}-${params.data.asset_hash}-${params.data.url_sha256}`;
  }, []);

  const onSelectionChanged = useCallback(
    event => {
      //  if event is not triggered by user, return
      if (event.source?.includes('api')) return;
      const selectedRows: any = event.api.getSelectedRows();
      onAcquisitionItemCheck?.(true, selectedRows, selectedRows);
    },
    [onAcquisitionItemCheck],
  );

  const selectedAcquisitionItemsCheckSet = useMemo(() => {
    return new Set(selectedAcquisitionItems?.map(item => item.domain_sha256 || item.asset_hash));
  }, [selectedAcquisitionItems]);

  const { handleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  useEffect(() => {
    if (selectedAcquisitionItemsCheckSet.size && agRef.current && agRef.current.api) {
      agRef.current.api.forEachNode(node => {
        if (selectedAcquisitionItemsCheckSet.has(node.data.domain_sha256)) {
          if (!node.isSelected()) node.setSelected(true);
        }
      });
    }
  }, [selectedAcquisitionItemsCheckSet]);

  useOnClientSideTableExport(isExportCSVCalled, agRef, tableId, columns);

  const { handleFirstDataRendered, handleOnPaginationChanged } =
    usePersistentTablePageNumber(tableId);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData: rowData,
  });

  useOnAgGridClientModeTableRefresh(agRef);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme) + ` ${className}`}
      style={style}
    >
      {showExportCSV && (
        <ExportCSV
          onClick={() => {
            agRef.current?.api.exportDataAsCsv();
          }}
        />
      )}
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        rowModelType='clientSide'
        rowData={rowData}
        animateRows={false}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowSelection={'multiple'}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={handleFilterChanged}
        suppressRowClickSelection={true}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default AgGridClientModeTable;

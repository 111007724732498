import React, { useState, useRef } from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import ModalOverlay from '../../../Common/ModalOverlay';
import { UploadFiles } from '../../../Common/UploadFiles';
import TextArea from '../../../Common/TextArea';
import { alertActions } from '../../../../actions';
import { connect } from 'react-redux';
import { AlertActionsTypes, IAlertDispatchProps } from '../../../../constants';
import { ugcType } from '../../Types/ugc.types';
import AppStoreTakedownModal from '../../../AppStore/Components/Findings/common/AppStoreTakedownModal';

interface IFindingActionModalProps {
  data: any;
  show: boolean;
  title: string;
  description: string;
  details?: any;
  displayUrls?: boolean;
  onCloseModal: () => void;
  submitButtonLabel: string;
  onModalSubmit: (data: any) => Promise<any>;
  successMessage: string;
  failureMessage: string;
  noFileUpload?: boolean;
  type?: string;
}

const FindingActionModal = ({
  data,
  show,
  title,
  description,
  details,
  displayUrls = true,
  submitButtonLabel,
  onCloseModal,
  onModalSubmit,
  successMessage,
  failureMessage,
  noFileUpload,
  alertSuccess,
  alertError,
  type,
}: IFindingActionModalProps & IAlertDispatchProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const comment = useRef<string>('');
  const uploadFiles = useRef<File[]>([]);
  const onHandleFilesSelect = (files: File[]) => {
    uploadFiles.current = files;
  };

  const onChangeComments = (value: string) => {
    comment.current = value;
  };

  const onSubmit = () => {
    setIsLoading(true);
    onModalSubmit({ data, files: uploadFiles.current, comment: comment.current })
      .then(() => {
        alertSuccess(successMessage);
      })
      .catch(error => {
        console.log(error);
        alertError(failureMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ModalOverlay
      show={show}
      title={title}
      onCancel={onCloseModal}
      onSubmit={onSubmit}
      submitButtonLabel={submitButtonLabel}
      isLoading={isLoading}
    >
      <div className={'detection-action-modal'}>
        <Form.Group>
          <div className={'description'}>
            {description}
            <br />
            <b>{details}</b>
            {displayUrls && (
              <div className={'url-list scrollbar-hidden'}>
                <b>
                  {_.map(data, (item, i) => (
                    <div key={i} className={'long-text-ellipsis-1'}>
                      {item?.url}
                    </div>
                  ))}
                </b>
              </div>
            )}
          </div>
        </Form.Group>
        {!noFileUpload && (
          <Form.Group>
            <UploadFiles label='Add File' onChangeHandler={onHandleFilesSelect} dragAndDrop />
          </Form.Group>
        )}
        <TextArea label='Comments' onChange={onChangeComments} controlId='comments' />
      </div>
    </ModalOverlay>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(undefined, mapDispatchToProps)(FindingActionModal);

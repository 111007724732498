import * as React from 'react';
import './StepProgressv2.scss';
import { Stepper, Step } from 'react-form-stepper';
import { useState } from 'react';
import CompletedStep from '../../assets/icons/completedStep.svg';
import ProcessingStep from '../../assets/icons/processingStep.svg';
import PendingStep from '../../assets/icons/pendingStep.svg';
import _ from 'lodash';

interface IStep {
  stepId: number;
  label: string;
}

export interface IStepProgressBarProps {
  activeStep: number;
  textMsg?: string;
}

const steps: IStep[] = [
  {
    stepId: 0,
    label: 'Generating',
  },
  {
    stepId: 1,
    label: 'Identifying',
  },
  {
    stepId: 2,
    label: 'Scanning first 100',
  },
];

const srcList = [CompletedStep, ProcessingStep, PendingStep];

export function StepProgressBar({ activeStep, textMsg }: IStepProgressBarProps) {
  const [preLoading, setPreLoading] = useState<number>(0);
  const imgList: any[] = [];

  const generateImg = (src: string, alt: string) => {
    return <>{<img src={src} alt={alt} />}</>;
  };

  React.useEffect(() => {
    srcList.forEach((item: any) => {
      const img = new Image();
      img.src = item;
      img.onload = () => {
        imgList.push(img);
        setPreLoading(oldState => oldState + 1);
      };
    });
    return () => {
      setPreLoading(0);
    };
  }, []);

  const renderSteps = (stepId: number) => {
    const stepDiff = activeStep - stepId;
    let stepCase = 0;
    if (stepDiff > 0) stepCase = 1;
    if (stepDiff < 0) stepCase = -1;
    switch (stepCase) {
      case 0:
        return generateImg(ProcessingStep, 'processing-step-image');
      case 1:
        return generateImg(CompletedStep, 'completed-step-image');
      case -1:
        return generateImg(PendingStep, 'pending-step-image');
    }
  };

  return (
    <div className='stepper-wrap'>
      {preLoading > 2 ? (
        <>
          <Stepper
            className={'stepper'}
            activeStep={activeStep}
            connectorStyleConfig={{
              completedColor: '#4479f3',
              activeColor: '#4479f3',
              disabledColor: '#4479f3',
              size: 3,
              stepSize: '1.05em',
              style: 'solid',
            }}
          >
            {steps.map((item: IStep) => (
              <Step
                label={item.label}
                className={`step ${item.stepId === activeStep ? 'active' : ''}`}
                index={item.stepId}
              >
                {renderSteps(item.stepId)}
              </Step>
            ))}
          </Stepper>
          {textMsg && <div className='txt-msg'>{textMsg}</div>}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

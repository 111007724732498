import React from 'react';
import TakedownInProgress from '../../../Ugc/Components/Findings/TakedownInProgress';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import {
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  SOCIAL_MEDIA_TABLE_NAME,
  SOCIAL_MEDIA_PATHNAME,
} from '../../constants';

const SocialMediaTakendownInProgress = () => {
  return (
    <TakedownInProgress
      type={ugcType.Social}
      tableId={SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID}
      urlColPathName={SOCIAL_MEDIA_PATHNAME}
      tableName={SOCIAL_MEDIA_TABLE_NAME}
      enableSocInterface
    />
  );
};

export default SocialMediaTakendownInProgress;

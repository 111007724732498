import React, { useState, useRef, useEffect } from 'react';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';
import { DashboardSummary } from './Widgets/DashboardSummary';
import { AppState, history } from '../../../helpers';
import { alertActions, dashboardActions } from '../../../actions';
import { connect } from 'react-redux';
import { ILinkStateProp, ILinkDispatchProps } from './Types/dashboard.types';
import DashboardHeader from './DashboardHeader';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { WidgetsSection } from './Widgets/DashboardWidgetsSection';
import CheckPhishService from '../../../services/checkphish.service';
import {
  CHECKPHISH_SUMMARY_FIELDS,
  CHECKPHISH_SUMMARY_FIELDS_MAPPER,
} from './Constants/dashboardCP';
import { DEFAULT_DATE_RANGE } from '../../../constants';
import _ from 'lodash';

const initialSummaryData = [
  {
    id: '',
    label: '',
    value: '',
    tooltip: '',
  },
];

const initialWidgetsData = {
  topGenerationCountries: {},
  topGenerationBrands: {},
  top10PhishScamSiteHosting: {},
  phishAndScamSiteDetection: {},
  scansByCategories: {},
};
const initialUrlBySourceData = {
  bulk: 0,
  apli: 0,
  email: 0,
};

type Props = ILinkStateProp & ILinkDispatchProps;

const DashboardContainer: React.FC<Props> = (props: Props) => {
  const user = props.user;
  const [cookies] = useCookies(['checkphishChosenPlan']);
  const checkphishService = new CheckPhishService();
  const selectedDashboard = props.selectedDashboard;
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(DEFAULT_DATE_RANGE, 'day'),
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const lastXDay = useRef(DEFAULT_DATE_RANGE);
  const onDateRangeChange = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    lastXDayInput: number,
  ) => {
    lastXDay.current = lastXDayInput;
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const [summaryData, setSummaryData] = useState(initialSummaryData);
  const [widgetsData, setWidgetsData] = useState(initialWidgetsData);
  const [urlBySourceData, setUrlBySourceData] = useState<any>(initialUrlBySourceData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cookies.checkphishChosenPlan) {
      history.push('/account/profile?pricing=true');
      return;
    }
    setIsLoading(true);
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    Promise.all([checkphishService.getWidgetsData(query), checkphishService.getSummaryData(query)])
      .then((allResp: any[]) => {
        const [widgetResp, summaryResp] = allResp;
        const data: any = [];
        CHECKPHISH_SUMMARY_FIELDS.forEach(field => {
          const { fieldName, label } = field;
          const obj = {};
          obj['id'] = fieldName;
          obj['label'] = label;
          obj['value'] =
            Object.keys(summaryResp).indexOf(fieldName) !== -1 ? summaryResp[fieldName] : '--';
          obj['tooltip'] = label;
          data.push(obj);
        });
        setUrlBySourceData({
          [CHECKPHISH_SUMMARY_FIELDS_MAPPER.bulkScanNumber]: summaryResp['bulkScanNumber'],
          [CHECKPHISH_SUMMARY_FIELDS_MAPPER.apiCalls]: summaryResp['apiCalls'],
          [CHECKPHISH_SUMMARY_FIELDS_MAPPER.emailScanNumber]: summaryResp['emailScanNumber'],
        });
        setSummaryData(data);
        setWidgetsData(widgetResp);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  }, [startDate, endDate]);

  return (
    <AuthenticationWrapper>
      <DashboardHeader
        selectedDashboard={selectedDashboard}
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={onDateRangeChange}
        user={user}
      />
      <DashboardSummary isLoading={isLoading} summaryData={summaryData} />
      <div className={'page-content dashboard-page dashboard-page-v2'}>
        <WidgetsSection
          user={user}
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoading}
          widgetsData={{ ...widgetsData, urlBySource: urlBySourceData }}
        />
      </div>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { categories } = state.appReducer;
  const { user, selectedDashboard } = state.dashboardReducer;
  return {
    user,
    categories,
    selectedDashboard,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  getDashboardConfig: dashboardActions.getDashboardConfig,
  onDeleteDashboard: dashboardActions.onDeleteDashboard,
  onSetDefaultDashboard: dashboardActions.onSetDefaultDashboard,
  onChangeDashboard: dashboardActions.onChangeDashboard,
  onSaveDashboard: dashboardActions.onSaveDashboard,
  onDeleteWidgets: dashboardActions.onDeleteWidgets,
};

const connectedDashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
export { connectedDashboard as DashboardContainer };

import fetchApi from '../../services/api.service';
import { downloadApi, handleResponse, setPostRequestOptions } from '../../services/serviceWorker';

export const fetchBrandProperties = async (payload: any) => {
  const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(payload));
  const rawResponse = await fetchApi('/platform-api/v1/user/property/search', requestOptions).then(
    handleResponse,
  );
  return rawResponse;
};

export const addNewBrandProperty = async (payload: any) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    body: payload,
  };
  const rawResponse = await fetchApi('/platform-api/v1/user/property', requestOptions).then(
    handleResponse,
  );
  return rawResponse;
};

export const deleteBrandProperty = async (payload: any) => {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload.propertyIds),
  };
  const rawResponse = await fetchApi('/platform-api/v1/user/property', requestOptions).then(
    handleResponse,
  );
  return rawResponse;
};

export const downloadPdfAssets = async (payload: any) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: payload }),
  };
  return await downloadApi('/api/v1/user/property/get-pdf-document', requestOptions);
};

import { getDisplayTimeFromTimeStamp, isAdminUser, platformUserRolesMap } from '../../constants';
import { ITeamMemberData, IUserListItemDto } from './TeamMembers';

export const massagedTeamMembersData = (data: IUserListItemDto[], user: any): ITeamMemberData[] => {
  const teamMembers = data.map(item => {
    const { role } = item['user']?.['userAttribute'] || {};
    const { brandDisplayName } = item['user']?.['brand'] || {};

    return {
      email: item['email'],
      verified: item['isVerified']?.toString() === 'true' ? 'True' : 'False',
      firstName: item['user']?.['firstName'] || '--',
      lastName: item['user']?.['lastName'] || '--',
      roleId: platformUserRolesMap[role?.role_name] || role?.role_name || '--',
      teamMemberModule: item,
      lastActivity: getDisplayTimeFromTimeStamp(item['lastActiveTs']),
      editable: user && isAdminUser(user, false, false),
      brandId: brandDisplayName || '--',
      lastActiveTs: item['lastActiveTs'],
    };
  });
  return teamMembers;
};

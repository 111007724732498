import React, { useContext } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import WarningRedRoundIcon from '../../assets/icons/Warning-round-red.svg';
import ThemeContext from '../../context/ThemeContext';
import './typosquat.scss';
import { history } from '../../helpers';

const TyposquatBlurredModal = () => {
  const { selectedTheme } = useContext(ThemeContext);

  return (
    <div className={`${selectedTheme} typosquat-blurred-modal`}>
      <div className={`monitiored-container-v2`}>
        <Row>
          <Col md={12} className='d-flex no-padding'>
            <Col md={1} className='no-padding'>
              <img src={WarningRedRoundIcon} alt={'warning'} />
            </Col>
            <Col md={11} className='header no-padding' as={'p'}>
              Continue Monitoring Typosquats
            </Col>
          </Col>
          <Col md={12} as={'p'} className='no-padding'>
            Your 2-week domain monitoring has ended. <br /> Please{' '}
            <span className='capitalise'>add your work email</span> to continue access. <br /> The
            URL Scanner feature is still available.
          </Col>
          <Col md={12} className='no-padding'>
            <Button
              variant='primary'
              className='btn'
              onClick={() => history.push('/account/profile')}
            >
              Add Work Email
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TyposquatBlurredModal;

import _ from 'lodash';
import { getLocalStorageValue, setLocalStorageValue } from '../constants';

export function extractDomain(url: string) {
  const withoutProtocol = url.replace(/^(https?:|ftp:)?(\/\/)?/, '');

  // Extract domain and optional path
  const match = withoutProtocol.match(/^([^\?\/#]+(?:\.[a-zA-Z]{2,})+)/);

  // Check if there's a match and return the result
  return match ? match[1] : '';
}

export function hasTld(domain: any) {
  if (!_.isString(domain)) return false;
  // Define a regular expression pattern for matching TLDs
  const tldPattern = /\.([a-zA-Z]{2,})$/;

  // Use the pattern to test if the given domain has a valid TLD
  return tldPattern.test(domain);
}

export function countString(str: string, letter: string) {
  let count = 0;

  // looping through the items
  for (let i = 0; i < str.length; i++) {
    // check if the character is at that position
    if (str.charAt(i) == letter) {
      count += 1;
    }
  }
  return count;
}

export const specialChars = [',', '@', '$', '#', '%', '/'];

export const navItemIsActive = (href: string) => {
  const { pathname } = window.location;
  const prevPath = getLastVisitedPage();
  return pathname.includes('insights') ? _.isEqual(prevPath, href) : _.isEqual(pathname, href);
};

export const setLastVisitedPage = (prevUrl: any) => {
  if (_.isEmpty(prevUrl) || prevUrl.includes('insights')) return;
  setLocalStorageValue('lastVisitedPageCheckPhish', prevUrl);
};

export const getLastVisitedPage = () => {
  return getLocalStorageValue('lastVisitedPageCheckPhish');
};

export const getCurrentActivePage = () => {
  const lastVisitedTs = getLocalStorageValue('cpLastVisitedTs') || new Date();
  const currentTs = new Date();
  const diff = Math.abs(currentTs.getTime() - lastVisitedTs) > 1800000;
  if (diff) {
    setLocalStorageValue('currentActiveUrl', '');
  }
  setLocalStorageValue('cpLastVisitedTs', new Date());
  return getLocalStorageValue('currentActiveUrl');
};

import React from 'react';
import _ from 'lodash';
import { ITableApiColumn } from '../../../../Common/Table/table.api';
import { Link } from 'react-router-dom';
import RiskLevel from '../RiskLevel';
import {
  DARK_WEB_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME,
  DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME,
  DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME,
} from '../../../DarkWebConstants';
import RiskLevelGroup from '../RiskLevelGroup';
import SearchStatus from '../SearchStatus';
import {
  EDarkWebFindingStatus,
  EDarkWebRiskLevel,
  IDarkWebFindingData,
  IDarkWebThreatActorData,
} from '../../Types/DarkWeb.types';
import { capitalize, DISPLAYED_NULL, getDisplayTimeFromTimeStamp } from '../../../../../constants';
import PlatformDetails from '../PlatformDetails';
import AgGridDateRangeFilter from '../../../../Common/Table/ag-table/AgGridDateRangeFilter';

export const DEFAULT_VALUE_NO_PASSWORD = 'No Password';
export const TITLE_COLUMN_DYNAMIC_HEADER = (
  header: string,
  filterDisabled: boolean,
): ITableApiColumn => ({
  id: 'title',
  accessor: 'title',
  header,
  filterDisabled,
  render: (data: IDarkWebFindingData) => {
    const { title, status, content_sha_256 } = data;
    let urlPath = '';
    switch (status) {
      case EDarkWebFindingStatus.ACTIVE:
        urlPath = DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME;
        break;
      case EDarkWebFindingStatus.MITIGATED:
        urlPath = DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME;
        break;
      case EDarkWebFindingStatus.IGNORED:
        urlPath = DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME;
        break;
    }
    return (
      <div className={'table-source-url-column d-flex align-items-center'}>
        {header === 'Title' ? (
          <Link className='text-link' to={`${DARK_WEB_PATHNAME}/insights/${content_sha_256}`}>
            {title}
          </Link>
        ) : (
          <>{title}</>
        )}
      </div>
    );
  },
  agFilterType: 'agTextColumnFilter',
});

export const TITLE_COLUMN: ITableApiColumn = TITLE_COLUMN_DYNAMIC_HEADER('Title', false);

export const RISK_COLUMN: ITableApiColumn = {
  id: 'risk',
  accessor: 'risk_score',
  header: 'Risk',
  render: (data: IDarkWebFindingData) => {
    return <RiskLevel risk={data.risk_level} />;
  },
  type: 'options',
  filterOptions: _.map(EDarkWebRiskLevel, riskLevel => ({ value: riskLevel, label: riskLevel })),
  filterDisabled: true,
  fieldForExport: 'risk_level',
};

export const CCN_COLUMN: ITableApiColumn = {
  id: 'ccn',
  accessor: 'ccn',
  header: 'Credit Card Number',
  agFilterType: 'agTextColumnFilter',
  fieldForPlaybook: 'ccn',
};

export const CVV_COLUMN: ITableApiColumn = {
  id: 'cvv',
  accessor: 'cvv',
  header: 'CVV',
  agFilterType: 'agTextColumnFilter',
  fieldForExport: 'cvv',
};

export const CONTENT_COLUMN: ITableApiColumn = {
  id: 'content',
  accessor: 'content',
  header: 'Content',
  agFilterType: 'agTextColumnFilter',
};

export const PASSWORD_COLUMN: ITableApiColumn = {
  id: 'password',
  accessor: 'password',
  header: 'Password',
  agFilterType: 'agTextColumnFilter',
  fieldForPlaybook: 'password',
};

export const PASSWORD_TYPE_COLUMN: ITableApiColumn = {
  id: 'password_type',
  accessor: 'password_type',
  header: 'Type',
  agFilterType: 'agTextColumnFilter',
  render: ({ password_type }) => {
    if (_.isEmpty(password_type) || password_type === '--') return DEFAULT_VALUE_NO_PASSWORD;
    return capitalize(password_type);
  },
  type: 'options',
  filterOptions: [
    { label: 'Plain Password', value: 'plain' },
    { label: DEFAULT_VALUE_NO_PASSWORD, value: DEFAULT_VALUE_NO_PASSWORD },
    { label: 'Hashed Password', value: 'hashed' },
  ],
  fieldForPlaybook: 'password_type',
};

export const EXP_DATE_COLUMN: ITableApiColumn = {
  id: 'exp_date',
  accessor: 'exp_date',
  header: 'Expiry Date',
  type: 'options',
  fieldForPlaybook: 'exp_date',
  filterDisabled: true,
  agFilterType: 'agTextColumnFilter',
};

export const DATALEAK_SOURCE_COLUMN: ITableApiColumn = {
  id: 'platform',
  accessor: 'platform',
  header: 'Data Leak Source',
  agFilterType: 'agTextColumnFilter',
  render: (data: any) => {
    return <PlatformDetails data={data} />;
  },
};

export const STATUS_COLUMN_DYNAMIC_RENDER = (render?: any): ITableApiColumn => ({
  id: 'status',
  accessor: 'status',
  header: 'Status',
  sortDisabled: true,
  filterDisabled: true,
  ...(render && { render }),
});

export const STATUS_COLUMN: ITableApiColumn = STATUS_COLUMN_DYNAMIC_RENDER();

export const PLATFORM_COLUMN: ITableApiColumn = {
  id: 'platform',
  accessor: 'platform',
  header: 'Platform',
  render: data => {
    return <PlatformDetails data={data} />;
  },
  agFilterType: 'agTextColumnFilter',
};

export const CATEGORY_COLUMN: ITableApiColumn = {
  id: 'category',
  accessor: 'category',
  header: 'Category',
  filterDisabled: true,
};

export const THREAT_ACTOR_COLUMN: ITableApiColumn = {
  id: 'threat_actor',
  accessor: 'threat_actor',
  header: 'Threat Actor',
  render: (data: IDarkWebFindingData) => {
    const { threat_actor } = data;
    if (!threat_actor) {
      return DISPLAYED_NULL;
    }
    return (
      <div className={'table-source-url-column d-flex align-items-center'}>
        <Link to={`${DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME}/insights/${threat_actor}`}>
          {threat_actor}
        </Link>
      </div>
    );
  },
  agFilterType: 'agTextColumnFilter',
};

export const SEARCH_TERM_COLUMN_DYNAMIC = (header: string): ITableApiColumn => ({
  id: 'search_term',
  accessor: 'search_term',
  header,
  type: 'options',
  agFilterType: 'agTextColumnFilter',
  render: ({ search_term }) => {
    if (search_term.length > 1 && typeof search_term !== 'string') {
      let searchTermVal = '';
      for (let i = 0; i < search_term.length; i++) {
        searchTermVal += search_term[i];

        if (i !== search_term.length - 1) {
          searchTermVal += ', ';
        }
      }
      return searchTermVal;
    }
    return search_term;
  },
});

export const SEARCH_TERM_COLUMN: ITableApiColumn =
  SEARCH_TERM_COLUMN_DYNAMIC('Matched Search Terms');

export const DISCOVERY_DATE_COLUMN: ITableApiColumn = {
  id: 'platform_published_ts',
  accessor: 'platform_published_ts',
  header: 'Discovery Date',
  isDefaultSort: true,
  defaultSortDirection: 'desc',
  render: (data: IDarkWebFindingData) => {
    return getDisplayTimeFromTimeStamp(data.platform_published_ts);
  },
  filterDisabled: false,
  agFilterType: AgGridDateRangeFilter,
};

// Threat Actor table
export const CATEGORIES_COLUMN: ITableApiColumn = {
  id: 'categories',
  accessor: 'categories',
  header: 'Categories',
};
export const RELATED_INCIDENTS_COLUMN: ITableApiColumn = {
  id: 'relatedIncidents',
  accessor: 'risk',
  header: 'Related Incidents',
  render: (data: IDarkWebThreatActorData) => {
    return <RiskLevelGroup risks={data.risk} />;
  },
  sortDisabled: true,
  filterDisabled: true,
  hiddenInExport: true,
};
export const LAST_CHATTERS_COLUMN: ITableApiColumn = {
  id: 'last_chatters',
  accessor: 'last_chatters',
  header: 'Last Chatters',
  render: (data: IDarkWebThreatActorData) => {
    return getDisplayTimeFromTimeStamp(data.last_chatters);
  },
  filterDisabled: true,
  isDefaultSort: true,
  defaultSortDirection: 'desc',
};
export const LAST_SEARCH_COLUMN: ITableApiColumn = {
  id: 'lastSearch',
  accessor: 'lastSearch',
  header: 'Last Search',
  render: (data: any) => {
    return <SearchStatus data={data} />;
  },
};

export const SENSITIVE_DATA_EXISTS_COLUMN: ITableApiColumn = {
  id: 'sensitiveData',
  accessor: 'source_specific_info.has_sensitive_data',
  header: 'Sensitive Data',
  fieldForExport: 'has_sensitive_data',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  type: 'options',
  render: (data: any) => {
    const sensitiveInfo = data?.source_specific_info?.has_sensitive_data || false;
    return <div> {capitalize(sensitiveInfo.toString())} </div>;
  },
  agFilterType: 'agTextColumnFilter',
};

export const DARK_WEB_EMAIL_COL = {
  id: 'email',
  accessor: 'emails',
  header: 'Emails',
};

export const DARK_WEB_CCN = {
  id: 'ccn',
  accessor: 'ccns',
  header: 'Credit Card Numbers',
};

export const DARK_WEB_SSN = {
  id: 'ssn',
  accessor: 'ssns',
  header: 'Social Security Numbers',
};

export const DARK_WEB_IP = {
  id: 'ip',
  accessor: 'ips',
  header: 'IP Addresses',
};

export const DARK_WEB_CRYPTO = {
  id: 'crypto',
  accessor: 'cryptos',
  header: 'Cryptocurrency Addresses',
};

export const DARK_WEB_LAST_SCANNED_COLUMN = {
  id: 'created_ts',
  accessor: 'created_ts',
  header: 'Last Scanned',
  isDefaultSort: true,
  defaultSortDirection: 'desc',
  render: (data: IDarkWebFindingData) => {
    return getDisplayTimeFromTimeStamp(data.created_ts);
  },
  type: 'date',
  fieldForPlaybook: 'dw_last_scan',
  dateRangeFilterForPlaybook: 'dw_last_scan_start',
  filterDisabled: false,
  agFilterType: AgGridDateRangeFilter,
};

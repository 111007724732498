import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Card, Form, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { BulkScanForm } from './bulkscan.form';
import BulkScanService from '../../services/bulkscan.service';
import DashboardService from '../../services/dashboard.service';
import CheckPhishService from '../../services/checkphish.service';
import { PageTitle } from '../Common/PageTitle';
import { Table } from '../Common/Table/table';
import { DashboardTile } from '../Dashboard/dashboard.tile';
import './bulkscan.scss';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { IFilter } from '../Common/Table/constant';
import {
  AlertActionsTypes,
  appConstants,
  capitalize,
  DashBoardDto,
  featureIsAvailable,
  getLocalStorageValue,
  getProvidedTagLabelValue,
  getUsersListLabelValue,
  saveBulkscanTableParameters,
  ThemeModes,
  generateId,
  getDisplayTimeFromTimeStamp,
  UserAppSettings,
  IAppDispatchProps,
  setLocalStorageValue,
  ClientAppType,
  IRunByClientAppType,
  isBrandReadOnlyUser,
} from '../../constants';
import {
  FIRST_SEEN_COLUMN,
  TAKEDOWNS_COLUMN,
  LOGO_DETECTION_COLUMN,
} from '../Dashboard/dashboard.component';
import { AppState } from '../../helpers';
import { alertActions, appActions } from '../../actions';
import {
  CATEGORY_COLUMN,
  TAGS_AUTHOR_COLUMN,
  TAGS_COLUMN,
} from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import DisputeDispositionModal from './Common/DisputeDispositionModal';
import BulkScanTakedownRequestModal from './Common/BulkScanTakedownRequestModal';
import { BulkScanIcon } from '../../assets/SVGIcons';
import { BulkScanSelectedItem, IBulkScanState, IScanLocations } from './BulkScanConstants';
import Country from '../Common/Country';
import Tags from '../Common/Tags/Tags';
import { SCANTYPE } from '../Checkphish/AllScans/scanType.enum';
import { ITags } from '../Common/Tags/Tags';
import {
  DISPOSITION_COLUMN_CP,
  HOSTING_COLUMN_CP,
  IP_COLUMN_CP,
  SCANNED_BY_COLUMN_CP,
  URL_COLUMN_CP,
  BRAND_COLUMN_CP,
  SOURCE_COLUMN_CP,
  LAST_SCANNED_COLUMN,
  SCAN_LOCATION,
  WAIT_COLUMN,
} from '../Checkphish/AllScans/columns.constants';
import { DEFAULT_FILTERS } from '../Checkphish/AllScans/AllScans';
import { ITableApiColumn } from '../Common/Table/table.api';
import UserService from '../../services/user.service';
import AppSettingContext from '../../context/AppSettingContext';
import DeleteCustomTagsModal from '../Common/Tags/DeleteCustomTagsModal';
import EditCustomTagsModal from '../Common/Tags/EditCustomTagsModal';
export const DEFAULT_SCAN_TYPE = 'full';

interface ILinkStateProp {
  user: DashBoardDto;
  usersList?: any;
  providedTags?: any;
  match: any;
  selectedTheme: any;
  modifyCustomTag: any;
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
}

interface ILinkDispatchProps {
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

type BSProps = ILinkStateProp & ILinkDispatchProps & IAppDispatchProps;

const BulkScan: React.FC<BSProps> = ({
  user,
  alertSuccess,
  alertError,
  runByClientApp,
  modifyCustomTag,
  providedTags,
  selectedTheme,
  usersList,
}) => {
  const [bulkScanstate, setBulkScanState] = useState<IBulkScanState>({
    isLoading: false,
    scanType: DEFAULT_SCAN_TYPE,
    api_key: '',
    data: [],
    total: 0,
    selectedItems: [],
    disputeDispositionModalShown: false,
    takedownRequestModalShown: false,
    allUserAgents: [],
    refreshOnTag: false,
    isLayoutChanged: false,
    isBulkscanLoading: false,
    scanLevel: SCANTYPE.SOLO,
    teamView: false,
    displayLayout: false,
    isTorChecked: false,
  });

  const [tablePageNumber, setTablePageNumber] = useState(1);
  const [defaultSortBy, setDefaultSortBy] = useState('lastScanned');
  const [tableSort, setTableSort] = useState({ sortBy: 'lastScanned', sortDirection: 'desc' });
  const [tableFilters, setTableFilters] = useState<IFilter[]>([]);
  const [numOfFinish, setNumOfFinish] = useState(0);
  const [submittedUrls, setSubmittedUrls] = useState<string[]>([]);
  const [submittedScanLocations, setSubmittedScanLocations] = useState<string[]>([]);
  const [renderUrlAndTimestamp, setRenderUrlAndTimestamp] = useState<string[]>([]);
  const appSettings = useContext(AppSettingContext);

  const showTeamView = getLocalStorageValue('showTeamView');
  const isTeamCheckedRef = useRef<boolean>(showTeamView ? showTeamView : appSettings.teamViewValue);

  const bulkScanService = new BulkScanService();
  const dashboardService = new DashboardService();
  const checkPhishService = new CheckPhishService();
  const userService = new UserService();
  useEffect(() => {
    window.document.title =
      user.type_name === 'non_brand'
        ? 'Live Scan | Bolster Platform'
        : 'Bulk Scan | Bolster Platform';
    setTableParams();
    if (!_.isEmpty(user.email)) {
      const query = {
        pageNumber: tablePageNumber,
      };
      getBulkScanHistory(query, tableFilters, tableSort);
    }
    fetchData();
  }, [
    user,
    bulkScanstate.isLayoutChanged,
    bulkScanstate.refreshOnTag,
    bulkScanstate.api_key,
    appSettings.scanLevel,
  ]);

  const fetchData = () => {
    Promise.allSettled([dashboardService.getScanCount(), bulkScanService.getUserAgents()])
      .then(allResponses => {
        const [scanCountData, allUserAgents] = allResponses.map((result: any) => result.value);
        setBulkScanState(prevState => ({
          ...prevState,
          api_key: scanCountData['api_key'],
          allUserAgents: allUserAgents,
        }));
      })
      .catch(error => {
        console.log(error);
      });
    setBulkScanState(prevState => ({
      ...prevState,
      teamView: appSettings.teamViewValue,
      scanLevel: appSettings.scanLevel,
    }));
  };

  const getTileCompProperties = (isNonBrand: boolean, user: DashBoardDto) => {
    return [
      ...(featureIsAvailable(user, [appConstants.FEATURE_CODE.LOCATION_BASED])
        ? [{ id: 'scan_location' }, { id: 'wait' }]
        : []),
      { id: 'Last scanned' },
      { id: 'First seen' },
      { id: 'Logo Detected' },

      ...(runByClientApp({
        onBolster: () => (!isNonBrand ? [{ id: 'Number Takedowns' }] : []),
        onCheckPhish: () => [],
      }) as Array<{ id: string }>),
      ...(runByClientApp({
        onBolster: () => [{ id: 'tags' }],
        onCheckPhish: () => [],
      }) as Array<{ id: string }>),
    ];
  };

  const handleDisplayLayout = (layout: string) => {
    const isDisplayLayout = layout === 'table';
    setBulkScanState(prevState => ({
      ...prevState,
      displayLayout: isDisplayLayout,
    }));
  };

  const saveSwitchValue = () => {
    const currSwitchValue = !bulkScanstate.teamView;
    appSettings.saveTeamView(currSwitchValue);
    isTeamCheckedRef.current = currSwitchValue;
    setTablePageNumber(1);
    setLocalStorageValue('showTeamView', isTeamCheckedRef.current);
    setBulkScanState(prevState => ({
      ...prevState,
      teamView: currSwitchValue,
      scanLevel: currSwitchValue ? SCANTYPE.TEAM : SCANTYPE.SOLO,
    }));
  };

  const toggleDisputeDispositionModal = () => {
    setBulkScanState(prevState => ({
      ...prevState,
      disputeDispositionModalShown: !prevState.disputeDispositionModalShown,
    }));
  };

  const toggleTakedownRequestModal = () => {
    setBulkScanState(prevState => ({
      ...prevState,
      takedownRequestModalShown: !prevState.takedownRequestModalShown,
    }));
  };

  const onModalSuccess = (res: any, msg: string) => {
    alertSuccess(msg);
    getBulkScanHistory({ pageNumber: tablePageNumber }, tableFilters, tableSort);
  };

  const setTableParams = () => {
    const brandType = user.type_name;
    const isBranded = brandType === 'brand';
    setDefaultSortBy(isBranded ? 'lastScanned' : 'createdTs');
    const tableParameters = getLocalStorageValue(['bulkscan', 'table']);
    const activeFilters = _.get(tableParameters, ['filters'], []);
    const tablePageNum = _.get(tableParameters, ['query', 'pageNumber'], 1);
    setTablePageNumber(tablePageNum);
    setTableSort(
      _.get(tableParameters, ['sortBy'], {
        sortBy: defaultSortBy,
        sortDirection: 'desc',
      }),
    );
    setTableFilters(
      _.uniqBy(
        [
          ...activeFilters,
          ...(brandType === 'brand' ? DEFAULT_FILTERS.bulkAndApiScans : DEFAULT_FILTERS.bulkScans),
        ],
        'filterValue',
      ),
    );
  };
  const bulkScan = (arg: any) => {
    const { api_key, scanType } = bulkScanstate;
    const bulkScanApiCalls: any = [];
    arg.submittedLocationsForScan.map((scanLocation: any) => {
      bulkScanApiCalls.push(
        bulkScanService.bulkScan(arg.urlsSubmitted, 'bulk_' + api_key, scanType, {
          scanLocation: scanLocation,
          wait: arg.wait,
          userAgent: arg.selectedUserAgent,
        }),
      );
    });
    let query: any = { pageNumber: tablePageNumber };
    query.pageNumber = Math.max(query.pageNumber ? query.pageNumber - 1 : 0, 0);
    query = {
      ...query,
      startDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    };
    Promise.allSettled(bulkScanApiCalls)
      .then((allResponses: any) => {
        allResponses.map((result: any) => {
          if (result.status === 'fulfilled') {
            renderUrlAndTimestamp.push(result.value.srcUrl + result.value.timestamp);
            result.value.map((v: any) => {
              if (v.error) {
                alertError(v.error.message);
              }
            });
            return result.value;
          }
          if (result.status === 'rejected') {
            if (result?.reason instanceof SyntaxError) {
              alertError('Error processing the request.');
            } else {
              alertError(result?.reason || 'Error processing the request.');
            }
          }
        });
      })
      .catch((err: Error) => {
        alertError(err?.message || 'Error processing the request.');
      })
      .finally(() => {
        setTimeout(() => {
          fetchBulkScanHistory(query, tableFilters, tableSort, 'bulk_scan');
        }, 1000);
      });
  };

  const submitScans = (scanInfo: any) => {
    const { urls, scanLocations, wait, onSubmitType, selectedUserAgent } = scanInfo;
    const tempData: object[] = [];

    setNumOfFinish(0);
    setTableSort({
      sortBy: defaultSortBy,
      sortDirection: 'desc',
    });
    setTablePageNumber(1);
    const urlsSubmitted = urls;
    const submittedLocationsForScan =
      scanLocations.length > 0 ? scanLocations : [IScanLocations.US.value];
    setSubmittedUrls(urls);
    setSubmittedScanLocations(scanLocations.length > 0 ? scanLocations : [IScanLocations.US.value]);

    if (onSubmitType === 'bulkscan') {
      setBulkScanState(prevState => ({
        ...prevState,
        isBulkscanLoading: true,
        isLoading: false,
        selectedItems: [],
      }));
    }

    urlsSubmitted.forEach((url: string) => {
      submittedLocationsForScan.forEach((scanLocation: string) => {
        url = url.trim();
        if (!_.isEmpty(url)) {
          tempData.push({
            urlAndTimestamp: url + Math.random(),
            url: url,
            imageUrl: '--',
            status: '--',
            takedowns: '--',
            brandId: '--',
            ipAddress: '--',
            networkOwner: '--',
            disposition: '--',
            brandLogoDetected: '--',
            logoDetectedCount: '--',
            firstSeen: '',
            lastScanned: moment().valueOf(),
            createdTs: moment().valueOf(),
            scanLocation,
            wait,
            userAgent: selectedUserAgent,
            isLocationBasedEnabled: featureIsAvailable(user, [
              appConstants.FEATURE_CODE.LOCATION_BASED,
            ]),
            urlSha256: '--',
            timestamp: '--',
            isNewAdded: true,
            isLoading: true,
            isBulkscan: true,
            onSubmitType,
          });
        }
      });
    });
    setBulkScanState(prevState => {
      const mostRecentData = prevState.data;
      return {
        ...prevState,
        data: [...tempData, ...mostRecentData],
        selectedItems: [],
      };
    });

    bulkScan({
      wait,
      selectedUserAgent: selectedUserAgent === '--' ? '' : selectedUserAgent,
      urlsSubmitted,
      submittedLocationsForScan,
    });
  };

  const onTableFilterApplied = (filters: IFilter[]) => {
    const brandType = user.type_name;
    const tablefiltrs = _.uniqBy(
      [
        ...filters,
        ...(brandType === 'brand' ? DEFAULT_FILTERS.bulkAndApiScans : DEFAULT_FILTERS.bulkScans),
      ],
      'filterValue',
    );
    getBulkScanHistory({}, tablefiltrs, tableSort);
    setTableFilters(tablefiltrs);
  };

  const onTableSortChanged = (sort: any) => {
    setTableSort(sort);
    getBulkScanHistory({ pageNumber: tablePageNumber }, tableFilters, sort);
  };

  const onTablePageChange = (num: number, cb?: any) => {
    setTablePageNumber(num);
    getBulkScanHistory({ pageNumber: num }, tableFilters, tableSort, cb);
  };

  const fetchBulkScanHistory = (
    query?: any,
    filters?: IFilter[],
    sort?: any,
    scanType?: string,
  ) => {
    let scanLevel = SCANTYPE.SOLO;
    if (isTeamCheckedRef.current) {
      scanLevel = SCANTYPE.TEAM;
    }
    setBulkScanState(prevState => ({
      ...prevState,
      isLoading: scanType === 'bulk_scan' ? false : true,
      isBulkscanLoading: scanType === 'bulk_scan' ? true : false,
    }));
    checkPhishService
      .getUrlScanData(scanLevel, query, filters, sort)
      .then((res: any) => {
        let scanData: any[] = [];
        const total = res.total;
        if (res && res.urlInfo) {
          scanData = res.urlInfo.map((item: any, i: number) => {
            return {
              urlAndTimestamp: item.srcUrl + item.timestamp + i,
              url: item.srcUrl,
              url_sha256: item.urlSha256,
              imageUrl: item.imagePath,
              status: item.status,
              takedowns: item.takedowns,
              brandId: item.brandId,
              brandDisplayName: item.brandDisplayName || capitalize(item.brandId),
              ipAddress: item.ip,
              networkOwner: item.as_description,
              disposition: item.disposition,
              brandLogoDetected: item.brand_logo_detected ?? '--',
              firstSeen: item.firstSeen,
              lastScanned: moment(item.createdTs).valueOf(),
              createdTs: moment(item.createdTs).valueOf(),
              urlSha256: item.urlSha256,
              timestamp: item.timestamp,
              isNewAdded: renderUrlAndTimestamp?.indexOf(item.srcUrl + item.timestamp) !== -1,
              isBulkscan: true,
              final_category: item.final_category,
              scanLocation: item.scan_location,
              wait: item.wait,
              userAgent: item.user_agent,
              isLocationBasedEnabled: featureIsAvailable(user, [
                appConstants.FEATURE_CODE.LOCATION_BASED,
              ]),
              rescanDomain: submitScans,
              tags: item.tags || [],
              _index: item.srcUrl + item.timestamp + i,
              source: item.source,
              ...(item.scannedBy && { scannedBy: item.scannedBy }),
            };
          });
        }
        // Save the query setting in local storage
        query.pageNumber++;
        saveBulkscanTableParameters({
          query,
          filters: _.uniqBy(filters, 'filterValue'),
          sortBy: sort,
        });
        setBulkScanState(prevState => ({
          ...prevState,
          data: scanData,
          total,
          isLayoutChanged: false,
          refreshOnTag: false,
          isLoading: false,
          isBulkscanLoading: false,
        }));
      })
      .catch(error => {
        setBulkScanState(prevState => ({
          ...prevState,
          isLoading: false,
          isBulkscanLoading: false,
        }));
        alertError(error.message || 'Error while fetching the scans');
      });
  };

  const getBulkScanHistory = (query?: any, filters?: IFilter[], sort?: any, cb?: any) => {
    query.pageNumber = Math.max(query.pageNumber ? query.pageNumber - 1 : 0, 0);
    query = {
      ...query,
      startDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    };
    if (cb) {
      cb();
    } else {
      fetchBulkScanHistory(query, filters, sort);
    }
  };

  const onItemCheck = (checked: boolean, item: any, selectedItems: BulkScanSelectedItem[]) => {
    setBulkScanState(prevState => ({
      ...prevState,
      selectedItems: selectedItems,
    }));
  };

  const onTagsChangeHandler = (isAdded: boolean, url_sha256: string, newTags: any[]) => {
    const { data } = bulkScanstate;
    const newPayload = data.map((item: any) => {
      if (item.url_sha256 === url_sha256) {
        item.tags = newTags;
      }
      return item;
    });
    setBulkScanState(prevState => ({
      ...prevState,
      data: newPayload,
    }));
  };

  const { type_name } = user;
  const BULK_SCAN_HISTORY_COLUMNS = [
    URL_COLUMN_CP(),
    IP_COLUMN_CP(),
    CATEGORY_COLUMN,
    HOSTING_COLUMN_CP,
    DISPOSITION_COLUMN_CP(),
    LOGO_DETECTION_COLUMN,
    FIRST_SEEN_COLUMN,
    LAST_SCANNED_COLUMN,
    BRAND_COLUMN_CP,
    ...(type_name === 'brand' ? [SOURCE_COLUMN_CP] : []),
    ...(bulkScanstate.scanLevel === SCANTYPE.TEAM ? [SCANNED_BY_COLUMN_CP(false, usersList)] : []),
  ];
  if (
    featureIsAvailable(user, [appConstants.FEATURE_CODE.LOCATION_BASED]) &&
    !BULK_SCAN_HISTORY_COLUMNS.includes(SCAN_LOCATION)
  ) {
    BULK_SCAN_HISTORY_COLUMNS.push(SCAN_LOCATION);
    BULK_SCAN_HISTORY_COLUMNS.push(WAIT_COLUMN);
  }

  if (type_name === 'brand') {
    BULK_SCAN_HISTORY_COLUMNS.push({
      ...TAKEDOWNS_COLUMN,
    });
  }

  runByClientApp({
    onBolster: () => {
      BULK_SCAN_HISTORY_COLUMNS.push(
        {
          ...TAGS_COLUMN,
          accessor: 'tagIds',
          filterOptions: getProvidedTagLabelValue(
            providedTags.filter((tag: any) => tag.label !== 'Marked as Scam'),
            selectedTheme === ThemeModes.DARK.toLowerCase(),
          ),
          render: (rowData: any) => {
            return (
              <Tags
                leftAligned={bulkScanstate.displayLayout}
                rowData={rowData}
                onTagsChangeHandler={onTagsChangeHandler}
                type={appConstants.CONTENT_TYPE.BULK_SCAN}
              />
            );
          },
        },
        {
          ...TAGS_AUTHOR_COLUMN,
          accessor: 'tagUpdatedBy',
          filterOptions: getUsersListLabelValue(usersList),
        },
      );
    },
  });

  const requestTakedown = 'REQUEST TAKEDOWN';
  const disputeDisposition = 'DISPUTE DISPOSITION';
  const customTools = !isBrandReadOnlyUser(user)
    ? [
        <div className='custom-btn-wrapper' key={'bulk-action'}>
          <button
            id='bulk-scan-dispute-disposition'
            className='btn btn-default'
            disabled={bulkScanstate.selectedItems.length === 0}
            onClick={toggleDisputeDispositionModal}
          >
            {disputeDisposition}
          </button>
          <button
            id='bulk-scan-request-takedown'
            className='btn btn-default'
            disabled={bulkScanstate.selectedItems.length === 0}
            onClick={toggleTakedownRequestModal}
          >
            {requestTakedown}
          </button>
        </div>,
      ]
    : [];

  return (
    <AuthenticationWrapper>
      <div className={'bulk-scan-page'}>
        <PageTitle title={'Scan URLs'} />
        {!isBrandReadOnlyUser(user) && (
          <div className='title-description'>
            {runByClientApp({
              onBolster: () =>
                `To use our Scan URLs feature, simply enter the website URLs you want to scan (e.g.,
                http://google.com), and our advanced algorithms will provide you with a comprehensive
                report that identifies any potential security risks or vulnerabilities associated with
                those URLs. For Social Media or Mobile App Scanning, please use the corresponding module (and NOT this page)`,
              onCheckPhish: () =>
                `A real-time URL scanner that provides detailed threat intelligence, monitors 3000+ brands, and flags potential brand impersonation.`,
            })}
          </div>
        )}
        <div className='bulk-scan-page-content'>
          {!isBrandReadOnlyUser(user) && (
            <Card className='card-between'>
              <div className='bulk-scan-form-header'>
                <div className='d-flex align-items-center '>
                  <div className='bulk-scan-icon-wrapper'>
                    <BulkScanIcon color='grey' />
                  </div>
                  <Card.Text>New Scan</Card.Text>
                </div>
                {appConstants.CLIENT_APP_TYPE === ClientAppType.Bolster ? (
                  <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                      <Tooltip id='proxy-tooltip'>
                        With Tor Proxy enabled, you can obfuscate the source of scan, but the
                        scanning time may increase.
                      </Tooltip>
                    }
                  >
                    <div className='tor-proxy-icon'>
                      <Form.Check
                        type='switch'
                        id='tor-switch'
                        label='Tor Proxy'
                        onChange={() =>
                          setBulkScanState(prevState => ({
                            ...prevState,
                            isTorChecked: !prevState.isTorChecked,
                          }))
                        }
                        checked={bulkScanstate.isTorChecked}
                      />
                    </div>
                  </OverlayTrigger>
                ) : null}
              </div>
              <BulkScanForm
                submitScans={submitScans}
                isLoading={bulkScanstate.isBulkscanLoading}
                user={user}
                allUserAgents={bulkScanstate.allUserAgents}
                isTorChecked={bulkScanstate.isTorChecked}
              />
            </Card>
          )}
          <Card className='bulk-scan-table-container card-between'>
            {runByClientApp({
              onBolster: () => (
                <div className='team-view'>
                  <Form.Check
                    type='switch'
                    id='team-view-switch'
                    onChange={saveSwitchValue}
                    checked={bulkScanstate.teamView}
                  />
                  {'Team View'}
                </div>
              ),
            })}
            <Table
              id={'bulk-scan-table'}
              key={`${bulkScanstate.scanLevel}-bulk-scan-table`}
              showLoader={bulkScanstate.isLoading}
              userType={user.type_name}
              customTools={type_name === 'brand' ? customTools : null}
              disableDatePicker
              titleComp={<div />}
              indexBy={'urlAndTimestamp'}
              enableCheckbox={type_name === 'brand' && !isBrandReadOnlyUser(user)}
              data={bulkScanstate.data}
              total={bulkScanstate.total}
              filterAppliedOnFetchApi
              onItemCheck={onItemCheck}
              fetchApi={true}
              rowId={'urlAndTimestamp'}
              initialFilters={tableFilters}
              onFilterApplied={onTableFilterApplied}
              onSortChange={onTableSortChanged}
              columns={BULK_SCAN_HISTORY_COLUMNS}
              sortBy={tableSort.sortBy}
              sortDirection={tableSort.sortDirection}
              startDate={moment().subtract(1, 'year')}
              endDate={moment()}
              tileComp={DashboardTile}
              tileCompProperties={getTileCompProperties(type_name === 'non_brand', user)}
              initialPageNumber={tablePageNumber}
              onPageChange={onTablePageChange}
              defaultLayout={'tile'}
              allUserAgents={bulkScanstate.allUserAgents}
              selectedItems={bulkScanstate.selectedItems}
              handleDisplayLayout={handleDisplayLayout}
              user={user}
            />
          </Card>
        </div>
      </div>
      {bulkScanstate.disputeDispositionModalShown && (
        <DisputeDispositionModal
          data={bulkScanstate.selectedItems}
          show={bulkScanstate.disputeDispositionModalShown}
          onCloseModal={toggleDisputeDispositionModal}
          submitButtonLabel='Submit'
          onSuccess={onModalSuccess}
          onFailure={alertError}
          bulkScanPage={true}
        />
      )}
      {bulkScanstate.takedownRequestModalShown && (
        <BulkScanTakedownRequestModal
          data={bulkScanstate.selectedItems}
          show={bulkScanstate.takedownRequestModalShown}
          onCloseModal={toggleTakedownRequestModal}
          onSuccess={onModalSuccess}
          onFailure={alertError}
          user={user}
          itemOnSelect={onItemCheck}
        />
      )}
      {modifyCustomTag.isDeleteMode && <DeleteCustomTagsModal />}
      {modifyCustomTag.isEditMode && <EditCustomTagsModal />}
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { usersList, user } = state.dashboardReducer;
  const { modifyCustomTag, allPlatformTags } = state.tagsReducer;

  return {
    user,
    providedTags: allPlatformTags,
    usersList,
    modifyCustomTag,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  runByClientApp: appActions.runByClientApp,
};

const connectedBulkScan = connect(mapStateToProps, mapDispatchToProps)(BulkScan);

export { connectedBulkScan as BulkScan };

import { useCallback } from 'react';
import { useAppSelector } from '../../../../helpers/hooks';
import { transformTagObject } from './ag-utils';

export const useUpdateTagsFromBulkAction = ({ agRef }: { agRef: React.MutableRefObject<any> }) => {
  const tags = useAppSelector(state => state.tagsReducer.allPlatformTags);
  const { tagsFromBulkAction } = useAppSelector(state => state.tableReducer);

  const updateTagsFromBulkAction = useCallback(() => {
    if (agRef.current && agRef.current.api && tagsFromBulkAction) {
      const { tagIdsToAdd, tagIdsToRemove, urlShas256 } = tagsFromBulkAction;
      const tagsToAdd = tags
        .filter((tag: any) => {
          return tagsFromBulkAction.tagIdsToAdd.includes(tag.id);
        })
        .map(transformTagObject);
      agRef.current.api.forEachNode((node: any) => {
        if (!node.data) return;
        const nodeUrlSha = node.data.url_sha256 || node.data.sha256;
        if (Array.from(urlShas256)?.includes(nodeUrlSha)) {
          const newData = {
            ...node.data,
            tags: (node.data.tags || [])
              ?.filter(
                (tag: any) => !tagIdsToRemove.includes(tag.id) && !tagIdsToAdd.includes(tag.id),
              )
              .concat(tagsToAdd),
          };
          node.setData(newData);
        }
      });
    }
  }, [tags, tagsFromBulkAction, agRef]);

  return { updateTagsFromBulkAction };
};

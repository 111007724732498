import React from 'react';
import { SearchTerms } from '../../../Ugc/Components/SearchTerms/SearchTerms';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import { APP_STORE_TABLE_NAME } from '../../constants';

const AppStoreSearchTerms = () => {
  return <SearchTerms type={ugcType.App_Store} tableName={APP_STORE_TABLE_NAME} />;
};

export default AppStoreSearchTerms;

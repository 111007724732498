import { userConstants } from '../constants';

const verifyReducer = (state = { result: true }, action: any) => {
  switch (action.type) {
    case userConstants.VERIFY_SUCCESS:
      return {
        result: true,
      };
    case userConstants.VERIFY_FAILURE:
      return {
        result: false,
      };
    default:
      return state;
  }
};
export { verifyReducer };

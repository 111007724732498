import React from 'react';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import DashboardService from '../../services/dashboard.service';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { LabelAndValue } from '../Common/LabelAndValue';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Insights/insights.scss';
import { LoadingWrapper } from '../Common/LoadingWrapper';

interface Metadata {
  key: string;
  value: any;
}

interface MetadataObj {
  ipWhois: Metadata[];
  hostWhois: Metadata[];
  domainWhois: Metadata[];
  isLoading: boolean;
}
interface IWhoisProps extends Partial<RouteComponentProps<any>> {
  ip: string;
  host: string;
  domain: string;
  type?: string;
}

type Props = IWhoisProps;

class Whois extends React.Component<Props, MetadataObj> {
  private readonly dashboardService: DashboardService;
  private _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      ipWhois: [],
      hostWhois: [],
      domainWhois: [],
      isLoading: false,
    };
    this.dashboardService = new DashboardService();

    window.document.title = 'WHOIS Info | Dashboard | Bolster Platform';
  }

  componentDidMount() {
    this._isMounted = true;
    this.setCompState({ isLoading: true });

    const getWhois = async (arg: string, key: string) => {
      if (arg) {
        let resp: any;
        switch (key) {
          case 'ipWhois':
            resp = await this.dashboardService.getWhoisInfoOnIP(arg);
            break;
          case 'hostWhois':
          case 'domainWhois':
            resp = await this.dashboardService.getWhoisInfo(arg);
            break;
          default:
            console.error(`Unknown Whois key: ${key}`);
            break;
        }
        const whois: Metadata[] = Object.keys(resp.result).map(key => {
          return { key, value: resp.result[key] };
        });
        this.setCompState({ [key]: whois });
      }
    };

    const { host, domain, ip } = this.props as IWhoisProps;
    if (host !== domain) {
      getWhois(domain, 'domainWhois');
    }
    getWhois(ip, 'ipWhois');
    getWhois(host, 'hostWhois');
    this.setCompState({ isLoading: false });
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  renderCard = (
    headerValue: string,
    whoisData: Metadata[],
    totalDataSets: number,
    header: string,
  ) => {
    if (_.isEmpty(whoisData)) {
      return null;
    }

    const dataLength = whoisData.length;
    const [whoisLeft, whoisRight = []] = _.chunk(
      whoisData,
      dataLength % 2 === 0 ? dataLength / 2 : dataLength / 2 + 1,
    );

    const containerClassName = totalDataSets > 1 ? 'col-xl-6' : '';
    return (
      <div className={'card-container col-12 ' + containerClassName}>
        <Card>
          <Card.Header>
            {' '}
            On {header} : {headerValue}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                {whoisLeft.map((item: any, i: any) => (
                  <LabelAndValue
                    key={i}
                    label={item['key']}
                    longTextLineNumberLimit={1}
                    value={item['value'] ?? '--'}
                  />
                ))}
              </Col>
              <Col sm={6}>
                {whoisRight.map((item: any, i: any) => (
                  <LabelAndValue
                    key={i}
                    label={item['key']}
                    longTextLineNumberLimit={1}
                    value={item['value'] ?? '--'}
                  />
                ))}
              </Col>
            </Row>
          </Card.Body>{' '}
        </Card>
      </div>
    );
  };

  render() {
    const { ipWhois, hostWhois, domainWhois } = this.state;
    const { ip, host, domain, type } = this.props;
    let totalDataSets = 0;
    if (!_.isEmpty(ipWhois)) {
      totalDataSets++;
    }
    if (!_.isEmpty(hostWhois)) {
      totalDataSets++;
    }
    if (!_.isEmpty(domainWhois)) {
      totalDataSets++;
    }

    return (
      <AuthenticationWrapper>
        <div className={'whois-page'}>
          <LoadingWrapper isLoading={this.state.isLoading}>
            <Row className='page-content'>
              {this.renderCard(ip, ipWhois, totalDataSets, 'IP')}
              {this.renderCard(host, hostWhois, totalDataSets, 'Host')}
              {this.renderCard(domain, domainWhois, totalDataSets, 'Domain')}
            </Row>
          </LoadingWrapper>
        </div>
      </AuthenticationWrapper>
    );
  }
}

export { Whois };

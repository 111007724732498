import React, { useContext, useEffect, useState } from 'react';
import ModalOverlay from '../Common/ModalOverlay';
import { SocEmailTemplate } from './types/soc-email-template.interface';
import { Card } from 'react-bootstrap';
import { LabelAndValue } from '../Common/LabelAndValue';
import sanitizeHtml from 'sanitize-html';
import ThemeContext from '../../context/ThemeContext';

export interface ISocEmailTemplatePreviewModalProps {
  show: boolean;
  showApply: boolean;
  template: SocEmailTemplate;
  onCancel: () => void;
  onApply?: (et: SocEmailTemplate) => void;
}

export function SocEmailTemplatePreviewModal({
  show,
  template,
  showApply,
  onApply,
  onCancel,
}: ISocEmailTemplatePreviewModalProps) {
  const { selectedTheme } = useContext(ThemeContext);
  const [headerBg, setHeaderBg] = useState(selectedTheme === 'dark' ? 'bg-dark' : 'bg-light');

  useEffect(() => {
    if (selectedTheme === 'dark') {
      setHeaderBg('bg-dark');
    } else {
      setHeaderBg('bg-light');
    }
  }, [selectedTheme]);

  if (template == null) {
    return null;
  }

  return (
    <ModalOverlay
      show={show}
      title={`Template Preview`}
      onCancel={onCancel}
      onSubmit={() => onApply?.(template)}
      showButtons={showApply}
      submitButtonLabel={'Apply'}
      size={'lg'}
    >
      <Card>
        <Card.Header className={`${headerBg} pb-0`}>
          <LabelAndValue label={'Name'} value={template.name} direction={'row'} />

          <LabelAndValue label={'Description'} value={template.description} direction={'row'} />
          <LabelAndValue label={'Subject'} value={template.data.subject} direction={'row'} />
          {template.data.toEmails.length > 0 && (
            <LabelAndValue
              label={'To'}
              value={template.data.toEmails.map(x => x).join(', ')}
              direction={'row'}
            />
          )}

          {template.data.ccEmails.length > 0 && (
            <LabelAndValue
              label={'Cc'}
              value={template.data.ccEmails.map(x => x).join(', ')}
              direction={'row'}
            />
          )}

          {template.data.bccEmails.length > 0 && (
            <LabelAndValue
              label={'Bcc'}
              value={template.data.bccEmails.map(x => x).join(', ')}
              direction={'row'}
            />
          )}
        </Card.Header>
        <Card.Body className={'pt-2'}>
          <div
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(template.data.bodyHtml ?? '') }}
          ></div>
        </Card.Body>
      </Card>
    </ModalOverlay>
  );
}

import React, { useState, useRef } from 'react';

import ReactSlider from 'react-slider';
import './Slider.scss';
import Switch from 'react-switch';
import { numberWithCommas } from '../../../constants';

interface IIconProps {
  priority: number;
}
const Icon = ({ priority }: IIconProps) => {
  return <div className='switch-text'>{`P${priority}`}</div>;
};

interface ISliderProps {
  domains: any[];
  maxDomainsLength: number;
  priority: number;
  themColorName: string;
  onChange: (value: number, priority: number) => void;
}

const Slider = ({ domains, maxDomainsLength, priority, themColorName, onChange }: ISliderProps) => {
  if (maxDomainsLength === 0) {
    maxDomainsLength = 1;
  }
  const getTotalPrice = (firstNItems: number = domains.length) => {
    let sum = 0;
    for (let i = 0; i < firstNItems; i++) {
      sum += domains[i].estimated_price;
    }
    return sum;
  };

  const [isDisabled, setIsDisabled] = useState(false);
  const [totalPrice, setTotalPrice] = useState(getTotalPrice());
  const sliderValue = useRef(domains.length);

  const onToggleChange = (checked: boolean) => {
    setIsDisabled(!checked);
    onChange(checked ? sliderValue.current : 0, priority);
  };

  const onAfterSliderChange = (value: number) => {
    onChange(value, priority);
  };
  const onSliderChange = (value: number) => {
    sliderValue.current = value;
    setTotalPrice(getTotalPrice(value));
  };

  return (
    <div className={'slider-component ' + (isDisabled ? '' : themColorName)}>
      <Switch
        height={20}
        onChange={onToggleChange}
        checked={!isDisabled}
        className={'switch' + (isDisabled ? ' disabled' : '')}
        uncheckedIcon={<Icon priority={priority} />}
        checkedIcon={<Icon priority={priority} />}
      />
      <div
        className={'total-price flex-center' + (isDisabled ? ' disabled' : '')}
      >{`$${numberWithCommas(totalPrice)}`}</div>
      <div className={'w-100'}>
        <div
          className={'slider-container' + (domains.length > 0 ? ' min-width' : '')}
          style={{ width: `calc(${(domains.length / maxDomainsLength) * 100}% + 17px)` }}
        >
          <ReactSlider
            defaultValue={domains.length || 0}
            max={domains.length || 0}
            className='slider flex-center'
            thumbClassName='slider-thumb'
            trackClassName='slider-track'
            disabled={isDisabled}
            renderThumb={(props, state) => (
              <div {...props} className={'domain-thumb' + (isDisabled ? ' disabled' : '')}>
                <div className={'number-of-domains flex-center'}>
                  {numberWithCommas(state.valueNow)}
                </div>
              </div>
            )}
            onAfterChange={onAfterSliderChange}
            onChange={onSliderChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Slider;

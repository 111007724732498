import React from 'react';
import {
  BRAND_COLUMN,
  BRAND_PROPERTY_ACTION_COL,
  LOGO_PROPERTY_COL,
  LOGO_STATUS_COL,
  PROPERTY_CREATED_BY_COL,
  PROPERTY_CREATED_ON_COL,
} from './AssetsColDefination/BrandPropertyCols';
import BrandPropertyComponent from './BrandPropertyComponent';
import { LOGOS_TABLE_ID, LOGOS_TABLE_NAME } from './constants';
import { PropertyType } from './PropertyTypes';

const LogosAssets = () => {
  const PAGE_TITLE = 'Logos';
  window.document.title = `${PAGE_TITLE} | Assets`;

  const TABLE_COLUMNS = [
    BRAND_COLUMN,
    LOGO_PROPERTY_COL,
    PROPERTY_CREATED_ON_COL,
    PROPERTY_CREATED_BY_COL,
    LOGO_STATUS_COL,
    BRAND_PROPERTY_ACTION_COL,
  ];
  const defaultColumnIds = [
    'brand',
    'logos',
    'created_ts',
    'created_by',
    'status',
    'assets-action-col',
  ];
  return (
    <BrandPropertyComponent
      tableName={LOGOS_TABLE_NAME}
      id={LOGOS_TABLE_ID}
      columns={TABLE_COLUMNS}
      title={PAGE_TITLE}
      disableDatePicker
      type={PropertyType.LOGO}
      defaultColumnIds={defaultColumnIds}
    />
  );
};

export default LogosAssets;

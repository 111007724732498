import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getCSVFile } from '../Assets_v2/util';
import { ITableDataColumn } from '../Common/Table/table.data';
import { TExportTable } from '../Common/Table/constant';
import { TYPOSQUAT_TABLE_ID } from './constant';
import { TableContextProvider } from '../Common/Table/table.context';
import { TableData } from '../Common/Table/table.data';
import { DASHBOARD_NAME } from './constant';
import { TableTile } from '../Common/Table/table.tile';
import TypoBlurrTable from './TypoBlurrTable';
import LockIconLight from '../../assets/icons/TypoLockBlack.svg';
import LockIconDark from '../../assets/icons/TypoLockLight.svg';
import { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { ThemeModes } from '../../constants';
import _ from 'lodash';

import './typosquat.scss';
import {
  buildSourceUrlPermutationColumn,
  buildIpAddressColumn,
  NAMESERVERS_COLUMN,
  MAILSERVERS_COLUMN,
  CATEGORY_COLUMN,
  URL_CONSTRUCTION_COLUMN,
  REGISTRATION_DATE_COLUMN,
  MX_RECORDS_COLUMN,
  RISK_COLUMN,
  LIVE_SCAN_VERDICT_COLUMN,
  TAKEDOWN_ENQUIRY_COLUMN,
  REGISTRAR_COLUMN,
  SSL_COLUMN,
} from './columns';
import { history } from '../../helpers';

const createColumns = (isAnnonymous = false): ITableDataColumn[] =>
  isAnnonymous
    ? [
        buildSourceUrlPermutationColumn(isAnnonymous),
        buildIpAddressColumn(isAnnonymous),
        NAMESERVERS_COLUMN,
        MAILSERVERS_COLUMN,
      ]
    : [
        buildSourceUrlPermutationColumn(isAnnonymous),
        URL_CONSTRUCTION_COLUMN,
        RISK_COLUMN,
        LIVE_SCAN_VERDICT_COLUMN,
        CATEGORY_COLUMN,
        buildIpAddressColumn(isAnnonymous),
        NAMESERVERS_COLUMN,
        MAILSERVERS_COLUMN,
        MX_RECORDS_COLUMN,
        REGISTRATION_DATE_COLUMN,
        REGISTRAR_COLUMN,
        SSL_COLUMN,
        TAKEDOWN_ENQUIRY_COLUMN,
      ];

export interface ITypoSquatTable {
  tableData: any[];
  customToolBar: any;
  enableCheckbox: boolean;
  onItemCheck?: any;
  selectedItems?: any[];
  isAnnonymous: boolean;
  showLoader: boolean;
  triggerState?: boolean;
  triggerCSVExport?: (newState: boolean) => void;
  showUpgradeModal?: boolean;
}

const TypoSquatTable = ({
  tableData,
  customToolBar,
  enableCheckbox,
  selectedItems,
  onItemCheck,
  isAnnonymous = true,
  showLoader = false,
  triggerState = false,
  triggerCSVExport,
  showUpgradeModal = false,
}: ITypoSquatTable) => {
  const [columns, setColumns] = useState(createColumns(isAnnonymous));
  const [defaultLayout, setDefaultLayout] = useState<any>('table');
  const { selectedTheme } = useContext(ThemeContext);
  const lockIcon = _.isEqual(selectedTheme, ThemeModes.DARK.toLocaleLowerCase())
    ? LockIconDark
    : LockIconLight;
  const { setTriggerContactUsModal, setShowContactUsV2, paidUser } = useContext(
    CheckPhishContactUsContext,
  );

  const handleDisplayLayout = (newLayout: any) => {
    setDefaultLayout(newLayout);
  };

  useEffect(() => {
    if (history.location.state?.defaultLayout)
      setDefaultLayout(history.location.state?.defaultLayout);
  }, [history]);

  const exportCSVTitle = 'Bolster_Typosquat_Detections';

  const exportApi = (data: any, columns: ITableDataColumn[], type: TExportTable) => {
    getCSVFile(data, columns, exportCSVTitle);
  };

  const paginationProps = {
    ...(isAnnonymous
      ? {
          disablePagination: true,
        }
      : {
          initialPageNumber: 1,
          initialPageSize: tableData.length,
        }),
  };

  useEffect(() => {
    if (!triggerState) return;
    const cols = columns.map(item => ({
      ...item,
      label: item.header,
      field: item.fieldForExport || item.accessor,
    }));
    exportApi(tableData, cols, 'csv');
    triggerCSVExport && triggerCSVExport(false);
  }, [triggerState]);

  const upgrade = () => {
    setShowContactUsV2(true);
    setTriggerContactUsModal(true);
  };

  return (
    <div className={`table-page typosquat-table`}>
      <Card className='table-container'>
        <TableContextProvider
          key={TYPOSQUAT_TABLE_ID}
          columns={columns}
          dashboardName={DASHBOARD_NAME}
          tableId={TYPOSQUAT_TABLE_ID}
        >
          <TableData
            key={TYPOSQUAT_TABLE_ID}
            id={TYPOSQUAT_TABLE_ID}
            defaultLayout={defaultLayout}
            tableClassName={`tool-bar-container-padding ${
              showUpgradeModal ? ' no-bottom-padding' : ''
            }`}
            title=''
            columns={columns}
            {...paginationProps}
            exportFn={exportApi}
            exportOptions={[{ label: 'CSV', value: 'csv' }]}
            tableIndex={isAnnonymous ? 'hostname' : 'brand_analytics'}
            indexBy={isAnnonymous ? 'hostname' : 'domain'}
            tileComp={isAnnonymous ? false : TableTile}
            data={tableData}
            disableDatePicker
            disablePagination={showUpgradeModal}
            customTools={customToolBar}
            enableCheckbox={enableCheckbox}
            onItemCheck={onItemCheck}
            selectedItems={selectedItems}
            tileTopThreeProps={['mailservers', 'nameservers']}
            showLoader={showLoader}
            handleDisplayLayout={handleDisplayLayout}
          />
          {showUpgradeModal && defaultLayout === 'table' && (
            <div className='table-wrap'>
              <TableData
                key={'upgrade-demo-data'}
                id={'upgrade-demo-data'}
                tableClassName='tool-bar-container-padding'
                title=''
                columns={columns}
                tableIndex={isAnnonymous ? 'hostname' : 'brand_analytics'}
                indexBy={isAnnonymous ? 'hostname' : 'domain'}
                tileComp={isAnnonymous ? false : TableTile}
                data={tableData.slice(0, 50)}
                disableDatePicker
                disablePagination
                enableCheckbox={enableCheckbox}
                selectedItems={[]}
                tileTopThreeProps={['mailservers', 'nameservers']}
                hideHeader={true}
              />
              {!paidUser && <TypoBlurrTable lockIcon={lockIcon} onUpgrade={upgrade} />}
            </div>
          )}
        </TableContextProvider>
      </Card>
    </div>
  );
};

export default TypoSquatTable;

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import DashboardService from '../../../../services/dashboard.service';
import {
  IChartPlaceholderProps,
  ILineChartDataProps,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import LineChart from '../../../Common/DashboardWidgets/LineChart';
import NoDetections from '../../../../assets/icons/NoDetections.svg';
import { convertLineChartData } from '../../../Common/DashboardWidgets/utils';
import { Nullable } from '../../../../types/common';

const dashboardService = new DashboardService();

const placeholder: IChartPlaceholderProps = {
  icon: NoDetections,
  title: 'No detections found',
  description:
    'We haven’t detected any phish and scam sites for this period. Please select another time frame.',
};

const tooltipFormatter = (name: string): string => {
  if (name.indexOf('Total') !== -1) {
    return 'Total';
  } else if (name.indexOf('live') !== -1) {
    return 'Live';
  } else if (name.indexOf('down') !== -1) {
    return 'Taken Down';
  }
  return '';
};

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  activeOUId?: Nullable<number>;
}

function DashboardDetection({ startDate, endDate, activeOUId }: IComponentProps) {
  const dateFormat = 'YYYY-MM-DD';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ILineChartDataProps[]>([]);

  const lineChartProperties = {
    startDate,
    endDate,
    totalLabel: 'Total detected sites',
    liveLabel: 'Sites still live',
    takedownLabel: 'Sites taken down',
  };

  const getDetectionData = () => {
    setIsLoading(true);
    const query = {
      startDate: startDate.format(dateFormat),
      endDate: endDate.format(dateFormat),
    };
    if (activeOUId) {
      query['ouId'] = activeOUId;
    }
    dashboardService.getSitesByStatusAndHosting(query).then((res: any) => {
      const dateStatusMap = res.result.dateStatusMap;
      if (_.isEmpty(dateStatusMap)) {
        setData([]);
        setIsLoading(false);
        return;
      }
      setData(convertLineChartData(dateStatusMap, lineChartProperties));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getDetectionData();
  }, [startDate, endDate, activeOUId]);

  return (
    <LineChart
      title='Phish and Scam Site Detection'
      isLoading={isLoading}
      description='New Phish and Scam sites detected by time'
      data={data}
      placeholder={placeholder}
      yAxisTitle='Sites'
      tooltipFormatter={tooltipFormatter}
    />
  );
}

export { DashboardDetection };

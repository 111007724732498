import * as React from 'react';
import { Link } from 'react-router-dom';
import CheckCircleGreen from '../../assets/icons/CheckCircleGreen.svg';
import { CompanyIcon } from '../Common/CompanyIcon';

export default class ForgotPassHelper extends React.Component {
  render() {
    return (
      <div>
        <div className={'auth-title '}>
          <CompanyIcon withName />
        </div>
        <div className={'auth-page-wrapper'}>
          <div className={'nav-form'}>
            <img src={CheckCircleGreen} alt={'email sent'} />
            <span>Email Sent</span>
          </div>
          <div className='info-text'>Please check your email and reset your password to login.</div>
          <div className={'helper-bottom'}>
            <Link to={{ pathname: '/sign-in' }} className='btn btn-primary'>
              Login
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export const APP_STORE_TABLE_NAME = 'appStore';
export const APP_STORE_FINDING_DETECTION_TABLE_ID = 'appStoreDetection';
export const APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID = 'appStoreFindingsTakedownInProgress';
export const APP_STORE_TAKEN_DOWN_TABLE_ID = 'appStoreFindingsTakenDown';
export const APP_STORE_FINDING_SAFE_LIST_TABLE_ID = 'appStoreFindingSafeList';
export const APP_STORE_TABLE_IDS = [
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  APP_STORE_TAKEN_DOWN_TABLE_ID,
  APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
];

export const APP_STORE_PATHNAME = '/app-store';
export const APP_STORE_DASHBOARD_PATHNAME = `${APP_STORE_PATHNAME}/dashboard`;
export const APP_STORE_PATHNAME_FINDINGS_DETECTION_PATHNAME = `${APP_STORE_PATHNAME}/detection`;
export const APP_STORE_PATHNAME_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME = `${APP_STORE_PATHNAME}/takedown-in-progress`;
export const APP_STORE_PATHNAME_FINDINGS_TAKENDOWN_PATHNAME = `${APP_STORE_PATHNAME}/takendown`;
export const APP_STORE_PATHNAME_FINDINGS_SAFE_LIST_PATHNAME = `${APP_STORE_PATHNAME}/ignored`;
export const APP_STORE_PATHNAME_SEARCH_TERMS = `${APP_STORE_PATHNAME}/search`;

import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import './ActionButton.scss';
import { useMemo } from 'react';
import { PRICING_PLANS } from '../types';
import {
  ICheckPhishSubscription,
  initialState,
  CheckphishSubscriptionContext,
} from '../../../context/CheckPhishContactUsContext';
import _ from 'lodash';
import { EPlanProduct } from '../../Common/Subscription';

export type ActionBtnProps = {
  btnText?: string;
  planName: PRICING_PLANS;
  onClick: () => any;
  customClass?: string;
  isSmall?: boolean;
  disable?: boolean;
};

const ActionButton = ({
  btnText = '',
  onClick,
  customClass = '',
  planName,
  isSmall = false,
  disable = false,
}: ActionBtnProps) => {
  const {
    getCurrentPlanName,
    currentTyposquatSubscription,
    currentLiveScanSubscription,
    getSelectedPlan,
    selectedTerm,
    numberOfDomains,
    numberOfScans,
    selectedPlans,
  } = useContext(CheckphishSubscriptionContext);
  const currentPlanName = useMemo(() => {
    return getCurrentPlanName(
      planName === PRICING_PLANS.PROGESSIONAL_API
        ? EPlanProduct.LIVE_SCAN
        : EPlanProduct.TYPOSQUATTING,
    );
  }, [currentLiveScanSubscription, currentTyposquatSubscription]);

  const isNext = useMemo(() => {
    const plans = Object.values(PRICING_PLANS);
    const currentPlanIndex = plans.indexOf(currentPlanName);
    const nextPlan = plans[currentPlanIndex + 1];
    if (_.isEmpty(nextPlan)) return false;
    return nextPlan === planName;
  }, [currentPlanName]);

  const disableBtn = useMemo(() => {
    if (disable) return true;
    //livescan
    if (planName === PRICING_PLANS.PROGESSIONAL_API) {
      return (
        !_.isEmpty(currentLiveScanSubscription) &&
          planName === currentPlanName &&
          currentLiveScanSubscription.term === selectedTerm.value,
        currentLiveScanSubscription?.quantity === numberOfScans
      );
    } else {
      //typosquatPlan
      if (_.isEmpty(currentTyposquatSubscription) && planName === PRICING_PLANS.FREE) return true;
      return (
        !_.isEmpty(currentTyposquatSubscription) &&
        currentTyposquatSubscription.term &&
        currentTyposquatSubscription.quantity === numberOfDomains &&
        planName === currentPlanName
      );
    }
  }, [
    disable,
    numberOfDomains,
    selectedTerm,
    currentLiveScanSubscription,
    currentTyposquatSubscription,
  ]);

  const buttonText = useMemo(() => {
    if (!_.isEmpty(btnText)) return btnText;
    if (disableBtn) return 'Current Plan';
    if (currentPlanName === PRICING_PLANS.PROGESSIONAL_API) {
      //logic for liveScans
      if (_.isEmpty(currentLiveScanSubscription)) return 'Purchase';
      return currentLiveScanSubscription.quantity < numberOfScans ? 'Purchase' : 'Downgrade';
    } else {
      //typosquat
      if (planName === PRICING_PLANS.FREE) {
        return currentPlanName === PRICING_PLANS.FREE ? 'Current Plan' : 'Downgrade';
      }

      if (planName === PRICING_PLANS.STARTER) {
        if (!currentTyposquatSubscription) return 'Purchase';
        if (currentPlanName === PRICING_PLANS.STARTER) {
          return currentTyposquatSubscription.quantity < numberOfDomains ? 'Purchase' : 'Downgrade';
        }
        return 'Downgrade';
      }
      return 'Purchase';
    }
  }, [currentPlanName, disableBtn, btnText, numberOfScans, numberOfDomains]);

  return (
    <Button
      onClick={onClick}
      variant='outline'
      className={`action-btn ${isNext ? `highlight-${planName}` : ''} ${
        disableBtn ? 'disabled-btn disabled' : customClass
      } ${isSmall ? 'size-small' : ''}`}
      disabled={disableBtn}
    >
      {buttonText}
    </Button>
  );
};

export default ActionButton;

import React from 'react';
import { ThemeModes } from '../../../constants';
import { ETagsTypes, ITags } from './Tags';

export const getTagsStyle = (tag: ITags, selectedTheme: string) => {
  const activeColor =
    selectedTheme === ThemeModes.DARK.toLowerCase() ? tag.color.darkColor : tag.color.lightColor;

  if (tag.type === ETagsTypes.CUSTOMER_CREATED) {
    return {
      background: `${activeColor}50`,
      border: `1px solid ${activeColor}`,
      color: activeColor,
    };
  }

  return {
    backgroundColor: activeColor,
  };
};

import fetchApi from '../../../services/api.service';
import { handleResponse, setPostRequestOptions } from '../../../services/serviceWorker';
import { ITags } from './Tags';

export interface ICreateCustomTag {
  label: string;
  colorId: number;
}
export interface ITagColors {
  id: number;
  lightColor: string;
  darkColor: string;
}

export interface ICustomTagCount {
  status: string;
  webCount: number;
  appCount: number;
  bulkScanCount: number;
  darkWebCount: number;
  socialCount: number;
}

export interface IEditCustomTag {
  tagId: number;
  label: string;
  colorId: number;
}

export const fetchAllPlatformTags = async (): Promise<ITags[]> => {
  const rawResponse = await fetchApi('/platform-api/v1/tag').then(handleResponse);
  return rawResponse;
};

export const fetchAllTagsColor = async (): Promise<ITagColors[]> => {
  const rawResponse = await fetchApi('/platform-api/v1/tag/custom-tag-colors').then(handleResponse);
  return rawResponse;
};

export const createCustomTag = async (tag: ICreateCustomTag): Promise<any> => {
  const requestOptions = setPostRequestOptions(JSON.stringify(tag));
  const response = await fetchApi('/platform-api/v1/tag/create', requestOptions).then(
    handleResponse,
  );
  return response;
};

export const deleteCustomTag = async (tagId: number): Promise<any> => {
  const requestOptions = setPostRequestOptions(JSON.stringify({ tagId }));
  const response = await fetchApi('/platform-api/v1/tag/delete-custom-tag', requestOptions).then(
    handleResponse,
  );
  return response;
};

export const editCustomTag = async (tag: IEditCustomTag): Promise<any> => {
  const requestOptions = setPostRequestOptions(JSON.stringify(tag));
  const response = await fetchApi('/platform-api/v1/tag/edit', requestOptions).then(handleResponse);
  return response;
};

export const getTagsFindingCounts = async (tagId: number): Promise<ICustomTagCount> => {
  const response = await fetchApi(`/platform-api/v1/tag/custom-tag-count?tagId=${tagId}`).then(
    handleResponse,
  );
  return response;
};

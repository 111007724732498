import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../../Style/MultiSelectTakedown.scss';
import { AppState, history } from '../../../../helpers';
import {
  MALICIOUS_TABLE_IDS,
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
  WEB_INITIATE_TAKEDOWN_PATHNAME,
} from '../../../MonitorAndTakedown/constants';
import { CloseIcon } from '../../../../assets/SVGIcons';
import { WebUrlData } from '../../../../types/web-url-data.interface';
import { connect } from 'react-redux';
import { dashboardActions } from '../../../../actions';
import { ISelectedWebUrls } from '../../../../constants/dashboard.constants';
import { isBrandReadOnlyUser } from '../../../../constants';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';

interface IMultiTakeDownProps {
  typeId: MALICIOUS_TABLE_IDS;
  selectedWebUrls: ISelectedWebUrls;
  setSelectedWebUrls: (tableId: MALICIOUS_TABLE_IDS, selected: WebUrlData[]) => void;
}

function MultiSelectTakedown(props: IMultiTakeDownProps) {
  const { typeId, setSelectedWebUrls } = props;

  const removeSelected = (item: WebUrlData) => {
    setSelectedWebUrls(
      typeId,
      props.selectedWebUrls[typeId].filter(url => url.url_sha256 !== item.url_sha256),
    );
  };

  const initiateTakedown = () => {
    const params = new URLSearchParams('');
    if (props.selectedWebUrls[typeId].length) {
      props.selectedWebUrls[typeId].forEach(item => {
        params.append('urlSha256s', item.url_sha256);
      });
    }
    switch (typeId) {
      case PRE_MALICIOUS_TABLE_ID:
        params.append('urlPage', 'pre-malicious');
        break;
      case TAKEDOWN_MALICIOUS_TABLE_ID:
        params.append('urlPage', 'takedown');
        break;
      case POST_MALICIOUS_TABLE_ID:
        params.append('urlPage', 'post-malicious');
        break;
      default:
        params.append('urlPage', 'pre-malicious');
    }

    setSelectedWebUrls(typeId, []);
    history.push(WEB_INITIATE_TAKEDOWN_PATHNAME + '?' + params.toString());
  };

  const manageTemplatesClicked = () => {
    const params = new URLSearchParams('');
    params.append('returnUrl', window.location.pathname);
    history.push(`/manage-email-templates?${params.toString()}`);
  };
  const urlBubbles = (item: WebUrlData, ix: number) => {
    return (
      <div className='url-bubbles' key={`${item.src_url}-${ix}`}>
        <OverlayTrigger
          placement={'bottom'}
          overlay={
            <Tooltip id={`tooltip-${item.src_url}-${ix}`} className={'table-source-url-tooltip'}>
              {item.src_url}
            </Tooltip>
          }
        >
          <div className={'text-container'}>{item.src_url}</div>
        </OverlayTrigger>
        <div onClick={() => removeSelected(item)}>
          <CloseIcon color='black' />
        </div>
      </div>
    );
  };

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) return null;

  return (
    <div className={'single-takedown-form'}>
      <div className={'first-container'}>
        {props.selectedWebUrls[typeId].length === 0 ? (
          <div className={'instruction-label'}>Select URL(s) to initiate Bulk Takedown</div>
        ) : (
          <div className={'instruction-label'}>
            Findings Selected: {props.selectedWebUrls[typeId].length}{' '}
          </div>
        )}
        <div className={'button-containers'}>
          <Button variant='outline-secondary small-margin' onClick={manageTemplatesClicked}>
            Manage Templates
          </Button>
          <Button
            variant='outline-secondary'
            onClick={initiateTakedown}
            disabled={!props.selectedWebUrls[typeId].length}
          >
            Initiate Takedown
          </Button>
        </div>
      </div>
      {props.selectedWebUrls[typeId].length !== 0 && (
        <div className={'url-bubbles-container d-flex flex-row'}>
          {props.selectedWebUrls[typeId].map((item, ix) => {
            return urlBubbles(item, ix);
          })}
        </div>
      )}
      {props.selectedWebUrls[typeId].length > 1 && (
        <Button
          className='px-0'
          variant='link'
          size='sm'
          onClick={() => setSelectedWebUrls(typeId, [])}
        >
          Clear All
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  const { selectedWebUrls } = state.dashboardReducer;

  return {
    selectedWebUrls,
  };
};

const mapDispatchToProps = {
  setSelectedWebUrls: dashboardActions.setSelectedWebUrls,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectTakedown);

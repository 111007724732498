import React from 'react';
import _ from 'lodash';
import { EDarkWebRiskLevel } from '../Types/DarkWeb.types';
import { DISPLAYED_NULL } from '../../../../constants';

interface IRiskLevelGroupProps {
  risks: Record<EDarkWebRiskLevel, number>;
}

export default function RiskLevelGroup({ risks }: IRiskLevelGroupProps) {
  let total = 0;
  const keys = Object.keys(risks);
  for (const key in risks) {
    total += risks[key];
  }
  if (total === 0) {
    return <>{DISPLAYED_NULL}</>;
  }

  return (
    <div className={`d-flex align-items-center`}>
      {_.map(keys, risk_level => {
        const value = risks[risk_level];
        if (!value) {
          return null;
        }
        return (
          <div
            key={risk_level}
            className={`risk-level d-flex align-items-center`}
            data-risk-level-value={risk_level.toLocaleLowerCase()}
          >
            <div className={`indicator`} />
            <div className='risk-count'>{value}</div>
          </div>
        );
      })}
    </div>
  );
}

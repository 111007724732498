import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { history } from '../../../../../helpers';
import { AuthenticationWrapper } from '../../../../AuthenticationWrapper';
import { PageTitleInsights } from '../../../../Common/PageTitleInsights';
import {
  EDarkWebRiskLevel,
  IDarkWebFindingData,
  IDarkWebThreatActorData,
} from '../../Types/DarkWeb.types';
import ActorDetailsSection from './ActorDetailsSection';
import RelatedFindings from './RelatedFindings';
import ActorChatters from './ActorChatters';
import { generateId, getSha256 } from '../../../../../constants';
import DarkWebService from '../../../../../services/darkWeb.service';

const darkWebService = new DarkWebService();

const DEFAULT_THREAT_INSIGHTS: IDarkWebThreatActorData = {
  threat_actor: '--',
  languages: [],
  last_chatters: '--',
  lastScanned: '--',
  firstSeen: '--',
  categories: [],
  telegramHandle: '--',
  risk: {
    [EDarkWebRiskLevel.HIGH]: 0,
    [EDarkWebRiskLevel.MEDIUM]: 0,
    [EDarkWebRiskLevel.LOW]: 0,
  },
};
interface IThreatActorInsightsProps {}
export default function ThreatActorInsights({}: IThreatActorInsightsProps) {
  const [actorDetailsLoading, setActorDetailsLoading] = useState<boolean>(false);
  const [relatedThreatLoading, setRelatedThreatLoading] = useState<boolean>(false);
  const [actorChattersLoading, setActorChattersLoading] = useState<boolean>(false);
  const [actorChatters, setActorChatters] = useState<any[]>([]);
  const [threatActorFindings, setThreatActorFindings] = useState<IDarkWebFindingData[]>([]);
  const [threatActorData, setThreatActorData] =
    useState<IDarkWebThreatActorData>(DEFAULT_THREAT_INSIGHTS);

  useEffect(() => {
    setActorDetailsLoading(true);
    setRelatedThreatLoading(true);
    setActorChattersLoading(true);

    const threatActorName = getSha256();
    const threatActorFilter = {
      id: generateId(10),
      filterType: 'string',
      filterBy: {
        label: 'Threat Actor',
        value: 'threat_actor',
      },
      filterMethod: {
        label: 'Includes',
        value: 'includes',
      },
      filterValue: threatActorName,
      filterErr: '',
    };

    Promise.allSettled([
      darkWebService.getThreatActors(),
      darkWebService.getFindings(undefined, [], [threatActorFilter], {
        sortBy: 'platform_published_ts',
        sortDirection: 'desc',
      }),
      darkWebService.getActorChatters(threatActorName),
    ])
      .then((allResponses: any) => {
        const [threatActorsDetails, threatActorFindings, actorChatters] = allResponses.map(
          (result: any) => result.value,
        );
        const mappedData = _.find(threatActorsDetails, ['threat_actor', threatActorName]);
        setThreatActorData(mappedData || DEFAULT_THREAT_INSIGHTS);
        setThreatActorFindings(threatActorFindings?.findings || []);
        setActorChatters(actorChatters || []);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setActorDetailsLoading(false);
        setRelatedThreatLoading(false);
        setActorChattersLoading(false);
      });
  }, []);

  return (
    <AuthenticationWrapper>
      <div className='dark-web-threat-actor-insights-page'>
        <PageTitleInsights
          title={threatActorData?.threat_actor || '--'}
          backFn={() => history.go(-2)}
          isLoading={actorDetailsLoading}
        />
        <div className='page-content insights-container'>
          <div className='first-two-sections d-flex align-items-stretch'>
            <ActorDetailsSection
              className='actor-details'
              data={threatActorData}
              isLoading={actorDetailsLoading}
            />
            <RelatedFindings
              className='related-findings mt-0'
              data={threatActorFindings}
              isLoading={relatedThreatLoading}
            />
          </div>
          <ActorChatters actorChatters={actorChatters} isLoading={actorChattersLoading} />
        </div>
      </div>
    </AuthenticationWrapper>
  );
}

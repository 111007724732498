import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { LabelAndValue } from '../../../../Common/LabelAndValue';
import { LoadingWrapper } from '../../../../Common/LoadingWrapper';
import { IDarkWebThreatActorData } from '../../Types/DarkWeb.types';

interface IActorDetailsSectionProps {
  data: IDarkWebThreatActorData;
  isLoading: boolean;
  className?: string;
}
export default function ActorDetailsSection({
  data,
  isLoading,
  className = '',
}: IActorDetailsSectionProps) {
  const { threat_actor, languages, last_chatters, categories } = data;
  return (
    <Card className={className}>
      <Card.Header className='self-card-header'>Actor Details</Card.Header>
      <Card.Body className='self-card-body'>
        <LoadingWrapper isLoading={isLoading} className='p-4'>
          <Row>
            <Col sm={6}>
              <LabelAndValue label={'User Handle'} value={threat_actor} direction={'column'} />
            </Col>
            <Col sm={6}>
              <LabelAndValue label={'Language'} value={languages.join(', ')} direction={'column'} />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <LabelAndValue label={'Last Chatter'} value={last_chatters} direction={'column'} />
            </Col>
            <Col sm={6}>
              <LabelAndValue label={'Categories'} value={categories} direction={'column'} />
            </Col>
          </Row>
        </LoadingWrapper>
      </Card.Body>
    </Card>
  );
}

import fetch from './api.service';
import { handleResponse, setPostRequestOptions } from './serviceWorker';

export default class PlaybookService {
  private readonly playbookApi = '/api/v1/playbook';

  getPlaybooksDataClassList(type: string) {
    return fetch(`${this.playbookApi}/type/${type}/data-class`).then(handleResponse);
  }
  getPlaybookTemplatesList(type: any) {
    // return fetch(`${this.playbookApi}/template`).then(handleResponse);
    return fetch(`${this.playbookApi}/type/${type}/template`).then(handleResponse);
  }

  getPlaybooksList(type: string) {
    // return fetch(`${this.playbookApi}/`).then(handleResponse);
    return fetch(`${this.playbookApi}/type/${type}`).then(handleResponse);
  }

  updatePlaybook(playbook: any, type: string) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(playbook));
    return fetch(`${this.playbookApi}/type/${type}`, requestOptions).then(handleResponse);
  }

  runPlaybook(id: number) {
    const requestOptions: RequestInit = setPostRequestOptions('');
    return fetch(`${this.playbookApi}/${id}/run`, requestOptions).then(handleResponse);
  }

  deletePlaybook(id: number) {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${this.playbookApi}/${id}`, requestOptions).then(handleResponse);
  }

  updateConnector(connector: any) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify({ connector }));
    return fetch(`${this.playbookApi}/connector`, requestOptions).then(handleResponse);
  }

  testConnectorType(id: number, details: object) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(details || {}));
    return fetch(`${this.playbookApi}/connector/type/${id}/test`, requestOptions).then(
      handleResponse,
    );
  }

  deleteConnector(id: number) {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${this.playbookApi}/connector/${id}`, requestOptions).then(handleResponse);
  }

  getConnectorsList() {
    return fetch(`${this.playbookApi}/connector`).then(handleResponse);
  }

  getConnectorDefinition() {
    return fetch(`${this.playbookApi}/connector/definition`).then(handleResponse);
  }

  getStep() {
    return fetch(`${this.playbookApi}/step`).then(handleResponse);
  }

  getSlackOAuth(code: string, redirectUri: string) {
    return fetch(
      `${this.playbookApi}/connector/slack/oauth?code=${code}&redirect_uri=${redirectUri}`,
    ).then(handleResponse);
  }
}

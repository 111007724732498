import React from 'react';
import { Button } from 'react-bootstrap';
import { IDashboardHeader } from './Types/dashboard.types';
import { DropdownDateRangePicker } from '../../Common/DropdownDateRangePicker';
import { PageTitle } from '../../Common/PageTitle';
import { appConstants } from '../../../constants';

const DashboardHeader = ({
  user,
  selectedDashboard,
  startDate,
  endDate,
  onDateRangeChange,
}: IDashboardHeader): JSX.Element => {
  const { organization = '' } = user;

  return (
    <PageTitle
      title={'Dashboard'}
      className={'dashboard-page-title'}
      tools={
        <div className={'d-flex align-items-center'}>
          <DropdownDateRangePicker
            key={'DropdownDateRangePicker'}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateRangeChange}
            type={appConstants.CONTENT_TYPE.CHECK_PHISH}
          />
        </div>
      }
    />
  );
};

export default DashboardHeader;

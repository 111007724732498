import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import _ from 'lodash';
import './Scan.scss';
import ApiService from '../../services/api.service.checkphish';
import { DEFAULT_SCAN_TYPE } from '../BulkScan/bulkscan.component';
import PlayActiveIcon from '../../assets/icons/PlayActive.svg';
import SearchIcon from '../../assets/icons/Search.svg';
import SettingsGreyIcon from '../../assets/icons/SettingsGrey.svg';
import { Dropdown, IDropdownOption } from '../Common/Dropdown';
import { AlertActionsTypes, validateDomain, validateIpv4Address } from '../../constants';
import { history } from '../../helpers';
import useOnClickOutside from '../Common/UseClickOutside';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import BlueTooltipIcon from '../../assets/icons/BlueTooltip.svg';
declare global {
  interface Window {
    dataLayer: any;
  }
}

const TYPE_URL = 'url';
const TYPE_IP_DOMAIN = 'ipDomain';
const TYPE_TYPOSQUAT = 'typosquat';

const DEFAULT_AGENT = {
  label:
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.113 Safari/537.36',
  value:
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.113 Safari/537.36',
};
const AGENTS_OPTIONS = [
  {
    label: 'CHROME',
    value: 'CHROME',
    disabled: true,
  },
  DEFAULT_AGENT,
  {
    label:
      'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36',
    value:
      'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36',
  },
  {
    label:
      'Mozilla/5.0 (X11; CrOS x86_64 11021.56.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.76 Safari/537.36',
    value:
      'Mozilla/5.0 (X11; CrOS x86_64 11021.56.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.76 Safari/537.36',
  },
  {
    label: 'MOBILE',
    value: 'MOBILE',
    disabled: true,
  },
  {
    label:
      'Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1',
    value:
      'Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1',
  },
  {
    label:
      'Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/69.0.3497.105 Mobile/15E148 Safari/605',
    value:
      'Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/69.0.3497.105 Mobile/15E148 Safari/605',
  },
  {
    label:
      'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
    value:
      'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
  },
  {
    label:
      'Mozilla/5.0 (Linux; Android 8.0.0; SM-G960F Build/R16NW) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36',
    value:
      'Mozilla/5.0 (Linux; Android 8.0.0; SM-G960F Build/R16NW) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36',
  },
  {
    label: 'BOTS',
    value: 'BOTS',
    disabled: true,
  },
  {
    label: 'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
    value: 'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  },
  {
    label: 'Mozilla/5.0 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm)',
    value: 'Mozilla/5.0 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm)',
  },
  {
    label: 'DuckDuckBot/1.0; (+http://duckduckgo.com/duckduckbot.html)',
    value: 'DuckDuckBot/1.0; (+http://duckduckgo.com/duckduckbot.html)',
  },
  {
    label: 'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html)',
    value: 'Mozilla/5.0 (compatible; Baiduspider/2.0; +http://www.baidu.com/search/spider.html)',
  },
  {
    label: 'Mozilla/5.0 (compatible; YandexBot/3.0; +http://yandex.com/bots)',
    value: 'Mozilla/5.0 (compatible; YandexBot/3.0; +http://yandex.com/bots)',
  },
  {
    label: 'Edge & IE',
    value: 'Edge & IE',
    disabled: true,
  },
  {
    label: 'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; SV1; .NET CLR 1.1.4322)',
    value: 'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; SV1; .NET CLR 1.1.4322)',
  },
  {
    label: 'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; SV1)',
    value: 'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; SV1)',
  },
  {
    label: 'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1)',
    value: 'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1)',
  },
  {
    label: 'FIREFOX',
    value: 'FIREFOX',
    disabled: true,
  },
  {
    label: 'Mozilla/5.0 (Windows NT 5.1; rv:7.0.1) Gecko/20100101 Firefox/7.0.1',
    value: 'Mozilla/5.0 (Windows NT 5.1; rv:7.0.1) Gecko/20100101 Firefox/7.0.1',
  },
  {
    label:
      'Mozilla/5.0 (X11; U; Linux Core i7-4980HQ; de; rv:32.0; compatible; JobboerseBot; http://www.jobboerse.com/bot.htm) Gecko/20100101 Firefox/38.0',
    value:
      'Mozilla/5.0 (X11; U; Linux Core i7-4980HQ; de; rv:32.0; compatible; JobboerseBot; http://www.jobboerse.com/bot.htm) Gecko/20100101 Firefox/38.0',
  },
  {
    label: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10; rv:33.0) Gecko/20100101 Firefox/33.0',
    value: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10; rv:33.0) Gecko/20100101 Firefox/33.0',
  },
];

const sizeOption = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const apiService = new ApiService();

export interface IScanComponentProps {
  className?: string;
  direction?: 'vertical' | 'horizontal';
  alertError?: (message: string) => AlertActionsTypes;
  onlyScanBar?: boolean;
  isLoading?: boolean;
  onChangeIsLoading?: any;
  onChangeSearchText?: any;
  onChangeCustomerUserAgent?: any;
  onInputFocus?: any;
  onInputBlur?: any;
  isTypoSquat?: boolean;
  onTyposquatSubmit?: any;
  size?: string;
  value?: string;
  setValue?: (value: string) => void;
  onDomainChange?: (domainName: string) => void;
  disableGenerate?: boolean;
  placeholderText?: string;
  submitBtnText?: string;
  submitBtnCustomClass?: string;
  showPretext: boolean;
  preText: string;
  displayTooltip: boolean;
  displayError?: boolean;
  extractedDomain?: string;
  limitStatus: string;
  toggleScanBarBanner: () => void;
}

const ScanComponent: FunctionComponent<IScanComponentProps> = ({
  className = '',
  direction = 'vertical',
  alertError = _.noop,
  onlyScanBar = false,
  onChangeIsLoading,
  onChangeSearchText,
  onChangeCustomerUserAgent,
  onInputFocus,
  onInputBlur,
  isTypoSquat,
  onTyposquatSubmit,
  size = 'medium',
  value = '',
  onDomainChange = _.noop,
  disableGenerate = false,
  placeholderText,
  submitBtnText,
  submitBtnCustomClass = '',
  preText,
  showPretext,
  displayError,
  extractedDomain,
  displayTooltip,
  toggleScanBarBanner,
  limitStatus,
}) => {
  const { identifier } = useContext(CheckPhishContactUsContext);
  const [searchType, setSearchType] = useState(isTypoSquat ? TYPE_TYPOSQUAT : TYPE_URL);
  const [searchText, setSearchText] = useState(value);
  const [settingShown, setSettingShown] = useState(false);
  const [userAgent, setUserAgent] = useState(DEFAULT_AGENT);
  const [customAgent, setCustomAgent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const privateScan: boolean = false;
  const customUserAgent: string = '';
  const timer = useRef<any>(null);
  useEffect(() => {
    setSearchType(isTypoSquat ? TYPE_TYPOSQUAT : TYPE_URL);
    !value && setSearchText('');
  }, [isTypoSquat]);

  useEffect(() => {
    if (isTypoSquat && value !== searchText) {
      onChangeSearchText(searchText);
    }
    if (timer.current) clearTimeout(timer.current);
    if (_.isEmpty(searchText)) return onDomainChange(searchText);
    timer.current = setTimeout(() => {
      onDomainChange && onDomainChange(searchText);
    }, 500);
  }, [searchText, isTypoSquat]);

  const startScan = () => {
    if (!isLoading) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'scanInProgress',
        category: 'Page',
        action: 'clicked',
        label: 'scanInProgress',
      });
      setIsLoading(true);
      apiService
        .bulkScan(
          searchText,
          `cp_${identifier ?? ''}`,
          DEFAULT_SCAN_TYPE,
          privateScan,
          customUserAgent,
        )
        .then((res: any) => {
          if (res.msg) {
            throw new Error(res.msg);
          }
          console.log(res, ' Res');
          history.push(`/public/insights/${res.timestamp}/${res.urlSHA256}`);
        })
        .catch((err: any) => {
          if (err?.status === 401) {
            history.push(`sign-up?errMsg=${err.error}`);
          } else {
            console.log(err);
            history.push('/');
          }
        });
    }
  };

  const clickRef = useRef(null);
  useOnClickOutside(clickRef, onInputBlur);

  const onSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const toggleSetting = () => {
    setSettingShown(!settingShown);
  };

  const onUserAgentChange = (selection: IDropdownOption) => {
    setUserAgent(selection);
  };

  const onCustomAgentChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCustomAgent(e.currentTarget.value);
  };

  const onSubmit = () => {
    if (disableGenerate && isTypoSquat) return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'scanClicked',
      category: 'Page',
      action: 'clicked',
      label: 'scanClicked',
    });

    if (searchType === TYPE_URL) {
      const customUserAgent = customAgent || userAgent.value;
      if (_.isEmpty(searchText)) {
        alertError('Please type in a URL.');
      } else {
        onChangeSearchText(searchText);
        onChangeCustomerUserAgent(customUserAgent);
        onChangeIsLoading(true);
        startScan();
      }
    } else if (searchType === TYPE_IP_DOMAIN) {
      if (validateIpv4Address(searchText)) {
        history.push(`public/ip/${searchText}`);
      } else if (validateDomain(searchText)) {
        history.push(`public/domain/${searchText}`);
      } else {
        alertError('Please type in a valid IP or Domain.');
      }
    } else if (searchType === TYPE_TYPOSQUAT) {
      onChangeSearchText(searchText);
      onTyposquatSubmit(searchText);
    } else {
      setSettingShown(false);
    }
  };

  const isUrlScan = searchType === TYPE_URL;
  const generatePlaceHolder = () => {
    if (placeholderText) return placeholderText;
    return isTypoSquat
      ? 'Enter Domain Name'
      : isUrlScan
      ? 'Enter a URL: www.example.com'
      : 'Search by 89.62.149.159 or a domain name';
  };
  const searchPlaceholder = generatePlaceHolder();

  return (
    <div className={'w-100 d-flex single-scan-component ' + className + ' ' + direction}>
      {!onlyScanBar && (
        <div className={'d-flex type-buttons'}>
          <div
            className={'flex-center type-button ' + (isUrlScan ? 'active' : '')}
            onClick={() => {
              setSearchType(TYPE_URL);
            }}
          >
            URL SCAN
          </div>
          <div
            className={'flex-center type-button ' + (!isUrlScan ? 'active' : '')}
            onClick={() => {
              setSearchType(TYPE_IP_DOMAIN);
              setSettingShown(false);
            }}
          >
            IP / DOMAIN LOOKUP
          </div>
        </div>
      )}
      <div className={'search-container'}>
        <div className={`flex-center w-100 search-bar`} ref={clickRef}>
          {showPretext && <div className='pre-text-wrapper'>{preText}</div>}
          <div className={displayError ? `err-text input-wrap` : `input-wrap`}>
            <input
              className={`search-text-field ${sizeOption[size]} ${
                isTypoSquat && disableGenerate ? 'disabled-border' : ''
              }`}
              placeholder={searchPlaceholder}
              onKeyDown={handleKeyDown}
              onChange={onSearchTextChanged}
              onFocus={onInputFocus}
              disabled={limitStatus === 'offlimit'}
              // disabled={isTypoSquat && disableGenerate}
            />
            {!displayError && (
              <img src={BlueTooltipIcon} alt='tooltip' onClick={toggleScanBarBanner} />
            )}
          </div>
          <div
            className={
              `flex-center p-3 search-bar-button submit-button ${
                isTypoSquat && disableGenerate ? 'disabled' : ''
              } ${sizeOption[size]}` +
              ' ' +
              submitBtnCustomClass
            }
            onClick={onSubmit}
          >
            {submitBtnText ? (
              <div className={'web-only text-white'}>{submitBtnText}</div>
            ) : isTypoSquat ? (
              <div className={'web-only text-white'}>{'Generate'}</div>
            ) : (
              <div className={'web-only text-white'}>{isUrlScan ? 'SCAN' : 'SEARCH'}</div>
            )}
            <img
              src={isUrlScan ? PlayActiveIcon : SearchIcon}
              alt={'scan'}
              className={'mobile-only scan-image'}
            />
          </div>
          {!onlyScanBar && isUrlScan && (
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip id='tooltip-bulk-scan'>
                  <Card.Text> {'Scan settings'} </Card.Text>
                </Tooltip>
              }
            >
              <div className={'flex-center search-bar-button settings-button'}>
                <img src={SettingsGreyIcon} alt={'setting'} onClick={toggleSetting} />
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div className={'settings-container bg-ocean-light ' + (settingShown ? '' : 'height-0')}>
          <div className={'d-flex w-100 setting-field flex-column flex-lg-row'}>
            <div
              className={'custom-text-dark font-size-medium font-weight-500 py-2 settings-label'}
            >
              USER AGENT
            </div>
            <div className={'d-flex flex-column user-agent-dropdown-container'}>
              <Dropdown
                btnClassName={'w-100 user-agent-dropdown'}
                key={'dns-filter'}
                options={AGENTS_OPTIONS}
                onChange={onUserAgentChange}
                defaultSelection={userAgent}
              />
              <div className={'help-text'}>Default: Internet Explorer on Windows 10</div>
            </div>
          </div>
          <div className={'d-flex w-100 pt-4 setting-field flex-column flex-lg-row'}>
            <div
              className={'custom-text-dark font-size-medium font-weight-500 py-2 settings-label'}
            >
              CUSTOM AGENT
            </div>
            <div className={'d-flex flex-column user-agent-input-container'}>
              <input
                className={'user-agent-input'}
                placeholder={'Custom user agent string'}
                value={customAgent}
                onChange={onCustomAgentChanged}
              />
              <div className={'help-text'}>
                Enter your custom user agent string. This will override default user agent string.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ScanComponent };

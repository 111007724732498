export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',
  CUSTOM: 'ALERT_CUSTOM',
  REQUIRED_ACTION: 'REQUIRED_ACTION',
  RELEASE_NOTE_ACTION: 'RELEASE_NOTE_ACTION',
  INFORMATION: 'ALERT_INFORMATION',
};

export interface AlertSuccess {
  type: typeof alertConstants.SUCCESS;
  message: string;
  id: number;
  domElement?: any;
}

export interface AlertError {
  type: typeof alertConstants.ERROR;
  message: string;
  id: number;
}

export interface AlertClear {
  type: typeof alertConstants.CLEAR;
  message: string;
  id: number;
}

export interface AlertRequiredAction {
  type: typeof alertConstants.REQUIRED_ACTION;
  message: string;
}

export interface AlertReleaseNoteAction {
  type: typeof alertConstants.RELEASE_NOTE_ACTION;
  message: string;
}

export interface IAlertDispatchProps {
  alertSuccess: (message: string, domElement?: any) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

export interface AlertInformation {
  type: typeof alertConstants.INFORMATION;
  message: string;
  id: number;
}

export type AlertActionsTypes =
  | AlertSuccess
  | AlertError
  | AlertClear
  | AlertRequiredAction
  | AlertReleaseNoteAction
  | AlertInformation;

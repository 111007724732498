import React, { Fragment } from 'react';
import _ from 'lodash';
import { SplitButton, DropdownButton, Dropdown as BsDropdown } from 'react-bootstrap';
import './Dropdown.scss';

export interface IDropdownOption {
  label: string;
  value: string;
  disabled?: boolean;
  docValue?: string;
}

export const DropdownOptionDefaultValue = {
  label: '',
  value: '',
};

interface IComponentProps {
  boxStyle: boolean;
  hideArrow: boolean;
  splitButton: boolean;
  disabled: boolean;
  variant: string;
  emptyText: any;
  fixedPlaceholder: any;
  btnClassName?: string;
  optionsHeader?: any;
  options: IDropdownOption[] | Record<string, IDropdownOption[]>;
  ordersPersist?: boolean;
  onChange: (value: any, controlId: string) => void;
  defaultSelection: IDropdownOption;
  controlId?: string;
  isLoading?: boolean;
  onSplitToggle?: (isToggled: boolean) => void;
  onClick?: () => void;
}

interface IComponentState {
  selectedOption: IDropdownOption;
}

class Dropdown extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    boxStyle: false,
    hideArrow: false,
    splitButton: false,
    disabled: false,
    variant: 'default',
    emptyText: 'Please Select',
    fixedPlaceholder: '',
    btnClassName: '',
    onChange: _.noop,
    defaultSelection: {},
    controlId: '',
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      selectedOption: DropdownOptionDefaultValue,
    };
  }

  onSelected = (option: any) => {
    this.setState({
      selectedOption: option,
    });
    this.props.onChange(option, this.props.controlId || '');
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.defaultSelection.label !== this.props.defaultSelection.label) {
      this.setState({
        selectedOption: { label: '', value: '' },
      });
    }
  }

  renderFlatDropdown = (options: IDropdownOption[]) => {
    const { isLoading } = this.props;

    return (
      <>
        {options.map((option: IDropdownOption) => {
          return (
            <BsDropdown.Item
              key={option.label}
              disabled={option.disabled}
              onClick={e => {
                e.stopPropagation();
                this.onSelected(option);
              }}
              className={isLoading ? 'loading-skeleton' : ''}
            >
              {isLoading ? (
                <a href='#' className='btn'>
                  {option.label}
                </a>
              ) : (
                <> {option.label}</>
              )}
            </BsDropdown.Item>
          );
        })}
      </>
    );
  };

  renderGroupedDropdown = (options: Record<string, IDropdownOption[]>) => {
    return (
      <>
        {options &&
          Object.keys(options).map((k, index: number) => {
            return (
              <Fragment key={index}>
                <BsDropdown.Header>{k}</BsDropdown.Header>
                {options[k].map((option: IDropdownOption) => {
                  return (
                    <BsDropdown.Item
                      key={option.label}
                      disabled={option.disabled}
                      onClick={e => {
                        e.stopPropagation();
                        this.onSelected(option);
                      }}
                    >
                      {option.label}
                    </BsDropdown.Item>
                  );
                })}
              </Fragment>
            );
          })}
      </>
    );
  };

  render() {
    const {
      boxStyle,
      hideArrow,
      splitButton,
      disabled,
      variant,
      emptyText,
      fixedPlaceholder,
      ordersPersist,
      defaultSelection,
      btnClassName = '',
      optionsHeader,
      onClick,
    } = this.props;
    const { selectedOption } = this.state;
    const MainButton: any = splitButton ? SplitButton : DropdownButton;

    let compClassName = 'dropdown-component ' + btnClassName;
    if (boxStyle) {
      compClassName += ' box-style';
    }
    if (hideArrow) {
      compClassName += ' dropdown-hide-arrow';
    }

    let placeholder = emptyText;
    if (!_.isEmpty(fixedPlaceholder)) {
      placeholder = fixedPlaceholder;
    } else if (selectedOption && !_.isEmpty(selectedOption.label)) {
      placeholder = selectedOption.label;
    } else if (defaultSelection && !_.isEmpty(defaultSelection.label)) {
      placeholder = defaultSelection.label;
    }

    let { options } = this.props;

    if (options) {
      if (_.isArray(options)) {
        if (!ordersPersist) {
          options = _.sortBy(options, 'label');
        }
      } else {
        if (!ordersPersist) {
          Object.keys(options).forEach(k => {
            options[k] = _.sortBy(options[k], 'label');
          });
        }
      }
    }

    return (
      <div className={compClassName}>
        <MainButton
          id='dropdown-basic-button'
          variant={variant}
          title={placeholder}
          disabled={disabled}
          onToggle={(isToggled: boolean) => {
            this.props.onSplitToggle && this.props.onSplitToggle(isToggled);
          }}
          onClick={(e: any) => {
            this.props.onClick && this.props.onClick();
          }}
          data-testid='dropdown-basic-button'
        >
          {optionsHeader}
          {_.isArray(options)
            ? this.renderFlatDropdown(options)
            : this.renderGroupedDropdown(options)}
        </MainButton>
      </div>
    );
  }
}

export { Dropdown };

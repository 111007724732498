import React from 'react';
import { PageTitle } from '../../Common/PageTitle';
import { DropdownDateRangePicker } from '../../Common/DropdownDateRangePicker';
import { ISocialDashboardHeader } from '../Types/ugc.types';

const DashboardHeader = ({
  startDate,
  endDate,
  onDateRangeChange,
  user,
  type,
}: ISocialDashboardHeader): JSX.Element => {
  return (
    <PageTitle
      title={'Dashboard'}
      className={'dashboard-page-title'}
      tools={
        <div className={'d-flex align-items-center'}>
          <DropdownDateRangePicker
            key={'DropdownDateRangePicker'}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateRangeChange}
            type={type}
            user={user}
          />
        </div>
      }
    />
  );
};

export default DashboardHeader;

import React from 'react';
import _ from 'lodash';

import {
  JSONToCSVConvertor,
  getDisplayTimeFromTimeStamp,
  getNameFromUrlPath,
} from '../../constants';
import { ITableDataColumn } from '../Common/Table/table.data';
import { PropertyType, PropertyValueType } from './PropertyTypes';

export const getCSVFile = (data: any, columns: ITableDataColumn[], title: string) => {
  const result: any[] = [];

  // Edge case if the filter data is not available
  if (data.length === 0 && columns.length > 0) {
    const emptyColHeaders = [];
    const headers = {};
    _.map(columns, (col: any) => {
      headers[col.label] = '';
    });
    emptyColHeaders.push(headers);
    JSONToCSVConvertor(emptyColHeaders, title, true);
    return;
  }

  _.map(data, (item: any) => {
    const asset = {};
    columns.map((col: any) => {
      asset[col.label] =
        item[col.field] === undefined || item[col.field] === null || item[col.field] === ''
          ? '--'
          : item[col.field];
    });
    result.push(asset);
  });

  JSONToCSVConvertor(result, title, true);
};

export const massagedAssetsManagedByCustomer = (assets: any) => {
  return assets.map((asset: any) => {
    const { created_ts, brand, asset_hash, ip_address } = asset;
    return {
      ...asset,
      brand: brand?.brand_display_name,
      brand_id: brand?.brand_id,
      ip_address: _.isEmpty(ip_address) || ip_address === '0.0.0.0' ? '--' : ip_address,
      displayedCreatedTs: getDisplayTimeFromTimeStamp(created_ts),
      _index: asset_hash,
      created_ts: getDisplayTimeFromTimeStamp(created_ts),
    };
  });
};

export const massagedBrandPropertyAssets = (rawData: any[]) => {
  return rawData.map((property: any): any => {
    switch (property.valueType) {
      case PropertyValueType.FILE:
        property.value = property.valueFile;
        property.label = getNameFromUrlPath(property.valueFile);
        break;
      case PropertyValueType.JSON:
        switch (property.propertyType.value) {
          case PropertyType.TRADEMARK:
            property.value = property.valueJson.label;
            property.label = property.valueJson.label;
            break;
          case PropertyType.SOCIAL:
          case PropertyType.APP_STORE:
            property.value = property.valueJson.url;
            property.label = property.valueJson.url;
            break;
          case PropertyType.DOCUMENT:
            property.value = getNameFromUrlPath(property.valueJson.path);
            property.label = getNameFromUrlPath(property.valueJson.path);
            break;
          case PropertyType.MARKETPLACE:
            property.value = property.valueJson.sellerName;
            property.label = property.valueJson.sellerName;
            break;
          default:
            property.value = Object.values(property.valueJson).join(', ');
        }
        break;
      default:
        break;
    }
    return {
      ...property,
    };
  });
};

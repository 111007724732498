import React from 'react';

const CardContent = ({
  value,
  label,
  bottomText,
  background,
  parentLabel,
  handleRedirect,
}: any): JSX.Element => {
  const onHandleRouter = () => {
    handleRedirect(parentLabel, label.toUpperCase());
  };

  return (
    <div className={'card-content-wrapper'} onClick={onHandleRouter}>
      <div style={{ width: '100%', height: '17px', background }}></div>
      <div className={'card-content'}>
        <div className={'card-content-value'}>{value}</div>
        <div className={'card-content-label'}>{label}</div>
        <div className={'card-content-bottomText'}>{bottomText || ''}</div>
      </div>
    </div>
  );
};
export default CardContent;

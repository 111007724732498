import React from 'react';
import _ from 'lodash';
import { Form, Button } from 'react-bootstrap';
import './feedback.scss';
import { getTimestampAndUrlSHA256 } from '../../constants';
import DashboardService from '../../services/dashboard.service';
import { DispositionStatus } from '../Common/DispositionStatus';
import { LabelAndValue } from '../Common/LabelAndValue';
import { RadioButtons } from '../Common/RadioButtons';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import Card from 'react-bootstrap/Card';
import { PageTitle } from '../Common/PageTitle';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Image } from '../Common/Image';

interface IFeedbackProps {}

interface IData {
  srcUrl: string;
  disposition: string;
  brandName: string;
  imagePath: string;
  urlSHA256: string;
  createdTS: string;
}

interface IFeedbackState {
  data: IData;
  disposition: string;
  responseMessage: string;
}

class Feedback extends React.Component<IFeedbackProps, IFeedbackState> {
  private readonly dashboardService: DashboardService;
  private readonly timestamp: string;
  private readonly urlSHA256: string;

  constructor(props: IFeedbackProps) {
    super(props);
    this.dashboardService = new DashboardService();
    [this.timestamp, this.urlSHA256] = getTimestampAndUrlSHA256();

    this.state = {
      disposition: '',
      data: {
        srcUrl: '',
        disposition: '',
        brandName: '',
        imagePath: '',
        urlSHA256: '',
        createdTS: '',
      },
      responseMessage: '',
    };
  }

  componentDidMount(): void {
    this.dashboardService.getInsights(this.timestamp, this.urlSHA256).then((insightData: any) => {
      this.setState({
        data: insightData.scanResults,
      });
    });
  }

  onDispositionChange = (disposition: string) => {
    this.setState({
      disposition,
    });
  };

  submitDisputeVerdict = () => {
    const { data, disposition } = this.state;

    this.dashboardService
      .submitDisputeVerdict({
        dispute: {
          srcUrl: data.srcUrl,
          urlSHA256: data.urlSHA256,
          createdTS: data.createdTS,
          customerDisposition: disposition,
          currentDisposition: data.disposition,
        },
      })
      .then(res => {
        this.setState({
          responseMessage: 'Thanks for your feedback, we will check right away.',
        });
      });
  };

  render() {
    const { data, disposition, responseMessage } = this.state;

    return (
      <AuthenticationWrapper>
        <div className={'feedback-page'}>
          <PageTitle title={'Submit Feedback'} />
          <Row className={'feedback-page-content box-shadow'}>
            <Col lg={6}>
              <LabelAndValue label={'Source URL: '} value={data.srcUrl} />
              <LabelAndValue
                label={'Disposition: '}
                renderDom={<DispositionStatus status={data.disposition} />}
              />
              <LabelAndValue label={'Brand: '} value={data.brandName} />
              <Image imagePath={data.imagePath} alt='scan result screenshot' />
            </Col>
            <Col lg={6} className={'form-container'}>
              {_.isEmpty(responseMessage) ? (
                <Form className='form'>
                  <RadioButtons
                    label='Please choose appropriate disposition'
                    defaultValue={data.disposition}
                    options={[
                      { label: 'Clean', value: 'clean' },
                      { label: 'Phish', value: 'phish' },
                      { label: 'Scam', value: 'scam' },
                    ]}
                    onChange={this.onDispositionChange}
                    direction={'column'}
                  />
                  <Button
                    variant='primary'
                    onClick={this.submitDisputeVerdict}
                    disabled={_.isEmpty(disposition)}
                  >
                    Submit
                  </Button>
                </Form>
              ) : (
                <Card.Text className='card-lable'>{responseMessage}</Card.Text>
              )}
            </Col>
          </Row>
        </div>
      </AuthenticationWrapper>
    );
  }
}

export { Feedback };

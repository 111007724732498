import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IItem } from '../components/Reports/Reports.types';

export interface ReportsState {
  isGenerating: boolean;
  isEmailModalShown: boolean;
  selectedReport: IItem[];
  isEmailLoading: boolean;
  newlyGeneratedReport: number[];
  refetchOldTableTs?: number;
}

const initialState: ReportsState = {
  isGenerating: false,
  isEmailModalShown: false,
  selectedReport: [],
  isEmailLoading: false,
  newlyGeneratedReport: [],
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    toggleIsGenerating: (state, action: PayloadAction<boolean>) => {
      state.isGenerating = action.payload;
    },
    shouldShowEmailModal: (state, action: PayloadAction<boolean>) => {
      state.isEmailModalShown = action.payload;
    },
    shouldSetSelectedReport: (state, action: PayloadAction<IItem[]>) => {
      state.selectedReport = action.payload;
    },
    shouldSetIsEmailLoading: (state, action: PayloadAction<boolean>) => {
      state.isEmailLoading = action.payload;
    },
    shouldSetNewlyGeneratedReport: (state, action: PayloadAction<number[]>) => {
      state.newlyGeneratedReport = action.payload;
    },
    shouldRefetchOldTable: (state, action: PayloadAction<number>) => {
      state.refetchOldTableTs = action.payload;
    },
  },
});

export const {
  toggleIsGenerating,
  shouldShowEmailModal,
  shouldSetSelectedReport,
  shouldSetIsEmailLoading,
  shouldSetNewlyGeneratedReport,
  shouldRefetchOldTable,
} = reportsSlice.actions;

export default reportsSlice.reducer;

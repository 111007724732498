import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { IChartPlaceholderProps } from '../../../Common/DashboardWidgets/Types/chart.type';
import LineChart from '../../../Common/DashboardWidgets/LineChart';
import NoDetections from '../../../../assets/icons/NoDetections.svg';
import { convertLineChartDataCP } from '../../../Common/DashboardWidgets/utils';

const placeholder: IChartPlaceholderProps = {
  icon: NoDetections,
  title: `No detections found`,
  description: `We haven’t detected any phish and scam sites for this period. Please select another time frame.`,
};

const tooltipFormatter = (name: string): string => {
  if (name.indexOf('Total') !== -1) {
    return 'Total';
  }
  return '';
};

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  isLoading: boolean;
  widgetData: any;
}

function DashboardDetection({ startDate, endDate, isLoading, widgetData }: IComponentProps) {
  const lineChartProperties = {
    startDate,
    endDate,
    totalLabel: 'Total detected sites',
  };

  const chardData = Object.keys(widgetData).length
    ? convertLineChartDataCP(widgetData, lineChartProperties)
    : [];

  return (
    <LineChart
      title='Phish and Scam Site Detection'
      isLoading={isLoading}
      description='New Phish and Scam sites detected by time'
      data={chardData}
      placeholder={placeholder}
      yAxisTitle='Sites'
      tooltipFormatter={tooltipFormatter}
      hideLegend={true}
    />
  );
}

export { DashboardDetection };

import React, { ReactNode } from 'react';
import ModalOverlay from '../Common/ModalOverlay';

export interface ISocConfirmDeleteModalProps {
  show: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  children: ReactNode;
}

export function SocConfirmDeleteModal({
  show,
  title,
  children,
  onConfirm,
  onCancel,
}: ISocConfirmDeleteModalProps) {
  return (
    <ModalOverlay
      show={show}
      title={title}
      onCancel={onCancel}
      onSubmit={() => onConfirm}
      showButtons={true}
      submitButtonLabel={'Confirm'}
      size={'sm'}
    >
      {children}
    </ModalOverlay>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../helpers/hooks';
import { getBreachDataLeakDetails } from './darkweb-requests';
import { checkIsURL } from '../../../../constants';
export default function useOnDataLeakInsightDetails() {
  const [leakDetailText, setLeakDetailText] = useState<string[]>([]);
  const [dataLeakExternalLinks, setDataLeakExternalLinks] = useState<string[]>([]);
  const [breachDataLeakDetails, setBreachDataLeakDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentDataLeakParams = useAppSelector(state => state.darkWebReducer.currentDataLeakParams);

  const currentDataLeakEntityType = useAppSelector(
    state => state.darkWebReducer.currentDataLeakEntityType,
  );
  const shouldShowDataLeakModal = useAppSelector(
    state => state.darkWebReducer.shouldShowDataLeakModal,
  );
  const shouldShowDataLeakTablePopup = useAppSelector(
    state => state.darkWebReducer.shouldShowDataLeakTablePopup,
  );
  const breachDescriptions = useAppSelector(state => state.darkWebReducer.breachDescriptions);
  const currentSearchTermId = useAppSelector(state => state.darkWebReducer.currentSearchTermID);

  const currentDataLeakDescription = useAppSelector(
    state => state.darkWebReducer.currentDataLeakDescription,
  );

  const getExtraDataLeakDetails = useCallback(() => {
    if (breachDescriptions?.[currentDataLeakParams]) {
      setBreachDataLeakDetails(breachDescriptions?.[currentDataLeakParams]);
    } else {
      setBreachDataLeakDetails({});
    }
  }, [breachDescriptions, currentDataLeakParams]);

  //UseEffect to fetch the details of the data leak in DW dashbaord
  useEffect(() => {
    if (
      !currentDataLeakParams ||
      !currentDataLeakEntityType ||
      !shouldShowDataLeakModal ||
      !currentSearchTermId
    )
      return;
    const fetchDetails = async () => {
      setIsLoading(true);
      try {
        const response = await getBreachDataLeakDetails({
          entity: currentDataLeakEntityType,
          breach: currentDataLeakParams,
          searchTermId: currentSearchTermId,
        });
        if (response) {
          setLeakDetailText(response?.details?.slice(0, 5));
          setDataLeakExternalLinks(response?.externalLinks?.slice(0, 5));
        }
        getExtraDataLeakDetails();
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    void fetchDetails();
  }, [
    breachDescriptions,
    currentDataLeakParams,
    currentDataLeakEntityType,
    shouldShowDataLeakModal,
    currentSearchTermId,
    getExtraDataLeakDetails,
  ]);

  //UseEffect to map the details of the data leak in DW finding page
  useEffect(() => {
    clearPrevState();
    if (!currentDataLeakParams && !shouldShowDataLeakTablePopup && !currentDataLeakDescription) {
      return;
    } else if (currentDataLeakDescription !== '') {
      if (checkIsURL(currentDataLeakDescription)) {
        setDataLeakExternalLinks([currentDataLeakDescription]);
      } else {
        setLeakDetailText([currentDataLeakDescription]);
      }
    }
    getExtraDataLeakDetails();
  }, [
    currentDataLeakParams,
    shouldShowDataLeakTablePopup,
    currentDataLeakDescription,
    getExtraDataLeakDetails,
  ]);

  const clearPrevState = () => {
    setLeakDetailText([]);
    setDataLeakExternalLinks([]);
    setBreachDataLeakDetails({});
  };

  return {
    isLoading,
    leakDetailText,
    dataLeakExternalLinks,
    breachDataLeakDetails,
    currentDataLeakParams,
    shouldShowDataLeakTablePopup,
  };
}

import fetch from './api.service';
import { handleResponse } from './serviceWorker';
import { UrlTakedownInfoResponse } from '../components/SocTakedown/types/url-takedown-info-response.interface';
import { TakedownReview } from '../components/SocTakedown/types/takedown-review.interface';
import { SubmitSocTakedownDto } from '../components/SocTakedown/types/submit-soc-takedown.interface';
import { SocEmailTemplate } from '../components/SocTakedown/types/soc-email-template.interface';
import { SocEmailTemplateSaveDto } from '../components/SocTakedown/types/soc-email-template-save-dto';
import { TakedownRequestFormErrors } from '../components/SocTakedown/types/soc-takedown-errors.interface';
import { TakedownRequestForm } from '../components/SocTakedown/types/takedown-request-form.interface';
import { EmailTemplateForm } from '../components/SocTakedown/types/email-template-form.interface';
import { EmailTemplateFormErrors } from '../components/SocTakedown/types/email-template-form-errors.interface';
import { SocEmailTemplateSearchDto } from '../components/SocTakedown/types/soc-email-template-search-dto';
import { SearchResult } from '../types/search-result.interface';
import { AdhocScreenshotDto } from '../components/SocTakedown/types/historical-takedown.interface';
import { SocBrandAssetDetails } from '../components/SocTakedown/types/soc-brand-asset-response.interface.ts/soc-brand-asset-details.interface';
import { IBrandInfo } from '../constants';
import { IPropertyType, PropertyType } from '../components/Assets_v2/PropertyTypes';
import {
  AddressPropertyValue,
  DocumentPropertyValue,
  TrademarkPropertyValue,
} from '../types/brand-property-result.interface';
import AssetsService from './assests.service';
import { TimelineResponse } from '../types/timeline-response.interface';
import { SocRequestMessageDto } from '../components/SocTakedown/types/soc-request-documents';
import { TakedownEmailDetails } from '../components/SocTakedown/types/takedown-email-details.interface';
import { Base64Img } from '../types/base-64-img.interface';
import { SocTakedownStatusDto } from '../types/soc-takedown-status.dto';

const assetsService = new AssetsService();

export interface TakedownEmailDto {
  toEmails: string[];
  ccEmails: string[];
  bccEmails: string[];
  subject: string;
  urlSha256s: string[];
  bodyHtml: string;
  brandName?: string;
}
export interface SocCustomerMessageDto {
  urlSha256s: string[];
  message: string;
}

export class SocTakedownService {
  async getUrlTakedownInfo(urlSha256s: string[]): Promise<UrlTakedownInfoResponse> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ urlSha256s }),
    };

    return await handleResponse<UrlTakedownInfoResponse>(
      await fetch('/platform-api/v1/takedown/url-takedown-info', requestOptions),
    );
  }

  async getTakedownReview(takedownEmailDto: TakedownEmailDto): Promise<TakedownReview> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(takedownEmailDto),
    };
    const res = await handleResponse(
      await fetch('/platform-api/v1/takedown/takedown-review', requestOptions),
    );
    return res;
  }

  async submitTakedown(submitTakedownDto: SubmitSocTakedownDto, files: File[]): Promise<void> {
    const body = new FormData();
    body.append('details', JSON.stringify(submitTakedownDto));
    for (let i = 0; i < files.length; i++) {
      body.append('files', files[i]);
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: body,
    };
    const res = await handleResponse(
      await fetch('/platform-api/v1/takedown/takedown-submit', requestOptions),
    );
    return res;
  }

  async getRetakeScreenshot(url: string): Promise<{ screenshotPath: string }> {
    return await handleResponse<{ screenshotPath: string }>(
      await fetch(`/platform-api/web-scan/screenshot/${encodeURIComponent(url)}`, {
        credentials: 'include',
      }),
    );
  }

  async getBrandAssets(
    brandInfo: IBrandInfo,
    brandPropertyTypes: IPropertyType[],
  ): Promise<SocBrandAssetDetails> {
    const addressPropertyTypeId = this.fetchPropertyTypeId(
      PropertyType.HQ_ADDRESS,
      brandPropertyTypes,
    );
    const domainPropertyTypeId = this.fetchPropertyTypeId(
      PropertyType.OFFICIAL_WEBSITE,
      brandPropertyTypes,
    );
    const trademarkPropertyTypeId = this.fetchPropertyTypeId(
      PropertyType.TRADEMARK,
      brandPropertyTypes,
    );
    const documentPropertyTypeId = this.fetchPropertyTypeId(
      PropertyType.DOCUMENT,
      brandPropertyTypes,
    );

    const [addressRes, domainRes, trademarkRes, documentRes] = await Promise.all([
      assetsService.getBrandProperties<AddressPropertyValue>({
        query: {},
        filters: [
          {
            filterBy: { label: 'TypeId', value: 'type_id' },
            filterMethod: { label: 'Is', value: 'is' },
            filterValue: addressPropertyTypeId,
          },
        ],
        sort: {},
      }),
      assetsService.getBrandProperties({
        query: {},
        filters: [
          {
            filterBy: { label: 'TypeId', value: 'type_id' },
            filterMethod: { label: 'Is', value: 'is' },
            filterValue: domainPropertyTypeId,
          },
        ],
        sort: {},
      }),
      assetsService.getBrandProperties<TrademarkPropertyValue>({
        query: {},
        filters: [
          {
            filterBy: { label: 'TypeId', value: 'type_id' },
            filterMethod: { label: 'Is', value: 'is' },
            filterValue: trademarkPropertyTypeId,
          },
        ],
        sort: {},
      }),
      assetsService.getBrandProperties<DocumentPropertyValue>({
        query: {},
        filters: [
          {
            filterBy: { label: 'TypeId', value: 'type_id' },
            filterMethod: { label: 'Is', value: 'is' },
            filterValue: documentPropertyTypeId,
          },
        ],
        sort: {},
      }),
    ]);

    const addressArray: AddressPropertyValue[] = [];
    const domainArray: string[] = [];
    const trademarkArray: TrademarkPropertyValue[] = [];
    const documentsArray: DocumentPropertyValue[] = [];
    addressRes.properties.forEach(item => {
      if (item) {
        addressArray.push(item.valueJson);
      }
    });
    domainRes.properties.forEach(item => {
      if (item) {
        domainArray.push(item.valueString);
      }
    });
    trademarkRes.properties.forEach(item => {
      if (item) {
        trademarkArray.push({ ...item.valueJson, brand: item.brand.brandDisplayName });
      }
    });
    documentRes.properties.forEach(item => {
      if (item) {
        documentsArray.push({ ...item.valueJson, brand: item.brand.brandDisplayName });
      }
    });
    return {
      brand: {
        brandName: brandInfo.brand.displayName,
        brandId: brandInfo.brand.brandId,
      },
      organization: { name: brandInfo.brand.orgName },
      address: addressArray[0],
      domain: domainArray[0],
      trademarks: trademarkArray,
      documents: documentsArray,
    };
  }

  async submitSocCustomerMessage(messageDto: SocCustomerMessageDto, files: File[]): Promise<void> {
    const body = new FormData();
    body.append('details', JSON.stringify(messageDto));
    for (let i = 0; i < files.length; i++) {
      body.append('files', files[i]);
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: body,
    };
    const res = await handleResponse(
      await fetch('/platform-api/v1/takedown/soc-customer-message', requestOptions),
    );
    return res;
  }

  async getADHocScreenshots(sha256: string): Promise<{ screenshots: AdhocScreenshotDto[] }> {
    return fetch(`/platform-api/web-scan/adhoc-timeline/${sha256}`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  async getEmailTemplateBrands(): Promise<{ brandId: number; displayName: string }[]> {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    const res = await handleResponse(
      await fetch(`/platform-api/v1/takedown/soc-email-template/brands`, requestOptions),
    );
    return res;
  }

  async getEmailTemplate(id: number): Promise<SocEmailTemplate> {
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    const res = await handleResponse(
      await fetch(`/platform-api/v1/takedown/soc-email-template/${id}`, requestOptions),
    );
    return res;
  }

  async getEmailTemplates(
    searchDto: SocEmailTemplateSearchDto,
  ): Promise<SearchResult<SocEmailTemplate>> {
    const searchParams = new URLSearchParams();
    if (searchDto?.brandId != null) {
      searchParams.append('brandId', searchDto.brandId.toString());
    }

    if (searchDto?.name != null) {
      searchParams.append('name', searchDto.name.toString());
    }

    if (searchDto?.description != null) {
      searchParams.append('description', searchDto.description.toString());
    }

    if (searchDto?.offset != null) {
      searchParams.append('offset', searchDto.offset.toString());
    }

    if (searchDto?.limit != null) {
      searchParams.append('limit', searchDto.limit.toString());
    }

    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    const res = await handleResponse(
      await fetch(
        `/platform-api/v1/takedown/soc-email-template/search?${searchParams.toString()}`,
        requestOptions,
      ),
    );
    return res;
  }

  async createEmailTemplate(saveDto: SocEmailTemplateSaveDto): Promise<SocEmailTemplate> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(saveDto),
    };
    const res = await handleResponse<SocEmailTemplate>(
      await fetch(`/platform-api/v1/takedown/soc-email-template`, requestOptions),
    );
    return res;
  }

  async updateEmailTemplate(
    id: number,
    saveDto: SocEmailTemplateSaveDto,
  ): Promise<SocEmailTemplate> {
    const requestOptions: RequestInit = {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(saveDto),
    };
    const res = await handleResponse<SocEmailTemplate>(
      await fetch(`/platform-api/v1/takedown/soc-email-template/${id}`, requestOptions),
    );
    return res;
  }

  async deleteEmailTemplate(id: number): Promise<void> {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    await handleResponse<SocEmailTemplate>(
      await fetch(`/platform-api/v1/takedown/soc-email-template/${id}`, requestOptions),
    );
  }

  validateRequestForm(updatedForm: TakedownRequestForm): TakedownRequestFormErrors {
    const errors: TakedownRequestFormErrors = {
      to: [],
      subject: [],
      body: [],
    };

    if (!updatedForm.submittedViaForm && updatedForm.toEmails.trim().length < 1) {
      errors.to.push('At least one email must be specified.');
    }

    if (updatedForm.subject.trim().length < 1) {
      errors.subject.push('Subject is required.');
    }

    if (updatedForm.bodyHtml.trim().length < 1) {
      errors.body.push('Message body is required.');
    }

    return errors;
  }

  validateEmailTemplate(updatedForm: EmailTemplateForm): EmailTemplateFormErrors {
    const errors: EmailTemplateFormErrors = {
      name: [],
      description: [],
      subject: [],
      bodyHtml: [],
    };

    if (updatedForm.name.trim().length < 1) {
      errors.name.push('Name is required.');
    }

    if (updatedForm.description.trim().length < 1) {
      errors.description.push('Description is required.');
    }

    if (updatedForm.subject.trim().length < 1) {
      errors.subject.push('Subject is required.');
    }

    if (updatedForm.bodyHtml.trim().length < 1) {
      errors.bodyHtml.push('A body is required.');
    }

    return errors;
  }

  async getTimeline(urlSHA256: string): Promise<TimelineResponse> {
    return await handleResponse(
      await fetch('/platform-api/v1/intel/timeline/' + urlSHA256, {
        credentials: 'include',
      }),
    );
  }

  async autoFillTemplate(takedownEmailDto: TakedownEmailDto): Promise<TakedownEmailDetails> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(takedownEmailDto),
    };
    const res = await handleResponse<TakedownEmailDetails>(
      await fetch('/platform-api/v1/takedown/autofill/soc-email-template', requestOptions),
    );
    return res;
  }

  async getCurrentTakedownStatus(urlSha256: string): Promise<SocTakedownStatusDto> {
    const res = await handleResponse(
      await fetch(`/platform-api/v1/takedown/current-status/${urlSha256}`),
    );
    return res;
  }

  async socRequestDocuments(saveDto: SocRequestMessageDto): Promise<{ result: string }> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(saveDto),
    };
    const res = await handleResponse<{ result: string }>(
      await fetch(`/platform-api/v1/takedown/soc-requested-document`, requestOptions),
    );
    return res;
  }

  async getAttachment(urlSha256: string, s3Url: string): Promise<Base64Img> {
    const attachmentKey = s3Url.split('.com/')[1];
    return await handleResponse<Base64Img>(
      await fetch(
        `/platform-api/v1/takedown/attachment/${urlSha256}/${encodeURIComponent(attachmentKey)}`,
      ),
    );
  }

  private fetchPropertyTypeId(typeValue: PropertyType, brandPropertyTypes: IPropertyType[]) {
    const propertyType = brandPropertyTypes.find(x => x.value === typeValue);
    if (propertyType == null) {
      throw new Error(`Could not find property type: ${propertyType}.`);
    }
    return propertyType.id;
  }
}

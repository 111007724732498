import React from 'react';
import _ from 'lodash';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { LabelAndValue } from '../Common/LabelAndValue';
import { ImagesSlide, IImageProp } from '../Common/ImagesSlide';
import {
  appConstants,
  getIp,
  getLocalStorageValue,
  getNavPathnameForInsightPage,
  setLocalStorageValue,
} from '../../constants';
import DashboardService from '../../services/dashboard.service';
import { history } from '../../helpers';
import './insights.scss';
import { Table } from '../Common/Table/table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { PageTitleInsights } from '../Common/PageTitleInsights';
import Card from 'react-bootstrap/Card';
import { IDropdownOption } from '../Common/Dropdown';
import { renderDispositionFilter } from './insights.component';
import { WEB_DASHBOARD_PATHNAME } from '../MonitorAndTakedown/constants';
import { DEFAULT_FILTER } from './constants';

interface IDomain {
  domain: string;
  srcUrl: string;
  disposition: string;
}

interface IInsightsState {
  isLoading: boolean;
  ip: string;
  seen: string;
  isp: string;
  asn: string;
  location: string;
  countryCode: string;
  relatedDomains: IDomain[];
  relatedDomainsFilter: IDropdownOption;
  imagePaths: IImageProp[];
}

interface IIPInsightsProps {
  type: string;
  isPublic?: boolean;
  webDashboardRoute?: any;
  isCommunity?: boolean;
}

class IpInsights extends React.Component<IIPInsightsProps, IInsightsState> {
  private readonly dashboardService: DashboardService;
  private originRelatedDomains: any = [];
  private readonly ip: string;

  constructor(props: IIPInsightsProps) {
    super(props);
    //TOOD: empty the state after API is ready
    this.dashboardService = new DashboardService();
    this.ip = getIp();
    this.state = {
      isLoading: true,
      ip: this.ip,
      seen: '',
      isp: '',
      asn: '',
      location: '',
      countryCode: '',
      relatedDomains: [],
      relatedDomainsFilter: DEFAULT_FILTER,
      imagePaths: [],
    };
    window.document.title = 'IP Insight | Dashboard | Bolster Platform';
  }

  componentDidMount() {
    const { isPublic } = this.props;
    if (isPublic) {
      this.setState({ isLoading: false });
      return;
    } else {
      this.dashboardService
        .getIpInsights(this.ip)
        .then((ipInsightData: any) => {
          const insights = _.get(ipInsightData, ['insights']);

          this.setState({
            isLoading: false,
            location: insights.country,
            countryCode: insights.countryCode,
            asn: insights.asn,
            isp: insights.networkOwner,
          });
        })
        .catch(err => {
          console.log(err);
        });

      this.dashboardService
        .getIpPhishCount(this.ip)
        .then((data: any) => {
          this.setState({
            seen: data['result'],
          });
        })
        .catch((err: any) => {
          console.log(JSON.stringify(err));
        });

      this.dashboardService
        .getRelatedURLs(this.ip)
        .then((data: any) => {
          const related = _.get(data, ['result']);
          const relatedDomains: IDomain[] = [];
          this.originRelatedDomains = relatedDomains;
          const imagePaths: IImageProp[] = [];
          _.forEach(related, (item: any) => {
            const { srcUrl, disposition, host, imagePath } = item;
            relatedDomains.push({
              domain: host,
              srcUrl: srcUrl,
              disposition,
            });
            imagePaths.push({
              name: host,
              path: imagePath,
            });
          });
          this.setState({
            relatedDomains,
            imagePaths,
          });
        })
        .catch((err: any) => {
          console.log(JSON.stringify(err));
        });
    }
  }

  onChangeRelatedDomainsFilter = (filter: IDropdownOption) => {
    let newRelatedDomains = this.originRelatedDomains.concat();
    if (filter.value === 'other') {
      const blacklist = ['clean', 'phish'];
      newRelatedDomains = _.filter(newRelatedDomains, (item: any) => {
        return blacklist.indexOf(item['disposition']) === -1;
      });
    } else if (filter.value === 'phish' || filter.value === 'clean') {
      newRelatedDomains = _.filter(newRelatedDomains, (item: any) => {
        return filter.value === item['disposition'];
      });
    }
    this.setState({
      relatedDomainsFilter: filter,
      relatedDomains: newRelatedDomains,
    });
  };

  onWhoISClick = (domain?: string) => {
    localStorage.setItem(
      'whoisState',
      JSON.stringify({
        ip: this.ip,
        type: this.props.type,
        domain: domain,
      }),
    );
  };

  renderColumn = () => {
    const COLUMNS = [
      {
        header: 'URLs related to this domain',
        accessor: 'srcUrl',
        isLongText: true,
        render: (data: any) => {
          const { domain, srcUrl } = data;
          if (_.isEmpty(domain)) {
            return '--';
          }
          return (
            <Link
              to={`${
                this.props.webDashboardRoute ? this.props.webDashboardRoute : `/${this.props.type}`
              }/domain/${domain}`}
              onClick={() => this.onWhoISClick(domain)}
            >
              {srcUrl}
            </Link>
          );
        },
      },
    ];
    return COLUMNS;
  };
  render() {
    const {
      isLoading,
      ip,
      seen,
      isp,
      asn,
      location,
      countryCode,
      relatedDomains,
      relatedDomainsFilter,
      imagePaths,
    } = this.state;
    const countryClassName = 'flag-icon flag-icon-' + countryCode;

    return (
      <AuthenticationWrapper skipAuthentication={this.props?.isPublic}>
        <PageTitleInsights
          title={ip}
          backFn={() => {
            setLocalStorageValue('insightPageFrom', '');
            const lastVisistedPage = getLocalStorageValue('lastVisitedPageCheckPhish');
            this.props.isCommunity && _.isEmpty(lastVisistedPage)
              ? history.push(-2)
              : history.push(lastVisistedPage);
          }}
        />
        {isLoading ? (
          <div className={'insight-spinner-container'}>
            <Spinner animation='border' />
          </div>
        ) : (
          <div className={'page-content ip-insights-content'}>
            <Row>
              <Col lg={6}>
                <Card className='card-between'>
                  <Card.Header>IP Address Details</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <LabelAndValue
                          label={'Past Phish on IP'}
                          value={seen}
                          direction={'column'}
                        />
                      </Col>
                      <Col md={6}>
                        <LabelAndValue label={'ASN'} value={asn} direction={'column'} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <LabelAndValue label={'ISP'} value={isp} direction={'column'} />
                      </Col>
                      <Col md={6}>
                        <LabelAndValue
                          label={'location'}
                          renderDom={
                            <div className={'country-name'}>
                              <span className={countryClassName} />
                              {location}
                            </div>
                          }
                          direction={'column'}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card bg='light' className='card-between'>
                  <Table
                    tableClassName='tool-bar-container-padding'
                    disableDatePicker={true}
                    disableFilter={true}
                    data={relatedDomains}
                    title={'Related Domains'}
                    columns={this.renderColumn()}
                    customTools={renderDispositionFilter(
                      relatedDomainsFilter,
                      this.onChangeRelatedDomainsFilter,
                    )}
                  />
                </Card>
              </Col>
              <Col lg={6}>
                {imagePaths.length ? (
                  <Card bg='light' className='card-between images-slide-card'>
                    <Card.Body>
                      {imagePaths.length && <ImagesSlide images={imagePaths} />}
                    </Card.Body>
                  </Card>
                ) : null}
              </Col>
            </Row>
          </div>
        )}
      </AuthenticationWrapper>
    );
  }
}

export { IpInsights };

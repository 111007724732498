import React, { useState } from 'react';
import { AuthenticationWrapper } from '../../../../AuthenticationWrapper';
import { LoadingWrapper } from '../../../../Common/LoadingWrapper';
import _ from 'lodash';
import SubNavigationBar from '../../../../InsightsContainer/Common/SubNavigationBar';
import { Route } from 'react-router';
import FindingInsight from './FindingInsight';
import { ugcTypeAndInsightRoutes } from '../../../constants';
import FindingInsightHeader from './Header';
import RequestTakedownModal from '../../Common/RequestTakedownModal';
import MarkAsSafelistModal from '../../Common/MarkAsSafelistModal';
import { Timeline } from '../../../../Timeline/timeline.component';
import { DomTree } from '../../../../DomTree/domTree.component';
import moment from 'moment';
import { useOnFetchUgcInsightData } from './useOnFetchUgcInsightData';
import { useGetSubNavigationTab } from './useGetSubNavigationTab';
import { useOnUgcModalAction } from './useOnUgcModalAction';
import { Whois } from '../../../../WhoIsInfo/whois.component';
import { useAppSelector } from '../../../../../helpers/hooks';
import DeleteCustomTagsModal from '../../../../Common/Tags/DeleteCustomTagsModal';
import EditCustomTagsModal from '../../../../Common/Tags/EditCustomTagsModal';

export interface IUgcInsightsContainer {
  moduleType: string;
}
export default function UgcInsightsContainer({ moduleType }: IUgcInsightsContainer) {
  const sha256 = window.location.href.split('/').pop();

  //Custom hook for fetching insights data
  const { isLoading, setIsLoading, findingDetails, safelist, takedown, scanCountInfo } =
    useOnFetchUgcInsightData(sha256 as string, moduleType);

  //Custom hook for sub bar routes actions
  const { subBarItems } = useGetSubNavigationTab(sha256 as string, moduleType);
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);

  //Custom hook for modal actions
  const {
    toggleMarkSafelistModal,
    toggleResumeTakedownModal,
    toggleRequestTakedownModal,
    requestTakedownModalShown,
    resumeTakedownModalShown,
    markSafelistModalShown,
  } = useOnUgcModalAction();

  const renderDomTreeComp = () => {
    return (
      <DomTree
        timestamp={`${moment(findingDetails.createdTs).valueOf()}`}
        urlSHA256={sha256 as string}
        type={moduleType}
      />
    );
  };
  const renderTimeLineComp = () => {
    return <Timeline urlSHA256={sha256 as string} type={moduleType} />;
  };
  const renderInsightComp = () => {
    return (
      <FindingInsight
        findingDetails={findingDetails}
        takedownDetails={takedown}
        safelistDetails={safelist}
        moduleType={moduleType}
        scanCountInfo={scanCountInfo}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    );
  };
  const renderWhoisComp = () => {
    return (
      <Whois
        ip={findingDetails.ip}
        host={findingDetails.host}
        domain={findingDetails.primaryDomain}
        type={moduleType}
      />
    );
  };
  return (
    <AuthenticationWrapper>
      <FindingInsightHeader
        data={findingDetails}
        onRequestTakedown={toggleRequestTakedownModal}
        onResumeTakedown={toggleResumeTakedownModal}
        onMarkSafelist={toggleMarkSafelistModal}
        moduleType={moduleType}
      />
      <SubNavigationBar isPublic={false} subBarItems={subBarItems} />
      <div data-testid='ugc-insights-container'>
        <LoadingWrapper isLoading={isLoading}>
          {!isLoading && (
            <>
              <Route
                path={`${ugcTypeAndInsightRoutes[moduleType]}/${sha256}`}
                render={renderInsightComp}
              />
              <Route
                path={`${ugcTypeAndInsightRoutes[moduleType]}/timeline/${sha256}`}
                render={renderTimeLineComp}
              />
              <Route
                path={`${ugcTypeAndInsightRoutes[moduleType]}/dom/${sha256}`}
                render={renderDomTreeComp}
              />
              <Route
                path={`${ugcTypeAndInsightRoutes[moduleType]}/whois/${sha256}`}
                render={renderWhoisComp}
              />
            </>
          )}
        </LoadingWrapper>
        {requestTakedownModalShown && (
          <RequestTakedownModal
            data={[findingDetails]}
            show={requestTakedownModalShown}
            onCloseModal={toggleRequestTakedownModal}
            onModalSubmit={toggleRequestTakedownModal}
            type={moduleType}
          />
        )}
        {resumeTakedownModalShown && (
          <RequestTakedownModal
            data={[findingDetails]}
            show={resumeTakedownModalShown}
            isResumeTakedown
            onCloseModal={toggleResumeTakedownModal}
            onModalSubmit={toggleResumeTakedownModal}
            title='Provide Additional Info To Resume Takedown'
            description='Please provide more information regarding '
            submitButtonLabel='Submit'
            type={moduleType}
          />
        )}
        {markSafelistModalShown && (
          <MarkAsSafelistModal
            data={[findingDetails]}
            show={markSafelistModalShown}
            onCloseModal={toggleMarkSafelistModal}
            onModalSubmit={toggleMarkSafelistModal}
            type={moduleType}
          />
        )}
      </div>
      {modifyCustomTag.isDeleteMode && <DeleteCustomTagsModal />}
      {modifyCustomTag.isEditMode && <EditCustomTagsModal />}
    </AuthenticationWrapper>
  );
}

import React from 'react';
import './LoadingSpinner.scss';

interface LoadingSpinner {
  size?: number;
  customOuterClass?: string;
  customInnerClass?: string;
}

export function LoadingSpinner({
  customOuterClass = '',
  customInnerClass = '',
  size = 35,
}: LoadingSpinner) {
  return (
    <div className={`outer-layer ${customOuterClass}`} style={{ height: size, width: size }}>
      <div className={`inner-layer ${customInnerClass}`} data-testid='spinner'></div>
    </div>
  );
}

import _ from 'lodash';
import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import CheckCircleSmall from '../../assets/icons/CheckCircleSmall.svg';
import DangerCircleRed from '../../assets/icons/DangerCircleRed.svg';
import { duplicateObject, integrationDocsSufixMap } from '../../constants';
import PlaybookService from '../../services/playbook.service';
import { Dropdown } from '../Common/Dropdown';
import { ConnectorParametersInput } from './connector.parameters.input';

import '../Playbook/Style/playbook.scss';
import { ConnectorParametersTextarea } from './connector.parameters.textarea';
import ThemeContext from '../../context/ThemeContext';
import './styles.scss';
import { Button } from '@material-ui/core';

enum ConnectorTypeId {
  Email = 1,
  API = 2,
  Slack = 3,
}

const DEFAULT_VALUES = {
  name: '',
  connectorTypeId: '',
  details: {
    method: 'POST',
  },
  isNewAdded: true,
};
const DEFAULT_TEST = {
  loading: false,
  isSuccess: true,
  message: '',
};
const playbookService = new PlaybookService();

interface IConnectorDefinition {
  label: string;
  value: string;
  docUrl: string;
  fields: any[];
  nonGlobalFields: any[];
  isSelectable: boolean;
}

interface INewConnectorModalProps {
  show: boolean;
  toggleModal: () => void;
  onSubmit: (values: any) => void;
  initValues?: any;
  fromIntegration?: boolean;
}

const NewConnectorModal = ({
  show,
  toggleModal,
  onSubmit,
  initValues,
  fromIntegration,
}: INewConnectorModalProps) => {
  const [values, setValues] = useState(initValues || DEFAULT_VALUES);

  const [test, setTest] = useState(DEFAULT_TEST);
  const [connectorDefinition, setConnectorDefinition] = useState<IConnectorDefinition[]>([]);
  const [fields, setFields] = useState<any[]>();
  const [nonGlobalFields, setNonGlobalFields] = useState<any[]>();

  const { selectedTheme } = useContext(ThemeContext);

  const isGenericApi = values?.selectedIntegration === 'API Integration';
  const isM365 = values.selectedIntegration === 'Microsoft 365 Exchange/Defender';
  const isSplunk = values.selectedIntegration === 'Splunk';
  const isXSOAR = values.selectedIntegration === 'Cortex XSOAR';

  const exchangeId = useMemo(() => {
    const exchange = _.find(connectorDefinition, ['label', 'Exchange']);
    return exchange?.value;
  }, [connectorDefinition]);

  useEffect(() => {
    void playbookService.getConnectorDefinition().then(res => {
      if (res.result?.length) {
        setConnectorDefinition(
          _.map(res.result, def => {
            return {
              label: def.label,
              value: def.id,
              docUrl: def.doc_url,
              fields: _.filter(def.metadata, i => i.isGlobal),
              nonGlobalFields: _.filter(def.metadata, i => !i.isGlobal),
              isSelectable: def.metadata.some((field: any) => field.isGlobal),
            };
          }),
        );
      }
    });
  }, []);

  useEffect(() => {
    setValues(initValues || DEFAULT_VALUES);
    if (initValues) {
      const selectedType = _.find(connectorDefinition, ['value', initValues.connectorTypeId]) || {};
      setFields(_.get(selectedType, 'fields', []));
      setNonGlobalFields(_.get(selectedType, 'nonGlobalFields', []));
    }
  }, [initValues, connectorDefinition]);

  const existAndResetModal = () => {
    setValues(DEFAULT_VALUES);
    setTest(DEFAULT_TEST);
    setFields([]);
    setNonGlobalFields([]);
    toggleModal();
  };

  const onConnectorTypeChange = useCallback(
    selection => {
      const selectedType = _.find(connectorDefinition, ['value', selection.value]) || {};
      setValues({
        ...values,
        connectorTypeId: selection.value,
      });
      setFields(_.get(selectedType, 'fields', []));
      setNonGlobalFields(_.get(selectedType, 'nonGlobalFields', []));
    },
    [connectorDefinition, values],
  );

  const onUpdateParameters = useCallback(
    (key: string, value) => {
      const newValues = duplicateObject(values);
      newValues.details[key] = value;
      setValues(newValues);
      setTest(DEFAULT_TEST);
    },
    [values, test],
  );

  const hardCodeFields = (values: any) => {
    values.details.selectedIntegration =
      values.details.selectedIntegration || values.selectedIntegration;

    if (!isGenericApi) {
      values.details.header = [{ parameter: 'Content-Type', value: 'application/json' }];
      values.details.form = [{ parameter: 'file', value: '%FILE_CONTENT%' }];
      values.details.body = '%FILE_CONTENT%';
    }

    if (isSplunk || isXSOAR) {
      values.details.header.push({
        parameter: 'Authorization',
        value: `${isSplunk ? 'Splunk ' : ''}${values.details.extraToken}`,
      });
      delete values.details.extraToken;
    }

    if (isM365) {
      values.connectorTypeId = exchangeId;
    }
  };

  const testConnector = (event: any) => {
    event.preventDefault();
    setTest({ loading: true, isSuccess: true, message: 'Testing connector...' });

    hardCodeFields(values);
    playbookService
      .testConnectorType(values.connectorTypeId, values.details)
      .then(() => {
        setTest({ loading: false, isSuccess: true, message: 'Success!' });
      })
      .catch(err => {
        setTest({ loading: false, isSuccess: false, message: err });
      });
  };

  const renderDetailField = (field: any) => {
    if (field.type === 'parameters') {
      if (!isGenericApi) return null;

      return (
        <ConnectorParametersInput
          key={field.key}
          values={values}
          field={field}
          onUpdate={onUpdateParameters}
        />
      );
    } else if (field.type === 'textarea') {
      if (!isGenericApi) return null;

      return (
        <ConnectorParametersTextarea
          key={field.key}
          values={values}
          field={field}
          onUpdate={onUpdateParameters}
        />
      );
    }
    if (!values.details) {
      values.details = {};
      values.details[field.key] = '';
    }

    if (field.key === 'method') {
      const isGenericApi = values?.selectedIntegration === 'API Integration';
      if (!isGenericApi && fromIntegration) return null;
      return (
        <div key={field.key}>
          <Form.Label className={'mt-3'}>{field.label}</Form.Label>
          <Form.Control
            as='select'
            style={{ width: '180px' }}
            defaultValue={values.details['method'] || 'POST'}
            onChange={(e: any) => {
              const newValues = duplicateObject(values);
              newValues.details[field.key] = e.currentTarget.value;
              setValues(newValues);
              setTest(DEFAULT_TEST);
            }}
          >
            {(isGenericApi || !fromIntegration
              ? ['GET', 'POST', 'PUT', 'DELETE', 'PATCH']
              : ['POST']
            ).map((methodName: any, index: number) => (
              <option key={index} value={methodName}>
                {methodName}
              </option>
            ))}
          </Form.Control>
        </div>
      );
    }

    return (
      <>
        {!isM365 && (
          <div key={field.key}>
            <Form.Label className={'mt-3'}>{field.label}</Form.Label>
            <Form.Control
              value={values.details[field.key] || ''}
              placeholder={
                field.key === 'url' ? `Paste URL obtained from ${values?.selectedIntegration}` : ''
              }
              onChange={(e: any) => {
                const newValues = duplicateObject(values);
                newValues.details[field.key] = e.currentTarget.value;
                setValues(newValues);
                setTest(DEFAULT_TEST);
              }}
            />
          </div>
        )}
      </>
    );
  };

  const selectedConnector = _.find(connectorDefinition, ['value', values.connectorTypeId]);
  let docUrl = '';
  let label = '';
  if (selectedConnector) {
    docUrl = selectedConnector.docUrl;
    label = selectedConnector.label;
  }

  const isFieldValid = useCallback(() => {
    if (!values.name || !values.connectorTypeId) return false;

    if (isM365) return Boolean(values.details?.TENANT_ID && values.details?.ORGANISATION_ID);
    switch (values.connectorTypeId) {
      case ConnectorTypeId.API:
        return Boolean(values.details?.url);
      case ConnectorTypeId.Email:
        return Boolean(values.details?.email);
      case ConnectorTypeId.Slack:
        return Boolean(values.details?.SLACK_CHANNELS && values.details?.SLACK_TOKEN);
      default:
        return true;
    }
  }, [values, isM365]);

  const getDocUrl = () => {
    // For previous connectors, we dont know values.details.selectedIntegration, we use unified link https://bolster.ai/kbarticles/api-integration
    // For later created connectors, we know values.details.selectedIntegration, so use specific link
    return values.details?.selectedIntegration
      ? `https://bolster.ai/kbarticles/${
          integrationDocsSufixMap[values.details.selectedIntegration as string]
        }`
      : 'https://bolster.ai/kbarticles/api-integration';
  };

  const renderFields = () => {
    const shouldDisableBtn = !isFieldValid();
    return (
      <>
        <Modal.Header>
          <Modal.Title>
            <div className='d-flex align-items-center'>
              {values.isNewAdded ? `Setup` : 'Edit Connector'}
            </div>
          </Modal.Title>

          {!values.isNewAdded && (
            <div>
              <span className='mr-2 header-tips'>Learn how to configure</span>

              <a href={getDocUrl()} target='_blank' rel='noreferrer'>
                <img src='/integrations/question-mark.svg' alt='question-mark' />
              </a>
            </div>
          )}
        </Modal.Header>
        <Modal.Body className={'connector-modal-body modal-body'}>
          {fromIntegration && (
            <div className='connector-hints'>
              Follow the steps on the right side to first setup {values?.selectedIntegration} and
              then configure its integration with Bolster platform.
            </div>
          )}
          <Form.Label>Connector Name</Form.Label>
          <Form.Control
            required={true}
            value={values.name}
            placeholder='Provide a name for the connector'
            onChange={(e: any) => {
              setValues({
                ...values,
                name: e.currentTarget.value,
              });
            }}
          />
          {isGenericApi && (
            <>
              <Form.Label className={'mt-3'}>Connector Type</Form.Label>
              <Dropdown
                boxStyle
                disabled={!values.isNewAdded}
                defaultSelection={{
                  label,
                  value: values.connectorTypeId,
                }}
                options={
                  values.isNewAdded
                    ? connectorDefinition.filter(def => def.isSelectable)
                    : connectorDefinition
                }
                onChange={onConnectorTypeChange}
              />
            </>
          )}

          {values.details && _.map(fields, renderDetailField)}

          {isM365 && (
            <>
              <div key='M365-tenant-id'>
                <Form.Label className={'mt-3'}>Tenant ID</Form.Label>
                <Form.Control
                  value={values.details?.TENANT_ID || ''}
                  placeholder={`Enter your Tenant ID`}
                  onChange={(e: any) => {
                    const newValues = { ...values };
                    newValues.details.TENANT_ID = e.currentTarget.value;
                    setValues(newValues);
                    setTest(DEFAULT_TEST);
                  }}
                />
              </div>
              <div key='M365-domain-name'>
                <Form.Label className={'mt-3'}>Primary Domain</Form.Label>
                <Form.Control
                  value={values.details?.ORGANISATION_ID || ''}
                  placeholder={`Enter Primary Domain`}
                  onChange={(e: any) => {
                    const newValues = { ...values };
                    newValues.details.ORGANISATION_ID = e.currentTarget.value;
                    setValues(newValues);
                    setTest(DEFAULT_TEST);
                  }}
                />
              </div>
            </>
          )}

          {(isSplunk || isXSOAR) && (
            <div key={'extra-token'}>
              <Form.Label className={'mt-3'}>Token Value</Form.Label>
              <Form.Control
                value={values.details?.extraToken || ''}
                placeholder={`Paste Token obtained from ${values?.selectedIntegration}`}
                onChange={(e: any) => {
                  const newValues = { ...values };
                  newValues.details.extraToken = e.currentTarget.value;
                  setValues(newValues);
                  setTest(DEFAULT_TEST);
                }}
              />
            </div>
          )}

          {nonGlobalFields && nonGlobalFields.length > 0 && (
            <>
              <div className={'mt-3 mb-0'}>
                Optional: To test the connector, fill out the field(s) below and click on 'Test
                Connector'
              </div>
              {_.map(nonGlobalFields, renderDetailField)}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex align-items-center justify-content-between'>
            {test.loading || test.message ? (
              <div>
                {test.loading ? (
                  <Spinner className='spinner' animation='border' variant='primary' size='sm' />
                ) : (
                  <img src={test.isSuccess ? CheckCircleSmall : DangerCircleRed} alt={'Status'} />
                )}
                <span className='ml-2'>{test.message}</span>
                {!test.isSuccess && (
                  <span>
                    <span className='ml-2 mr-2'>|</span>
                    <a href='' onClick={testConnector}>
                      Re-test Connector
                    </a>
                  </span>
                )}
              </div>
            ) : (
              <Button
                className='mr-auto'
                onClick={testConnector}
                variant='outlined'
                color='primary'
                disabled={shouldDisableBtn}
              >
                Test Connector
              </Button>
            )}
            <div className='d-flex justify-content-end connector-modal-footer-buttons'>
              <Button
                variant='text'
                className='cancel-button'
                onClick={() => {
                  existAndResetModal();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={shouldDisableBtn}
                onClick={() => {
                  hardCodeFields(values);
                  onSubmit(values);
                  existAndResetModal();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </>
    );
  };

  if (values.isNewAdded && fromIntegration) {
    return <div className='new-connector-flat-container'>{renderFields()}</div>;
  }

  return (
    <Modal
      className={`${selectedTheme} new-connector-modal`}
      show={show}
      size='lg'
      onHide={existAndResetModal}
    >
      {renderFields()}
    </Modal>
  );
};

export { NewConnectorModal };

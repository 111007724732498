import React from 'react';
import './LinearProgressBar.scss';

interface IProgressBar {
  bgcolor?: string;
  progress: string;
  height: number;
  width: number;
  displayText: string;
  textPosition: 'top' | 'bottom';
}

const ProgressBar = (props: IProgressBar) => {
  const { height, progress, width, displayText, textPosition } = props;
  const Parentdiv = {
    height: height,
    width: `${width}%`,
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
  };

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900,
  };
  return (
    <div className='progress-bar-container'>
      {textPosition === 'top' && <div className='result-text'>{displayText}</div>}
      <div style={Parentdiv} className='bar'>
        <div style={Childdiv} className='progress'>
          <span style={progresstext}>{displayText}</span>
        </div>
      </div>
      {textPosition === 'bottom' && <div className='result-text'>{displayText}</div>}
    </div>
  );
};

export default ProgressBar;

import { PlatformMainModules, featureIsAvailable } from '../constants';
import { store } from './store';

export const getAvailableModules = () => {
  const user = store.getState().dashboardReducer?.user;

  const allAvailableModules = PlatformMainModules.filter(
    module =>
      featureIsAvailable(user, module.enabledCode) &&
      !featureIsAvailable(user, module.disabledCode),
  );

  return allAvailableModules;
};

import React from 'react';
import _ from 'lodash';
import Card from 'react-bootstrap/Card';
import './LabelAndValue.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CopyButton, { ICopyButtonProps } from './CopyButton';
import RedirectButton from './RedirectButton';

interface IValueProps extends ICopyButtonProps {
  tooltip?: string;
  renderDom?: any;
  toLink?: string;
  longTextLineNumberLimit?: 0 | 1 | 2 | 3;
  customButton?: any;
  redirectButton?: boolean;
}

export interface IComponentProps extends IValueProps {
  label: string;
  tooltip?: string;
  direction?: 'row' | 'column';
  noMarginBottom?: boolean;
  noColon?: boolean;
  width?: React.CSSProperties['width'];
}
interface ILabelAndValueProps extends Omit<IComponentProps, 'value'> {
  value?: string | string[];
  alignItems?: string;
}

const ValueDom = (props: IValueProps) => {
  const {
    value = '',
    renderDom = null,
    tooltip,
    longTextLineNumberLimit = 0,
    toLink = '',
    copyButton = false,
    redirectButton = false,
    onCopy = _.noop,
  } = props;
  let longTextClassName = 'card-value';
  if (longTextLineNumberLimit) {
    longTextClassName += ' long-text-ellipsis-' + longTextLineNumberLimit;
  }

  let content = _.isEmpty(toLink) ? (
    <Card.Text className={longTextClassName}> {value} </Card.Text>
  ) : (
    <Link className={longTextClassName} to={toLink}>
      {value}
    </Link>
  );

  if (longTextLineNumberLimit || tooltip) {
    content = (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id='tooltip-bulk-scan'>
            <Card.Text> {tooltip || value} </Card.Text>
          </Tooltip>
        }
      >
        {content}
      </OverlayTrigger>
    );
  }

  return (
    <div className={'d-flex align-items-center'}>
      {renderDom ? renderDom : content}
      <CopyButton value={value} copyButton={copyButton} onCopy={onCopy} />
      {redirectButton && <RedirectButton value={value} />}
    </div>
  );
};

export const LabelAndValue = (props: ILabelAndValueProps): any => {
  const {
    label,
    value,
    renderDom = null,
    direction = 'row',
    noMarginBottom = false,
    redirectButton = false,
    customButton,
    noColon = false,
    alignItems,
    width = '100%',
  } = props;
  const style = {
    display: 'flex',
    flexDirection: direction,
    alignItems: alignItems ?? (direction === 'row' ? 'center' : 'flexStart'),
    width: width,
  };

  let compClassName = 'label-and-value-component';
  if (noMarginBottom) {
    compClassName += ' no-margin-bottom';
  }

  return (
    <div className={compClassName} style={style}>
      <Card.Text className={`card-label direction-${direction}`}>
        {label + (noColon ? '' : ':')}
        {customButton}
      </Card.Text>
      {renderDom ? (
        <div className={'d-flex'}>
          {renderDom}
          {typeof value === 'string' && <CopyButton {...props} value={value} />}
          {typeof value === 'string' && redirectButton && <CopyButton {...props} value={value} />}
        </div>
      ) : typeof value === 'object' ? (
        _.map(value, (v, i) => <ValueDom {...props} value={v} key={v + i} />)
      ) : (
        <ValueDom {...props} value={value} />
      )}
    </div>
  );
};

import React, { useState, createContext, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../helpers';
import UserService from '../services/user.service';
import {
  DashBoardDto,
  IAlertDispatchProps,
  UserAppSettings,
  IAppDispatchProps,
  featureIsAvailable,
  appConstants,
  setLocalStorageValue,
  getLocalStorageValue,
} from '../constants';
import { alertActions, appActions } from '../actions';
import DashboardService from '../services/dashboard.service';
import { SCANTYPE } from '../components/Checkphish/AllScans/scanType.enum';
import {
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_MASTER_PATHNAME,
} from '../components/DomainMonitoring/constant';

const AppSettingContext = createContext({
  landingPage: '',
  defaultLandingPage: '',
  teamViewValue: false,
  scanLevel: SCANTYPE.SOLO,
  saveLandingPage: _.noop,
  saveTeamView: _.noop,
});
export default AppSettingContext;

interface IAppSettingProps {
  user: DashBoardDto;
  children: React.ReactElement;
}

type Prop = IAppSettingProps & IAlertDispatchProps & IAppDispatchProps;

const AppSettingContextProvider = (props: Prop): any => {
  const userService = new UserService();
  const dashboardService = new DashboardService();
  const { user, children, alertSuccess, alertError, runByClientApp } = props;

  const getLandingPage = (user?: DashBoardDto) => {
    if (user) {
      const landingPageSetting = _.find(user.userAppSetting, {
        setting_name: UserAppSettings.LadingPage,
      });
      if (landingPageSetting) {
        return landingPageSetting.setting_value;
      }
    }
    const landingPageURL = getLocalStorageValue('selectedLandingPage');
    if (!_.isEmpty(landingPageURL)) {
      return landingPageURL;
    }

    // fallback landing page
    const landingUrl = runByClientApp({
      onBolster: () => '/web/dashboard',
      onCheckPhish: () => '/domain-monitoring/dashboard',
    });

    return landingUrl;
  };

  const [defaultLandingPage] = useState(getLandingPage());
  const [landingPage, setLandingPage] = useState(getLandingPage());
  const updateUserSettings = (settingName: string, settingValue: any) => {
    return userService.updateUserSettings({
      setting_name: settingName,
      setting_value: settingValue,
    });
  };
  useEffect(() => {
    setLandingPage(getLandingPage(user));
    if (featureIsAvailable(user, appConstants.FEATURE_CODE.TYPOSQUATTING_CHECKPHISH_DASHBOARD_V2)) {
      setLandingPage(
        user.monitoredDomainPresent &&
          user.domainMonitoringDaysToExpire &&
          user.domainMonitoringDaysToExpire > 0
          ? TYPOSQUATTING_DASHBOARD_PATHNAME
          : TYPOSQUATTING_MASTER_PATHNAME,
      );
    }
    const switchValue = getSwitchValue(user) === 'true' ? true : false;
    setTeamViewValue(switchValue);
    setScanLevel(switchValue ? SCANTYPE.TEAM : SCANTYPE.SOLO);
    setLocalStorageValue('showTeamView', switchValue);
  }, [user]);

  const saveLandingPage = (href: string, updateSetting: boolean = true) => {
    const oldLandingPage = landingPage;
    setLandingPage(href);
    if (updateSetting) {
      updateUserSettings(UserAppSettings.LadingPage, href)
        .then(res => {
          alertSuccess('Save App Setting Successfully');
          setLocalStorageValue('selectedLandingPage', href);
        })
        .catch(err => {
          alertError(err);
          setLandingPage(oldLandingPage);
        });
    }
  };
  const [teamViewValue, setTeamViewValue] = useState(false);
  const [scanLevel, setScanLevel] = useState(SCANTYPE.SOLO);
  const getSwitchValue = (user: DashBoardDto) => {
    const switchValue = _.find(user.userAppSetting, {
      setting_name: UserAppSettings.TeamView,
    });
    if (switchValue) {
      return switchValue.setting_value;
    }
    return false;
  };

  const saveTeamView = (teamViewValue: boolean) => {
    updateUserSettings(UserAppSettings.TeamView, teamViewValue)
      .then(res => {
        setTeamViewValue(teamViewValue);
        setScanLevel(teamViewValue ? SCANTYPE.TEAM : SCANTYPE.SOLO);
        dashboardService.getUserInfo();
      })
      .catch(err => {
        alertError(err);
      });
  };
  return (
    <AppSettingContext.Provider
      value={{
        defaultLandingPage,
        landingPage,
        teamViewValue,
        scanLevel,
        saveLandingPage,
        saveTeamView,
      }}
    >
      {children}
    </AppSettingContext.Provider>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};
const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  runByClientApp: appActions.runByClientApp,
};

const connectedAppSettingContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppSettingContextProvider);
export { connectedAppSettingContextProvider as AppSettingContextProvider };

import { userConstants } from '../constants';

const confirmRegistrationReducer = (state = { result: true }, action: any) => {
  switch (action.type) {
    case userConstants.CONFIRM_REGISTRATION_SUCCESS:
      return {
        result: true,
      };
    case userConstants.CONFIRM_REGISTRATION_FAILURE:
      return {
        result: false,
      };
    default:
      return state;
  }
};
export { confirmRegistrationReducer };

import React from 'react';
import _ from 'lodash';
import { ILineChartDataProps } from './Types/chart.type';
import { colors } from '../../../constants';
import moment from 'moment';

interface ILineChartProperties {
  startDate: moment.Moment;
  endDate: moment.Moment;
  totalLabel: string;
  liveLabel: string;
  takedownLabel: string;
}

const convertLineChartData = (dateStatusMap: any, lineChartProperties: ILineChartProperties) => {
  const { startDate, endDate, totalLabel, liveLabel, takedownLabel } = lineChartProperties;
  const totalData: ILineChartDataProps = {
    legend: `${totalLabel}: `,
    color: colors.allScans,
    values: [],
  };
  const liveData: ILineChartDataProps = {
    legend: `${liveLabel}: `,
    color: colors.live,
    values: [],
  };
  const downData: ILineChartDataProps = {
    legend: `${takedownLabel}: `,
    color: colors.takedown,
    values: [],
  };

  for (let time = startDate.valueOf(); time <= endDate.valueOf(); time += 24 * 3600000) {
    const date = moment(time).format('YYYY-MM-DD');
    if (_.isEmpty(dateStatusMap[date])) {
      dateStatusMap[date] = { live: 0, down: 0 };
    }
  }
  dateStatusMap = _.chain(dateStatusMap)
    .forEach((item, date) => {
      item.date = moment(date).valueOf();
    })
    .sortBy([
      item => {
        return item.date;
      },
    ])
    .forEach(item => {
      const { live, down, date } = item;
      downData.values.push([date, down]);
      liveData.values.push([date, live]);
      totalData.values.push([date, live + down]);
    })
    .value();

  return [totalData, liveData, downData];
};

interface ILineChartPropertiesCP {
  startDate: moment.Moment;
  endDate: moment.Moment;
  totalLabel: string;
}

const convertLineChartDataCP = (
  dateStatusMap: any,
  lineChartProperties: ILineChartPropertiesCP,
) => {
  const { startDate, endDate, totalLabel } = lineChartProperties;
  const totalData: ILineChartDataProps = {
    legend: `${totalLabel}: `,
    color: colors.allScans,
    values: [],
  };

  for (let time = startDate.valueOf(); time <= endDate.valueOf(); time += 24 * 3600000) {
    const date = moment(time).format('YYYY-MM-DD');
    if (!dateStatusMap[date]) {
      dateStatusMap[date] = 0;
    }
  }

  totalData.values = Object.keys(dateStatusMap)
    .sort((a, b) => {
      return moment(a).valueOf() - moment(b).valueOf();
    })
    .map(item => [moment(item).valueOf(), dateStatusMap[item]]);

  return [totalData];
};

export { convertLineChartData, convertLineChartDataCP };

import React from 'react';
import './LoadingWrapper.scss';
import Spinner from 'react-bootstrap/Spinner';

interface IComponentProps {
  isLoading: boolean;
  className?: string;
  size?: 'sm';
}

interface IComponentState {}

class LoadingWrapper extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    isLoading: true,
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    let { isLoading, className = '', size } = this.props;
    let content = this.props.children;

    if (isLoading) {
      className += ' is-loading';
      content = (
        <div
          className={'loading-wrapper-component ' + className}
          data-testid='loading-wrapper-component'
        >
          <Spinner animation='border' size={size} />
        </div>
      );
    }

    return content || null;
  }
}

export { LoadingWrapper };

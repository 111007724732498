import React from 'react';

import '../Style/playbook.scss';
import { LabelAndValue } from '../../Common/LabelAndValue';
import { Link } from 'react-router-dom';
import Redirect from '../../assets/icons/Redirect.svg';
import DangerCircleRed from '../../../assets/icons/DangerCircleRed.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface ILastRunResult {
  data: any;
}

const LastRunResult = ({ data }: ILastRunResult) => {
  const { isSuccess, status } = data.lastRun;
  if (isSuccess) {
    if (status !== 'COMPLETE') {
      return 'In Progress';
    }
    return data.lastRunCount;
  } else if (isSuccess === false) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id={'tooltip-' + Math.random()}>Please contact us about the error</Tooltip>
        }
      >
        <img src={DangerCircleRed} alt={'Error'} />
      </OverlayTrigger>
    );
  }
  return 0;
};

interface IPlaybookTileProps {
  data: any;
  events: any;
}

const PlaybookTile = ({ data, events }: IPlaybookTileProps) => {
  const showPlaybookDetail = () => {
    events.onEditPlaybook(data);
  };

  const onRunNowPlaybook = () => {
    events.onRunNowPlaybook(data);
  };

  return (
    <div className={'playbook-tile-component flex-fill d-flex'}>
      <div className={'tile-status' + (data.isActive ? ' active' : '')} />
      <div className={'tile-info flex-fill d-flex flex-column'}>
        <div className={'tile-info-top d-flex align-items-center justify-content-between'}>
          <div className={'d-flex align-items-center'}>{data.name}</div>
          <div className={'d-flex align-items-center'}>
            {/* <Link to={ '/playbook/insights/id/' + data.id }>
              <img src={ Redirect } alt={ "playbook" } className={ 'mr-3 cursor-pointer' }/>
            </Link> */}
            {data.displaySchedule}
          </div>
        </div>
        <div className={'tile-info-bottom d-flex align-items-center'}>
          <LabelAndValue
            noMarginBottom
            direction={'column'}
            label={'Total Times Run'}
            value={data.runCount}
          />
          <LabelAndValue
            noMarginBottom
            direction={'column'}
            label={'# from the last Run'}
            renderDom={<LastRunResult data={data} />}
          />
          <LabelAndValue noMarginBottom direction={'column'} label={'Author'} value={data.author} />
          <div className={'run-now-button cursor-pointer'} onClick={onRunNowPlaybook}>
            Run Now
          </div>
        </div>
      </div>
    </div>
  );
};

export { PlaybookTile, LastRunResult };

import { Card, CardContent } from '@material-ui/core';
import React, { EventHandler, useContext, useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TogglePlanType } from './constants';
import CheckPhishContactUsContext, {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import AppSettingContext from '../../context/AppSettingContext';
import StripeService from '../../services/stripe.service';
import _ from 'lodash';
import moment from 'moment';
import PlanChip from './Common/PlanChip';
import { IPricingPlan, PRICING_PLANS } from './types';
import { generatePrice } from './helpers';
import { useState } from 'react';
import { history } from '../../helpers';

export enum EPlanTermType {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export enum DowngradType {
  TYPOSQUAT_FREE = 'typosquat_free',
  TYPOSQUAT_DOWNGRADE = 'typosquat_downgrade',
  LIVESCAN_DOWNGRADE = 'livescan_downgrade',
  None = '',
}

export interface TogglePlanProps {
  popUpType: TogglePlanType;
  cardTitle: string;
  actionButtonText: string;
  onActionButtonHandler?: (event: React.SyntheticEvent) => void;
  afterSuccessFn: () => void;
  onGoBackActionHandler: () => void;
  DowngradeType?: DowngradType;
}

const stripeService = new StripeService();

const TogglePlan = ({
  popUpType,
  cardTitle,
  onGoBackActionHandler,
  onActionButtonHandler,
  DowngradeType,
  actionButtonText,
  afterSuccessFn,
}: TogglePlanProps) => {
  const {
    currentLiveScanSubscription,
    currentTyposquatSubscription,
    numberOfDomains,
    numberOfScans,
    selectedTerm,
    selectedPlans,
    subscription,
  } = useContext<ICheckPhishSubscription>(CheckphishSubscriptionContext);
  const { setShowFeedbackPopup, setAfterCancelationFeedback } = useContext(
    CheckPhishContactUsContext,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { landingPage } = useContext(AppSettingContext);

  const downgradePlan = () => {
    setLoading(true);
    const plan =
      DowngradeType === DowngradType.LIVESCAN_DOWNGRADE
        ? {
            priceId: currentLiveScanSubscription?.id || 0,
            quantity: numberOfScans || 0,
          }
        : {
            priceId: currentTyposquatSubscription?.id || 0,
            quantity: DowngradeType === DowngradType.TYPOSQUAT_FREE ? 0 : numberOfDomains || 0,
          };
    stripeService
      .downgradeSubscription(plan)
      .then()
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
        onGoBackActionHandler();
        afterSuccessFn();
        history.push(landingPage);
      });
  };

  const cancelSubscription = () => {
    setLoading(true);
    stripeService
      .cancelSubscription('v2')
      .then(() => {
        setShowFeedbackPopup(true);
        setAfterCancelationFeedback(true);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
        onGoBackActionHandler();
        afterSuccessFn();
        history.push(landingPage);
      });
  };

  const description = useMemo(() => {
    if (popUpType === TogglePlanType.CANCEL) {
      return (
        <p className='plan--availability' data-test-id='description'>
          Your {currentTyposquatSubscription && <strong>Starter Plan</strong>}{' '}
          {!_.isEmpty(currentLiveScanSubscription) &&
            !_.isEmpty(currentTyposquatSubscription) &&
            'and '}
          {currentLiveScanSubscription && <strong>API Add-On</strong>} will be canceled together,
          but is still available until the end of your billing period on{' '}
          {moment(subscription?.subscriptionEndDate).format('MMMM Do YYYY')}
        </p>
      );
    } else {
      if (
        DowngradeType === DowngradType.TYPOSQUAT_FREE &&
        !_.isEmpty(currentTyposquatSubscription)
      ) {
        return (
          <p className='plan--availability' data-testid='description'>
            Your current plan will be downgraded to free, but is still available until the end of
            your billing period on {moment(subscription.subscriptionEndDate).format('MMMM Do YYYY')}
            .
          </p>
        );
      }
      if (
        DowngradeType === DowngradType.TYPOSQUAT_DOWNGRADE &&
        !_.isEmpty(currentTyposquatSubscription)
      ) {
        return (
          <p className='plan--availability' data-testid='description'>
            Your current Starter will be downgraded to {numberOfDomains}{' '}
            {numberOfDomains > 1 ? 'Domains' : 'Domain'} per {selectedTerm.value} , but is still
            available until the end of your billing period on{' '}
            {moment(subscription.subscriptionEndDate).format('MMMM Do YYYY')}.
          </p>
        );
      }
    }
    if (
      DowngradeType === DowngradType.LIVESCAN_DOWNGRADE &&
      !_.isEmpty(currentLiveScanSubscription)
    ) {
      return (
        <p className='plan-availability' data-testid='description'>
          Your current API Add On will be downgraded to {numberOfScans} scans per{' '}
          {selectedTerm.value} , but is still available until the end of your billing period on{' '}
          {moment(subscription.subscriptionEndDate).format('MMMM Do YYYY')}.
        </p>
      );
    }
    return (
      <p className='plan-availablity' data-testid='description'>
        Your current plan will be downgraded
      </p>
    );
  }, [popUpType, currentLiveScanSubscription, currentTyposquatSubscription]);

  const renderPlanChips = () => {
    return (
      <div style={{ display: 'flex', width: '180px', justifyContent: 'space-between' }}>
        {currentTyposquatSubscription && <PlanChip planName={PRICING_PLANS.STARTER} />}
        {currentLiveScanSubscription && <PlanChip planName={PRICING_PLANS.PROGESSIONAL_API} />}
      </div>
    );
  };

  const currentPrice = useMemo(() => {
    let price = 0;
    if (currentTyposquatSubscription) {
      const selectedTypoPlan = selectedPlans.find(
        (plan: IPricingPlan) => plan.priceId === currentTyposquatSubscription?.id,
      );
      if (selectedTypoPlan) {
        price += generatePrice(
          { ...selectedTypoPlan, price: currentTyposquatSubscription.price },
          currentTyposquatSubscription?.quantity,
        );
      }
    }
    if (currentLiveScanSubscription) {
      const selectedLiveScanPlan = selectedPlans.find(
        (plan: IPricingPlan) => plan.priceId === currentLiveScanSubscription?.id,
      );
      if (selectedLiveScanPlan) {
        price += generatePrice(
          { ...selectedLiveScanPlan, price: currentLiveScanSubscription.price },
          currentLiveScanSubscription?.quantity,
        );
      }
    }
    return price;
  }, [selectedPlans, currentLiveScanSubscription, currentTyposquatSubscription]);
  return (
    <div className='new-pricing-modal-toggle' data-testid='toggle-plan-wrap'>
      <Card className='plan-card'>
        <span className='close-html-entity' data-testid='close-btn' onClick={onGoBackActionHandler}>
          &#10005;
        </span>
        <CardContent className='plan-card--content'>
          {/* Plan Heading */}
          <div className='card-content--wrapper--title'>
            <h3 className='plan-card--title'>{cardTitle}</h3>

            <span className='plan-card--current-plan--title'>Current Plan</span>
          </div>

          {/*  Divider  */}
          <div className='divider'></div>

          {/* Card Content */}
          <div className='card-content-wrapper--description '>
            <div className='plan-card--pack'>
              {renderPlanChips()}
              <h3 className='pack-type--pricing' data-testid='current-plan-price'>
                ${currentPrice} per {selectedTerm.value.replace('ly', '')}
              </h3>
            </div>
            <div className='plan-card-description-container'>
              {description}
              <p className='plan--service-term'>
                If you change your mind, you can renew your subscription. By canceling your plan,
                you agree to CheckPhish&apos;s{' '}
                <a href='https://checkphish.bolster.ai/terms-of-service/'>Terms of Service</a> and{' '}
                <a href='https://checkphish.bolster.ai/privacy-policy/'>Privacy Policy</a>.
              </p>
            </div>

            {/* Button Container */}
            <div className='button-container'>
              <Button
                className='btn cta-btn'
                onClick={popUpType === TogglePlanType.CANCEL ? cancelSubscription : downgradePlan}
                disabled={loading}
              >
                {loading ? 'Processing...' : actionButtonText}
              </Button>
              <Button className='btn plain-btn' onClick={onGoBackActionHandler}>
                Go back
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TogglePlan;

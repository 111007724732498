export const FILES_LIMIT = 10;

export enum EFileTypes {
  IMAGE = 'image/*,.eml,text/plain',
  PDF = 'application/pdf',
}

export enum FileTypeDescription {
  IMAGE = 'Files must be less than 5 MB. Allowed file types: png, txt, jpg, jpeg, svg, gif or eml',
  PDF = 'Files must be less than 25 MB. Allowed file type: pdf',
  FILES = 'Files must be less than 10 MB. Allowed file types: pdf, png, txt, jpg, jpeg, svg, gif or eml',
}

export enum EFileSizeInMB {
  IMAGE = 5,
  PDF = 25,
}

export enum UploadFileErrors {
  MAX_COUNT,
  MAX_FILE_SIZE,
}

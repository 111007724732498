import React from 'react';
import { ICustomeFilterData } from './GlobalTakedown';
import { Row, Col, Card } from 'react-bootstrap';
import {
  AllTakedownIcon,
  AppStoreIcon,
  SocialMediaIcon,
  WebTakedownIcon,
} from '../../assets/SVGIcons';
import './TakedownFilterWidget.scss';
import _ from 'lodash';
import { appConstants } from '../../constants';
import { useAppSelector } from '../../helpers/hooks';

const MODULE_ICON_MAP = {
  [appConstants.CONTENT_TYPE.WEB]: () => <WebTakedownIcon />,
  [appConstants.CONTENT_TYPE.SOCIAL]: () => <SocialMediaIcon />,
  [appConstants.CONTENT_TYPE.APP_STORE]: () => <AppStoreIcon />,
  all: () => <AllTakedownIcon />,
};

interface IProps {
  filterData: ICustomeFilterData[];
  selectedModule: string;
  handleFilterChange: (value: string) => void;
}
export default function TakedownFilterWidget({
  filterData,
  selectedModule,
  handleFilterChange,
}: IProps) {
  const user = useAppSelector(state => state.dashboardReducer.user);

  const widgetDetailsView = (details: ICustomeFilterData) => {
    const { label, value, submittedByUser, submittedByBolster, commingSoon } = details;
    return (
      <div
        className={`widget-details-item ${
          commingSoon ? 'coming-soon' : selectedModule === value ? 'active' : ''
        }`}
        key={label}
        onClick={() => {
          commingSoon ? _.noop() : handleFilterChange(value);
        }}
      >
        <Row className='tile'>
          <Col className='tile-head d-flex mb-1' md={12}>
            <Col className='no-padding icon' as={'div'}>
              {MODULE_ICON_MAP[value]()}
            </Col>
            <Col className='no-padding mb-0 title' style={{ top: '5px' }} md={10} as={'h5'}>
              {label}
            </Col>
          </Col>
          {!commingSoon ? (
            <Col className='tile-body d-flex' md={12}>
              <Col className='data-set no-padding'>
                <Col className='head no-padding' as={'div'} md={12}>
                  {submittedByBolster}
                </Col>
                <Col className='body no-padding' as={'div'} md={12}>
                  {'Submitted by Bolster'}
                </Col>
              </Col>
              <Col className='data-set no-padding'>
                <Col className='head no-padding' as={'div'} md={12}>
                  {submittedByUser}
                </Col>
                <Col className='body no-padding' as={'div'} md={12}>
                  {`Submitted by ${user?.organization || '--'}`}
                </Col>
              </Col>
            </Col>
          ) : (
            <div className='available-soon'>Available Soon</div>
          )}
        </Row>
      </div>
    );
  };

  return (
    <Card className={'global-takedown-filter-container'}>
      <div className='widget-details-container d-flex'>
        {filterData.map((item, index) => {
          return widgetDetailsView(item as ICustomeFilterData);
        })}
      </div>
    </Card>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../helpers/hooks';
import { setShouldRefreshTimeline } from '../../reducers/timeline.reducer';
import _ from 'lodash';

interface IUseWebSocDetailFormValuesProps {
  initialValues: {
    title: string;
    comments: string;
    timeStamp: string;
    screenShot: string;
  };
  onCancel: () => void;
}

export default function useWebSocDetailFormValues({
  initialValues,
  onCancel,
}: IUseWebSocDetailFormValuesProps) {
  const [isInvalid, setIsInvalid] = useState(true);
  const values = useRef<any>(initialValues);

  const dispatch = useAppDispatch();

  const updateValue = (value: any, field: string) => {
    values.current[field] = value;
    const { title, comments, timeStamp } = values.current;
    setIsInvalid(_.isEmpty(title) || _.isEmpty(comments) || _.isEmpty(timeStamp));
  };

  const handleTextFieldChange = (value: string, controlId: string) => {
    updateValue(value, controlId);
  };

  const onHandleFilesSelect = (files: File[]) => {
    values.current['screenShot'] = files;
  };

  const onModalCancel = () => {
    setIsInvalid(true);
    values.current = {
      ...initialValues,
    };
    onCancel();
  };

  useEffect(() => {
    return () => {
      dispatch(setShouldRefreshTimeline(false));
    };
  }, [dispatch]);

  return { onModalCancel, values, handleTextFieldChange, onHandleFilesSelect, isInvalid };
}

import React, { useEffect, useState } from 'react';
import ModalOverlay from '../Common/ModalOverlay';
import { ExportScanDetails } from './types/export-scan-details.interface';
import { Nullable } from '../../types/common';
import { Spinner } from 'react-bootstrap';
import { invoke } from '../../helpers/async';
import DashboardService from '../../services/dashboard.service';
import {
  CSV_EXPORT_MAX,
  appConstants,
  featureIsAvailable,
  getLocalStorageValue,
} from '../../constants';
import { useAppSelector } from '../../helpers/hooks';
import { transformWebTableFilterToApiParams } from '../Common/Table/ag-table/ag-filter-to-api-params';

const FETCH_PAGE_SIZE = 500;

const dashboardService = new DashboardService();

export interface IExportScanHistoryModalProps {
  show: boolean;
  details?: Nullable<ExportScanDetails>;
  onCancel: () => void;
  onComplete: () => void;
  maxCounts?: number;
  fetchForExport: (pageNumber: number, pageSize: number) => Promise<any>;
  type: string;
  tableId?: string;
}

export function ExportScanHistoryModal({
  show,
  details,
  onComplete,
  onCancel,
  maxCounts,
  fetchForExport,
  type,
  tableId,
}: IExportScanHistoryModalProps) {
  const [error, setError] = useState<Nullable<string>>(null);
  const [totalCount, setTotalCount] = useState<Nullable<number>>(null);
  const [fetchedCount, setFetchedCount] = useState<Nullable<number>>(null);

  const user = useAppSelector(state => state.dashboardReducer.user);
  const isWebTablesGroupView = useAppSelector(state => state.tableReducer.isWebTablesGroupView);

  useEffect(() => {
    let subscribed = true; // will be false on unmount

    invoke(async () => {
      setError(null);
      setTotalCount(null);
      setFetchedCount(null);
      const isOldTable =
        !user || !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);

      if (details != null) {
        try {
          let exportCSVCounts = 0;
          if (type === appConstants.CONTENT_TYPE.WEB && maxCounts === undefined) {
            //fetch the counts for web module
            //TODO remove after done with ag grid
            if (details && details.query && details.filters && isOldTable) {
              const res = await dashboardService.getDataForMonitorAndTakedownCount(
                { ...details.query, type: tableId },
                details.filters,
              );
              exportCSVCounts = res.count;
            } else if (!isOldTable && tableId) {
              const agGridFilter = getLocalStorageValue(['tableFilterModel', tableId]);
              const convertedFilterFromAgGrid = transformWebTableFilterToApiParams(agGridFilter);
              const { must, mustNot, query } = convertedFilterFromAgGrid;
              const apiParams = {
                must,
                mustNot,
                query: {
                  ...query,
                  type: tableId,
                  isGroupViewEnabled: isWebTablesGroupView,
                },
                sortBy: details && details.sort,
              };

              const res = await dashboardService.getDataForMonitorAndTakedownCountViaAgGrid(
                apiParams,
              );
              exportCSVCounts = res.count;
            }

            //for new table
          } else if (maxCounts !== undefined && maxCounts > 0) {
            exportCSVCounts = maxCounts;
          }
          if (exportCSVCounts >= CSV_EXPORT_MAX) {
            setError(
              `Too many records for export, current limit is ${CSV_EXPORT_MAX}. Please filter results and try again.`,
            );
          } else if (subscribed) {
            // check if modal is still mounted
            // set progress counts
            setTotalCount(exportCSVCounts);
            setFetchedCount(0);

            let records: Record<string, any>[] = [];
            const pageSize = FETCH_PAGE_SIZE;
            let pageNumber = 0;

            //loop through each page building the export report
            while (pageNumber * pageSize < exportCSVCounts) {
              const res = await fetchForExport(pageNumber, pageSize);
              records = records.concat(res?.data);
              pageNumber = pageNumber + 1;
              if (subscribed) {
                setFetchedCount(pageNumber * pageSize);
              } else {
                break;
              }
            }

            if (details) {
              await dashboardService.buildTableDataCsv(details.columns, records, details.fileName);
            }
            // notify report is built
            onComplete();
          }
        } catch (e) {
          console.error(e);
          setError(`Error occurred processing the csv file.`);
        }
      }
    });

    return () => {
      subscribed = false;
    };
  }, [details]);

  function body() {
    if (error) {
      return <div className={'text-danger'}>{error}</div>;
    } else {
      return (
        <React.Fragment>
          <span className={'mr-1'}>Building the csv export</span>
          {totalCount != null && fetchedCount != null ? (
            <span className={'mr-1'}>
              {' '}
              ({Math.min(totalCount, fetchedCount)} / {totalCount})
            </span>
          ) : (
            <span className={'mr-1'}>...</span>
          )}
          <div data-testid='exporting-spinner'>
            <Spinner variant={'primary'} size={'sm'} animation='border' />
          </div>
        </React.Fragment>
      );
    }
  }

  return (
    <ModalOverlay
      show={show}
      title={'Exporting to CSV'}
      onCancel={() => onCancel()}
      onSubmit={() => onComplete()}
      showButtons={false}
      size={'sm'}
    >
      <div>{body()}</div>
    </ModalOverlay>
  );
}

import React from 'react';
import ModalOverlay from '../../../Common/ModalOverlay';
import './UploadFailedModal.scss';
import CopyButton from '../../../Common/CopyButton';

interface IUploadFailedModal {
  urlsList: string[];
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
}
export default function UploadFailedModal({
  showModal,
  urlsList,
  setShowModal,
}: IUploadFailedModal) {
  return (
    <ModalOverlay
      show={showModal}
      title={'URL Upload Failed'}
      isLoading={false}
      submitButtonLabel={'Log out'}
      cancelButtonLabel={`Close`}
      customClassName={`${'alert-session-wrapper failed-upload-modal'}`}
      centered={false}
      hideCancelButton={false}
      hideSubmitBtn={true}
      onSubmit={() => {}}
      onCancel={() => setShowModal(false)}
    >
      <div className='modal-container'>
        <div className='heading'>URL Upload has failed for following urls :</div>
        <ol className='content'>
          {urlsList.map((url: string, index: number) => (
            <li className='url-data'>
              <span style={{ marginRight: '.5rem' }}>{url}</span>
              <CopyButton value={url} copyButton={true} />
            </li>
          ))}
        </ol>
      </div>
    </ModalOverlay>
  );
}

import React, { useEffect, useState } from 'react';
import { EMenuType } from '../../Drawer/RouteOptions';
import './SubNavigation.scss';
import { history } from '../../../helpers/history';
import { appConstants, getNavPathnameForInsightPage } from '../../../constants';
import _ from 'lodash';

interface ISubNavigationTabs {
  isPublic: boolean;
  subBarItems: ISubBarItem[];
}

export interface ISubBarItem {
  href: string;
  id: string;
  type: EMenuType;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
}
type Props = ISubNavigationTabs;

export default function SubNavigationBar(props: Props) {
  const { isPublic, subBarItems } = props;
  useEffect(() => {
    subTabIsActive();
    if (
      isPublic &&
      (history.location.pathname.includes('timeline/') ||
        history.location.pathname.includes('dom/') ||
        history.location.pathname.includes('whois/'))
    ) {
      history.push(`/sign-in`);
    }
  }, [isPublic]);

  const [tabItems, setTabItems] = useState<ISubBarItem[]>([]);

  const subTabIsActive = () => {
    //Hotfix for bulkscan type
    const activeUrl = window.location.pathname.split('/')[3];
    const newItems = subBarItems.map((route: ISubBarItem) => {
      const activeRoute = route.href?.split('/')?.[3];

      if (activeRoute === activeUrl) {
        route.isActive = true;
      } else {
        route.isActive = false;
      }

      return route;
    });

    setTabItems(newItems);
  };

  const onTabClicked = (item: ISubBarItem) => {
    history.push(item?.href);
    subTabIsActive();
  };
  return (
    <div className='sub-menu-tab-wrapper'>
      {tabItems.map((item: ISubBarItem, index: number) => {
        return (
          <div
            className={`tab-wrapper ${item?.isActive ? 'active' : ''} ${
              item?.disabled ? 'tab-disabled' : ''
            }`}
            key={index}
            onClick={() => (item.disabled ? _.noop() : onTabClicked(item))}
          >
            <div
              className={`tab-label ${item?.isActive ? 'active' : ''} ${
                item?.disabled ? 'tab-disabled' : ''
              }`}
            >
              {' '}
              {item.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}

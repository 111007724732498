import React from 'react';
import { AppState } from '../helpers';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { dashboardActions, userActions, appActions } from '../actions';
import { ELoggedIn } from '../reducers';
import { connect } from 'react-redux';
import {
  setLocalStorageValue,
  DashBoardDto,
  getLocalStorageValue,
  IAppDispatchProps,
} from '../constants';
import AppSettingContext from '../context/AppSettingContext';
import _ from 'lodash';
import PendoService from '../services/pendo.service';
import { WEB_DASHBOARD_PATHNAME } from './MonitorAndTakedown/constants';

const ADMIN_ONLY_ROUTES = ['/team-members'];

const getGlobalError = () => {
  const urlObj = new URL(window.location.href);
  return urlObj.searchParams.get('error') || '';
};

interface IPropsFromCompProps {
  children: any;
  skipAuthentication?: boolean;
}

interface IPropsFromGlobalState {
  user: DashBoardDto;
  loggedIn: ELoggedIn;
  loginError: string;
}

interface IPropsFromActions {
  checkAuthentication: () => void;
  getUserInfo: () => void;
}

type Props = IPropsFromCompProps & IPropsFromGlobalState & IPropsFromActions & IAppDispatchProps;

// Wrapper for children elements those require authentication check.
class AuthenticationWrapper extends React.Component<Props> {
  static contextType = AppSettingContext;
  static defaultProps = {
    children: <div />,
    skipAuthentication: false,
  };

  private readonly pendoService = new PendoService();

  constructor(props: Props) {
    super(props);

    if (props.loggedIn === 0 || getGlobalError() || getLocalStorageValue('isLoggedIn') === false) {
      if (props?.skipAuthentication) {
        return;
      }
      props.checkAuthentication();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.loggedIn !== prevProps.loggedIn) {
      if (_.get(this.props.user, 'first_name') === '') {
        this.props.getUserInfo();
      }
    }

    try {
      if (
        window?.pendo &&
        this.props.loggedIn === ELoggedIn.true &&
        this.props.user &&
        this.props.user.email &&
        this.props.user.email !== '' &&
        (this.props.loggedIn !== prevProps.loggedIn ||
          this.props.user.email !== prevProps.user.email)
      ) {
        this.pendoService.initialize(this.props.user);
      }
    } catch (e) {
      //
    }
  }

  getPreviousUrl() {
    let url = this.context.landingPage;
    const storagePreviousUrl = getLocalStorageValue('bolsterPreviousUrl', '');
    if (
      !_.isEmpty(storagePreviousUrl) &&
      storagePreviousUrl !== '/' &&
      storagePreviousUrl !== '/logout'
    ) {
      url = storagePreviousUrl;
    }
    return url;
  }

  render() {
    const { user, loggedIn, loginError, children, skipAuthentication } = this.props;
    const { REACT_APP_BOLSTER_APP_TYPE } = process.env;
    let pathname = window.location.pathname;

    if (skipAuthentication) {
      if (pathname.includes(`${WEB_DASHBOARD_PATHNAME}/insights`))
        setLocalStorageValue('bolsterPublicRoute', pathname);
      return children;
    }

    const urls = ['/sign-in', '/sign-up'];
    this.props.runByClientApp({
      onCheckPhish: () => {
        urls.push('/sign-up');
      },
    });
    const isSignInSignUpPage = urls.includes(pathname) || pathname === '/' || pathname === '';

    if (ADMIN_ONLY_ROUTES.indexOf(pathname) !== -1 && user.role_name !== 'brand_admin') {
      return <div />;
    }

    const userInfoIsFetched = !_.isEmpty(user?.first_name);

    if (!isSignInSignUpPage) {
      // Store the current url, so that after log in, user could be redirect back to this page.
      // (except for user account related page)
      if (pathname.indexOf('/account/') !== -1) {
        pathname = this.context.landingPage;
      }
      if (pathname !== this.context.defaultLandingPage) {
        setLocalStorageValue('bolsterPreviousUrl', pathname);
      }
      switch (loggedIn) {
        case 2:
          if (userInfoIsFetched) {
            return children;
          }
          return <div />;
        case 1:
          return (
            <Redirect
              to={
                REACT_APP_BOLSTER_APP_TYPE === 'CHECKPHISH' ? '/' : '/sign-in?error=' + loginError
              }
            />
          );
        default:
          return <div />;
      }
    } else {
      switch (loggedIn) {
        case 2:
          // display /sign-in page if global error.
          if (getGlobalError()) {
            return children;
          }
          // otherwise, redirect to the previous route
          if (userInfoIsFetched) {
            return <Redirect to={this.getPreviousUrl()} />;
          }
          return <div />;
        case 1:
          return children;
        default:
          return <div />;
      }
    }
  }
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  const { loggedIn, loginError } = state.login;
  return { loggedIn, loginError, user };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    checkAuthentication: bindActionCreators(userActions.checkAuthentication, dispatch),
    getUserInfo: bindActionCreators(dashboardActions.getUserInfo, dispatch),
    runByClientApp: appActions.runByClientApp,
  };
};

const connectedAuthenticationWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticationWrapper);
export { connectedAuthenticationWrapper as AuthenticationWrapper };

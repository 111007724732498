import React, { useState } from 'react';
import _ from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { LabelAndValue } from '../../../../Common/LabelAndValue';
import { DISPLAYED_NULL, getLocalTimeFromUtcTime } from '../../../../../constants';
import { IFindingInsightSectionProps } from '../../../Types/ugc.types';
import { FINDING_METADATA_TEMPLATE } from '../../../constants';
import RedirectToMaliciousLink from '../../../../MonitorAndTakedown/RedirectToMaliciousLink';

const DetectionDetailsSection = ({ data }: IFindingInsightSectionProps) => {
  const [isValidPathName, setIsValidPathName] = useState<any>();

  const checkPathNameExist = (value: any): void => {
    setIsValidPathName(value ? true : false);
  };
  return (
    <Card bg='light' className='card-between'>
      <Card.Header className='self-card-header'>Detection Details</Card.Header>
      <Card.Body className='self-card-body'>
        <Row>
          <Col sm={6}>
            <LabelAndValue label={'Platform'} value={data.platformLabel} direction={'column'} />
          </Col>
          <Col sm={6}>
            <LabelAndValue label={'Origin'} value={data.originLabel} direction={'column'} />
          </Col>
          {_.map(
            _.get(FINDING_METADATA_TEMPLATE, [data.platformLabel, data.originLabel], []),
            templateItem => {
              const { label, labelWithoutExternalLink, propName, type, navToInsight, prefix } =
                templateItem;
              let { copyButton, redirectButton } = templateItem;
              let value = data.metadata?.[propName] || DISPLAYED_NULL;
              if (_.isArray(value) && value.length === 0) {
                value = DISPLAYED_NULL;
                copyButton = false;
                redirectButton = false;
              }
              let renderDom = null;
              if (value && value !== DISPLAYED_NULL) {
                const pathName = `${window.location.pathname.split('/url')[0]}/url-external`;
                switch (type) {
                  case 'time':
                    value = getLocalTimeFromUtcTime(value, 'MMMM Do YYYY, h:mm:ss a');
                    break;
                  case 'length':
                    value = value.length;
                    break;
                  case 'link':
                    renderDom = (
                      <a href={value} target='_blank' className='card-value' rel='noreferrer'>
                        {value}
                      </a>
                    );
                    break;
                  case 'linksInContent':
                    let domContent = null;
                    if (navToInsight) {
                      domContent = _.map(value, link => {
                        return (
                          <RedirectToMaliciousLink
                            url={link.url}
                            urlSha256={link.urlSha256}
                            defaultPathName={pathName}
                            key={link.url}
                            displayAsInsightPageUrl
                            copyButton={copyButton}
                            checkPathNameExist={checkPathNameExist}
                          />
                        );
                      });
                      renderDom = <div className='d-flex flex-column'>{domContent}</div>;
                    } else {
                      value = _.map(value, link => link.url);
                    }
                    break;
                }
              } else {
                copyButton = false;
                redirectButton = false;
              }
              if (prefix) {
                value = prefix + value;
              }
              return (
                <Col sm={6} key={label + propName}>
                  <LabelAndValue
                    label={
                      isValidPathName !== undefined &&
                      isValidPathName === false &&
                      labelWithoutExternalLink
                        ? labelWithoutExternalLink
                        : type === 'linksInContent' && value === DISPLAYED_NULL
                        ? labelWithoutExternalLink
                        : label
                    }
                    value={value}
                    copyButton={copyButton}
                    redirectButton={redirectButton}
                    renderDom={renderDom}
                    direction={'column'}
                    longTextLineNumberLimit={1}
                  />
                </Col>
              );
            },
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetectionDetailsSection;

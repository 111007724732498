import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import '../Style/playbook.scss';
import PlaybookService from '../../../services/playbook.service';
import { getPlaybookId } from '../../../constants';
import { IPlaybook, playbookDefaultValue } from '../../../context/playbook.context';

const playbookService = new PlaybookService();

interface IPlaybookInsightProps {}

const PlaybookInsight = ({}: IPlaybookInsightProps) => {
  const playbookId = getPlaybookId();
  const [playbook, setPlaybook] = useState<IPlaybook>(playbookDefaultValue);
  const type = 'web';
  useEffect(() => {
    fetchPlaybooks();
  }, []);

  const fetchPlaybooks = useCallback(() => {
    playbookService.getPlaybooksList(type).then(res => {
      let data = playbookDefaultValue;
      if (res.result && res.result.length) {
        data = _.find(res.result, ['id', playbookId]);
      }
      setPlaybook(data);
    });
  }, []);

  return <div className={'playbook-insight-page page-content'}>{playbook.name}</div>;
};

export { PlaybookInsight };

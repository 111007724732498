import React from 'react';
import '../Style/playbook.scss';
import { ITemplate } from './PlaybookTypes';

interface ITemplateTileProps {
  data: ITemplate;
  onClick: (data: ITemplate) => void;
}

const NewPlaybookTemplatesTile = ({ data, onClick }: ITemplateTileProps) => {
  return (
    <div
      className={'d-flex w-100 choose-playbook-template-tile cursor-pointer'}
      onClick={() => {
        onClick(data);
      }}
    >
      <div className={'tile-status'} />
      <div className={'tile-info flex-fill d-flex flex-column'}>
        <div className={'name'}>{data.name}</div>
        <div className={'description'}>{data.description}</div>
      </div>
    </div>
  );
};
export { NewPlaybookTemplatesTile };

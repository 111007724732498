import { DashBoardWidgetConfigDto } from '../../../../constants';

const CHECKPHISH_SUMMARY_FIELDS_MAPPER = {
  bulkScanNumber: 'Bulk Scans',
  apiCalls: 'API Scans',
  emailScanNumber: 'Email Scans',
};

const CHECKPHISH_SUMMARY_FIELDS = [
  {
    fieldName: 'totalLiveScansCount',
    label: 'Total Live Scans',
  },
  {
    fieldName: 'apiCalls',
    label: CHECKPHISH_SUMMARY_FIELDS_MAPPER['apiCalls'],
  },
  {
    fieldName: 'bulkScanNumber',
    label: CHECKPHISH_SUMMARY_FIELDS_MAPPER['bulkScanNumber'],
  },
  {
    fieldName: 'emailScanNumber',
    label: CHECKPHISH_SUMMARY_FIELDS_MAPPER['emailScanNumber'],
  },
  {
    fieldName: 'targetedBrand',
    label: 'Targeted Brands',
  },
  {
    fieldName: 'originatingCountriesNumber',
    label: 'Originating Countries',
  },
];

const CHECKPHISH_WIDGETS: DashBoardWidgetConfigDto[] = [
  {
    name: 'detection_1243',
    widgetType: 'chart',
    idx: 1243,
  },
  {
    name: 'hosting_1243',
    widgetType: 'chart',
    idx: 1243,
  },
  {
    name: 'brands_1243',
    widgetType: 'chart',
    idx: 1243,
  },
  {
    name: 'country_1243',
    widgetType: 'chart',
    idx: 1243,
  },
  {
    name: 'source_url_1243',
    widgetType: 'chart',
    idx: 1243,
  },
  {
    name: 'category_url_1243',
    widgetType: 'chart',
    idx: 1243,
  },
];

export { CHECKPHISH_SUMMARY_FIELDS, CHECKPHISH_WIDGETS, CHECKPHISH_SUMMARY_FIELDS_MAPPER };

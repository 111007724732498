import _ from 'lodash';
import React from 'react';
import { OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDisplayTimeFromTimeStamp, getUsersListLabelValue } from '../../../constants';
import { IUserListItemDto } from '../../TeamMembers/TeamMembers';
import { DispositionStatus } from '../../Common/DispositionStatus';
import { SCANTYPE } from './scanType.enum';
import { ITableApiColumn } from '../../Common/Table/table.api';
import Country from '../../Common/Country';

export const URL_COLUMN_CP = (disabled: boolean = false, filterDisabled: boolean = false) => ({
  header: 'Source URL',
  headerTooltip: 'Link to URL Details View',
  accessor: 'url',
  filterDisabled,
  render: (data: any) => {
    const { url } = data;
    if (url) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + url} className={'table-source-url-tooltip'}>
              {url}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column d-flex align-items-center'}>
            {data.isLoading && (
              <Spinner className='spinner' animation='border' variant='primary' size='sm' />
            )}
            {disabled ? (
              <div>{url}</div>
            ) : (
              <Link
                to={{
                  pathname: '/bulk-scan/insights/' + data.timestamp + '/' + data.urlSha256,
                  state: { prevPath: window.location.pathname },
                }}
              >
                {url}
              </Link>
            )}
          </div>
        </OverlayTrigger>
      );
    }
    return null;
  },
});

export const IP_COLUMN_CP = (disabled: boolean = false) => ({
  header: 'IP Address',
  headerTooltip: 'Link to IP Details View',
  accessor: 'ipAddress',
  type: 'ipv4',
  columnClassName: 'hide-below-sm',
  render: (data: any) => {
    const { ipAddress } = data;
    if (_.isEmpty(ipAddress) || ipAddress === '0.0.0.0') {
      return '--';
    }
    if (disabled) {
      return <div>{ipAddress}</div>;
    }
    return (
      <Link
        to={{
          pathname: '/bulk-scan/ip/' + ipAddress,
          state: { prevPath: window.location.pathname },
        }}
      >
        {ipAddress}
      </Link>
    );
  },
});

export const HOSTING_COLUMN_CP = {
  id: 'Hosting Provider',
  header: 'Hosting Provider',
  headerTooltip: 'Entity that hosts the site and to which CheckPhish directs takedown requests',
  accessor: 'networkOwner',
  isLongText: true,
  hiddenOnMobile: true,
};
export const DISPOSITION_COLUMN_CP = (sortDisabled: boolean = false) => ({
  id: 'Disposition',
  header: 'Disposition',
  headerTooltip: 'Disposition when Site was First Scanned',
  accessor: 'disposition',
  type: 'options',
  sortDisabled,
  filterOptions: [
    { label: 'Clean', value: 'clean' },
    { label: 'Phish', value: 'phish' },
    { label: 'Suspicious', value: 'suspicious' },
    { label: 'Scam', value: 'scam' },
  ],
  render: (data: any) => {
    if (_.isEmpty(data)) {
      return null;
    }
    return <DispositionStatus status={data.disposition} />;
  },
});

export const LAST_SCANNED_COLUMN_CP = {
  id: 'Detection Date',
  header: 'Detection Date',
  headerTooltip: 'Time of Scan',
  columnClassName: 'hide-below-lg',
  accessor: 'createdTs',
  isLongText: true,
  filterDisabled: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.createdTs);
  },
};

export const BRAND_COLUMN_CP = {
  id: 'Brand',
  header: 'Brand',
  headerTooltip: 'Brand',
  accessor: 'brandDisplayName',
  filterDisabled: true,
};

export const SOURCE_COLUMN_CP = {
  header: 'Source',
  headerTooltip: 'Source',
  accessor: 'source',
  filterDisabled: true,
};

export const SCANNED_BY_COLUMN_CP = (
  sortDisabled: boolean = false,
  userLists?: IUserListItemDto[],
) => ({
  header: 'Scan Source',
  headerTooltip: 'Scan Source',
  accessor: 'scanSource',
  id: 'scan_source',
  sortDisabled,
  type: 'options',
  filterOptions: (userLists && [...getUsersListLabelValue(userLists)]) || [],
  render: ({ scannedBy }: any) => {
    return `${scannedBy?.firstName} ${scannedBy?.lastName}`;
  },
});

export const SENDER_COLUMN_CP = (sortDisabled: boolean = false) => ({
  header: 'Sender',
  headerTooltip: 'Sender',
  accessor: 'sender',
  sortDisabled,
});

export const SUBJECT_COLUMN_CP = (sortDisabled: boolean = false) => ({
  header: 'Subject',
  headerTooltip: 'Subject',
  accessor: 'subject',
  sortDisabled,
});

export const SCAN_HISTORY_COLUMNS_NON_BRAND_CP = (
  scanType: SCANTYPE,
  disabled: boolean = false,
  sortDisabled: boolean = false,
): any[] => [
  URL_COLUMN_CP(disabled, false),
  IP_COLUMN_CP(),
  HOSTING_COLUMN_CP,
  DISPOSITION_COLUMN_CP(sortDisabled),
  LAST_SCANNED_COLUMN_CP,
  BRAND_COLUMN_CP,
  SOURCE_COLUMN_CP,
  ...(scanType === SCANTYPE.TEAM ? [SCANNED_BY_COLUMN_CP(sortDisabled)] : []),
];

export const SCAN_HISTORY_COLUMNS_NON_BRAND_EMAIL_SCANS = (
  scanType: SCANTYPE,
  disabled: boolean = false,
  sortDisabled: boolean = false,
): any[] => [
  URL_COLUMN_CP(disabled, false),
  IP_COLUMN_CP(),
  HOSTING_COLUMN_CP,
  DISPOSITION_COLUMN_CP(sortDisabled),
  LAST_SCANNED_COLUMN_CP,
  BRAND_COLUMN_CP,
  SOURCE_COLUMN_CP,
  SENDER_COLUMN_CP(sortDisabled),
  SUBJECT_COLUMN_CP(sortDisabled),
  ...(scanType === SCANTYPE.TEAM ? [SCANNED_BY_COLUMN_CP(sortDisabled)] : []),
];

export const SCAN_LOCATION: ITableApiColumn = {
  id: 'scan_location',
  accessor: 'scanLocation',
  header: 'Scan Location',
  headerTooltip: 'Scan Location',
  fieldForExport: 'scan_location',
  filterDisabled: true,
  sortDisabled: true,
  render: (data: any) => {
    return <Country countryCode={data.scanLocation} defaultCountryCode={'US'} />;
  },
  agFilterType: 'agTextColumnFilter',
};

export const WAIT_COLUMN: ITableApiColumn = {
  id: 'wait',
  accessor: 'wait',
  header: 'Timeout',
  headerTooltip: 'Timeout',
  render: ({ wait }: any) => {
    return <div>{wait === 'Auto' || wait === undefined ? 'Auto' : wait + 'ms'}</div>;
  },
  filterDisabled: true,
  sortDisabled: true,
  agFilterType: 'agTextColumnFilter',
};

export const LAST_SCANNED_COLUMN: ITableApiColumn = {
  id: 'Last scanned',
  header: 'Last scanned',
  headerTooltip: 'Time of Scan',
  columnClassName: 'hide-below-lg',
  accessor: 'createdTs',
  isLongText: true,
  filterDisabled: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.createdTs);
  },
  agFilterType: 'agTextColumnFilter',
};

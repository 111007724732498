import moment from 'moment';
import packageJson from '../../package.json';
import { setLocalStorageValue } from '../constants';
import fetch from './api.service';
import { handleResponse } from './serviceWorker';
import { userFeedbackBody } from '../components/UserFeedback/UserFeedbackPopUp';

export default class UserService {
  register(user: any) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user }),
    };

    return fetch('/api/v1/user/create', requestOptions).then(handleResponse);
  }

  signUp(user: any) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user }),
    };

    return fetch(`/platform-api/v1/user/sign-up`, requestOptions).then(handleResponse);
  }

  validateWorkEmail(email: any) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    };

    return fetch(`/platform-api/v1/user/work-email-validate`, requestOptions).then(handleResponse);
  }

  login(user: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    };

    return fetch(`/platform-api/v1/user/login`, requestOptions)
      .then(handleResponse)
      .then(user => {
        setLocalStorageValue('sessionExpiration', user.expired);
        setLocalStorageValue('sessionInActiveLimit', user.inActiveLimit);
        setLocalStorageValue('userLatestActivity', moment().valueOf());
        return user;
      });
  }

  logout() {
    return fetch('/platform-api/v1/user/logout/', {
      credentials: 'include',
    }).then(handleResponse);
  }

  verify(token: string) {
    return fetch('/api/v1/user/verify/' + token).then(handleResponse);
  }

  verifyWorkEmail(token: string) {
    return fetch('/platform-api/v1/user/verify-work-email/' + token).then(handleResponse);
  }

  addEditWorkEmail(workEmail: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ workEmail }),
    };
    return fetch('/platform-api/v1/user/work-email', requestOptions).then(handleResponse);
  }

  sendPrimaryEmailVerification(body: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
    return fetch('/platform-api/v1/user/resend-email-verification', requestOptions).then(
      handleResponse,
    );
  }

  sendWorkEmailVerification(body: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
    return fetch('/platform-api/v1/user/resend-work-email-verification', requestOptions).then(
      handleResponse,
    );
  }

  forgotPassword(user: any) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    return fetch('/api/v1/user/forgot-password/' + user.email, requestOptions).then(handleResponse);
  }

  resetPassword(password: any, token: any) {
    const user = {
      newPassword: password,
      token: token,
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user }),
    };

    return fetch('/api/v1/user/reset-password/', requestOptions).then(handleResponse);
  }

  changePassword(user: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    console.log(user);
    return fetch(
      '/api/v1/user/change-password/' + user.currPassword + '/' + user.newPassword,
      requestOptions,
    ).then(handleResponse);
  }

  checkAuthentication() {
    return fetch('/api/v1/user/get-session-info/', {
      credentials: 'include',
    }).then(handleResponse);
  }

  saveFrontendVersion() {
    const currentUiVersion = packageJson.version;
    return fetch('/api/v1/user-app/save-frontend-version/' + currentUiVersion, {
      credentials: 'include',
    }).then(handleResponse);
  }

  confirmRegistration = (user: object) => {
    console.log(user);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: user }),
    };
    return fetch('/api/v1/user/confirm-registration/', requestOptions).then(handleResponse);
  };

  acceptTermsAndCondition = (user: any) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user }),
    };
    return fetch('/platform-api/v1/user/accept-terms-and-condition', requestOptions).then(
      handleResponse,
    );
  };

  acceptTermsAndConditionForSso = () => {
    return fetch('/platform-api/v1/user/accept-terms-and-condition-sso', {
      credentials: 'include',
    }).then(handleResponse);
  };

  validateUser(user: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      redirect: 'follow',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    };

    return fetch('/platform-api/v1/sso/validate', requestOptions).then(handleResponse);
  }

  handleAuthenticated(response: any) {
    return response
      .text()
      .then((text: any) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          console.log('comes from service - false');
          const error = (data && data.message) || response.statusText;
          console.log(error);
          return false;
        } else {
          console.log('comes from service - true');
          return true;
        }
      })
      .catch(function (err: any) {
        throw err;
      });
  }

  updateUserSettings(userSetting: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userSetting),
    };

    return fetch('/api/v1/user/update-user-settings', requestOptions).then(handleResponse);
  }

  refreshUserSession() {
    return fetch('/platform-api/v1/user/refresh-user-session', {
      credentials: 'include',
    }).then(handleResponse);
  }

  sendUserFeedback(feedbackObject: userFeedbackBody) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedbackObject),
    };

    return fetch('/platform-api/v1/user-feedback', requestOptions).then(handleResponse);
  }
}

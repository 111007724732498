import React from 'react';
import '../Insights/insights.scss';

interface IComponentProps {
  ip?: string;
  urlSHA256?: string;
  hostPhishCount: string | number;
  ipPhishCount: string | number;
  phishingKitsCountOnHost: string | number;
}

interface IComponentState {}

class ThreatIntelligence extends React.Component<IComponentProps, IComponentState> {
  renderItem = (label: string, value: string | number) => {
    return (
      <div className='threat-component-item col-md-12 col-lg-4 flex-wrap'>
        <div className='item-wrapper'>
          <div className='threat-component-label long-text-ellipsis-1'>{label}</div>
          <div className='threat-component-value long-text-ellipsis-1'>{value}</div>
        </div>
      </div>
    );
  };

  render() {
    const { hostPhishCount, ipPhishCount, phishingKitsCountOnHost } = this.props;
    return (
      <div className='threat-component d-flex'>
        {this.renderItem('Past Phish on Host', hostPhishCount)}
        {this.renderItem('Past Phish on IP', ipPhishCount)}
        {this.renderItem('Phishing Kits on Host', phishingKitsCountOnHost)}
      </div>
    );
  }
}

export { ThreatIntelligence };

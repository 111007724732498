import { setLocalStorageValue, userConstants } from '../constants';
import { Record } from 'immutable';

export enum ELoggedIn {
  unknown,
  false,
  true,
}

interface IState {
  loggingIn: boolean;
  loggedIn: ELoggedIn;
  loginError: string;
  email: string;
  password: string;
}

const initialStateValue: IState = {
  loggingIn: false,
  loggedIn: 0,
  loginError: '',
  email: '',
  password: '',
};

class InitialState extends Record(initialStateValue) implements IState {}

const initialState = new InitialState();

export const login = (state = initialState, action: any) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return state
        .set('loggingIn', true)
        .set('loginError', '')
        .set('email', action.payload.email)
        .set('password', action.payload.password);
    case userConstants.ACCEPT_TERMS_AND_CONDITION_SUCCESS:
    case userConstants.LOGIN_SUCCESS:
      setLocalStorageValue('isLoggedIn', true);
      return state
        .set('loggedIn', 2)
        .set('loggingIn', false)
        .set('loginError', '')
        .set('email', '')
        .set('password', '');
    case userConstants.ACCEPT_TERMS_AND_CONDITION_FAILURE:
    case userConstants.LOGIN_FAILURE:
      let loginError = '';
      if (state.loggedIn === 2) {
        loginError = action.payload.error;
      }
      return state.set('loggedIn', 1).set('loggingIn', false).set('loginError', loginError);
    case userConstants.LOGOUT:
      setLocalStorageValue('isLoggedIn', false);
      return state.set('loggedIn', 0).set('loggingIn', false).set('loginError', '');
    // the default case remained to be verified
    default:
      return state;
  }
};

import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import _ from 'lodash';
import './UploadFiles.scss';
import { AttachedFileObject } from './DisputeStatus';
import AttachedFileViewer from './AttachedFileViewer';
import { convertToDataUrl, getSingleOrPluralForm } from '../../constants';
import { Nullable } from '../../types/common';
import moment from 'moment';
import { invoke } from '../../helpers/async';
import UploadFilesButton from './UploadFilesButton';
import {
  EFileSizeInMB,
  EFileTypes,
  FILES_LIMIT,
  FileTypeDescription,
  UploadFileErrors,
} from '../../types/upload-files';

type AttachedItem = AttachedFileObject & { item: UploadFileItem };

export type UploadFileItem<TValue = unknown> =
  | {
      type: 'file';
      file: File;
      value?: TValue;
    }
  | {
      type: 'path';
      value?: TValue;
      name: string;
      path: string;
      createdTs?: Nullable<string>;
    };

interface UploadFilesMultiProps {
  items: UploadFileItem[];
  onChange: (items: UploadFileItem[]) => void;
  options: {
    dragAndDrop?: boolean;
    label?: string;
    filesLimit?: number;
    initialFilePaths?: string[];
    fileType?: string;
    customHelpText?: string;
    hideError?: boolean;
    onError?: (errorType: UploadFileErrors, message: string) => void;
  };
}

function UploadFilesMulti({ items, onChange, options }: UploadFilesMultiProps) {
  const {
    dragAndDrop,
    label,
    filesLimit = FILES_LIMIT,
    fileType = 'FILES',
    customHelpText,
    hideError,
    onError,
  } = options;
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [fileIsOnInDropArea, setFileIsOnInDropArea] = useState(false);
  const [error, setError] = useState<string | null>();
  const [attachedFiles, setAttachFiles] = useState<AttachedItem[]>([]);

  const maxFileSizeInBytes = Number(EFileSizeInMB[fileType]) * 1000000;

  function openFileSelect() {
    uploadInputRef.current?.click();
  }

  function handleDragEnter(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    setFileIsOnInDropArea(true);
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    setFileIsOnInDropArea(false);
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>): void {
    // preventing the browser from downloading the files.
    event.preventDefault();
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    addFiles((event.target as unknown as { files: Nullable<FileList> }).files);
  }

  function handleError(errorType: UploadFileErrors): void {
    let message: string | null = null;
    switch (errorType) {
      case UploadFileErrors.MAX_COUNT:
        message = `No more than ${filesLimit} files.`;
        break;
      case UploadFileErrors.MAX_FILE_SIZE:
        message = `Files size should not exceeds ${EFileSizeInMB[fileType]} MB`;
        break;
    }

    setError(message);
    onError?.(errorType, message);
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    addFiles(event.target.files);
  }

  function addFiles(files: Nullable<FileList>): void {
    setError(null);
    const newItems: UploadFileItem[] = [...(files ?? [])].map(file => {
      return {
        type: 'file',
        file,
      };
    });

    const updated = [...items, ...newItems];
    if (updated.length > filesLimit) {
      handleError(UploadFileErrors.MAX_COUNT);
    }

    newItems
      .filter(i => i.type === 'file')
      .forEach(i => {
        if (i.type === 'file') {
          if (i.file.size > maxFileSizeInBytes) {
            handleError(UploadFileErrors.MAX_FILE_SIZE);
            return;
          }
        }
      });

    onChange(updated);
  }

  function fileRemoved(item: UploadFileItem): void {
    onChange(items.filter(i => i !== item));
  }

  async function mapToAttachedFileObject(item: UploadFileItem): Promise<AttachedItem> {
    if (item.type === 'path') {
      return {
        path: item.path,
        name: item.name,
        createdTS: item.createdTs ?? moment().toISOString(),
        item: item,
      };
    } else {
      return {
        path: await convertToDataUrl(item.file),
        name: item.file.name,
        createdTS: moment(item.file.lastModified).toISOString(),
        item: item,
      };
    }
  }

  useEffect(() => {
    invoke(async () => {
      const newAttached: AttachedItem[] = [];
      for (const item of items) {
        newAttached.push(await mapToAttachedFileObject(item));
      }

      setAttachFiles(newAttached);
    });
  }, [items]);

  return (
    <div className={'upload-files-container'}>
      <input
        type='file'
        name='file'
        id='attach-file'
        accept={EFileTypes[fileType]}
        ref={uploadInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        multiple
      />
      <div>
        {label && <div className={'label'}>{label}</div>}
        <div
          className={
            'd-flex align-items-center ' +
            (dragAndDrop ? 'drag-and-drop-area' : '') +
            (fileIsOnInDropArea ? ' is-dragging' : '')
          }
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <UploadFilesButton onClick={openFileSelect} />
          {dragAndDrop && <span className='drop-files-text'>or Drop Files</span>}
        </div>
        <div className={'mt-2 text-dark-grey-2 help-text'}>
          {customHelpText != null && <div>{customHelpText}</div>}

          {customHelpText == null && (
            <div>
              Maximum {filesLimit} {getSingleOrPluralForm(filesLimit, 'file')}.{' '}
              {FileTypeDescription[fileType]}
            </div>
          )}
        </div>

        {!hideError && error && <div className={'mt-2 text-danger'}>{error}</div>}
        {attachedFiles?.length > 0 && (
          <AttachedFileViewer
            attachedFiles={attachedFiles}
            horizontal
            theme={'sm'}
            handleFileRemove={(e, ix, attachedItem) =>
              fileRemoved((attachedItem as unknown as AttachedItem).item)
            }
          />
        )}
      </div>
    </div>
  );
}

export default UploadFilesMulti;

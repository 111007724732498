import React, { useContext } from 'react';
import _ from 'lodash';
import { getColors } from '../../../../constants';
import {
  IChartPlaceholderProps,
  TColorMap,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import BarChart from '../../../Common/DashboardWidgets/BarChart';
import NoHosting from '../../../../assets/icons/NoHosting.svg';
import ThemeContext from '../../../../context/ThemeContext';

const BULK = 'bulk scanned url';
const API = 'api scanned url';
const EMAIL = 'email scanned url';

const convert2BarChartDataFormat = (rawData: any) => {
  const results = Object.keys(rawData).map(item => {
    return {
      label: item,
      total: rawData[item],
      categories: [
        {
          label: `${item} scanned url`,
          count: rawData[item],
        },
      ],
    };
  });
  return results;
};

const placeholder: IChartPlaceholderProps = {
  icon: NoHosting,
  title: `No URL Found`,
  description: `We haven't scanned any URL for this period. Please select another time frame, or scan more.`,
};

interface IComponentProps {
  isLoading: boolean;
  widgetData: any;
}

const DashboardUrlBySource: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { selectedTheme } = useContext(ThemeContext);
  const isLoading = props.isLoading;
  const widgetData = props.widgetData;

  const colorMap: TColorMap = {
    [BULK]: getColors(selectedTheme).barChart1,
    [API]: getColors(selectedTheme).barChart1,
    [EMAIL]: getColors(selectedTheme).barChart1,
  };

  return (
    <BarChart
      title='URLs By Source'
      isLoading={isLoading}
      description=' URLs By Source'
      data={convert2BarChartDataFormat(widgetData)}
      colorMap={colorMap}
      placeholder={placeholder}
      yAxisTitle='Pages'
      hideLegend={true}
    />
  );
};

export default DashboardUrlBySource;

import React from 'react';
import _ from 'lodash';
import FilledRightArrow from '../../../../../assets/icons/RightArrowFilled.svg';
import { numberWithCommas, convertToThousand } from '../../../../../constants';
import TextCenterWithChevronRight from '../../MonitoredDomains/TextCenterWithChevronRight';
import { MONITOR_PRE_MALICIOUS } from './MonitoredDomainFunnelV2';

export default function Funnel({
  width,
  height,
  background,
  isTransform,
  label,
  value,
  totalVariants,
  handleRedirect,
  parentLabel,
  className,
}: any): JSX.Element {
  const customFunnelStyle = {
    width,
    height,
    background,
    transform: isTransform
      ? `perspective( calc( ${width} - 13vw ) ) rotateY(1deg) translate(0.325vw)`
      : 'none',
  };

  const handleRouter = () => {
    handleRedirect(parentLabel, label);
  };

  return (
    <div style={customFunnelStyle} className={'hover-wrapper ' + className} id='funnel-container'>
      <div className='funnel-content' onClick={handleRouter}>
        <div className='funnel-value'>{numberWithCommas(value || 0)}</div>
        {totalVariants !== undefined && totalVariants > 0 && (
          <div className='funnel-info'>
            {' '}
            {`out of ${convertToThousand(totalVariants)} variants `}{' '}
          </div>
        )}
        <div className='funnel-label-v2'>
          {parentLabel === MONITOR_PRE_MALICIOUS ? (
            <TextCenterWithChevronRight iconSrc={FilledRightArrow} hidden>
              <div className='label-text'>{label}</div>
            </TextCenterWithChevronRight>
          ) : (
            <div
              className='label-text'
              style={{
                paddingLeft: '24px',
              }}
            >
              {label}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

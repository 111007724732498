import React from 'react';
import styled from 'styled-components';

import './SsoSignInButton.scss';

interface ISsoSignInButtonProps {
  href: string;
  label: string;
  icon?: string;
  color?: string;
  eventName: string;
}

const SsoButton = styled.a`
  color: ${props => (props.color ? `${props.color} !important` : '')};
`;

const SsoSignInButton = ({ href, label, icon, color, eventName }: ISsoSignInButtonProps) => {
  return (
    <SsoButton
      onClick={() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: eventName,
          category: 'Page',
          action: 'clicked',
          label: eventName,
        });
      }}
      href={href}
      className={'sso-sign-in-button-component flex-center'}
      color={color}
    >
      {icon && <img src={icon} alt={'logo'} />}
      <span>{label}</span>
    </SsoButton>
  );
};

export { SsoSignInButton };

import React, { useEffect, useState } from 'react';
import NoDetections from '../../../../assets/icons/NoDetections.svg';
import DashboardService from '../../../../services/dashboard.service';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { IFilter } from '../../../Common/Table/constant';
import { renderNoDataPlaceholder } from '../../../Dashboard/dashboard.detection';
import { LOGO_DETECTION_COLUMN } from '../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import '../../Style/monitoredDomains.scss';
import MonitoredDomainFunnel from './MonitoredDomainFunnel';
import MonitoredDomainsHeader from './MonitoredDomainsHeader';

const FILTER_MAP = {
  [LOGO_DETECTION_COLUMN.accessor]: LOGO_DETECTION_COLUMN.id,
};

const MonitoredDomainContainer = ({ startDate, endDate, domainCategories }: any) => {
  const dashboardService = new DashboardService();

  const [monitoredDomains, setMonitoredDomains] = useState<any[]>([]);
  const [filters, setFilters] = useState<Record<string, IFilter>>({});
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  startDate = startDate.format('YYYY-MM-DD');
  endDate = endDate.format('YYYY-MM-DD');

  useEffect(() => {
    setIsLoading(true);

    dashboardService
      .getMonitoredDomains(startDate, endDate, query)
      .then(res => {
        const { counts } = res;
        setMonitoredDomains(counts);
      })
      .catch(error => {
        console.error(error);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [startDate, endDate, query]);

  useEffect(() => {
    const newQueries: string[] = [];
    Object.keys(filters).forEach(filter => {
      const {
        filterBy: { value: field },
        filterValue: value,
      } = filters[filter];
      newQueries.push(`${FILTER_MAP[field] || field}: ${value}`);
    });

    setQuery(newQueries.length ? newQueries.join(' AND ') : undefined);
  }, [filters]);

  const updateFilter = (filter: IFilter) => {
    setFilters({
      ...filters,
      [filter.filterBy.value]: filter,
    });
  };

  const deleteFilter = (filterByValue: string) => {
    const newFilters = { ...filters };
    delete newFilters[filterByValue];
    setFilters(newFilters);
  };

  return (
    <div className='monitored-domains-container'>
      <MonitoredDomainsHeader
        title='Monitored Domains'
        updateFilter={updateFilter}
        deleteFilter={deleteFilter}
        monitoredDomains={monitoredDomains}
      />
      <LoadingWrapper isLoading={isLoading || !domainCategories?.length}>
        {monitoredDomains.length > 0 && !isError ? (
          <div className='monitored-domains-funnel-container'>
            <MonitoredDomainFunnel
              monitoredDomains={monitoredDomains}
              domainCategories={domainCategories}
              query={{ startDate, endDate }}
              filters={Object.values(filters)}
            />
          </div>
        ) : (
          renderNoDataPlaceholder(
            NoDetections,
            'No data found',
            'We haven’t found any data related to this period. Please select another time frame.',
          )
        )}
      </LoadingWrapper>
    </div>
  );
};

export default MonitoredDomainContainer;

import { handleResponse } from './serviceWorker';
import fetch from './api.service';
import { EventHistoryEntity } from '../types/event-history-entity.interface';
import { Nullable } from '../types/common';
import { CustomerComment, CustomerSocResponse } from '../types/url-event.types';

export class UserCommentService {
  async createUrlComment(details: {
    urlSha256: string;
    message: string;
    files?: File[];
    requestEventHistoryId?: Nullable<number>;
  }): Promise<EventHistoryEntity<CustomerComment | CustomerSocResponse>> {
    const body = new FormData();
    body.append('message', details.message);

    if (details.files) {
      for (let i = 0; i < details.files.length; i++) {
        body.append('files', details.files[i]);
      }
    }

    if (details.requestEventHistoryId) {
      body.append('requestEventHistoryId', details.requestEventHistoryId.toString());
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: body,
    };
    const res = await handleResponse<EventHistoryEntity<CustomerComment | CustomerSocResponse>>(
      await fetch(`/platform-api/v1/user-comment/url/${details?.urlSha256}`, requestOptions),
    );
    return res;
  }
}

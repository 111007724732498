import React, { useEffect, useState } from 'react';
import '../../Styles/EntityDropdown.scss';
import { Dropdown } from 'react-bootstrap';
import ExpandBottomDark from '../../../../assets/icons/ExpandBottomDropdownDark.svg';
import ExpandBottomLight from '../../../../assets/icons/ExpandBottom.svg';
import { ILableValues } from '../../types';
import ThemeContext from '../../../../context/ThemeContext';
import { useContext } from 'react';
import _ from 'lodash';
import { ThemeModes } from '../../../../constants';
export interface IEntityDropdownProps {
  dropdownLabel: string;
  dropdownValues: ILableValues[];
  onValueSelectFn?: (selectedValue: ILableValues) => any;
}

const EntityDropdown = ({
  onValueSelectFn,
  dropdownLabel,
  dropdownValues,
}: IEntityDropdownProps) => {
  const [selectedVal, setSelectedVal] = useState<ILableValues | null>(null);
  const { selectedTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (_.isEmpty(dropdownValues)) return;
    setSelectedVal(dropdownValues[0]);
  }, [dropdownValues]);

  useEffect(() => {
    if (onValueSelectFn && selectedVal) {
      onValueSelectFn(selectedVal);
    }
  }, [selectedVal]);

  const onValueSelect = (selectedVal: any) => {
    const selectedDropdownItem = dropdownValues.find(
      (item: ILableValues) => item.value === selectedVal,
    );
    setSelectedVal(selectedDropdownItem ? selectedDropdownItem : null);
  };
  return (
    <Dropdown className='entity-dropdown-wrap' onSelect={onValueSelect}>
      <Dropdown.Header>{dropdownLabel}</Dropdown.Header>
      <Dropdown.Toggle id='enity-dropdown' bsPrefix='entity-dropdown-btn'>
        {selectedVal ? selectedVal.label : ''}{' '}
        <img
          src={
            selectedTheme === ThemeModes.DARK.toLowerCase() ? ExpandBottomDark : ExpandBottomLight
          }
          alt='expand dropdown'
        />
      </Dropdown.Toggle>
      <Dropdown.Menu id='entity-dropdown' className='entity-dropdown-menu'>
        {dropdownValues.map((item: ILableValues, index: number) => (
          <Dropdown.Item
            key={index}
            eventKey={item.value}
            className='dropdown-options'
            value={item.value}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EntityDropdown;

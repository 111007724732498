import React from 'react';
import { useState, useEffect } from 'react';
import '../../Styles/DonutWidget.scss';
import { IDonutEntityType, ILableValues, IDonut } from '../../types';
import DonutGraph from '../Common/DonutGraph';
import DataSetItem from '../Common/DataSetItem';
import _ from 'lodash';
import { ENTITY_TYPE } from '../../DarkWebConstants';

interface ICircularGraph {
  type: string;
  innerLabel: number;
  showLegend?: boolean;
}

export type Props = ICircularGraph & IDonutEntityType;

const DonutWidget = ({
  showGradient,
  dataSet,
  donutLabel,
  dataSetLabel,
  type,
  innerLabel,
  showLegend = true,
  searchTermId,
}: Props) => {
  const [donutData, setDonutData] = useState<IDonut[]>([]);
  const [legendData, setLegends] = useState<ILableValues[]>([]);

  const setDataSetLable = () => {
    if (!showGradient) return '';
    if (type === ENTITY_TYPE.EXECUTIVE_MONITORING) return '';
    const dataSetFound = dataSet.length;
    if (dataSetFound > 5) {
      return `Out of ${dataSetFound} data leak sources, here are the top 5 with the highest number of findings.`;
    } else if (dataSetFound <= 5 && dataSetFound > 1) {
      return `Here are the ${dataSetFound} data leak sources with the most findings.`;
    }
    return `Here is the ${dataSetFound} data leak source with the most findings.`;
  };

  useEffect(() => {
    if (_.isEmpty(dataSet)) return;
    const totalSum = dataSet.reduce((sum: number, item1: ILableValues) => {
      sum = sum + _.toNumber(item1.value);
      return sum;
    }, 0);
    const updatedGraphItems: IDonut[] = dataSet.map((item: ILableValues) => {
      const percentage = Math.round((_.toNumber(item.value) / totalSum) * 100);
      return {
        label: item.label,
        value: item.value,
        percentage,
      };
    });
    setLegends(dataSet.slice(0, 5));
    setDonutData(updatedGraphItems);
  }, [dataSet]);

  return (
    <div
      className={`donut-wrapper ${!showLegend ? 'employee-donut' : ''}`}
      data-testid='widget-item'
    >
      <DonutGraph
        donutLabel={donutLabel}
        dataSet={donutData}
        innerLable={innerLabel}
        showGradient={showGradient}
      />
      {legendData.length && showLegend ? (
        <div className='details'>
          <div className='heading' data-testid='dataset-label'>
            {dataSetLabel || setDataSetLable()}
          </div>
          <div className='data-set-wrap'>
            {legendData.map((dataItem: ILableValues, index: number) => (
              <DataSetItem
                dataItem={dataItem}
                key={index}
                showTooltip={showGradient}
                entityType={type}
                searchTermId={searchTermId}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DonutWidget;

import React from 'react';
import {
  APP_STORE_NAME,
  APP_STORE_STORE_NAME,
  UGC_URL,
  PROPERTY_CREATED_BY_COL,
  UGC_DATE_ADDED,
  BRAND_COLUMN,
  BRAND_PROPERTY_ACTION_COL,
} from './AssetsColDefination/BrandPropertyCols';
import BrandPropertyComponent from './BrandPropertyComponent';
import { APP_STORE_TABLE_NAME, APP_STORE_TABLE_ID } from './constants';
import { PropertyType } from './PropertyTypes';
import { appConstants } from '../../constants';

const AppStoreAssets = () => {
  const PAGE_TITLE = 'App Store';
  window.document.title = `${PAGE_TITLE} | Assets`;

  const TABLE_COLUMNS = [
    APP_STORE_NAME,
    APP_STORE_STORE_NAME,
    UGC_URL(),
    PROPERTY_CREATED_BY_COL,
    UGC_DATE_ADDED,
    BRAND_COLUMN,
    BRAND_PROPERTY_ACTION_COL,
  ];
  const defaultColumnIds = [
    'brand',
    'domainLabel',
    'name',
    'url',
    'created_by',
    'status',
    'created_ts',
    'assets-action-col',
  ];

  return (
    <BrandPropertyComponent
      tableName={APP_STORE_TABLE_NAME}
      id={APP_STORE_TABLE_ID}
      columns={TABLE_COLUMNS}
      title={PAGE_TITLE}
      disableDatePicker
      type={PropertyType.APP_STORE}
      defaultColumnIds={defaultColumnIds}
      ugcType={appConstants.CONTENT_TYPE.APP_STORE}
    />
  );
};

export default AppStoreAssets;

import React from 'react';
import _ from 'lodash';
import { IPrice } from '../types';
import './PricingInfo.scss';
import { Form } from 'react-bootstrap';
import { ISubscriptionItem } from '../types';

export type PricingInfoProps = {
  price: string | IPrice[];
  alignContent?: boolean;
};

type AmtProps = {
  amount: IPrice;
  showBorder: boolean;
  alignContent: boolean;
  showPadding: boolean;
  index: number;
};

const Amount = ({ amount, showBorder, alignContent, showPadding, index }: AmtProps) => {
  const { price, subText, duration } = amount;
  return (
    <div
      className={`amount-wrapper ${showBorder ? 'border-left' : ''} ${
        alignContent ? 'align-content' : ''
      } ${showPadding ? 'amount-padding' : ''}`}
      data-testid='amount-wrapper'
    >
      <div className='amount-wrap'>
        <div className='price-wrap'>
          {_.isEmpty(subText) ? (
            <h3>${price}</h3>
          ) : (
            <>
              <h3>${price}</h3>/<span className='sub-text'>{subText}</span>
            </>
          )}
        </div>
        <div className='duration'>/ {duration}</div>
      </div>
    </div>
  );
};

const PricingInfo = ({ price, alignContent = false }: PricingInfoProps) => {
  if (_.isString(price)) return <h3 className='pricing-info-wrap bold-text'>{price}</h3>;
  return (
    <div className='pricing-info-wrap' data-testid='pricing-info-wrap'>
      {price.map((amount, index) => {
        return (
          <Amount
            amount={amount}
            showBorder={index !== 0}
            key={`amt-${index}`}
            alignContent={alignContent}
            showPadding={index !== 0}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default PricingInfo;

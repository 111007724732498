import { handleResponse } from '../../services/serviceWorker';
import { GlobalWebTableParams } from '../Common/Table/ag-table/ag-utils';

export const fetchGlobalTakedownWebData = async (payload: GlobalWebTableParams) => {
  const rawResponse = await fetch(`/platform-api/v1/global-takedown/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then(handleResponse);
  return rawResponse;
};

export const updateSocActionDetails = async (data: any) => {
  const rawResponse = await fetch(`/platform-api/v1/event-history/create-update`, {
    method: 'POST',
    credentials: 'include',
    body: data,
  }).then(handleResponse);
  return rawResponse;
};

export const deleteSocActionDetails = async (data: { eventId: number }) => {
  const rawResponse = await fetch(`/platform-api/v1/event-history/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(handleResponse);
  return rawResponse;
};

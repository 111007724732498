import React from 'react';
import {
  NextPageDisabledIcon,
  NextPageIcon,
  PrevPageDisabledIcon,
  PrevPageIcon,
} from '../../../assets/SVGIcons';
import { IControllerProps } from './constant';
import './table.scss';
interface IComponentState {
  pageSize: number;
  totalPages: number;
  currentPageNumber: number;
}

class TablePaginationController extends React.Component<IControllerProps, IComponentState> {
  constructor(props: IControllerProps) {
    super(props);
    this.state = this.setNewState();
  }

  onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { totalPages } = this.state;
    const value = e.currentTarget.value.replace(/\b0+/g, '');
    let pageNumber = parseInt(value) || 0;
    if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    this.setState({
      currentPageNumber: pageNumber,
    });
    this.onPageChange(pageNumber);
  };

  onPageChange = (pageNumber: number): void => {
    if (pageNumber >= 1 && pageNumber <= this.state.totalPages) {
      this.props.onPageChange(pageNumber);
    }
  };

  setNewState = (): IComponentState => {
    const { total, pageSize, currentPageNumber } = this.props;

    return {
      pageSize: pageSize,
      totalPages: Math.ceil(total / pageSize) || 1,
      currentPageNumber,
    };
  };

  componentDidUpdate(prevProps: IControllerProps, prevState: IComponentState) {
    if (
      prevProps.total !== this.props.total ||
      prevProps.pageSize !== this.props.pageSize ||
      prevProps.currentPageNumber !== this.props.currentPageNumber
    ) {
      this.setState(this.setNewState());
    }
  }

  render() {
    const { total, firstRowIndex, lastRowIndex } = this.props;
    const { totalPages, currentPageNumber, pageSize } = this.state;

    return (
      <div className={'table-pagination-controller'} data-testid='table-pagination-controller'>
        <span className='page-number-view'>
          Showing {Math.min(firstRowIndex + 1, lastRowIndex)} - {lastRowIndex} of {total}
        </span>
        {total > pageSize && (
          <div className={'d-flex align-items-center'}>
            <div className='table-pagination-button-wrapper'>
              <div
                id='prev-page-button'
                className={currentPageNumber === 1 ? 'icon-disabled' : ''}
                onClick={() => {
                  this.onPageChange(currentPageNumber - 1);
                }}
              >
                {currentPageNumber === 1 ? (
                  <PrevPageDisabledIcon color='black' />
                ) : (
                  <PrevPageIcon color='black' />
                )}
              </div>
            </div>

            <input value={currentPageNumber} type='number' onChange={this.onInputChange} />
            <span>{` of ${totalPages}`}</span>
            <div className='table-pagination-button-wrapper'>
              <div
                id='next-page-button'
                className={currentPageNumber >= totalPages ? 'icon-disabled' : ''}
                onClick={() => {
                  this.onPageChange(currentPageNumber + 1);
                }}
              >
                {currentPageNumber >= totalPages ? (
                  <NextPageDisabledIcon color='black' />
                ) : (
                  <NextPageIcon color='black' />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { TablePaginationController };

import React from 'react';
import _ from 'lodash';
import { IRowProps } from './constant';
import Form from 'react-bootstrap/Form';
import { CHECKBOX_COLUMN_WIDTH } from './table';
import './table.scss';
import { Checkbox } from '../Checkbox';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Dropdown } from '../../Common/Dropdown';

interface IComponentState {
  editing: boolean;
}

class TableRow extends React.Component<IRowProps, IComponentState> {
  private editedValues: object = {};
  private selectedItems: any[] = [];

  static defaultProps = {
    isNew: false,
    editing: false,
    selectedItems: [],
  };

  constructor(props: IRowProps) {
    super(props);
    this.state = {
      editing: props.editing,
    };
    this.selectedItems = props.selectedItems.concat();
  }
  toggleEditMode = () => {
    const editing = !this.state.editing;
    if (editing) {
      this.editedValues = {};
    }
    this.setState({
      editing,
    });
  };

  addItem = () => {
    this.props.addItemFn(this.editedValues);
    this.props.onClose();
  };

  deleteItem = () => {
    const { item } = this.props;
    this.props.onCheck(true, item);
    this.props.toggleDeleteModal(item);
  };

  saveItem = () => {
    const { item } = this.props;
    this.props.editItemFn({ ...item, ...this.editedValues }, item, this.editedValues);
    this.toggleEditMode();
  };

  onValueChange = (newValue: string, key: string) => {
    this.editedValues[key] = newValue;
  };

  onRowDblClick = (item: any) => {
    this.props.onRowDblClick(item);
  };

  shouldComponentUpdate(nextProps: IRowProps, nextState: IComponentState): boolean {
    if (nextState !== this.state) {
      return true;
    }
    const isPropChange = (key: string): boolean => {
      return JSON.stringify(nextProps[key]) !== JSON.stringify(this.props[key]);
    };
    if (
      isPropChange('item') ||
      isPropChange('columns') ||
      isPropChange('isNew') ||
      isPropChange('editing') ||
      isPropChange('enableCheckbox') ||
      isPropChange('onCheck') ||
      isPropChange('onRowDblClick') ||
      isPropChange('addItemFn') ||
      isPropChange('editItemFn') ||
      isPropChange('deleteItemFn') ||
      isPropChange('toggleDeleteModal') ||
      isPropChange('onClose') ||
      isPropChange('selectedItems')
    ) {
      return true;
    }
    const nextChecked = _.some(nextProps.selectedItems, ['_index', nextProps.item._index]);
    const thisChecked = _.some(this.selectedItems, ['_index', this.props.item._index]);
    this.selectedItems = nextProps.selectedItems.concat();
    if (nextChecked !== thisChecked) {
      return true;
    }
    return false;
  }

  renderColumnCell = (column: any) => {
    const { item, isNew } = this.props;
    const { editing } = this.state;

    let cellContent = ' ';
    if (column.render) {
      cellContent = column.render(item);
    } else if (
      column.accessor &&
      item[column.accessor] !== undefined &&
      item[column.accessor] !== null
    ) {
      cellContent = item[column.accessor];
    }

    let classNames = column.columnClassName || '';
    if (column.hiddenOnMobile) {
      classNames += ' web-only';
    }

    if (!!column.editable && editing && (!column.notUpdatable || isNew)) {
      let brandOptions: any[] = [];
      if (this.props.user && this.props.user?.brand_info?.brand.subBrands.length !== 0) {
        brandOptions = this.props.user?.brand_info?.brand.subBrands.map(
          (item: { brandId: number; brandDisplayName: string }) => {
            return {
              value: item.brandId,
              label: item.brandDisplayName,
            };
          },
        );
      }
      const roleOptions: any[] = [
        { label: 'Admin', value: 3 },
        { label: 'User', value: 2 },
      ];
      return (
        <td className={classNames + ' editing-row'} key={'column-' + column.accessor}>
          {column.accessor === 'brandId' ? (
            <Dropdown
              boxStyle
              variant='primary'
              key={'brandDisplayName'}
              emptyText={'Brand'}
              options={brandOptions}
              defaultSelection={brandOptions.length === 1 && brandOptions[0]}
              onChange={(e: { value: string; label: string }) => {
                this.onValueChange(e.value, column.accessor);
              }}
            />
          ) : column.accessor === 'roleId' ? (
            <Dropdown
              boxStyle
              variant='primary'
              key={'role'}
              emptyText={'Role'}
              options={roleOptions}
              onChange={(e: { value: string; label: string }) => {
                this.onValueChange(e.value, column.accessor);
              }}
            />
          ) : (
            <Form.Control
              required={!column.isOptional}
              type='text'
              placeholder={column.header}
              defaultValue={item[column.accessor]}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                this.onValueChange(e.currentTarget.value, column.accessor);
              }}
            />
          )}
        </td>
      );
    }

    if (column.isLongText) {
      return (
        <td
          className={classNames}
          key={Math.random()}
          onDoubleClick={() => {
            this.onRowDblClick(item);
          }}
        >
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip id={'tooltip-' + cellContent} className={'table-source-url-tooltip'}>
                {cellContent}
              </Tooltip>
            }
          >
            <div className={'table-long-text long-text-ellipsis-1'}>{cellContent}</div>
          </OverlayTrigger>
        </td>
      );
    }

    return (
      <td
        className={classNames}
        key={Math.random()}
        onDoubleClick={() => {
          this.onRowDblClick(item);
        }}
      >
        {cellContent}
      </td>
    );
  };

  render() {
    const {
      item,
      columns,
      selectedItems,
      enableCheckbox,
      isNew,
      addItemFn,
      editItemFn,
      deleteItemFn,
    } = this.props;

    const { editing } = this.state;
    let row: any[] = [];
    if (enableCheckbox) {
      const hasChecked = _.some(selectedItems, ['_index', item._index]);
      const content = _.isEmpty(item) ? null : (
        <Checkbox
          onChange={(checked: boolean) => {
            this.props.onCheck(checked, item);
          }}
          disabled={item?.disabledCheckbox}
          isStateless={true}
          defaultChecked={hasChecked}
        />
      );
      row.push(
        <td
          key={'table-check-box-' + JSON.stringify(item)}
          style={{ width: CHECKBOX_COLUMN_WIDTH, paddingTop: 14 }}
        >
          {content}
        </td>,
      );
    }

    row = row.concat(
      columns.map((column: any) => {
        return this.renderColumnCell(column);
      }),
    );

    const buttons = [];

    if (addItemFn || editItemFn || deleteItemFn) {
      if (_.isEmpty(item)) {
        if (isNew) {
          buttons.push(
            <span
              key={'cancel-button'}
              className={'action delete-item cancel-item'}
              onClick={this.props.onClose}
            >
              Cancel
            </span>,
          );
          buttons.push(
            <span key={'save-button'} className={'action save-item'} onClick={this.addItem}>
              Add
            </span>,
          );
        } else {
          row.push(<td className={'web-only'} key={Math.random()} />);
        }
      } else {
        if (editing) {
          buttons.push(
            <span
              key={'cancel-button'}
              className={'action delete-item cancel-item'}
              onClick={this.toggleEditMode}
            >
              Cancel
            </span>,
          );
          buttons.push(
            <span key={'save-button'} className={'action save-item'} onClick={this.saveItem}>
              Save
            </span>,
          );
        } else if (_.get(item, ['editable'], true)) {
          if (deleteItemFn) {
            buttons.push(
              <span
                key={'delete-button'}
                className={'action delete-item'}
                onClick={this.deleteItem.bind(this, item)}
              >
                Delete
              </span>,
            );
          }
          if (editItemFn) {
            buttons.push(
              <span
                key={'edit-button'}
                className={'action edit-item'}
                onClick={this.toggleEditMode}
              >
                Edit
              </span>,
            );
          }
        }
      }
      row.push(
        <td
          key={Math.random()}
          onDoubleClick={() => {
            this.onRowDblClick(item);
          }}
        >
          <div className={'table-action-container'}>{buttons}</div>
        </td>,
      );
    }

    return row;
  }
}

export { TableRow };

import React, { useState } from 'react';
import RequestTakedownModal from '../../Common/RequestTakedownModal';
import MarkAsSafelistModal from '../../Common/MarkAsSafelistModal';
import WarningIcon from '../../../../../assets/icons/Warning.svg';
import { IFindingsTableItemProps, ugcType, ugcTypeLabel } from '../../../Types/ugc.types';
import AppStoreTakedownModal from '../../../../AppStore/Components/Findings/common/AppStoreTakedownModal';
import {
  appConstants,
  featureIsAvailable,
  IFindingsTableUrls,
  isInternalUser,
} from '../../../../../constants';
import { FINDINGS_TABLE_IDS } from '../../../../MonitorAndTakedown/constants';
import { AppState } from '../../../../../helpers';
import { connect } from 'react-redux';
import { dashboardActions } from '../../../../../actions';

interface IActionColRenderProps {
  data: IFindingsTableItemProps;
  onSuccess?: (message?: string, data?: any) => void;
  type: string;
  tableId: FINDINGS_TABLE_IDS;
  user: any;
  setFindingsTableUrls: (
    tableId: FINDINGS_TABLE_IDS,
    selected: IFindingsTableItemProps[],
    type: string,
  ) => void;
  findingsTableUrls: IFindingsTableUrls;
}

const DetectionsActionColRender = ({
  data,
  onSuccess,
  type,
  user,
  tableId,
  setFindingsTableUrls,
  findingsTableUrls,
}: IActionColRenderProps) => {
  const [initiateTakedownModalShown, setInitiateTakedownModalShown] = useState<boolean>(false);
  const toggleInitiateTakedownModal = () => {
    setInitiateTakedownModalShown(!initiateTakedownModalShown);
  };
  const onTakedownSuccess = () => {
    toggleInitiateTakedownModal();
    onSuccess && onSuccess(undefined, data);
  };

  const [safelistModalShown, setSafelistModalShown] = useState<boolean>(false);
  const toggleSafelistModalShown = () => {
    setSafelistModalShown(!safelistModalShown);
  };
  const onSafelistSuccess = () => {
    toggleInitiateTakedownModal();
    onSuccess && onSuccess();
  };
  const onSubmitModals = (data: any, type: string, tableId: FINDINGS_TABLE_IDS) => {
    if (
      (user && featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE)) ||
      isInternalUser(user)
    ) {
      setFindingsTableUrls(
        tableId,
        findingsTableUrls[type][tableId]?.filter(
          (url: { url_sha256: string }) => url.url_sha256 !== data[0].url_sha256,
        ),
        type,
      );
    }
  };
  return (
    <div className={'action-col-render-component d-flex align-items-center'}>
      <img src={WarningIcon} alt={'warning'} />
      <div className='action-text-container'>
        <div className='action-text' onClick={toggleInitiateTakedownModal}>
          Initiate Takedown
        </div>
      </div>
      <div className='action-text-container'>
        <div className='action-text' onClick={toggleSafelistModalShown}>
          Ignore
        </div>
      </div>
      {type === ugcType.App_Store ? (
        <AppStoreTakedownModal
          show={initiateTakedownModalShown}
          onCloseModal={toggleInitiateTakedownModal}
          data={[data]}
          type={type}
          onModalSubmit={(data: any) => {
            onSubmitModals(data, type, tableId);
          }}
        />
      ) : (
        <RequestTakedownModal
          data={[data]}
          onModalSubmit={(data: any) => {
            onSubmitModals(data, type, tableId);
          }}
          show={initiateTakedownModalShown}
          onCloseModal={toggleInitiateTakedownModal}
          type={type}
        />
      )}
      {safelistModalShown && (
        <MarkAsSafelistModal
          data={[data]}
          show={safelistModalShown}
          onModalSubmit={(data: any) => {
            onSubmitModals(data, type, tableId);
          }}
          onCloseModal={toggleSafelistModalShown}
          type={type}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { findingsTableUrls } = state.dashboardReducer;

  return {
    findingsTableUrls,
  };
};
const mapDispatchToProps = {
  setFindingsTableUrls: dashboardActions.setFindingsTableUrls,
};
export default connect(mapStateToProps, mapDispatchToProps)(DetectionsActionColRender);

import moment from 'moment';
import React from 'react';
import './DisputeStatus.scss';
import { Card } from 'react-bootstrap';
import AttachedFileViewer from './AttachedFileViewer';
import '../Insights/insights.scss';

interface IComponentProps {
  disposition: string;
  bolsterDisposition: string;
  status: string;
  user: any;
  timestamp: number;
  comments: string;
  hideHeader?: boolean;
  attachedFiles?: AttachedFileObject[];
}

export const DISPUTE_STATUS = {
  under_review: 'Under Review',
  accepted: 'Accepted',
  rejected: 'Rejected',
};

export const DISPUTE_DISPOSITION_OPTIONS = {
  clean: 'Clean',
  phish: 'Phish',
  scam: 'Scam',
  suspicious: 'Suspicious',
};

const DISPUTE_DISPOSITION = {
  ...DISPUTE_DISPOSITION_OPTIONS,
  likely_phish: 'Likely Phish',
  hackedWebsite: 'Hacked',
  cryptojacking: 'Cryptojacking',
  other: 'Other',
};

export interface AttachedFileObject {
  path: string;
  name: string;
  createdTS: string;
}

class DisputeStatus extends React.Component<IComponentProps> {
  constructor(props: IComponentProps) {
    super(props);
  }
  getUserName(user: any): string {
    return `${user.first_name} ${user.last_name}`;
  }

  render() {
    const {
      disposition,
      bolsterDisposition,
      status,
      user,
      timestamp,
      comments,
      attachedFiles = [],
      hideHeader = false,
    } = this.props;

    return (
      <div className='dispute-status'>
        {!hideHeader && (
          <Card.Header>
            <div className='dispute-header'>
              Disputed Disposition:{' '}
              <span className='dispute-disposition'>{DISPUTE_DISPOSITION[bolsterDisposition]}</span>
            </div>
            <div className='dispute-header'>
              Disputed as:{' '}
              <span className='dispute-disposition'>{DISPUTE_DISPOSITION[disposition]}</span> -{' '}
              {DISPUTE_STATUS[status]}
            </div>
          </Card.Header>
        )}
        <Card.Body>
          {user && (
            <Card.Text className={'dispute-reporter-name'}>{this.getUserName(user)}</Card.Text>
          )}
          <Card.Text>{moment(timestamp).format('DD-MMM-YYYY, h:mm a')}</Card.Text>
          <Card.Text className={'dispute-comments'}>{comments || '--'}</Card.Text>
          {attachedFiles?.length > 0 && <AttachedFileViewer attachedFiles={attachedFiles} />}
        </Card.Body>
      </div>
    );
  }
}

export { DisputeStatus };

import { userConstants } from '../constants';

const resetReducer = (state = { result: true }, action: any) => {
  switch (action.type) {
    case userConstants.RESETPASS_SUCCESS:
      return {
        result: true,
      };
    case userConstants.RESETPASS_FAILURE:
      return {
        result: false,
      };
    default:
      return state;
  }
};
export { resetReducer };

import React, { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react';
import './darkwebfindings.scss';
import { STATUS_COLUMN_DYNAMIC_RENDER } from '../../../DarkWeb/Components/Common/TableColDefs/DarkWebColDefs';
import _, { capitalize, find, map } from 'lodash';
import { Dropdown, IDropdownOption } from '../../../Common/Dropdown';
import {
  EDarkWebFindingStatus,
  IDarkWebFindingData,
} from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { connect } from 'react-redux';
import { alertActions } from '../../../../actions';
import {
  ThemeModes,
  appConstants,
  featureIsAvailable,
  getProvidedTagLabelValue,
  getUsersListLabelValue,
} from '../../../../constants';
import { Card } from 'react-bootstrap';
import { TableContextProvider } from '../../../Common/Table/table.context';
import { DARK_WEB_TABLE_NAME } from '../../../DarkWeb/DarkWebConstants';
import DarkWebFindingStatus from '../../../DarkWeb/Components/Common/DarkWebFindingStatus';
import Tags, { ITags, ETagsTypes } from '../../../Common/Tags/Tags';
import {
  TAGS_COLUMN,
  TAGS_AUTHOR_COLUMN,
} from '../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import { AppState } from '../../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import ThemeContext from '../../../../context/ThemeContext';
import { ENTITY_MAPPER, ENTITY_TYPE } from '../../DarkWebConstants';
import AgGridDarkWebTable from '../../../Common/Table/ag-table/AgGridDarkWebTable';
import AgGridDarkWebFlatTable from '../../../Common/Table/ag-table/AgGridDarkWebTableFlatView';
import ChangeStatusModal from '../../../DarkWeb/Components/Common/ChangeStatusModal';
import { ExportIcon } from '../../../../assets/SVGIcons';
import { convertToFetchDarkWebApiParams } from '../../../Common/Table/ag-table/ag-utils';
import {
  ExportFindingsDarkWebV2HistoryModal,
  IExportFindingsDarkWebV2HistoryModalDependencies,
} from './ExportFindingsDarkWebV2HistoryModal';
import { FilterModel } from 'ag-grid-community';
import { EBulkActionModalType } from '../../../Ugc/Components/Findings/FindingsTableComponent';
import {
  setIsGroupRelatedDataReady,
  setTagsFromBulkAction,
} from '../../../../reducers/table.reducer';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';

interface IProps {
  id: string;
  status: EDarkWebFindingStatus;
  selectedEntity: ENTITY_TYPE;
  widgetDetails: any;
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>;
  selectedItems: any[];
  setIsDarkWebTablesGroupView: (isDarkWebTablesGroupView: boolean) => void;
  onChangeStatusSuccess?: () => void;
  handleGroupFilterChange: (filterModel: FilterModel) => void;
}

const DarkWebFindingsTable = (props: IProps) => {
  const {
    id,
    status,
    selectedEntity,
    selectedItems,
    setSelectedItems,
    widgetDetails,
    setIsDarkWebTablesGroupView,
    onChangeStatusSuccess,
    handleGroupFilterChange,
  } = props;

  const { selectedTheme } = useContext(ThemeContext);
  const [newStatus, setNewStatus] = useState<EDarkWebFindingStatus>(status);

  const [changeStatusModalShown, setChangeStatusModalShown] = useState<boolean>(false);
  const displayLayoutRef = useRef(true);
  const [exportDependencies, setExportDependencies] = useState<
    IExportFindingsDarkWebV2HistoryModalDependencies | undefined
  >(undefined);
  const [bulkActionModal, setBulkActionModal] = useState<EBulkActionModalType>(
    EBulkActionModalType.None,
  );
  const [tagsAnchorEl, setTagsAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const providedTags = useAppSelector((state: AppState) => state.tagsReducer.allPlatformTags);
  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);
  const usersList = useAppSelector((state: AppState) => state.dashboardReducer.usersList);
  const isDarkWebTablesGroupView = useAppSelector(
    (state: AppState) => state.tableReducer.isDarkWebTablesGroupView,
  );
  const outGoingPersistentFilterString = useAppSelector(
    (state: AppState) => state.tableReducer.outGoingPersistentFilterString,
  );

  //Todo : remove the feature check once the feature is available for all
  const filterCustomTags = providedTags.filter((tag: ITags) => {
    if (featureIsAvailable(user, appConstants.FEATURE_CODE.CUSTOMER_GENERATED_TAGS)) {
      return true;
    } else if (tag.type !== ETagsTypes.CUSTOMER_CREATED) {
      return true;
    }
  });

  const filteredTags = filterCustomTags.filter(
    (tag: ITags) => tag.type !== ETagsTypes.BOLSTER_RESTRICTED,
  );
  const tagsDropdown = getProvidedTagLabelValue(
    filteredTags,
    selectedTheme === ThemeModes.DARK.toLowerCase(),
  );

  useEffect(() => {
    if (selectedEntity === ENTITY_TYPE.OTHERS) {
      setIsDarkWebTablesGroupView(false);
    } else {
      setIsDarkWebTablesGroupView(true);
    }
  }, [selectedEntity]);

  useEffect(() => {
    const bulkActionBtnElement = document.getElementById('dropdown-basic-button');
    if (bulkActionBtnElement) {
      setTagsAnchorEl(bulkActionBtnElement);
      dispatch(setIsGroupRelatedDataReady(true));
    }
  }, [tagsAnchorEl, selectedItems, dispatch]);

  const toggleChangeStatusModal = () => {
    setChangeStatusModalShown(!changeStatusModalShown);
  };
  const onItemCheck = (checked: boolean, item: any, selectedItems: any) => {
    setSelectedItems(selectedItems);
  };

  const onChangeStatusSuccessLogic = () => {
    setSelectedItems([]);
    setChangeStatusModalShown(false);
    onChangeStatusSuccess && onChangeStatusSuccess();
  };

  const openExportModal = useCallback(() => {
    const searchTermIds: string[] = [];
    let searchTermsCount = 0;
    const columns: { label: string; field: string }[] = [];
    ENTITY_MAPPER[selectedEntity].columns.forEach(c => {
      if (c.header) {
        columns.push({
          label: c.header,
          field: c.accessor,
        });
      }
    });
    columns.push({
      label: 'Status',
      field: 'status',
    });
    Object.keys(widgetDetails.searchterms).map(k => {
      searchTermIds.push(widgetDetails.searchterms[k].searchTermId);
      searchTermsCount = searchTermsCount + widgetDetails.searchterms[k].totalFindings;
    });
    const apiParams = convertToFetchDarkWebApiParams(
      {
        sortModel: [],
        startRow: undefined,
        endRow: undefined,
        rowGroupCols: [],
        valueCols: [],
        pivotCols: [],
        groupKeys: [],
        pivotMode: false,
        filterModel: outGoingPersistentFilterString
          ? JSON.parse(outGoingPersistentFilterString)
          : null,
      },
      searchTermsCount,
      status,
      {
        ...ENTITY_MAPPER[selectedEntity].extraFilters,
        searchTermIds,
      },
      ENTITY_MAPPER[selectedEntity].additionalFields,
    );
    setExportDependencies({
      apiParams,
      columns,
      status,
      label: ENTITY_MAPPER[selectedEntity].label,
    });
  }, [status, outGoingPersistentFilterString, selectedEntity, widgetDetails]);

  const renderTable = () => {
    const searchTermsColumn: any = find(ENTITY_MAPPER[selectedEntity].columns, [
      'id',
      'search_term',
    ]);
    if (searchTermsColumn) {
      (searchTermsColumn as any).filterOptions = Object.keys(widgetDetails.searchterms).map(k => {
        return {
          label: k,
          value: widgetDetails.searchterms[k].searchTermId,
        };
      });
    }
    const columns = [
      ...ENTITY_MAPPER[selectedEntity].columns,
      STATUS_COLUMN_DYNAMIC_RENDER((data: IDarkWebFindingData) => {
        return (
          <DarkWebFindingStatus
            data={data}
            status={data.status}
            placement='right-start'
            showChevron
            onSubmit={onChangeStatusSuccessLogic}
          />
        );
      }),
      {
        ...TAGS_COLUMN,
        filterOptions: tagsDropdown,
        render: (rowData: any) => {
          return (
            <Tags
              leftAligned={displayLayoutRef.current}
              rowData={rowData}
              type={appConstants.CONTENT_TYPE.DARK_WEB}
            />
          );
        },
      },
      {
        ...TAGS_AUTHOR_COLUMN,
        filterOptions: getUsersListLabelValue(usersList),
      },
    ];
    return (
      <Card className={'dashboard-table-container'}>
        <div className='table-component'>
          <div className='bs-table-container'>
            <TableContextProvider
              key={`${id}-${selectedEntity}`}
              columns={columns as any}
              dashboardName={DARK_WEB_TABLE_NAME}
              tableId={`${id}-${selectedEntity}`}
              onItemCheck={onItemCheck}
            >
              {isDarkWebTablesGroupView ? (
                <AgGridDarkWebTable
                  key={`${id}-${selectedEntity}`}
                  darkwebStatus={status}
                  widgetDetails={widgetDetails}
                  extraFilters={ENTITY_MAPPER[selectedEntity].extraFilters}
                  additionalFields={ENTITY_MAPPER[selectedEntity].additionalFields}
                  statusUpdateSuccess={() => {
                    onChangeStatusSuccessLogic();
                  }}
                  handleGroupFilterChange={handleGroupFilterChange}
                />
              ) : (
                <AgGridDarkWebFlatTable
                  columns={columns as any}
                  key={`${id}-${selectedEntity}`}
                  darkwebStatus={status}
                  widgetDetails={widgetDetails}
                  extraFilters={ENTITY_MAPPER[selectedEntity].extraFilters}
                />
              )}
            </TableContextProvider>
          </div>
        </div>
      </Card>
    );
  };

  const bulkActionDropDownOptions = map(EDarkWebFindingStatus, value => ({
    label: `Mark as ${capitalize(value.toLowerCase())}`,
    value,
    disabled: value === status,
  }));

  const tableId = `${id}-${selectedEntity}`;

  const onChangeBulkActionHandler = (event: any) => {
    toggleChangeStatusModal();

    setNewStatus(event.value);
    setBulkActionModal(event.value);
  };

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <>
      <div className='mb-3 mt-3 tools-container d-flex justify-content-end align-content-center'>
        <div
          key={'Export CSV'}
          onClick={openExportModal}
          className='export-wrapper d-flex align-items-center tool-buttons-wrapper mr-3'
        >
          <ExportIcon color={'grey'} />
          <div className='pl-2 export-label'>Export CSV</div>{' '}
        </div>
        <Dropdown
          boxStyle
          variant='primary'
          fixedPlaceholder={'BULK ACTION'}
          key={'change dark web status'}
          options={bulkActionDropDownOptions}
          disabled={selectedItems.length === 0 || isReadOnlyUser}
          onChange={event => onChangeBulkActionHandler(event)}
        />
      </div>
      {renderTable()}
      <ChangeStatusModal
        data={selectedItems}
        show={changeStatusModalShown}
        status={newStatus}
        onCancel={toggleChangeStatusModal}
        onSubmit={onChangeStatusSuccessLogic}
      />
      <ExportFindingsDarkWebV2HistoryModal
        show={exportDependencies !== undefined}
        dependencies={exportDependencies}
        onComplete={() => setExportDependencies(undefined)}
        onCancel={() => setExportDependencies(undefined)}
      />
      {bulkActionModal === 'Add Tags' && (
        <Tags
          fromAgGrid
          agAnchorEl={tagsAnchorEl}
          rowData={selectedItems}
          showTagsOverlay={true}
          type={appConstants.CONTENT_TYPE.DARK_WEB}
          bulkOption
          onClose={() => {
            setBulkActionModal(EBulkActionModalType.None);
          }}
          getUpdatedTags={async (updatedTagsRes: any) => {
            dispatch(setTagsFromBulkAction(updatedTagsRes));
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};
export default connect(undefined, mapDispatchToProps)(DarkWebFindingsTable);

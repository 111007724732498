import React, { useContext, useEffect, useRef, useState } from 'react';
import ModalOverlay from './ModalOverlay';
import { TimerIcon } from '../../assets/SVGIcons';
import './UserTimeoutOverlay.scss';
import UserService from '../../services/user.service';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';
import { useCookies } from 'react-cookie';
import { getLocalStorageValue, setLocalStorageValue } from '../../constants';
import moment from 'moment';
import ActiveBrowserTabContext from '../../context/ActiveBrowserTabContext';

interface IUserTimeoutOverlayProps {
  duration?: number;
  alertSuccess: (message: string) => any;
  alertError: (message: string) => any;
  logout: () => any;
  signOut: (redirectUrl: string) => any;
}

const UserTimeoutOverlay = ({
  alertSuccess,
  alertError,
  logout,
  signOut,
}: IUserTimeoutOverlayProps) => {
  const sessionInActiveLimit = getLocalStorageValue('sessionInActiveLimit');
  const userService = new UserService();
  const { isTabHidden, onSameDomain, handleVisibilityChange } = useContext(ActiveBrowserTabContext);
  const timeoutDurationRefs = useRef(sessionInActiveLimit);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds
  const overlayDetails = useRef({
    title: 'Are you still here?',
    description: 'If not, your online session will end in:',
    showCountdown: true,
  });

  let timeoutId: any = null;

  useEffect(() => {
    const isTabHidden = getLocalStorageValue('isTabHidden');
    const resetTimer = () => {
      const now = moment().valueOf();
      const sessionExpiration = getLocalStorageValue('sessionExpiration');
      const remainingTime = sessionExpiration - now;
      const userLatestActivity = getLocalStorageValue('userLatestActivity');
      if (
        userLatestActivity &&
        userLatestActivity < sessionExpiration &&
        now - userLatestActivity <= 120000 &&
        sessionExpiration - now > 120000
      ) {
        setShowOverlay(false);
        clearTimeout(timeoutId);
        setCountdown(120);
        timeoutId = setTimeout(() => {
          setShowOverlay(true);
        }, timeoutDurationRefs.current);
        return;
      }

      if (remainingTime <= 120000 && remainingTime > 0) {
        onRefreshSession(true);
        return;
      } else if (remainingTime < 0) {
        signOut('/sign-in?error=Session timed out.');
        return;
      } else {
        clearTimeout(timeoutId);
        setShowOverlay(false);
        timeoutId = setTimeout(() => {
          setShowOverlay(true);
        }, timeoutDurationRefs.current);
        return;
      }
    };

    const handleUserActivity = () => {
      handleVisibilityChange();
      setLocalStorageValue('userLatestActivity', moment().valueOf());
      if (onSameDomain && !isTabHidden && !showOverlay) {
        resetTimer();
      }
    };

    // Initialize the timer
    timeoutId = setTimeout(() => {
      setShowOverlay(true);
    }, timeoutDurationRefs.current);

    // Add event listeners for click and keydown events
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      // Clean up event listeners and timers when the component unmounts
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, [timeoutDurationRefs.current, isTabHidden, onSameDomain]);

  useEffect(() => {
    const isTabHidden = getLocalStorageValue('isTabHidden');

    if (countdown === 0 || (isTabHidden && showOverlay)) {
      setShowOverlay(false);
      signOut('/sign-in?error=Session timed out.');
      return;
    }
  }, [countdown, onSameDomain]);

  useEffect(() => {
    if (showOverlay) {
      const countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => (prevCountdown > 1 ? prevCountdown - 1 : 0));
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [showOverlay]);

  const onLogoutHandler = () => {
    setShowOverlay(false);
    logout();
  };

  const onRefreshSession = async (hideSuccessMessage: boolean = false) => {
    //This is to handle the case when user is on the same domain but the session is already refreshed;
    const userLatestActivity = getLocalStorageValue('userLatestActivity');
    const sessionExpiration = getLocalStorageValue('sessionExpiration');
    const now = moment().valueOf();
    if (
      userLatestActivity &&
      userLatestActivity < sessionExpiration &&
      now - userLatestActivity <= 120000 &&
      sessionExpiration - now > 120000
    ) {
      setShowOverlay(false);
      clearTimeout(timeoutId);
      setCountdown(120);
      timeoutId = setTimeout(() => {
        setShowOverlay(true);
      }, timeoutDurationRefs.current);
      return;
    }

    try {
      const res = await userService.refreshUserSession();
      if (res && res.result === 'success') {
        setShowOverlay(false);
        clearTimeout(timeoutId);
        setCountdown(120);
        !hideSuccessMessage && alertSuccess('Session refreshed successfully');
        setLocalStorageValue('sessionExpiration', res.expired);
        timeoutId = setTimeout(() => {
          setShowOverlay(true);
        }, timeoutDurationRefs.current);
      }
    } catch (error) {
      alertError('Error refreshing the session');
      console.log(error);
      signOut('/sign-in?error=Session timed out.');
    }
  };

  return (
    <ModalOverlay
      show={showOverlay}
      title={'Session Timeout'}
      isLoading={false}
      onSubmit={onLogoutHandler}
      onCancel={onRefreshSession}
      submitButtonLabel={'Log out'}
      cancelButtonLabel={`I'm here`}
      hideCancelButton={countdown === 0 ? true : false}
      customClassName={`${'alert-session-wrapper'}`}
      centered={false}
    >
      <div className='session-timeout-container'>
        <div className='session-title'>{overlayDetails.current.title} </div>
        <div className='session-description'>
          {overlayDetails.current.showCountdown && (
            <div className='session-icon'>
              <TimerIcon />{' '}
            </div>
          )}
          <div className='description-wrapper'>
            {overlayDetails.current.description}{' '}
            {overlayDetails.current.showCountdown && (
              <span className='timer'>
                {Math.floor(countdown / 60)}:
                {countdown % 60 <= 9 ? '0' + (countdown % 60) : countdown % 60}
              </span>
            )}
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  logout: userActions.logout,
  signOut: userActions.signOut,
};
export default connect(null, mapDispatchToProps)(UserTimeoutOverlay);

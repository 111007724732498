import './CheckIcon.scss';
import CheckIconFree from '../../../assets/icons/CheckIconFree.svg';
import CheckIconFreeDark from '../../../assets/icons/CheckIconFreeDark.svg';
import CheckIconStarter from '../../../assets/icons/CheckIconStarter.svg';
import CheckIconStarterDark from '../../../assets/icons/CheckIconStarterDark.svg';
import CheckIconPro from '../../../assets/icons/CheckIcon.pro.svg';
import CheckIconProDark from '../../../assets/icons/CheckIconProDark.svg';
import CheckIconPremium from '../../../assets/icons/CheckIconPremium.svg';
import CheckIconPremiumDark from '../../../assets/icons/CheckIconPremiumDark.svg';
import CheckIconProfessionalLight from '../../../assets/icons/ProfessionalApiCheck.svg';
import CheckIconProfessionalDark from '../../../assets/icons/ProfessionalApiCheckDark.svg';
import { PRICING_PLANS } from '../types';

import { ThemeModes } from '../../../constants';

import React, { useContext } from 'react';
import ThemeContext from '../../../context/ThemeContext';

export interface CheckIconProps {
  planType: PRICING_PLANS;
}

const CheckIcon = ({ planType }: CheckIconProps) => {
  const { themeName } = useContext(ThemeContext);
  const setSrc = (planType: string) => {
    switch (planType) {
      case PRICING_PLANS.STARTER: {
        return themeName === ThemeModes.LIGHT ? CheckIconStarter : CheckIconStarterDark;
      }
      case PRICING_PLANS.PREMIUM: {
        return themeName === ThemeModes.LIGHT ? CheckIconPremium : CheckIconPremiumDark;
      }
      case PRICING_PLANS.PROGESSIONAL_API: {
        return themeName === ThemeModes.LIGHT
          ? CheckIconProfessionalLight
          : CheckIconProfessionalDark;
      }
      default: {
        return themeName === ThemeModes.LIGHT ? CheckIconFree : CheckIconFreeDark;
      }
    }
  };
  const src = setSrc(planType);

  return (
    <img src={src} alt={`${planType}-check-icon-${themeName}`} className={`pricing-check-icon`} />
  );
};

export default CheckIcon;

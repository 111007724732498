import React from 'react';
import { AlertActionsTypes, DashBoardDto, IAppDispatchProps } from '../../constants';
import { SCANTYPE } from '../Checkphish/AllScans/scanType.enum';
import { ITags } from '../Common/Tags/Tags';
import { IOption, IOptionGroup } from '../Common/DropdownMultiSelect';

export const IScanLocations = {
  US: {
    label: 'United States',
    value: 'US',
    isDefault: true,
  },
  FRANCE: {
    label: 'France',
    value: 'FR',
  },
  AUSTRALIA: {
    label: 'Australia',
    value: 'AUR',
  },
};

export const BULK_SCAN_TABLE_ID = 'bulk-scan-table';

export interface ILinkStateProp {
  user: DashBoardDto;
  usersList?: any;
  providedTags?: any;
  match: any;
  selectedTheme: any;
  modifyCustomTag: any;
}

export interface ILinkDispatchProps {
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

export type Props = ILinkStateProp & ILinkDispatchProps & IAppDispatchProps;

export type TScanType = 'quick' | 'full' | string;

export interface IBulkScanState {
  isLoading: boolean;
  scanType: TScanType;
  scanLevel: SCANTYPE;
  api_key: string;
  data: any[];
  total: number;
  selectedItems: BulkScanSelectedItem[];
  disputeDispositionModalShown: boolean;
  takedownRequestModalShown: boolean;
  allUserAgents: any;
  refreshOnTag: boolean;
  isLayoutChanged: boolean;
  isBulkscanLoading: boolean;
  teamView: boolean;
  displayLayout: boolean;
  isTorChecked: boolean;
}

export interface BulkScanSelectedItem {
  brandDisplayName: string;
  brandId: string;
  brandLogoDetected: boolean;
  createdTs: number;
  disposition: string;
  final_category: string;
  firstSeen: string;
  imageUrl: string;
  ipAddress: string;
  isBulkscan: boolean;
  isLocationBasedEnabled: boolean;
  isNewAdded: boolean;
  lastScanned: number;
  logoDetectedCount: string;
  networkOwner: string;
  rescanDomain: unknown;
  scanLocation: string;
  status: string;
  tags: ITags[];
  takedowns: number;
  timestamp: number;
  url: string;
  urlAndTimestamp: string;
  urlSha256: string;
  url_sha256: string;
  userAgent: string;
  wait: string;
  _index: string;
}

export const SCAN_LIMIT = 10;
export const MIN_WAIT = 5000;
export const MAX_WAIT = 60000;
export const STEP_SIZE = 5000;
export const DEFAULT_WAIT = MIN_WAIT - STEP_SIZE;

export interface IScanLocation extends IOption {
  continentCode: string;
  continentName: string;
}

export interface IBulkScanFormProps {
  isLoading: boolean;
  submitScans: (scanInfo: any) => void;
  user: DashBoardDto;
  allUserAgents: any;
  isTorChecked: boolean;
}

export interface IBulkScanFormState {
  urls: string[];
  selectedScanLocations: IOption[];
  locationOptions: IOptionGroup[];
  wait: number;
  selectedUserAgent: string;
  renderTimestamp: number;
}

export interface BFScanLocation {
  label: string;
  value: string;
  continentCode?: string;
  continentName?: string;
  isDefault: boolean;
}

export interface BFOptionGroup {
  label: string;
  value: string;
  options: BFScanLocation[];
}

export interface ScanLocation {
  locationCode: string;
  locationName: string;
  locationType: string;
  continent: {
    continentCode: string;
    continentName: string;
  };
  isDefault: boolean;
}

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './dashboard.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrandType, covertToMillion, numberWithCommas } from '../../constants';
import DashboardService from '../../services/dashboard.service';

interface IComponentProps {
  brandType: BrandType;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IComponentState {
  company: string;
  totalSites: number;
  phishingSites: number;
  nameUrls: number;
  typoSquattingUrls: number;
  searches: number;
  totalApi: number;
  customerName: string;
  api: number;
  bulkScans: number;
  brands: number;
  countries: number;
  isLoading: boolean;
}

class DashboardMonitoring extends React.Component<IComponentProps, IComponentState> {
  private readonly dashboardService: DashboardService;
  private brandName = '';
  private _isMounted = false;
  static defaultProps = {};

  constructor(props: any) {
    super(props);
    this.state = {
      company: ' ',
      totalSites: 0,
      phishingSites: 0,
      nameUrls: 0,
      typoSquattingUrls: 0,
      searches: 0,
      isLoading: false,
      totalApi: 0,
      customerName: '',
      api: 0,
      bulkScans: 0,
      brands: 0,
      countries: 0,
    };
    this.dashboardService = new DashboardService();
  }

  componentDidMount() {
    this._isMounted = true;
    this.getMonitoringData();
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  componentDidUpdate(prevProps: IComponentProps): void {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.getMonitoringData();
    }
  }

  getBrandName = () => {
    return this.dashboardService.getBrandInfo(this.props.brandType).then((info: any) => {
      this.brandName = info.brand.brandName;
    });
  };

  getMonitoringData = () => {
    const { brandType, startDate, endDate } = this.props;
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    const fetchMonitoring = () => {
      this.dashboardService.getDataForMonitoring(query, brandType).then((res: any) => {
        if (brandType === 'non_brand') {
          res = res.result;
        }

        this.setCompState({
          company: this.brandName,
          totalSites: covertToMillion(res.totalMonitoredSites),
          phishingSites: numberWithCommas(res.pastPhishingSitesForBrand),
          nameUrls: numberWithCommas(res.pastSuspiciousSitesForBrand),
          typoSquattingUrls: numberWithCommas(res.squattingUrlCountForBrand),
          searches: numberWithCommas(res.searchEngineScanCountForBrand),
          totalApi: covertToMillion(res.totalApiCalls),
          customerName: res.customerName,
          api: covertToMillion(res.apiCalls),
          bulkScans: covertToMillion(res.bulkScanNumber),
          brands: numberWithCommas(res.targetedBrand),
          countries: numberWithCommas(res.originatingCountriesNumber),
        });
      });
    };
    if (this.brandName) {
      fetchMonitoring();
    } else {
      this.getBrandName().then(fetchMonitoring);
    }
  };

  renderItem = (label: string, tooltip: string, value: number | string, sm = 6, dom?: any) => {
    return (
      <Col className='monitoring-widget-item flex-wrap' xs={sm} sm={2} md={2} lg={2}>
        <div className='monitoring-widget-item-inner'>
          <OverlayTrigger
            placement={'bottom'}
            overlay={
              <Tooltip id={'tooltip-' + label} className={'table-source-url-tooltip'}>
                {tooltip}
              </Tooltip>
            }
          >
            <div className='monitoring-widget-item-label long-text-ellipsis-1'>{label}:</div>
          </OverlayTrigger>
          {dom ? dom : <div className='monitoring-widget-item-value'>{value}</div>}
        </div>
      </Col>
    );
  };

  render() {
    const { brandType } = this.props;
    const {
      totalSites,
      phishingSites,
      nameUrls,
      typoSquattingUrls,
      searches,
      totalApi,
      customerName,
      api,
      bulkScans,
      brands,
      countries,
    } = this.state;

    if (brandType === 'non_brand') {
      return (
        <Row className='dashboard-monitoring-widget'>
          {this.renderItem('Total API Calls', 'Number of globally API calls', totalApi)}
          {this.renderItem(
            'Stats for',
            'Customer Name',
            0,
            12,
            <div className='monitoring-widget-company'>{customerName}</div>,
          )}
          {this.renderItem(`API calls`, 'API calls', api)}
          {this.renderItem(`Interactive Bulk Scans`, 'Interactive Bulk Scans', bulkScans)}
          {this.renderItem('Targeted Brands', 'Targeted Brands', brands)}
          {this.renderItem('Originating Countries', 'Originating Countries', countries)}
        </Row>
      );
    }

    return (
      <Row className='dashboard-monitoring-widget'>
        {this.renderItem(
          'Total sites monitored',
          'Number of globally monitored sites by Bolster',
          totalSites,
        )}
        {this.renderItem(
          'Sites monitored for',
          'Company name',
          0,
          12,
          <div className='monitoring-widget-company'>{customerName}</div>,
        )}
        {this.renderItem(
          `Past ${customerName} Phishing Sites`,
          'Includes any counterfeit site that we detected in the past',
          phishingSites,
        )}
        {this.renderItem(
          `URLs with ${customerName}`,
          'Includes any site that has your company in its name ',
          nameUrls,
        )}
        {this.renderItem(
          'Look-Alike Domains',
          'Includes any site that we identified as an intentionally confusing name similar to your company name',
          typoSquattingUrls,
        )}
        {this.renderItem(
          'Google/Bing searches',
          'Number of links monitored based on company-related keyword searches',
          searches,
        )}
      </Row>
    );
  }
}

export { DashboardMonitoring };

import _ from 'lodash';
import moment from 'moment';
import React, { createContext, useReducer } from 'react';
import { appConstants, generateId, ipv4RemoveTailZero } from '../constants';
import { DropdownOptionDefaultValue, IDropdownOption } from '../components/Common/Dropdown';
import { IFilter } from '../components/Common/Table/constant';
import {
  DEFAULT_FILTER_METHOD_DATE,
  DEFAULT_FILTER_METHOD_IPV4,
  DEFAULT_FILTER_METHOD_NUMBER,
  DEFAULT_FILTER_METHOD_OPTIONS,
  DEFAULT_FILTER_METHOD_STRING,
  DEFAULT_OLDER_THEN_DATE_METHOD,
  MUST_NOT_FILTER_METHOD_IPV4,
  MUST_NOT_FILTER_METHOD_OPTIONS,
  MUST_NOT_FILTER_METHOD_STRING,
} from '../components/Common/Table/filter.controller.edit.item';
import { VALUE_DELIMITER } from '../components/Common/Table/filter.date';

import {
  Playbook_Filter_Controller,
  PLAYBOOK_PROPERTY_TYPE,
} from '../components/Playbook/Common/PlaybookConstants';
import { passwordTypeValueCheckForDWPlaybook } from '../components/Playbook/playbook-util';
import { useAppDispatch } from '../helpers/hooks';
import {
  setRecentChangesDateRange,
  setRecentChangesMultiOptions,
} from '../reducers/playbook.reducer';

export const RESET_DATA = 'RESET_DATA';
export const INIT_DATA = 'INIT_DATA';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_INTERNAL_PLAYBOOK = 'UPDATE_INTERNAL_PLAYBOOK';
export const UPDATE_DATA_CLASS = 'UPDATE_DATA_CLASS';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_COLUMNS = 'UPDATE_COLUMNS';
export const UPDATE_SORT_BY = 'UPDATE_SORT_BY';
export const UPDATE_SORT_DIRECTION = 'UPDATE_SORT_DIRECTION';
export const UPDATE_OUTPUT_TYPE = 'UPDATE_OUTPUT_TYPE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_CONNECTOR = 'UPDATE_CONNECTOR';
export const UPDATE_CONNECTOR_FIELD = 'UPDATE_CONNECTOR_FIELD';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE';
export const CHECKBOX_FILTER_ARRAY = 'CHECKBOX_FILTER_ARRAY';
const stepsDefaultArray: any = [];
const defaultFiltersArray: any = [];
const dataAttributesType: any = '';
export const playbookDefaultValue = {
  id: '',
  name: '',
  description: '',
  isActive: true,
  timezone: moment.tz.guess(),
  createdBy: {
    first_name: '',
    last_name: '',
    id: '',
  },
  attributes: [],
  sortBy: DropdownOptionDefaultValue,
  sortDirection: DropdownOptionDefaultValue,
  outputType: DropdownOptionDefaultValue,
  filters: defaultFiltersArray,
  hiddenFilters: {},
  connector: {
    id: '',
    label: '',
    value: '',
    fields: [
      {
        key: '',
        label: '',
        value: '',
      },
    ],
  },
  schedule: '',
  steps: stepsDefaultArray,
  scheduleDropdown: {
    date: undefined,
    time: DropdownOptionDefaultValue,
    frequency: DropdownOptionDefaultValue,
  },
  dataAttributes: dataAttributesType,
  type: '',
  playbookFilterIds: defaultFiltersArray,
  internalPlaybook: false,
};

const defaultColumns: any[] = [];

export type IPlaybook = typeof playbookDefaultValue;

type TAction = {
  type: string;
  payload: any;
};

export const PlaybookReducer = (state: IPlaybook, action: TAction) => {
  let connector, scheduleDropdown;

  switch (action.type) {
    case RESET_DATA:
    case INIT_DATA:
      return { ...state, ...action.payload.data };
    case UPDATE_NAME:
      return { ...state, name: action.payload.name };
    case UPDATE_INTERNAL_PLAYBOOK:
      return { ...state, internalPlaybook: action.payload };
    case UPDATE_DESCRIPTION:
      return { ...state, description: action.payload.description };
    case UPDATE_COLUMNS:
      return { ...state, attributes: action.payload.attributes };
    case UPDATE_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case UPDATE_SORT_DIRECTION:
      return { ...state, sortDirection: action.payload.sortDirection };
    case UPDATE_OUTPUT_TYPE:
      return { ...state, outputType: action.payload.outputType };
    case UPDATE_FILTERS:
      return { ...state, ...action.payload };
    case UPDATE_CONNECTOR:
      return { ...state, ...action.payload };
    case UPDATE_CONNECTOR_FIELD:
      connector = _.cloneDeep(state.connector);
      const index = _.findIndex(connector.fields, ['key', action.payload.key]);
      connector.fields[index].value = action.payload.value;
      return { ...state, connector };
    case UPDATE_SCHEDULE:
      scheduleDropdown = _.cloneDeep(state.scheduleDropdown);
      scheduleDropdown[action.payload.key] = action.payload.value;
      if (action.payload.key === 'frequency') {
        scheduleDropdown['time'] = DropdownOptionDefaultValue;
      }
      return { ...state, scheduleDropdown };
    case UPDATE_TIMEZONE:
      return { ...state, timezone: action.payload.timezone };
    case UPDATE_DATA_CLASS:
      return { ...state, dataAttributes: action.payload.selection };
    default:
      return state;
  }
};

const contextDefaultValue = {
  ...playbookDefaultValue,
  playbookColumns: defaultColumns,
  addPlaybookFilterIds: _.noop,
  resetData: _.noop,
  initData: _.noop,
  updateName: _.noop,
  updateInternalPlaybook: _.noop,
  updateDescription: _.noop,
  updateColumns: _.noop,
  updateSortBy: _.noop,
  updateSortDirection: _.noop,
  updateOutputType: _.noop,
  updateFilters: _.noop,
  updateConnector: _.noop,
  updateConnectorField: _.noop,
  updateSchedule: _.noop,
  updateTimezone: _.noop,
  updateDataClass: _.noop,
};

export const PlaybookContext = createContext(contextDefaultValue);

const PlaybookContextProvider = (props: any) => {
  const { value, type, dataClass, playbookFilterIds, addPlaybookFilterIds, setCurrentFilterIds } =
    props;
  const [playbookState, PlaybookDispatch] = useReducer(PlaybookReducer, value);
  const resetData = () => {
    PlaybookDispatch({
      type: RESET_DATA,
      payload: { data: playbookDefaultValue },
    });
  };
  const dispatch = useAppDispatch();

  const webFiltersMapping = {
    bolsterScanCountStart: 'bolster_scan_count',
    brandScanCountStart: 'brand_scan_count',
    takeDownCountStart: 'takedowns',
  };

  // This method is converting the BE filters to FE filters
  // TODO extract this logic to custom hook
  const initData = (data: any) => {
    const filters: IFilter[] = [];
    const hiddenFilters: Record<string, any> = {};

    const massagedDataClassAttributes = dataClass[0].attributes;
    if (!(type === appConstants.CONTENT_TYPE['WEB'])) {
      massagedDataClassAttributes.forEach((item: any) => {
        const splitBeginning = item.field.split('.');
        item.field = splitBeginning[splitBeginning.length - 1];
      });
    }

    // dateRanges
    _.forEach(data.dateRanges, (startDate, key, dateRanges) => {
      if (key === 'timezone' || key.indexOf('EndDate') !== -1 || key.indexOf('_end') !== -1) {
        return;
      }

      const dateType =
        type === appConstants.CONTENT_TYPE.DARK_WEB
          ? key.replace('_start', '')
          : key.replace('StartDate', '');

      let endDate = '';
      if (type === appConstants.CONTENT_TYPE.DARK_WEB) {
        endDate = dateRanges[dateType + '_end']; // to support the different date types
      } else {
        endDate = dateRanges[dateType + 'EndDate'];
      }
      let filterMethod = DEFAULT_FILTER_METHOD_DATE;
      let filterValue = '';
      const unit = endDate[endDate.length - 1];

      if (endDate === `now/${unit}`) {
        filterValue =
          startDate.replace('now-', '').replace(`${unit}/${unit}`, '') +
          `${VALUE_DELIMITER}${unit}`;
      } else {
        filterMethod = {
          label: 'older than',
          value: 'older',
        };
        filterValue =
          endDate.replace('now-', '').replace(`${unit}/${unit}`, '') + `${VALUE_DELIMITER}${unit}`;
      }

      const column = _.find(
        Playbook_Filter_Controller[PLAYBOOK_PROPERTY_TYPE[type]],
        c => c.dateRangeFilterForPlaybook === dateType || c.fieldForPlaybook === dateType,
      );
      if (column) {
        filters.push({
          id: generateId(10),
          filterBy: {
            label: column.header,
            value: column.accessor,
          },
          filterMethod,
          filterType: 'date',
          filterValue,
        });
      }
    });

    _.forEach(data.filters, filter => {
      let key = filter.key;
      const isMustNot = filter.isMustNot;

      if (key === 'search_term_id' && props.type !== appConstants.CONTENT_TYPE.SOCIAL) {
        key = 'search_term';
      }
      if (key === 'tags.id' && props.type !== appConstants.CONTENT_TYPE.DARK_WEB) {
        key = 'tags_label';
      }

      //Converting the tag column filter tags.id to user_tags_label for mapping column from dataClass
      if (key === 'tags.id' && props.type === appConstants.CONTENT_TYPE.DARK_WEB) {
        key = 'user_tags_label';
      }
      if ((!isMustNot && key === 'ipAddressStart') || (isMustNot && key === 'ipAddressEnd')) {
        key = 'ip';
      }
      if (key === 'risk_score_start') {
        key = 'risk_score';
      }
      if (webFiltersMapping.hasOwnProperty(key) && props.type === appConstants.CONTENT_TYPE.WEB) {
        key = webFiltersMapping[key];
      }

      if (
        key.toLowerCase().indexOf('end') !== -1 ||
        (key === 'scanSource' && filter.value.indexOf('bolster|') === 0)
      ) {
        return;
      }

      let column = _.find(
        type === appConstants.CONTENT_TYPE['WEB']
          ? Playbook_Filter_Controller[PLAYBOOK_PROPERTY_TYPE[type]]
          : massagedDataClassAttributes,
        c =>
          c.playbookFilterId === key ||
          c.fieldForPlaybook === key ||
          c.field === key ||
          c.fieldForExport === key ||
          c.accessor === key ||
          c.id === key,
      );
      if (props.type === appConstants.CONTENT_TYPE.SOCIAL) {
        const columnFinder = props.dataClass[0].attributes;
        switch (key) {
          case 'category_id':
            column = _.find(columnFinder, c => c.field === 'category_labels');
            break;
          case 'external_links':
            column = _.find(columnFinder, c => c.field === 'external_links');
            break;
          case 'is_logo_detection':
            column = _.find(columnFinder, c => c.field === 'is_logo_detection');
            break;
          case 'created_ts':
            column = _.find(columnFinder, c => c.field === 'created_ts');
            break;
          case 'metadata->>title':
            column = _.find(columnFinder, c => c.field === 'metadata.title');
            break;
          case 'origin_id':
            column = _.find(columnFinder, c => c.field === 'origin_label');
            break;
          case 'platform_id':
            column = _.find(columnFinder, c => c.field === 'platform_label');
            break;
          case 'search_term_id':
            column = _.find(columnFinder, c => c.field === 'search_term_labels');
            break;
          case 'source':
            column = _.find(columnFinder, c => c.field === 'source');
            break;
          case 'srcUrl':
            column = _.find(columnFinder, c => c.field === 'url');
            break;
          case 'status':
            column = _.find(columnFinder, c => c.field === 'status');
            break;
          case 'tags_label':
          case 'tags.id':
            column = _.find(columnFinder, c => c.field === 'tag_labels');
            break;
          case 'takedown_ts':
            column = _.find(columnFinder, c => c.field === 'takedown_ts');
            break;
          case 'active_since':
            column = _.find(columnFinder, c => c.field === 'active_since');
            break;
          default:
            break;
        }
      }

      if (key === 'srcUrl' && props.type !== 'social') {
        column = _.find(massagedDataClassAttributes, c => c.accessor === 'url');
      }

      //Hot fix will imporve in ZION-5599
      if (key === 'app_store_status' && props.type === appConstants.CONTENT_TYPE.APP_STORE) {
        column = {
          field: 'app_store_status',
          index: 'brand_analytics',
          label: 'Status',
          value: 'Status',
        };
      }

      //Converting back to tags.id and overwriting the column field for mapping column from dataClass for UI filter
      if (key === 'user_tags_label' && props.type === appConstants.CONTENT_TYPE.DARK_WEB) {
        column = {
          field: 'tags.id',
          index: 'darkweb_brand_info',
          label: 'Tags',
          value: 'Tags',
        };
      }

      //Hot fix will imporve in ZION-5599
      if (key === 'platform_id' && props.type === appConstants.CONTENT_TYPE.APP_STORE) {
        column = {
          field: 'platform_id',
          index: 'brand_analytics',
          label: 'Category',
          value: 'Category',
        };
      }

      if (!column && key.toLocaleLowerCase().indexOf('start') !== -1) {
        column = _.find(massagedDataClassAttributes, c => c.dateRangeFilterForPlaybook === key);
      }

      if (!column) {
        hiddenFilters[key] = filter;
      } else {
        let filterMethod = DEFAULT_FILTER_METHOD_STRING;
        let filterType = column.type || 'string';
        let filterValue = filter.value || '';

        switch (key) {
          case 'has_sensitive_data':
          case 'search_term':
          case 'app_store_status':
          case 'search_term_id':
          case 'status':
          case 'platform_id':
          case 'category_id':
          case 'category':
          case 'final_category':
          case 'origin_id':
          case 'brand_logo_detected':
          case 'external_links':
          case 'is_logo_detection':
          case 'subBrandId':
            filterType = 'options';
            break;
          case 'tags_label':
          case 'user_tags_label':
            filterType = 'optionsMultiSelect';
            break;
          case 'ip':
            filterType = 'ipv4';
            break;
          case 'active_since':
          case 'created_ts':
          case 'takedown_ts':
            filterType = 'date';
            break;
          case 'risk_score':
            filterType = 'number';
            break;
          case 'password_type':
            filterType = 'options';
            filterValue = passwordTypeValueCheckForDWPlaybook(filterValue);
            break;
          default:
            break;
        }

        switch (filterType) {
          case 'string':
            filterMethod = DEFAULT_FILTER_METHOD_STRING;
            if (isMustNot) {
              filterMethod = MUST_NOT_FILTER_METHOD_STRING;
            }
            if (type !== appConstants.CONTENT_TYPE.SOCIAL) {
              if (key === 'src_url') {
                filterValue = filterValue.replace(' ', ', ');
              } else {
                filterValue = filterValue.replace(/\*/g, '');
              }
            }
            break;
          case 'ipv4':
            if (!isMustNot && filter.key === 'ipAddressStart') {
              filterMethod = DEFAULT_FILTER_METHOD_IPV4;
            }
            if (isMustNot && filter.key === 'ipAddressEnd') {
              filterMethod = MUST_NOT_FILTER_METHOD_IPV4;
            }
            filterValue = ipv4RemoveTailZero(filterValue);
            break;
          case 'number':
            filterMethod = DEFAULT_FILTER_METHOD_NUMBER;
            const endValueKey =
              filter.key.indexOf('Start') !== -1
                ? filter.key.replace('Start', 'End')
                : filter.key.replace('start', 'end');
            const endValue = _.get(_.find(data.filters, { key: endValueKey }), 'value', '');
            filterValue = filterValue + ',' + endValue;
            break;
          case 'date':
            if (type === appConstants.CONTENT_TYPE.SOCIAL) {
              if (filterValue.split('.')[0] === 'gte') {
                filterMethod = DEFAULT_FILTER_METHOD_DATE;
              } else if (filterValue.split('.')[0] === 'lte') {
                filterMethod = DEFAULT_OLDER_THEN_DATE_METHOD;
              }
              filterValue = filterValue.split('.')[1];
              break;
            }
            filterMethod = DEFAULT_FILTER_METHOD_DATE;
            break;
          case 'options':
          case 'optionsMultiSelect':
            filterMethod = DEFAULT_FILTER_METHOD_OPTIONS;
            if (isMustNot) {
              filterMethod = MUST_NOT_FILTER_METHOD_OPTIONS;
            }
            break;
          default:
            break;
        }
        const filterByWeb = {
          label: column.header,
          value: column.accessor,
        };
        const filterByNotWeb = {
          label: column.label,
          value: column.field,
        };
        if (filterType === 'optionsMultiSelect') {
          const parsedFilterValue = filterValue
            .replace(/\s/g, '')
            .replace(/[\(\)]/g, '')
            .replace(/AND/gi, '&')
            .replace(/OR/gi, '|');
          const orFilterValues = parsedFilterValue.split('|');
          for (const value of orFilterValues) {
            filters.push({
              id: generateId(10),
              filterBy: type === appConstants.CONTENT_TYPE['WEB'] ? filterByWeb : filterByNotWeb,
              filterMethod,
              filterType,
              filterValue: value,
            });
          }
        } else {
          // TODO : need to refactor or remove this logic as don't know why we are splitting the filter value
          let filterValues: any = '';
          if (typeof filterValue !== 'number') {
            filterValues =
              filterValue !== '' && filterValue?.includes('|')
                ? filterValue.split('|')
                : [filterValue];
            for (const value of filterValues) {
              filters.push({
                id: generateId(10),
                filterBy: type === appConstants.CONTENT_TYPE['WEB'] ? filterByWeb : filterByNotWeb,
                filterMethod,
                filterType,
                filterValue: value,
              });
            }
          } else {
            filters.push({
              id: generateId(10),
              filterBy: type === appConstants.CONTENT_TYPE['WEB'] ? filterByWeb : filterByNotWeb,
              filterMethod,
              filterType,
              filterValue: filterValue,
            });
          }
        }
      }
    });

    //Recent Changes filter are stored in the the "Query" step
    const scheduleQueryFilter = _.filter(data.steps, steps => {
      return steps.step.key === 'QUERY';
    });

    const recentChangesFilter = _.first(scheduleQueryFilter);

    if (recentChangesFilter && recentChangesFilter.details) {
      if ('recentChanges' in recentChangesFilter.details) {
        const recentChanges = recentChangesFilter.details.recentChanges;
        if (recentChanges?.fields?.length > 0 && recentChanges?.dateRange?.length > 0) {
          filters.push({
            id: generateId(10),
            filterBy: {
              label: 'Recent Changes',
              value: 'recent_changes',
            },
            filterMethod: {
              label: 'is',
              value: 'is',
            },
            filterType: 'recentChangeDateOptions',
            filterValue: recentChanges.fields,
          });
          dispatch(setRecentChangesMultiOptions({ fields: recentChanges.fields }));
          dispatch(setRecentChangesDateRange({ dateRange: recentChanges.dateRange }));
        }
      }
    }

    const schedule = data.schedule;
    const modalData = {
      id: data.id,
      name: data.name,
      internalPlaybook: data.internalPlaybook,
      description: data.description,
      steps: data.steps,
      isActive: data.isActive,
      timezone: data.timezone,
      createdBy: data.createdBy,
      attributes: data.attributes,
      dataAttributes: data.dataAttributes,
      playbookFilterIds: data.filterIds,
      sortBy: {
        //TODO
        label: '',
        value: '',
      },
      sortDirection: {
        //TODO
        label: '',
        value: '',
      },
      outputType: {
        label: data.outputType,
        value: data.outputType,
      },
      filters,
      hiddenFilters,
      connector: data.connector,
      schedule,
      scheduleDropdown: data.scheduleDropdown,
    };
    setCurrentFilterIds(modalData.playbookFilterIds);
    PlaybookDispatch({
      type: INIT_DATA,
      payload: { data: modalData },
    });
  };

  const updateDataClass = (selection: {}) => {
    PlaybookDispatch({
      type: UPDATE_DATA_CLASS,
      payload: { selection },
    });
  };
  const updateName = (name: string) => {
    PlaybookDispatch({
      type: UPDATE_NAME,
      payload: { name },
    });
  };

  const updateDescription = (description: string) => {
    PlaybookDispatch({
      type: UPDATE_DESCRIPTION,
      payload: { description },
    });
  };

  const updateColumns = (attributes: IDropdownOption[]) => {
    PlaybookDispatch({
      type: UPDATE_COLUMNS,
      payload: { attributes },
    });
  };

  const updateSortBy = (sortBy: IDropdownOption) => {
    PlaybookDispatch({
      type: UPDATE_SORT_BY,
      payload: { sortBy },
    });
  };

  const updateSortDirection = (sortDirection: IDropdownOption) => {
    PlaybookDispatch({
      type: UPDATE_SORT_DIRECTION,
      payload: { sortDirection },
    });
  };

  const updateOutputType = (outputType: IDropdownOption) => {
    PlaybookDispatch({
      type: UPDATE_OUTPUT_TYPE,
      payload: { outputType },
    });
  };

  const updateFilters = (filters: IFilter[]) => {
    PlaybookDispatch({
      type: UPDATE_FILTERS,
      payload: { filters },
    });
  };

  const updateConnector = (connector: any) => {
    PlaybookDispatch({
      type: UPDATE_CONNECTOR,
      payload: { connector },
    });
  };

  const updateConnectorField = (key: string, value: string) => {
    PlaybookDispatch({
      type: UPDATE_CONNECTOR_FIELD,
      payload: { key, value },
    });
  };

  const updateSchedule = (key: string, value: string) => {
    PlaybookDispatch({
      type: UPDATE_SCHEDULE,
      payload: { key, value },
    });
  };
  const updateInternalPlaybook = (internalPlaybook: boolean) => {
    PlaybookDispatch({
      type: UPDATE_INTERNAL_PLAYBOOK,
      payload: internalPlaybook,
    });
  };

  const updateTimezone = (timezone: string) => {
    PlaybookDispatch({
      type: UPDATE_TIMEZONE,
      payload: { timezone },
    });
  };
  return (
    <PlaybookContext.Provider
      value={{
        ...playbookState,
        resetData,
        initData,
        updateName,
        updateDescription,
        updateColumns,
        updateSortBy,
        updateSortDirection,
        updateOutputType,
        updateFilters,
        updateConnector,
        updateConnectorField,
        updateSchedule,
        updateTimezone,
        updateDataClass,
        updateInternalPlaybook,
        playbookFilterIds,
        type,
        addPlaybookFilterIds,
      }}
    >
      {props.children}
    </PlaybookContext.Provider>
  );
};

export { PlaybookContextProvider };

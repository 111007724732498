import React from 'react';
import InProgressIcon from '../../../../../assets/icons/InProgress.svg';
import CheckmarkGreenIcon from '../../../../../assets/icons/CheckmarkGreen.svg';
import { EFindingStatus, IFindingsTableItemProps } from '../../../Types/ugc.types';

interface IScanStatusColRenderProps {
  data: IFindingsTableItemProps;
}

const ScanStatusColRender = ({ data }: IScanStatusColRenderProps) => {
  const { status } = data;
  const ScanIsReady = status === EFindingStatus.UNDER_REVIEW;
  return (
    <div className={'action-col-render-component d-flex align-items-center'}>
      <img src={ScanIsReady ? CheckmarkGreenIcon : InProgressIcon} alt={'icon'} />
      <div className='pl-2'>{ScanIsReady ? 'Ready for Approval' : 'In Progress'}</div>
    </div>
  );
};

export default ScanStatusColRender;

import React, { useContext, useEffect } from 'react';
import './LiveScanPlansSelector.scss';
import { useState } from 'react';
import { IPricingPlan } from '../types';
import { ISubsciptionPlan } from '../types';
import _ from 'lodash';
import { generatePrice } from '../helpers';
import {
  ICheckPhishSubscription,
  initialState,
  CheckphishSubscriptionContext,
} from '../../../context/CheckPhishContactUsContext';
import { EPlanProduct } from '../../Common/Subscription';
import { convertToInternationalFormat } from '../helpers';

export interface liveScanPlanSelectorProps {
  liveScanBundles: number[];
  liveScanPlan: IPricingPlan;
  onSliderChange?: (sliderIndex: number) => any;
  initialValue?: number;
}

const LiveScanPlansSelector = ({
  liveScanBundles,
  onSliderChange,
  liveScanPlan,
}: liveScanPlanSelectorProps) => {
  const [sliderIndex, setSliderIndex] = useState<number>(1);
  const { currentLiveScanSubscription, selectedPlans } = useContext<ICheckPhishSubscription>(
    CheckphishSubscriptionContext,
  );
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (_.isEmpty(selectedPlans) || !firstLoad) return;
    const selectedliveScanPlan = selectedPlans.find(
      (item: ISubsciptionPlan) => item.product === EPlanProduct.LIVE_SCAN,
    );
    if (selectedliveScanPlan) {
      const index = liveScanBundles.indexOf(selectedliveScanPlan?.quantity);
      if (index !== -1) {
        setSliderIndex(index + 1);
        setFirstLoad(false);
      }
    }
  }, [selectedPlans]);

  const onChange = (event: any) => {
    setSliderIndex(event.target.value);
  };

  useEffect(() => {
    if (onSliderChange) {
      onSliderChange(sliderIndex);
    }
  }, [sliderIndex]);

  return (
    <div className='plans-slider-wrap' data-testid='plans-slider-wrap'>
      <div className='price-wrap'>
        {currentLiveScanSubscription?.quantity === liveScanBundles[sliderIndex - 1] ? (
          <h4 className='price-data'>Current Plan</h4>
        ) : (
          <>
            <h4 className='price-data'>
              $ {' ' + generatePrice(liveScanPlan, liveScanBundles[sliderIndex - 1])}
            </h4>
            <span className='subtext'>{`Billed ${liveScanPlan?.term}`}</span>
          </>
        )}
      </div>
      <input
        onChange={onChange}
        type='range'
        min={1}
        max={liveScanBundles.length}
        className='plans-range'
        id='plansRange'
        name='range'
        data-testid='price-slider'
        value={sliderIndex}
      />
      <div className='plan-slide-ridge-wrap'>
        {liveScanBundles.map((item: number, index: number) => {
          return (
            <span
              key={index}
              className={`plan-slide-ridge rod-${index + 1}`}
              style={{ position: 'initial' }}
              data-testid='range-points'
            >
              &nbsp;
            </span>
          );
        })}
      </div>
      <div className='plan-details'>
        <h5>
          {liveScanBundles[sliderIndex - 1]}
          {' Scans / Day'}
        </h5>
        <h5>
          {liveScanPlan?.term === 'yearly' ? (
            <>
              {convertToInternationalFormat(liveScanBundles[sliderIndex - 1], 365)}
              {' Scans / Year'}
            </>
          ) : (
            <>
              {convertToInternationalFormat(liveScanBundles[sliderIndex - 1], 30)}
              {' Scans / Month'}
            </>
          )}
        </h5>
      </div>
    </div>
  );
};

export default LiveScanPlansSelector;

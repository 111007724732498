import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  FilterChangedEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import {
  DarkWebTableApiV2Params,
  convertToFetchDarkWebApiParams,
  getAgGridThemeClassName,
} from './ag-utils';
import { fetchDarkWebData } from './ag-requests';
import ThemeContext from '../../../../context/ThemeContext';
import { appConstants, getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';
import { generateDarkWebFlatTableColumnDefs } from './ag-col-defs';
import { ITableApiColumn } from '../table.api';
import { DARK_WEB_THREAT_ACTOR_TABLE_ID } from '../../../DarkWeb/DarkWebConstants';
import DarkWebService from '../../../../services/darkWeb.service';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import {
  setCurrentRowIndex,
  setIsPersistentFilterSaveButtonVisible,
  setOutGoingPersistentFilterString,
} from '../../../../reducers/table.reducer';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { useSearchTermFilters } from './useSearchTermFilters';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import useOnCustomTagActions from '../../CustomHooks/useOnCustomTagActions';
import useOnTagFilterOptions from '../../CustomHooks/useOnTagFilterOptions';
import { useAgGridEvents } from './useAgGridEvents';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { TABLE_EMPTY_RESULTS_MESSAGE, paginationPageSizeSelector } from './constants';
import { alertActions } from '../../../../actions';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import useOnAgRowHighlight from './useOnAgRowHighlight';
import { useAgGridCurrentPageRows } from './useAgGridCurrentPageRows';
import { useOnSelectionChangeEvent } from './useOnSelectionChangeEvent';
import { useUpdateTagsFromBulkAction } from './useUpdateTagsFromBulkAction';
import useOnAgCellClicked from './useOnAgCellClicked';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const AgGridDarkWebFlatTable = ({
  columns,
  darkwebStatus,
  widgetDetails,
  extraFilters,
}: {
  columns: ITableApiColumn[];
  darkwebStatus?: EDarkWebFindingStatus;
  widgetDetails?: any;
  extraFilters?: DarkWebTableApiV2Params;
}) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const agRef = useRef<AgGridReact>(null);
  const { selectedTheme } = useContext(ThemeContext);
  const { onTableSaveColumns, onItemCheck, tableId, displayedColumnsIDs } =
    useContext(TableContext);
  const dispatch = useAppDispatch();
  const [totalNumOfNodes, setTotalNumOfNodes] = useState<number>(0);

  const isThreatActorTable = useMemo<boolean>(() => {
    return tableId === DARK_WEB_THREAT_ACTOR_TABLE_ID;
  }, [tableId]);

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  useSearchTermFilters({ agRef, columns, colFieldForSearchTerm: 'search_term' });

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({
      tableId: tableId ?? '',
    });

  const colDefs = useMemo<ColDef[]>(() => {
    return generateDarkWebFlatTableColumnDefs(
      columns,
      firstRenderDisplayColumnIdSet,
      isThreatActorTable,
    );
  }, [columns, isThreatActorTable, displayedColumnsIDs]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: !isThreatActorTable,
      menuTabs: ['columnsMenuTab'],
      suppressHeaderMenuButton: isThreatActorTable ? true : false,
      mainMenuItems: columnHeaderMenuItems,
    };
  }, [isThreatActorTable]);

  const isFilterModelCacheUsed = useRef(false);
  const darkWebService = useMemo(() => new DarkWebService(), []);

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const searchTermIds: string[] = [];
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[]; total?: number } = { data: [], total: 0 };
        if (widgetDetails && widgetDetails?.searchterms) {
          Object.keys(widgetDetails.searchterms).map(k => {
            searchTermIds.push(widgetDetails?.searchterms?.[k]?.searchTermId);
          });
        }

        const limit = params.api?.paginationGetPageSize();
        const apiParams = convertToFetchDarkWebApiParams(
          agRequest,
          limit,
          darkwebStatus,
          widgetDetails && extraFilters
            ? {
                ...(extraFilters && extraFilters),
                searchTerms: Object.keys(widgetDetails?.searchterms),
                searchTermIds,
              }
            : {},
        );
        try {
          if (!isThreatActorTable) {
            const rawResponse = await fetchDarkWebData(apiParams, true);
            response.data = rawResponse.findings || [];
            response.total = rawResponse?.metadata?.total || 0;
          } else {
            const rawResponse = await darkWebService.getThreatActors();
            response.data = rawResponse || [];
            response.total = rawResponse?.length || 0;
          }
          if (response.total === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }
          setTimeout(() => {
            params.success({
              rowData: response.data,
              rowCount: response.total,
            });
          }, 0);
          setRowData(response.data);
          setTotalNumOfNodes(response.total as number);
        } catch (error) {
          console.log(error);
          params.fail();
        }
      },
    };
  }, [darkWebService, isThreatActorTable]);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      // first render, only initiate filter model from local storage.
      const filterModelCache = tableId ? getLocalStorageValue(['tableFilterModel', tableId]) : {};
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource],
  );

  const getRowId = useCallback(
    (params: GetRowIdParams) => {
      return !isThreatActorTable
        ? `row-id-${params.data.sha256}`
        : `row-id-${params.data.threat_actor}`;
    },
    [isThreatActorTable],
  );

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId: tableId ?? '',
    allRowData: rowData,
  });

  useEffect(() => {
    setLocalStorageValue(['savedSelectionState', tableId ?? ''], []);
  }, []);

  const {
    handleFirstDataRendered,
    handleOnPaginationChanged,
    currentPageNumber,
    currentPaginationSize,
  } = usePersistentTablePageNumber(tableId || '');

  const { currentRowsOfPage } = useAgGridCurrentPageRows({
    agRef,
    allRowData: rowData,
    currentPageNumber,
    currentPaginationSize,
  });

  const { handleOnSelectionChange } = useOnSelectionChangeEvent({
    agRef,
    tableId: tableId ?? '',
    allRowData: rowData,
    moduleType: appConstants.CONTENT_TYPE.DARK_WEB,
    currentRowsOfPage,
    currentPageNumber,
    currentPaginationSize,
  });

  const { updateTagsFromBulkAction } = useUpdateTagsFromBulkAction({ agRef });

  useEffect(() => {
    updateTagsFromBulkAction();
  }, [updateTagsFromBulkAction]);

  const handleFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      // console.log('===handleFilterChanged:', event);
      const filterModel = event.api.getFilterModel();
      const filterModelIsNotEmpty = Object.keys(filterModel).length !== 0;
      const isAgGridDateRangeFilter = event?.columns[0]?.getColDef()?.filter?.name ? true : false;
      // from dashboard, or user interacted with filter, show the button
      const shouldShowSaveButton =
        (event.source !== 'api' && filterModelIsNotEmpty) ||
        (isAgGridDateRangeFilter && filterModelIsNotEmpty);
      dispatch(setIsPersistentFilterSaveButtonVisible(shouldShowSaveButton));
      dispatch(setOutGoingPersistentFilterString(JSON.stringify(filterModel)));
      if (tableId) {
        setLocalStorageValue(['tableFilterModel', tableId], filterModel);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  //Custom hooks for triggering fetch call on custom tag action
  useOnCustomTagActions(agRef);

  const currentRowIndex = useAppSelector(state => state.tableReducer.currentRowIndex);
  const { onCellClicked } = useOnAgCellClicked();

  //custom hook to add new custom tag filter options
  useOnTagFilterOptions(agRef, columns, 'tags.id', appConstants.CONTENT_TYPE.DARK_WEB);

  //Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(setIsPersistentFilterSaveButtonVisible(false));
      dispatch(setOutGoingPersistentFilterString('{}'));
      setLocalStorageValue(['savedSelectionState', tableId ?? ''], []);
      dispatch(setCurrentRowIndex(-1));
    };
  }, [dispatch, tableId]);

  const { getRowClass } = useOnAgRowHighlight();

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme)}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={paginationPageSizeSelector}
        cacheBlockSize={currentPaginationSize}
        blockLoadDebounceMillis={1000}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={handleOnSelectionChange}
        onFilterChanged={handleFilterChanged}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        getRowClass={getRowClass}
        suppressRowVirtualisation={true}
        onCellClicked={onCellClicked}
        context={{
          cellClickedIndex: currentRowIndex,
        }}
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default AgGridDarkWebFlatTable;

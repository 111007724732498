import {
  NextPageDisabledIcon,
  NextPageIcon,
  PrevPageDisabledIcon,
  PrevPageIcon,
} from '../../assets/SVGIcons';
import React, { useEffect, useState } from 'react';

export interface IPagerProps {
  page: number;
  pageSize: number;
  totalCount: number;
  onPageChange: (page: number) => void;
}

export function Pager({ page, pageSize, totalCount, onPageChange }: IPagerProps) {
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setTotalPages(Math.max(Math.ceil(totalCount / pageSize), 1));
  }, [pageSize, totalCount]);

  return (
    <div className={'d-flex justify-content-center pager-container'}>
      {page === 1 ? (
        <div className={'icon-disabled'}>
          <PrevPageDisabledIcon color='black' />
        </div>
      ) : (
        <div
          onClick={() => {
            onPageChange(page - 1);
          }}
        >
          <PrevPageIcon color='black' />
        </div>
      )}

      {page >= totalPages ? (
        <div className={'icon-disabled'}>
          <NextPageDisabledIcon color='black' />
        </div>
      ) : (
        <div
          onClick={() => {
            onPageChange(page + 1);
          }}
        >
          <NextPageIcon color='black' />
        </div>
      )}
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import '../../Style/FindingTablesCategoryFilter.scss';
import DarkWebService from '../../../../services/darkWeb.service';
import { ICustomFilterSystemProps, IFilter } from '../../../Common/Table/constant';
import { initialCategoryFilter } from '../Dashboard/BubbleCategoryChart';
import { EDarkWebFindingStatus, EDarkWebRiskLevel } from '../Types/DarkWeb.types';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { BellPlusIcon } from '../../../../assets/SVGIcons';
import SetUpNotificationModal from './SetUpNotificationModal';
import { AppState } from '../../../../helpers';
import { connect } from 'react-redux';
import { dashboardActions } from '../../../../actions';
import { appConstants, DashBoardDto, featureIsAvailable } from '../../../../constants';
interface ICategoryFilter {
  category: string;
  total: number;
  risk: EDarkWebRiskLevel;
}
interface IFindingTablesCategoryFilter {
  status: EDarkWebFindingStatus;
  brandInfo: any;
  user: DashBoardDto;
}
const darkWebService = new DarkWebService();

function FindingTablesCategoryFilter({
  user,
  status,
  onFiltersApplied,
  appliedFilters,
  brandInfo,
}: IFindingTablesCategoryFilter & ICustomFilterSystemProps) {
  const allCategoriesTotal = useRef(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categoryFilters, setCategoryFilters] = useState<ICategoryFilter[]>([]);
  const currentNativeFilters = useRef<IFilter[]>(appliedFilters);

  useEffect(() => {
    fetchAndSetCategoryFilters();
  }, []);

  useEffect(() => {
    const nativeFilters = _.filter(appliedFilters, f => !f.isCustomFilter);
    if (JSON.stringify(currentNativeFilters.current) !== JSON.stringify(nativeFilters)) {
      fetchAndSetCategoryFilters();
      currentNativeFilters.current = nativeFilters;
    }
  }, [appliedFilters]);

  const fetchAndSetCategoryFilters = () => {
    setIsLoading(true);
    const nativeFilters = _.filter(appliedFilters, f => !f.isCustomFilter);
    darkWebService.getDarkWebCategory(nativeFilters).then(res => {
      setIsLoading(false);
      const filters: ICategoryFilter[] = [];
      if (res.length === 0) {
        setCategoryFilters([]);
        return;
      }
      const categoryByStatus = _.find(res, ['status', status]);
      if (!categoryByStatus) {
        return;
      }
      allCategoriesTotal.current = 0;
      _.forEach(categoryByStatus.categoryByRisk, riskGroup => {
        const { risk, categories } = riskGroup;
        _.forEach(categories, categoryInfo => {
          const { category, total } = categoryInfo;
          allCategoriesTotal.current += total;
          filters.push({ category, total, risk });
        });
      });
      setCategoryFilters(filters);
    });
  };

  const findCategoryFilterIndex = (category: string): number => {
    return _.findIndex(appliedFilters, { filterValue: category });
  };
  const categoryFilterHasBeenApplied = (category: string): boolean => {
    return _.some(appliedFilters, { filterValue: category });
  };

  const showSetUpNotificationModal = (e: any) => {
    e.stopPropagation();
    setSelectedCategory(e.currentTarget.dataset.category);
  };

  const hideSetUpNotificationModal = () => {
    setSelectedCategory('');
  };

  const onToggleFilter = (e: any) => {
    const { category } = e.currentTarget.dataset;
    const newAppliedFilters = _.cloneDeep(appliedFilters);
    const categoryFilterIndex = findCategoryFilterIndex(category);
    if (categoryFilterIndex === -1) {
      const filter = {
        ...initialCategoryFilter,
        filterValue: category,
      };
      onFiltersApplied([...newAppliedFilters, filter]);
    } else {
      newAppliedFilters.splice(categoryFilterIndex, 1);
      onFiltersApplied(newAppliedFilters);
    }
  };

  const customFilters = _.filter(appliedFilters, f => f.isCustomFilter);
  const filterIsApplied = customFilters.length > 0;

  return (
    <div className='finding-table-filter-by-category d-flex'>
      <LoadingWrapper isLoading={isLoading}>
        {_.map(categoryFilters, ({ category, total, risk }) => {
          const categoryIsApplied = categoryFilterHasBeenApplied(category);
          const isDarken = filterIsApplied && !categoryIsApplied;
          return (
            <div
              key={`${category}-${risk}`}
              style={{ flex: total }}
              className={`category-filter ${isDarken ? 'darken' : ''}`}
              onClick={onToggleFilter}
              data-category={category}
              data-risk={risk.toLowerCase()}
            >
              <div className='category long-text-ellipsis-2'>{category}</div>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='total'>{total}</div>

                {featureIsAvailable(user, appConstants.FEATURE_CODE.DARK_WEB_V2) &&
                  brandInfo?.brand?.brandName === 'bankofbolster' && (
                    <div data-category={category} onClick={showSetUpNotificationModal}>
                      {' '}
                      {BellPlusIcon}{' '}
                    </div>
                  )}
              </div>
            </div>
          );
        })}
        <SetUpNotificationModal
          show={selectedCategory !== ''}
          category={selectedCategory}
          onCancel={hideSetUpNotificationModal}
          onSubmit={hideSetUpNotificationModal}
        />
      </LoadingWrapper>
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  const { brandInfo, user } = state.dashboardReducer;

  return {
    brandInfo: brandInfo,
    user: user,
  };
};
const mapDispatchToProps = {
  getBrandInfo: dashboardActions.getBrandInfo,
  getUserInfo: dashboardActions.getUserInfo,
};
export default connect(mapStateToProps, mapDispatchToProps)(FindingTablesCategoryFilter);

import _ from 'lodash';
import { IFindingInsightDataProps, IScanCountInfo } from '../../../Types/ugc.types';

export const UGC_INSIGHT_INIT_DATA = {
  url: '',
  sha256: '',
  status: '',
  source: '',
  categoryLabels: '',
  searchTermLabels: '',
  createdTs: '',
  screenshot: '',
  isLogoDetection: false,
  uploads: [],
  platformLabel: '',
  originLabel: '',
  accountName: '',
  accountFollowers: '',
  accountVerified: '',
  postedTs: '',
  takedownBy: '',
  takedownTs: '',
  takedownComment: '',
  takedownUploads: [],
  logoDetection: {},
  metadata: {},
  url_sha256: '',
  ip: '',
  host: '',
  primaryDomain: '',
};
export const SCAN_COUNT_INIT_DATA = {
  brandScanCount: '',
  bolsterScanCount: '',
  scanSource: '',
};

export const massagedUgcInsightData = (finding: any, tagsData: any): IFindingInsightDataProps => {
  const { searchTermLabels, categoryLabels, sha256 } = finding;

  return {
    ...finding,
    categoryLabels: categoryLabels?.join(', ') || '',
    searchTermLabels: searchTermLabels?.join(', ') || '',
    screenshot: finding?.screenshotUrl,
    uploads: finding?.imageUrls,
    tags: tagsData.tags || [],
    url_sha256: sha256,
  };
};

export const massagedAppStoreTakedownData = (takedownDetails: any): any => {
  const massagedData: any = {};
  const appStoreTakedownHistory = [];
  appStoreTakedownHistory.push({
    user: takedownDetails?.user,
    comments: takedownDetails?.comments,
    uploads: takedownDetails?.uploads?.map((upload: any) => ({ url: upload })),
  });
  massagedData.takedownStatus = takedownDetails?.status;
  massagedData.takedownTs = takedownDetails?.takedown_ts;
  massagedData.createdTs = takedownDetails?.createdTs;
  massagedData.history = appStoreTakedownHistory;

  return massagedData;
};

export const massagedAppStoreSafelistData = (safeListDetails: any): any => {
  const massagedData: any = {};
  massagedData.comments = safeListDetails?.safeListInfo?.comments;
  massagedData.createdTs = safeListDetails?.safeListInfo?.createdTs;
  massagedData.user = safeListDetails?.safeListInfo?.user;
  return massagedData;
};

export const massagedAppStoreScanSourceData = (scanCount: any): IScanCountInfo => {
  return {
    scanSource: _.get(scanCount, ['result', 'scanSource'], '--'),
    brandScanCount: _.get(scanCount, ['result', 'brandScanCount'], '--'),
    bolsterScanCount: _.get(scanCount, ['result', 'bolsterScanCount'], '--'),
  };
};

import * as React from 'react';
import { Dropdown, IDropdownOption } from './Dropdown';
import _ from 'lodash';
import './DropdownTimezone.scss';
import moment from 'moment';

interface IDropdownTimezoneProps {
  className?: string;
  defaultValue?: string;
  onSelectTimezone?: any;
}

interface IDropdownTimezoneState {}

const timezones = [
  'US/Samoa',
  'Pacific/Midway',
  'US/Hawaii',
  'US/Alaska',
  'Canada/Pacific',
  'America/Los_Angeles',
  'America/Tijuana',
  'US/Arizona',
  'America/Chihuahua',
  'America/Mazatlan',
  'Canada/Mountain',
  'US/Central',
  'Canada/Central',
  'America/Mexico_City',
  'America/Monterrey',
  'Canada/Saskatchewan',
  'America/Bogota',
  'Canada/Eastern',
  'US/Eastern',
  'US/East-Indiana',
  'America/Lima',
  'America/Caracas',
  'America/La_Paz',
  'Canada/Newfoundland',
  'America/Santiago',
  'America/Buenos_Aires',
  'America/Montevideo',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Europe/Lisbon',
  'Europe/London',
  'Africa/Monrovia',
  'UTC',
  'Europe/Amsterdam',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Budapest',
  'Africa/Casablanca',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Ljubljana',
  'Europe/Madrid',
  'Europe/Paris',
  'Europe/Prague',
  'Europe/Rome',
  'Europe/Sarajevo',
  'Europe/Skopje',
  'Europe/Stockholm',
  'Europe/Vienna',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zurich',
  'Europe/Athens',
  'Europe/Bucharest',
  'Africa/Cairo',
  'Africa/Harare',
  'Europe/Helsinki',
  'Asia/Jerusalem',
  'Europe/Kaliningrad',
  'Europe/Riga',
  'Europe/Sofia',
  'Europe/Tallinn',
  'Europe/Vilnius',
  'Asia/Baghdad',
  'Asia/Istanbul',
  'Asia/Kuwait',
  'Europe/Minsk',
  'Europe/Moscow',
  'Africa/Nairobi',
  'Asia/Riyadh',
  'America/Indiana/Petersburg',
  'Asia/Tehran',
  'Asia/Kabul',
  'Asia/Baku',
  'Asia/Muscat',
  'Europe/Samara',
  'Asia/Tbilisi',
  'Europe/Volgograd',
  'Asia/Yerevan',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Tashkent',
  'Asia/Calcutta',
  'Asia/Kathmandu',
  'Asia/Almaty',
  'Asia/Dhaka',
  'Asia/Urumqi',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Jakarta',
  'Asia/Krasnoyarsk',
  'Asia/Novosibirsk',
  'Asia/Chongqing',
  'Hongkong',
  'Asia/Irkutsk',
  'Asia/Kuala_Lumpur',
  'Australia/Perth',
  'Singapore',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Yakutsk',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Canberra',
  'Pacific/Guam',
  'Australia/Hobart',
  'Australia/Melbourne',
  'Pacific/Port_Moresby',
  'Australia/Sydney',
  'Asia/Vladivostok',
  'Asia/Magadan',
  'Asia/Srednekolymsk',
  'Pacific/Auckland',
  'Pacific/Fiji',
  'Asia/Kamchatka',
  'Pacific/Chatham',
];

const timezoneOptions: IDropdownOption[] = _.map(timezones, timezone => ({
  value: timezone,
  label: `${timezone}, ${moment.tz(moment(), timezone).format('z, Z')}`,
}));

class DropdownTimezone extends React.Component<IDropdownTimezoneProps, IDropdownTimezoneState> {
  render() {
    const { defaultValue, onSelectTimezone, className } = this.props;
    return (
      <div className={'dropdown-timezone-picker-component ' + className}>
        <Dropdown
          boxStyle
          defaultSelection={_.find(timezoneOptions, ['value', defaultValue])}
          options={timezoneOptions}
          ordersPersist
          onChange={onSelectTimezone}
        />
      </div>
    );
  }
}

export { DropdownTimezone };

import React, { useCallback } from 'react';
import { setLocalStorageValue } from '../../../../constants';
import { FilterChangedEvent } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { REPORTS_TABLE_ID } from '../../../Reports/ReportsConstant';
import {
  BRAND_PROPERTY_TABLE_IDS,
  CONNECTOR_TABLE_ID,
  LOGOS_TABLE_ID,
  MANAGED_BY_AFFILIATED,
  MANAGED_BY_BOLSTER,
  MANAGED_BY_ORGANIZATION,
} from '../../../Assets_v2/constants';
import { GLOBAL_TAKEDOWN_TABLE_ID } from '../../../GlobalTakedown/GlobalTakedown';
import { alertActions } from '../../../../actions';
import { TABLE_EMPTY_RESULTS_MESSAGE } from './constants';
import {
  setIsPersistentFilterSaveButtonVisible,
  setOutGoingPersistentFilterString,
} from '../../../../reducers/table.reducer';
import { FINDINGS_TABLE_IDS, MALICIOUS_TABLE_IDS } from '../../../MonitorAndTakedown/constants';

export default function useAgGridFilterChange(
  agRef: any,
  tableId:
    | typeof REPORTS_TABLE_ID
    | typeof BRAND_PROPERTY_TABLE_IDS
    | typeof GLOBAL_TAKEDOWN_TABLE_ID
    | 'domain_acquisition'
    | 'searchesTerm'
    | 'user_submitted_malicious'
    | 'global_takedown_table_web'
    | typeof CONNECTOR_TABLE_ID
    | typeof MANAGED_BY_AFFILIATED
    | typeof MANAGED_BY_ORGANIZATION
    | typeof MANAGED_BY_BOLSTER
    | FINDINGS_TABLE_IDS
    | MALICIOUS_TABLE_IDS,
) {
  const dispatch = useAppDispatch();
  const { isFilterFromDashboard } = useAppSelector(state => state.tableReducer);

  const handleFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      const filterModel = event.api.getFilterModel();
      setLocalStorageValue(['tableFilterModel', tableId], filterModel);
      const rowsDisplayed = agRef?.current?.api?.getRenderedNodes() || [];
      if (rowsDisplayed.length === 0) {
        dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
      }
    },
    [dispatch, tableId],
  );

  // Use for web or server side grid modules
  const handleUGCModuleFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      const filterModel = event.api.getFilterModel();
      const filterModelIsNotEmpty = filterModel && Object.keys(filterModel).length !== 0;
      const isAgGridDateRangeFilter = event?.columns?.[0]?.getColDef()?.filter?.name ? true : false;
      // come from dashboard, or user interact with filter, show the button
      // refresh page, or user apply from persistent filter list, hide the button
      const shouldShowSaveButton =
        isFilterFromDashboard ||
        (event.source !== 'api' && filterModelIsNotEmpty) ||
        (isAgGridDateRangeFilter && filterModelIsNotEmpty);
      dispatch(setIsPersistentFilterSaveButtonVisible(shouldShowSaveButton));
      dispatch(setOutGoingPersistentFilterString(JSON.stringify(filterModel)));

      setLocalStorageValue(['tableFilterModel', tableId], filterModel);
    },
    [dispatch, isFilterFromDashboard, tableId],
  );
  return { handleFilterChanged, handleUGCModuleFilterChanged };
}

import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { colors, getColors } from '../../../../constants';
import DashboardService from '../../../../services/dashboard.service';
import {
  IChartPlaceholderProps,
  IBarChartDataProps,
  TColorMap,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import BarChart from '../../../Common/DashboardWidgets/BarChart';
import NoHosting from '../../../../assets/icons/NoHosting.svg';
import ThemeContext from '../../../../context/ThemeContext';
import { Nullable } from '../../../../types/common';

const dashboardService = new DashboardService();

const LIVE_LABEL = 'Sites still live';
const DOWN_LABEL = 'Sites taken down';
const LABEL_MAP = {
  live: LIVE_LABEL,
  down: DOWN_LABEL,
};

const convert2BarChartDataFormat = (rawData: any): IBarChartDataProps[] => {
  const result: IBarChartDataProps[] = [];
  _.forEach(rawData, function (item, label) {
    if (!['undefined', 'null', 'Unknown'].includes(label)) {
      const categories: { label: string; count: number }[] = [];
      let total = 0;
      _.forEach(item, (count, key) => {
        total += count;
        categories.push({
          label: LABEL_MAP[key],
          count,
        });
      });
      result.push({
        label,
        total,
        categories,
      });
    }
  });
  return result;
};

const placeholder: IChartPlaceholderProps = {
  icon: NoHosting,
  title: 'No hosting data found',
  description:
    'We haven’t detected any phish and scam site hosting for this period. Please select another time frame.',
};

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  activeOUId?: Nullable<number>;
}

function DashboardHosting({ startDate, endDate, activeOUId }: IComponentProps) {
  const { selectedTheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IBarChartDataProps[]>([]);
  const colorMap: TColorMap = {
    [LIVE_LABEL]: getColors(selectedTheme).live,
    [DOWN_LABEL]: getColors(selectedTheme).takedown,
  };

  const getDetectionData = () => {
    setIsLoading(true);
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    if (activeOUId) {
      query['ouId'] = activeOUId;
    }
    dashboardService.getSitesByStatusAndHosting(query).then((res: any) => {
      const networkOwnerStatusMap = res.result.networkOwnerStatusMap;
      setData(convert2BarChartDataFormat(networkOwnerStatusMap));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getDetectionData();
  }, [startDate, endDate, activeOUId]);

  return (
    <BarChart
      title='Top 10 Phish and Scam Site Hosting'
      isLoading={isLoading}
      description='New Phish and Scam sites detected by hosting provider'
      data={data}
      colorMap={colorMap}
      placeholder={placeholder}
      yAxisTitle='Pages'
    />
  );
}

export { DashboardHosting };

import React from 'react';
import moment from 'moment';
import { Dropdown, IDropdownOption } from './Dropdown';
import './DropdownTimePicker.scss';

const TIME_OPTIONS: IDropdownOption[] = [];
for (let timeValue = 0; timeValue < 24 * 60 * 60 * 1000; timeValue += 15 * 60 * 1000) {
  TIME_OPTIONS.push({
    label: moment.utc(timeValue).format('hh:mm A'),
    value: JSON.stringify(timeValue),
  });
}

interface IDropdownTimePickerProps {
  defaultSelection?: IDropdownOption;
  className?: string;
  disabled?: boolean;
  onChange: (selection: IDropdownOption) => void;
}

const DropdownTimePicker = ({
  defaultSelection,
  className,
  disabled,
  onChange,
}: IDropdownTimePickerProps) => {
  return (
    <div className={'dropdown-time-picker-component ' + className}>
      <Dropdown
        boxStyle
        disabled={disabled}
        btnClassName={'w-100'}
        defaultSelection={defaultSelection}
        options={TIME_OPTIONS}
        ordersPersist
        onChange={onChange}
        emptyText={'Time'}
      />
    </div>
  );
};

export { DropdownTimePicker };

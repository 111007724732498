import React, { useCallback, useContext, useEffect } from 'react';
import ModalOverlay from '../ModalOverlay';
import {
  ICustomTagCount,
  deleteCustomTag,
  fetchAllPlatformTags,
  getTagsFindingCounts,
} from './tag-request';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import PillLabel from '../PillLabel';
import './DeleteCustomTagsModal.scss';
import { getTagsStyle } from './util';
import ThemeContext from '../../../context/ThemeContext';
import { setAllPlatformTags, setModifyCustomTag } from '../../../reducers/tags.reducer';
import { alertActions } from '../../../actions';
import { LoadingWrapper } from '../LoadingWrapper';
export default function DeleteCustomTagsModal() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [moduleCounts, setModuleCounts] = React.useState<ICustomTagCount>({
    status: '',
    webCount: 0,
    appCount: 0,
    bulkScanCount: 0,
    darkWebCount: 0,
    socialCount: 0,
  });
  const [isFindingLoaded, setIsFindingLoaded] = React.useState<boolean>(false);
  const totalCounts = React.useRef<number>(0);

  const { selectedTheme } = useContext(ThemeContext);

  const dispatch = useAppDispatch();
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);

  useEffect(() => {
    try {
      setIsFindingLoaded(true);

      const fetchTagsColors = async () => {
        const selectedTag = modifyCustomTag.selectedTag;
        if (!selectedTag) return;

        const response = await getTagsFindingCounts(selectedTag.id);
        if (response) {
          setModuleCounts(response);
          totalCounts.current =
            response.webCount +
            response.appCount +
            response.bulkScanCount +
            response.darkWebCount +
            response.socialCount;
        }
        setIsFindingLoaded(false);
      };
      void fetchTagsColors();
    } catch (error) {
      dispatch(alertActions.error('Error fetching tags counts'));
      setIsFindingLoaded(false);
    }
  }, [dispatch, modifyCustomTag.selectedTag]);

  const onCancel = useCallback(() => {
    dispatch(
      setModifyCustomTag({
        isDeleteMode: false,
        selectedTag: null,
        isEditMode: false,
        triggerFetchCall: false,
      }),
    );
  }, [dispatch]);

  const onModalSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!modifyCustomTag.selectedTag) return;

      const response = await deleteCustomTag(modifyCustomTag.selectedTag?.id);
      if (response) {
        dispatch(alertActions.success('Tag deleted successfully'));
        const tags = await fetchAllPlatformTags();
        dispatch(setAllPlatformTags(tags));
      }
    } catch (error: any) {
      if (error) {
        dispatch(alertActions.error(error?.message || 'Tag deletion failed'));
      }
    } finally {
      setIsLoading(false);
      dispatch(
        setModifyCustomTag({
          isDeleteMode: false,
          selectedTag: null,
          isEditMode: false,
          triggerFetchCall: true,
        }),
      );
    }
  }, []);

  return (
    <div>
      <ModalOverlay
        show={modifyCustomTag.isDeleteMode}
        isLoading={isLoading}
        title={'Delete Tag'}
        onCancel={onCancel}
        onSubmit={onModalSubmit}
        cancelButtonLabel='Cancel'
        submitButtonLabel={'Delete'}
      >
        <LoadingWrapper isLoading={isFindingLoaded}>
          <div className='delete-custom-tag-wrapper'>
            <div className='delete-custom-tag-description'>
              {totalCounts.current === 0
                ? 'You have selected to delete a tag that relates to no findings.'
                : `You have selected to delete a tag associated with ${moduleCounts.webCount} findings in Web module, ${moduleCounts.socialCount} in Social Media, ${moduleCounts.darkWebCount} in Dark Web, and ${moduleCounts.bulkScanCount} in Bulk Scan.`}
            </div>
            <div className='selected-tag-wrapper'>
              {modifyCustomTag.selectedTag?.label && (
                <div>
                  <PillLabel
                    label={modifyCustomTag.selectedTag?.label}
                    customStyle={getTagsStyle(modifyCustomTag.selectedTag, selectedTheme)}
                  />
                </div>
              )}
            </div>
            <div className='delete-custom-tag-description'>This action can’t be undone.</div>
          </div>
        </LoadingWrapper>
      </ModalOverlay>
    </div>
  );
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';
import AddWidgetIcon from '../../../assets/icons/AddWidget.svg';
import { AppState } from '../../../helpers';
import './dashboard.scss';
import { alertActions, dashboardActions } from '../../../actions';
import DashboardHeader from './DashboardHeader';
import { IDropdownOption } from '../../Common/Dropdown';
import { ILinkStateProp, ILinkDispatchProps } from '../Types/dashboard.types';
import MonitoredDomainContainer from './MonitoredDomains/MonitoredDomainContainer';
import { WidgetsSection } from './Widgets/DashboardWidgetsSection';
import { DashboardSummary } from './Widgets/DashboardSummary';
import { appConstants, DEFAULT_DATE_RANGE_V2, featureIsAvailable } from '../../../constants';
import { MonitoredContainerV2 } from './MonitoredDomainsV2/MonitoredContainerV2';
import { DomainCount } from '../../../types/typosquat-dashboard.interface';
import { DomainCountLabelEnum } from '../../Checkphish/TypoSquattingDashboardV2/DashboardContainer';
import ActiveOUContext from '../../../context/ActiveOUContext';
import LockBanner from '../../Drawer/CheckPhishV2/LockBanner';
import { useParams } from 'react-router';
import { titleMapper } from '../../Drawer/CheckPhishV2/Constants';

type Props = ILinkStateProp & ILinkDispatchProps;

const DashboardContainer = ({
  user,
  categories,
  selectedDashboard,
  getDashboardConfig,
  scanSourceCategories,
}: Props) => {
  window.document.title = 'Dashboard | Bolster Platform';
  const isDemo = window.location.href.includes('premium') || false;
  const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
  const [monitoredDomainsForAcquisitions, setMonitoredDomainsForAcquisitions] = useState<any>(null);
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(DEFAULT_DATE_RANGE_V2, 'day'),
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const lastXDay = useRef(DEFAULT_DATE_RANGE_V2);
  const { activeOUId } = useContext(ActiveOUContext);

  const updateMonitoredDomainsForAcquisition = (monitoredDomains: DomainCount[]) => {
    const monitoredDomainsForAcquisition = monitoredDomains.filter(
      (c: any) => c.type === DomainCountLabelEnum.MONITOR_FOR_ACQUISITIONS,
    )[0];
    setMonitoredDomainsForAcquisitions(monitoredDomainsForAcquisition);
  };

  useEffect(() => {
    if (isEditingMode) {
      setIsEditingMode(false);
    } else {
      setIsEditingMode(selectedDashboard.isNew || false);
    }
  }, [selectedDashboard]);

  const toggleEditMode = () => {
    // TODO
    console.log('ON EDIT');
    setIsEditingMode(true);
  };

  const toggleDeleteDashboardModal = () => {
    // TODO
    console.log('ON DELETE');
  };

  const onSetDefaultDashboard = () => {
    // TODO
    console.log('ON DEFAULT');
  };

  const cancelEditMode = () => {
    getDashboardConfig(selectedDashboard.name);
    setIsEditingMode(false);
  };

  const onDashboardActionChange = (selection: IDropdownOption) => {
    switch (selection.value) {
      case 'edit':
        toggleEditMode();
        break;
      case 'setDefault':
        onSetDefaultDashboard();
        break;
      case 'delete':
        toggleDeleteDashboardModal();
        break;
      default:
        break;
    }
  };

  const onDateRangeChange = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    lastXDayInput: number,
  ) => {
    lastXDay.current = lastXDayInput;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <AuthenticationWrapper>
      <DashboardHeader
        selectedDashboard={selectedDashboard}
        isEditingMode={isEditingMode}
        onDashboardActionChange={onDashboardActionChange}
        onCancel={cancelEditMode}
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={onDateRangeChange}
        user={user}
        isDemo={isDemo}
      />
      {isDemo && (
        <LockBanner
          showLockedIcon={false}
          heading={<>Don’t worry! This isn’t your data.</>}
          subHeading={
            <>
              Use Bolster's sample data to explore the functionality of{' '}
              <span className='highlight-text normal-text'>{titleMapper['web']} monitoring</span>{' '}
              dashboard.
            </>
          }
          upgradeBtnText='Request Demo'
        />
      )}
      {featureIsAvailable(user, [appConstants.FEATURE_CODE.NEW_WEB_DASHBOARD]) ? (
        <>
          <MonitoredContainerV2
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={onDateRangeChange}
            domainCategories={categories}
            user={user}
            scanSourceCategories={scanSourceCategories}
            sendMonitoredDomains={updateMonitoredDomainsForAcquisition}
          />
        </>
      ) : (
        <>
          {' '}
          <DashboardSummary user={user} />
          <MonitoredDomainContainer
            startDate={startDate}
            endDate={endDate}
            domainCategories={categories}
          />
        </>
      )}

      <div className={'page-content dashboard-page dashboard-page-v2'}>
        {isEditingMode &&
          selectedDashboard.widgets.length === 0 &&
          !selectedDashboard.table &&
          !selectedDashboard.monitoring && (
            <div className='zero-section-help-text'>
              Please add a section before saving a new dashboard.
            </div>
          )}
        {isEditingMode && (
          <Card className='add-section-component no-border-or-background'>
            <div className='widget-item-inner add-new-widget-component'>
              <img src={AddWidgetIcon} alt={'Add Widget'} />
              <Button variant='primary' onClick={() => console.log('clicked add section.')}>
                Add Section
              </Button>
            </div>
          </Card>
        )}
        <WidgetsSection
          user={user}
          startDate={startDate}
          endDate={endDate}
          isEditingMode={isEditingMode}
          monitoredDomainsForAcquisitions={monitoredDomainsForAcquisitions}
          enableCommunity={false}
          activeOUId={activeOUId}
          isDemo={isDemo}
        />
      </div>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { categories, scanSourceCategories } = state.appReducer;
  const { user, selectedDashboard } = state.dashboardReducer;
  return {
    user,
    categories,
    selectedDashboard,
    scanSourceCategories,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  getDashboardConfig: dashboardActions.getDashboardConfig,
  onDeleteDashboard: dashboardActions.onDeleteDashboard,
  onSetDefaultDashboard: dashboardActions.onSetDefaultDashboard,
  onChangeDashboard: dashboardActions.onChangeDashboard,
  onSaveDashboard: dashboardActions.onSaveDashboard,
  onDeleteWidgets: dashboardActions.onDeleteWidgets,
};

const connectedDashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
export { connectedDashboard as DashboardContainer };

import React, { ChangeEvent, useContext, useMemo } from 'react';
import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import {
  ICheckPhishSubscription,
  initialState,
  CheckphishSubscriptionContext,
} from '../../../context/CheckPhishContactUsContext';
import { NumberOfDomains } from '../constants';
import ThemeContext from '../../../context/ThemeContext';
import { ThemeModes } from '../../../constants';

type Props = {};
const DomainSelector = (props: Props) => {
  const { numberOfDomains, setNumberOfDomains } = useContext<ICheckPhishSubscription>(
    CheckphishSubscriptionContext,
  );
  const { selectedTheme } = useContext(ThemeContext);

  const useStyles = makeStyles({
    select: {
      '& .MuiSvgIcon-root': {
        color: `${selectedTheme === ThemeModes.DARK.toLowerCase() ? '#fff' : '#000000de'}`,
      },
    },
  });

  const onChangeHandler = (event: ChangeEvent<any>) => {
    const selectedNumberOfDomain = event.target.value;
    if (selectedNumberOfDomain === numberOfDomains) return;
    setNumberOfDomains(selectedNumberOfDomain);
  };
  const classes = useStyles();

  return (
    <div className='domain-select-wrap' data-testid='domain-selector-wrap'>
      <FormControl size='small'>
        <Select
          id='number_of_domain_selector'
          value={numberOfDomains}
          defaultValue={numberOfDomains}
          onChange={onChangeHandler}
          variant='outlined'
          data-testid='domain-selector'
          style={{
            margin: '0 5px',
          }}
          className={`select-domain-input-box ${classes.select}`}
        >
          {NumberOfDomains.map((domains: number, index: number) => (
            <MenuItem value={domains} data-testid='domain-select-value' key={`domain-${index}`}>
              {domains}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {numberOfDomains === 1 ? 'Domain' : 'Domains'}
    </div>
  );
};

export default DomainSelector;

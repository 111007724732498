import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import LOGO from '../../assets/logo/CP_by_BO_logo.svg';
import { ITabItem, ITabSubItem } from './TabItems';
import './WebHeader.scss';
import HamburgerIcon from '../../assets/icons/HamburgerBlack.svg';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { redirectToCheckphish } from '../../constants';

interface IHeaderProps {
  tabItems: ITabItem[];
  toggle: () => void;
}

const WebHeader: FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {
  const [activeTab, changeActiveTab] = useState('');

  const checkCurrentTab = (href: string) => {
    return href === activeTab;
  };

  useEffect(() => {
    const { tabItems } = props;
    const { pathname } = window.location;
    const currentTab = tabItems.find((item: any) => item.href === pathname);
    if (currentTab?.href) {
      changeActiveTab(currentTab.href);
    }
  }, []);

  const renderTabItem = (item: ITabItem) => {
    if (item.subItems) {
      return (
        <NavDropdown key={item.label} title={item.label} id={item.label}>
          {item.subItems.map((subTab: ITabSubItem) => (
            <NavDropdown.Item
              className={subTab.className}
              disabled={subTab.disabled}
              href={subTab.href}
              key={subTab.label}
            >
              {subTab.label}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    }

    return (
      <Nav.Item key={item.label} className={'px-2'}>
        {item.isNewLabel && <div className='new-label-text'>New</div>}
        <Nav.Link
          href={item.redirect ? redirectToCheckphish(item.href, true) : item.href}
          onClick={() => {
            changeActiveTab(item.href);
          }}
          className={checkCurrentTab(item.href) ? 'active-link' : 'custom-text-dark'}
        >
          {item.label}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <header className='web-header-container'>
      <div
        className={'headerWrapper p-4 d-flex flex-row justify-content-between align-items-center'}
      >
        <a href={redirectToCheckphish('/', true)}>
          <img src={LOGO} alt={'companyIcon'} />
        </a>
        <Nav className={'d-none d-xl-flex flex-center tabs-container'}>
          {props.tabItems.map((item: ITabItem) => renderTabItem(item))}
        </Nav>
        <div className={'d-none d-xl-block'}>
          <Link
            onClick={() => {
              changeActiveTab('/sign-in');
            }}
            to={'/sign-in'}
          >
            <Button variant={'secondary'} className={'web-header-button login-button-box mr-2'}>
              <div className={'login-button'}>Log In</div>
            </Button>
          </Link>
          <Link
            onClick={() => {
              changeActiveTab('/sign-up');
            }}
            to={'/sign-up'}
          >
            <Button variant={'primary'} className={'web-header-button sign-up-button-box px-4'}>
              <div className={'sign-up-button'}>Sign Up</div>
            </Button>
          </Link>
        </div>
        <div className={'d-xl-none'}>
          <img
            className={'cursor-pointer'}
            src={HamburgerIcon}
            alt={'menu'}
            onClick={props.toggle}
          />
        </div>
      </div>
    </header>
  );
};

export { WebHeader };

import { IDropdownOption } from '../components/Common/Dropdown';

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
}

export interface UserSignupDto {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface UserForgotPassDto {
  email: string;
}

export interface UserResetPassDto {
  password: string;
}

export interface UserLoginDto {
  email: string;
  password: string;
}

export interface INotificationDto {
  typosquatting: any;
}

export interface Alert {
  type: string;
  message: string;
  id: number;
  domElement?: any;
}

export type BrandType = 'brand' | 'non_brand' | 'hybrid' | 'table-config';
type WidgetType = 'chart' | 'monitoring' | 'table';

export interface DashBoardDto {
  first_name: string;
  last_name: string;
  role_name: string;
  type_name: BrandType;
  subscription_start_date: number;
  subscription_end_date: number;
  resource_info: GroupResourceType;
  email: string;
  features: IFeatureAccess[];
  brand: string;
  userAppSetting: any;
  organization?: string;
  is_internal?: boolean;
  brand_info: IBrandInfo;
  subscription?: any;
  orgInfo?: IOrgInfo;
  ouId?: number;
  notificationSettings: INotificationDto;
  brandId?: number;
  monitoredDomainPresent?: boolean;
  isPrimaryEmailWorkEmail?: boolean;
  workEmail?: string;
  workEmailVerified?: boolean;
  domainMonitoringDaysToExpire?: number;
}
export interface IOrgInfo {
  id: number;
  name: string;
  type: string;
  brandMapping: boolean;
  orgUnits: IOrgUnits[];
  brands: IBrand[];
}

export interface IBrand {
  id: number;
  ouId: any;
  name: string;
  mnemonic: string;
}

export interface IOrgUnits {
  id: number;
  name: string;
  brandIds?: number[];
}
export enum ERoleName {
  communityUser = 'community_user',
  internalUser = 'internal_user',
  internalAdmin = 'internal_admin',
  brandAdmin = 'brand_admin',
  internalSuperAdmin = 'internal_super_admin',
  undefined = '',
  brandUser = 'brand_user',
  brandReadOnlyUser = 'brand_read_only_user',
  brandModuleAdmin = 'brand_module_admin',
}

export interface UserDto {
  first_name: string;
  last_name: string;
  role_name: ERoleName;
  type_name: BrandType;
  subscription_start_date: string;
  subscription_end_date: string;
  resource_info: GroupResourceType;
  email: string;
  allow_change: boolean;
  subscription?: any;
  api_key: string;
}

export interface DashBoardConfigDto {
  id: string;
  label: string;
  name: string;
  dashboardType: BrandType;
  href: string;
  parent: string;
  isDefault: boolean;
  monitoring?: DashBoardWidgetConfigDto;
  widgets: DashBoardWidgetConfigDto[];
  table?: DashBoardWidgetConfigDto;
  isNew?: boolean;
}

export interface DashBoardWidgetConfigDto {
  name: string;
  widgetType: WidgetType;
  idx: number;
  columns?: string;
  oneRow?: boolean;
}

export interface ITableConfigDto {
  dashboardName: string;
  widgets: DashBoardWidgetConfigDto[];
}

export interface ScanCountDto {
  api_key: string;
  monthly_scan_limit: number;
  yearly_scan_limit: number;
  monthly_total_usage_count: number;
  monthly_api_usage_count: number;
  monthly_bulk_api_usage_count: number;
}

type ResourceType = {
  resource_name: string;
  access_rights: string;
};

type GroupResourceType = Array<ResourceType>;

export interface IFeatureAccess {
  code: string;
  label?: string;
  enabled: boolean;
}
export interface IAttribute {
  id: number;
  index: string;
  field: string;
  label: string;
}
export interface ICategory extends IDropdownOption {
  group: string;
}

export type TContentType = 'social' | 'marketplace';
export type IContentCategory = IDropdownOption;
export type IContentOrigin = IDropdownOption;
export interface IContentPlatform extends IDropdownOption {
  logoUrl: string;
  origins: IContentOrigin[];
}
export interface IContentProps {
  platforms: IContentPlatform[];
  origins: IContentOrigin[];
  categories: IContentCategory[];
}

export interface IBrandInfo {
  brand: {
    firstName: string;
    lastName: string;
    brandId: number;
    orgName: string;
    brandName: string;
    displayName: string;
    brandMapping: boolean;
    subBrands: {
      brand_id: number;
      brand_name: string;
      brand_display_name: string;
      related_organization_id: number;
      related_sso_id: number;
    }[];
    subBrandIds: number[];
    subBrandNames: string[];
  };
}

export interface InsightsDto {
  status: string;
  srcURL: string;
  redirectURL: string;
  location: string;
  tld: string;
  brand: string;
  brandId: string;
  subBrandId: string;
  subBrandName: string;
  networkOwner: string;
  asn: string;
  ip: string;
  takedownTS: string;
  takedownCount: string;
  timestamp: string;
  createdTS: string;
  imagePath: string;
  cert: string;
  abuseContact: string;
  disposition: string;
  host: string;
  primaryDomain: string;
  countryCode: string;
  firstSeenTS: string;
  jobId: string;
  hostPhishCount: string;
  ipPhishCount: string;
  phishingKitsCountOnHost: string;
  scanSource: string;
  annotations: any;
  brandScanCount: string;
  bolsterScanCount: string;
  brandInfo: any;
  finalCategory: string;
  mxRecords: string;
  hasMxRecords: string;
  allMXRecords: string[];
  registrationDate: string;
  registrant: string;
  nameservers: string;
  sfbDetected?: string;
  scanSettings: any;
  scanEngineDetails?: any;
  url_sha256?: string;
  tags?: any;
  scanContext?: any;
  scanCategoryType?: any;
  sourceFeed?: string;
}

export interface IpWhoisDto {
  result: object;
}

export interface IAttribute {
  id: number;
  index: string;
  field: string;
  label: string;
}

export interface IScanSourceFeeds {
  label: string;
  value: string;
  id: number;
}

export enum CHECKPHISH_APP_TYPE {
  CHECKPHISH = 'checkphish',
  PLATFORM = 'platform',
}

export type CheckPhishAppType = CHECKPHISH_APP_TYPE.CHECKPHISH | CHECKPHISH_APP_TYPE.PLATFORM;

export type ILableValue = { label: string; value: any; isLocked?: boolean };

export type PlatformModule = {
  name: string;
  enabledCode: string;
  disabledCode: string;
};

import { AppState } from '../../helpers';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { TakedownUrl } from './types/takedown-url.interface';
import { Button, Card, Spinner } from 'react-bootstrap';
import { LabelAndValue } from '../Common/LabelAndValue';
import { Image, ON_ERROR_IMAGE } from '../Common/Image';
import { Link } from 'react-router-dom';
import SocTakedownHistoricalScreenshotModal from './soc-takedown-historical-screenshot.modal';
import { getScreenshotUrl } from '../../helpers/screenshotUrl';

export interface ISocTakedownUrlsProps {
  insightsPath: string;
  onAddScreenshot: (urlInfo: TakedownUrl) => void;
  onRemoveScreenshot: (urlInfo: TakedownUrl) => void;
  onRetakeScreenshot: (urlInfo: TakedownUrl) => void;
  onRevertScreenshot: (urlInfo: TakedownUrl) => void;
  selectedTakedownUrls: TakedownUrl[];
  switchScreenshot: (urlInfo: TakedownUrl, historicalScreenshotPath: string) => void;
}

function SocTakedownUrls({
  insightsPath,
  selectedTakedownUrls,
  onAddScreenshot,
  onRemoveScreenshot,
  onRetakeScreenshot,
  onRevertScreenshot,
  switchScreenshot,
}: ISocTakedownUrlsProps) {
  const [showHistoricalModal, setShowHistoricalModal] = useState<boolean>(false);
  const [selectedUrlInfo, setSelectedUrlInfo] = useState<TakedownUrl | null>(null);
  const [urlLoadMap, setUrlLoadMap] = useState<{ [screenshotUrl: string]: boolean }>({});

  function screenshotButton(urlInfo: TakedownUrl) {
    if (urlInfo.selected) {
      return (
        <Button variant={'link'} size={'sm'} onClick={() => onRemoveScreenshot(urlInfo)}>
          Remove From Email
        </Button>
      );
    }
    return (
      <Button
        disabled={!urlLoadMap[urlInfo.retakeScreenshotPath || urlInfo.screenshotPath]}
        variant={'link'}
        size={'sm'}
        onClick={() => onAddScreenshot(urlInfo)}
      >
        Add To Email
      </Button>
    );
  }

  function retakeScreenshot(urlInfo: TakedownUrl) {
    if (urlInfo.retakeScreenshotPath == null) {
      return (
        <div className={'d-flex align-items-center'}>
          <Button
            variant={'link'}
            size={'sm'}
            onClick={() => onRetakeScreenshot(urlInfo)}
            disabled={urlInfo.retakeLoading}
          >
            Retake
          </Button>
          {urlInfo.retakeLoading && (
            <Spinner variant={'primary'} animation='border' size={'sm'} className={'ml-1'} />
          )}
        </div>
      );
    }
    return (
      <Button variant={'link'} size={'sm'} onClick={() => onRevertScreenshot(urlInfo)}>
        Revert
      </Button>
    );
  }

  return (
    <React.Fragment>
      {selectedTakedownUrls.map(urlInfo => {
        return (
          <Card className={'pt-4'} key={urlInfo.sha256}>
            <Card.Body>
              <LabelAndValue
                alignItems={'flexStart'}
                label={'URL'}
                value={urlInfo.url}
                direction={'column'}
                copyButton={true}
              />

              <Link to={`${insightsPath}/${urlInfo.timestamp}/${urlInfo.sha256}`} target='_blank'>
                View Insights Page
              </Link>
            </Card.Body>
            <hr className={'soc-hr'} />
            <Card.Body>
              <Card.Text className={'text-secondary'}>Screenshot</Card.Text>

              <Image
                className={'screenshot'}
                imagePath={getScreenshotUrl(urlInfo.retakeScreenshotPath || urlInfo.screenshotPath)}
                alt='scan result screenshot'
                onLoad={success => {
                  setUrlLoadMap({
                    ...urlLoadMap,
                    [urlInfo.retakeScreenshotPath || urlInfo.screenshotPath]: success,
                  });
                }}
              />
              <div className={'d-flex flex-row justify-content-between'}>
                <div className={'d-flex justify-content-start mt-2'}>
                  {retakeScreenshot(urlInfo)}
                </div>
                <div className={'d-flex justify-content-end mt-2'}>{screenshotButton(urlInfo)}</div>
              </div>
              <div className={'d-flex flex-row  justify-content-center'}>
                <Button
                  variant='primary'
                  onClick={() => {
                    setShowHistoricalModal(true);
                    setSelectedUrlInfo(urlInfo);
                  }}
                  className={'mt-1 '}
                >
                  Add Historical Screenshots
                </Button>
              </div>
            </Card.Body>
          </Card>
        );
      })}
      {showHistoricalModal && selectedUrlInfo && (
        <SocTakedownHistoricalScreenshotModal
          showHistoricalModal={showHistoricalModal}
          onCloseHistoricalModal={() => setShowHistoricalModal(false)}
          switchScreenshot={switchScreenshot}
          selectedUrlInfo={selectedUrlInfo}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SocTakedownUrls);

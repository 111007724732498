import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { capitalize, set } from 'lodash';
import CopyButton from '../../../Common/CopyButton';
import Skeleton from '@material-ui/lab/Skeleton';
import { CloseIcon } from '../../../../assets/SVGIcons';

import { camelCaseToTitleCase, checkIsURL } from '../../../../constants';
import useOnDataLeakInsightDetails from './useOnDataLeakInsightDetails';
import useOnToggleDataLeakDrawer from './useOnToggleDataLeakDrawer';

const excludedDetails = [
  'description',
  'name',
  'id',
  'targets',
  'lastUpdated',
  'dateAdded',
  'dateUpdated',
];
export default function DataLeakDetails() {
  const {
    isLoading,
    breachDataLeakDetails,
    leakDetailText,
    dataLeakExternalLinks,
    currentDataLeakParams,
    shouldShowDataLeakTablePopup,
  } = useOnDataLeakInsightDetails();

  const { toggleDrawer } = useOnToggleDataLeakDrawer();

  const shouldShowExtraDetailText = window.location.pathname.includes('/dark-web/dashboard');
  return (
    <Box sx={{ width: 300 }} role='presentation'>
      <div className='data-leak-container'>
        <div className='data-leak-header-wrapper'>
          <div className='data-leak-header'>Data Leak Source</div>
          <div className='data-leak-value'>{capitalize(currentDataLeakParams)}</div>
        </div>
        {shouldShowDataLeakTablePopup && (
          <div data-testid='close-icon' className='close-icon-wrapper' onClick={toggleDrawer}>
            <CloseIcon color='grey' />
          </div>
        )}
        <Divider />
        {isLoading ? (
          <Box>
            <Skeleton variant='rect' height={300} width={'auto'} animation={'pulse'} />
            <div style={{ height: 10 }}></div>
            <Skeleton variant='rect' height={200} width={'auto'} animation={'pulse'} />
            <div style={{ height: 10 }}></div>
            <Skeleton variant='rect' height={25} width={'auto'} animation={'pulse'} />
            <div style={{ height: 10 }}></div>
            <Skeleton variant='rect' height={25} width={'auto'} animation={'pulse'} />
            <div style={{ height: 10 }}></div>
            <Skeleton variant='rect' height={100} width={'auto'} animation={'pulse'} />
            <div style={{ height: 20 }}></div>
            <Skeleton variant='rect' height={100} width={'auto'} animation={'pulse'} />
            <div style={{ height: 20 }}></div>
          </Box>
        ) : (
          <div className='data-leak-description'>
            {breachDataLeakDetails?.description && (
              <div className='data-leak-header-wrapper'>
                <div className='data-leak-header'>Description</div>
                <div className='data-leak-value'>{breachDataLeakDetails?.description}</div>
              </div>
            )}
            {leakDetailText.length > 0 && (
              <div className='data-leak-header-wrapper'>
                <div className=' data-leak-header'>{`Details ${
                  shouldShowExtraDetailText ? 'for sample findings' : ''
                }`}</div>
                {leakDetailText.map((item: any, index: number) => (
                  <div
                    style={{ paddingLeft: '10px' }}
                    className='data-leak-details data-leak-value'
                    key={index}
                  >
                    {item}
                  </div>
                ))}
                {shouldShowExtraDetailText && (
                  <div className='details-additional-text'>
                    All additional details regarding the source of the leak can be found in the
                    Active Findings.
                  </div>
                )}
              </div>
            )}
            {dataLeakExternalLinks.length > 0 && (
              <div className='data-leak-header-wrapper'>
                <div className='data-leak-header'>{`External Links ${
                  shouldShowExtraDetailText ? 'for sample findings' : ''
                }`}</div>
                {dataLeakExternalLinks.map((item: any, index: number) => (
                  <div key={index} className='data-leak-value'>
                    {item}
                    <div className='external-link-user'>
                      <CopyButton copyButton showCopyText value={item} />
                    </div>
                  </div>
                ))}
              </div>
            )}

            {Object.keys(breachDataLeakDetails)?.length > 0 && (
              <>
                {Object.keys(breachDataLeakDetails)
                  .filter(item => !excludedDetails.includes(item))
                  .map((key: string, index: number) => {
                    if (
                      breachDataLeakDetails?.[key] !== null &&
                      breachDataLeakDetails?.[key] !== '' &&
                      breachDataLeakDetails?.[key]?.length > 0
                    )
                      return (
                        <div className='data-leak-header-wrapper' key={index}>
                          <div className='data-leak-header'>{camelCaseToTitleCase(key)}</div>
                          <div className='data-leak-value'>
                            {Array.isArray(breachDataLeakDetails?.[key])
                              ? breachDataLeakDetails?.[key].join(', ')
                              : breachDataLeakDetails?.[key]}
                          </div>
                        </div>
                      );
                  })}
              </>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}

import React from 'react';
import { ExportIcon } from '../../../assets/SVGIcons';

export const ExportCSV = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => {
  return (
    <div
      key={'Export CSV'}
      className='d-flex align-items-center'
      style={{ cursor: 'pointer', position: 'absolute', right: '20px', top: '-30px' }}
      {...props}
    >
      <ExportIcon color={'grey'} />
      <div className='pl-2 export-label'>Export CSV</div>{' '}
    </div>
  );
};

import React, { FunctionComponent, useState, useEffect, useRef } from 'react';

import { withCommas } from '../../constants/util';
import CheckIcon from '../../assets/icons/PlansCheck.svg';
import './Pricing.scss';

interface IPlansProps {
  onClickPro: Function;
  onClickEnt?: Function;
  children?: React.ReactNode;
  allPlans: any;
  values: any;
  setValues: any;
  user: any;
}

export interface IPlanV2 {
  //bulk scan
  id: number;
  price: number;
  limit: number;
  term: 'monthly' | 'yearly';
  bundles?: number[];
  discounts?: any[];
}

export interface IChosenPlan extends IPlanV2 {
  quantity: number;
}

interface IPlansV2Props {
  onClickPro: Function;
  onClickEnt?: Function;
  children?: React.ReactNode;
  chosenPlan: IChosenPlan;
  monthlyPlan: IPlanV2;
  yearlyPlan: IPlanV2;
  liveScanBundles: number[];
  onChange: (val: IChosenPlan) => void;
  liveScanSubscriptionPlan: any;
  displaySliderOnly?: boolean;
}

const setRangeColor = (rangeVal: string, inputElement: any) => {
  const changeColorValue = ((parseInt(rangeVal) - 1) / (5 - 1)) * 100;
  inputElement.current.style.background =
    'linear-gradient(to right, #8BB0EF 0%, #8BB0EF ' +
    changeColorValue +
    '%, #E9ECF0 ' +
    changeColorValue +
    '%, #E9ECF0 100%)';
};

const PlanCards: FunctionComponent<IPlansProps> = ({
  onClickPro,
  onClickEnt,
  children,
  allPlans,
  values,
  setValues,
  user,
}) => {
  const isCheckDisabled = user?.subscription ? true : false;
  const inputElement: any = useRef();

  useEffect(() => {
    if (!Object.keys(allPlans).length) {
      return;
    }
    if (values && values?.isMonth) {
      setValues((inputs: any) => ({
        ...inputs,
        monthlyPrice: withCommas(values.monthlyPrice),
        annualPrice: withCommas(values.annualPrice),
        scansPerDay: withCommas(values.scansPerDay),
        scansPerMonth: withCommas(values.scansPerMonth),
        planId: values.planId,
        priceId: values.priceId,
        isMonth: values.isMonth,
        range: values.range,
      }));
      setRangeColor(values.range, inputElement);
    } else {
      setValues((inputs: any) => ({
        ...inputs,
        monthlyPrice: withCommas(allPlans[0].prices[0].price),
        annualPrice: withCommas(allPlans[0].prices[1].price),
        scansPerDay: withCommas(allPlans[0].limits.day),
        scansPerMonth: withCommas(allPlans[0].limits.month),
        planId: allPlans[0].id,
        priceId: allPlans[0].prices[0].id,
      }));
    }
  }, [allPlans]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    let currValue = event.target.value;
    if (event.target.name === 'isMonth') {
      currValue = values.isMonth === 'on' ? 'off' : 'on';

      setValues((inputs: any) => {
        return {
          ...inputs,
          isMonth: currValue,
        };
      });

      return;
    }
    const at = parseInt(currValue);

    setValues((inputs: any) => ({
      ...inputs,
      range: at,
      monthlyPrice: withCommas(allPlans[at - 1].prices[0].price),
      annualPrice: withCommas(allPlans[at - 1].prices[1].price),
      scansPerDay: withCommas(allPlans[at - 1].limits.day),
      scansPerMonth: withCommas(allPlans[at - 1].limits.month),
    }));

    if (event.target.name === 'range') {
      setRangeColor(currValue, inputElement);
    }
  };

  const showPrice = function () {
    if (values.isMonth === 'on') {
      return `$${values.monthlyPrice} / Month`;
    }
    return `$${values.annualPrice} / Year`;
  };

  const handleProPurchaseClick = () => {
    if (!values || !values.priceId) {
      console.error('No plan priceId available in handleProPurchaseClick');
      return;
    }
    const at = values.range;
    values.planId = allPlans[at - 1].id;
    values.priceId =
      values.isMonth === 'on' ? allPlans[at - 1].prices[0].id : allPlans[at - 1].prices[1].id;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'purchaseProClicked',
      category: 'Page',
      action: 'clicked',
      label: 'purchaseProClicked',
    });

    onClickPro();
  };

  return (
    <section className='plans-cards'>
      {children}
      <div className='plans-card'>
        <h3 className={'plans-h3'}>Professional API</h3>
        <div className={'plans-info'}>
          <h4>{showPrice()}</h4>

          <div className='plans-switch-wrap'>
            <div>MONTHLY</div>
            <label className='plans-switch'>
              <input
                onChange={handleInputChange}
                checked={values.isMonth === 'off'}
                name='isMonth'
                type='checkbox'
                disabled={isCheckDisabled}
              />
              <span className={isCheckDisabled ? `plans-slider disabled` : `plans-slider`}></span>
            </label>
            <div>YEARLY</div>
          </div>
          <div className='plans-slider-wrap'>
            <input
              onChange={handleInputChange}
              type='range'
              min='1'
              max='5'
              className='plans-range'
              id='plansRange'
              name='range'
              value={values.range}
              ref={inputElement}
            />
            <span className='plan-slide-ridge mod-1'>&nbsp;</span>
            <span className='plan-slide-ridge mod-2'>&nbsp;</span>
            <span className='plan-slide-ridge mod-3'>&nbsp;</span>
            <span className='plan-slide-ridge mod-4'>&nbsp;</span>
            <span className='plan-slide-ridge mod-5'>&nbsp;</span>
          </div>
          <div>{values.scansPerDay} scans per day</div>
          <div>{values.scansPerMonth} scans per month</div>
        </div>
        <a className={'btn plans-btn mod-pro'} onClick={handleProPurchaseClick}>
          Purchase Now
        </a>
        <div className={'plans-title'}>Benefits:</div>
        <div className='plans-list'>
          <ul>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} />{' '}
              Multi-user environments (coming soon)
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pr-2 plans-check'} /> Configurable
              dashboards
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} />{' '}
              Dedicated support
            </li>
          </ul>
        </div>
      </div>
      <div className='plans-card'>
        <h3 className={'plans-h3'}>Enterprise API</h3>
        <div className={'plans-info'}>
          <h4>Let’s talk!</h4>
          <div>Custom packages &amp; pricing</div>
          <div>available from Bolster</div>
        </div>
        <button
          className={'btn plans-btn mod-ent'}
          onClick={() => {
            if (onClickEnt) {
              onClickEnt();
            }
          }}
        >
          Contact Bolster
        </button>
        <div className={'plans-title'}>Benefits:</div>
        <div className='plans-list'>
          <ul>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} /> Custom
              SLAs
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pr-2 plans-check'} /> Dedicated
              account management support
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} />{' '}
              Commercial integration capabilities
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export const showPrice = function (chosenPlans: IChosenPlan[], showTerm: boolean = true) {
  if (chosenPlans.length === 0) {
    return null;
  }
  let val = 0;
  chosenPlans.forEach(chosenPlan => {
    const { discounts, quantity, limit, price } = chosenPlan;
    if (discounts) {
      let validDiscounts: any[] = [];
      discounts.forEach(discount => {
        if (discount.minQuantity < quantity) {
          validDiscounts.push(discount);
        }
      });
      validDiscounts = validDiscounts.sort(
        (a, b) => b.discount.minQuantity - a.discount.minQuantity,
      );
      if (validDiscounts.length > 0) {
        val = val + quantity * limit * validDiscounts[0].price;
      } else {
        val = val + quantity * limit * price;
      }
    } else {
      val = val + quantity * limit * price;
    }
  });

  const { term } = chosenPlans[0];
  return `$${val}${showTerm ? ` / ${term === 'monthly' ? 'Month' : 'Year'}` : ''}`;
};

export const PlanCardsV2: FunctionComponent<IPlansV2Props> = ({
  onClickPro,
  onClickEnt,
  children,
  chosenPlan,
  liveScanBundles,
  monthlyPlan,
  yearlyPlan,
  onChange,
  liveScanSubscriptionPlan,
  displaySliderOnly = false,
}) => {
  const isCheckDisabled = liveScanSubscriptionPlan ? true : false;
  const inputElement: any = useRef();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (chosenPlan && yearlyPlan && monthlyPlan && liveScanBundles.length > 0) {
      let temp: IChosenPlan;
      if (event.target.name === 'term') {
        const checked = event.target.checked;
        temp = {
          ...(checked ? yearlyPlan : monthlyPlan),
          quantity: chosenPlan.quantity,
        };
      } else {
        const currValue = event.target.value;
        const index = parseInt(currValue);

        setRangeColor(currValue, inputElement);
        temp = {
          ...chosenPlan,
          quantity: liveScanBundles[index - 1],
        };
      }
      onChange({ ...temp });
    }
  };

  const handleProPurchaseClick = () => {
    window.dataLayer.push({
      event: 'purchaseProClicked',
      category: 'Page',
      action: 'clicked',
      label: 'purchaseProClicked',
    });

    onClickPro();
  };

  const getRangeValue = () => {
    if (!chosenPlan || liveScanBundles.length == 0) {
      return 1;
    }
    const { quantity, limit } = chosenPlan;
    const val = quantity / limit;
    const index = liveScanBundles.indexOf(val);
    return index + 1;
  };

  const renderSlider = () => {
    return (
      <>
        <div className={'plans-info'}>
          <h4>{showPrice([chosenPlan])}</h4>
          <div className='plans-switch-wrap'>
            <div>MONTHLY</div>
            <label className='plans-switch'>
              <input
                onChange={handleInputChange}
                defaultChecked={chosenPlan?.term === 'yearly'}
                name='term'
                type='checkbox'
                disabled={isCheckDisabled}
              />
              <span className={isCheckDisabled ? `plans-slider disabled` : `plans-slider`}></span>
            </label>
            <div>YEARLY</div>
          </div>
          <div className='plans-slider-wrap'>
            <input
              onChange={handleInputChange}
              type='range'
              min='1'
              max='5'
              className='plans-range'
              id='plansRange'
              name='range'
              defaultValue={getRangeValue()}
              ref={inputElement}
              style={{ background: 'black' }}
            />
            <span className='plan-slide-ridge mod-1'>&nbsp;</span>
            <span className='plan-slide-ridge mod-2'>&nbsp;</span>
            <span className='plan-slide-ridge mod-3'>&nbsp;</span>
            <span className='plan-slide-ridge mod-4'>&nbsp;</span>
            <span className='plan-slide-ridge mod-5'>&nbsp;</span>
          </div>
          <div>{chosenPlan.price * chosenPlan.limit * chosenPlan.quantity} scans per day</div>
        </div>
        <a className={'btn plans-btn mod-pro'} onClick={handleProPurchaseClick}>
          Purchase Now
        </a>
      </>
    );
  };

  if (displaySliderOnly) {
    return renderSlider();
  }

  return (
    <section className='plans-cards'>
      {children}
      <div className='plans-card'>
        <h3 className={'plans-h3'}>Professional API</h3>
        {renderSlider()}
        <div className={'plans-title'}>Benefits:</div>
        <div className='plans-list'>
          <ul>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} />{' '}
              Multi-user environments (coming soon)
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pr-2 plans-check'} /> Configurable
              dashboards
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} />{' '}
              Dedicated support
            </li>
          </ul>
        </div>
      </div>
      <div className='plans-card'>
        <h3 className={'plans-h3'}>Enterprise API</h3>
        <div className={'plans-info'}>
          <h4>Let’s talk!</h4>
          <div>Custom packages &amp; pricing</div>
          <div>available from Bolster</div>
        </div>
        <button
          className={'btn plans-btn mod-ent'}
          onClick={() => {
            if (onClickEnt) {
              onClickEnt();
            }
          }}
        >
          Contact Bolster
        </button>
        <div className={'plans-title'}>Benefits:</div>
        <div className='plans-list'>
          <ul>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} /> Custom
              SLAs
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pr-2 plans-check'} /> Dedicated
              account management support
            </li>
            <li className='py-2'>
              <img src={CheckIcon} alt={'checkmark'} className={'pt-1 pr-2 plans-check'} />{' '}
              Commercial integration capabilities
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

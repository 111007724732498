import * as React from 'react';
import _ from 'lodash';
import { AppState } from '../../helpers';
import { alertActions } from '../../actions';
import { connect } from 'react-redux';
import './GlobalAlert.scss';
import { GlobalAlertItem } from './GlobalAlertItem';
import { AlertActionsTypes, getLocalStorageValue } from '../../constants';
import packageJson from '../../../package.json';

interface ILinkStateProp {
  alerts: any;
}

interface ILinkDispatchProps {
  clearAlert: (id: number) => void;
  releaseNoteAction: (message: string) => AlertActionsTypes;
}

interface IGlobalAlertProps {}

type Props = ILinkStateProp & ILinkDispatchProps & IGlobalAlertProps;

interface IGlobalAlertState {}

class GlobalAlert extends React.Component<Props, IGlobalAlertState> {
  componentDidMount() {
    // TODO: Hide version notification for now until we have more meaningful content for release notes.
    // const currentUiVersion = packageJson.version;
    // if (_.isEmpty(getLocalStorageValue("showReleaseNoteCC" + currentUiVersion))) {
    //   this.props.releaseNoteAction('Click on the version number on the bottom left to view the release notes for version ' + currentUiVersion);
    // }
  }

  render() {
    const { alerts, clearAlert } = this.props;
    return (
      <div className={'global-alert-component'}>
        {alerts.length
          ? _.map(alerts, (alert, index: number) => {
              return (
                <GlobalAlertItem
                  key={alert.id}
                  alert={alert}
                  index={index}
                  clearAlert={clearAlert}
                />
              );
            })
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    alerts: state.alert.alerts,
  };
};

const mapDispatchToProps = {
  clearAlert: alertActions.clear,
  releaseNoteAction: alertActions.releaseNoteAction,
};

const connectedGlobalAlert = connect(mapStateToProps, mapDispatchToProps)(GlobalAlert);

export { connectedGlobalAlert as GlobalAlert };

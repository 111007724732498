import * as React from 'react';
import CheckphishLogo from '../../assets/checkphish-logo-white.svg';
import CheckphishDarkLogo from '../../assets/checkphish-logo-dark.svg';
import LogoIcon from '../../assets/logo.svg';
import BolsterDarkIcon from '../../assets/icons/BolsterDark.svg';
import BolsterLogoIcon from '../../assets/bolster-logo.svg';
import BolsterWhiteLogoIcon from '../../assets/bolster-logo-white.svg';
import companySuperAdminIcon from '../../assets/bolster-super-admin-logo.svg';
import './CompanyIcon.scss';
import { IAppDispatchProps, ThemeModes, redirectToCheckphish } from '../../constants';
import { appActions } from '../../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from '../../helpers';
import { ELoggedIn } from '../../reducers';

interface ICompanyIconProps {
  superAdmin: boolean;
  withName: boolean;
  theme: string;
  className: string;
  isLoggedIn: ELoggedIn;
  isDemo?: boolean;
}

interface ICompanyIconState {}

type Props = ICompanyIconProps & IAppDispatchProps;

class CompanyIcon extends React.Component<Props, ICompanyIconState> {
  static defaultProps = {
    superAdmin: false,
    withName: false,
    theme: '',
    className: '',
  };

  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(prevProps: ICompanyIconProps): boolean {
    if (prevProps.theme !== this.props.theme) {
      return true;
    }
    if (prevProps.isDemo !== this.props.isDemo) {
      return true;
    }
    return false;
  }

  render() {
    const isCommunity = this.props.runByClientApp({
      onCheckPhish: () => true,
      onBolster: () => false,
    });
    const { isLoggedIn, isDemo } = this.props;
    if (isDemo && isCommunity) {
      return (
        <img
          src={
            this.props.theme === ThemeModes.DARK.toLowerCase()
              ? BolsterWhiteLogoIcon
              : BolsterLogoIcon
          }
          alt={'companyIcon'}
          className={'bolster-logo-icon'}
          style={{ width: '80%' }}
        />
      );
    }
    if (isCommunity) {
      return (
        <div className={`checkphish-logo ${this.props.className}`}>
          {isLoggedIn === ELoggedIn.true ? (
            <Link to='/checkphish/dashboard' className='checkphish-logo'>
              <img
                src={
                  this.props.theme === ThemeModes.DARK.toLowerCase()
                    ? CheckphishDarkLogo
                    : CheckphishLogo
                }
                alt='checkphish-logo'
              />
            </Link>
          ) : (
            <a href={redirectToCheckphish('/', true)} className='checkphish-logo'>
              <img
                src={
                  this.props.theme === ThemeModes.DARK.toLowerCase()
                    ? CheckphishDarkLogo
                    : CheckphishLogo
                }
                alt='checkphish-logo'
              />
            </a>
          )}
        </div>
      );
    }
    if (this.props.superAdmin) {
      return (
        <img
          src={companySuperAdminIcon}
          alt={'companySuperAdminIcon'}
          className={'bolster-super-admin-logo-icon'}
        />
      );
    }
    if (this.props.withName) {
      return (
        <img
          src={
            window.matchMedia('(prefers-color-scheme: dark)').matches
              ? BolsterWhiteLogoIcon
              : BolsterLogoIcon
          }
          alt={'companyIcon'}
          className={'bolster-logo-icon'}
        />
      );
    }
    return (
      <img
        src={this.props.theme === ThemeModes.DARK.toLowerCase() ? BolsterDarkIcon : LogoIcon}
        alt={'companyIconDark'}
        className={'logo-icon'}
      />
    );
  }
}

const mapDispatchToProps = {
  runByClientApp: appActions.runByClientApp,
};
const mapStateToProps = (state: AppState) => {
  const { loggedIn } = state.login;
  return { isLoggedIn: loggedIn };
};
const connectedCompanyIcon = connect(mapStateToProps, mapDispatchToProps)(CompanyIcon);
export { connectedCompanyIcon as CompanyIcon };

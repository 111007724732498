import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { IAlertDispatchProps, IDashboardDispatchProps, User } from '../../../constants';
import { SocTakedownService } from '../../../services/soc-takedown.service';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import ModalOverlay from '../../Common/ModalOverlay';
import { dashboardActions } from '../../../actions';
import { connect } from 'react-redux';

const socTakedownService = new SocTakedownService();

export interface ISOCRequestModalProps {
  show: boolean;
  urlsha256: string;
  onSubmit: (eventHistoryItem: any) => void;
  onCancel: () => void;
  user: any;
}
const defaultValidationErrors = () => ({
  message: [] as string[],
});

function SocRequestDocuments({
  show,
  urlsha256,
  onCancel,
  onSubmit,
  alertSuccess,
  alertError,
  user,
  timelineUpdated,
}: ISOCRequestModalProps &
  Pick<IAlertDispatchProps, 'alertSuccess' | 'alertError'> &
  Pick<IDashboardDispatchProps, 'timelineUpdated'>) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [showErrors, setShowErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState(defaultValidationErrors());

  useEffect(() => {
    setMessage('');
    validate();
    setShowErrors(false);
  }, [show]);

  function validate(): boolean {
    const errors = defaultValidationErrors();
    if (!message.trim()) {
      errors.message = ['Comments are required.'];
    }
    setValidationErrors(errors);
    return errors.message.length == 0;
  }

  async function submit() {
    try {
      setIsLoading(true);
      setShowErrors(true);
      if (validate()) {
        const res = await socTakedownService.socRequestDocuments({
          urlsha256,
          message,
        });
        alertSuccess('Document requested successfully.');
        onSubmit(res);
        timelineUpdated();
      }
    } catch (e) {
      alertError('Error occurred while requesting the documents.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ModalOverlay
      show={show}
      title={'Request Documents'}
      onCancel={onCancel}
      onSubmit={submit}
      submitButtonDisabled={!message.length}
      showButtons={true}
      submitButtonLabel={'Send'}
      size={'lg'}
    >
      <LoadingWrapper isLoading={isLoading}>
        <Form>
          <Form.Group>
            <Form.Label>Leave a message for {user.organization} </Form.Label>
            <Form.Control
              as='textarea'
              rows={5}
              value={message}
              onChange={c => setMessage(c.target.value)}
              isInvalid={showErrors && validationErrors.message.length > 0}
            />
            {showErrors &&
              validationErrors.message.map(error => {
                return <div className='ml-1 text-danger'>{error}</div>;
              })}
          </Form.Group>
        </Form>
      </LoadingWrapper>
    </ModalOverlay>
  );
}

const mapDispatchToProps = {
  timelineUpdated: dashboardActions.timelineUpdated,
};
export default connect(null, mapDispatchToProps)(SocRequestDocuments);

//function to add hubspot script at the bottom of body tag
export const addHubspotScript = (): void => {
  const hubspotScript = document.getElementById('hs-script-loader');
  if (hubspotScript) return;
  const hubspotElement = document.createElement('script');
  hubspotElement.setAttribute('type', 'text/javascript');
  hubspotElement.setAttribute('id', 'hs-script-loader');
  hubspotElement.setAttribute('async', 'true');
  hubspotElement.setAttribute('defer', 'true');
  hubspotElement.setAttribute('src', '//js.hs-scripts.com/24174425.js');
  document.body.appendChild(hubspotElement);
};

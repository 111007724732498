import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import './ToolTip.scss';
import _ from 'lodash';

type Props = {
  tooltip: any;
  customClassName?: string;
  id: any;
  children: JSX.Element;
  placement?: Placement;
  minWidth?: number | string;
};

const ToolTip: React.FC<Props> = ({
  id,
  tooltip,
  children,
  customClassName = '',
  placement = 'top',
  minWidth = 'fit-content',
}: Props) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip
          id={id}
          className={`widget-tooltip ${customClassName}`}
          style={{ width: minWidth, textAlign: 'left' }}
        >
          {tooltip}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default ToolTip;

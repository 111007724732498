import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Check, ChevronRightIcon } from '../../../../assets/SVGIcons';
import { EDarkWebFindingStatus } from '../Types/DarkWeb.types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import ChangeStatusModal from './ChangeStatusModal';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';

interface IDarkWebFindingStatusProps {
  data: any;
  status: EDarkWebFindingStatus;
  placement: Placement;
  className?: string;
  showChevron?: boolean;
  onSubmit: () => void;
}

export default function DarkWebFindingStatus({
  data,
  status,
  placement,
  className = '',
  showChevron = false,
  onSubmit,
}: IDarkWebFindingStatusProps) {
  const [renderTs, setRenderTs] = useState<number>(moment().valueOf());
  const [newStatus, setNewStatus] = useState<EDarkWebFindingStatus | undefined>();

  const onChangeStatus = (selectedStatus: EDarkWebFindingStatus) => {
    setRenderTs(moment().valueOf());
    if (selectedStatus === status) {
      return;
    }
    setNewStatus(selectedStatus);
  };

  const closeChangeStatusModal = () => {
    setNewStatus(undefined);
  };

  const onChangeStatusSuccess = () => {
    setNewStatus(undefined);
    onSubmit();
  };

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <div className={`dark-web-status-column`}>
      {!isReadOnlyUser ? (
        <OverlayTrigger
          key={renderTs}
          placement={placement}
          trigger='click'
          overlay={
            <Tooltip id={'tooltip-dark-web-status'} className='dark-web-status-tooltip mt-1'>
              <div className='dark-web-status-column-dropdown'>
                <Status
                  status={EDarkWebFindingStatus.ACTIVE}
                  checked={EDarkWebFindingStatus.ACTIVE === status}
                  onSelect={onChangeStatus}
                />
                <Status
                  status={EDarkWebFindingStatus.MITIGATED}
                  checked={EDarkWebFindingStatus.MITIGATED === status}
                  onSelect={onChangeStatus}
                />
                <Status
                  status={EDarkWebFindingStatus.IGNORED}
                  checked={EDarkWebFindingStatus.IGNORED === status}
                  onSelect={onChangeStatus}
                />
              </div>
            </Tooltip>
          }
        >
          <div className={`dark-web-status-content d-flex align-items-center ${className}`}>
            <Status status={status} />
            {showChevron ? <ChevronRightIcon /> : null}
          </div>
        </OverlayTrigger>
      ) : (
        <div className={`dark-web-status-content d-flex align-items-center ${className}`}>
          <Status status={status} />
        </div>
      )}
      {newStatus && (
        <ChangeStatusModal
          show={newStatus !== undefined}
          data={[data]}
          status={newStatus}
          onCancel={closeChangeStatusModal}
          onSubmit={onChangeStatusSuccess}
        />
      )}
    </div>
  );
}

interface IStatusProps {
  status: EDarkWebFindingStatus;
  onSelect?: (status: EDarkWebFindingStatus) => void;
  className?: string;
  checked?: boolean;
}

function Status({ status, onSelect = _.noop, className = '', checked = false }: IStatusProps) {
  const onClick = () => {
    onSelect(status);
  };
  return (
    <div className={`dark-web-status d-flex align-items-center ${className}`} onClick={onClick}>
      <div className={`indicator`} data-status-value={status.toLowerCase()} />
      {status.toLowerCase()}
      {checked ? (
        <div className='check-wrapper'>
          <Check />
        </div>
      ) : null}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import DashboardService from '../../../../services/dashboard.service';
import {
  IChartPlaceholderProps,
  TDonutChartDataProps,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import DonutChart from '../../../Common/DashboardWidgets/DonutChart';
import NoDomain from '../../../../assets/icons/NoDomain.svg';
import { Nullable } from '../../../../types/common';

const dashboardService = new DashboardService();

const placeholder: IChartPlaceholderProps = {
  icon: NoDomain,
  title: 'No domain data found',
  description:
    'We haven’t detected any counterfeit site domains for this period. Please select another time frame.',
};

const massageData = (rawData: any): TDonutChartDataProps[] => {
  return _.map(
    rawData,
    (value: number, key: string): TDonutChartDataProps => ({
      label: key,
      count: value,
    }),
  );
};

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  disposition?: string;
  activeOUId?: Nullable<number>;
}

function DashboardTld({
  startDate,
  endDate,
  disposition = 'phish OR scam',
  activeOUId,
}: IComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<TDonutChartDataProps[]>([]);

  const getDetectionData = () => {
    setIsLoading(true);
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    if (activeOUId) {
      query['ouId'] = activeOUId;
    }
    dashboardService.getDomainWidgetData(query, 'brand').then((res: any) => {
      setData(res.result);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getDetectionData();
  }, [startDate, endDate, activeOUId]);

  return (
    <DonutChart
      title='Top TLDS (for acquisition)'
      isLoading={isLoading}
      description='New unregistered sites detected by Domain'
      data={massageData(data)}
      placeholder={placeholder}
    />
  );
}

export { DashboardTld };

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ITimelineState {
  shouldRefreshTimeline: boolean;
}
const initialState: ITimelineState = {
  shouldRefreshTimeline: false,
};

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setShouldRefreshTimeline: (state, action: PayloadAction<boolean>) => {
      state.shouldRefreshTimeline = action.payload;
    },
  },
});

export const { setShouldRefreshTimeline } = timelineSlice.actions;

export default timelineSlice.reducer;

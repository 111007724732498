import { userConstants, UserActionsTypes } from '../constants';

const forgotpassReducer = (state = { result: true }, action: UserActionsTypes) => {
  switch (action.type) {
    case userConstants.FORGOTPASS_SUCCESS:
      return {
        result: true,
      };
    case userConstants.FORGOTPASS_FAILURE:
      return {
        result: false,
      };
    default:
      return state;
  }
};
export { forgotpassReducer };

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HelpFilterIcon } from '../../../assets/SVGIcons';
import { Button, Overlay } from 'react-bootstrap';
import { Dropdown } from '../Dropdown';
import './SaveFiltersOverlay.scss';
import ThemeContext from '../../../context/ThemeContext';
import { useOnClickOutside } from '../CustomHooks/useOnClickOutside';
import { ESavedFilterTypes } from './PersistentFilters';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  setIsPersistentFilterDeleteMode,
  setIsPersistentFilterEditMode,
  setIsPersistentFilterEditViewClicked,
  setIsPersistentFilterSaveMode,
} from '../../../reducers/table.reducer';
import { alertActions } from '../../../actions';

interface ISaveFilterOverlayProps {
  onPersistentFilterSaved: (saveFor: ESelectedSaveOption, inputValue: string) => void;
  allPersistFilters: any[];
  selectedFilterForEdit: any;
}

export enum ESelectedSaveOption {
  SAVE_FOR_ME = 'save_for_me',
  SAVE_FOR_TEAM = 'save_for_team',
}

export enum ESelectedSaveOptionLabel {
  SAVE_FOR_ME = 'Save for me',
  SAVE_FOR_TEAM = 'Save for Team',
}

export default function SaveFiltersOverlay({
  onPersistentFilterSaved,
  allPersistFilters,
  selectedFilterForEdit,
}: ISaveFilterOverlayProps) {
  const saveFilterModalRefs = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const { selectedTheme } = useContext(ThemeContext);
  const [selectedSaveOption, setSelectedSaveOption] = useState<{ label: string; value: string }>({
    label: ESelectedSaveOptionLabel.SAVE_FOR_ME,
    value: ESelectedSaveOption.SAVE_FOR_ME,
  });

  const onSavedFilterValidation = useCallback(() => {
    const allSavedFilters = _.first(allPersistFilters);
    if (
      allSavedFilters &&
      allSavedFilters[ESavedFilterTypes.SAVED_BY_ORG] &&
      allSavedFilters[ESavedFilterTypes.SAVED_BY_ORG].length &&
      allSavedFilters[ESavedFilterTypes.SAVED_BY_ORG].length > 0
    ) {
      const isFilterNameExist = allSavedFilters[ESavedFilterTypes.SAVED_BY_ORG].find(
        (item: any) => item.name === inputValue,
      );
      if (isFilterNameExist) {
        return true;
      }
      return false;
    }
    return false;
  }, [inputValue, allPersistFilters]);

  useOnClickOutside(saveFilterModalRefs, (e: any) => {
    setInputValue('');
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedFilterForEdit.length > 0) {
      setInputValue(selectedFilterForEdit[0]?.name);
    }
  }, [selectedFilterForEdit]);

  const isPersistentFilterEditMode = useAppSelector(
    state => state.tableReducer.isPersistentFilterEditMode,
  );
  const isPersistentFilterSaveMode = useAppSelector(
    state => state.tableReducer.isPersistentFilterSaveMode,
  );
  const isPersistentFilterDeleteMode = useAppSelector(
    state => state.tableReducer.isPersistentFilterDeleteMode,
  );

  const isPersistentFilterEditViewClicked = useAppSelector(
    state => state.tableReducer.isPersistentFilterEditViewClicked,
  );

  const onFilterSaved = () => {
    const isFilterExist = onSavedFilterValidation();
    if (isFilterExist) {
      dispatch(alertActions.error('Filter name already exists'));
      return;
    }

    if (!inputValue) {
      dispatch(alertActions.error('Filter name is required'));
      return;
    }

    onPersistentFilterSaved(selectedSaveOption.value as ESelectedSaveOption, inputValue);
    onCancelHandler();
  };

  const onCancelHandler = () => {
    setInputValue('');
    dispatch(setIsPersistentFilterSaveMode(false));
    dispatch(setIsPersistentFilterEditMode(false));
    dispatch(setIsPersistentFilterDeleteMode(false));
    dispatch(setIsPersistentFilterEditViewClicked(false));
  };

  return (
    <div ref={saveFilterModalRefs} style={{ position: 'relative' }}>
      <div data-testid='save-current-filter-btn' className='save-current-filters'>
        {!isPersistentFilterEditViewClicked ? (
          <Button
            data-testid='save-current-filter-btn'
            id='save-current-filter-btn'
            onClick={() => {
              dispatch(setIsPersistentFilterSaveMode(true));
              dispatch(setIsPersistentFilterEditMode(false));
              dispatch(setIsPersistentFilterDeleteMode(false));
              dispatch(setIsPersistentFilterEditViewClicked(false));
            }}
            className='btn save-current-filter-btn'
            variant='outline-secondary'
          >
            Save view with current filters
          </Button>
        ) : (
          <div style={{ width: '100px' }}></div>
        )}
      </div>
      <div>
        <Overlay
          target={saveFilterModalRefs.current}
          show={
            isPersistentFilterSaveMode ||
            (isPersistentFilterEditViewClicked && selectedFilterForEdit.length > 0)
          }
          placement={'left'}
          flip={false}
          container={saveFilterModalRefs}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              className={`${selectedTheme} saved-filters-container`}
              style={{
                ...props.style,
              }}
              data-testod='saved-filters-container'
            >
              <div className='save-filter-header d-flex align-items-center'>
                <div className='saved-filter-name'>New Filter</div>
              </div>
              <div className='saved-filter-name-wrapper'>
                <div className='text-destination'>Name</div>
                <div className='text-saved-input'>
                  <input
                    type='text'
                    placeholder='Type filter name...'
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    className='input'
                    data-testid='save-filter-input'
                  />
                </div>
              </div>
              <div className='save-filters-btn-wrapper'>
                <Button
                  id='cancel-filter-button'
                  data-testid='cancel-filter-button'
                  variant='outline-secondary'
                  onClick={onCancelHandler}
                  size='sm'
                  className={'cancel-button'}
                >
                  Cancel
                </Button>
                <Dropdown
                  options={[
                    {
                      label: ESelectedSaveOptionLabel.SAVE_FOR_TEAM,
                      value: ESelectedSaveOption.SAVE_FOR_TEAM,
                    },
                    {
                      label: ESelectedSaveOptionLabel.SAVE_FOR_ME,
                      value: ESelectedSaveOption.SAVE_FOR_ME,
                    },
                  ]}
                  onChange={item => {
                    setSelectedSaveOption(item);
                  }}
                  fixedPlaceholder={selectedSaveOption.label}
                  variant={'primary'}
                  splitButton={true}
                  btnClassName={'save-for-button'}
                  disabled={false}
                  onClick={onFilterSaved}
                />
              </div>
            </div>
          )}
        </Overlay>
      </div>
    </div>
  );
}

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { OverlayTrigger, Table as BsTable, Tooltip, Modal } from 'react-bootstrap';
import { DropdownDateRangePicker } from '../DropdownDateRangePicker';
import { LoadingWrapper } from '../LoadingWrapper';
import { TableHeader } from './table.header';
import { TableBody } from './table.body';
import { TableFilterController } from './filter.controller';
import { TablePaginationController } from './pagination.controller';
import { TableColumnsController } from './columns.controller';
import { IFilter, TypeSortDirection, TExportTable, ITableProps } from './constant';
import './table.scss';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import {
  appConstants,
  dashedSortingFunction,
  DISPLAYED_NULL,
  featureIsAvailable,
  getLocalStorageValue,
  ipv4SortingFunction,
  isInternalUser,
  setLocalStorageValue,
  ThemeModes,
  UserAppSettings,
} from '../../../constants';
import ThemeContext from '../../../context/ThemeContext';
import {
  AddBlue,
  BinIcon,
  CloseIcon,
  GridViewIcon,
  ListViewIcon,
  SettingIcon,
  ExportIcon,
} from '../../../assets/SVGIcons';
import { PageTitle } from '../PageTitle';
import { ETagsTypes, ITags } from '../Tags/Tags';
import { AppState } from '../../../helpers';
import { alertActions, dashboardActions } from '../../../actions';
import {
  ACQUISITION_TABLE_ID,
  availableIds,
  USER_GLOBAL_TAKEDOWN_TABLE_ID,
  WEB_TABLE_IDS,
} from '../../MonitorAndTakedown/constants';
import { DARK_WEB_TABLE_IDS, DARK_WEB_THREAT_ACTOR_TABLE_ID } from '../../DarkWeb/DarkWebConstants';
import {
  APP_STORE_TABLE_ID,
  CONNECTOR_TABLE_ID,
  DOCUMENTS_TABLE_ID,
  LOGOS_TABLE_ID,
  MANAGED_BY_AFFILIATED,
  MANAGED_BY_BOLSTER,
  MANAGED_BY_ORGANIZATION,
  MARKETPLACE_TABLE_ID,
  SOCIAL_MEDIA_TABLE_ID,
  TRADEMARK_TABLE_ID,
} from '../../Assets_v2/constants';
import AgGridWebTable from './ag-table/AgGridWebTable';
import { SOCIAL_MEDIA_TABLE_IDS } from '../../SocialMedia/constants';
import { MARKET_PLACE_TABLE_IDS } from '../../Marketplace/constants';
import { APP_STORE_TABLE_IDS } from '../../AppStore/constants';
import AgGridDarkWebFlatTable from './ag-table/AgGridDarkWebTableFlatView';
import AgGridClientModeTable from './ag-table/AgGridClientModeTable';
import { SEARCH_TERM_TABLE_ID } from '../../Ugc/constants';
import PersistentFilters from '../PersistentFilters/PersistentFilters';
import AgGridServerModeTable from './ag-table/AgGridServerModeTable';
import { Switch, Tooltip as MUToolTip } from '@material-ui/core';
import {
  setIsWebTablesGroupView,
  setIsExportCSVCalled,
  setShouldRestorePageNumber,
} from '../../../reducers/table.reducer';
import AgGridWebTableFlatView from './ag-table/AgGridWebTableFlatView';
import {
  getResponseAfterAgQueryParamsConversion,
  getSortModelFromLocalStorage,
} from './ag-table/ag-utils';
import UserService from '../../../services/user.service';
import { REPORTS_TABLE_ID } from '../../Reports/ReportsConstant';
import AgGridReportsTable from './ag-table/AgGridReportsTable';
import {
  WEB_PLAYBOOK_TABLE_ID,
  SOCIAL_PLAYBOOK_TABLE_ID,
  DARKWEB_PLAYBOOK_TABLE_ID,
  APP_STORE_PLAYBOOK_TABLE_ID,
} from '../../Playbook/Common/PlaybookConstants';
import AgGridTeamMemberTable from './ag-table/AgGridTeamMemberTable';
import { TEAM_MEMBER_TABLE_ID } from '../../TeamMembers/TeamMemberConstant';
import { PropertyType } from '../../Assets_v2/PropertyTypes';
import AgGridBrandPropertyTable from './ag-table/AgGridBrandPropertyTable';

export const CHECKBOX_COLUMN_WIDTH = 40;

interface IComponentState {
  addingItemRowShown: boolean;
  filterExpanded: boolean;
  isLoading: boolean;
  data: any[];
  selectedItems: any[];
  isAllSelected: boolean;
  activeItem: any;
  total: number;
  pageSize: number;
  currentPageNumber: number;
  sortBy: string;
  sortDirection: TypeSortDirection;
  filters: IFilter[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  displayContent: 'table' | 'tile';
  deleteModalShown: boolean;
  displayEditColumns: boolean;
  displayedColumnsIDs: string[];
  _renderTimeTS: number;
  query?: {
    startDate?: string;
    endDate?: string;
    pageNumber?: number;
    pageSize?: number;
  };
  tagsOptions: { label: string; value: string | number; color: string }[];
}

class Table extends React.Component<ITableProps, IComponentState> {
  private _isMounted = false;
  // for now, only turn on the new ag table for limit modules even the feature flag is on.[
  private tempAgWhiteList = [
    ...WEB_TABLE_IDS,
    ...SOCIAL_MEDIA_TABLE_IDS,
    ...MARKET_PLACE_TABLE_IDS,
    ...APP_STORE_TABLE_IDS,
    ...DARK_WEB_TABLE_IDS,
    DARK_WEB_THREAT_ACTOR_TABLE_ID,
    SEARCH_TERM_TABLE_ID,
    REPORTS_TABLE_ID,
    WEB_PLAYBOOK_TABLE_ID,
    SOCIAL_PLAYBOOK_TABLE_ID,
    DARKWEB_PLAYBOOK_TABLE_ID,
    APP_STORE_PLAYBOOK_TABLE_ID,
    CONNECTOR_TABLE_ID,
    MANAGED_BY_AFFILIATED,
    MANAGED_BY_ORGANIZATION,
    MANAGED_BY_BOLSTER,
    TEAM_MEMBER_TABLE_ID,
    LOGOS_TABLE_ID,
    DOCUMENTS_TABLE_ID,
    TRADEMARK_TABLE_ID,
    MARKETPLACE_TABLE_ID,
    APP_STORE_TABLE_ID,
    SOCIAL_MEDIA_TABLE_ID,
  ];

  private tempPeristFilterBlackList: string[] = [
    SEARCH_TERM_TABLE_ID,
    ACQUISITION_TABLE_ID,
    DARK_WEB_THREAT_ACTOR_TABLE_ID,
    USER_GLOBAL_TAKEDOWN_TABLE_ID,
    REPORTS_TABLE_ID,
    WEB_PLAYBOOK_TABLE_ID,
    SOCIAL_PLAYBOOK_TABLE_ID,
    DARKWEB_PLAYBOOK_TABLE_ID,
    APP_STORE_PLAYBOOK_TABLE_ID,
    CONNECTOR_TABLE_ID,
    MANAGED_BY_AFFILIATED,
    MANAGED_BY_ORGANIZATION,
    MANAGED_BY_BOLSTER,
    TEAM_MEMBER_TABLE_ID,
    LOGOS_TABLE_ID,
    DOCUMENTS_TABLE_ID,
    TRADEMARK_TABLE_ID,
    MARKETPLACE_TABLE_ID,
    APP_STORE_TABLE_ID,
    SOCIAL_MEDIA_TABLE_ID,
  ];

  static contextType = ThemeContext;

  static defaultProps = {
    apiTs: 0,
    tableClassName: '',
    loadingWrapperClassName: '',
    title: '',
    titleComp: null,
    data: [],
    fetchApi: undefined,
    indexBy: '',
    filterAppliedOnFetchApi: false,
    onFilterApplied: _.noop,
    customFilterSystem: null,
    initialFilters: [],
    exportOptions: [],
    exportTableApi: undefined,
    exportDataFn: _.noop,
    editItemFn: undefined,
    deleteItemFn: undefined,
    deleteFn: undefined,
    addItemFn: undefined,
    addText: '',
    onRowDblClick: _.noop,
    initialPageSize: 15,
    initialPageNumber: 1,
    onPageChange: _.noop,
    onSortChange: _.noop,
    enableCheckbox: false,
    onAllCheck: _.noop,
    onItemCheck: _.noop,
    disableToolsBar: false,
    disableDatePicker: false,
    disableFilter: false,
    disablePagination: false,
    enableEditColumns: false,
    onSaveColumns: _.noop,
    sortBy: '',
    sortDirection: undefined,
    startDate: moment().subtract(1, 'week'),
    endDate: moment(),
    onDateChange: _.noop,
    tileComp: null,
    tileCompProperties: [],
    tileTopThreeProps: [],
    tileCompEvents: {},
    showLoader: false,
    customToolsDirection: false,
    hideDeleteBin: false,
  };

  constructor(props: ITableProps) {
    super(props);
    const { initialPageSize, initialPageNumber, initialFilters, data, indexBy } = this.props;
    const pageSize = initialPageSize;

    this.state = {
      addingItemRowShown: false,
      filterExpanded: false,
      isLoading: props.showLoader,
      data: Table.addIndex(data, indexBy),
      selectedItems: props.selectedItems || [],
      isAllSelected: false,
      activeItem: {},
      total: 0,
      filters: initialFilters,
      sortBy: props.sortBy || '',
      sortDirection: props.sortDirection || undefined,
      startDate: props.startDate,
      endDate: props.endDate,
      pageSize,
      currentPageNumber: initialPageNumber,
      displayContent: 'table',
      deleteModalShown: false,
      displayEditColumns: false,
      displayedColumnsIDs: props.displayedColumnsIDs || [],
      _renderTimeTS: moment().valueOf(),
      tagsOptions: [] as { label: string; value: string | number; color: string }[],
    };
  }

  static addIndex = (data: any, indexBy: string) => {
    return _.map(data, i => ({ ...i, _index: i[indexBy] }));
  };

  componentDidMount(): void {
    this._isMounted = true;
    if (
      !featureIsAvailable(this.props.user, appConstants.FEATURE_CODE.AG_GRID_TABLE) ||
      !this.tempAgWhiteList.includes(this.props.id)
    ) {
      this.fetchApi();
    }
    if (this.props.defaultLayout !== undefined) {
      this.setCompState({
        displayContent: this.props.defaultLayout,
      });
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  fetchApi = (opt: any = {}) => {
    const { fetchApi, indexBy } = this.props;
    const { currentPageNumber, pageSize, startDate, endDate, filters, sortBy, sortDirection } =
      this.state;
    const sort = { sortBy, sortDirection };

    if (fetchApi && typeof fetchApi === 'function') {
      const query: any = {
        startDate,
        endDate,
        pageNumber: opt.pageNumber || currentPageNumber,
        pageSize,
      };
      if (this.props.type === appConstants.CONTENT_TYPE.WEB) {
        query.isGroupViewEnabled =
          this.state.displayContent === 'tile' ? false : this.props.isWebTablesGroupView;
      }
      this.setState(
        {
          isLoading: true,
        },
        async () => {
          if (
            featureIsAvailable(this.props.user, appConstants.FEATURE_CODE.AG_GRID_TABLE) &&
            this.tempAgWhiteList.includes(this.props.id)
          ) {
            const sortModel = getSortModelFromLocalStorage(this.props.id);

            // in this we get the response with the filter applied;
            const response: { data: any[]; total: number } =
              await getResponseAfterAgQueryParamsConversion(
                this.props.id,
                pageSize,
                // currentPageNumber is 1 based, but api accepts 0 based page number.
                opt.pageNumber || currentPageNumber - 1,
                this.props.type,
                sortModel,
                this.props.ugcStatus,
              );
            this.setCompState({
              isLoading: false,
              data: _.map(response.data, (i: any) => ({ _index: i[indexBy], ...i })),
              total: response.total,
              _renderTimeTS: moment().valueOf(),
              selectedItems: [],
              isAllSelected: false,
              query,
              sortBy: sortModel.sortBy,
              sortDirection: sortModel.sortDirection,
            });
          } else {
            fetchApi(query, filters, sort).then((res: any) => {
              const { data, total } = res;
              this.setCompState({
                isLoading: false,
                data: _.map(data, i => ({ _index: i[indexBy], ...i })),
                total,
                currentPageNumber: opt.pageNumber || currentPageNumber,
                _renderTimeTS: moment().valueOf(),
                selectedItems: [],
                isAllSelected: false,
                query,
              });
            });
          }
        },
      );
    }
  };

  getFilteredSortedData = () => {
    const { columns, filterAppliedOnFetchApi } = this.props;
    const { data, sortBy, sortDirection, filters } = this.state;
    let filteredData = data.concat();
    if (!filterAppliedOnFetchApi && !_.isEmpty(filters)) {
      filters.forEach((filter: IFilter) => {
        const { filterBy, filterMethod, filterValue } = filter;
        const filterByValue = filterBy.value;
        if (!_.isEmpty(filterBy) && !_.isEmpty(filterMethod) && !_.isEmpty(filterValue)) {
          filteredData = _.filter(filteredData, (item: any) => {
            const columnData = _.get(item, filterByValue);
            let filterValueData = filterValue;
            let columnDataValue = columnData;
            if (_.isArray(columnDataValue)) {
              columnDataValue = columnDataValue.join(',');
            }
            if (_.isString(columnDataValue)) {
              columnDataValue = columnDataValue.toLocaleLowerCase();
            }
            if (_.isString(filterValueData)) {
              filterValueData = filterValueData.toLocaleLowerCase();
            }
            switch (filterMethod.value) {
              case 'includes':
                return columnDataValue && columnDataValue.indexOf(filterValueData) !== -1;
              case 'notIncludes':
                // make sure rows with empty value could be returned
                return columnDataValue != null && columnDataValue.indexOf(filterValueData) === -1;
              case 'is':
                return (
                  columnDataValue?.toString() &&
                  columnDataValue?.toString().toLowerCase() ===
                    filterValueData?.toString().toLowerCase()
                );
              case 'isNot':
                return (
                  columnDataValue?.toString() &&
                  columnDataValue?.toString().toLowerCase() !==
                    filterValueData?.toString().toLowerCase()
                );
              case 'numberRange': {
                const [min, max] = filterValueData.split(',');
                return (
                  _.isNumber(columnDataValue) &&
                  columnDataValue >= parseInt(min.trim(), 10) &&
                  columnDataValue <= parseInt(max.trim(), 10)
                );
              }
              case 'dateRange': {
                const [startDate, endDate] = filterValueData.split(',');
                return columnDataValue >= startDate && columnDataValue <= endDate;
              }
              case 'beginWith': {
                return columnDataValue && columnDataValue.toString().startsWith(filterValueData);
              }
              case 'notBeginWith': {
                return (
                  columnDataValue &&
                  !columnDataValue.toString().startsWith(filterValueData.toString())
                );
              }
              default:
                break;
            }
          });
        }
      });
    }

    let sortedData;
    if (!_.isEmpty(sortBy) && !_.isEmpty(sortDirection)) {
      let sortingFn;
      const sortColumn: any = _.find(columns, (column: any): any => {
        if (column && column.accessor) {
          return column.accessor === sortBy;
        }
        return false;
      });
      if (sortColumn) {
        if (sortColumn.sortingFn) {
          sortingFn = sortColumn.sortingFn;
        } else if (sortColumn && sortColumn.type === 'ipv4') {
          sortingFn = ipv4SortingFunction;
        }
      }
      if (sortingFn) {
        sortedData = filteredData.sort(sortingFn);
        if (sortDirection === 'des' || sortDirection === 'desc') {
          sortedData = _.reverse(sortedData);
        }
      } else {
        sortedData = dashedSortingFunction(filteredData, sortBy, sortDirection);
      }
    } else {
      sortedData = filteredData;
    }

    return sortedData;
  };

  exportTable = (type: TExportTable) => {
    const { currentPageNumber, selectedItems, startDate, endDate, filters, sortBy, sortDirection } =
      this.state;
    const { exportTableApi, exportDataFn } = this.props;
    const total = this.props.total || this.state.total;
    const sort = { sortBy, sortDirection };
    const query = {
      startDate,
      endDate,
      pageNumber: currentPageNumber - 1,
      pageSize: total,
    };

    //Ag grid client mode export
    if (!this.props.isOldTable && this.props.isAgGridClientMode) {
      if (selectedItems.length) {
        exportDataFn && exportDataFn(selectedItems, type);
      } else {
        this.props.setIsExportCSVCalled(true);
      }
    } else {
      exportTableApi && exportTableApi(query, type, filters, sort);

      // TODO for export csv in client side mode old table use this
      //selecteditem is if user selected the particular rows to export
      //this.getFilteredSortedData() is if user want to export the filtered and sorted data in ui side
      const exportedData = selectedItems?.length ? selectedItems : this.getFilteredSortedData();
      exportDataFn && exportDataFn(exportedData, type);
    }
  };

  onToggleFilter = (toExpand: boolean): void => {
    this.setState({
      filterExpanded: toExpand,
    });
  };

  onFiltersApplied = (filters: IFilter[]): void => {
    this.setState(
      {
        filters,
        currentPageNumber: 1,
      },
      () => {
        if (this.props.filterAppliedOnFetchApi) {
          this.fetchApi();
        }
        if (this.props.onFilterApplied) {
          this.props.onFilterApplied(filters);
        }
      },
    );
  };

  onFiltersCancelled = (filters: IFilter[]): void => {
    this.setState({
      filters,
      _renderTimeTS: moment().valueOf(),
    });
  };

  onDateChange = (startDate: moment.Moment, endDate: moment.Moment, lastXDay: number) => {
    this.setState(
      {
        startDate,
        endDate,
        currentPageNumber: 1,
      },
      this.fetchApi,
    );
    if (this.props.onDateChange) {
      this.props.onDateChange(startDate, endDate, lastXDay);
    }
  };

  //Extract this function to a custom hook after table component is converted to functional component
  onGroupViewSwitch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const userService = new UserService();
    this.props.setIsWebTablesGroupView(e.target.checked);
    try {
      const res = await userService.updateUserSettings({
        setting_name: UserAppSettings.GroupFindings,
        setting_value: e.target.checked,
      });

      if (res) {
        this.props.alertSuccess?.('User settings updated successfully');
        this.props.getUserInfo();
      }
    } catch (error) {
      this.props.alertError?.('Failed to update user settings');
    }
  };

  onSortChange = (sortBy: string, sortDirection: TypeSortDirection) => {
    this.setState(
      {
        sortBy,
        sortDirection,
      },
      this.fetchApi,
    );
    if (this.props.onSortChange) {
      this.props.onSortChange({ sortBy, sortDirection });
    }
  };

  onPageChange = (pageNumber: number) => {
    this.setState(
      {
        currentPageNumber: pageNumber,
      },
      this.fetchApi,
    );
    this.props.onPageChange(pageNumber);
    window.history.pushState({ prevUrl: window.location.pathname }, '', '');
    // sync back to ag grid
    setLocalStorageValue(['tablePageNumber', this.props.id], pageNumber - 1);
  };

  onAllCheck = (checked: boolean) => {
    const { indexBy, id, type, findingsTableUrls, setFindingsTableUrls } = this.props;
    const { selectedItems, data } = this.state;
    let newSelectedItems: any[];
    const currentPageItems = this.getFilteredSortedData();
    const notAllowedIds = [
      ...DARK_WEB_TABLE_IDS,
      'bulk-scan-table',
      MANAGED_BY_ORGANIZATION,
      ACQUISITION_TABLE_ID,
      MANAGED_BY_AFFILIATED,
    ];
    const isNotAllowed = notAllowedIds.includes(id);
    if (
      (featureIsAvailable(this.props.user, appConstants.FEATURE_CODE.CROSS_PAGE) &&
        !isNotAllowed) ||
      (this.props.user && isInternalUser(this.props.user) && !isNotAllowed)
    ) {
      if (checked) {
        if (findingsTableUrls && type && id) {
          newSelectedItems = _.unionBy(findingsTableUrls[type][id], currentPageItems, indexBy);
          setFindingsTableUrls(id, newSelectedItems, type);
        }
        this.setState({
          isAllSelected: true,
        });
      } else {
        if (findingsTableUrls && type && id) {
          newSelectedItems = _.filter(findingsTableUrls[type][id], (item: any) => {
            return !_.some(currentPageItems, ['_index', item._index]);
          });
          setFindingsTableUrls(id, newSelectedItems, type);
        }
        this.setState({
          isAllSelected: false,
        });
      }
    } else {
      if (checked) {
        newSelectedItems = [];
        const { data } = this.state;
        for (let i = 0; i < data.length; i++) {
          const item: any = Object.assign(
            { isNewAdded: false, isDisabled: false, _index: '' },
            data[i],
          );
          const domainName = data[i]['domain'];
          if (!item._index && domainName) {
            item._index = domainName;
          }
          newSelectedItems.push(item);
        }
        this.setState({
          isAllSelected: true,
          selectedItems: newSelectedItems,
        });
      } else {
        newSelectedItems = [];
        this.setState({
          isAllSelected: false,
          selectedItems: newSelectedItems,
        });
      }
      this.props.onAllCheck(checked, newSelectedItems);
      this.props.onItemCheck(checked, undefined, newSelectedItems);
    }
  };

  onItemCheck = (checked: boolean, item: any) => {
    const { selectedItems } = this.state;
    let newSelectedItems = selectedItems.concat();
    if (checked) {
      newSelectedItems.push(item);
    } else {
      newSelectedItems = _.filter(selectedItems, (selectedItem: any) => {
        return selectedItem._index !== item._index;
      });
    }
    this.setState({
      isAllSelected:
        this.props.type === appConstants.CONTENT_TYPE.WEB
          ? false
          : this.checkIfAllSelected(newSelectedItems),
      selectedItems: newSelectedItems,
    });

    this.props.onItemCheck(checked, item, newSelectedItems);
  };

  checkIfAllSelected = (selectedItems: any[]): boolean => {
    const currentPageItems = this.getFilteredSortedData();
    for (let i = 0; i < currentPageItems.length; i++) {
      if (!_.some(selectedItems, ['_index', currentPageItems[i]._index])) {
        return false;
      }
    }
    return true;
  };

  onToggleDisplayContent = (isOldTable: boolean) => {
    const { displayContent } = this.state;
    const activeLayout = displayContent === 'table' ? 'tile' : 'table';
    this.props.handleDisplayLayout && this.props.handleDisplayLayout(activeLayout);
    this.setState(
      {
        displayContent: activeLayout,
      },
      () => {
        if (this.props.refreshOnTag) {
          this.fetchApi();
        } else if (
          (this.props.isWebTablesGroupView || !isOldTable) &&
          this.state.displayContent === 'tile'
        ) {
          this.resetPageOnViewChanged();
          if (!isOldTable && !this.props.isWebTablesGroupView) {
            const pageNumber = getLocalStorageValue(['tablePageNumber', this.props.id], 0);
            const sortModelCache = getSortModelFromLocalStorage(this.props.id);

            // currentPageNumber means tile view page number, which is 1 based. But pageNumber is 0 based from ag grid.
            // api accepts 0 based page number.
            this.setCompState({
              currentPageNumber: pageNumber + 1,
              sortBy: sortModelCache.sortBy,
              sortDirection: sortModelCache.sortDirection,
            });
            this.fetchApi({ pageNumber });
          } else {
            this.fetchApi({ pageNumber: 0 });
          }
        }
        this.props.onLayoutChanged && this.props.onLayoutChanged();
      },
    );
  };

  onToggleEditColumns = () => {
    const { displayEditColumns } = this.state;
    this.setState({
      displayEditColumns: !displayEditColumns,
    });
  };

  toggleDeleteModal = (activeItem?: any) => {
    const { deleteModalShown } = this.state;
    this.setState({
      activeItem,
      deleteModalShown: !deleteModalShown,
    });
  };

  onSubmitColumnsConfig = (displayedColumnsIDs: string[]) => {
    this.setCompState({
      displayedColumnsIDs,
      displayEditColumns: false,
    });
    this.props.onSaveColumns && this.props.onSaveColumns(displayedColumnsIDs);
  };

  static getDerivedStateFromProps = (nextProps: ITableProps) => {
    const newState: any = {};

    if (!nextProps.fetchApi || typeof nextProps.fetchApi !== 'function') {
      newState.data = Table.addIndex(nextProps.data, nextProps.indexBy);
    }
    if (nextProps.startDate && nextProps.endDate && nextProps.disableDatePicker) {
      newState.startDate = nextProps.startDate;
      newState.endDate = nextProps.endDate;
      newState._renderTimeTS = moment().valueOf();
    }
    newState.displayedColumnsIDs = nextProps.displayedColumnsIDs;

    return newState;
  };

  shouldComponentUpdate(
    nextProps: Readonly<ITableProps>,
    nextState: Readonly<IComponentState>,
  ): boolean {
    // return true;
    return (
      !_.isEqual(this.props.data, nextProps.data) ||
      this.props.providedTags !== nextProps.providedTags ||
      this.props.columns !== nextProps.columns ||
      (this.props.apiTs !== nextProps.apiTs &&
        nextProps.fetchApi &&
        typeof nextProps.fetchApi === 'function') ||
      this.state.isLoading !== nextState.isLoading ||
      this.state.displayEditColumns !== nextState.displayEditColumns ||
      this.state.displayedColumnsIDs !== nextState.displayedColumnsIDs ||
      this.state._renderTimeTS !== nextState._renderTimeTS ||
      this.state.filterExpanded !== nextState.filterExpanded ||
      this.state.data.length !== nextState.data.length ||
      !_.isEqual(this.state.data, nextState.data) ||
      this.state.selectedItems !== nextState.selectedItems ||
      this.props.selectedItems !== nextProps.selectedItems ||
      this.state.total !== nextState.total ||
      !_.isEqual(this.state.filters, nextState.filters) ||
      this.state.sortBy !== nextState.sortBy ||
      this.state.sortDirection !== nextState.sortDirection ||
      this.state.pageSize !== nextState.pageSize ||
      this.state.currentPageNumber !== nextState.currentPageNumber ||
      this.state.displayContent !== nextState.displayContent ||
      this.state.deleteModalShown !== nextState.deleteModalShown ||
      this.props.refreshOnTag !== nextProps.refreshOnTag ||
      !_.isEqual(this.props.tagsAddedPerSha, nextProps.tagsAddedPerSha) ||
      this.props.isWebTablesGroupView !== nextProps.isWebTablesGroupView
    );
  }

  componentDidUpdate(prevProps: Readonly<ITableProps>, prevState: Readonly<IComponentState>): void {
    if (
      !featureIsAvailable(this.props.user, appConstants.FEATURE_CODE.AG_GRID_TABLE) ||
      !this.tempAgWhiteList.includes(this.props.id)
    ) {
      if (
        this.props.startDate.format('YYYY-MM-DD') !== prevProps.startDate.format('YYYY-MM-DD') ||
        this.props.endDate.format('YYYY-MM-DD') !== prevProps.endDate.format('YYYY-MM-DD')
      ) {
        this.fetchApi({ pageNumber: 1 });
      }
      if (prevProps.providedTags !== this.props.providedTags) {
        const { type, providedTags } = this.props;
        const { selectedTheme } = this.context;

        if (providedTags) {
          const tagOptions: { label: string; value: string | number; color: string }[] = [];
          const filterTags = !featureIsAvailable(this.props.user, [
            appConstants.FEATURE_CODE.WEB_SOC_TAKEDOWN,
          ])
            ? providedTags.filter((tag: any) => tag.type !== ETagsTypes.BOLSTER_RESTRICTED)
            : providedTags;

          filterTags.forEach((tag: ITags) => {
            const labelAndValue: { label: string; value: string | number; color: string } = {
              label: '',
              value: '',
              color: '',
            };
            labelAndValue['label'] = tag.label;
            labelAndValue['value'] = tag.id;
            labelAndValue['color'] =
              selectedTheme === ThemeModes.DARK.toLowerCase()
                ? tag.color.darkColor
                : tag.color.lightColor;
            tagOptions.push(labelAndValue);
          });
          this.setState({ tagsOptions: tagOptions });
        }
      }
      if (prevProps.apiTs !== this.props.apiTs) {
        this.fetchApi();
      }
      if (prevProps.sortBy !== this.props.sortBy) {
        this.setCompState({
          sortBy: this.props.sortBy,
        });
      }

      if (
        !_.isEqual(prevProps.data, this.props.data) ||
        prevProps.showLoader !== this.props.showLoader
      ) {
        this.setCompState({
          isLoading: this.props.showLoader,
        });
      }
      if (prevProps.user !== this.props.user) {
        this.fetchApi();
      }
      if (
        this.props.isWebTablesGroupView &&
        this.state.displayContent !== prevState.displayContent
      ) {
        this.resetPageOnViewChanged();
        this.fetchApi({ pageNumber: 1 });
      }
      if (
        window?.history?.state?.prevUrl !== window.location.pathname &&
        window?.history?.state?.prevUrl &&
        !window?.location?.pathname?.includes('insights') &&
        !window?.history?.state?.prevUrl?.includes('insights')
      ) {
        window.history.pushState({ prevUrl: window.location.pathname }, '', '');
        this.fetchApi({ pageNumber: 1 });
      }
      if (this.state.total !== prevState.total) {
        if (this.state.total % this.state.pageSize === 0 && this.state.currentPageNumber > 1) {
          this.fetchApi({ pageNumber: this.state.currentPageNumber - 1 });
        }
      }
      const { findingsTableUrls, type, id } = this.props;
      const notAllowedIds = [
        ...DARK_WEB_TABLE_IDS,
        'bulk-scan-table',
        MANAGED_BY_ORGANIZATION,
        ACQUISITION_TABLE_ID,
      ];
      const isNotAllowed = notAllowedIds.includes(id);
      if (
        (featureIsAvailable(this.props.user, appConstants.FEATURE_CODE.CROSS_PAGE) &&
          !isNotAllowed) ||
        (this.props.user && isInternalUser(this.props.user) && !isNotAllowed)
      ) {
        if (findingsTableUrls && type && id && availableIds.includes(id)) {
          const isAllChecked = findingsTableUrls[type][id];
          const multipleExist = this.state.data.every(value => {
            return isAllChecked.find(
              (array1Object: { url_sha256: string }) =>
                array1Object.url_sha256 === value.url_sha256,
            );
          });
          if (this.state.isAllSelected !== multipleExist && this.state.data.length !== 0) {
            this.setState({ isAllSelected: multipleExist });
          }
        }
      }
    }

    if (
      prevProps.selectedItems !== this.props.selectedItems ||
      (this.props.selectedItems != null && this.props.selectedItems !== this.state.selectedItems)
    ) {
      this.setCompState({
        selectedItems: this.props.selectedItems,
      });
    }
  }

  onClickAddItem = () => {
    this.setState({
      addingItemRowShown: !this.state.addingItemRowShown,
    });
  };

  renderDeleteModal = () => {
    const { deleteModalConfig } = this.props;
    const { deleteModalShown, selectedItems, activeItem } = this.state;
    const { selectedTheme } = this.context;

    if (_.isEmpty(deleteModalConfig)) {
      return null;
    }

    let deletingItems = selectedItems;
    if (!_.isEmpty(activeItem)) {
      deletingItems = [activeItem];
    }

    const { title, renderBody } = deleteModalConfig;

    return (
      <Modal
        show={deleteModalShown}
        size='lg'
        onHide={this.toggleDeleteModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className={`${selectedTheme}`}
      >
        <Modal.Header>
          <Modal.Title id='download-via-email'>{title}</Modal.Title>
          <div onClick={this.toggleDeleteModal}>
            <CloseIcon color='grey' />
          </div>
        </Modal.Header>
        <Modal.Body className={'modal-body'}>{renderBody(deletingItems)}</Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={this.toggleDeleteModal}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              this.props.deleteFn?.(deletingItems);
              this.setState({
                selectedItems: [],
                activeItem: undefined,
                deleteModalShown: false,
              });
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderAddItemController = () => {
    return (
      <div
        className={'add-item-container d-flex align-items-center'}
        onClick={this.onClickAddItem}
        data-testid='add-item-container'
      >
        <AddBlue color='blue' />
        <span>{this.props.addText}</span>
      </div>
    );
  };

  resetPageOnViewChanged = () => {
    this.setState({
      currentPageNumber: 1,
      pageSize: this.state.pageSize,
    });
  };

  render() {
    const {
      tableClassName,
      loadingWrapperClassName,
      fetchApi,
      exportOptions,
      exportTableApi,
      exportDataFn,
      addItemFn,
      deleteFn,
      title,
      titleTooltip,
      titleComp,
      columns,
      filterAppliedOnFetchApi,
      customFilterSystem,
      customFilterSystemProps,
      disableToolsBar,
      disableDatePicker,
      disableFilter,
      disablePagination,
      enableEditColumns,
      onRowDblClick,
      tileComp,
      tileCompEvents,
      tileCompProperties,
      tileTopThreeProps,
      customTools,
      summaryWidget,
      indexBy,
      allUserAgents,
      customToolsDirection,
      hideDeleteBin,
      alertSuccess,
      alertError,
    } = this.props;
    const {
      filterExpanded,
      isLoading,
      data,
      selectedItems,
      pageSize,
      currentPageNumber,
      startDate,
      endDate,
      sortBy,
      sortDirection,
      filters,
      displayContent,
      displayEditColumns,
    } = this.state;
    let { displayedColumnsIDs } = this.state;
    const { selectedTheme } = this.context;
    const { hideHeader = false } = this.props;
    let shownColumns = _.filter(columns, column => !column['hidden']);
    if (_.isEmpty(displayedColumnsIDs)) {
      displayedColumnsIDs = _.map(shownColumns, column => column['id']);
    } else {
      shownColumns = [];
      _.forEach(displayedColumnsIDs, name => {
        const column = _.find(columns, column => !column['hidden'] && column['id'] === name);
        if (column) {
          shownColumns.push(column);
        }
      });
    }
    const total = this.props.total || this.state.total;

    const isOldTable =
      !featureIsAvailable(this.props.user, appConstants.FEATURE_CODE.AG_GRID_TABLE) ||
      this.props.isOldTable ||
      !this.tempAgWhiteList.includes(this.props.id);

    const isPersistentFilterEnabled = featureIsAvailable(this.props.user, [
      appConstants.FEATURE_CODE.PERSISTENT_FILTERS,
    ]);
    let tools = [];
    let onClickFn = this.toggleDeleteModal;
    let classNames = ' tool-buttons-wrapper';
    if (selectedItems.length <= 0) {
      classNames += ' disabled';
      onClickFn = _.noop;
    }

    if (deleteFn && !hideDeleteBin) {
      tools.push(
        <div
          key={'delete-button'}
          className={classNames}
          onClick={() => {
            onClickFn();
          }}
        >
          <BinIcon color='grey' />
        </div>,
      );
    }
    if (
      !isOldTable &&
      !this.tempPeristFilterBlackList.includes(this.props.id) &&
      isPersistentFilterEnabled &&
      this.state.displayContent === 'table'
    ) {
      tools.push(<PersistentFilters tableId={this.props.id} moduleType={this.props.type} />);
    }

    if (tileComp) {
      const isTableView = displayContent === 'table';

      tools.push(
        <div
          id='layout-toggle'
          data-testid='layout-toggle'
          aria-disabled={isLoading}
          className={`tool-buttons-wrapper ${isLoading ? 'is-disabled' : ''}`}
          key={'toggle-display'}
          onClick={() => {
            if (!isTableView) {
              this.props.setShouldRestorePageNumber(true);
            }
            this.onToggleDisplayContent(isOldTable);
          }}
        >
          {isTableView ? <GridViewIcon color='grey' /> : <ListViewIcon color='grey' />}
        </div>,
      );
    }

    if (enableEditColumns && isOldTable) {
      tools.push(
        <div
          id='columns-setting'
          data-testid='columns-setting'
          aria-disabled={isLoading}
          className={`tool-buttons-wrapper ${isLoading ? 'is-disabled' : ''}`}
          key={'columns-setting'}
          onClick={() => {
            this.onToggleEditColumns();
          }}
        >
          <SettingIcon color='grey' />
        </div>,
      );
    }

    if (
      (!!exportTableApi && exportTableApi !== _.noop) ||
      (!!exportDataFn && exportDataFn !== _.noop)
    ) {
      tools.push(
        <div
          key={'Export CSV'}
          onClick={() => this.exportTable('csv')}
          className='export-wrapper d-flex align-items-center tool-buttons-wrapper'
        >
          <ExportIcon color={'grey'} />
          <div className='pl-2 export-label'>Export CSV</div>{' '}
        </div>,
      );
    }

    if (!disableDatePicker && isOldTable) {
      tools.push(
        <DropdownDateRangePicker
          onChange={this.onDateChange}
          key='DropdownDateRangePicker'
          startDate={startDate}
          endDate={endDate}
          type={this.props.type}
        />,
      );
    }

    if (
      !isOldTable &&
      this.props.type === appConstants.CONTENT_TYPE.WEB &&
      this.state.displayContent === 'table'
    ) {
      tools.push(
        <MUToolTip title='Switch to group view of results'>
          <Switch
            checked={this.props.isWebTablesGroupView}
            onChange={this.onGroupViewSwitch}
            color='primary'
          />
        </MUToolTip>,
      );
    }

    if (customTools) {
      if (customToolsDirection) {
        tools = customTools.concat(tools);
      } else {
        tools = tools.concat(customTools);
      }
    }

    let totalRows = total || data.length;
    const firstRowIndex = (currentPageNumber - 1) * pageSize;
    let lastRowIndex = Math.min(currentPageNumber * pageSize, totalRows);
    const filteredSortedData = this.getFilteredSortedData();
    if (!filterAppliedOnFetchApi && !_.isEmpty(filters)) {
      totalRows = filteredSortedData.length;
      lastRowIndex = Math.min(currentPageNumber * pageSize, totalRows);
    }

    const displayData =
      fetchApi || disablePagination
        ? filteredSortedData
        : filteredSortedData.slice(firstRowIndex, lastRowIndex);

    let toolsContainerClassName = 'tools-container d-flex justify-content-end align-content-center';
    if (this.props.type === appConstants.CONTENT_TYPE.WEB) {
      toolsContainerClassName += ' no-max-width';
    }
    if (filterExpanded) {
      toolsContainerClassName += ' expanded';
    }
    const tableToolbar = (
      <div
        className={
          this.props.type === appConstants.CONTENT_TYPE.WEB
            ? 'tool-bar-container flex-down'
            : 'tool-bar-container'
        }
        data-testid='tool-bar-container'
      >
        {titleComp ? (
          titleComp
        ) : (
          <div className='d-flex'>
            <PageTitle title={title} className='table-title' />
            {this.props.isWebTablesGroupView && displayContent === 'table' && (
              <div className='sub-title'>{'( Grouped View )'}</div>
            )}
          </div>
        )}
        <div className={toolsContainerClassName}>
          {!disableFilter && isOldTable && (
            <TableFilterController
              filters={filters}
              columns={columns}
              onFiltersApplied={this.onFiltersApplied}
              onFiltersCancelled={this.onFiltersCancelled}
              onToggleFilter={this.onToggleFilter}
              selectedTheme={selectedTheme}
              tableLoading={isLoading}
              type={this.props.type}
              tagsOptions={this.state.tagsOptions}
            />
          )}
          {displayEditColumns && (
            <TableColumnsController
              columns={_.filter(columns, col => col?.['hidden'] !== true)}
              onClose={this.onToggleEditColumns}
              displayedColumnsIDs={displayedColumnsIDs}
              onSubmitColumnsConfig={this.onSubmitColumnsConfig}
              type={this.props.type}
              resetPageOnViewChanged={this.resetPageOnViewChanged}
            />
          )}
          {!_.isEmpty(tools) && tools}
        </div>
      </div>
    );

    const CustomFilterSystem = customFilterSystem;
    const isDarkWebTable =
      DARK_WEB_TABLE_IDS.includes(this.props.id) ||
      this.props.id === DARK_WEB_THREAT_ACTOR_TABLE_ID;

    const isWebModule = this.props.type === appConstants.CONTENT_TYPE.WEB;

    const isReportModule = this.props.type === appConstants.CONTENT_TYPE.REPORT;
    const isTeamMemberModule = this.props.type === appConstants.CONTENT_TYPE.TEAM_MEMBERS;
    const isBrandPropertyModule =
      this.props.type === PropertyType.APP_STORE ||
      this.props.type === PropertyType.LOGO ||
      this.props.type === PropertyType.SOCIAL ||
      this.props.type === PropertyType.TRADEMARK ||
      this.props.type === PropertyType.DOCUMENT ||
      this.props.type === PropertyType.MARKETPLACE;
    return (
      <div className={'table-component ' + tableClassName}>
        {!disableToolsBar && tableToolbar}
        {this.renderDeleteModal()}
        {summaryWidget && summaryWidget}
        {customFilterSystem && (
          <CustomFilterSystem
            {...customFilterSystemProps}
            onFiltersApplied={this.onFiltersApplied}
            appliedFilters={filters}
          />
        )}
        <LoadingWrapper
          isLoading={isOldTable ? isLoading : false}
          className={'table-loading-spinner ' + loadingWrapperClassName}
        >
          {displayContent === 'table' ? (
            <div className={'bs-table-container'} data-testid='bs-table-container'>
              {isOldTable ? (
                <BsTable
                  variant={
                    selectedTheme === ThemeModes.DARK.toLowerCase()
                      ? ThemeModes.DARK.toLowerCase()
                      : ThemeModes.LIGHT.toLowerCase()
                  }
                >
                  {!hideHeader && (
                    <TableHeader
                      {...this.props}
                      columns={shownColumns}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      onSortChange={this.onSortChange}
                      onAllCheck={this.onAllCheck}
                      isAllSelected={this.state.isAllSelected}
                    />
                  )}
                  <TableBody
                    {...this.props}
                    columns={shownColumns}
                    onRowDblClick={onRowDblClick}
                    data={displayData}
                    pageSize={pageSize}
                    selectedItems={selectedItems}
                    onCheck={this.onItemCheck}
                    toggleDeleteModal={this.toggleDeleteModal}
                    addingItemRowShown={this.state.addingItemRowShown}
                    onCloseAddItemRowShown={() => this.setState({ addingItemRowShown: false })}
                  />
                </BsTable>
              ) : this.props.isAgGridClientMode && !isTeamMemberModule ? (
                <AgGridClientModeTable
                  columns={columns as any}
                  tableId={this.props.id}
                  rowData={this.props.data}
                  onAcquisitionItemCheck={this.props.onItemCheck}
                  selectedAcquisitionItems={selectedItems}
                />
              ) : isDarkWebTable ? (
                <AgGridDarkWebFlatTable
                  columns={columns as any}
                  key={this.props.apiTs}
                  darkwebStatus={this.props.darkwebStatus}
                />
              ) : isWebModule ? (
                this.props.isWebTablesGroupView ? (
                  <AgGridWebTable
                    columns={columns as any}
                    tableId={this.props.id}
                    enableCheckbox={this.props.enableCheckbox}
                    moduleType={this.props.type || ''}
                  />
                ) : (
                  <AgGridWebTableFlatView
                    columns={columns as any}
                    tableId={this.props.id}
                    enableCheckbox={this.props.enableCheckbox}
                    moduleType={this.props.type || ''}
                  />
                )
              ) : isReportModule ? (
                <AgGridReportsTable columns={columns as any} tableId={this.props.id} />
              ) : isTeamMemberModule ? (
                <AgGridTeamMemberTable
                  columns={columns as any}
                  tableId={this.props.id}
                  rowData={this.props.data}
                />
              ) : isBrandPropertyModule && this.props.brandPropertyId ? (
                <AgGridBrandPropertyTable
                  columns={columns as any}
                  tableId={this.props.id}
                  moduleType={this.props.type as string}
                  brandPropertyId={this.props.brandPropertyId}
                />
              ) : (
                <AgGridServerModeTable
                  columns={columns as any}
                  tableId={this.props.id}
                  moduleType={this.props.type || ''}
                  ugcStatus={this.props.ugcStatus}
                />
              )}
            </div>
          ) : (
            <LoadingWrapper
              isLoading={isLoading}
              className={'table-loading-spinner ' + loadingWrapperClassName}
            >
              <div className={'tiles-container'} data-testid='tiles-container'>
                {displayData.map((item, i) => {
                  const Comp = tileComp;
                  return (
                    <Comp
                      key={i}
                      userType={this.props?.userType ? this.props.userType : null}
                      data={item}
                      enableCheckbox={this.props.enableCheckbox}
                      selectedItems={selectedItems}
                      onItemCheck={this.onItemCheck}
                      events={tileCompEvents}
                      topThreeProps={tileTopThreeProps}
                      properties={_.map(tileCompProperties, (property, index) => {
                        const column: any = _.find(columns, ['id', property.id]);
                        if (!column) {
                          return undefined;
                        }
                        const value = item?.[column?.accessor] || DISPLAYED_NULL;
                        let renderDom = null;
                        if (column.render) {
                          renderDom = column.render(item);
                        }
                        return {
                          ...column,
                          id: column.id,
                          label: column.header,
                          value,
                          renderDom,
                          copyButton: property.copyButton,
                          noMarginBottom: index + 4 >= tileCompProperties.length,
                        };
                      })}
                      allUserAgents={allUserAgents}
                    />
                  );
                })}
              </div>
            </LoadingWrapper>
          )}
        </LoadingWrapper>
        {!disablePagination && (isOldTable || displayContent !== 'table') && (
          <TablePaginationController
            total={totalRows}
            pageSize={pageSize}
            currentPageNumber={currentPageNumber}
            firstRowIndex={firstRowIndex}
            lastRowIndex={lastRowIndex}
            onPageChange={this.onPageChange}
          />
        )}
        {/* {addItemFn && this.renderAddItemController()} */}
      </div>
    );
  }
}
const mapStateToProps = (state: AppState, contentType: any) => {
  const { findingsTableUrls, user } = state.dashboardReducer;
  const { isAgGridClientMode, isWebTablesGroupView } = state.tableReducer;

  return { findingsTableUrls, isAgGridClientMode, isWebTablesGroupView, user };
};
const mapDispatchToProps = {
  setFindingsTableUrls: dashboardActions.setFindingsTableUrls,
  alertError: alertActions.error,
  alertSuccess: alertActions.success,
  setIsWebTablesGroupView,
  setIsExportCSVCalled,
  setShouldRestorePageNumber,
  getUserInfo: dashboardActions.getUserInfo,
};

const connectedTable = connect(mapStateToProps, mapDispatchToProps)(Table);
export { connectedTable as Table };

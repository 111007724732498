import { DomainCountLabelEnum } from '../components/Checkphish/TypoSquattingDashboardV2/DashboardContainer';
import { TypoJobStatus } from '../components/DomainMonitoring/constant';

export interface TypoSquatDashboardAnalytics {
  domainCounts: DomainCount[];
  topFeedsScannedForUser: TopFeedsScannedForUser[];
  topDispositionScans: TypoDispositionScan[];
  monitoredDomain: string;
  status: string;
}

export interface DomainCount {
  type: DomainCountLabelEnum;
  label: string;
  count: number;
  categories?: string[];
  subCount?: SubCount;
}

export interface SubCount {
  type: any;
  label: string;
  count: number;
  categories: any;
  subCount: any;
}

export interface TopFeedsScannedForUser {
  id: number;
  name: string;
  label: string;
  iconLightUrl: string;
  iconDarkUrl: string;
  colorCodingLight: string;
  colorCodingDark: string;
  displayOrder: number;
  createdTs: string;
  updatedTs: string;
  count: number;
}

export interface TypoDispositionScan {
  id: number;
  typo_domain_job_id: number;
  domain: string;
  domain_sha: string;
  src_url: string;
  url_sha: string;
  status: string;
  ipv4: string[];
  mx?: string[];
  ns: string[];
  created_ts: string;
  updated_ts: string;
  disposition: string;
  takedown_enquiry: boolean;
  registrar?: string;
  domain_registration_date?: string;
  risk_score: number;
  matrix_algorithm: string;
  final_category: string;
  has_mx_records: boolean;
  as_description: string;
  host_phish_count: number;
  source: string;
  ssl_cert_provider?: string;
}

export interface TyposquatFetchResults {
  result: TypoDispositionScan[];
  count: number;
}

export interface UserTyposquatConfigMetadata {
  paidUser: boolean;
  usedDomainMonitoringScanLimit: number;
  totalDomainMonitoringScanLimit: number;
}

export interface AggregatedTyposquatFetchResults {
  scanResults: Record<string, UserTypoSquatFetchResults>;
  metadata: UserTyposquatConfigMetadata;
}

export interface AggregatedTyposquatFetchResultsDashboard {
  scanResults: Record<string, any>;
  metadata: UserTyposquatConfigMetadata;
}

export interface TyposquattingDomain {
  value?: string;
  status?: TypoJobStatus;
}

export interface UserTypoSquatFetchResults {
  monitoredDomain: string;
  typosquattingResult: TyposquatFetchResults;
  status: string;
  metadata: Record<string, any>;
  lastScanTs: string;
  nextScanTs: string;
}

export enum CHANGE_TYPES {
  NEW_SUSPICIOUS = 'new_suspicious',
  NEW_SCAM = 'new_scam',
  NEW_PHISH = 'new_phish',
  MX_RECORD_CHANGE = 'mx_record_change',
  NEW_TYPOSQUAT = 'new_typosquat',
  IP_CHANGES = 'ip_changes',
  TYPOSQUAT_GONE = 'typosquat_gone',
}

export interface DeltaChangeFromAPI {
  domain: string;
  newDisposition?: string;
  newIp?: string[];
  newMx?: string[];
  latestlastScannedDate?: string;
  latestlastScannedTs?: number;
  oldDisposition?: string;
  oldlastScannedTs?: number;
  oldlastScannedDate?: string;
  oldIp?: string[];
  oldMx?: string[];
  urlSHA256: string;
  type: CHANGE_TYPES[];
}

export interface DeltaChangeFromItem {
  domain: string;
  primary_domain: string;
  newDisposition?: string;
  newIp?: string[];
  newMx?: string[];
  latestlastScannedDate?: string;
  latestlastScannedTs?: number;
  oldDisposition?: string;
  oldlastScannedTs?: number;
  oldlastScannedDate?: string;
  oldIp?: string[];
  oldMx?: string[];
  urlSHA256: string;
  type: CHANGE_TYPES;
  findingsCount?: number;
}

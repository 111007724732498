import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { MANAGED_BY_AFFILIATED } from './constants';
import {
  DATE_ADDED,
  DOMAIN_COLUMN,
  IP_COLUMN,
  BRAND_NAME_COLUMN,
  COUNTRY_COL,
} from './AssetsColDefination/ManagedByOrgCols';
import { REGISTRAR_COLUMN } from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import ManagedByCustomer from './ManagedByCustomer';

const TABLE_ID = MANAGED_BY_AFFILIATED;
const TABLE_COLUMNS = [
  DOMAIN_COLUMN,
  IP_COLUMN,
  DATE_ADDED,
  BRAND_NAME_COLUMN,
  REGISTRAR_COLUMN,
  COUNTRY_COL,
];
const ManagedByAffiliated = (): JSX.Element => {
  window.document.title = 'Managed By Affiliates | Assets';

  const exportCSVTitle = `Bolster Assets Managed By Affiliate ${moment().format('YYYYMMDDhhmmss')}`;
  return (
    <ManagedByCustomer
      tableTitle={'Managed By Affiliates'}
      tableId={TABLE_ID}
      tableColumns={TABLE_COLUMNS}
      exportCSVTitle={exportCSVTitle}
    />
  );
};

export default ManagedByAffiliated;

import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Calendar } from 'react-date-range';
import enhanceWithClickOutside from 'react-click-outside';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DropdownDateRangePicker.scss';
import calendarIcon from '../../assets/icons/Calendar.svg';

interface IComponentProps {
  initDate?: moment.Moment;
  onChange: (date: moment.Moment) => void;
}

interface IComponentState {
  date: moment.Moment | undefined;
  expanded: boolean;
}

class DropdownDatePicker extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {};

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      date: props.initDate,
      expanded: false,
    };
  }

  handleClickOutside() {
    this.setState({
      expanded: false,
    });
  }

  toggleMenu = () => {
    const { expanded } = this.state;
    const toExpanded = !expanded;
    const newState = {
      expanded: toExpanded,
    };
    this.setState(newState);
  };

  handleSelect = (selection: any) => {
    this.setState(
      {
        date: moment(selection),
        expanded: false,
      },
      () => {
        this.props.onChange(selection);
      },
    );
  };

  render() {
    const { expanded, date } = this.state;

    let toggleButtonClassName = 'date-range-dropdown btn btn-sm dropdown-toggle';
    let menuClassName = 'dropdown-menu drop-down-left';

    if (expanded) {
      toggleButtonClassName += ' blue-box';
      menuClassName += ' dropdown-menu-shown';
    }

    const minDate = new Date(moment().valueOf());

    return (
      <div
        className={'dropdown-date-picker-component dropdown-date-range-picker-component btn-group'}
      >
        <button
          className={toggleButtonClassName}
          type='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
          onClick={this.toggleMenu}
        >
          <img src={calendarIcon} alt={'calendar'} />
          <span>{_.isEmpty(date) ? 'Select date' : date?.format('dddd, MMM.DD')}</span>
        </button>
        <div className={menuClassName}>
          <Calendar
            minDate={minDate}
            date={new Date(this.props.initDate as any)}
            onChange={this.handleSelect}
            color={'#cce5ed'}
          />
        </div>
      </div>
    );
  }
}

const EnhancedDropdownDatePicker = enhanceWithClickOutside(DropdownDatePicker);
export { EnhancedDropdownDatePicker as DropdownDatePicker };

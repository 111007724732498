import {
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  APP_STORE_TAKEN_DOWN_TABLE_ID,
} from '../AppStore/constants';
import { IFilter, TypeSortDirection } from '../Common/Table/constant';
import { EDarkWebFindingStatus } from '../DarkWeb/Components/Types/DarkWeb.types';
import {
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_FINDING_REVIEW_TABLE_ID,
  MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID,
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
} from '../Marketplace/constants';
import {
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
  SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
} from '../SocialMedia/constants';

export const DASHBOARD_NAME = 'Monitor and Takedown';

export const TAKEDOWN_MALICIOUS_TABLE_ID = 'takedown_malicious';
export const POST_MALICIOUS_TABLE_ID = 'post_malicious';
export const PRE_MALICIOUS_TABLE_ID = 'pre_malicious';
export const ACQUISITION_TABLE_ID = 'domain_acquisition';
export const USER_GLOBAL_TAKEDOWN_TABLE_ID = 'submitted_malicious';

export const WEB_TABLE_IDS = [
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
  POST_MALICIOUS_TABLE_ID,
  ACQUISITION_TABLE_ID,
  USER_GLOBAL_TAKEDOWN_TABLE_ID,
];

export const TAKEDOWN_MALICIOUS_TABLE_ID_DASH = 'takedown-malicious';
export const PRE_MALICIOUS_TABLE_ID_DASH = 'pre-malicious';
export const POST_MALICIOUS_TABLE_ID_DASH = 'post-malicious';

export const MONITOR_AND_TAKEDOWN_PATHNAME = '/monitor-and-takedown';
export const WEB_DASHBOARD_PATHNAME = '/web';
export const PRE_MALICIOUS_PATHNAME = `${WEB_DASHBOARD_PATHNAME}/pre-malicious`;
export const TAKEDOWN_MALICIOUS_PATHNAME = `${WEB_DASHBOARD_PATHNAME}/takedown-malicious`;
export const POST_MALICIOUS_PATHNAME = `${WEB_DASHBOARD_PATHNAME}/post-malicious`;
export const ACQUISITIONS_PATHNAME = `${WEB_DASHBOARD_PATHNAME}/acquisitions`;
export const WEB_INITIATE_TAKEDOWN_PATHNAME = `${WEB_DASHBOARD_PATHNAME}/initiate-takedown`;
export const MANAGE_EMAIL_TEMPLATES_PATH = `/manage-email-templates`;
export const MANAGE_EMAIL_TEMPLATES_CREATE_PATH = `/manage-email-templates/create`;
export const MANAGE_EMAIL_TEMPLATES_UPDATE_PATH = `/manage-email-templates/update/:id`;
export const USER_GLOBAL_TAKEDOWN_PATHNAME = '/global-takedown';
export type MALICIOUS_TABLE_IDS =
  | typeof PRE_MALICIOUS_TABLE_ID
  | typeof TAKEDOWN_MALICIOUS_TABLE_ID
  | typeof POST_MALICIOUS_TABLE_ID;

export type FINDINGS_TABLE_IDS =
  | typeof SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID
  | typeof SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID
  | typeof SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID
  | typeof SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID
  | typeof SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID
  | typeof SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID
  | typeof MARKET_PLACE_FINDING_DETECTION_TABLE_ID
  | typeof MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID
  | typeof MARKET_PLACE_TAKEN_DOWN_TABLE_ID
  | typeof MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID
  | typeof MARKET_PLACE_FINDING_REVIEW_TABLE_ID
  | typeof APP_STORE_FINDING_DETECTION_TABLE_ID
  | typeof APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID
  | typeof APP_STORE_TAKEN_DOWN_TABLE_ID
  | typeof APP_STORE_FINDING_SAFE_LIST_TABLE_ID;

export const availableIds = [
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
  SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
  MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID,
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  APP_STORE_TAKEN_DOWN_TABLE_ID,
  APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
];
export interface IMatSort {
  sortBy?: string;
  sortDirection?: TypeSortDirection;
}

export interface IMatQuery {
  type?: string;
  startDate?: moment.Moment | string;
  endDate?: moment.Moment | string;
  pageNumber?: number;
  pageSize?: number;
  isGroupViewEnabled?: boolean;
}

export enum EMatFetchTypes {
  PRE_MALICIOUS,
  POST_MALICIOUS,
  TAKEDOWN,
}

export const mapEnumToTableId = {
  [EMatFetchTypes.PRE_MALICIOUS]: PRE_MALICIOUS_TABLE_ID,
  [EMatFetchTypes.POST_MALICIOUS]: POST_MALICIOUS_TABLE_ID,
  [EMatFetchTypes.TAKEDOWN]: TAKEDOWN_MALICIOUS_TABLE_ID,
};

export const mapTableIdToEnum = {
  [PRE_MALICIOUS_TABLE_ID]: EMatFetchTypes.PRE_MALICIOUS,
  [POST_MALICIOUS_TABLE_ID]: EMatFetchTypes.POST_MALICIOUS,
  [TAKEDOWN_MALICIOUS_TABLE_ID]: EMatFetchTypes.TAKEDOWN,
};

export type IMatFetchOptions =
  | Record<string, never>
  | {
      type: EMatFetchTypes | EDarkWebFindingStatus;
      filters: IFilter[];
      sort: IMatSort;
      query: IMatQuery;
    };

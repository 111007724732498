import React from 'react';
import { Button } from 'react-bootstrap';
import { AlertIcon } from '../../assets/SVGIcons';
import { DomainMap } from './soc-takedown-request-page.component';

interface IPropsSocTakedownTabs {
  domainMap: DomainMap;
  activeDomain: string;
  showErrors: boolean;
  tabClick: (domain: string) => void;
}
const SocTakedownTabs = ({
  domainMap,
  activeDomain,
  showErrors,
  tabClick,
}: IPropsSocTakedownTabs) => {
  const TabCreation = Object.entries(domainMap).map(([domain, domainInfo], key: number) => {
    return (
      <Button
        onClick={() => {
          tabClick(domain);
        }}
        className={activeDomain === domain ? 'tab-box active' : 'tab-box'}
        key={key}
      >
        {domain}
        {showErrors && !domainInfo.isValid && (
          <span className='ml-2'>
            <AlertIcon color={'red'} />
          </span>
        )}
      </Button>
    );
  });
  return <div className='d-flex flex-row'> {TabCreation} </div>;
};

export default SocTakedownTabs;

import React from 'react';
import '../../../Style/DarkWeb.scss';
import { IDarkWebFindingData } from '../../Types/DarkWeb.types';
import DarkWebFindingStatus from '../../Common/DarkWebFindingStatus';
import _ from 'lodash';

interface IFindingStatusAndRiskProp {
  data: IDarkWebFindingData;
}

export default function FindingStatusAndRisk({ data }: IFindingStatusAndRiskProp) {
  const { status } = data;
  return (
    <div className='mark-as-status d-flex align-items-center pl-2'>
      Mark as:
      <DarkWebFindingStatus
        data={data}
        status={status}
        placement='bottom-end'
        className='ml-2 p-2'
        onSubmit={_.noop}
      />
    </div>
  );
}

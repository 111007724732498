import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ModalOverlay from '../../../Common/ModalOverlay';
import { IAlertDispatchProps, IContentPlatform } from '../../../../constants';
import { AppState } from '../../../../helpers';
import { alertActions } from '../../../../actions';
import { Form } from 'react-bootstrap';
import { TextInput } from '../../../Common/TextInput';
import { IDropdownOption, Dropdown } from '../../../Common/Dropdown';
import UgcService from '../../../../services/ugc.service';
import { ISearchTermOptions } from '../../Types/ugc.types';

const ugcService = new UgcService();

interface INewFinding {
  url: string;
  searchTermId: number | undefined;
  platformId: number | undefined;
}
interface IModalProps {
  show: boolean;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  type: string;
}
interface IPropsFromGlobalState {
  ugcPlatforms: IContentPlatform[];
}

const SocAddDetectionModal = ({
  show,
  onCancel,
  onSubmit,
  ugcPlatforms,
  alertSuccess,
  alertError,
  type,
}: IModalProps & IPropsFromGlobalState & IAlertDispatchProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  const [searchTermOptions, setSearchTermOptions] = useState<ISearchTermOptions[]>([]);
  const [platformOptions, setPlatformOptions] = useState<IContentPlatform[]>([]);
  const values = useRef<INewFinding>({
    url: '',
    searchTermId: undefined,
    platformId: undefined,
  });

  useEffect(() => {
    setIsLoading(true);
    ugcService.getSearches(type).then((res: any) => {
      setIsLoading(false);
      setSearchTermOptions(
        _.chain(res)
          .filter({ isDisabled: false })
          .map((item): ISearchTermOptions => {
            const { searchTerm, id, platforms } = item;
            return {
              id,
              label: searchTerm,
              value: id,
              platforms,
            };
          })
          .uniqBy('label')
          .value(),
      );
    });
  }, []);

  const onModalCancel = () => {
    setPlatformOptions([]);
    setIsInvalid(true);
    values.current = {
      url: '',
      searchTermId: undefined,
      platformId: undefined,
    };
    onCancel();
  };

  const onModalSubmit = () => {
    setIsLoading(true);
    ugcService
      .addScanFinding(values.current, type)
      .then(data => {
        alertSuccess(`Congratulations! Your URL scan is in progress.`);
        onSubmit(values.current);
      })
      .catch(err => {
        alertError(`Error on adding ${values.current.url}`);
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateValue = (value: any, field: string) => {
    values.current[field] = value;
    const { url, searchTermId, platformId } = values.current;
    setIsInvalid(_.isEmpty(url) || !platformId || !searchTermId);
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateValue(event.target.value, controlId);
  };

  const handleDropdownChange = (option: ISearchTermOptions, controlId: string) => {
    updateValue(option.value, controlId);
    if (controlId === 'searchTermId') {
      const searchTerm = _.find(searchTermOptions, ['value', option.value]);
      if (searchTerm) {
        setPlatformOptions(
          _.map(searchTerm.platforms, p => ({
            ...p,
            value: p.id,
          })),
        );
      }
    }
  };

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={'Add Bolster Detection'}
      onCancel={onModalCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Scan'}
      submitButtonDisabled={isInvalid}
    >
      <div className={'soc-add-finding-modal'}>
        <Form>
          <Form.Group>
            <Form.Label>URL</Form.Label>
            <TextInput controlId='url' onChange={handleTextFieldChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Matched Search Term</Form.Label>
            <Dropdown
              boxStyle
              btnClassName='font-weight-normal'
              controlId='searchTermId'
              options={searchTermOptions}
              onChange={handleDropdownChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Platform</Form.Label>
            <Dropdown
              boxStyle
              btnClassName='font-weight-normal'
              controlId='platformId'
              options={platformOptions}
              onChange={handleDropdownChange}
            />
          </Form.Group>
        </Form>
      </div>
    </ModalOverlay>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { user } = state.dashboardReducer;
  const { contents } = state.appReducer;
  return {
    user,
    ugcPlatforms: contents?.[type]?.platforms || [],
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocAddDetectionModal);

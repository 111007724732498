import React, { FunctionComponent } from 'react';
import LOGO from '../../assets/logo/CP_by_BO_logo.svg';
import twitterIcon from '../../assets/logo/twitterIcon-white.svg';
import linkedInIcon from '../../assets/logo/linkedInIcon-white.svg';
import { ITabItem } from './TabItems';
import './WebFooter.scss';
import { Nav } from 'react-bootstrap';
import { redirectToCheckphish } from '../../constants';

interface IWebFooterProps {
  tabItems: ITabItem[];
  tabItemsL2: ITabItem[];
}

const WebFooter: FunctionComponent<IWebFooterProps> = ({ tabItems, tabItemsL2 }) => {
  const checkCurrentTab = (href: string) => {
    const { pathname } = window.location;
    return pathname === href;
  };

  return (
    <div className={'d-flex flex-column web-footer-container p-5'}>
      <div className={'m-4 pb-5 flex-center justify-content-lg-between web-footer-top'}>
        <a href={redirectToCheckphish('/', true)}>
          <img src={LOGO} alt={'companyIcon'} />
        </a>
      </div>
      <div className={'m-4 flex-center flex-column flex-lg-row web-footer-middle'}>
        {tabItems.map((item: ITabItem, index: number) => (
          <Nav.Link
            key={index}
            href={item.redirect ? redirectToCheckphish(item.href, true) : item.href}
            className={`font-weight-500 ${
              checkCurrentTab(item.href) ? 'active-link' : 'custom-text-light'
            }`}
          >
            {item.label}
          </Nav.Link>
        ))}
      </div>
      <div className={'m-4 flex-center flex-lg-rows web-footer-bottom justify-content-lg-between'}>
        <div className={'font-size-small custom-text-light footer-item'}>
          Copyright @ 2023 Bolster Inc. All Rights Reserved.
        </div>
        <div className={'flex-center footer-item'}>
          {tabItemsL2.map((item: ITabItem, index: any) => (
            <a
              key={index}
              href={item.redirect ? redirectToCheckphish(item.href, true) : item.href}
              className={`custom-text-dark font-size-normal ${!index && 'pr-2 policy-right'} mr-1`}
            >
              {item.label}
            </a>
          ))}
        </div>
        <div className='footer-item align-right'>
          <div className='social-icon'>
            <a href={'https://www.linkedin.com/company/bolster-ai'}>
              <img src={linkedInIcon} alt={'linkedInIcon'} />
            </a>
          </div>
          <div className='social-icon'>
            <a href={'https://twitter.com/bolsterai'}>
              <img src={twitterIcon} alt={'twitterIcon'} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { WebFooter };

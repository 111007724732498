import React, { useCallback, useState, useContext } from 'react';
import { Dropdown } from '../../Common/Dropdown';
import DeleteBrandPropertyModal from './DeleteBrandPropertyModal';
import DotDotDot from '../../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../../assets/icons/DotDotDotWhite.svg';
import ThemeContext from '../../../context/ThemeContext';
import { ThemeModes } from '../../../constants';
import _ from 'lodash';
import { EPropertyStatus, PropertyType } from '../PropertyTypes';
import { useAppDispatch } from '../../../helpers/hooks';
import { deleteBrandProperty } from '../brand-property-requests';
import { alertActions } from '../../../actions';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { ThemeProvider, createTheme, ThemeOptions } from '@material-ui/core/styles';
import useOnThemeChange from '../../DarkWeb_v2/Components/Common/useOnThemeChange';
import { setShouldTriggerAgGrigRefresh } from '../../../reducers/table.reducer';
interface IAssetsActionColRenderProps {
  data: any;
}

const ModifyAssetsColRender = (props: IAssetsActionColRenderProps) => {
  const [deleteAssetsModalShown, setDeleteAssetsModalShown] = useState(false);
  const [deleteProperty, setDeleteProperty] = useState<any>([]);
  const { selectedTheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { palleteType } = useOnThemeChange();

  const theme: ThemeOptions = createTheme({
    palette: {
      type: palleteType as any,
    },
  });
  const { data } = props;
  const dispatch = useAppDispatch();

  const checkDisabled = () => {
    return data.propertyType.value === PropertyType.LOGO && data.status === EPropertyStatus.ACTIVE;
  };
  const actionOptions = [
    {
      label: 'Delete',
      value: 'delete',
      disabled: checkDisabled(),
    },
  ];

  const toggleDeleteAssetsModal = useCallback(() => {
    setDeleteProperty([data]);
    setDeleteAssetsModalShown(!deleteAssetsModalShown);
  }, [deleteAssetsModalShown, data]);

  const onAction = useCallback(
    (action: string, data: any) => {
      switch (action) {
        case 'delete':
          toggleDeleteAssetsModal();
          break;
        default:
          break;
      }
    },
    [toggleDeleteAssetsModal],
  );

  const onDeleteHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload = { propertyIds: [_.get(data, ['id'])] };
      await deleteBrandProperty(payload);
      dispatch(
        alertActions.success(`Your ${data.propertyType.label} property has successfully deleted.`),
      );
    } catch (error) {
      dispatch(alertActions.error(error as string));
    } finally {
      dispatch(setShouldTriggerAgGrigRefresh(true));

      setIsLoading(false);
      setDeleteAssetsModalShown(false);
    }
  }, [dispatch, data]);

  const onCloseModal = () => {
    setDeleteProperty([]);
    setDeleteAssetsModalShown(!deleteAssetsModalShown);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id='basic-button-brand-col-actions'
        aria-controls={open ? 'basic-menu-brand-col-actions' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img
          src={selectedTheme === ThemeModes.DARK.toLowerCase() ? DotDotDotWhiteIcon : DotDotDot}
          alt={'dotdotdot'}
          data-testid='basic-button-brand-col-actions'
        />{' '}
      </Button>
      <ThemeProvider theme={theme}>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {actionOptions.map((actionOption, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  onAction(actionOption.value, data);
                  handleClose();
                }}
                disabled={actionOption.disabled}
              >
                {actionOption.label}
              </MenuItem>
            );
          })}
        </Menu>
      </ThemeProvider>
      {deleteAssetsModalShown && (
        <DeleteBrandPropertyModal
          show={deleteAssetsModalShown}
          selectProptertyType={data.propertyType}
          onDeleteProperty={onDeleteHandler}
          onCloseModal={onCloseModal}
          deleteProperty={deleteProperty}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ModifyAssetsColRender;

import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import '../Style/playbook.scss';
import { PlaybookContext } from '../../../context/playbook.context';
import { Dropdown } from '../../Common/Dropdown';
import { PlaybookTile } from './PlaybookTile';
import { appConstants } from '../../../constants';
import moment from 'moment';
import { IPlaybooksTableProps } from './PlaybookTypes';
import {
  PLAYBOOK_ACTION_COLUMN,
  PLAYBOOK_AUTHOR,
  PLAYBOOK_CREATED_TS,
  PLAYBOOK_LAST_COUNT,
  PLAYBOOK_NAME,
  PLAYBOOK_NEXT_RUN,
  PLAYBOOK_OUTPUT,
  PLAYBOOK_PAST_RUN,
  PLAYBOOK_SCHEDULE,
  PLAYBOOK_TOTAL_RESULT,
  PLAYBOOK_UPDATED_TS,
  PLAYBOOK_ID_COLUMN,
} from './PlaybookTableCols';
import { useAppDispatch } from '../../../helpers/hooks';
import { setIsAgGridClientMode } from '../../../reducers/table.reducer';
import { TableContextProvider } from '../../Common/Table/table.context';
import { TableData } from '../../Common/Table/table.data';
import PlaybookActionColRender from './PlaybookActionColRender';

const PlaybooksTable = ({
  playbooks,
  setModalShown,
  setUseTemplate,
  onRunNowPlaybook,
  onDeletePlaybook,
  setNewPlaybook,
  tableId,
}: IPlaybooksTableProps) => {
  const playbookContext = useContext(PlaybookContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, [dispatch]);

  const columns = useMemo(() => {
    return [
      PLAYBOOK_ID_COLUMN,
      PLAYBOOK_NAME,
      {
        // for nowm fitler is disabled, may need this logic later
        ...PLAYBOOK_SCHEDULE,
        filterOptions: playbooks.map((playbook: any) => {
          return { label: playbook.displaySchedule, value: playbook.displaySchedule };
        }),
      },
      PLAYBOOK_NEXT_RUN,
      PLAYBOOK_OUTPUT,
      PLAYBOOK_AUTHOR,
      PLAYBOOK_CREATED_TS,
      PLAYBOOK_UPDATED_TS,
      PLAYBOOK_PAST_RUN,
      PLAYBOOK_TOTAL_RESULT,
      PLAYBOOK_LAST_COUNT,
      {
        ...PLAYBOOK_ACTION_COLUMN,
        playbookColumns: playbookContext.playbookColumns,
        render: (data: any) => {
          return (
            <PlaybookActionColRender
              data={data}
              onEditPlaybook={onEditPlaybook}
              onRunNowPlaybook={onRunNowPlaybook}
              onDeletePlaybook={onDeletePlaybook}
            />
          );
        },
      },
    ];
  }, [playbookContext]);

  const onEditPlaybook = useCallback(
    data => {
      playbookContext.initData(data);
      setModalShown(true);
      setUseTemplate(false);
    },
    [playbookContext, setModalShown, setUseTemplate],
  );

  const setAddPlaybookTool = () => {
    const newPlaybookOptions = [
      {
        label: 'Blank playbook',
        value: 'draft',
      },
    ];
    if (playbookContext.type === appConstants.CONTENT_TYPE['WEB']) {
      newPlaybookOptions.push({
        label: 'From a template',
        value: 'template',
      });
    }

    return (
      <Dropdown
        boxStyle
        variant='primary'
        fixedPlaceholder={'New Playbook'}
        key={'New Playbook Dropdown'}
        options={newPlaybookOptions}
        onChange={selection => {
          const useTemplate = selection.value === 'template';
          if (!useTemplate) {
            playbookContext.updateSchedule('date', moment().valueOf());
          }
          setModalShown(true);
          setUseTemplate(useTemplate);
          setNewPlaybook(true);
        }}
      />
    );
  };

  return (
    <TableContextProvider
      columns={columns}
      dashboardName={'Automation'}
      tableId={tableId}
      tableIndex={'id'}
    >
      <TableData
        tableIndex={'id'}
        id={tableId}
        title={'Playbooks'}
        disableDatePicker
        customTools={setAddPlaybookTool()}
        data={playbooks}
        columns={columns}
        tileComp={PlaybookTile}
        tileCompEvents={{ onEditPlaybook: onEditPlaybook, onRunNowPlaybook: onRunNowPlaybook }}
      />
    </TableContextProvider>
  );
};

export { PlaybooksTable };

import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './dashboard.scss';

interface IComponentProps {
  hasAdded?: boolean;
  image: string;
  title: string;
  description?: string;
  onClick: () => void;
  imgBorder?: boolean;
}

interface IComponentState {}

class DashboardAddSection extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    data: {},
  };

  render() {
    const { hasAdded, image, title, description = '', imgBorder } = this.props;
    return (
      <Card>
        <div className={'dashboard-add-section-tile'}>
          <img
            src={image}
            alt={'widget example'}
            className={'dashboard-add-section-tile-image' + (imgBorder ? ' border' : '')}
          />
          <div className={'dashboard-add-section-info'}>
            <div className={'dashboard-add-section-title'}>{title}</div>
            {description && (
              <div className={'dashboard-add-section-description'}>{description}</div>
            )}
          </div>
          {hasAdded ? (
            <Button variant={'secondary'} disabled>
              Added
            </Button>
          ) : (
            <Button variant={'primary'} onClick={this.props.onClick}>
              Add
            </Button>
          )}
        </div>
      </Card>
    );
  }
}

export { DashboardAddSection };

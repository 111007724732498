import React, { useState } from 'react';
import _ from 'lodash';
import './Step.scss';
import { CheckCircleBlue } from '../../assets/SVGIcons';
interface IStep {
  stepId: number;
  label: string;
}

interface IStepProps {
  steps: IStep[];
  className?: string;
  onStepChange?: (step: IStep) => void;
  initialStep?: number;
  disabledFutureClick?: boolean;
}

const Step = ({
  steps = [],
  className,
  initialStep = 0,
  onStepChange = _.noop,
  disabledFutureClick,
}: IStepProps) => {
  const [currentStep, setCurrentStep] = useState(initialStep || 1);

  const onStepClicked = (step: IStep) => {
    setCurrentStep(step.stepId);
    onStepChange(step);
  };

  return (
    <div className={'steps-component w-100 d-flex align-items-center ' + className}>
      {_.map(steps, step => {
        const { stepId, label } = step;
        let stepClassName = 'step-container d-flex align-items-center ';
        let numberDom = <div className={'step-number flex-center'}>{stepId}</div>;
        const labelDom = <div className={'step-label'}>{label}</div>;

        if (currentStep === stepId) {
          stepClassName += 'current-step';
        } else if (currentStep > stepId) {
          stepClassName += 'passed-step';
          numberDom = <div className='check-circle-wrapper'>{CheckCircleBlue}</div>;
        }

        return (
          <button
            key={step.stepId}
            className={stepClassName}
            disabled={disabledFutureClick && currentStep < stepId}
            onClick={() => onStepClicked(step)}
          >
            {numberDom}
            {labelDom}
          </button>
        );
      })}
    </div>
  );
};

export { Step };

import _ from 'lodash';
import moment from 'moment';
import { IFilter } from '../components/Common/Table/constant';
import { EFindingStatus, ERequestTakedown, ugcType } from '../components/Ugc/Types/ugc.types';
import { appConstants, subtractDays } from '../constants';
import fetch from './api.service';
import { handleResponse, setPostRequestOptions } from './serviceWorker';
import { UTCTimeFormat } from '../components/Common/Table/ag-table/constants';

export const convertToUtcDateTime = (date: string, isEndDate = false): string => {
  const addOneDay = isEndDate ? 1 : 0;
  return moment(date).add(addOneDay, 'day').utc().format();
};

const formatCurrentTimeToUtc = (dateToConvert: number): string => ('0' + dateToConvert).slice(-2);

export const convertCustomCalendarRangeToUtc = (dateToFormat: any) => {
  const dateStr = moment(dateToFormat).format('YYYY-MM-DD');
  const currentDate = new Date();
  const utcTimeStr = `${formatCurrentTimeToUtc(currentDate.getUTCHours())}:${formatCurrentTimeToUtc(
    currentDate.getUTCMinutes(),
  )}:${formatCurrentTimeToUtc(currentDate.getUTCSeconds())}`;
  return `${dateStr}T${utcTimeStr}Z`;
};

export const convertDefaultRangeToUtc = (date: string): string => {
  return moment(date).utc().format();
};

export const getCorrectUtcDateAndTime = (dateToConvert: string, isCustomCalendarRange: boolean) => {
  return isCustomCalendarRange
    ? convertCustomCalendarRangeToUtc(dateToConvert)
    : convertDefaultRangeToUtc(dateToConvert);
};

export const buildFilterQueryString = (
  filters: IFilter[],
  status?: EFindingStatus,
  type?: string,
): string => {
  let queryString = '';
  if (status !== undefined) {
    queryString += `status=in.(${status.split('|').join(',')})`;
  }
  const orFilters: Record<string, string[]> = {};
  const orNotFilters: Record<string, string[]> = {};
  _.forEach(filters, (filter: IFilter) => {
    const filterMethod = filter.filterMethod.value;
    let filterBy = filter.filterBy.value;
    const filterValue = filter.filterValue;
    const filterLabelValue = filter.filterLabel;
    if (filter.filterType === 'optionsMultiSelect') {
      if (type !== appConstants.CONTENT_TYPE.APP_STORE) {
        if (filterBy === 'tags.id') filterBy = 'tag_ids';
        let filter = '';

        // ZION-7371: add support for filtering any tags/no tags for SM and MP
        if (filterLabelValue === 'Any tags') {
          filter = `${filterBy}.is.null`;

          // Special case for SM any tags filter, its opposite of the regular IS and IS_NOT filter method
          if (filterMethod === 'is') {
            if (!orNotFilters[filterBy]) orNotFilters[filterBy] = [];
            orNotFilters[filterBy].push(filter);
          } else {
            if (!orFilters[filterBy]) orFilters[filterBy] = [];
            orFilters[filterBy].push(filter);
          }
        } else {
          filter = `${filterBy}.cs.{${filterValue.replaceAll('&', ',')}}`;
          if (filterMethod === 'is') {
            if (!orFilters[filterBy]) orFilters[filterBy] = [];
            orFilters[filterBy].push(filter);
          } else {
            if (!orNotFilters[filterBy]) orNotFilters[filterBy] = [];
            orNotFilters[filterBy].push(filter);
          }
        }

        return;
      }
    }

    let startOper = '';
    let endOper = '';
    let queryOperator = 'eq';
    let logicalOperator = 'or';

    if (filterBy === 'srcUrl') {
      filterBy = 'url';
    }

    switch (filterBy) {
      case 'category_labels':
      case 'search_term_labels':
      case 'category_id':
      case 'search_term_id':
        if (filterBy === 'category_labels' || filterBy === 'category_id') {
          filterBy = 'category_ids';
        } else if (filterBy === 'search_term_labels' || filterBy === 'search_term_id') {
          filterBy = 'search_term_ids';
        }
        switch (filterMethod) {
          case 'is':
          case 'includes':
            queryOperator = 'ov';
            break;
          case 'isNot':
          case 'notIncludes':
            queryOperator = 'not.ov';
            break;
          default:
            break;
        }
        startOper = `{`;
        endOper = `}`;
        if (filterMethod !== 'numberRange') {
          queryString += `&${filterBy}=${queryOperator}.${startOper}${filterValue}${endOper}`;
        }
        break;
      case 'tags.id':
        // Tags query string filter for app store
        if (type === appConstants.CONTENT_TYPE.APP_STORE) {
          switch (filterMethod) {
            case 'is':
            case 'includes':
              queryOperator = 'in';
              break;
            case 'isNot':
            case 'notIncludes':
              queryOperator = 'not.in';
              break;
            default:
              break;
          }
          queryString += `&${filterBy}=${queryOperator}.(${filterValue.replaceAll(/[&|]/g, ',')})`;
          return;
        }
      case 'tags.updated_by':
        if (type === appConstants.CONTENT_TYPE.APP_STORE) {
          switch (filterMethod) {
            case 'is':
            case 'includes':
              queryOperator = 'in';
              break;
            case 'isNot':
            case 'notIncludes':
              queryOperator = 'not.in';
              break;
            default:
              break;
          }
          queryString += `&${filterBy}=${queryOperator}.(${filterValue})`;
          return;
        } else {
          filterBy = 'tag_authors';
          switch (filterMethod) {
            case 'is':
            case 'includes':
              queryOperator = 'ov';
              break;
            case 'isNot':
            case 'notIncludes':
              queryOperator = 'not.ov';
              break;
            default:
              break;
          }
          startOper = `{`;
          endOper = `}`;
          if (filterMethod !== 'numberRange') {
            queryString += `&${filterBy}=${queryOperator}.${startOper}${filterValue}${endOper}`;
          }
          return;
        }
      case 'url':
      case 'src_url':
        if (filterMethod === 'includes') {
          logicalOperator = 'or';
          queryOperator = 'ilike';
        } else {
          logicalOperator = 'and';
          queryOperator = 'not.ilike';
        }
        startOper = '*';
        endOper = '*';
        if (filterValue.includes(',')) {
          queryString += `&${logicalOperator}=(${filterValue
            .replace(/[.*+?&#^${}()/:|[\]\\]/g, '\\$&')
            .split(',')
            .map(
              (value: string) =>
                `${filterBy}.${queryOperator}.${startOper}${value.trim()}${endOper}`,
            )
            .join(',')})`;
        } else {
          queryString += `&${filterBy}=${queryOperator}.${startOper}${filterValue.replace(
            /[.*+?&#^${}()/:|[\]\\]/g,
            '\\$&',
          )}${endOper}`;
        }
        return;
      default:
        switch (filterMethod) {
          case 'is':
            queryOperator = 'eq';
            break;
          case 'isNot':
            queryOperator = 'not.eq';
            break;
          case 'beginWith':
            endOper = '*';
            queryOperator = 'ilike';
            break;
          case 'notBeginWith':
            endOper = '*';
            queryOperator = 'not.ilike';
            break;
          case 'includes':
            queryOperator = 'ilike';
            startOper = '*';
            endOper = '*';
            break;
          case 'notIncludes':
            queryOperator = 'not.ilike';
            startOper = '*';
            endOper = '*';
            break;
          case 'numberRange':
            const [min, max] = filterValue.split(',');
            queryString += `&${filterBy}=gte.${min}&${filterBy}=lte.${max}`;
            break;
          case 'last':
            if (type !== appConstants.CONTENT_TYPE.SOCIAL_PLAYBOOK) {
              const lastFormatDate = subtractDays(filterValue?.split('-')[0]);
              queryString += `&${filterBy}=gte.(${lastFormatDate})`;
            }
            break;
          case 'older':
            if (type !== appConstants.CONTENT_TYPE.SOCIAL_PLAYBOOK) {
              const olderFormatDate = subtractDays(filterValue?.split('-')[0]);
              queryString += `&${filterBy}=lte.(${olderFormatDate})`;
              break;
            }
          default:
            break;
        }
        if (filterMethod !== 'numberRange' && filter.filterType !== 'date') {
          queryString += `&${filterBy}=${queryOperator}.${startOper}${filterValue}${endOper}`;
        }
        break;
    }
  });

  if (!_.isEmpty(orFilters)) {
    Object.values(orFilters).forEach(filters => (queryString += `&or=(${filters.join(',')})`));
  }

  if (!_.isEmpty(orNotFilters)) {
    Object.values(orNotFilters).forEach(
      filters => (queryString += `&not.or=(${filters.join(',')})`),
    );
  }

  return queryString;
};
export default class UgcService {
  getSearches(type: string) {
    return fetch(`/platform-api/v1/content/${type}/term`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  addSearchTerm(data: any, type: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    return fetch(`/platform-api/v1/content/${type}/term`, requestOptions).then(handleResponse);
  }

  updateSearchTerm(termId: number | undefined, data: any, type: string) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch(`/platform-api/v1/content/${type}/term/${termId}`, requestOptions).then(
      handleResponse,
    );
  }

  reScanSearchTerm(data: any, type: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    return fetch(`/platform-api/v1/content/${type}/rescan`, requestOptions);
  }

  exportFindings(status: EFindingStatus, query: any, filters: IFilter[], sort: any, type: string) {
    const data = {
      filter: buildFilterQueryString(filters, status, type),
      startDate: convertToUtcDateTime(query.startDate),
      endDate: convertToUtcDateTime(query.endDate, true),
      order: `${sort.sortBy}.${sort.sortDirection}.nullslast`,
    };
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch(`/platform-api/v1/content/${type}/finding/search`, requestOptions).then(
      handleResponse,
    );
  }

  getFindings(status: EFindingStatus, query: any, filters: IFilter[], sort: any, type: string) {
    const data = {
      filter: buildFilterQueryString(filters, status, type),
      startDate: convertToUtcDateTime(query.startDate),
      endDate: convertToUtcDateTime(query.endDate, true),
      limit: query.pageSize,
      offset: query.pageSize * query.pageNumber,
      order: `${sort.sortBy}.${sort.sortDirection}.nullslast`,
      total: true,
    };
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch(`/platform-api/v1/content/${type}/finding/search`, requestOptions).then(
      handleResponse,
    );
  }

  getFinding(urlSha256: string, type: string = ugcType.Social) {
    return fetch(`/platform-api/v1/content/${type}/finding/${urlSha256}`, {
      credentials: 'include',
    }).then(handleResponse);
  }
  getFindingSafelist(urlSha256: string, type: string) {
    return fetch(`/platform-api/v1/content/${type}/finding/${urlSha256}/safelist`, {
      credentials: 'include',
    }).then(handleResponse);
  }
  getFindingTakedown(urlSha256: string, type: string) {
    return fetch(`/platform-api/v1/content/${type}/finding/${urlSha256}/takedown`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  addAndTakedownFinding(data: any, type: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: data,
    };
    return fetch(`/platform-api/v1/content/${type}/finding`, requestOptions).then(handleResponse);
  }

  uploadMultipleFindings(file: any, type: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: file,
    };
    return fetch(`/platform-api/v1/content/${type}/finding/file`, requestOptions).then(
      handleResponse,
    );
  }

  addScanFinding(data: any, type: string) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch(
      `/platform-api/v1/content/${type}/term/${data.searchTermId}/finding`,
      requestOptions,
    ).then(handleResponse);
  }

  deleteFindings(findings: any[], type: string) {
    const sha256s = findings.map(finding => finding.sha256);
    return fetch(`/platform-api/v1/content/${type}/finding/hide/bulk`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ sha256s }),
    }).then(handleResponse);
  }

  approveFindings(data: any[], type: string) {
    const formData = new FormData();
    formData.append('sha256', _.map(data, i => i.sha256).join(','));
    formData.append('status', EFindingStatus.LIVE);
    return this.updateFindingStatus(formData, type);
  }

  updateFindingStatus(formData: FormData, type: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: formData,
    };
    return fetch(`/platform-api/v1/content/${type}/finding/status/bulk`, requestOptions).then(
      handleResponse,
    );
  }

  editFinding(sha256: string, formData: FormData, type: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: formData,
    };
    return fetch(`/platform-api/v1/content/${type}/finding/${sha256}`, requestOptions).then(
      handleResponse,
    );
  }

  getSummaryDetails(type: string) {
    return fetch(`/platform-api/v1/content/${type}/summary`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getDetectionCounts(
    startDate: string,
    endDate: string,
    filters: IFilter[],
    type: string,
    isCustomCalendarRange: boolean,
  ) {
    const options = {
      filter: buildFilterQueryString(filters),
      startDate: getCorrectUtcDateAndTime(startDate, isCustomCalendarRange),
      endDate: getCorrectUtcDateAndTime(endDate, isCustomCalendarRange),
    };
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(options));
    return fetch(`/platform-api/v1/content/${type}/count`, requestOptions).then(handleResponse);
  }

  getDetectionsByField(query: any, type: string) {
    const { field, limit } = query;
    const startDate = getCorrectUtcDateAndTime(query.startDate, query.isCustomCalendarRange);
    const endDate = getCorrectUtcDateAndTime(query.endDate, query.isCustomCalendarRange);
    return fetch(
      `/platform-api/v1/content/${type}/count/field/${field}?startDate=${startDate}&endDate=${endDate}&limit=${limit}`,
      {
        credentials: 'include',
      },
    ).then(handleResponse);
  }

  getDetectionsByFieldAndSubField(query: any, type: string) {
    const { field, subfield, limit } = query;
    const startDate = getCorrectUtcDateAndTime(query.startDate, query.isCustomCalendarRange);
    const endDate = getCorrectUtcDateAndTime(query.endDate, query.isCustomCalendarRange);

    const params = new URLSearchParams();
    params.append('startDate', startDate);
    params.append('endDate', endDate);
    if (limit) params.append('limit', limit);

    return fetch(
      `/platform-api/v1/content/${type}/count/field/${field}/subfield/${subfield}?${params.toString()}`,
      {
        credentials: 'include',
      },
    ).then(handleResponse);
  }

  initiateTakedownFinding(data: any, type: string, takedownType?: string, sha256?: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: data,
    };
    let apiUrl = '';

    if (takedownType === ERequestTakedown.Bulk) {
      apiUrl = `/platform-api/v1/content/${type}/finding/bulk/request-takedown`;
    } else {
      apiUrl = `/platform-api/v1/content/${type}/finding/${sha256}/takedown`;
    }
    return fetch(apiUrl, requestOptions).then(handleResponse);
  }

  addFindingToSafelist(data: any, type: string) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(data));
    return fetch(`/platform-api/v1/content/${type}/finding/bulk/safelist`, requestOptions).then(
      handleResponse,
    );
  }

  addSOCFindingsNotes(notes: any, sha256: string, type: string) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(notes));
    return fetch(`/platform-api/v1/content/${type}/finding/${sha256}/note`, requestOptions).then(
      handleResponse,
    );
  }
  initiateTakedownAppStoreFinding(data: any, type: string) {
    const { urlSha256, comments, files } = data;
    const takedownData = new FormData();
    takedownData.append('urlSha256s', urlSha256);
    takedownData.append('comments', comments || '--');
    for (let i = 0; i < files.length; i++) {
      takedownData.append('uploads', files[i]);
    }
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: takedownData,
    };
    return fetch(
      `/platform-api/v1/platform-administration/${type}/takedown/user`,
      requestOptions,
    ).then(handleResponse);
  }
  deleteSearchTerm(termId: number | undefined, type: string) {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`/platform-api/v1/content/${type}/term/delete/${termId}`, requestOptions).then(
      handleResponse,
    );
  }
}

import React from 'react';
import _ from 'lodash';
import FindingActionModal from './FindingActionModal';
import UgcService from '../../../../services/ugc.service';
import { IFindingsTableItemProps, IFindingInsightDataProps } from '../../Types/ugc.types';
import { getSingleOrPluralForm } from '../../../../constants/util';
import { ugcTypeAndLiveRoutes } from '../../constants';
import { useAppDispatch } from '../../../../helpers/hooks';
import { history } from '../../../../helpers';
import { setShouldTriggerAgGrigRefresh } from '../../../../reducers/table.reducer';

const ugcService = new UgcService();

interface IMarkAsSafelistModalProps {
  data: IFindingsTableItemProps[] | IFindingInsightDataProps[];
  show: boolean;
  onCloseModal: () => void;
  onModalSubmit?: (data: any) => void;
  title?: string;
  submitButtonLabel?: string;
  type: string;
}

const MarkAsSafelistModal = (props: IMarkAsSafelistModalProps) => {
  const dispatch = useAppDispatch();
  const {
    data,
    title = 'Confirm to mark as Ignored',
    submitButtonLabel = 'Mark as Ignored',
    type,
  } = props;
  const onModalSubmit = ({ data, comment }: any) => {
    const values = {
      safelistings: _.map(data, item => item.sha256),
      comments: comment,
    };
    return ugcService.addFindingToSafelist(values, type).then(() => {
      props.onModalSubmit && props.onModalSubmit(data);
      handleRedirect();
    });
  };
  const handleRedirect = () => {
    const isFromInsights = window.location.pathname.includes('insights');
    if (isFromInsights) {
      history.push(ugcTypeAndLiveRoutes[type]);
    } else {
      dispatch(setShouldTriggerAgGrigRefresh(true));
    }
  };

  return (
    <FindingActionModal
      {...props}
      title={title}
      description='Are you sure you want to ignore ?'
      submitButtonLabel={submitButtonLabel}
      onModalSubmit={onModalSubmit}
      successMessage={`Congratulations! You have marked ${data?.length} ${getSingleOrPluralForm(
        data?.length,
        'detection',
      )} as ignored.`}
      failureMessage={`Failure marking as ignored.`}
      noFileUpload
    />
  );
};

export default MarkAsSafelistModal;

import React from 'react';
import _ from 'lodash';
import { SummaryWidget } from '../../../Common/SummaryWidget';

interface IDashboardSummaryProp {
  isLoading: boolean;
  summaryData: any;
}

const formatValue = (number: number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  } else {
    return number;
  }
};

const formatSummaryData = (data: any) => {
  return data.map((item: any) => {
    if (item.id === 'totalLiveScansCount') {
      return {
        ...item,
        value: formatValue(item.value),
      };
    }
    return item;
  });
};

function DashboardSummary({ isLoading, summaryData }: IDashboardSummaryProp) {
  return <SummaryWidget isLoading={isLoading} data={formatSummaryData(summaryData)} />;
}

export { DashboardSummary };

import React from 'react';
import {
  TRADEMARK_REGISTRATION,
  TRADEMARK_JURISDICTION,
  TRADEMARK_CLASS,
  TRADEMARK_PROPERTY_NAME,
  PROPERTY_CREATED_BY_COL,
  BRAND_COLUMN,
  BRAND_PROPERTY_ACTION_COL,
} from './AssetsColDefination/BrandPropertyCols';
import BrandPropertyComponent from './BrandPropertyComponent';
import { TRADEMARK_TABLE_ID, TRADEMARK_TABLE_NAME } from './constants';
import { PropertyType } from './PropertyTypes';

const TrademarkAssets = () => {
  const PAGE_TITLE = 'Registered Trademarks';
  window.document.title = `${PAGE_TITLE} | Assets`;

  const TABLE_COLUMNS = [
    BRAND_COLUMN,
    TRADEMARK_PROPERTY_NAME,
    TRADEMARK_REGISTRATION,
    TRADEMARK_JURISDICTION,
    TRADEMARK_CLASS,
    { ...PROPERTY_CREATED_BY_COL, isDefaultSort: true },
    BRAND_PROPERTY_ACTION_COL,
  ];

  const defaultColumnIds = [
    'brand',
    'trademark',
    'registration',
    'jurisdiction',
    'classId',
    'created_by',
    'assets-action-col',
  ];

  return (
    <BrandPropertyComponent
      tableName={TRADEMARK_TABLE_NAME}
      id={TRADEMARK_TABLE_ID}
      columns={TABLE_COLUMNS}
      title={PAGE_TITLE}
      disableDatePicker
      type={PropertyType.TRADEMARK}
      defaultColumnIds={defaultColumnIds}
    />
  );
};

export default TrademarkAssets;

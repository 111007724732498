import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import './dashboard.scss';
import {
  GRAPH_HEIGHT_INDEX,
  ROW_HEIGHT,
  THEME_COLOR_BLUE_3,
  THEME_COLOR_ORANGE,
  THEME_DARK_GRAY_3,
} from '../../constants';
import ReactApexChart from 'react-apexcharts';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NoHosting from '../../assets/icons/NoHosting.svg';
import { renderNoDataPlaceholder } from './dashboard.detection';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import DashboardService from '../../services/dashboard.service';
import { ApexOptions } from 'apexcharts';

interface IComponentProps {
  limit: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IComponentState {
  data: any;
  isLoading: boolean;
}

class DashboardHosting extends React.Component<IComponentProps, IComponentState> {
  private readonly dashboardService: DashboardService;
  private _isMounted = false;
  static defaultProps = {
    isLoading: false,
    limit: 10,
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      data: {},
      isLoading: true,
    };
    this.dashboardService = new DashboardService();
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.getHostingData();
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  componentDidUpdate(prevProps: IComponentProps): void {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.getHostingData();
    }
  }

  getHostingData = () => {
    this.setCompState({
      isLoading: true,
    });

    const { startDate, endDate } = this.props;
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    this.dashboardService.getDataForDetectionAndHosting(query).then((res: any) => {
      this.setCompState({
        data: res.result.networkOwnerStatusMap,
        isLoading: false,
      });
    });
  };

  render() {
    const COLOR_LIVE = THEME_COLOR_ORANGE;
    const COLOR_TAKEN_DOWN = THEME_COLOR_BLUE_3;
    const { limit } = this.props;
    const { data, isLoading } = this.state;
    const shownData: any[] = [];
    const liveData: number[] = [];
    const downData: number[] = [];
    const categories: string[] = [];

    let dataWithoutUndefined: any[] = [];
    _.forEach(data, function (item, key) {
      if (!['undefined', 'null', 'Unknown'].includes(key)) {
        item.category = key;
        dataWithoutUndefined.push(item);
      }
    });

    dataWithoutUndefined = _.reverse(
      _.sortBy(dataWithoutUndefined, [
        item => {
          return item.live + item.down;
        },
      ]),
    );

    for (let i = 0; i < dataWithoutUndefined.length && i < limit; i++) {
      shownData.push(dataWithoutUndefined[i]);
    }

    _.forEach(shownData, function (value) {
      liveData.push(value.live);
      downData.push(value.down);
      categories.push(value.category);
    });

    const series = [
      {
        name: 'Sites still live: ',
        data: liveData,
      },
      {
        name: 'Sites taken down: ',
        data: downData,
      },
    ];

    const tickAmount = 2;
    let xMax = 2;
    if (dataWithoutUndefined[0]) {
      xMax = Math.max(dataWithoutUndefined[0].live + dataWithoutUndefined[0].down, 4);
      xMax += xMax % tickAmount;
    }

    const options: ApexOptions = {
      chart: {
        type: 'bar',
        fontFamily: 'Fakt',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories,
        labels: {
          style: {
            colors: THEME_DARK_GRAY_3,
          },
        },
        tickAmount,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        max: xMax,
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          align: 'left',
          style: {
            colors: THEME_DARK_GRAY_3,
          },
          maxWidth: 120,
          offsetX: -12,
        },
        show: dataWithoutUndefined.length > 0,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        custom: (tooltipData: any) => {
          const { dataPointIndex } = tooltipData;
          const currentData = shownData[dataPointIndex];
          const { live, down, category } = currentData;
          return (
            '<div class="hosting-widget-tooltip-container">' +
            '<div class="apexcharts-tooltip-title">' +
            category +
            '</div>' +
            '<div class="live d-flex align-items-center"><div class="dot" style="background-color: ' +
            COLOR_LIVE +
            '"></div>Live: <b>' +
            live +
            '</b></div>' +
            '<div class="down d-flex align-items-center"><div class="dot" style="background-color: ' +
            COLOR_TAKEN_DOWN +
            '"></div>Taken down: <b>' +
            down +
            '</b></div>' +
            '</div>'
          );
        },
      },
      fill: {
        colors: [COLOR_LIVE, COLOR_TAKEN_DOWN],
      },
      colors: [COLOR_LIVE, COLOR_TAKEN_DOWN],
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: -50,
        floating: true,
        markers: {
          radius: 6,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 0,
        },
        formatter: (name: string, legendData: any) => {
          const { seriesIndex } = legendData;
          const value = seriesIndex === 0 ? _.sum(liveData) : _.sum(downData);
          return `<div class="hosting-widget-legend-labe">${name}<b>${value}</b></div>`;
        },
      },
    };

    return (
      <div className='dashboard-widget dashboard-hosting-widget'>
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-hosting'} className={'table-source-url-tooltip'}>
              New counterfeit sites detected by hosting provider
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title'>Top 10 Counterfeit Site Hosting</div>
        </OverlayTrigger>
        <LoadingWrapper isLoading={isLoading}>
          {shownData.length ? (
            <ReactApexChart
              options={options}
              series={series}
              height={ROW_HEIGHT * GRAPH_HEIGHT_INDEX}
              type='bar'
            />
          ) : (
            renderNoDataPlaceholder(
              NoHosting,
              'No hosting data found',
              'We haven’t detected any counterfeit site hosting for this period. Please select another time frame.',
            )
          )}
        </LoadingWrapper>
      </div>
    );
  }
}

export { DashboardHosting };

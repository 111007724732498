import React from 'react';
import _ from 'lodash';
import './Checkbox.scss';
interface IComponentProps {
  defaultChecked: boolean | string | undefined; // default value. Used at initialization
  isStateless: boolean; // updated value. This will trigger state update.
  onChange: (value: any) => void;
  disabled: boolean;
  label: string;
  bulkOption?: boolean;
  checkAll?: boolean;
  checkPartial?: boolean;
}

export enum CHECKBOX_STATES {
  Checked = 'Checked',
  Previous = 'Previous',
  Empty = 'Empty',
}

interface IComponentState {
  checked: any;
  checkType?: CHECKBOX_STATES;
  checkAll?: boolean;
  checkPartial?: boolean;
}

function getCheckType(
  curCheckType: CHECKBOX_STATES | undefined,
  checkAll: boolean | undefined,
  checkPartial: boolean | undefined,
) {
  if (curCheckType === CHECKBOX_STATES.Empty) {
    return !checkAll && checkPartial ? CHECKBOX_STATES.Previous : CHECKBOX_STATES.Checked;
  } else if (curCheckType === CHECKBOX_STATES.Checked) {
    return CHECKBOX_STATES.Empty;
  } else if (curCheckType === CHECKBOX_STATES.Previous) {
    return CHECKBOX_STATES.Checked;
  }
}

function getCheckedValue(curCheckType: CHECKBOX_STATES | undefined) {
  if (curCheckType === CHECKBOX_STATES.Checked || curCheckType === CHECKBOX_STATES.Previous) {
    return true;
  }
  return false;
}

class Checkbox extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    defaultChecked: false,
    isStateless: false,
    disabled: false,
    onChange: _.noop,
    label: '',
    bulkOption: false,
    defaultCheckType: CHECKBOX_STATES.Previous,
  };

  constructor(props: IComponentProps) {
    super(props);

    this.state = this.props.bulkOption
      ? {
          checked: this.props.checkAll ? this.props.checkAll : this.props.checkPartial,
          checkType: this.props.checkAll
            ? CHECKBOX_STATES.Checked
            : this.props.checkPartial
            ? CHECKBOX_STATES.Previous
            : CHECKBOX_STATES.Empty,
        }
      : {
          checked: props.defaultChecked,
        };
  }

  componentDidUpdate(prevProps: IComponentProps) {
    if (this.props.isStateless && prevProps.defaultChecked !== this.props.defaultChecked) {
      this.setState({
        checked: this.props.defaultChecked,
      });
    }
  }

  onChange = () => {
    const { checked, checkType } = this.state;
    const { checkAll, checkPartial } = this.props;
    const value = !checked;
    if (this.props.bulkOption) {
      this.props.onChange({
        value: getCheckedValue(checkType),
        checkType: getCheckType(checkType, checkAll, checkPartial),
      });
      this.setState({
        checked: getCheckedValue(checkType),
        checkType: getCheckType(checkType, checkAll, checkPartial),
      });
    } else {
      this.props.onChange(value);
      if (!this.props.isStateless) {
        this.setState({
          checked: value,
        });
      }
    }
  };

  render() {
    const { checked, checkType } = this.state;
    const { isStateless, defaultChecked, disabled, label, bulkOption } = this.props;
    const value = isStateless ? defaultChecked : checked;

    return (
      <label
        className={'check-box-container ' + (disabled ? 'disabled' : '')}
        data-testid='checkbox-container'
      >
        {label && <span className='label'>{label}</span>}
        <input
          disabled={disabled}
          type='checkbox'
          checked={bulkOption ? getCheckedValue(checkType) : value}
          onChange={this.onChange}
        />
        <span
          className={`checkmark ${
            bulkOption && checkType === CHECKBOX_STATES.Checked
              ? 'checkmark-style'
              : bulkOption && CHECKBOX_STATES.Previous
              ? 'minus-checkmark-style'
              : 'checkmark-style'
          }`}
        />
      </label>
    );
  }
}

export { Checkbox };

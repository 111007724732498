import React, { FunctionComponent } from 'react';

const DEFAULT_MAX_CONTENT_WIDTH = '1000px';

interface ISectionContainerProps {
  className?: string;
  childClassName?: string;
  maxContentWidth?: string;
}

const SectionContainer: FunctionComponent<ISectionContainerProps> = prods => {
  return (
    <div className={'section-container py-lg-5 px-lg-5 ' + prods.className}>
      <div className={'section-container py-md-3 px-md-5 '}>
        <div
          className={'px-4 py-5 flex-center flex-column custom-text-dark ' + prods.childClassName}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: prods.maxContentWidth || DEFAULT_MAX_CONTENT_WIDTH,
          }}
        >
          {prods.children}
        </div>
      </div>
    </div>
  );
};

export { SectionContainer };

import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { RowClassParams } from 'ag-grid-community';
import { setPreviousUrlSha } from '../../../../reducers/table.reducer';

export default function useOnAgRowHighlight() {
  const previousUrlSha = useAppSelector(state => state.tableReducer.previousUrlSha);
  const dispatch = useAppDispatch();

  const getRowClass = useCallback(
    (params: RowClassParams) => {
      if (params.data?.url_sha256 === previousUrlSha || params.data?.sha256 === previousUrlSha) {
        return 'custom-row-background';
      }
    },
    [previousUrlSha],
  );

  useEffect(() => {
    return () => {
      dispatch(setPreviousUrlSha(''));
    };
  }, [dispatch]);

  return { getRowClass };
}

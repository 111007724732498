import { isAdminUser, isBrandModuleAdmin, isBrandReadOnlyUser } from '../constants';
import { store } from '../helpers';
import { useMemo } from 'react';

export const useReadOnlyUser = () => {
  const user = useMemo(() => store.getState().dashboardReducer?.user || {}, []);
  const isReadOnlyUser = useMemo(() => isBrandReadOnlyUser(user), [user]);

  return isReadOnlyUser;
};

export const useBrandModuleAdmin = () => {
  const user = useMemo(() => store.getState().dashboardReducer?.user || {}, []);
  const isBrandModuleAdminUser = useMemo(() => isBrandModuleAdmin(user), [user]);

  return isBrandModuleAdminUser;
};

export const useBrandAdmin = () => {
  const user = useMemo(() => store.getState().dashboardReducer?.user || {}, []);
  const isBrandAdmin = useMemo(() => isAdminUser(user, false, false), [user]);

  return isBrandAdmin;
};

// for other usage outside function component
export const getIsReadOnlyUser = () => {
  const user = store.getState().dashboardReducer?.user || {};
  return isBrandReadOnlyUser(user);
};

export const getIsBrandModuleAdmin = () => {
  const user = store.getState().dashboardReducer?.user || {};
  return isBrandModuleAdmin(user);
};

export const getIsBrandAdmin = () => {
  const user = store.getState().dashboardReducer?.user || {};
  return isAdminUser(user, false, false);
};

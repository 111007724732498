import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  THEME_COLOR_BLUE_GREEN,
  THEME_COLOR_BLUE_3,
  THEME_COLOR_ORANGE,
  THEME_LIGHT_GRAY_2,
  THEME_DARK_GRAY_3,
  GRAPH_HEIGHT_INDEX,
  ROW_HEIGHT,
} from '../../constants';
import ReactApexChart from 'react-apexcharts';
import './dashboard.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NoDetections from '../../assets/icons/NoDetections.svg';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import resetIcon from '../../assets/icons/Reset.svg';
import DashboardService from '../../services/dashboard.service';
import { ApexOptions } from 'apexcharts';

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IComponentState {
  data: any;
  isLoading: boolean;
}

export const renderNoDataPlaceholder = (icon: any, title: string, text: string) => {
  return (
    <div className={'no-data-placeholder-container'}>
      <img src={icon} alt={'No Data'} />
      <div className={'title'}>{title}</div>
      <div className={'text'}>{text}</div>
    </div>
  );
};

class DashboardDetection extends React.Component<IComponentProps, IComponentState> {
  private readonly dashboardService: DashboardService;
  private _isMounted = false;
  static defaultProps = {
    isLoading: false,
  };
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      data: {},
      isLoading: true,
    };
    this.dashboardService = new DashboardService();
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.getDetectionData();
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  componentDidUpdate(prevProps: IComponentProps): void {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.getDetectionData();
    }
  }

  getDetectionData = () => {
    this.setCompState({
      isLoading: true,
    });

    const { startDate, endDate } = this.props;
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    this.dashboardService.getDataForDetectionAndHosting(query).then((res: any) => {
      this.setCompState({
        data: res.result.dateStatusMap,
        isLoading: false,
      });
    });
  };

  // Add zeros on the missing dates.
  parseDetectionMap = (data: any) => {
    const { startDate, endDate } = this.props;
    for (let time = startDate.valueOf(); time <= endDate.valueOf(); time += 24 * 3600000) {
      const date = moment(time).format('YYYY-MM-DD');
      if (_.isEmpty(data[date])) {
        data[date] = { live: 0, down: 0 };
      }
    }
  };

  render() {
    let { data, isLoading } = this.state;
    const downData: any[] = [];
    const liveData: any[] = [];
    const totalData: any[] = [];
    const yTickAmount = 2;
    let yMax = 2;

    if (!_.isEmpty(data)) {
      this.parseDetectionMap(data);
    }
    data = _.forEach(data, (item, date) => {
      item.date = moment(date).valueOf();
    });
    data = _.sortBy(data, [
      item => {
        return item.date;
      },
    ]);
    _.forEach(data, item => {
      const { live, down, date } = item;
      yMax = Math.max(yMax, live + down);
      downData.push([date, down]);
      liveData.push([date, live]);
      totalData.push([date, live + down]);
    });
    yMax += yMax % yTickAmount;

    const series = [
      {
        name: 'Total detected sites: ',
        data: totalData,
      },
      {
        name: 'Sites still live: ',
        data: liveData,
      },
      {
        name: 'Sites taken down: ',
        data: downData,
      },
    ];
    const options: ApexOptions = {
      chart: {
        width: '100%',
        type: 'line',
        fontFamily: 'Fakt',
        toolbar: {
          show: true,
          offsetX: 10,
          offsetY: -40,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: '<img src="' + resetIcon + '" width="20" alt="reset">',
          },
        },
        events: {
          zoomed: () => {
            const resetBtn = document.getElementsByClassName('apexcharts-reset-icon')[0];
            resetBtn.setAttribute('style', 'opacity: 1; cursor: pointer');
          },
        },
      },
      stroke: {
        width: 3,
      },
      colors: [THEME_COLOR_BLUE_GREEN, THEME_COLOR_ORANGE, THEME_COLOR_BLUE_3],
      grid: {
        borderColor: THEME_LIGHT_GRAY_2,
        padding: {
          top: 30,
        },
      },
      markers: {
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: THEME_DARK_GRAY_3,
          },
          formatter: function (val: string) {
            return moment(val).format('MMM DD');
          },
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
      },
      yaxis: {
        title: {
          text: 'Sites',
        },
        tickAmount: yTickAmount,
        labels: {
          style: {
            colors: THEME_DARK_GRAY_3,
          },
          formatter: function (val: number) {
            return val.toFixed(0);
          },
        },
        max: yMax,
      },
      tooltip: {
        y: {
          title: {
            formatter: (name: string): string => {
              if (name.indexOf('Total') !== -1) {
                return 'Total';
              } else if (name.indexOf('live') !== -1) {
                return 'Live';
              } else if (name.indexOf('down') !== -1) {
                return 'Taken Down';
              }
              return '';
            },
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        floating: true,
        offsetX: -40,
        itemMargin: {
          horizontal: 10,
          vertical: 3,
        },
        formatter: (name: string, data: any) => {
          const series = data.w.config.series[data.seriesIndex].data;
          let value = 0;
          _.forEach(series, item => {
            value += item[1];
          });
          return `<div class="hosting-widget-legend-labe">${name}<b>${value}</b></div>`;
        },
      },
    };

    return (
      <div className='dashboard-widget dashboard-detection-widget'>
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-detection'} className={'table-source-url-tooltip'}>
              New counterfeit sites detected by time
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title'>Counterfeit Site Detection</div>
        </OverlayTrigger>
        <LoadingWrapper isLoading={isLoading}>
          {data.length ? (
            <ReactApexChart
              options={options}
              series={series}
              height={ROW_HEIGHT * GRAPH_HEIGHT_INDEX}
              type='line'
            />
          ) : (
            renderNoDataPlaceholder(
              NoDetections,
              'No detections found',
              'We haven’t detected any counterfeit sites for this period. Please select another time frame.',
            )
          )}
        </LoadingWrapper>
      </div>
    );
  }
}

export { DashboardDetection };

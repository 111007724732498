import React from 'react';
import _ from 'lodash';
import { ITableApiColumn } from '../Common/Table/table.api';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DISPOSITION_FILTER_OPTIONS } from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import { DispositionStatus } from '../Common/DispositionStatus';
import moment from 'moment';
import { WEB_DASHBOARD_PATHNAME } from '../MonitorAndTakedown/constants';
import { TakedownSubmittedByOptions, globalTakeDownStatus, latestGtdActions } from './constants';
import AgGridDateRangeFilter from '../Common/Table/ag-table/AgGridDateRangeFilter';

export const SUBMITTED_COLUMNS: ITableApiColumn[] = [
  {
    id: 'src_url',
    accessor: 'src_url',
    fieldForExport: 'src_url',
    header: 'Source URL',
    headerTooltip: 'Link to URL Details View',
    render: (data: any) => {
      const { src_url } = data;
      const timestamp = moment(data?.created_ts).valueOf();
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + src_url} className={'table-source-url-tooltip'}>
              {src_url}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column d-flex align-items-center'}>
            <Link to={`${WEB_DASHBOARD_PATHNAME}/insights/${timestamp}/${data.url_sha256}`}>
              {src_url}
            </Link>
          </div>
        </OverlayTrigger>
      );
    },
    hiddenInDragDrop: true,
    agFilterType: 'agTextColumnFilter',
    disableForSelection: true,
  },
  {
    id: 'scan_source',
    accessor: 'scan_source',
    header: 'Scan Source',
    render: (data: any) => {
      if (_.isEmpty(data.scan_source)) {
        return '--';
      }
      return data.scan_source;
    },
    agFilterType: 'agTextColumnFilter',
  },
  {
    id: 'global_takedown_status',
    accessor: 'global_takedown_status',
    header: 'Status',
    filterOptions: Object.values(globalTakeDownStatus).map(({ label, value }) => ({
      label,
      value,
    })),
    type: 'options',
    agFilterType: 'agTextColumnFilter',
    render: (data: any) => {
      const statusValues = globalTakeDownStatus[data.global_takedown_status.toUpperCase()];
      if (_.isEmpty(data.global_takedown_status) || !statusValues) {
        return '--';
      }
      return (
        <span className={`global_takedown_status status_${statusValues.value}`}>
          {statusValues.label}
        </span>
      );
    },
  },
  {
    id: 'takedown_eligible',
    accessor: 'takedown_eligible',
    header: 'Eligible for Takedown',
    agFilterType: 'agTextColumnFilter',
    filterOptions: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    type: 'options',
    hidden: true,
  },
  {
    id: 'gtd_submitted_ts',
    accessor: 'gtd_submitted_ts',
    header: 'Takedown Submission Timestamp',
    type: 'date',
    render: (data: any) => {
      if (!data.gtd_submitted_ts) {
        return '--';
      }
      return moment(data.gtd_submitted_ts).format('DD-MMM-YYYY hh:mm a');
    },
    filterDisabled: false,
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'latest_gtd_action',
    accessor: 'latest_gtd_action',
    header: 'Latest Actions',
    render: (data: any) => {
      const latestAction = data?.latest_gtd_action;
      if (!latestAction || latestAction === undefined) {
        return '--';
      }
      return latestAction;
    },
    type: 'options',
    filterOptions: latestGtdActions,
    agFilterType: 'agTextColumnFilter',
  },

  {
    id: 'current_disposition',
    accessor: 'current_disposition',
    fieldForExport: 'current_disposition',
    header: 'Current Disposition',
    headerTooltip: 'Disposition when Site was Last Scanned',
    filterOptions: DISPOSITION_FILTER_OPTIONS,
    render: (data: any) => {
      if (_.isEmpty(data.current_disposition)) {
        return '--';
      }
      return <DispositionStatus status={data.current_disposition} />;
    },
    type: 'options',
    agFilterType: 'agTextColumnFilter',
  },
  {
    id: 'takedown_ts',
    accessor: 'takedown_ts',
    header: 'Takedown Timestamp',
    type: 'date',
    render: (data: any) => {
      if (!data.takedown_ts) {
        return '--';
      }
      return moment(data.takedown_ts).format('DD-MMM-YYYY hh:mm a');
    },
    filterDisabled: false,
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'latest_gtd_ts',
    accessor: 'latest_gtd_ts',
    header: 'Latest Actions Timestamp',
    type: 'date',
    render: (data: any) => {
      if (!data.latest_gtd_ts) {
        return '--';
      }
      return moment(data.latest_gtd_ts).format('DD-MMM-YYYY hh:mm a');
    },
    filterDisabled: false,
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'gtd_submitted_by',
    accessor: 'gtd_submitted_by',
    header: 'Takedown Submitted By',
    filterOptions: TakedownSubmittedByOptions,
    render: (data: any) => {
      if (!data.gtd_submitted_by) {
        return '--';
      }
      return data.gtd_submitted_by;
    },
    type: 'options',
    agFilterType: 'agTextColumnFilter',
  },
];

import React from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import { IFilter, IFilterProps, TFilterAction } from './constant';
import { TableFilterControllerAddedItem } from './filter.controller.added.item';
import {
  TableFilterControllerEditItem,
  DEFAULT_FILTER_METHOD_STRING,
} from './filter.controller.edit.item';
import { IDropdownOption, DropdownOptionDefaultValue } from '../Dropdown';
import './table.scss';
import { AddBlue, CloseIcon, FilterIcon, HelpFilterIcon } from '../../../assets/SVGIcons';
import { generateId } from '../../../constants';

export const generateDefaultFilter = (): IFilter => ({
  id: generateId(10),
  filterType: 'string',
  filterBy: DropdownOptionDefaultValue,
  filterMethod: DEFAULT_FILTER_METHOD_STRING,
  filterValue: '',
});

interface IComponentState {
  expanded: boolean;
  submitEnabled: boolean;
  filters: IFilter[];
}

class TableFilterController extends React.Component<IFilterProps, IComponentState> {
  public currentFilters: IFilter[] = [];
  static defaultProps = {
    columns: [],
    autoAddFirstFilter: true,
    alwaysExpanded: false,
    afterFilterChange: _.noop,
    tableLoading: false,
  };

  constructor(props: IFilterProps) {
    super(props);
    this.currentFilters =
      _.isEmpty(props.filters) && props.autoAddFirstFilter
        ? [generateDefaultFilter()]
        : props.filters;
    this.state = {
      expanded: props.alwaysExpanded || false,
      submitEnabled: false,
      filters: this.currentFilters,
    };
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.filters.length !== prevProps.filters.length) {
      this.setState({
        filters: this.props.filters,
      });
    }
  }
  onToggleFilter = (toExpand: boolean | undefined = undefined): void => {
    let { filters } = this.state;
    if (toExpand === undefined) {
      toExpand = !this.state.expanded;
    }
    const nonCustomFilters = _.filter(filters, f => !f.isCustomFilter);
    if (toExpand && nonCustomFilters.length === 0) {
      filters = [...filters, generateDefaultFilter()];
    }
    this.setState({
      expanded: toExpand,
      filters,
    });
    this.props.onToggleFilter(toExpand);
  };

  onAddedFilterRemove = (id: string) => {
    const newFilters: IFilter[] = this.state.filters.concat();
    const index = _.findIndex(newFilters, { id });
    newFilters.splice(index, 1);
    this.setState({
      filters: newFilters,
    });
    this.currentFilters = newFilters.concat();
    this.props.onFiltersApplied(newFilters);
  };

  afterFilterChange = (newFilters: IFilter[]) => {
    this.validateForm();
    this.props.afterFilterChange(newFilters);
  };

  onFilterChange = (id: string, filter: IFilter, action: TFilterAction) => {
    const newFilters: IFilter[] = this.state.filters.concat();
    const index = _.findIndex(newFilters, { id });
    switch (action) {
      case 'add':
        newFilters.push(filter);
        break;
      case 'remove':
        newFilters.splice(index, 1);
        break;
      case 'edit':
        newFilters.splice(index, 1, filter);
        break;
    }
    this.setState(
      {
        filters: newFilters,
      },
      this.afterFilterChange.bind(this, newFilters),
    );
  };

  validateForm = () => {
    const { filters } = this.state;
    let submitEnabled = true;
    for (let i = 0; i < filters.length; i++) {
      const { filterBy, filterMethod, filterValue, filterErr } = filters[i];
      if (_.isEmpty(filterBy) || _.isEmpty(filterMethod) || !filterValue || !_.isEmpty(filterErr)) {
        submitEnabled = false;
        break;
      }
    }
    this.setState({
      submitEnabled,
    });
  };

  onFiltersApplied = () => {
    const { filters } = this.state;
    this.props.onFiltersApplied(filters);
    this.onToggleFilter();
    this.currentFilters = filters.concat();
  };

  onFiltersCancelled = () => {
    const activeFilters: any[] = _.filter(this.currentFilters, (filter: any) => {
      if (filter?.filterValue) {
        return true;
      }
      if (filter?.isCustomFilter) {
        return true;
      }
      return false;
    });
    this.currentFilters = activeFilters;
    this.props.onFiltersCancelled(this.currentFilters);
    const toExpand = false;
    this.setState({
      filters: this.currentFilters,
      expanded: toExpand,
    });
    this.props.onToggleFilter(toExpand);
  };

  onFilterCleared = () => {
    this.setState({ filters: [generateDefaultFilter()] });
    this.validateForm();
    this.onFiltersApplied();
    this.onToggleFilter();
  };

  render() {
    const { type, columns, alwaysExpanded, tableLoading, playbookFilterIds, dataClass } =
      this.props;
    const { expanded, submitEnabled } = this.state;
    let filters = _.filter(this.state.filters, f => !f.isCustomFilter && !f.hideFilter);
    if (filters.length === 0) {
      filters = [generateDefaultFilter()];
    }

    const addedFilters: any = [];
    let controllerClassName =
      'table-filter-controller d-flex justify-content-start align-content-center';
    const filterByOptions: IDropdownOption[] = [];

    if (expanded) {
      controllerClassName = controllerClassName + ' expanded';
      columns.forEach((column: any) => {
        if (!column.filterDisabled) {
          filterByOptions.push({
            label: column.header,
            value: column.index || column.accessor || column.header,
          });
        }
      });
    } else {
      filters.forEach((filter: IFilter, i: number) => {
        const { filterBy, filterMethod, filterValue } = filter;
        if (!_.isEmpty(filterBy) && !_.isEmpty(filterMethod) && filterValue) {
          addedFilters.push(
            <TableFilterControllerAddedItem
              key={i}
              filter={filter}
              onRemove={this.onAddedFilterRemove}
              id={filter.id}
            />,
          );
        }
      });
    }

    return (
      <div className={controllerClassName} data-testid='table-filter-controller'>
        {!expanded && (
          <div className={'added-filters-container'} data-testid='added-filters-container'>
            {addedFilters}
          </div>
        )}
        {!expanded && (
          <div
            id='add-filter-button'
            data-testid='add-filter-button'
            aria-disabled={tableLoading}
            className={`tool-buttons-wrapper ${tableLoading ? 'is-disabled' : ''}`}
            onClick={() => {
              this.onToggleFilter();
            }}
          >
            <FilterIcon color='grey' />
          </div>
        )}
        {expanded && (
          <div className={'filters-editor-container'} data-testid='filters-editor-container'>
            {!alwaysExpanded && (
              <div className='filters-editor-header d-flex justify-content-between align-items-center'>
                <div className={'d-flex align-items-center'}>
                  <span>Filter Columns</span>
                  <a target='_blank' rel='noopener noreferrer' href={'/filterFaq'}>
                    <div className={'filter-helper-icon-wrapper'}>
                      <HelpFilterIcon color='grey' />
                    </div>
                  </a>
                </div>
                <div
                  id='close-filter-modal'
                  data-testid='close-filter-modal'
                  onClick={this.onFiltersCancelled}
                >
                  <CloseIcon color='grey' />
                </div>
              </div>
            )}
            {filters.map((filter: IFilter, i: number) => {
              let checkBoxOptionEnabled = false;
              if (
                filter.filterBy.value === 'ssns' ||
                filter.filterBy.value === 'ips' ||
                filter.filterBy.value === 'cryptos'
              ) {
                checkBoxOptionEnabled = true;
                if (playbookFilterIds?.length && dataClass?.length) {
                  playbookFilterIds.forEach(item => {
                    const filterObj = _.find(
                      dataClass[0].attributes,
                      attributes => attributes.id === item,
                    );
                    if (filter.filterBy.value === filterObj.field) {
                      filter.isChecked = true;
                    }
                  });
                }
              }
              return (
                <TableFilterControllerEditItem
                  key={filter.filterBy.value + i}
                  id={filter.id}
                  filter={filter}
                  columns={columns}
                  type={type}
                  checkBoxOptionEnabled={checkBoxOptionEnabled}
                  filterByOptions={filterByOptions}
                  isSelectedCheckbox={filter.isChecked || false}
                  removePlaybookFilterId={this.props.removePlaybookFilterId}
                  onRemove={this.onFilterChange}
                  onChange={this.onFilterChange}
                  addPlaybookFilterIds={this.props.addPlaybookFilterIds}
                  tagsOptions={this.props.tagsOptions || undefined}
                />
              );
            })}
            <div
              className='add-filter-button'
              onClick={() => {
                this.onFilterChange(generateId(10), generateDefaultFilter(), 'add');
              }}
            >
              <div className='add-filter-wrapper'>
                <AddBlue color='blue' />
              </div>
              Add Filter
            </div>
            {!alwaysExpanded && (
              <div className='filters-editor-buttons'>
                <Button
                  id='cancel-filter-button'
                  data-testid='cancel-filter-button'
                  variant='outline-secondary'
                  onClick={() => {
                    this.onFiltersCancelled();
                  }}
                  size='sm'
                  className={'cancel-button'}
                >
                  Cancel
                </Button>
                <Button
                  id='apply-filter-button'
                  data-testid='apply-filter-button'
                  variant='primary'
                  disabled={!submitEnabled}
                  onClick={this.onFiltersApplied}
                  size='sm'
                  className={'submit-button'}
                >
                  Apply
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export { TableFilterController };

import { Observable, repeat, startWith, switchMap, timer } from 'rxjs';
import { appConstants } from '../constants';

let token: string;

async function updateOptions(options: RequestInit, isPlatformEndPoint: boolean) {
  if (!token) {
    ({ token } = await fetch('/api/v1/auth/csrf-token').then(response => response.json()));
  }

  options.headers = {
    ...options.headers,
    'x-csrf-token': token,
    ...(isPlatformEndPoint === true && {
      applicationtype: appConstants.CLIENT_APP_TYPE,
    }),
  };

  return options;
}
function fetchApi(url: RequestInfo, options?: RequestInit): Promise<Response> {
  if (!options) options = {};
  return updateOptions(options, url.toString().includes('/platform-api/')).then(options =>
    fetch(url, options),
  );
}

export function pollRequest(
  handleApiResponse: any,
  url: RequestInfo,
  options?: RequestInit,
  pollFrequency: number = 15000,
): Observable<Response> {
  return timer(pollFrequency).pipe(
    startWith(0),
    switchMap(() => fetchApi(url, options).then(handleApiResponse)),
    repeat(),
  );
}

export default fetchApi;

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TableFilterController } from '../../../Common/Table/filter.controller';
import { generateId, getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { IUgcThreatHeaders, ugcType } from '../../Types/ugc.types';
import '../../Style/ThreatDetection.scss';
import { InfoIcon } from '../../../../assets/SVGIcons';
import { DropdownMultiSelect } from '../../../Common/DropdownMultiSelect';

const UgcThreatHeader = ({
  title,
  ugcPlatforms,
  updateFilters,
  type,
}: IUgcThreatHeaders): JSX.Element => {
  const [activeFilters, setActiveFilters] = useState([]);
  const isDemo = window.location.pathname.includes('premium');
  const platformOptions = ugcPlatforms.map((platform: any) => {
    return {
      label: platform.label,
      value: platform.value,
    };
  });

  useEffect(() => {
    const oldFilters = getLocalStorageValue([`${type}DashBoard`, 'summary_widget']);
    if (Object.keys(oldFilters).length > 0 && oldFilters.filters.length > 0) {
      setActiveFilters(oldFilters.filters);
    }
  }, []);

  const onHandleFilters = (selectedFilter: any[]) => {
    const filters: any = [];

    selectedFilter.map((filter: any) => {
      const filterProperties = {
        filterBy: { label: 'Platforms', value: 'platform_id' },
        filterErr: '',
        filterLabel: filter.label,
        filterMethod: { label: 'Is', value: 'is' },
        filterType: 'options',
        filterValue: filter.value,
      };
      filters.push(filterProperties);
    });
    setLocalStorageValue([`${type}DashBoard`, 'summary_widget'], {
      filters,
    });
    setActiveFilters(filters);
    updateFilters(filters);
  };

  return (
    <div className='social-threat-header' data-testid='ugc-threat-header'>
      <div className='social-threat-title'>
        <div className='title-container'>
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-unregister`} className='social-threat-icon-tooltip'>
                {'We monitor every search term on each relevant platform daily for new detections'}
              </Tooltip>
            }
          >
            <div className='icon'>
              <InfoIcon color='grey' />
            </div>
          </OverlayTrigger>
          <div className='title-wrapper' data-testid='title-wrapper'>
            {title}
          </div>
        </div>
        {type !== ugcType.App_Store && (
          <div className={'tool-bar-container'}>
            <div className={'tools-container d-flex justify-content-end align-content-center'}>
              <DropdownMultiSelect
                options={platformOptions}
                onSubmit={onHandleFilters}
                placeholder='Filter By Platform:'
                updateOnChange
                hideFooter
                removable
                limit={1}
                disabled={isDemo}
              />
            </div>
          </div>
        )}
      </div>
      <div className='social-threat-actions'>
        <div className='action-circle' />
        <div className='action-label'>Bolster actions</div>
      </div>
    </div>
  );
};

export default UgcThreatHeader;

import React, { FunctionComponent, useState, useEffect, useMemo, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState, history } from '../../helpers';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash';
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button, Modal } from 'react-bootstrap';
import StripeService from '../../services/stripe.service';
import { ContactUsPage } from '../ContactUs/ContactUsPage';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import StripeImg from '../../assets/logo/stripe-logo.png';
import './UpgradeStripe.scss';
import { LoadingWrapper } from './LoadingWrapper';
import { useCookies } from 'react-cookie';
import { appActions } from '../../actions';
import { IChosenPlan, IPlanV2, PlanCardsV2, showPrice } from '../Plans/PlanCards';
import { DashBoardDto, ThemeModes, withCommas } from '../../constants';
import DashboardService from '../../services/dashboard.service';
import { EPlanProduct } from './Subscription';
import { TextField } from '@material-ui/core';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { IDashboardReducerState } from '../../reducers/dashboard.reducer';
import ThemeContext from '../../context/ThemeContext';
import {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import { AlertActionsTypes } from '../../constants';
import { alertActions } from '../../actions';

interface IUpgradeV1Props {
  className: string;
  user: DashBoardDto;
  startAt: number;
  onHide: Function;
  successCallback: Function;
  disablePricing: (message: boolean) => void;
}

interface IUpgradeV2Props {
  className: string;
  liveScanSubscriptionPlan: any;
  startAt?: 1 | 2 | 3;
  onHide: Function;
  successCallback: Function;
  disablePricing: (message: boolean) => void;
}

const stripeService: StripeService = new StripeService();

const SubmitButton = ({ processing, error, children, disabled }: any) => {
  return (
    <button
      className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
      type='submit'
      disabled={processing || disabled}
    >
      {processing ? 'Processing...' : children}
    </button>
  );
};

export const CardField = ({ onChange }: any) => (
  <div className='FormRow'>
    <CardElement
      options={{
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: '#6e94ed',
            color: '#333',
            fontWeight: 500,
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#6e94ed',
            },
            '::placeholder': {
              color: '#6e94ed',
            },
          },
          invalid: {
            iconColor: '#6e94ed',
            color: '#6e94ed',
          },
        },
      }}
      onChange={onChange}
    />
  </div>
);

const ErrorMessage = ({ children }: any) => (
  <div className='ErrorMessage' role='alert'>
    {/* <svg width='16' height='16' viewBox='0 0 17 17'>
      <path
        fill='#fafafb'
        d='M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z'
      />
      <path
        fill='#6772e5'
        d='M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z'
      />
    </svg> */}
    {children}
  </div>
);

interface ICheckoutFormV1 {
  priceId: number;
  successCallback: Function;
  setSuccess: Function;
  chosenPlan: any;
}

const CheckoutFormV1: FunctionComponent<ICheckoutFormV1> = ({
  priceId,
  successCallback,
  setSuccess,
  chosenPlan,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethodID, setPaymentMethodID] = useState('');
  const [processing, setProcessing] = useState(false);
  const [cardErrorMsg, setCardErrorMsg] = useState('');
  const [cardValues, setCardValues] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    region: '',
  });

  const handleCardInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setCardValues((inputs: any) => {
      return { ...inputs, [event.target.name]: event.target.value };
    });
  };

  const selectCountry = (val: string) => {
    setCardValues((inputs: any) => {
      return { ...inputs, country: val };
    });
  };

  const selectRegion = (val: string) => {
    setCardValues((inputs: any) => {
      return { ...inputs, region: val };
    });
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setCardErrorMsg('');

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    setProcessing(true);

    if (!cardValues.name || cardValues.name.length < 2) {
      setProcessing(false);
      setCardErrorMsg('Please enter a valid name');
      return;
    } else if (!cardValues.city || cardValues.city.length < 2) {
      setProcessing(false);
      setCardErrorMsg('Please enter a valid city name');
      return;
    } else if (!cardValues.region || cardValues.region.length < 2) {
      setProcessing(false);
      setCardErrorMsg('State/Province is a required field');
      return;
    } else if (!cardValues.country || cardValues.country.length < 2) {
      setProcessing(false);
      setCardErrorMsg('Country is a required field');
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'payClicked',
      category: 'Page',
      action: 'clicked',
      label: 'payClicked',
    });

    if (!chosenPlan) {
      return;
    }

    const { vendorCustomerId } = await stripeService.createCustomer({
      name: cardValues.name,
      city: cardValues.city,
      country: cardValues.country,
      line1: cardValues.address1,
      line2: cardValues.address2,
      state: cardValues.region,
    });
    if (!vendorCustomerId) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardValues.name,
        address: {
          city: cardValues.city,
          country: cardValues.country,
          line1: cardValues.address1,
          line2: cardValues.address2,
          state: cardValues.region,
        },
      },
    });

    if (error) {
      setProcessing(false);
      if (error.message) {
        setCardErrorMsg(error.message);
      }
      const cardEl = elements.getElement('card');
      if (!cardEl) {
        return;
      }
      cardEl.focus();
      return;
    } else {
      if (!paymentMethod || !paymentMethod.id) {
        return;
      }
      stripeService
        .createSubscription({
          priceId: priceId,
          vendorPaymentMethodId: paymentMethod.id,
        })
        .then(data => {
          successCallback();
        })
        .catch(e => {
          console.error('Error creating customer:', e);
        });

      setPaymentMethodID(paymentMethod.id);
      setSuccess(true);
    }
  };
  const priorityCountries = ['US'];

  return paymentMethodID ? (
    <div className='Result'>
      <div className='ResultTitle' role='alert'>
        Thank you for subscribing to
      </div>
      <div className='ResultMessage'>
        <strong>CheckPhish Professional API</strong>.
      </div>
    </div>
  ) : (
    <form onSubmit={handleSubmit}>
      <div className='FormGroup'>
        <input
          type='text'
          className={'form-control'}
          placeholder='Name On Card'
          name='name'
          required
          aria-required='true'
          onChange={handleCardInputChange}
        />
      </div>
      <div className='FormGroup'>
        <input
          type='text'
          className={'form-control'}
          placeholder='Street Address 1'
          name='address1'
          onChange={handleCardInputChange}
        />
      </div>
      <div className='FormGroup'>
        <input
          type='text'
          className={'form-control'}
          placeholder='Street Address 2'
          name='address2'
          onChange={handleCardInputChange}
        />
      </div>
      <div className='FormGroup'>
        <input
          type='text'
          className={'form-control'}
          placeholder='City'
          name='city'
          aria-required='true'
          required
          onChange={handleCardInputChange}
        />
      </div>
      <div className='FormGroup mod-select'>
        <CountryDropdown
          priorityOptions={priorityCountries}
          value={cardValues.country}
          valueType='short'
          onChange={val => selectCountry(val)}
        />
      </div>
      <div className='FormGroup mod-select'>
        <RegionDropdown
          country={cardValues.country}
          value={cardValues.region}
          onChange={val => selectRegion(val)}
          disableWhenEmpty={true}
          valueType='short'
          countryValueType='short'
          defaultOptionLabel='Select State/Province'
        />
      </div>
      <div className='FormGroup cc'>
        <CardField
          onChange={(e: any) => {
            if (e.complete) {
              setCardErrorMsg('');
            }
          }}
        />
      </div>
      {cardErrorMsg && <ErrorMessage>{cardErrorMsg}</ErrorMessage>}
      <SubmitButton processing={processing} error={cardErrorMsg} disabled={!stripe}>
        {chosenPlan.isMonth === 'on'
          ? `Pay $${chosenPlan.monthlyPrice}`
          : `Pay $${chosenPlan.annualPrice}`}
      </SubmitButton>
    </form>
  );
};

const generateChosenPlan = (subscription: any, allPlans: any) => {
  const at = parseInt(subscription.planId);
  return {
    isMonth: subscription.term == 'yearly' ? 'off' : 'on',
    range: at,
    monthlyPrice: withCommas(allPlans[at - 1].prices[0].price),
    annualPrice: withCommas(allPlans[at - 1].prices[1].price),
    scansPerDay: subscription.scansPerDay,
    scansPerMonth: subscription.scansPerMonth,
    planId: subscription.planId,
    priceId: subscription.priceId,
  };
};

const generateInitialPlan = (allPlans: any) => {
  const initialPlan = allPlans[0];
  return {
    isMonth: initialPlan.prices[0].term === 'monthly' ? 'on' : 'off',
    range: initialPlan.id,
    monthlyPrice: initialPlan.prices[0].price,
    annualPrice: initialPlan.prices[1].price,
    scansPerDay: initialPlan.limits.day,
    scansPerMonth: initialPlan.limits.month,
    planId: initialPlan.id,
    priceId: initialPlan.prices[0].id,
  };
};

const UpgradeStripeV1: FunctionComponent<IUpgradeV1Props> = ({
  className = '',
  startAt,
  onHide,
  successCallback,
  user,
  disablePricing,
}) => {
  const dashboardService: DashboardService = new DashboardService();
  const [upgradeSteps, setUpgradeSteps] = useState(startAt || 3);
  const [message, setMessage] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [allPlans, setAllPlans] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState({
    phone: '',
    company: '',
  });
  const [chosenPlan, changeChosenPlan] = useState<any>(null);
  const [cookies, _setCookie, removeCookie] = useCookies(['checkphishChosenPlan']);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      stripeService.getToken(),
      stripeService.getPlans(),
      dashboardService.getUserInfo(),
    ])
      .then(data => {
        const [{ config }, plansFromStripe, user] = data;
        setStripePromise(loadStripe(config.pubKey, { apiVersion: '2022-11-15' }));
        setAllPlans(plansFromStripe);
        if (user?.subscription && Object.keys(plansFromStripe).length) {
          changeChosenPlan(generateChosenPlan(user.subscription, plansFromStripe));
        } else if (Object.keys(plansFromStripe).length) {
          changeChosenPlan(
            cookies.checkphishChosenPlan
              ? cookies.checkphishChosenPlan
              : generateInitialPlan(plansFromStripe),
          );
        }
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  }, []);

  const hideForm = () => {
    onHide();
  };

  const showChangeSubscription = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setUpgradeSteps(3);
  };

  const callbackPro = function () {
    setUpgradeSteps(1);
  };

  const callbackEnt = function () {
    setUpgradeSteps(4);
  };

  let modalBody;
  switch (upgradeSteps) {
    case 1:
      modalBody = (
        <div className={'products-page-plans-section py-1'}>
          <h4 className={'pb-4 text-center'}>{success ? 'Payment successful' : 'Checkout'}</h4>
          <div className='container stripe-description'>
            <div className='row text-muted border-bottom mb-2'>
              <div className='col-6'>
                <h6>DESCRIPTION</h6>
              </div>
              <div className='col-4  text-right'>
                <h6>PRICE</h6>
              </div>
              <div className='col-2 text-right'>
                <h6>TOTAL</h6>
              </div>
            </div>

            <div className='row border-bottom mb-2'>
              <div className='col-6 text-left  text-muted'>
                <span className='font-weight-bold'>Professional API</span>
                <br />- {chosenPlan.scansPerDay} scans per day
                <br />- {chosenPlan.scansPerMonth} scans per month
              </div>
              <div className='col-4 text-right  text-muted'>
                {chosenPlan.isMonth === 'on'
                  ? `$${chosenPlan.monthlyPrice}/month`
                  : `$${chosenPlan.annualPrice}/year`}
              </div>
              <div className='col-2 text-right  text-muted'>
                {chosenPlan.isMonth === 'on'
                  ? `$${chosenPlan.monthlyPrice}`
                  : `$${chosenPlan.annualPrice}`}
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                {!success && (
                  <a href='' onClick={showChangeSubscription}>
                    Change Subscription
                  </a>
                )}
              </div>
              <div className='col-4  text-right  text-muted'>
                {success ? 'Total Paid' : 'Amount Due'}
              </div>
              <div className='col-2  text-right  text-muted'>
                {chosenPlan.isMonth === 'on'
                  ? `$${chosenPlan.monthlyPrice}`
                  : `$${chosenPlan.annualPrice}`}
              </div>
            </div>
          </div>
          <div className={'products-page-plans-section py-1 upgrade-stripe'}>
            <Elements stripe={stripePromise}>
              <CheckoutFormV1
                successCallback={successCallback}
                priceId={chosenPlan.priceId}
                setSuccess={setSuccess}
                chosenPlan={chosenPlan}
              />
            </Elements>
          </div>
          <div className='stripe-logo'>
            <img width='140' src={StripeImg} alt={'Secure payment with Stripe'} />
          </div>
        </div>
      );
      break;
    case 3:
      modalBody = (
        <div className='products-page pro-plan-modal'>
          {/* <PlanCards
            onClickPro={callbackPro}
            onClickEnt={callbackEnt}
            allPlans={allPlans}
            user={user}
            values={
              chosenPlan
                ? chosenPlan
                : {
                    isMonth: '',
                    range: '',
                    monthlyPrice: '',
                    annualPrice: '',
                    scansPerDay: '',
                    scansPerMonth: '',
                    planId: '',
                    priceId: '',
                  }
            }
            setValues={(val: any) => {
              changeChosenPlan(val);
              disablePricing(false);
              removeCookie('checkphishChosenPlan', { path: '/', domain: 'checkphish.ai' });
              history.replace('/account/profile');
            }}
          /> */}
        </div>
      );
      break;
    case 4:
      modalBody = (
        <div className={'products-page-plans-section py-1'}>
          <h4 className={'pb-4 text-center'}>Upgrade to Enterprise</h4>
          <ContactUsPage user={user} />
        </div>
      );
      break;
    case 5:
      modalBody = (
        <div>
          <div>{message}</div>
        </div>
      );
      break;
    default:
      modalBody = null;
      break;
  }

  const modal = (
    <Modal
      className={'upgrade-modal'}
      show={upgradeSteps > 0}
      size='lg'
      onHide={hideForm}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='download-via-email'>Upgrade API</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'modal-body'}>
        <LoadingWrapper isLoading={loading}>{modalBody}</LoadingWrapper>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={hideForm}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return <div className={'upgrade-button-component ' + className}>{modal}</div>;
};

const mapStateToPropsV1 = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};

const mapDispatchToPropsV1 = {
  disablePricing: appActions.setPricingPopupFlag,
};

const connectedUpgradeV1 = connect(mapStateToPropsV1, mapDispatchToPropsV1)(UpgradeStripeV1);

export interface ICheckoutFormV2 {
  chosenPlans: IChosenPlan[];
  successCallback: Function;
  setSuccess: Function;
  checkoutV2?: boolean;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  subTotal?: number;
}

interface IError {
  cardHolderName: string;
  cardNumberError: string;
  cardCvcError: string;
  cardExpiryError: string;
}

const CheckoutFormV2 = ({
  chosenPlans,
  successCallback,
  setSuccess,
  checkoutV2 = false,
  alertSuccess,
  alertError,
  subTotal,
}: ICheckoutFormV2) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethodID, setPaymentMethodID] = useState('');
  const [processing, setProcessing] = useState(false);
  const [cardErrorMsg, setCardErrorMsg] = useState('');
  const [cardValues, setCardValues] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    region: '',
  });
  const [cardHolderName, setCardHolderName] = useState<string>('');
  const [inputError, setError] = useState<IError>({
    cardHolderName: '',
    cardNumberError: '',
    cardCvcError: '',
    cardExpiryError: '',
  });

  const { themeName } = useContext(ThemeContext);
  const { currentLiveScanSubscription, currentTyposquatSubscription, getSelectedPlan } =
    useContext<ICheckPhishSubscription>(CheckphishSubscriptionContext);

  const user = useSelector((appState: AppState) => appState.dashboardReducer.user);

  useEffect(() => {
    let name = '';
    if (_.has(user, 'first_name')) {
      name = user.first_name;
    }
    if (_.has(user, 'last_name')) {
      name += ' ' + user.last_name;
    }
    !_.isEmpty(name) && setCardHolderName(name);
  }, [user]);

  const elementOptions = useMemo(() => {
    const isLightTheme: boolean = themeName === ThemeModes.LIGHT;
    return {
      classes: {
        base: 'form-control',
        invalid: 'form-control-invalid',
        complete: 'form-control-complete',
      },
      style: {
        base: {
          color: isLightTheme ? '#555e68' : '#ffffff',
          fontSize: '16px',
          '::placeholder': {
            color: '#9fa6ad',
            fontSize: '16px',
            paddingTop: '10px',
          },
          ':focus': {},
        },
        invalid: {
          color: '#df1b41',
        },
        complete: {},
      },
    };
  }, [themeName]);

  const onCardHolderNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    _.isEmpty(value)
      ? setError(error => ({ ...error, cardHolderName: 'Card holder name cannot be empty' }))
      : setError(error => ({ ...error, cardHolderName: '' }));
    setCardHolderName(value);
  };

  const onCardNumberChange = (data: any) => {
    if (data.error) {
      return setError((error: IError) => ({
        ...error,
        cardNumberError: data.error?.message || '',
      }));
    }
    setError((error: IError) => ({ ...error, cardNumberError: '' }));
  };

  const onCVCChange = (data: any) => {
    if (data.error) {
      return setError((error: IError) => ({
        ...error,
        cardCvcError: data.error?.message || '',
      }));
    }
    setError((error: IError) => ({ ...error, cardCvcError: '' }));
  };

  const onExpiryChange = (data: any) => {
    if (data.error) {
      return setError((error: IError) => ({
        ...error,
        cardExpiryError: data.error?.message || '',
      }));
    }
    setError((error: IError) => ({ ...error, cardExpiryError: '' }));
  };

  const handleCardInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setCardValues((inputs: any) => {
      return { ...inputs, [event.target.name]: event.target.value };
    });
  };

  const selectCountry = (val: string) => {
    setCardValues((inputs: any) => {
      return { ...inputs, country: val };
    });
  };

  const selectRegion = (val: string) => {
    setCardValues((inputs: any) => {
      return { ...inputs, region: val };
    });
  };

  const setPlansForSubscription = (chosenPlans: IChosenPlan[]) => {
    const selectedLiveScanPlan = getSelectedPlan(EPlanProduct.LIVE_SCAN);
    const selectedTyposquatPlan = getSelectedPlan(EPlanProduct.TYPOSQUATTING);
    const subscription = chosenPlans.map(chosenPlan => {
      return {
        priceId: chosenPlan.id,
        quantity: chosenPlan.quantity,
      };
    });
    if (_.isEmpty(selectedLiveScanPlan) && !_.isEmpty(currentLiveScanSubscription)) {
      subscription.push({
        priceId: currentLiveScanSubscription?.id,
        quantity: currentLiveScanSubscription?.quantity,
      });
    }
    if (_.isEmpty(selectedTyposquatPlan) && !_.isEmpty(currentTyposquatSubscription)) {
      subscription.push({
        priceId: currentTyposquatSubscription?.id,
        quantity: currentTyposquatSubscription?.quantity,
      });
    }
    return subscription;
  };

  const handleSubmitV2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setCardErrorMsg('');
    if (_.isEmpty(cardHolderName)) {
      return setError((error: IError) => ({
        ...error,
        cardHolderName: 'Please enter card holder name',
      }));
    } else {
      setError((error: IError) => ({ ...error, cardHolderName: '' }));
    }
    if (
      !_.isEmpty(inputError.cardHolderName) ||
      !_.isEmpty(inputError.cardNumberError) ||
      !_.isEmpty(inputError.cardCvcError) ||
      !_.isEmpty(inputError.cardExpiryError)
    )
      return;
    //return if any existing error is there.
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }
    setProcessing(true);
    //custom
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'payClicked',
      category: 'Page',
      action: 'clicked',
      label: 'payClicked',
    });
    if (chosenPlans.length === 0) {
      return;
    }
    try {
      const { vendorCustomerId } = await stripeService.createCustomer(
        { name: cardHolderName },
        'v2',
      );

      if (!vendorCustomerId) {
        setProcessing(false);
        return alertError('Something went wrong, Please try again');
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardHolderName,
        },
      });

      if (error) {
        setProcessing(false);
        if (error.message) {
          return alertError(error.message);
        }
        const cardEl = elements.getElement(CardNumberElement);
        if (!cardEl) {
          return;
        }
        cardEl.focus();
        return;
      } else {
        if (!paymentMethod || !paymentMethod.id) {
          setProcessing(false);
          return alertError('Something went wrong, Please try again');
        }
        stripeService
          .createSubscription(
            {
              items: setPlansForSubscription(chosenPlans),
              vendorPaymentMethodId: paymentMethod.id,
            },
            'v2',
          )
          .then(data => {
            successCallback();
            alertSuccess('Plan Purchased Successfully');
            setPaymentMethodID(paymentMethod.id);
            setSuccess(true);
          })
          .catch(e => {
            console.error('Error creating customer:', e);
            setProcessing(false);
            alertError('Error in Payment!!!, Please Try Again');
          });
      }
    } catch (err) {
      console.error(err);
      setProcessing(false);
      alertError('Something went wrong!!, please try again');
    }
  };
  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setCardErrorMsg('');

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    setProcessing(true);

    if (!cardValues.name || cardValues.name.length < 2) {
      setProcessing(false);
      setCardErrorMsg('Please enter a valid name');
      return;
    } else if (!cardValues.city || cardValues.city.length < 2) {
      setProcessing(false);
      setCardErrorMsg('Please enter a valid city name');
      return;
    } else if (!cardValues.region || cardValues.region.length < 2) {
      setProcessing(false);
      setCardErrorMsg('State/Province is a required field');
      return;
    } else if (!cardValues.country || cardValues.country.length < 2) {
      setProcessing(false);
      setCardErrorMsg('Country is a required field');
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'payClicked',
      category: 'Page',
      action: 'clicked',
      label: 'payClicked',
    });

    if (chosenPlans.length === 0) {
      return;
    }

    const { vendorCustomerId } = await stripeService.createCustomer({
      name: cardValues.name,
      city: cardValues.city,
      country: cardValues.country,
      line1: cardValues.address1,
      line2: cardValues.address2,
      state: cardValues.region,
    });
    if (!vendorCustomerId) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardValues.name,
        address: {
          city: cardValues.city,
          country: cardValues.country,
          line1: cardValues.address1,
          line2: cardValues.address2,
          state: cardValues.region,
        },
      },
    });

    if (error) {
      setProcessing(false);
      if (error.message) {
        setCardErrorMsg(error.message);
      }
      const cardEl = elements.getElement('card');
      if (!cardEl) {
        return;
      }
      cardEl.focus();
      return;
    } else {
      if (!paymentMethod || !paymentMethod.id) {
        return;
      }

      stripeService
        .createSubscription(
          {
            items: chosenPlans.map(chosenPlan => {
              return {
                priceId: chosenPlan.id,
                quantity: chosenPlan.quantity,
              };
            }),
            vendorPaymentMethodId: paymentMethod.id,
          },
          'v2',
        )
        .then(data => {
          successCallback();
        })
        .catch(e => {
          console.error('Error creating customer:', e);
        });

      setPaymentMethodID(paymentMethod.id);
      setSuccess(true);
    }
  };
  const priorityCountries = ['US'];

  return (
    <form onSubmit={checkoutV2 ? handleSubmitV2 : handleSubmit} autoComplete='off'>
      {checkoutV2 ? (
        <div className='stripe-payment-form'>
          {cardErrorMsg && <ErrorMessage>{cardErrorMsg}</ErrorMessage>}
          <div className='form-group'>
            <label htmlFor='card-holder'>
              Name on Card
              <input
                id='card-holder'
                type='text'
                className={'form-control'}
                name='name'
                value={cardHolderName}
                onChange={onCardHolderNameChangeHandler}
              />
              {!_.isEmpty(inputError.cardHolderName) && (
                <span className='error'>{inputError.cardHolderName}</span>
              )}
            </label>
          </div>
          <div className='form-group'>
            <label>
              Card Number
              <CardNumberElement options={elementOptions} onChange={onCardNumberChange} />
              {!_.isEmpty(inputError.cardNumberError) && (
                <span className='error'>{inputError.cardNumberError}</span>
              )}
            </label>
          </div>
          <div className='form-group'>
            <label>
              Expiry
              <CardExpiryElement options={elementOptions} onChange={onExpiryChange} />
              {!_.isEmpty(inputError.cardExpiryError) && (
                <span className='error'>{inputError.cardExpiryError}</span>
              )}
            </label>
            <label>
              CVC
              <CardCvcElement options={elementOptions} onChange={onCVCChange} />
              {!_.isEmpty(inputError.cardCvcError) && (
                <span className='error'>{inputError.cardCvcError}</span>
              )}
            </label>
          </div>
          <SubmitButton processing={processing} error={cardErrorMsg} disabled={!stripe}>
            Pay $ {subTotal || showPrice(chosenPlans, false)}
          </SubmitButton>
        </div>
      ) : (
        <>
          <div className='FormGroup'>
            <input
              type='text'
              className={'form-control'}
              placeholder='Name On Card'
              name='name'
              required
              aria-required='true'
              onChange={handleCardInputChange}
            />
          </div>
          <div className='FormGroup'>
            <input
              type='text'
              className={'form-control'}
              placeholder='Street Address 1'
              name='address1'
              onChange={handleCardInputChange}
            />
          </div>
          <div className='FormGroup'>
            <input
              type='text'
              className={'form-control'}
              placeholder='Street Address 2'
              name='address2'
              onChange={handleCardInputChange}
            />
          </div>
          <div className='FormGroup'>
            <input
              type='text'
              className={'form-control'}
              placeholder='City'
              name='city'
              aria-required='true'
              required
              onChange={handleCardInputChange}
            />
          </div>
          <div className='FormGroup mod-select'>
            <CountryDropdown
              priorityOptions={priorityCountries}
              value={cardValues.country}
              valueType='short'
              onChange={val => selectCountry(val)}
            />
          </div>
          <div className='FormGroup mod-select'>
            <RegionDropdown
              country={cardValues.country}
              value={cardValues.region}
              onChange={val => selectRegion(val)}
              disableWhenEmpty={true}
              valueType='short'
              countryValueType='short'
              defaultOptionLabel='Select State/Province'
            />
          </div>
          <div className='FormGroup cc'>
            <CardField
              onChange={(e: any) => {
                if (e.complete) {
                  setCardErrorMsg('');
                }
              }}
            />
          </div>

          {cardErrorMsg && <ErrorMessage>{cardErrorMsg}</ErrorMessage>}
          <SubmitButton processing={processing} error={cardErrorMsg} disabled={!stripe}>
            Pay {showPrice(chosenPlans, false)}
          </SubmitButton>
        </>
      )}
    </form>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};
const ConnectedCheckoutFormV2 = connect(undefined, mapDispatchToProps)(CheckoutFormV2);

export { ConnectedCheckoutFormV2 as CheckoutFormV2 };

export const generateAllPlans = (
  liveScanPlans: any,
  liveScanSubscriptionPlan: any,
  liveScanBundles: any[],
): {
  monthlyPlan: IPlanV2;
  yearlyPlan: IPlanV2;
  chosenLiveScanPlan: IChosenPlan;
} => {
  let yearlyPlan: any;
  let monthlyPlan: any;
  const liveScanPlanIdToDiscountsMapper = {};
  liveScanPlans.forEach((liveScanPlan: any) => {
    const { discounts } = liveScanPlan;
    liveScanPlanIdToDiscountsMapper[liveScanPlan.id] = discounts;
    const plan: IPlanV2 = {
      id: liveScanPlan.id,
      price: liveScanPlan.price,
      limit: liveScanPlan.limit,
      term: liveScanPlan.term,
      discounts,
    };
    if (liveScanPlan.term === 'monthly') {
      monthlyPlan = plan;
    } else {
      yearlyPlan = plan;
    }
  });

  if (liveScanSubscriptionPlan) {
    return {
      monthlyPlan,
      yearlyPlan,
      chosenLiveScanPlan: {
        id: liveScanSubscriptionPlan.id,
        price: liveScanSubscriptionPlan.price,
        limit: liveScanSubscriptionPlan.limit,
        term: liveScanSubscriptionPlan.term,
        quantity: liveScanSubscriptionPlan.quantity,
        discounts: liveScanPlanIdToDiscountsMapper[liveScanSubscriptionPlan.id],
      },
    };
  }

  return {
    monthlyPlan,
    yearlyPlan,
    chosenLiveScanPlan: {
      ...monthlyPlan,
      quantity: liveScanBundles[0],
    },
  };
};

const UpgradeStripeV2: FunctionComponent<IUpgradeV2Props> = ({
  className = '',
  startAt = 2,
  onHide,
  successCallback,
  liveScanSubscriptionPlan,
  disablePricing,
}) => {
  const [upgradeSteps, setUpgradeSteps] = useState(startAt);
  const [success, setSuccess] = useState(false);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [_cookies, _setCookie, removeCookie] = useCookies(['checkphishChosenPlan']);
  const [chosenLiveScanPlan, setChosenLiveScanPlan] = useState<IChosenPlan | undefined>(undefined);
  const [monthlyPlan, setMonthlyPlan] = useState<IPlanV2 | undefined>(undefined);
  const [yearlyPlan, setYearlyPlan] = useState<IPlanV2 | undefined>(undefined);

  const [liveScanBundles, setLiveScanBundles] = useState<number[]>([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([stripeService.getToken(), stripeService.getPlans('v2')])
      .then(data => {
        const [{ config }, { plans: plansFromStripe, productBundles }] = data;
        // TO DO: Currently only looking for live scans
        const liveScanPlans = plansFromStripe.filter(
          (planFromStripe: any) => planFromStripe.product === EPlanProduct.LIVE_SCAN,
        );
        const liveScanBundles = productBundles[EPlanProduct.LIVE_SCAN];
        setStripePromise(loadStripe(config.pubKey, { apiVersion: '2022-11-15' }));
        const { monthlyPlan, chosenLiveScanPlan, yearlyPlan } = generateAllPlans(
          liveScanPlans,
          liveScanSubscriptionPlan,
          liveScanBundles,
        );
        setChosenLiveScanPlan(chosenLiveScanPlan);
        setMonthlyPlan(monthlyPlan);
        setYearlyPlan(yearlyPlan);
        setLiveScanBundles(liveScanBundles);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  }, []);

  const generateModalBody = () => {
    let modalBody = null;
    if (chosenLiveScanPlan && monthlyPlan && yearlyPlan) {
      switch (upgradeSteps) {
        case 1:
          modalBody = (
            <div className={'products-page-plans-section py-1'}>
              <h4 className={'pb-4 text-center'}>{success ? 'Payment successful' : 'Checkout'}</h4>
              <div className='container stripe-description'>
                <div className='row text-muted border-bottom mb-2'>
                  <div className='col-6'>
                    <h6>DESCRIPTION</h6>
                  </div>
                  <div className='col-4  text-right'>
                    <h6>PRICE</h6>
                  </div>
                  <div className='col-2 text-right'>
                    <h6>TOTAL</h6>
                  </div>
                </div>

                <div className='row border-bottom mb-2'>
                  <div className='col-6 text-left  text-muted'>
                    <span className='font-weight-bold'>Professional API</span>
                    <br />- {chosenLiveScanPlan.quantity * chosenLiveScanPlan.limit} scans per day
                    <br />- {chosenLiveScanPlan.quantity * chosenLiveScanPlan.limit * 30} scans per
                    month
                  </div>
                  <div className='col-4 text-right  text-muted'>
                    {showPrice([chosenLiveScanPlan])}
                  </div>
                  <div className='col-2 text-right  text-muted'>
                    {showPrice([chosenLiveScanPlan], false)}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    {!success && (
                      <a
                        href=''
                        onClick={e => {
                          e.preventDefault();
                          setUpgradeSteps(2);
                        }}
                      >
                        Change Subscription
                      </a>
                    )}
                  </div>
                  <div className='col-4  text-right  text-muted'>
                    {success ? 'Total Paid' : 'Amount Due'}
                  </div>
                  <div className='col-2  text-right  text-muted'>
                    {showPrice([chosenLiveScanPlan], false)}
                  </div>
                </div>
              </div>
              <div className={'products-page-plans-section py-1 upgrade-stripe'}>
                <Elements stripe={stripePromise}>
                  <ConnectedCheckoutFormV2
                    successCallback={successCallback}
                    chosenPlans={[chosenLiveScanPlan]}
                    setSuccess={setSuccess}
                  />
                </Elements>
              </div>
              <div className='stripe-logo'>
                <img width='140' src={StripeImg} alt={'Secure payment with Stripe'} />
              </div>
            </div>
          );
          break;
        case 2:
          modalBody = (
            <div className='products-page pro-plan-modal'>
              <PlanCardsV2
                onClickPro={() => setUpgradeSteps(1)}
                onClickEnt={() => setUpgradeSteps(3)}
                liveScanBundles={liveScanBundles}
                liveScanSubscriptionPlan={liveScanSubscriptionPlan}
                chosenPlan={chosenLiveScanPlan}
                yearlyPlan={yearlyPlan}
                monthlyPlan={monthlyPlan}
                onChange={val => {
                  setChosenLiveScanPlan(val);
                  disablePricing(false);
                  removeCookie('checkphishChosenPlan', { path: '/', domain: 'checkphish.ai' });
                }}
              />
            </div>
          );
          break;
        case 3:
          modalBody = (
            <div className={'products-page-plans-section py-1'}>
              <h4 className={'pb-4 text-center'}>Upgrade to Enterprise</h4>
              <ContactUsPage />
            </div>
          );
          break;
        default:
          modalBody = null;
          break;
      }
    }
    return modalBody;
  };

  const modal = (
    <Modal
      className={'upgrade-modal'}
      show={upgradeSteps > 0}
      size='lg'
      onHide={() => onHide()}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='download-via-email'>Upgrade API</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'modal-body'}>
        <LoadingWrapper isLoading={loading}>{generateModalBody()}</LoadingWrapper>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return <div className={'upgrade-button-component ' + className}>{modal}</div>;
};

const mapDispatchToPropsV2 = {
  disablePricing: appActions.setPricingPopupFlag,
};

const connectedUpgradeV2 = connect(undefined, mapDispatchToPropsV2)(UpgradeStripeV2);

export { connectedUpgradeV1 as UpgradeStripeV1, connectedUpgradeV2 as UpgradeStripeV2 };

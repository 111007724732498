import moment from 'moment';
import fetchApi from '../../../../services/api.service';
import { handleResponse, setPostRequestOptions } from '../../../../services/serviceWorker';
import {
  DarkWebTableApiParams,
  DarkWebTableApiV2Params,
  UgcTableApiParams,
  convertToFetchDarkWebApiParams,
} from './ag-utils';
import { DISPLAYED_NULL, getLocalTimeFromUtcTime } from '../../../../constants';
import { ugcType } from '../../../Ugc/Types/ugc.types';
import { capitalize } from 'lodash';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { DEFAULT_SEARCH_FIELDS } from '../../../DarkWeb/DarkWebConstants';
import { DEFAULT_VALUE_NO_PASSWORD } from '../../../DarkWeb/Components/Common/TableColDefs/DarkWebColDefs';

export interface TableApiParams {
  must: object;
  mustNot: object;
  query: object;
  sortBy: object;
  primaryDomain?: string;
}

export const fetchWebTableData = async (apiParams: TableApiParams, isGroupViewEnabled = true) => {
  const { must, mustNot, query, sortBy } = apiParams;
  const requestGroupOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      must,
      mustNot,
      sortBy,
      query: {
        ...query,
        pageNumber: query['pageNumber'],
        pageSize: query['pageSize'],
        isGroupViewEnabled,
      },
    }),
  };
  const rawGroupResponse = await fetchApi('/api/v1/brand/url/search', requestGroupOptions).then(
    handleResponse,
  );
  return rawGroupResponse;
};

export const fetchRelatedData = async (primaryDomain: string, apiParams: TableApiParams) => {
  const { must, mustNot, query, sortBy } = apiParams;
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      must,
      mustNot,
      sortBy,
      primaryDomain,
      query: {
        ...query,
        pageNumber: 0,
        pageSize: 100,
      },
    }),
  };
  const rawResponse = await fetchApi('/api/v1/brand/url/related', requestOptions).then(
    handleResponse,
  );
  return rawResponse;
};

export const fetchAcquisitionData = async (tableId: string) => {
  const requestGroupOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: {
        startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        timezone: moment.tz?.guess() || 'America/Los_Angeles',
        type: tableId,
      },
    }),
  };
  const rawGroupResponse = await fetchApi(
    '/api/v1/brand/domain/acquisition/search',
    requestGroupOptions,
  ).then(handleResponse);
  return rawGroupResponse;
};

/**
 * UGC Module including Social Media, Marketplace, and App Store
 * @param apiParams
 * @param moduleType
 * @returns
 */
export const fetchUGCData = async (apiParams: UgcTableApiParams, moduleType: string) => {
  const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(apiParams));
  const rawResponse = await fetch(
    `/platform-api/v1/content/${moduleType}/finding/search`,
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const massageUGCData = (findings: any[], moduleType: string) => {
  return findings.map(finding => {
    return {
      ...finding,
      platform_label: finding.platformLabel,
      origin_label: finding.originLabel,
      category_labels: finding.categoryLabels?.join(', ') || '',
      search_term_labels: finding.searchTermLabels?.join(', ') || DISPLAYED_NULL,
      // app-store use search_term
      search_term: finding.searchTerm || DISPLAYED_NULL,
      upload_count: finding.uploadCount,
      external_links: finding.externalLinks,
      displayFirstSeenTime: getLocalTimeFromUtcTime(
        moduleType !== ugcType.App_Store ? finding.createdTs : finding.firstSeenTs,
      ),
      displayTakedownTime: getLocalTimeFromUtcTime(finding.takedownTs),
      is_logo_detection:
        finding.isLogoDetection === null ? '--' : capitalize(`${finding.isLogoDetection}`),
      logos: finding.imageUrls,
      logosLength: finding.imageUrls?.length || 0,
      'metadata->>title': finding?.metadata?.title || DISPLAYED_NULL,
      'metadata.activeSince': finding?.metadata?.activeSince,
      brand_logo_detected: finding?.brandLogoDetected ? 'True' : 'False',
      siteTitle: finding?.siteTitle,
      countryCode: finding?.countryCode,
      srcUrl: finding?.srcUrl,
      app_last_updated: finding?.app_last_updated,
      scanSource: capitalize(finding?.scanSource),
      domain_label: finding.domainLabel,
      displayLastScannedTime: getLocalTimeFromUtcTime(finding.createdTs),
      url_sha256: finding.sha256,
      tags_label: finding.tags
        ? finding?.tags.map((tag: any) => tag.label).join(',')
        : DISPLAYED_NULL,
    };
  });
};

// dark web
export const fetchDarkWebData = async (
  apiParams: DarkWebTableApiParams,
  ignoreOffset: boolean = false,
) => {
  if (ignoreOffset) {
    apiParams.offset = 0;
  }
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      must: {
        ...apiParams,
        ...(!apiParams.includeFields
          ? {
              includeFields: DEFAULT_SEARCH_FIELDS,
            }
          : {}),
      },
    }),
  };
  const rawResponse = await fetchApi(
    `/platform-api/v1/content/dark-web/findings`,
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const massagedDarkWebData = (findings: any[]) => {
  return findings?.map(finding => {
    return {
      ...finding,
      password_type:
        finding.password_type !== ''
          ? capitalize(finding.password_type)
          : DEFAULT_VALUE_NO_PASSWORD,
    };
  });
};
export const getSearchTermFindings = (
  darkwebStatus: EDarkWebFindingStatus,
  extraFilters: DarkWebTableApiV2Params,
  searchTerms: string[],
  searchTermsCount: number,
) => {
  const apiParams = convertToFetchDarkWebApiParams(
    {
      sortModel: [],
      startRow: undefined,
      endRow: undefined,
      rowGroupCols: [],
      valueCols: [],
      pivotCols: [],
      groupKeys: [],
      pivotMode: false,
      filterModel: null,
    },
    searchTermsCount,
    darkwebStatus,
    {
      ...extraFilters,
      searchTerms,
    },
    [],
  );
  apiParams.includeFields = ['content_sha_256'];
  return fetchDarkWebData(apiParams);
};

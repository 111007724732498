import React from 'react';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import { TableData } from '../../../../Common/Table/table.data';
import { getCSVFile } from '../../../../Assets_v2/util';
import ActorChattersPostColRender from './ActorChattersPostColRender';
import { TableContextProvider } from '../../../../Common/Table/table.context';
import { DARK_WEB_ACTOR_CHATTERS_TABLE_NAME } from '../../../DarkWebConstants';
import { getDisplayTimeFromTimeStamp } from '../../../../../constants';
import { connect } from 'react-redux';
import { AppState } from '../../../../../helpers';
const COLUMNS: any[] = [
  {
    id: 'post',
    accessor: 'post',
    header: 'Post',
    fieldForExport: 'titleAndContent',
    render: (data: any) => {
      return <ActorChattersPostColRender title={data.title} content={data.content} />;
    },
    filterDisabled: true,
    sortDisabled: true,
  },
  {
    id: 'postedDate',
    accessor: 'platform_published_ts',
    header: 'Date Posted',
    filterDisabled: true,
    render: (data: IActorChattersData) => {
      return getDisplayTimeFromTimeStamp(data.platform_published_ts);
    },
  },
  {
    id: 'url',
    accessor: 'url',
    header: 'URL',
    isLongText: true,
  },
];

const exportCSVTitle = `Bolster Actor Chatters ${moment().format('YYYYMMDDhhmmss')}`;

interface IActorChattersData {
  title: string;
  content: string;
  titleAndContent: string;
  platform_published_ts: string;
  url: string;
}
interface IActorChattersProps {
  actorChatters: IActorChattersData[];
  isLoading: boolean;
  user?: any;
}

function ActorChatters({ actorChatters, isLoading, user }: IActorChattersProps) {
  const exportActorChatters = (data: any, columns: any[]) => {
    getCSVFile(data, columns, exportCSVTitle);
  };

  return (
    <Card className={'actor-chatters'}>
      <TableContextProvider
        columns={COLUMNS}
        dashboardName={DARK_WEB_ACTOR_CHATTERS_TABLE_NAME}
        tableId={'actor chatters'}
      >
        <TableData
          id={'actor chatters'}
          tableIndex={'darkweb_brand_info'}
          title={''}
          titleComp={<div>Actor Chatters</div>}
          tableClassName='tool-bar-container-padding'
          data={actorChatters}
          columns={COLUMNS}
          columnsNotEditable
          exportOptions={[{ label: 'CSV', value: 'csv' }]}
          exportFn={exportActorChatters}
          indexBy={'url'}
          disableDatePicker
          showLoader={isLoading}
          user={user}
        />
      </TableContextProvider>
    </Card>
  );
}
const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};

export default connect(mapStateToProps, null)(ActorChatters);

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { LabelAndValue } from '../../../../Common/LabelAndValue';
import {
  appConstants,
  featureIsAvailable,
  getLocalTimeFromUtcTime,
} from '../../../../../constants';
import { IFindingInsightSectionProps, ugcType } from '../../../Types/ugc.types';
import Tags from '../../../../Common/Tags/Tags';
import { AppState } from '../../../../../helpers';
import { connect } from 'react-redux';

const ScanResultSection = ({ data, type, user }: IFindingInsightSectionProps) => {
  return (
    <Card bg='light' className='card-between'>
      <Tags rowData={data} type={type} />
      <Card.Header className='self-card-header'>Scan Results</Card.Header>
      <Card.Body className='self-card-body'>
        <Row>
          <Col sm={6}>
            <LabelAndValue
              label={'Source URL'}
              value={data.url}
              direction={'column'}
              longTextLineNumberLimit={1}
              copyButton
            />
            <LabelAndValue label={'Source'} value={data.source} direction={'column'} />
            <LabelAndValue
              label={'Category'}
              value={data.categoryLabels}
              direction={'column'}
              longTextLineNumberLimit={1}
            />
          </Col>
          <Col sm={6}>
            <LabelAndValue
              label={'Matched Search Terms'}
              value={data.searchTermLabels}
              direction={'column'}
            />
            <LabelAndValue
              label='First Seen'
              value={getLocalTimeFromUtcTime(data.createdTs, 'MMMM Do YYYY, h:mm:ss a')}
              direction={'column'}
              longTextLineNumberLimit={1}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};

export default connect(mapStateToProps, null)(ScanResultSection);

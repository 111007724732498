import React, { Fragment } from 'react';
import _ from 'lodash';
import {
  THEME_COLOR_ORANGE,
  THEME_COLOR_BLUE_3,
  appConstants,
  DashBoardDto,
  IAlertDispatchProps,
  ThemeModes,
  isAdminUser,
  IBrandInfo,
} from '../../constants';
import { AppState } from '../../helpers';
import { userActions, alertActions } from '../../actions';
import { connect } from 'react-redux';
import DashboardService from '../../services/dashboard.service';
import './timeline.scss';
import moment from 'moment';
import { Image } from '../Common/Image';
import SiteBackUpIcon from '../../assets/icons/SiteBackUp.svg';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import PillLabel from '../Common/PillLabel';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { Accordion, Button } from 'react-bootstrap';
import { ChevronDownIcon } from '../../assets/SVGIcons';
import { LabelAndValue } from '../Common/LabelAndValue';
import { TimelineEvent } from '../../types/timeline-event.interface';
import { TimeLineEventType, TimelineTitles } from '../Assets_v2/PropertyTypes';
import { EventHistoryEntity } from '../../types/event-history-entity.interface';
import {
  CustomerComment,
  CustomerSocResponse,
  SocRequestedDocument,
  reportedEntites,
} from '../../types/url-event.types';
import { TimelineEventTypes } from '../../types/timeline-response.interface';
import UserCommentModal from '../InsightsContainer/Components/UserCommentModal';
import { Nullable } from '../../types/common';
import { RouteComponentProps } from 'react-router-dom';
import TimelineCommentAttachments from './TimelineCommentAttachments';
import { DispositionStatus } from '../Common/DispositionStatus';
import { getScreenshotUrl } from '../../helpers/screenshotUrl';

import BigArrowLeft from '../../assets/icons/BigArrowLeft.svg';
import BigArrowLeftWhite from '../../assets/icons/BigArrowLeftWhite.svg';
import ThemeContext from '../../context/ThemeContext';
import WebSocActionColRender, { ESocActionOrigin } from '../GlobalTakedown/WebSocActionColRender';
import { getUgcTimeLineDetails } from '../Ugc/Components/Findings/Insight/UgcInsights-requests';
import { mapLatestActions } from '../GlobalTakedown/GlobalTakeDownUtils';

const ICON_SIZE = 12;
const DATE_TIME_FORMAT = 'DD-MMM-YYYY, h:mm a';

interface ILegend {
  color: string;
  label: string;
}

interface ITimelineProps extends Partial<RouteComponentProps<any>> {
  user: DashBoardDto;
  urlSHA256: string;
  type: string;
  timelineUpdated: string;
  shouldRefreshTimeline: boolean;
  brandInfo: IBrandInfo | undefined;
}

interface ITimelineState {
  initialTakedownRequestTs: string;
  siteTakenDownTs: string;
  data: TimelineEvent[] | EventHistoryEntity<TimelineEventTypes>[];
  isLoading: boolean;
  requestEventHistoryId?: Nullable<number>;
  selectedEventKey: string | null;
  rowExpanded: {};
  expandedRows: any;
}

export interface SocCustomerMessageEventData {
  message: string;
  timestamp: string;
  attachments: string[];
  submittedByUserId: number;
}

type Props = ITimelineProps & ILinkDispatchProps;

class Timeline extends React.Component<Props, ITimelineState> {
  private readonly dashboardService: DashboardService;
  private readonly socTakedownService: SocTakedownService;
  static contextType = ThemeContext;

  constructor(props: Props) {
    super(props);

    this.dashboardService = new DashboardService();
    this.socTakedownService = new SocTakedownService();
    this.state = {
      initialTakedownRequestTs: '',
      siteTakenDownTs: '',
      data: [],
      isLoading: false,
      selectedEventKey: '0',
      rowExpanded: {},
      expandedRows: [],
    };
    window.document.title = 'Timeline | Dashboard | Bolster Platform';
  }

  componentDidMount() {
    void this.fetchTimelineDetails();
  }

  componentDidUpdate(prevProps: any, prevState: ITimelineState) {
    if (prevProps.timelineUpdated !== this.props.timelineUpdated) {
      void this.fetchTimelineDetails();
    }

    if (
      prevProps.shouldRefreshTimeline !== this.props.shouldRefreshTimeline &&
      this.props.shouldRefreshTimeline
    ) {
      void this.fetchTimelineDetails();
    }
  }

  async fetchTimelineDetails() {
    const { urlSHA256, type, brandInfo } = this.props;
    this.setState({
      isLoading: true,
    });
    try {
      const res =
        type === appConstants.CONTENT_TYPE.SOCIAL
          ? await getUgcTimeLineDetails(urlSHA256)
          : await this.socTakedownService.getTimeline(urlSHA256);
      this.setState({
        initialTakedownRequestTs: res.initialTakedownRequestTs
          ? moment(res.initialTakedownRequestTs).format(DATE_TIME_FORMAT)
          : '',
      });
      this.setState({
        siteTakenDownTs: res.siteTakenDownTs
          ? moment(res.siteTakenDownTs).format(DATE_TIME_FORMAT)
          : '',
      });

      //Massaged the soc takedwon event to show the action taken by the user
      const mapHash: { [key: string]: { count: number } } = {};
      const massagedTimeline = res.events.reverse().map((event: any) => {
        if (event.data && event.eventType === TimeLineEventType.SOC_TAKEDOWN) {
          reportedEntites.forEach((entity: { key: string; value: string }) => {
            if (event.data[entity.key]) {
              if (!mapHash[entity.value]) {
                mapHash[entity.value] = { count: 1 };
                event.data.eventLabel = mapLatestActions(
                  entity.value,
                  1,
                  event.data.isReply,
                  brandInfo,
                );
              } else {
                event.data.eventLabel = mapLatestActions(
                  entity.value,
                  2,
                  event.data.isReply,
                  brandInfo,
                );
              }
            }
          });
        }
        return event;
      });

      this.setState({
        data: massagedTimeline.reverse(),
      });
    } catch (error) {
      this.props.alertError('Error loading timeline, please contact support@bolster.ai.');
      console.log(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  renderTimelineItem = (item: TimelineEvent) => {
    const { time, status, imagePath, disposition, createdTS } = item;
    const renderTimeAndStatus = (className: string) => {
      return (
        <div className={'time-and-status ' + className}>
          {status === 'live' && (
            <div className={'status d-flex align-items-center'}>
              <img src={SiteBackUpIcon} alt={'Site Back Up'} />
              <span>Site put back up</span>
            </div>
          )}
        </div>
      );
    };

    let dotClassName;
    let backgroundColor;
    switch (disposition.toLocaleLowerCase()) {
      case 'scam':
      case 'phish':
        dotClassName = 'dot';
        backgroundColor = status === 'active' ? THEME_COLOR_ORANGE : THEME_COLOR_BLUE_3;
        break;
      default:
        dotClassName = 'line-dot';
        backgroundColor = 'transparent';
    }
    return (
      <div className={'time-line-item'} key={time.format()}>
        {renderTimeAndStatus('web')}
        <div>{moment(createdTS).format('MMMM Do YYYY, h:mm a')}</div>
        <div className={'time-indicator d-flex flex-column align-items-center'}>
          <span className={'line line-top'} />
          <span className={'line line-bottom'} />
        </div>
        <div className={'image-container'}>
          {renderTimeAndStatus('mobile')}
          <Image
            imagePath={getScreenshotUrl(imagePath)}
            alt='scan result screenshot'
            className={'timeline-image'}
          />
        </div>
      </div>
    );
  };
  toggleExpander = (index: any) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowExpanded = currentExpandedRows.includes(index);

    const toggledUrl = {};

    isRowExpanded ? (toggledUrl[index] = false) : (toggledUrl[index] = true);

    // If the row is expanded, we will collapseit.
    //  Hence remove it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id: any) => id !== index)
      : currentExpandedRows.concat(index);

    this.setState({
      rowExpanded: toggledUrl,
      expandedRows: newExpandedRows,
      selectedEventKey: index,
    });
  };
  render() {
    const { data } = this.state;
    const { type, brandInfo } = this.props;
    const { selectedTheme } = this.context;
    const localState: any = localStorage.getItem('linkTimelineState');
    const linkState = JSON.parse(localState);
    const moduleType = type ?? linkState.type;
    const firstItem = data[0];
    let srcUrl = '',
      timestamp = '',
      urlSHA256 = '';
    if (!_.isEmpty(firstItem)) {
      srcUrl = 'srcUrl' in firstItem ? firstItem.srcUrl : '';
      timestamp = 'timestamp' in firstItem ? firstItem.timestamp : '';
      urlSHA256 = 'urlSHA256' in firstItem ? firstItem.urlSHA256 : '';
    }

    const constructTimeLineItem = (item: EventHistoryEntity<TimelineEventTypes>, key: number) => {
      const format1 = 'DD-MMM-YYYY h:mm a';
      const thisTime = moment(item.data.timestamp).format(format1);
      let title = 'Soc Takedown Requested';
      let hideScreenshot = false;
      let showCollapased = false;
      const groupedAutoTakedownTimestamps: string[] = [];
      let activityBy: string = 'Bolster Soc';
      const contentChanged: any[] = [];

      const dispositions: any[] = [];
      switch (item.eventType) {
        case TimeLineEventType.URL_DETECTED:
          title = TimelineTitles.URL_DETECTED;
          activityBy =
            (item.data &&
              'reportingEntityUserName' in item.data &&
              item.data?.reportingEntityUserName) ||
            'Bolster';
          break;
        case TimeLineEventType.URL_PHISH_DETECTED:
          title = `${TimelineTitles.URL_DETECTED}, ${TimelineTitles.PHISH_DETECTED}`;
          break;
        case TimeLineEventType.URL_SCAM_DETECTED:
          title = `${TimelineTitles.URL_DETECTED}, ${TimelineTitles.SCAM_DETECTED}`;
          break;
        case TimeLineEventType.MANUAL_TAKEDOWN:
          title = TimelineTitles.MANUAL_TAKEDOWN;
          hideScreenshot = true;
          activityBy =
            (item.data &&
              'reportingEntityUserName' in item.data &&
              item.data?.reportingEntityUserName) ||
            (item.data && 'reportingEntity' in item.data && item.data?.reportingEntity) ||
            'Unknown';
          break;
        case TimeLineEventType.AUTO_TAKEDOWN:
          title = TimelineTitles.AUTO_TAKEDOWN;
          hideScreenshot = true;
          if (
            item.data &&
            'groupedTimestamp' in item.data &&
            item.data?.groupedTimestamp &&
            item.data?.groupedTimestamp.length > 0
          ) {
            showCollapased = true;
            item.data.groupedTimestamp.map((item: string) =>
              groupedAutoTakedownTimestamps.push(item),
            );
          }
          break;
        case TimeLineEventType.DISPUTE:
          title = TimelineTitles.DISPUTE;
          hideScreenshot = true;
          activityBy =
            (item.data &&
              'reportingEntityUserName' in item.data &&
              item.data?.reportingEntityUserName) ||
            'User';
          break;
        case TimeLineEventType.HUNTING_UPDATE:
          title = TimelineTitles.HUNTING_UPDATE;
          break;
        case TimeLineEventType.CUSTOMER_COMMENT:
          if ((item.data as CustomerComment)?.attachments?.length > 0) {
            title = TimelineTitles.CUSTOMER_COMMENT_WITH_DOCUMENTS;
          } else {
            title = TimelineTitles.CUSTOMER_COMMENT;
          }
          activityBy =
            (item.data &&
              'reportingEntityUserName' in item.data &&
              item.data?.reportingEntityUserName) ||
            'User';
          hideScreenshot = true;
          break;
        case TimeLineEventType.CUSTOMER_SOC_RESPONSE:
          if ((item.data as CustomerSocResponse)?.attachments?.length > 0) {
            title = TimelineTitles.CUSTOMER_SOC_RESPONSE_WITH_DOCUMENTS;
          } else {
            title = TimelineTitles.CUSTOMER_SOC_RESPONSE;
          }
          activityBy =
            (item.data &&
              'reportingEntityUserName' in item.data &&
              item.data?.reportingEntityUserName) ||
            'User';
          hideScreenshot = true;
          break;
        case TimeLineEventType.CONTENT_CHANGE:
          title = TimelineTitles.CONTENT_CHANGE;
          if (item.data && 'siteTitleChanged' in item.data && item.data?.siteTitleChanged) {
            contentChanged.push(
              <PillLabel
                label={'Site Title changed'}
                customStyle={{
                  backgroundColor: '#ff5741',
                }}
              />,
            );
          }
          if (item.data && 'mxRecordsChanged' in item.data && item.data?.mxRecordsChanged) {
            contentChanged.push(
              <PillLabel
                label={'MX Records Changed'}
                customStyle={{
                  backgroundColor: '#16a4eb',
                }}
              />,
            );
          }
          break;
        case TimeLineEventType.RECENT_SCAN:
          title = TimelineTitles.RECENT_SCAN;
          break;
        case TimeLineEventType.DISPOSITION_CHANGE:
          title = TimelineTitles.DISPOSITION_CHANGE;
          if (item.data && 'previousDisposition' in item.data && item.data?.previousDisposition) {
            dispositions.push(item.data?.previousDisposition);
          }
          if (item.data && 'disposition' in item.data && item.data?.disposition) {
            dispositions.push(item.data?.disposition);
          }
          break;
        case TimeLineEventType.PHISH_DETECTED:
          title = TimelineTitles.PHISH_DETECTED;
          break;
        case TimeLineEventType.SCAM_DETECTED:
          title = TimelineTitles.SCAM_DETECTED;
          break;
        case TimeLineEventType.SOC_REQUESTED_DOCUMENT:
          title = TimelineTitles.ACTION_NEEDED;
          break;
        case TimeLineEventType.SOC_CUSTOMER_MESSAGE:
          title = TimelineTitles.SOC_CUSTOMER_MESSAGE;
          hideScreenshot = true;
          break;
        case TimeLineEventType.TAKEN_DOWN:
          title = TimelineTitles.TAKEN_DOWN;
          break;
        case TimeLineEventType.SOC_TAKEDOWN:
          title = item.data.eventLabel || TimeLineEventType.SOC_TAKEDOWN;
          hideScreenshot = true;
          break;
        case TimeLineEventType.SOC_ACTION:
          if (item.data && 'title' in item.data) {
            title = item.data.title as string;
          }
          break;
        default:
          title = item.eventType; // this should not happen, show eventType so we can catch it
          hideScreenshot = true;
      }

      const timelineBody = (item: any) => {
        if (item.eventType === TimeLineEventType.SOC_REQUESTED_DOCUMENT) {
          const requestData = item.data as SocRequestedDocument;
          return (
            <>
              <p>{requestData.message}</p>
              <Button
                variant={'link'}
                onClick={() => this.setState({ requestEventHistoryId: item.id })}
              >
                Upload File/Add Comment
              </Button>
            </>
          );
        } else if (item.eventType === TimeLineEventType.SOC_ACTION) {
          return (
            <div>
              <div className='d-flex'>
                <div>{item?.data?.comments}</div>
              </div>
              {item?.data?.imagePath && (
                <Image
                  imagePath={item.data.imagePath}
                  alt='scan result screenshot'
                  className={'timeline-image'}
                />
              )}
            </div>
          );
        } else {
          return (
            <>
              {item.eventType === TimeLineEventType.DISPUTE &&
                item?.data?.comments &&
                item?.data?.comments}
              {!hideScreenshot && item.data.imagePath && (
                <Image
                  imagePath={
                    item.eventType === TimeLineEventType.SOC_ACTION
                      ? item.data.imagePath
                      : getScreenshotUrl(item?.data?.imagePath)
                  }
                  alt='scan result screenshot'
                  className={'timeline-image'}
                />
              )}

              {(item.eventType === TimeLineEventType.CUSTOMER_COMMENT ||
                item.eventType === TimeLineEventType.CUSTOMER_SOC_RESPONSE ||
                item.eventType === TimeLineEventType.SOC_CUSTOMER_MESSAGE) && (
                <TimelineCommentAttachments
                  urlSha256={this.props.urlSHA256}
                  item={item}
                  key={key}
                />
              )}

              {item.eventType === TimeLineEventType.DISPUTE &&
                item?.data?.attachmentPaths &&
                item?.data?.attachmentPaths.length > 0 && (
                  <TimelineCommentAttachments
                    urlSha256={this.props.urlSHA256}
                    item={item}
                    key={key}
                    isDispute
                  />
                )}
              <div className='d-flex flex-row pill-container'>
                {item.data && 'sentAbuseEmail' in item.data && item.data.sentAbuseEmail && (
                  <PillLabel
                    label={'Hosting Provider Notified'}
                    customStyle={{
                      backgroundColor: '#899aad',
                    }}
                  />
                )}
                {item.data && 'reportedToApwg' in item.data && item.data.reportedToApwg && (
                  <PillLabel
                    label={'APWG Notified'}
                    customStyle={{
                      backgroundColor: '#green',
                    }}
                  />
                )}
                {item.data && 'reportedToFreenom' in item.data && item.data.reportedToFreenom && (
                  <PillLabel
                    label={'Freenom Notified'}
                    customStyle={{
                      backgroundColor: '#ebc616',
                    }}
                  />
                )}
                {item.data && 'reportedToGoDaddy' in item.data && item.data.reportedToGoDaddy && (
                  <PillLabel
                    label={'GoDaddy Notified'}
                    customStyle={{
                      backgroundColor: '#ff9141',
                    }}
                  />
                )}
                {item.data &&
                  'reportedToGoogleSafeBrowsing' in item.data &&
                  item.data.reportedToGoogleSafeBrowsing && (
                    <PillLabel
                      label={'Google Safebrowsing Notified'}
                      customStyle={{
                        backgroundColor: 'coral',
                      }}
                    />
                  )}
                {item.data &&
                  'reportedToCloudflare' in item.data &&
                  item.data.reportedToCloudflare && (
                    <PillLabel
                      label={'Cloudflare Security Notified'}
                      customStyle={{
                        backgroundColor: '#05c1af',
                      }}
                    />
                  )}
              </div>
            </>
          );
        }
      };

      const renderDispositions = () => {
        return (
          <>
            <span>
              <DispositionStatus status={dispositions[0]} displayedStyle={'pill'} />
            </span>
            <img
              src={
                selectedTheme === ThemeModes.DARK.toLowerCase() ? BigArrowLeftWhite : BigArrowLeft
              }
              alt={'change to'}
            />
            <span>
              <DispositionStatus status={dispositions[1]} displayedStyle={'pill'} />
            </span>
          </>
        );
      };
      const renderContentChanged = () => {
        return contentChanged.map((change, index) => {
          return <Fragment key={index}>{change}</Fragment>;
        });
      };
      if (showCollapased) {
        return (
          <li
            className={key === 0 ? 'event first-event' : 'event'}
            data-date={`${thisTime} ${moment(groupedAutoTakedownTimestamps[0]).format(format1)}`}
            key={key}
          >
            <Accordion
              as={'div'}
              onSelect={() => this.toggleExpander(key.toString())}
              className={' event d-flex flex-column'}
            >
              <Accordion.Toggle as={'div'} eventKey={key.toString()} className='d-flex flex-column'>
                <div className='expandable-row-wrapper d-flex flex-row'>
                  <div className='icon-wrapper mr-1'>
                    <ChevronDownIcon
                      width='15'
                      height='8'
                      color={'grey'}
                      className={`chevron-icon ml-1 ${
                        this.state.rowExpanded[key.toString()] ? 'chevron-up' : ''
                      }`}
                    />
                  </div>
                  <div className='title font-weight-bold'>{title}</div>
                </div>

                <div className='by-who text-capitalize'>by {activityBy}</div>
                {timelineBody(item)}
              </Accordion.Toggle>
              {this.state.rowExpanded[key.toString()] && (
                <Accordion.Collapse eventKey={key.toString()} className={'px-2'}>
                  <div>
                    {groupedAutoTakedownTimestamps
                      .sort((a, b) => new Date(b).valueOf() - new Date(a).valueOf())
                      .map((timestamp, index) => {
                        return (
                          <div className={'by-who'} key={index}>
                            {moment(timestamp).format(format1)}
                          </div>
                        );
                      })}
                  </div>
                </Accordion.Collapse>
              )}
            </Accordion>
          </li>
        );
      } else if (!item.data?.skipAutoTakedown) {
        return (
          <li className={key === 0 ? 'event first-event' : 'event'} data-date={thisTime} key={key}>
            <div className='d-flex flex-row align-items-center justify-content-between'>
              <div className='title font-weight-bold'>{title}</div>
              {isAdminUser(this.props.user) && item.eventType === TimeLineEventType.SOC_ACTION && (
                <WebSocActionColRender eventData={item} actionFrom={ESocActionOrigin.TIMELINE} />
              )}
            </div>

            <div className='d-flex flex-row disposition-changes'>
              {dispositions.length > 0 && renderDispositions()}
            </div>
            <div className='d-flex flex-row pill-container'>
              {contentChanged.length > 0 && renderContentChanged()}
            </div>
            <div className='by-who text-capitalize'>by {activityBy}</div>
            {timelineBody(item)}
          </li>
        );
      }
    };

    return (
      <>
        <div className={'timeline-container p-3  '}>
          <LoadingWrapper isLoading={this.state.isLoading}>
            <div>
              <div className='card'>
                <div className={'pt-4 pb-2 px-4 d-flex justify-content-start'}>
                  <div>
                    <LabelAndValue
                      alignItems={'flexStart'}
                      label={'Initial Takedown Request'}
                      value={this.state.initialTakedownRequestTs}
                      noMarginBottom
                      width={'auto'}
                      direction={'column'}
                    />
                  </div>

                  <div className={'ml-4'}>
                    <LabelAndValue
                      alignItems={'flexStart'}
                      label={'Site Taken Down'}
                      value={this.state.siteTakenDownTs}
                      width={'auto'}
                      noMarginBottom
                      direction={'column'}
                    />
                  </div>
                </div>
                <hr />
                <div className='card-body'>
                  <ul className='timeline'>
                    {data && data.length !== 0 ? (
                      (data as EventHistoryEntity<TimelineEventTypes>[]).map(
                        (item, key: number) => {
                          return constructTimeLineItem(item, key);
                        },
                      )
                    ) : (
                      <div> Could Not Find Timeline Events</div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </LoadingWrapper>
          <UserCommentModal
            show={this.state.requestEventHistoryId != null}
            urlSha256={this.props.urlSHA256}
            requestEventHistoryId={this.state.requestEventHistoryId}
            onSubmit={() => {
              this.setState({ requestEventHistoryId: null });
            }}
            onCancel={() => this.setState({ requestEventHistoryId: null })}
          />
        </div>
      </>
    );
  }
}

interface ILinkDispatchProps {
  alertError: IAlertDispatchProps['alertError'];
  logout: () => void;
}

const mapStateToProps = (state: AppState) => {
  const { user, timelineUpdated, brandInfo } = state.dashboardReducer;
  const { loggingIn, loggedIn } = state.login;
  const { shouldRefreshTimeline } = state.timelineReducer;
  return { loggingIn, loggedIn, user, timelineUpdated, shouldRefreshTimeline, brandInfo };
};

const mapDispatchToProps = {
  alertError: alertActions.error,
  logout: userActions.logout,
};

const connectedTimeline = connect(mapStateToProps, mapDispatchToProps)(Timeline);
export { connectedTimeline as Timeline };

import React, { useCallback } from 'react';
import { updateTeamMember } from '../../../TeamMembers/team-member-requests';
import { useAppDispatch } from '../../../../helpers/hooks';
import { alertActions } from '../../../../actions';
import { setShouldRefreshClientSideTable } from '../../../../reducers/table.reducer';
import _ from 'lodash';

const useOnAgGridEditCell = () => {
  const dispatch = useAppDispatch();

  const onHandleCellEdit = useCallback(
    async (params: any) => {
      try {
        const { data, newValue, oldValue, column } = params;
        const payload = {
          ...data,
          [column.colId]: newValue,
        };
        await updateTeamMember(payload);
        dispatch(alertActions.success(`User ${oldValue} updated to ${newValue} successfully!`));
      } catch (error) {
        dispatch(alertActions.error(_.get(error, ['User'], 'Edit user failed!')));
      } finally {
        dispatch(setShouldRefreshClientSideTable(true));
      }
    },
    [dispatch],
  );
  return { onHandleCellEdit };
};

export default useOnAgGridEditCell;

import React from 'react';
import { Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LabelAndValue } from '../../../../Common/LabelAndValue';
import { LoadingWrapper } from '../../../../Common/LoadingWrapper';
import { DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME } from '../../../DarkWebConstants';
import { IDarkWebFindingData } from '../../Types/DarkWeb.types';
import moment from 'moment';
import PlatformDetails from '../../Common/PlatformDetails';
import Tags from '../../../../Common/Tags/Tags';
interface IScanResultSectionProps {
  data: IDarkWebFindingData;
  isLoading: boolean;
  type: string;
}

export default function ScanResultSection({ data, isLoading, type }: IScanResultSectionProps) {
  const {
    threat_actor,
    category,
    url,
    network,
    platform,
    searchTerm,
    highlights,
    platform_published_ts,
  } = data;
  return (
    <Card className='card-between'>
      <Tags rowData={data} type={type} />
      <Card.Header className='self-card-header'>Scan Results</Card.Header>
      <LoadingWrapper isLoading={isLoading} className='p-4'>
        <Card.Body className='self-card-body'>
          <Row>
            <Col sm={3}>
              <LabelAndValue label={'Category'} value={category} direction={'column'} />
            </Col>
            <Col sm={3}>
              <LabelAndValue
                label={'Threat Actor'}
                value={threat_actor}
                direction={'column'}
                renderDom={
                  threat_actor ? (
                    <div className={'table-source-url-column d-flex align-items-center'}>
                      <Link
                        to={`${DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME}/insights/url/${threat_actor}`}
                      >
                        {threat_actor}
                      </Link>
                    </div>
                  ) : (
                    <div>--</div>
                  )
                }
              />
            </Col>
            <Col sm={3}>
              <LabelAndValue
                label={'URL'}
                value={url ? url : '--'}
                direction={'column'}
                longTextLineNumberLimit={1}
                copyButton={url ? true : false}
              />
            </Col>
            <Col sm={3}>
              <LabelAndValue
                label={'Discovery Date'}
                value={moment(platform_published_ts).format('DD-MMM-YYYY')}
                direction={'column'}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <LabelAndValue label={'Network'} value={network} direction={'column'} />
            </Col>
            <Col sm={3}>
              <LabelAndValue
                label={'Platform'}
                value={platform}
                direction={'column'}
                renderDom={<PlatformDetails data={data} />}
              />
            </Col>
            <Col sm={3}>
              <LabelAndValue label={'Search Term'} value={searchTerm} direction={'column'} />
            </Col>
            <Col sm={3}>
              <LabelAndValue label={'Highlights'} value={highlights} direction={'column'} />
            </Col>
          </Row>
        </Card.Body>
      </LoadingWrapper>
    </Card>
  );
}

import React, { useCallback, useRef } from 'react';
import { getLocalStorageValue } from '../../../../constants';
import { REPORTS_TABLE_ID } from '../../../Reports/ReportsConstant';

export default function useOnGridReadyForReports(
  datasource: any,
  tableId: typeof REPORTS_TABLE_ID,
) {
  const isFilterModelCacheUsed = useRef(false);

  const onGridReady = useCallback(
    params => {
      params.api.setGridOption('serverSideDatasource', datasource);
      // first render, only initiate filter model from local storage.
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource, tableId],
  );

  return { onGridReady };
}

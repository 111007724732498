import React, { useContext, useEffect } from 'react';
import { useAppSelector } from '../../../helpers/hooks';
import {
  ThemeModes,
  appConstants,
  featureIsAvailable,
  getProvidedTagLabelValue,
} from '../../../constants';
import { ITags } from '../Tags/Tags';
import ThemeContext from '../../../context/ThemeContext';
import { checkTagForFilterOptions } from '../Table/ag-table/ag-utils';

const useOnTagFilterOptions = (
  agRefs: React.MutableRefObject<any>,
  columns: any[],
  colFieldId: 'tags' | 'tags.id',
  moduleType?: string,
) => {
  const user = useAppSelector(state => state.dashboardReducer.user);
  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);
  const { selectedTheme } = useContext(ThemeContext);

  const isCustomTagFeatureAvailable = featureIsAvailable(
    user,
    appConstants.FEATURE_CODE.CUSTOMER_GENERATED_TAGS,
  );

  const filterTags = providedTags.filter((tag: ITags) => {
    return checkTagForFilterOptions(tag, isCustomTagFeatureAvailable, moduleType);
  });

  const restrictedTagSM =
    moduleType === appConstants.CONTENT_TYPE.SOCIAL
      ? providedTags.filter((tag: any) => tag.label === 'Marked as Scam')
      : [];

  const tagsDropdown = getProvidedTagLabelValue(
    [...filterTags, ...restrictedTagSM],
    selectedTheme === ThemeModes.DARK.toLowerCase(),
  );

  useEffect(() => {
    const colDefs: any = agRefs.current?.api?.getColumnDefs();
    if (!colDefs) return;

    const tagColDef = colDefs.find((col: any) => col.field === colFieldId);
    if (!tagColDef) return;

    tagColDef.filterParams = {
      values: [
        // 'Any tags',
        'No tags',
        ...new Set(tagsDropdown?.map((option: { label: string; value: string }) => option.label)),
      ],
      suppressSorting: true,
      defaultToNothingSelected: true,
      buttons: ['reset'],
      suppressSelectAll: true,
    };

    agRefs.current?.api?.setGridOption('columnDefs', colDefs);
  }, [agRefs, colFieldId, columns, tagsDropdown]);
};

export default useOnTagFilterOptions;

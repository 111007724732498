import React, { useRef, useState } from 'react';
import { useAppSelector } from '../../../helpers/hooks';
import ModalOverlay from '../../Common/ModalOverlay';
import { Form } from 'react-bootstrap';
import { Dropdown, IDropdownOption } from '../../Common/Dropdown';
import Label from '../../Common/Label';
import { TextInput } from '../../Common/TextInput';
import _, { capitalize } from 'lodash';
import {
  PlatformMainModules,
  PlatformModule,
  appConstants,
  featureIsAvailable,
  isBrandModuleAdmin,
} from '../../../constants';
import { useAvailableModules } from '../../../hooks/useAvailableModules';

export interface INewMemberPayload {
  firstName: string;
  lastName: string;
  email: string;
  brandId: string;
  roleId: string;
  disabledModules: PlatformModule[];
}
export interface IAddTeamMemberModalProps {
  showAddTeamMembersModal: boolean;
  closeAddTeamMembersModal: () => void;
  onAddNewMember: (payload: INewMemberPayload) => Promise<void>;
  isLoading: boolean;
}

enum TeamRole {
  Admin = '3',
  User = '2',
  ReadOnly = '-1',
  BrandModuleAdmin = '-2',
}

export default function AddTeamMemberModal(props: IAddTeamMemberModalProps) {
  const DEFAULT_ROLE = { label: 'User', value: TeamRole.User };
  const { showAddTeamMembersModal, onAddNewMember, closeAddTeamMembersModal, isLoading } = props;
  const [isInvalid, setIsInvalid] = useState(true);

  const user = useAppSelector(state => state.dashboardReducer.user);
  const isReadOnlyFeatureOn = featureIsAvailable(
    user,
    appConstants.FEATURE_CODE.READ_ONLY_USER_PER_MODULE,
  );

  const allAvailableModules = useAvailableModules();

  const formValues = useRef<INewMemberPayload>({
    firstName: '',
    lastName: '',
    email: '',
    brandId: '',
    roleId: DEFAULT_ROLE.value,
    disabledModules: isReadOnlyFeatureOn ? PlatformMainModules : [], // all are unchecked by default if RO on
  });

  const brandInfo = useAppSelector(state => state.dashboardReducer.brandInfo);

  const updateFormValues = (value: any, field: string) => {
    formValues.current[field] = value;
    const { firstName, lastName, email, roleId, disabledModules } = formValues.current;
    const enabledModules = allAvailableModules.filter(module => !disabledModules.includes(module));
    setIsInvalid(
      _.isEmpty(firstName) ||
        _.isEmpty(lastName) ||
        _.isEmpty(email) ||
        _.isEmpty(roleId) ||
        _.isEmpty(enabledModules),
    );
  };
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateFormValues(event.target.value, controlId);
  };
  const onFormSubmit = () => {
    // send customized disabled payload to backend
    const disabled = formValues.current.disabledModules.map(module => ({
      code: module.disabledCode,
      enabled: true,
    }));

    const isModuleAdmin = formValues.current?.roleId === TeamRole.Admin && disabled.length !== 0;
    if (isReadOnlyFeatureOn && isModuleAdmin) {
      formValues.current.roleId = TeamRole.BrandModuleAdmin;
    }

    const payload = { ...formValues.current, disabled };
    void onAddNewMember(payload);
    closeAddTeamMembersModal();
  };

  const handleDropdownChange = (option: IDropdownOption, controlId: string) => {
    updateFormValues(option.value, controlId);
  };

  const setBrandColumnOptions = () => {
    let brandOptions: any = [];
    if (brandInfo) {
      brandOptions = _.chain(brandInfo.brand.subBrands)
        .map(subBrand => ({
          label: capitalize(subBrand.brand_display_name),
          value: subBrand.brand_id.toString(),
        }))
        .sortBy('label')
        .value();
    }
    return brandOptions;
  };

  return (
    <ModalOverlay
      show={showAddTeamMembersModal}
      title='Add Team Member'
      isLoading={isLoading}
      onSubmit={onFormSubmit}
      onCancel={closeAddTeamMembersModal}
      submitButtonDisabled={isInvalid}
      submitButtonLabel='Submit'
    >
      <Form>
        <Form.Group>
          <Label label='First Name' isRequired />
          <TextInput controlId='firstName' onChange={handleTextFieldChange} />
        </Form.Group>
        <Form.Group>
          <Label label='Last Name' isRequired />
          <TextInput controlId='lastName' onChange={handleTextFieldChange} />
        </Form.Group>
        <Form.Group>
          <Label label='Email' isRequired />
          <TextInput controlId='email' onChange={handleTextFieldChange} />
        </Form.Group>
        <Form.Group>
          <Label label='Brand' isRequired />
          <Dropdown
            boxStyle
            options={setBrandColumnOptions()}
            onChange={handleDropdownChange}
            controlId='brandId'
            defaultSelection={setBrandColumnOptions()[0]}
          />
        </Form.Group>
        <Form.Group>
          <Label label='Role' isRequired />
          <Dropdown
            btnClassName='font-weight-normal'
            boxStyle
            options={
              [
                !isBrandModuleAdmin(user) && { label: 'Admin', value: TeamRole.Admin },
                { label: 'User', value: TeamRole.User },
                isReadOnlyFeatureOn && {
                  label: 'Read-Only',
                  value: TeamRole.ReadOnly,
                },
              ].filter(Boolean) as IDropdownOption[]
            }
            onChange={handleDropdownChange}
            controlId='roleId'
            defaultSelection={DEFAULT_ROLE}
          />
        </Form.Group>
        {/* checkboxes for Web, Social Media, App Store, Dark Web */}
        {isReadOnlyFeatureOn && (
          <Form.Group controlId='Modules'>
            <Label label='Modules' isRequired />
            {/* Send only disabled code to backend */}
            {allAvailableModules.map((item, index) => (
              <Form.Check
                key={index}
                type='checkbox'
                label={item.name}
                id={`${item.disabledCode}-checkbox`}
                inline
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target;
                  const disabledModules = [...formValues.current.disabledModules];
                  if (!checked) {
                    disabledModules.push(item);
                  } else {
                    _.remove(disabledModules, module => module.disabledCode === item.disabledCode);
                  }
                  console.log('disabledModules', disabledModules);
                  updateFormValues(disabledModules, 'disabledModules');
                }}
              />
            ))}
          </Form.Group>
        )}
      </Form>
    </ModalOverlay>
  );
}

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import ThemeContext from '../../../context/ThemeContext';
import { useOnClickOutside } from '../CustomHooks/useOnClickOutside';
import './PersistentFilters.scss';
import { Checkbox } from '../Checkbox';
import { DotDotDot, EyeGreyIcon } from '../../../assets/SVGIcons';
import { Dropdown } from '../Dropdown';
import { alertActions } from '../../../actions';
import SaveFiltersOverlay, { ESelectedSaveOption } from './SaveFiltersOverlay';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  setCurrentAppliedPersistentFilter,
  setIsFilterFromDashboard,
  setIsPersistentFilterDeleteMode,
  setIsPersistentFilterEditMode,
  setIsPersistentFilterEditViewClicked,
  setIsPersistentFilterSaveButtonVisible,
  setIsPersistentFilterSaveMode,
  setOutGoingPersistentFilterString,
} from '../../../reducers/table.reducer';
import {
  ISavedFilters,
  fetchPersistentFiltersData,
  saveNewPersistentFilter,
  deletePersistentFilter,
  FetchPersistentFiltersResponse,
  updatePersistentFilter,
} from './PersistentFiltersRequest';
import { LoadingWrapper } from '../LoadingWrapper';
import { setLocalStorageValue } from '../../../constants';
import { useReadOnlyUser } from '../../../basic-hooks/useUserRoles';

interface IPersistentFiltersProps {
  tableId: string;
  moduleType?: string;
}

export enum ESavedFilterOptionTypes {
  DELETE = 'delete',
  RENAME = 'rename',
}

export enum ESavedFilterTypes {
  SAVED_BY_ORG = 'saved_by_org',
  SAVED_BY_BOLSTER = 'saved_by_bolster',
}

const PersistentFilters = ({ tableId, moduleType }: IPersistentFiltersProps) => {
  const savedFilterRefs = useRef<HTMLDivElement>(null);

  const DEFAULT_DROPDOWN_OPTIONS = [
    { label: 'Rename', value: ESavedFilterOptionTypes.RENAME },
    { label: 'Delete filter', value: ESavedFilterOptionTypes.DELETE },
  ];

  const [allSavedFilters, setAllSavedFilters] = useState<any[]>([]);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isOrgSavedFilterPresent = useRef<boolean>(false);

  const outGoingPersistentFilterString = useAppSelector(
    state => state.tableReducer.outGoingPersistentFilterString,
  );
  const isPersistentFilterSaveButtonVisible = useAppSelector(
    state => state.tableReducer.isPersistentFilterSaveButtonVisible,
  );

  const isPersistentFilterEditMode = useAppSelector(
    state => state.tableReducer.isPersistentFilterEditMode,
  );
  const isPersistentFilterSaveMode = useAppSelector(
    state => state.tableReducer.isPersistentFilterSaveMode,
  );
  const isPersistentFilterDeleteMode = useAppSelector(
    state => state.tableReducer.isPersistentFilterDeleteMode,
  );
  const isPersistentFilterEditViewClicked = useAppSelector(
    state => state.tableReducer.isPersistentFilterEditViewClicked,
  );

  const user = useAppSelector(state => state.dashboardReducer.user);

  const { selectedTheme } = useContext(ThemeContext);

  const dispatch = useAppDispatch();

  useOnClickOutside(savedFilterRefs, () => {
    setShowOverlay(false);
  });

  useEffect(() => {
    setIsLoading(true);
    try {
      const fetchData = async () => {
        if (moduleType) {
          const response: FetchPersistentFiltersResponse[] = await fetchPersistentFiltersData({
            tableId,
            moduleType,
          });
          if (response && response.length > 0) {
            const savedFilters = [];

            const groupedByOrg = response.filter((item: any) => item.defaultFilter === false);
            const groupedByBolster = response.filter((item: any) => item.defaultFilter === true);
            if (groupedByOrg.length > 0) {
              isOrgSavedFilterPresent.current = true;
            }

            savedFilters.push({
              [ESavedFilterTypes.SAVED_BY_ORG]: groupedByOrg.map((savedFilter: any) => {
                return {
                  name: savedFilter.filter?.name || '',
                  id: savedFilter.id,
                  filterString: savedFilter.filter.value,
                  isUsers: savedFilter.isUsers,
                };
              }),
              [ESavedFilterTypes.SAVED_BY_BOLSTER]: groupedByBolster.map((savedFilter: any) => {
                return {
                  name: savedFilter.filter?.name || '',
                  id: savedFilter.id,
                  filterString: savedFilter.filter.value,
                };
              }),
            });
            setAllSavedFilters(savedFilters);
          }
          setIsLoading(false);
        }
      };
      if (showOverlay) {
        void fetchData();
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      setIsLoading(false);
    };
  }, [moduleType, showOverlay, tableId]);

  const onSavedFilterClick = (item: any) => {
    try {
      dispatch(setCurrentAppliedPersistentFilter(JSON.parse(item.filterString)));
    } catch (error) {
      console.log(error);
    }
  };

  //Clear all applied filters state
  const onClearFilters = () => {
    setLocalStorageValue(['tableFilterModel', tableId], {});
    dispatch(setCurrentAppliedPersistentFilter({}));
    dispatch(setIsPersistentFilterSaveButtonVisible(false));
    dispatch(setOutGoingPersistentFilterString('{}'));
    dispatch(setIsFilterFromDashboard(false));
  };

  const onSelectedFilter = (selected: any, selectedFilter: any) => {
    if (isPersistentFilterDeleteMode && !isPersistentFilterEditMode) {
      if (selected) {
        const isValid = selectedFilter.isUsers;
        if (!isValid) {
          dispatch(alertActions.error('You cannot delete the filter saved by other user'));
          return;
        }
        setSelectedItems([...selectedItems, selectedFilter]);
      } else {
        setSelectedItems(selectedItems.filter(item => item.id !== selectedFilter.id));
      }
    } else if (!isPersistentFilterDeleteMode && isPersistentFilterEditMode) {
      if (selected) {
        const isValid = selectedFilter.isUsers;
        if (!isValid) {
          dispatch(alertActions.error('You cannot edit the filter saved by other user'));
          return;
        }
        setSelectedItems([selectedFilter]);
      }
    }
  };

  const onDeleteSavedFilter = async () => {
    if (!selectedItems.length) {
      dispatch(alertActions.error('Please select atleast one filter to delete'));
      return;
    }

    try {
      const deleteFilterIds = selectedItems.map(item => item.id);
      const response = await deletePersistentFilter({ filterIds: deleteFilterIds });
      if (response && response.success) {
        dispatch(alertActions.success('Saved filter deleted successfully'));
      }
      onCancelHandler();
    } catch (error) {
      console.log(error);
    }
  };

  const onEditSavedFilter = () => {
    if (!selectedItems.length) {
      dispatch(alertActions.error('Please select atleast one filter to rename'));
      return;
    }
    setShowOverlay(false);
    dispatch(setIsPersistentFilterEditViewClicked(true));
    dispatch(setIsPersistentFilterEditMode(true));
    dispatch(setIsPersistentFilterDeleteMode(false));
    dispatch(setIsPersistentFilterSaveMode(false));
  };

  const onPersistentFilterSaved = (saveFor: ESelectedSaveOption, filterName: string) => {
    //Edit mode
    if (isPersistentFilterEditMode && !isPersistentFilterDeleteMode) {
      filterOnEditingHandler(filterName);
      return;
    } else {
      //Save mode
      const savePayload: ISavedFilters = {
        moduleType: moduleType || '',
        tableName: tableId,
        visibility: saveFor === ESelectedSaveOption.SAVE_FOR_ME ? false : true,
        filter: { name: filterName, value: outGoingPersistentFilterString },
      };

      saveNewPersistentFilter(savePayload)
        .then(() => {
          dispatch(alertActions.success('Filter saved successfully'));
        })
        .catch(error => {
          if (error && error.message) {
            dispatch(alertActions.error(error.message));
          }
        })
        .finally(() => {
          onCancelHandler();
          dispatch(setIsPersistentFilterSaveButtonVisible(false));
        });
    }
  };

  const filterOnEditingHandler = (filterName: string) => {
    const updateFilterPayload: { id: number; filter: { name: string; value: string } } = {
      id: selectedItems[0]?.id,
      filter: { name: filterName, value: selectedItems[0]?.filterString },
    };
    void updatePersistentFilter(updateFilterPayload)
      .then(response => {
        if (response && response.success) {
          dispatch(alertActions.success('Saved filter renamed successfully'));
          onCancelHandler();
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(alertActions.error('Error while updating filter. Please try again.'));
      });
    dispatch(setIsPersistentFilterSaveButtonVisible(false));
  };

  const onCancelHandler = () => {
    setShowOverlay(false);
    setSelectedItems([]);
    dispatch(setIsPersistentFilterSaveMode(false));
    dispatch(setIsPersistentFilterEditMode(false));
    dispatch(setIsPersistentFilterDeleteMode(false));
    dispatch(setIsPersistentFilterEditViewClicked(false));
  };
  const renderSavedFilters = () => {
    if (!allSavedFilters.length) {
      return (
        <div className='empty-persistent-filters-text'> Currently there are no saved views</div>
      );
    }

    return allSavedFilters.map((filter, index) => {
      return (
        <div className='filters-wrapper' key={index}>
          {filter[ESavedFilterTypes.SAVED_BY_ORG] &&
            filter[ESavedFilterTypes.SAVED_BY_ORG].length > 0 && (
              <div className='persistent-filter-header'>Saved By {user?.organization || '--'}</div>
            )}
          {filter[ESavedFilterTypes.SAVED_BY_ORG] &&
            filter[ESavedFilterTypes.SAVED_BY_ORG].map((filter: any) => {
              return (
                <div
                  className={`persistent-filter ${
                    outGoingPersistentFilterString === filter.filterString ? 'active' : ''
                  }`}
                  key={filter.id}
                >
                  {isPersistentFilterEditMode || isPersistentFilterDeleteMode ? (
                    <Checkbox
                      isStateless={true}
                      onChange={selection => {
                        onSelectedFilter(selection, filter);
                      }}
                      key={filter.id}
                      defaultChecked={selectedItems.some(item => item.id === filter.id)}
                    />
                  ) : (
                    <div style={{ width: '16px' }}></div>
                  )}
                  <div
                    className='persistent-filter-name'
                    data-testid='persistent-filter-name'
                    onClick={() => onSavedFilterClick(filter)}
                    id={filter.id}
                  >
                    {filter.name}
                  </div>
                </div>
              );
            })}
          {filter[ESavedFilterTypes.SAVED_BY_BOLSTER] &&
            filter[ESavedFilterTypes.SAVED_BY_BOLSTER].length > 0 && (
              <div className='persistent-filter-header'>Saved By Bolster</div>
            )}
          {filter[ESavedFilterTypes.SAVED_BY_BOLSTER] &&
            filter[ESavedFilterTypes.SAVED_BY_BOLSTER].map((filter: any) => {
              return (
                <div className='persistent-filter' key={filter.id}>
                  <div style={{ width: '16px' }}></div>
                  <div
                    className='persistent-filter-name'
                    data-testid='persistent-filter-name'
                    onClick={() => onSavedFilterClick(filter)}
                    id={filter.id}
                  >
                    {filter.name}
                  </div>
                </div>
              );
            })}
        </div>
      );
    });
  };

  const enterDeleteMode = useCallback(() => {
    dispatch(setIsPersistentFilterEditMode(false));
    dispatch(setIsPersistentFilterDeleteMode(true));
    dispatch(setIsPersistentFilterSaveMode(false));
    dispatch(setIsPersistentFilterEditViewClicked(false));
  }, []);

  const enterEditMode = useCallback(() => {
    dispatch(setIsPersistentFilterEditMode(true));
    dispatch(setIsPersistentFilterDeleteMode(false));
    dispatch(setIsPersistentFilterSaveMode(false));
    dispatch(setIsPersistentFilterEditViewClicked(false));
  }, []);

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <>
      {outGoingPersistentFilterString !== '{}' && (
        <div className='clear-filter-btn' onClick={onClearFilters}>
          Clear filters
        </div>
      )}
      {(isPersistentFilterSaveMode ||
        isPersistentFilterEditViewClicked ||
        isPersistentFilterSaveButtonVisible) && (
        <SaveFiltersOverlay
          onPersistentFilterSaved={onPersistentFilterSaved}
          allPersistFilters={allSavedFilters}
          selectedFilterForEdit={selectedItems}
        />
      )}
      <div
        ref={savedFilterRefs}
        data-testid='persistent-filters-wrapper'
        className='persistent-filters-wrapper tool-buttons-wrapper '
        key={'persistent-filters-wrapper'}
      >
        <div data-testid='eye-icon' id={'eye-icon'} onClick={() => setShowOverlay(true)}>
          <EyeGreyIcon color='grey' />
        </div>
        <Overlay
          target={savedFilterRefs.current}
          show={showOverlay}
          placement={'left'}
          flip={false}
          container={savedFilterRefs}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              data-testid='persistent-filter-overlay'
              {...props}
              className={`${selectedTheme} persistent-filters-container`}
              style={{
                ...props.style,
              }}
            >
              <LoadingWrapper isLoading={isLoading}>
                <div className='ellipsis-wrapper'>
                  <div className='persistent-filters-title'>Saved Filters</div>
                  {isOrgSavedFilterPresent.current && !isReadOnlyUser && (
                    <Dropdown
                      options={DEFAULT_DROPDOWN_OPTIONS}
                      onChange={selection => {
                        if (selection.value === ESavedFilterOptionTypes.DELETE) {
                          enterDeleteMode();
                        } else if (selection.value === ESavedFilterOptionTypes.RENAME) {
                          enterEditMode();
                        }
                      }}
                      btnClassName={'bg-transparent'}
                      hideArrow
                      fixedPlaceholder={<DotDotDot />}
                    />
                  )}
                </div>
                <div className='persistent-filters-wrapper'>{renderSavedFilters()}</div>
                {(isPersistentFilterEditMode || isPersistentFilterDeleteMode) && (
                  <div className='delete-view-wrapper'>
                    <div
                      className='cancel-delete-view'
                      data-testid='cancel-delete-view'
                      onClick={onCancelHandler}
                    >
                      Cancel
                    </div>
                    <div
                      className='delete-view-btn'
                      onClick={
                        isPersistentFilterDeleteMode && !isPersistentFilterEditMode
                          ? onDeleteSavedFilter
                          : onEditSavedFilter
                      }
                    >
                      {isPersistentFilterDeleteMode && !isPersistentFilterEditMode
                        ? 'Delete Filter'
                        : 'Rename'}
                    </div>
                  </div>
                )}
              </LoadingWrapper>
            </div>
          )}
        </Overlay>
      </div>
    </>
  );
};
export default PersistentFilters;

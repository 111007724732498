import React, { useCallback, useMemo } from 'react';
import { appConstants } from '../../../constants';

const useTagsChangeHandler = ({
  type,
  onTagsChangeHandler,
  selectedTags,
  detectionData,
  activeTags,
}: any) => {
  const updateParentState = useCallback(() => {
    if (type === appConstants.CONTENT_TYPE.BULK_SCAN) {
      onTagsChangeHandler &&
        selectedTags.length &&
        onTagsChangeHandler(selectedTags.length > 0, detectionData?.url_sha256, selectedTags);
    } else {
      onTagsChangeHandler && onTagsChangeHandler(true, detectionData?.url_sha256, activeTags);
    }
  }, [type, onTagsChangeHandler, selectedTags, detectionData, activeTags]);

  return { updateParentState };
};

export default useTagsChangeHandler;

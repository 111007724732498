import { AppState } from '../../helpers';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import ModalOverlay from '../Common/ModalOverlay';
import { Form } from 'react-bootstrap';
import './soc-takedown.scss';
import CheckCircleIcon from '../../assets/icons/CheckCircle.svg';
import { UploadFiles } from '../Common/UploadFiles';
import { formatError } from '../../helpers/errors';
import { alertActions } from '../../actions';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { getSingleOrPluralForm, IAlertDispatchProps } from '../../constants';
import { SocTakedownService } from '../../services/soc-takedown.service';
import { DomainMap } from './soc-takedown-request-page.component';

const socTakedownService = new SocTakedownService();

export interface ISocTakedownCommentsProps {
  show: boolean;
  domainMap: DomainMap;
  urlSha256s: string[];
  onSubmit: () => void;
  onCancel: () => void;
}
export interface SocCustomerMessageDto {
  urlSha256s: string[];
  message: string;
}

function SocTakedownCommentsModal({
  show,
  domainMap,
  urlSha256s,
  onSubmit,
  onCancel,
  alertSuccess,
  alertError,
}: ISocTakedownCommentsProps & Pick<IAlertDispatchProps, 'alertSuccess' | 'alertError'>) {
  const [comments, setComments] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  async function commentsSubmission(comments: string, files: File[]) {
    const CommentSent: SocCustomerMessageDto = {
      urlSha256s: urlSha256s,
      message: comments,
    };

    try {
      setError('');
      if (comments.trim().length < 1) {
        setError('A message is required to add to the timeline.');
      } else {
        setSubmitting(true);
        await socTakedownService.submitSocCustomerMessage(CommentSent, files);
        alertSuccess('Successfully Submitted Comments');
        onSubmit();
      }
    } catch (error) {
      alertError(formatError(error));
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <ModalOverlay
      show={show}
      title={'Leave a Message on Timeline'}
      submitButtonDisabled={submitting}
      onSubmit={() => void commentsSubmission(comments, files)}
      onCancel={onCancel}
      submitButtonLabel={'Add to Timeline'}
      cancelButtonLabel={'Skip'}
      size={'xl'}
    >
      <LoadingWrapper isLoading={submitting}>
        <Form className='comments-container'>
          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
            <div className={'logo-containers'}>
              <span className={'global-alert-message'}>
                <img
                  src={CheckCircleIcon}
                  alt={'Check Circle Icon'}
                  className={'alert-status-icon'}
                />
                Successfully submitted{' '}
                {getSingleOrPluralForm(Object.keys(domainMap).length, 'takedown')}.
              </span>
            </div>
            <Form.Control
              value={comments}
              onChange={e => setComments(e.target.value)}
              as='textarea'
              placeholder={'Add a message for customer'}
              rows={7}
            />
            <Form.Group className={'mt-4'}>
              <UploadFiles onChangeHandler={x => setFiles(x)} dragAndDrop controlId='uploadFiles' />
            </Form.Group>
          </Form.Group>
        </Form>
        {error && <div className='my-2 text-danger'>{error}</div>}
      </LoadingWrapper>
    </ModalOverlay>
  );
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocTakedownCommentsModal);

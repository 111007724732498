import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import './dashboard.scss';
import ReactApexChart from 'react-apexcharts';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { renderNoDataPlaceholder } from './dashboard.detection';
import NoDomain from '../../assets/icons/NoDomain.svg';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import DashboardService from '../../services/dashboard.service';
import { BrandType, GRAPH_HEIGHT_INDEX, ROW_HEIGHT } from '../../constants';

interface IDataArrayItem {
  domain: string;
  value: number;
}

interface IComponentProps {
  dashboardType: BrandType;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IComponentState {
  data: any;
  isLoading: boolean;
}

class DashboardDomain extends React.Component<IComponentProps, IComponentState> {
  private readonly dashboardService: DashboardService;
  private _isMounted = false;

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      data: {},
      isLoading: true,
    };
    this.dashboardService = new DashboardService();
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.getDomainData();
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  componentDidUpdate(prevProps: IComponentProps): void {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.getDomainData();
    }
  }

  getDomainData = () => {
    this.setCompState({
      isLoading: true,
    });

    const { dashboardType, startDate, endDate } = this.props;
    const query = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    this.dashboardService.getDomainWidgetData(query, dashboardType).then((res: any) => {
      this.setCompState({
        data: res.result,
        isLoading: false,
      });
    });
  };

  render() {
    const { data, isLoading } = this.state;
    let dataArray: IDataArrayItem[] = [];
    const series: number[] = [];
    const labels: string[] = [];

    _.forEach(data, (value, key) => {
      dataArray.push({
        domain: key,
        value: value,
      });
    });
    dataArray = _.reverse(_.sortBy(dataArray, ['value']));

    _.forEach(dataArray, (item: IDataArrayItem) => {
      if (series.length < 10) {
        series.push(item.value);
        labels.push('.' + item.domain);
      }
    });

    const options = {
      labels,
      colors: [
        '#FFA25F',
        '#FFB885',
        '#FFD3B2',
        '#A7DFFF',
        '#74C5F4',
        '#1BA1CC',
        '#007EA7',
        '#337F86',
        '#3CB2BC',
        '#76EAF5',
      ],
      dataLabels: {
        enabled: false,
      },
    };

    const title = 'Top Level Domains with Most Counterfeit Sites';

    return (
      <div className='dashboard-widget dashboard-tld-widget'>
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-hosting'} className={'table-source-url-tooltip'}>
              New counterfeit sites detected by Domain
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title'>{title}</div>
        </OverlayTrigger>
        <LoadingWrapper isLoading={isLoading}>
          {series.length ? (
            <div className={'donut-chart-container'}>
              <ReactApexChart
                options={options}
                series={series}
                height={ROW_HEIGHT * GRAPH_HEIGHT_INDEX}
                type='donut'
              />
            </div>
          ) : (
            renderNoDataPlaceholder(
              NoDomain,
              'No domain data found',
              'We haven’t detected any counterfeit site domains for this period. Please select another time frame.',
            )
          )}
        </LoadingWrapper>
      </div>
    );
  }
}

export { DashboardDomain };

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronRightIcon, TrendingDownIcon, TrendingUpIcon } from '../../../../assets/SVGIcons';
import { appConstants, DashBoardDto, featureIsAvailable } from '../../../../constants';
import DashboardService from '../../../../services/dashboard.service';
import { SummaryWidget } from '../../../Common/SummaryWidget';

const SEARCH_ENGINE_SCAN_COUNT = 'searchEngineScanCount';
const SUMMARY_FIELDS = [
  {
    fieldName: 'typosquattingScore',
    label: 'Typosquatting Score',
  },
  {
    fieldName: 'monitoredDomainVariants',
    label: 'Monitored Domain Variants',
  },
  {
    fieldName: 'monitoredRegisteredDomains',
    label: 'Monitored Registered Domains',
  },
  {
    fieldName: 'monitoredTLDs',
    label: 'Monitored TLDS',
  },
  {
    fieldName: 'recommenedDomainsToAcquire',
    label: 'Recommended Domains to Acquire',
  },
  {
    fieldName: SEARCH_ENGINE_SCAN_COUNT,
    label: 'Google/Bing Searches',
  },
];

interface IDashboardSummaryProp {
  user: DashBoardDto;
}

function DashboardSummary({ user }: IDashboardSummaryProp) {
  const dashboardService = new DashboardService();
  const [summaryData, setSummaryData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getSummaryData();
  }, []);

  const getSummaryData = () => {
    setIsLoading(true);
    dashboardService
      .getBrandDomainSummary()
      .then(res => {
        const data: any = [];
        const typosquattingScoreFeature = featureIsAvailable(
          user,
          appConstants.FEATURE_CODE.TYPOSQUATTING_SCORE,
        );
        const typosquattingScoreValue = _.get(res, ['typosquattingScore', 'value'], 0);
        const showTyposquattingScoreValue =
          typosquattingScoreFeature && typosquattingScoreValue !== 0;

        SUMMARY_FIELDS.forEach(field => {
          const { fieldName, label } = field;
          switch (fieldName) {
            case 'typosquattingScore': {
              if (!showTyposquattingScoreValue) {
                break;
              }
              const obj = {};
              obj['id'] = fieldName;
              obj['label'] = label;
              obj['value'] = res[fieldName];
              obj['tooltip'] = label;
              obj['valueDom'] = (
                <div className='summary-widget-item-value d-flex align-items-center'>
                  {res[fieldName].value}
                  {res[fieldName].change === 'up'
                    ? TrendingUpIcon
                    : res[fieldName].change === 'down'
                    ? TrendingDownIcon
                    : null}
                </div>
              );
              obj['thirdRowDom'] = (
                <OverlayTrigger
                  placement={'right'}
                  trigger={'click'}
                  overlay={
                    <Tooltip id='tooltip-bulk-scan'>
                      <Card.Text>
                        The Typosquatting score is a number in the range of 1 to 100 reflecting the
                        protection against typosquatting risks. In order to improve your score, you
                        should purchase additional P1 domains and automate the reporting of active
                        typosquatting domains via playbooks
                      </Card.Text>
                    </Tooltip>
                  }
                >
                  <div className='typosquatting-score-learn-more d-flex align-items-center justify-content-between'>
                    Learn more
                    {<ChevronRightIcon />}
                  </div>
                </OverlayTrigger>
              );
              data.push(obj);
              break;
            }
            default: {
              if (showTyposquattingScoreValue && fieldName === SEARCH_ENGINE_SCAN_COUNT) {
                break;
              }
              const obj = {};
              obj['id'] = fieldName;
              obj['label'] = label;
              obj['value'] = res[fieldName];
              obj['tooltip'] =
                fieldName === SEARCH_ENGINE_SCAN_COUNT
                  ? 'Number of links monitored based on company-related keyword searches'
                  : label;
              data.push(obj);
              break;
            }
          }
        });

        setIsLoading(false);
        setSummaryData(data);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return <SummaryWidget isLoading={isLoading} data={summaryData} />;
}

export { DashboardSummary };
